import Vue from 'vue';
import Router from 'vue-router';
import { checkRoles } from './checkRoles';
import store from '../store';
import notify from '../modules/notifier';

import Navigation from '../components/partials/navigation.vue';
import NotificationsIndex from '../components/notifications/index.vue';
import NotificationsList from '../components/notifications/list.vue';

import PageAuth from '../components/auth.vue';
import PageLogin from '../components/auth/login.vue';
import PagePayment from '../components/payment/payment.vue';
import PageLogout from '../components/auth/logout.vue';
import PageForgot from '../components/auth/forgot.vue';
import PageReset from '../components/auth/reset.vue';

import PageDashboard from '../components/dashboard/index.vue';

import PageInvoiceDetails from '../components/orders/view/invoices/order-invoice-details.vue';

import DashboardMyEstimates from '../components/dashboard/my-estimates';
import DashboardMyOrders from '../components/dashboard/my-orders';
import DashboardActiveOrders from '../components/dashboard/active-orders';
import DashboardUnclaimedEstimates from '../components/dashboard/unclaimed-estimates';
import DashboardUnclaimedBlanks from '../components/dashboard/unclaimed-blanks';
import DashboardUnclaimedMocks from '../components/dashboard/unclaimed-mocks';
import EstimatesIndex from '../components/estimates/index';
import EstimatesList from '../components/estimates/list';
import EstimatesDetails from '../components/estimates/details';
import CustomerEstimateApprovePublic from '../components/customer/estimate/approve-public';
import OrdersIndex from '../components/orders/index';
import OrdersOrders from '../components/orders/orders';
import OrdersCreate2 from '../components/orders/create2';
import OrdersViewIndex from '../components/orders/view/index';
import OrdersViewOrderDetails from '../components/orders/view/order-details';
import OrdersViewFiles from '../components/orders/view/files';
import OrdersViewInvoicesIndex from '../components/orders/view/invoices/index';
import OrdersViewInvoicesCreate from '../components/orders/view/invoices/create';
import OrdersViewInvoicesEdit from '../components/orders/view/invoices/edit';
import OrdersViewPaymentsIndex from '../components/orders/view/payments/index';
import AccountsIndex from '../components/accounts/index';
import AccountsAccounts from '../components/accounts/accounts';
import AccountsCreate from '../components/accounts/create';
import AccountsView from '../components/accounts/view';
import AccountsEdit from '../components/accounts/edit';
import AccountsPostRegister from '../components/accounts/post-register';
import AccountsCreatePassword from '../components/accounts/create-password';
import AccountsChangePassword from '../components/accounts/change-password';
import CustomerIndex from '../components/customer/index';

import CustomerDashboard from '../components/customer/dashboard';
import CustomerOrderIndex from '../components/customer/order/index';
import CustomerOrderOrderDetails from '../components/customer/order/order-details';
import CustomerOrdersViewPaymentsIndex from '../components/customer/order/payments/index';

import CustomerEstimateApprove from '../components/customer/estimate/approve';
import OrdersEstimateEdit from '../components/orders/estimate-edit';
import CustomerCompaniesIndex from '../components/customer/companies/index';
import CustomerCompaniesList from '../components/customer/companies/list';
import CustomerCompaniesCreate from '../components/customer/companies/create';
import CustomerCompaniesView from '../components/customer/companies/view.vue';
import CustomerCompaniesDetails from '../components/customer/companies/tabs/details.vue';
import CustomerCompaniesReports from '../components/customer/companies/tabs/reports';
import CustomerCompaniesEdit from '../components/customer/companies/edit';
import CustomerCompaniesCustomPrices from '../components/customer/companies/custom-prices';
import OrdersViewInvoicesPaymentThankYou from '../components/orders/view/invoices/payment-thank-you';
import OrdersViewInvoicesPaymentSuccess from '../components/orders/view/invoices/payment-success';
import OrdersViewInvoicesPaymentFailure from '../components/orders/view/invoices/payment-failure';
import RevisionNewIndex from '../components/revision-new/index';
import RevisionNewPrint from '../components/revision-new/print';

import ReportsIndex from '../components/reports/tabs/index';
import ReportsDashboard from '../components/reports/tabs/dashboard.vue';
import CsrConversionsReport from '../components/reports/tabs/csr-conversions.vue';
import SalesTaxReport from '../components/reports/tabs/sales-tax-tab';
import DiscountReport from '../components/reports/tabs/discount-tab';
import RushFeeReport from '../components/reports/tabs/rush-fee-tab';
import CompaniesReport from '../components/reports/tabs/companies-report-tab';
import DesignsReport from '../components/reports/tabs/designs-report-tab';
import OrdersReport from '../components/reports/tabs/orders-report-tab';
import EstimatesReport from '../components/reports/tabs/estimates-report-tab';
import ClientsReport from '../components/reports/tabs/clients-report-tab';

import PageError from '../components/error';

// Roles
import {
  admins,
  canCreateEstimate,
  canEditEstimate,
  canSeeCompanyReports,
  canSeeCsrConversionsPage,
  canSeeCustomerDashboard,
  canSeeOrderPayments,
  canSeePaymentStatusPage,
  customers,
  printers,
  teamMembers
} from '../helpers/role-access';

window.accessError = () => {
  notify({
    message: 'Access Denied',
    type: 'danger'
  });
};
Vue.use(Router);

const router = new Router({
  mode: 'hash',
  linkActiveClass: 'is-active',
  scrollBehavior: () => ({ y: 0 }),
  routes: [

    {
      name: 'Auth',
      path: '/auth',
      redirect: '/auth/login',
      component: PageAuth,
      children: [
        {
          name: 'Login',
          path: 'login',
          meta: { auth: false },
          component: PageLogin
        },
        {
          name: 'Logout',
          path: 'logout',
          meta: { auth: true },
          component: PageLogout
        },
        {
          name: 'Forgot',
          path: 'forgot',
          meta: { auth: false, bodyClass: 'row align-middle' },
          component: PageForgot
        },
        {
          name: 'Reset Password',
          path: 'password/reset/:token',
          meta: { auth: false, bodyClass: 'row align-middle' },
          component: PageReset
        }
      ]
    },
    {
      name: 'Dashboard',
      path: '/dashboard',
      redirect: '/dashboard/my-orders',
      components: {
        default: PageDashboard,
        nav: Navigation
      },
      children: [
        {
          name: 'My Estimates',
          path: 'my-estimates',
          meta: {
            auth: true,
            roles: teamMembers
          },
          component: DashboardMyEstimates
        },
        {
          name: 'My Orders',
          path: 'my-orders',
          meta: {
            auth: true,
            roles: [...teamMembers, ...printers]
          },
          component: DashboardMyOrders
        },
        {
          name: 'New Orders View',
          path: 'active-orders',
          meta: {
            auth: true,
            roles: [...teamMembers, ...printers]
          },
          component: DashboardActiveOrders
        },
        {
          name: 'Dashboard - Unclaimed Estimates',
          path: 'unclaimed-estimates',
          meta: {
            auth: true,
            roles: teamMembers
          },
          component: DashboardUnclaimedEstimates
        },
        {
          name: 'Unclaimed Blanks',
          path: 'unclaimed-blanks',
          meta: {
            auth: true,
            roles: teamMembers
          },
          component: DashboardUnclaimedBlanks
        },
        {
          name: 'Unclaimed Mocks',
          path: 'unclaimed-mocks',
          meta: {
            auth: true,
            roles: teamMembers
          },
          component: DashboardUnclaimedMocks
        }
      ]
    },
    {
      path: '/',
      redirect: (to, from) => {
        if (checkRoles(store.getters.roles, customers, true)) {
          return '/customer/dashboard';
        } else if (
          checkRoles(
            store.getters.roles,
            ['developer', 'admin', 'csr', 'production-coordinator'],
            true
          )
        ) {
          return '/dashboard/my-estimates';
        } else if (
          checkRoles(
            store.getters.roles,
            ['purchaser', 'art-producer', 'printer'],
            true
          )
        ) {
          return '/dashboard/my-orders';
        } else {
          return '/auth/login';
        }
      }
    },
    {
      path: '/dashboard/my-estimates',
      redirect: to => {
        if (store.getters.roles.filter(r => r === 'customer').length > 0) {
          return '/customer/dashboard';
        } else {
          return '/dashboard/my-estimates';
        }
      }
    },
    {
      path: '/estimates',
      components: {
        default: EstimatesIndex,
        nav: Navigation
      },
      children: [
        {
          name: 'Estimates List',
          path: '',
          component: EstimatesList,
          meta: {
            auth: true,
            roles: teamMembers
          }
        },
        {
          name: 'Estimate Details',
          path: ':id/details',
          meta: {
            auth: true,
            roles: teamMembers
          },
          component: EstimatesDetails
        },
        {
          name: 'Public Estimate',
          path: 'public/:token/approve',
          component: CustomerEstimateApprovePublic,
          meta: {}
        }
      ]
    },
    {
      path: '/orders',
      components: {
        default: OrdersIndex,
        nav: Navigation
      },
      children: [
        {
          name: 'Orders List',
          path: '',
          component: OrdersOrders,
          meta: {
            auth: true,
            roles: [...teamMembers, ...printers]
          }
        },
        {
          name: 'New order',
          path: 'create',
          component: OrdersCreate2,
          meta: {
            auth: true,
            roles: canCreateEstimate
          }
        },
        {
          name: 'Edit order',
          path: ':id/edit',
          component: OrdersCreate2,
          meta: {
            auth: true,
            roles: canEditEstimate
          }
        },
        {
          name: 'Order Details Section',
          path: ':id',
          redirect: ':id/details',
          component: OrdersViewIndex,
          children: [
            {
              name: 'Order Details',
              path: 'details/:action?/:designId?',
              component: OrdersViewOrderDetails,
              meta: {
                auth: true,
                roles: [...teamMembers, ...printers]
              }
            },
            {
              name: 'Order Files',
              path: 'files',
              component: OrdersViewFiles,
              meta: {
                auth: true,
                roles: [...teamMembers, ...printers]
              }
            },
            {
              name: 'Order Invoices',
              path: 'invoices',
              component: OrdersViewInvoicesIndex,
              meta: {
                auth: true,
                roles: [...teamMembers, ...printers]
              }
            },
            {
              name: 'New Invoice',
              path: 'invoices/new',
              component: OrdersViewInvoicesCreate,
              meta: {
                auth: true,
                roles: [...teamMembers, ...printers]
              }
            },
            {
              name: 'Edit invoice',
              path: 'invoices/:invoiceId/edit',
              component: OrdersViewInvoicesEdit,
              meta: {
                auth: true,
                roles: [...teamMembers, ...printers]
              }
            },
            {
              name: 'Order Payments',
              path: 'payments',
              component: OrdersViewPaymentsIndex,
              meta: {
                auth: true,
                roles: canSeeOrderPayments
              }
            }
          ]
        }
      ]
    },
    {
      path: '/accounts',
      components: {
        default: AccountsIndex,
        nav: Navigation
      },
      children: [
        {
          name: 'Accounts List',
          path: '',
          component: AccountsAccounts,
          meta: {
            auth: true,
            roles: teamMembers
          }
        },
        {
          name: 'New Account',
          path: 'new',
          component: AccountsCreate,
          meta: {
            auth: true,
            roles: teamMembers
          }
        },
        {
          name: 'Account Details',
          path: ':userId/view',
          component: AccountsView,
          meta: {
            auth: true,
            roles: [...teamMembers, ...customers, ...printers]
          }
        },
        {
          name: 'Edit Account',
          path: ':id/edit',
          component: AccountsEdit,
          meta: {
            auth: true,
            roles: [...teamMembers, ...customers, ...printers]
          }
        },
        {
          name: 'Post Registration',
          path: 'post-register/:verificationToken',
          component: AccountsPostRegister,
          meta: { auth: false }
        },
        {
          name: 'Create Password',
          path: ':userId/create-password',
          component: AccountsCreatePassword,
          meta: {
            auth: true,
            roles: [...teamMembers, ...customers, ...printers]
          }
        },
        {
          name: 'Change Password',
          path: ':userId/change-password',
          component: AccountsChangePassword,
          meta: {
            auth: true,
            roles: [...teamMembers, ...customers, ...printers]
          }
        }
      ]
    },
    {
      name: 'Invoice Payment Page',
      path: '/customer/orders/:id/pay/:hash',
      component: PagePayment,
      meta: {
        auth: true,
        roles: customers
      }
    },
    {
      path: '/customer',
      redirect: '/customer/dashboard',
      components: {
        default: CustomerIndex,
        nav: Navigation
      },
      children: [
        {
          name: 'Customer Dashboard',
          path: 'dashboard',
          component: CustomerDashboard,
          meta: { auth: true, roles: canSeeCustomerDashboard }
        },
        {
          name: 'Customer Order View',
          path: 'orders/:id',
          component: CustomerOrderIndex,
          meta: {
            auth: true,
            roles: [...teamMembers, ...printers, ...customers]
          },
          children: [
            {
              name: 'Customer Order Details',
              path: 'details',
              component: CustomerOrderOrderDetails,
              meta: {
                auth: true,
                roles: [...teamMembers, ...printers, ...customers]
              }
            },
            {
              name: 'Customer Order Payments',
              path: 'payments',
              component: CustomerOrdersViewPaymentsIndex,
              meta: {
                auth: true,
                roles: [...teamMembers, ...printers, ...customers]
              }
            },
            {
              name: 'Customer Order Invoices',
              path: 'invoice/:hash/:print?',
              component: PageInvoiceDetails,
              meta: {
                auth: true,
                roles: [...teamMembers, ...printers, ...customers]
              }
            }
          ]
        },
        {
          name: 'Approve estimate',
          path: 'estimates/:id/approve',
          component: CustomerEstimateApprove,
          meta: {
            auth: true,
            roles: [...teamMembers, ...customers]
          }
        },
        {
          name: 'Confirm order',
          path: 'orders/:id/confirm',
          component: OrdersEstimateEdit,
          meta: {
            auth: true,
            roles: [...teamMembers, ...customers]
          }
        }
      ]
    },
    {
      path: '/companies',
      components: {
        default: CustomerCompaniesIndex,
        nav: Navigation
      },
      children: [
        {
          name: 'Companies List',
          path: '',
          component: CustomerCompaniesList,
          meta: {
            auth: true,
            roles: teamMembers
          }
        },
        {
          name: 'New Company',
          path: 'new',
          component: CustomerCompaniesCreate,
          meta: {
            auth: true,
            roles: teamMembers
          }
        },
        {
          path: ':id',
          redirect: 'details',
          components: {
            default: CustomerCompaniesView,
            nav: Navigation
          },
          children: [
            {
              name: 'Company Details',
              path: 'details',
              component: CustomerCompaniesDetails,
              meta: {
                auth: true,
                roles:  [...teamMembers, ...printers, ...customers]
              }
            },
            {
              name: 'Company Reports',
              path: 'reports',
              component: CustomerCompaniesReports,
              meta: {
                auth: true,
                roles: canSeeCompanyReports
              }
            }
          ]
        },
        {
          name: 'Edit Company',
          path: ':id/edit',
          component: CustomerCompaniesEdit,
          meta: {
            auth: true,
            roles: teamMembers
          }
        },
        {
          name: 'company Custom Prices',
          path: ':companyId/custom-prices',
          component: CustomerCompaniesCustomPrices,
          meta: {
            auth: true,
            roles: teamMembers
          }
        }
      ]
    },
    {
      name: 'Invoice',
      path: '/invoice',
      meta: {
        auth: true,
        roles: customers
      },
      components: {
        default: CustomerIndex,
        nav: Navigation
      },
      children: [
        {
          name: 'Invoice payment thank you',
          path: '/invoices/payment/thank-you',
          meta: {
            auth: true,
            roles: customers
          },
          component: OrdersViewInvoicesPaymentThankYou
        },
        {
          name: 'Invoice details',
          path: '/invoices/:hash/:print?',
          meta: {
            auth: true,
            roles: [...teamMembers, ...customers]
          },
          component: PageInvoiceDetails
        },
        {
          name: 'Invoice payment success',
          path: '/invoices/payment/success/:transactionId',
          meta: {
            auth: true,
            roles: canSeePaymentStatusPage
          },
          component: OrdersViewInvoicesPaymentSuccess
        },
        {
          name: 'Invoice payment failure',
          path: '/invoices/payment/failure/:transactionId',
          meta: {
            auth: true,
            roles: canSeePaymentStatusPage
          },
          component: OrdersViewInvoicesPaymentFailure
        }
      ]
    },
    {
      name: 'Revision',
      path: '/revision/:id/:designId?/:mockId?',
      meta: {
        auth: true,
        roles: [...teamMembers, ...printers, ...customers]
      },
      component: RevisionNewIndex
    },
    {
      name: 'Revision Print',
      path: '/revision/:id/:designId/:mockId/:print',
      meta: {
        auth: true,
        roles: [...teamMembers, ...printers]
      },
      component: RevisionNewPrint
    },
    {
      path: '/notifications',
      components: {
        default: NotificationsIndex,
        nav: Navigation
      },
      children: [
        {
          name: 'Notifications List',
          path: '',
          component: NotificationsList,
          meta: {
            auth: true,
            roles: [...teamMembers, ...printers, ...customers]
          }
        }
      ]
    },
    {
      path: '/reports',
      redirect: '/reports/sales-tax',
      components: {
        default: ReportsIndex,
        nav: Navigation
      },
      children: [
        {
          name: 'Dashboard Report',
          path: 'dashboard',
          component: ReportsDashboard,
          meta: {
            auth: true,
            roles: admins
          }
        },
        {
          name: 'CSR Conversions',
          path: 'csr-conversions',
          component: CsrConversionsReport,
          meta: {
            auth: true,
            roles: canSeeCsrConversionsPage
          }
        },
        {
          name: 'Sales Tax',
          path: 'sales-tax',
          component: SalesTaxReport,
          meta: {
            auth: true,
            roles: teamMembers
          }
        },
        {
          name: 'Discounts',
          path: 'discounts',
          component: DiscountReport,
          meta: {
            auth: true,
            roles: [...teamMembers, ...printers]
          }
        },
        {
          name: 'Rush Fees',
          path: 'rush-fees',
          component: RushFeeReport,
          meta: {
            auth: true,
            roles: [...teamMembers, ...printers]
          }
        },
        {
          name: 'Companies Report',
          path: 'companies',
          component: CompaniesReport,
          meta: {
            auth: true,
            roles: teamMembers
          }
        },
        {
          name: 'Estimates Report',
          path: 'estimates',
          meta: {
            auth: true,
            roles: teamMembers
          },
          component: EstimatesReport
        },
        {
          name: 'Designs Report',
          path: 'designs',
          component: DesignsReport,
          meta: {
            auth: true,
            roles: admins
          }
        },
        {
          name: 'Clients Report',
          path: 'clients',
          component: ClientsReport,
          meta: {
            auth: true,
            roles: admins
          }
        },
        {
          name: 'Orders Report',
          path: 'production-and-purchasing',
          component: OrdersReport,
          meta: {
            auth: true,
            roles: teamMembers
          }
        }
      ]
    },
    {
      name: 'Error',
      path: '*',
      components: {
        default: PageError,
        nav: Navigation
      }
    }
  ]
});

/**
 * Set route body class
 * @param matched
 * @private
 */
const _setBodyClass = matched => {
  if (matched.some(record => typeof record.meta.bodyClass === 'string')) {
    matched.forEach(record => {
      if (typeof record.meta.bodyClass === 'string') {
        document.body.className += ' ' + record.meta.bodyClass;
      }
    });
  } else {
    document.body.className = '';
  }
};

/**
 * Protecting routes
 */
router.beforeEach((to, from, next) => {
  let token = localStorage.getItem('jwt-token');
  let stop = false;
  // set bodyClass
  _setBodyClass(to.matched);
  switch (to.meta.auth) {
    case true:
      // Protect by token
      if ((!token) && to.path !== '/auth/login') {
        return next({
          path: '/auth/login',
          query: {
            redirect: to.fullPath
          }
        });
      } else {
        if (store.getters.roles && to.meta.roles) {
          if (!checkRoles(store.getters.roles, to.meta.roles)) {
            window.accessError();
            let redirect = from.path;
            if (
              redirect === '/' &&
              checkRoles(store.getters.roles, ['customer'])
            ) {
              redirect = '/accounts/' + store.getters.userProfile.id + '/view';
            }
            return next(from.path);
          }
        }
      }
      break;
    // This routes are not allowed for logged in (log in, register, etc)
    case false:
      if (token) {
        return next('/');
      }
      break;
    // This routes are not allowed for logged in (log in, register, etc)
    case null:
      break;
  }

  if (!stop) {
    return next();
  }
});

export default router;
