<template>
  <div class="revision-page">
    <loader-screen
      :is-loading="isLoading"
      :background-color="'#151414'"
      class="is-hidden-print"
    >
    </loader-screen>
    <section class="print-version" v-if="isReadyToPrint">
      <div class="design-page">
          <div class="content">
            <div class="images">
              <div class="imagebox" v-for="(attachment, index) in attachments">
                  <div class="col-l">
                    <article
                      :class="isEvent ? 'danger-info-box info-box ship-info' : 'info-box ship-info'"
                      v-if="attachment.filepath === attachments[attachments.length - attachments.length].filepath">
                      <h1 class="info-box-head">SHIP TO / EVENT</h1>
                      <div class="info-box-content">
                        <ship-to
                          :order="order"
                          :design="design"
                        >
                        </ship-to>
                      </div>
                      <div class="info-box-bottom">SHIP BY DATE: {{ shipBy }}</div>
                      <!-- <div class="info-box-bottom">EVENT: {{ isEvent ? 'Yes' : 'No' }}</div> -->
                    </article>
                    <article
                      class="info-box"
                      v-if="attachment.filepath === attachments[attachments.length - attachments.length].filepath"
                    >
                      <h1 class="info-box-head">DETAILS</h1>
                      <component
                        v-if="design && design.service"
                        ref="designInfo"
                        :is="designInfoType"
                        :order="order"
                        :design="design"
                        :mock="mock"
                        :editable="false"
                        :options="designOptions">
                      </component>
                      <DesignComment
                        ref="designComment"
                        :editable="false"
                        :design="design"
                        v-if="design.comment"
                      />
                      <Finishings
                        v-if="designFinishings.length"
                        ref="finishings"
                        :editable="false"
                        :design="design"
                        :options="designOptions"
                        :mock="mock"
                      />
                      <MockComment
                        ref="mockComment"
                        :editable="false"
                        :mock="mock"
                        v-if="mock.details"
                      />
                      <MockInternalComment
                        v-role-access="['admin', 'csr', 'production-coordinator', 'purchaser', 'art-producer','developer','printer']"
                        ref="mockInternalComment"
                        :editable="false"
                        :mock="mock"
                        v-if="mock.internal_comment"/>
                      <OrderComment
                        ref="orderComment"
                        :editable="false"
                        :order="order"
                        v-if="order.comment"
                      />
                    </article>
                  </div>
                <div class="col-main">
                  <header class="page-header" v-if="(attachment.filepath === attachments[attachments.length - attachments.length].filepath)">
                    <h1 class="title is-4" v-html="title"></h1>
                  </header>
                  <img
                    class="img"
                    :key="attachment.filepath"
                    :src="s3Domain + attachment.filepath"/>
                  <div class="warnings-tags"
                       v-if="(attachment.filepath === attachments[attachments.length - 1].filepath) && mock && mock.warnings && mock.warnings.length">
                    <strong>WARNINGS</strong>
                    <div class="tags">
                      <span class="tag" v-for="warning in mock.warnings">{{warning.title}}</span>
                    </div>
                  </div>
                  <div class="main-details">
                      <div class="finishings-tags"
                           v-if="(attachment.filepath === attachments[attachments.length - 1].filepath) && designFinishings.length">
                        <strong>FINISHINGS</strong>
                        <ul class="tags">
                          <li v-for="finishing in designFinishings">
                            <span class="color-box" style="background-color: rgb(0, 0, 0);"></span>
                            <span>{{( finishing.label === 'Folding & Bagging' ?
                              (finishing.finishable.folding_type === 'I would like my garments folded' ? 'Folding only' : 'Folding & Bagging')
                              : finishing.label )}}
                              <small v-if="finishing.comment"><br>{{finishing.comment}}</small>
                            </span>
                          </li>
                        </ul>
                      </div>
                      <component
                        :is="designGarmentType"
                        v-if="attachment.filepath === attachments[attachments.length -1].filepath"
                        :design="design"
                        :mock="mock"
                        :showDetails="false"
                        :class="designFinishings.length ? 'margin-left-150 design-garments': 'design-garments'"
                        :options="designOptions"
                      ></component>
                  </div>
                </div>
              </div>
            </div>
            <div class="shipping-information" v-if="areShippingsExist && isSplitShipping">
              <header class="page-header">
                <h1 class="title is-4" v-html="title"></h1>
              </header>
              <design-details-shippings
                :order="order"
                :design="design"
                :design-shippings="designShippings"
              ></design-details-shippings>
            </div>
          </div>
        </div>
    </section>
  </div>
</template>


<script>
import apiService from '../../services/api.service';
import { cloneDeep } from 'lodash';
import alertify from 'alertify.js';
import moment from 'moment';
import { isThumbable, fileExt } from '../../helpers/files';

import { mapGetters } from 'vuex';

import LoaderScreen from '../common/loader-screen';
import DesignInfoApparel from './print_widgets/design-info-types/design-info-apparel';
import DesignInfoButton from './print_widgets/design-info-types/design-info-button';
import DesignInfoDigital from './print_widgets/design-info-types/design-info-digital';
import DesignInfoOther from './print_widgets/design-info-types/design-info-other';
import DesignInfoPoster from './print_widgets/design-info-types/design-info-poster';
import DesignInfoSticker from './print_widgets/design-info-types/design-info-sticker';
import DesignInfoPromotional from './print_widgets/design-info-types/design-info-promotional';
import GarmentApparelDetails from './print_widgets/garments-info-types/garment-apparel-details.vue';
import GarmentPosterDetails from './print_widgets/garments-info-types/garment-poster-details.vue';
import GarmentButtonDetails from './print_widgets/garments-info-types/garment-button-details.vue';
import GarmentStickerDetails from './print_widgets/garments-info-types/garment-sticker-details.vue';
import GarmentDigitalDetails from './print_widgets/garments-info-types/garment-digital-details.vue';
import GarmentOtherDetails from './print_widgets/garments-info-types/garment-other-details.vue';
import Finishings from './widgets/finishings';
import MockComment from './widgets/mock-comment.vue';
import MockInternalComment from './widgets/mock-internal-comment';
import DesignComment from './widgets/design-comment.vue';
import OrderComment from './widgets/order-comment.vue';
import DesignDetailsShippings from './design-details-shippings';
import ShipTo from './widgets/ship-to';

const DATE_FORMAT = 'MM/DD/YY';

export default {
  name: 'revision-print',
  components: {
    ShipTo,
    DesignDetailsShippings,
    LoaderScreen,
    GarmentApparelDetails,
    GarmentPosterDetails,
    GarmentButtonDetails,
    GarmentStickerDetails,
    GarmentDigitalDetails,
    GarmentOtherDetails,
    Finishings,
    MockComment,
    MockInternalComment,
    DesignComment,
    OrderComment,
    DesignInfoApparel,
    DesignInfoButton,
    DesignInfoDigital,
    DesignInfoOther,
    DesignInfoPoster,
    DesignInfoSticker,
    DesignInfoPromotional
  },
  props: {},
  data() {
    return {
      isLoading: !this.isReadyToPrint,
      order: {},
      design: {},
      mock: {},
      designOptions: {}
    };
  },
  computed: {
    ...mapGetters(['s3Domain']),
    orderId() {
      return this.$route.params.id;
    },
    designId() {
      return this.$route.params.designId;
    },
    mockId() {
      return this.$route.params.mockId;
    },
    isReadyToPrint() {
      return !!(this.order && this.design && this.mock && this.designOptions);
    },
    attachments() {
      return (
        (this.mock &&
          this.mock.attachments &&
          this.mock.attachments.filter(a => isThumbable(a.filepath))) ||
        []
      );
    },
    isEvent() {
      return !!(this.design && this.design.in_hands_date);
    },
    shipBy() {
      let date = ' - ';
      if (this.design && this.design.ship_by_date) {
        date = moment(this.design.ship_by_date).format(DATE_FORMAT);
      }
      return date;
    },
    designGarmentType() {
      const defServices = ['poster', 'button', 'sticker', 'digital', 'other'];
      const def = 'apparel';
      if (defServices.includes(this.design.service)) {
        return `garment-${this.design.service}-details`;
      }
      return `garment-${def}-details`;
    },
    designInfoType() {
      // Embroidery uses the same models/components as apparel,
      // with some different fields.
      return (
        'design-info-' +
        (this.design.service === 'embroidery' || this.design.service === 'blank'
          ? 'apparel'
          : this.design.service)
      );
    },
    designFinishings() {
      return (
        (this.design &&
          this.design.designable &&
          this.design.designable.finishings &&
          this.design.designable.finishings.length &&
          this.design.designable.finishings) ||
        []
      );
    },
    title() {
      let orderId = this.order && this.order.id;
      let designName = this.design && this.design.name;
      let accountName =
        (this.order &&
          this.order.user &&
          ((this.order.user.company && this.order.user.company.name) ||
            (this.order.user.first_name &&
              this.order.user.last_name &&
              `${this.order.user.first_name} ${this.order.user.last_name}`) ||
            (this.order.user.first_name && `${this.order.user.first_name}`) ||
            (this.order.user.last_name && `${this.order.user.last_name}`) ||
            this.order.user.title)) ||
        '';
      let mockVersion =
        (this.mock.version && `Version ${this.mock.version}`) || '';
      return `<strong class="order-id">${orderId} ${accountName}</strong> "${designName}" ${mockVersion}`;
    },
    shippings() {
      return this.order.shippings || [];
    },
    areShippingsExist() {
      return this.shippings.length >= 1;
    },
    isSplitShipping() {
      return this.designShippings.length > 1;
    },
    designShippings() {
      return (
        (this.areShippingsExist &&
          this.shippings.filter(
            s =>
              (s.shipping_items &&
                s.shipping_items.length &&
                s.shipping_items.find(
                  si => this.design && +si.design_id === +this.design.id
                )) ||
              false
          )) ||
        []
      );
    }
  },
  methods: {
    loadData() {
      return this.loadOrder()
        .then(order => {
          this.$set(this, 'order', order);
          return this.getDesignFromOrder(order)
            .then(design => {
              if (
                ['apparel', 'embroidery'].includes(design.service) &&
                order.finishings
              ) {
                design.designable.finishings = [];
                order.finishings
                  .filter(finishing => {
                    return finishing.items.find(
                      ({ design_id }) => design_id === design.id
                    );
                  })
                  .forEach(finishing =>
                    design.designable.finishings.push(cloneDeep(finishing))
                  );
              }
              this.$set(this, 'design', design);
              return this.loadDesignOptions(design.service)
                .then(options => {
                  this.$set(this, 'designOptions', options);
                  return this.getMockFromDesign(design)
                    .then(mock => {
                      this.$set(this, 'mock', mock);
                    })
                    .catch(err => {
                      alertify.error(
                        (err && err.body && err.body.message) ||
                          "Can't load data."
                      );
                    });
                })
                .catch(err => {
                  alertify.error(
                    (err && err.body && err.body.message) || "Can't load data."
                  );
                });
            })
            .catch(err => {
              alertify.error(
                (err && err.body && err.body.message) || "Can't load data."
              );
            });
        })
        .catch(err => {
          alertify.error(
            (err && err.body && err.body.message) || "Can't load data."
          );
        });
    },
    loadOrder() {
      return apiService
        .getRevisionOrder(this.orderId)
        .then(data => {
          if (data.order && data.order.id) {
            return Promise.resolve(data.order);
          } else {
            return Promise.reject(`Order ${this.orderId} not found.`);
          }
        })
        .catch(err => {
          return Promise.reject(err.body.message);
        });
    },
    getDesignFromOrder(order) {
      return new Promise((resolve, reject) => {
        let design =
          (order &&
            order.designs &&
            order.designs.length &&
            order.designs.find(d => +d.id === +this.designId)) ||
          {};
        if (design && design.id) {
          resolve(design);
        } else {
          // Design not found.
          reject({
            body: {
              message: `Design ${this.designId} not found`
            }
          });
        }
      });
    },
    getMockFromDesign(design) {
      return new Promise((resolve, reject) => {
        let mock =
          (design &&
            design.mocks &&
            design.mocks.length &&
            design.mocks.find(m => +m.id === +this.mockId)) ||
          {};
        if (mock && mock.id) {
          resolve(mock);
        } else {
          reject({
            body: {
              message: `Mock ${this.mockId} not found`
            }
          });
        }
      });
    },
    loadDesignOptions(design_type) {
      return apiService.getDesignFormOptions(design_type).then(data => {
        return (data && data.form_data) || {};
      });
    }
  },
  mounted() {
    this.loadData()
      .then(() => {
        this.$set(this, 'isLoading', false);
        setTimeout(() => {
          window.print();
          window.close();
        }, 1000);
      })
      .catch(err => {
        this.$set(this, 'isLoading', false);
      });
  }
};
</script>
<style lang="scss">
.revision-page {
  .loading-message {
    color: #0c9ad6;
    font-weight: 500;
    font-size: 20px;
    margin-bottom: 100px;
  }

  .sk-circle:before {
    background-color: #0c9ad6 !important;
  }

  .pg-loading-screen {
    opacity: 0.97;
  }

  .pg-loading-html {
    margin-left: 30px;
  }

  img.pg-loading-logo {
    width: auto !important;
    height: auto !important;
  }

  @media print {
    @page {
      size: landscape;
      width: 297mm;
    }
    body {
      width: 297mm;
    }

    .control {
      margin-bottom: 0px !important;
    }
    .print-version .design-page .info-box {
      font-size: 12px !important;
      line-height: 16px !important;
    }
    .ship-info .info-box-content {
      font-size: 12px !important;
      line-height: 16px !important;
      padding: 10px !important;
    }
    .ship-info .info-box-content p {
      margin: 0 !important;
    }
    .tag.is-medium {
      font-size: 12px !important;
      height: 20px !important;
      padding-left: 0px !important;
      padding-right: 8px !important;
      background-color: unset !important;
    }
    .tag.is-medium .color-box {
      display: inline-block !important;
      width: 15px !important;
      height: 15px !important;
      margin: 0 5px 0 0px !important;
      border-radius: 10px !important;
      border: solid 1px !important;
    }
    .widget,
    .widget.has-separator:last-child {
      margin-bottom: 0px !important;
      margin-top: 0px !important;
      page-break-inside: avoid;
      /* border: 1px solid #d1d2d4 !important; */
      min-height: 30px !important;
      padding: 2px 3px 0px 3px !important;
    }

    .content {
      font-size: 12px !important;
      line-height: 16px !important;
    }

    img {
      width: auto !important;
      height: auto !important;
      max-width: 80% !important;
      max-height: 80% !important;
      page-break-after: always !important;
      margin-left: 75px !important;
    }
    .print-version .design-page .col-main .content .images {
      margin: 0 0 !important;
      text-align: center !important;
    }
    .design-garments {
    }
    .design-garments h2 {
      display: none !important;
    }
    .design-garments .table .td {
      font-size: 12px !important;
      line-height: 16px !important;
    }
    .print-version .page-header .title {
      padding: 10px 15px !important;
      margin: 0 !important;
      background: #00aeef !important;
      -webkit-print-color-adjust: exact !important;
      color: #fff !important;
      font-weight: 500 !important;
      line-height: 35px !important;
    }
    .warnings-tags {
      text-align: left !important;
      border-bottom: 1px gray solid !important;
      padding-bottom: 12px !important;
    }
    .warnings-tags .tags {
      display: inline-block;
      vertical-align: baseline;
      /* margin-right: 12px; */
      border-bottom: 1px saddlebrown;
    }

    .warnings-tags .tags .tag {
      -webkit-box-align: center;
      align-items: center;
      background-color: white;
      border-radius: 5px;
      color: #4a4a4a;
      display: -webkit-inline-box;
      display: inline-flex;
      font-size: 14px;
      height: 30px;
      -webkit-box-pack: center;
      justify-content: center;
      line-height: 20px;
      padding-left: 5px;
      padding-right: 5px;
      vertical-align: baseline;
      white-space: nowrap;
      border: 1px solid;
      margin-right: 10px;
    }
    .info-box-content {
      page-break-inside: avoid !important;
    }
    .print-version .design-page .info-box .info-box-head {
      margin-bottom: 0 !important;
    }
    .finishings-tags {
      padding: 0;
      width: 150px;
      margin: 0;
      float: left;
      line-height: 15px;
    }
    .finishings-tags ul {
      list-style: none;
      /* list-style: disc outside; */
      margin-left: 0;
      margin-right: 0;
      margin-top: 0;
      padding-top: 0;
    }
    .finishings-tags li {
      margin-top: 5px;
      margin-bottom: 5px;
    }
    .finishings-tags li span.color-box {
      vertical-align: middle;
    }
    .title strong {
      font-weight: 700 !important;
    }
    .table {
      margin-bottom: 0;
      line-height: 20px;
    }
    .table td,
    .table th {
      padding: 5px 4px;
    }
    table thead {
      display: table-row-group;
    }
    table thead th {
      border: none !important;
    }
    table thead tr {
      border: 1px solid #dbdbdb !important;
      border-width: 0 0 2px !important;
      height: 20px;
      line-height: 15px;
    }
    table tbody tr {
      /* line-height: 35px; */
      border: 1px solid #dbdbdb !important;
      border-width: 0 0 2px !important;
    }
    tr.with-gray-back {
      background: #dbdbdb;
    }
    .main-details {
      width: 100%;
      page-break-inside: avoid;
      display: table;
    }
    .margin-left-150 {
      margin-left: 150px;
    }
    .imagebox {
      page-break-after: always;
    }
  }
}
</style>
