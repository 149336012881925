<template>
  <div class="dropdown" :class="{
    [`dropdown-${direction}`]: true
  }">
    <div class="dropdown__opener" v-if="$slots.opener" @click="toggle">
      <slot name="opener" class="slot-opener"></slot>
    </div>
    <button class="btn2 is-secondary is-square dropdown__opener" @click="toggle" v-else>
      <svg width="4px" height="16px" viewBox="0 0 4 16" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <path d="M2,4 C3.1,4 4,3.1 4,2 C4,0.9 3.1,0 2,0 C0.9,0 0,0.9 0,2 C0,3.1 0.9,4 2,4 Z M2,6 C0.9,6 0,6.9 0,8 C0,9.1 0.9,10 2,10 C3.1,10 4,9.1 4,8 C4,6.9 3.1,6 2,6 Z M2,12 C0.9,12 0,12.9 0,14 C0,15.1 0.9,16 2,16 C3.1,16 4,15.1 4,14 C4,12.9 3.1,12 2,12 Z" id="path-1"></path>
      </svg>
    </button>
    <div
      class="dropdown__list"
      :class="{ 'is-open': isOpen }"
      v-click-outside="clickOutsideConfig"
    >
      <template
        v-for="(item, index) in items"
        v-if="!item.hidden"
      >
        <hr v-if="item === 'divider'">
        <p
          @click="triggerAction(item)"
          :class="{ 'is-disabled': item.disabled }"
          :style="item.style"
          v-else
        >{{ item.title }}</p>
      </template>
    </div>
  </div>
</template>
<script>
import Vue from 'vue';
import VClickOutside from 'v-click-outside';

Vue.use(VClickOutside);

export default {
  name: 'dropdown',
  props: {
    items: {
      type: Array,
      required: true
    },
    direction: {
      type: String,
      default: 'bottom'
    }
  },
  data() {
    return {
      isOpen: false,
      clickOutsideConfig: {
        handler: () => {
          this.toggle(false);
        },
        middleware: this.checkToggle
      }
    };
  },
  methods: {
    toggle(value) {
      this.isOpen = typeof value === 'boolean' ? value : !this.isOpen;
    },
    triggerAction(item) {
      if (item.disabled) return;
      item.action();
      this.isOpen = false;
    },
    checkToggle(event, el) {
      if (el.previousElementSibling === event.target) return false;
      if (el.previousElementSibling === event.target.parentNode) return false;
      return true;
    }
  }
};
</script>
<style lang="scss">
.dropdown {
  display: inline-block;
  vertical-align: middle;
  position: relative;

  &__opener {
    position: relative;
    & > svg {
      pointer-events: none;
    }
    & > * {
      // pointer-events: none;
    }
  }
  &.dropdown-above &__list {
    top: auto;
    bottom: 100%;
  }
  &__list {
    position: absolute;
    display: none;
    top: 100%;
    right: 0;
    width: 157px;
    padding: 5px 0;
    background: #fff;
    border: 1px solid #dbdbdb;
    border-radius: 2px;
    z-index: 2;
    &.is-open {
      display: block;
    }
    hr {
      margin: 5px 0;
    }
    p {
      cursor: pointer;
      padding: 10px;
      &.is-disabled {
        pointer-events: none;
        opacity: 0.5;
      }
      &:hover {
        background: rgba(#2b99ee, 0.2);
      }
    }
  }
}
</style>
