export const fileExt = path => {
  if (path) {
    let parts = path.split('.');
    return parts[parts.length - 1];
  }
  return '';
};

export const isThumbable = path => {
  let fileExtention = fileExt(path);
  return (
    path &&
    fileExtention &&
    fileExtention.toLowerCase().match(/(png|jpg|jpeg|gif|BMP|webp)/)
  );
};
