<template>
  <tr>
    <td>
      <invoice-field
        label="Category"
        :value="getOptionLabel(item.print_type, formOptions.print_types)"
      />
    </td>
    <td>
      <invoice-field
        :label="sizeLabel"
        :value="sizeValue"
      />
    </td>
    <td>
      <invoice-field
        v-if="item.paper_type"
        :label="paperTypeLabel"
        :value="getOptionLabel(item.paper_type, formOptions.print_type_options[item.print_type].paper_types)"
      />
    </td>
    <td v-if="item.border">
      <invoice-field
        label="Borders"
        :value="getOptionLabel(item.border, formOptions.print_type_options[item.print_type].borders) "
      />
    </td>
    <td v-if="getOptionLabel(item.print_type, formOptions.print_types)==='Hang Tags'">
      <invoice-field
        label="Rounded Corners"
        :value="item.has_rounded_corners ? 'Yes' : 'No'"
      />
    </td>
    <td v-if="getOptionLabel(item.print_type, formOptions.print_types)==='Patches' &&  getOptionLabel(item.product_type, formOptions.print_type_options[item.print_type].product_types) !== 'Faux Leather Patch'">
      <invoice-field
        label="Extra"
        :value="extraOptions"
      />
    </td>
  </tr>
</template>
<script>
import {getOptionLabel} from '@/helpers/estimates';
import InvoiceField from '@/components/common/invoice-field.vue';

export default {
  name: 'FinishingCreationInvoiceItemOptions',
  components: {InvoiceField},
  props: {
    item: {
      type: Object,
      required: true
    },
    formOptions: {
      type: Object,
      required: true
    }
  },
  methods: {
    getOptionLabel
  },
  computed: {
    paperTypeLabel() {
      let label = 'Folding Type';
      if (getOptionLabel(this.item.print_type, this.formOptions.print_types) === 'Hang Tags') {
        label = 'Paper Type';
      } else if (getOptionLabel(this.item.print_type, this.formOptions.print_types) === 'Patches') {
        label = 'Backing';
      }

      return label;
    },
    sizeLabel() {
      if (this.item.custom_width && this.item.custom_height) {
        return 'Size (Custom)';
      }
      return 'Size';
    },
    sizeValue() {
      if (this.item.custom_width && this.item.custom_height) {
        return `${this.item.custom_width} x ${this.item.custom_height}`;
      } else {
        return getOptionLabel(this.item.size, this.formOptions.print_type_options[this.item.print_type].sizes);
      }
    },
    extraOptions(){
      let addons = [];
      if(getOptionLabel(this.item.print_type, this.formOptions.print_types)==='Patches' &&  getOptionLabel(this.item.product_type, this.formOptions.print_type_options[this.item.print_type].product_types) !== 'Faux Leather Patch'){
        if(this.item.button_loops){
          addons.push('Button Loops');
        }
        if(this.item.extra_colors){
          addons.push('Extra Colors 9+');
        }
      }

      return addons.join(', ');
    }
  }
};
</script>
