<template>
  <tr
    class="is-garment"
    :class="{ 'is-first-garment': sizeGroupIndex === 0 }"
  >
    <td colspan="3">
      <invoice-field
        :label="sizeGroupIndex === 0 ? getGarmentLabel(garment) : undefined"
        :value="sortedGarmentsSizes.filter(size => sizeGroup.sizes[size]).map(size => `${size}-${sizeGroup.sizes[size]}`).join(', ')"
      />
    </td>
    <td>
      <invoice-field
        :label="sizeGroupIndex === 0 ? 'Qty' : undefined"
        :value="sizeGroup.qty"
      />
    </td>
    <td>
      <invoice-field
        :label="sizeGroupIndex === 0 ? 'Price' : undefined"
        :value="sizeGroup.cost | price"
      />
    </td>
  </tr>
</template>
<script>
import InvoiceField from '@/components/common/invoice-field.vue';

export default {
  name: 'ApparelGarment',
  components: {InvoiceField},
  props: {
    garment: {
      type: Object,
      required: true
    },
    garmentIndex: {
      type: Number,
      required: true
    },
    sizeGroup: {
      type: Object,
      required: true
    },
    sizeGroupIndex: {
      type: Number,
      required: true
    },
    sortedGarmentsSizes:{
      type: Array,
      required: true
    }
  },
  methods:{
    getGarmentLabel(garment) {
      return `${garment.model.apparel_brand.name} ${garment.model.number} - ${
        garment.model.name
      } ${garment.color}`;
    },
  }
};
</script>
