<template>
    <div class="page-content container section">
        <section id="companies">
            <router-view></router-view>
        </section>
    </div>
</template>

<script>
    import Vue from 'vue';

    export default {
        name: 'customer-company-index',

                data () {
            return {
            }
        },
        computed: {
        },
        components: {
        },
        mounted () {
        },
        methods: {

        }
    };
</script>