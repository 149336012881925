<template>
  <div>
    <p>Please enter a name</p>
    <form-item
      v-model="name"
      name="name"
      :rules="'required|max:32'"
      :required="true"
      :hideLabel="true"
      :fakeLabel="true"
      scope="order-name-popup"
    ></form-item>
  </div>
</template>

<script>
import Vue from 'vue';
import bus from '@/bus';
import FormItem from '@/components/common/form/FormItem';

import apiService from '../../../services/api.service';
import alertify from 'alertify.js';

import { mapGetters, mapActions } from 'vuex';
import { CLEAR_VALIDATION_PROMISES } from '@/store/action-types';

export default {
  name: 'order-name-popup',
  props: ['additional'],
  components: {
    FormItem
  },
  data() {
    return {
      name: this.additional && this.additional.orderName
    };
  },
  created() {
    this.initBusEvents();
  },
  methods: {
    ...mapActions({
      clearValidations: CLEAR_VALIDATION_PROMISES
    }),
    initBusEvents() {
      bus.$on('errors-changed', errors => {
        if (errors) {
          errors.forEach(e => {
            this.errors.add(e.field, e.msg, e.rule, e.scope);
          });
        }
      });

      bus.$on('errors-deleted', oldErrors => {
        this.errors.errors = [];
        if (oldErrors) {
          oldErrors.forEach(e => {
            this.errors.remove(e.field, e.scope);
          });
        }
      });
    },
    validateBeforeSubmit() {
      bus.$emit('clear');
      this.errors.errors = [];
      bus.$emit('validate', `order-name-popup`);

      return new Promise((resolve, reject) => {
        Vue.nextTick(() => {
          Promise.all(this.$store.state.common.validations).then(() => {
            this.clearValidations();
            resolve(!this.errors.count());
          });
        });
      });
    },
    submitForm() {
      return new Promise(resolve => {
        this.validateBeforeSubmit().then(isValid => {
          if (isValid) {
            let data = {
              id: this.additional && this.additional.orderId,
              data: {
                name: this.name
              }
            };
            apiService
              .updateOrder(data)
              .then(data => {
                alertify.log(data.message);
                this.additional.callback(this.additional.orderId, this.name);
                this.$nextTick(() => {
                  this.$emit('close');
                });
              })
              .catch(error => {
                alertify.error(error.body.message);
              });
          } else {
            alertify.log('Please, fix all validation errors to proceed.');
          }
        });
      });
    }
  }
};
</script>
