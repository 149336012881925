<template>
  <div>
    <!-- Main container -->
    <nav class="level">
      <!-- Left side -->
      <div class="level-left">
        <div class="level-item">
          <p class="subtitle is-5">Search accounts</p>
        </div>
        <div class="level-item">
          <div class="field">
            <p class="control">
              <input class="input" type="text" v-model="searchQuery" v-focus="true">
            </p>
          </div>
        </div>
        <div class="level-item">
          <div class="field is-horizontal">
            <div class="field-label is-normal">
              <label class="label">Role</label>
            </div>
            <div class="field-body">
              <div class="field is-narrow">
                <div class="control">
                  <div class="select is-fullwidth">
                    <form-select
                      v-model="searchRole"
                      name="Role"
                      :options="{all: roles}"
                      :required="false"
                      rules=""
                      :horizontal="true"
                      :hide-label="true"
                      @change="getAccountsData"/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Right side -->
      <div class="level-right">

        <p class="level-item">
          <router-link
            v-roles="['admin', 'csr', 'production-coordinator', 'purchaser', 'art-producer']"
            class="button" to="/accounts/new">
            Create New Account
          </router-link>
        </p>
      </div>
    </nav>
    <div  class="table-container">
      <table :class="{'is-skeleton' :isLoading}" class="table is-fullwidth customers-table" v-if="accountsList.length">
        <thead>
        <tr>
          <th v-for="head in headers"
              @click="orderByHead(head)"
              :class="{'is-active': head == sortKey}"
              class="is-clickable">
            {{ head }}
            <span v-if="head == sortKey" class="icon is-small">
              <i :class="sortDir == 'asc' ? 'ff-triangle-up' : 'ff-triangle-down'"></i>
            </span>
          </th>
          <th width="60px"></th>
          <th width="60px"></th>
        </tr>
        </thead>
        <tfoot>
        <tr>
          <th v-for="head in headers"
              @click="orderByHead(head)"
              :class="{'is-active': head == sortKey}">
            {{ head }}
            <span v-if="head == sortKey" class="icon is-small">
              <i :class="sortDir == 'asc' ? 'ff-triangle-up' : 'ff-triangle-down'"></i>
            </span>
          </th>
          <th></th>
          <th></th>
        </tr>
        </tfoot>
        <tbody>
        <tr v-for="(entry, rowKey) in accountsList">
          <th>{{ entry.id }}</th>
          <td>{{ entry.title }}</td>
          <td>{{ entry.first_name + ' ' + entry.last_name }}</td>
          <td><span v-if="entry.company">{{ entry.company }}</span><span
            v-else>-</span></td>
          <td>{{ entry.email }}</td>
          <td>{{ entry.roles[0].display_name }}</td>
          <td>{{ entry.last_login_at ? formatDate(entry.last_login_at) : ' - ' }}</td>
          <td>
            <router-link class="button is-small"
                         :to="'/accounts/' + entry.id + '/view'"
            >View
            </router-link>
          </td>
          <td>
            <router-link class="button is-small"
                         :to="'/accounts/' + entry.id + '/edit'"
            >Edit
            </router-link>
          </td>
        </tr>
        </tbody>
      </table>
      <section class="hero has-text-centered" v-else>
        <div class="hero-body">
          <div class="container"><p class="subtitle" v-if="!isLoading">
            No accounts found
          </p></div>
        </div>
      </section>
      <paginator v-if="accountsList.length"
                 :pagesNumber="pagesNumber"
                 :prevPageUrl="prevPageUrl"
                 :nextPageUrl="nextPageUrl"
                 :currentPage="currentPage"
                 :getListItems="getAccountsData"/>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.table thead th.is-active {
  color: #424242;
}
</style>
<script>
import {mapActions, mapGetters} from 'vuex';
import {focus} from 'vue-focus';

import {LOAD_ROLES} from '../../store/action-types';

import formSelect from '../common/form/select';
import notify from '../../modules/notifier/index';
import {debounce} from 'lodash';
import moment from 'moment';
import paginator from '../common/paginator.vue';
import {teamMembers} from '../../helpers/role-access.js';

export default {
  name: 'accounts',
  directives: {focus},
  data() {
    return {
      isLoading: false,
      headers: [
        'Id',
        'Username',
        'Contact',
        'Company',
        'Email',
        'Role',
        'Last login'
      ],
      accountsList: [],
      prevPageUrl: null,
      nextPageUrl: null,
      currentPage: 1,
      pagesNumber: 1,
      searchQuery: '',
      searchRole: '',
      sortKey: 'Company',
      sortDir: 'asc'
    };
  },
  watch: {
    searchQuery: function(str) {
      if (str.length == 0 || str.length >= 3) {
        this.searchAccounts();
      }
    },
    sortKey: function(key) {
      this.searchAccounts();
    },
    sortDir: function(key) {
      this.searchAccounts();
    }
  },
  computed: {
    ...mapGetters({
      roles: 'systemRoles'
    })
  },
  components: {
    paginator,
    formSelect
  },
  created() {
    this.isLoading = true;

    Promise.all([this.getAccountsData(), this.loadRoles()]).then(() => {
      this.isLoading = false;
    });
  },
  methods: {
    ...mapActions({
      loadRoles: LOAD_ROLES
    }),

    searchAccounts: debounce(function() {
      this.getAccountsData();
    }, 500),

    orderByHead: function(head) {
      if (head !== this.sortKey) {
        this.sortKey = head;
        this.sortDir = 'asc';
      } else {
        this.sortDir = this.sortDir == 'asc' ? 'desc' : 'asc';
      }
    },

    getAccountsData: function(page, url) {
      this.isLoading = true;
      let role = this.searchRole ? this.searchRole : 'all';

      if (page === undefined) page = 1;

      if (url === undefined) {
        url = `/api/accounts/list/${role}?page=${page}&order_by=${
          this.sortKey
        }&direction=${this.sortDir}`;
      }

      if (this.searchQuery) {
        url +=
          url.indexOf('?') == -1
            ? '?q=' + this.searchQuery
            : '&q=' + this.searchQuery;
      }

      return this.$http
        .get(url)
        .then(response => {
          this.isLoading = false;
          this.accountsList = response.data.users_data.data;
          this.prevPageUrl = response.data.users_data.prev_page_url;
          this.nextPageUrl = response.data.users_data.next_page_url;
          this.currentPage = response.data.users_data.current_page;
          this.pagesNumber = response.data.users_data.last_page;
        })
        .catch(err => {
          this.isLoading = false;
          notify({
            message: 'Could not load accounts list',
            type: 'danger'
          });
        });
    },
    formatDate(date) {
      const instance = moment(date);
      if (!instance.isValid()) return date;
      return moment(date).format('MM/DD/YYYY HH:mm');
    }
  }
};
</script>
