<template>
  <dl class="ship-to widget has-separator">
    <!-- <dt><strong>SHIP TO:</strong></dt> -->
    <dd v-if="areShippingsExist && !isSplitShipping">
      <div>
        {{designRecipient}}
      </div>
      <p v-if="designShippingAddressLine">
        <span>{{designShippingAddressLine}}, {{designShipping.shipping_address.city}}, </span>
        <span>{{designShipping.shipping_address.state_id}} {{designShipping.shipping_address.postal_code}}, </span>
        <span>{{designShipping.shipping_address.country_id}}</span>
      </p>
      <span v-if="designShipping.type_of_delivery === 'fulfillment'">**HOLD FOR FULFILLMENT**</span>
      <span v-if="designShipping.type_of_delivery === 'pickup'">**PICKUP**</span>
    </dd>
    <dd v-else-if="areShippingsExist && isSplitShipping">
      Split Shipping info is below.
    </dd>
    <dd v-else>
      There is no shipping information.
    </dd>
  </dl>
</template>

<script>
export default {
  name: 'ship-to',
  props: {
    order: {
      type: Object,
      required: true
    },
    design: {
      type: Object,
      required: true
    }
  },
  computed: {
    shippings() {
      return this.order.shippings || [];
    },
    areShippingsExist() {
      return this.shippings.length >= 1;
    },
    isSplitShipping() {
      return this.designShippings.length > 1;
    },
    designShippings() {
      return (
        (this.areShippingsExist &&
          this.shippings.filter(
            s =>
              (s.shipping_items &&
                s.shipping_items.length &&
                s.shipping_items.find(
                  si => +si.design_id === +this.design.id
                )) ||
              false
          )) ||
        []
      );
    },
    designShipping() {
      return (
        (!this.isSplitShipping &&
          this.designShippings.length === 1 &&
          this.designShippings[0]) ||
        null
      );
    },
    designShippingAddressLine() {
      return `${this.designShipping.shipping_address.address_1} ${(this
        .designShipping.shipping_address.address_2 &&
        ', ' + this.designShipping.shipping_address.address_2) ||
        ''}`;
    },
    designRecipient() {
      return (
        (this.designShipping &&
          this.designShipping.shipping_address &&
          this.designShipping.shipping_address.recipient) ||
        `${this.order.contact_firstname + ' ' + this.order.contact_lastname}`
      );
    }
  }
};
</script>
