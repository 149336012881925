<template>
  <div class="revision-page page-content container">
    <loader-screen
      :is-loading="isLoading"
      :background-color="'#151414'"
      class="is-hidden-print"
    >
    </loader-screen>
    <StickMessenger :order="order" :right="true" :open="isMessengerOpen" @toggleMessenger="toggleMessenger" class="is-hidden-print"/>
    <transition name="fade" mode="out-in">
      <div v-if="!loading">
        <section
          v-if="order && order.id && order.id === orderId"
          class="is-hidden-print"
        >
          <navigation/>
          <div class="columns revision-content">
            <div class="column is-3">
              <DesignDetailsSidebar
                ref="sidebarBlock"
                :order="order"
                :editable="editable"
                :design="currentDesign"
                :options="currentOptions"
                :mock="mock"
                :shipBy="shipBy"
                :isEvent="isEvent"
                @toggleMessenger="toggleMessenger"
              />
            </div>
            <div class="column is-9">
              <DesignDetailsContent
                ref="contentBlock"
                :editable="editable"
                :order="order"
                :design="currentDesign"
                :mock="mock"
                :designs="designs"
                :mocksWarnings="filteredWarnings"
                :options="currentOptions"
                :isReadyToSaveFinal="isReadyToSaveFinal"
                @saveDraft="saveDraft"
                @saveFinal="saveFinal"
              />
            </div>
          </div>
          <div class="columns shipping-information" v-if="areShippingsExist && isSplitShipping">
            <design-details-shippings
              :order="order"
              :design="currentDesign"
              :designShippings="designShippings"
              class="column is-12"
            >
            </design-details-shippings>
          </div>
        </section>
        <div v-else>Mock not found</div>
      </div>
      <div v-else>Loading...</div>
    </transition>
    </div>
</template>

<script>
import Vue from 'vue';
import { mapGetters, mapActions, mapMutations } from 'vuex';
import cloneDeep from 'lodash/cloneDeep';

import apiService from '../../services/api.service';
import notify from '../../modules/notifier';

import RevisionHeader from './header';
import StickMessenger from '../common/stick-messenger';
import DesignDetailsSidebar from './design-details-sidebar';
import DesignDetailsContent from './design-details-content';
import Print from '../revision/print';

import {
  REVISION_LOAD_ORDER,
  SET_ACTIVE_DESIGN,
  SET_ACTIVE_MOCK
} from '@/store/action-types';

import pubnubService from '../../services/pubnub.service';
import { DRAFT_STATUS, FINAL_STATUS } from '@/helpers/revision';
import {
  teamMembers,
  customers,
  printers,
  canCreateMock
} from '@/helpers/role-access';

import { checkRoles } from '@/router/checkRoles';
import store from '../../store';

import bus from '../../bus';
import LoaderScreen from '../common/loader-screen';
import DesignDetailsShippings from './design-details-shippings';
import Navigation from '../partials/navigation.vue';

const newMock = {
  id: 'new',
  attachments: [],
  // finishings: [],
  version: '',
  status: DRAFT_STATUS
};

export default {
  name: 'revision-page',
  components: {
    Navigation,
    DesignDetailsShippings,
    LoaderScreen,
    RevisionHeader,
    DesignDetailsSidebar,
    DesignDetailsContent,
    StickMessenger,
    Print
  },
  data() {
    return {
      mocksWarnings: [],
      filteredWarnings: [],
      fromNew: false,
      loading: true,
      teamMembers,
      customers,
      printers,
      isMessengerOpen: false,
      isReadyToSaveFinal: false,
      isLoading: false,
      isPrint: false
    };
  },
  computed: {
    ...mapGetters('revision', [
      'order',
      'currentOptions',
      'designs',
      'currentDesign',
      'currentMock',
      'hasMocks',
      'showMockActions',
      'isEvent',
      'shipBy',
      'getActiveMock'
    ]),
    ...mapGetters(['route']),
    mock() {
      if (this.isNewMock) {
        return cloneDeep(newMock);
      }
      return this.currentMock || {};
    },
    isNewMock() {
      return this.$route.params && this.$route.params.mockId === 'new';
    },
    orderId() {
      return this.$route.params ? +this.$route.params.id : '';
    },
    designId() {
      return this.$route.params ? +this.$route.params.designId : '';
    },
    mockId() {
      return this.$route.params ? +this.$route.params.mockId : '';
    },
    isDraft() {
      return !this.mock || this.mock.status === DRAFT_STATUS;
    },
    editable() {
      return this.isNewMock || this.isDraft;
    },
    canCreateMock() {
      return checkRoles(store.getters.roles, canCreateMock, true);
    },
    shippings() {
      return this.order.shippings || [];
    },
    areShippingsExist() {
      return this.shippings.length >= 1;
    },
    isSplitShipping() {
      return this.designShippings.length > 1;
    },
    designShippings() {
      return (
        (this.areShippingsExist &&
          this.shippings.filter(
            s =>
              (s.shipping_items &&
                s.shipping_items.length &&
                s.shipping_items.find(
                  si =>
                    this.currentDesign &&
                    +si.design_id === +this.currentDesign.id
                )) ||
              false
          )) ||
        []
      );
    }
  },
  watch: {
    $route(to, from) {
      const fromNew =
        from.params &&
        to.params &&
        from.params.mockId === 'new' &&
        to.params.mockId !== 'new';
      this.updateState(fromNew);
    },
    editable(editable) {},
    currentDesign: {
      handler: function(currentDesign) {
        if (currentDesign && currentDesign.service === 'poster') {
          this.filteredWarnings = this.mocksWarnings.filter(
            warning =>
              warning.title !== 'Printing Over Seams' &&
              warning.title !== 'Ribbed Garments'
          );
        } else {
          this.filteredWarnings = cloneDeep(this.mocksWarnings);
        }
      },
      deep: true
    },
    mocksWarnings(mocksWarnings) {
      if (this.currentDesign && this.currentDesign.service === 'poster') {
        this.filteredWarnings = mocksWarnings.filter(
          warning =>
            warning.title !== 'Printing Over Seams' &&
            warning.title !== 'Ribbed Garments'
        );
      } else {
        this.filteredWarnings = cloneDeep(mocksWarnings);
      }
    }
  },
  methods: {
    ...mapActions('revision', {
      setActiveDesign: SET_ACTIVE_DESIGN,
      setActiveMock: SET_ACTIVE_MOCK,
      revisionLoadOrder: REVISION_LOAD_ORDER
    }),
    ...mapMutations('revision', {
      updateMockStatus: 'updateMockStatus'
    }),
    checkPermissions() {
      if (!this.canCreateMock) {
        if (this.isNewMock) {
          this.$router.push('/');
        }
        if (this.designs && !this.designs.find(d => +d.id === +this.designId)) {
          this.$router.push('/');
        }
      }
    },
    getMockWarnings() {
      apiService
        .getMockWarnings()
        .then(warnings => {
          this.mocksWarnings = warnings;
        })
        .catch(() => {
          console.error('Mocks warnings were not found');
        });
    },
    updateState(fromNew) {
      const setActiveState = () => {
        if (this.designId) {
          this.checkPermissions();
          this.setActiveDesign(this.designId);
        }
        if (
          !this.isNewMock &&
          this.$route.params.mockId !== 'no-mocks' &&
          this.mockId
        ) {
          this.setActiveMock(this.mockId);
        }
      };
      if (fromNew) {
        this.loading = true;
        this.revisionLoadOrder(this.orderId).then(() => {
          setActiveState();
          this.loading = false;
        });
      } else {
        setActiveState();
      }
    },
    orderUpdateHandler(message) {
      if (message.order_id && message.order_id == this.orderId) {
        this.revisionLoadOrder(this.orderId);
      }
    },
    save(mock_status) {
      this.$set(this, 'isLoading', true);
      let newMockId;
      return Promise.all([
        this.$refs.contentBlock.getFormData(),
        this.$refs.sidebarBlock.getFormData()
      ])
        .then(([contentBlockData, sidebarBlockData]) => {
          const mockData = {
            mock_id: this.isNewMock ? null : this.mock.id,
            status: mock_status,
            design_id: this.designId,
            items: sidebarBlockData.items,
            details: sidebarBlockData.details,
            internal_comment: sidebarBlockData.mockInternalComment,
            design_comment: sidebarBlockData.design_comment,
            order_comment: sidebarBlockData.order_comment,
            finishings: sidebarBlockData.finishings,
            design_service: this.currentDesign.service,
            warnings: contentBlockData.warnings
            // details: this.details,
          };
          return apiService.saveDesignMock(mockData);
        })
        .then(data => {
          newMockId = data.mock_id;
          return this.$refs.contentBlock.sendFiles({
            designId: this.designId,
            mockId: data.mock_id
          });
        })
        .then(() => {
          this.$set(this, 'isLoading', false);
          this.updateMockStatus(mock_status);
          if (this.isNewMock) {
            this.$router.push({
              name: 'Revision',
              params: {
                id: this.orderId,
                designId: this.designId,
                mockId: newMockId
              }
            });
          }
          notify({
            message: 'Mock is saved',
            type: 'success'
          });
        })
        .catch(err => {
          this.$set(this, 'isLoading', false);
          console.error(err);
          notify({
            message: "Couldn't save mock",
            type: 'error'
          });
        });
    },
    saveDraft() {
      this.validateBeforeSubmit().then(isValid => {
        if (!isValid) {
          return notify({
            message:
              'Some validation errors have been detected. Please check required fields.',
            type: 'danger'
          });
        }
        this.save(DRAFT_STATUS);
      });
    },
    saveFinal() {
      this.validateBeforeSubmit().then(isValid => {
        if (!isValid) {
          return notify({
            message:
              'Some validation errors have been detected. Please check required fields.',
            type: 'danger'
          });
        }
        this.save(FINAL_STATUS);
      });
    },
    toggleMessenger(bool) {
      this.$set(this, 'isMessengerOpen', bool);
    },
    validateChild() {
      bus.$emit('validate');
    },

    clearChild() {
      bus.$emit('clear');
    },
    validateBeforeSubmit(e) {
      this.clearChild();
      this.errors.errors = [];
      this.validateChild();

      return new Promise((resolve, reject) => {
        Vue.nextTick(() => {
          resolve(!this.errors.count());
        });
      });
    }
  },
  created() {
    this.updateState(true);
    this.getMockWarnings();
    pubnubService.subscribeUpdateEvent('mock', this.orderUpdateHandler);

    this.validateBeforeSubmit().then(isValid => {
      this.$set(this, 'isReadyToSaveFinal', isValid);
    });

    bus.$on('errors-changed', errors => {
      if (errors) {
        errors.forEach(e => {
          this.errors.add(e.field, e.msg, e.rule, e.scope);
        });
      }
      this.$set(this, 'isReadyToSaveFinal', false);
    });

    bus.$on('errors-deleted', oldErrors => {
      if (oldErrors) {
        oldErrors.forEach(e => {
          this.errors.remove(e.field, e.scope);
        });
      }
      this.validateBeforeSubmit().then(isValid => {
        this.$set(this, 'isReadyToSaveFinal', isValid);
      });
    });
  },
  destroyed() {
    pubnubService.unsubscribeUpdateEvent('mock', this.orderUpdateHandler);
  }
};
</script>
<style lang="scss">
.revision-page {
  .design-details-sidebar ul:not(.multiselect__content) {
    list-style: initial;
    margin-left: 20px;
  }
  .loading-message {
    color: #0c9ad6;
    font-weight: 500;
    font-size: 20px;
    margin-bottom: 100px;
  }

  .sk-circle:before {
    background-color: #0c9ad6 !important;
  }

  .pg-loading-screen {
    opacity: 0.97;
  }

  .pg-loading-html {
    margin-left: 30px;
  }
}
</style>
