<template>
  <div class="columns">
    <div class="column is-12">
      <div class="has-text-centered">
        <h5 class="subtitle is-5">Redo Design?</h5>
        <p>
          This will create a clone of this designd and call it {{additional.designName}}-R
        </p>
      </div><br />
      <form @submit.prevent="submitForm" data-vv-scope="form">
        <p class="control has-addons has-addons-centered">
          <span class="select">
            <select name="redoCategory"
              :class="{'is-danger': errors.has('form.redoCategory'), 'is-loading': redoCategoriesLoading}"
              v-validate:redoCategory="'required'"
              data-rules="required"
              scope="form"
              v-model="redoCategory">
              <option value="">Redo Category</option>
              <option v-for="(value, key) in redoCategories"
              :value="key">{{value}}</option>
            </select>
          </span>
        </p>
        <p class="control">
          <label class="checkbox">
            <input v-model="needs_mocks_reapproval"
              type="checkbox">
            Needs Mocks Re-approval
          </label>
        </p>
        <p class="control">
          <textarea name="reason"
            v-model="reason"
            v-validate="{ rules: 'required', scope: 'form' }"
            class="textarea"
            :class="{'is-danger': errors.has('form.reason')}"
            placeholder="Reason/Conclusion"></textarea>
        </p>
      </form>
    </div>
  </div>
</template>

<script>
  import Vue from 'vue';
  import notify from '../../../modules/notifier';
  import apiService from '../../../services/api.service';
  import alertify from 'alertify.js';

  export default {
    name: 'redo-design',
    props: ['submitted', 'additional'],
    data() {
      return {
        redoCategory: '',
        redoCategories: '',
        redoCategoriesLoading: false,
        needs_mocks_reapproval: false,
        reason: '',
        processing: false
      }
    },
    methods: {
      submitForm() {
        this.processing = true;

        return new Promise((res, rej) => {
          this.$validator.validateScopes().then((success) => {
            if (success) {
              apiService.changeFollowUpStatus({
                designId: this.additional.designId,
                status: this.additional.status,
                reasonCategoryId: this.redoCategory,
                description: this.reason,
                reApproveMock: this.needs_mocks_reapproval,
              }).then((data) => {
                notify({
                  message: 'Design clone is added',
                  type: 'success'
                });

                this.additional.callback({
                  redoCategory: this.redoCategory,
                  needsMocksReapproval: this.needs_mocks_reapproval,
                  reason: this.reason,
                  newDesign: data.design,
                });
                this.processing = false;
                res();
                this.$emit('close');
              }).catch(err => {
                this.processing = false;
                alertify.error('Something went wrong');
                rej();
              });
            } else {
              alertify.error('Please fill all fields.');
              this.processing = false;
              rej();
            }
          });
        });
      },
      cancel() {
        this.additional.callback({
          cancel: true
        });
        this.$emit('close');
      }
    },
    mounted() {
      this.redoCategoriesLoading = true;
      apiService.getRedoCategoriesList().then((cats) => {
        this.redoCategories = cats;
        this.redoCategoriesLoading = false;
      });
    }
  };
</script>
