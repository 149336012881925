<template>
  <div class="buttons revision-actions">
    <a
      class="button is-primary is-fullwidth is-uppercase"
      @click.prevent="approveMock"
      v-role-access="canApprove"
      v-if="isReadyToApprove"
    >Approve</a>
    <a
      class="button is-fullwidth is-uppercase"
      @click="requestRevisions"
      v-role-access="canRequestRevision"
      v-if="isReadyToApprove"
    >Request Revisions</a>
    <a
      class="button is-fullwidth is-uppercase"
      @click.prevent="sendMock"
      v-if="isReadyToSend"
      v-roles="canSendMocks"
    >Send</a>
    <a
      class="button is-fullwidth is-uppercase"
      :href="`#/revision/${order.id}/${design.id}/${mock.id}/print`"
      target="_blank"
      v-role-access="canPrintMock"
      v-show="isLastMock && isApproved"
    >Print</a>
    <a
      class="button is-fullwidth is-uppercase"
      @click.prevent="toggleMessenger"
      v-if="isCustomer"
    >Message</a>
            <modals-container :clickToClose="false"/>
  </div>
</template>

<script>
import popup from '../../../modules/popupper';
import ApproveMockPopup from '../popups/approve-mock';
import RequestRevisionPopup from '@/components/common/Popups/RequestRevisionPopup';

import { mapActions } from 'vuex';
import { REQUEST_REVISIONS } from '../../../store/action-types';

import { APPROVED_STATUS, DRAFT_STATUS } from '../../../helpers/revision';
import {
  canApproveMock,
  canRequestRevisionMock,
  canSendMocks,
  canPrintMock
} from '../../../helpers/role-access';
import apiService from '../../../services/api.service';
import alertify from 'alertify.js';

import { checkRoles } from '../../../router/checkRoles';
import store from '../../../store';

export default {
  name: 'revision-actions',
  props: {
    mock: {
      type: Object,
      required: true
    },
    design: {
      type: Object,
      required: true
    },
    order: {
      type: Object,
      required: true
    }
  },
  components: {
    RequestRevisionPopup
  },
  computed: {
    isApproved() {
      return this.mock.status === APPROVED_STATUS;
    },
    isDraft() {
      return this.mock.status === DRAFT_STATUS;
    },
    canApprove: () => canApproveMock,
    canRequestRevision: () => canRequestRevisionMock,
    canSendMocks: () => canSendMocks,
    canPrintMock: () => canPrintMock,
    isLastMockApproved() {
      return (
        this.design &&
        this.design.mocks &&
        this.design.mocks.length &&
        this.design.mocks[this.design.mocks.length - 1] &&
        +this.design.mocks[this.design.mocks.length - 1] === +APPROVED_STATUS
      );
    },
    isLastMock() {
      return (
        this.design &&
        this.design.mocks &&
        this.design.mocks.length &&
        this.design.mocks[this.design.mocks.length - 1] &&
        +this.design.mocks[this.design.mocks.length - 1].id === +this.mock.id
      );
    },
    isApprovedAll() {
      return (
        this.order.designs.filter(
          design =>
            design.mocks.length &&
            design.mocks[design.mocks.length - 1] &&
            +design.mocks[design.mocks.length - 1].status === +APPROVED_STATUS
        ).length === this.order.designs.length
      );
    },
    isSent() {
      return this.mock.is_sent;
    },
    isReadyToSend() {
      return this.isReadyToApprove;
    },
    isReadyToApprove() {
      return this.isLastMock && !this.isApproved && !this.isDraft;
    },
    isCustomer() {
      return checkRoles(store.getters.roles, ['customer'], true);
    }
  },
  methods: {
    ...mapActions('revision', {
      requestRevision: REQUEST_REVISIONS
    }),
    approveMock(e) {
      popup({
        title: 'Warnings & Terms of Art Approval',
        subtitle:
          'By approving this mock, you agree to the following warnings & terms of conditions:',
        additional: {
          warnings: this.mock.warnings,
          mockId: this.mock.id,
          callback: this.sendMocksApprovedEmailNotification
        },
        bodyComponent: ApproveMockPopup,
        showButtons: true,
        submitLabel: 'Yes, I approve',
        modalCardLong: true,
        runCallback: true,
        confirm:
          'I understand that once I approve this design it will be immediately queued for printing and no further changes can be made.'
      });
    },
    requestRevisions() {
      let additional = {
        mockId: this.mock.id,
        orderId: this.order.id,
        designName: this.design.name,
        mockVersion: this.mock.version
      };

      this.$modal.show(
        RequestRevisionPopup,
        {
          callback: message => {
            this.submitFormRequestRevision(message, additional);
            this.$modal.hide('request-revision-popup');
          }
        },
        {
          name: 'request-revision-popup',
          title: 'Request Revision',
          height: 'auto'
        }
      );
    },

    validate(message) {
      return new Promise((res, rej) => {
        if (message) {
          res();
        } else {
          rej();
          this.error = 'Message is required';
        }
      });
    },
    submitFormRequestRevision(message, additional) {
      this.validate(message)
        .then(() => {
          let data = {
            mock_id: additional.mockId,
            order_id: additional.orderId,
            designName: additional.designName,
            mockVersion: additional.mockVersion,
            message: message
          };

          this.requestRevision(data).then(response => {
            let status =
              response.status == 'success' ? response.status : 'danger';

            if (status === 'danger') {
              notify({
                message: response.message,
                type: status
              });
            }

            this.$emit('close');
          });
        })
        .catch(() => {
          this.$emit('submitFail');
        });
    },

    sendMock() {
      if (this.mock && this.mock.id !== 'new') {
        apiService
          .sendMock(this.mock.id)
          .then(data => {
            alertify.success('The mock was sent to the customer');
          })
          .catch(err => {
            alertify.error(err.body.error);
          });
      } else {
        alertify.error("The mock isn't ready to send!");
      }
    },
    toggleMessenger() {
      this.$emit('toggleMessenger', true);
    },
    printMock() {
      this.$emit('printMock', true);
    },
    sendMocksApprovedEmailNotification() {
      if (this.isApprovedAll) {
        apiService.sendMocksApprovedNotification({ order_id: this.order.id });
      }
    }
  }
};
</script>
