<template>
  <div class="Accordion__item" :class="variant">
    <div class="Accordion__heading" @click="isOpen = !isOpen">
      <div class="Accordion__title">
        <slot name="title"></slot>
      </div>
      <div class="Accordion__icon" v-bind:class="{'is-open': isOpen}">
        <slot name="icon">
          <span class="icon icon_smaller clr-blue">
            <i
              :class="{
                'ff-triangle-right': !isOpen,
                'ff-triangle-down': isOpen
              }"
              aria-hidden="true"
            ></i>
          </span>
        </slot>
      </div>
    </div>
    <div class="Accordion__content" v-bind:class="{'is-open': isOpen}">
      <slot name="content"></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    variant: {
      default: '',
      type: String,
      required: false
    }
  },
  data() {
    return {
      isOpen: false
    };
  }
};
</script>

<style>
.Accordion__item {
  display: flex;
  flex-basis: 100%;
  flex-direction: column;
  margin-bottom: 20px;
}

.Accordion__heading {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  cursor: pointer;
  background-color: #4db4ea;
  padding-left: 7px;
}

.Accordion__heading h5 {
  margin: 0;
  line-height: 2em;
}

.Accordion__content {
  display: none;
  border: 1px solid #363636;
  border-top: none;
}

.is-open.Accordion__content {
  display: block;
}
.Accordion__content .acc-item-explanation {
  padding: 0 7px;
  line-height: 2em;
}
.Accordion__icon .icon {
  padding-top: 3px;
}
</style>
