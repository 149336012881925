<template>
  <div>
    <div class="column">
      <orders-report></orders-report>
    </div>
    <v-dialog></v-dialog>
  </div>
</template>

<script>
import Datepicker from '../../../modules/datepicker/index';
import OrdersReport from "@/components/reports/orders-report.vue";

export default {
  name: 'orders-report-tab',
  components: {OrdersReport, Datepicker},
};
</script>
