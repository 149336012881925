<template>
  <div v-if="!orderDetailsLoading">
    <nav class="level">
      <!-- Left side -->
      <breadcrumbs :links="crumbLinks"/>
    </nav>
    <orders-grid :orders="orderDetails"
      :ordersLoading="orderDetailsLoading"
      :ordersPhase="orderDetailsPhase"
      :getDesignProperty="getDesignProperty"
      :mockPopupCallback="mockPopupCallback"
      :designPrinterCallback="designPrinterCallback"
      :orderFollowUpStatuses="orderFollowUpStatuses"
      :designPrintStatuses="designPrintStatuses"
      :finishingPrintStatuses="finishingPrintStatuses"
      :expanded="true"
      :assignButton="true"
      @setPhase="setPhase"
      @sendMockCallback="sendMockCallback"
      @changePaymentStatus="changePaymentStatus"
      @changeFollowUpStatus="changeFollowUpStatus"
      @sendSurveyCallback="sendSurveyCallback"
      @changeBlankStatus="changeBlankStatus"
      @changePrinterStatus="changePrinterStatus"
      @onChangeFilesLinks="onChangeFilesLinks">
    </orders-grid>

    <div class="level">
      <div class="level-left"></div>
      <div class="level-right buttons are-small">
        <template v-if="latestInvoice && latestInvoice.id">
          <button
            class="button is-link is-outlined"
            @click="printInvoice"
            v-roles="['admin', 'csr', 'production-coordinator', 'purchaser', 'art-producer', 'customer']">Print invoice
          </button>
          <button
            class="button is-link is-outlined"
            @click="emailInvoice"
            v-roles="['admin', 'csr', 'production-coordinator', 'purchaser', 'art-producer']">Email invoice
          </button>
        </template>
        <button class="button is-danger is-outlined"
          @click="deleteOrder"
          v-if="canDelete"
        >Delete</button>
        <button class="button is-danger is-outlined"
          :disabled="isPaid || isNotEditable"
          @click="archive"
          v-roles="['admin', 'csr', 'production-coordinator', 'purchaser', 'art-producer']"
        >Archive</button>
        <button class="button is-link is-outlined"
          v-if="isBlankClaimedByCurrentUser"
          @click="unclaimBlank"
          v-roles="['admin', 'csr', 'production-coordinator', 'purchaser', 'art-producer']"
        >Unclaim blank</button>
        <button class="button is-link is-outlined"
          v-if="isMockClaimedByCurrentUser"
          @click="unclaimMock"
          v-roles="['admin', 'csr', 'production-coordinator', 'purchaser', 'art-producer']"
        >Unclaim mock</button>
        <button class="button is-link is-outlined"
          :disabled="isNotEditable"
          @click="editOrder"
          v-roles="['admin', 'csr', 'production-coordinator', 'purchaser', 'art-producer']"
        >Edit order</button>
        </div>
    </div>
    <div class="level" v-if="!orderDate">
      <div class="level-left"></div>
      <div class="level-right">
        <div class="control order-date" v-roles="['admin', 'csr', 'production-coordinator', 'purchaser', 'art-producer']">
          <datepicker
            v-model="orderDate"
            :config="{dateFormat: 'm/d/Y H:i', wrap: true, minDate: orderCreatedAt, enableTime: true }"
            id="orderDate"
          />
        </div>
        <button class="button ml-1"
                @click="setOrderDate"
                v-roles="['admin', 'csr', 'production-coordinator', 'purchaser', 'art-producer']"
        >Set order date</button>
      </div>
    </div>
    <div class="columns">
      <div class="column is-9">
        <details-estimate
          :data="data"
          :formOptions="formOptions"
          :shippingOptions="shippingOptions"
          :finishingsPrices="finishingsPrices"
          v-if="!isLoading"
        ></details-estimate>
        <div v-else>Loading...</div>
      </div>

      <div class="column is-3" style="padding-top: 40px;">
        <div class="estimate-totals" style="margin-bottom: 20px;" v-roles="canSeeProductionForm"  v-if="!isLoading">
          <h3 class="estimate-totals__title">Summary</h3>
          <ul class="estimate-totals__list">
            <li>
              <div class="item">
                <span class="item__label">
                  <template v-if="isNonOrderInvoice">References</template>
                  <template v-else>Designs</template>
                </span>
                <span class="item__total">{{ totals.designsTotal  | price }}</span>
              </div>
              <ul class="estimate-totals__subitems">
                <li v-for="(designTotal, index) in totals.designs" :key="index">
                  <div class="item">
                    <label class="item__label">
                      <template v-if="isNonOrderInvoice">Reference</template>
                      <template v-else>Design</template> {{ index + 1 }}
                    </label>
                    <span class="item__total">{{ designTotal.total | price }}</span>
                  </div>
                </li>
              </ul>
            </li>
            <li class="estimate-totals__item" v-if="totals.tagSetups > 0">
              <div class="item">
                <span class="item__label">Tag Setups</span>
                <span class="item__total">{{ totals.tagSetups | price }}</span>
              </div>
            </li>
            <li class="estimate-totals__item" v-if="!isNonOrderInvoice">
              <div class="item">
                <span class="item__label">Shipping</span>
                <span class="item__total">{{ totals.shipping | price }}</span>
              </div>
            </li>
            <li class="estimate-totals__item" v-if="data.payment.state_tax">
              <div class="item">
                <label class="item__label">State Tax</label>
                <span class="item__total">{{ data.payment.state_tax | price }}</span>
              </div>
            </li>
            <li class="estimate-totals__item" v-if="totals.rushFee > 0">
              <div class="item">
                <label class="item__label">Rush Fee ({{ data.payment.rush_fee.rush_fee_percentage }}%)</label>
                <span class="item__total">{{ totals.rushFee | price }}</span>
              </div>
            </li>
            <li class="estimate-totals__item" v-if="totals.discount > 0">
              <div class="item">
                <label class="item__label">Discount ({{ data.payment.discount.discount_percentage }}%)</label>
                <span class="item__total">{{ totals.discount | price }}</span>
              </div>
            </li>
          </ul>
          <ul class="estimate-totals__list is-total">
            <li>
              <div class="item">
                <span class="item__label">Total Cost</span>
                <span class="item__total">{{ estimateTotal | price }}</span>
              </div>
            </li>
            <li class="is-amount" v-if="data.order.invoice">
              <div class="item">
                <span class="item__label">Amount Paid</span>
                <span class="item__total">{{ data.order.invoice.paid_sum | price }}</span>
              </div>
            </li>
          </ul>
          <div class="estimate-totals__balance-due"
            :class="{ 'is-paid': balanceDue === 0 }"
            v-if="data.order.invoice"
          >
            <span>Balance Due</span>
            <span class="value">{{ balanceDue | price }}</span>
          </div>
        </div>
        <production-notes
          v-roles="canSeeProductionForm"
          v-if="!isNonOrderInvoice && !isLoading"
          :id="id"
          :orderDetails="orderDetails" />
        <br>
        <div class="card is-fullwidth embedded-messenger" v-roles="canSeeCommunicationForm">
          <header class="card-header">
            <p class="card-header-title">Order Activity</p>
          </header>
          <div class="card-content">
            <div v-if="company_customers.length" v-roles="canSeeProductionForm">
              <add-to-messages
                :id="id"
                :orderDetails="orderDetails"
                :customers="company_customers"></add-to-messages>
            </div>
            <messenger :data="messages"
              :isEmbedded="true"></messenger>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.row-with-top-whitespace {
  margin-top: 15px;
}

.details-base-info {
  box-shadow: 0 1px 2px rgba(10, 10, 10, 0.1);
}
.paid-amount {
  padding: 20px;
  display: flex;
  justify-content: flex-end;

  .paid-amount-content {
    width: 25%;
  }
}

.comment-card {
  margin-top: 45px;
}

.label-bigger {
  font-size: 1.2em;
}

.order-date {
  margin-top: 9px;
}
</style>

<script>
import Vue from 'vue';
import { mapGetters, mapActions, mapMutations } from 'vuex';
import bus from '../../../bus';
import {
  PAYMENT_STATUS_PENDING,
  PAYMENT_STATUS_PAID,
  ORDER_STATUS_ARCHIVED
} from '../../../helpers/orders';
import {
  LOAD_ORDER,
  UPDATE_ORDER_PAYMENT_STATUS,
  UPDATE_DESIGN_BLANK_STATUS,
  UPDATE_ORDER_DESIGN_PRINTER,
  SET_ORDER_PHASE,
  SET_SURVEY_DATE,
  SET_MOCK_DATE,
  UPDATE_DESIGN_FOLLOW_UP_STATUS,
  UPDATE_ORDER_DESIGN_PROGRESS,
  UPDATE_ORDER_FINISHING_PROGRESS,
  UPDATE_ORDER_PRINTER_STATUS,
  UPDATE_ORDER_DESIGN_MOCK_PROGRESS,
  UNCLAIM,
  UPDATE_DESIGN_MOCK_STATUS,
  UPDATE_ORDER_PRINT_BY_AND_SHIP_BY_DATES,
  UPDATE_ORDER_DESIGN_PRINT_BY_AND_SHIP_BY_DATES,
  LOAD_EDIT_ORDER_INIT_DATA
} from '../../../store/action-types';

import breadcrumbs from './../../common/breadcrumbs';
import OrdersGrid from '../orders-grid';
import EstimateSummary from './../estimate-summary';
import Messenger from '../../common/messenger';
import AddToMessages from './add-to-messages';
import ProductionNotes from './production-notes';

import DesignListItem from '../design-list-item/index';
import PaidAndRemainAmount from '../paid-and-remain-amount';

import apiService from '../../../services/api.service';
import alertify from 'alertify.js';
import notify from '../../../modules/notifier';
import { forEach, includes } from 'lodash';
import popup from '../../../modules/popupper';
import pubnubService from '../../../services/pubnub.service';
import moment from 'moment';
import {canSeeProductionForm, canSeeCommunicationForm} from '../../../helpers/role-access';

import { checkRoles } from '../../../router/checkRoles';

import { getEstimateTotals } from '@/helpers/prices';
import DetailsEstimate from '@/components/common/DetailsEstimate/DetailsEstimate';
import Datepicker from '@/modules/datepicker/index';

export default {
  name: 'order-details',

  data() {
    return {
      form: {
        estimate_becomes_to_order_at: null
      },
      isLoading: true,
      data: null,
      formOptions: null,
      finishingsPrices: null,
      shippingOptions: null,
      messages: {
        id: this.$route.params.id
      },
      invoices: [],
      canSeeProductionForm,
      canSeeCommunicationForm
    };
  },

  components: {
    OrdersGrid,
    EstimateSummary,
    Messenger,
    breadcrumbs,
    AddToMessages,
    ProductionNotes,
    DesignListItem,
    PaidAndRemainAmount,
    DetailsEstimate,
    Datepicker
  },

  watch: {
    id(id) {
      this.$store.commit('setOrder', {});
      this.messages.id = id;
      this.loadOrder(id).then(() => {
        this.doViewAction(id);
        this.loadInvoices();
      });

      this.isLoading = true;
      Promise.all([
        this.fetchEstimateData(),
        this.fetchShippingMethods(),
        this.getInitData()
      ])
        .then(() =>
          Promise.all([
            this.fetchFormOptions(this.data.account.account_id),
            this.fetchFinishingsPrices(this.data.account.account_id)
          ])
        )
        .then(() => {
          this.isLoading = false;
        });
    },
    designScrollId() {
      if (this.orderDetails && this.orderDetails[0]) {
        this.doViewAction();
      }
    },
    $route() {
      if (checkRoles(this.$store.getters.roles, ['printer', 'customer'])) {
        this.checkOids();
      }
    }
  },

  computed: {
    ...mapGetters([
      'orderDetails',
      'orderDetailsSummary',
      'orderDetailsLoading',
      'orderDetailsPhase',
      'orderFollowUpStatuses',
      'designPrintStatuses',
      'finishingPrintStatuses',
      'orderDetailsOptions',
      'isTestAccount'
    ]),
    canDelete() {
      return (
        (this.isTestAccount &&
          checkRoles(this.$store.getters.roles, ['admin', 'csr'])) ||
        checkRoles(this.$store.getters.roles, ['admin']) ||
        (checkRoles(this.$store.getters.roles, ['admin', 'csr']) &&
          this.isTestOrder)
      );
    },
    isTestOrder() {
      if (!this.orderDetails || !this.orderDetails[0]) {
        return false;
      }

      return this.orderDetails[0].is_test;
    },
    isPaid() {
      if (!this.orderDetails || !this.orderDetails[0]) {
        return;
      }

      return (
        this.paidAmount > 0 ||
        +this.orderDetails[0].payment_status === +PAYMENT_STATUS_PAID
      );
    },
    isNonOrderInvoice() {
      return (
        this.orderDetails.length &&
        this.designs[0].service === 'non_order_invoice'
      );
    },
    showPaidAndRemain() {
      return (
        this.orderDetails &&
        this.orderDetails.length &&
        this.orderDetails[0].invoices &&
        this.orderDetails[0].invoices.length
      );
    },
    paidAmount() {
      if (
        !this.orderDetails ||
        !this.orderDetails[0] ||
        !this.orderDetails[0].invoices ||
        !this.orderDetails[0].invoices.length
      ) {
        return;
      }

      return this.orderDetails[0].latest_invoice.paid_sum;
    },
    remainAmount() {
      if (
        !this.orderDetails ||
        !this.orderDetails[0] ||
        !this.orderDetails[0].invoices ||
        !this.orderDetails[0].invoices.length
      ) {
        return;
      }

      const remaining =
        this.orderDetails[0].latest_invoice.amount - this.paidAmount;
      return remaining > 0 ? remaining : 0;
    },
    totalPrice() {
      if (this.estimate.total.upcharge) {
        return +this.estimate.total.price + +this.estimate.total.upcharge;
      } else {
        return +this.estimate.total.price;
      }
    },
    itemsString() {
      if (
        !this.estimate ||
        !this.estimate.total ||
        this.estimate.total.qty === undefined
      ) {
        return;
      }

      return `(${this.estimate.total.qty} Item${
        this.estimate.total.qty > 1 ? 's' : ''
      })`;
    },
    boxesString() {
      if (
        !this.estimate ||
        !this.estimate.total ||
        this.estimate.total.packages_qty === undefined
      ) {
        return;
      }
      return `(${this.estimate.total.packages_qty} Box${
        this.estimate.total.packages_qty > 1 ? 'es' : ''
      })`;
    },
    id() {
      return this.$route.params.id;
    },
    // Check if blank is claimed by currently logged in user.
    isBlankClaimedByCurrentUser() {
      if (
        !this.$store.getters.userProfile.id ||
        !this.orderDetails ||
        !this.orderDetails[0] ||
        !this.orderDetails[0].claim_blank_user_id
      ) {
        return false;
      }

      let logged_in_user = this.$store.getters.userProfile.id;
      let claim_blank_user_id = this.orderDetails[0].claim_blank_user_id;
      if (
        logged_in_user &&
        claim_blank_user_id &&
        logged_in_user == claim_blank_user_id
      ) {
        return true;
      }
      return false;
    },

    // Check if blank is claimed by currently logged in user.
    isMockClaimedByCurrentUser() {
      if (
        !this.$store.getters.userProfile.id ||
        !this.orderDetails ||
        !this.orderDetails[0] ||
        !this.orderDetails[0].claim_mock_user_id
      ) {
        return false;
      }

      let logged_in_user = this.$store.getters.userProfile.id;
      let claim_mock_user_id = this.orderDetails[0].claim_mock_user_id;
      if (
        logged_in_user &&
        claim_mock_user_id &&
        logged_in_user == claim_mock_user_id
      ) {
        return true;
      }
      return false;
    },
    designScrollId() {
      return this.$route.params.action;
    },
    crumbLinks() {
      return [
        { url: '/orders', label: 'Orders' },
        { url: '/orders/' + this.id, label: '#' + this.id },
        { label: 'Details' }
      ];
    },
    orderCreatedAt() {
      return (
        (this.orderDetails &&
          this.orderDetails[0] &&
          this.orderDetails[0].created_at) ||
        null
      );
    },
    orderDate: {
      get() {
        return (
          (this.orderDetails &&
            this.orderDetails[0] &&
            this.orderDetails[0].estimate_becomes_to_order_at) ||
          null
        );
      },
      set(val) {
        this.form.estimate_becomes_to_order_at = val;
      }
    },
    isNotEditable() {
      return (
        (
          (this.orderDetails &&
            this.orderDetails[0] &&
            this.orderDetails[0].designs) ||
          []
        ).every(design => design.printer_status >= 5) || this.isArchived
      );
    },
    billed() {
      if (!this.orderDetails || !this.orderDetails[0]) return;

      const {
        b_city,
        b_country_id,
        b_mailing_address,
        b_postal_code,
        b_state,
        b_state_id,
        contact_firstname,
        contact_lastname
      } = this.orderDetails[0];

      return {
        city: b_city,
        countryId: b_country_id,
        mailingAddress: b_mailing_address,
        postalCode: b_postal_code,
        state: b_state,
        stateId: b_state_id,
        shipper: `${contact_firstname} ${contact_lastname}`
      };
    },
    shipped() {
      if (!this.orderDetails || !this.orderDetails[0]) return;
      const {
        s_city,
        s_country_id,
        s_mailing_address,
        s_postal_code,
        s_state,
        s_state_id,
        recipient_firstname,
        recipient_lastname,
        contact_firstname,
        contact_lastname
      } = this.orderDetails[0];

      return {
        city: s_city,
        countryId: s_country_id,
        mailingAddress: s_mailing_address,
        postalCode: s_postal_code,
        state: s_state,
        stateId: s_state_id,
        shipper:
          (recipient_firstname &&
            recipient_lastname &&
            `${recipient_firstname} ${recipient_lastname}`) ||
          (contact_firstname &&
            contact_lastname &&
            `${contact_firstname} ${contact_lastname}`)
      };
    },
    estimate() {
      if (!this.orderDetailsSummary) return;

      return this.orderDetailsSummary;
    },
    comment() {
      if (
        this.orderDetails &&
        this.orderDetails[0] &&
        this.orderDetails[0].comment
      ) {
        return this.orderDetails[0].comment;
      }
    },
    designs() {
      if (
        this.orderDetails &&
        this.orderDetails[0] &&
        this.orderDetails[0].designs
      ) {
        return this.orderDetails[0].designs;
      }
    },
    company_customers() {
      if (
        this.orderDetails &&
        this.orderDetails[0] &&
        this.orderDetails[0].user &&
        this.orderDetails[0].user.company &&
        this.orderDetails[0].user.company.customers
      ) {
        return this.orderDetails[0].user.company.customers;
      } else {
        return [];
      }
    },
    isArchived() {
      return (
        this.orderDetails &&
        this.orderDetails[0] &&
        +this.orderDetails[0].status === +ORDER_STATUS_ARCHIVED
      );
    },
    totals() {
      return getEstimateTotals(
        this.data,
        this.formOptions,
        this.finishingsPrices
      );
    },
    estimateRawTotal() {
      let total = 0;
      total += +this.totals.designsTotal;
      total += +this.totals.shipping;
      total += +this.totals.stateTaxTotal;
      total += +this.totals.tagSetups;
      if (total === 0) return 0;
      return total;
    },
    estimateTotal() {
      let total = this.estimateRawTotal;
      if (total === 0) return 0;
      total -= +this.totals.discount;
      total += +this.totals.rushFee;
      if (total <= 0) return 0;
      return total;
    },
    balanceDue() {
      return this.data.order.invoice.amount - this.data.order.invoice.paid_sum;
    },
    latestInvoice() {
      return this.invoices.find(({ is_current }) => !!is_current);
    }
  },
  methods: {
    ...mapActions({
      loadOrder: LOAD_ORDER,
      getInitData: LOAD_EDIT_ORDER_INIT_DATA,
      changePaymentStatus: UPDATE_ORDER_PAYMENT_STATUS,
      changeBlankStatus: UPDATE_DESIGN_BLANK_STATUS,
      updateDesignFollowUpStatus: UPDATE_DESIGN_FOLLOW_UP_STATUS,
      sendSurveyCallback: SET_SURVEY_DATE,
      sendMockCallback: SET_MOCK_DATE,
      updatePrinter: UPDATE_ORDER_DESIGN_PRINTER,
      setPhase: SET_ORDER_PHASE,
      updateOrderDesignProgress: UPDATE_ORDER_DESIGN_PROGRESS,
      updateOrderFinishingProgress: UPDATE_ORDER_FINISHING_PROGRESS,
      changePrinterStatus: UPDATE_ORDER_PRINTER_STATUS,
      updateOrderDesignMockProgress: UPDATE_ORDER_DESIGN_MOCK_PROGRESS,
      changeMockStatus: UPDATE_DESIGN_MOCK_STATUS,
      updateOrderDesignPrintByAndShipByDates: UPDATE_ORDER_DESIGN_PRINT_BY_AND_SHIP_BY_DATES,
      updateOrderPrintByAndShipByDates: UPDATE_ORDER_PRINT_BY_AND_SHIP_BY_DATES
    }),
    ...mapMutations({
      setDesignTrackings: 'setDesignTrackings',
      updateDesignBlankStatus: 'updateDesignBlankStatus'
    }),
    printInvoice() {
      if (this.latestInvoice && this.latestInvoice.link_hash) {
        window.open('/#/invoices/' + this.latestInvoice.link_hash + '/print');
      }
    },
    emailInvoice() {
      if (this.latestInvoice && this.latestInvoice.id) {
        apiService
          .emailInvoice(this.latestInvoice.id)
          .then(data => {
            if (data.status == 'success') {
              notify({
                message: 'Invoice sent.',
                type: 'success'
              });
            } else {
              notify({
                message: data.message,
                type: 'danger'
              });
            }
          })
          .catch(err => {
            notify({
              message: 'Something wrong.',
              type: 'danger'
            });
          });
      }
    },
    designPrinterCallback(orderId, designId, printer, design) {
      this.updatePrinter({ orderId, designId, printer, design });
    },
    changeFollowUpStatus({ designId, status, design, newDesign }) {
      this.updateDesignFollowUpStatus({ designId, status, design, newDesign });

      this.isLoading = true;
      Promise.all([
        this.fetchEstimateData(),
        this.fetchShippingMethods(),
        this.getInitData()
      ])
        .then(() =>
          Promise.all([
            this.fetchFormOptions(this.data.account.account_id),
            this.fetchFinishingsPrices(this.data.account.account_id)
          ])
        )
        .then(() => {
          this.isLoading = false;
        });
    },
    archive() {
      alertify
        .okBtn('Archive')
        .confirm('Are you sure that you want to archive the order?', ev => {
          return apiService
            .archiveOrder(this.id)
            .then(data => {
              alertify.success('Order archived');
              this.$router.push('/orders');
            })
            .catch(err => {
              console.log('Error:', err);
            });
        });
    },
    deleteOrder() {
      alertify
        .okBtn('Delete')
        .confirm('Are you sure that you want to delete the order?', ev => {
          return apiService
            .deleteOrder(this.id)
            .then(data => {
              alertify.success('Order deleted!');
              this.$router.push('/orders');
            })
            .catch(err => {
              console.log('Error:', err);
            });
        });
    },
    mockPopupCallback() {
      this.loadOrder(this.id).then(() => {
        this.setPhase('M');
      });
    },

    getDesignProperty(orderId, designId, prop) {
      return this.orderDetails[0].designs.filter(
        d => d.id === parseInt(designId)
      )[0][prop];
    },
    setOrderDate() {
      return apiService
        .updateOrder({
          id: this.id,
          data: {
            estimate_becomes_to_order_at: this.form.estimate_becomes_to_order_at
          }
        })
        .then(data => {
          this.loadOrder(this.id);
        })
        .catch(err => {
          console.error(err);
        });
    },
    editOrder() {
      this.$router.push(`/orders/${this.id}/edit`);
    },

    // on send design files
    onChangeFilesLinks({ itemId, orderId, modelType, model }) {
      switch (modelType) {
        case 'design':
          this.updateOrderDesignProgress({
            orderId,
            designId: itemId,
            progress: model
          });
          break;
        case 'finishing':
          this.updateOrderFinishingProgress({
            orderId,
            finishingId: itemId,
            progress: model
          });
          break;
      }
    },

    scrollTo(id) {
      if (!id) return;

      let offsetTop = 0;
      let elem = document.getElementById(id);
      if (!elem) {
        alertify.error(id + ' not found. Probably the order has been edited.');
        return;
      }

      do {
        if (!isNaN(elem.offsetTop)) {
          offsetTop += elem.offsetTop;
        }
      } while ((elem = elem.offsetParent));

      scroll(0, offsetTop);
    },

    loadInvoices() {
      return apiService
        .getInvoices(this.id)
        .then(data => {
          this.invoices = data;
        })
        .catch(err => {
          console.log('Error:', err);
        });
    },

    sendInitialInvoice() {
      alertify.okBtn('Send').confirm('Initial invoice will be sent', ev => {
        let data = {
          invoice: {
            payment_term:
              this.orderDetails && this.orderDetails.length
                ? this.orderDetails[0].payment_term
                : null,
            description: 'Initial invoice for order #' + this.id
          },
          orderId: this.id
        };

        apiService.requestInitialPayment(data).then(
          data => {
            alertify.success('Invoice sent.');
            this.$router.push('/orders/' + this.id + '/invoices');
          },
          response => {
            if (
              response.body &&
              response.body.status == 'error' &&
              response.body.messages
            ) {
              response.body.messages.forEach(msg => {
                alertify.error(msg);
              });
            }
          }
        );
      });
    },

    designOptions(design) {
      let options = {};

      if (this.designs && this.orderDetailsOptions) {
        options = this.orderDetailsOptions[design.service];
        if (options instanceof Array) {
          options = {};
        }
      }

      return options;
    },
    doViewAction() {
      if (this.designScrollId) {
        setTimeout(() => {
          this.scrollTo(this.designScrollId);
        }, 0);
      }
    },
    unclaimBlank() {
      if (this.id) {
        this.$store.dispatch(UNCLAIM, {
          orderId: this.id,
          subject: 'blank',
          vue: this
        });
      }
    },
    unclaimMock() {
      if (this.id) {
        this.$store.dispatch(UNCLAIM, {
          orderId: this.id,
          subject: 'mock',
          vue: this
        });
      }
    },
    mockUpdateHandler(message) {
      // Update mock status and design mock progress.
      if (message.order_id && this.id == message.order_id && message.mock_id) {
        let mock_id = message.mock_id;
        apiService
          .getMock(mock_id)
          .then(data => {
            if (data.status === 'success') {
              let mock = data.mock;
              let design = data.mock.design;
              this.updateOrderDesignMockProgress({
                designId: design.id,
                progress: design.progress_mock
              });
              this.changeMockStatus({
                mock_id: mock.id,
                status: mock.status,
                designId: design.id
              });
              this.setOrderDesignPrintByAndShipByDates(design);
              this.setOrderPrintByAndShipByDates(message.order_id);
            } else {
              alertify.error(data.message);
            }
          })
          .catch(error => {
            alertify.error(error.message);
          });
      }
    },
    setOrderDesignPrintByAndShipByDates(design) {
      this.updateOrderDesignPrintByAndShipByDates({
        designId: design.id,
        dates: {
          print_by_date:
            (design.print_by_date &&
              moment(design.print_by_date).format('MM/DD/YY')) ||
            null,
          ship_by_date:
            (design.ship_by_date &&
              moment(design.ship_by_date).format('MM/DD/YY')) ||
            null,
          in_hands_date:
            (design.in_hands_date &&
              moment(design.in_hands_date).format('MM/DD/YY')) ||
            null
        }
      });
    },
    setOrderPrintByAndShipByDates(orderId) {
      apiService.getOrder(orderId).then(data => {
        let dates = {
          print_by_date:
            (data.data.print_by_date &&
              moment(data.data.print_by_date).format('MM/DD/YY')) ||
            null,
          ship_by_date:
            (data.data.ship_by_date &&
              moment(data.data.ship_by_date).format('MM/DD/YY')) ||
            null
        };
        this.updateOrderPrintByAndShipByDates({ orderId, dates });
      });
    },
    checkOids() {
      apiService
        .getOids()
        .then(data => {
          if (!includes(data, +this.id)) {
            alertify.error("You're not allowed to see the order.");
            this.$nextTick(() => {
              this.$router.push('/');
            });
          }
        })
        .catch(e => {
          alertify.error("You're not allowed to see the order.");
          this.$nextTick(() => {
            this.$router.push('/');
          });
        });
    },
    orderUpdateHandler(message) {
      if (message && +message.order_id === +this.id && message.action) {
        let action = JSON.parse(message.action);
        if (action.method && typeof this[action.method] === 'function') {
          this[action.method](action.args);
        }
      }
    },

    updateTrackingsFedEx({ orderId, designId }) {
      this.updateTrackings({ orderId, designId });
    },
    updateTrackingsUPS({ orderId, designId }) {
      this.updateTrackings({ orderId, designId });
    },
    updateTrackings({ orderId, designId }) {
      apiService.getShippingLabels(designId).then(trackings => {
        this.setDesignTrackings({
          orderId: orderId,
          designId: designId,
          trackings: trackings
        });
      });
    },
    updateDesignBlanksProgress(payload) {
      this.updateDesignBlankStatus(payload);
      this.updateOrderDesignProgress(payload);
    },
    fetchEstimateData() {
      return apiService
        .getEstimateData(this.id)
        .then(({ data }) => {
          this.data = data;
        })
        .catch(error => {
          console.error(error);
        });
    },
    fetchFormOptions(userId) {
      return apiService
        .get(
          `estimates/designs/form-options?${
            userId ? `filter[user_id]=${userId}` : ''
          }`
        )
        .then(({ data }) => {
          this.formOptions = this.convertFormOptions(data);
        });
    },
    fetchFinishingsPrices(userId) {
      return apiService.getApparelFinishingPrices(userId).then(response => {
        this.finishingsPrices = response;
      });
    },
    fetchShippingMethods() {
      return apiService.getShippingMethods().then(({ data }) => {
        const { ups } = data;
        this.shippingOptions = ups.services;
      });
    },
    convertFormOptions(data) {
      Object.keys(data).forEach(service => {
        if (!data[service].locations) return;
        const locationsRaw = data[service].locations;
        const locations = Object.keys(locationsRaw).map(groupKey => {
          return {
            title: groupKey,
            options: Object.keys(locationsRaw[groupKey]).map(childKey => {
              return {
                id: childKey,
                label: locationsRaw[groupKey][childKey]
              };
            })
          };
        });
        data[service].locations = locations;
      });
      return data;
    },
    initOrderDetails() {
      this.isLoading = true;
      Promise.all([
      !checkRoles(this.$store.getters.roles, ['printer'])?this.loadInvoices():null,
        this.fetchEstimateData(),
        this.fetchShippingMethods(),
        this.getInitData()
      ])
        .then(() =>
          Promise.all([
            this.fetchFormOptions(this.data.account.account_id),
            this.fetchFinishingsPrices(this.data.account.account_id)
          ])
        )
        .then(() => {
          this.isLoading = false;
        });

      this.loadOrder(this.id).then(() => {
        this.doViewAction();
        pubnubService.subscribeUpdateEvent('order', this.orderUpdateHandler);
        pubnubService.subscribeUpdateEvent('mock', this.mockUpdateHandler);
      });
    }
  },
  created() {
    if (checkRoles(this.$store.getters.roles, ['printer', 'customer'])) {
      this.checkOids();
    }
  },
  mounted() {
    this.initOrderDetails();
  },
  destroyed() {
    pubnubService.unsubscribeUpdateEvent('order');
    pubnubService.unsubscribeUpdateEvent('mock', this.mockUpdateHandler);
  }
};
</script>
