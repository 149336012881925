<template>
  <div class="item-finishing">
    <header class="step-section__subheader">
      <h4 class="step-section__subheader__title">Finishing {{ index + 1 }}</h4>
      <a href="#" class="clr-destructive"
        @click.prevent="onDelete"
        v-if="canDelete"
      >Delete Finishing {{ index + 1 }}</a>
    </header>
    <div class="step-section__body">
      <form-row class="fixed-3">
        <!-- Option -->
        <form-multiselect
          v-model="finishingOptionModel"
          :disabled="hasRedo"
          :options="orderFinishings"
          name="finishingOption"
          :scope="scope"
          :required="true"
          label="Finishing Option"
          :config="{
            trackBy: 'key'
          }"
        ></form-multiselect>
        <!-- Name -->
        <form-item
          class="x2"
          v-model="form.common.name"
          id="name"
          name="name"
          :required="true"
          label="Finishing Name"
          :scope="scope"
        ></form-item>
      </form-row>
      <template v-if="form.common.finishing_option">
        <!-- Only for 2 first options -->
        <form-row class="fixed-3">
          <!-- Tag Setups -->
          <form-item
            v-model="form.specific.tag_setups_number"
            :required="true"
            label="Unique Tag Setups"
            name="tagSetups"
            rules="numeric|required|min_value:1"
            :scope="scope"
            v-if="['tag-printing', 'heat-pressed-tags'].includes(form.common.finishing_option)"
          ></form-item>
        </form-row>

        <!-- Only for 2 first options -->
        <form-row v-if="['tag-printing', 'heat-pressed-tags'].includes(form.common.finishing_option)">
          <svg slot="icon" style="enable-background:new 0 0 50 50;" version="1.1" viewBox="0 0 50 50" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
            <path d="M0.586,34L16,49.414L49.414,16L34,0.586L0.586,34z M16,46.586L3.414,34L5.5,31.914l4.793,4.793l1.414-1.414L6.914,30.5 l3.586-3.586l2.793,2.793l1.414-1.414L11.914,25.5l3.586-3.586l4.793,4.793l1.414-1.414L16.914,20.5l3.586-3.586l2.793,2.793 l1.414-1.414L21.914,15.5l3.586-3.586l4.793,4.793l1.414-1.414L26.914,10.5L30.5,6.914l2.793,2.793l1.414-1.414L31.914,5.5 L34,3.414L46.586,16L16,46.586z"/>
          </svg>

          <!-- Width -->
          <form-item
            v-model="form.specific.tag_width"
            label="Width"
            name="width"
            :scope="scope"
            id="width"
            placeholder="Inches"
            style="max-width: calc(64px + 30px);"
            rules="min_value:0.00|decimal:2"
          ></form-item>

          <div class="form-item-text" style="align-self: center;">X</div>

          <!-- Height -->
          <form-item
            :scope="scope"
            v-model="form.specific.tag_height"
            label="Height"
            name="height"
            id="height"
            placeholder="Inches"
            style="max-width: calc(64px + 30px);"
            rules="min_value:0.00|decimal:2"
          ></form-item>

          <!-- Manufacturer Tag -->
          <div class="form-item-text" style="align-self: center;">
            <form-checkbox
              :scope="scope"
              name="remove_manufacturer_tag"
              v-model="form.specific.remove_manufacturer_tag"
            >Remove Manufacturer Tag</form-checkbox>
          </div>
        </form-row>

        <!-- Specific -->
        <form-row class="fixed-3" v-if="['hem-tags-fleece','hem-tag'].includes(form.common.finishing_option)">
          <!-- Location -->
          <form-multiselect
            v-model="hemTagModel"
            :options="hemLocations"
            :required="true"
            label="Hem Location"
            name="hemLocation"
            :scope="scope"
            :config="{
              trackBy: 'key'
            }"
          ></form-multiselect>
          <!-- Thread Color -->
          <form-item
            v-model="form.specific.hem_tag_thread_color"
            label="Hem Tag Thread Color"
            name="hemTagThreadColor"
            :scope="scope"
            :required="true"
          ></form-item>
        </form-row>
        <template v-if="['neck-tags-fleece','sewn-tag-neck-and-four-sided'].includes(form.common.finishing_option)">
          <div class="form-row">
            <ul class="list-radio">
              <li>
                <label class="form-radio">
                  <input
                    type="radio"
                    value="Remove Original Tag"
                    :name="`${form.uniqueId}-originalTagOptions`"
                    v-model="form.specific.sewn_tag_neck_tag_type"
                  >
                  <span class="form-radio__title">Remove Original Tag</span>
                </label>
              </li>
              <li>
                <label class="form-radio">
                  <input
                    type="radio"
                    value="Sew on Top of Original Tag"
                    :name="`${form.uniqueId}-originalTagOptions`"
                    v-model="form.specific.sewn_tag_neck_tag_type"
                  >
                  <span class="form-radio__title">Sew on Top of Original Tag</span>
                </label>
              </li>
              <li>
                <form-item
                  slot="input-after"
                  v-model="form.specific.sewn_tag_neck_tag_type"
                  type="hidden"
                  :scope="scope"
                  :required="!form.specific.sewn_tag_neck_tag_type"
                  :name="`${form.uniqueId}-originalTagOptions`"
                  :rules="form.specific.sewn_tag_neck_tag_type ? '' : 'required'"
                ></form-item>
              </li>
            </ul>
          </div>
          <div class="form-row">
            <form-item
              v-model="form.specific.sewn_tag_neck_tag_thread_color"
              label="Thread Color"
              placeholder="e.g. Black Thread"
              name="threadColor"
              :scope="scope"
              :required="true"
            ></form-item>
          </div>
        </template>

        <template v-if="form.common.finishing_option === 'woven-neck-tag'">
          <div class="form-row">
            <ul class="list-radio">
              <li>
                <label class="form-radio">
                  <input
                    type="radio"
                    value="Remove Original Tag"
                    name="originalTagOptions"
                    v-model="form.specific.woven_neck_tag_type"
                  >
                  <span class="form-radio__title">Remove Original Tag</span>
                </label>
              </li>
              <li>
                <label class="form-radio">
                  <input
                    type="radio"
                    value="Sew on Top of Original Tag"
                    name="originalTagOptions"
                    v-model="form.specific.woven_neck_tag_type"
                  >
                  <span class="form-radio__title">Sew on Top of Original Tag</span>
                </label>
              </li>
            </ul>
          </div>
          <div class="form-row">
            <form-item
              v-model="form.specific.woven_neck_tag_thread_color"
              label="Thread Color"
              placeholder="e.g. Black Thread"
              name="threadColor"
              :scope="scope"
              :required="true"
            ></form-item>
          </div>
        </template>

<!--        <template v-if="form.common.finishing_option === 'folding-bagging'">-->
<!--          <form-row class="fixed-3">-->
<!--            <form-multiselect-->
<!--              class="x2"-->
<!--              :required="true"-->
<!--              v-model="foldingTypeModel"-->
<!--              :options="foldingTypes"-->
<!--              label="Folding Option"-->
<!--              :scope="scope"-->
<!--              name="folding"-->
<!--            ></form-multiselect>-->
<!--          </form-row>-->
<!--        </template>-->

        <template v-if="form.common.finishing_option === 'hang-tag'">
          <form-row class="fixed-3">
            <!-- Location -->
            <form-multiselect
              v-model="hangTagModel"
              :options="hangLocations"
              :required="true"
              label="Hang Tag Placement"
              name="hangLocation"
              :scope="scope"
              :config="{
                trackBy: 'key'
              }"
            ></form-multiselect>
          </form-row>
        </template>

        <template>
          <form-row>
            <svg slot="icon" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
              <path d="M7 14c-1.66 0-3 1.34-3 3 0 1.31-1.16 2-2 2 .92 1.22 2.49 2 4 2 2.21 0 4-1.79 4-4 0-1.66-1.34-3-3-3zm13.71-9.37l-1.34-1.34c-.39-.39-1.02-.39-1.41 0L9 12.25 11.75 15l8.96-8.96c.39-.39.39-1.02 0-1.41z"/>
            </svg>
            <div class="form-item">
              <form-row v-if="finishableDesigns.length > 0">
                <div class="form-item">
                  <ul class="list-delivered-designs">
                    <li
                      v-for="item, index in form.items"
                      :key="`${item.design_id}${item.garment_id ? `-${item.garment_id}` : ''}`"
                      class="list-delivered-designs__item delivered-design"
                    >
                      <div class="delivered-design__header">
                        <h3 class="delivered-design__title">Design {{ getFinishingItem(item).index + 1 }} / {{ getFinishingItem(item).common.name }}</h3>
                        <a href="#" @click.prevent="removeDesign(index)" class="delivered-design__delete">
                          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                            <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"/>
                          </svg>
                        </a>
                      </div>
                      <div class="delivered-design__info">
                        <template v-if="item.garment_id">{{ getGarmentLabel(getFinishingItem(item).garment) }}</template>
                        <template v-else>{{ getFinishingItem(item).common.service }}</template>
                        <template>
                          <p>
                            Qty: <strong class="clr-primary1">{{ Object.values(item.sizes).reduce((acc, qty) => acc + +qty, 0) }}</strong>
                          </p>
                        </template>
                      </div>
                    </li>
                  </ul>
                </div>
              </form-row>

              <form-row class="fixed-5">
                <form-multiselect
                  class="dropdown-split-shipping x2"
                  :placeholder="false"
                  name="relatedDesignsGarments"
                  v-model="relatedDesignsModel"
                  :scope="scope"
                  :options="relatedDesignsGarmentsOptions"
                  label="Related Designs"
                  :disabled="hasRedo"
                  :hideLabel="parentForm.finishings.length > 1 && form.items.length > 0"
                  :required="true"
                  :config="{
                searchable: false,
                multiple: true,
                trackBy: 'id',
                allowEmpty: true
              }"
                >
                  <div slot="option" slot-scope="{ option }" class="dropdown-designs__option">
                    <header class="dropdown-designs__option__header">
                      <h5 class="dropdown-designs__option__title">
                        Design {{ option.index + 1 }} | {{ option.common.name }}
                      </h5>
                      <div class="dropdown-designs__option__sizes" v-if="option.garment">
                        <svg width="12px" height="12px" viewBox="0 0 13 13" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                          <path d="M2.93533664,11.0588499 L2.93533664,4.82316222 L2.47395253,5.6052742 L2.47435623,5.60549997 C2.42042441,5.69692713 2.30096927,5.72825251 2.20755354,5.67546968 C2.20225745,5.67247824 2.19717281,5.66927044 2.19228041,5.6658839 L0.0984494978,4.48276053 L0.195345922,4.31774207 L0.0976902248,4.48328739 C0.00427327354,4.43050433 -0.0277338694,4.31359361 0.0261977099,4.22216765 C0.0292542599,4.21698437 0.0325414972,4.21200803 0.0359921124,4.20721983 L1.97789029,0.915380607 L1.97735203,0.914637449 C2.00164101,0.873462758 2.0392135,0.844479691 2.08171668,0.829880038 L4.13294773,0.0143070316 L4.13286123,0.0141000764 C4.21973256,-0.0204333048 4.31717683,0.0106382799 4.36783084,0.0835899999 L6.25000035,2.55199752 L8.13722599,0.0769474947 L8.13710104,0.0768628312 C8.1934933,0.00290450872 8.29412871,-0.0197005579 8.37629988,0.0179653141 L10.4267424,0.833237258 L10.4270115,0.833105559 C10.4749165,0.852145416 10.5108936,0.887694766 10.5309245,0.930308588 L12.4730717,4.22257791 L12.4738022,4.222164 C12.527734,4.31359115 12.4957266,4.43050187 12.4023097,4.48328374 C12.3970232,4.48627519 12.3916406,4.48897501 12.3862003,4.49143025 L10.2920227,5.67475194 L10.2924456,5.67546688 C10.1990287,5.72824995 10.0795735,5.69692435 10.0256429,5.60549717 C10.0234034,5.60169672 10.0213272,5.59783984 10.0193761,5.59396413 L9.56466184,4.82316113 L9.56466184,11.0588488 C9.56466184,11.1644147 9.47721385,11.25 9.36935045,11.25 L3.13064067,11.25 C3.02277727,11.25 2.93532928,11.1644147 2.93532928,11.0588488 L2.93533664,11.0588499 Z"></path>
                        </svg>
                        <span v-html="getGarmentSizesLabel(option)"></span>
                      </div>
                    </header>
                    <div class="dropdown-designs__option__info">
                      <template v-if="option.garment_id">{{getGarmentLabel(option.garment)}}</template>
                      <template v-else>{{ option.common.service }}</template>
                    </div>
                  </div>
                  <template slot="noOptions">All designs are attached to finishing</template>
                  <span class="multiselect__single" slot="selection">Add Design to Finishing {{ index + 1 }}</span>
                </form-multiselect>
              </form-row>
              <!-- Quantity -->
              <template v-if="form.specific.tag_garments_quantity !== undefined">
                <form-item
                  v-if="['tag-printing', 'heat-pressed-tags'].includes(form.common.finishing_option)&&!hasRedo"
                  slot="input-after"
                  v-model="form.specific.tag_garments_quantity"
                  type="hidden"
                  :scope="scope"
                  :required="true"
                  name="quantity"
                  :rules="`finishing_min_qty:50,${finishingOptionModel.label}|required`"
                ></form-item>
                <form-item
                  v-else
                  slot="input-after"
                  v-model="form.specific.tag_garments_quantity"
                  type="hidden"
                  :scope="scope"
                  :required="true"
                  name="quantity"
                  :rules="`required`"
                ></form-item>
              </template>
            </div>
          </form-row>
        </template>

        <!-- Common -->
        <form-row>
          <div class="form-item ta-right">
            <span class="total-cost">
              <span class="total-cost__label">Total Qty</span>
              <span class="total-cost__value">{{ garmentsTotalSizesQty }}</span>
            </span>
          </div>
        </form-row>

        <form-row>
          <div class="form-item ta-right">
            <span class="total-cost">
              <span class="total-cost__label">Total Cost</span>
              <span class="total-cost__value">{{ total | price }}</span>
            </span>
          </div>
        </form-row>

        <form-row>
          <svg slot="icon" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <path d="M3.9 12c0-1.71 1.39-3.1 3.1-3.1h4V7H7c-2.76 0-5 2.24-5 5s2.24 5 5 5h4v-1.9H7c-1.71 0-3.1-1.39-3.1-3.1zM8 13h8v-2H8v2zm9-6h-4v1.9h4c1.71 0 3.1 1.39 3.1 3.1s-1.39 3.1-3.1 3.1h-4V17h4c2.76 0 5-2.24 5-5s-2.24-5-5-5z"/>
          </svg>
          <form-item
            label="Art Link"
            id="art"
            name="art"
            v-model="form.common.original_files_link"
            :scope="scope"
            :clearable="true"
            :rules="{
              url: {
                require_protocol: true
              }
            }"
          ></form-item>
        </form-row>
        <form-row>
          <svg slot="icon" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <path d="M20 2H4c-1.1 0-1.99.9-1.99 2L2 22l4-4h14c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zM6 9h12v2H6V9zm8 5H6v-2h8v2zm4-6H6V6h12v2z"/>
          </svg>
          <form-item
            label="Finishing Comment"
            id="finishing_comment"
            name="finishing_comment"
            type="textarea"
            v-model="form.common.comment"
            :scope="scope"
          ></form-item>
        </form-row>
      </template>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import { hemTagLocationsList, hangTagLocationsList } from '@/helpers/revision';
import { FORM_ESTIMATE_TEMPLATES } from '@/helpers/estimates';
import { finishingTotal } from '@/helpers/prices/finishings';
import { flatten, cloneDeep, uniqueId } from 'lodash';
import alertify from 'alertify.js';

export default {
  name: 'fieldset-finishing',
  props: {
    value: {
      required: true,
      type: Object
    },
    scope: {
      required: true,
      type: String
    },
    index: {
      required: true,
      type: Number
    },
    parentForm: {
      required: true,
      type: Object
    },
    finishingsPrices: {
      type: Object,
      required: true
    }
  },
  data() {
    const form = Object.assign({}, JSON.parse(JSON.stringify(this.value)));

    return {
      isDirty: false,
      form,

      hemLocations: hemTagLocationsList.slice(),
      hangLocations: hangTagLocationsList.slice(),
      // TODO - move to helpers
      foldingTypes: [
        {
          id: 'folding_only',
          label: 'I would like my garments folded',
          priceCode: 'folding_only'
        },
        {
          id: 'folding_and_bagging',
          label: 'I would like my garments folded & polybagged',
          priceCode: 'folding_and_bagging'
        },
        {
          id: 'folding_only_fleece',
          label: 'I would like my garments folded (Fleece)',
          priceCode: 'folding_only_fleece'
        },
        {
          id: 'folding_and_bagging_fleece',
          label: 'I would like my garments folded & polybagged (Fleece)',
          priceCode: 'folding_and_bagging_fleece'
        }
      ]
    };
  },
  computed: {
    ...mapGetters(['orderFinishings', 'sortedGarmentsSizes']),
    hasRedo() {
      return (
        this.relatedDesignsModel.length > 0 &&
        this.relatedDesignsModel.every(d => d.common.redo_design_id !== null)
      );
    },
    canDelete() {
      return (
        !!this.form.common.finishing_option ||
        this.isDirty ||
        this.parentForm.finishings.length > 1
      );
    },
    total() {
      return finishingTotal(this.form, this.finishingsPrices);
    },
    foldingTypeModel: {
      get() {
        console.log('folding model type', this.form.specific.folding_type);
        if (this.form.specific.folding_type === null) return null;
        return (
          this.foldingTypes.find(
            ({ label }) => this.form.specific.folding_type === label
          ) || null
        );
      },
      set({ label, priceCode }) {
        this.form.specific.folding_type = label;
        this.form.specific._folding_type = label;
        this.form.specific.price_code = priceCode;
      }
    },
    flattenGarments() {
      if (this.form.items.length === 0) return [];
      return flatten(
        this.form.items.map(item => {
          return this.parentForm.designs
            .find(design => design.common.uniqueId === item.design_id)
            .specific.garments.filter(
              garment => garment.uniqueId === item.garment_id
            );
        })
      );
    },
    relatedDesignsModel: {
      get() {
        if (this.form.items === null) return [];

        return this.finishingItems.filter(item => {
          return (
            this.form.items.filter(
              ({ garment_id }) => garment_id === item.garment_id
            ).length > 0
          );
        });
      },
      set(val) {
        if (!val) {
          this.form.items = [];
          return;
        }

        this.form.items = val.map(({ common, garment }) => ({
          design_id: common.uniqueId,
          garment_id: garment.uniqueId,
          sizes: garment.sizes
        }));
      }
    },
    hemTagModel: {
      get() {
        if (this.form.specific.hem_tag === null) return null;
        return (
          this.hemLocations.find(
            ({ key }) => this.form.specific.hem_tag === key
          ) || null
        );
      },
      set(option) {
        this.form.specific.hem_tag = option.key;
        this.form.specific._hem_tag = option.label;
      }
    },
    hangTagModel: {
      get() {
        if (this.form.specific.hang_tag === null) return null;
        return (
          this.hangLocations.find(
            ({ key }) => this.form.specific.hang_tag === key
          ) || null
        );
      },
      set(option) {
        this.form.specific.hang_tag = option.key;
        this.form.specific._hang_tag = option.label;
      }
    },
    finishingOptionModel: {
      get() {
        if (this.form.common.finishing_option === null) return null;
        return (
          this.orderFinishings.find(
            ({ key }) => this.form.common.finishing_option === key
          ) || null
        );
      },
      set(option) {
        this.form.common.finishing_option = option.key;
        this.form.common._finishing_option = option.label;
      }
    },
    qty() {
      return this.parentForm.finishings.length;
    },
    finishableDesigns() {
      return this.parentForm.designs.filter(design => {
        return ['apparel', 'embroidery'].includes(design.common.service);
      });
    },
    designs() {
      const options = this.finishableDesigns.slice();
      if (options.length !== this.relatedDesignsModel.length) {
        options.unshift({ uniqueId: 'all' });
      }
      return options;
    },
    garmentsTotalSizesQty() {
      if (!Array.isArray(this.form.items) || this.form.items.length === 0)
        return 0;

      const total = this.form.items.reduce((designGarmentsTotal, garment) => {
        return (
          designGarmentsTotal +
          Object.keys(garment.sizes).reduce(
            (garmentSizesTotal, size) =>
              garmentSizesTotal + +garment.sizes[size],
            0
          )
        );
      }, 0);

      return total;
    },
    finishingItems() {
      const options = [];
      this.finishableDesigns.forEach((design, index) => {
        if (design.specific.garments) {
          design.specific.garments.forEach(garment => {
            options.push({
              id: `${this.form.uniqueId}-${design.uniqueId}-${
                garment.uniqueId
              }`, // trackBy
              common: design.common,
              design_id: design.uniqueId,
              garment,
              garment_id: garment.uniqueId,
              index
            });
          });
        } else {
          options.push({
            id: `${this.form.uniqueId}-${design.uniqueId}`,
            common: design.common,
            design_id: design.uniqueId,
            index
          });
        }
      });
      return options;
    },
    finishedItems() {
      return this.form.items;
    },
    relatedDesignsGarmentsOptions() {
      return this.finishingItems.filter(finishingItem => {
        if (finishingItem.garment_id) {
          const sizesQty = Object.values(finishingItem.garment.sizes).reduce(
            (total, qty) => total + +qty,
            0
          );
          const attachedSizesQty = this.finishedItems
            .filter(item => item.garment_id === finishingItem.garment_id)
            .map(item => item.sizes)
            .reduce((total, itemSizes) => {
              return (
                total +
                Object.values(itemSizes).reduce(
                  (sizesTotal, sizeQty) => sizesTotal + +sizeQty,
                  0
                )
              );
            }, 0);

          const isNotAdded =
            this.form.items.findIndex(
              item => finishingItem.garment_id === item.garment_id
            ) === -1;

          if (isNotAdded) {
            return attachedSizesQty < sizesQty;
          }
          return false;
        }
        const isAdded = !!this.form.items.find(
          item => item.design_id === finishingItem.design_id
        );

        return !isAdded;
      });
    },

    relatedItemsModel: {
      get() {
        if (this.form.items === null) return null;
        if (this.form.items.length === 0) return null;

        // at least 1 design without garments is assigned
        if (this.form.items.find(item => item.garment_id === undefined))
          return true;

        // TODO check if at least one size item added
        return true;
      },
      set() {}
    }
  },
  watch: {
    form: {
      handler() {
        this.isDirty = true;
        this.$emit('input', this.form);
      },
      deep: true
    },
    'form.common.finishing_option': function(val, oldVal) {
      let valLabel;
      let valQty = 0;

      this.orderFinishings.forEach(({ key, label }) => {
        if (key === val) valLabel = label;
      });

      this.parentForm.finishings.forEach(({ common }) => {
        if (common.finishing_option === val) valQty += 1;
      });

      let name = valLabel;

      if (valQty > 1) {
        name += ` ${valQty}`;
      }

      // search for name duplicates
      let isDuplicate = false;
      do {
        isDuplicate = this.parentForm.finishings.find(
          ({ common }) => common.name === name
        );
        if (!isDuplicate) break;
        name = `${valLabel} ${valQty + 1}`;
      } while (isDuplicate);

      this.form.common.name = name;

      this.prepareSpecificFields(val);
    },
    'form.specific.tag_setups_number': function(val, old) {
      if (val !== old) {
        this.resetCost('tag_setups_number_cost');
      }
    },
    'form.specific.tag_garments_quantity': function(val, old) {
      if (val !== old) {
        this.resetCost();
      }
    },
    'form.specific.folding_type': function(val, old) {
      if (val !== old) {
        this.resetCost();
      }
    },
    'form.related_designs': {
      handler(relatedDesigns) {
        // if (
        //   !this.form.related_designs ||
        //   this.form.related_designs.length === 0
        // ) {
        //   return;
        // }

        if (this.form.specific.tag_setups_number !== undefined) {
          this.form.specific.tag_setups_number = this.calcTagSetups(
            this.flattenGarments
          );
        }
        this.setGarmentsQty();
      }
    },
    'form.items': {
      handler(items) {
        if (this.form.specific.tag_setups_number !== undefined) {
          this.form.specific.tag_setups_number = this.calcTagSetups(
            this.flattenGarments
          );
        }
        this.setGarmentsQty();
      },
      deep: true
    }
  },
  created() {
    /*if (this.form.related_designs.length) {
      this.setGarmentsQty();
    }*/
    this.setGarmentsQty();
  },
  methods: {
    resetCost(cost_field = 'tag_garments_quantity_cost') {
      if (this.hasRedo) return;
      if (this.form.specific[cost_field] !== undefined) {
        this.form.specific[cost_field] = null;
      }
    },
    setGarmentsQty() {
      if (this.form.specific.tag_garments_quantity !== undefined) {
        this.form.specific.tag_garments_quantity = this.calcTagQuantity(
          this.flattenGarments
        );
      }
    },
    prepareSpecificFields(option) {
      this.form.specific = cloneDeep(
        FORM_ESTIMATE_TEMPLATES.finishingSpecific[option]
      );

      /*this.form.related_designs = this.parentForm.designs
        .filter(
          design =>
            design.common.redo_design_id === null &&
            ['apparel', 'embroidery'].includes(design.common.service)
        )
        .map(design => design.common.uniqueId);*/

      if (!this.form.items || this.form.items.length === 0) {
        this.finishingItems.forEach(design => {
          this.onDesignSelect(design);
        });
      } else {
        if (this.form.specific.tag_setups_number !== undefined) {
          this.form.specific.tag_setups_number = this.calcTagSetups(
            this.flattenGarments
          );
        }
        this.setGarmentsQty();
      }
    },
    onDelete() {
      if (!this.form.common.finishing_option && !this.isDirty) {
        this.$emit('delete', this.index);
        return;
      }

      this.$mx_confirmModal({
        title: 'Are you sure?',
        text: 'If you proceed, data for this finishing will be lost.'
      })
        .then(() => {
          this.$emit('delete', this.index);
        })
        .catch(() => {});
    },
    calcTagQuantity(garments) {
      return garments.reduce(
        (total, garment) =>
          total +
          Object.keys(garment.sizes).reduce(
            (sizesTotal, size) => sizesTotal + +garment.sizes[size],
            0
          ),
        0
      );
    },
    calcTagSetups(garments) {
      let tagSetups = 0;

      const groupedGarments = {};

      garments.forEach(garment => {
        const key = garment.model.origin + '/' + garment.model.fabric;
        if (!groupedGarments[key]) groupedGarments[key] = {};
        Object.keys(garment.sizes).forEach(size => {
          if (+garment.sizes[size] === 0) return;
          if (!groupedGarments[key][size]) groupedGarments[key][size] = 0;
          groupedGarments[key][size] += +garment.sizes[size];
        });
      });

      tagSetups = Object.values(groupedGarments).reduce(
        (total, group) => total + Object.keys(group).length,
        0
      );

      return tagSetups;
    },
    getRelatedProductLabel(option) {
      if (!option.common) {
        // ALL option
        return 'All designs';
      }

      let label = option.common.name;

      const itemsQty = option.specific.garments.reduce(
        (total, garment) =>
          total +
          Object.keys(garment.sizes).reduce(
            (sizesTotal, sizeKey) => sizesTotal + +garment.sizes[sizeKey],
            0
          ),
        0
      );

      label += ` - ${itemsQty} items`;

      return label;
    },
    getFinishingItem({ design_id, garment_id }) {
      return this.finishingItems.find(finishingItem => {
        if (garment_id) return finishingItem.garment_id === garment_id;
        return finishingItem.design_id === design_id;
      });
    },
    getGarmentLabel(garment) {
      return `${garment.model.apparel_brand.name} ${garment.model.number} ${
        garment.model.name
      } (${garment.color}) `;
    },
    getGarmentSizesLabel({ garment_id, garment }) {
      const garmentQtyTotal = Object.values(garment.sizes).reduce(
        (total, qty) => total + +qty,
        0
      );
      /*const addedGarmentQty = flatten(
        this.parentForm.finishings.map(finishing => finishing.items)
      )
        .filter(item => item.garment_id === garment_id)
        .reduce(
          (total, item) =>
            total +
            Object.values(item.sizes).reduce(
              (itemTotal, sizeQty) => itemTotal + +sizeQty,
              0
            ),
          0
        );*/

      const notAddedGarmentQty = garmentQtyTotal /* - addedGarmentQty*/;
      let result = notAddedGarmentQty;
      if (notAddedGarmentQty < garmentQtyTotal) result += `/${garmentQtyTotal}`;
      // else result += `<em>/${garmentQtyTotal}</em>`;
      return result;
    },
    onDesignSelect(design) {
      const item = { design_id: design.design_id };
      if (design.garment_id) {
        item.garment_id = design.garment_id;
        item.sizes = {};

        Object.keys(design.garment.sizes).forEach(sizeKey => {
          if (+design.garment.sizes[sizeKey] === 0) return;
          item.sizes[sizeKey] = design.garment.sizes[sizeKey];
        });
      }
      if (!this.form.items) this.form.items = [];
      this.form.items.push(item);
    },
    removeDesign(index) {
      this.form.items.splice(index, 1);
    },
    onSizeBlur(event, sizeKey, sizes) {
      const value = event.target.value;
      if (value === '') {
        event.target.value = 0;
        sizes[sizeKey] = 0;
        return;
      }
      if (isNaN(parseFloat(value))) {
        event.target.value = 0;
        sizes[sizeKey] = 0;
        return;
      }
      event.target.value = (+value).toString();
      sizes[sizeKey] = (+value).toString();
    },
    onSizeKeyUp(event, sizeKey, item) {
      const { design_id, garment_id, sizes } = item;

      const garment = this.parentForm.designs
        .find(design => design.common.uniqueId === design_id)
        .specific.garments.find(garment => garment.uniqueId === garment_id);
      const maxSizeQty = +garment.sizes[sizeKey];

      const maxAvailableQty = maxSizeQty;
      const value = event.target.value;

      if (value === '') {
        event.target.value = 0;
        sizes[sizeKey] = 0;
        return;
      }

      if (value > maxAvailableQty) {
        event.target.value = maxAvailableQty;
        sizes[sizeKey] = maxAvailableQty;
        return;
      }
    },
    onSizeKeyPress(event) {
      const key = event.key;
      if (!/^\d*$/.test(key)) {
        event.preventDefault();
      }
    }
  }
};
</script>
