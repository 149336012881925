<template>
  <div class="page-content container section pt-3">
    <div>
      <div class="tabs is-boxed is-size-6" v-roles="['admin', 'csr', 'production-coordinator', 'purchaser', 'art-producer']">
        <ul data-tabs id="dashboard-tabs">
          <router-link tag="li" class="tabs-title" to="/reports/dashboard" v-roles="['admin','developer']"><a>Dashboard</a></router-link>
          <router-link tag="li" class="tabs-title" to="/reports/sales-tax" v-roles="['admin', 'csr', 'production-coordinator', 'purchaser', 'art-producer', 'developer']"><a>Sales Tax</a></router-link>
          <router-link tag="li" class="tabs-title" to="/reports/discounts" v-roles="['admin', 'csr', 'production-coordinator', 'purchaser', 'art-producer','printer', 'developer']"><a>Discounts</a></router-link>
          <router-link tag="li" class="tabs-title" to="/reports/rush-fees" v-roles="['admin', 'csr', 'production-coordinator', 'purchaser', 'art-producer','printer', 'developer']"><a>Rush Fees</a></router-link>
          <router-link tag="li" class="tabs-title" to="/reports/companies" v-roles="['admin', 'csr', 'production-coordinator', 'purchaser', 'art-producer', 'developer']"><a>Companies Report</a></router-link>
          <router-link tag="li" class="tabs-title" to="/reports/estimates" v-roles="['admin', 'csr', 'developer', 'production-coordinator', 'purchaser', 'art-producer']"><a>Estimates Report</a></router-link>
          <router-link tag="li" class="tabs-title" to="/reports/designs" v-roles="['admin']"><a>Designs Report</a></router-link>
          <router-link tag="li" class="tabs-title" to="/reports/production-and-purchasing" v-roles="['admin', 'csr', 'developer', 'production-coordinator', 'purchaser', 'art-producer']"><a>Orders Report</a></router-link>
          <router-link tag="li" class="tabs-title" to="/reports/clients" v-roles="['admin']"><a>Clients Report</a></router-link>
          <router-link tag="li" class="tabs-title" to="/reports/csr-conversions" v-roles="['admin','csr']"><a>CSR Conversions</a></router-link>
        </ul>
      </div>
      <section id="report">
        <router-view></router-view>
      </section>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';

export default {
  name: 'reports-tabs-index'
};
</script>
<style scoped>
.tabs {
  font-size: 0.9rem;
}
</style>
