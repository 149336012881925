<template>
  <tr>
    <td colspan="3">
      <invoice-field
        colspan="3"
        label="Name"
        :value="item.name"
      />
    </td>
    <td>
      <invoice-field
        label="Quantity"
        :value="item.quantity | intlNumber"
      />
    </td>
    <td>
      <invoice-field
        label="Price"
        :value="item.price | price"
      />
    </td>
  </tr>
</template>
<script>
import InvoiceField from '@/components/common/invoice-field.vue';

export default {
  name: 'InvoiceItem',
  components: {InvoiceField},
  props: {
    item: {
      type: Object,
      required: true
    },
    itemIndex: {
      type: Number,
      required: true
    },
    totalPrice:
    {
      type: String,
      required: true
    }
  },
}
</script>
