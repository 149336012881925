/**
 * Calculate design total.
 *
 * @param design
 * @param designData
 * @returns {number}
 */
export const getDesignTotal = (design, designData) => {
  let total = 0;
  let [designItem, product, size, quantity, customUnitPrice] = parseDesign(design, designData);

  if (!designItem || !size || !quantity) {
    total += 0;
  } else if (size === 'empty_size' && customUnitPrice){
    total += customUnitPrice * quantity;
  } else if (designItem.cost !== undefined && designItem.cost !== null) {
    total += designItem.cost;
  } else if (product && designItem && designData.upcharges[product].quantity_per_item_size) {
    let upchargeSizeObj = findUpcharge(designData, product, quantity, size);
    if (upchargeSizeObj !== undefined) {
      let upcharge = parseFloat(upchargeSizeObj.upcharge) || 0;
      total += upcharge * quantity;
      if (designItem.addons) {
        if (designItem.addons.pricing_formula === 'per_piece') {
          const addonUpcharge = parseFloat(designItem.addons.pricing_formula);
          upcharge += addonUpcharge * quantity;
        }
      }
    }
  }

  return total;
};

export const getUnitPrice = (design, designData) => {
  let unitPrice = 0;
  let [designItem, product, size, quantity, customUnitPrice] = parseDesign(design, designData);
  if (!designItem || !size || !quantity) {
    unitPrice = 0;
  } else if (size === 'empty_size' && customUnitPrice){
    unitPrice = customUnitPrice;
  } else if (designItem.cost !== undefined && designItem.cost !== null) {
    unitPrice = designItem.cost / quantity;
  } else if (product && designItem && designData.upcharges[product].quantity_per_item_size) {
    let upchargeSizeObj = findUpcharge(designData, product, quantity, size);
    if (upchargeSizeObj !== undefined) {
      let upcharge = parseFloat(upchargeSizeObj.upcharge) || 0;
      if (designItem.addons) {
        if (designItem.addons.pricing_formula === 'per_piece') {
          const addonUpcharge = parseFloat(designItem.addons.pricing_formula) || 0;
          upcharge += addonUpcharge;
        }
      }
      unitPrice = upcharge;
    }
  }

  return unitPrice;
}

function parseDesign(design, designData) {
  let designItem = design.specific.item;
  let product = designItem.product;
  let size = designItem.size ? designItem.size : null;
  let quantity = designItem.quantity;
  let customUnitPrice = designItem.custom_unit_price;
  return [designItem, product, size, quantity, customUnitPrice]
}

function findUpcharge(designData, product, quantity, size) {
  return designData.upcharges[product].quantity_per_item_size.filter(
    upcharge => {
      return (upcharge.size === size &&
        (
          upcharge.quantity.from <= quantity &&
          (upcharge.quantity.to >= quantity || upcharge.quantity.to === null)
        )
      );
    }
  )[0];
}
