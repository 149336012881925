<template>
  <div class="poster-design">
    <div class="columns">
      <div class="column is-4">
        <form-select v-model="design.item.number_of_colors"
          name="number_of_colors"
          label="Number of Colors"
          :scope="getScope(designKey)"
          :options="{all: colorNumbers}"
          :disabled="isDisabled" />
      </div>
      <div class="column is-4">
        <form-select v-model="design.item.size"
          name="size"
          label="Size of Poster"
          :scope="getScope(designKey)"
          :options="{all: sizes}"
          :disabled="isDisabled" />
      </div>
      <div class="column is-4" v-if="isCustomSizeSelected">
        <form-input v-model="design.item.custom_size"
          name="custom_size"
          label="Custom size"
          :scope="getScope(designKey)"
          rules="required"
          placeholder="Enter custom size (in inches)"
          :disabled="isDisabled" />
      </div>
    </div>
    <div class="columns">
      <div class="column is-4" v-if="isCustomSizeSelected">
        <form-input v-model="design.item.custom_unit_price"
          name="custom_unit_price"
          label="Unit cost"
          :scope="getScope(designKey)"
          type="number"
          rules="required|min_value:0.01"
          placeholder="Enter unit cost"
          :disabled="isDisabled" />
      </div>
      <div class="column is-4">
        <form-select v-model="design.item.quantity"
          name="quantity"
          label="Quantity"
          :scope="getScope(designKey)"
          :options="{all: quantities}" />
      </div>
    </div>
    <div class="columns">
      <div class="column is-4">
        <form-select v-model="design.item.brand"
          name="brand"
          label="Brand"
          @change="onChangeBrand"
          :disabled="loadingColors || isDisabled"
          :scope="getScope(designKey)"
          :options="{all: brands}" />
      </div>
      <div class="column is-4" v-if="isCustomPaperBrand">
        <form-input v-model="design.item.custom_brand"
          name="custom_brand"
          label="Custom paper brand"
          rules="required"
          :scope="getScope(designKey)"
          placeholder="Specify custom paper brand"
          :disabled="isDisabled" />
      </div>
    </div>
    <div class="columns">
      <div class="column is-4">
        <form-select v-model="design.item.paper_weight"
          name="paper_weight"
          label="Paper weight"
          @change="onChangePaperWeight"
          :disabled="loadingColors || isDisabled"
          :scope="getScope(designKey)"
          :options="{all: weights}" />
      </div>
      <div class="column is-4" v-if="!isCustomPaperBrand">
        <form-select v-model="design.item.paper_color"
          name="paper_color"
          label="Paper color"
          :isHierarchical="true"
          :disabled="loadingColors || isDisabled"
          :scope="getScope(designKey)"
          :options="{all: colors}" />
      </div>
      <div class="column is-4" v-else>
        <form-input v-model="design.item.custom_paper_color"
          name="custom_paper_color"
          label="Custom paper color"
          rules="required"
          :scope="getScope(designKey)"
          placeholder="Specify custom paper color"
          :disabled="isDisabled" />
      </div>
    </div>
    <div class="columns">
      <div class="column is-4">
        <form-select v-model="design.item.bleed_type"
          name="bleed_type"
          label="Bleed"
          :scope="getScope(designKey)"
          :options="{all: bleeds}"
          :disabled="isDisabled" />
      </div>
      <div class="column is-4">
        <form-select v-model="design.item.ink_type"
          name="ink_type"
          label="Inks"
          :scope="getScope()"
          :required="false"
          :rules="''"
          :options="{all: inkTypes}"
          :disabled="isDisabled" />
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import bus from '../../../bus';
import apiService from '../../../services/api.service';
import notify from '../../../modules/notifier/index';
import { isEmpty, forEach, merge, isNumber, cloneDeep } from 'lodash';

import formInput from '../../common/form/input';
import formSelect from '../../common/form/select';

let emptyDesignModel = {
  item: {
    number_of_colors: '',
    size: '',
    custom_size: '',
    custom_unit_price: '',
    quantity: '',
    brand: '',
    custom_brand: '',
    paper_weight: '',
    paper_color: '',
    custom_paper_color: '',
    bleed_type: '',
    ink_type: ''
  },
  service: {}
};

export default {
  props: {
    getScope: {
      type: Function,
      default: () => () => ''
    },
    designCommon: {
      type: Object,
      default: () => {}
    },
    designSpecific: Object,
    designKey: Number,
    isEmbroidery: Boolean,
    upsServices: Array,
    deliveryTypes: Array,
    resetKey: Number,
    accountId: Number,
    index: Number
  },
  data() {
    let design = cloneDeep(this.designSpecific);

    if (design.item && design.item.custom_size && design.item.size == null) {
      design.item.size = 'empty_size';
    }

    if (design.item && design.item.custom_brand && design.item.brand == null) {
      design.item.brand = 'empty_brand';
    }

    if (design.item && design.item.ink_type === null) {
      design.item.ink_type = '';
    }

    if (
      design.item &&
      design.item.custom_paper_color &&
      design.item.paper_color == null
    ) {
      design.item.paper_color = 'empty_paper_color';
    }

    return {
      type: 'poster',
      colorNumbers: [],
      sizes: [],
      quantities: [],
      brands: [],
      weights: [],
      colors: {},
      bleeds: [],
      inkTypes: [],
      summary: {},
      loadingColors: false,
      design: cloneDeep(Object.assign({}, emptyDesignModel, design))
    };
  },

  components: {
    formInput,
    formSelect
  },

  computed: {
    isCustomSizeSelected() {
      return this.design.item.size === 'empty_size';
    },
    isCustomPaperBrand() {
      return this.design.item.brand === 'empty_brand';
    },
    isDisabled() {
      return this.designCommon.printed_before_order_id ||
        this.designCommon.printed_before_design_id
        ? 'disabled'
        : false;
    }
  },

  watch: {
    designCommon: {
      handler(design, oldDesign) {
        this.calculateSummary();
      },
      deep: true
    },
    design: {
      handler(design, oldDesign) {
        this.calculateSummary();
      },
      deep: true
    },
    summary: {
      handler(summary, oldSummary) {
        this.$emit('summary-changed', this.designKey, summary);
      },
      deep: true
    },
    resetKey(key) {
      this.calculateSummary();
      if (key !== null && key !== undefined && key === this.designKey) {
        this.setEmpty();
      }
    }
  },

  methods: {
    /**
     * Calculate summary
     */
    calculateSummary() {
      let quantityOption = this.quantities.filter(
        qty => qty.key == this.design.item.quantity
      )[0];
      let sizeOption = this.sizes.filter(
        size => size.key == this.design.item.size
      )[0];
      let customSizeLabel = `${this.design.item.custom_size || '-'} (custom)`;
      let inkOption = this.inkTypes.filter(
        ink => ink.key == this.design.item.ink_type
      )[0];
      let numOfColorsOption = this.colorNumbers.filter(
        color => color.key == this.design.item.number_of_colors
      )[0];
      let paperColorLabel = this.getPaperColorNameById(
        this.design.item.paper_color
      );
      let customPaperColorLabel = `${this.design.item.custom_paper_color ||
        0} (custom)`;
      let paperBrandOption = this.brands.filter(
        brand => brand.key == this.design.item.brand
      )[0];
      let customPaperBrandLabel = `${this.design.item.custom_brand ||
        '-'} (custom)`;
      let bleedTypeOption = this.bleeds.filter(
        bleed => bleed.key == this.design.item.bleed_type
      )[0];
      let paperWeightOption = this.weights.filter(
        weight => weight.key == this.design.item.paper_weight
      )[0];

      let totalPrice = 0;
      let quantity = (quantityOption && parseInt(quantityOption.label)) || 0;
      let summary = {
        numOfColors: (numOfColorsOption && numOfColorsOption.label) || '',
        brand: this.isCustomPaperBrand
          ? customPaperBrandLabel
          : (paperBrandOption && paperBrandOption.label) || '',
        paperWeight: (paperWeightOption && paperWeightOption.label) || '',
        paperColor: this.isCustomPaperBrand
          ? customPaperColorLabel
          : paperColorLabel || '',
        bleedType: (bleedTypeOption && bleedTypeOption.label) || '',
        inkType: (inkOption && inkOption.label) || '',
        size: this.isCustomSizeSelected
          ? customSizeLabel
          : (sizeOption && sizeOption.label) || '',
        cost: 0,
        inkUpcharge: 0,
        type: this.designCommon.service,
        name: this.designCommon.name,
        printed_before: this.designCommon.printed_before,
        printed_before_order_id: this.designCommon.printed_before_order_id,
        printed_before_design_id: this.designCommon.printed_before_design_id,
        reference_order_id: this.designCommon.reference_order_id,
        reference_design_id: this.designCommon.reference_design_id,
        comment: this.designCommon.comment,
        type_of_delivery: this.designCommon.type_of_delivery,
        ship_by_date: this.designCommon.ship_by_date,
        print_by_date: this.designCommon.print_by_date,
        in_hands_date: this.designCommon.in_hands_date
      };

      if (!this.isCustomSizeSelected && sizeOption) {
        let colorNumUpcharges =
          sizeOption.color_num_upcharges[this.design.item.number_of_colors];
        let colorNumUpchargeRate = 0;
        let weightUpchargeRate =
          parseFloat(
            sizeOption.weight_upcharges[this.design.item.paper_weight]
          ) || 0;

        if (colorNumUpcharges) {
          let rangeRate = Object.keys(colorNumUpcharges)
            .filter(range => {
              let adges = range.toString().split('-');
              let from = Number(adges[0]);
              let to = Number(adges[1]);

              return !isNaN(to)
                ? quantity >= from && quantity <= to
                : quantity >= from;
            })
            .map(range => {
              return parseFloat(colorNumUpcharges[range]);
            })[0];

          colorNumUpchargeRate = rangeRate || colorNumUpchargeRate;
        }

        totalPrice = quantity * (colorNumUpchargeRate + weightUpchargeRate);

        summary.cost = colorNumUpchargeRate + weightUpchargeRate;
        summary.cost = this.toFixed(summary.cost);
      } else if (this.isCustomSizeSelected) {
        summary.cost = +this.design.item.custom_unit_price;
        totalPrice = quantity * +this.design.item.custom_unit_price;
      }

      if (inkOption && inkOption.upcharge) {
        let inkUpcharge = parseFloat(inkOption.upcharge);
        totalPrice += inkUpcharge;
        summary.inkUpcharge = inkUpcharge;
      }

      if (quantity) {
        summary.total = {
          price: this.toFixed(Math.round(totalPrice * 100) / 100),
          qty: quantity,
          upcharge: Math.round(this.designCommon.upcharge * 100) / 100,
          upcharge_description: this.designCommon.upcharge_description
        };
      }

      this.$set(this, 'summary', summary);
    },

    toFixed(number, digits = 2) {
      return +number.toFixed(digits);
    },

    getPaperColorNameById(colorId) {
      let colors = this.colors;
      for (let key in colors) {
        if (!Object.hasOwnProperty.call(colors, key)) continue;
        if (colorId in colors[key]) {
          return colors[key][colorId];
        }
      }
      return null;
    },

    getDesignData(user_id) {
      return apiService.getDesignFormOptions(this.type, user_id).then(
        data => {
          let sourceData = data.form_data;

          this.$set(this, 'colorNumbers', sourceData.color_numbers);
          this.$set(this, 'weights', sourceData.weights);
          this.$set(this, 'bleeds', sourceData.bleed);
          this.$set(this, 'brands', sourceData.brands);
          this.$set(this, 'quantities', sourceData.quantities);
          this.$set(this, 'sizes', sourceData.sizes);
          this.$set(this, 'inkTypes', sourceData.ink_types);
        },
        response => {
          notify({
            message: 'Could not load info for the design',
            type: 'danger'
          });
        }
      );
    },

    onValidate(validateScope) {
      if (validateScope) {
        this.$validator.validateAll(validateScope).catch(err => {});
      } else {
        this.$validator.validateScopes().catch(err => {});
      }
    },

    onClear() {
      this.errors.clear();
    },

    onChangeBrand() {
      this.onChangeBrandOrWeight();
    },

    onChangePaperWeight() {
      this.onChangeBrandOrWeight();
    },

    onChangeBrandOrWeight(skipResetting) {
      if (
        this.isCustomPaperBrand ||
        !this.design.item.brand ||
        !this.design.item.paper_weight
      ) {
        return Promise.resolve();
      }
      if (!skipResetting) {
        this.$set(this.design.item, 'paper_color', '');
      }
      this.loadingColors = true;
      return apiService
        .getPosterPaperColors(
          this.design.item.brand,
          this.design.item.paper_weight
        )
        .then(
          data => {
            this.$set(this, 'colors', data);
            this.loadingColors = false;
            return data;
          },
          error => {
            notify({
              message: 'Could not load paper colors',
              type: 'danger'
            });
            this.loadingColors = false;
          }
        );
    },

    setEmpty() {
      this.$set(this, 'design', merge({}, emptyDesignModel));
    },

    setDesign() {
      return {
        key: this.designKey,
        design: this.design
      };
    },

    onMountData(user_id) {
      this.getDesignData(user_id).then(() => {
        this.calculateSummary();
      });
      this.onChangeBrandOrWeight(true).then(() => {
        this.calculateSummary();
      });
    }
  },
  created() {
    bus.$on('validate', this.onValidate);
    bus.$on('clear', this.onClear);
    this.$watch(
      () => this.errors.errors,
      value => {
        bus.$emit('errors-changed', value);
      }
    );
  },
  mounted() {
    if (isNumber(this.resetKey) && this.resetKey === this.designKey) {
      this.setEmpty();
    }

    this.onMountData(this.accountId);

    bus.$on('order-set-account-info', user_id => {
      this.onMountData(user_id);
    });
  },
  destroyed() {
    bus.$off('order-set-account-info');
  }
};
</script>
