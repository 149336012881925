<template>
  <div v-if="data" class="columns">
    <div class="column">
      <strong>{{label}}</strong>
      <div class="columns is-marginless is-paddingless">
        <div class="column is-marginless is-paddingless">
          <div class="control">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { cloneDeep } from 'lodash';

export default {
  name: 'four-sided-tag',
  props: {
    finishing: {
      type: Object,
      required: true
    },
    label: {
      type: String,
      default: '4 Sided Tags'
    }
  },
  components: {},
  data() {
    return {
      data: (this.finishing && cloneDeep(this.finishing)) || {}
    };
  },
  methods: {
    getFormData() {
      return this.data;
    }
  }
};
</script>
<style>
</style>
