<template>
  <div>
    <li>
      <label>Category</label>
      <strong>{{ getOptionLabel(item.print_type, formOptions.print_types) }}</strong>
    </li>
    <li v-if="item.product_type">
      <label>Product Type</label>
      <strong>{{
          getOptionLabel(item.product_type, formOptions.print_type_options[item.print_type].product_types)
        }}</strong>
    </li>
    <li v-if="item.paper_type">
      <label v-if="getOptionLabel(item.print_type, formOptions.print_types)==='Hang Tags'">Paper Type</label>
      <label v-else-if="getOptionLabel(item.print_type, formOptions.print_types)==='Patches'">Backing</label>
      <label v-else>Folding Type</label>
      <strong>{{
          getOptionLabel(item.paper_type, formOptions.print_type_options[item.print_type].paper_types)
        }}</strong>
    </li>
    <li>
      <label>Size</label>
      <strong>
        <template v-if="item.custom_width && item.custom_height">{{ item.custom_width }}x{{
            item.custom_height
          }}
        </template>
        <template v-else>{{
            getOptionLabel(item.size, formOptions.print_type_options[item.print_type].sizes)
          }}
        </template>
      </strong>
    </li>
    <li v-if="item.border">
      <label>Borders</label>
      <strong>{{ getOptionLabel(item.border, formOptions.print_type_options[item.print_type].borders) }}</strong>
    </li>
    <li>
      <label>Quantity</label>
      <strong v-if="item.product_type &&
      ['Woven Patch','Printed Patch'].includes( getOptionLabel(item.product_type, formOptions.print_type_options[item.print_type].product_types))">
        {{ item.quantity }}
      </strong>
      <strong v-else>{{
          getOptionLabel(item.quantity, formOptions.print_type_options[item.print_type].quantities)
        }}</strong>
    </li>
    <li v-if="getOptionLabel(item.print_type, formOptions.print_types)==='Hang Tags'">
      <label>Rounded Corners</label>
      <strong>{{ item.has_rounded_corners ? 'Yes' : 'No' }}</strong>
    </li>
    <li
      v-if="getOptionLabel(item.print_type, formOptions.print_types)==='Patches' &&  getOptionLabel(item.product_type, formOptions.print_type_options[item.print_type].product_types) !== 'Faux Leather Patch'">
      <label>Button Loops</label>
      <strong>{{ item.button_loops ? 'Yes' : 'No' }}</strong>
    </li>
    <li
      v-if="getOptionLabel(item.print_type, formOptions.print_types)==='Patches' &&  getOptionLabel(item.product_type, formOptions.print_type_options[item.print_type].product_types) !== 'Faux Leather Patch'">
      <label>Extra Colors 9+</label>
      <strong>{{ item.extra_colors ? 'Yes' : 'No' }}</strong>
    </li>
    <li v-roles="['admin', 'csr', 'production-coordinator', 'purchaser', 'art-producer', 'developer', 'customer']">
      <label v-if="item.custom_unit_price && item.custom_unit_price.length">Unit Price(Custom)</label>
      <label v-else>Unit Price</label>
      <strong>{{ getUnitPrice(item, formOptions)| price }}</strong>
    </li>
  </div>
</template>
<script>
import {getOptionLabel} from '@/helpers/estimates';
import {getUnitPrice} from '@/helpers/prices/finishings_creation';

export default {
  name: 'FinishingCreationItem',
  props: {
    item: {
      type: Object,
      required: true
    },
    formOptions: {
      type: Object,
      required: true
    }
  },
  computed: {},
  methods: {
    getOptionLabel,
    getUnitPrice
  }
};
</script>
