<template>
  <div>
    <div class="content">
      <div class="container">
        <nav class="level">
          <div class="level-left">
            <div class="level-item">
              <h3>Custom Prices {{company.name}}</h3>
            </div>
          </div>
          <div class="level-right">
            <p class="level-item"
               v-roles="['admin', 'csr', 'production-coordinator', 'purchaser', 'art-producer']"
               v-if="customerPrices.length > 0">
              <button class="button btn__delete"
                      @click="restoreToDefault">Restore to default
              </button>
            </p>
          </div>
        </nav>

        <table v-if="customerPrices.length" class="table is-fullwidth">
          <thead>
          <tr>
            <th>Design Type</th>
            <th>Items</th>
            <th>Price</th>
            <th width="60px"></th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(entry, rowKey) in customerPrices">
            <th>{{entry.service}}</th>
            <td>{{entry.items}}</td>
            <td>{{entry.price}}</td>
            <td>
              <p class="level-item"
                 v-roles="['admin', 'csr', 'production-coordinator', 'purchaser', 'art-producer']">
                <button class="button btn__delete"
                        @click="deleteItem(entry.id)">Delete
                </button>
              </p>
            </td>
          </tr>
          </tbody>
        </table>

        <paginator v-if="customerPrices.length"
                   :pagesNumber="pagesNumber"
                   :prevPageUrl="prevPageUrl"
                   :nextPageUrl="nextPageUrl"
                   :currentPage="currentPage"
                   :getListItems="getCompanyPricesList"></paginator>
      </div>
    </div>

    <div class="content box">
      <h3>Add Company Price</h3>

      <fieldset class="billing-address control">
        <h5>Select Design Type</h5>
        <div class="columns">
          <div class="column is-4">
            <form-select v-model="selected_service"
                         :scope="formScope"
                         name="design_services"
                         label="Design Type"
                         :options="{all: servicesOptionsAll}"
                         :addEmpty="false"
                         :required="false"/>
          </div>
        </div>
      </fieldset>

      <component :is="`custom-prices-${selected_service}`"
                 :formScope="formScope"
                 v-bind:key="forUpdateComp"></component>
    </div>
  </div>
</template>

<style>
.content .section {
  padding: 0;
  margin: 40px auto;
}
</style>

<script>
import Vue from 'vue';
import apiService from '../../../services/api.service';
import formInput from '../../common/form/input';
import formSelect from '../../common/form/select';
import notify from '../../../modules/notifier/index';
import bus from '../../../bus';
import alertify from 'alertify.js';
import paginator from '../../common/paginator';

import customPricesApparel from '../custom-price-design-types/custom-prices-apparel';
import customPricesEmbroidery from '../custom-price-design-types/custom-prices-embroidery';
import customPricesButton from '../custom-price-design-types/custom-prices-button';
import customPricesDigital from '../custom-price-design-types/custom-prices-digital';
import customPricesOther from '../custom-price-design-types/custom-prices-other';
import customPricesPoster from '../custom-price-design-types/custom-prices-poster';
import customPricesSticker from '../custom-price-design-types/custom-prices-sticker';

export default {
  name: 'companies-custom-prices',
  components: {
    formSelect,
    formInput,
    customPricesApparel,
    customPricesEmbroidery,
    customPricesButton,
    customPricesDigital,
    customPricesOther,
    customPricesPoster,
    customPricesSticker,
    paginator
  },
  data() {
    return {
      servicesOptionsAll: [],
      selected_service: 'apparel',
      formScope: 'custom-prices',
      customerPrices: [],
      prevPageUrl: null,
      nextPageUrl: null,
      currentPage: 1,
      pagesNumber: 1,
      company: {},
      forUpdateComp: 1
    };
  },
  computed: {
    companyId() {
      return this.$route.params.companyId;
    }
  },
  methods: {
    getServicesList() {
      return apiService.getOrderServices().then(data => {
        this.servicesOptionsAll = data.filter(
          service => service.key != 'other' && service.custom_prices === true
        );
      });
    },

    getCompanyPricesList(page, url) {
      if (page === undefined) page = 1;

      if (url === undefined)
        url = `/api/company_prices/prices/${this.companyId}?page=${page}`;

      if (this.searchQuery) {
        url +=
          url.indexOf('?') == -1
            ? '?q=' + this.searchQuery
            : '&q=' + this.searchQuery;
      }

      return this.$http.get(url).then(
        res => {
          this.customerPrices = res.data.data.prices;
          this.prevPageUrl = res.data.data.prev_page_url;
          this.nextPageUrl = res.data.data.next_page_url;
          this.currentPage = res.data.data.current_page;
          this.pagesNumber = res.data.data.last_page;
        },
        err => {
          notify({
            message: 'Could not load prices',
            type: 'danger'
          });
        }
      );
    },

    deleteItem(item_id) {
      return apiService.deleteCustomPrice(item_id).then(
        res => {
          this.forUpdateComp += 1;

          notify({
            message: res.message,
            type: 'success'
          });

          this.getCompanyPricesList(this.currentPage);
          bus.$emit(res.service + '-customer-prices-updated');
        },
        err => {
          notify({
            message: 'Could not delete item price',
            type: 'danger'
          });
        }
      );
    },

    restoreToDefault() {
      alertify
        .okBtn('Restore')
        .confirm(
          'Are you sure that you want to restore custome prices?',
          ev => {
            return apiService.removeCompanyCustomPrices(this.companyId).then(
              data => {
                if (data.error) {
                  alertify.error(data.message);
                } else {
                  this.customerPrices = [];
                  this.pagesNumber = 1;
                  this.currentPage = 1;
                  bus.$emit('resetPrices');
                  alertify.success(data.message);
                }
              },
              response => {
                alertify.error('Could not restore prices');
              }
            );
          }
        );
    },

    getCompany() {
      return apiService.getCompany(this.companyId).then(data => {
        this.company = data.company;
      });
    }
  },
  mounted() {
    this.getCompany();
    this.getServicesList();
    this.getCompanyPricesList();
  },
  created() {
    bus.$on('updated-customer-prices-for-design', () => {
      this.getCompanyPricesList(this.currentPage);
    });
  }
};
</script>
