<template>
  <form @submit.prevent="submitForm" class="box">
    <account :model="model"
      :roles="roles"
      :options="options"></account>
    <component :is="getComponentNameByRole(model)"
               :model="model"
               @accountChanged="accountChanged"
               :options="options"></component>
    <br>
    <div class="control control-form-actions"
         v-if="canMarkAsTest">
      <label for="test-account-option">
        <input id="test-account-option"
               type="checkbox"
               v-model="model.user.is_test"/>
        Check if you want to mark this account as a test.
      </label>

    </div>
    <br>
    <div class="control control-form-actions" v-if="!hideSubmitBtn">
      <button type="submit"
        :class="{'is-loading': processing, 'button is-primary': true} "
        :disabled="processing">{{options.submitBtnText}}</button>
    </div>

  </form>
</template>

<script>
import Vue from 'vue';
import { mapGetters } from 'vuex';

//form
import bus from '../../../bus';
import notify from '../../../modules/notifier';

import account from './account-fields';
import customer from './customer-fields';
import printer from './printer-fields';

import { checkRoles } from '../../../router/checkRoles';

export default {
  name: 'account-form',
  components: {
    account,
    customer,
    printer
  },
  props: {
    options: {
      type: Object
    },
    roles: {
      type: Array
    },
    model: {
      type: Object
    },
    processing: {
      type: Boolean
    },
    errorsList: {
      type: Array,
      default: []
    },
    hideSubmitBtn: {
      type: Boolean
    }
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(['isTestAccount']),
    canMarkAsTest() {
      return (
        (this.isTestAccount &&
          checkRoles(this.$store.getters.roles, ['admin', 'csr'])) ||
        checkRoles(this.$store.getters.roles, ['admin'])
      );
    }
  },
  watch: {
    errorsList(errors) {
      if (errors && errors.length) {
        errors.forEach(error => {
          if (error) {
            bus.$emit('add-error', {
              field: error.field,
              msg: error.msg,
              rule: error.rule,
              scope: error.scope,
              fullName: error.fullName
            });
          }
        });
      }
    }
  },
  methods: {
    getComponentNameByRole(account) {
      return account &&
        account.role &&
        (account.role == 'customer' || account.role == 'printer')
        ? account.role
        : false;
    },
    accountChanged(account) {
      this.$emit('accountChanged', account);
    },
    validateBeforeSubmit(e) {
      this.clearChild();
      this.errors.errors = [];
      this.validateChild();
      return new Promise((resolve, reject) => {
        Vue.nextTick(() => {
          resolve(!this.errors.count());
        });
      });
    },
    submitForm() {
      this.validateBeforeSubmit().then(isValid => {
        if (isValid) {
          this.$emit('submitForm');
        } else {
          notify({
            message: 'Validation failed. Please check error messages',
            type: 'danger'
          });
        }
      });
    },
    validateChild() {
      bus.$emit('validate', 'account-form');
      bus.$emit('validate', 'printer');
      bus.$emit('validate', 'shipping_address');
      bus.$emit('validate', 'billing_address');
      bus.$emit('validate', 'company-form');
    },
    clearChild() {
      bus.$emit('clear');
    }
  },
  created() {
    bus.$on('validateAndSubmit', () => {
      this.submitForm();
    });

    bus.$on('errors-changed', errors => {
      if (errors) {
        errors.forEach(e => {
          this.errors.add(e.field, e.msg, e.rule, e.scope);
        });
      }
    });

    bus.$on('errors-deleted', oldErrors => {
      if (oldErrors) {
        oldErrors.forEach(e => {
          this.errors.remove(e.field, e.scope);
        });
      }
    });
  }
};
</script>
