<template>
  <div class="content">
    <div v-if="finishing.name">
      <strong>Finishing:</strong> {{finishing.name || finishing.label}}
    </div>
    <div><strong>{{finishing.label}}:</strong>
      <span v-roles="['admin', 'csr', 'production-coordinator', 'purchaser', 'art-producer', 'customer']">
        {{finishing.price | price}}
      </span>
    </div>
    <table class="table table__without-hover">
      <tr>
        <td>Type</td>
        <td :class="{'align-right': this.customerView}">{{finishing.fields.four_sided_tag_type}}</td>
      </tr>
      <tr v-if="finishing.fields.four_sided_tag_thread_color != ''">
        <td>Thread Color</td>
        <td :class="{'align-right': this.customerView}">{{finishing.fields.four_sided_tag_thread_color}}</td>
      </tr>
    </table>
  </div>
</template>

<script>
import Vue from 'vue';

export default {
  props: ['finishing', 'customerView'],
  name: 'finishing-summary-four-sided-tag'
};
</script>
