<template>
  <div class="columns"
       v-roles="['admin', 'csr', 'production-coordinator', 'purchaser', 'art-producer']">
    <div class="column manage-files">
      <div class="control">
        <h5 class="subtitle is-5">
          {{this.additional.modelType | capitalize}}
          name: {{ model.name }}
        </h5>
      </div>
      <manage-files-box
        :itemId="additional.itemId"
        :orderId="additional.orderId"
        :model="model"
        :modelType="additional.modelType"
        @onFilesLinksUpdated="onSubmit"></manage-files-box>
    </div>
  </div>
</template>

<style scoped>
  .progress-loader {
    padding: 7px 0;
  }
</style>

<script>
  import Vue from 'vue';
  import apiService from '../../../services/api.service';
  import notify from '../../../modules/notifier';
  import ManageFilesBox from '../manage-files-box';
  import Xml from '../../../helpers/xml-helper';
  import {
    forEach
  } from 'lodash';

  export default {
    name: 'manage-files',
    props: ['additional'],
    components: {
      ManageFilesBox
    },
    data() {
      return {
        model: {},
      }
    },
    filters: {
      capitalize(str) {
        return str.charAt(0).toUpperCase() + str.slice(1)
      }
    },
    methods: {
      onSubmit(data) {
        if (this.additional.onUpdateFilesLinks) {
          this.additional.onUpdateFilesLinks(data);
        }
        this.closePopup();
      },
      closePopup() {
        this.$emit('close');
      },
      getFiles() {
        return apiService.getAttachments(this.additional.itemId, this.additional.modelType)
          .then(data => {
            this.model = data;
          });
      }
    },
    mounted() {
      this.getFiles();
    }
  };
</script>
