<template>
  <div id="create-password-form">
    <div class="container hero is-medium hero">
      <div class="hero-body">
        <div class="container">
          <div class="columns is-vcentered">
            <div class="column is-4 is-offset-4">
              <div>
                <form class="box">
                  <h5 class="title is-4">Create New Password</h5>
                  <h5 class="subtitle is-6">Let's start by creating a password that you will remember when you come
                    back!</h5>
                  <div class="columns">
                    <div class="column">
                      <div class="field">
                        <p class="control has-icons-right">
                          <form-input :type="passwordFieldType"
                                      v-model="password_data.password"
                                      name="password" scope="change-password-form"
                                      placeholder="●●●●●●●"
                                      :required="true"
                                      rules="required" label="New Password">
                        <span slot="input-after" class="icon is-medium is-right" @click="showPass = !showPass">
                          <i :class="[
                                showPass ? 'ff-eye-disabled' : 'ff-eye'
                              ]"></i>
                        </span>
                          </form-input>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="columns">
                    <div class="column">
                      <div class="field">
                        <p class="control has-icons-right">
                          <form-input :type="passwordConfirmFieldType"
                                      v-model="password_data.password_confirmation"
                                      name="password_confirmation"
                                      placeholder="●●●●●●●"
                                      :required="true"
                                      scope="change-password-form" rules="required"
                                      label="Confirm Password">
                       <span slot="input-after" class="icon is-medium is-right"
                             @click="showPassConfirm = !showPassConfirm">
                          <i :class="[
                                showPassConfirm ? 'ff-eye-disabled' : 'ff-eye'
                              ]"></i>
                        </span>
                          </form-input>
                        </p>
                      </div>
                    </div>
                  </div>
                  <p class="control">
                    <button @click.prevent="attempt"
                            :class="{'is-loading': processing, 'button is-primary': true} "
                            :disabled="processing">Submit
                    </button>
                    <button @click.prevent="cancel"
                            :class="{'is-loading': processing, 'button': true} "
                            :disabled="processing">Cancel
                    </button>
                  </p>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <v-dialog :click-to-close="false" @opened="showThankYouPopupOpened()"></v-dialog>
  </div>
</template>
<style lang="scss">
#create-password-form {
  .hero {
    background-color: unset;

    .icon.is-right {
      top: 20px;
      right: 0;
      cursor: pointer;
      pointer-events: auto;
    }
  }
}
</style>
<script>
import Vue from 'vue';
import { mapGetters } from 'vuex';
import alertify from 'alertify.js';
import bus from '../../bus';

import formInput from '../common/form/FormItem';
import VeeValidate from 'vee-validate';

Vue.use(VeeValidate);

import { CREATE_PASSWORD } from '../../store/action-types';
import { checkRoles } from '../../router/checkRoles';

export default {
  name: 'create-password-form',
  watch: {
    userId(userId) {
      this.checkAccess(userId);
    },
    passwordFieldType() {},
    passwordConfirmFieldType() {}
  },
  data() {
    return {
      showPass: false,
      showPassConfirm: false,
      password_data: {
        password: null,
        password_confirmation: null
      },
      messages: [],
      processing: false
    };
  },
  computed: {
    ...mapGetters(['userProfile']),
    userId() {
      return this.$route.params.userId;
    },
    dashboardLink() {
      return !!checkRoles(this.$store.getters.roles, ['customer'])
        ? '/customer/dashboard'
        : '/dashboard';
    },
    passwordFieldType() {
      return this.showPass ? 'text' : 'password';
    },
    passwordConfirmFieldType() {
      return this.showPassConfirm ? 'text' : 'password';
    }
  },
  components: {
    formInput
  },
  mounted() {
    this.checkAccess(this.userId);
  },
  methods: {
    redirectToDashboard() {
      this.$router.push(this.dashboardLink);
    },
    showThankYouPopupOpened() {
      setTimeout(() => {
        this.$modal.hide('dialog');
        this.$nextTick(() => {
          this.redirectToDashboard();
        });
      }, 5000);
    },
    showThankYouPopup() {
      this.$mx_alertModal({
        title: 'Thank You!',
        text:
          'Thank you for creating a new Password! <br> ' +
          'You will be redirected to your Dasboard in <strong>5 sec</strong>. <br>' +
          "Or let's head over to your Dashboard by clicking the button below!",
        okText: 'Go To Dashboard'
      })
        .then(() => {
          this.redirectToDashboard();
        })
        .catch(() => {});
    },
    checkAccess(userId) {
      if (+userId !== +this.userProfile.id) {
        alertify.error("You're not allowed to do this action");
        this.$router.push('/');
      }
    },
    cancel() {
      this.redirectToDashboard();
    },
    validateBeforeSubmit(e) {
      this.clearErrors();
      this.validateChild();

      return new Promise((resolve, reject) => {
        Vue.nextTick(() => {
          resolve(!this.errors.count());
        });
      });
    },

    validateChild() {
      bus.$emit('validate', 'change-password-form');
    },
    clearErrors() {
      bus.$emit('clear');
      this.errors.errors = [];
    },
    attempt: function(e) {
      this.validateBeforeSubmit().then(isValid => {
        if (isValid) {
          this.messages = [];
          this.processing = true;
          this.$store
            .dispatch(CREATE_PASSWORD, this.password_data)
            .then(data => {
              this.processing = false;
              this.showThankYouPopup();
            })
            .catch(response => {
              this.processing = false;

              if (
                response.body &&
                response.body.messages &&
                response.body.messages.length
              ) {
                response.body.messages.forEach(function(message) {
                  alertify.error(message);
                });
              } else {
                alertify.error('Something wrong :(');
              }
            });
        }
      });
    },
    initBusEvents() {
      bus.$on('errors-changed', errors => {
        if (errors) {
          errors.forEach(e => {
            this.errors.add(e.field, e.msg, e.rule, e.scope);
          });
        }
      });
      bus.$on('errors-deleted', oldErrors => {
        if (oldErrors) {
          oldErrors.forEach(e => {
            this.errors.remove(e.field, e.scope);
          });
        }
      });
    }
  },
  beforeDestroy() {},
  created() {
    this.initBusEvents();
  }
};
</script>
