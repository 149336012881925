<template>
  <div v-if="show">
    <div class="">
      <div class="tabs is-boxed is-hidden-print">
        <ul data-tabs id="dashboard-tabs">
          <router-link tag="li"
            class="tabs-title"
            :to="'/customer/orders/' + id + '/details'">
            <a>Details</a>
          </router-link>
          <router-link tag="li"
                       class="tabs-title"
                       :to="'/customer/orders/' + id + '/payments'">
            <a>Payments</a>
          </router-link>
          <router-link
            v-if="latestInvoiceHash"
            tag="li"
            class="tabs-title"
            :to="'/customer/orders/' + id + '/invoice/' + latestInvoiceHash">
            <a>Invoice</a>
          </router-link>
        </ul>
      </div>
      <section id="my-threadbird">
        <router-view></router-view>
      </section>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import alertify from 'alertify.js';

import { LOAD_CUSTOMER_ORDER } from '../../../store/action-types';

import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'customer-order-view',
  data() {
    return {
      show: false
    };
  },
  watch: {
    id(id) {
      this.doLoadOrder();
    }
  },
  computed: {
    ...mapGetters(['orderDetails']),
    id() {
      return this.$route.params.id;
    },
    latestInvoiceHash() {
      let invoiceHash = null;
      if (
        this.orderDetails &&
        this.orderDetails[0] &&
        this.orderDetails[0].invoices
      ) {
        const invoices = this.orderDetails[0].invoices;
        const invoice = invoices.find(i => !!i.is_current);
        invoiceHash = invoice && invoice.link_hash;
      }
      return invoiceHash;
    }
  },
  methods: {
    ...mapActions({
      loadOrder: LOAD_CUSTOMER_ORDER
    }),
    doLoadOrder() {
      return this.loadOrder(this.id)
        .then(() => {
          this.$set(this, 'show', true);
        })
        .catch(err => {
          alertify.error(err.statusText);

          this.$nextTick(() => {
            this.$router.push('/');
          });
        });
    }
  },

  mounted() {
    this.doLoadOrder();
  }
};
</script>
