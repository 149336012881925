export const INVOICE_STATUS_UNPAID = 0;
export const INVOICE_STATUS_PENDING = 1;
export const INVOICE_STATUS_PAID = 2;
export const INVOICE_STATUS_DECLINED = 3;

export const INVOICE_PAYMENT_STATUSES = {
  [INVOICE_STATUS_PAID]: 'Paid',
  [INVOICE_STATUS_PENDING]: 'Partial',
  [INVOICE_STATUS_UNPAID]: 'Unpaid',
  [INVOICE_STATUS_DECLINED]: 'Declined'
};
