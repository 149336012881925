<template>
  <div>
    <logo/>
    <form @submit.prevent="attempt" class="box">
      <div :class="['message', 'is-' + message.type]" v-if="messages.length >0" v-for="message in messages">
        <div class="message-body">
          {{ message.message }}
        </div>
      </div>
      <div class="field">
        <label class="label">Login</label>
        <div class="control">
          <input v-model="user.login"  required class="input" type="text" placeholder="jsmith or jsmith@example.org">
        </div>
      </div>
      <div class="field">
        <label class="label">Password</label>
        <div class="control">
          <input v-model="user.password" required class="input" type="password" placeholder="●●●●●●●">
        </div>
      </div>

      <div class="field ">
        <div class="control">
          <button type="submit" :class="{'is-loading': loggingIn, 'button is-primary': true}"
                  :disabled="loggingIn">
            Login
          </button>
        </div>
      </div>
    </form>
    <div class="field has-text-centered">
      <div class="control">
        <router-link to="/auth/forgot">Forgot password</router-link>
      </div>
    </div>
  </div>
</template>

<script>
import {LOGIN_USER} from '../../store/action-types';
import Logo from "../partials/logo.vue";

export default {
  name: 'login',
  components: {Logo},
  data() {
    return {
      user: {
        login: null,
        password: null
      },
      messages: [],
      loggingIn: false
    };
  },
  methods: {
    attempt: function (e) {
      this.messages = [];

      if (this.user.login && this.user.password) {
        this.loggingIn = true;
        this.$store
          .dispatch(LOGIN_USER, this.user)
          .then(user => {
            // this.$store.dispatch(RESUBSCRIBE_PUBNUB, this);
            this.$router.push(
              (this.$route.query && this.$route.query.redirect) || '/'
            );
          })
          .catch(response => {
            this.messages = [];
            if (response.status && response.status === 422) {
              this.messages.push({
                type: 'danger',
                message: 'Sorry, you provided invalid credentials'
              });
            }
            this.loggingIn = false;
          });
      } else {
        this.messages.push({
          type: 'danger',
          message: 'Please fill the form fields'
        });
      }
    }
  },
};
</script>
