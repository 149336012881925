<template>
  <div class="page-content section container">
    <div class="columns">
      <div class="column is-8">
        <h3 class="title is-3" v-if="isOrder">Edit Order</h3>
        <h3 class="title is-3" v-else>Edit Estimate</h3>
        <!-- Estimate fotm -->
        <estimate-edit-form
          :model="estimate"
          :isOrder="isOrder"
          :isEdit="true"
          :isSent="isSent"
          :options="options"
          :errorsList="errorsList"
          :processing="processing"
          ref="estimateForm"
          @orderSummary="setOrderSummary"
          @submitForm="submitForm"
          @cancel="cancel"></estimate-edit-form>
          <!-- END Estimate form -->
      </div>

      <!-- Summary sidebar -->
      <div class="column is-4 ">
        <estimate-summary :order-summary="orderSummary"
          :hideShipping="isCustomer"></estimate-summary>
      </div>
      <!-- END Summary sidebar -->
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import { mapGetters, mapActions } from 'vuex';
import VueCookie from 'vue-cookie';
import alertify from 'alertify.js';
import { map, isEmpty, merge, forEach, cloneDeep, isEqual } from 'lodash';
import moment from 'moment';
import store from '../../store';
import {
  LOAD_ORDER_TO_EDIT,
  UPDATE_ORDER_TO_EDIT,
  CLEAR_ACCOUNT,
  RESUBSCRIBE_PUBNUB
} from '../../store/action-types';
import notify from '../../modules/notifier';
import apiService from '../../services/api.service';
import popup from '../../modules/popupper';
import CreateInvoiceComponent from './popups/create-invoice.vue';

//inner
import estimateEditForm from './estimate-edit-form';
import estimateSummary from './estimate-summary';
import bus from '../../bus';
import { checkRoles } from '../../router/checkRoles';

export default {
  name: 'estimate-edit',
  components: {
    estimateEditForm,
    estimateSummary
  },
  data() {
    return {
      estimate: {
        account: {
          account_id: '',
          contact_firstname: '',
          contact_lastname: '',
          email: '',
          phone: '',

          s_mailing_address: '',
          s_city: '',
          s_country_id: '',
          s_postal_code: '',
          s_state: '',
          s_state_id: '',

          billing_address_same: false,

          b_mailing_address: '',
          b_city: '',
          b_country_id: '',
          b_postal_code: '',
          b_state: '',
          b_state_id: '',
          payment_term: '',
          comment: '',
          recipient_firstname: '',
          recipient_lastname: '',
          recipient_company: '',
          address_source: null,
          is_test: false
        },
        designs: [
          {
            common: {},
            specific: {}
          }
        ],
        discount: {
          discount_amount: 0,
          discount_percentage: 0,
          discount_description: 0,
          discount_field_for_calculation: ''
        },
        summary: {
          designs: {},
          ship_by_date: null,
          print_by_date: null,
          discount: {
            discount_amount: 0,
            discount_percentage: 0,
            discount_description: '',
            discount_field_for_calculation: ''
          },
          rush_fee: {
            rush_fee_amount: 0,
            rush_fee_description: '',
            rush_fee_percentage: 0
          }
        },
        finishings: []
      },
      orderSummary: null,
      errorsList: [],
      processing: false,
      emptyDiscount: {
        discount_amount: 0,
        discount_percentage: 0,
        discount_description: '',
        discount_field_for_calculation: ''
      }
    };
  },
  computed: {
    ...mapGetters(['orderToEdit', 'orderDetailsSummary', 'orderFinishings']),
    isOrder: function() {
      return !!(this.orderToEdit && this.orderToEdit.is_order);
    },
    orderPaidSum: function() {
      return this.isOrder && this.orderToEdit.latest_invoice
        ? this.orderToEdit.latest_invoice.paid_sum * 1
        : 0;
    },
    orderId() {
      return this.$route.params.id;
    },
    submitBtnText() {
      return this.isOrder ? 'Update Order' : 'Update Estimate';
    },
    isReorder: function() {
      return (
        this.$route.params.id &&
        this.$cookie.get('reorder_id') == this.$route.params.id
      );
    },
    isCustomer() {
      return checkRoles(store.getters.roles, ['customer'], true);
    },
    isSent() {
      return !!(this.orderToEdit && this.orderToEdit.estimate_sent_at);
    },
    options() {
      return {
        submitAndSendBtnText: 'Update and Send',
        submitDraftBtnText: 'Update Draft',
        submitBtnText: this.submitBtnText
      };
    }
  },
  beforeRouteEnter(to, from, next) {
    store
      .dispatch(LOAD_ORDER_TO_EDIT, {
        orderId: to.params.id
      })
      .then(next)
      .catch(error => {
        notify({
          message: error.message || 'Can not load data.',
          type: 'danger'
        });
        console.log(error);
        next(false);
      });
  },
  watch: {
    $route(to, from) {
      this.getOrder({ orderId: to.params.id }).catch(error => {
        notify({
          message: error.message || 'Can not load data.',
          type: 'danger'
        });
      });
    },
    orderToEdit(model) {
      // Need to update the local state
      this.applyModel(model);
    }
  },
  methods: {
    ...mapActions({
      getOrder: LOAD_ORDER_TO_EDIT,
      updateOrderToEdit: UPDATE_ORDER_TO_EDIT,
      clearAccount: CLEAR_ACCOUNT
    }),
    createInvoice({ amount, description, term }) {
      return apiService
        .requestPayment({
          orderId: this.orderId,
          invoice: {
            amount: amount,
            description: description,
            payment_term: term,
            estimate: this.orderSummary
          }
        })
        .then(data => {
          this.processing = false;
          this.$emit('success');
        })
        .catch(error => {
          this.$emit('submitFail');
          this.processing = false;
          alertify.error((error && error.message) || 'Unexpected Error.');
        });
    },
    applyModel(model) {
      let finishingsOptions = this.orderFinishings;

      model = Object.assign({}, model);
      let estimate = {
        account: {
          id: model.id,
          account_id: model.account_id,
          contact_firstname: model.contact_firstname,
          contact_lastname: model.contact_lastname,
          email: model.email,
          phone: model.phone,

          s_mailing_address: model.s_mailing_address,
          s_city: model.s_city,
          s_country_id: model.s_country_id,
          s_postal_code: model.s_postal_code,
          s_state: model.s_state,
          s_state_id: model.s_state_id,

          billing_address_same: false,

          b_mailing_address: model.b_mailing_address,
          b_city: model.b_city,
          b_country_id: model.b_country_id,
          b_postal_code: model.b_postal_code,
          b_state: model.b_state,
          b_state_id: model.b_state_id,
          payment_term: model.payment_term,
          comment: model.comment,
          shipping_cost: model.shipping_cost,
          tax_id: model.tax_id,
          packages_qty: model.packages_qty,
          recipient_firstname: model.recipient_firstname,
          recipient_lastname: model.recipient_lastname,
          recipient_company: model.recipient_company,
          address_source: model.address_source,
          is_test: model.is_test
        },
        designs: model.designs.map(design => {
          let d = {
            common: {
              id: design.id,
              in_hands_date:
                design.in_hands_date &&
                moment(design.in_hands_date).format('MM/DD/YY'),
              name: design.name,
              comment: design.comment,
              printed_before: design.printed_before,
              service: design.service,
              shipping_method: design.shipping_method,
              type_of_delivery: design.type_of_delivery,
              upcharge: design.upcharge,
              upcharge_description: design.upcharge_description,
              printed_before_order_id: design.printed_before_order_id,
              printed_before_design_id: design.printed_before_design_id,
              reference_order_id: design.reference_order_id,
              reference_design_id: design.reference_design_id,
              ship_by_date:
                design.ship_by_date &&
                moment(design.ship_by_date).format('MM/DD/YY'),
              print_by_date:
                design.print_by_date &&
                moment(design.print_by_date).format('MM/DD/YY')
            },
            specific: {
              item: Object.assign({}, design.designable.item),
              items: Object.assign({}, design.designable.items),
              ink_color_changes: design.designable.ink_color_changes,
              garments: design.designable.garments.map(garment => {
                return {
                  id: garment.id,
                  apparel_type: garment.apparel_type,
                  brand: garment.brand,
                  product: garment.product,
                  color: garment.color,
                  count: garment.count,
                  price: garment.price,
                  sizes: JSON.parse(garment.size_qty),
                  fabric: garment.product_object.fabric,
                  origin: garment.product_object.origin
                };
              }),
              locations: design.designable.locations
            }
          };

          return d;
        }),
        summary: {
          designs: {},
          ship_by_date:
            model.ship_by_date && moment(model.ship_by_date).format('MM/DD/YY'),
          print_by_date:
            model.print_by_date &&
            moment(model.print_by_date).format('MM/DD/YY'),
          discount:
            (model.discount && cloneDeep(model.discount)) ||
            cloneDeep(this.emptyDiscount),
          rush_fee: (model.rushfee && cloneDeep(model.rushfee)) || {
            rush_fee_amount: 0,
            rush_fee_description: '',
            rush_fee_percentage: 0
          }
        },
        finishings: [],
        discount:
          (model.discount && cloneDeep(model.discount)) ||
          cloneDeep(this.emptyDiscount)
      };

      let finishings = {};

      if (finishingsOptions) {
        let finishingModelHash = {};

        finishingsOptions.forEach(option => {
          finishingModelHash[option.model] = option;
        });

        model.designs.forEach((d, key) => {
          d.designable.finishings.forEach(finishing => {
            let finishing_option = finishing.finishing_option;
            if (/^App\\/.test(finishing.finishable_type)) {
              let modelId =
                finishing.finishable_type.split('\\').slice(-1)[0] || '';
              if (modelId in finishingModelHash) {
                finishing_option = finishingModelHash[modelId].key;
              }
            }

            if (finishings[finishing.id]) {
              finishings[finishing.id].related_design.push(key);
            } else {
              finishings[finishing.id] = {
                id: finishing.id,
                name: finishing.name,
                fields: Object.assign({}, finishing.finishable),
                finishing_option: finishing_option,
                related_design: [key]
              };
            }
          });
        });
      }

      if (!isEmpty(finishings)) {
        forEach(finishings, finishing => {
          estimate.finishings.push(finishing);
        });
      }

      this.$set(this, 'estimate', estimate);
    },
    setOrderSummary(data) {
      this.orderSummary = data;
    },
    isNeedNewInvoice() {
      let isOrder = this.isOrder;
      let orderId = this.orderId;
      this.processing = true;

      if (!isOrder || !this.orderDetailsSummary) {
        return Promise.resolve();
      }

      let term = this.estimate.account.payment_term;
      let estimate = this.orderSummary;

      // If it's order we need to check if total price has been changed
      let upcharge = +this.orderDetailsSummary.total.upcharge;
      let newUpcharge = +estimate.total.upcharge;

      let price = upcharge + +this.orderDetailsSummary.total.price;
      let newPrice = newUpcharge + +estimate.total.price;

      let invoice = {
        amount: +newPrice.toFixed(2),
        description: 'The contents of the order have been updated.',
        term: term
      };

      if (newPrice === price) {
        // Can save without prompt
        return Promise.resolve();
      }

      if (
        newPrice === this.orderPaidSum ||
        (newPrice < price && newPrice > this.orderPaidSum)
      ) {
        return this.createInvoice(invoice).then(() => {
          return Promise.resolve();
        });
      }

      if (newPrice < this.orderPaidSum) {
        // Show confirmation popup
        return new Promise((resolve, reject) => {
          popup({
            title: 'Confirmation',
            message:
              'Please note, you need to manually refund the client through the payment gateway because the new total is lower than paid amount.',
            submitLabel: 'Got it, Save changes',
            additional: {
              callback: () => {
                this.createInvoice(invoice).then(() => {
                  resolve();
                });
                this.processing = false;
              },
              closeCallback: () => {
                this.processing = false;
                reject();
              }
            },
            runCallback: true,
            runCancellCallback: true
          });
        });
      }

      if (newPrice > price) {
        return new Promise((resolve, reject) => {
          popup({
            title: 'Confirmation',
            message:
              'Please note, the new invoice will be sent to the client because the total has increased.',
            submitLabel: 'Got it, Add invoice',
            additional: {
              callback: () => {
                popup({
                  title: 'Create New Invoice',
                  subtitle: 'Order #' + orderId,
                  additional: {
                    orderId: orderId,
                    amount: invoice.amount,
                    description: invoice.description,
                    payment_term: term,
                    estimate: estimate,
                    callback: () => {
                      resolve();
                    },
                    closeCallback: () => {
                      this.processing = false;
                      reject();
                    }
                  },
                  submitLabel: 'Save and Send invoice',
                  bodyComponent: CreateInvoiceComponent,
                  runCallback: true,
                  runCancellCallback: true
                });
              },
              closeCallback: () => {
                this.processing = false;
                reject();
              }
            },
            runCallback: true
          });
        });
      }
    },
    submitForm(isValid, action) {
      if (!isValid) {
        return notify({
          message:
            'Some validation errors have been detected. Please check error messages.',
          type: 'danger'
        });
      }

      //Propagate events to collect data into model from all components
      return this.$refs.estimateForm.setOrder().then(estimate => {
        if (
          estimate.designs.length === 0 ||
          (estimate.designs.length === 1 &&
            estimate.designs[0].common.service === undefined)
        ) {
          return Promise.resolve();
        }

        this.$set(this, 'estimate', estimate);
        let data = cloneDeep(estimate);
        data.summary = this.orderSummary;

        this.isNeedNewInvoice()
          .then(() => {
            this.processing = true;

            if (typeof this[action] === 'function') {
              this[action](data);
            }
          })
          .catch(error => {
            this.processing = false;
          });
      });
    },

    updateOrder(data, send) {
      this.updateOrderToEdit({
        orderId: this.orderId,
        data: data
      }).then(
        data => {
          this.$cookie.delete('reorder_id');

          //Redirect to next step
          notify({
            message: `The ${
              this.isOrder ? 'order' : 'estimate'
            } has been saved.`,
            type: 'success'
          });

          // If "Update and Send" button clicked.
          if (send) {
            apiService
              .sendEstimate(this.orderId)
              .then(() => {
                notify({
                  message: 'The estimate has been sent.',
                  type: 'success'
                });
                this.pushToDetails(this.orderId);
              })
              .catch(err => {
                notify({
                  message:
                    "Unfortunately, estimate wasn't sent. Please try to resend it from dashboard",
                  type: 'danger'
                });
              });
          } else {
            //  If "Update Draft" or "Update Order" button clicked.
            this.pushToDetails(this.orderId);
          }
        },
        response => {
          this.processing = false;

          if (response.status == 422 && response.data.errors) {
            notify({
              message:
                'Some validation errors have been detected. Please check error messages.',
              type: 'danger'
            });

            let errors = map(response.data.errors, (messages, fullName) => {
              let chunks = fullName.split('.');
              let name = chunks.splice(chunks.length - 1, 1);
              let scope = chunks.join('-');

              if (chunks[2] == 'garments' && name[0] == 'sizes') {
                alertify.error(messages.join('\n'));
              }

              return {
                field: name[0],
                msg: messages.join('\n'),
                rule: 'required',
                scope: scope,
                fullName: fullName
              };
            });

            this.$set(this, 'errorsList', errors);
          } else if (response.body && response.body.error) {
            notify({
              message: response.body.error,
              type: 'danger'
            });
          } else {
            notify({
              message: "An Error occurred. The estimate hasn't been saved.",
              type: 'danger'
            });
          }
        }
      );
    },
    saveAndSend(data) {
      this.updateOrder(data, true);
    },
    saveDraft(data) {
      this.updateOrder(data, false);
    },
    saveOrder(data) {
      this.updateOrder(data, false);
    },
    cancel() {
      if (this.isReorder) {
        alertify.confirm('Are you sure? Order will be deleted.', ev => {
          return apiService.destroyReordered(this.$route.params.id).then(() => {
            this.$cookie.delete('reorder_id');
          });
        });
      }
      if (checkRoles(store.getters.roles, ['customer'], true)) {
        this.$router.push('/customer/dashboard');
      } else if (this.isOrder) {
        this.$router.push('/dashboard/my-orders');
      } else {
        this.$router.push('/estimates');
      }
    },
    pushToDashboard() {
      this.clearAccount();

      if (checkRoles(store.getters.roles, ['customer'], true)) {
        this.$router.push('/customer/dashboard');
        this.$store.dispatch(RESUBSCRIBE_PUBNUB, this);
      } else {
        this.$router.push(
          `/dashboard/${this.isOrder ? 'my-orders' : 'my-estimates'}`
        );
      }
    },
    pushToDetails(orderId) {
      this.clearAccount();
      this.$router.push(
        `/${this.isOrder ? 'orders' : 'estimates'}/${orderId}/details`
      );
    }
  },
  created() {
    this.applyModel(this.orderToEdit);

    bus.$on('account-changed', account => {
      this.$set(this.estimate, 'account', account);
    });
  },
  mounted() {
    Vue.use(VueCookie);
  },
  beforeRouteLeave(to, from, next) {
    let answer = true;

    if (!this.processing) {
      answer = window.confirm(
        'You have unsaved changes, are you sure you want to leave this page?'
      );
    }
    if (answer) {
      next();
    } else {
      next(false);
    }
  }
};
</script>
