<template>
  <div>
    <div class="columns" v-if="reportActive">
      <div class="column">
        <sales-tax-report/>
      </div>
    </div>
    <hr v-if="reportActive">
    <div class="columns">
      <div class="column is-4 input-cntr">
        <label class="label" for="date_from"  style="margin-bottom: 0">From</label>
        <div class="control">
          <datepicker
            v-model="filters.date_from"
            :config="{dateFormat: 'm/d/Y', wrap: true }"
            id="date_from"
          />
        </div>
      </div>
      <div class="column is-4 input-cntr">
        <label class="label" for="date_to" style="margin-bottom: 0">To</label>
        <div class="control">
          <datepicker
            v-model="filters.date_to"
            :config="{dateFormat: 'm/d/Y', wrap: true }"
            id="date_to"
          />
        </div>
      </div>
      <div class="column is-4 input-cntr">
        <a class="is-pulled-right" @click="reportActive = !reportActive">{{
            reportActive ? 'Close' : 'Generate report'
          }}</a>
      </div>
    </div>
    <grid :orders="gridData"
          :columns="gridHeaders"
          :loading="salesTaxOrdersLoading"
          noResMsg="No Sales Taxes"
          :showTriangle="false"
          @onGridSortBy="gridUpdateOrders"></grid>

    <paginator v-if="salesTaxOrders.length"
               :pagesNumber="salesTaxOrdersPaginatorData.last_page"
               :prevPageUrl="salesTaxOrdersPaginatorData.prev_page_url"
               :nextPageUrl="salesTaxOrdersPaginatorData.next_page_url"
               :currentPage="salesTaxOrdersPaginatorData.current_page"
               :getListItems="gridUpdateOrders"
               :showOffset="true"
               :offsetNumber="Number(pagination.perPage)"
               :totalNumber="salesTaxOrdersPaginatorData.total"
               :sortField="pagination.sortField"
               :sortDir="pagination.sortDir"></paginator>

    <v-dialog></v-dialog>
  </div>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from 'vuex';
import Grid from '../../common/grid/grid';
import Paginator from '../../common/paginator';
import {LOAD_ORDERS_REPORTS_DATA} from '@/store/action-types';

import {getGridData, gridHeaders} from '@/helpers/reports/sales-tax';
import Datepicker from '../../../modules/datepicker/index';
import SalesTaxReport from '../sales-tax-report';

export default {
  name: 'sales-tax',
  components: {SalesTaxReport, Datepicker, Paginator, Grid},
  data() {
    return {
      filters: {
        date_to: '',
        date_from: '',
        timezone: ''
      },
      pagination: {
        page: 1,
        perPage: 50,
        sortField: 'estimate_becomes_to_order_at',
        sortDir: 'DESC',
      },
      gridHeaders,
      reportActive: false,
    };
  },
  watch: {
    'filters.date_from'() {
      this.pagination.page = 1;
      this.$nextTick(() => {
        this.gridUpdateOrders();
      });
    },
    'filters.date_to'() {
      this.pagination.page = 1;
      this.$nextTick(() => {
        this.gridUpdateOrders();
      });
    }
  },
  computed: {
    ...mapGetters([
      'salesTaxOrders',
      'salesTaxOrdersPaginatorData',
      'salesTaxOrdersLoading',
      'roles'
    ]),
    gridData() {
      return {
        data: getGridData(this.salesTaxOrders, this.roles, {})
      };
    }
  },
  methods: {
    ...mapActions({
      loadOrdersReportsData: LOAD_ORDERS_REPORTS_DATA
    }),
    ...mapMutations(['dropRow']),

    // Update orders in store on orders sorting.
    gridUpdateOrders(page, url, perPage) {
      if(page){
        this.pagination.page = page;
      }
      if(perPage && Number(perPage) !== this.pagination.perPage){
        this.pagination.perPage = Number(perPage)
      }
      // Get default or updated by "loadSalesTaxesPaginated" method.

      this.loadOrdersReportsData({
        model: 'sales_tax',
        pagination: this.pagination,
        filters: this.filters,
      });
    }
  },
  created() {
    this.filters.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    this.gridUpdateOrders();
  }
};
</script>
