import apiService from '../../services/api.service';
import router from '../../router';
import { checkRoles } from '../../router/checkRoles';
import Vue from 'vue';

import {
  LOGIN_USER,
  POST_REGISTER_LOGIN_USER,
  GET_USER_DATA,
  RESUBSCRIBE_PUBNUB,
  SUBSCRIBE_TO_ORDER,
  FORGOT_PASSWORD,
  RESET_PASSWORD,
  LOAD_PAYMENT_TERMS,
  CREATE_PASSWORD,
  CHANGE_PASSWORD,
  SET_S3_DOMAIN,
  GET_OPTIONS,
  GET_PRINTER_OPTIONS,
  GET_FINISHING_PRINTER_OPTIONS,
  GET_GARMENTS_SIZES,
  ADD_VALIDATION_PROMISE,
  CLEAR_VALIDATION_PROMISES
} from '../action-types';
import {printers, teamMembers, customers} from '../../helpers/role-access.js';

export default {
  state: {
    user: {
      authenticated: false,
      token: null,
      profile: {}
    },
    s3Domain: '',
    order_follow_up_statuses: [],
    design_printer_statuses: [],
    finishing_printer_statuses: [],
    payment_terms: [],
    sortedGarmentsSizes: [],
    validations: []
  },
  mutations: {
    clearValidations(state) {
      state.validations = [];
    },
    addValidation(state, promise) {
      state.validations.push(promise);
    },
    setProfile(state, profile) {
      state.user.profile = profile;
    },
    setUserOids(state, oids) {
      state.user.profile.oids = oids;
    },
    addToUserOids(state, oid) {
      let oids = state.user.profile.oids_communication;
      oids.push(oid);
      state.user.profile.oids_communication = oids;
    },
    setAuth(state, authenticated) {
      state.user.authenticated = authenticated;
    },
    setLogin: function(state, user) {
      state.user.profile = user;
      localStorage.setItem('user', JSON.stringify(user));
      state.user.authenticated = true;
      state.user.token = localStorage.getItem('jwt-token');
    },
    destroyLogin: function(state) {
      state.user.profile = null;
      state.user.authenticated = false;
      state.user.token = null;
      localStorage.removeItem('jwt-token');
      localStorage.removeItem('user');
      document.cookie = "user_email=; expires=Thu, 01 Jan 1970 00:00:00 UTC;";
    },
    setS3Domain: function(state, domain) {
      state.s3Domain = domain;
    },
    setFollowUpStatuses(state, statuses) {
      state.order_follow_up_statuses = statuses;
    },
    setPrinterStatuses(state, statuses) {
      state.design_printer_statuses = statuses;
    },
    setFinishingPrinterStatuses(state, statuses) {
      state.finishing_printer_statuses = statuses;
    },
    setPaymentTerms(state, terms) {
      state.payment_terms = terms;
    },
    setSortedGarmentsSizes(state, sizes) {
      state.sortedGarmentsSizes = sizes;
    }
  },
  getters: {
    userProfile: state => {
      return state.user.profile;
    },
    userName: state => {
      return `${state.user.first_name || ''} ${state.user.last_name ||
        ''}`.trim();
    },
    userId: state => {
      return (
        (state.user && state.user.profile && state.user.profile.id) || null
      );
    },
    isAuthenticated: state => {
      return state.user.authenticated;
    },
    isTestAccount: state => {
      // deprecated
      return state.user && state.user.profile && state.user.profile.is_test;
    },
    roles: state => {
      return state.user.profile && state.user.profile.roles
        ? state.user.profile.roles.map(r => r.name)
        : [];
    },
    oids: state => {
      return state.user.profile && state.user.profile.oids
        ? state.user.profile.oids
        : [];
    },
    active_oids: state => {
      return state.user.profile && state.user.profile.active_oids
        ? state.user.profile.active_oids
        : [];
    },
    oids_communication: state => {
      return state.user.profile && state.user.profile.oids_communication
        ? state.user.profile.oids_communication
        : [];
    },
    channelsToSubscribe: (state, getters, rootState) => {
      let channels = [];
      // active_oids - If user connected to an order by claim, or he is a customer.
      // oids_communication - If user connected to an order by mention(for example)
      const oids = [...new Set([...getters.active_oids, ...getters.oids_communication])];
      if (oids.length > 0) {
        oids.forEach(id => {
          if (checkRoles(getters.roles, teamMembers)) {
            channels.push(`${getters.pubNubEnv}-order-${id}-internal`);
            channels.push(`${getters.pubNubEnv}-order-${id}-printer`);
            channels.push(`${getters.pubNubEnv}-order-${id}-customer`);
          }
          if (checkRoles(getters.roles, customers)) {
            channels.push(`${getters.pubNubEnv}-order-${id}-customer`);
          }
          if (checkRoles(getters.roles, printers)) {
            channels.push(`${getters.pubNubEnv}-order-${id}-printer`);
          }
        });
      }

      channels.push(`${getters.pubNubEnv}-user-${getters.userId}-mentioned`);
      channels.push(`${getters.pubNubEnv}-user-${getters.userId}-email-service`);

      if (checkRoles(getters.roles, customers)) {
        channels.push(`${getters.pubNubEnv}-user-${getters.userId}-on-estimate-sent`);
      }

      channels.push(`${getters.pubNubEnv}-user-${getters.userId}-mark-as-read`);

      return channels;
    },
    route: (state, getters, rootState) => {
      return rootState.route;
    },
    s3Domain: state => {
      return state.s3Domain;
    },
    orderFollowUpStatuses: state => {
      return state.order_follow_up_statuses;
    },
    designPrintStatuses: state => {
      return state.design_printer_statuses;
    },
    finishingPrintStatuses: state => {
      return state.finishing_printer_statuses;
    },
    paymentTerms: state => {
      return state.payment_terms;
    },
    sortedGarmentsSizes: state => {
      return state.sortedGarmentsSizes;
    }
  },
  actions: {
    [ADD_VALIDATION_PROMISE]: ({ commit }, promise) => {
      commit('addValidation', promise);
    },
    [CLEAR_VALIDATION_PROMISES]: ({ commit }) => {
      commit('clearValidations');
    },
    [LOGIN_USER]: ({ dispatch }, userCredentials) => {
      return apiService.loginUser(userCredentials).then(data => {
        localStorage.setItem('jwt-token', data.token);
        return dispatch(GET_USER_DATA);
      });
    },
    [POST_REGISTER_LOGIN_USER]: ({ dispatch }, verificationToken) => {
      return apiService.postRegisterLoginUser(verificationToken).then(data => {
        if (data.login_token != null) {
          localStorage.removeItem('jwt-token');
          localStorage.removeItem('user');
          localStorage.setItem('jwt-token', data.login_token);
          return dispatch(GET_USER_DATA);
        } else {
          return { link_expired: true };
        }
      });
    },
    [FORGOT_PASSWORD]: (store, user) => {
      return apiService.forgotPassword(user);
    },
    [RESET_PASSWORD]: (store, pass_data) => {
      return apiService.resetPassword(pass_data);
    },
    [CREATE_PASSWORD]: (store, pass_data) => {
      return apiService.createPassword(pass_data);
    },
    [CHANGE_PASSWORD]: (store, pass_data) => {
      return apiService.changePassword(pass_data);
    },
    [GET_USER_DATA]: ({ commit, dispatch }) => {
      return apiService.getMe().then(data => {
        
        if (checkRoles(data.user.roles[0], customers)) {
          let domain = document.location.host;
          if (domain === 'orders.threadbird.com') {
            domain = domain.replace(/^[^.]+\./g, '.');
          }
          let expires = new Date();
          // add 2 hours
          expires.setTime(expires.getTime() + (2 * 60 * 60 * 1000));
          document.cookie = 'user_email=' + data.user.email + '; domain=' + domain + '; expires=' + expires;
        }

        commit('setLogin', data.user);
        dispatch(GET_OPTIONS);
        dispatch(LOAD_PAYMENT_TERMS);
        dispatch(GET_PRINTER_OPTIONS);
        dispatch(GET_FINISHING_PRINTER_OPTIONS);
        dispatch(GET_GARMENTS_SIZES);
        return data.user;
      });
    },
    [RESUBSCRIBE_PUBNUB]: (store, vue) => {
      return apiService.getOids().then(data => {
        store.commit('setUserOids', data);

        Vue.prototype.$pubNub.unsubscribeAll();
        Vue.prototype.$pubNub.subscribe({
          channels: store.getters.channelsToSubscribe
        });
      });
    },
    [SUBSCRIBE_TO_ORDER]: (store, { orderId, vue }) => {
      store.commit('addToUserOids', orderId);
      Vue.prototype.$pubNub.unsubscribeAll();

      Vue.prototype.$pubNub.subscribe({
        channels: store.getters.channelsToSubscribe
      });
    },
    [SET_S3_DOMAIN]: ({ commit }, domain) => {
      commit('setS3Domain', domain);
    },
    [GET_OPTIONS]: ({ commit }) => {
      // we can add different option to this section
      apiService.getFollowUpStatusesList().then(statuses => {
        commit('setFollowUpStatuses', statuses);
      });
    },
    [GET_PRINTER_OPTIONS]: ({ commit }) => {
      // we can add different option to this section
      apiService.getPrinterStatusesList().then(statuses => {
        commit('setPrinterStatuses', statuses);
      });
    },
    [GET_FINISHING_PRINTER_OPTIONS]: ({ commit }) => {
      // we can add different option to this section
      apiService.getFinishingPrinterStatusesList().then(statuses => {
        commit('setFinishingPrinterStatuses', statuses);
      });
    },
    [GET_GARMENTS_SIZES]: ({ commit }) => {
      // we can add different option to this section
      apiService.getSortedGarmentsSizes().then(sizes => {
        commit('setSortedGarmentsSizes', sizes);
      });
    },
    [LOAD_PAYMENT_TERMS]: ({ commit, getters }) => {
      if (getters.paymentTerms.length) {
        return Promise.resolve(getters.paymentTerms);
      }
      return apiService.getPaymentTerms().then(data => {
        commit('setPaymentTerms', data);
      });
    }
  }
};
