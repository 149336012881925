import apiService from '../../services/api.service';
import notify from '../../modules/notifier';
import { progressFieldsList } from '../../helpers/orders';

import Vue from 'vue';
import VueResource from 'vue-resource';
import {formatFiltersOptions} from "@/helpers/grid-helper";
import { cloneDeep } from 'lodash';
import {
  CLAIM,
  IS_VIEWED,
  LOAD_ACTIVE_ORDERS,
  LOAD_ESTIMATES,
  LOAD_MY_ESTIMATES,
  LOAD_MY_ORDERS,
  LOAD_ORDERS_DESIGNS,
  LOAD_UNCLAIMED_BLANKS,
  LOAD_UNCLAIMED_ESTIMATES,
  LOAD_UNCLAIMED_MOCKS,
  RESUBSCRIBE_PUBNUB,
  SEND_ESTIMATE,
  SET_ACTIVE_ORDERS_PHASE,
  SET_MY_ORDERS_MOCK_SENT_DATE,
  SET_MY_ORDERS_PHASE,
  SET_MY_ORDERS_SURVEY_DATE,
  SET_ORDER_STATUS,
  SET_ORDER_TERMS,
  UPDATE_MY_DESIGNS_BLANK_STATUS,
  UPDATE_MY_ESTIMATES_DESIGN_PROGRESS,
  UPDATE_MY_ESTIMATES_FINISHING_PROGRESS,
  UPDATE_MY_ORDERS_DESIGN_BLANK_PROGRESS,
  UPDATE_MY_ORDERS_DESIGN_FINISH_PROGRESS,
  UPDATE_MY_ORDERS_DESIGN_FOLLOW_UP_STATUS,
  UPDATE_MY_ORDERS_DESIGN_MOCK_PROGRESS,
  UPDATE_MY_ORDERS_DESIGN_MOCKS_COUNT,
  UPDATE_MY_ORDERS_DESIGN_PRINT_PROGRESS,
  UPDATE_MY_ORDERS_DESIGN_PRINTER,
  UPDATE_MY_ORDERS_DESIGN_PROGRESS,
  UPDATE_MY_ORDERS_DESIGN_SETUP_PROGRESS,
  UPDATE_MY_ORDERS_DESIGN_SHIPMENT_PROGRESS,
  UPDATE_MY_ORDERS_FINISHING_PROGRESS,
  UPDATE_MY_ORDERS_PAYMENT_STATUS,
  UPDATE_MY_ORDERS_PRINTER_STATUS,
  EMPTY_GRID_DATA,
  SET_ESTIMATE_IS_APPROVED, SET_ESTIMATE_SENT_DATE
} from '../action-types';
import moment from "moment";
import {APPAREL_SERVICE_CODE, EMBROIDERY_SERVICE_CODE} from '@/store/service-types';

Vue.use(VueResource);

const errorNotify = err => {
  console.log(err);

  if (err.status && err.status === 422 && err.body.messages instanceof Array) {
    err.body.messages.forEach(msg => {
      if (msg instanceof Array) {
        msg.forEach(e => {
          notify({
            message: e,
            type: 'danger'
          });
        });
      } else {
        notify({
          message: msg,
          type: 'danger'
        });
      }
    });
  } else {
    let msg = err.body.message ? err.body.message : 'Something wrong';
    notify({
      message: msg,
      type: 'danger'
    });
  }
};

const dashboard = {
  state: {
    grid_data: [],
    pagination_data: {},
    grid_loading: false,
    filter_options: {
      csr_users: [],
      order_statuses: [],
      estimate_statuses: [],
      phases: [],
      printers: [],
      payment_status: [],
      art_producers: [],
      service_types: [],
      redo_categories: [],
      delivery_types: [],
      companies: []
    },
    actual_filters: [],

    services: [],
    estimate_statuses: [],
    my_orders: [],
    my_orders_paginator_data: {},
    my_orders_phase: 'S',
    my_orders_loading: false,

    active_orders: [],
    active_orders_paginator_data: {},
    active_orders_loading: false,
    active_orders_phase: 'S',

    my_estimates: [],
    my_estimates_paginator_data: {},
    my_estimates_loading: false,

    unclaimed_estimates: [],
    unclaimed_estimates_paginator_data: {},
    unclaimed_estimates_loading: false,

    estimates: [],
    estimates_paginator_data: {},
    estimates_loading: false,

    unclaimed_blanks: [],
    unclaimed_blanks_paginator_data: {},
    unclaimed_blanks_loading: false,

    unclaimed_mocks: [],
    unclaimed_mocks_paginator_data: {},
    unclaimed_mocks_loading: false
  },
  getters: {
    gridLoading: state => {
      return state.grid_loading;
    },
    gridData: state => {
      return state.grid_data;
    },
    paginatorData: state => {
      return state.pagination_data;
    },
    filterOptions: state => {
      return formatFiltersOptions(state.filter_options);
    },
    designServices: state => {
      return state.services;
    },
    estimateStatuses: state => {
      return state.estimate_statuses;
    },
    myOrders: state => {
      return state.my_orders;
    },
    myOrdersPaginatorData: state => {
      return state.my_orders_paginator_data;
    },
    myOrdersLoading: state => {
      return state.my_orders_loading;
    },
    myOrdersPhase: state => {
      return state.my_orders_phase;
    },
    activeOrders: state => {
      return state.active_orders;
    },
    activeOrdersPaginatorData: state => {
      return state.active_orders_paginator_data;
    },
    activeOrdersLoading: state => {
      return state.active_orders_loading;
    },
    activeOrdersPhase: state => {
      return state.active_orders_phase;
    },
    unclaimedEstimates: state => {
      return state.unclaimed_estimates;
    },
    unclaimedEstimatesPaginatorData: state => {
      return state.unclaimed_estimates_paginator_data;
    },
    unclaimedBlanks: state => {
      return state.unclaimed_blanks;
    },
    unclaimedBlanksPaginatorData: state => {
      return state.unclaimed_blanks_paginator_data;
    },
    unclaimedMocks: state => {
      return state.unclaimed_mocks;
    },
    unclaimedMocksPaginatorData: state => {
      return state.unclaimed_mocks_paginator_data;
    },
    unclaimedEstimatesLoading: state => {
      return state.unclaimed_estimates_loading;
    },
    unclaimedBlanksLoading: state => {
      return state.unclaimed_blanks_loading;
    },
    unclaimedMocksLoading: state => {
      return state.unclaimed_mocks_loading;
    },
    estimates: state => {
      return state.estimates;
    },
    estimatesPaginatorData: state => {
      return state.estimates_paginator_data;
    },
    estimatesLoading: state => {
      return state.estimates_loading;
    },
    myEstimates: state => {
      return state.my_estimates;
    },
    myEstimatesPaginatorData: state => {
      return state.my_estimates_paginator_data;
    },
    myEstimatesLoading: state => {
      return state.my_estimates_loading;
    }
  },
  mutations: {
    setOrdersDesigns(state, { orders, stateName }) {
      console.log(stateName, state);
      if (state[stateName]) {
        state[stateName] = state[stateName].map(order => {
          const orderWithDesign = orders.find(o => o.id === order.id);
          if (!orderWithDesign.designs) {
            return order;
          }

          order.designs = orderWithDesign.designs;
          order.designs = order.designs.map(design => {
            if ([APPAREL_SERVICE_CODE, EMBROIDERY_SERVICE_CODE].includes(design.service)) {
              design.designable.finishings = [];
              if (order.finishings) {
                order.finishings
                  .filter(finishing => {
                    return finishing.items.find(
                      ({ design_id }) => design_id === design.id
                    );
                  })
                  .forEach(finishing =>
                    design.designable.finishings.push(cloneDeep(finishing))
                  );
              }
            }
            return design;
          });

          return order;
        });
      }
    },
    updateMyOrdersPaymentStatus(state, { orderId, status }) {
      state.my_orders = state.my_orders.map(o => {
        if (o.id === orderId) {
          o.payment_status = status;
        }
        return o;
      });
    },
    updateMyDesignsBlankStatus(state, { designId, status }) {
      state.my_orders = state.my_orders.map(o => {
        o.designs = o.designs.map(d => {
          if (d.id === designId) {
            d.blank_status = status;
          }
          return d;
        });
        return o;
      });
    },
    updateMyOrdersDesignPrinter(state, { orderId, designId, printer }) {
      state.my_orders = state.my_orders.map(o => {
        if (o.id === orderId) {
          o.designs = o.designs.map(d => {
            if (d.id === designId) {
              d.printer = printer;
              let printerId = printer == null ? null : printer.id;
              d.printer_id = printerId;
            }
            return d;
          });
        }
        return o;
      });
    },
    updateMyOrdersDesignFollowUpStatus(state, {orderId, designId, status, newDesign}) {
      state.my_orders = state.my_orders.map(o => {
        if (o.id === orderId) {
          if (status == 2) {
            o.survey_sent_at = null;
          }
          o.designs = o.designs.map(d => {
            if (d.id === designId) {
              d.followup_status = status;
            }
            return d;
          });
          if (status == 2 && newDesign) {
            o.designs.push(newDesign);
          }
        }
        return o;
      });
    },
    updateMyOrdersDesignMocksCount(state, { orderId, designId, mock }) {
      state.my_orders = state.my_orders.map(o => {
        if (o.id === orderId) {
          o.designs = o.designs.map(d => {
            if (d.id === designId) {
              d.mocks.push(mock);
            }
            return d;
          });
        }
        return o;
      });
    },
    updateMyOrdersDesignPrinterStatus(state, { orderId, designId, status }) {
      state.my_orders = state.my_orders.map(o => {
        if (o.id === orderId) {
          o.designs = o.designs.map(d => {
            if (d.id === designId) {
              d.printer_status = status;
            }
            return d;
          });
        }
        return o;
      });
    },
    updateMyOrdersDesignPhaseProgress(state, {orderId, designId, progressName, progress}) {
      state.my_orders = state.my_orders.map(o => {
        if (o.id === orderId) {
          o.designs = o.designs.map(d => {
            if (d.id === designId) {
              d[progressName] = progress;
            }
            return d;
          });
        }
        return o;
      });
    },
    updateMyOrdersDesignProgress(state, { orderId, designId, progress }) {
      state.my_orders = state.my_orders.map(o => {
        if (o.id === orderId) {
          o.designs = o.designs.map(d => {
            if (d.id === +designId) {
              progressFieldsList.forEach(progressName => {
                if (progressName in progress) {
                  d[progressName] = progress[progressName];
                }
                if (d.original_files_link !== progress.original_files_link)
                  d.original_files_link = progress.original_files_link;
              });
            }
            return d;
          });
        }
        return o;
      });
    },
    updateMyEstimatesFinishingProgress(state, {orderId, finishingId, progress}) {
      state.my_orders = state.my_orders.map(o => {
        if (o.id === orderId) {
          o.designs = o.designs.map(d => {
            if (d.designable.finishings) {
              d.designable.finishings = d.designable.finishings.map(f => {
                if (f.id === finishingId) {
                  f.original_files_link = progress.original_files_link;
                  f.print_ready_files_link = progress.print_ready_files_link;
                  f.printer_status = progress.printer_status;
                }
                return f;
              });
            }
            return d;
          });
        }
        return o;
      });
    },
    updateMyOrdersFinishingProgress(state, { orderId, finishingId, progress }) {
      state.my_orders = state.my_orders.map(o => {
        if (o.id === orderId) {
          o.designs = o.designs.map(d => {
            if (d.designable.finishings) {
              d.designable.finishings = d.designable.finishings.map(f => {
                if (f.id === finishingId) {
                  f.original_files_link = progress.original_files_link;
                  f.print_ready_files_link = progress.print_ready_files_link;
                  f.printer_status = progress.printer_status;
                }
                return f;
              });
            }
            return d;
          });
        }
        return o;
      });
    },
    setMyOrdersSurveyDate(state, orderId) {
      state.my_orders = state.my_orders.map(o => {
        if (o.id === orderId) {
          o.survey_sent_at = true;
        }
        return o;
      });
    },
    setMyOrdersMockSentDate(state, { orderId, designId, sent }) {
      state.my_orders = state.my_orders.map(o => {
        if (o.id === orderId) {
          o.designs.map(d => {
            if (d.id === designId) {
              d.mock_sent_at = sent;
              if (d.mocks.length && d.mocks[d.mocks.length - 1]) {
                d.mocks[d.mocks.length - 1].is_sent = sent; // Change "is_sent" attribute for a last mock after it is sent to the customer.
              }
            }
            return d;
          });
        }
        return o;
      });
    },
    updateTerms(state, { model, orderId, terms }) {
      state[model] = state[model].map(o => {
        if (o.id === orderId) {
          o.payment_term = terms;
        }
        return o;
      });
    },
    updateMyEstimatesDesignProgress(state, { estimateId, designId, progress }) {
      state.my_estimates = state.my_estimates.map(o => {
        if (o.id === estimateId) {
          o.designs = o.designs.map(d => {
            if (d.id === designId) {
              progressFieldsList.forEach(progressName => {
                if (progressName in progress) {
                  d[progressName] = progress[progressName];
                }
                if (d.original_files_link != progress.original_files_link)
                  d.original_files_link = progress.original_files_link;
                if (d.print_ready_files_link != progress.print_ready_files_link)
                  d.print_ready_files_link = progress.print_ready_files_link;
              });
            }
            return d;
          });
        }
        return o;
      });
    },
    updateIsViewedStatus(state, { estimateId }) {
      state.my_estimates = state.my_estimates.map(estimate => {
        if (estimate.id === estimateId) {
          estimate.is_viewed = true;
        }
      });
    },
    updateIsApprovedStatus(state, { estimateId, key }) {
      state[key] = state[key].map(estimate => {
        if (estimate.id === estimateId) {
          estimate.is_approved = true;
          estimate.estimate_sent_at = moment.now();
        }
        return estimate;
      });
    },
    updateEstimateSentAtDate(state, { estimateId }) {
      state.estimates = state.estimates.map(estimate => {
        if (estimate.id === estimateId) {
          estimate.is_revision_requested = false;
          estimate.is_declined = false;
          estimate.is_viewed = false;
          estimate.estimate_sent_at = moment.now();
        }
        return estimate;
      });
    },
    setStateValue(state, { prop, val }) {
      state[prop] = val;
    },
    dropRow(state, { model, id }) {
      state[model] = state[model].filter(order => order.id !== id);
    },
    setDesignTrackings(state, { orderId, designId, trackings }) {
      state.my_orders = state.my_orders.map(o => {
        if (+o.id === +orderId) {
          o.designs.map(d => {
            if (+d.id === designId) {
              d.trackings = trackings;
            }
            return d;
          });
        }
        return o;
      });
    }
  },
  actions: {
    [LOAD_MY_ORDERS]: ({ commit, dispatch }, filters) => {
      commit('setStateValue', { prop: 'my_orders_loading', val: true });
      let url = null;

      if (filters) {
        url = filters.url;
        delete filters.url;
      }

      return apiService
        .getMyOrders(filters, url)
        .then(response => {
          const orders = cloneDeep(response.orders.data);
          const ordersId = orders.map(order => order.id);

          commit('setStateValue', { prop: 'my_orders', val: orders });

          delete response.orders.data;
          commit('setStateValue', {
            prop: 'my_orders_paginator_data',
            val: response.orders
          });

          commit('setStateValue', {
            prop: 'filter_options',
            val: response.filter_options
          });
          commit('setStateValue', {
            prop: 'actual_filters',
            val: response.actual_filters
          });

          dispatch(LOAD_ORDERS_DESIGNS, {
            ordersId: ordersId,
            stateName: 'my_orders'
          });

          commit('setStateValue', { prop: 'my_orders_loading', val: false });
        })
        .catch(err => {
          notify({
            message: 'Could not load orders list',
            type: 'danger'
          });
        });
    },
    [LOAD_ACTIVE_ORDERS]: ({commit}, filters) => {
      commit('setStateValue', { prop: 'grid_loading', val: true });
      let url = null;

      if (filters) {
        url = filters.url;
        delete filters.url;
      }
      apiService.getOrderServices().then(data => {
        commit('setStateValue', {prop: 'services', val: data});
      });

      return apiService
        .getDashboardActiveOrders(filters, url)
        .then(response => {
          const orders = cloneDeep(response.designs.data);
          commit('setStateValue', { prop: 'grid_data', val: orders });
          delete response.designs.data;
          commit('setStateValue', {prop: 'pagination_data', val: response.designs});
          commit('setStateValue', {prop: 'filter_options', val: response.filter_options});
          commit('setStateValue', {prop: 'actual_filters', val: response.actual_filters});
          commit('setStateValue', {prop: 'grid_loading', val: false});
        })
        .catch(err => {
          notify({
            message: 'Could not load active orders list',
            type: 'danger'
          });
        });
    },
    [LOAD_UNCLAIMED_ESTIMATES]: ({ commit }, pagination) => {
      commit('setStateValue', {prop: 'unclaimed_estimates_loading', val: true});

      if (!pagination) {
        pagination = {};
      }

      let page = pagination.page;
      let url = pagination.url;
      let perPage = pagination.perPage;
      let sortField = pagination.sortField;
      let sortDir = pagination.sortDir;

      if (!page) page = 1;

      if (!url) url = `/api/orders/unclaimed_estimates?page=${page}`;

      if (perPage) {
        url += `&perPage=${perPage}`;
      }

      if (sortField) {
        sortField = encodeURIComponent(sortField);
        url += `&sortField=${sortField}`;
        url += `&sortDir=${sortDir}`;
      }

      return Vue.http
        .get(url)
        .then(response => {
          commit('setStateValue', {
            prop: 'unclaimed_estimates',
            val: response.data.data
          });

          delete response.data.data;
          commit('setStateValue', {
            prop: 'unclaimed_estimates_paginator_data',
            val: response.data
          });
          commit('setStateValue', {
            prop: 'unclaimed_estimates_loading',
            val: false
          });
        })
        .catch(err => {
          notify({
            message: 'Could not load estimates list',
            type: 'danger'
          });
        });
    },
    [LOAD_UNCLAIMED_BLANKS]: ({ commit, dispatch }, pagination) => {
      commit('setStateValue', { prop: 'unclaimed_blanks_loading', val: true });

      if (!pagination) {
        pagination = {};
      }

      let page = pagination.page;
      let url = pagination.url;
      let perPage = pagination.perPage;
      let sortField = pagination.sortField;
      let sortDir = pagination.sortDir;

      if (!page) page = 1;

      if (!url) url = `/api/orders/unclaimed_blanks?page=${page}`;

      if (perPage) {
        url += `&perPage=${perPage}`;
      }

      if (sortField) {
        sortField = encodeURIComponent(sortField);
        url += `&sortField=${sortField}`;
        url += `&sortDir=${sortDir}`;
      }

      return Vue.http
        .get(url)
        .then(response => {
          const orders = cloneDeep(response.data.data);
          const ordersId = orders.map(order => order.id);

          commit('setStateValue', { prop: 'unclaimed_blanks', val: orders });

          delete response.data.data;
          commit('setStateValue', {
            prop: 'unclaimed_blanks_paginator_data',
            val: response.data
          });
          dispatch(LOAD_ORDERS_DESIGNS, {
            ordersId: ordersId,
            stateName: 'unclaimed_blanks'
          });
          commit('setStateValue', {
            prop: 'unclaimed_blanks_loading',
            val: false
          });
        })
        .catch(err => {
          notify({
            message: 'Could not load blanks list',
            type: 'danger'
          });
        });
    },
    [LOAD_UNCLAIMED_MOCKS]: ({ commit, dispatch }, pagination) => {
      commit('setStateValue', { prop: 'unclaimed_mocks_loading', val: true });

      if (!pagination) {
        pagination = {};
      }

      let page = pagination.page;
      let url = pagination.url;
      let perPage = pagination.perPage;
      let sortField = pagination.sortField;
      let sortDir = pagination.sortDir;

      if (!page) page = 1;

      if (!url) url = `/api/orders/unclaimed_mocks?page=${page}`;

      if (perPage) {
        url += `&perPage=${perPage}`;
      }

      if (sortField) {
        sortField = encodeURIComponent(sortField);
        url += `&sortField=${sortField}`;
        url += `&sortDir=${sortDir}`;
        console.log(sortField, sortDir, url);
      }

      return Vue.http
        .get(url)
        .then(response => {
          const orders = cloneDeep(response.data.data);
          const ordersId = orders.map(order => order.id);

          commit('setStateValue', { prop: 'unclaimed_mocks', val: orders });

          delete response.data.data;
          commit('setStateValue', {
            prop: 'unclaimed_mocks_paginator_data',
            val: response.data
          });
          commit('setStateValue', {
            prop: 'unclaimed_mocks_loading',
            val: false
          });
          dispatch(LOAD_ORDERS_DESIGNS, {
            ordersId: ordersId,
            stateName: 'unclaimed_mocks'
          });
        })
        .catch(err => {
          notify({
            message: 'Could not load mocks list',
            type: 'danger'
          });
        });
    },
    [LOAD_ESTIMATES]: ({ commit, dispatch }, filters) => {
      commit('setStateValue', { prop: 'estimates_loading', val: true });
      let url = '';
      if (filters) {
        url = filters.url;
        delete filters.url;
      }

      return apiService
        .getEstimates(filters, url)
        .then(response => {
          const estimates = cloneDeep(response.estimates.data);
          commit('setStateValue', {prop: 'estimates', val: estimates});
          delete response.estimates.data;
          commit('setStateValue', {prop: 'estimates_paginator_data', val: response.estimates});
          commit('setStateValue', {prop: 'filter_options', val: response.filter_options});
          commit('setStateValue', {prop: 'actual_filters', val: response.actual_filters});
        })
        .catch(err => {
          notify({
            message: 'Could not load estimates list',
            type: 'danger'
          });
        })
        .finally(() => {
          commit('setStateValue', {prop: 'estimates_loading', val: false});
        });
    },
    [LOAD_MY_ESTIMATES]: ({ commit, dispatch }, filters) => {
      commit('setStateValue', { prop: 'my_estimates_loading', val: true });
      let url = `orders/my_estimates`;

      if (filters) {
        delete filters.url;
      }
      return apiService
        .getEstimates(filters, url)
        .then(response => {
          const estimates = cloneDeep(response.estimates.data);
          commit('setStateValue', {prop: 'my_estimates', val: estimates});
          delete response.estimates.data;
          commit('setStateValue', {prop: 'my_estimates_paginator_data', val: response.estimates});
          commit('setStateValue', {prop: 'filter_options', val: response.filter_options});
          commit('setStateValue', {prop: 'actual_filters', val: response.actual_filters});
        })
        .catch(err => {
          notify({
            message: 'Could not load estimates list',
            type: 'danger'
          });
        })
        .finally(() => {
          commit('setStateValue', { prop: 'my_estimates_loading', val: false });
        });
    },
    [SET_MY_ORDERS_SURVEY_DATE]: ({ commit }, orderId) => {
      commit('setMyOrdersSurveyDate', orderId);
    },
    [SET_MY_ORDERS_MOCK_SENT_DATE]: (
      { commit },
      { orderId, designId, sent }
    ) => {
      commit('setMyOrdersMockSentDate', { orderId, designId, sent });
    },
    [SET_ESTIMATE_IS_APPROVED]: ({commit}, {estimateId, key}) => {
      commit('updateIsApprovedStatus', {estimateId, key});
    },
    [SET_ESTIMATE_SENT_DATE]: ({commit}, {estimateId}) => {
      commit('updateEstimateSentAtDate', {estimateId});
    },
    [UPDATE_MY_ORDERS_PAYMENT_STATUS]: ({ commit }, { orderId, status }) => {
      return apiService
        .updateOrderPaymentStatus(status, orderId)
        .then(data => {
          commit('updateMyOrdersPaymentStatus', { orderId, status });
          notify({
            message: data.message,
            type: data.status
          });
        })
        .catch(errorNotify);
    },
    [UPDATE_MY_DESIGNS_BLANK_STATUS]: (
      { commit, dispatch },
      { designId, status }
    ) => {
      return apiService
        .updateDesignBlankStatus(status, designId)
        .then(data => {
          commit('updateMyDesignsBlankStatus', { designId, status });
          notify({ message: data.message, type: data.status });
          dispatch(UPDATE_MY_ORDERS_DESIGN_PROGRESS, {
            orderId: data.design.order_id,
            designId: data.design.id,
            progress: data.design
          });
        })
        .catch(errorNotify);
    },
    [UPDATE_MY_ORDERS_DESIGN_PRINTER]: (
      { commit, dispatch },
      { orderId, designId, printer, design }
    ) => {
      commit('updateMyOrdersDesignPrinter', { orderId, designId, printer });
      if (design) {
        dispatch(UPDATE_MY_ORDERS_DESIGN_PROGRESS, {
          orderId,
          designId,
          progress: design
        });
      }
    },
    [UPDATE_MY_ORDERS_DESIGN_FOLLOW_UP_STATUS]: (
      { commit, dispatch },
      { orderId, designId, status, design, newDesign }
    ) => {
      commit('updateMyOrdersDesignFollowUpStatus', {
        orderId,
        designId,
        status,
        newDesign
      });

      if (design) {
        dispatch(UPDATE_MY_ORDERS_DESIGN_PROGRESS, {
          orderId,
          designId,
          progress: design
        });
      }
    },
    [UPDATE_MY_ORDERS_DESIGN_MOCKS_COUNT]: (
      { commit },
      { orderId, designId, mock }
    ) => {
      commit('updateMyOrdersDesignMocksCount', { orderId, designId, mock });
    },
    [UPDATE_MY_ORDERS_PRINTER_STATUS]: (
      { commit, dispatch },
      { orderId, itemId, status, modelType, model }
    ) => {
      if (modelType === 'design') {
        commit('updateMyOrdersDesignPrinterStatus', {
          orderId,
          designId: itemId,
          status
        });
      }

      if (model) {
        switch (modelType) {
          case 'design':
            dispatch(UPDATE_MY_ORDERS_DESIGN_PROGRESS, {
              orderId,
              designId: itemId,
              progress: model
            });
            break;
          case 'finishing':
            dispatch(UPDATE_MY_ORDERS_FINISHING_PROGRESS, {
              orderId,
              finishingId: itemId,
              progress: model
            });
            break;
        }
      }
    },
    [CLAIM]: ({ commit, dispatch }, { orderId, subject, vue }) => {
      return apiService
        .claim(orderId, subject)
        .then(data => {
          if (data.status === 'success') {
            notify({
              message: data.message,
              type: 'success'
            });
            commit('dropRow', { model: `unclaimed_${subject}s`, id: orderId });
            dispatch(RESUBSCRIBE_PUBNUB, vue);
          } else {
            notify({
              message: data.message,
              type: 'danger'
            });
          }
        })
        .catch(errorNotify);
    },
    [IS_VIEWED]: ({ commit, dispatch }, { estimateId, vue }) => {
      return apiService
        .viewed(estimateId)
        .then(data => {
          if (data.status === 'success') {
            commit('updateIsViewedStatus', { estimateId: estimateId });
            dispatch(RESUBSCRIBE_PUBNUB, vue);
          } else {
            notify({
              message: data.message,
              type: 'danger'
            });
          }
        })
        .catch(errorNotify);
    },
    [SET_ORDER_STATUS]: (
      { commit, state },
      { model, status, orderId, status_reason }
    ) => {
      if (status_reason === undefined) status_reason = {};
      return apiService
        .setOrderStatus(status, orderId, status_reason)
        .then(data => {
          notify({
            message: data.message,
            type: data.status
          });
          if (status !== 'close_lost_order' && model !== 'estimates') {
            commit('dropRow', {model, id: orderId});
          }
        })
        .catch(errorNotify);
    },
    [SET_ORDER_TERMS]: ({ commit, state }, { model, terms, orderId }) => {
      return apiService
        .setOrderTerms(orderId, terms)
        .then(data => {
          notify({
            message: data.message,
            type: data.status
          });

          commit('updateTerms', { model, orderId, terms });
        })
        .catch(errorNotify);
    },
    [SEND_ESTIMATE]: ({ commit, dispatch, state }, orderId) => {
      return apiService
        .sendEstimate(orderId)
        .then(data => {
          notify({
            message: data.message,
            type: data.status
          });
          dispatch(LOAD_MY_ESTIMATES, state.actual_filters );
        })
        .catch(errorNotify);
    },
    [SET_MY_ORDERS_PHASE]: ({ commit }, phase) => {
      commit('setStateValue', { prop: 'my_orders_phase', val: phase });
    },
    [SET_ACTIVE_ORDERS_PHASE]: ({ commit }, phase) => {
      commit('setStateValue', { prop: 'my_orders_phase', val: phase });
    },
    [UPDATE_MY_ORDERS_DESIGN_SETUP_PROGRESS]: (
      { commit },
      { orderId, designId, progress }
    ) => {
      commit('updateMyOrdersDesignPhaseProgress', {
        orderId,
        designId,
        progressName: 'progress_setup',
        progress
      });
    },
    [UPDATE_MY_ORDERS_DESIGN_BLANK_PROGRESS]: (
      { commit },
      { orderId, designId, progress }
    ) => {
      commit('updateMyOrdersDesignPhaseProgress', {
        orderId,
        designId,
        progressName: 'progress_blank',
        progress
      });
    },
    [UPDATE_MY_ORDERS_DESIGN_MOCK_PROGRESS]: (
      { commit },
      { orderId, designId, progress }
    ) => {
      commit('updateMyOrdersDesignPhaseProgress', {
        orderId,
        designId,
        progressName: 'progress_mock',
        progress
      });
    },
    [UPDATE_MY_ORDERS_DESIGN_PRINT_PROGRESS]: (
      { commit },
      { orderId, designId, progress }
    ) => {
      commit('updateMyOrdersDesignPhaseProgress', {
        orderId,
        designId,
        progressName: 'progress_print',
        progress
      });
    },
    [UPDATE_MY_ORDERS_DESIGN_SHIPMENT_PROGRESS]: (
      { commit },
      { orderId, designId, progress }
    ) => {
      commit('updateMyOrdersDesignPhaseProgress', {
        orderId,
        designId,
        progressName: 'progress_shipment',
        progress
      });
    },
    [UPDATE_MY_ORDERS_DESIGN_FINISH_PROGRESS]: (
      { commit },
      { orderId, designId, progress }
    ) => {
      commit('updateMyOrdersDesignPhaseProgress', {
        orderId,
        designId,
        progressName: 'progress_finish',
        progress
      });
    },
    [UPDATE_MY_ORDERS_DESIGN_PROGRESS]: (
      { commit },
      { orderId, designId, progress }
    ) => {
      commit('updateMyOrdersDesignProgress', {
        orderId,
        designId,
        progress
      });
    },
    [UPDATE_MY_ORDERS_FINISHING_PROGRESS]: (
      { commit },
      { orderId, finishingId, progress }
    ) => {
      commit('updateMyOrdersFinishingProgress', {
        orderId,
        finishingId,
        progress
      });
    },
    [UPDATE_MY_ESTIMATES_DESIGN_PROGRESS]: ({ commit }, payload) => {
      commit('updateMyEstimatesDesignProgress', payload);
    },
    [UPDATE_MY_ESTIMATES_FINISHING_PROGRESS]: (
      { commit },
      { orderId, finishingId, progress }
    ) => {
      commit('updateMyEstimatesFinishingProgress', {
        orderId,
        finishingId,
        progress
      });
    },
    [LOAD_ORDERS_DESIGNS]: ({ commit, dispatch }, { ordersId, stateName }) => {
      commit('setStateValue', { prop: `${stateName}_loading`, val: true });

      return apiService.getOrdersDesigns(ordersId).then(response => {
        console.log(response);
        commit('setOrdersDesigns', {
          orders: response.orders,
          stateName: stateName
        });
        commit('setStateValue', { prop: `${stateName}_loading`, val: false });
      });
    },
    [EMPTY_GRID_DATA]: ({ commit }) => {
      console.log('EMPTY_GRID_DATA')
      commit('setStateValue', { prop: `grid_data`, val: [] });
      commit('setStateValue', { prop: `pagination_data`, val: {} });
      commit('setStateValue', { prop: `actual_filters`, val: [] });
      commit('setStateValue', { prop: `grid_loading`, val: false });
    }
  }
};

export default dashboard;
