<template>
  <div class="paid-amount">
    <div class="paid-amount-content">
      <p v-if="paidAmount !== undefined">
        Amount Paid: {{paidAmount | price}}
      </p>
      <p v-if="remainAmount !== undefined">
        Balance Due: {{remainAmount | price}}
      </p>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';

export default {
  name: 'paid-and-remain-amount',
  props: ['paidAmount', 'remainAmount'],

  data() {
    return {};
  }
};
</script>
