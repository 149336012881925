import { forEach } from 'lodash';

/**
 * Calculate design total.
 *
 * @param design
 * @param formOptions
 * @returns {number}
 */
export const getDesignTotal = (design, formOptions) => {
  let itemTotal = 0;
  let item = design.specific.item;

  if (!item) {
    return itemTotal + +design.common.upcharge;
  }
  if (item && item.cost !== undefined && item.cost !== null) {
    itemTotal = parseFloat(item.cost);
  } else {
    let pricesData = item.model && {
      prices: item.model.prices || [],
      pricing_formula: item.model.pricing_formula || {
        prices: [],
        pricing_formula: ''
      }
    };
    if (!pricesData || !pricesData.pricing_formula) {
      return itemTotal + +design.common.upcharge;
    }

    let size = item.size;
    let quantity = item.quantity;
    let num_colors = item.number_of_colors;
    switch (pricesData.pricing_formula) {
      case 'quantity_size':
        let sizePrice = pricesData.prices.find(
          p => p.size === size && checkRange(p.quantity, quantity)
        );
        if (sizePrice) {
          itemTotal = parseFloat(sizePrice.price) * +item.quantity;
        }
        break;
      case 'quantity_per_item_size':
        const isPetProduct = item.model.product_type && item.model.product_type.name === 'Pet Products';
        const isPetToys = isPetProduct && item.model.product_category && item.model.product_category.name === 'Toys';
        const isBlankets = item.model.product_type && item.model.product_type.name === 'Blankets';
        // todo: add sort by quantity
        let sizeNumColorPrice = pricesData.prices.filter(
          p => {
            if (isBlankets) {
              return p.number_of_colors === size && checkRange(p.quantity, quantity);
            } else {
              return ((isPetProduct && !isPetToys) || p.size === size) && p.number_of_colors === num_colors && checkRange(p.quantity, quantity);
            }
          }).pop();
        if (sizeNumColorPrice) {
          itemTotal = parseFloat(sizeNumColorPrice.price) * +item.quantity;
        }
        break;
      case 'quantity_per_item_fixed':
        let quantityPrice = pricesData.prices.find(p =>
          checkRange(p.quantity, quantity)
        );
        if (quantityPrice) {
          itemTotal =
            parseFloat(quantityPrice.price) * +item.quantity +
            parseFloat(quantityPrice.fixed_1) +
            parseFloat(quantityPrice.fixed_2);
        }
        break;
    }
    itemTotal += getAddonsTotal(design, formOptions);
  }

  return itemTotal + +design.common.upcharge;
};

/**
 * Calculate design total.
 *
 * @param design
 * @param formOptions
 * @returns {number}
 */
export const getAddonsTotal = (design, formOptions) => {
  return design.specific.item.addons.reduce((acc, curr) => {
    let quantity = +design.specific.item.quantity;
    let number_of_colors = parseInt(design.specific.item.number_of_colors);
    switch (curr.pricing_formula) {
      case 'quantity_per_item_fixed':
        acc +=
          quantity * parseFloat(curr.price) +
          parseFloat(curr.fixed_1) +
          parseFloat(curr.fixed_2);
        break;
      case 'per_color':
        acc += number_of_colors * parseFloat(curr.price) * quantity;
        break;
      default:
        acc += quantity * parseFloat(curr.price);
        break;
    }

    return acc;
  }, 0);
};

export const findProductPrice = (item, formOptions) => {
  let itemPrice = 0;
  if (!item) {
    return itemPrice;
  }
  let pricesData = item.model && {
    prices: item.model.prices || [],
    pricing_formula: item.model.pricing_formula || {
      prices: [],
      pricing_formula: ''
    }
  };
  if (!pricesData || !pricesData.pricing_formula) {
    return itemPrice;
  }
  let size = item.size;
  let quantity = item.quantity;
  let num_colors = item.number_of_colors;
  switch (pricesData.pricing_formula) {
    case 'quantity_size':
      let sizePrice = pricesData.prices.find(
        p => p.size === size && checkRange(p.quantity, quantity)
      );
      if (sizePrice) {
        itemPrice = parseFloat(sizePrice.price);
      }
      break;
    case 'quantity_per_item_size':
      const isPetProduct = item.model.product_type && item.model.product_type.name === 'Pet Products';
      const isPetToys = isPetProduct && item.model.product_category && item.model.product_category.name === 'Toys';
      const isBlankets = item.model.product_type && item.model.product_type.name === 'Blankets';
      let sizeNumColorPrice = pricesData.prices.filter(
        p => {
          if (isBlankets) {
            return p.number_of_colors === size && checkRange(p.quantity, quantity);
          } else {
            return ((isPetProduct && !isPetToys) || p.size === size) && p.number_of_colors === num_colors && checkRange(p.quantity, quantity);
          }
        }
      ).pop();
      if (sizeNumColorPrice) {
        itemPrice = parseFloat(sizeNumColorPrice.price);
      }
      break;
    case 'quantity_per_item_fixed':
      let quantityPrice = pricesData.prices.find(p =>
        checkRange(p.quantity, quantity)
      );
      if (quantityPrice) {
        itemPrice =
          parseFloat(quantityPrice.price) +
          (quantityPrice.fixed_1 !== null && quantity > 0
            ? parseFloat(quantityPrice.fixed_1) / quantity
            : 0) +
          (quantityPrice.fixed_2 !== null && quantity > 0
            ? parseFloat(quantityPrice.fixed_2) / quantity
            : 0);
      }
      break;
  }
  return itemPrice;
};

/**
 * check if number in qty range.
 *
 * @param minMax
 * @param qty
 * @returns {boolean}
 */
export const checkRange = (minMax, qty) => {
  minMax = minMax.toString().split('-');
  return !!(minMax.length === 1 && qty >= +minMax[0].replace(/[+|,]/g, '')) ||
  !!(+minMax[0].replace(',', '') <= +qty && +minMax[1].replace(/[+|,]/g, '') >= +qty);
};
