export const TRANSACTION_STATUSES = {
  0: 'Paid',
  1: 'Paid',
  2: 'Failed'
};

export const PAYMENT_METHODS = [
  {
    key: 1,
    label: 'Authorize.net'
  },
  {
    key: 2,
    label: 'Bank Transfer'
  },
  {
    key: 3,
    label: 'PayPal'
  },
  {
    key: 4,
    label: 'Credit'
  },
  {
    key: 5,
    label: 'Credit card'
  },
  {
    key: 6,
    label: 'Cash'
  },
  {
    key: 7,
    label: 'Debit'
  }
];
