<template>
  <tr>
    <td>
      <invoice-field
        label="Product"
        :value="item.model.name"
      />
    </td>
    <td>
      <invoice-field
        :label="productAddonName"
        :value="item.addons.product_addon_name"
      />
    </td>
    <td>
      <invoice-field
        v-if="item.size === 'empty_size' && item.custom_width && item.custom_height"
        label="Size (Custom)"
        :value="`${ item.custom_width } x ${ item.custom_height }`"
      ></invoice-field>
      <invoice-field
        v-else
        label="Size"
        :value="item.size"
      />
    </td>
    <td>
      <invoice-field
        label="Quantity"
        :value="item.quantity"
      ></invoice-field>
    </td>
    <td>
      <invoice-field
        label="Price"
        :value="parseFloat(item.cost)/parseFloat(item.quantity) | price"
      />
    </td>
  </tr>
</template>
<script>
import {getOptionLabel} from "@/helpers/estimates";
import InvoiceField from '@/components/common/invoice-field.vue';

export default {
  name: 'StickerPrintingInvoiceItem',
  components: {InvoiceField},
  props: {
    item: {
      type: Object,
      required: true
    },
    formOptions: {
      type: Object,
      required: true
    },
    totalPrice:
    {
      type: String,
      required: true
    }
  },
  methods: {
    getOptionLabel,
  },
  computed: {
    productAddonName() {
      const productAddons = this.formOptions.options[this.item.product];
      let label = '';
      Object.keys(productAddons).forEach(key => {
        productAddons[key].forEach(item => {
            if (item.id === this.item.addons.id) {
              label = key
            }
          }
        )
      })
      return label
    }
  },
}
</script>
