<template>
  <!--  @deprecated-->
    <div>
        There are no prices options
    </div>
</template>


<script>
    import Vue from 'vue';

    export default {
        name: 'accounts-other-custom-prices'
    }
</script>
