<template>
  <div>
    <h3 class="title is-3">Discount</h3>
    <fieldset class="discount-fields control">
      <div class="columns">
        <div class="column is-4">
          <form-input :name="`discount_amount`"
                      label="Amount"
                      v-model="discount.discount_amount"
                      :scope="scope"
                      type="number" :rules="`min_value:0|max_value:${originalPrice}`"
                      step="0.01" min="0.00"
                      placeholder="$0.00"
                      @input="setDiscountFieldForCalculation('discount_amount')"
                      :required="false"/>
        </div>
        <div class="column is-4">
          <form-input :name="`discount_percentage`"
                      label="Percentage"
                      v-model="discount.discount_percentage"
                      :scope="scope"
                      type="number" :rules="`min_value:0|max_value:100`"
                      step="0.01" min="0.00"
                      placeholder="0%"
                      @input="setDiscountFieldForCalculation('discount_percentage')"
                      :required="false"/>
        </div>
      </div>
      <div class="columns">
        <div class="column is-8">
          <form-input :name="`discount-description`"
                      label="Discount"
                      v-model="discount.discount_description"
                      :scope="scope"
                      placeholder="Enter description..."
                      rules="max:255"
                      @input="discountChanged"
                      :required="false"/>
        </div>
      </div>
    </fieldset>
    <div class="control">
      <button :disabled="isApplyDisabled"
              type="button" class="button" @click.prevent="applyDiscount">Apply
      </button>
      <button :disabled="!reapply" type="button" class="button" @click.prevent="resetDiscount">Reset
      </button>
    </div>
    <hr>
  </div>
</template>
<script>
import { cloneDeep } from 'lodash';
import alertify from 'alertify.js';
import FormInput from '../../components/common/form/input';

export default {
  name: 'discount',
  components: { FormInput },
  props: {
    scope: {
      type: String,
      default: 'form-scope'
    },
    totalPrice: {
      type: Number,
      default: 0
    },
    totalDiscount: {
      type: Number,
      default: 0
    },
    originalPrice: {
      type: Number,
      default: 0
    },
    modelDiscount: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      discount: cloneDeep(this.modelDiscount),
      reapply: false
    };
  },
  watch: {
    modelDiscount: {
      handler: function(newDiscount) {
        this.$set(this, 'discount', cloneDeep(newDiscount));
      },
      deep: true
    },
    originalPrice(newPrice) {
      if (!this.reapply) {
        this.calculateDiscount();
        this.applyDiscount();
      } else {
        this.calculateDiscount();
        if (newPrice <= 0) {
          this.applyDiscount();
        }
      }
    }
  },
  computed: {
    isApplyDisabled() {
      return (
        this.originalPrice <= 0 ||
        (this.originalPrice > 0 && !this.reapply) ||
        this.discount.discount_amount > this.originalPrice
      );
    }
  },
  methods: {
    setDiscountFieldForCalculation(field) {
      this.$set(this.discount, 'discount_field_for_calculation', field);
      this.$nextTick(() => {
        this.calculateDiscount();
      });
    },
    calculateAmount(v) {
      let discount_amount = +v / 100 * +this.originalPrice;
      this.$set(
        this.discount,
        'discount_amount',
        Number(discount_amount).toFixed(2) * 1
      );
      this.discountChanged();
    },
    calculatePercentage(v) {
      let discount_percentage =
        this.originalPrice > 0 ? +v * 100 / +this.originalPrice : 0;
      this.$set(
        this.discount,
        'discount_percentage',
        Number(discount_percentage).toFixed(2) * 1
      );
      this.discountChanged();
    },
    discountChanged() {
      this.$set(this, 'reapply', this.originalPrice > 0);
    },
    applyDiscount() {
      let discount = cloneDeep(this.discount);
      this.$emit('applyDiscount', discount);
      this.$nextTick(() => {
        this.$set(this, 'reapply', false);
      });
    },
    calculateDiscount() {
      if (this.originalPrice <= 0) {
        this.$set(this.discount, 'discount_amount', 0);
        this.$set(this.discount, 'discount_percentage', 0);
      }
      switch (this.discount.discount_field_for_calculation) {
        case 'discount_amount':
          this.calculatePercentage(this.discount.discount_amount);
          break;
        case 'discount_percentage':
          this.calculateAmount(this.discount.discount_percentage);
          break;
      }
    },
    resetDiscount() {
      this.$set(this, 'discount', cloneDeep(this.modelDiscount));
      this.$set(this, 'reapply', false);
    }
  },
  mounted() {}
};
</script>
<style scoped>
.discount-fields {
  margin-bottom: 25px;
}
</style>
