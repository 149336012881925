import { get } from 'lodash';

export const createMultiselectModel = (
  formPath,
  fieldName,
  optionsPath,
  trackBy,
  groupOptionsField
) => {
  return {
    get() {
      const form = get(this, formPath);
      const fieldValue = form[fieldName];
      if (fieldValue === null) return null;
      let options = get(this, optionsPath);
      if (groupOptionsField) {
        options = options.reduce(
          (list, group) => list.concat(group[groupOptionsField]),
          []
        );
      }
      return (
        options.find(option => form[fieldName] === option[trackBy]) || null
      );
    },
    set(val) {
      const form = get(this, formPath);
      form[fieldName] = val[trackBy];
    }
  };
};

export const naturalSort = function(array) {
  let a,
    b,
    a1,
    b1,
    rx = /(\d+)|(\D+)/g,
    rd = /\d+/;
  return array.sort(function(as, bs) {
    a = String(as)
      .toLowerCase()
      .match(rx);
    b = String(bs)
      .toLowerCase()
      .match(rx);
    while (a.length && b.length) {
      a1 = a.shift();
      b1 = b.shift();
      if (rd.test(a1) || rd.test(b1)) {
        if (!rd.test(a1)) return 1;
        if (!rd.test(b1)) return -1;
        if (a1 !== b1) return a1 - b1;
      } else if (a1 !== b1) return a1 > b1 ? 1 : -1;
    }
    return a.length - b.length;
  });
};
