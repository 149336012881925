<template>
  <div>
    <div class="columns">
      <div class="column is-4 input-cntr">
        <label class="label" for="date_from"  style="margin-bottom: 0">From</label>
        <div class="control">
          <datepicker
            v-model="date_from"
            :config="{dateFormat: 'm/d/Y', wrap: true }"
            @input="gridUpdateOrders"
            id="date_from"
          />
        </div>
      </div>
      <div class="column is-4 input-cntr">
        <label class="label" for="date_to" style="margin-bottom: 0">To</label>
        <div class="control">
          <datepicker
            v-model="date_to"
            :config="{dateFormat: 'm/d/Y', wrap: true }"
            @input="gridUpdateOrders"
            id="date_to"
          />
        </div>
      </div>
    </div>
    <grid :orders="gridData"
          :columns="gridHeaders"
          :loading="rushFeeOrdersLoading"
          noResMsg="No Rush Fee Data"
          :showTriangle="false"
          @onGridSortBy="gridUpdateOrders"></grid>

    <paginator v-if="rushFeeOrders.length"
               :pagesNumber="rushFeeOrdersPaginatorData.last_page"
               :prevPageUrl="rushFeeOrdersPaginatorData.prev_page_url"
               :nextPageUrl="rushFeeOrdersPaginatorData.next_page_url"
               :currentPage="rushFeeOrdersPaginatorData.current_page"
               :getListItems="loadRushFeeOrdersPaginated"
               :showOffset="true"
               :totalNumber="rushFeeOrdersPaginatorData.total"
               :sortField="sortField"
               :sortDir="sortDir"></paginator>

    <v-dialog></v-dialog>
  </div>
</template>

<script>
import Vue from 'vue';
import { mapGetters, mapActions, mapMutations } from 'vuex';
import Grid from '../../common/grid/grid';
import Paginator from '../../common/paginator';
import { LOAD_ORDERS_REPORTS_DATA } from '../../../store/action-types';

import { gridHeaders, getGridData } from '../../../helpers/reports/rush-fee';

import apiService from '../../../services/api.service';
import alertify from 'alertify.js';
import Datepicker from '../../../modules/datepicker/index';

export default {
  name: 'rush-fee',
  components: { Paginator, Grid, Datepicker },
  data() {
    return {
      gridHeaders,
      sortField: 'estimate_becomes_to_order_at',
      sortDir: 'DESC',
      page: 1,
      perPage: 50,
      date_from: null,
      date_to: null
    };
  },
  watch: {
    date_from() {
      this.page = 1;
      this.$nextTick(() => {
        this.gridUpdateOrders();
      });
    },
    date_to() {
      this.page = 1;
      this.$nextTick(() => {
        this.gridUpdateOrders();
      });
    }
  },
  computed: {
    ...mapGetters([
      'rushFeeOrders',
      'rushFeeOrdersPaginatorData',
      'rushFeeOrdersLoading',
      'roles'
    ]),
    gridData() {
      return {
        data: getGridData(this.rushFeeOrders, this.roles, {})
      };
    }
  },
  methods: {
    ...mapActions({
      loadOrdersReportsData: LOAD_ORDERS_REPORTS_DATA
    }),
    ...mapMutations(['dropRow']),
    loadRushFeeOrdersPaginated(page, url, perPage, sortField, sortDir) {
      // Update values in component to populate it for other methods. For example - "gridUpdateOrders"
      this.page = page;
      this.perPage = perPage;
      return this.loadOrdersReportsData({
        model: 'rush_fee',
        filters: {
          date_from: this.date_from,
          date_to: this.date_to
        },
        pagination: {
          page,
          url,
          perPage,
          sortField,
          sortDir
        }
      });
    },

    // Update orders in store on orders sorting.
    gridUpdateOrders(sortField, sortDir) {
      // Update values in component to populate it for "loadRushFeePaginated"
      this.sortField = sortField;
      this.sortDir = sortDir;

      // Get default or updated by "loadRushFeePaginated" method.
      let page = this.page;
      let perPage = this.perPage;

      this.loadOrdersReportsData({
        model: 'rush_fee',
        filters: {
          date_from: this.date_from,
          date_to: this.date_to
        },
        pagination: {
          page,
          perPage,
          sortField,
          sortDir
        }
      });
    }
  },
  created() {
    this.gridUpdateOrders(this.sortField, this.sortDir);
  }
};
</script>
