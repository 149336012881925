<template xmlns="http://www.w3.org/1999/html">
  <form @submit.prevent="submitForm" id="company-edit-form">
    <!-- Contact Information -->
    <fieldset class="control main-control">
      <div class="columns">
        <div class="column is-4">
          <form-input v-model="model.name"
                      name="name"
                      scope="company-form"
                      rules="required"
                      label="Company Name"/>
        </div>
        <div class="column is-3">
          <form-select v-model="model.terms"
                       name="term"
                       scope="company-form"
                       :options="{all: terms}"
                       :addEmpty="false"/>
        </div>
        <div class="column is-2">
          <form-input v-model="model.tax_id"
                      name="tax_id"
                      scope="company-form"
                      :required="false"
                      rules=""
                      label="Tax Id"/>
        </div>
        <div class="column is-2">
          <form-input v-model="model.avatax_id"
                      name="avatax_id"
                      scope="company-form"
                      :required="false"
                      rules=""
                      label="AvaTax Id"/>
        </div>
      </div>
    </fieldset>

    <fieldset class="control">
      <!-- Shipping Address -->
      <fieldset class="shipping-address control">
        <h5 class="subtitle">Shipping Address</h5>
        <div class="columns">
          <div class="column">
            <form-select v-model="model.shipping_address.country_code"
                         name="country_code"
                         scope="company-form-shipping-address"
                         label="Country"
                         :options="countriesOptions"/>
          </div>
          <div class="column">
            <form-select v-if="statesCount['shipping_address'] != 0"
                         v-model="model.shipping_address.state_code"
                         scope="company-form-shipping-address"
                         name="state_code"
                         label="State/Province"
                         :options="statesOptions('shipping_address')"/>
            <form-input v-else v-model="model.shipping_address.state_code"
                        name="state_code"
                        scope="company-form-shipping-address"
                        label="State/Province Code"/>
          </div>
          <div class="column">
            <form-input v-model="model.shipping_address.city"
                        name="city"
                        scope="company-form-shipping-address"
                        label="City"/>
          </div>
        </div>
        <div class="columns">
          <div class="column is-5">
            <form-input v-model="model.shipping_address.address_line_1"
                        name="address_line_1"
                        scope="company-form-shipping-address"
                        label="Address line 1"/>
          </div>
          <div class="column is-5">
            <form-input v-model="model.shipping_address.address_line_2"
                        name="address_line_2"
                        scope="company-form-shipping-address"
                        label="Address line 2"
                        :required="false"
                        rules=""/>
          </div>
          <div class="column is-2">
            <form-input v-model="model.shipping_address.postal_code"
                        name="postal_code"
                        scope="company-form-shipping-address"
                        label="Postal Code"/>
          </div>
        </div>
      </fieldset>

      <div class="control">
        <br>
        <label class="">
          <input v-model="billing_address_same"
                 @click="cloneShippingToBilling"
                 type="checkbox"
                 class="checkbox">
          Billing Address Same as Shipping
        </label>
      </div>
      <br>
      <!-- END Shipping Address -->

      <!-- Billing Address -->
      <fieldset class="shipping-address control">
        <h5 class="subtitle">Billing Address</h5>
        <div class="columns">
          <div class="column">
            <form-select v-model="model.billing_address.country_code"
                         name="country_code"
                         scope="company-form-billing-address"
                         label="Country"
                         :options="countriesOptions"/>
          </div>
          <div class="column">
            <form-select v-if="statesCount['billing_address'] != 0"
                         v-model="model.billing_address.state_code"
                         scope="company-form-billing-address"
                         name="state_code"
                         label="State/Province"
                         :options="statesOptions('billing_address')"/>
            <form-input v-else v-model="model.billing_address.state_code"
                        name="state_code"
                        scope="company-form-billing-address"
                        label="State/Province Code"/>
          </div>
          <div class="column">
            <form-input v-model="model.billing_address.city"
                        name="city"
                        scope="company-form-billing-address"
                        label="City"/>
          </div>
        </div>
        <div class="columns">
          <div class="column is-5">
            <form-input v-model="model.billing_address.address_line_1"
                        name="address_line_1"
                        scope="company-form-billing-address"
                        label="Address line 1"/>
          </div>
          <div class="column is-5">
            <form-input v-model="model.billing_address.address_line_2"
                        name="address_line_2"
                        scope="company-form-billing-address"
                        label="Address line 2"
                        :required="false"
                        rules=""/>
          </div>
          <div class="column is-2">
            <form-input v-model="model.billing_address.postal_code"
                        name="postal_code"
                        scope="company-form-billing-address"
                        label="Postal Code"/>
          </div>
        </div>
      </fieldset>
      <!-- END Billing Address -->

      <div class="columns"
           v-roles="['admin','csr','production-coordinator','purchaser','art-producer','printer']">
        <div class="column">
          <form-textarea
            v-model="model.production_notes"
            name="production_notes"
            scope="company-form"
            label="Production notes"
            :required="false"
            rules=""
          ></form-textarea>
        </div>
      </div>
    </fieldset>

    <br>
    <hr>

    <div>
      <h5 class="subtitle">Add New Account</h5>
      <div v-for="(customer, key) in model.new_customers" class="content"
           :key="key">
        <account-fields
          :model="customer"
          :roles="roles"
          :options="accountOptions"></account-fields>
        <customer-fields
          :model="customer"
          :newCompanyModel="model"
          :formKey="key"
          :options="accountOptions"
          :fieldsetName="`new_customers.${key}`"
          @accountChanged="accountChanged"></customer-fields>
        <button type="button" class="button is-danger"
                @click="removeNewCustomerForm(key)">Remove
        </button>
      </div>

      <button type="button" class="button" @click="addNewCustomerForm">
        <span v-if="model.new_customers.length">Add Another Customer</span>
        <span v-else>Add New Customer</span>
      </button>
    </div>

    <hr>

    <div>
      <button type="button"
              @click="selectCustomersPopup"
              class="button">Select Customers
      </button>

      <table class="table" v-if="model.selected_customers.length">
        <thead>
        <tr>
          <th>ID</th>
          <th>Username</th>
          <th>Contact</th>
          <th>Email</th>
          <th width="20px">&nbsp;</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(entry, rowKey) in model.selected_customers">
          <td>{{entry.id}}</td>
          <td>{{entry.title}}</td>
          <td>{{entry.first_name + ' ' + entry.last_name}}</td>
          <td>{{entry.email}}</td>
          <td>
            <button type="button"
                    class="button is-danger"
                    @click="removeSelectedCustomer(rowKey)">
              Remove from Company
            </button>
          </td>
        </tr>
        </tbody>
      </table>
    </div>

    <hr>

    <div class="control control-form-actions">
      <button type="submit"
              :class="{'is-loading': processing, 'button is-primary': true} "
              :disabled="processing">{{ options.submitBtnText }}
      </button>
    </div>
  </form>
</template>

<script>
import Vue from 'vue';
import { mapGetters } from 'vuex';
import bus from '../../../../bus';
import notify from '../../../../modules/notifier';
import apiService from '../../../../services/api.service';

import accountFields from '../../../accounts/form/account-fields';
import customerFields from './customer-fields';
import popup from '../../../../modules/popupper';
import CustomersPopupComponent from '../popups/customers.vue';

//form
import formInput from '../../../common/form/input';
import formSelect from '../../../common/form/select';
import formTextarea from '../../../common/form/textarea';

import { checkRoles } from '../../../../router/checkRoles';

export default {
  name: 'company-form',
  components: {
    formInput,
    formSelect,
    accountFields,
    customerFields,
    formTextarea
  },
  data() {
    return {
      terms: [],
      countriesOptions: {},
      states: [],
      statesCount: {
        shipping_address: 0,
        billing_address: 0
      },
      billing_address_same: false,
      accountOptions: {
        hideRole: true,
        hideCompany: true
      },
      roles: [],
      emptyAccount: {
        role: 'customer',
        user: {
          first_name: '',
          last_name: '',
          title: '',
          email: '',
          phone: '',
          terms: 'request-payment',
          production_notes: '',
          company_id: ''
        },
        shipping_address: {
          address_line_1: '',
          address_line_2: '',
          city: '',
          state_code: '',
          country_code: 'US',
          postal_code: ''
        },
        billing_address: {
          address_line_1: '',
          address_line_2: '',
          city: '',
          state_code: '',
          country_code: 'US',
          postal_code: ''
        }
      }
    };
  },
  props: {
    model: {
      type: Object
    },
    processing: {
      type: Boolean
    },
    errorsList: {
      type: Array,
      default: []
    },
    options: {
      type: Object
    }
  },
  watch: {
    'model.shipping_address.country_code': {
      handler(val, oldVal) {
        this.getStates('shipping_address');
      },
      deep: false
    },
    'model.billing_address.country_code': {
      handler(val, oldVal) {
        this.getStates('billing_address');
      },
      deep: false
    },
    errorsList(errors) {
      if (errors && errors.length) {
        errors.forEach(error => {
          bus.$emit('add-error', {
            field: error.field,
            msg: error.msg,
            rule: error.rule,
            scope: error.scope,
            fullName: error.fullName
          });
        });
      }
    }
  },
  computed: {
    billing_address_same_computed() {
      let is_same = false;
      let sh_address = this.model.shipping_address;
      let bill_address = this.model.billing_address;
      let is_empty =
        sh_address.address_line_1 == '' &&
        sh_address.address_line_2 == '' &&
        sh_address.city == '' &&
        sh_address.country_code == '' &&
        sh_address.postal_code == '' &&
        sh_address.state_code == '';

      if (!is_empty) {
        is_same =
          sh_address.address_line_1 == bill_address.address_line_1 &&
          sh_address.address_line_2 == bill_address.address_line_2 &&
          sh_address.city == bill_address.city &&
          sh_address.country_code == bill_address.country_code &&
          sh_address.postal_code == bill_address.postal_code &&
          sh_address.state_code == bill_address.state_code;
      }

      return is_same;
    }
  },
  methods: {
    removeNewCustomerForm(key) {
      this.model.new_customers.splice(key, 1);
    },
    removeSelectedCustomer(key) {
      this.model.selected_customers.splice(key, 1);
    },
    selectCustomersPopup() {
      popup({
        title: 'Select customers',
        bodyComponent: CustomersPopupComponent,
        modalCardLong: true,
        showButtons: true,
        runCallback: true,
        additional: {
          onSelected: this.onCustomersSelected.bind(this),
          selectedCustomers: this.model.selected_customers
        }
      });
    },
    onCustomersSelected(ids, selectedCustomers) {
      this.model.selected_customers = selectedCustomers;
      this.$emit('close');
    },
    accountChanged(account, key) {
      if (account.shipping_address || account.billing_address) {
        this.model.new_customers.splice(key, 1, account);
      }
    },
    addNewCustomerForm() {
      let new_item = JSON.parse(JSON.stringify(this.emptyAccount));
      this.model.new_customers.push(new_item);
    },
    submitForm() {
      this.validateBeforeSubmit().then(isValid => {
        this.$emit('submitForm');
      });
    },
    loadFormData() {
      return apiService.getCompanyFormData().then(
        response => {
          this.terms = response.data.terms;

          let countries = {
            primary: [],
            all: []
          };

          response.data.countries.forEach(country => {
            if (country.is_primary) {
              countries.primary.push({
                key: country.country_id,
                label: country.name
              });
            }
            countries.all.push({
              key: country.country_id,
              label: country.name
            });
          });

          this.countriesOptions = countries;
        },
        response => {
          notify({
            message: 'Could not load form data. Please try later.',
            type: 'danger'
          });
        }
      );
    },
    getStates(field, callback) {
      if (this.model[field].country_code) {
        return apiService
          .getCompanyFormStates(this.model[field].country_code)
          .then(
            response => {
              this.statesCount[field] = response.data.total;
              this.states[field] = response.data.list;

              let state_is_actual = this.states[field].filter(state => {
                return state.key == this.model[field].state_code;
              }).length;

              if (state_is_actual === 0) {
                this.model[field].state_code = '';
              }

              if (callback) {
                callback();
              }
            },
            response => {
              notify({
                message: 'Could not load form data. Please try later.',
                type: 'danger'
              });
            }
          );
      } else {
        return Promise.resolve();
      }
    },
    cloneShippingToBilling(e) {
      if (e.target.checked) {
        let ship = this.model.shipping_address;
        this.model.billing_address.address_line_1 = ship.address_line_1;
        this.model.billing_address.address_line_2 = ship.address_line_2;
        this.model.billing_address.city = ship.city;
        this.model.billing_address.country_code = ship.country_code;
        this.model.billing_address.postal_code = ship.postal_code;
        this.getStates('billing_address', () => {
          this.model.billing_address.state_code = ship.state_code;
        });
      }
    },
    statesOptions(country) {
      return {
        all: this.states[country]
      };
    },
    validateBeforeSubmit(e) {
      this.clearChild();
      this.errors.errors = [];
      this.validateChild();
      return new Promise((resolve, reject) => {
        Vue.nextTick(() => {
          resolve(!this.errors.count());
        });
      });
    },
    validateChild() {
      bus.$emit('validate');
      bus.$emit('validate', 'company-form');
      bus.$emit('validate', 'account-form');
      bus.$emit('validate', 'billing_address');
      bus.$emit('validate', 'shipping_address');
      bus.$emit('validate', 'customer-fields');
    },
    clearChild() {
      bus.$emit('clear');
    }
  },
  created() {
    bus.$on('errors-changed', errors => {
      if (errors) {
        errors.forEach(e => {
          this.errors.add(e.field, e.msg, e.rule, e.scope);
        });
      }
    });
    bus.$on('errors-deleted', oldErrors => {
      if (oldErrors) {
        oldErrors.forEach(e => {
          this.errors.remove(e.field, e.scope);
        });
      }
    });
  },
  mounted() {
    this.loadFormData();

    if (this.model.billing_address.country_code) {
      this.getStates(
        'billing_address',
        (state_code => {
          this.model.billing_address.state_code = state_code;
        }).bind(this, this.model.billing_address.state_code)
      );
    }

    if (this.model.shipping_address.country_code) {
      this.getStates(
        'shipping_address',
        (state_code => {
          this.model.shipping_address.state_code = state_code;
        }).bind(this, this.model.shipping_address.state_code)
      );
    }
  }
};
</script>
