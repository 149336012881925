<template>
  <div class="design-info widget has-separator" v-if="design.designable && design.designable.item">
    <dl class="info">
      <dt v-if="size_label !== 'N/A'"><strong>SIZE: </strong>{{ size_label }}</dt>
      <dt v-if="color_label !== 'N/A'"><strong>COLORS: </strong>{{ color_label  }}</dt>
      <dt v-if="number_of_colors !== 'N/A'"><strong>{{ numberOfColorsLabel|uppercase }}: </strong>{{ number_of_colors }}</dt>
      <dt><strong>QUANTITY: </strong>{{ quantity_label }}</dt>
    </dl>
    <dl v-if="this.design.designable.item.addons && this.design.designable.item.addons.length" >
      <dt><strong>{{ 'Add-Ons'  | uppercase }}:</strong></dt>
      <dt v-for="addon in this.design.designable.item.addons">
        <strong>{{ addon.product_addon_type_name|uppercase }}: </strong><div v-html="addon.product_addon_name" style="display: inline-block"/>
      </dt>
    </dl>
  </div>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep';
export default {
  name: 'design-info-promotional',
  props: {
    design: {
      type: Object,
      required: true
    },
    order: {
      type: Object,
      required: true
    },
    mock: {
      type: Object,
      required: true
    },
    options: {
      type: Object,
      required: true
    },
    editable: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      mock_item: {
        location: '',
        width: 0,
        height: 0,
        location_comment:
          this.mock &&
          this.mock.items &&
          this.mock.items[0] &&
          this.mock.items[0].location_comment
            ? cloneDeep(this.mock.items[0].location_comment)
            : ''
      }
    };
  },
  computed: {
    color_label() {
      let label = 'N/A';

      if (this.design.designable.item.color) {
        label = this.design.designable.item.color;
      }
      return label;
    },
    number_of_colors() {
      let label = 'N/A';

      if (this.design.designable.item.number_of_colors) {
        label = this.design.designable.item.number_of_colors;
      }
      return label;
    },
    numberOfColorsLabel(){
      if (this.isPetProduct && !this.isPetToysCategory) {
        return 'Pet Product Size';
      }
      if(this.isShoelacesProduct){
        return 'Shoelace Options';
      }
      if (this.isCuffBeanieProduct) {
        return 'Beanie Option';
      }
      if (this.isScarfsProduct) {
        return 'Scarfs';
      }
      return 'Number of Colors';
    },
    isPetProduct(){
      return  this.design.designable.item.product_object.product_type && this.design.designable.item.product_object.product_type.name === 'Pet Products';
    },
    isCustomProduct(){
      return this.design.designable.item.product_object.product_type &&
        this.design.designable.item.product_object.product_type.name === 'Custom Products';
    },
    isShoelacesProduct() {
      return this.isCustomProduct &&
        this.design.designable.item.product_object && this.design.designable.item.product_object.product_category &&
        this.design.designable.item.product_object.product_category.name === 'Shoelaces'
    },
    isCuffBeanieProduct() {
      return this.isCustomProduct &&
        this.design.designable.item.product_object && this.design.designable.item.product_object.product_category &&
        this.design.designable.item.product_object.product_category.name === 'Cuff Beanie'
    },
    isScarfsProduct() {
      return this.isCustomProduct &&
        this.design.designable.item.product_object && this.design.designable.item.product_object.product_category &&
        this.design.designable.item.product_object.product_category.name.trim() === 'Scarfs'
    },
    isPetToysCategory() {
      return this.isPetProduct && this.design.designable.item.product_object
        && this.design.designable.item.product_object.product_category && this.design.designable.item.product_object.product_category.name === 'Toys'
    },
    size_label() {
      return this.design.designable.item.size
        ? this.design.designable.item.size
        : 'N/A';
    },
    quantity_label() {
      return this.design.designable.item.quantity
        ? this.design.designable.item.quantity
        : 'N/A';
    }
  },
  methods: {
    getFormData() {
      return [this.mock_item];
    }
  }
};
</script>
