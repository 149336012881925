<template>
  <div class="card is-fullwidth production-notes">
    <header class="card-header">
      <p class="card-header-title">Production Notes</p>
    </header>
    <div class="card-content">
      <div>
        <span class="has-text-weight-bold small-padding">Last 5 jobs</span>
        <ul v-if="latestOrders.length">
          <li v-for="order in latestOrders">
            <router-link class="link" :to="'/orders/' + order.id">#{{ order.id }}</router-link>
            <span v-if="order.printers">({{order.printers}})</span>
            <small v-else>Printers not assigned.</small>
          </li>
        </ul>
        <p class="paragraph" v-else>This is the first order for this customer.</p>
      </div>
      <div v-if="referenceDesigns.length" >
        <h5 class="subtitle is-6 has-text-weight-bold">Reference jobs</h5>
        <ul>
          <li v-for="design in referenceDesigns">
            <router-link class="link" :to="`/orders/${design.order_id}/details/design-${design.id}`">#{{ design.order_id }}
              - Design #{{ design.number }}
            </router-link>
          </li>
        </ul>
      </div>
      <hr>
      <div v-roles="['admin','csr','production-coordinator','purchaser','art-producer']">
        <div v-if="isCompany && this.ids['company'] !== 'create-new'">
          <div class="flex flex__horizontal-cols cols-flex">
            <div>
              <h5 class="subtitle is-6 has-text-weight-bold">Company Notes</h5>
            </div>
            <div>
              <button @click="openNotesPopup('company')">
                <span class="icon is-medium has-text-link">
                  <i class="ff-lg ff-compose" v-if="notes.company"></i>
                  <i class="ff-lg ff-plus-circle" v-else></i>
                </span>
              </button>
            </div>
          </div>
          <div v-if="notes.company">
            <p class="content-pre paragraph">{{ notes.company }}</p>
          </div>
          <br>
        </div>
        <div v-if="this.ids['user']">
          <div class="flex flex__horizontal-cols cols-flex">
            <div>
              <h5 class="subtitle is-6 has-text-weight-bold">Customer Notes</h5>
            </div>
            <div>
              <button @click="openNotesPopup('user')">
                <span class="icon is-medium has-text-link">
                  <i class="ff-lg ff-compose" v-if="notes.user"></i>
                  <i class="ff-lg ff-plus-circle" v-else></i>
                </span>
              </button>
            </div>
          </div>

          <div v-if="notes.user">
            <p class="content-pre paragraph">{{ notes.user }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.icon-btn {
  padding: 2px;
}
.small-padding {
  display: inline-block;
  padding-top: 15px !important;
  padding-bottom: 10px !important;
}
.paragraph {
  font-size: 0.9rem;
}
</style>

<script>
import apiService from '../../services/api.service';
import popup from '../../modules/popupper';
import ProductionNotesPopup from '../orders/popups/add-production-nodes';
import { sortedUniq } from 'lodash';

export default {
  name: 'production-notes',
  props: ['estimateDetails'],
  data() {
    return {
      latestOrders: [],
      referenceDesigns: []
    };
  },
  computed: {
    estimateId() {
      return this.estimateDetails.order.id;
    },
    accountId() {
      return this.estimateDetails.account.account_id;
    },
    notes() {
      return {
        user: this.estimateDetails.account
          ? this.estimateDetails.account.customer_production_notes
          : '',
        company: this.estimateDetails.account
          ? this.estimateDetails.account.company_production_notes
          : ''
      };
    },
    isCompany() {
      return !!(
        this.estimateDetails.account && this.estimateDetails.account.company
      );
    },
    ids() {
      return {
        user: this.accountId,
        company: this.estimateDetails.account
          ? this.estimateDetails.account.company_id
          : ''
      };
    }
  },
  methods: {
    openNotesPopup(type) {
      popup({
        title: type === 'user' ? 'Add Customer Note' : 'Add Production Note',
        additional: {
          id: this.ids[type],
          type: type,
          message: this.notes[type],
          isEstimate: true,
          callback: this.updateNotes
        },
        bodyComponent: ProductionNotesPopup,
        showButtons: true,
        submitLabel: 'Submit Notes',
        runCallback: true
      });
    },
    updateNotes(message, type) {
      switch (type) {
        case 'user':
          this.estimateDetails.account.customer_production_notes = message;
          break;
        case 'company':
          this.estimateDetails.account.company_production_notes = message;
          break;
      }
    },
    loadInfo() {
      let data = {
        userId: this.accountId,
        skipId: this.estimateId
      };
      if (data.userId) {
        apiService.getLatestCustomerOrders(data).then(data => {
          this.latestOrders = data.orders.data.map(order => {
            return {
              id: order.id,
              printers: sortedUniq(
                order.designs
                  .map(design => {
                    if (design.printer) {
                      return design.printer.code;
                    }
                  })
                  .filter(function(n) {
                    return n != undefined;
                  })
              ).join(' / ')
            };
          });
        });
      }

      apiService.getReprintedDesigns(this.estimateId).then(data => {
        this.referenceDesigns = data;
      });

      return [];
    }
  },
  mounted() {
    this.loadInfo();
  }
};
</script>
