<template>
  <div class="page-content container section">
    <section class="sales-tax-report">
      <header class="header">
        <h3 class="title">Sales Tax Report</h3>
      </header>
      <div class="columns">
        <div class="column is-4 input-cntr">
          <label class="label" for="date_from"  style="margin-bottom: 0">From</label>
          <div class="control">
            <datepicker
              v-model="filters.date_from"
              :config="{dateFormat: 'm/d/Y', wrap: true }"
              id="date_from"
            />
          </div>
        </div>
        <div class="column is-4 input-cntr">
          <label class="label" for="date_to" style="margin-bottom: 0">To</label>
          <div class="control">
            <datepicker
              v-model="filters.date_to"
              :config="{dateFormat: 'm/d/Y', wrap: true }"
              id="date_to"
            />
          </div>
        </div>
        <div class="column is-4 input-cntr">
          <form-checkbox v-model="filters.exclude_with_tax_id" name="exclude_with_tax_id">
            Exclude orders with tax id
          </form-checkbox>
          <form-checkbox v-model="filters.include_with_tax_id" name="include_with_tax_id">
            Include orders with tax id only
          </form-checkbox>
        </div>
      </div>

      <div class="progress" style="margin-top: 10px; margin-bottom: 10px" v-if="inProgress">
        <div class="determinate" :style='`width: ${progress}%`'></div>
      </div>
      <div class="row" v-if="message">
        <div class="col s6 offset-s3 m6 offset-m3">
          <div class="card-panel">
            <h5 class="center-align">{ message != null ? message : ''}</h5>
          </div>
        </div>
      </div>
    </section>
    <button @click="run()" :disabled="inProgress" class="button is-pulled-right">
      Export Sales Tax Report
    </button>
  </div>
</template>
<script>
import apiService from '../../services/api.service';
import alertify from 'alertify.js';
import Datepicker from '../../modules/datepicker/index';
import {downloadReport} from '@/helpers/reports/reports';

export default {
  name: 'sales-tax-report',
  data() {
    return {
      cancel: false,
      inProgress: false,
      message: '',
      filters: {
        page: 1,
        date_to: '',
        date_from: '',
        exclude_with_tax_id: false,
        include_with_tax_id: false,
        timezone: ''
      },
      data: [],
      json_fields: [
        {
          label: 'State',
          value: 'tax_state'
        },
        {
          label: 'Number of Orders',
          value: 'orders'
        },
        {
          label: 'Total Number of Sales',
          value: 'total_price'
        },
        {
          label: 'Sales Tax Collected',
          value: 'sales_tax'
        }
      ],
      progress: 0,
      downloadName: 'sales_tax'
    };
  },
  components: {
    Datepicker
  },
  methods: {
    run() {
      if (!this.cancel) {
        this.$set(this, 'inProgress', true);
        console.log('filters',this.filters)
        apiService
          .getReport('generate_sales_tax_orders', this.filters)
          .then(res => {
            this.$set(this, 'progress', +res.to / +res.total * 100);
            if (res.data.length) {
              this.$set(this, 'data', [...this.data, ...res.data]);
            }
            if (+res.to >= +res.total) {
              this.$set(this, 'inProgress', false);
              this.download();
            } else {
              this.$set(this.filters, 'page', res.current_page + 1);
              this.run();
            }
          })
          .catch(err => {
            console.log(err);
            this.$set(this.filters, 'page', 1);
            this.$set(this, 'inProgress', false);
            alertify.error(err.body.message);
          });
      }
    },
    download() {
      downloadReport(this.data, this.json_fields,
        `${this.downloadName}_${this.filters.date_from}_${this.filters.date_to}.csv`
      );
      this.$set(this.filters, 'page', 1);
      this.$set(this, 'progress', 0);
      this.$set(this, 'data', []);
    }
  },
  created() {
    this.filters.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  },
  beforeDestroy() {
    this.$set(this, 'cancel', true);
  }
};
</script>
<style scoped>
.progress {
  background: aliceblue;
}

.determinate {
  background: #1992d1;
  height: 12px;
}
</style>
