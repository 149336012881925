<template>
  <div class="button-design">
    <fieldset v-for="(item, key) in design.items" class="items-fieldset">
      <div class="columns">
        <div class="column is-2">
          <form-input v-model="item.name"
            name="item"
            :scope="getScope(designKey)"
            :rules="`required`"
            :disabled="isDisabled" />
        </div>
        <div class="column is-2">
          <form-input v-model="item.price"
            type="number"
            name="price"
            :disabled="isDisabled"
            :scope="getScope(designKey)"
            label="Unit Cost"
            rules="min_value:0.00"
            :required="false"
            step="0.01"
            min="0" />
        </div>
        <div class="column is-2">
          <form-input v-model="item.quantity"
            type="number"
            name="quantity"
            :scope="getScope(designKey)"
            rules="required|min_value:1"
            step="1"
            min="0" />
        </div>
        <div class="column is-2">
          <form-input v-model="item.total_cost"
            name="total_cost"
            disabled="disabled"
            :scope="getScope(designKey)"
            label="Total Cost"
            :required="false"
            :rules="''" />
        </div>
      </div>
      <div class="columns">
        <div class="column is-8">
          <form-textarea v-model="item.description"
                         name="description"
                         :scope="getScope(designKey)"
                         rules="required"
                         :disabled="isDisabled" />
        </div>
      </div>
      <a v-if="design.items.length > 1"
          @click="dropItemRow(key)"
          class="button is-danger is-small">
          <span>Delete</span>
          <span class="icon icon__bigger-1">
            <i class="ff-cross-mark"></i>
          </span>
      </a>
    </fieldset>
    <div class="btn-cnt">
      <button type="button"
        @click="addItemRow()"
        class="button"
        :disabled="isDisabled">Add Item</button>
    </div>
    <br>
  </div>
</template>

<script>
import Vue from 'vue';
import bus from '../../../bus';
import apiService from '../../../services/api.service';
import { isEmpty, forEach, merge, isNumber, cloneDeep } from 'lodash';

import formInput from '../../common/form/input';
import formSelect from '../../common/form/select';
import formTextarea from '../../common/form/textarea';

let defaultItem = {
  name: '',
  description: '',
  price: 0,
  quantity: 0,
  total_cost: 0
};

let emptyDesignModel = {
  items: [defaultItem]
};
export default {
  name: 'other',
  props: {
    getScope: {
      type: Function,
      default: () => () => ''
    },
    designCommon: {
      type: Object,
      default: () => {}
    },
    designSpecific: Object,
    designKey: Number,
    isEmbroidery: Boolean,
    upsServices: Array,
    deliveryTypes: Array,
    resetKey: Number,
    accountId: Number,
    index: Number
  },
  data() {
    let items = emptyDesignModel.items.slice();

    if (typeof this.designSpecific.items === 'object') {
      items = [];
      items = this.getItemsArray(this.designSpecific.items);
    } else if (typeof this.designSpecific.items === 'array') {
      items = this.designSpecific.items.slice();
    } else if (
      this.designSpecific.designable &&
      this.designSpecific.designable.items
    ) {
      items = this.designSpecific.designable.items.slice();
    }

    return {
      type: 'other',
      summary: {},
      design: Object.assign(
        { ...emptyDesignModel },
        { ...this.designSpecific, items }
      )
    };
  },
  components: {
    formInput,
    formSelect,
    formTextarea
  },
  computed: {
    isDisabled() {
      return this.designCommon.printed_before_order_id ||
        this.designCommon.printed_before_design_id
        ? 'disabled'
        : false;
    }
  },
  watch: {
    designCommon: {
      handler(design, oldDesign) {
        this.calculateSummary();
      },
      deep: true
    },
    design: {
      handler(design, oldDesign) {
        this.calculateSummary();
      },
      deep: true
    },
    summary: {
      handler(summary, oldSummary) {
        this.$emit('summary-changed', this.designKey, summary);
      },
      deep: true
    },
    resetKey(key) {
      this.calculateSummary();
      if (key !== null && key !== undefined && key === this.designKey) {
        this.setEmpty();
      }
    }
  },
  methods: {
    getItemsArray(items) {
      return Object.keys(items).map(key => {
        return this.designSpecific.items[key];
      });
    },

    /**
     * Calculate summary
     */
    calculateSummary() {
      let summary = {
        type: this.designCommon.service,
        name: this.designCommon.name,
        printed_before: this.designCommon.printed_before,
        printed_before_order_id: this.designCommon.printed_before_order_id,
        printed_before_design_id: this.designCommon.printed_before_design_id,
        reference_order_id: this.designCommon.reference_order_id,
        reference_design_id: this.designCommon.reference_design_id,
        items: [],
        comment: this.designCommon.comment,
        type_of_delivery: this.designCommon.type_of_delivery,
        ship_by_date: this.designCommon.ship_by_date,
        print_by_date: this.designCommon.print_by_date,
        in_hands_date: this.designCommon.in_hands_date
      };

      let total_price = 0;
      let total_qty = 0;

      if (this.design.items) {
        this.design.items.forEach((item, key) => {
          if (!item.price) {
            item.price = 0;
          }
          let total_cost_of_design_item =
            Math.round(item.price * item.quantity * 100) / 100;
          this.$set(
            this.design.items[key],
            'total_cost',
            total_cost_of_design_item
          );

          if (item.name) {
            summary.items.push({
              name: item.name,
              price: item.price,
              description: item.description,
              total_cost: item.total_cost,
              qty: parseInt(item.quantity)
            });
            total_price += parseFloat(item.price) * parseInt(item.quantity);
            total_qty += parseInt(item.quantity);
          }
        });
      }
      summary.total = {
        price: total_price,
        qty: total_qty
      };

      this.$set(this, 'summary', summary);
    },

    onValidate(validateScope) {
      if (validateScope) {
        this.$validator.validateAll(validateScope).catch(err => {});
      } else {
        this.$validator.validateScopes().catch(err => {});
      }
    },

    onClear() {
      this.errors.clear();
    },

    setEmpty() {
      this.$set(this, 'design', merge({}, emptyDesignModel));
    },

    addItemRow() {
      this.design.items.push({ ...defaultItem });
    },

    dropItemRow(key) {
      this.design.items.splice(key, 1);
    },

    setDesign() {
      return {
        key: this.designKey,
        design: this.design
      };
    },

    getItemNamesBesidesId(besidesId) {
      return this.design.items.reduce((names, item, id) => {
        if (id !== besidesId) {
          names.push(item.name.trim());
        }
        return names;
      }, []);
    }
  },
  created() {
    bus.$on('validate', this.onValidate);
    bus.$on('clear', this.onClear);
    this.$watch(
      () => this.errors.errors,
      value => {
        bus.$emit('errors-changed', value);
      }
    );
  },
  mounted() {
    if (isNumber(this.resetKey) && this.resetKey === this.designKey) {
      this.setEmpty();
    }

    this.calculateSummary();
  }
};
</script>
