<template>
  <div v-if="garment.id">
    <h2>Garment details</h2>
    <table class="table is-fullwidth">
      <thead>
      <tr class="is-name">
        <th>Size</th>
        <th>QTY Total</th>
      </tr>
      </thead>
      <tbody>
      <tr class="has-separator">
        <td>{{size_label}}</td>
        <td class="is-pulled-right">Total: {{quantity_label}}</td>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { canApproveMock } from '../../../../helpers/role-access';

export default {
  name: 'garment-button-details',
  props: {
    design: {
      type: Object,
      required: true
    },
    options: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    canApproveMock
  }),
  computed: {
    size_label() {
      let label = 'N/A';
      if (
        this.design &&
        this.design.designable &&
        this.design.designable.item &&
        this.options['sizes']
      ) {
        let size_option = this.options['sizes'].filter(
          s => s.key === this.design.designable.item.size
        )[0];

        if (size_option && size_option.label) {
          label = size_option.label;
        }
      }

      return label;
    },
    quantity_label() {
      let label = 'N/A';
      if (
        this.design &&
        this.design.designable &&
        this.design.designable.item &&
        this.options['sizes']
      ) {
        let qty_option = this.options['quantities'].filter(
          s => s.key === this.design.designable.item.quantity
        )[0];

        if (qty_option && qty_option.label) {
          label = qty_option.label;
        }
      }

      return label;
    },
    garment() {
      return this.design ? this.design.designable.item : {};
    }
  }
};
</script>

<style scoped>
h2 {
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 1rem;
  margin-bottom: 10px;
}
.is-name td {
  background: #dedede;
}
tbody {
  background: #dedede;
}
th {
  border: none;
}
tbody td {
  border-bottom: 1px solid #d6d5d5;
}
.has-separator:last-child {
  border-bottom: 1px solid;
  border-color: #eae6e6;
}
</style>
