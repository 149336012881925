<template>
  <tr>
    <td colspan="3">
      <invoice-field
        label="Product"
        :value="productName"
      />
    </td>
    <td>
      <invoice-field
        label="Quantity"
        :value="quantityValue"
      />
    </td>
    <td>
      <invoice-field
        label="Price"
        :value="parseFloat(item.cost)/parseFloat(quantityValue) | price"
      ></invoice-field>
    </td>
  </tr>
</template>
<script>
import {getOptionLabel} from '@/helpers/estimates';
import InvoiceField from '@/components/common/invoice-field.vue';

export default {
  name: 'FinishingCreationInvoiceItem',
  components: {InvoiceField},
  props: {
    item: {
      type: Object,
      required: true
    },
    formOptions: {
      type: Object,
      required: true
    }
  },
  methods: {
    getOptionLabel
  },
  computed: {
    quantityValue() {
      if (this.item.product_type && ['Woven Patch', 'Printed Patch'].includes(getOptionLabel(this.item.product_type, this.formOptions.print_type_options[this.item.print_type].product_types))) {
        return this.item.quantity;
      } else {
        return getOptionLabel(this.item.quantity, this.formOptions.print_type_options[this.item.print_type].quantities);
      }
    },
    productName() {
      if (this.item.product_type) {
        return getOptionLabel(this.item.product_type, this.formOptions.print_type_options[this.item.print_type].product_types);
      } else {
        return getOptionLabel(this.item.print_type, this.formOptions.print_types);
      }
    }
  }

};
</script>
