<template>
  <div class="revision-design-tabs">
    <swiper :options="swiperOption">
      <swiper-slide
        class="item"
        v-for="design in prepearedDesigns"
        :key="design.id"
        @click.prevent="setActive(design)"
      >
        <a
          :href="`#/revision/${order.id}/${design.id}/${getLastMockDesign(design).id}`"
          class="tab nav-item is-tab is-pulled-left"
          :class="{'is-active': designId === design.id}"
        >
          <div class="text" :title="design.name">{{ add3Dots(design.name, 25) }}
            <span :class="['tag', design.mockStatus.class]">
              {{ design.mockStatus.label }}
            </span>
          </div>
        </a>
      </swiper-slide>
      <div class="swiper-button-prev" slot="button-prev"></div>
      <div class="swiper-button-next" slot="button-next"></div>
    </swiper>
  </div>
</template>

<script>
import {
  DRAFT_STATUS,
  FINAL_STATUS,
  PENDING_STATUS,
  APPROVED_STATUS
} from '../../../helpers/revision';
import cloneDeep from 'lodash/cloneDeep';
import { swiper, swiperSlide } from 'vue-awesome-swiper';
import { checkRoles } from '../../../router/checkRoles';
import store from '../../../store';
import { canCreateMock } from '../../../helpers/role-access';

const labels = {
  [DRAFT_STATUS]: {
    class: 'is-light',
    label: 'Not ready'
  },
  [FINAL_STATUS]: {
    class: 'is-dark',
    label: 'Ready'
  },
  [PENDING_STATUS]: {
    class: 'is-warning has-text-black',
    label: 'Pending'
  },
  [APPROVED_STATUS]: {
    class: 'is-success has-text-white',
    label: 'Approved'
  },
  ['no-mocks']: {
    class: 'is-danger',
    label: 'No Mocks'
  }
};

export default {
  name: 'design-tabs',
  components: {
    swiper,
    swiperSlide
  },
  props: {
    designs: {
      type: Array,
      required: true
    },
    order: {
      type: Object,
      required: true
    },
    design: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    swiperOption: {
      slidesPerView: 4,
      spaceBetween: 10,
      allowTouchMove: false,
      mousewheel: true,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev'
      }
    }
  }),
  computed: {
    designId() {
      return this.$route.params ? +this.$route.params.designId : '';
    },
    mockId() {
      return this.$route.params ? +this.$route.params.mockId : '';
    },
    prepearedDesigns() {
      let designs = this.designs.filter(design => design.service !== 'blank');

      designs = designs
        ? designs.map(design => {
            const mock =
              design.mocks.find(m => m.id === this.mockId) ||
              design.mocks[design.mocks.length - 1];
            const status = mock ? mock.status : 'no-mocks';
            const newDesign = cloneDeep(design);
            newDesign.mockStatus = labels[status];
            return newDesign;
          })
        : [];

      if (!this.canCreateMock && designs.length) {
        designs = designs.filter(design => {
          const mock =
            design.mocks.find(m => m.id === this.mockId) ||
            design.mocks[design.mocks.length - 1];
          const status = mock ? mock.status : 'no-mocks';
          return status !== 'no-mocks';
        });
      }
      return designs;
    },
    canCreateMock() {
      return checkRoles(store.getters.roles, canCreateMock, true);
    }
  },
  methods: {
    setActive(design) {
      if (design.id !== this.designId) {
        this.$router.push({
          name: 'Revision',
          params: {
            id: this.order.id,
            designId: design.id,
            mockId: getLastMockDesign(design).id
          }
        });
      }
    },
    getLastMockDesign(design){
      return design.mocks && design.mocks.length ? design.mocks[design.mocks.length - 1] : {id: 'new'};
    },
    add3Dots(string, limit = 15) {
      let dots = '...';
      if (string.length > limit) {
        string = string.substr(0, limit) + dots;
      }

      return string;
    }
  },
  mounted() {
    if (!this.canCreateMock && !this.prepearedDesigns.length) {
      this.$router.push('/');
    }
  }
};
</script>

<style scoped>
.revision-design-tabs {
  margin-bottom: 25px;
}
.tab {
  color: #7a7a7a;
  white-space: nowrap;
  padding: 10px;
  &:hover{
    color: #363636;
    border-bottom: 1px solid #1992d1;
    //border-top: 1px solid transparent;
  }
  &.is-active{
    border-bottom: 3px solid #1992d1;
    border-top: 3px solid transparent;
    color: #1992d1;
  }
}
</style>
