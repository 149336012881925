<template>
  <div class="manage-files">
    <form @submit.prevent="updateFilesLinks">
      <div v-if="model">
        <div><label>Original Files</label></div>
        <div v-if="showInputs['original']" class="columns">
          <div class="column is-9">
            <input type="text"
              v-model="model.original_files_link"
              class="input">
          </div>
          <div class="column is-3">
            <button type="submit" class="button">Save</button>
          </div>
        </div>
        <div v-else class="columns">
          <div class="column is-9">
            <a :href="model.original_files_link"
              target="_blank"
              v-if="model.original_files_link">Open</a>
            <span v-else>No Link presented</span>
          </div>
          <div class="column is-3">
            <a class="button"
              @click="showTextInput('original')">
              <span v-if="model.original_files_link">Change Link</span>
              <span v-else>Add Link</span>
            </a>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import Vue from 'vue';
import { mapGetters } from 'vuex';
import apiService from '../../services/api.service';
import popup from '../../modules/popupper';
import alertify from 'alertify.js';

export default {
  name: 'manage-files-box',
  props: ['itemId', 'orderId', 'model', 'modelType'],
  data() {
    return {
      showInputs: {
        original: false,
        print: false
      }
    };
  },
  methods: {
    showTextInput(index) {
      this.showInputs[index] = 'true';
    },

    updateFilesLinks() {
      const data = {
        original_files_link: this.model.original_files_link,
        print_ready_files_link: this.model.print_ready_files_link
      };

      apiService.updateFilesLinks(this.model.id, this.modelType, data).then(
        response => {
          this.$emit('onFilesLinksUpdated', {
            itemId: this.itemId,
            modelType: this.modelType,
            model: response.model
          });

          alertify.success(
            `Links for ${this.modelType} "${
              this.model.name
            }" successfully updated`
          );
        },
        response => {
          this.$emit('onFilesLinksUpdated', {
            itemId: this.itemId,
            modelType: this.modelType,
            model: response.model
          });

          if (response.data && response.data.message) {
            alertify.error(response.data.message);
          }
        }
      );
    }
  }
};
</script>
