import PubNub from 'pubnub';
import apiService from './api.service';
import Vue from 'vue';

const EventBus = new Vue();

import {
  ADD_MESSAGES,
  LOAD_MESSAGES_BY_ORDER_ID,
  RESUBSCRIBE_PUBNUB,
  SET_PUBNUB_ENV,
  SUBSCRIBE_TO_ORDER
} from '../store/action-types';
import { isMentionedMe } from '../helpers/messages';
import { checkRoles } from '../router/checkRoles';

class PubNubService {
  subscribe() {
    Vue.prototype.$pubNub.addListener({
      message: this.onMessage.bind(this)
    });
    Vue.prototype.$pubNub.subscribe({
      channels: this.store.getters.channelsToSubscribe
    });
  }
  subscribeUpdateEvent(updatedModel, cb) {
    EventBus.$on(updatedModel, cb);
  }

  unsubscribeUpdateEvent(updatedModel, cb) {
    EventBus.$off(updatedModel, cb);
  }

  onMessage(data) {
    if (data.message) {
      const newMessage = data.message;

      // newMessage.updated has values - order, mock
      if (newMessage.updated_model) {
        EventBus.$emit(newMessage.updated_model, newMessage);
      }

      // Subscribe user as soon as he was mentioned and is not related to an order.
      if (
        !this.store.getters.oids.includes(newMessage.order_id) &&
        !this.store.getters.oids_communication.includes(newMessage.order_id) &&
        isMentionedMe([newMessage])
      ) {
        let orderId = newMessage.order_id;
        this.store.dispatch(SUBSCRIBE_TO_ORDER, { orderId: orderId });
        this.store.dispatch(LOAD_MESSAGES_BY_ORDER_ID, orderId);
      }

      this.store.dispatch(ADD_MESSAGES, {
        orderId: newMessage.order_id,
        mockId: newMessage.mock_id,
        messages: [newMessage],
        channel: newMessage.channel
      });
    }
  }

  create() {
    apiService
      .getPubNubKeys()
      .then(data => {
        this.store.dispatch(SET_PUBNUB_ENV, data.env);
        this.keys = data;
        Vue.prototype.$pubNub = new PubNub(this.keys);

        this.subscribe();
      })
      .catch(err => {
        console.error(err);
      });
  }

  install(Vue, { store }) {
    if (store && store.getters.isAuthenticated) {
      this.create();
    } else if (store) {
      store.subscribe((mutation, state) => {
        if (
          !Vue.prototype.$pubNub &&
          !this.creating &&
          store.getters.isAuthenticated
        ) {
          this.creating = true;
          this.create();
        }
      });
    }
    this.store = store;
  }
}

export default new PubNubService();
