<template>
  <div class="page-content is-fullheight payment-page">
    <div class="header">
      <div class="container">
        <div class="columns is-vcentered">
          <div class="column is-offset-1-mobile is-10-mobile is-6 is-offset-3">
            <div>
              <div class="text-center logo"><a href="/"><img
                src="@/../images/logos/logo_horizontal.png" width="260" height="46" alt="Threadbird"></a>
              </div>
              <h1 class="block-header">
                Order Information
              </h1>
              <div class="description">
                <b>Description:</b> {{ invoice.description }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="container">
        <div class="columns">
          <div class="amount is-offset-1-mobile is-10-mobile column is-6 is-offset-3">
            <div class="columns ">
              <div class="title column is-7-mobile is-7">
                <p>Enter Payment Amount for #{{ this.orderId }}</p>
              </div>
              <div class="link column is-4-mobile is-4">
                <router-link
                  v-if="invoice.link_hash"
                  :to="'/customer/orders/' + orderId + '/invoice/' + invoice.link_hash"
                  class="is-pulled-right">
                  View Invoice
                </router-link>
              </div>
            </div>
            <div class="columns is-vcentered">
              <div class="amount-input column is-12-mobile column">
                <i class="icon icon__smaller ff-dollar clr-white"></i>
                <money
                  data-vv-name="amount"
                  id="amount"
                  :max="maxAmount"
                  v-validate="{rules: 'required|between:0,'+ maxAmount, scope: 'form'}"
                  v-model="payment.amount" v-bind="money"/>
                <i class="icon icon__smaller ff-cross-mark clr-red"
                   @click="clearAmount"><b @click.stop>USD</b></i>

              </div>
              <div class="amount-description column is-offset-1-mobile is-11-mobile">
                <div>Total Amount Due:
                  <div class="is-pulled-right">{{ invoice.amount | price }}</div>
                </div>
                <div>Amount Paid:
                  <div class="is-pulled-right">{{ invoice.paid_sum | price }}</div>
                </div>
                <div><b>Balance Due:</b>
                  <div class="is-pulled-right"><b>{{ +invoice.amount - +invoice.paid_sum | price }}</b></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="payment-information">
      <div class="container">
        <div class="columns is-vcentered">
          <div class="payment-block is-offset-1-mobile is-10-mobile column is-6 is-offset-3">
            <div class="column is-12-mobile is-8 title block-header">
              <p>Payment Information</p>
            </div>
            <div class='payment-methods column is-7-mobile is-hidden-desktop'>
              <img src="@/../images/icons/payment_cards.png" width="142px" height="16px">
            </div>
            <div class="notes column is-5-mobile is-5 is-pulled-right">
              <p>* Required fields</p>
            </div>
            <div class='field column is-12-mobile is-6'>
              <label class="label required" style="padding-left: 1rem">Card Number</label>
              <p>
                <input
                  v-maska="getCardMask"
                  v-model="payment.card_number"
                  v-validate="{rules: 'required', scope: 'form'}"
                  :class="{'is-danger': errors.has('form.card_number')}"
                  placeholder="4242 4242 4242 4242"
                  id="card_number"
                  name="card_number"
                  type="text"/>
              </p>
            </div>
            <div class='column is-5 is-hidden-mobile is-pulled-right' style="margin-top: -3rem">
              <img src="@/../images/icons/payment_cards.png" width="243px" height="28px">
            </div>
            <div class='field half column is-5-mobile is-3'>
              <label class="label required" style="padding-left: 1rem">Exp Date</label>
              <p>
                <input placeholder="MM/YY" type="text"
                       v-maska="'##/##'"
                       v-model="payment.exp_date"
                       v-validate="{rules: 'required', scope: 'form'}"
                       :class="{'is-danger': errors.has('form.exp_date')}"
                       name="exp_date">
              </p>
            </div>
            <div class='field half column is-5-mobile is-3'>
              <label class="label required" style="padding-left: 1rem">CVC</label>
              <div>
                <input
                  v-maska="'####'"
                  v-validate="{rules: 'required', scope: 'form'}"
                  v-model="payment.cvv"
                  :class="{'is-danger': errors.has('form.cvv'),'cvv':true} "
                  name="cvv"
                  placeholder="" type="text">
                <!--                    <div class="tooltip"><img src="@/../images/input_placeholder.png"/>-->
                <!--                      <span class="tooltiptext">3-digit security code usually found on the back of your card. American Express cards have a 4-digit code located on the front</span>-->
                <!--                    </div>-->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="billing-information">
      <div class="container">
        <div class="columns is-vcentered">
          <div class="billing-block is-offset-1-mobile is-10-mobile column is-6 is-offset-3">
            <div class="title column is-12-mobile block-header">
              <p>Billing Information</p>
            </div>
            <div class="columns is-vcentered">
              <div class='column is-12-mobile is-6'>
                <label class="label required" style="padding-left: 1rem">First Name</label>
                <p>
                  <input
                    v-validate="{rules: 'required', scope: 'form'}"
                    v-model="payment.billing_information.first_name"
                    :class="{'is-danger': errors.has('form.billing_first_name')}"
                    name="billing_first_name"
                    :placeholder="errors.has('form.billing_first_name')?'Please fill in required fields':''"
                    type="text">
                </p>
              </div>
              <div class='column is-12-mobile is-6'>
                <label class="label required" style="padding-left: 1rem">Last Name</label>
                <p>
                  <input
                    v-validate="{rules: 'required', scope: 'form'}"
                    v-model="payment.billing_information.last_name"
                    :class="{'is-danger': errors.has('form.billing_last_name')}"
                    name="billing_last_name"
                    :placeholder="errors.has('form.billing_last_name')?'Please fill in required fields':''"
                    type="text">
                </p>
              </div>
            </div>
            <div class="columns is-vcentered">
              <div class='column is-12-mobile is-12'>
                <label class="label" style="padding-left: 1rem">Company</label>
                <p>
                  <input v-model="payment.billing_information.company" placeholder="" type="text">
                </p>
              </div>
            </div>
            <div class="columns is-vcentered">
              <div class='column is-12-mobile is-12'>
                <label class="label required" style="padding-left: 1rem">Address</label>
                <p>
                  <input
                    v-validate="{rules: 'required', scope: 'form'}"
                    v-model="payment.billing_information.address"
                    :class="{'is-danger': errors.has('form.billing_address_1')}"
                    name="billing_address_1"
                    :placeholder="errors.has('form.billing_address_1')?'Please fill in required fields':''"
                    type="text">
                </p>
              </div>
            </div>
            <div class="columns is-vcentered">
              <div class='column is-12-mobile is-6'>
                <label class="label required" style="padding-left: 1rem">City</label>
                <p>
                  <input type="text"
                         v-validate="{rules: 'required', scope: 'form'}"
                         v-model="payment.billing_information.city"
                         :class="{'is-danger': errors.has('form.billing_city')}"
                         :placeholder="errors.has('form.billing_city')?'Please fill in required fields':''"
                         name="billing_city">
                </p>
              </div>
              <div class='hide-validation-errors column is-12-mobile is-6'>
                <label class="label required" style="padding-left: 1rem">State/Province</label>
                <form-select v-model="payment.billing_information.state_code"
                             name="state_code"
                             scope="form"
                             :hideLabel="true"
                             :options="statesOptions(payment.billing_information.country_code)"/>
              </div>
            </div>
            <div class="columns is-vcentered">
              <div class='hide-validation-errors column is-12-mobile is-6'>
                <label class="label required" style="padding-left: 1rem">Country</label>
                <form-select v-model="payment.billing_information.country_code"
                             name="country_code"
                             scope="form"
                             :hideLabel="true"
                             @change="getStates()"
                             :options="countriesOptions"/>
              </div>
              <div class='column is-12-mobile is-6'>
                <label class="label required" style="padding-left: 1rem">Zip/Postal Code</label>
                <p>
                  <input
                    v-validate="{rules: 'required', scope: 'form'}"
                    v-model="payment.billing_information.postcode"
                    :class="{'is-danger': errors.has('form.billing_postcode')}"
                    name="billing_postcode"
                    :placeholder="errors.has('form.billing_postcode')?'Please fill in required fields':''"
                    type="text">
                </p>
              </div>
            </div>
            <div class="columns is-vcentered">
              <div class='column is-12-mobile is-6'>
                <label class="label required" style="padding-left: 1rem">Email</label>
                <p>
                  <input
                    v-validate="{rules: 'required|email', scope: 'form'}"
                    v-model="payment.billing_information.email"
                    :class="{'is-danger': errors.has('form.email')}"
                    :placeholder="errors.has('form.email')?'Please fill in required fields':''"
                    name="email"
                    type="email">
                </p>
              </div>
              <div class='column is-12-mobile is-6'>
                <label class="label required" style="padding-left: 1rem">Phone</label>
                <p>
                  <input
                    v-validate="{rules: 'required', scope: 'form'}"
                    v-model="payment.billing_information.phone"
                    :class="{'is-danger': errors.has('form.billing_phone')}"
                    name="billing_phone"
                    :placeholder="errors.has('form.billing_phone')?'Please fill in required fields':''"
                    type="text">
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="is-12-mobile column is-6 is-offset-3">
        </div>
      </div>
    </div>
    <div class="pay-button">
      <div class="container">
        <div class="has-text-centered">

          <p class="control">
            <button type="submit" :class="{'is-loading': isLoading, 'button is-primary': true}"
                    @click="makePayment"
                    :disabled="errors.length || !payment.billing_information.email"
            >
              Pay Now
            </button>
          </p>

          <div class="link">
            <router-link
              v-if="invoice.link_hash"
              :to="'/customer/orders/' + orderId + '/invoice/' + invoice.link_hash">
              View Invoice Details
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <div class="footer">
      <div class="content">
        <div class="columns">
          <div class="column is-11 is-offset-1" style="font-size: 20px;"><b>Questions?</b> Contact Us</div>
        </div>
        <div class="columns is-vcentered" style="">
          <div class="column is-12-mobile is-2 is-offset-1" style=" padding: 0">
            <img src="@/../images/icons/phone.png" width="36" height="36" style="vertical-align:middle">(407) 545-6505
          </div>
          <div class="column is-12-mobile is-2" style="border-left: solid 1px #707070; padding: 0">
            <img src="@/../images/icons/email.png" width="36" height="36" style="vertical-align:middle">printing@threadbird.com
          </div>
          <div class="column is-12-mobile is-3" style="border-left: solid 1px #707070; padding: 0">
            <img src="@/../images/icons/clock.png" width="36" height="36" style="vertical-align:middle">Mon - Fri | 9AM - 5PM EST
          </div>
          <div class="column is-12-mobile is-4  is-pulled-right copyright" style="color: #B5B6C6">Copyright ©
            {{ new Date().getFullYear() }} Threadbird | All Rights Reserved
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import apiService from '@/services/api.service';
import VeeValidate from 'vee-validate';
import alertify from 'alertify.js';
import Vue from 'vue';
import {Money} from 'v-money';
import formSelect from '@/components/common/form/select.vue';
import {AMERICAN_EXPRESS, DISCOVER, MASTERCARD, UNION_PAY, VISA} from '@/store/modules/payment';
import Maska from 'maska';
import popup from '@/modules/popupper';
import PaymentFailureComponent from '../orders/view/invoices/partials/payment-failure.vue';


Vue.use(Maska);
Vue.use(VeeValidate);

export default {
  components: {formSelect, Money},
  name: 'payment',
  data() {
    return {
      orderId: '',
      invoice: {
        status: '',
        paid_sum: 0,
        amount: 0
      },
      countriesOptions: {},
      states: {},
      amount: 0,
      amountToPay: 0,
      inProgress: false,
      isLoading: true,
      price: 0,
      money: {
        decimal: '.',
        thousands: ',',
        prefix: '',
        suffix: '',
        precision: 2,
        masked: false
      },
      payment: {
        card_number: '',
        exp_date: null,
        cvv: null,
        amount: 0,
        billing_information: {
          first_name: null,
          last_name: null,
          country_code: 'US',
          postcode: null,
          state_code: null,
          city: null,
          company: null,
          address: null,
          email: null,
          phone: null
        },
        order_id: null,
        description: null,
        invoice_id: null
      },
      statesCount: 0
    };
  },
  methods: {
    makePayment: function() {
      apiService
        .makePayment(this.$route.params.hash, this.payment).then(response => {
        this.$router.push('/invoices/payment/success/' + response.transactionId);
      }, response => {
        apiService.getTransaction(response.body.transactionId).then(data=>{
          this.showPaymentStatusPopup(data.transaction);
        });
      });
    },
    clearAmount: function(){
      this.payment.amount = 0;
    },
    getStates: function() {
      return apiService.getAccStates(this.payment.billing_information.country_code).then(data => {
        if (!this.states[this.payment.billing_information.country_code]) {
          this.statesCount = data.total;
          this.states[this.payment.billing_information.country_code] = data.list;
        }
        this.state_code = null;
      });
    },
    statesOptions: function(country_code) {
      return {
        all: this.states[country_code]
      };
    },
    showPaymentStatusPopup(transaction) {
      popup({
        hideHeader: true,
        hideFooter: true,
        modalCardLong: false,
        customClass: 'payment-status-modal-card',
        additional: {
          transaction: transaction,
          isPopup: true,
        },
        bodyComponent: PaymentFailureComponent,
        runCallback: false,
        showButtons: false
      });
    },
  },
  computed: {
    maxAmount() {
      let amount = +this.invoice.amount;
      return +(amount - +this.invoice.paid_sum).toFixed(2);
    },
    getCardType() {
      let number = this.payment.card_number;
      let re = new RegExp('^4');
      if (number.match(re) != null) return VISA;

      re = new RegExp('^5[1-5]');
      if (number.match(re) != null) return MASTERCARD;

      re = new RegExp('^6011');
      if (number.match(re) != null) return DISCOVER;

      re = new RegExp('^(34|37)');
      if (number.match(re) != null) return AMERICAN_EXPRESS;

      re = new RegExp('^(60|62)');
      if (number.match(re) != null) return UNION_PAY;

      return MASTERCARD; // default type
    },
    getCardMask() {
      switch (this.getCardType) {
        case UNION_PAY:
          return ['###### #############','#### #### #### ####'];
        case AMERICAN_EXPRESS:
          return '#### ###### #####';
        default:
          return '#### #### #### ####';
      }
    },
  },
  created() {
    this.isLoading = true;
    this.getStates();
    apiService
      .getInvoiceDetails(this.$route.params.hash)
      .then(response => {
        if (response.status == 'success') {
          this.payment.order_id = response.data.invoice.order_id;
          this.payment.description = response.data.invoice.description;
          this.payment.invoice_id = response.data.invoice.id;
          this.orderId = response.data.invoice.order_id;
          this.invoice = response.data.invoice;
          this.amountToPay = Number(this.maxAmount).toFixed(2);
          this.payment.amount = Number(this.maxAmount).toFixed(2);
          let countries = {
            primary: [],
            all: []
          };
          response.data.countries.forEach(country => {
            if (country.is_primary) {
              countries.primary.push({
                key: country.country_id,
                label: country.name
              });
            }
            countries.all.push({
              key: country.country_id,
              label: country.name
            });
          });
          this.countriesOptions = countries;
        } else {
          alertify.error(data.message);
        }
      });
    this.isLoading = false;
  }
};
</script>
<style>
.payment-page {

  .header {
    background-color: #2cb7f4;
    color: #FFFFFF;
    padding-bottom: 5rem;

    .logo {
      margin-top: 2rem;
      margin-bottom: 2rem;
    }

    .description {
      font-size: 1.3rem;
      line-height: 1.7rem;
      margin-top: 1rem;

      b {
        display: block;
        @media screen and (min-width: 769px) {
          display: inline-block;
        }
      }
    }

    .amount {
      //padding: 2rem 3rem;
      background-color: #039bdf;
      border-radius: 2rem;
      margin-top: 2rem;
      //margin-bottom: 10rem;
      padding: 2rem;
      margin-bottom: 12rem;

      .title {
        color: inherit;
        font-size: 1.15rem;
        line-height: 1.7rem;
        @media screen and (min-width: 769px) {
          font-size: 1.4rem;
          line-height: 3.85rem;
        }
      }
      .ff-dollar{
        font-size: 1.3rem;
        position: absolute;
        //margin-top: 40px;
        margin-left: 15px;
        font-weight: bold;
      }
      .ff-cross-mark {
        position: absolute;
        //margin-top: 45px;
        font-size: 15px;
        margin-left: -80px;
        @media screen and (min-width: 769px) {
          margin-left: -40px;
        }
        &:before {
          padding: 5px;
          background-color: #fdf2f3;
          border-radius: 20px;
        }
        &:hover {
          cursor: pointer;
        }
        b{
          color: #ffffff;
          padding-left: 5px;
          font-size: 1.5rem;
          cursor: default;
          font-style: normal;
          @media screen and (min-width: 769px) {
           display: none;
          }
        }
      }

      .column {
        display: inline-block;
      }

      .link {
        font-size: 0.85rem;
        line-height: 1.7rem;
        @media screen and (min-width: 769px) {
          font-size: 1rem;
          line-height: 3.85rem;
        }

        a {
          color: #ffffff;
          text-decoration: underline;
        }
      }
      #amount{
        &:before{
          font-family: 'IconFont';

          //content: "\e02b";
        }
      }
      .amount-input {
        font-size: 54px;
        line-height: 100px;

        input {
          height: 90px;
          //width: 260px;
          font-size: 35px;
          border-radius: 30px;
          padding-left: 35px;
          background-color: inherit;
          border-color: #5fceff;
          color: inherit;
          width: 100%;
          font-weight: 700;
        }

        margin-top: -2rem;
      }

      .amount-description {
        font-size: 16px;
        line-height: 30px;
      }
    }

    .block-header {
      font-size: 4rem;
      font-weight: 700;
      margin: 0;
      @media screen and (max-width: 768px) {
        font-size: 2.7rem;
      }
    }
  }

  .payment-information {
    position: relative;
    top: -13rem;

    .payment-block {
      //top: -10rem;
      box-shadow: 0 0 10px #7BCCFA65;
      background-color: #ffffff;
      border-radius: 2rem;
      //padding: 3rem 3rem 2rem;
      padding: 0 2rem 2rem;
      //margin: 1.7rem 1.7rem 0;
      color: #353544;

      .title {
        font-size: 1.85rem;
        font-weight: 700;
        margin-bottom: 0;
        padding-bottom: 0;
        @media screen and (min-width: 769px) {
          font-size: 2rem;
        }
      }

      .payment-methods {
        padding-top: 10px;
        @media screen and (max-width: 768px) {
          display: inline-block;
        }

      }

      .notes {
        color: #B5B6C6;
        font-size: 0.85rem;
        line-height: 1.1rem;
        @media screen and (max-width: 768px) {
          display: inline-block;
        }
      }

      .field {
        @media screen and (max-width: 768px) {
          display: inline-block;
        }
        padding: 1rem 0 0;

        &.half {
          @media screen and (max-width: 768px) {
            width: 48%;
            &:last-child {
              float: right;
            }
          }
          @media screen and (min-width: 769px) {
            //width: 48%;
            &:last-child {
              margin-top: -6.3rem;
              margin-left: 10rem;
            }
          }
        }
      }
    }
  }

  .billing-information {
    position: relative;
    top: -12rem;
    padding: 0 2rem 2rem;
    @media screen and (min-width: 769px) {
      padding: 2rem;
    }

    .title {
      font-size: 1.85rem;
      @media screen and (min-width: 769px) {
        font-size: 2rem;
      }
      font-weight: 700;
      margin-bottom: 0;
      padding-bottom: 0;
    }

    .label {
      font-size: 14px;
    }
  }

  .pay-button {
    position: relative;
    top: -13rem;
    background-color: #fafafa;

    button {
      width: 206px;
      //margin-left: 70px;
      height: 70px;
      border-radius: 45px;
      font-size: 16px;
      font-weight: 700;
      display: block;
      margin: 1rem auto 1rem auto;
    }

    .link {
      color: #1992d1;
      font-size: 1.15rem;
      text-decoration: underline;
    }
  }

  .footer {
    color: #57586E;
    background-color: #fafafa;
    //padding: 0;
    padding: 1rem;

    .column {
    }

    @media screen and (max-width: 768px) {
      font-size: 1rem;
    }

    .copyright {
      @media screen and (max-width: 768px) {
        font-size: 0.85rem;
      }
    }
  }

  .block-header {
    font-size: 32px;
    line-height: 60px;
  }

  /* Chrome, Safari, Edge, Opera */

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */

  input[type=number] {
    -moz-appearance: textfield;
  }

  input, select {
    border: 1px solid #D3D4DD;
    border-radius: 33px;
    opacity: 1;
    line-height: 47px;
    width: 100%;
    padding-left: 1rem;
    font-size: 1rem;
  }

  select {
    height: 3.64rem;
  }

  .select {
    height: inherit;

    &:after {
      right: 1.5rem;
      color: #76778d;
      font-size: 1rem;
    }

    .help {
      &.is-danger {
        display: none;
      }
    }
  }

  .hide-validation-errors {
    .help {
      &.is-danger {
        display: none;
      }
    }
  }

  .help {
    &.is-danger {
      font-size: 1.15rem;
      margin-bottom: -3rem;
      padding-left: 1rem;
      margin-top: -3.6rem;
    }
  }


  .label {
    &.required {
      &:after {
        content: '*';
        color: #FE464C;
      }
    }

    font-size: 1.15rem;
  }

  .help {
    &.is-danger {
      font-size: 1.15rem;
      margin-bottom: -3rem;
      padding-left: 1rem;
      margin-top: -3.6rem;
    }
  }

  .is-danger {
    &::placeholder {
      color: #FE464C;
      font-size: 1.15rem;
      opacity: 1; /* Firefox */
      background: url("@/../images/icons/exclusion.png") no-repeat 95% 50%;
      background-size: 18px;
    }

    &::-ms-input-placeholder { /* Edge 12 -18 */
      color: #FE464C;
    }

    border-color: #FE464C;
  }

  .tooltip {
    position: relative;
    display: inline-block;
    border-bottom: 1px dotted black;
  }

  .tooltip .tooltiptext {
    visibility: hidden;
    width: 120px;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    font-size: 0.75rem;
    z-index: 1;
    top: -5px;
    left: 110%;
  }

  .tooltip .tooltiptext::after {
    content: "";
    position: absolute;
    top: 50%;
    right: 100%;
    margin-top: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent black transparent transparent;
  }

  .tooltip:hover .tooltiptext {
    visibility: visible;
  }
}
.payment-status-modal-card{
  width: 740px;
  border-radius: 30px;
  .container{
    &.payment-status{
      margin-top: 30px;
      margin-bottom: 20px;
    }
  }

}
</style>
