<template>
  <span :title="isLoading"></span>
</template>
<script>
// import { pleaseWait } from 'please-wait';

export default {
  name: 'loader-screen',
  props: {
    isLoading: {
      type: Boolean,
      default: false
    },
    logo: {
      type: String,
      default: 'images/logo.png'
    },
    backgroundColor: {
      type: String,
      default: 'white'
    },
    loadingHtml: {
      type: String,
      default:
        `<p class="loading-message">Loading. Please wait...</p>` +
        `<div class="sk-fading-circle">` +
        `<div class="sk-circle1 sk-circle"></div>` +
        `<div class="sk-circle2 sk-circle"></div>` +
        `<div class="sk-circle3 sk-circle"></div>` +
        `<div class="sk-circle4 sk-circle"></div>` +
        `<div class="sk-circle5 sk-circle"></div>` +
        `<div class="sk-circle6 sk-circle"></div>` +
        `<div class="sk-circle7 sk-circle"></div>` +
        `<div class="sk-circle8 sk-circle"></div>` +
        `<div class="sk-circle9 sk-circle"></div>` +
        `<div class="sk-circle10 sk-circle"></div>` +
        `<div class="sk-circle11 sk-circle"></div>` +
        `<div class="sk-circle12 sk-circle"></div>` +
        `</div>`
    }
  },
  data() {
    return {
      pleaseWaitInstance: null
    };
  },
  methods: {
    updatePleaseWait() {
      if (this.isLoading && this.pleaseWaitInstance == null) {
        let pleaseWaitInstance = pleaseWait({
          logo: this.logo,
          backgroundColor: this.backgroundColor,
          loadingHtml: this.loadingHtml
        });

        this.$set(this, 'pleaseWaitInstance', pleaseWaitInstance);
      }
      if (!this.isLoading && this.pleaseWaitInstance != null) {
        this.pleaseWaitInstance.finish();
        this.$set(this, 'pleaseWaitInstance', null);
      }
    }
  },
  mounted() {
    this.updatePleaseWait();
  },
  beforeUpdate() {
    this.updatePleaseWait();
  },
  beforeDestroy() {
    this.$set(this, 'pleaseWaitInstance', null);
  }
};
</script>
