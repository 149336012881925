<template>
  <div>
    <nav class="level">
      <!-- Left side -->
      <breadcrumbs :links="crumbLinks"/>
    </nav>
    <h3 class="title is-3 has-text-centered">Edit Invoice</h3>
    <div class="columns">
      <div class="column is-half is-offset-one-quarter">
        <invoice-form v-if="invoice"
          :invoice="invoice"
          :paymentOptions="paymentOptions"
          :processing="processing"
          @submitForm="submitForm"></invoice-form>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';

import apiService from '../../../../services/api.service';
import notify from '../../../../modules/notifier';
import invoiceForm from './form';
import breadcrumbs from './../../../common/breadcrumbs';

export default {
  name: 'order-invoices-edit',
  data() {
    return {
      invoice: null,
      paymentOptions: {},
      processing: false
    };
  },
  components: {
    invoiceForm,
    breadcrumbs
  },
  computed: {
    orderId() {
      return this.$route.params.id;
    },
    invoiceId() {
      return this.$route.params.invoiceId;
    },
    crumbLinks() {
      return [
        {
          url: '/orders',
          label: 'Orders'
        },
        {
          url: '/orders/' + this.orderId,
          label: '#' + this.orderId
        },
        {
          url: '/orders/' + this.orderId + '/invoices',
          label: 'Invoices'
        },
        {
          url:
            '/orders/' + this.orderId + '/invoices/' + this.invoiceId + '/edit',
          label: '#' + this.invoiceId
        },
        {
          label: 'Edit'
        }
      ];
    }
  },
  mounted() {
    apiService.getInvoice(this.invoiceId).then(invoice => {
      this.invoice = invoice.order_invoice;
    });
  },
  methods: {
    submitForm({ isValid, model }) {
      if (isValid) {
        this.processing = true;
        let updateData = {
          amount: model.amount,
          paid_sum: model.paid_sum,
          description: model.description,
          payment_term: model.payment_term
          //status: model.status
        };

        apiService
          .updateInvoice({
            invoiceId: this.invoiceId,
            invoice: updateData
          })
          .then(data => {
            notify({
              message: 'Invoice saved.',
              type: 'success'
            });
            this.processing = false;
            this.$router.push('/orders/' + this.orderId + '/invoices');
          });
      } else {
        notify({
          message: 'Validation failed. Please check error messages',
          type: 'danger'
        });
        this.processing = false;
      }
    }
  }
};
</script>
