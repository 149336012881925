<template>
  <grid
    :orders="gridData"
    :columns="gridHeaders"
    :forceExpanded="expanded"
    :loading="ordersLoading"
    :stickyHeader="stickyHeader"
    :showTriangle="showTriangle"
    noResMsg="No orders"
    @onGridSortBy="gridUpdateOrders"></grid>
</template>

<script>
import popup from '../../modules/popupper';
import alertify from 'alertify.js';

import {getGridData, gridHeaders} from '../../helpers/designs';

import gridHelper from '../../helpers/grid-helper';
import apiService from '../../services/api.service';
import gridComponent from '../common/grid/grid.vue';
import RedoDesignComponent from '../orders/popups/redo-design.vue';
import ManageFilesComponent from '../dashboard/popups/manage-files.vue';
import ShippingTrackingListComponent from './popups/shipping-tracking-list.vue';
import ShippingTrackingComponent from '../orders/popups/shipping-tracking.vue';
import ManagePrintersComponent from '../orders/popups/manage-printers.vue';

export default {
  name: 'designs-grid',
  props: [
    'orders',
    'services',
    'expanded',
    'ordersLoading',
    'ordersPhase',
    'getDesignProperty',
    'showVersionsButton',
    'designPrinterCallback',
    'orderFollowUpStatuses',
    'mockPopupCallback',
    'designPrintStatuses',
    'finishingPrintStatuses',
    'isActivePhase',
    'stickyHeader',
    'showTriangle',
    'colorizeArchivedLinks'
  ],
  computed: {
    gridHeaders() {
      return gridHeaders(
        this.setPhaseAction,
        this.isActivePhaseAction,
        this.phaseForGrid
      );
    },
    gridData() {
      return {
        data: getGridData(this.orders, this.services, {
          colorizeArchivedOrderLinks: this.colorizeArchivedLinks
        })
      };
    },
    phaseForGrid() {
      return this.orders[0] === undefined || !this.orders[0].designs
        ? false
        : this.orders.length > 1
          ? 'no-specific-phases'
          : gridHelper.phasesStateForOrder(this.orders[0].designs);
    }
  },
  components: {
    grid: gridComponent
  },
  methods: {
    setPhaseAction(phase) {
      this.$emit('setPhase', phase);
    },
    isActivePhaseAction(phase) {
      return this.ordersPhase === phase;
    },
    sendSurveyAction(orderId) {
      // request is added for case with different states
      apiService
        .sendSurvey(orderId)
        .then(data => {
          alertify.success('The survey was sent to the customer');
          const order = this.orders.filter(o => o.id === orderId);
          this.$emit('sendSurveyCallback', orderId);
        })
        .catch(err => {
          alertify.error(err.body.error);
        });
    },
    changeFollowStatusAction(designId, orderId, status) {
      if (this.orderFollowUpStatuses[status] === 'Redo') {
        popup({
          title: 'Redo design',
          additional: {
            orderId: orderId,
            designId: designId,
            status: status,
            designName: this.getDesignProperty(orderId, designId, 'name'),
            callback: ({cancel, newDesign}) => {
              const order = this.orders.filter(o => o.id === orderId);
              const design = order[0].designs.filter(d => d.id === designId)[0];

              if (cancel) {
                status = design.followup_status;
              }

              this.$emit('changeFollowUpStatus', {
                designId,
                orderId,
                status,
                design,
                newDesign
              });
            }
          },
          submitLabel: 'Send',
          bodyComponent: RedoDesignComponent,
          showButtons: true,
          runCallback: true
        });
      } else {
        this.sendFollowUpStatus({designId, orderId, status});
      }
    },
    // request is added for case with different states for different pages which use component
    sendFollowUpStatus({designId, orderId, status}) {
      return apiService
        .changeFollowUpStatus({
          designId,
          status
        })
        .then(data => {
          alertify.success('Follow Up Status is updated');
          this.$emit('changeFollowUpStatus', {
            designId,
            orderId,
            status,
            design: data.design
          });
        });
    },
    // mock actions
    uploadMockActionClick(orderId, designId) {
      this.$router.push({
        name: 'Revision',
        params: {id: orderId, designId: designId, mockId: 'new'}
      });
    },
    showMockVersionsPopupAction(oId, dId) {
      const o = this.orders.find(({id}) => id === oId);
      const d = o.designs.find(({id}) => id === dId);
      const m = d.mocks.slice(0);
      const sortedMocks = m.sort((a, b) => a.version - b.version);
      this.$router.push({
        name: 'Revision',
        params: {
          id: oId,
          designId: dId,
          mockId: sortedMocks[sortedMocks.length - 1].id
        }
      });
    },
    sendMock(orderId, designId) {
      let mocks = this.getDesignProperty(orderId, designId, 'mocks');
      let latest = mocks[mocks.length - 1];
      apiService
        .sendMock(latest.id)
        .then(data => {
          alertify.success('The mock was sent to the customer');
          let sent = true;
          this.$emit('sendMockCallback', {orderId, designId, sent});
        })
        .catch(err => {
          alertify.error(err.body.error);
        });
    },
    // payment actions
    changePaymentStatusAction(orderId, status) {
      this.$emit('changePaymentStatus', {orderId, status});
    },
    changeBlankStatusAction(designId, orderId, status) {
      this.$emit('changeBlankStatus', {designId, status});
    },
    // files actions
    filesPopupAction(orderId, itemId, modelType) {
      popup({
        title: 'Manage Files',
        additional: {
          itemId: itemId,
          orderId: orderId,
          modelType: modelType,
          onUpdateFilesLinks: ({model}) => {
            this.$emit('onChangeFilesLinks', {
              itemId,
              orderId,
              modelType,
              model
            });
          }
        },
        bodyComponent: ManageFilesComponent,
        showButtons: false
      });
    },
    showTrackShippingPopup(orderId, designId) {
      popup({
        title: 'Tracking Numbers',
        additional: {
          target: 'design',
          designId: designId
        },
        bodyComponent: ShippingTrackingListComponent,
        showButtons: false,
        modalCardLong: true
      });
    },
    // printer actions
    printerStatusChangePopupAction(itemId, orderId, status, modelType) {
      if (this.designPrintStatuses[status] === 'Shipped') {
        popup({
          title: 'Are you sure?',
          message:
            'Are you sure you want to set "Shipped status"? <br>' +
            'Please note, it cannot be changed once confirmed.',
          showButtons: true,
          submitLabel: 'Confirm',
          additional: {
            callback: () => {
              this.sendPrinterStatus({itemId, orderId, status, modelType});
            },
            closeCallback: () => {
              const order = this.orders.filter(o => o.id === orderId);
              const design = order[0].designs.filter(d => d.id === itemId)[0];
              status = design.printer_status;

              this.$emit('changePrinterStatus', {
                itemId,
                orderId,
                status,
                modelType
              });
            }
          }
        });
      } else {
        this.sendPrinterStatus({itemId, orderId, status, modelType});
      }
    },
    // request is added for case with different states for different pages which use component
    sendPrinterStatus({itemId, orderId, status, modelType}) {
      return apiService
        .changePrinterStatus({itemId, status, modelType})
        .then(data => {
          if (data.status == 'error') {
            alertify.error(data.message);
          } else {
            alertify.success(
              `Printer status for the ${modelType} ` +
              `changed to ${this[modelType + 'PrintStatuses'][status]}`
            );
          }
          this.$emit('changePrinterStatus', {
            itemId,
            orderId,
            status,
            modelType,
            model: data.model
          });
        })
        .catch(err => {
          alertify.error("Printer status can't be updated. Please try later.");
        });
    },
    // printer actions
    shipTrackingPopupAction(oId, dId) {
      popup({
        title: 'Print Shipping Label',
        additional: {
          orderId: oId,
          designId: dId,
          designName: this.getDesignProperty(oId, dId, 'name'),
          tracking_number: this.getDesignProperty(oId, dId, 'tracking_number')
        },
        bodyComponent: ShippingTrackingComponent,
        submitLabel: 'Add tracking',
        hideCancel: true,
        runCallback: true
      });
    },
    assignDesignPrinterPopupAction(oId, dId) {
      popup({
        title: 'Manage Printers',
        additional: {
          target: 'design',
          orderId: oId,
          designId: dId,
          designName: this.getDesignProperty(oId, dId, 'name'),
          assignedPrinterId: this.getDesignProperty(oId, dId, 'printer_id'),
          designPrinterCallback: this.designPrinterCallback
        },
        bodyComponent: ManagePrintersComponent,
        showButtons: false,
        modalCardLong: true
      });
    },
    assignOrderPrinterPopupAction(orderId) {
      popup({
        title: 'Manage Printers',
        additional: {
          target: 'order',
          orderId: orderId
        },
        bodyComponent: ManagePrintersComponent,
        modalCardLong: true
      });
    },
    gridUpdateOrders(sortField, sortDir) {
      this.$emit('onGridSortBy', sortField, sortDir);
    }
  }
};
</script>
