<template></template>
<script>
    export default {
        name: 'logout',
        mounted(){
            this.$store.commit('destroyLogin');
            this.$router.push('/auth/login');
        }
    }
</script>

