import _ from 'lodash';
// todo: remove
/**
 * Calculate design total.
 *
 * @param design
 * @param prices
 * @returns {number}
 */
export const getDesignTotal = (design, digitalDesignData) => {
  let digitalTotal = 0;
  let digitalItem = design.specific.item;
  let printType = digitalItem.print_type;
  let sizeId = digitalItem.size;
  let quantityId = digitalItem.quantity;

  if (!printType || !sizeId || !quantityId) {
    digitalTotal += 0;
  } else if (digitalItem.cost !== undefined && digitalItem.cost !== null) {
    digitalTotal += +digitalItem.cost;
  } else if (
    printType &&
    digitalDesignData.upcharges.by_size &&
    digitalDesignData.upcharges.by_size[printType] !== undefined
  ) {
    let upchargeSizeObj = digitalDesignData.upcharges.by_size[printType].filter(
      upcharge => {
        return (
          upcharge.digital_print_size_id === sizeId &&
          upcharge.digital_print_quantity_id === quantityId
        );
      }
    )[0];

    if (upchargeSizeObj !== undefined) {
      let upcharge = parseFloat(upchargeSizeObj.upcharge) || 0;
      digitalTotal += upcharge;

      if (
        digitalItem.has_rounded_corners &&
        digitalDesignData.upcharges.rounded_corners[printType] !== undefined
      ) {
        let upchargeCornersObj =
          digitalDesignData.upcharges.rounded_corners[printType][0];

        if (upchargeCornersObj !== undefined) {
          let qtySelected = digitalDesignData.print_type_options[
            printType
          ].quantities.find(q => +q.key === +quantityId).label;
          let qtyOfRoundedCornerUpcharge = digitalDesignData.print_type_options[
            printType
          ].quantities.find(
            q => +q.key === +upchargeCornersObj.digital_print_quantity_id
          ).label;

          qtySelected = qtySelected.replace(/[^0-9 | ^.]/g, '');
          qtyOfRoundedCornerUpcharge = qtyOfRoundedCornerUpcharge.replace(
            /[^0-9 | ^.]/g,
            ''
          );

          digitalTotal +=
            qtySelected *
            ((parseFloat(upchargeCornersObj.upcharge) || 0) /
              +qtyOfRoundedCornerUpcharge);
        }
      }
    }
  }

  return digitalTotal + +design.common.upcharge;
};
