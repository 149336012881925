<template>
  <order-invoices-payment-failure-partial :additional="{transaction: transaction,routerLink: getRouterLink}"/>
</template>

<style lang="scss" scoped>
.container {
  margin-top: 100px;
}
</style>

<script>
import apiService from '../../../../services/api.service';
import OrderInvoicesPaymentFailurePartial from '@/components/orders/view/invoices/partials/payment-failure.vue';

export default {
  name: 'order-invoices-payment-failure',
  components: {OrderInvoicesPaymentFailurePartial},
  data() {
    return {
      transaction: {},
    };
  },
  computed:{
    getRouterLink() {
      if(!this.transaction.invoice){
        return null;
      }
      return '/customer/orders/' + this.transaction.invoice.order_id + '/invoice/' + this.transaction.invoice.link_hash
    }
  },
  mounted() {
    if (this.$route.params.transactionId) {
      apiService.getTransaction(this.$route.params.transactionId).then(data => {
        this.transaction = data.transaction;
      });
    }
  }
};
</script>
