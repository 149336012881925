<template>
  <div>
    <nav class="level">
      <!-- Left side -->
      <breadcrumbs :links="crumbLinks"/>
      <template v-if="!invoices.length">
        <button @click="sendInitialInvoice" class="button is-small is-primary level-right">Send Initial Invoice</button>
      </template>
    </nav>

    <table class="table is-striped">
      <tr>
        <th>Description</th>
        <th>Payment Term</th>
        <th>Status</th>
        <th>Total Sum</th>
        <th>Amount Paid</th>
        <th>Expiration date</th>
        <th>Balance Due</th>
        <th colspan="3">Invoice Actions</th>
      </tr>
      <tr v-for="invoice in invoices">
        <td>{{invoice.description}}</td>
        <td>{{paymentTerms[invoice.payment_term]}}</td>
        <td>{{statuses[invoice.status]}}</td>
        <td>{{invoice.amount | price}}</td>
        <td>{{invoice.paid_sum | price}}</td>
        <td>
          <span v-if="invoice.valid_to_date">
            {{formatDate(invoice.valid_to_date)}}
          </span>
          <span v-else> - </span>
        </td>
        <td>{{(invoice.amount - invoice.paid_sum) | price}}</td>
        <td>
          <button v-if="statuses[invoice.status] != 'Declined'"
            class="button is-small"
            @click="printInvoice(invoice)"
            v-roles="['admin', 'csr', 'production-coordinator', 'purchaser', 'art-producer', 'customer']">Print invoice</button>
          <span v-else> - </span>
        </td>
        <td>
          <button  v-if="statuses[invoice.status] != 'Declined'"
            class="button is-small"
            @click="emailInvoice(invoice.id)"
            v-roles="['admin', 'csr', 'production-coordinator', 'purchaser', 'art-producer']">Email invoice</button>
          <span v-else> - </span>
        </td>
        <td>
          <router-link v-if="statuses[invoice.status] !== 'Declined'"
            :disabled="statuses[invoice.status] === 'Paid' || statuses[invoice.status] === 'Manually Paid'"
            class="button is-small"
            :to="'/orders/'+ orderId +'/invoices/' + invoice.id + '/edit'"
            v-roles="['admin', 'csr', 'production-coordinator', 'purchaser', 'art-producer']">Edit Invoice</router-link>
          <span v-else> - </span>
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
import Vue from 'vue';
import apiService from '../../../../services/api.service';
import notify from '../../../../modules/notifier';
import breadcrumbs from './../../../common/breadcrumbs';
import moment from 'moment';
import alertify from 'alertify.js';
import { mapGetters, mapActions } from 'vuex';

const DATE_FORMAT = 'MM/DD/YY';

import { LOAD_ORDER } from '../../../../store/action-types';
export default {
  name: 'order-invoices',
  data() {
    return {
      invoices: [],
      paymentTerms: {},
      statuses: {},
      order: {}
    };
  },
  computed: {
    orderId() {
      return this.$route.params.id;
    },
    crumbLinks() {
      return [
        {
          url: '/orders',
          label: 'Orders'
        },
        {
          url: '/orders/' + this.orderId,
          label: '#' + this.orderId
        },
        {
          label: 'Invoices'
        }
      ];
    }
  },
  components: {
    breadcrumbs
  },
  methods: {
    ...mapActions({
      loadOrder: LOAD_ORDER
    }),

    getData() {
      apiService.getOrder(this.orderId).then(data => {
        this.order = data.data;
      });

      apiService.getInvoiceStatuses().then(data => {
        this.statuses = data;
      });

      apiService.getPaymentTerms().then(data => {
        data.forEach(option => {
          this.paymentTerms[option.key] = option.label;
        });
      });

      apiService
        .getInvoices(this.orderId)
        .then(data => {
          this.invoices = data;
        })
        .catch(err => {
          notify({
            message: 'Something wrong',
            type: 'danger'
          });
        });
    },

    printInvoice(invoice) {
      window.open('/#/invoices/' + invoice.link_hash + '/print');
    },

    sendInitialInvoice(invoice) {
      alertify.okBtn('Send').confirm('Initial invoice will be sent', ev => {
        let data = {
          invoice: {
            payment_term: this.order.payment_term,
            description: 'Initial invoice for order #' + this.orderId
          },
          orderId: this.orderId
        };
        apiService.requestInitialPayment(data).then(response => {
          apiService
            .getInvoices(this.orderId)
            .then(response => {
              this.invoices = response;
              alertify.success('Invoice was sent.');
            })
            .catch(err => {
              notify({
                message: Object.values(err.body.messages).join('<br>'),
                type: 'danger'
              });
            });
          this.$router.push('/orders/' + this.orderId + '/invoices');
        });
      });
    },

    emailInvoice(id) {
      apiService
        .emailInvoice(id)
        .then(data => {
          if (data.status == 'success') {
            notify({
              message: 'Invoice sent.',
              type: 'success'
            });
          } else {
            notify({
              message: data.message,
              type: 'danger'
            });
          }
        })
        .catch(err => {
          notify({
            message: 'Something wrong.',
            type: 'danger'
          });
        });
    },

    formatDate(date) {
      return moment(date).format(DATE_FORMAT);
    }
  },
  watch: {
    orderId() {
      this.getData();
    }
  },
  mounted() {
    this.getData();
  }
};
</script>
