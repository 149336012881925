<template>
  <div class="poster-design-item">
    <div class="container">
      <table class="table">
        <thead>
          <tr>
            <th>Print Type</th>
            <th>Paper Type</th>
            <th>Size</th>
            <th>Quantity</th>
            <th>Has Rounded Corners</th>
            <th v-roles="['admin', 'csr', 'production-coordinator', 'purchaser', 'art-producer', 'customer']">Total</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{{design.category}}</td>
            <td>{{design.paperType}}</td>
            <td>{{design.size}}</td>
            <td>{{design.total.qty | itemsNumberFormat}}</td>
            <td>{{hasRoundedCorders ? 'Yes' : 'No'}}</td>
            <td v-roles="['admin', 'csr', 'production-coordinator', 'purchaser', 'art-producer', 'customer']">{{design.total.price | price}}</td>
          </tr>
        </tbody>
      </table>
    </div>

    <div v-if="design.total && design.total.upcharge > 0" v-roles="['admin', 'csr', 'production-coordinator', 'purchaser', 'art-producer', 'customer']">
      <strong>Upcharge for <span v-if="design.name">{{design.name}}</span><span v-else>Design #{{parseInt(index) + 1}}</span>:</strong> {{design.total.upcharge | price}}
      <p v-if="design.total.upcharge_description">{{design.total.upcharge_description}}</p>
    </div>
  </div>
</template>

<script>
  import Vue from 'vue';

  export default {
    name: 'poster-design-type',
    props: ['design'],
    computed: {
      hasRoundedCorders() {
        return this.design.designable &&
          this.design.designable.item &&
          this.design.designable.item.has_rounded_corners;
      }
    }
  };
</script>

