<template>
  <div>
    <h5 class="title">Change Password</h5>

    <form @submit.prevent="attempt" class="box">
      <!-- Contact Information -->
      <fieldset class="contact-information control">
        <div class="columns">
          <div class="column is-4">
            <form-input type="password" v-model="password_data.current_password"
                        name="current_password" scope="change-password-form"
                        rules="required" label="Current Password"/>
          </div>
        </div>
        <div class="columns">
          <div class="column is-4">
            <form-input type="password" v-model="password_data.password"
                        name="password" scope="change-password-form"
                        rules="required" label="New Password"/>
          </div>
        </div>
        <div class="columns">
          <div class="column is-4">
            <form-input type="password"
                        v-model="password_data.password_confirmation"
                        name="password_confirmation"
                        scope="change-password-form" rules="required"
                        label="Confirm Password"/>
          </div>
        </div>
      </fieldset>

      <br>
      <div class="field is-grouped">
        <button type="submit"
                class="button is-primary"
                :class="{'is-loading': processing} "
                :disabled="processing">Save
        </button>
        <button class="button" @click.prevent="cancel">Cancel</button>
      </div>
      <!-- END Contact Info-->
    </form>
  </div>
</template>

<script>
  import Vue from 'vue';
  import alertify from 'alertify.js';
  import bus from '../../bus';

  import {forEach, isEmpty} from 'lodash';
  import notify from '../../modules/notifier/index';
  import {checkRoles} from '../../router/checkRoles';
  import apiService from '../../services/api.service';
  import formInput from '../common/form/input';
  import VeeValidate from 'vee-validate';

  Vue.use(VeeValidate);

  import {CHANGE_PASSWORD} from '../../store/action-types';

  export default {
    name: 'change-password-form',

    data() {
      return {
        password_data: {
          current_password: null,
          password: null,
          password_confirmation: null,
        },
        messages: [],
        processing: false,
      }
    },
    computed: {
      userId() {
        return this.$route.params.userId;
      },
    },
    components: {
      formInput
    },
    mounted() {
      if (this.userId != this.$store.getters.userProfile.id
        && !checkRoles(this.$store.getters.roles, ['admin', 'csr', 'customer', 'production-coordinator'])) {
        alertify.error("You're not allowed to do this action");
        this.$router.push('/');
      }
    },
    methods: {
      cancel() {
        this.$router.push('/accounts/' + this.userId + '/view')
      },
      validateBeforeSubmit(e) {
        this.clearErrors();
        this.validateChild();

        return new Promise((resolve, reject) => {
          Vue.nextTick(() => {
            resolve(!this.errors.count());
          });
        });
      },

      validateChild() {
        bus.$emit('validate', 'change-password-form');
      },
      clearErrors() {
        bus.$emit('clear');
        this.errors.errors = [];
      },
      attempt: function (e) {
        this.validateBeforeSubmit().then(isValid => {
          if (isValid) {
            this.messages = [];
            this.processing = true;
            this.$store.dispatch(CHANGE_PASSWORD, this.password_data).then((data) => {
              this.processing = false;
              alertify.success('Password successfully changed');
              this.$router.push('/accounts/' + this.userId + '/view')
            }).catch((response) => {
              this.processing = false;

              if (response.body && response.body.messages && response.body.messages.length) {
                response.body.messages.forEach(function (message) {
                  alertify.error(message);
                });

              } else {
                alertify.error('Something wrong :(');
              }
            });
          }
        });
      }
    },
    created() {
      bus.$on('errors-changed', (errors) => {
        if (errors) {
          errors.forEach(e => {
            this.errors.add(e.field, e.msg, e.rule, e.scope);
          });
        }
      });
      bus.$on('errors-deleted', (oldErrors) => {
        if (oldErrors) {
          oldErrors.forEach(e => {
            this.errors.remove(e.field, e.scope);
          });
        }
      });
    }

  };
</script>

