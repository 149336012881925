<template>
  <div class="page-content container">
    <template v-if="!submitted">
      <div class="details-page-header">
        <h3 class="title is-3">Estimate Details</h3>
        <router-link :to="'/customer/dashboard'">< Back to Orders</router-link>
      </div>

      <div v-if="!isLoading">
        <div v-if="data" class="columns">
          <div class="column is-9">
            <details-estimate
              :data="data"
              :formOptions="formOptions"
              :shippingOptions="shippingOptions"
              :finishingsPrices="finishingsPrices"
              v-if="!isLoading"
            ></details-estimate>

            <div v-if="!data.order.is_approved && data.order.estimate_sent_at" class="mt-4 buttons is-centered">
              <button @click="approveEstimate" class="button is-success has-text-white">Approve</button>
              <button @click="showRequestRevisionModal" class="button">Request Revision</button>
            </div>
            <modals-container :clickToClose="false"/>
            <section v-if="data.order.is_approved" class="mt-4 hero has-text-centered">
              <div class="hero-body">
                <div class="container">
                  <p class="subtitle">The estimate is already approved.</p>
                </div>
              </div>
            </section>
          </div>
          <div class="column is-3">
            <div class="estimate-totals" v-if="!isLoading">
              <h3 class="estimate-totals__title">Order Summary</h3>
              <ul class="estimate-totals__list">
                <li>
                  <div class="item">
                    <span class="item__label">Designs</span>
                    <span class="item__total">{{ totals.designsTotal  | price }}</span>
                  </div>
                  <ul class="estimate-totals__subitems">
                    <li v-for="designTotal, index in totals.designs" :key="index">
                      <div class="item">
                        <label class="item__label">Design {{ index + 1 }}</label>
                        <span class="item__total">{{ designTotal.total | price }}</span>
                      </div>
                    </li>
                  </ul>
                </li>
                <li class="estimate-totals__item" v-if="totals.tagSetups > 0">
                  <div class="item">
                    <span class="item__label">Tag Setups</span>
                    <span class="item__total">{{ totals.tagSetups | price }}</span>
                  </div>
                </li>
                <li class="estimate-totals__item">
                  <div class="item">
                    <span class="item__label">Shipping</span>
                    <span class="item__total">{{ totals.shipping | price }}</span>
                  </div>
                </li>
                <li class="estimate-totals__item" v-if="data.payment.state_tax">
                  <div class="item">
                    <span class="item__label">State Tax</span>
                    <span class="item__total">{{ data.payment.state_tax | price }}</span>
                  </div>
                </li>
                <li class="estimate-totals__item" v-if="totals.rushFee > 0">
                  <div class="item">
                    <label class="item__label">Rush Fee ({{ data.payment.rush_fee.rush_fee_percentage }}%)</label>
                    <span class="item__total">{{ totals.rushFee | price }}</span>
                  </div>
                </li>
                <li class="estimate-totals__item" v-if="totals.discount > 0">
                  <div class="item">
                    <label class="item__label">Discount ({{ data.payment.discount.discount_percentage }}%)</label>
                    <span class="item__total">{{ totals.discount | price }}</span>
                  </div>
                </li>
              </ul>
              <ul class="estimate-totals__list is-total">
                <li>
                  <div class="item">
                    <span class="item__label">Total Cost</span>
                    <span class="item__total">{{ estimateTotal | price }}</span>
                  </div>
                </li>
                <li class="is-amount" v-if="data.order.invoice">
                  <div class="item">
                    <span class="item__label">Amount Paid</span>
                    <span class="item__total">{{ data.order.invoice.paid_sum | price }}</span>
                  </div>
                </li>
              </ul>
              <div class="estimate-totals__balance-due"
                :class="{ 'is-paid': balanceDue === 0 }"
                v-if="data.order.invoice"
              >
                <span>Balance Due</span>
                <span class="value">{{ balanceDue | price }}</span>
              </div>
            </div>
          </div>
        </div>
        <section v-else class="mt-4 hero has-text-centered">
          <div class="hero-body">
            <div class="container">
              <p class="subtitle">The estimate not found.</p>
            </div>
          </div>
        </section>
      </div>
      <section v-else class="mt-4 hero has-text-centered">
        <div class="hero-body">
          <div class="container">
            <p class="subtitle">Loading...</p>
          </div>
        </div>
      </section>
    </template>
    <template v-else>
      <div class="hero-body">
        <div class="container has-text-centered">
          <span class="icon is-success is-large"><i class="ff-android-done ff-3x"></i></span>
          <h2 class="title is-2 has-text-centered mt-5">Thank you for approving the estimate.</h2>
          <h4 class="subtitle is-4 has-text-centered">The invoice for payment has been sent to your email.</h4>
          <router-link v-if="data.order.invoice" class="button is-success has-text-white" :to="'/customer/orders/' + data.order.id + '/invoice/' + data.order.invoice.link_hash + '/'">Pay Now</router-link>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import Vue from 'vue';
import EstimateSummary from './approve-summary';
import { mapActions } from 'vuex';
import { getEstimateTotals } from '@/helpers/prices';

import apiService from '../../../services/api.service';
import notify from '../../../modules/notifier';
import moment from 'moment';
import alertify from 'alertify.js';
import RequestRevisionPopup from '@/components/common/Popups/RequestRevisionPopup';

import {
  IS_VIEWED,
  LOAD_EDIT_ORDER_INIT_DATA
} from '../../../store/action-types';

import DetailsEstimate from '@/components/common/DetailsEstimate/DetailsEstimate';

export default {
  name: 'estimate-approve',
  components: {
    DetailsEstimate,
    EstimateSummary,
    RequestRevisionPopup
  },
  data() {
    return {
      estimate: null,
      invoice: null,
      submitted: false,
      isLoading: true,
      data: null,
      formOptions: null,
      finishingsPrices: null,
      shippingOptions: null
    };
  },
  watch: {
    estimateId(id) {
      this.loadOrder();
    }
  },
  computed: {
    estimateSummary() {
      return this.estimate ? JSON.parse(this.estimate.estimate) : {};
    },
    estimateId() {
      return this.$route.params.id;
    },
    totals() {
      return getEstimateTotals(
        this.data,
        this.formOptions,
        this.finishingsPrices
      );
    },
    estimateRawTotal() {
      let total = 0;
      total += +this.totals.designsTotal;
      total += +this.totals.shipping;
      total += +this.totals.stateTaxTotal;
      total += +this.totals.tagSetups;
      if (total === 0) return 0;
      return total;
    },
    estimateTotal() {
      let total = this.estimateRawTotal;
      if (total === 0) return 0;
      total -= +this.totals.discount;
      total += +this.totals.rushFee;
      if (total <= 0) return 0;
      return total;
    },
    balanceDue() {
      return this.data.order.invoice.amount - this.data.order.invoice.paid_sum;
    }
  },
  methods: {
    ...mapActions({
      getInitData: LOAD_EDIT_ORDER_INIT_DATA
    }),
    fetchEstimateData() {
      return apiService
        .getEstimateData(this.$route.params.id)
        .then(({ data }) => {
          this.data = data;
        })
        .catch(error => {
          console.error(error);
        });
    },
    fetchFormOptions(userId) {
      return apiService
        .get(
          `estimates/designs/form-options?${
            userId ? `filter[user_id]=${userId}` : ''
          }`
        )
        .then(({ data }) => {
          this.formOptions = this.convertFormOptions(data);
        });
    },
    fetchFinishingsPrices(userId) {
      return apiService.getApparelFinishingPrices(userId).then(response => {
        this.finishingsPrices = response;
      });
    },
    fetchShippingMethods() {
      return apiService.getShippingMethods().then(({ data }) => {
        const { ups } = data;
        this.shippingOptions = ups.services;
      });
    },
    convertFormOptions(data) {
      Object.keys(data).forEach(service => {
        if (!data[service].locations) return;
        const locationsRaw = data[service].locations;
        const locations = Object.keys(locationsRaw).map(groupKey => {
          return {
            title: groupKey,
            options: Object.keys(locationsRaw[groupKey]).map(childKey => {
              return {
                id: childKey,
                label: locationsRaw[groupKey][childKey]
              };
            })
          };
        });
        data[service].locations = locations;
      });
      return data;
    },
    approveEstimate() {
      apiService
        .approveEstimate(this.estimateId)
        .then(data => {
          this.submitted = true;
          // if (
          //   this.data &&
          //   this.data.order &&
          //   this.data.order.invoice &&
          //   this.data.order.invoice.id
          // ) {
          //   apiService.emailInvoice(this.data.order.invoice.id);
          // }
          alertify.success(data.message);
        })
        .catch(err => {
          alertify.error(err.statusText);
        });
    },
    loadOrder() {
      apiService
        .getCustomerOrder(this.estimateId)
        .then(data => {
          if (data.data.is_lost) {
            this.$router.push('/');
          } else {
            this.$set(this, 'estimate', data.data);

            // Set latest invoice.
            if (data.data && data.data.latest_invoice) {
              this.$set(this, 'invoice', data.data.latest_invoice);
            }
          }
        })
        .catch(err => {
          alertify.error(err.statusText);

          this.$nextTick(() => {
            this.$router.push('/');
          });
        });
    },
    requestRevisionEstimate(message) {
      apiService
        .requestRevisionPublicEstimate(this.data.order.id, { message: message })
        .catch(err => {
          if (err.body && err.body.message) {
            alertify.error(err.body.message);
          } else {
            console.log(err);
          }
        });
    },
    showRequestRevisionModal() {
      this.$modal.show(
        RequestRevisionPopup,
        {
          callback: message => {
            this.requestRevisionEstimate(message);
            this.$modal.hide('request-revision-popup');
          }
        },
        {
          name: 'request-revision-popup',
          title: 'Request Revision',
          height: 'auto'
        }
      );
    }
  },
  created() {
    this.isLoading = true;
    Promise.all([
      this.fetchEstimateData(),
      this.fetchShippingMethods(),
      this.getInitData()
    ])
      .then(() =>
        Promise.all([
          this.fetchFormOptions(this.data.account.account_id),
          this.fetchFinishingsPrices(this.data.account.account_id)
        ])
      )
      .then(() => {
        this.isLoading = false;
        if (this.data.order.is_lost) {
          this.$router.push('/');
        } else {
          if (!this.data.order.is_viewed) {
            this.$store.dispatch(IS_VIEWED, {
              estimateId: this.estimateId,
              vue: this
            });
          }
        }
      });
  }
};
</script>
