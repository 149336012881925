<template>
  <div>
    <customPricesApparel
      :formScope="formScope"
      :isEmbroidery="true"
    ></customPricesApparel>
  </div>
</template>

<script>
  import customPricesApparel from './custom-prices-apparel';

  export default {
    props: ['formScope'],

    name: 'custom-prices-embroidery',
    components: {
      customPricesApparel
    }
  };
</script>
