<template>
  <div class="">
    <nav class="level">
      <!-- Left side -->
      <breadcrumbs :links="crumbLinks"/>
    </nav>
    <h3 class="title is-3 has-text-centered">Create New Invoice</h3>
    <div class="columns">
      <div class="column is-half is-offset-one-quarter">
        <invoice-form :invoice="invoice"
          :processing="processing"
          @submitForm="submitForm"></invoice-form>
      </div>
    </div>
  </div>
</template>

<script>
  import Vue from 'vue';

  import apiService from '../../../../services/api.service';
  import notify from '../../../../modules/notifier';
  import invoiceForm from './form';
  import breadcrumbs from './../../../common/breadcrumbs';

  export default {
    name: 'order-invoices-new',
    data() {
      return {
        invoice: {
          amount: null,
          description: null,
          payment_term: '',
        },
        processing: false
      }
    },
    components: {
      invoiceForm,
      breadcrumbs
    },
    computed: {
      orderId() {
        return this.$route.params.id;
      },
      crumbLinks() {
        return [{
          url: '/orders',
          label: 'Orders'
        }, {
          url: '/orders/' + this.orderId,
          label: '#' + this.orderId
        }, {
          url: '/orders/' + this.orderId + '/invoices',
          label: 'Invoices'
        }, {
          label: 'New'
        }];
      }
    },
    methods: {
      submitForm({isValid, model}) {
        if (isValid) {
          this.processing = true;

          apiService.requestPayment({
            orderId: this.orderId,
            invoice: model
          }).then(data => {
            notify({
              message: 'Invoice sent.',
              type: 'success'
            });
            this.processing = false;
            this.$router.push('/orders/' + this.orderId + '/invoices');
          });
        } else {
          notify({
            message: 'Validation failed. Please check error messages',
            type: 'danger'
          });
          this.processing = false;
        }
      }
    }
  };
</script>

