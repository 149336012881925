<template>
  <div class="content order-summary-content">
    <h3 class="subtitle" :id="'design-' + (parseInt(index) + 1)">
      Design #{{parseInt(index) + 1}}{{shortOne ? `: ${design.name}` : ''}}
      <template v-if="!shortOne">
        <span class="printed-before-reprint-label" v-if="design.printed_before_order_id">
          - Reprint
          <router-link class="link"
            :to="getOrderPageUrl(design.printed_before_order_id)">
            #{{design.printed_before_order_id}}
          </router-link>
        </span>
        <span class="printed-before-reprint-label" v-if="design.reference_order_id">
          - Reference
          <router-link class="link"
            :to="getOrderPageUrl(design.reference_order_id)">
            #{{design.reference_order_id}}
          </router-link>
        </span>
      </template>
      <div class="is-warning tag is-pulled-right"
           v-if="design.type_of_delivery == 'pickup'">PICKUP</div><br>
    </h3>

    <div class="design-summary-notes" v-if="!shortOne">
      <div v-if="design.printed_before" class="printed-before">Design has been printed before</div>
      <div v-if="design.reference_order_id" class="printed-before">Reference design has been printed before</div>
    </div>
    <h5 v-if="design.name && !shortOne" class="subtitle"><strong>Design:</strong> {{design.name}}</h5>

    <div v-if="design.total">
      <div class="control">
        <p><strong>Shape: </strong>{{design.shape}}</p>
      </div>

      <table class="table table__without-hover">
        <thead>
          <tr>
            <th>Size</th>
            <th>Qty.</th>
            <th v-roles="['admin', 'csr', 'production-coordinator', 'purchaser', 'art-producer', 'customer']" :class="{'align-right': shortOne}">Total</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td><strong>{{design.size}}</strong></td>
            <td>{{design.total.qty | itemsNumberFormat}}</td>
            <td v-roles="['admin', 'csr', 'production-coordinator', 'purchaser', 'art-producer', 'customer']" :class="{'align-right': shortOne}">{{design.total.price | price}}</td>
          </tr>
        </tbody>
      </table>

      <div v-if="design.total && design.total.upcharge > 0" v-roles="['admin', 'csr', 'production-coordinator', 'purchaser', 'art-producer', 'customer']">
        <strong>Upcharge for <span v-if="design.name">{{design.name}}</span><span v-else>Design #{{ parseInt(index)+1 }}</span>:</strong> {{design.total.upcharge | price}}
        <p v-if="design.upcharge_description">{{design.upcharge_description}}</p>
      </div>
    </div>

    <div v-if="design.comment">
      <p>
        <strong>Comment: </strong>
        <span>{{design.comment}}</span>
      </p>
    </div>
  </div>
</template>

<script>
  import Vue from 'vue'

  export default {
    props: ['design', 'index', 'shortOne'],
    name: 'summary-sticker',
    methods: {
      getOrderPageUrl(id) {
        return this.$route.path.indexOf('customer') !== -1 ?
          `/customer/orders/${id}/details` : `/orders/${id}`;
      }
    }
  };
</script>
