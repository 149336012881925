<template>
  <div
    class="image-gallery"
    v-if="images.length && currentAttachment && currentAttachment.filepath || editable"
  >
    <div class="full-image-holder">
      <div v-if="!addImageState && currentUrl" class="full-image">
        <a target="_blank" :href="currentUrl">
          <img :src="currentUrl" />
        </a>
      </div>
      <div
        tooltip
        tooltip-ico="pensil"
        tooltip-position="right"
        v-show="addImageState"
        class="image-uploader dotted flex-holder"
        :class="{'dragging': $refs.upload && $refs.upload.dropActive}"
      >
        <div class="centered-holder">
          <i class="icon add-image-plus ff-plus"></i>
          <h3>Drag &amp; Drop Images</h3>
          <p>or <br /> upload below</p>
          <file-upload
            name="file"
            input-id="file1"
            :class="{'upload-btn button is-primary': true, 'is-danger': errors.has('imagesUrls') }"
            extensions="jpeg,jpg,png,gif,webp,bmp"
            accept="image/*"
            :multiple="true"
            :drop="true"
            :post-action="postAction"
            :data="sendData"
            v-model="uploadFiles"
            @input="fileAdded"
            ref="upload">
              Choose Files
          </file-upload>
        </div>
        <span class="is-danger" v-if="errors.has('imagesUrls')">Please, add at least one file.</span>
      </div>
    </div>
    <swiper
      class="thumbs-holder"
      ref="imagesSwiper"
      :options="swiperOption"
      v-sortable="{ sort: editable, wrapperSelector: '.swiper-wrapper', onUpdate: onImagesSort }">
      <swiper-slide
        class="item"
        v-for="(url, key) in imagesUrlsFromS3"
        :key="url">
        <div
          class=""
          :class="{'is-active': isActiveAttachment(key)}"
          @click.prevent="setActiveAttachment(key)">
          <img :src="url" />
        </div>
        <button
          class="delete is-small"
          v-if="editable"
          @click.prevent="deleteAttachment(key)"
        ></button>
      </swiper-slide>
      <swiper-slide
        key="add-image"
        v-if="editable">
        <div class="add-image dotted flex-holder" @click.prevent="addImage">
          <div class="centered-holder">
            <i class="icon add-image-plus ff-plus"></i>
            <strong>Add Image</strong>
          </div>
        </div>
      </swiper-slide>
      <div class="swiper-button-prev" slot="button-prev" @dragover="slidePrev"></div>
      <div class="swiper-button-next" slot="button-next" @dragover="slideNext"></div>
    </swiper>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import FileUpload from 'vue-upload-component';
import { swiper, swiperSlide } from 'vue-awesome-swiper';

import apiService from '../../../services/api.service';
import notify from '../../../modules/notifier';
import { SET_ACTIVE_ATTACHMENT } from '../../../store/action-types';
import { isThumbable, fileExt } from '../../../helpers/files';
import Xml from '../../../helpers/xml-helper';
import bus from '../../../bus';

export default {
  name: 'image-gallery',
  components: {
    FileUpload,
    swiper,
    swiperSlide
  },
  props: {
    images: {
      type: Array,
      default: () => []
    },
    editable: {
      type: Boolean,
      default: false
    },
    mock: {
      type: Object,
      required: true
    },
    design: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      postAction: '',
      sendData: {},
      uploadFiles: [],
      imagesUrls: [],
      addImageState: this.editable,
      swiperOption: {
        slidesPerView: 4,
        spaceBetween: 10,
        allowTouchMove: false,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        }
      }
    };
  },
  computed: {
    ...mapGetters('revision', ['currentAttachmentKey', 'currentAttachment']),
    ...mapGetters(['s3Domain']),
    currentUrl() {
      if (this.imagesUrls[this.currentAttachmentKey]) {
        const { filepath } = this.imagesUrls[this.currentAttachmentKey];
        return filepath.indexOf('data') !== 0
          ? this.s3Domain + filepath
          : filepath;
      }
      return '';
    },
    imagesUrlsFromS3() {
      return this.imagesUrls.map(img => {
        if (img.filepath.indexOf('data') !== 0) {
          return this.s3Domain + img.filepath;
        }
        return img.filepath;
      });
    }
  },
  watch: {
    postAction(val) {
      if (this.uploadFiles.length) {
        this.uploadFiles.forEach(file => {
          file.postAction = val;
          if (this.sendData) {
            file.data = this.sendData;
          }
        });
      }
    },
    images() {
      this.imagesUrls = this.images.map(img => ({
        filepath: img.filepath
      }));
    },
    editable(bool) {
      this.$set(this, 'addImageState', bool);
    },
    imagesUrls(imagesUrls) {
      if (!imagesUrls.length) {
        this.$set(this, 'addImageState', this.editable);
      }
      this.$validator.validate('imagesUrls', imagesUrls);
    },
    mock() {
      this.$set(this, 'uploadFiles', []);
    },
    design() {
      this.$set(this, 'uploadFiles', []);
    }
  },
  methods: {
    ...mapActions('revision', {
      setActiveAttachmentToState: SET_ACTIVE_ATTACHMENT
    }),
    fileExt: fileExt,
    isThumbable: isThumbable,
    getFormData() {
      return this.imagesUrls;
    },
    fileAdded(files) {
      files.forEach(({ file }) => {
        const reader = new FileReader();
        reader.onload = e => {
          if (
            !this.imagesUrls.find(urlObj => urlObj.filepath === e.target.result)
          ) {
            this.imagesUrls.push({ file, filepath: e.target.result });
            // set last image from array
            this.setActiveAttachment(this.imagesUrls.length - 1);
            // diactivate upload screen
            this.$set(this, 'addImageState', false);
          }
        };
        reader.readAsDataURL(file);
      });
    },
    setActiveAttachment(key) {
      //this.$set(this, 'addImageState', false);
      this.setActiveAttachmentToState(key);
    },
    isActiveAttachment(key) {
      return this.currentAttachmentKey == key;
    },
    addImage() {
      this.$refs.upload.$children[0].$el.click();
    },
    sendFiles(data) {
      return apiService
        .getS3Form({
          designId: data.designId,
          mockId: data.mockId
        })
        .then(s3Data => {
          this.postAction = s3Data.form_url;
          this.sendData = s3Data.inputs;

          let mocksData = {
            mock_id: data.mockId,
            filepaths: []
          };
          return new Promise((resolve, reject) => {
            if (!this.$refs.upload) {
              reject(new Error('File uploader error'));
            } else {
              this.$refs.upload.active = true;
              this.uploadInterval = setInterval(() => {
                if (this.$refs.upload.uploaded) {
                  // get response information
                  this.uploadFiles.forEach(file => {
                    if (file) {
                      let keys = Xml.parse(file.response).getElementsByTagName(
                        'Key'
                      );
                      if (keys.length) {
                        this.imagesUrls.forEach(img => {
                          if (img.file && img.file.name === file.name) {
                            img.s3path = keys[0].innerHTML;
                          }
                        });
                      }
                    }
                  });
                  mocksData.filepaths = this.imagesUrls.map(
                    img => img.s3path || img.filepath
                  );
                  clearInterval(this.uploadInterval);
                  resolve(
                    apiService
                      .storeMockFiles(mocksData)
                      .then(result => {
                        if (result.status === 'success') {
                          notify({
                            message: 'Mock files are uploaded',
                            type: 'success'
                          });
                        }
                        return result;
                      })
                      .catch(err => {
                        console.error(err);
                        notify({
                          message: "Couldn't save mock files",
                          type: 'error'
                        });
                      })
                  );
                }
              }, 100);
            }
          });
        });
    },
    deleteAttachment(key) {
      this.$set(this, 'addImageState', true);
      let images = this.imagesUrls.filter((image, imgKey) => {
        if (+key !== +imgKey) {
          return true;
        } else {
          this.uploadFiles = this.uploadFiles.filter(file => {
            return (
              image &&
              image.file &&
              image.file.name &&
              image.file.name !== file.file.name
            );
          });
          return false;
        }
      });
      this.$set(this, 'imagesUrls', images);
      this.$nextTick(() => {
        if (this.imagesUrls && this.imagesUrls.length) {
          this.setActiveAttachment(this.imagesUrls.length - 1);
          this.$set(this, 'addImageState', false);
        } else {
          this.$set(this, 'addImageState', true);
        }
      });
    },
    onValidate() {
      this.$validator.validate('imagesUrls', this.imagesUrls);
    },
    onClear() {
      this.errors.clear();
    },
    slideNext() {
      this.$refs.imagesSwiper.swiper.slideNext();
    },
    slidePrev() {
      this.$refs.imagesSwiper.swiper.slidePrev();
    },
    onImagesSort: function(event) {
      if (this.imagesUrls.length) {
        this.imagesUrls.splice(
          event.newIndex,
          0,
          this.imagesUrls.splice(event.oldIndex, 1)[0]
        );
      }
    }
  },
  mounted() {
    this.$set(this, 'addImageState', !!this.currentUrl);
    this.imagesUrls = this.images.map(img => {
      return { filepath: img.filepath };
    });
    this.$nextTick(() => {
      this.onValidate();
    });
  },
  created() {
    this.$validator.attach('imagesUrls', 'required');
    bus.$on('validate', this.onValidate);
    bus.$on('clear', this.onClear);
    this.$watch(
      () => this.errors.errors,
      (value, oldValue) => {
        if (value.length) {
          bus.$emit('errors-changed', value);
        } else {
          bus.$emit('errors-deleted', oldValue);
        }
      }
    );
  },
  beforeDestroy() {
    bus.$emit('errors-deleted', this.errors.errors);
    bus.$off('validate', this.onValidate);
    bus.$off('clear', this.onClear);
  },
  destroyed() {
    if (this.uploadInterval) clearInterval(this.uploadInterval);
  }
};
</script>

<style lang="scss" scoped>
.full-image-holder {
  padding: 20px 0;
  border: 1px solid #ccc;
  border-width: 1px 0;
  margin-bottom: 20px;

  img {
    display: block;
    margin: 0 auto;
    max-width: 100%;
    max-height: 100%;
  }

  .dragging {
    background-color: lightblue;
  }
}
.dotted {
  border: 3px dashed #eeeeee;
}
.flex-holder {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .centered-holder {
    width: auto;
  }
}
.add-image {
  height: 175px;
  text-align: center;
  cursor: pointer;

  strong {
    display: block;
  }
}
.image-uploader {
  height: 500px;
  text-align: center;

  .add-image-plus {
    font-size: 90px;
    height: 90px;
    width: 90px;
  }
  h3 {
    text-transform: uppercase;
    font-weight: bold;
  }
  p {
    font-size: 12px;
    margin-bottom: 10px;
  }
  .upload-btn {
    height: auto;
    line-height: 24px;
    color: #ffffff;
  }
}
.add-image-plus {
  font-size: 60px;
  height: 60px;
  width: 60px;
  margin: 0 auto 20px;
  display: block;
  color: #cccccc;
}

.thumbs-holder {
  padding-bottom: 20px;
  border-bottom: 1px solid #ccc;
  margin-bottom: 20px;

  .item {
    cursor: pointer;
    height: 175px;
    overflow: hidden;
    position: relative;

    img {
      max-width: 100%;
      max-height: 100%;
    }
    .delete.is-small {
      display: block;
      width: 16px;
      height: 16px;
      position: absolute;
      top: 3px;
      right: 3px;
      overflow: hidden;
      text-indent: -9999px;
      background-color: red;
    }
  }
}
span.is-danger {
  margin-top: 10px;
  color: red;
}
</style>
