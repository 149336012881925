<template>
  <div>
    <filter-estimate
      :filters-options="filterOptions"
      @takeFilters="onFilters"/>
    <grid :orders="gridData"
      :finishings="gridOrderFinishings"
      :designs="gridOrderDetails"
      :columns="gridHeaders"
      :loading="estimatesLoading"
      noResMsg="No estimates"
      :showTriangle="false"
      @onGridSortBy="gridUpdateOrders"/>

    <paginator v-if="estimates.length"
      :pagesNumber="estimatesPaginatorData.last_page"
      :prevPageUrl="estimatesPaginatorData.prev_page_url"
      :nextPageUrl="estimatesPaginatorData.next_page_url"
      :currentPage="estimatesPaginatorData.current_page"
      :getListItems="loadEstimatesPaginated"
      :showOffset="true"
      :totalNumber="estimatesPaginatorData.total"
      :sortField="sortField"
      :sortDir="sortDir"/>

    <v-dialog :clickToClose="false" />
  </div>
</template>

<!--<style lang="scss">-->
<!--.table.grid1 {-->
<!--  thead {-->
<!--    .head-row {-->
<!--      z-index: 999;-->
<!--    }-->
<!--  }-->
<!--}-->
<!--</style>-->

<script>
import popup from '../../modules/popupper';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import pubnubService from '../../services/pubnub.service';
import apiService from '../../services/api.service';
import filterEstimate from './filter-estimate.vue';
import StatusLostReasonComponent from '../dashboard/popups/status-lost-reason.vue';

import {
  LOAD_ESTIMATES,
  SEND_ESTIMATE, SET_ESTIMATE_IS_APPROVED,
  SET_ORDER_STATUS,
  SET_ESTIMATE_SENT_DATE
} from '@/store/action-types';

import grid from '../common/grid/grid.vue';
import paginator from '../common/paginator.vue';

import {
  activeOrderActionsDetails,
  ESTIMATE_FILTER_STATUSES,
  getGridData,
  getGridMyEstimatesFinishings,
  getGridMyEstimatesFinishingsActions,
  getGridOrderDetails,
  gridHeaders
} from '@/helpers/estimates';
import { cloneDeep } from 'lodash';
import alertify from 'alertify.js';

export default {
  name: 'estimates-list',
  data() {
    return {
      filters: {
        date_to: '',
        date_from: '',
        status: ESTIMATE_FILTER_STATUSES.STATUS_FILTER_ALL
      },
      timezone: '',
      gridHeaders,
      sortField: 'created_at',
      sortDir: 'DESC',
      page: 1,
      perPage: 50
    };
  },
  computed: {
    ...mapGetters([
      'estimates',
      'estimatesPaginatorData',
      'estimatesLoading',
      'unreadOrdersMessages',
      'roles',
      'filterOptions'
    ]),

    gridData() {
      return {
        data: getGridData(
          this.estimates,
          this.roles,
          {
            setStatusOrder: this.setStatusOrder,
            setStatusLost: this.setStatusLost,
            estimateDetails: this.estimateDetails,
            subtag: this.subActions,
            showLostReason: this.showLostReason,
            deleteEstimate: this.deleteEstimate
          },
          true
        )
      };
    },

    gridOrderDetails() {
      return {
        data: getGridOrderDetails(this.estimates),
        actions: activeOrderActionsDetails(this.estimates)
      };
    },

    gridOrderFinishings() {
      return {
        data: getGridMyEstimatesFinishings(this.estimates),
        actions: getGridMyEstimatesFinishingsActions(this.estimates)
      };
    }
  },
  components: {
    grid,
    paginator,
    filterEstimate
  },
  created() {
    this.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  },
  mounted() {
    this.gridUpdateOrders(this.sortField, this.sortDir);
    pubnubService.subscribeUpdateEvent('order', this.orderUpdateHandler);
  },
  destroyed() {
    pubnubService.unsubscribeUpdateEvent('order', this.orderUpdateHandler);
  },
  methods: {
    ...mapActions({
      loadEstimates: LOAD_ESTIMATES,
      setEstimateIsApproved: SET_ESTIMATE_IS_APPROVED,
      setEstimateSentAtDate: SET_ESTIMATE_SENT_DATE
    }),
    ...mapMutations(['dropRow']),
    loadEstimatesPaginated(page, url, perPage, sortField, sortDir) {
      // Update values in component to populate it for other methods. For example - "gridUpdateOrders"
      this.page = page;
      this.perPage = perPage;
      let pagination_filter = {
        page,
        url,
        perPage,
        sortField,
        sortDir,
        timezone: this.timezone
      };
      let params = Object.assign(pagination_filter, this.filters);
      return this.loadEstimates(params);
    },

    // Update orders in store on orders sorting.
    gridUpdateOrders(sortField, sortDir) {
      // Update values in component to populate it for "loadEstimatesPaginated"
      this.sortField = sortField;
      this.sortDir = sortDir;

      let filters = {
        url: this.url,
        perPage: this.perPage,
        sortField: this.sortField,
        sortDir: this.sortDir,
        timezone: this.timezone
      };

      if (this.page) {
        filters.page = this.page;
      }

      let params = Object.assign(filters, this.filters);
      this.loadEstimates(params);
    },

    setStatusLost(orderId) {
      popup({
        title: 'Lost Order Reason',
        additional: {
          orderId: orderId,
          status: 'close_lost_order',
          model: 'estimates',
          refreshData: this.gridUpdateOrders.bind(this),
        },
        bodyComponent: StatusLostReasonComponent,
        runCallback: true,
        showButtons: true
      });
    },
    setConvertToOrderStatus(orderId) {
      this.$store.dispatch(SET_ORDER_STATUS, {
        model: 'estimates',
        orderId,
        status: 'estimate_to_order',
        status_reason: {}
      });
    },
    setStatusOrder(orderId) {
      this.setStatus(orderId, 'estimate_to_order');
    },

    setStatus(orderId, status) {
      this.$store.dispatch(SET_ORDER_STATUS, {
        model: 'estimates',
        orderId,
        status
      });
    },
    estimateDetails(orderId) {
      this.$router.push(`/orders/${orderId}/edit`);
    },
    approveEstimate(estimateId) {
      let estimate = this.estimates.find(e => e.id === estimateId);
      if (estimate) {
        apiService
          .approveEstimate(estimate.id)
          .then(data => {
            alertify.log(data.message);
            const key = 'estimates';
            this.setEstimateIsApproved({estimateId, key})
          })
          .catch(err => {
            console.log('Error:', err);
          });
      }
    },

    isReadyForCsrApproval(estimate) {
      return (
        !estimate.is_approved &&
        !estimate.is_declined &&
        !estimate.is_revision_requested
      );
    },
    subActions(estimate) {
      let actions = [
        {
          tag: 'action-menu',
          str: 'Copy Estimate',
          click: this.copyEstimate
        }
      ];
      if (!estimate.lost_order_reason) {
        if (this.isReadyForCsrApproval(estimate)) {
          actions.push({
            tag: 'action-menu',
            str: 'Approve',
            status: 'approve',
            click: this.approveEstimate
          });
        } else if (estimate.is_approved) {
          actions.push({
            tag: 'action-menu',
            str: 'Convert to Order',
            click: this.setConvertToOrderStatus
          });
        } else {
          actions.push({
            tag: 'action-menu',
            str: 'Send Estimate',
            click: this.sendEstimate
          });


        }
      }

      return actions;
    },
    copyEstimate(estimateId) {
      this.$router.push({
        path: '/orders/create',
        query: {
          copy_from_id: estimateId
        }
      });
    },
    deleteEstimate(estimate_id) {
      alertify
        .okBtn('Delete')
        .confirm('Are you sure that you want to delete the estimate?', ev => {
          return apiService
            .deleteOrder(estimate_id)
            .then(data => {
              alertify.log(data.message);
              this.dropRow({ model: `lost_estimates`, id: estimate_id });
            })
            .catch(err => {
              console.log('Error:', err);
            });
        });
    },
    sendEstimate(orderId) {
      const estimate = this.estimates.find(e => +e.id === orderId);
      if (!estimate) return false;

      if (estimate.shippings && estimate.shippings.length > 1) {
        this.$mx_confirmModal({
          title: 'Confirm send',
          text:
            'Estimate contains split shipping. Please ensure that everything is configured correctly',
          yesText: 'Send anyway',
          cancelText: 'Cancel'
        })
          .then(() => {
            this.$store.dispatch(SEND_ESTIMATE, orderId)
              .then(
                this.setEstimateSentAtDate(orderId)
              );
          })
          .catch(err => {
            return false;
          });
      } else {
        this.$store.dispatch(SEND_ESTIMATE, orderId)
          .then(
            this.setEstimateSentAtDate(orderId)
          );
      }
    },
    orderUpdateHandler(message) {
      if (message.order_id) {
        const updatedOrders = this.estimates.find(
          order => order.id === message.order_id
        );
        if (updatedOrders) {
          this.gridUpdateOrders(this.sortField, this.sortDir);
        }
      }
    },
    onFilters(filters) {
      this.filters = cloneDeep(filters);
      this.page = 1;
      this.gridUpdateOrders(this.sortField, this.sortDir);
    },
    showLostReason(estimateId) {
      let estimate = this.estimates.find(e => e.id === estimateId);
      if (estimate && estimate.lost_order_reason) {
        this.$mx_alertModal({
          title: `<strong>Category: </strong>${
            estimate.lost_order_reason.category
          }`,
          text: `<strong>Comment: </strong>${
            estimate.lost_order_reason.comment
          }<br>
                 <strong>Lost Date: </strong>${
                   estimate.lost_order_reason.created_at
                 }`
        });
      } else {
        this.$mx_alertModal({
          title: 'Lost Reason',
          text: 'Expired'
        });
      }
    }
  }
};
</script>
