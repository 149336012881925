<template>
  <div class="poster-design-item">
    <div class="table-container">
      <div v-if="design.total">
        <div v-for="(item, key) in design.items" :key="`other-design-item-details-${key}`">
          <table  class="table">
            <thead>
            <tr>
              <th colspan="2">Item</th>
              <th>Qty.</th>
              <th v-roles="['admin', 'csr', 'production-coordinator', 'purchaser', 'art-producer', 'customer']">Unit cost</th>
              <th v-roles="['admin', 'csr', 'production-coordinator', 'purchaser', 'art-producer', 'customer']">Total</th>
            </tr>
            </thead>
            <tbody >
            <tr>
              <td colspan="2">{{item.name}}</td>
              <td>{{item.qty | itemsNumberFormat}}</td>
              <td v-roles="['admin', 'csr', 'production-coordinator', 'purchaser', 'art-producer', 'customer']">{{item.price | price}}</td>
              <td v-roles="['admin', 'csr', 'production-coordinator', 'purchaser', 'art-producer', 'customer']">{{item.total_cost | price}}</td>
            </tr>
            <tr>
              <td class="break-word content-pre"
                  colspan="5"
                  v-html="item.description">
              </td>
            </tr>
            </tbody>
          </table>
          <hr v-if="design.items.length > 1 && key < design.items.length - 1">
        </div>
      </div>
    </div>
    <div v-if="design.total && design.total.upcharge > 0" v-roles="['admin', 'csr', 'production-coordinator', 'purchaser', 'art-producer', 'customer']">
      <strong>Upcharge for <span v-if="design.name">{{design.name}}</span><span v-else>Design #{{ parseInt(index)+1 }}</span>:</strong> {{design.total.upcharge | price}}
      <p v-if="design.total.upcharge_description">{{design.total.upcharge_description}}</p>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.table-container {
  max-width: 400px;
}
</style>

<script>
import Vue from 'vue';

export default {
  name: 'other-design-type',
  props: ['design']
};
</script>
