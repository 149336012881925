export const DRAFT_STATUS = 0;
export const FINAL_STATUS = 1;
export const PENDING_STATUS = 2;
export const APPROVED_STATUS = 3;

export const finishingComponents = {
  'App\\Models\\Services\\Apparel\\ApparelDesignTagPrinting': {
    label: 'Printed Tags',
    component: 'tag-printing',
    editable: true
  },
  'App\\Models\\Services\\Apparel\\ApparelDesignHemTag': {
    label: 'Hem Tags',
    component: 'hem-tag',
    editable: true
  },
  'App\\Models\\Services\\Apparel\\ApparelDesignHemTagFleece': {
    label: 'Hem Tags (Fleece, Beanies, Jackets)',
    component: 'hem-tag',
    editable: true
  },
  'App\\Models\\Services\\Apparel\\ApparelDesignWovenNeckTag': {
    label: 'Woven Neck Tags',
    component: 'woven-neck-tag',
    editable: true
  },
  'App\\Models\\Services\\Apparel\\ApparelDesignFourSidedTag': {
    label: '4 Sided Tags',
    component: 'four-sided-tag',
    editable: false
  },
  'App\\Models\\Services\\Apparel\\ApparelDesignFoldingAndBagging': {
    label: 'Folding & Bagging',
    component: 'folding-bagging'
  },
  'App\\Models\\Services\\Apparel\\ApparelDesignFoldingOnly': {
    label: 'Folding Only',
    component: 'folding-bagging'
  },
  'App\\Models\\Services\\Apparel\\ApparelDesignFoldingAndBaggingFleece': {
    label: 'Folding & Bagging (Fleece)',
    component: 'folding-bagging'
  },
  'App\\Models\\Services\\Apparel\\ApparelDesignFoldingOnlyFleece': {
    label: 'Folding Only (Fleece)',
    component: 'folding-bagging'
  },
  'App\\Models\\Services\\Apparel\\ApparelDesignHangTag': {
    label: 'Hang Tags',
    component: 'hang-tag',
    editable: true
  },
  'App\\Models\\Services\\Apparel\\ApparelDesignStickerAttachment': {
    label: 'Sticker Attachment',
    component: 'sticker-attachment'
  },
  'App\\Models\\Services\\Apparel\\ApparelDesignHeatPressedTags': {
    label: 'Heat Pressed Tags',
    component: 'heat-pressed-tags',
    editable: true
  },
  'App\\Models\\Services\\Apparel\\ApparelDesignSewnTagBottomSleeve': {
    label: 'Sewn Tags - Bottom or Sleeve Hem',
    component: 'sewn-tag-bottom-or-sleeve',
    editable: false
  },
  'App\\Models\\Services\\Apparel\\ApparelDesignSewnTagNeckFourSided': {
    label: 'Sew in Neck Tags',
    component: 'sewn-tag-neck-and-four-sided',
    editable: true
  },
  'App\\Models\\Services\\Apparel\\ApparelDesignNeckTagsFleece': {
    label: 'Neck Tags (Fleece, Jackets)',
    component: 'sewn-tag-neck-and-four-sided',
    editable: true
  },
};

export const hemTagLocationsList = [
  {
    key: 'Bottom Left Hem',
    label: 'Bottom Left Hem'
  },
  {
    key: 'Bottom Right Hem',
    label: 'Bottom Right Hem'
  },
  {
    key: 'Left Side',
    label: 'Left Side'
  },
  {
    key: 'Right Side',
    label: 'Right Side'
  },
  {
    key: 'Left Sleeve',
    label: 'Left Sleeve'
  },
  {
    key: 'Right Sleeve',
    label: 'Right Sleeve'
  },
  {
    key: 'Front Collar',
    label: 'Front Collar'
  },
  {
    key: 'Back Collar',
    label: 'Back Collar'
  },
  {
    key: 'Right Hood',
    label: 'Right Hood'
  },
  {
    key: 'Left Hood',
    label: 'Left Hood'
  },
  {
    key: 'Center Hood',
    label: 'Center Hood'
  }
];

export const hangTagLocationsList = [
  {
    key: 'Neck',
    label: 'Neck'
  },
  {
    key: 'Right Armpit',
    label: 'Right Armpit'
  },
  {
    key: 'Left Armpit',
    label: 'Left Armpit'
  }
];

export const locationLabel = (id, options) => {
  let label = '';

  if (options.locations) {
    Object.keys(options.locations).forEach(area => {
      Object.keys(options.locations[area]).forEach(key => {
        if (key == id) {
          label = area + ' ' + options.locations[area][key];
        }
      });
    });
  }

  return label;
};
