<template>
  <div class="page-company">
    <div class="company-content" style="padding-top: 1rem;">
      <h5 class="subtitle is-marginless has-text-weight-bold subtitle-with-whitespace">Company Stats</h5>
      <div class="columns">
        <div class="column is-4">Number of Days Since Last Order: <b>{{ daysSinceLastOrder }}</b></div>
        <div class="column is-4">Amount Due: <b>{{ amountDue | price }}</b></div>
      </div>
      <div class="columns charts">
        <div class="column is-6">
          <apexchart class="chart" :options="totalSalesChartOptionsV2" :series="totalSalesChartDataV2"/>
        </div>
        <div class="column is-6 charts">
          <apexchart class="chart" :options="totalOrdersPerMonthChartOptionsV2" :series="totalOrdersPerMonthChartDataV2"/>
        </div>
      </div>
      <div class="columns charts">
        <div class="column is-6">
          <apexchart class="chart" :options="totalByServiceTypeChartOptionsV2" :series="totalByServiceTypeChartDataV2"/>
        </div>
        <div class="column is-6 charts">
          <apexchart class="chart" :options="totalByPrinterChartOptions" :series="totalByPrinterChartData"/>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.company-content {
  .subtitle-with-whitespace {
    margin-bottom: 10px !important;
  }
}
</style>

<script>

import apiService from '../../../../services/api.service';
import VueApexCharts from 'vue-apexcharts';
import Vue from 'vue';

Vue.use(VueApexCharts);

export default {
  name: 'accounts-view-report',
  data() {
    return {
      company: [],
      filters: {},
      report: {
        daysSinceLastOrder: null,
        amountDue: null,
        totalSalesLabels: null,
        totalSalesPerPeriod: null,
        totalSalesPerMonth: null,
        totalOrdersPerMonth: null,
        totalDesignsPerMonth: null,
        servicesTotal: null,
        printersTotal: null
      }
    };
  },
  computed: {
    dataLabelsConfig() {
      return {
        enabled: true,
        offsetX: 30,
        position: 'bottom', // top, center, bottom
        style: {
          fontSize: '12px',
          colors: ['#304758']
        }
      };
    },
    plotOptionsConfig() {
      return {
        bar: {
          horizontal: true,
          dataLabels: {
            position: 'top',
            total: {
              enabled: true,
              offsetX: 0,
              style: {
                fontSize: '13px',
                fontWeight: 900,
                colors: ['#304758']
              }
            }
          }
        }
      };
    },
    companyId() {
      return this.$route.params.id;
    },
    daysSinceLastOrder() {
      return this.report && this.report.daysSinceLastOrder ? this.report.daysSinceLastOrder : '-';
    },
    amountDue() {
      return this.report && this.report.amountDue ? this.report.amountDue : 0;
    },
    totalSalesChartOptionsV2() {
      return {
        chart: {
          height: 500,
          type: 'bar'
        },
        plotOptions: {
          bar: {
            borderRadius: 4,
            horizontal: true,
            dataLabels: {
              position: 'bottom' // top, center, bottom
            }
          }
        },
        dataLabels: {
          enabled: true,
          formatter: (value) => {
            return this.$options.filters.price(value);
          },
          offsetX: 10,
          style: {
            fontSize: '12px',
            colors: ['#304758']
          },
          dropShadow: {
            enabled: false,
            color: '#fff'
          }
        },
        xaxis: {
          categories: this.totalSalesLabels
        },
        title: {
          text: this.$options.filters.price(this.totalSalesPerPeriodLabel),
          offsetX: 20,
          style: {
            fontSize: '24px'
          }
        },
        subtitle: {
          text: 'Total Sales',
          offsetX: 0,
          style: {
            fontSize: '14px'
          }
        }
      };
    },
    totalSalesLabels() {
      return this.report && this.report.totalSalesLabels ? this.report.totalSalesLabels : [];
    },
    totalSalesPerPeriodLabel() {
      return this.report && this.report.totalSalesPerPeriod ? this.report.totalSalesPerPeriod : 0;
    },
    totalSalesChartDataV2() {
      return [{
        name: 'Sales',
        data: this.report && this.report.totalSalesPerMonth ? this.report.totalSalesPerMonth : []
      }];
    },
    totalOrdersPerMonthChartOptionsV2() {
      return {
        title: {
          text: 'Number of Orders & Designs by Month'
        },
        chart: {
          height: 500,
          type: 'bar'
        },
        plotOptions: this.plotOptionsConfig,
        dataLabels: this.dataLabelsConfig,
        xaxis: {
          categories: this.totalSalesLabels
        }
      };
    },
    totalOrdersPerMonthChartDataV2() {
      return [
        {
          name: 'Orders',
          data: this.report && this.report.totalOrdersPerMonth ? this.report.totalOrdersPerMonth : []
        },
        {
          name: 'Designs',
          data: this.report && this.report.totalDesignsPerMonth ? this.report.totalDesignsPerMonth : []
        }
      ];
    },
    totalByServiceTypeChartOptionsV2() {
      return {
        title: {
          text: 'Number of Orders & Designs by Service Type'
        },
        chart: {
          type: 'bar',
          height: 475

        },
        plotOptions: this.plotOptionsConfig,
        dataLabels: this.dataLabelsConfig,
        xaxis: {
          categories: this.totalByServiceTypeLabelsV2
        }
      };
    },
    totalByServiceTypeChartDataV2() {
      return [
        {
          name: 'Orders',
          data: this.report && this.report.servicesTotal ? this.report.servicesTotal.orders : []
        },
        {
          name: 'Designs',
          data: this.report && this.report.servicesTotal ? this.report.servicesTotal.designs : []
        }
      ];
    },
    totalByServiceTypeLabelsV2() {
      return this.report && this.report.servicesTotal ? this.report.servicesTotal.labels : [];
    },
    totalByPrinterChartOptions() {
      return {
        title: {
          text: 'Number of Orders & Designs by Printer'
        },
        chart: {
          type: 'bar',
          height: 1000
        },
        plotOptions: this.plotOptionsConfig,
        dataLabels: this.dataLabelsConfig,
        xaxis: {
          categories: this.totalByPrinterLabels
        }
      };
    },
    totalByPrinterChartData() {
      return [
        {
          name: 'Orders',
          data: this.report && this.report.printersTotal ? this.report.printersTotal.orders : []
        },
        {
          name: 'Designs',
          data: this.report && this.report.printersTotal ? this.report.printersTotal.designs : []
        }
      ];
    },
    totalByPrinterLabels() {
      return this.report && this.report.printersTotal ? this.report.printersTotal.labels : [];
    }
  },
  components: {
    apexchart: VueApexCharts
  },
  mounted() {
    this.loadCompanyReport();
  },
  methods: {
    loadCompanyReport() {
      console.log('filters', this.filters);
      return apiService.getReport(`company/${this.companyId}`, this.filters).then(
        res => {
          this.$set(this, 'report', res);
        }
      );
    }
  },
  created() {
    this.filters.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  }
};
</script>
<style lang="scss" scoped>
.charts {
  .chart {
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    padding: 10px;
    margin-left: -10px;
    margin-right: -10px;
  }

  .column {
    &.is-6 {
      width: 49.5%;

      &:first-child {
        margin-right: 0.5%;
      }

      &:last-child {
        margin-left: 0.5%;
      }
    }
  }
}
</style>
