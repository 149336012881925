import _, {flatten} from 'lodash';

export const finishingsTotal = (finishings, designs, prices) => {
  return finishings.reduce((acc, finishing) => {
    return acc + finishingTotal(finishing, prices);
  }, 0);
};

export const finishingTotal = (finishing, prices) => {
  let price = 0;
  let totalGarments = +finishing.specific.tag_garments_quantity;
  let finishing_prices = prices[finishing.specific.price_code];

  let finishingItem = finishing.specific;

  // Check if we have saved "quantity cost" first.
  if (
    finishingItem.tag_garments_quantity_cost !== undefined &&
    finishingItem.tag_garments_quantity_cost !== null
  ) {
    price += parseFloat(
      finishingItem.tag_garments_quantity_cost * totalGarments
    );
  } else {
    // Calculate cost if we doesn't have saved costs.
    _.forEach(finishing_prices, function(cost, num_garments) {
      let minMax = num_garments.split('-');

      if (+minMax[0] === 1000 && totalGarments >= 1000) {
        price += parseFloat(cost * totalGarments);
      } else if (+minMax[0] <= totalGarments && +minMax[1] >= totalGarments) {
        price += parseFloat(cost * totalGarments);
      }
    });
  }

  if (finishing.specific.tag_setups_number) {
    price += finishingTagSetupsTotal(finishing, prices);
  }

  return price;
};

export const finishingTotalPerGarment = (finishing, garment, prices) => {
  let price = 0;
  let finishing_prices = prices[finishing.specific.price_code];
  let totalGarments = +finishing.specific.tag_garments_quantity;
  let garmentQty = Object.keys(garment.sizes).reduce((acc, sizeKey) => {
    return acc + +garment.sizes[sizeKey];
  }, 0);

  let finishingItem = finishing.specific;

  // Check if we have saved "quantity cost" first.
  if (
    finishingItem.tag_garments_quantity_cost !== undefined &&
    finishingItem.tag_garments_quantity_cost !== null
  ) {
    price += parseFloat(finishingItem.tag_garments_quantity_cost * garmentQty);
  } else {
    // Calculate cost if we doesn't have saved costs.
    _.forEach(finishing_prices, function(cost, num_garments) {
      let minMax = num_garments.split('-');

      if (+minMax[0] === 1000 && totalGarments >= 1000) {
        price += parseFloat(cost * garmentQty);
      } else if (+minMax[0] <= totalGarments && +minMax[1] >= totalGarments) {
        price += parseFloat(cost * garmentQty);
      }
    });
  }

  return price;
};

export const finishingTotalPerDesign = (finishing, design, prices) => {
  let price = 0;
  let finishing_prices = prices[finishing.specific.price_code];
  let totalGarments = +finishing.specific.tag_garments_quantity;
  let garmentQty = 0;
  const flattenGarments = _.flatten(
    finishing.items.map(item => {
      return design.specific.garments.filter(
        garment => garment.uniqueId === item.garment_id
      );
    })
  );
  garmentQty = flattenGarments.reduce((garmentsTotal, garment) => {
    return (
      garmentsTotal +
      Object.values(garment.sizes).reduce((sizesTotal, size) => {
        return sizesTotal + (+size || 0);
      }, 0)
    );
  }, 0);

  let finishingItem = finishing.specific;

  // Check if we have saved "quantity cost" first.
  if (
    finishingItem.tag_garments_quantity_cost !== undefined &&
    finishingItem.tag_garments_quantity_cost !== null
  ) {
    price += parseFloat(finishingItem.tag_garments_quantity_cost * garmentQty);
  } else {
    // Calculate cost if we doesn't have saved costs.
    _.forEach(finishing_prices, function(cost, num_garments) {
      let minMax = num_garments.split('-');

      if (+minMax[0] === 1000 && totalGarments >= 1000) {
        price += parseFloat(cost * garmentQty);
      } else if (+minMax[0] <= totalGarments && +minMax[1] >= totalGarments) {
        price += parseFloat(cost * garmentQty);
      }
    });
  }

  return price;
};

export const finishingTagSetupsTotal = (finishing, prices) => {
  let price = 0;
  let tagSetupsNumber = +finishing.specific.tag_setups_number;
  let finishing_prices = prices[finishing.specific.price_code];

  let finishingItem = finishing.specific;

  // Check if we have saved "tags setup cost" first.
  if (
    finishingItem.tag_setups_number_cost !== undefined &&
    finishingItem.tag_setups_number_cost !== null
  ) {
    price += parseFloat(finishingItem.tag_setups_number_cost * tagSetupsNumber);
  } else {
    // Calculate cost if we doesn't have saved costs.
    _.forEach(finishing_prices, function(cost, num_garments) {
      let minMax = num_garments.split('-');

      if (+minMax.length === 1) {
        if (+minMax[0] === 0 || minMax[0] === 'setup') {
          if (tagSetupsNumber !== undefined) {
            price = parseFloat(cost) * tagSetupsNumber;
          }
        }
      }
    });
  }

  return price;
};

export const getFinishingFields = (finishing) => {
  const result = [];
  const specific = finishing.specific;
  switch (finishing.common.finishing_option) {
    case 'tag-printing':
    case 'heat-pressed-tags':
      result.push(
        `${specific.tag_setups_number} Tag Setup${
          specific.tag_setups_number > 1 ? 's' : ''
        }`
      );
      result.push(
        `Size
              ${specific.tag_width !== null ? `${specific.tag_width}"` : '*'}
              x
              ${specific.tag_height !== null ? `${specific.tag_height}"` : '*'}
            `
      );
      if (specific.remove_manufacturer_tag) {
        result.push('Remove manufacturer tag');
      }
      break;
    case 'hem-tag':
    case 'hem-tags-fleece':
      result.push(
        `Hem Location - ${specific._hem_tag || specific.hem_tag}`
      );
      if (specific.hem_tag_thread_color.length) {
        result.push(`Thread Color - ${specific.hem_tag_thread_color}`);
      }
      break;
    case 'sewn-tag-neck-and-four-sided':
    case 'neck-tags-fleece':
      if (specific.sewn_tag_neck_tag_type) {
        result.push(specific.sewn_tag_neck_tag_type);
        if (
          specific.sewn_tag_neck_tag_thread_color &&
          specific.sewn_tag_neck_tag_thread_color.length
        ) {
          result.push(
            `Thread Color - ${specific.sewn_tag_neck_tag_thread_color}`
          );
        }
      }
      break;
    case 'four-sided-tag':
      break;
    case 'folding-only':
    case 'folding-only-fleece':
    case 'folding-and-bagging':
    case 'folding-and-bagging-fleece':
      result.push(specific._folding_type || specific.folding_type);
      break;
    case 'hang-tag':
      result.push(specific._hang_tag || specific.hang_tag);
      break;
    case 'sticker-attachment':
      result.push(specific._sticker_attachment || specific.sticker_attachment);
      break;
  }
  return result.join(', ');
};
export const getDesignFinishings = (finishings, designId) => {
  if (finishings) {
    return finishings.filter(
      ({items}) =>
        items.filter(item => item.design_id === designId).length >
        0
    );
  }
  return [];
};

export const getFinishingGarmentsQty = (finishing, garments) => {
  const flattenGarments = flatten(
    finishing.items.map(item => {
      return garments.filter(
        garment => garment.uniqueId === item.garment_id
      );
    })
  );

  return flattenGarments.reduce((garmentsTotal, garment) => {
    return (
      garmentsTotal +
      Object.values(garment.sizes).reduce((sizesTotal, size) => {
        return sizesTotal + (+size || 0);
      }, 0)
    );
  }, 0);
};

export const getFinishingPrice = (finishing, finishingsPrices) => {
  const prices = finishingsPrices[finishing.specific.price_code];
  const itemsQty = finishing.specific.tag_garments_quantity;

  if (
    finishing.specific.tag_garments_quantity_cost !== undefined &&
    finishing.specific.tag_garments_quantity_cost !== null
  ) {
    return +finishing.specific.tag_garments_quantity_cost;
  }

  const priceGroup = Object.keys(prices).find(priceKey => {
    const priceRange = priceKey.split('-');
    if (+priceRange[0] === 1000 && itemsQty >= 1000) {
      return true;
    } else if (+priceRange[0] <= itemsQty && +priceRange[1] >= itemsQty) {
      return true;
    }
    return false;
  });

  return prices[priceGroup] || 0;
};
