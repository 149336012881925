<template>
  <div class="button-design-item">
    <div class="table-container">
      <table v-if="design.total" class="table">
        <thead>
          <tr>
            <th>Size</th>
            <th v-roles="['admin', 'csr', 'production-coordinator', 'purchaser', 'art-producer', 'customer']">Cost</th>
            <th>Qty.</th>
            <th v-roles="['admin', 'csr', 'production-coordinator', 'purchaser', 'art-producer', 'customer']">Total</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{{design.size}}</td>
            <td v-roles="['admin', 'csr', 'production-coordinator', 'purchaser', 'art-producer', 'customer']">{{(design.total.subtotal / design.total.qty) | price}}</td>
            <td>{{design.total.qty | itemsNumberFormat}}</td>
            <td v-roles="['admin', 'csr', 'production-coordinator', 'purchaser', 'art-producer', 'customer']">{{design.total.subtotal | price}}</td>
          </tr>
        </tbody>
      </table>
    </div>

    <div v-if="design.total && design.total.upcharge > 0" v-roles="['admin', 'csr', 'production-coordinator', 'purchaser', 'art-producer', 'customer']">
      <strong>Upcharge for <span v-if="design.name">{{design.name}}</span><span v-else>Design #{{parseInt(index) + 1}}</span>:</strong> {{design.total.upcharge | price}}
      <p v-if="design.total.upcharge_description">{{design.total.upcharge_description}}</p>
    </div>
  </div>
</template>

<style lang="scss" scoped>
    .table-container {
        max-width: 500px;
    }
</style>

<script>
    import Vue from 'vue';

    export default {
        name: 'button-design-type',
        props: ['design']
    };
</script>

