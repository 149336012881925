<template>
  <nav class="level">
    <div class="level-item has-text-centered">
      <a href="/#/">
        <img src="@/../images/logo.png" width="250px" alt="Threadbird">
      </a>
    </div>
  </nav>
</template>

<script>
export default {
  name: 'revision-header'
};
</script>
