/**
 * Calculate design total.
 *
 * @param design
 * @param buttonDesignData
 * @returns {number}
 */
export const getDesignTotal = (design, buttonDesignData) => {
  let buttonTotal = 0;
  let designItem = design.specific.item;
  let designSize = design.specific.item.size;
  let quantity = design.specific.item.quantity;
  let sizeOption = buttonDesignData.sizes.find(
    size => +size.key === +designSize
  );

  if (
    designItem &&
    (designItem.cost !== undefined && designItem.cost !== null)
  ) {
    buttonTotal += +designItem.cost;
  } else if (sizeOption && sizeOption.upcharges) {
    buttonTotal += parseFloat(sizeOption.upcharges[quantity]) || 0;
  }

  buttonTotal += +design.common.upcharge;
  return buttonTotal;
};
