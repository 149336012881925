import Vue from 'vue';
import Multiselect from 'vue-multiselect';
import VueScrollto from 'vue-scrollto';
import VModal from 'vue-js-modal';
import VeeValidate from 'vee-validate';

import 'vue-multiselect/dist/vue-multiselect.min.css';

Vue.component('multiselect', Multiselect);
Vue.use(VueScrollto, {
  force: false,
  onDone: () => {}
});
Vue.use(VModal, { dialog: true, dynamic: true });
Vue.use(VeeValidate);

Vue.mixin({
  methods: {
    $mx_highlightPhrase(text, query) {
      if (!text) return '';
      if (!query.length) return text;
      const index = text.toLowerCase().indexOf(query.toLowerCase());
      if (index < 0) return text;
      return [
        text.slice(0, index),
        '<em>',
        text.slice(index, query.length + index),
        '</em>',
        text.slice(query.length + index)
      ].join('');
    },
    $mx_confirmModal({ title, text, yesText, cancelText } = {}) {
      const vm = this;
      return new Promise((resolve, reject) => {
        vm.$modal.show('dialog', {
          title: title || 'Are you sure?',
          text: text || undefined,
          buttons: [
            {
              title: cancelText || 'Cancel',
              class: 'btn2 is-transparent',
              handler() {
                reject(new Error('cancelled'));
                vm.$modal.hide('dialog');
              }
            },
            {
              title: yesText || 'Ok',
              class: 'btn2',
              handler() {
                resolve(true);
                vm.$modal.hide('dialog');
              }
            }
          ]
        });
      });
    },
    $mx_alertModal({ title, text, okText } = {}) {
      const vm = this;
      return new Promise(resolve => {
        vm.$modal.show('dialog', {
          title: title || 'Are you sure?',
          text: text || undefined,
          buttons: [
            {
              title: okText || 'Close',
              class: 'btn2 is-transparent',
              handler() {
                resolve(true);
                vm.$modal.hide('dialog');
              }
            }
          ]
        });
      });
    }
  }
});
