<template>
  <div class="field has-addons">
    <div class="control has-addons  flatpickr" data-wrap="true" data-clickOpens="false" :class="{ [`has-addons-${alignment}`]: alignment }">
      <input class="input" :class="inputClass" type="text" :placeholder="placeholder" :value="value" :readonly="readonly" data-input/>
    </div>
    <div class="control">
      <a class="button" data-clear>
        <span class="icon is-medium has-text-link">
          <i class="ff-lg ff-cross-mark"></i>
        </span>
      </a>
    </div>
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    alignment: String,
    config: {
      type: Object,
      default: () => ({})
    },
    l10n: {
      type: Object,
      default: () => ({})
    },
    placeholder: {
      type: String,
      default: 'Pick date'
    },
    readonly: Boolean,
    inputClass: {
      type: Object,
      default: () => ({})
    },
    value: {
      type: String,
      default: ''
    }
  },

  data() {
    return {};
  }
};
</script>
