<template>
  <div v-if="garment.id">
    <h2>Garment details</h2>
    <table class="table is-fullwidth">
      <thead>
      <tr>
        <th v-if="this.design.designable.item.product">Product</th>
        <th>{{ this.design.designable.item.addons.addon_category }}</th>
        <th>Size</th>
        <th>QTY Total</th>
      </tr>
      </thead>
      <tbody>
      <tr class="has-separator">
        <td v-if="this.design.designable.item.product">
          <strong>{{ this.design.designable.item.product_object.name }}</strong>
        </td>
        <td>{{ this.design.designable.item.addons.product_addon_name }}</td>
        <td v-if="this.design.designable.item.size === 'empty_size'">{{ this.design.designable.item.custom_width }} x {{ this.design.designable.item.custom_height }}</td>
        <td v-else>{{ this.design.designable.item.size }}</td>
        <td class="is-pulled-right">Total: {{ this.design.designable.item.quantity | itemsNumberFormat }}</td>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import {canApproveMock} from '../../../../helpers/role-access';

export default {
  name: 'garment-sticker-details',
  props: {
    design: {
      type: Object,
      required: true
    },
    options: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    canApproveMock
  }),
  computed: {
    garment() {
      return this.design ? this.design.designable.item : {};
    },
    quantity_label() {
      return this.design.designable.item.quantity_object
        ? this.design.designable.item.quantity_object.name
        : 'N/A';
    },
  }
};
</script>

<style scoped>
h2 {
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 1rem;
  margin-bottom: 10px;
}

tbody {
  background: #dedede;
}

th {
  border: none;
}

tbody td {
  border-bottom: 1px solid #d6d5d5;
}

.has-separator:last-child {
  border-bottom: 1px solid;
  border-color: #eae6e6;
}
</style>
