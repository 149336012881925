<template>
  <div v-if="groupedGarments.length">
    <h2>Garment details</h2>
    <table class="table is-fullwidth">
      <thead>
      <tr>
        <th>Garment</th>
        <th>Color</th>
        <th>Quantities</th>
        <th class="ta-right">QTY</th>
      </tr>
      </thead>
      <tbody>
      <template v-for="(groupedGarment, index) in groupedGarments">
        <tr
          :class="(+index + 1 % 2 === 1) ? 'with-gray-back' : ''"
          v-for="garment in groupedGarment.garments"
          :key="`product-garment-${garment.id}`"
        >
          <td>
            <span><strong>{{ garment.brand_object.name + ' ' + garment.product_object.number}}</strong> {{garment.product_object.name}}
              <span v-if="garment.product_object.fabric">({{garment.product_object.fabric}})</span>
            </span>
          </td>
          <td colspan="">{{ garment.color }}</td>
          <td>{{ garment.sizesString }}</td>
          <td class="ta-right">{{ garment.sizesTotalQty }}</td>
        </tr>
      </template>
      </tbody>
    </table>
  </div>
</template>

<script>
import { cloneDeep } from 'lodash';

export default {
  name: 'garment-apparel-details',
  props: {
    design: {
      type: Object,
      required: true
    },
    options: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      sortedSizes: []
    };
  },
  computed: {
    groupedGarments() {
      const groupedGarments = {};
      if (
        this.design &&
        this.design.designable &&
        this.design.designable.garments
      ) {
        this.design.designable.garments.forEach(garment => {
          const sizes = JSON.parse(garment.size_qty);
          const sortedSizes = this.sortedSizes
            .filter(size => Object.keys(sizes).includes(size))
            .map(size => [size, sizes[size]]);
          const sizesString = sortedSizes
            .filter(item => item[1]) // filter empty sizes
            .map(item => item.join('-'))
            .join(', ');
          const sizesTotalQty = Object.values(sizes).reduce(
            (result, i) => result + parseInt(i, 10),
            0
          );
          const parsedGarmed = { ...garment, sizesTotalQty, sizesString };

          if (!groupedGarments[garment.product]) {
            groupedGarments[garment.product] = {
              product: garment.product_object,
              garments: [parsedGarmed]
            };
          } else {
            groupedGarments[garment.product].garments.push(parsedGarmed);
          }
        });
      }
      return Object.values(groupedGarments);
    }
  },
  mounted() {
    this.sortedSizes = cloneDeep(this.$store.getters.sortedGarmentsSizes);
  }
};
</script>

<style scoped>
h2 {
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 1rem;
  margin-bottom: 10px;
}

th {
  border: none;
}

.is-name td {
  background: #dedede;
}

.white-space-pre {
  white-space: pre;
}
</style>
