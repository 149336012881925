<template>
  <div class="notifications-list">
    <!-- Main container -->
    <nav class="level">
      <!-- Left side -->
      <div class="level-left">
        <div class="level-item">
          <p class="subtitle is-5">Search latest notifications</p>
        </div>
        <div class="level-item">
          <div class="control">
            <p class="control has-addons">
              <input type="text" class="input"
                     v-model="searchQuery"
                     v-focus="true">
            </p>
          </div>
        </div>
      </div>
      <div class="level-right">
         <a @click="MarkAllAsRead()" class="button">Mark all as Read</a>
      </div>
    </nav>

    <template v-if="!isLoading">
      <table v-if="notificationsList.length" class="table is-fullwidth">
        <thead>
        <tr>
          <th v-for="head in headers"
              @click="sortableFields.includes(head) ? orderByHead(head): () => {}"
              :class="{'is-active': head === sortKey && sortableFields.includes(head),
                       'ta-right': head === 'Unread Messages',
                       'is-clickable': sortableFields.includes(head)
                      }">
            {{head}}
            <span v-if="head == sortKey" class="icon is-small">
              <i :class="sortDir == 'asc' ? 'ff-triangle-up' : 'ff-triangle-down'"></i>
            </span>
          </th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(entry, rowKey) in notificationsList">
          <th>
            <router-link :to="prepareLink(entry)"
            >{{ entry.order_id }}
            </router-link>
          </th>
          <td>{{ viewDate(entry) }}</td>
          <td><strong>{{entry.sender}}: </strong>
            <div v-html="newLines(entry.body)"></div>
          </td>
          <td class="ta-right">
            <router-link :to="prepareLink(entry)"
                         class="icon notifications-bell is-medium"
                         :class="{
                                'is-warning': isWarn(entry) && !isMentionedMe(entry),
                                'is-danger': isMentionedMe(entry)
                                }">
              <p class="icon is-medium">
                <i class="ff-2x ff-bell" aria-hidden="true"/>
                <span v-if="isWarn(entry)"
                      class="tag message-icon is-warning is-rounded has-text-black-invert"
                      :class="{'is-danger': isMentionedMe(entry)}">{{ isWarn(entry) }}</span>
              </p>
            </router-link>
          </td>
        </tr>
        </tbody>
      </table>

      <section class="hero has-text-centered" v-else>
        <div class="hero-body">
          <div class="container"><p class="subtitle">
            No notifications found
          </p></div>
        </div>
      </section>
      <paginator v-if="notificationsList.length"
                 :pagesNumber="pagesNumber"
                 :prevPageUrl="prevPageUrl"
                 :nextPageUrl="nextPageUrl"
                 :currentPage="currentPage"
                 :getListItems="getMessagesData"
                 :showOffset="false"
                 :totalNumber="total"
                 :sortField="sortKey"
                 :sortDir="sortDir"></paginator>

    </template>

    <div v-else>Loading...</div>

  </div>
</template>
<style lang="scss">
.notifications-list {
  .message-icon {
    position: absolute;
    top: -8px;
    right: 0;
    font-size: 11px;
  }

  .notifications-bell {
    position: relative;
  }

  mentioned {
    border: 1px solid #5fc1ec;
    background-color: #eef9ff;
    color: #009bdf;
    border-radius: 10px;
    padding: 0 4px;
  }
}
</style>
<script>
import Vue from 'vue';
import { mapGetters, mapActions, mapMutations } from 'vuex';
import { focus } from 'vue-focus';

import formSelect from '../common/form/select';
import apiService from '../../services/api.service';
import pubnubService from '../../services/pubnub.service';
import notify from '../../modules/notifier/index';
import { debounce, cloneDeep } from 'lodash';
import moment from 'moment';
import Paginator from '../common/paginator';
import { checkRoles } from '../../router/checkRoles';
import { isMentionedMe } from '@/helpers/messages';
import { SET_UNREAD_MESSAGES } from '../../store/action-types';

export default {
  name: 'notifications-list',
  directives: { focus },
  data() {
    return {
      isLoading: false,
      headers: ['Order', 'Timestamp', 'Sender', 'Unread Messages'],
      notificationsList: [],
      sortableFields: ['Order', 'Timestamp', 'Unread Messages'],
      prevPageUrl: null,
      nextPageUrl: null,
      currentPage: 1,
      pagesNumber: 1,
      searchQuery: '',
      searchRole: '',
      sortKey: 'Timestamp',
      sortDir: 'desc',
      total: 0
    };
  },
  watch: {
    searchQuery: function(str) {
      if (str.length == 0 || str.length >= 3) {
        this.searchMessages();
      }
    },
    sortKey: function(key) {
      this.searchMessages();
    },
    sortDir: function(key) {
      this.searchMessages();
    }
  },
  computed: {
    ...mapGetters(['userId', 'unreadMessages', 'unreadOrdersMessages']),
    isCustomer() {
      return checkRoles(this.$store.getters.roles, ['customer'], true);
    }
  },
  components: {
    Paginator,

    formSelect
  },
  mounted() {
    this.isLoading = true;
    Promise.all([this.getMessagesData()]).then(() => {
      this.isLoading = false;
    });

    pubnubService.subscribeUpdateEvent('order', this.messagesListener);
    pubnubService.subscribeUpdateEvent('mock', this.messagesListener);
    pubnubService.subscribeUpdateEvent('chat', this.messagesListener);
    pubnubService.subscribeUpdateEvent('mark-as-read', this.markAsReadListener);
  },
  destroyed() {
    pubnubService.unsubscribeUpdateEvent('order');
    pubnubService.unsubscribeUpdateEvent('mock');
    pubnubService.unsubscribeUpdateEvent('chat');
    pubnubService.unsubscribeUpdateEvent('mark-as-read');
  },
  methods: {
    ...mapMutations({
      setUnreadMessages: SET_UNREAD_MESSAGES
    }),
    prepareLink(entry) {
      if (entry.is_order) {
        return !this.isCustomer
          ? '/orders/' + entry.order_id + '/details'
          : '/customer/orders/' + entry.order_id + '/details';
      } else {
        return !this.isCustomer
          ? '/estimates/' + entry.order_id + '/details'
          : '/customer/estimates/' + entry.order_id + '/approve';
      }
    },
    messagesListener(message) {
      if (message.order_id) {
        this.notificationsList = this.notificationsList.map(notification => {
          if (
            notification.order_id === message.order_id &&
            message.author_id !== this.userId
          ) {
            notification = {
              order_id: message.order_id,
              is_order: notification.is_order,
              author_id: message.author_id,
              timestamp: this.viewDate(message.created_at),
              sender:
                message.author &&
                `${message.author.first_name} ${message.author.last_name}`,
              body: this.newLines(message.body),
              is_mentioned_me: this.checkIfMentionedInMessage([message]),
              unread_messages: notification.unread_messages + 1
            };
          }
          return notification;
        });
      }
    },
    markAsReadListener(message) {
      // Mark other unread messages which not on the current page.
      this.notificationsList = this.notificationsList.map(n => {
        if (
          message.order_id &&
          (n.order_id && n.order_id === message.order_id)
        ) {
          n.unread_messages = 0;
        }
        return n;
      });
    },
    viewDate(entry) {
      return moment
        .utc(entry.timestamp)
        .local()
        .format('MM/DD/YY LT');
    },
    checkIfMentionedInMessage: isMentionedMe,
    isMentionedMe(entry) {
      return entry && entry.is_mentioned_me;
    },
    isWarn(entry) {
      return entry && entry.unread_messages;
    },
    newLines(text) {
      return text ? text.replace(/(?:\r\n|\r|\n)/g, '<br />') : '';
    },
    searchMessages: debounce(function() {
      this.getMessagesData();
    }, 500),

    orderByHead: function(head) {
      if (head !== this.sortKey) {
        this.sortKey = head;
        this.sortDir = 'asc';
      } else {
        this.sortDir = this.sortDir === 'asc' ? 'desc' : 'asc';
      }
    },

    getMessagesData(page, url) {
      if (page === undefined) page = 1;

      if (url === undefined)
        url = `messages/list?user_id=${this.userId}&page=${page}&order_by=${
          this.sortKey
        }&direction=${this.sortDir}`;

      if (this.searchQuery) {
        url +=
          url.indexOf('?') == -1
            ? '?q=' + this.searchQuery
            : '&q=' + this.searchQuery;
      }

      return apiService
        .get(url)
        .then(response => {
          this.$set(this, 'notificationsList', response.messages.data);
          this.$set(this, 'prevPageUrl', response.messages.prev_page_url);
          this.$set(this, 'nextPageUrl', response.messages.next_page_url);
          this.$set(this, 'currentPage', response.messages.current_page);
          this.$set(this, 'pagesNumber', response.messages.last_page);
          this.$set(this, 'total', response.messages.total);
        })
        .catch(err => {
          console.log(err);
          notify({
            message: 'Could not load notifications list',
            type: 'danger'
          });
        });
    },
    MarkAllAsRead() {
      const loggedUser = { userId: this.userId };
      apiService
        .markAllMessagesAsRead(loggedUser)
        .then(result => {
          this.setUnreadMessages([]);
          this.notificationsList = this.notificationsList.map(notification => {
            notification.unread_messages = 0;
            return notification;
          });

          notify({
            message: 'Messages marked as read',
            type: 'success'
          });
        })
        .catch(e => {
          console.log(e);
          notify({
            message: 'Could not load notifications list',
            type: 'danger'
          });
        });
    }
  }
};
</script>
