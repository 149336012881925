<template>
  <div class="sticker-design">
    <div class="columns">
      <div class="column is-4">
        <form-select v-model="design.item.shape"
          name="shape"
          label="Shape"
          :scope="getScope(designKey)"
          :options="{all: shapes}"
          @change="onChangeShape"
          :disabled="isDisabled" />
      </div>
      <div class="column is-4">
        <form-select v-model="design.item.size"
          name="size"
          label="Size"
          :scope="getScope(designKey)"
          :isHierarchical="isSizeHierarchical"
          :disabled="isDisabled"
          :options="{all: sizes}" />
      </div>
      <div class="column is-4">
        <form-select v-model="design.item.quantity"
          name="quantity"
          label="Quantity"
          :scope="getScope(designKey)"
          :options="{all: qtyList}" />
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import bus from '../../../bus';
import apiService from '../../../services/api.service';
import { isEmpty, forEach, merge, isNumber, cloneDeep } from 'lodash';

import formInput from '../../common/form/input';
import formSelect from '../../common/form/select';

let emptyDesignModel = {
  item: {
    shape: '',
    size: '',
    quantity: '',
    parent_shape: ''
  }
};

export default {
  props: {
    getScope: {
      type: Function,
      default: () => () => ''
    },
    designCommon: {
      type: Object,
      default: () => {}
    },
    designSpecific: Object,
    designKey: Number,
    isEmbroidery: Boolean,
    upsServices: Array,
    deliveryTypes: Array,
    resetKey: Number,
    accountId: Number,
    index: Number
  },
  data() {
    return {
      type: 'sticker',
      shapes: [],
      sizes: [],
      qtyList: [],
      summary: {},
      design: cloneDeep(
        Object.assign({}, emptyDesignModel, this.designSpecific)
      )
    };
  },

  components: {
    formInput,
    formSelect
  },

  computed: {
    designShape() {
      return this.design.item.shape;
    },
    isSizeHierarchical() {
      return !!(
        this.sizes &&
        typeof this.sizes.splice === 'undefined' &&
        Object.keys(this.sizes).length > 0
      );
    },
    isDisabled() {
      return this.designCommon.printed_before_order_id ||
        this.designCommon.printed_before_design_id
        ? 'disabled'
        : false;
    }
  },

  watch: {
    designCommon: {
      handler(design, oldDesign) {
        this.calculateSummary();
      },
      deep: true
    },
    design: {
      handler(design, oldDesign) {
        this.calculateSummary();
      },
      deep: true
    },
    summary: {
      handler(summary, oldSummary) {
        this.$emit('summary-changed', this.designKey, summary);
      },
      deep: true
    },
    resetKey(key) {
      this.calculateSummary();

      if (key !== null && key !== undefined && key === this.designKey) {
        this.setEmpty();
      }
    }
  },

  methods: {
    /**
     * Calculate summary
     */
    calculateSummary() {
      let summary = {
        name: this.designCommon.name,
        type: this.designCommon.service,
        printed_before: this.designCommon.printed_before,
        printed_before_order_id: this.designCommon.printed_before_order_id,
        printed_before_design_id: this.designCommon.printed_before_design_id,
        reference_order_id: this.designCommon.reference_order_id,
        reference_design_id: this.designCommon.reference_design_id,
        comment: this.designCommon.comment,
        type_of_delivery: this.designCommon.type_of_delivery,
        ship_by_date: this.designCommon.ship_by_date,
        print_by_date: this.designCommon.print_by_date,
        in_hands_date: this.designCommon.in_hands_date
      };

      let sizeOption = this.sizes.filter(
        size => size.key == this.design.item.size
      )[0];
      let qtyOption = this.qtyList.filter(
        quantity => quantity.key == this.design.item.quantity
      )[0];
      let shapeOption = this.shapes.filter(
        shape => shape.key == this.design.item.shape
      )[0];

      summary.shape = (shapeOption && shapeOption.label) || '';
      summary.size = (sizeOption && sizeOption.label) || '';

      if (sizeOption !== undefined && qtyOption !== undefined) {
        let sizePrice =
          parseFloat(sizeOption.prices[this.design.item.quantity]) || 0;

        summary.total = {
          price: Math.round(sizePrice * 100) / 100,
          qty: parseInt(qtyOption.label.replace(/[^0-9 | ^.]/g, '')),
          upcharge: Math.round(this.designCommon.upcharge * 100) / 100,
          upcharge_description: this.designCommon.upcharge_description
        };
      }

      this.$set(this, 'summary', summary);
    },

    filterUpcharge(items) {
      let basePrice = 0;
      return basePrice;
    },

    objSize(object) {
      return Object.keys(object).length;
    },

    getDesignData(user_id) {
      return apiService.getDesignFormOptions('sticker', user_id).then(
        data => {
          var sourceData = data.form_data;
          this.shapes = sourceData.shapes;
          this.qtyList = sourceData.quantities;
        },
        response => {
          notify({
            message: 'Could not load info for designs',
            type: 'danger'
          });
        }
      );
    },

    onValidate(validateScope) {
      if (validateScope) {
        this.$validator.validateAll(validateScope).catch(err => {});
      } else {
        this.$validator.validateScopes().catch(err => {});
      }
    },

    onClear() {
      this.errors.clear();
    },

    onChangeShape(skipResetting, user_id) {
      if (!this.design.item.shape) {
        return Promise.resolve();
      }

      let shapeOption = this.shapes.filter(
        shape => shape.key == this.design.item.shape
      )[0];
      if (shapeOption) {
        this.$set(this.design.item, 'parent_shape', shapeOption.parent_shape);
      }

      if (skipResetting !== true) {
        // Need to reset selected size field
        this.$set(this.design.item, 'size', '');
        // Need to reset selected Qty field
        this.$set(this.design.item, 'quantity', '');
      }

      if (user_id == undefined) {
        user_id = this.accountId;
      }

      // Retrieve size data for selected shape
      return apiService
        .getStickerSizesByShape(this.design.item.shape, user_id)
        .then(
          data => {
            this.$set(this, 'sizes', data);
            return data;
          },
          response => {
            notify({
              message: 'Could not load info for design',
              type: 'danger'
            });
          }
        );
    },

    setEmpty() {
      this.$set(this, 'design', merge({}, emptyDesignModel));
    },

    setDesign() {
      return {
        key: this.designKey,
        design: this.design
      };
    },

    onMountData(user_id) {
      this.getDesignData(user_id).then(() => {
        this.onChangeShape(true, user_id).then(() => {
          this.calculateSummary();
        });
      });
    }
  },
  created() {
    if (this.design.item.parent_shape) {
      this.design.item.shape =
        this.design.item.parent_shape + '-' + this.design.item.shape;
    }

    bus.$on('validate', this.onValidate);
    bus.$on('clear', this.onClear);
    this.$watch(
      () => this.errors.errors,
      value => {
        bus.$emit('errors-changed', value);
      }
    );
  },
  mounted() {
    if (isNumber(this.resetKey) && this.resetKey === this.designKey) {
      this.setEmpty();
    }

    this.onMountData(this.accountId);

    bus.$on('order-set-account-info', user_id => {
      this.onMountData(user_id);
    });
  },
  destroyed() {
    bus.$off('order-set-account-info');
  }
};
</script>
