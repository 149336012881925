<template>
  <div class="container has-text-centered  payment-status" v-if="additional">
    <p><img src="@/../images/icons/payment_failed.png" width="68" height="68"/></p>
    <div style="font-size: 38px; font-weight: bold;">
      <p>Oh no! Something went wrong.</p>
    </div>
    <div style="font-size: 16px; margin-top: 10px;">
      <p v-if="!additional.transaction.custom_status_comment">There was an error processing your request.</p>
      <p v-else v-html="additional.transaction.custom_status_comment " style="padding-bottom: 1rem;"/>
      <p v-if="additional.transaction.custom_support_comment">{{ additional.transaction.custom_support_comment }}</p>
      <p v-else>Please try again or contact us for assistance at orders@threadbird.com</p>
    </div>
    <div class="buttons action-buttons is-justify-content-center">
      <router-link v-if="additional.routerLink" class="button is-rounded pt-5 pb-5 has-text-weight-bold is-size-5 mt-6"
                   :to='additional.routerLink'>Try Again
      </router-link>
      <a v-if="additional.isPopup" class="button is-rounded" @click="$emit('close')">Try Again</a>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.container {
  margin-top: 100px;
}
</style>

<script>

export default {
  props: {
    additional: Object
  },
  name: 'order-invoices-payment-failure-partial'
};
</script>
