import { getQtySegmentUpcharge } from '@/helpers/prices/base';

/**
 * Calculate design total.
 *
 * @param design
 * @param apparelDesignData
 * @param basePrice
 * @returns {number}
 */
export const getDesignTotal = (design, apparelDesignData, basePrice = false) => {
  let totalGarmentQty = design.specific.extra.total_garments_qty;
  let totalLocations = design.specific.extra.total_locations;
  let totalColorsNumber = design.specific.extra.total_colors_number;
  let locationsCost = 0;
  const hasValidLocation = design.specific.locations.find(
    ({ location, colors_number, ink_type }) =>
      location !== null && colors_number !== null && ink_type !== null
  );

  if (!hasValidLocation) return 0;

  if (totalLocations > 0) {
    locationsCost = getLocationsTotal(
      design.specific.locations,
      apparelDesignData
    );
  }

  let garments = design.specific.garments;

  let garmentsUpcharge = garmentsTotal(
    garments,
    totalGarmentQty,
    totalLocations,
    totalColorsNumber,
    locationsCost,
    apparelDesignData,
    basePrice
  );

  let ink_color_changes = +design.specific.extra.ink_color_changes;
  let ink_color_cost =
    design.specific.extra.ink_color_changes_cost !== undefined &&
    design.specific.extra.ink_color_changes_cost !== null
      ? +design.specific.extra.ink_color_changes_cost
      : +apparelDesignData.garment_upcharges.color_ink_change;
  let ink_color_upcharge = 0;
  if (ink_color_changes > 0) {
    ink_color_upcharge = ink_color_changes * ink_color_cost;
  }

  return garmentsUpcharge + +design.common.upcharge + ink_color_upcharge;
};

/**
 * Calculate garments total.
 *
 * @param garments
 * @param totalGarmentQty
 * @param totalLocations
 * @param totalColorsNumber
 * @param locationsCost
 * @param prices
 * @param basePrice
 * @returns {number}
 */
export const garmentsTotal = (
  garments,
  totalGarmentQty,
  totalLocations,
  totalColorsNumber,
  locationsCost,
  prices,
  basePrice
) => {
  return garments.reduce((gt, garment) => {
    return (
      gt +
      garmentTotal(
        garment,
        totalGarmentQty,
        totalLocations,
        totalColorsNumber,
        locationsCost,
        prices,
        basePrice
      )
    );
  }, 0);
};

export const garmentTotal = (
  garment,
  totalGarmentQty,
  totalLocations,
  totalColorsNumber,
  locationsCost,
  prices,
  basePrice = false
) => {
  return Object.keys(garment.sizes).reduce((st, sizeKey) => {
    const size = garment.sizes[sizeKey];
    const sizeCost =
      garment &&
      garment &&
      garment.size_cost &&
      garment.size_cost[sizeKey] &&
      garment.size_cost[sizeKey].cost !== undefined &&
      garment.size_cost[sizeKey].cost !== null &&
      !basePrice
        ? +garment.size_cost[sizeKey].cost
        : calculateSizeCost(
          basePrice && garment.model.baseUpcharge ? garment.model.baseUpcharge : garment.model.upcharge,
            sizeKey,
            totalGarmentQty,
            totalLocations,
            totalColorsNumber,
            locationsCost,
            prices
          );
    return st + (+size || 0) * sizeCost;
  }, 0);
};

/**
 * Apply locations formula to size cost.
 *
 * @param sizeBaseCost
 * @param totalGarmentQty
 * @param totalLocations
 * @param totalColorsNumber
 * @param locationsCost
 * @param prices
 * @returns {number}
 */
export const calculateSizeCost = (
  sizeBaseCost,
  sizeKey,
  totalGarmentQty,
  totalLocations,
  totalColorsNumber,
  locationsCost,
  prices
) => {
  let sizeCost = +sizeBaseCost;
  sizeCost += +(prices.garment_upcharges[`size_${sizeKey.toLowerCase()}`] || 0);

  let firstPrintLocationCost = getQtySegmentUpcharge(
    prices.garment_upcharges.first_print_location,
    2500,
    totalGarmentQty
  );
  let additionalLocationCost = getQtySegmentUpcharge(
    prices.garment_upcharges.additional_location,
    2500,
    totalGarmentQty
  );
  let additionalColorsCost = getQtySegmentUpcharge(
    prices.garment_upcharges.add_colors,
    2500,
    totalGarmentQty
  );

  // Add additional upcharges base on location count and options of all of them.
  sizeCost += firstPrintLocationCost;

  if (totalLocations - 1) {
    sizeCost += (totalLocations - 1) * additionalLocationCost;
  }

  if (totalColorsNumber - totalLocations) {
    sizeCost += (totalColorsNumber - totalLocations) * additionalColorsCost;
  }

  sizeCost += locationsCost;

  return +parseFloat(sizeCost).toFixed(2);
};

/**
 * Calculate locations cost.
 *
 * @param locations
 * @param prices
 * @returns {number}
 */
export const getLocationsTotal = (locations, prices) => {
  return +locations
    .reduce((acc, location) => {
      if (location.cost !== undefined && location.cost !== null) {
        return acc + +location.cost;
      }

      // Get prices per location option.
      let inkTypeOption = prices.ink_types.find(
        ink_type => +location.ink_type === +ink_type.key
      );
      let inkTypeUpcharge = (inkTypeOption && +inkTypeOption.upcharge) || 0;
      let processAddonsUpcharge = location.process_addons.reduce((acc, lpa) => {
        let processAddonOption = prices.process_addons.find(
          ppa => +lpa.key === +ppa.key
        );
        return acc + (processAddonOption && +processAddonOption.upcharge) || 0;
      }, 0);
      let jumboPrintUpcharge =
        (location.has_jumbo_print && +prices.garment_upcharges.jumbo) || 0;

      // Calculate cost.
      let location_cost =
        parseFloat(+location.colors_number * inkTypeUpcharge) +
        parseFloat(processAddonsUpcharge) +
        parseFloat(jumboPrintUpcharge);
      return acc + location_cost;
    }, 0)
    .toFixed(2);
};
