<template>
  <div>
    <div class="columns filters-block">
      <div class="column is-12 ">
        <div class="columns">
          <button v-for="filter in availableFilters"
                  @click="applyFilters(filter.key,{
                date_from: filter.date_from,
                date_to:filter.date_to
              })"
                  :class="{ active: activeFilter === filter.key }"
                  :disabled="isDataFetching" class="button">
            {{ filter.name }}
          </button>
          <button
            @click="activeFilter = 'custom'"
            :class="{ active: this.activeFilter === 'custom' }"
            :disabled="isDataFetching" class="button">
            Custom
          </button>
          <button @click="applyFilters(null,{})" :disabled="isDataFetching" class="button clear-filters">
            Clear filters
          </button>
        </div>
        <div class="columns" v-if="activeFilter === 'custom'">
          <div class="column is-3 input-cntr">
            <label class="label" for="date_from"  style="margin-bottom: 0">From</label>
            <div class="control">
              <datepicker
                :disabled="isDataFetching"
                v-model="filters.date_from"
                :config="{wrap: true, dateFormat: 'Z', altInput: true, altFormat: 'm/d/Y'}"
                id="date_from"
              />
            </div>
          </div>
          <div class="column is-3 input-cntr">
            <label class="label" for="date_from" style="margin-bottom: 0">To</label>
            <div class="control">
              <datepicker
                :disabled="isDataFetching"
                v-model="filters.date_to"
                :config="{wrap: true, dateFormat: 'Z', altInput: true, altFormat: 'm/d/Y'}"
                id="date_to"
              />
            </div>
          </div>

          <button
            @click="applyFilters('custom',filters)"
            :disabled="isDataFetching" class="button apply-filters">
            Apply
          </button>
        </div>
      </div>
    </div>
    <div class="columns totals">
      <div class="column is-4">
        <div class="block">Total Sales Per Period: <b>{{ totalSalesPerPeriodLabel|price }}</b>
        </div>
      </div>
      <div class="column is-4">
        <div class="block">Sales for the Current Month: <b>{{ currentMonthSalesLabel|price }}</b></div>
      </div>
      <div class="column is-4">
        <div class="block"> Total Orders & Designs Per Period: <b>{{ totalOrdersPerPeriodLabel }}</b> |
          <b>{{ totalDesignsPerPeriodLabel }}</b></div>
      </div>
    </div>
    <div class="columns charts">
      <div class="column is-6">
        <apexchart class="chart" :options="totalSalesChartOptions" :series="totalSalesChartData"/>
      </div>
      <div class="column is-6">
        <apexchart class="chart" :options="totalOrdersPerMonthChartOptions" :series="totalOrdersPerMonthChartData"/>
      </div>
    </div>
    <div class="columns charts">
      <div class="column is-6">
        <div class="chart">
          <div>Number of Orders & Designs (Apparel): <b>{{ totalApparelOrdersLabel }}</b> |
            <b>{{ totalApparelDesignsLabel }}</b></div>
          <apexchart :options="totalByServiceTypeChartOptions" :series="totalByServiceTypeChartData"/>
        </div>
      </div>
      <div class="column is-6">
        <apexchart class="chart" :options="totalByArtProducerOptions" :series="totalByArtProducerChartData"/>
      </div>
    </div>
    <div class="columns charts">
      <div class="column is-12">
        <apexchart class="chart" :options="totalByClientChartOptions" :series="totalByClientChartData"/>
      </div>
    </div>
    <div class="columns charts">
      <div class="column is-12">
        <apexchart class="chart" :options="totalSalesByClientChartOptions" :series="totalSalesByClientChartData"/>
      </div>
    </div>
    <div class="columns charts">
      <div class="column is-12">
        <apexchart class="chart" :options="totalByPrinterTopChartOptions" :series="totalByPrinterTopChartData"/>
      </div>
    </div>
    <div class="columns charts">
      <div class="column is-12">
        <apexchart class="chart" :options="totalByPrinterChartOptions" :series="totalByPrinterChartData"/>
      </div>
    </div>
    <div class="columns charts">
      <div class="column is-12">
        <apexchart class="chart" :options="apparelDesignsByQuantityRangeChartOptions"
                   :series="apparelDesignsByQuantityRangeChartData"/>
      </div>
    </div>
    <div class="columns charts">
      <div class="column is-12">
        <div class="chart">
          <apexchart :options="apparelDesignsByNumberSetupsChartOptions"
                     :series="apparelDesignsByNumberSetupsChartData"/>
        </div>
      </div>
    </div>
    <div class="columns charts">
      <div class="column is-12">
        <apexchart class="chart" :options="apparelImpressionsNumberChartOptions"
                   :series="apparelImpressionsNumberChartData"/>
      </div>
    </div>
    <div class="columns charts">
      <div class="column is-6">
        <apexchart class="chart" :options="apparelDesignsColorsCountChartOptions"
                   :series="apparelDesignsColorsCountChartData"/>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import VueApexCharts from 'vue-apexcharts';
import apiService from '@/services/api.service';
import alertify from 'alertify.js';
import Datepicker from '../../../modules/datepicker/index';

Vue.use(VueApexCharts);

export default {
  name: 'dashboard',
  components: {
    apexchart: VueApexCharts,
    Datepicker
  },

  data() {
    return {
      activeFilter: null,
      filters: {},
      isDataFetching: false,
      dashboardData: {
        totalSalesPerPeriod: null,
        servicesTotal: null,
        servicesTotalV2: null,
        servicesTotalV2Apparel: null,
        currentMonthSales: null,
        totalOrdersPerPeriod: null,
        totalDesignsPerPeriod: null,
        customersTotal: null,
        totalSalesByCustomer: null,
        artProducerTotal: null,
        printersTotal: null,
        topPrintersTotal: null,
        apparelDesignsByQuantityRange: null,
        apparelDesignsBySetupsCount: null,
        apparelColorsCountByLocation: null,
        apparelImpressionsNumber: null
      },
      availableFilters: [
        {
          key: 'rolling_12',
          date_from: new Date(new Date().getFullYear() - 1, new Date().getMonth(), new Date().getDate()).toISOString(),
          date_to: new Date().toISOString(),
          name: 'Rolling 12'
        },
        {
          key: 'year_to_date',
          date_from: new Date(new Date().getFullYear(), 0, 1).toISOString(),
          date_to: new Date(new Date().getFullYear(), 11, 31, 23, 59, 59).toISOString(),
          name: 'Year to Date'
        },
        {
          key: 'current_month',
          date_from: new Date(new Date().getFullYear(), new Date().getMonth(), 1).toISOString(),
          date_to: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0, 23, 59, 59).toISOString(),
          name: 'Current Month'
        },
        {
          key: 'previous_month',
          date_from: new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1).toISOString(),
          date_to: new Date(new Date().getFullYear(), new Date().getMonth(), 0, 23, 59, 59).toISOString(),
          name: 'Previous Month'
        },
        {
          key: 'last_year',
          date_from: new Date(new Date().getFullYear() - 1, 0, 1).toISOString(),
          date_to: new Date(new Date().getFullYear() - 1, 11, 31, 23, 59, 59).toISOString(),
          name: 'Last Year'
        }
      ]
    }
      ;

  },
  computed: {
    dataLabelsConfig() {
      return {
        enabled: true,
        offsetX: 30,
        position: 'bottom', // top, center, bottom
        style: {
          fontSize: '12px',
          colors: ['#304758']
        }
      };
    },
    plotOptionsConfig() {
      return {
        bar: {
          horizontal: true,
          dataLabels: {
            position: 'top',
            total: {
              enabled: true,
              offsetX: 0,
              style: {
                fontSize: '13px',
                fontWeight: 900,
                colors: ['#304758']
              }
            }
          }
        }
      };
    },
    totalSalesPerPeriodLabel() {
      return this.dashboardData && this.dashboardData.totalSalesPerPeriod ? this.dashboardData.totalSalesPerPeriod : 0;
    },
    currentMonthSalesLabel() {
      return this.dashboardData && this.dashboardData.currentMonthSales ? this.dashboardData.currentMonthSales : 0;
    },
    totalOrdersPerPeriodLabel() {
      return this.dashboardData && this.dashboardData.totalOrdersPerPeriod ? this.dashboardData.totalOrdersPerPeriod : 0;
    },
    totalDesignsPerPeriodLabel() {
      return this.dashboardData && this.dashboardData.totalDesignsPerPeriod ? this.dashboardData.totalDesignsPerPeriod : 0;
    },
    totalApparelOrdersLabel() {
      return this.dashboardData && this.dashboardData.servicesTotalV2Apparel ? this.dashboardData.servicesTotalV2Apparel.orders : 0;
    }, totalApparelDesignsLabel() {
      return this.dashboardData && this.dashboardData.servicesTotalV2Apparel ? this.dashboardData.servicesTotalV2Apparel.designs : 0;
    },
    totalSalesLabels() {
      return this.dashboardData && this.dashboardData.totalSalesLabels ? this.dashboardData.totalSalesLabels : [];
    },
    totalByServiceTypeLabelsV2() {
      return this.dashboardData && this.dashboardData.servicesTotalV2 ? this.dashboardData.servicesTotalV2.labels : [];
    },
    totalByClientLabels() {
      return this.dashboardData && this.dashboardData.customersTotal ? this.dashboardData.customersTotal.labels : [];
    },
    totalSalesByClientLabels() {
      return this.dashboardData && this.dashboardData.totalSalesByCustomer ? this.dashboardData.totalSalesByCustomer.labels : [];
    },
    totalByArtProducerLabels() {
      return this.dashboardData && this.dashboardData.artProducerTotal ? this.dashboardData.artProducerTotal.labels : [];
    },
    totalByPrinterLabels() {
      return this.dashboardData && this.dashboardData.printersTotal ? this.dashboardData.printersTotal.labels : [];
    },
    totalByTopPrintersLabels() {
      return this.dashboardData && this.dashboardData.topPrintersTotal ? this.dashboardData.topPrintersTotal.labels : [];
    },
    apparelDesignsByQuantityRangeLabels() {
      return this.dashboardData && this.dashboardData.apparelDesignsByQuantityRange ? this.dashboardData.apparelDesignsByQuantityRange.labels : [];
    },
    apparelDesignsByNumberSetupsLabels() {
      return this.dashboardData && this.dashboardData.apparelDesignsBySetupsCount ? this.dashboardData.apparelDesignsBySetupsCount.labels : [];
    },
    apparelColorsCountByLocationLabels() {
      return this.dashboardData && this.dashboardData.apparelColorsCountByLocation ? this.dashboardData.apparelColorsCountByLocation.labels : [];
    },
    apparelImpressionsNumberLabels() {
      return this.dashboardData && this.dashboardData.apparelImpressionsNumber ? this.dashboardData.apparelImpressionsNumber.labels : [];
    },

    totalSalesChartOptions() {
      return {
        chart: {
          height: 500,
          type: 'bar'
        },
        plotOptions: {
          bar: {
            borderRadius: 4,
            horizontal: true,
            dataLabels: {
              position: 'bottom' // top, center, bottom
            }
          }
        },
        dataLabels: {
          enabled: true,
          formatter: (value) => {
            return this.$options.filters.price(value);
          },
          offsetX: 10,
          style: {
            fontSize: '12px',
            colors: ['#304758']
          },
          dropShadow: {
            enabled: false,
            color: '#fff'
          }
        },
        xaxis: {
          categories: this.totalSalesLabels
        },
        title: {
          text: this.$options.filters.price(this.totalSalesPerPeriodLabel),
          offsetX: 20,
          style: {
            fontSize: '24px'
          }
        },
        subtitle: {
          text: 'Total Sales',
          offsetX: 0,
          style: {
            fontSize: '14px'
          }
        }
      };
    },
    totalSalesChartData() {
      return [{
        name: 'Sales',
        data: this.dashboardData && this.dashboardData.totalSalesPerMonth ? this.dashboardData.totalSalesPerMonth : []
      }];
    },
    totalOrdersPerMonthChartOptions() {
      return {
        title: {
          text: 'Number of Orders & Designs by Month'
        },
        chart: {
          height: 500,
          type: 'bar'
        },
        plotOptions: this.plotOptionsConfig,
        dataLabels: this.dataLabelsConfig,
        xaxis: {
          categories: this.totalSalesLabels
        }
      };
    },
    totalOrdersPerMonthChartData() {
      return [
        {
          name: 'Orders',
          data: this.dashboardData && this.dashboardData.totalOrdersPerMonth ? this.dashboardData.totalOrdersPerMonth : []
        },
        {
          name: 'Designs',
          data: this.dashboardData && this.dashboardData.totalDesignsPerMonth ? this.dashboardData.totalDesignsPerMonth : []
        }
      ];
    },
    totalByServiceTypeChartOptions() {
      return {
        title: {
          text: 'Number of Orders & Designs by Service Type'
        },
        chart: {
          type: 'bar',
          height: 475
        },
        plotOptions: this.plotOptionsConfig,
        dataLabels: this.dataLabelsConfig,
        xaxis: {
          categories: this.totalByServiceTypeLabelsV2
        }
      };
    },
    totalByServiceTypeChartData() {
      return [
        {
          name: 'Orders',
          data: this.dashboardData && this.dashboardData.servicesTotalV2 ? this.dashboardData.servicesTotalV2.orders : []
        },
        {
          name: 'Designs',
          data: this.dashboardData && this.dashboardData.servicesTotalV2 ? this.dashboardData.servicesTotalV2.designs : []
        }
      ];
    },
    totalByClientChartOptions() {
      return {
        title: {
          text: 'Number of Orders & Designs by Client (Top 25)'
        },
        chart: {
          type: 'bar',
          height: 1000
        },
        plotOptions: this.plotOptionsConfig,
        dataLabels: this.dataLabelsConfig,
        xaxis: {
          categories: this.totalByClientLabels
        }
      };
    },
    totalByClientChartData() {
      return [
        {
          name: 'Orders',
          data: this.dashboardData && this.dashboardData.customersTotal ? this.dashboardData.customersTotal.orders : []
        },
        {
          name: 'Designs',
          data: this.dashboardData && this.dashboardData.customersTotal ? this.dashboardData.customersTotal.designs : []
        }
      ];
    },
    totalSalesByClientChartOptions() {
      return {
        title: {
          text: 'Total Sales by Client (Top 25)'
        },
        chart: {
          type: 'bar',
          height: 1000
        },
        plotOptions: this.plotOptionsConfig,

        dataLabels: {
          enabled: true,
          offsetX: 75,
          position: 'bottom', // top, center, bottom
          style: {
            fontSize: '12px',
            colors: ['#304758']
          },
          formatter: (value) => {
            return this.$options.filters.price(value);
          },
          dropShadow: {
            enabled: false,
            color: '#fff'
          }
        },
        xaxis: {
          categories: this.totalSalesByClientLabels
        }
      };
    },
    totalSalesByClientChartData() {
      return [
        {
          name: 'Total Sales',
          data: this.dashboardData && this.dashboardData.totalSalesByCustomer ? this.dashboardData.totalSalesByCustomer.total_price : []
        }
      ];
    },
    totalByPrinterChartOptions() {
      return {
        title: {
          text: 'Number of Orders & Designs by Printer'
        },
        chart: {
          type: 'bar',
          height: 1000
        },
        plotOptions: this.plotOptionsConfig,
        dataLabels: this.dataLabelsConfig,
        xaxis: {
          categories: this.totalByPrinterLabels
        }
      };
    },
    apparelDesignsByQuantityRangeChartOptions() {
      return {
        title: {
          text: 'Number of Designs by Quantity Range (Apparel Only)'
        },
        chart: {
          type: 'bar',
          height: 500,
          stacked: true
        },
        plotOptions: this.plotOptionsConfig,
        dataLabels: {
          enabled: false
        },
        xaxis: {
          categories: this.apparelDesignsByQuantityRangeLabels
        }
      };
    },
    apparelDesignsByQuantityRangeChartData() {
      return (this.dashboardData.apparelDesignsByQuantityRange && this.dashboardData.apparelDesignsByQuantityRange.series) ?
        Object.values(this.dashboardData.apparelDesignsByQuantityRange.series) : [];
    },
    apparelDesignsByNumberSetupsChartData() {
      return (this.dashboardData.apparelDesignsBySetupsCount && Object.keys(this.dashboardData.apparelDesignsBySetupsCount.series).length) ?
        Object.values(this.dashboardData.apparelDesignsBySetupsCount.series) : [];
    },
    totalByPrinterChartData() {
      return [
        {
          name: 'Orders',
          data: this.dashboardData && this.dashboardData.printersTotal ? this.dashboardData.printersTotal.orders : []
        },
        {
          name: 'Designs',
          data: this.dashboardData && this.dashboardData.printersTotal ? this.dashboardData.printersTotal.designs : []
        }
      ];
    },
    totalByPrinterTopChartOptions() {
      return {
        title: {
          text: 'Number of Orders & Designs by Printers (Top 3)'
        },
        chart: {
          type: 'bar',
          height: 250
        },
        plotOptions: this.plotOptionsConfig,
        dataLabels: this.dataLabelsConfig,
        xaxis: {
          categories: this.totalByTopPrintersLabels
        }
      };
    },
    totalByPrinterTopChartData() {
      return [
        {
          name: 'Orders',
          data: this.dashboardData && this.dashboardData.topPrintersTotal ? this.dashboardData.topPrintersTotal.orders : []
        },
        {
          name: 'Designs',
          data: this.dashboardData && this.dashboardData.topPrintersTotal ? this.dashboardData.topPrintersTotal.designs : []
        }
      ];
    },
    totalByArtProducerOptions() {
      return {
        title: {
          text: 'Number of Orders & Designs by Art Producer'
        },
        chart: {
          type: 'bar',
          height: 535
        },
        plotOptions: this.plotOptionsConfig,
        dataLabels: this.dataLabelsConfig,
        xaxis: {
          categories: this.totalByArtProducerLabels
        }
      };
    },
    totalByArtProducerChartData() {
      return [
        {
          name: 'Orders',
          data: this.dashboardData && this.dashboardData.artProducerTotal ? this.dashboardData.artProducerTotal.orders : []
        },
        {
          name: 'Designs',
          data: this.dashboardData && this.dashboardData.artProducerTotal ? this.dashboardData.artProducerTotal.designs : []
        }
      ];
    },
    apparelDesignsColorsCountChartOptions() {
      return {
        title: {
          text: 'Color Counts (Apparel Only)'
        },
        chart: {
          type: 'bar',
          height: 500
        },
        plotOptions: this.plotOptionsConfig,
        dataLabels: this.dataLabelsConfig,
        xaxis: {
          categories: this.apparelColorsCountByLocationLabels
        }
      };
    },
    apparelDesignsColorsCountChartData() {
      return [
        {
          name: 'Number of Designs',
          data: this.dashboardData.apparelColorsCountByLocation && this.dashboardData.apparelColorsCountByLocation.series ? this.dashboardData.apparelColorsCountByLocation.series : []
        }
      ];
    },
    apparelDesignsByNumberSetupsChartOptions() {
      return {
        title: {
          text: 'Number of Setups (Apparel Only)'
        },
        chart: {
          type: 'bar',
          height: 500,
          stacked: true
        },
        plotOptions: this.plotOptionsConfig,
        dataLabels: {
          enabled: false
        },
        xaxis: {
          categories: this.apparelDesignsByNumberSetupsLabels
        }
      };
    },
    apparelImpressionsNumberChartOptions() {
      return {
        title: {
          text: 'Number of Impressions (Apparel Only)'
        },
        chart: {
          type: 'bar',
          height: 500,
          stacked: true
        },
        dataLabels: {
          enabled: false
        },
        plotOptions: this.plotOptionsConfig,
        xaxis: {
          categories: this.apparelImpressionsNumberLabels
        }
      };
    },
    apparelImpressionsNumberChartData() {
      return (this.dashboardData.apparelImpressionsNumber && Object.keys(this.dashboardData.apparelImpressionsNumber.series).length) ?
        Object.values(this.dashboardData.apparelImpressionsNumber.series) : [];
    }
  },
  methods: {
    loadDashboardData() {
      this.isDataFetching = true;
      apiService
        .getReport('dashboard', this.filters)
        .then(res => {
          this.$set(this, 'dashboardData', res);
        })
        .catch(err => {
          alertify.error(err.body.message);
        })
        .finally(() => {
          this.isDataFetching = false;
        });
    },
    applyFilters(filterName, filters) {
      this.activeFilter = filterName;
      this.filters = {...filters, ...{timezone: this.filters.timezone}};
      this.loadDashboardData();
    }
  },
  created() {
    this.filters.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const currentDate = new Date();
    this.applyFilters('rolling_12', {
      date_from: new Date(currentDate.getFullYear() - 1, currentDate.getMonth(), currentDate.getDate()).toISOString(),
      date_to: currentDate.toISOString()
    });
  }
};
</script>
<style lang="scss" scoped>
.filters-block {
  margin-top: 1rem;
  padding: 1rem;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);

  button {
    margin: 1rem 0 1rem 1rem;

    &.apply-filters {
      margin-top: 30px;
    }
  }

  .clear-filters {
    background-color: inherit;
    border: none;

    &.active,
    &:hover,
    &:focus {
      color: #1793D3;
    }

    &:active {
      box-shadow: none;
    }

    &[disabled] {
      background-color: inherit;
      border-color: inherit;
    }
  }
}

.totals {
  .block {
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    padding: 10px;
  }

  .column {
    &:first-child {
      margin-left: -10px;
      margin-right: 10px;
    }

    &:last-child {
      margin-left: 10px;
      margin-right: -10px;
    }

    margin-top: 10px;
  }
}

.charts {
  .chart {
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    padding: 10px;
    margin-left: -10px;
    margin-right: -10px;
  }

  .column {
    &.is-6 {
      width: 49.5%;

      &:first-child {
        margin-right: 0.5%;
      }

      &:last-child {
        margin-left: 0.5%;
      }
    }
  }
}
</style>
