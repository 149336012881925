<template>
  <div class="columns">
    <div class="column">
      <legend><strong>Tags</strong></legend>
      <div class="columns">
        <div class="column">
          <form-input v-model="tag_setups_number"
            name="tag_setups_number"
            :scope="getScope"
            type="number"
            label="Number of Tag Setups?"
            placeholder="e.g. 4"
            rules="required|min_value:0"
            min="0" step="1"/>
        </div>
        <div class="column">
          <div class="control">
            <br>
            <label for="finishing-remove-manufacturer-tag-0">
              <input  class="checkbox"
                v-model="finishing.remove_manufacturer_tag"
                id="finishing-remove-manufacturer-tag-0"
                type="checkbox"> Remove Manufacturer Tag?
            </label>
          </div>
        </div>
      </div>
      <div class="columns">
        <div class="column">
          <form-input v-model="tag_width"
            :required="false"
            name="tag_width"
            :scope="getScope"
            rules="between:0.00,100"
            type="number"
            label="Tag Width"
            placeholder="3.8"
            step="0.01"
            min="0.00"
            max="100" />
        </div>
        <div class="column">
          <form-input v-model="tag_height"
            :required="false"
            name="tag_height"
            rules="between:0.00,100"
            :scope="getScope"
            type="number"
            label="Tag Height"
            placeholder="3.8"
            step="0.01" min="0.00" max="100"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import VeeValidate from 'vee-validate';
Vue.use(VeeValidate);

import bus from '../../../bus';

import formInput from '../../common/form/input';

export default {
  props: ['finishing', 'finishingKey', 'designs', 'selectedDesigns'],
  components: {
    formInput
  },
  data() {
    return {
      tag_setups_number: this.finishing.tag_setups_number || 0,
      tag_width: this.finishing.tag_width || '',
      tag_height: this.finishing.tag_height || '',
      remove_manufacturer_tag: !!this.finishing.remove_manufacturer_tag
    };
  },
  computed: {
    getScope() {
      return 'finishings-' + this.finishingKey + '-fields';
    }
  },
  watch: {
    tag_setups_number(val) {
      this.$set(this.finishing, 'tag_setups_number', val);
    },
    tag_width(val) {
      this.$set(this.finishing, 'tag_width', val);
    },
    tag_height(val) {
      this.$set(this.finishing, 'tag_height', val);
    },
    selectedDesigns() {
      // this.setTagSetupsNumber();
      this.$emit('selectedDesignChanged');
    }
  },
  methods: {
    setTagSetupsNumber() {
      let count = 0;
      let processed_items = {};

      if (this.selectedDesigns != undefined) {
        this.selectedDesigns.forEach(id => {
          if (
            this.designs[id].specific.garments &&
            this.designs[id].specific.garments.length
          ) {
            this.designs[id].specific.garments.forEach(garment => {
              if (garment.sizesData && garment.sizesData.length) {
                garment.sizesData.forEach(sizeLabel => {
                  let productInfo =
                    garment.productsData.filter(
                      item => item.key == garment.product
                    )[0] || {};
                  let key = '';
                  if (!productInfo.origin) {
                    key = sizeLabel + '-' + '-' + productInfo.fabric;
                  } else {
                    key =
                      sizeLabel +
                      '-' +
                      productInfo.origin +
                      '-' +
                      productInfo.fabric;
                  }
                  if (
                    garment.sizes[sizeLabel] > 0 &&
                    processed_items[key] == undefined
                  ) {
                    count++;
                    processed_items[key] = 1;
                  }
                });
              }
            });
          }
        });
      }
      this.tag_setups_number = count;
    }
  },
  mounted() {
    // init dynamic subfields
    this.$set(this.finishing, 'tag_setups_number', this.tag_setups_number);
    this.$set(this.finishing, 'tag_width', this.tag_width);
    this.$set(this.finishing, 'tag_height', this.tag_height);
    this.$set(
      this.finishing,
      'remove_manufacturer_tag',
      !!this.remove_manufacturer_tag
    );
    this.$set(this.finishing, 'price_code', 'tag_printing_and_removal');

    bus.$on('updateTagSetupsNumber', () => {
      this.setTagSetupsNumber();
    });
  },
  beforeDestroy() {
    this.$delete(this.finishing, 'tag_setups_number');
    this.$delete(this.finishing, 'tag_width');
    this.$delete(this.finishing, 'tag_height');
    this.$delete(this.finishing, 'remove_manufacturer_tag');
    this.$delete(this.finishing, 'price_code');
  }
};
</script>
