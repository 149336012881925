import apiService from '../../services/api.service';
import notify from '../../modules/notifier';
import popup from '../../modules/popupper';
import alertify from 'alertify.js';
import { isMentionedMe, openMessenger } from '../../helpers/messages';
import { checkRoles } from '../../router/checkRoles';

import {
  SET_ORDER_MESSAGES,
  ADD_MESSAGES,
  SET_PUBNUB_ENV,
  SEND_MESSAGE,
  LOAD_MESSAGES_BY_ORDER_ID,
  MARK_MESSAGE_AS_READ,
  LOAD_UNREAD_MESSAGES,
  SET_UNREAD_MESSAGES,
  RESUBSCRIBE_PUBNUB
} from '../action-types';

export default {
  state: {
    allMessages: {},
    unreadMessages: {},
    pubNubEnv: ''
  },
  mutations: {
    [SET_UNREAD_MESSAGES](state, messages) {
      state.unreadMessages = messages;
    },
    [SET_ORDER_MESSAGES](state, { orderId, messages }) {
      const am = Object.assign({}, state.allMessages);
      am[orderId] = {};
      messages.forEach(msg => {
        if (am[orderId][msg.channel]) {
          am[orderId][msg.channel].push(msg);
        } else {
          am[orderId][msg.channel] = [msg];
        }
      });
      state.allMessages = am;
    },
    [SET_PUBNUB_ENV](state, env) {
      state.pubNubEnv = env;
    },
    [ADD_MESSAGES](state, { orderId, messages, user, channel }) {
      if (channel === 'on-estimate-sent' || channel === 'mark-as-read') {
        return;
      }

      let am = Object.assign({}, state.allMessages),
        um = Object.assign({}, state.unreadMessages);

      //All messages
      if (am[orderId]) {
        if (am[orderId][channel]) {
          am[orderId][channel] = [...am[orderId][channel], ...messages];
        } else {
          am[orderId][channel] = messages;
        }
      } else {
        am[orderId] = {};
        am[orderId][channel] = messages;
      }

      messages.forEach(msg => {
        if (user.profile.id !== msg.author_id) {
          // push unread messages
          if (typeof um[orderId] === 'undefined') {
            um[orderId] = [];
          }
          um[orderId].push(msg);

          let msg_body =
            msg.attachment_id && !msg.body
              ? 'You have received an attachment.'
              : msg.body;
          const text =
            `<span class="msg-notification">` +
            `<span class="icon is-small is-warning"><i aria-hidden="true" class="ff-chatbubbles"></i></span>` +
            `<strong class="msg-username is-warning">` +
            `${(msg.author && msg.author.first_name) || ''} ${(msg.author &&
              msg.author.last_name) ||
              ''}` +
            `</strong>` +
            `<p>${msg_body}</p></span>`;

          if (
            channel === 'customer' &&
            msg.is_system &&
            checkRoles(user.profile.roles.map(r => r.name), [
              'admin',
              'csr',
              'production-coordinator',
              'purchaser',
              'art-producer'
            ])
          ) {
            return;
          }

          let oids = (user && user.profile && user.profile.oids) || [];
          let oids_communication =
            (user && user.profile && user.profile.oids_communication) || [];

          if (channel === 'email-service') {
            alertify.log(text);
          } else {
            if (
              oids.includes(msg.order_id) ||
              (oids_communication.includes(msg.order_id) &&
                isMentionedMe([msg]))
            ) {
              alertify.log(text, () => {
                openMessenger(msg.order_id);
              });
            } else if (isMentionedMe([msg])) {
              alertify.log(text, () => {
                openMessenger(msg.order_id);
              });
            }
          }
        }
      });

      state.unreadMessages = um;
      state.allMessages = am;
    },
    [MARK_MESSAGE_AS_READ](state, { orderId, message }) {
      const um = Object.assign({}, state.unreadMessages);
      um[orderId] = um[orderId].filter(msg => {
        return msg.id !== message.id;
      });
      state.unreadMessages = um;
    }
  },
  getters: {
    allMessages: state => {
      return state.allMessages;
    },
    pubNubEnv: state => {
      return state.pubNubEnv;
    },
    unreadMessages: state => {
      let undreadMessages = [];
      Object.keys(state.unreadMessages).forEach(orderId => {
        if (
          state.unreadMessages[orderId] &&
          state.unreadMessages[orderId].length
        ) {
          state.unreadMessages[orderId].forEach(msg => {
            undreadMessages.push(msg);
          });
        }
      });
      return undreadMessages;
    },
    unreadOrdersMessages: state => {
      return state.unreadMessages;
    }
  },
  actions: {
    [LOAD_MESSAGES_BY_ORDER_ID]: ({ commit }, orderId) => {
      return apiService.getMessages(orderId).then(messages => {
        commit(SET_ORDER_MESSAGES, {
          orderId,
          messages
        });
        return messages;
      });
    },
    [LOAD_UNREAD_MESSAGES]: ({ commit, rootState }) => {
      return apiService
        .getUnreadMessages(rootState.common.user.profile.id)
        .then(data => {
          if (Object.keys(data.messages).length) {
            commit(SET_UNREAD_MESSAGES, data.messages);
          }
          return data.messages;
        });
    },
    [ADD_MESSAGES]: (
      { commit, dispatch, rootState },
      { orderId, messages, channel }
    ) => {
      commit(ADD_MESSAGES, {
        user: rootState.common.user,
        orderId,
        messages,
        channel
      });
      if (!rootState.common.user.profile.oids.includes(orderId)) {
        dispatch(RESUBSCRIBE_PUBNUB);
      }
    },
    [SET_PUBNUB_ENV]: ({ commit, rootState }, env) => {
      commit(SET_PUBNUB_ENV, env);
    },
    [MARK_MESSAGE_AS_READ]: (
      { commit, getters, rootState },
      { orderId, message }
    ) => {
      if (getters.unreadMessages.filter(um => um.id === message.id).length) {
        commit(MARK_MESSAGE_AS_READ, { orderId, message });
        return apiService.markMessageAsRead({
          message_id: message.id,
          user_id: rootState.common.user.profile.id
        });
      }
    },
    [SEND_MESSAGE]: ({ commit }, msgData) => {
      return apiService.sendMessage(msgData).catch(err => {
        console.error(err);
        notify({ message: 'Something wrong', type: 'danger' });
      });
    }
  }
};
