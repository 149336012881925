<template>
  <div class="finishing">
    <h5 class="title is-5 is-marginless has-text-weight-bold">{{ label }}</h5>
    <div class="control">
      <strong>Name: </strong>
      {{ item.finishing.name }}
    </div>
  </div>
</template>

<script>
  export default {
    name: 'heat-pressed-tags',
    props: {
      item: {
        type: Object,
        required: true
      },
      label: {
        type: String,
        required: true
      }
    },
  }
</script>
