import apiService from '../../services/api.service';
import {
  REVISION_LOAD_ORDER,
  REVISION_LOAD_ORDER_OPTIONS,
  REVISION_LOAD_MESSAGES,
  SET_ACTIVE_DESIGN,
  SET_ACTIVE_MOCK,
  SET_ACTIVE_ATTACHMENT,
  APPROVE_MOCK,
  REQUEST_REVISIONS,
  LOAD_ORDER_OPTIONS
} from '../action-types';
import { APPROVED_STATUS, PENDING_STATUS } from '../../helpers/revision';

import moment from 'moment';
import notify from '../../modules/notifier';
import router from '../../router';
import alertify from 'alertify.js';
import { forEach, cloneDeep } from 'lodash';
import {APPAREL_SERVICE_CODE, EMBROIDERY_SERVICE_CODE} from '@/store/service-types';

const DATE_FORMAT = 'MM/DD/YY';

export default {
  namespaced: true,
  state: {
    activeDesign: 0,
    activeMock: 0,
    activeAttachment: 0,
    order: {},
    orderOptions: {},
    messages: []
  },
  mutations: {
    setOrder(state, order) {
      state.order = order;
    },
    setOrderOptions(state, options) {
      let opts = {
        ...state.orderOptions
      };
      opts[options.type] = options.form_data;
      state.orderOptions = opts;
    },
    setGarmentFabricOrOrigin(state, data) {
      let design = state.order.designs &&
        state.order.designs.find(d => d.id === state.activeDesign);
      let finishingItems = design.designable.finishings[data.finishing_index].items.filter(item => item.garment_id === data.garment_id);

      if (finishingItems && finishingItems[0]) {
        finishingItems[0][data.key] = data.value;
      }
    },
    setMessages(state, messages) {
      state.messages = messages;
    },
    setActiveDesign(state, designId) {
      state.activeDesign = designId;
    },
    setActiveMock(state, mockId) {
      state.activeMock = mockId;
    },
    setActiveAttachment(state, index) {
      state.activeAttachment = index;
    },
    updateMockStatus(state, status) {
      state.order.designs = state.order.designs.map(design => {
        if (design.id === state.activeDesign && design.mocks) {
          design.mocks = design.mocks.map(mock => {
            if (mock.id === state.activeMock) {
              mock.status = status;
            }
            return mock;
          });
        }
        return design;
      });
    }
  },
  getters: {
    order(state) {
      let order = {
        ...state.order
      };
      return order;
    },
    orderOptions(state) {
      return state.orderOptions;
    },
    messages(state) {
      return state.messages;
    },
    designs(state) {
      return state.order.designs;
    },
    currentDesign(state) {
      return (
        (state.order.designs &&
          state.order.designs.find(d => d.id === state.activeDesign)) ||
        {}
      );
    },
    currentMock(state) {
      if (state.order.designs && state.activeDesign && state.activeMock) {
        const design = state.order.designs.find(
          d => d.id === state.activeDesign
        );
        if (design && design.mocks) {
          return design.mocks.find(m => m.id === state.activeMock) || {};
        }
      }
      return {};
    },
    hasMocks(state) {
      if (state.order.designs) {
        const design = state.order.designs.find(
          d => d.id === state.activeDesign
        );
        return design && design.mocks && design.mocks.length;
      }
      return false;
    },
    getActiveMock(state) {
      return state.activeMock;
    },
    currentAttachmentKey(state) {
      return state.activeAttachment;
    },
    currentAttachment(state) {
      if (state.order.designs) {
        const design = state.order.designs.find(
          d => d.id === state.activeDesign
        );
        if (design && design.mocks) {
          const mock = design.mocks.find(m => m.id === state.activeMock) || {};
          return (
            (mock.attachments && mock.attachments[state.activeAttachment]) || {}
          );
        }
      }
      return {};
    },
    currentOptions(state) {
      let options = {};

      if (state.order.designs) {
        const design = state.order.designs.find(
          d => d.id === state.activeDesign
        );
        if (
          design &&
          state.orderOptions &&
          design.service &&
          state.orderOptions[design.service]
        ) {
          options = state.orderOptions[design.service];
          if (state.orderOptions[design.service] instanceof Array) {
            options = {};
          }
        }
      }

      return options;
    },
    showMockActions(state) {
      const design =
        (state.order.designs &&
          state.order.designs.find(d => d.id === state.activeDesign)) ||
        {};
      const mock =
        (design.mocks && design.mocks.find(m => m.id === state.activeMock)) ||
        {};
      const latest =
        (design.mocks &&
          design.mocks.reduce((largest, mock) => {
            if (!largest || mock.version > largest.version) {
              largest = mock;
            }
            return largest;
          }, null)) ||
        {};
      return mock.version == latest.version;
    },
    isEvent(state) {
      let design =
        (state.order.designs &&
          state.order.designs.find(d => d.id === state.activeDesign)) ||
        {};
      return !!(design && design.in_hands_date);
    },
    shipBy(state) {
      let date = ' - ';
      let design =
        (state.order.designs &&
          state.order.designs.find(d => d.id === state.activeDesign)) ||
        {};

      if (design && design.ship_by_date) {
        date = moment(design.ship_by_date).format(DATE_FORMAT);
      }
      return date;
    }
  },
  actions: {
    [REVISION_LOAD_ORDER_OPTIONS]: ({ commit }, type) => {
      apiService.getDesignFormOptions(type).then(data => {
        commit('setOrderOptions', data);
      });
    },
    [REVISION_LOAD_ORDER]: ({ commit, dispatch, rootState }, orderId) => {
      return apiService.getRevisionOrder(orderId).then(data => {
        if (data.order) {
          if (data.order.designs) {
            const services = data.order.designs
              .map(({ service }) => service)
              .filter((value, index, self) => self.indexOf(value) === index);
            services.forEach(service => {
              dispatch(REVISION_LOAD_ORDER_OPTIONS, service);
            });
          }

          let designKey = 0;
          let mockKey = null;
          let p = rootState.route.params;

          if (data.order && data.order.designs) {
            data.order.designs = data.order.designs.map(design => {
              if ([APPAREL_SERVICE_CODE, EMBROIDERY_SERVICE_CODE].includes(design.service)) {
                design.designable.finishings = [];
                data.order.finishings
                  .filter(finishing => {
                    return finishing.items.find(
                      ({ design_id }) => design_id === design.id
                    );
                  })
                  .forEach(finishing =>
                    design.designable.finishings.push(cloneDeep(finishing))
                  );
              }
              return design;
            });

            data.order.designs = data.order.designs
              .sort((a, b) => a.version - b.version)
              .map(design => {
                if (design.mocks) {
                  design.mocks = design.mocks.sort(
                    (a, b) => a.version - b.version
                  );
                }
                return design;
              });

            if (p.designId) {
              data.order.designs.forEach((design, k) => {
                if (design.id === +p.designId) {
                  designKey = k;
                  if (design.mocks) {
                    design.mocks.forEach((mock, mk) => {
                      if (p.mockId !== 'new' && mock.id === +p.mockId) {
                        mockKey = mk;
                      }
                    });
                  }
                }
              });
            }

            if (
              mockKey === null &&
              data.order.designs &&
              data.order.designs[designKey].mocks
            ) {
              mockKey = data.order.designs[designKey].mocks.length - 1;
            }

            commit('setOrder', data.order);
            commit('setActiveDesign', data.order.designs[designKey].id);
            if (data.order.designs[designKey].mocks[mockKey]) {
              commit(
                'setActiveMock',
                data.order.designs[designKey].mocks[mockKey].id
              );
            }
          }
        } else {
          alertify.error("You're not allowed to see this page.");
          router.push('/');
        }
      });
    },
    [REVISION_LOAD_MESSAGES]: ({ commit }, orderId) => {
      apiService.getMessagesByOrderId(orderId).then(data => {
        commit('setMessages', data.messages);
      });
    },
    [SET_ACTIVE_DESIGN]: ({ commit, state }, designId) => {
      commit('setActiveDesign', designId);
      const design = state.order.designs.find(d => d.id === designId);
      if (design && design.mocks && design.mocks.length) {
        commit('setActiveMock', design.mocks[design.mocks.length - 1].id);
      }
      commit('setActiveAttachment', 0);
    },
    [SET_ACTIVE_MOCK]: ({ commit }, mockId) => {
      commit('setActiveMock', mockId);
      commit('setActiveAttachment', 0);
    },
    [SET_ACTIVE_ATTACHMENT]: ({ commit }, key) => {
      commit('setActiveAttachment', key);
    },
    [APPROVE_MOCK]: ({ commit }, mockId) => {
      return apiService
        .approveMock({
          mock_id: mockId
        })
        .then(() => {
          commit('updateMockStatus', APPROVED_STATUS);
        })
        .catch(err => {
          console.error(err);
          notify({
            message: 'Something wrong',
            type: 'danger'
          });
        });
    },
    [REQUEST_REVISIONS]: ({ commit }, data) => {
      return apiService
        .requestRevisions(data)
        .then(response => {
          commit('updateMockStatus', PENDING_STATUS);
          return response;
        })
        .catch(err => {
          console.error(err);
          notify({
            message: 'Something wrong',
            type: 'danger'
          });
        });
    }
  }
};
