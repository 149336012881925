<template>
  <div>
    <form @submit.prevent="submitForm">
      <p class="control has-addons has-addons-centered">
        <span class="select">
          <select name="category"
            :class="{'is-danger': errors.has('form.category'), 'is-loading': lostReasonCategoriesLoading}"
            v-validate="{ rules: 'required', scope: 'form' }"
            scope="form"
            v-model="category">
            <option value="">Category</option>
            <option v-for="(value, key) in lostReasonCategories" :value="key">{{ value }}</option>
          </select>
        </span>
      </p>
      <p class="control">
        <textarea name="comment"
          v-model="comment"
          v-validate="{ rules: 'required', scope: 'form' }"
          class="textarea"
          :class="{'is-danger': errors.has('form.comment')}"
          placeholder="Comment"></textarea>
      </p>
    </form>
  </div>
</template>

<script>
  import Vue from 'vue';
  import apiService from '../../../services/api.service';
  import alertify from 'alertify.js';
  import {
    SET_ORDER_STATUS,
  } from '@/store/action-types';

  export default {
    name: 'status-lost-reason-popup',
    props: ['additional'],
    data() {
      return {
        category: '',
        lostReasonCategories: '',
        lostReasonCategoriesLoading: false,
        comment: '',
        processing: false
      }
    },
    methods: {
      submitForm() {
        return new Promise((res, rej) => {
          this.processing = true;
          this.$validator.validateScopes().then((success) => {
            if (success) {
              this.$store.dispatch(SET_ORDER_STATUS, {
                model: this.additional.model,
                orderId: this.additional.orderId,
                status: this.additional.status,
                status_reason: {
                  category: this.category,
                  comment: this.comment
                }
              }).then(data => {
                this.processing = false;
                if (this.additional.refreshData) {
                  this.additional.refreshData();
                }
                this.$emit('close');
              });
            } else {
              alertify.error('Please fill all fields.');
              this.processing = false;
              rej();
            }
          }).catch((err) => {
            this.processing = false;
            rej();
          });
        });
      }
    },
    mounted() {
      this.lostReasonCategoriesLoading = true;

      apiService.getLostReasonCategoriesList().then((cats) => {
        this.lostReasonCategories = cats;
        this.lostReasonCategoriesLoading = false;
      });
    }
  };
</script>
