import Quill from 'quill';

const Embed = Quill.import('blots/embed');

class Mentioned extends Embed {
  static create(data) {
    const node = super.create();
    node.innerHTML += data.value;
    return Mentioned.setDataValues(node, data);
  }

  static setDataValues(element, data) {
    const domNode = element;
    Object.keys(data).forEach(key => {
      domNode.dataset[key] = data[key];
    });
    return domNode;
  }
  static value(domNode) {
    return domNode.dataset;
  }
}

Mentioned.blotName = 'mentioned';
Mentioned.tagName = 'MENTIONED';

export default Mentioned;
