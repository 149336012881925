<template>
  <div class="content order-summary-content">
    <h3 v-if="!hideHeading" class="subtitle" :id="'design-' + (parseInt(index) + 1)">
      Design #{{parseInt(index) + 1}}{{shortOne || customerOrderDetails ? `: ${design.name}` : ''}}
      <template >
        <span class="printed-before-reprint-label" v-if="design.printed_before_order_id">
          - Reprint
          <router-link class="link"
            :to="getOrderPageUrl(design.printed_before_order_id)">
            #{{design.printed_before_order_id}}
          </router-link>
        </span>
        <span class="printed-before-reprint-label" v-if="design.reference_order_id">
          - Reference
          <router-link class="link"
            :to="getOrderPageUrl(design.reference_order_id)">
            #{{design.reference_order_id}}
          </router-link>
        </span>
      </template>
      <div class="is-warning tag is-pulled-right"
           v-if="design.type_of_delivery == 'pickup'">PICKUP</div><br>
    </h3>
    <div class="design-summary-notes" v-if="!shortOne">
      <div v-if="design.printed_before" class="printed-before">Design has been printed before</div>
      <div v-if="design.reference_order_id" class="printed-before">Reference design has been printed before</div>
    </div>
    <h5 v-if="design.name && !shortOne && !hideHeading && !customerOrderDetails" class="subtitle">
      <strong>Design:</strong> {{design.name}}
    </h5>

    <h5 class="subtitle" v-if="design.locations.length && !shortOne && !customerOrderDetails"><strong>Locations</strong></h5>
    <div v-for="location in design.locations" class="control">
      <strong>{{location.location}}</strong>
      <div v-if="!location.stitches && location.colors_number">
        <span>
          {{getColorsLabel(location)}}
        </span>
        <span v-if="location.colors" v-for="color in location.colors" class="tag is-medium">
          <span class="color-box" :style="'background-color:#' + color.hex"> </span>
          <span>{{color.name}}</span>
        </span>
      </div>
      <div v-if="location.width || location.height"><strong>Size:</strong>
        <div class="info">{{ location.width }}" X {{ location.height }}"</div>
      </div>
      <div v-if="location.depth"><strong>Depth:</strong> {{location.depth.toUpperCase()}}</div>
      <div v-if="location.thread_colors"><strong>Thread colors:</strong> {{location.thread_colors}}</div>
      <div v-if="location.stitches"><strong>Stitch count:</strong> {{location.stitches | itemsNumberFormat}}</div>
      <div v-if="location.ink_type"><strong>Ink Type:</strong> {{location.ink_type}}</div>
      <div v-if="location.process_addons_label"><strong>Process Add-Ons:</strong> {{location.process_addons_label}}</div>
      <div v-if="location.has_jumbo_print"><strong>Jumbo Print</strong></div>
    </div>

    <template>
      <div v-for="(garment, key) in design.garments" v-bind:key="key" class="garments-fieldset">
        <div class="is-grayed-title">
          <strong>Garment: {{ getGarmentName(garment) }}</strong><br>
          <span class="is-italic"><span v-if="garment.fabric">{{ garment.fabric }}</span><span v-if="garment.origin"> / Made in {{garment.origin}}</span></span>
        </div>

        <table class="table table__without-hover">
          <thead>
            <tr>
              <th>Size</th>
              <th v-roles="['admin', 'csr', 'production-coordinator', 'purchaser', 'art-producer', 'customer']">Cost</th>
              <th>Qty.</th>
              <th v-roles="['admin', 'csr', 'production-coordinator', 'purchaser', 'art-producer', 'customer']" class="align-right">Total</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(garmentSize, sizeKey) in sizesList"
               v-if="typeof garment.sizes[garmentSize] !== 'undefined' && +garment.sizes[garmentSize].qty > 0"
               :key="garmentSize">
              <td>{{garmentSize}}</td>
              <td v-roles="['admin', 'csr', 'production-coordinator', 'purchaser', 'art-producer', 'customer']">
                <span v-if="garment.sizes[garmentSize].qty">{{garment.sizes[garmentSize].cost | price}}</span>
                <span v-else>{{0 | price}}</span></td>
              <td>{{garment.sizes[garmentSize].qty | itemsNumberFormat}}</td>
              <td v-roles="['admin', 'csr', 'production-coordinator', 'purchaser', 'art-producer', 'customer']"
                  class="align-right">
                {{garment.sizes[garmentSize].total | price}}
              </td>
            </tr>
            <tr v-roles="['admin', 'csr', 'production-coordinator', 'purchaser', 'art-producer', 'customer']">
              <td colspan="3"><strong>Total:</strong></td>
              <td class="align-right">{{garment.total | price}}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </template>

    <div v-if="!shortOne && design.total.ink_color_changes_upcharge > 0 && design.total">
      <h5 class="subtitle">{{design.ink_color_changes}} ink color change{{design.ink_color_changes > 1 ? 's' : ''}}:
        <span v-roles="['admin', 'csr', 'production-coordinator', 'purchaser', 'art-producer', 'customer']">
         {{design.total.ink_color_changes_upcharge | price}}
        </span>
      </h5>
    </div>


    <div v-if="design.total && design.total.upcharge > 0"  v-roles="['admin', 'csr', 'production-coordinator', 'purchaser', 'art-producer', 'customer']">
      <strong>Upcharge for <span v-if="design.name">{{design.name}}</span>
        <span v-else>Design #{{parseInt(index) + 1}}</span>:
      </strong> {{design.total.upcharge | price}}
      <p v-if="design.total.upcharge_description">{{design.total.upcharge_description}}</p>
    </div>

    <template v-if="!shortOne">
      <h5 v-if="design.finishings && design.finishings.length" class="subtitle">Finishings</h5>

      <div v-for="finishing in design.finishings">
        <component v-if="finishing.finishing_option"
          :is="'finishing-summary-' + finishing.finishing_option"
          :design="design"
          :finishing="finishing"></component>
      </div>
    </template>

    <div class="is-grayed-title" v-if="design.comment">
      <p>
        <strong>Design Comment: </strong>
        <span class="break-word content-pre" v-html="design.comment"></span>
      </p>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.garment-colsgroup {
  & + .garment-colsgroup {
    tr:first-child {
      td {
        border-top: 2px solid #dbdbdb;
      }
    }
  }
}
</style>

<script>
import Vue from 'vue';

import finishingSummaryFoldingBagging from './apparel-finishing-summary-types/finishing-summary-folding-bagging';
import finishingSummaryHangTag from './apparel-finishing-summary-types/finishing-summary-hang-tag';
import finishingSummaryHeatPressedTags from './apparel-finishing-summary-types/finishing-summary-heat-pressed-tags';
import finishingSummaryHemTag from './apparel-finishing-summary-types/finishing-summary-hem-tag';
import finishingSummarySewnTagBottomOrSleeve from './apparel-finishing-summary-types/finishing-summary-sewn-tag-bottom-or-sleeve';
import finishingSummarySewnTagNeckAndFourSided from './apparel-finishing-summary-types/finishing-summary-sewn-tag-neck-and-four-sided';
import finishingSummaryTagPrinting from './apparel-finishing-summary-types/finishing-summary-tag-printing';
import finishingSummaryWovenNeckTag from './apparel-finishing-summary-types/finishing-summary-woven-neck-tag';
import finishingSummaryFourSidedTag from './apparel-finishing-summary-types/finishing-summary-four-sided-tag';

import { forEach } from 'lodash';
import { cloneDeep } from 'lodash';

export default {
  name: 'summary-apparel',
  props: [
    'design',
    'index',
    'hideZeroQuantitySizes',
    'shortOne',
    'hideHeading',
    'customerOrderDetails'
  ],
  components: {
    finishingSummaryFoldingBagging,
    finishingSummaryHangTag,
    finishingSummaryHeatPressedTags,
    finishingSummaryHemTag,
    finishingSummarySewnTagBottomOrSleeve,
    finishingSummarySewnTagNeckAndFourSided,
    finishingSummaryTagPrinting,
    finishingSummaryWovenNeckTag,
    finishingSummaryFourSidedTag
  },
  data() {
    return {
      garments: [],
      sizesList: []
    };
  },
  methods: {
    getGarments() {
      if (!this.design.garments) return [];

      return this.design.garments.slice().map(garm => {
        let garment = Object.assign({}, garm);
        garment.sizes = Object.keys(garment.sizes).reduce((obj, curKey) => {
          this.sizesList[curKey] = curKey;
          obj[curKey] = garm.sizes[curKey];
          return obj;
        }, {});
        return garment;
      });
    },
    getOrderPageUrl(id) {
      return this.$route.path.indexOf('customer') !== -1
        ? `/customer/orders/${id}/details`
        : `/orders/${id}`;
    },
    getColorsLabel(location) {
      return `${location.colors_number} ${
        +location.colors_number > 1 ? 'colors' : 'color'
      }`;
    },
    getGarmentName(g) {
      return `${g.brand} ${g.number} - ${g.name} (${g.color})`;
    }
  },
  computed: {
    productsData() {
      let allSizes = [];
      let garments = Object.values(this.garments);
      let groupedProducts = {};

      if (garments.length > 0) {
        garments.forEach(garment => {
          let standardPrice = 0;

          Object.values(garment.sizes).forEach(size => {
            standardPrice =
              standardPrice == 0 || size.cost < standardPrice
                ? size.cost
                : standardPrice;
          });

          garment.standardPrice = standardPrice;

          if (!groupedProducts[garment.id]) {
            let title = garment.number;
            if (garment.fabric) title += ' - ' + garment.fabric;
            if (garment.origin) title += ' - ' + garment.origin;

            groupedProducts[garment.id] = {
              products: [],
              title: title
            };
          }

          groupedProducts[garment.id].products.push(garment);

          allSizes = allSizes.concat(
            Object.keys(garment.sizes).filter(item => {
              return allSizes.indexOf(item) < 0;
            })
          );
        });
      }

      return {
        allSizes,
        groupedProducts
      };
    },
    garmentsSubtotal() {
      return this.design.garments.reduce(
        (subtotal, garment) => subtotal + garment.total,
        0
      );
    }
  },
  mounted() {
    this.sizesList = cloneDeep(this.$store.getters.sortedGarmentsSizes);
    this.garments =
      this.design.garments && this.design.garments.length
        ? this.getGarments()
        : this.design.garments;
  }
};
</script>
