<template>
  <div></div>
</template>

<script>
  import Vue from 'vue';

  export default {
    props: ['finishing', 'finishingKey'],
    data() {
      return {}
    },
    computed: {
      getScope() {
        return 'finishings-' + this.finishingKey + '-fields';
      }
    },
    mounted() {
      this.$set(this.finishing, 'price_code', 'sewn_tag_neck_and_four_sided');
    },
    beforeDestroy() {
      this.$delete(this.finishing, 'price_code', 'sewn_tag_neck_and_four_sided');
    }
  };
</script>
