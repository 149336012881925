<template>
  <table class="table is-borderless table-design-data is-fullwidth">
    <thead>
    <tr>
      <th>Info</th>
      <th v-roles="['admin', 'csr', 'production-coordinator', 'purchaser', 'art-producer', 'developer', 'customer']">Price</th>
      <th>Quantity</th>
    </tr>
    </thead>
    <tbody>
    <tr>
      <td data-title="Info">
        <p>Item: {{ item.model.product_brand.name }} {{ item.model.number }} {{ item.model.name }}</p>
        <div v-html="item.model.description" v-if="item.model.description && item.model.description.length"></div>
      </td>
      <td data-title="Price" v-roles="['admin', 'csr', 'production-coordinator', 'purchaser', 'art-producer', 'developer', 'customer']">{{ productPrice.toFixed(2)| price }}</td>
      <td data-title="Quantity">{{ item.quantity !== null ? item.quantity : '-' }}</td>
    </tr>
    <tr>
      <td data-title="Info" v-if="item.size">
        <p>Size: {{ item.size }}</p>
      </td>
    </tr>
    <tr>
      <td data-title="Info" v-if="item.number_of_colors">
        <p>{{ numberOfColorsLabel }}: {{ item.number_of_colors }}</p>
      </td>
    </tr>
    <tr>
      <td data-title="Info" v-if="item.color">
        <p>Color: {{ item.color }}</p>
      </td>
    </tr>
    <template v-if="item.addons.length">
      <tr v-for="addon in item.addons">
        <td data-title="Info">
          <p v-html="`Add-On: ${addon.product_addon_name}`"></p>
        </td>
        <td data-title="Price"></td>
        <td data-title="Quantity">{{ item.quantity !== null ? item.quantity : '-' }}</td>
      </tr>
    </template>
    </tbody>
  </table>

</template>
<script>
import {findProductPrice} from "@/helpers/prices/promotional";

export default {
  name: 'PromotionalProductItem',
  props: {
    item: {
      type: Object,
      required: true
    },
    formOptions: {
      type: Object,
      required: true
    },
    isRedoDesign: {
      type: Boolean,
      default: false,
      required: false
    },
  },
  computed: {
    numberOfColorsLabel() {
      if (this.isPetProduct && !this.isPetToysCategory) {
        return 'Pet Product Size';
      }
      if (this.isShoelacesProduct) {
        return 'Shoelace Options';
      }
      if (this.isCuffBeanieProduct) {
        return 'Beanie Option';
      }
      if (this.isScarfsProduct) {
        return 'Scarfs';
      }

      return 'Number of Colors';
    },
    isPetProduct() {
      return this.item.model.product_type && this.item.model.product_type.name === 'Pet Products';
    },
    isCustomProduct(){
      return this.item.model.product_type && this.item.model.product_type.name === 'Custom Products'
    },
    isShoelacesProduct() {
      return this.isCustomProduct && this.item.model && this.item.model.product_category && this.item.model.product_category.name === 'Shoelaces'
    },
    isCuffBeanieProduct() {
      return this.isCustomProduct && this.item.model && this.item.model.product_category && this.item.model.product_category.name === 'Cuff Beanie'
    },
    isScarfsProduct() {
      return this.isCustomProduct && this.item.model && this.item.model.product_category && this.item.model.product_category.name.trim() === 'Scarfs'
    },
    isPetToysCategory() {
      return this.isPetProduct && this.item.model
        && this.item.model.product_category && this.item.model.product_category.name === 'Toys'
    },
    productPrice(){
      let price = 0;

      if(this.isRedoDesign){
        return price
      }

      if (this.item.quantity) {
        price += findProductPrice(this.item, this.formOptions);
        if(this.item.addons.length){
          this.item.addons.forEach(addon => {
            if(addon.price !== null){
              price += (parseFloat(addon.price) + (addon.fixed_1 !== null ? parseFloat(addon.fixed_1) / +this.item.quantity : 0) + (addon.fixed_2 !== null ? parseFloat(addon.fixed_2) / +this.item.quantity : 0))
            }
          })
        }
      }

      return price;
    }
  },
  methods: {
    findProductPrice: findProductPrice,
  }
}
</script>
