<template>
  <div class="columns is-gapless is-fullwidth">
    <div class="column">
      <div class="table-grid-wrapper">
        <!-- sticky header -->
        <div v-if="stickyHeader"
          :class="{'sticky-header': stickyHeader}">
          <table v-if="filteredData.length"
            class="table is-fullwidth grid1 table__without-hover">
            <table-head :dataColumns="columns"
              :actionColumns="actionColumns"
              :orders="orders"
              :forceExpanded="forceExpanded"
              :sortField="sortField"
              :sortDir="sortDir"
              @sortBy="sortBy"
              ref="stickyHead"></table-head>
          </table>
        </div>
        <!-- end sticky header -->

        <!-- data table -->
        <table v-if="!loading && filteredData.length"
          :class="{
            'table is-fullwidth grid1 table__without-hover': true,
            'with-sticky-header': stickyHeader
          }">
          <!-- table head -->
          <table-head :hidden="stickyHeader"
            :dataColumns="columns"
            :actionColumns="actionColumns"
            :orders="orders"
            :forceExpanded="forceExpanded"
            :sortField="sortField"
            :sortDir="sortDir"
            @sortBy="sortBy"
            ref="tableHead"></table-head>
          <!-- end table head -->

          <!-- rows -->
          <transition-group name="flip-list" tag="tbody" ref="tbody">
            <template v-for="(entry, rowKey) in filteredData">

              <!-- orders -->
              <row :row="entry"
                :actions="orders.actions && orders.actions[rowKey]"
                :id="entry[0].value"
                :forceExpanded="forceExpanded"
                :showDesigns="entry ? showDetailRow[entry[0].value]: false"
                :dataColumns="columns"
                :actionColumns="actionColumns"
                :key="entry[0].value + phase"
                :phase="phase"
                :showTriangle="showTriangle"
                rowClass="main-row"
                rowType="order"
                :actionMenu="actionMenu"
                @handleActionsMenu="handleActionsMenu"
                @toggleDetailRow="toggleDetailRow"></row>

              <template v-if="entry && (showDetailRow[entry[0].value] || forceExpanded)">
                <!-- designs -->
                <row v-for="(inner, innerKey) in designs.data[entry[0].value]"
                  :row="inner"
                  :actions="designs.actions && designs.actions[entry[0].value][innerKey]"
                  :id="inner[0].value"
                  :parentId="entry[0].value"
                  :forceExpanded="forceExpanded"
                  :showDesigns="showDetailRow[entry[0].value]"
                  :dataColumns="columns"
                  :actionColumns="actionColumns"
                  :key="'design-' + phase + entry[0].value + inner[0].value"
                  :phase="phase"
                  rowClass="details-row"
                  :showTriangle="showTriangle"
                  rowType="design"
                  :actionMenu="actionMenu"
                  @handleActionsMenu="handleActionsMenu"
                  @toggleDetailRow="toggleDetailRow"></row>
                <!-- end designs -->

                <!-- finishings -->
                <template v-if="!!finishings && finishings.data">
                  <row v-for="(finishing, finishingKey) in finishings.data[entry[0].value]"
                    :row="finishing"
                    :actions="finishings.actions && finishings.actions[entry[0].value][finishingKey]"
                    :id="finishing[0].value"
                    :parentId="entry[0].value"
                    :forceExpanded="forceExpanded"
                    :showDesigns="showDetailRow[entry[0].value]"
                    :dataColumns="columns"
                    :actionColumns="actionColumns"
                    :key="'finishing-' + phase + entry[0].value + finishing[0].value"
                    :phase="phase"
                    :showTriangle="showTriangle"
                    rowClass="details-row"
                    rowType="finishing"
                    :actionMenu="actionMenu"
                    @handleActionsMenu="handleActionsMenu"
                    @toggleDetailRow="toggleDetailRow"></row>
                </template>
                <!-- end finishings -->
              </template>
            </template>
          </transition-group>
          <!-- end rows -->
        </table>
        <!-- loader -->
        <section v-else class="hero has-text-centered">
          <div class="hero-body">
            <div class="container">
              <p class="subtitle">
                {{loading ? 'Loading...' : noResMsg}}
              </p>
            </div>
          </div>
        </section>
        <!-- end loader -->
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.sticky-header {
  position: -webkit-sticky;
  position: -moz-sticky;
  position: -ms-sticky;
  position: -o-sticky;
  position: sticky;
  top: 0;
  z-index: 2;

  // thead tr:last-child {
  //   th {
  //     &:before {
  //       content: '';
  //       display: block;
  //       position: absolute;
  //       left: 0;
  //       background: linear-gradient(
  //         to bottom,
  //         rgba(0, 0, 0, 0.03) 0%,
  //         rgba(0, 0, 0, 0) 100%
  //       );
  //       right: 0;
  //       height: 6px;
  //       bottom: -6px;
  //       border-radius: 5px;
  //     }
  //   }
  // }
}

.with-sticky-header {
  position: relative;
  margin-top: -115px;
}
</style>

<script>
import Vue from 'vue';
import { forEach, isEmpty } from 'lodash';
import Row from './row';
import Head from './head';

export default {
  name: 'grid',
  template: '#grid-template',
  props: {
    forceExpanded: {
      type: Boolean,
      default: false
    },
    assignButton: {
      type: Boolean,
      default: false
    },
    showTriangle: {
      type: Boolean,
      default: true
    },
    loading: {
      type: Boolean,
      default: false
    },
    noResMsg: {
      type: String,
      default: 'No items found'
    },
    orders: Object,
    designs: Object,
    finishings: {
      type: Object,
      default: null
    },
    columns: Array,
    actionColumns: Array,
    stickyHeader: Boolean,
    defaultSortfield: {
      type: String,
      default: 'created_at'
    }
  },
  data() {
    return {
      sortField: this.defaultSortfield,
      showDetailRow: {},
      sortDir: 'DESC',
      expandedSmall: false,
      actionMenu: false
    };
  },
  watch: {
    details(newDetails, old) {
      if (isEmpty(old) && !isEmpty(newDetails)) {
        this.expandSmall(newDetails);
      }
    }
  },
  components: {
    Row,
    tableHead: Head
  },
  computed: {
    //sort
    phase() {
      return (
        this.$store.getters.myOrdersPhase +
        this.$store.getters.orderDetailsPhase
      );
    },
    filteredData() {
      return this.orders.data;
    }
  },
  //capitalize filter
  filters: {
    capitalize(str) {
      return str.charAt(0).toUpperCase() + str.slice(1);
    }
  },
  methods: {
    //change sort order
    sortBy({ sortField, sortDir }) {
      if (this.sortField !== sortField) {
        this.sortField = sortField;
        this.sortDir = sortDir;
      } else {
        this.sortDir = this.sortDir == 'DESC' ? 'ASC' : 'DESC';
      }
      this.$emit('onGridSortBy', this.sortField, this.sortDir);
    },
    expandSmall(details) {
      forEach(details, (val, id) => {
        if (val.length == 1) {
          this.$set(this.showDetailRow, id, true);
        }
      });
    },

    toggleDetailRow(id) {
      const prev = this.showDetailRow[id];
      this.$set(this.showDetailRow, id, !prev);
    },

    syncTables() {
      if (!this.$refs.tableHead || !this.$refs.stickyHead) return;

      const headSubhead = this.$refs.tableHead.$refs.subheadRow;
      const stickyHeadSubhead = this.$refs.stickyHead.$refs.subheadRow;

      for (let i = 0; i < headSubhead.children.length; i++) {
        stickyHeadSubhead.children[i].style.width = getComputedStyle(
          headSubhead.children[i]
        ).width;
      }
    },

    observeMutationsAndUpdate() {
      if (!MutationObserver) return;

      this.observer = new MutationObserver(mutations => {
        this.syncTables();
        this.observer.disconnect();
      });

      this.observer.observe(this.$refs.tbody.$el, {
        childList: true
      });
    },
    handleActionsMenu(cellKey) {
      if (cellKey === this.actionMenu || !cellKey) {
        this.$set(this, 'actionMenu', false);
      } else {
        this.$set(this, 'actionMenu', cellKey);
      }
    }
  },
  mounted() {
    this.expandSmall(this.details);
    if (this.stickyHeader) {
      window.addEventListener('resize', this.syncTables.bind(this));
    }
  },
  updated() {
    if (this.stickyHeader && this.$refs.tbody) {
      this.syncTables();
      this.observeMutationsAndUpdate();
    }
  },
  destroyed() {
    window.removeEventListener('resize', this.syncTables.bind(this));
  }
};
</script>
