<template>
  <div class="form-item" :class="{
    'is-horizontal': horizontal,
    'with-icon': $slots.icon
  }">

    <div class="form-item-icon" v-if="$slots.icon">
      <slot name="icon"></slot>
    </div>

    <label
      class="form-item-label"
      :for="scope + '-' + name"
      v-if="!hideLabel && hasLabel"
    >
      {{ label }}
      <span
        class="form-item-required"
        v-if="required"
      > *</span>
    </label>

    <slot name="input-before"></slot>

    <div class="form-item-control">
      <datepicker
        :value="value"
        :placeholder="placeholder"
        :disabled="disabled"
        :id="scope + '-' + name"
        :name="scope + '-' + name"
        :data-vv-as="name | startCase"
        :data-vv-name="name"
        v-validate="{ rules: rulesObject, scope: scope }"
        v-bind="datepickerConfig"
        :wrapper-class="'form-item-control'"
        :input-class="'form-item-input'"
        @input="onInput"
      ></datepicker>
      <button
        class="form-item-clear"
        @click.prevent="clear"
        v-if="clearable && value"
      >x</button>
    </div>
    <span
      class="form-item-help help is-danger is-visible error-msg"
      v-show="errors.has(scope + '.' + name) && showErrors"
    >
      <slot name="errors">{{ errors.first(scope + '.' + name) }}</slot>
    </span>

    <slot name="input-after"></slot>

  </div>
</template>

<script>
import Vue from 'vue';
import bus from '@/bus';
import alertify from 'alertify.js';
import Datepicker from 'vuejs-datepicker';

const defaultConfig = {};

export default {
  name: 'form-item',
  components: {
    Datepicker
  },
  props: {
    clearable: {
      type: Boolean,
      default: false
    },
    value: { required: true },
    isLoading: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: ''
    },
    hideLabel: {
      type: Boolean,
      default: false
    },

    // datepicker config
    config: {},

    // common attributes, any form element may have ones
    disabled: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: ''
    },
    rules: {
      type: String,
      default: ''
    },
    scope: {
      type: String,
      default: 'form-scope'
    },
    name: {
      type: String,
      required: true
    },
    horizontal: {
      type: Boolean,
      default: false
    },
    showErrors: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    hasLabel() {
      return this.label.length > 0;
    },
    datepickerConfig() {
      return Object.assign({}, defaultConfig, this.config);
    },
    rulesObject() {
      const rules = {};
      this.rules
        .trim()
        .split('|')
        .forEach(rule => {
          if (!rule || rule.length === 0) return;
          const parts = rule.split(':');
          rules[parts[0]] = parts[1] === undefined ? true : parts[1];
        });
      rules.required = this.required;
      return rules;
    }
  },
  methods: {
    onInput(value) {
      this.$emit('input', value);
    },
    clear() {
      this.$emit('input', null);
    },
    onValidate(validateScope) {
      if (validateScope) {
        this.$validator.validateAll(validateScope).catch(err => {});
      } else {
        this.$validator.validateScopes().catch(err => {});
      }
    },
    onClear() {
      this.errors.clear(this.scope);
    },
    addError(e) {
      if (
        e.field === this.name &&
        e.scope === this.scope &&
        (!this.fieldsetName ||
          e.fullName === `${this.fieldsetName}.${this.name}`)
      ) {
        this.errors.add(
          e.field,
          e.msg.replace(e.fullName, this.getLabel),
          e.rule,
          e.scope
        );
        if (this.showErrors) {
          alertify.error(e.msg.replace(e.fullName, this.getLabel));
        }
      }
    }
  },
  created() {
    if (this.static) return;
    bus.$on('add-error', this.addError);
    bus.$on('validate', this.onValidate);
    bus.$on('clear', this.onClear);
    this.$watch(
      () => this.errors.errors,
      (value, oldValue) => {
        if (value.length) {
          bus.$emit('errors-changed', value);
        } else {
          bus.$emit('errors-deleted', oldValue);
        }
      }
    );
  },
  beforeDestroy() {
    if (!this.static) {
      bus.$emit('errors-deleted', this.errors.errors);
      bus.$off('validate', this.onValidate);
      bus.$off('clear', this.onClear);
      bus.$off('add-error', this.addError);
    }
  }
};
</script>
