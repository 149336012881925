<template>
  <div class="page-content container section pt-3" style="padding: 0">
    <section id="orders">
      <filterOrder v-if="showFilters" :filtersOptions="filtersOptions"
      @takeFilters="onFilters"></filterOrder>
      <br>
      <grid :orders="gridData"
        :finishings="gridOrderFinishings"
        :designs="gridOrderDetails"
        :columns="gridHeaders"
        :loading="ordersLoading"
        :sticky-header="true"
        noResMsg="No orders"
        @onGridSortBy="gridUpdateOrders"></grid>

      <paginator v-if="orders.length"
        :pagesNumber="ordersPaginatorData.last_page"
        :prevPageUrl="ordersPaginatorData.prev_page_url"
        :nextPageUrl="ordersPaginatorData.next_page_url"
        :currentPage="ordersPaginatorData.current_page"
        :getListItems="loadOrdersPaginated"
        :showOffset="true"
        :totalNumber="ordersPaginatorData.total"
        :sortField="sortField"
        :sortDir="sortDir">
      </paginator>
    </section>
  </div>
</template>

<style lang="scss" scoped>
.input-cntr {
  position: relative;

  .button {
    position: absolute;
    right: 0;
    background: white;

    &:hover {
      background: #1793d3;
    }
  }

  input {
    width: 100% !important;
  }
}
</style>


<script>
import Vue from 'vue';
import grid from '../common/grid/grid';

import formSelect from '../common/form/select';
import Datepicker from '../../modules/datepicker/index';
import paginator from '../common/paginator.vue';
import { merge, filter, cloneDeep } from 'lodash';

import filterOrder from './filter-order.vue';

import { mapGetters, mapActions, mapMutations } from 'vuex';

import { LOAD_ORDERS } from '../../store/action-types';

import {
  gridHeaders,
  getGridData,
  getGridOrderDetails,
  getGridOrderFinishings
} from '../../helpers/orders-summary';

import pubnubService from '../../services/pubnub.service';
import { ORDER_FILTER_STATUSES } from '../../helpers/orders';

export default {
  name: 'orders',

  props: {
    showFilters: {
      type: Boolean,
      default: true
    },
    companyId: {
      type: Number | String,
      default: null
    },
    status: {
      type: Number | String,
      default: ORDER_FILTER_STATUSES.ORDER_FILTER_STATUS_ACTIVE
    }
  },
  data() {
    return {
      filters: {
        companies: [this.companyId],
        status: this.status
      },
      sortField: 'estimate_becomes_to_order_at',
      sortDir: 'DESC',
      page: 1,
      perPage: 50,
      url: 'orders/list',
      colorizeArchivedOrderLinks: false
    };
  },
  computed: {
    ...mapGetters([
      'orders',
      'ordersPaginatorData',
      'ordersLoading',
      'actualFilters',
      'filtersOptions'
    ]),

    gridHeaders() {
      return gridHeaders();
    },

    gridData() {
      return {
        data: getGridData(
          this.orders,
          {
            readMessages: this.readMessagesAction,
            colorizeArchivedOrderLinks: this.colorizeArchivedOrderLinks
          },
          this.companyId ? 'user' : 'company'
        )
      };
    },

    gridOrderDetails() {
      return {
        data: getGridOrderDetails(this.orders)
      };
    },

    gridOrderFinishings() {
      return {
        data: getGridOrderFinishings(this.orders)
      };
    }
  },

  watch: {
    companyId() {
      this.filters['companies'] = [this.companyId];
      this.gridUpdateOrders();
    }
  },
  components: {
    grid,
    formSelect,
    Datepicker,
    paginator,
    filterOrder
  },
  mounted() {
    this.gridUpdateOrders(this.sortField, this.sortDir);
    pubnubService.subscribeUpdateEvent('mock', this.mockUpdateHandler);
    pubnubService.subscribeUpdateEvent('order', this.orderUpdateHandler);
  },
  destroyed() {
    pubnubService.unsubscribeUpdateEvent('order');
    pubnubService.unsubscribeUpdateEvent('mock', this.mockUpdateHandler);
  },
  methods: {
    ...mapActions({
      loadOrders: LOAD_ORDERS
    }),
    getDesignProperty(orderId, designId, prop) {
      return this.myOrders
        .filter(o => o.id === orderId)[0]
        .designs.filter(d => d.id === designId)[0][prop];
    },
    loadOrdersPaginated(page, url, perPage, sortField, sortDir) {
      this.perPage = perPage;
      this.page = page;
      this.url = url;

      let pagination_filter = {
        url: url,
        perPage: perPage,
        sortField: sortField,
        sortDir: sortDir
      };

      if (page) {
        pagination_filter.page = page;
      }

      let params = Object.assign(pagination_filter, this.filters);

      return this.loadOrders(params);
    },
    // Update orders in store on orders sorting.
    gridUpdateOrders(sortField, sortDir) {
      this.sortField = sortField;
      this.sortDir = sortDir;

      let filters = {
        url: this.url,
        perPage: this.perPage,
        sortField: this.sortField,
        sortDir: this.sortDir
      };

      if (this.page) {
        filters.page = this.page;
      }
      let params = Object.assign(filters, this.filters);
      this.loadOrders(params);
      this.colorizeArchivedOrderLinks =
        (params &&
          +params.status === ORDER_FILTER_STATUSES.ORDER_FILTER_STATUS_ALL) ||
        false;
    },
    mockUpdateHandler(message) {
      if (message.order_id) {
        const updatedOrders = this.orders.find(
          order => order.id === message.order_id
        );
        if (updatedOrders) {
          this.gridUpdateOrders(this.sortField, this.sortDir);
        }
      }
    },
    onFilters(filters) {
      this.filters = cloneDeep(filters);
      if (this.companyId) {
        this.filters['companies'] = [this.companyId];
      }
      this.page = 1;
      this.gridUpdateOrders(this.sortField, this.sortDir);
    },
    orderUpdateHandler(message) {
      if (message.order_id) {
        const updatedOrders = this.orders.find(
          order => order.id === message.order_id
        );
        if (updatedOrders) {
          this.gridUpdateOrders(this.sortField, this.sortDir);
        }
      }
    }
  }
};
</script>
