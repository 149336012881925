import moment from 'moment';
import gridHelper, {
  colorizeArchivedOrderLinks,
  getGridOrderPaymentStatus,
  toPriceFormat
} from './grid-helper';

import {teamMembers} from './role-access';

import store from '../store';
import { APPROVED_STATUS } from './revision';
import { isMentionedMe, openMessenger } from './messages';
import { checkRoles } from '@/router/checkRoles';
import {
  APPAREL_SERVICE_CODE,
  EMBROIDERY_SERVICE_CODE,
  FINISHING_CREATION_SERVICE_CODE,
  PROMOTIONAL_SERVICE_CODE,
  PAPER_SERVICE_CODE,
  STICKER_SERVICE_CODE,
  BUTTON_SERVICE_CODE,
  BLANK_SERVICE_CODE,
  OTHER_SERVICE_CODE,
  INVOICE_SERVICE_CODE,
  DIGITAL_SERVICE_CODE,
  POSTER_SERVICE_CODE
} from '@/store/service-types';

const DATE_FORMAT = 'MM/DD/YY';
export const BLANK_STATUS_NOT_ORDERED = '0';
export const BLANK_STATUS_PARTIAL_ORDERED = '1';
export const BLANK_STATUS_ORDERED = '2';
export const BLANK_STATUS_STOCK_ISSUE = '3';

export const FOLLOWUP_STATUS_NOT_READY = 0;
export const FOLLOWUP_STATUS_APPROVED = 1;
export const FOLLOWUP_STATUS_REDO = 2;

export const PAYMENT_STATUS_UNPAID = 0;
export const PAYMENT_STATUS_PENDING = 1;
export const PAYMENT_STATUS_PAID = 2;
export const PAYMENT_STATUS_DECLINED = 3;
export const PAYMENT_STATUSES = {
  [PAYMENT_STATUS_UNPAID]: 'Unpaid',
  [PAYMENT_STATUS_PENDING]: 'Partial',
  [PAYMENT_STATUS_PAID]: 'Paid'
};
export const PAYMENT_STATUSES_HTML = {
  [PAYMENT_STATUS_UNPAID]:
    "<span class='status-unpaid has-text-grey'>Unpaid</span>",
  [PAYMENT_STATUS_PENDING]:
    "<span class='status-partial has-color-warning'>Partial</span>",
  [PAYMENT_STATUS_PAID]:
    "<span class='status-paid has-color-positive'>Paid</span>"
};

export const ORDER_STATUS_ARCHIVED = 3;
export const ORDER_STATUS_ARCHIVED_LOST = 4;

export const ORDER_FILTER_STATUSES = {
  ORDER_FILTER_STATUS_ACTIVE: 1,
  ORDER_FILTER_STATUS_ARCHIVED: 2,
  ORDER_FILTER_STATUS_OVERDUE: 3,
  ORDER_FILTER_STATUS_ALL: 4
};

export const headers = [
  { head: 'Order', subhead: '', sortField: 'id' },
  { head: 'Account/Design', subhead: '' },
  { head: 'Date', subhead: '', sortField: 'estimate_becomes_to_order_at' },
  { head: 'Print By', subhead: '', sortField: 'print_by_date' },
  {
    head: 'Ship by',
    subhead: '(event = red)',
    sortField: 'ship_by_date',
    sortDir: 'ASC'
  }
];

export const gridHeaders = (
  setActions,
  isActiveAction,
  phaseForGrid = 'no-specific-phases'
) =>
  headers.concat([
    { head: 'Printer', subhead: '' },
    { head: 'CSR', subhead: '', sortField: 'csr_title', sortDir: 'ASC' },
    { head: 'Art', subhead: '' },
    { head: 'Des/Loc', subhead: '' },
    { head: 'PD', subhead: '' },
    {
      head: '',
      subhead: {
        value: 'S',
        str: 'SET',
        tag: 'link',
        click:
          phaseForGrid === 'no-specific-phases'
            ? setActions
            : phaseForGrid.S === true ? setActions : false,
        isActive:
          phaseForGrid === 'no-specific-phases'
            ? isActiveAction
            : phaseForGrid.S === true ? isActiveAction : false,
        clickableArea:
          phaseForGrid === 'no-specific-phases' ? true : phaseForGrid.S,
        iconClass: 'ff-settings'
      }
    },
    {
      head: '',
      subhead: {
        value: 'B',
        str: 'BLK',
        tag: 'link',
        click:
          phaseForGrid === 'no-specific-phases'
            ? setActions
            : phaseForGrid.B === true ? setActions : false,
        isActive:
          phaseForGrid === 'no-specific-phases'
            ? isActiveAction
            : phaseForGrid.B === true ? isActiveAction : false,
        clickableArea:
          phaseForGrid === 'no-specific-phases' ? true : phaseForGrid.B,
        iconClass: 'ff-tshirt'
      }
    },
    {
      head: 'Phases',
      subhead: {
        value: 'M',
        str: 'MOC',
        tag: 'link',
        click:
          phaseForGrid === 'no-specific-phases'
            ? setActions
            : phaseForGrid.M === true ? setActions : false,
        isActive:
          phaseForGrid === 'no-specific-phases'
            ? isActiveAction
            : phaseForGrid.M === true ? isActiveAction : false,
        clickableArea:
          phaseForGrid === 'no-specific-phases' ? true : phaseForGrid.M,
        iconClass: 'ff-photos-pictures'
      }
    },
    {
      head: '',
      subhead: {
        value: 'P',
        str: 'PRT',
        tag: 'link',
        click:
          phaseForGrid === 'no-specific-phases'
            ? setActions
            : phaseForGrid.P === true ? setActions : false,
        isActive:
          phaseForGrid === 'no-specific-phases'
            ? isActiveAction
            : phaseForGrid.P === true ? isActiveAction : false,
        clickableArea:
          phaseForGrid === 'no-specific-phases' ? true : phaseForGrid.P,
        iconClass: 'ff-droplet'
      }
    },
    {
      head: '',
      subhead: {
        value: 'Sh',
        str: 'SHP',
        tag: 'link',
        click:
          phaseForGrid === 'no-specific-phases'
            ? setActions
            : phaseForGrid.Sh === true ? setActions : false,
        isActive:
          phaseForGrid === 'no-specific-phases'
            ? isActiveAction
            : phaseForGrid.Sh === true ? isActiveAction : false,
        clickableArea:
          phaseForGrid === 'no-specific-phases' ? true : phaseForGrid.Sh,
        iconClass: 'ff-pin-map'
      }
    },
    {
      head: '',
      subhead: {
        value: 'F',
        str: 'FUP',
        tag: 'link',
        click:
          phaseForGrid === 'no-specific-phases'
            ? setActions
            : phaseForGrid.F === true ? setActions : false,
        isActive:
          phaseForGrid === 'no-specific-phases'
            ? isActiveAction
            : phaseForGrid.F === true ? isActiveAction : false,
        clickableArea:
          phaseForGrid === 'no-specific-phases' ? true : phaseForGrid.F,
        iconClass: 'ff-heart'
      }
    },
    {
      head: 'Messages',
      subhead: ''
    },
    {
      head: 'Total',
      subhead: '',
      roles: teamMembers
    },
    {
      head: 'Status',
      subhead: ''
    }
  ]);

export const gridHeadersSimple = headers.concat([
  { head: 'Printer', subhead: '' },
  { head: 'CSR', subhead: '', sortField: 'csr_title', sortDir: 'ASC' },
  { head: 'Art', subhead: '' },
  { head: 'Des/Loc', subhead: '' },
  { head: 'PD', subhead: '' },
  { head: '', subhead: 'SET' },
  { head: '', subhead: 'BLK' },
  { head: 'Phases', subhead: 'MOC' },
  { head: '', subhead: 'PRT' },
  { head: '', subhead: 'SHP' },
  { head: '', subhead: 'FUP' },
  { head: 'Messages', subhead: '' },
  { head: 'Total', subhead: '', roles: teamMembers }
]);

export const gridHeadersSimpleUnclaimedMocks = headers.concat([
  { head: '', subhead: '' },
  { head: 'CSR', subhead: '', sortField: 'csr_title', sortDir: 'ASC' },
  { head: ' ', subhead: '' },
  { head: 'Des/Loc', subhead: '' },
  { head: 'PD', subhead: '' },
  { head: '', subhead: 'SET' },
  { head: '', subhead: 'BLK' },
  { head: 'Phases', subhead: 'MOC' },
  { head: '', subhead: 'PRT' },
  { head: '', subhead: 'SHP' },
  { head: '', subhead: 'FUP' },
  { head: 'Messages', subhead: '' },
  { head: 'Total', subhead: '', roles: teamMembers }
]);

export const progressFieldsList = [
  'progress_setup',
  'progress_blank',
  'progress_mock',
  'progress_print',
  'progress_shipment',
  'progress_finish'
];

export const getGridData = (orders, actions) => {
  return orders.map((order, index) => {
    let summary = (order && order.estimate && JSON.parse(order.estimate)) || {};
    let hasEvent = false;
    let print_by_date = order.print_by_date
      ? moment(order.print_by_date).format(DATE_FORMAT)
      : ' - ';
    let ship_by_date = order.ship_by_date
      ? moment(order.ship_by_date).format(DATE_FORMAT)
      : ' - ';
    let printer = '';
    let orderProgressStatusesSum = {};
    let designsHasErrors = false;
    if (order.designs) {
      let designsLength = order.designs.length;
      designsHasErrors = order.designs.some(
        ({ blank_status }) => +blank_status === +BLANK_STATUS_STOCK_ISSUE
      );
      progressFieldsList.forEach(progressType => {
        if (!orderProgressStatusesSum[progressType]) {
          orderProgressStatusesSum[progressType] = 0;
        }
        order.designs.forEach(design => {
          if (!printer && design.printer && design.printer.code)
            printer = design.printer.code;
          else if (printer && design.printer && printer !== design.printer.code)
            printer = 'MIX';

          if (!hasEvent) hasEvent = !!design.in_hands_date;
          let designStatus = design[progressType] || 0;

          if (
            design.designable &&
            design.designable.finishings &&
            design.designable.finishings.length &&
            (progressType === 'progress_print' ||
              progressType === 'progress_setup')
          ) {
            const finishingStatus = design.designable.finishings.reduce(
              (status, finish) => {
                switch (progressType) {
                  case 'progress_print':
                    return finish.printer_status * 50 <= status
                      ? finish.printer_status * 50
                      : status;
                  case 'progress_setup':
                    // original_files_link is 0 or 1.
                    return !!finish.original_files_link * 100 <= status
                      ? !!finish.original_files_link * 100
                      : status;
                }
              },
              100
            );
            designStatus =
              finishingStatus <= designStatus
                ? finishingStatus
                : design[progressType];
          }
          /**
           * The idea of that is set to designStatus 100 points, if current progress type of design is disabled.
           * so orderProgressStatusesSum calculation will understand that disabled progresses are ready by default
           * and if all another progresses from other designs is ready so make a green color for whole phase
           */
          let phasesStatusForDesing = gridHelper.phasesStateForDesign(design);
          let currentPhase = gridHelper.convertProgressTypeToPhase(
            progressType
          );
          //if current phase on current design is disabled
          if (!phasesStatusForDesing[currentPhase]) {
            let designProgressesSum = 0;
            order.designs.forEach(
              design => (designProgressesSum += design[progressType])
            );
            if (designProgressesSum) designStatus = 100;
          }
          orderProgressStatusesSum[progressType] += designStatus;
        });

        orderProgressStatusesSum[progressType] =
          orderProgressStatusesSum[progressType] / designsLength;
      });
    }
    if (hasEvent)
      ship_by_date =
        '<span class="has-color-error">' + ship_by_date + '</span>';

    let accountName =
      (order.user &&
        ((order.user.company && order.user.company.name) ||
          (order.user.first_name &&
            order.user.last_name &&
            `${order.user.first_name} ${order.user.last_name}`) ||
          (order.user.first_name && `${order.user.first_name}`) ||
          (order.user.last_name && `${order.user.last_name}`) ||
          order.user.title)) ||
      order.company ||
      (order.contact_firstname &&
        order.contact_lastname &&
        `${order.contact_firstname} ${order.contact_lastname}`) ||
      '';
    accountName = `<span class="ellipsis" style="max-width: 140px">${accountName}</span>`;

    let amount = +(
      +order.total_price ||
      (summary &&
        summary.total &&
        +summary.total.price + +summary.total.upcharge) ||
      0
    ).toFixed(2);

    let notice = null;

    let dateColumn =
      (order &&
        order.estimate_becomes_to_order_at &&
        moment(`${order.estimate_becomes_to_order_at}`).format(DATE_FORMAT)) ||
      '-';
    let data = [
      {column: 'id', value: order.id, isFirst: order.is_first, roles: teamMembers},
      {
        column: 'Order',
        value: colorizeArchivedOrderLinks(
          order,
          actions.colorizeArchivedOrderLinks
        ),
        notice
      },

      {
        column: 'Account/Design',
        value: accountName,
        alignLeft: true,
        strong: true
      },

      {
        column: 'Date',
        value: dateColumn
      },
      {
        column: 'Print By',
        value: print_by_date
      },
      {
        column: 'Ship by',
        value: ship_by_date
      }
    ];
    data.push({ column: 'Printer', value: printer || '' });
    data.push({ column: 'CSR', value: (order.csr && order.csr.title) || '' });
    data.push({
      column: 'Art',
      value: (order.designer && order.designer.title) || ''
    });
    data.push({
      column: 'Des/Loc',
      value: (order.designs && order.designs.length) || 0
    });
    data.push({
      column: 'PD',
      value: order.days_in_production || ' - '
    });
    progressFieldsList.forEach(progressType => {
      let currentPhase = gridHelper.convertProgressTypeToPhase(progressType);
      if (order.designs && gridHelper.phasesStateForOrder(order.designs)[currentPhase]) {
        data.push({
          column: 'Phase',
          value: `<div class="${gridHelper.getProgressIcon(
            orderProgressStatusesSum[progressType],
            false,
            progressType === 'progress_blank' ? designsHasErrors : false
          )}"></div>`
        });
      } else {
        data.push({
          column: 'Phase',
          value: `<div class="${gridHelper.getProgressIcon(
            orderProgressStatusesSum[progressType],
            true,
            progressType === 'progress_blank' ? designsHasErrors : false
          )}"></div>`
        });
      }
    });
    let uM = store.getters.unreadOrdersMessages[order.id]
      ? store.getters.unreadOrdersMessages[order.id]
      : [];
    data.push({
      column: 'Messages',
      value: {
        count: uM.length ? uM.length : null,
        isCountDanger: isMentionedMe(uM),
        tag: 'custom-icon',
        customClass: 'ff-lg ff-chatbubbles',
        str: 'Read',
        click: openMessenger
      }
    });

    data.push({
      column: 'Total',
      value: toPriceFormat(amount),
      roles: teamMembers
    });

    data.push({
      column: 'Status',
      rolesAct: [
        'admin',
        'csr',
        'production-coordinator',
        'purchaser',
        'art-producer'
      ],
      value: getGridOrderPaymentStatus(order)
    });
    return data;
  });
};

export const getGridOrderDetails = orders => {
  let details = {};
  orders.forEach(order => {
    if (order.designs) {
      details[order.id] = order.designs.map(design => {
        let ship_by_date = design.ship_by_date
          ? moment(design.ship_by_date).format(DATE_FORMAT)
          : ' - ';
        let print_by_date = design.print_by_date
          ? moment(design.print_by_date).format(DATE_FORMAT)
          : ' - ';

        if (design.in_hands_date) {
          ship_by_date = `<span class="has-color-error">${ship_by_date}</span>`;
        }

        let printer = (design && design.printer && design.printer.code) || '  ';

        let designName = design.name;

        if (design.printed_before_order_id || design.printed_before_design_id) {
          designName += ' (Reprint)';
        } else if (design.reference_order_id || design.reference_design_id) {
          designName += ' (Reference)';
        }

        let designDetails = [
          { column: 'id', value: design.id },
          { column: 'Order', value: '  ' },
          { column: 'Account/Design', value: designName, alignLeft: true },
          { column: 'Date', value: ' - ' },
          { column: 'Print By', value: print_by_date },
          { column: 'Ship By', value: ship_by_date }
        ];

        designDetails.push({ column: 'Printer', value: printer });
        designDetails.push({ column: 'CSR', value: '  ' });
        designDetails.push({ column: 'Art', value: '  ' });
        designDetails.push({
          column: 'Des/Loc',
          value:
            design.designable && design.designable.locations
              ? design.designable.locations.length
              : ' - '
        });
        designDetails.push({ column: 'PD', value: '  ' });

        let isSpecificService = [BLANK_SERVICE_CODE, DIGITAL_SERVICE_CODE, BUTTON_SERVICE_CODE, STICKER_SERVICE_CODE, POSTER_SERVICE_CODE, INVOICE_SERVICE_CODE]
          .includes(design.service);
        progressFieldsList.forEach(progressType => {
          if (isSpecificService) {
            switch (design.service) {
              case INVOICE_SERVICE_CODE:
                switch (progressType) {
                  case 'progress_setup':
                  case 'progress_blank':
                  case 'progress_mock':
                  case 'progress_print':
                  case 'progress_shipment':
                  case 'progress_finish':
                    designDetails.push({
                      column: 'Phase',
                      value: `<div class="${gridHelper.getProgressIcon(
                        design[progressType],
                        true
                      )}"></div>`
                    });
                }
                break;
              case BLANK_SERVICE_CODE:
                switch (progressType) {
                  case 'progress_setup':
                  case 'progress_mock':
                  case 'progress_print':
                    designDetails.push({
                      column: 'Phase',
                      value: `<div class="${gridHelper.getProgressIcon(
                        design[progressType],
                        true
                      )}"></div>`
                    });
                    break;
                  case 'progress_blank':
                    designDetails.push({
                      column: 'Phase',
                      value: `<div class="${gridHelper.getProgressIcon(
                        design[progressType],
                        true,
                        +design.blank_status === +BLANK_STATUS_STOCK_ISSUE
                      )}"></div>`
                    });
                    break;
                  default:
                    designDetails.push({
                      column: 'Phase',
                      value: `<div class="${gridHelper.getProgressIcon(
                        design[progressType]
                      )}"></div>`
                    });
                    break;
                }
                break;
              case DIGITAL_SERVICE_CODE:
              case STICKER_SERVICE_CODE:
              case POSTER_SERVICE_CODE:
                switch (progressType) {
                  case 'progress_blank':
                    designDetails.push({
                      column: 'Phase',
                      value: `<div class="${gridHelper.getProgressIcon(
                        design[progressType],
                        true,
                        +design.blank_status === +BLANK_STATUS_STOCK_ISSUE
                      )}"></div>`
                    });
                    break;
                  default:
                    designDetails.push({
                      column: 'Phase',
                      value: `<div class="${gridHelper.getProgressIcon(
                        design[progressType]
                      )}"></div>`
                    });
                    break;
                }
                break;
              case BUTTON_SERVICE_CODE:
                switch (progressType) {
                  case 'progress_blank':
                    designDetails.push({
                      column: 'Phase',
                      value: `<div class="${gridHelper.getProgressIcon(
                        design[progressType],
                        true,
                        +design.blank_status === +BLANK_STATUS_STOCK_ISSUE
                      )}"></div>`
                    });
                    break;
                  case 'progress_mock':
                    designDetails.push({
                      column: 'Phase',
                      value: `<div class="${gridHelper.getProgressIcon(
                        design[progressType],
                        true
                      )}"></div>`
                    });
                    break;
                  default:
                    designDetails.push({
                      column: 'Phase',
                      value: `<div class="${gridHelper.getProgressIcon(
                        design[progressType]
                      )}"></div>`
                    });
                    break;
                }
                break;
            }
          } else {
            if (progressType === 'progress_blank') {
              designDetails.push({
                column: 'Phase',
                value: `<div class="${gridHelper.getProgressIcon(
                  design[progressType],
                  false,
                  +design.blank_status === +BLANK_STATUS_STOCK_ISSUE
                )}"></div>`
              });
            } else {
              designDetails.push({
                column: 'Phase',
                value: `<div class="${gridHelper.getProgressIcon(
                  design[progressType]
                )}"></div>`
              });
            }
          }
        });

        designDetails.push({
          column: 'Messages',
          value: '  '
        });

        designDetails.push({
          column: 'Total',
          value: '  ',
          roles: teamMembers
        });

        designDetails.push({
          column: 'Status',
          value: '  '
        });
        return designDetails;
      });
    }
  });
  return details;
};

export const getGridOrderFinishings = orders => {
  let allFinishings = {};
  orders.forEach(order => {
    let orderFinishings = {};
    if (order.designs) {
      order.designs.map(design => {
        if (!(design && design.designable && design.designable.finishings))
          return;

        let designName = design.name;
        design.designable.finishings.forEach(finishing => {
          if (orderFinishings[finishing.id]) {
            orderFinishings[finishing.id].designNames += ', ' + designName;
            orderFinishings[finishing.id].label = design.redo_design_id
              ? finishing.label + '-R'
              : finishing.label;
          } else {
            orderFinishings[finishing.id] = {
              finishing,
              label: design.redo_design_id
                ? finishing.label + '-R'
                : finishing.label,
              designNames: designName
            };
          }
        });
      });
    }
    if (!Object.keys(orderFinishings).length) return;

    allFinishings[order.id] = Object.keys(orderFinishings).map(key => {
      let finishing = orderFinishings[key].finishing;
      let data = [
        { column: 'id', value: finishing.id },
        { column: 'Order', value: '  ' },
        {
          column: 'Account/Design',
          value: orderFinishings[key].label || finishing.label,
          alignLeft: true
        },
        { column: 'Date', value: ' - ' },
        { column: 'Print By', value: ' - ' },
        { column: 'Ship by', value: ' - ' }
      ];
      data.push({ column: 'Printer', value: ' ' });
      data.push({ column: 'CSR', value: ' ' });
      data.push({ column: 'Art', value: ' ' });
      data.push({ column: 'Des/Loc', value: ' ' });
      data.push({ column: 'PD', value: ' ' });

      progressFieldsList.forEach(progressType => {
        let progress = null;
        switch (progressType) {
          case 'progress_print':
            progress = finishing.printer_status * 50;
            break;
          case 'progress_setup':
            progress = !!finishing.original_files_link * 100;
            break;
        }

        data.push({
          column: 'Phase',
          value:
            progress !== null
              ? `<div class="${gridHelper.getProgressIcon(progress)}"></div>`
              : '-'
        });
      });

      data.push({
        column: 'Messages',
        value: '  '
      });

      data.push({
        column: 'Total',
        value: '  ',
        roles: teamMembers
      });

      data.push({
        column: 'Status',
        value: '  '
      });

      return data;
    });
  });
  return allFinishings;
};

export const getActionsPhaseS = (orders, actions, assignButton) => {
  let actionsPhase = {
    columns: [
      { head: 'SETUP ACTIONS', subhead: 'Files' },
      { head: '', subhead: 'Printer' }
    ],
    data: [],
    details: {},
    finishings: {}
  };

  orders.forEach(order => {
    let orderPrinterNames = [];
    let orderPrinterCount = 0;
    let orderFinishings = {};
    if (order.designs) {
      // prepare designs
      actionsPhase.details[order.id] = order.designs.map(design => {
        if (design && design.designable && design.designable.finishings) {
          design.designable.finishings.forEach(finishing => {
            orderFinishings[finishing.id] = finishing;
          });
        }

        let assignDesignPrinterCell = {
          column: 'Printer',
          str: 'Assign',
          tag: 'button',
          click: actions.assignDesignPrinterPopup,
          smallText: true
        };

        if (design.printer) {
          // Store unique printer name for current order
          orderPrinterNames.indexOf(design.printer.code) === -1 &&
            orderPrinterNames.push(design.printer.code);
          orderPrinterCount++;

          assignDesignPrinterCell = {
            column: 'Printer',
            str: '',
            tag: 'custom-icon',
            customClass: 'ff-lg ff-compose',
            click: actions.assignDesignPrinterPopup,
            textBeforeBtn: design.printer.code,
            smallText: true
          };

          if (checkRoles(store.getters.roles, ['printer'])) {
            delete assignDesignPrinterCell.click;
            delete assignDesignPrinterCell.tag;
            delete assignDesignPrinterCell.customClass;
          }
        }

        let fileLinkClass = 'ff-lg ff-cloud-upload has-text-grey';

        if (design.original_files_link) {
          fileLinkClass = 'ff-lg ff-link has-text-primary';
        }

        return [
          { column: 'id', value: design.id },
          {
            column: 'Files',
            roles: [
              'admin',
              'csr',
              'production-coordinator',
              'purchaser',
              'art-producer'
            ],
            value:
              design.service === 'blank'
                ? '-'
                : {
                    tag: 'custom-icon',
                    str: '',
                    click: actions.filesPopup,
                    customClass:fileLinkClass
                  },
            smallText: true
          },
          {
            column: 'Printer',
            value: assignDesignPrinterCell,
            roles: [
              'admin',
              'csr',
              'production-coordinator',
              'printer',
              'purchaser',
              'art-producer'
            ],
            smallText: true
          }
        ];
      });
    }
    let orderPrinters = '-';
    if (orderPrinterNames.length) {
      let printerName =
        orderPrinterNames.length > 1 ? 'MIX' : orderPrinterNames[0];
      orderPrinters = '<span>' + printerName + '</span>';
    } else if (assignButton) {
      orderPrinters = {
        column: 'Printer',
        str: 'Assign All',
        tag: 'button',
        click: actions.assignAllDesignPrinterPopup,
        smallText: true
      };
    }
    actionsPhase.data.push([
      { column: 'id', value: order.id },
      { column: 'Files', value: '-', smallText: true },
      { column: 'Printer', value: orderPrinters, smallText: true }
    ]);

    if (!Object.keys(orderFinishings).length) return;

    // prepare finishings
    actionsPhase.finishings[order.id] = Object.keys(orderFinishings).map(
      key => {
        let fileLinkClass = 'ff-lg ff-cloud-upload has-text-grey';

        if (orderFinishings[key].original_files_link) {
          fileLinkClass = 'ff-lg ff-link has-text-primary';
        }

        return [
          { column: 'id', value: orderFinishings[key].id },
          {
            column: 'Files',
            roles: [
              'admin',
              'csr',
              'production-coordinator',
              'purchaser',
              'art-producer'
            ],
            value: {
              tag: 'custom-icon',
              str: '', // Manage files
              click: actions.filesPopup,
              customClass: fileLinkClass
            },
            smallText: true
          },
          { column: 'Printer', value: '  ', smallText: true }
        ];
      }
    );
  });

  return actionsPhase;
};

export const getActionsPhaseB = (orders, actions) => {
  let actionsPhase = {
    columns: [{ head: 'BLANKS ACTIONS', subhead: 'Status' }],
    data: [],
    details: {},
    finishings: {}
  };

  orders.forEach(order => {
    let orderFinishings = {};
    if (order.designs) {
      actionsPhase.details[order.id] = order.designs.map(design => {
        if (design && design.designable && design.designable.finishings) {
          design.designable.finishings.forEach(finishing => {
            orderFinishings[finishing.id] = finishing;
          });
        }
        return [
          { column: 'id', value: design.id },
          {
            column: 'Status',
            rolesAct: [
              'admin',
              'csr',
              'production-coordinator',
              'purchaser',
              'art-producer'
            ],
            value: {
              tag: 'select',
              options: {
                [BLANK_STATUS_NOT_ORDERED]: 'Not ordered',
                [BLANK_STATUS_PARTIAL_ORDERED]: 'Partial ordered',
                [BLANK_STATUS_ORDERED]: 'Ordered',
                [BLANK_STATUS_STOCK_ISSUE]: 'Stock Issue'
              },
              value: design.blank_status,
              change: actions.changeBlankStatus
            },
            smallText: true
          }
        ];
      });
    }
    actionsPhase.data.push([
      { column: 'id', value: order.id },
      { column: '', value: '-' }
    ]);

    if (!Object.keys(orderFinishings).length) return;

    // prepare finishings
    actionsPhase.finishings[order.id] = Object.keys(orderFinishings).map(
      key => {
        return [
          { column: 'id', value: orderFinishings[key].id },
          { column: '', value: '-' }
        ];
      }
    );
  });

  return actionsPhase;
};

export const getActionsPhaseM = (orders, actions) => {
  let actionsPhase = {
    columns: [
      { head: 'MOCK ACTIONS', subhead: 'VER' },
      { head: '', subhead: 'Upload' },
      { head: '', subhead: 'Status' },
      { head: '', subhead: 'Files' }
    ],
    data: [],
    details: {},
    finishings: {}
  };

  orders.forEach(order => {
    let orderFinishings = {};
    if (order.designs) {
      actionsPhase.details[order.id] = order.designs.map(design => {
        if (design && design.designable && design.designable.finishings) {
          design.designable.finishings.forEach(finishing => {
            orderFinishings[finishing.id] = finishing;
          });
        }

        let viewMockVersionsCell = {
          column: '',
          value: '-'
        };

        let uploadCell = {};

        if (design.service === 'blank') {
          uploadCell = {
            column: '',
            roles: [
              'admin',
              'csr',
              'production-coordinator',
              'purchaser',
              'art-producer'
            ],
            value: '-'
          };
        } else {
          uploadCell = {
            column: '',
            roles: [
              'admin',
              'csr',
              'production-coordinator',
              'purchaser',
              'art-producer'
            ],
            value: {
              tag: 'custom-icon',
              str: '',
              click: actions.uploadMockPage,
              customClass:
                'ff-lg ff-cloud-upload has-text-grey'
            },
            smallText: true
          };
        }

        let sendMockCell = {
          column: 'Send',
          value: '  '
        };

        if (design.service === 'blank') {
          sendMockCell = {
            column: 'Send',
            value: '-'
          };
        }

        if (design.mocks && design.mocks.length) {
          // ETPM-1149: Due to the changes in a workflow of mocks upload/approve the best option should be to check the latest mock here.
          let is_latest_mock_approved =
            design.mocks[design.mocks.length - 1].status === APPROVED_STATUS;
          let is_latest_mock_sent =
            design.mocks[design.mocks.length - 1].is_sent;

          // If latest mock is sent and approved then replace Send button with Approved string.
          if (is_latest_mock_sent && is_latest_mock_approved) {
            sendMockCell = {
              column: 'Send',
              value:
                '<span class="grid-status-cell clr-success">Approved</span>',
              smallText: true
            };
          } else {
            // If latest mock is not approved and isn't sent then allow to send it.
            sendMockCell = {
              column: 'Send',
              roles: [
                'admin',
                'csr',
                'production-coordinator',
                'purchaser',
                'art-producer',
                'printer'
              ],
              value: {
                tag: 'a',
                str: 'Send mock',
                click: actions.sendMock
              },
              smallText: true
            };
            // If latest mock is not approved but is sent then allow to resend it.
            if (is_latest_mock_sent && !is_latest_mock_approved) {
              sendMockCell.value.textBeforeBtn = 'Sent';
              sendMockCell.value.str = 'Resend';
            }
          }

          viewMockVersionsCell = {
            column: '',
            roles: [
              'admin',
              'csr',
              'production-coordinator',
              'purchaser',
              'art-producer',
              'printer',
              'customer'
            ],
            value: {
              tag: 'button',
              str: design.mocks.length,
              click: actions.showMockVersionsPopup,
              customClass:
                'button button-circled is-primary has-text-weight-bold'
            },
            smallText: true
          };
        }

        let path = design.original_files_link;
        let filesLink = `<a href="${path}" target="_blank" class="ff-lg ff-link has-text-primary"></a>`;

        return [
          { column: 'id', value: design.id },
          viewMockVersionsCell,
          uploadCell,
          sendMockCell,
          {
            column: 'Files',
            roles: [
              'admin',
              'csr',
              'production-coordinator',
              'purchaser',
              'art-producer',
              'printer'
            ],
            value: path ? filesLink : '-',
            smallText: true
          }
        ];
      });
    }
    actionsPhase.data.push([
      { column: 'id', value: order.id },
      { column: '', value: '-' },
      { column: '', value: '-' },
      { column: '', value: '-' }
    ]);

    if (!Object.keys(orderFinishings).length) return;

    // prepare finishings
    actionsPhase.finishings[order.id] = Object.keys(orderFinishings).map(
      key => {
        const finishing = orderFinishings[key];
        const path = finishing.original_files_link;
        const filesLink = `<a href="${path}" target="_blank" class="ff-lg ff-link has-text-primary"></a>`;

        return [
          { column: 'id', value: finishing.id },
          { column: '', value: '-' },
          { column: '', value: '-' },
          { column: '', value: '-' },
          {
            column: 'Files',
            roles: [
              'admin',
              'csr',
              'production-coordinator',
              'purchaser',
              'art-producer',
              'printer'
            ],
            value: path ? filesLink : '-',
            smallText: true
          }
        ];
      }
    );
  });

  return actionsPhase;
};

export const getActionsPhaseP = (orders, actions, data) => {
  let actionsPhase = {
    columns: [
      { head: 'PRINTER ACTIONS', subhead: 'Files' },
      { head: '', subhead: 'Mocks' },
      { head: '', subhead: 'Shipping' },
      { head: '', subhead: 'Status' }
    ],
    data: [],
    details: {},
    finishings: {}
  };

  orders.forEach(order => {
    let orderFinishings = {};
    if (order.designs) {
      actionsPhase.details[order.id] = order.designs.map(design => {
        if (design && design.designable && design.designable.finishings) {
          design.designable.finishings.forEach(finishing => {
            orderFinishings[finishing.id] = finishing;
          });
        }

        // ETPM-1149: Due to the changes in a workflow of mocks upload/approve the best option should be to check the latest mock here.
        let is_latest_mock_approved =
          design.mocks.length &&
          design.mocks[design.mocks.length - 1].status === APPROVED_STATUS;

        let path = design.print_ready_files_link;
        let filesLink = `<a href="${path}" target="_blank" class="button btn is-ghost ff-link icon__bigger"></a>`;

        // Filter shippings and add tracking button if it can be added.
        let addTrackingBtn = '-';
        let designShippings =
          (order.shippings &&
            order.shippings.length &&
            order.shippings.filter(
              s =>
                (s.shipping_items &&
                  s.shipping_items.length &&
                  s.shipping_items.find(si => +si.design_id === +design.id)) ||
                false
            )) ||
          [];
        if (designShippings.length && designShippings.length > 0) {
          addTrackingBtn = {
            tag: 'a',
            str: 'Add',
            click: actions.trackingPopup
          };
        }

        return [
          { column: 'id', value: design.id },
          {
            column: 'Files',
            roles: [
              'admin',
              'csr',
              'production-coordinator',
              'purchaser',
              'art-producer',
              'printer'
            ],
            value: path ? filesLink : '-',
            smallText: true
          },
          {
            column: 'Mocks',
            roles: [
              'admin',
              'csr',
              'production-coordinator',
              'purchaser',
              'art-producer',
              'printer',
              'customer'
            ],
            value: is_latest_mock_approved
              ? '<a href="/#/revision/' +
                order.id +
                '/' +
                design.id +
                '">View</a>'
              : 'N/A',
            smallText: true
          },
          {
            column: 'Shipping',
            roles: [
              'admin',
              'csr',
              'production-coordinator',
              'purchaser',
              'art-producer',
              'printer'
            ],
            value: addTrackingBtn,
            smallText: true
          },
          {
            column: 'Status',
            rolesAct: [
              'admin',
              'csr',
              'production-coordinator',
              'purchaser',
              'art-producer',
              'printer'
            ],
            value: {
              tag: 'select',
              options: data.designPrintStatuses,
              value: design.printer_status,
              change: actions.statusChangePopup
            },
            smallText: true
          }
        ];
      });
    }
    actionsPhase.data.push([
      { column: 'id', value: order.id },
      { column: '', value: '-' },
      { column: '', value: '-' },
      { column: '', value: '-' },
      { column: '', value: '-' }
    ]);

    if (!Object.keys(orderFinishings).length) return;

    // prepare finishings
    actionsPhase.finishings[order.id] = Object.keys(orderFinishings).map(
      key => {
        let finishing = orderFinishings[key];
        let path = finishing.print_ready_files_link;
        let filesLink = `<a href="${path}" target="_blank" class="button btn is-ghost ff-link icon icon__bigger"></a>`;

        return [
          { column: 'id', value: finishing.id },
          {
            column: 'Files',
            roles: [
              'admin',
              'csr',
              'production-coordinator',
              'purchaser',
              'art-producer',
              'printer'
            ],
            value: path ? filesLink : '-',
            smallText: true
          },
          { column: '', value: '-' },
          { column: '', value: '-' },
          {
            column: 'Status',
            rolesAct: [
              'admin',
              'csr',
              'production-coordinator',
              'purchaser',
              'art-producer',
              'printer'
            ],
            value: {
              tag: 'select',
              options: data.finishingPrintStatuses,
              value: orderFinishings[key].printer_status,
              change: actions.statusChangePopup
            },
            smallText: true
          }
        ];
      }
    );
  });

  return actionsPhase;
};

export const getActionsPhaseSh = (orders, actions) => {
  let actionsPhase = {
    columns: [{ head: 'SHIPPING ACTIONS', subhead: 'Track Shipping' }],
    data: [],
    details: {},
    finishings: {}
  };

  orders.forEach(order => {
    let orderFinishings = {};
    if (order.designs) {
      actionsPhase.details[order.id] = order.designs.map(design => {
        if (design && design.designable && design.designable.finishings) {
          design.designable.finishings.forEach(finishing => {
            orderFinishings[finishing.id] = finishing;
          });
        }
        return [
          { column: 'id', value: design.id },
          {
            column: 'Track Shipping',
            value:
              design.trackings.length ||
              design.shippings_trackings_number.length
                ? {
                    tag: 'button',
                    click: actions.showTrackShippingPopup,
                    str: '',
                    customClass: 'button btn is-ghost ff-pin-map'
                  }
                : '-'
          }
        ];
      });
    }
    actionsPhase.data.push([
      { column: 'id', value: order.id },
      { column: '', value: '-' },
      { column: '', value: '-' }
    ]);

    if (!Object.keys(orderFinishings).length) return;

    // prepare finishings
    actionsPhase.finishings[order.id] = Object.keys(orderFinishings).map(
      key => {
        return [
          { column: 'id', value: orderFinishings[key].id },
          { column: '', value: '-' },
          { column: '', value: '-' }
        ];
      }
    );
  });

  return actionsPhase;
};

export const getActionsPhaseF = (orders, actions, data) => {
  let actionsPhase = {
    columns: [
      { head: 'FOLLOW-UP ACTIONS', subhead: 'Client Followup' },
      { head: '', subhead: 'Close Job' }
    ],
    data: [],
    details: {},
    finishings: {}
  };

  orders.forEach(order => {
    const orderFinishings = {};
    if (order.designs) {
      actionsPhase.details[order.id] = order.designs.map(design => {
        if (design && design.designable && design.designable.finishings) {
          design.designable.finishings.forEach(finishing => {
            orderFinishings[finishing.id] = finishing;
          });
        }
        return [
          { column: 'id', value: design.id },
          {
            column: 'Status',
            roles: [
              'admin',
              'csr',
              'production-coordinator',
              'purchaser',
              'art-producer',
              'printer'
            ],
            rolesAct: [
              'admin',
              'csr',
              'production-coordinator',
              'purchaser',
              'art-producer'
            ],
            value: {
              tag: 'select',
              options: data.orderFollowUpStatuses,
              value: design.followup_status,
              change: actions.changeFollowStatus
            },
            smallText: true
          },
          { column: '', value: '-' }
        ];
      });
      actionsPhase.data.push([
        { column: 'id', value: order.id },
        { column: '', value: '-' },
        {
          column: '',
          roles: [
            'admin',
            'csr',
            'production-coordinator',
            'purchaser',
            'art-producer'
          ],
          value: {
            tag: 'button-with-icon',
            disabled:
              order.designs.some(
                design => design.followup_status !== FOLLOWUP_STATUS_APPROVED
              ) || order.survey_sent_at,
            click: actions.sendSurvey,
            btnColor: 'is-ghost',
            str: '',
            customClass:
              'ff-lg ff-email-plane',
            tooltip: order.survey_sent_at ? 'Send Survey Again' : null,
            tooltipPos: 'left'
          }
        }
      ]);
    }
    if (!Object.keys(orderFinishings).length) return;

    // prepare finishings
    actionsPhase.finishings[order.id] = Object.keys(orderFinishings).map(
      key => {
        return [
          { column: 'id', value: orderFinishings[key].id },
          { column: '', value: '-' },
          { column: '', value: '-' }
        ];
      }
    );
  });

  return actionsPhase;
};

export const orderModel = {
  id: null,
  csr_id: '',
  contact: {
    account_id: null,
    email: '',
    phone: '',

    contact_firstname: '',
    contact_lastname: '',

    recipient_company: ''
  },

  address: {
    recipient_firstname: '',
    recipient_lastname: '',

    payment_term: 'request-payment',
    shipping_cost: 0,

    s_mailing_address: '',
    s_city: '',
    s_country_id: '',
    s_state_id: '',
    s_state: '',
    s_postal_code: '',

    b_mailing_address: '',
    b_city: '',
    b_country_id: '',
    b_state_id: '',
    b_state: '',
    b_postal_code: ''
  }
};
