<template>
  <grid :orders="gridData"
    :designs="gridOrderDetails"
    :finishings="gridOrderFinishings"
    :columns="gridHeaders"
    :actionColumns="actions.columns"
    :forceExpanded="expanded"
    :loading="ordersLoading"
    :stickyHeader="stickyHeader"
    :assignButton="assignButton"
    noResMsg="No orders"
    @onGridSortBy="gridUpdateOrders"></grid>
</template>

<script>
import Vue from 'vue';
import popup from '../../modules/popupper';
import alertify from 'alertify.js';
import gridComponent from '../common/grid/grid.vue';
import managePrintersPopupComponent from '../orders/popups/manage-printers.vue';
import redoDesignPopupComponent from '../orders/popups/redo-design.vue';
import shippingTrackingPopupComponent from '../orders/popups/shipping-tracking.vue';
import manageFilesPopupComponent from '../dashboard/popups/manage-files.vue';
import shippingTrackingListPopupComponent from './popups/shipping-tracking-list.vue';


import {
  gridHeaders,
  getGridData,
  getGridOrderDetails,
  getGridOrderFinishings,
  getActionsPhaseS,
  getActionsPhaseB,
  getActionsPhaseM,
  getActionsPhaseP,
  getActionsPhaseSh,
  getActionsPhaseF
} from '@/helpers/orders';

import gridHelper from '../../helpers/grid-helper';
import apiService from '../../services/api.service';

export default {
  name: 'orders-grid',
  props: [
    'orders',
    'expanded',
    'assignButton',
    'ordersLoading',
    'ordersPhase',
    'getDesignProperty',
    'showVersionsButton',
    'designPrinterCallback',
    'orderFollowUpStatuses',
    'mockPopupCallback',
    'designPrintStatuses',
    'finishingPrintStatuses',
    'isActivePhase',
    'stickyHeader',
    'colorizeArchivedLinks'
  ],
  computed: {
    gridHeaders() {
      return gridHeaders(
        this.setPhaseAction,
        this.isActivePhaseAction,
        this.phaseForGrid
      );
    },
    gridData() {
      return {
        data: getGridData(this.orders, {
          colorizeArchivedOrderLinks: this.colorizeArchivedLinks
        }),
        actions: this.actions.data
      };
    },
    gridOrderDetails() {
      return {
        data: getGridOrderDetails(this.orders),
        actions: this.actions.details
      };
    },
    gridOrderFinishings() {
      return {
        data: getGridOrderFinishings(this.orders),
        actions: this.actions.finishings
      };
    },
    actions() {
      switch (this.ordersPhase) {
        case 'S':
          return getActionsPhaseS(this.orders, {
            assignDesignPrinterPopup: this.assignDesignPrinterPopupAction,
            assignAllDesignPrinterPopup: this.assignAllDesignPrinterPopupAction,
            assignOrderPrinterPopup: this.assignOrderPrinterPopupAction,
            filesPopup: this.filesPopupAction,
            changePaymentStatus: this.changePaymentStatusAction
          }, this.assignButton);
        case 'B':
          return getActionsPhaseB(this.orders, {
            changeBlankStatus: this.changeBlankStatusAction
          });
        case 'M':
          return getActionsPhaseM(this.orders, {
            sendMock: this.sendMock,
            showMockVersionsPopup: this.showMockVersionsPopupAction,
            uploadMockPage: this.uploadMockActionClick,
            filesPopup: this.filesPopupAction
          });
        case 'P':
          return getActionsPhaseP(
            this.orders,
            {
              trackingPopup: this.shipTrackingPopupAction,
              statusChangePopup: this.printerStatusChangePopupAction
            },
            {
              designPrintStatuses: this.designPrintStatuses,
              finishingPrintStatuses: this.finishingPrintStatuses
            }
          );
        case 'Sh':
          return getActionsPhaseSh(this.orders, {
            showTrackShippingPopup: this.showTrackShippingPopup
          });
        case 'F':
          return getActionsPhaseF(
            this.orders,
            {
              sendSurvey: this.sendSurveyAction,
              changeFollowStatus: this.changeFollowStatusAction.bind(this)
            },
            {
              orderFollowUpStatuses: this.orderFollowUpStatuses
            }
          );
      }
    },
    phaseForGrid() {
      return this.orders[0] === undefined
        ? false
        : this.orders.length > 1
          ? 'no-specific-phases'
          : gridHelper.phasesStateForOrder(this.orders[0].designs);
    }
  },
  components: {
    grid: gridComponent
  },
  methods: {
    setPhaseAction(phase) {
      this.$emit('setPhase', phase);
    },

    isActivePhaseAction(phase) {
      return this.ordersPhase === phase;
    },

    sendSurveyAction(orderId) {
      // request is added for case with different states
      apiService
        .sendSurvey(orderId)
        .then(data => {
          alertify.success('The survey was sent to the customer');
          const order = this.orders.filter(o => o.id === orderId);
          this.$emit('sendSurveyCallback', orderId);
        })
        .catch(err => {
          alertify.error(err.body.error);
        });
    },

    changeFollowStatusAction(designId, orderId, status) {
      if (this.orderFollowUpStatuses[status] === 'Redo') {
        popup({
          title: 'Redo design',
          additional: {
            orderId: orderId,
            designId: designId,
            status: status,
            designName: this.getDesignProperty(orderId, designId, 'name'),
            callback: ({ cancel, newDesign }) => {
              const order = this.orders.filter(o => o.id === orderId);
              const design = order[0].designs.filter(d => d.id === designId)[0];

              if (cancel) {
                status = design.followup_status;
              }

              this.$emit('changeFollowUpStatus', {
                designId,
                orderId,
                status,
                design,
                newDesign
              });
            }
          },
          submitLabel: 'Send',
          bodyComponent: redoDesignPopupComponent,
          showButtons: true,
          runCallback: true
        });
      } else {
        this.sendFollowUpStatus({ designId, orderId, status });
      }
    },

    // request is added for case with different states for different pages which use component
    sendFollowUpStatus({ designId, orderId, status }) {
      return apiService
        .changeFollowUpStatus({
          designId,
          status
        })
        .then(data => {
          alertify.success('Follow Up Status is updated');
          this.$emit('changeFollowUpStatus', {
            designId,
            orderId,
            status,
            design: data.design
          });
        });
    },

    // mock actions
    uploadMockActionClick(orderId, designId) {
      this.$router.push({
        name: 'Revision',
        params: { id: orderId, designId: designId, mockId: 'new' }
      });
    },

    showMockVersionsPopupAction(oId, dId) {
      const o = this.orders.find(({ id }) => id === oId);
      const d = o.designs.find(({ id }) => id === dId);
      const m = d.mocks.slice(0);
      const sortedMocks = m.sort((a, b) => a.version - b.version);
      this.$router.push({
        name: 'Revision',
        params: {
          id: oId,
          designId: dId,
          mockId: sortedMocks[sortedMocks.length - 1].id
        }
      });
    },

    sendMock(orderId, designId) {
      let mocks = this.getDesignProperty(orderId, designId, 'mocks');
      let latest = mocks[mocks.length - 1];
      apiService
        .sendMock(latest.id)
        .then(data => {
          alertify.success('The mock was sent to the customer');
          let sent = true;
          this.$emit('sendMockCallback', { orderId, designId, sent });
        })
        .catch(err => {
          alertify.error(err.body.error);
        });
    },

    // payment actions
    changePaymentStatusAction(orderId, status) {
      this.$emit('changePaymentStatus', { orderId, status });
    },

    changeBlankStatusAction(designId, orderId, status) {
      this.$emit('changeBlankStatus', { designId, status });
    },

    // files actions
    filesPopupAction(orderId, itemId, modelType) {
      popup({
        title: 'Manage Files',
        additional: {
          itemId: itemId,
          orderId: orderId,
          modelType: modelType,
          onUpdateFilesLinks: ({ model }) => {
            this.$emit('onChangeFilesLinks', {
              itemId,
              orderId,
              modelType,
              model
            });
          }
        },
        bodyComponent: manageFilesPopupComponent,
        showButtons: false
      });
    },

    showTrackShippingPopup(orderId, designId) {
      popup({
        title: 'Tracking Numbers',
        additional: {
          target: 'design',
          designId: designId
        },
        bodyComponent: shippingTrackingListPopupComponent,
        showButtons: false,
        modalCardLong: true
      });
    },

    // printer actions
    printerStatusChangePopupAction(itemId, orderId, status, modelType) {
      if (this.designPrintStatuses[status] === 'Shipped') {
        popup({
          title: 'Are you sure?',
          message:
            'Are you sure you want to set "Shipped status"? <br>' +
            'Please note, it cannot be changed once confirmed.',
          showButtons: true,
          submitLabel: 'Confirm',
          additional: {
            callback: () => {
              this.sendPrinterStatus({ itemId, orderId, status, modelType });
            },
            closeCallback: () => {
              const order = this.orders.filter(o => o.id === orderId);
              const design = order[0].designs.filter(d => d.id === itemId)[0];
              status = design.printer_status;

              this.$emit('changePrinterStatus', {
                itemId,
                orderId,
                status,
                modelType
              });
            }
          }
        });
      } else {
        this.sendPrinterStatus({ itemId, orderId, status, modelType });
      }
    },

    // request is added for case with different states for different pages which use component
    sendPrinterStatus({ itemId, orderId, status, modelType }) {
      return apiService
        .changePrinterStatus({ itemId, status, modelType })
        .then(data => {
          if (data.status == 'error') {
            alertify.error(data.message);
          } else {
            alertify.success(
              `Printer status for the ${modelType} ` +
                `changed to ${this[modelType + 'PrintStatuses'][status]}`
            );
          }
          this.$emit('changePrinterStatus', {
            itemId,
            orderId,
            status,
            modelType,
            model: data.model
          });
        })
        .catch(err => {
          alertify.error("Printer status can't be updated. Please try later.");
        });
    },

    // printer actions
    shipTrackingPopupAction(oId, dId) {
      popup({
        title: 'Print Shipping Label',
        additional: {
          orderId: oId,
          designId: dId,
          designName: this.getDesignProperty(oId, dId, 'name'),
          tracking_number: this.getDesignProperty(oId, dId, 'tracking_number')
        },
        bodyComponent: shippingTrackingPopupComponent,
        submitLabel: 'Add tracking',
        hideCancel: true,
        runCallback: true
      });
    },

    assignDesignPrinterPopupAction(oId, dId) {
      popup({
        title: 'Manage Printers',
        additional: {
          target: 'design',
          orderId: oId,
          designId: dId,
          designName: this.getDesignProperty(oId, dId, 'name'),
          assignedPrinterId: this.getDesignProperty(oId, dId, 'printer_id'),
          designPrinterCallback: this.designPrinterCallback
        },
        bodyComponent: managePrintersPopupComponent,
        showButtons: false,
        modalCardLong: true
      });
    },
    assignAllDesignPrinterPopupAction(oId) {
      popup({
        title: 'Manage Printers',
        additional: {
          target: 'design',
          orderId: oId,
          designPrinterCallback: this.designPrinterCallback
        },
        bodyComponent: managePrintersPopupComponent,
        showButtons: false,
        modalCardLong: true
      });
    },
    assignOrderPrinterPopupAction(orderId) {
      popup({
        title: 'Manage Printers',
        additional: {
          target: 'order',
          orderId: orderId
        },
        bodyComponent: managePrintersPopupComponent,
        modalCardLong: true
      });
    },
    gridUpdateOrders(sortField, sortDir) {
      this.$emit('onGridSortBy', sortField, sortDir);
    }
  }
};
</script>
