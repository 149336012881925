<template>
  <div class="page-content container section">
    <section id="customer-view">
      <router-view></router-view>
    </section>
  </div>
</template>

<script>
import Vue from 'vue';
import VeeValidate from 'vee-validate';

Vue.use(VeeValidate);

export default {
  name: 'index-customer-view'
};
</script>
