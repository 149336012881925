<template>
  <!-- Customer Information -->
  <fieldset class="control">
    <!-- Shipping Address -->
    <fieldset class="shipping-address control">
      <div class="columns">
        <div class="column is-4">
          <form-select v-model="terms"
                       name="terms" label="Term"
                       :addEmpty="false"
                       scope="company-form"
                       :options="{all: paymentOptionsAll}"/>
        </div>
      </div>
      <h5 class="subtitle">Shipping Address
        <button
          v-if="company_id || (newCompanyModel && newCompanyModel.shipping_address)"
          class="button is-small"
          @click.prevent="copyCompanyAddress('shipping_address')">
          {{ 'Copy from company' }}
        </button>
      </h5>
      <div class="columns">
        <div class="column">
          <form-select v-model="shipping_address.country_code"
                       name="country_code" label="Country"
                       scope="company-form" :options="countriesOptions"
                       @change="getStates('shipping_address')"
                       rules="required"
                       :fieldsetName="fieldsetName ? `${fieldsetName}.shipping_address` : null"/>
        </div>
        <div class="column">
          <form-select v-if="statesCount && statesCount.shipping_address !== 0"
                       v-model="shipping_address.state_code"
                       scope="company-form"
                       :rules="'required|max:3'"
                       name="state_code" label="State/Province"
                       :options="statesOptions('shipping_address')"
                       :fieldsetName="fieldsetName ? `${fieldsetName}.shipping_address` : null"/>
          <form-input v-else v-model="shipping_address.state_code"
                      name="state_code" label="State/Province Code"
                      :rules="'required|max:3|uppercase'"
                      scope="company-form"
                      :fieldsetName="fieldsetName ? `${fieldsetName}.shipping_address` : null"/>
        </div>
        <div class="column">
          <form-input v-model="shipping_address.city"
                      name="city" label="City"
                      scope="company-form"
                      :fieldsetName="fieldsetName ? `${fieldsetName}.shipping_address` : null"/>
        </div>
      </div>
      <div class="columns">
        <div class="column is-5">
          <form-input v-model="shipping_address.address_line_1"
                      name="address_line_1"
                      label="Address line 1"
                      scope="company-form"
                      rules="required"
                      :fieldsetName="fieldsetName ? `${fieldsetName}.shipping_address` : null"/>
        </div>
        <div class="column is-5">
          <form-input v-model="shipping_address.address_line_2"
                      name="address_line_2"
                      label="Address line 2"
                      scope="company-form"
                      :required="false" rules=""
                      :fieldsetName="fieldsetName ? `${fieldsetName}.shipping_address` : null"/>
        </div>
        <div class="column is-2">
          <form-input v-model="shipping_address.postal_code"
                      name="postal_code" label="Postal Code"
                      scope="company-form"
                      :fieldsetName="fieldsetName ? `${fieldsetName}.shipping_address` : null"/>
        </div>
      </div>
    </fieldset>
    <div class="control flex flex__vertical-center">
      <div class="form-checkbox-container">
        <label>
          <input v-model="billing_address_same" type="checkbox"
                 class="checkbox">
          Billing Address Same as Shipping
        </label>
      </div>
    </div>
    <br>
    <!-- END Shipping Address -->

    <!-- Billing Address -->
    <fieldset class="shipping-address control">
      <h5 class="subtitle">Billing Address
        <button
          v-if="company_id || (newCompanyModel && newCompanyModel.billing_address)"
          class="button is-small"
          @click.prevent="copyCompanyAddress('billing_address')">
          {{ 'Copy from company' }}
        </button>
      </h5>
      <div class="columns">
        <div class="column">
          <form-input v-model="billing_address.address_line_1"
                      name="address_line_1"
                      label="Address line 1"
                      scope="company-form"
                      :fieldsetName="fieldsetName ? `${fieldsetName}.billing_address` : null"/>
        </div>
        <div class="column">
          <form-input v-model="billing_address.address_line_2"
                      name="address_line_2"
                      label="Address line 2"
                      scope="company-form"
                      :required="false" rules=""
                      :fieldsetName="fieldsetName ? `${fieldsetName}.billing_address` : null"/>
        </div>
      </div>
      <div class="columns">
        <div class="column">
          <form-select v-model="billing_address.country_code"
                       name="country_code" label="Country"
                       scope="company-form"
                       :options="countriesOptions"
                       @change="getStates('billing_address')"
                       :fieldsetName="fieldsetName ? `${fieldsetName}.billing_address` : null"/>
        </div>
        <div class="column">
          <form-select v-if="statesCount && statesCount.billing_address !== 0"
                       v-model="billing_address.state_code"
                       scope="company-form"
                       name="state_code"
                       :rules="'required|max:3'"
                       label="State/Province"
                       :options="statesOptions('billing_address')"
                       :fieldsetName="fieldsetName ? `${fieldsetName}.billing_address` : null"/>
          <form-input v-else v-model="billing_address.state_code"
                      name="state_code"
                      :rules="'required|max:3|uppercase'"
                      label="State/Province Code"
                      scope="company-form"
                      :fieldsetName="fieldsetName ? `${fieldsetName}.billing_address` : null"/>
        </div>
        <div class="column">
          <form-input v-model="billing_address.city"
                      name="city"
                      label="City"
                      scope="company-form"
                      :fieldsetName="fieldsetName ? `${fieldsetName}.billing_address` : null"/>
        </div>
        <div class="column">
          <form-input v-model="billing_address.postal_code"
                      name="postal_code"
                      label="Postal Code"
                      scope="company-form"
                      :fieldsetName="fieldsetName ? `${fieldsetName}.billing_address` : null"/>
        </div>
      </div>
    </fieldset>
    <!-- END Billing Address -->
    <div class="columns"
         v-roles="['admin','csr','production-coordinator','purchaser','art-producer','printer']">
      <div class="column">
        <form-textarea
          v-model="production_notes"
          name="production_notes"
          label="Production notes"
          scope="company-form"
          :required="false"
          rules=""
        ></form-textarea>
      </div>
    </div>
  </fieldset>
  <!-- END Customer Info-->
</template>

<script>
import Vue from 'vue';
import { mapGetters } from 'vuex';
import { merge } from 'lodash';
import apiService from '../../../../services/api.service';
import bus from '../../../../bus';

//form
import formInput from '../../../common/form/input';
import formSelect from '../../../common/form/select';
import formTextarea from '../../../common/form/textarea';
import Autocomplete from '../../../common/form/autocomplete';

let defaultAddress = {
  address_line_1: '',
  address_line_2: '',
  city: '',
  state_code: '',
  country_code: 'US',
  postal_code: ''
};

export default {
  name: 'customer-fields',
  components: {
    Autocomplete,
    formInput,
    formSelect,
    formTextarea
  },
  data() {
    return {
      billing_address_same: false,
      statesCount: {
        billing_address: 0,
        shipping_address: 0
      },
      states: {
        billing_address: [],
        shipping_address: []
      },
      terms: this.model.user.terms,
      production_notes: this.model.user.production_notes,
      company_id: this.model.user.company_id,
      billing_address: merge({}, defaultAddress, this.model.billing_address),
      shipping_address: merge({}, defaultAddress, this.model.shipping_address),
      companyOptionsAll: [],
      companyDetailsAll: []
    };
  },
  watch: {
    billing_address: {
      handler(address) {
        let account = { ...this.model };
        account.billing_address = address;
        this.$emit('accountChanged', account, this.formKey);
      },
      deep: true
    },
    shipping_address: {
      handler: function(address) {
        let account = { ...this.model };
        account.shipping_address = address;
        this.$emit('accountChanged', account, this.formKey);
      },
      deep: true
    },
    terms: {
      handler(terms) {
        let account = { ...this.model };
        account.user.terms = terms;
        this.$emit('accountChanged', account, this.formKey);
      },
      deep: true
    },
    production_notes: {
      handler(production_notes) {
        let account = { ...this.model };
        account.user.production_notes = production_notes;
        this.$emit('accountChanged', account, this.formKey);
      },
      deep: true
    },
    company_id: {
      handler(company_id) {
        let account = { ...this.model };
        account.user.company_id = company_id;
        this.$emit('accountChanged', account, this.formKey);
      },
      deep: true
    },
    billing_address_same(same) {
      if (same) {
        this.cloneAddress(this.shipping_address, 'billing_address');
      }
    }
  },
  props: {
    model: {
      type: Object
    },
    formKey: {
      type: Number,
      default: 0
    },
    newCompanyModel: {
      type: Object
    },
    options: {
      type: Object
    },
    fieldsetName: {
      type: String,
      default: ''
    }
  },
  computed: {
    ...mapGetters({
      countriesAll: 'countries',
      paymentOptionsAll: 'paymentTerms',
      countriesOptions: 'countriesOptions'
    })
  },
  methods: {
    copyCompanyAddress(addressType) {
      if (
        this.newCompanyModel != undefined &&
        this.newCompanyModel[addressType] != undefined
      ) {
        this.cloneAddress(this.newCompanyModel[addressType], addressType);
      }
    },
    getStates: function(field, callback) {
      if (!this[field].country_code) {
        this.statesCount[field] = 0;
        this.states[field] = [];
        this[field].state_code = '';
      } else {
        return apiService.getAccStates(this[field].country_code).then(data => {
          this.statesCount[field] = data.total;
          this.states[field] = data.list;
          this[field].state_code = '';

          if (callback) {
            callback();
          }
        });
      }
    },
    cloneAddress: function(clone, to) {
      this[to].address_line_1 = clone.address_line_1;
      this[to].address_line_2 = clone.address_line_2;
      this[to].city = clone.city;
      this[to].country_code = clone.country_code;
      this[to].postal_code = clone.postal_code;
      this.getStates(to, () => {
        this[to].state_code = clone.state_code;
      });
    },
    statesOptions: function(country) {
      return {
        all: this.states[country]
      };
    },
    onValidate(validateScope) {
      if (validateScope) {
        this.$validator.validateAll(validateScope).catch(err => {});
      } else {
        this.$validator.validateScopes().catch(err => {});
      }
    },
    onClear() {
      if (this.$validator.$scopes && this.errors) {
        Object.keys(this.$validator.$scopes).map(scope =>
          this.errors.clear(scope)
        );
      }
    }
  },
  created() {
    bus.$on('validate', this.onValidate);
    bus.$on('clear', this.onClear);

    this.$watch(
      () => this.errors.errors,
      value => {
        bus.$emit('errors-changed', value);
      }
    );
  },
  mounted() {
    if (this.billing_address.country_code) {
      this.getStates(
        'billing_address',
        function(state_code) {
          this.billing_address.state_code = state_code;
        }.bind(this, this.billing_address.state_code)
      );
    }

    if (this.shipping_address.country_code) {
      this.getStates(
        'shipping_address',
        function(state_code) {
          this.shipping_address.state_code = state_code;
        }.bind(this, this.shipping_address.state_code)
      );
    }
  },
  beforeDestroy() {
    let account = { ...this.model };
    delete account.shipping_address;
    delete account.billing_address;
    account.user.terms = 'request-payment';
    account.user.company_id = '';
    this.$emit('accountChanged', account, this.formKey);
  }
};
</script>
