<template>
  <div class="account-view-cnt">
    <header class="header">
      <router-link v-roles="['admin', 'csr', 'production-coordinator', 'purchaser', 'art-producer']"
                   :to="'/accounts'"><span class="back-char"><</span>Back to All Accounts
      </router-link>
      <h3 class="title without-margin">Account Details</h3>
    </header>

    <div class="box" :class="{'skeleton-block' :isLoading}">
      <div class="level">
        <div class="level-left company-title-cnt">
          <h4 class="title has-text-weight-bold without-margin user-name col">
            {{user.title}}</h4>
          <span class="term-lable"
                v-if="user.terms">{{user.terms === 'request-payment' ? 'No Terms' : user.terms_label}}</span>
        </div>

        <nav class="level-right">
          <p class="level-item" v-if="userId == loggedInUserId">
            <router-link class="button"
                        :to="'/accounts/' + userId + '/change-password'">
              Change Password
            </router-link>
          </p>
          <p class="level-item" v-roles="['admin']">
            <button class="button btn__delete" @click="deleteAccount">
              Delete Account
            </button>
          </p>
          <p class="level-item">
            <router-link class="button"
                        :to="'/accounts/' + userId + '/edit'">Edit
              Account Details
            </router-link>
          </p>
          <p class="level-item"
            v-roles="['admin', 'csr', 'production-coordinator', 'purchaser', 'art-producer']"
            v-if="isCustomer() && hasCompany()">
            <router-link
              class="button"
              :to="'/companies/' + user.company.id + '/custom-prices'"
            >View Custom Pricing</router-link>
          </p>
        </nav>
      </div>

      <!--General Data-->
      <div class="cols-flex address-cols company-content">
        <div class="col col_vertical">
          <h5 class="subtitle is-marginless has-text-weight-bold">Contact</h5>
          <div class="control">
            <p v-if="userName">{{ userName }}</p>
            <p v-if="user.phone">{{ user.phone }}</p>
            <p v-if="user.email">{{ user.email }}</p>
          </div>
        </div>
        <div class="col col_vertical" v-if="isCustomer()">
          <h5 class="subtitle is-marginless has-text-weight-bold">AvaTax info</h5>
          <div class="control">
            <p v-if="user.avatax_id">
              Customer ID: <b>{{ user.avatax_id }}</b>
            </p>
          </div>
        </div>

        <div class="col col_vertical" v-if="printer">
          <h5 class="subtitle is-marginless has-text-weight-bold">Address</h5>
          <div class="control">
            <p>{{printer.address_line_1}}
              <template v-if="printer.address_line_2">,
                {{printer.address_line_2}}
              </template>
            </p>
            <p>
              {{printer.city}},
              {{printer.state_code}}
              {{printer.location_zip_code}}
            </p>
            <p v-if="printer.country">{{printer.country.name}}</p>
          </div>
        </div>
      </div>

      <div class="company-content" v-if="printer">
        <h5 class="subtitle is-marginless has-text-weight-bold">Printer info</h5>
        <table class="table is-fullwidth">
          <thead>
          <tr>
            <th>Code</th>
            <th>Name</th>
            <th>Capacity</th>
            <th>Max points</th>
            <th>Setups</th>
            <th>Imps</th>
            <th>Avg Turn last 14 days</th>
            <th>Points</th>
            <th>Turnaround</th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td>{{printer.code}}</td>
            <td>{{printer.name}}</td>
            <td>{{printer.capacity}}</td>
            <td>{{printer.max_points}}</td>
            <td>{{printer.setups}}</td>
            <td>{{printer.impressions}}</td>
            <td>{{printer.avg_turn}}d</td>
            <td>{{printer.available_points}}</td>
            <td>{{printer.turnaround}}d</td>
          </tr>
          </tbody>
        </table>
      </div>

      <div class="company-content" v-if="user.production_notes"
          v-roles="['admin','csr','production-coordinator','purchaser','art-producer','printer']">
        <h5 class="subtitle is-marginless has-text-weight-bold">Production notes</h5>
        <div class="control content-pre">
          <div>{{user.production_notes}}</div>
        </div>
      </div>

      <div class="company-content" v-if="user.company">
        <h5 class="subtitle is-marginless has-text-weight-bold">Company</h5>
        <div class="control company-link">
          <router-link class="button" :to="`/companies/${user.company.id}/details`">
            {{user.company.name}}
          </router-link>
        </div>
      </div>

      <table class="table is-fullwidth" v-if="ordersGridData.length">
        <thead>
        <tr>
          <th>Order ID</th>
          <th>Order Date</th>
          <th>Ship By</th>
          <th>Qty.</th>
          <th v-roles="['admin', 'csr', 'production-coordinator', 'purchaser', 'art-producer', 'customer']">Total</th>
          <th>Status</th>
          <th colspan="5"></th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(entry, rowKey) in ordersGridData">
          <th>{{entry.id}}</th>
          <td>{{entry.order_date}}</td>
          <td>{{entry.ship_by_date}}</td>
          <td>{{entry.quantity | itemsNumberFormat}}</td>
          <td v-roles="['admin', 'csr', 'production-coordinator', 'purchaser', 'art-producer', 'customer']">{{getEntryTotal(entry) | price}}</td>
          <td>{{entry.custom_grid_status}}</td>
          <td>
            <template v-if="entry.need_payment">
              <router-link class="button"
                          :to="`/orders/${entry.id}/invoices`">
                Make Payment
              </router-link>
            </template>
          </td>
          <td>
            <template v-if="getMockLink(entry)">
              <router-link class="button" :to="getMockLink(entry)">
                View/Approve Mocks
              </router-link>
            </template>
          </td>
          <td class="details-col">
            <router-link class="button" v-if="canSeeDetails()"
                        :to="`/orders/${entry.id}/details`">
              Details
            </router-link>
            <router-link class="button" v-else
                        :to="`/customer/orders/${entry.id}/details`">
              Details
            </router-link>
          </td>
          <td>
            <span @click="openMessenger(entry.id, rowKey)" class="icon is-medium">
              <i class="ff-2x ff-chatbubbles has-text-link is-clickable"></i>
            </span>
            <span v-if="uM(entry.id).length"
                  :class="msgTagClass(entry.id)">{{uM(entry.id).length}}</span>
          </td>
        </tr>
        </tbody>
      </table>

      <paginator v-if="ordersGridData"
                :pagesNumber="ordersPagesNumber"
                :prevPageUrl="ordersPrevPageUrl"
                :nextPageUrl="ordersNextPageUrl"
                :currentPage="ordersCurrentPage"
                :getListItems="getAccountDetails">
      </paginator>
    </div>
<!--    <div v-else>Loading...</div>-->
  </div>
</template>

<style lang="scss" scoped>
.details-col {
  position: relative;
  text-align: right;
}
.account-view-cnt{
  .header{
    .title{
      padding-bottom: 2rem;
      padding-top: 2rem;
    }
  }
}
</style>

<script>
import Vue from 'vue';
import alertify from 'alertify.js';

import { forEach, isEmpty, cloneDeep } from 'lodash';
import notify from '../../modules/notifier/index';
import { checkRoles } from '../../router/checkRoles';
import apiService from '../../services/api.service';
import paginator from '../common/paginator';
import { openMessenger, isMentionedMe } from '../../helpers/messages';

import { APPROVED_STATUS, PENDING_STATUS } from '../../helpers/revision';

const DATE_FORMAT = 'MM/DD/YY';

export default {
  name: 'accounts-view',

  data() {
    return {
      user: [],
      billing_address: null,
      shipping_address: null,
      printer: null,
      ordersGridData: [],
      ordersPrevPageUrl: null,
      ordersNextPageUrl: null,
      ordersCurrentPage: 1,
      ordersPagesNumber: 1,
      isLoading: false
    };
  },
  components: {
    paginator
  },
  watch: {
    $route(to, from) {
      this.loadAccountData();
    }
  },
  computed: {
    userId() {
      return this.$route.params.userId;
    },
    userName() {
      return this.user.first_name + ' ' + this.user.last_name;
    },
    loggedInUserId() {
      return this.$store.getters.userProfile.id;
    }
  },
  created() {
    this.loadAccountData();
  },
  methods: {
    openMessenger: openMessenger,
    uM(id) {
      return this.$store.getters.unreadOrdersMessages[id]
        ? this.$store.getters.unreadOrdersMessages[id]
        : [];
    },
    msgTagClass(id) {
      return {
        'tag msg-tag is-warning is-small': true,
        'is-danger': isMentionedMe(this.uM(id))
      };
    },
    hasMocks(order) {
      return (
        order.designs.length &&
        order.designs.some(design => design.mocks && design.mocks.length)
      );
    },
    isCustomer() {
      let is_customer = false;
      if (this.user && this.user.roles) {
        is_customer = this.user.roles.filter(r => r.name == 'customer')[0];
      }

      return is_customer;
    },
    hasCompany() {
      return  this.user.company && this.user.company.id;
    },
    canSeeDetails() {
      return checkRoles(this.$store.getters.roles, [
        'admin',
        'csr',
        'production-coordinator',
        'purchaser',
        'art-producer'
      ]);
    },
    deleteAccount() {
      alertify
        .okBtn('Delete')
        .confirm('Are you sure that you want to delete the account?', ev => {
          return apiService.deleteAccount(this.userId).then(data => {
            if (data.status == 'success') {
              this.$router.push('/accounts');
              alertify.success(data.message);
            } else {
              alertify.error(data.message);
            }
          });
        });
    },
    getAccountDetails: function(page, url) {
      return apiService.getAccount(this.userId, page, url).then(
        response => {
          this.user = response.user;
          this.printer = response.user.printer;
          if (response.user.billing_addresses.length > 0) {
            this.billing_address = response.user.billing_addresses[0];
          }
          if (response.user.shipping_addresses.length > 0) {
            this.shipping_address = response.user.shipping_addresses[0];
          }

          if (!checkRoles(this.$store.getters.roles, ['customer'], true)) {
            this.ordersGridData = response.orders.data;
            this.ordersPrevPageUrl = response.orders.prev_page_url;
            this.ordersNextPageUrl = response.orders.next_page_url;

            this.ordersCurrentPage = response.orders.current_page;
            this.ordersPagesNumber = response.orders.last_page;
          }
        },
        response => {
          let msg = response.body
            ? response.body.message
            : 'Could not load account details';
          alertify.error(msg);
          this.$router.push('/');
        }
      );
    },
    getMockLink(order) {
      let link = '';
      let design = order.designs.find(design => {
        if (design.mocks && design.mocks.length) {
          return design.mocks.find(m => {
            return (
              +m.status === +PENDING_STATUS || +m.status === +APPROVED_STATUS
            );
          });
        }
      });
      if (design && design.mocks) {
        let mocks = cloneDeep(design.mocks);
        let mock = mocks.reverse().find(m => {
          return (
            +m.status === +PENDING_STATUS || +m.status === +APPROVED_STATUS
          );
        });
        if (mock && mock.design_id) {
          link = `/revision/${order.id}/${mock.design_id}/${mock.id}`;
        }
      }
      return link;
    },
    loadAccountData() {
      if (
        this.userId != this.$store.getters.userProfile.id &&
        !checkRoles(this.$store.getters.roles, [
          'admin',
          'csr',
          'production-coordinator',
          'purchaser',
          'art-producer'
        ])
      ) {
        alertify.error("You're not allowed to see others users accounts");
        this.$router.push('/');
      } else {
        this.isLoading = true;
        this.getAccountDetails().then(() => {
          this.isLoading = false;
        });
      }
    },
    getEntryTotal(entry) {
      const estimate = JSON.parse(entry.estimate);
      return estimate
        ? +estimate.total.price +
            +(estimate.total.upcharge ? estimate.total.upcharge : 0)
        : entry.total;
    }
  }
};
</script>
