<template>
  <div class="page-content">
    <section class="orders-report">
      <div class="columns">
        <div class="column is-4 input-cntr">
          <label class="label" for="date_from" style="margin-bottom: 0">From</label>
          <div class="control">
            <datepicker
              v-model="filters.date_from"
              :config="{dateFormat: 'm/d/Y', wrap: true }"
              id="date_from"
            />
          </div>
        </div>
        <div class="column is-4 input-cntr">
          <label class="label" for="date_to" style="margin-bottom: 0">To</label>
          <div class="control">
            <datepicker
              v-model="filters.date_to"
              :config="{dateFormat: 'm/d/Y', wrap: true }"
              id="date_to"
            />
          </div>
        </div>
      </div>

      <div class="progress" style="margin-top: 10px; margin-bottom: 10px">
        <div class="determinate" :style='`width: ${progress}%`'></div>
      </div>
      <div class="row" v-if="message">
        <div class="col s6 offset-s3 m6 offset-m3">
          <div class="card-panel">
            <h5 class="center-align">{ message != null ? message : ''}</h5>
          </div>
        </div>
      </div>
    </section>
    <button @click="run()" :disabled="inProgress" class="button is-pulled-right">
      Export Orders Report
    </button>
  </div>
</template>
<script>
import apiService from '../../services/api.service';
import alertify from 'alertify.js';
import Datepicker from '../../modules/datepicker/index';
import {downloadReport} from '@/helpers/reports/reports';

export default {
  name: 'orders-report',
  data() {
    return {
      cancel: false,
      inProgress: false,
      message: '',
      filters: {
        page: 1,
        date_to: '',
        date_from: '',
        timezone: ''
      },
      data: [],
      json_fields: [
        {
          label: 'Order Number',
          value: 'order_number'
        },
        {
          label: 'Company Name',
          value: 'company_name'
        },
        {
          label: 'CSR',
          value: 'csr_name'
        },
        {
          label: 'Mocks',
          value: 'mocks_name'
        },
        {
          label: 'Printer Assigned',
          value: 'printer'
        },
        {
          label: 'Date (Order Created)',
          value: 'in_date'
        },
        {
          label: 'Date (Order Placed)',
          value: 'estimate_becomes_to_order_at'
        },
        {
          label: 'Date (Mock Approved)',
          value: 'mock_approved_at'
        },
        {
          label: 'EVENT JOB: Ship by date',
          value: 'ship_by_date'
        },
        {
          label: 'EVENT JOB: In hands date',
          value: 'in_hands_date'
        },
        {
          label: 'Shipped date',
          value: 'shipped_date'
        },
        {
          label: 'Service type',
          value: 'service_type'
        },
        {
          label: 'Sub category',
          value: 'sub_category'
        },
        {
          label: 'Design Name',
          value: 'design_name'
        },
        {
          label: 'Brand',
          value: 'brand'
        },
        {
          label: 'Model #',
          value: 'model'
        },
        {
          label: 'Color',
          value: 'color'
        },
        {
          label: 'XXS',
          value: 'xxs_group'
        },
        {
          label: 'XS',
          value: 'xs_group'
        },
        {
          label: 'S\n' + '3/6\n' + '2T',
          value: 's_group'
        },
        {
          label: 'M\n' + '6/12\n' + '3T',
          value: 'm_group'
        },
        {
          label: 'L\n' + '12/18\n' + '4T',
          value: 'l_group'
        },
        {
          label: 'XL\n' + '18/24\n' + '5T',
          value: 'xl_group'
        },
        {
          label: '2XL',
          value: 'two_xl_group'
        },
        {
          label: '3XL',
          value: 'three_xl_group'
        },
        {
          label: '4XL',
          value: 'four_xl_group'
        },
        {
          label: '5XL',
          value: 'five_xl_group'
        },
        {
          label: '6XL\n' + '(Hats)',
          value: 'six_xl_group'
        },
        {
          label: 'ONE SIZE',
          value: 'one_size_group'
        },
        {
          label: 'Other sizes',
          value: 'other_sizes_group'
        },
        {
          label: 'Garment Total',
          value: 'sizing_total'
        },
        {
          label: 'CHARGED PRICE',
          value: 'charged_price'
        },
        {
          label: 'Impressions/Qty',
          value: 'impressions_number'
        },
        {
          label: 'Print Location',
          value: 'location_name'
        },
        {
          label: 'Color Count',
          value: 'color_count'
        },
        {
          label: 'Ink Type',
          value: 'ink_type'
        },
        {
          label: 'Stitch Count',
          value: 'stitches'
        },
        {
          label: 'Printed Tags',
          value: 'finishable_1'
        },
        {
          label: 'Heat Pressed Tags',
          value: 'finishable_2'
        },
        /*{
              label: 'Finishing Type 3',
              value: 'finishable_3'
          },*/
        {
          label: 'Hem Tags',
          value: 'finishable_4'
        },
        {
          label: 'Sewn in Neck Tags',
          value: 'finishable_5'
        },
        {
          label: '4 Sided Tags',
          value: 'finishable_6'
        },
        /*{
              label: 'Finishing Type 7',
              value: 'finishable_7'
          },*/
        {
          label: 'Folding & Bagging',
          value: 'finishable_8'
        },
        {
          label: 'Folding Only',
          value: 'finishable_9'
        },
        {
          label: 'Hang Tags',
          value: 'finishable_10'
        },
        {
          label: 'Folding & Bagging (Fleece)',
          value: 'finishable_11'
        },
        {
          label: 'Folding Only (Fleece)',
          value: 'finishable_12'
        },
        {
          label: 'Sticker Attachment',
          value: 'finishable_13'
        },
        {
          label: 'Size (for third party items)',
          value: 'size'
        },
        {
          label: 'Shape (for third party items)',
          value: 'shape'
        },
        {
          label: 'Paper (for third party items)',
          value: 'paper'
        },
        {
          label: 'Paper Color (for third party items)',
          value: 'paper_color'
        },
        {
          label: 'Quantity (for third party items)',
          value: 'quantity'
        },
        {
          label: 'Delivery type',
          value: 'type_of_delivery'
        },
        {
          label: 'Shipping Cost',
          value: 'shipping_cost'
        }
      ],
      progress: 0,
      downloadName: 'orders'
    };
  },
  components: {
    Datepicker
  },
  created() {
    this.filters.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  },
  methods: {
    run() {
      if (!this.cancel) {
        this.$set(this, 'inProgress', true);
        apiService
          .getReport('production_and_purchasing', this.filters)
          .then(res => {
            this.$set(this, 'progress', +res.to / +res.total * 100);
            if (res.data.length) {
              this.$set(this, 'data', [...this.data, ...res.data]);
            }
            if (+res.to >= +res.total) {
              this.$set(this, 'inProgress', false);
              this.download();
            } else {
              this.$set(this.filters, 'page', res.current_page + 1);
              this.run();
            }
          })
          .catch(err => {
            console.log(err);
            this.$set(this.filters, 'page', 1);
            this.$set(this, 'inProgress', false);
            alertify.error(err.body.message);
          });
      }
    },
    download() {
      downloadReport(this.data, this.json_fields,
        `${this.downloadName}_${this.filters.date_from}_${this.filters.date_to}.csv`
      );

      this.$set(this.filters, 'page', 1);
      this.$set(this, 'progress', 0);
      this.$set(this, 'data', []);
    }
  },
  mounted() {
  },
  beforeDestroy() {
    this.$set(this, 'cancel', true);
  }
};
</script>
<style scoped>
.progress {
  background: aliceblue;
}

.determinate {
  background: #1992d1;
  height: 12px;
}
</style>
