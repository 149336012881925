<template>

</template>

<script>
export default {
  name: 'design-info-sticker',
  props: {
    design: {
      type: Object,
      required: true
    },
    order: {
      type: Object,
      required: true
    },
    mock: {
      type: Object,
      required: true
    },
    options: {
      type: Object,
      required: true
    },
    editable: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      mock_item: {
        location: '',
        width: 0,
        height: 0,
        location_comment:
          this.mock &&
          this.mock.items &&
          this.mock.items[0] &&
          this.mock.items[0].location_comment
            ? cloneDeep(this.mock.items[0].location_comment)
            : ''
      }
    };
  },
  computed: {
    shape_label() {
      let label = 'N/A';

      if (this.design.designable.item.shape_object) {
        label = this.design.designable.item.shape_object.name;

        if (this.design.designable.item.parent_shape_object) {
          label =
            this.design.designable.item.parent_shape_object.name + ': ' + label;
        }
      }
      return label;
    },
    size_label() {
      return this.design.designable.item.size_object
        ? this.design.designable.item.size_object.name
        : 'N/A';
    },
    quantity_label() {
      return this.design.designable.item.quantity_object
        ? this.design.designable.item.quantity_object.name
        : 'N/A';
    }
  },
  methods: {
    getFormData() {
      return [this.mock_item];
    }
  }
};
</script>
