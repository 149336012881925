<template>
  <div class="item-shipping">
    <header class="step-section__subheader">
      <h4 class="step-section__subheader__title">Shipping {{ index + 1 }}</h4>
        <form-checkbox
          v-show="showDefaultCompanyCheckbox"
          :required="isDefaultCompanyCheckboxRequired"
          :showErrors="showDefaultCompanyCheckbox"
          :name="'default_company_address'"
          :rules="`${isDefaultCompanyCheckboxRequired ? 'required' : ''}`"
          :scope="scope"
          v-model="isDefaultCompanyAddress" :static="false"
        >Use as default company address</form-checkbox>
        <a href="#" class="clr-destructive"
          @click.prevent="onDelete"
          v-if="qty > 1"
        >Delete Shipping {{ index + 1 }}</a>
    </header>
    <div class="step-section__body">
      <form-row class="fixed-3">
        <svg slot="icon" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
          <path d="M20 8h-3V4H3c-1.1 0-2 .9-2 2v11h2c0 1.66 1.34 3 3 3s3-1.34 3-3h6c0 1.66 1.34 3 3 3s3-1.34 3-3h2v-5l-3-4zM6 18.5c-.83 0-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5 1.5.67 1.5 1.5-.67 1.5-1.5 1.5zm13.5-9l1.96 2.5H17V9.5h2.5zm-1.5 9c-.83 0-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5 1.5.67 1.5 1.5-.67 1.5-1.5 1.5z"/>
        </svg>

        <!-- Delivery Type -->
        <form-multiselect
          label="Delivery Type"
          :required="true"
          v-model="deliveryTypeModel"
          :options="deliveryTypes"
          :config="{
            trackBy: 'key'
          }"
          :scope="scope"
          name="deliveryType"
        ></form-multiselect>

        <!-- Shipping Method -->
        <form-multiselect
          label="Shipping Method"
          :required="true"
          v-model="shippingMethodModel"
          :options="shippingOptions"
          name="shippingMethod"
          :scope="scope"
          :config="{
            trackBy: 'key'
          }"
          v-if="value.common.type_of_delivery === 'shipping'"
        ></form-multiselect>

        <form-item
          label="Customer Account Number"
          :required="true"
          name="customerAccountNumber"
          :scope="scope"
          v-if="value.common.shipping_method === 'customer_account'"
          v-model="value.common.customer_account_number"
        ></form-item>

        <!-- Predefined Address -->
        <form-multiselect
          label="Threadbird Address"
          :required="true"
          v-model="threadbirdAddressModel"
          :options="threadbirdAddressOptions"
          :config="{
            customLabel: ({ address }) => `${address.city}, ${address.state_id} (${address.postal_code})`
          }"
          name="threadbirdAddress"
          :scope="scope"
          v-if="['pickup', 'fulfillment'].includes(value.common.type_of_delivery) && threadbirdAddressOptions.length > 1"
        ></form-multiselect>
      </form-row>

      <form-row>
        <svg slot="icon" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
          <path d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z"/>
        </svg>

        <!-- Recipient -->
        <form-item
          label="Company Name/Recipient Name"
          :required="true"
          v-model="value.specific.address.recipient"
          name="recipient"
          :scope="scope"
        ></form-item>

        <!-- Shipping Address 1 -->
        <form-item
          label="Shipping Address"
          :required="true"
          v-model="value.specific.address.address_1"
          name="shippingAddress"
          :scope="scope"
          :static="['pickup', 'fulfillment'].includes(value.common.type_of_delivery)"
          class="x2"
        ></form-item>

      </form-row>

      <form-row>
        <span slot="icon"></span>

        <!-- Shipping Address 2 -->
        <form-item
          label="Shipping Address 2 (optional)"
          v-model="value.specific.address.address_2"
          name="shippingAddress2"
          :scope="scope"
          :static="['pickup', 'fulfillment'].includes(value.common.type_of_delivery)"
        ></form-item>
      </form-row>

      <form-row :gap="8">
        <span slot="icon"></span>

        <!-- City -->
        <form-item
          label="City"
          :required="true"
          name="city"
          :scope="scope"
          v-model="value.specific.address.city"
          :static="['pickup', 'fulfillment'].includes(value.common.type_of_delivery)"
        ></form-item>

        <!-- State / Province -->
        <form-multiselect
          v-model="stateModel"
          :options="states.options"
          :isLoading="states.isLoading"
          :scope="scope"
          name="states"
          label="State/Province"
          :placeholder="countryModel ? 'Please, select...' : 'Please, select country at first'"
          :required="true"
          :config="{
            trackBy: 'key'
          }"
          :disabled="!countryModel"
          v-if="value.common.type_of_delivery === 'shipping'"
        ></form-multiselect>
        <form-item
          :value="(stateModel && stateModel.label) || value.specific.address.state_id"
          :static="true"
          name="state"
          label="State/Province"
          :required="true"
          v-if="['pickup', 'fulfillment'].includes(value.common.type_of_delivery)"
        ></form-item>

        <!-- Postal Code -->
        <form-item
          label="Postal Code"
          :required="true"
          name="postalCode"
          :scope="scope"
          v-model="value.specific.address.postal_code"
          :validateEvent="'none'"
          :rules="{
            'async_postal_code': {
              stateCode: value.specific.address.state_id,
              countryCode: value.specific.address.country_id
            }
          }"
          :static="['pickup', 'fulfillment'].includes(value.common.type_of_delivery)"
        ></form-item>

        <form-multiselect
          v-model="countryModel"
          name="country"
          label="Country"
          :required="true"
          :options="countries"
          :scope="scope"
          :config="{
            label: 'name',
            trackBy: 'country_id'
          }"
          @input="onCountryChange"
          v-if="value.common.type_of_delivery === 'shipping'"
        ></form-multiselect>
        <form-item
          v-model="countryModel.name"
          :static="true"
          name="country"
          label="Country"
          :required="true"
          v-if="['pickup', 'fulfillment'].includes(value.common.type_of_delivery)"
        ></form-item>

      </form-row>

      <form-row>
        <svg slot="icon" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
          <path d="M7 14c-1.66 0-3 1.34-3 3 0 1.31-1.16 2-2 2 .92 1.22 2.49 2 4 2 2.21 0 4-1.79 4-4 0-1.66-1.34-3-3-3zm13.71-9.37l-1.34-1.34c-.39-.39-1.02-.39-1.41 0L9 12.25 11.75 15l8.96-8.96c.39-.39.39-1.02 0-1.41z"/>
        </svg>
        <div class="form-item">

          <form-row v-if="parentForm.shippings.length > 1 && value.specific.items.length > 0">
            <div class="form-item">
              <ul class="list-delivered-designs">
                <li
                  v-for="item, index in value.specific.items"
                  :key="`${item.design_id}${item.garment_id ? `-${item.garment_id}` : ''}`"
                  class="list-delivered-designs__item delivered-design"
                >
                  <div class="delivered-design__header">
                    <h3 class="delivered-design__title">Design {{ getShippingItem(item).index + 1 }} / {{ getShippingItem(item).common.name }}</h3>
                    <a href="#" @click.prevent="removeDesign(index)" class="delivered-design__delete">
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                        <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"/>
                      </svg>
                    </a>
                  </div>
                  <div class="delivered-design__info">
                    <template v-if="item.garment_id">{{ getGarmentLabel(getShippingItem(item).garment) }}</template>
                    <template v-else>{{ getShippingItem(item).common.service }}</template>
                  </div>
                  <div class="delivered-design__sizes" v-if="item.sizes">
                    <div class="garment-sizes">
                      <div class="form-row garment-sizes__body">
                        <form-item
                          class="garment-sizes__item"
                          v-for="sizeKey in sortedGarmentsSizes"
                          v-if="item.sizes[sizeKey] !== undefined"
                          :key="`${item.garment_id}-${sizeKey}`"
                          :scope="scope"
                          v-model.number="item.sizes[sizeKey]"
                          :label="sizeKey | uppercase"
                          :name="`${item.garment_id}-${sizeKey}`"
                          :id="`${item.garment_id}-${sizeKey}`"
                          :selectAllOnFocus="true"
                          :showErrors="false"
                          rules="numeric"
                          @keypress="onSizeKeyPress"
                          @keyup="onSizeKeyUp($event, sizeKey, item)"
                          @blur="onSizeBlur($event, sizeKey, item.sizes)"
                        ></form-item>
                      </div>
                      <div class="garment-sizes__totals" style="margin-top: 10px;">
                        <p>Total Qty:</p>
                        <p class="garment-sizes__totals__total">
                          <strong class="clr-primary1">{{ Object.values(item.sizes).reduce((acc, qty) => acc + +qty, 0) }}</strong>
                        </p>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </form-row>

          <form-row class="fixed-5">
            <form-multiselect
              class="dropdown-split-shipping x2"
              :placeholder="false"
              name="deliveredDesigns"
              v-model="relatedDesignsModel"
              :scope="scope"
              :options="deliveredDesignsOptions"
              label="Delivered Designs"
              :hideLabel="parentForm.shippings.length > 1 && value.specific.items.length > 0"
              :required="true"
              :config="{
                searchable: false,
                multiple: true,
                trackBy: 'id',
                allowEmpty: true
              }"
              v-if="parentForm.shippings.length > 1"
              @select="onDesignSelect"
            >
              <div slot="option" slot-scope="{ option }" class="dropdown-designs__option">
                <header class="dropdown-designs__option__header">
                  <h5 class="dropdown-designs__option__title">
                    Design {{ option.index + 1 }} | {{ option.common.name }}
                  </h5>
                  <div class="dropdown-designs__option__sizes" v-if="option.garment">
                    <svg width="12px" height="12px" viewBox="0 0 13 13" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                      <path d="M2.93533664,11.0588499 L2.93533664,4.82316222 L2.47395253,5.6052742 L2.47435623,5.60549997 C2.42042441,5.69692713 2.30096927,5.72825251 2.20755354,5.67546968 C2.20225745,5.67247824 2.19717281,5.66927044 2.19228041,5.6658839 L0.0984494978,4.48276053 L0.195345922,4.31774207 L0.0976902248,4.48328739 C0.00427327354,4.43050433 -0.0277338694,4.31359361 0.0261977099,4.22216765 C0.0292542599,4.21698437 0.0325414972,4.21200803 0.0359921124,4.20721983 L1.97789029,0.915380607 L1.97735203,0.914637449 C2.00164101,0.873462758 2.0392135,0.844479691 2.08171668,0.829880038 L4.13294773,0.0143070316 L4.13286123,0.0141000764 C4.21973256,-0.0204333048 4.31717683,0.0106382799 4.36783084,0.0835899999 L6.25000035,2.55199752 L8.13722599,0.0769474947 L8.13710104,0.0768628312 C8.1934933,0.00290450872 8.29412871,-0.0197005579 8.37629988,0.0179653141 L10.4267424,0.833237258 L10.4270115,0.833105559 C10.4749165,0.852145416 10.5108936,0.887694766 10.5309245,0.930308588 L12.4730717,4.22257791 L12.4738022,4.222164 C12.527734,4.31359115 12.4957266,4.43050187 12.4023097,4.48328374 C12.3970232,4.48627519 12.3916406,4.48897501 12.3862003,4.49143025 L10.2920227,5.67475194 L10.2924456,5.67546688 C10.1990287,5.72824995 10.0795735,5.69692435 10.0256429,5.60549717 C10.0234034,5.60169672 10.0213272,5.59783984 10.0193761,5.59396413 L9.56466184,4.82316113 L9.56466184,11.0588488 C9.56466184,11.1644147 9.47721385,11.25 9.36935045,11.25 L3.13064067,11.25 C3.02277727,11.25 2.93532928,11.1644147 2.93532928,11.0588488 L2.93533664,11.0588499 Z"></path>
                    </svg>
                    <span v-html="getGarmentSizesLabel(option)"></span>
                  </div>
                </header>
                <div class="dropdown-designs__option__info">
                  <template v-if="option.garment_id">{{ option.garment.model.apparel_brand.name }} {{ option.garment.model.number }} {{ option.garment.model.name }} ({{option.garment.color}})</template>
                  <template v-else>{{ option.common.service }}</template>
                </div>
              </div>
              <template slot="noOptions">All designs are attached to shippings</template>
              <span class="multiselect__single" slot="selection">Add Design to Shipping {{ index + 1 }}</span>
            </form-multiselect>
            <form-item
              label="Delivered Designs"
              value="All"
              :static="true"
              v-else
            ></form-item>
          </form-row>
        </div>
      </form-row>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import alertify from 'alertify.js';
import { uniqueId, omit, flatten } from 'lodash';
import moment from 'moment';
import { FORM_ESTIMATE_TEMPLATES } from '@/helpers/estimates';

export default {
  name: 'fieldset-shipping',
  props: {
    shippingItems: {
      type: Array,
      required: true
    },
    parentForm: {
      type: Object,
      required: true
    },
    shippingAddress: {
      type: Object
    },
    loading: {
      default: false,
      type: Boolean
    },
    value: {
      required: true,
      type: Object
    },
    scope: {
      required: true,
      type: String
    },
    index: {
      required: true,
      type: Number
    },
    qty: {
      required: true,
      type: Number
    },
    shippingOptions: {
      required: true,
      type: Array
    },
    shippingDefault: {
      required: true,
      type: String
    }
  },
  data() {
    return {
      isDirty: false,
      countries: [],
      states: {
        options: [],
        isLoading: false
      }
    };
  },
  computed: {
    ...mapGetters(['deliveryTypes', 'orderCountries', 'sortedGarmentsSizes']),

    shippedItems() {
      return flatten(
        this.parentForm.shippings.map(shipping => {
          return shipping.specific.items;
        })
      );
    },
    threadbirdAddressOptions() {
      if (!this.deliveryTypeModel) return [];
      if (this.deliveryTypeModel.addresses.length === 0) return [];
      return this.deliveryTypeModel.addresses.map(address => ({
        ...address,
        id: uniqueId('threadbird-address_')
      }));
    },
    threadbirdAddressOptionDefault() {
      return this.threadbirdAddressOptions.find(
        option => option.default === true
      );
    },
    threadbirdAddressModel: {
      get() {
        if (
          !['pickup', 'fulfillment'].includes(
            this.value.common.type_of_delivery
          )
        )
          return null;
        return this.threadbirdAddressOptions.find(({ address }) => {
          return this.value.specific.address.city === address.city;
        });
      },
      set(val) {
        if (!val) return;
        const { address } = val;
        this.value.specific.address.address_1 = address.address_1;
        this.value.specific.address.address_2 = address.address_2 || '';
        this.value.specific.address.address_3 = address.address_3 || '';
        this.value.specific.address.city = address.city;
        this.value.specific.address.country_id = address.country_id;
        this.value.specific.address.postal_code = address.postal_code;
        this.value.specific.address.state_id = address.state_id;
      }
    },
    deliveredDesignsOptions() {
      return this.shippingItems.filter(shippingItem => {
        if (shippingItem.garment_id) {
          const sizesQty = Object.values(shippingItem.garment.sizes).reduce(
            (total, qty) => total + +qty,
            0
          );
          const shippedSizesQty = this.shippedItems
            .filter(item => item.garment_id === shippingItem.garment_id)
            .map(item => item.sizes)
            .reduce((total, itemSizes) => {
              return (
                total +
                Object.values(itemSizes).reduce(
                  (sizesTotal, sizeQty) => sizesTotal + +sizeQty,
                  0
                )
              );
            }, 0);

          const isNotAdded =
            this.value.specific.items.findIndex(
              item => shippingItem.garment_id === item.garment_id
            ) === -1;

          if (isNotAdded) {
            return shippedSizesQty < sizesQty;
          }
          return false;
        }
        const isAdded = !!this.parentForm.shippings.find(shipping =>
          shipping.specific.items.find(
            item => item.design_id === shippingItem.design_id
          )
        );
        return !isAdded;
      });
    },

    relatedDesignsModel: {
      get() {
        if (this.value.specific.items === null) return null;
        if (this.value.specific.items.length === 0) return null;

        // at least 1 design without garments is assigned
        if (
          this.value.specific.items.find(item => item.garment_id === undefined)
        )
          return true;

        // TODO check if at least one size item added
        return true;
      },
      set() {}
    },
    deliveredDesignsTotal() {
      return this.parentForm.shippings.reduce((acc, shipping) => {
        return acc.concat(shipping.specific.items);
      }, []);
    },
    relatedDesignsOptions() {
      return this.parentForm.designs.filter(
        design => !this.deliveredDesignsTotal.includes(design.common.uniqueId)
      );
    },
    countryModel: {
      get() {
        if (this.value.specific.address.country_id === null) return null;
        return (
          this.countries.find(
            country =>
              country.country_id === this.value.specific.address.country_id
          ) || null
        );
      },
      set(val) {
        this.value.specific.address.country_id = val ? val.country_id : null;
      }
    },
    stateModel: {
      get() {
        if (this.value.specific.address.state_id === null) return null;
        return (
          this.states.options.find(
            ({ key }) => key === this.value.specific.address.state_id
          ) || null
        );
      },
      set(val) {
        this.value.specific.address.state_id = val ? val.key : null;
      }
    },
    deliveryTypeModel: {
      get() {
        if (this.value.common.type_of_delivery === null) return null;
        return (
          this.deliveryTypes.find(
            ({ key }) => key === this.value.common.type_of_delivery
          ) || null
        );
      },
      set(val) {
        if (!this.isDirty) {
          this.value.common.type_of_delivery = val ? val.key : null;
          return;
        }
        this.$mx_confirmModal()
          .then(() => {
            this.value.common.type_of_delivery = val ? val.key : null;
          })
          .catch(() => {});
      }
    },
    shippingMethodModel: {
      get() {
        if (this.value.common.shipping_method === null) return null;
        return (
          this.shippingOptions.find(
            ({ key }) => key === this.value.common.shipping_method
          ) || null
        );
      },
      set(val) {
        this.value.common.shipping_method = val ? val.key : null;
      }
    },
    isCompanyAddressExists() {
      return !!this.shippingAddress;
    },
    isDefaultCompanyAddress: {
      get() {
        if (this.value.common.default_company_address === null) return false;
        return !!this.value.common.default_company_address;
      },
      set(val) {
        this.value.common.default_company_address = val ? val : false;
      }
    },
    showDefaultCompanyCheckbox() {
      return (
        this.value.common.type_of_delivery === 'shipping' &&
        (this.parentForm.shippings.length > 1 &&
          !this.isCompanyAddressExists &&
          !this.parentForm.shippings.find(
            s =>
              s.common &&
              s.common.type_of_delivery === 'shipping' &&
              s.common.default_company_address &&
              s.common.uniqueId !== this.value.common.uniqueId
          ))
      );
    },
    isDefaultCompanyCheckboxRequired() {
      return this.showDefaultCompanyCheckbox;
    }
  },
  watch: {
    shippingDefault(val, prevVal) {
      if (prevVal) return;
      this.value.common.shipping_method = this.shippingDefault;
    },
    'value.common.type_of_delivery': {
      handler(val) {
        this.prefillAddressFields(val);
        this.value.common.shipping_method =
          val === 'shipping' ? this.shippingDefault : null;
        this.value.common.default_company_address =
          val === 'shipping' &&
          (this.showDefaultCompanyCheckbox ||
            (this.parentForm.shippings.length === 1 &&
              !this.isCompanyAddressExists));

        this.$nextTick(() => {
          this.isDirty = false;
        });
      },
      immediate: false
    },
    'parentForm.shippings': function(val) {
      this.value.common.default_company_address =
        this.showDefaultCompanyCheckbox ||
        (this.parentForm.shippings.length === 1 &&
          !this.isCompanyAddressExists);
    }
  },
  created() {
    this.init();
  },
  methods: {
    getGarmentSizesLabel({ garment_id, garment }) {
      const garmentQtyTotal = Object.values(garment.sizes).reduce(
        (total, qty) => total + +qty,
        0
      );
      const addedGarmentQty = flatten(
        this.parentForm.shippings.map(shipping => shipping.specific.items)
      )
        .filter(item => item.garment_id === garment_id)
        .reduce(
          (total, item) =>
            total +
            Object.values(item.sizes).reduce(
              (itemTotal, sizeQty) => itemTotal + +sizeQty,
              0
            ),
          0
        );

      const notAddedGarmentQty = garmentQtyTotal - addedGarmentQty;
      let result = notAddedGarmentQty;
      if (notAddedGarmentQty < garmentQtyTotal) result += `/${garmentQtyTotal}`;
      // else result += `<em>/${garmentQtyTotal}</em>`;
      return result;
    },
    getGarmentLabel(garment) {
      return `${garment.model.apparel_brand.name} ${garment.model.number} ${
        garment.model.name
      } (${garment.color})`;
    },
    onSizeBlur(event, sizeKey, sizes) {
      const value = event.target.value;
      if (value === '') {
        event.target.value = 0;
        sizes[sizeKey] = 0;
        return;
      }
      if (isNaN(parseFloat(value))) {
        event.target.value = 0;
        sizes[sizeKey] = 0;
        return;
      }
      event.target.value = (+value).toString();
      sizes[sizeKey] = (+value).toString();
    },
    onSizeKeyUp(event, sizeKey, item) {
      const { design_id, garment_id, sizes } = item;

      const garment = this.parentForm.designs
        .find(design => design.common.uniqueId === design_id)
        .specific.garments.find(garment => garment.uniqueId === garment_id);
      const maxSizeQty = +garment.sizes[sizeKey];
      let alreadyAssignedQty = 0;

      this.parentForm.shippings.forEach(shipping => {
        if (shipping.common.uniqueId === this.value.common.uniqueId) return;
        shipping.specific.items.forEach(item => {
          if (item.garment_id !== garment_id) return;
          alreadyAssignedQty += +item.sizes[sizeKey];
        });
      });

      const maxAvailableQty = maxSizeQty - alreadyAssignedQty;
      const value = event.target.value;

      if (value === '') {
        event.target.value = 0;
        sizes[sizeKey] = 0;
        return;
      }

      if (value > maxAvailableQty) {
        event.target.value = maxAvailableQty > 0 ? maxAvailableQty : 0;
        sizes[sizeKey] = maxAvailableQty > 0 ? maxAvailableQty : 0;
        return;
      }
    },
    onSizeKeyPress(event) {
      const key = event.key;
      if (!/^\d*$/.test(key)) {
        event.preventDefault();
      }
    },
    getShippingItem({ design_id, garment_id }) {
      return this.shippingItems.find(shippingItem => {
        if (garment_id) return shippingItem.garment_id === garment_id;
        return shippingItem.design_id === design_id;
      });
    },
    onDesignSelect(design) {
      const item = { design_id: design.design_id };
      if (design.garment_id) {
        item.garment_id = design.garment_id;
        item.sizes = {};
        const alreadySelectedSizes = {};

        this.parentForm.shippings.forEach(shipping => {
          const garment = shipping.specific.items.find(
            item => item.garment_id === design.garment_id
          );
          if (!garment) return;
          Object.keys(garment.sizes).forEach(sizeKey => {
            if (!alreadySelectedSizes[sizeKey])
              alreadySelectedSizes[sizeKey] = 0;
            alreadySelectedSizes[sizeKey] += +garment.sizes[sizeKey];
          });
        });

        Object.keys(design.garment.sizes).forEach(sizeKey => {
          if (+design.garment.sizes[sizeKey] === 0) return;
          item.sizes[sizeKey] =
            design.garment.sizes[sizeKey] -
            (alreadySelectedSizes[sizeKey] || 0);
        });
      }
      this.value.specific.items.push(item);
    },
    removeDesign(index) {
      this.value.specific.items.splice(index, 1);
    },
    getRelatedProductLabel(design) {
      let label = design.common.name;

      const itemsQty = design.specific.garments.reduce(
        (total, garment) =>
          total +
          Object.keys(garment.sizes).reduce(
            (sizesTotal, sizeKey) => sizesTotal + +garment.sizes[sizeKey],
            0
          ),
        0
      );

      label += ` - ${itemsQty} items`;

      return label;
    },
    init() {
      this.value.common.default_company_address =
        this.showDefaultCompanyCheckbox ||
        (this.parentForm.shippings.length === 1 &&
          !this.isCompanyAddressExists);

      if (this.value.specific.address.country_id) {
        this.getStatesList(this.value.specific.address.country_id);
      }

      this.setCountries();
      this.$watch(
        'value',
        () => {
          if (this.value.common.type_of_delivery === 'shipping') {
            this.isDirty = true;
          }
        },
        { deep: true }
      );
      this.checkQuantities();
    },
    prefillAddressFields(deliveryType) {
      switch (deliveryType) {
        case 'shipping':
          this.value.specific.address.recipient = this.parentForm.account.company;

          if (this.shippingAddress) {
            this.value.specific.address.address_1 = this.shippingAddress.address_line_1;
            this.value.specific.address.address_2 = this.shippingAddress.address_line_2;
            this.value.specific.address.city = this.shippingAddress.city;
            this.value.specific.address.country_id = this.shippingAddress.country_code;
            this.value.specific.address.postal_code = this.shippingAddress.postal_code;
            this.value.specific.address.state_id = this.shippingAddress.state_code;
          } else {
            this.value.specific.address.address_1 = '';
            this.value.specific.address.address_2 = '';
            this.value.specific.address.city = '';
            this.value.specific.address.country_id = '';
            this.value.specific.address.postal_code = '';
            this.value.specific.address.state_id = '';
          }

          break;

        case 'fulfillment':
        case 'pickup':
          const defaultAddressOption = this.threadbirdAddressOptionDefault;
          const defaultAddress =
            defaultAddressOption && defaultAddressOption.address;

          if (defaultAddress) {
            this.value.specific.address.address_1 = defaultAddress.address_1;
            this.value.specific.address.address_2 =
              defaultAddress.address_2 || '';
            this.value.specific.address.address_3 =
              defaultAddress.address_3 || '';
            this.value.specific.address.city = defaultAddress.city;
            this.value.specific.address.postal_code =
              defaultAddress.postal_code;
            if (
              this.value.specific.address.country_id !==
              defaultAddress.country_id
            ) {
              this.getStatesList(defaultAddress.country_id).then(() => {
                this.value.specific.address.state_id = defaultAddress.state_id;
              });
            } else {
              this.value.specific.address.state_id = defaultAddress.state_id;
            }
            this.value.specific.address.country_id = defaultAddress.country_id;
          }

          break;
      }
    },

    onDelete() {
      if (!this.isDirty) {
        this.$emit('delete', this.index);
        return;
      }

      this.$mx_confirmModal()
        .then(() => {
          this.$emit('delete', this.index);
        })
        .catch(() => {});
    },
    onCountryChange({ country_id }) {
      this.stateModel = null;
      this.getStatesList(country_id);
    },
    getStatesList(countryId) {
      this.states.isLoading = true;
      return this.$apiService.getStates(countryId).then(({ list }) => {
        this.states.options = list.slice();
        this.states.isLoading = false;
      });
    },
    setCountries() {
      const primaryCountries = [];
      const secondaryCountries = [];
      this.orderCountries.forEach(country => {
        if (country.is_primary) primaryCountries.push(country);
        else secondaryCountries.push(country);
      });
      primaryCountries.push({
        id: 'divider',
        $isDisabled: true
      });
      this.countries = primaryCountries.concat(secondaryCountries);
    },
    checkQuantities() {
      this.value.specific.items.forEach(item => {
        if (item.sizes !== undefined) {
          Object.entries(item.sizes).forEach((size) => {
            const [sizeKey, sizeQuantity] = size;
            const garment = this.parentForm.designs
              .find(design => design.common.uniqueId === item.design_id)
              .specific.garments.find(garment => garment.uniqueId === item.garment_id);
            const maxSizeQty = +garment.sizes[sizeKey];
            let alreadyAssignedQty = 0;

            this.parentForm.shippings.forEach(shipping => {
              shipping.specific.items.forEach(shippingItem => {
                if (item.garment_id !== shippingItem.garment_id) return;
                alreadyAssignedQty += +shippingItem.sizes[sizeKey];
              });
            });
            const maxAvailableQty = maxSizeQty - alreadyAssignedQty;
            if (garment.uniqueId === item.garment_id && alreadyAssignedQty > maxSizeQty) {
              this.parentForm.shippings.forEach(shipping => {
                if (shipping.common.uniqueId !== this.value.common.uniqueId) return;
                shipping.specific.items.forEach(shippingItem => {
                  if (item.garment_id !== shippingItem.garment_id) return;
                  if (sizeQuantity - maxAvailableQty > 0) {
                    item.sizes[sizeKey] = +shippingItem.sizes[sizeKey] + maxAvailableQty;
                  } else {
                    item.sizes[sizeKey] = 0;
                  }
                });
              });
            }
          });
        }
      });
    }
  }
};
</script>
<style lang="scss">
@import '/resources/sass/base/helpers';

.delivered-design {
  transition: background 0.15s ease-in-out;
  margin-left: -5px;
  padding-left: 5px;
    & + & {
        margin-top: 10px;
    }
    &__info {
        color: rgba(0, 0, 0, 0.54);
        font-size: 12px;
        line-height: 16px;
        & + * {
            margin-top: 10px;
        }
    }
  &:hover {
    background: $clr-system4;
  }
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    & + * {
      margin-top: 10px;
    }
  }
  &__title {
    font-size: 14px;
    line-height: 24px;
  }
  &__delete {
    width: 24px;
    height: 24px;
    color: $clr-system3;
    &:hover {
      color: $clr-destructive;
    }
    svg {
      display: block;
      height: 100%;
      fill: currentColor;
      transition: all 0.15s ease-in-out;
    }
  }
}
.dropdown-split-shipping {
  .multiselect__tags {
    border-color: $clr-primary2;
  }
  .multiselect__single {
    color: $clr-primary2;
  }
}
.dropdown-designs {
  &__option {
    color: rgba(0, 0, 0, 0.54);
    font-size: 12px;
    line-height: 16px;
    &__header {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      font-size: 14px;
      & + * {
        margin-top: 4px;
      }
    }
    &__title {
      font-weight: bold;
      color: rgba(0, 0, 0, 0.87);
    }
    &__sizes {
      position: relative;
      font-size: 12px;
      padding-left: 14px;
      svg {
        position: absolute;
        fill: currentColor;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
      }
      // min-width: 14px + 33px;
      em {
        opacity: 0;
      }
    }
  }
}
</style>
