<template>
  <div class="finishing">
    <h5 class="title is-5 is-marginless has-text-weight-bold">{{label}}</h5>
    <div>
      <strong>Name: </strong>
      {{item.finishing.name}}
    </div>
    <div>
      <strong>Number of Tag Setups: </strong>
      {{item.finishing.finishable.tag_setups_number | itemsNumberFormat}}
    </div>
    <div>
      <strong>Remove Manufacturer Tag: </strong>
      <template v-if="item.finishing.finishable.remove_manufacturer_tag">Yes</template>
      <template v-else>No</template>
    </div>
    <div v-if="typeof item.colors !== 'undefined'">
      <span v-for="(color, key) in item.colors">
        <span class="tag is-medium">
          <span class="color-box"
                :style="'background-color:#' + color.hex"> </span>
          <span>{{color.name}}</span>
        </span>
      </span>
      <div>
        <strong>Tag Width: </strong>
        {{ item.width ? `${+item.width}"` : '*' }}
      </div>
      <div>
        <strong>Tag Height: </strong>
        {{ item.height ? `${+item.height}"` : '*' }}
      </div>
    </div>
    <div v-else>
      <div>
        <strong>Tag Width: </strong>
        {{ item.finishing.finishable.tag_width ? `${+item.finishing.finishable.tag_width}"` : '*' }}
      </div>
      <div>
        <strong>Tag Height: </strong>
        {{ item.finishing.finishable.tag_height ? `${+item.finishing.finishable.tag_height}"` : '*' }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'tag-printing',
  props: {
    item: {
      type: Object,
      required: true
    },
    label: {
      type: String,
      required: true
    }
  }
};
</script>
