// Common Actions
export const LOGIN_USER = 'common/loginUser';
export const POST_REGISTER_LOGIN_USER = 'common/postRegisterLoginUser';
export const GET_USER_DATA = 'common/getUserData';
export const RESUBSCRIBE_PUBNUB = 'common/resubscribePubNub';
export const SUBSCRIBE_TO_ORDER = 'common/subscribeToOrder';

export const FORGOT_PASSWORD = 'common/forgotPassword';
export const RESET_PASSWORD = 'common/resetPassword';
export const CREATE_PASSWORD = 'common/createPassword';
export const CHANGE_PASSWORD = 'common/changePassword';

export const SET_S3_DOMAIN = 'common/setS3Domain';
export const GET_OPTIONS = 'common/getOptions';
export const GET_PRINTER_OPTIONS = 'common/getPrinterOptions';
export const GET_FINISHING_PRINTER_OPTIONS =
  'common/getFinishingPrinterOptions';
export const LOAD_PAYMENT_TERMS = 'common/loadOrderTerms';
export const GET_GARMENTS_SIZES = 'common/getSortedGarmentsSizes';
export const ADD_VALIDATION_PROMISE = 'common/addValidationPromise';
export const CLEAR_VALIDATION_PROMISES = 'common/clearValidationPromises';

// Revision Actions
export const REVISION_LOAD_ORDER = 'revision/loadOrder';
export const REVISION_LOAD_ORDER_OPTIONS = 'revision/loadOrderOptions';
export const REVISION_LOAD_MESSAGES = 'revision/loadMessages';
export const SET_ACTIVE_DESIGN = 'revision/setActiveDesign';
export const SET_ACTIVE_MOCK = 'revision/setActiveMock';
export const SET_ACTIVE_ATTACHMENT = 'revision/setActiveAttachment';
export const APPROVE_MOCK = 'revision/approveMock';
export const REQUEST_REVISIONS = 'revision/requestRevisions';

// Dashboard Actions
export const LOAD_MY_ORDERS = 'dashboard/loadMyOrders';
export const LOAD_ACTIVE_ORDERS = 'dashboard/loadActiveOrders';
export const EMPTY_GRID_DATA = 'dashboard/emptyGridData';
export const LOAD_UNCLAIMED_ESTIMATES = 'dashboard/loadUnclaimedEstimates';
export const LOAD_ESTIMATES = 'dashboard/loadEstimates';
export const LOAD_UNCLAIMED_BLANKS = 'dashboard/loadUnclaimedBlocks';
export const LOAD_UNCLAIMED_MOCKS = 'dashboard/loadUnclaimedMocks';
export const LOAD_MY_ESTIMATES = 'dashboard/loadMyEstimates';
export const CLAIM = 'dashboard/claim';
export const IS_VIEWED = 'dashboard/viewed';
export const SET_ORDER_STATUS = 'dashboard/setOrderStatus';
export const SET_ORDER_TERMS = 'dashboard/setOrderTerms';
export const SEND_ESTIMATE = 'dashboard/sendEstimate';
export const SET_MY_ORDERS_PHASE = 'dashboard/setMyOrdersPhase';
export const SET_ACTIVE_ORDERS_PHASE = 'dashboard/setActiveOrdersPhase';
export const SET_MY_ORDERS = 'dashboard/setMyOrders';
export const UPDATE_MY_ORDERS_PAYMENT_STATUS =
  'dashboard/updateMyOrdersPaymentStatus';
export const UPDATE_MY_DESIGNS_BLANK_STATUS =
  'dashboard/updateMyDesignsBlankStatus';
export const UPDATE_MY_ORDERS_DESIGN_PRINTER =
  'dashboard/updateMyOrdersDesignPrinter';
export const UPDATE_MY_ORDERS_DESIGN_FOLLOW_UP_STATUS =
  'dashboard/updateMyOrdersDesignFollowUpStatus';
export const UPDATE_MY_ORDERS_DESIGN_MOCKS_COUNT =
  'dashboard/updateMyOrdersDesignMocksCount';
export const UPDATE_MY_ORDERS_DESIGN_PRINTER_STATUS =
  'dashboard/updateMyOrdersDesignPrinterStatus';
export const UPDATE_MY_ORDERS_PRINTER_STATUS =
  'dashboard/updateMyOrdersPrinterStatus';
export const UPDATE_MY_ORDERS_DESIGN_SETUP_PROGRESS =
  'dashboard/updateMyOrdersDesignSetupProgress';
export const UPDATE_MY_ORDERS_DESIGN_BLANK_PROGRESS =
  'dashboard/updateMyOrdersDesignBlankProgress';
export const UPDATE_MY_ORDERS_DESIGN_MOCK_PROGRESS =
  'dashboard/updateMyOrdersDesignMockProgress';
export const UPDATE_MY_ORDERS_DESIGN_PRINT_PROGRESS =
  'dashboard/updateMyOrdersDesignPrintProgress';
export const UPDATE_MY_ORDERS_DESIGN_SHIPMENT_PROGRESS =
  'dashboard/updateMyOrdersDesignShipmentProgress';
export const UPDATE_MY_ORDERS_DESIGN_FINISH_PROGRESS =
  'dashboard/updateMyOrdersDesignFinishProgress';
export const UPDATE_MY_ORDERS_DESIGN_PROGRESS =
  'dashboard/updateMyOrdersDesignProgress';
export const UPDATE_MY_ORDERS_FINISHING_PROGRESS =
  'dashboard/updateMyOrdersFinishingProgress';
export const UPDATE_MY_ESTIMATES_FINISHING_PROGRESS =
  'dashboard/updateMyEstimatesFinishingProgress';
export const SET_MY_ORDERS_SURVEY_DATE = 'order-details/setMyOrdersSurveyDate';
export const SET_MY_ORDERS_MOCK_SENT_DATE = 'dashboard/setMyOrdersMockSentDate';
export const SET_ESTIMATE_IS_APPROVED = 'dashboard/setEstimatesIsApproved';
export const SET_ESTIMATE_SENT_DATE = 'dashboard/setEstimateSentDate';
export const UPDATE_MY_ESTIMATES_DESIGN_PROGRESS =
  'dashboard/updateMyEstimatesDesignProgress';

// Order Details Actions
export const LOAD_ORDER = 'order-details/loadOrder';
export const SET_ORDER_APPROVE_STATUS = 'order-details/setOrderApproveStatus';
export const LOAD_CUSTOMER_ORDER = 'order-details/loadCustomerOrder';
export const LOAD_ORDER_OPTIONS = 'order-details/loadOrderOptions';
export const LOAD_FOLLOW_UP_STATUSES = 'order-details/loadFollowUpStatuses';
export const SET_ORDER_PHASE = 'order-details/setOrderPhase';
export const UPDATE_ORDER_PAYMENT_STATUS =
  'order-details/updateOrderPaymentStatus';
export const UPDATE_PRODUCTION_NOTES =
  'order-details/updateOrderProductionNotes';
export const UPDATE_DESIGN_BLANK_STATUS =
  'order-details/updateDesignBlankStatus';
export const UPDATE_ORDER_DESIGN_PRINTER =
  'order-details/updateOrderDesignPrinter';
export const UPDATE_DESIGN_FOLLOW_UP_STATUS =
  'order-details/updateDesignFollowUpStatus';
export const SET_SURVEY_DATE = 'order-details/setSurveyDate';
export const SET_MOCK_DATE = 'order-details/setMockDate';
export const UPDATE_ORDER_PRINTER_STATUS =
  'order-details/updateOrderDesignPtinterStatus';
export const LOAD_ORDER_TO_EDIT = 'order-details/loadOrderToEdit';
export const UPDATE_ORDER_TO_EDIT = 'order-details/updateOrderToEdit';
export const LOAD_ORDER_SERVICES = 'order-details/loadOrderServices';
export const LOAD_UPS_SERVICES = 'order-details/loadUpsServices';
export const LOAD_EDIT_ORDER_INIT_DATA = 'order-details/loadEditOrderInitData';
export const LOAD_EDIT_PUBLIC_ESTIMATE_INIT_DATA =
  'order-details/loadEditPublicEstimateInitData';
export const LOAD_ORDERS_DESIGNS = 'order-details/loadOrdersDesigns';

export const LOAD_PMS_COLORS = 'order-details/loadPmsColors';
export const UPDATE_ORDER_DESIGN_SETUP_PROGRESS =
  'order-details/updateOrderDesignSetupProgress';
export const UPDATE_ORDER_DESIGN_BLANK_PROGRESS =
  'order-details/updateOrderDesignBlankProgress';
export const UPDATE_ORDER_DESIGN_MOCK_PROGRESS =
  'order-details/updateOrderDesignMockProgress';
export const UPDATE_ORDER_DESIGN_PRINT_PROGRESS =
  'order-details/updateOrderDesignPrintProgress';
export const UPDATE_ORDER_DESIGN_SHIPMENT_PROGRESS =
  'order-details/updateOrderDesignShipmentProgress';
export const UPDATE_ORDER_DESIGN_FINISH_PROGRESS =
  'order-details/updateOrderDesignFinishProgress';
export const UPDATE_ORDER_DESIGN_PROGRESS =
  'order-details/updateOrderDesignProgress';
export const UPDATE_ORDER_FINISHING_PROGRESS =
  'order-details/updateOrderFinishingProgress';
export const UNCLAIM = 'order-details/unclaim';
export const UPDATE_DESIGN_MOCK_STATUS = 'order-details/updateDesignMockStatus';
export const UPDATE_ORDER_DESIGN_PRINT_BY_AND_SHIP_BY_DATES =
  'order-details/updateOrderDesignPrintByAndShipByDates';
export const UPDATE_ORDER_PRINT_BY_AND_SHIP_BY_DATES =
  'order-details/updateOrderPrintByAndShipByDates';

// Messages Actions
export const LOAD_MESSAGES_BY_ORDER_ID = 'messages/loadMessagesByOrderID';
export const LOAD_UNREAD_MESSAGES = 'messages/loadUnreadMessages';
export const ADD_MESSAGES = 'messages/addMessages';
export const SET_PUBNUB_ENV = 'messages/setPubNubEnv';
export const SET_ORDER_MESSAGES = 'messages/setOrderMessages';
export const SET_UNREAD_MESSAGES = 'messages/setUnreadMessages';
export const SEND_MESSAGE = 'messages/sendMessage';
export const MARK_MESSAGE_AS_READ = 'messages/markMessageAsRead';

// orders Actions
export const LOAD_ORDERS = 'orders-summary/loadOrders';
export const SET_ORDERS_PHASE = 'orders-summary/setMyOrdersPhase';
export const LOAD_ORDERS_LIST_DESIGNS = 'orders-summary/loadOrdersDesigns';

// account Actions
export const LOAD_ACCOUNT = 'accounts/loadAccount';
export const CLEAR_ACCOUNT = 'accounts/clearAccount';
export const CREATE_ACCOUNT = 'accounts/createAccount';
export const SAVE_ACCOUNT = 'accounts/saveAccount';
export const LOAD_ACCOUNT_FORM = 'accounts/loadAccountForm';
export const LOAD_ROLES = 'accounts/loadRoles';

// Reports
export const LOAD_ORDERS_REPORTS_DATA = 'reports/loadOrdersReportsData';
export const LOAD_ESTIMATES_REPORT = 'reports/loadEstimatesReport';
