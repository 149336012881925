<template>
  <div class="page-content container">
    <nav class="level is-hidden-print">
      <!-- Left side -->
      <div class="level-left">
        <breadcrumbs :links="crumbLinks"/>
      </div>
      <div class="level-right">
        <button
          class="button is-small btn-with-margin"
          @click="printInvoice">Print invoice
        </button>
      </div>
    </nav>
    <div class="columns is-hidden-print">
      <div class="column" v-if="!isLoading">
        <div class="card is-fullwidth">
          <div class="card-content">
            <div class="content">
              <form v-if="!isDeclined"
                    @submit.prevent="submitForm"
                    data-vv-scope="form"
                    class="payment-form">
                <div class="columns is-hidden-print">
                  <div class="column is-2 is-offset-5 has-text-centered">
                    <label class="label"
                           :for="amount">
                      <span class="required">*</span>Amount
                    </label>
                    <div class="control  has-text-centered">
                      <input v-validate="{rules: 'required|between:0,'+ maxAmount, scope: 'form'}"
                             :class="{'input': true, 'is-danger': errors.has('form.amount')}"
                             type="number"
                             data-vv-name="amount"
                             v-model="amountToPay"
                             id="amount"
                             step="0.01"
                             min="0.01"
                             placeholder="Amount"/>
                      <span v-show="errors.has('form.amount')"
                            class="help is-danger is-visible">{{ errors.first('form.amount') }}</span>
                    </div>
                  </div>
                </div>
                <div class="columns is-hidden-print payment-cnt is-vcentered is-centered">
                  <div class="has-text-centered">
                    <router-link class="button authorize-btn"
                                 style="text-decoration: underline;"
                                 :to="`/customer/orders/${this.$route.params.id}/pay/${this.$route.params.hash}`">Pay
                      with... <img src="@/../images/icons/visa-mastercard.png" alt=""></router-link>

                  </div>
                  <div class="has-text-centered">
                    <span class="or-separator">or</span>
                  </div>
                  <div class="has-text-centered">
                    <button class="button paypall-btn"
                            type="submit"
                            @click="setPaymentMethod('paypal')">
                      Pay with...
                      <img src="@/../images/icons/paypal1.png" alt="">
                    </button>
                  </div>
                </div>
              </form>
              <form v-if="!isDeclined && authorize.form_action"
                    :action="authorize.form_action"
                    id="authorizeForm"
                    method="post">
                <input v-for="(value, name) in authorize.form_data"
                       type="hidden"
                       :name="name"
                       :value="value">
              </form>
              <div v-if="isDeclined" class="has-text-centered">The estimate is declined.</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <article class="invoice" v-if="!isLoading">
      <header class="invoice__header">
        <img class="is-visible-print" src="@/../images/logo.png" width="250px" alt="Threadbird">
        <div class="invoice__date is-visible-print" v-if="invoiceDate">Date of Invoice: {{
            formatDate(invoiceDate)
          }}
        </div>
      </header>
      <h1 class="invoice__title">
        {{ 'Invoice Details' | uppercase }} - #{{ orderId }}
        <small v-if="isDeclined" class="tag">declined</small>
      </h1>

      <section class="invoice__section">
        <table class="table is-fullwidth invoice__section__table">
          <thead>
          <tr>
            <th colspan="2"><strong>{{ data.account.company }}</strong></th>
            <th colspan="3">Payment Summary</th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td colspan="2">
              <invoice-field
                label="Contact"
                :value="[
                  `${data.account.contact_firstname} ${data.account.contact_lastname}`,
                  data.account.email,
                  data.account.phone
                ]"
                :multiline="true"
              />
              <invoice-field
                label="Payment Term"
                :value="paymentTerms.find(({ key }) => key === data.payment.payment_term).label"
                v-if="data.payment.payment_term !== 'request-payment'"
              />
            </td>
            <td colspan="3">
              <table class="table is-fullwidth invoice__totals">
                <thead>
                <tr>
                  <th colspan="3">Invoice Details</th>
                </tr>
                </thead>
                <tbody>
                <tr v-if="+data.payment.discount.discount_amount > 0">
                  <th colspan="2">Discount</th>
                  <td>{{ data.payment.discount.discount_amount | price }}</td>
                </tr>
                <tr v-if="+data.payment.rush_fee.rush_fee_amount > 0">
                  <th colspan="2">Rush Fee</th>
                  <td>{{ data.payment.rush_fee.rush_fee_amount | price }}</td>
                </tr>
                <tr>
                  <th colspan="2"><strong>Total Amount</strong></th>
                  <td>{{ data.order.total_price | price }}</td>
                </tr>
                <tr>
                  <th colspan="2"><strong>Amount Paid</strong></th>
                  <td>{{ data.order.invoice.paid_sum | price }}</td>
                </tr>
                </tbody>
                <tfoot>
                <tr>
                  <th colspan="2"><strong>Balance Due</strong></th>
                  <td><strong class="clr-blue">{{
                      +data.order.total_price - +data.order.invoice.paid_sum | price
                    }}</strong></td>
                </tr>
                </tfoot>
              </table>
            </td>
          </tr>
          </tbody>
        </table>
      </section>

      <table class="table is-fullwidth invoice__designs with-separator">
        <thead>
        <tr>
          <th>
            <strong class="invoice__designs__title">Designs</strong>
          </th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(design, designIndex) in data.designs">
          <td>
            <!-- DESIGN -->
            <table class="table is-fullwidth invoice__design__table">
              <thead>
              <tr>
                <th colspan="2">
                  <div class="invoice__design__title">
                    Design {{ designIndex + 1 }}: {{ design.common.name }}
                  </div>
                </th>
                <th>
                  <invoice-field
                    label="Service"
                    :value="orderServices.find(({ key }) => design.common.service === key).label"
                  />
                </th>
                <th>
                  <invoice-field
                    label="In Hands"
                    :value="design.common.in_hands_date?formatDate(design.common.in_hands_date): 'None'"
                  />
                </th>
                <th>
                  <invoice-field
                    label="Upcharge"
                    :value="design.common.upcharge | price"
                  />
                </th>
              </tr>
              </thead>
              <tbody>
              <template
                v-if="[apparelServiceType, embroideryServiceType, blankServiceType].includes(design.common.service)">
                <!-- LOCATIONS -->
                <apparel-location
                    :key="'apparel-location-'+locationIndex"
                  :location-index="locationIndex"
                  :location="location"
                  :form-options="formOptions"
                  :service="design.common.service"
                  v-for="location, locationIndex in design.specific.locations"/>

                <!-- GARMENTS -->
                <template v-for="garment, garmentIndex in design.specific.garments">
                  <apparel-garment
                    :key="'apparel-garment-'+garmentIndex+'-'+sizeGroupIndex"
                    garment-index=""
                    :garment="garment"
                    :garmentIndex="garmentIndex"
                    :sizeGroup="sizeGroup"
                    :sizeGroupIndex="sizeGroupIndex"
                    :sortedGarmentsSizes="sortedGarmentsSizes"
                    v-for="sizeGroup, sizeGroupIndex in getGarmentSizesGroups(garment.size_cost)"/>
                </template>
                <!-- FINISHINGS -->
                <tr v-if="finishings && finishings.length && getDesignFinishings(finishings, design.uniqueId)">
                  <td colspan="3">
                    <invoice-field
                      label="Finishings"
                    />
                  </td>
                  <td>
                    <invoice-field
                      label="Qty"
                    />
                  </td>
                  <td>
                    <invoice-field
                      label="Price"
                    />
                  </td>
                </tr>
                <template v-for="(finishing, index) in getDesignFinishings(finishings, design.uniqueId)">
                  <tr>
                    <td colspan="3">
                      <invoice-field
                        :label="finishing.common.name"
                        :value="getFinishingFields(finishing)"
                      />
                    </td>
                    <td>
                      <invoice-field
                        :value="getFinishingGarmentsQty(finishing, design.uniqueId)"
                      />
                    </td>
                    <td>
                      <invoice-field
                        :value="finishing.specific.tag_garments_quantity_cost | price"
                      />
                    </td>
                  </tr>
                </template>
                <!-- TOTAL -->
                <total-row
                  :total-price="design.common.total_price"
                  :total-quantity="design.specific.extra.total_garments_qty"/>
              </template>
              <template v-if="design.common.service === buttonServiceType">
                <button-invoice-item
                  :form-options="formOptions[buttonServiceType]"
                  :item="design.specific.item"
                  :total-price="design.common.total_price"
                />
                <total-row :totalPrice="design.common.total_price"
                           :total-quantity="formOptions[buttonServiceType].quantities.find(({ key }) => key === design.specific.item.quantity).label"/>
              </template>
              <template v-if="design.common.service === invoiceServiceType">
                <invoice-item
                  v-for="item, index in design.specific.items" :key="index"
                  :item="item"
                  :itemIndex="index"
                  :totalPrice="design.common.total_price"
                />
                <total-row :totalPrice="design.common.total_price"
                           :totalQuantity="design.specific.items.reduce((acc, item) => {  return acc + parseFloat(item.quantity)},0)"/>
              </template>
              <template v-if="design.common.service === otherServiceType">
                <other-design-invoice-item v-for="item, index in design.specific.items" :key="index"
                                           :item="item"
                />
                <total-row :totalPrice="design.common.total_price"
                           :totalQuantity="design.specific.items.reduce((acc, item) => {  return acc + parseFloat(item.quantity)},0)"/>
              </template>
              <template v-if="design.common.service === finishingCreationServiceType">
                <finishing-creation-invoice-item-options
                  v-if="design.common.service === finishingCreationServiceType"
                  :form-options="formOptions[finishingCreationServiceType]"
                  :item="design.specific.item"/>
                <finishing-creation-invoice-item v-if="design.common.service === finishingCreationServiceType"
                                                 :form-options="formOptions[finishingCreationServiceType]"
                                                 :item="design.specific.item"/>
                <total-row :totalPrice="design.common.total_price"
                           :totalQuantity="design.specific.item.quantity"/>
              </template>
              <template v-if="design.common.service === paperServiceType">
                <paper-design-invoice-item-options
                  :form-options="formOptions[paperServiceType]"
                  :item="design.specific.item"/>
                <paper-design-invoice-item
                  :form-options="formOptions[paperServiceType]"
                  :item="design.specific.item"/>
                <total-row :totalPrice="design.common.total_price"
                           :totalQuantity="getOptionLabel(design.specific.item.quantity, formOptions[paperServiceType].print_type_options[design.specific.item.print_type].quantities)"/>
              </template>
              <template v-if="design.common.service === stickerServiceType">
                <sticker-printing-invoice-item :total-price="design.common.total_price"
                                               :form-options="formOptions[stickerServiceType]"
                                               :item="design.specific.item"
                />
                <total-row :totalPrice="design.common.total_price"
                           :totalQuantity="design.specific.item.quantity"/>
              </template>
              <template v-if="design.common.service === promotionalServiceType">
                <promotional-design-invoice-item-options
                  :form-options="formOptions[promotionalServiceType]"
                  :item="design.specific.item"/>
                <promotional-design-invoice-item
                  :form-options="formOptions[promotionalServiceType]"
                  :item="design.specific.item"/>
                <total-row :totalPrice="design.common.total_price"
                           :totalQuantity="design.specific.item.quantity"/>
              </template>
              </tbody>
            </table>
          </td>
        </tr>
        </tbody>
      </table>

      <section v-if="data.designs && data.designs.length" class="is-marginless with-middle-font">
        <table class="table is-fullwidth invoice__section__table">
          <tbody>
          <tr>
            <td colspan="3"></td>
            <td colspan="1"><strong>Designs Total:</strong></td>
            <td>
              <strong class="clr-blue">{{
                  data.designs.reduce((acc, design) => acc + +design.common.total_price, 0) |
                    price
                }}</strong>
            </td>
          </tr>
          </tbody>
        </table>
      </section>
      <table class="table is-fullwidth invoice__tag_setups with-bg with-separator" v-if="data.finishings && tagSetups && tagSetupsTotal > 0">
        <thead>
        <tr>
          <th>
            <strong class="invoice__tag_setups__title">Tag Setups</strong>
          </th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td>
            <!-- Tag setups -->
            <table class="table is-fullwidth invoice__tag_setup__table">
              <thead>
              <tr>
                <th colspan="5">
                  <div class="invoice__tag_setup__title">
                    Finishing Extras
                  </div>
                </th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(finishing, finishingIndex) in tagSetups" :key="finishingIndex">
                <td colspan="3">
                  <invoice-field
                    label="Finishing Option"
                    :value="finishing.common.name"

                  />
                </td>
                <td>
                  <invoice-field
                    label="Unique Tag Setups"
                    :value="finishing.specific.tag_setups_number"
                  />
                </td>
                <td>
                  <invoice-field
                    label="Price"
                    :value="finishing.specific.tag_setups_number_cost | price"
                  />
                </td>
              </tr>
              <total-row :totalPrice="tagSetupsTotal"
                         :totalQuantity="false"/>
              </tbody>
            </table>
          </td>
        </tr>
        </tbody>
      </table>
      <section v-if="data.finishings && tagSetups && tagSetupsTotal > 0" class="is-marginless with-middle-font">
        <table class="table is-fullwidth invoice__section__table">
          <tbody>
          <tr>
            <td colspan="3"></td>
            <td colspan="1"><strong>Tag Setups Total:</strong></td>
            <td>
              <strong class="clr-blue">{{ tagSetupsTotal | price }}</strong>
            </td>
          </tr>
          </tbody>
        </table>
      </section>
      <table class="table is-fullwidth invoice__shippings with-bg with-separator">
        <thead>
        <tr>
          <th>
            <strong class="invoice__shippings__title">Shipping</strong>
          </th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(shipping, shippingIndex) in data.shippings" :key="shippingIndex">
          <td>
            <!-- Shipping's -->
            <table class="table is-fullwidth invoice__shipping__table">
              <thead>
              <tr>
                <th>
                  <div class="invoice__shipping__title">
                    Shipping {{ shippingIndex + 1 }}
                  </div>
                </th>
                <th>
                  <invoice-field
                    label="Delivery Type"
                    :value="deliveryTypes.find(({ key }) => shipping.common.type_of_delivery === key).label"
                  />
                </th>
                <th>
                  <invoice-field
                    label="Recipient"
                    :value="shipping.specific.address.recipient"
                  />
                </th>
                <th colspan="2">
                  <invoice-field
                    label="Shipping"
                    :value="getShippingAddress(shipping.specific.address)"
                    :multiline="true"
                  />
                </th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(shippingItem, shippingItemIndex) in shipping.specific.items" :key="shippingItemIndex">
                <td>&nbsp;</td>
                <td colspan="3">
                  <invoice-field
                    :label="`Design ${getDesignIndexById(shippingItem.design_id,data.designs) + 1} / ${getShippingItemName(shippingItem, data.designs)}`"
                    :value="getShippingItemValue(shippingItem, data.designs)"
                  />
                </td>
                <td>
                  <invoice-field
                    :label="shippingItemIndex === 0 ? 'Total Qty' : ''"
                    :value="getShippingItemQty(shippingItem, data.designs) | intlNumber"
                  />
                </td>
              </tr>
              </tbody>
            </table>
          </td>
        </tr>
        </tbody>
      </table>

      <section v-if="+data.order.shipping_cost > 0" class="is-marginless with-middle-font">
        <table class="table is-fullwidth invoice__section__table">
          <tbody>
          <tr>
            <td colspan="3"></td>
            <td colspan="1"><strong>Shipping Total:</strong></td>
            <td>
              <strong class="clr-blue">{{ data.order.shipping_cost | price }}</strong>
            </td>
          </tr>
          </tbody>
        </table>
      </section>

      <section class="invoice__section with-bg">
        <table class="table is-fullwidth invoice__section__table">
          <thead>
          <tr>
            <th colspan="3">
              <strong>PAYMENT AND INVOICE SUMMARY</strong>
            </th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td colspan="2">
              <invoice-field
                label="Payment Term"
                :value="paymentTerms.find(({ key }) => key === data.payment.payment_term).label"
              />
            </td>
            <td colspan="3">
              <table class="table is-fullwidth invoice__totals">
                <thead>
                <tr>
                  <th colspan="3">Invoice Details</th>
                </tr>
                </thead>
                <tbody class="with-separator">
                <tr>
                  <th colspan="2">Designs</th>
                  <td>{{ data.designs.reduce((acc, design) => acc + +design.common.total_price, 0) | price }}</td>
                </tr>
                <tr>
                  <th colspan="2">Shipping</th>
                  <td>{{ data.order.shipping_cost | price }}</td>
                </tr>
                <tr v-if="+data.payment.state_tax > 0">
                  <th colspan="2">Sales Tax</th>
                  <td>{{ data.payment.state_tax | price }}</td>
                </tr>
                <tr v-if="+data.payment.discount.discount_amount > 0">
                  <th colspan="2">Discount</th>
                  <td>{{ data.payment.discount.discount_amount | price }}</td>
                </tr>
                <tr v-if="+data.payment.rush_fee.rush_fee_amount > 0">
                  <th colspan="2">Rush Fee</th>
                  <td>{{ data.payment.rush_fee.rush_fee_amount | price }}</td>
                </tr>
                <tr>
                  <th colspan="2"><strong>Total Amount</strong></th>
                  <td>{{ data.order.total_price | price }}</td>
                </tr>
                <tr>
                  <th colspan="2"><strong>Amount Paid</strong></th>
                  <td>{{ data.order.invoice.paid_sum | price }}</td>
                </tr>
                </tbody>
                <tfoot>
                <tr>
                  <th colspan="2"><strong>Balance Due</strong></th>
                  <td><strong class="clr-blue">{{
                      +data.order.total_price - +data.order.invoice.paid_sum | price
                    }}</strong></td>
                </tr>
                </tfoot>
              </table>
            </td>
          </tr>
          </tbody>
        </table>
      </section>


    </article>
    <div v-else class="is-hidden-print">Loading...</div>
  </div>
</template>

<script>
import Vue from 'vue';
import VeeValidate from 'vee-validate';
import {mapActions, mapGetters} from 'vuex';
import {flatten} from 'lodash';
import EstimateSummary from '../../estimate-summary';
import DetailsEstimate from '@/components/common/DetailsEstimate/DetailsEstimate';

import {LOAD_EDIT_ORDER_INIT_DATA} from '@/store/action-types';
//form
import apiService from '../../../../services/api.service';
import moment from 'moment';
import alertify from 'alertify.js';
import {INVOICE_STATUS_DECLINED} from '@/helpers/invoices';
import {getDesignFinishings, getFinishingFields} from '@/helpers/prices/finishings';
import TotalRow from '@/components/customer/order/invoices/services/TotalRow.vue';
import FinishingCreationInvoiceItemOptions
  from '@/components/customer/order/invoices/services/FinishingCreationOptions.vue';
import FinishingCreationInvoiceItem from '@/components/customer/order/invoices/services/FinishingCreation.vue';
import InvoiceItem from '@/components/customer/order/invoices/services/Invoice.vue';
import ApparelLocation from '@/components/customer/order/invoices/services/ApparelLocation.vue';
import ApparelGarment from '@/components/customer/order/invoices/services/ApparelGarment.vue';
import {
  APPAREL_SERVICE_CODE,
  BLANK_SERVICE_CODE,
  BUTTON_SERVICE_CODE,
  EMBROIDERY_SERVICE_CODE,
  FINISHING_CREATION_SERVICE_CODE,
  INVOICE_SERVICE_CODE,
  OTHER_SERVICE_CODE,
  PAPER_SERVICE_CODE,
  PROMOTIONAL_SERVICE_CODE,
  STICKER_SERVICE_CODE
} from '@/store/service-types';
import OtherDesignInvoiceItem from '@/components/customer/order/invoices/services/Other.vue';
import {getOptionLabel} from '@/helpers/estimates';
import ButtonInvoiceItem from '@/components/customer/order/invoices/services/ButtonPrinting.vue';
import InvoiceField from '@/components/common/invoice-field.vue';
import PromotionalDesignInvoiceItemOptions from '@/components/customer/order/invoices/services/PromotionalOptions.vue';
import PromotionalDesignInvoiceItem from '@/components/customer/order/invoices/services/Promotional.vue';
import PaperDesignInvoiceItemOptions from '@/components/customer/order/invoices/services/PaperOptions.vue';
import PaperDesignInvoiceItem from '@/components/customer/order/invoices/services/Paper.vue';
import StickerPrintingInvoiceItem from '@/components/customer/order/invoices/services/Sticker.vue';
import Breadcrumbs from '@/components/common/breadcrumbs.vue';

Vue.use(VeeValidate);

const DATE_FORMAT = 'MM/DD/YY';

export default {
  name: 'order-invoice-details',
  data() {
    return {
      orderId: '',
      invoice: {
        status: '',
        paid_sum: 0,
        amount: 0
      },
      amount: 0,
      amountToPay: 0,
      inProgress: false,
      authorize: {
        form_action: ''
      },
      paymentMethod: '',
      isLoading: true,
      data: null,
      formOptions: null,
      finishingsPrices: null,
      shippingOptions: null
    };
  },
  components: {
    Breadcrumbs,
    StickerPrintingInvoiceItem,
    PaperDesignInvoiceItem,
    PaperDesignInvoiceItemOptions,
    PromotionalDesignInvoiceItem,
    PromotionalDesignInvoiceItemOptions,
    InvoiceField,
    ApparelGarment,
    ApparelLocation,
    OtherDesignInvoiceItem,
    FinishingCreationInvoiceItemOptions,
    FinishingCreationInvoiceItem,
    InvoiceItem,
    ButtonInvoiceItem,
    TotalRow,
    EstimateSummary,
    DetailsEstimate
  },
  computed: {
    ...mapGetters([
      'orderServices',
      'sortedGarmentsSizes',
      'paymentTerms',
      'deliveryTypes',
      'orderCountries'
    ]),
    crumbLinks() {
      return [
        {
          url: '/customer/dashboard',
          label: 'Orders'
        },
        {
          url: '/customer/orders/' + this.orderId + '/details',
          label: '#' + this.orderId
        },
        {
          label: 'Invoice'
        }
      ];
    },
    apparelServiceType() {
      return APPAREL_SERVICE_CODE;
    },
    embroideryServiceType() {
      return EMBROIDERY_SERVICE_CODE;
    },
    blankServiceType() {
      return BLANK_SERVICE_CODE;
    },
    paperServiceType() {
      return PAPER_SERVICE_CODE;
    },
    promotionalServiceType() {
      return PROMOTIONAL_SERVICE_CODE;
    },
    buttonServiceType() {
      return BUTTON_SERVICE_CODE;
    },
    otherServiceType() {
      return OTHER_SERVICE_CODE;
    },
    invoiceServiceType() {
      return INVOICE_SERVICE_CODE;
    },
    finishingCreationServiceType() {
      return FINISHING_CREATION_SERVICE_CODE;
    },
    stickerServiceType() {
      return STICKER_SERVICE_CODE;
    },
    finishings() {
      return this.data.finishings;
    },
    maxAmount() {
      let amount = +this.invoice.amount;
      return +(amount - +this.invoice.paid_sum).toFixed(2);
    },
    isDeclined() {
      return +this.invoice.status === INVOICE_STATUS_DECLINED;
    },
    totalAmount() {
      return +(+this.invoice.amount).toFixed(2);
    },
    invoiceDate() {
      return this.data.order.estimate_becomes_to_order_at ? this.data.order.estimate_becomes_to_order_at : null;
    },
    tagSetups() {
      return this.data.finishings.filter(
        finishing => finishing.specific.tag_setups_number !== undefined
      );
    },
    tagSetupsTotal() {
      return this.tagSetups.reduce((total, finishing) => {
        if (finishing.specific) {
            // If there are tag setups.
            if (finishing.specific.tag_setups_number) {
              // If cost calculated before.
              if (
                finishing.specific.tag_setups_number_cost !== undefined &&
                finishing.specific.tag_setups_number_cost !== null
              ) {
                total +=
                  finishing.specific.tag_setups_number_cost *
                  finishing.specific.tag_setups_number;
              }
            }
            return total;
        }

        return total;
      }, 0)
    },
  },
  methods: {
    getOptionLabel,
    ...mapActions({
      getInitData: LOAD_EDIT_ORDER_INIT_DATA
    }),
    getFinishingFields: getFinishingFields,
    getDesignFinishings: getDesignFinishings,

    getFinishingGarmentsQty(finishing, designId) {
      return finishing.items.filter(item => item.design_id === designId).reduce(
        (total, item) =>
          total + Object.values(item.sizes).reduce((sizesTotal, sizeQty) => sizesTotal + (+sizeQty || 0), 0), 0
      );
    },
    getShippingItemQty(item, designs) {
      if (item.sizes) {
        return Object.values(item.sizes).reduce((acc, qty) => acc + +qty, 0);
      }
      const design = designs.find(
        ({common}) => common.uniqueId === item.design_id
      );
      let form_option = null;
      switch (design.common.service) {
        case this.stickerServiceType:
        case this.promotionalServiceType:
          return design.specific.item.quantity;
        case this.buttonServiceType:
          form_option = this.formOptions[this.buttonServiceType].quantities.find(
            ({key}) => key === design.specific.item.quantity
          );
          return (form_option && form_option.label) || '';
        case this.otherServiceType:
          return design.specific.items.reduce(
            (acc, {quantity}) => acc + quantity,
            0
          );
        case this.invoiceServiceType:
          return design.specific.items.reduce(
            (acc, {quantity}) => acc + parseFloat(quantity),
            0
          );
        case this.paperServiceType:
          return getOptionLabel(design.specific.item.quantity, this.formOptions[this.paperServiceType].print_type_options[design.specific.item.print_type].quantities);
        case this.finishingCreationServiceType:
          if (design.specific.item.product_type && ['Woven Patch', 'Printed Patch'].includes(getOptionLabel(design.specific.item.product_type, this.formOptions.finishing_creation.print_type_options[design.specific.item.print_type].product_types))) {
            return design.specific.item.quantity;
          } else {
            return getOptionLabel(design.specific.item.quantity, this.formOptions[this.finishingCreationServiceType].print_type_options[design.specific.item.print_type].quantities);
          }
      }
    },
    getDesignIndexById(designId, designs) {
      return designs.findIndex(
        ({common}) => common.uniqueId === designId
      );
    },
    getShippingItemName(item, designs) {
      const design = designs.find(
        ({common}) => common.uniqueId === item.design_id
      );
      if (!item.garment_id) return design.common.name;
      const garment = design.specific.garments.find(
        ({uniqueId}) => item.garment_id === uniqueId
      );
      const garmentLabel = this.getGarmentLabel(garment);
      return `${design.common.name} / ${garmentLabel}`;
    },
    getShippingItemValue(item, designs) {
      if (!item.sizes) {
        let service = designs.find(
          ({common}) => common.uniqueId === item.design_id
        ).common.service;
        return this.orderServices.find(({key}) => service === key).label;
      }
      return this.sortedGarmentsSizes
        .map(size => {
          if (!item.sizes[size]) return;
          return `${size}-${item.sizes[size]}`;
        })
        .filter(item => !!item)
        .join(', ');
    },
    getShippingAddress(address) {
      let result = [];
      result.push(address.address_1);
      if (address && address.address_2 && address.address_2.length) {
        result.push(address.address_2);
      }
      result.push(
        `${address.city}, ${address.state_id} ${address.postal_code}`
      );
      result.push(
        this.orderCountries.find(
          ({country_id}) => country_id === address.country_id
        ).name
      );
      return result;
    },
    getGarmentLabel(garment) {
      return `${garment.model.apparel_brand.name} ${garment.model.number} - ${
        garment.model.name
      } ${garment.color}`;
    },
    getGarmentSizesGroups(sizes) {
      const groups = {};
      this.sortedGarmentsSizes.forEach(sizeKey => {
        if (!sizes[sizeKey]) return;
        if (+sizes[sizeKey].qty === 0) return;
        if (!groups[sizes[sizeKey].cost]) {
          groups[sizes[sizeKey].cost] = {
            sizes: {},
            qty: 0
          };
        }
        groups[sizes[sizeKey].cost].sizes[sizeKey] = +sizes[sizeKey].qty;
        groups[sizes[sizeKey].cost].qty += +sizes[sizeKey].qty;
      });
      return Object.keys(groups)
        .sort()
        .map(groupKey => ({
          cost: groupKey,
          ...groups[groupKey]
        }));
    },
    setPaymentMethod(method) {
      this.paymentMethod = method;
    },

    submitForm() {
      if (this.paymentMethod == 'paypal') {
        this.submitPayPalPayment();
      } else if (this.paymentMethod == 'authorize_net') {
        this.submitAuthorizeNetForm();
      }
    },

    submitPayPalPayment() {
      this.inProgress = true;
      this.$validator
        .validateScopes()
        .then(data => {
          let sendData = {
            invoice_id: this.invoice.id,
            amount: Number(this.amountToPay).toFixed(2)
          };

          apiService.postPaypalPayment(sendData).then(
            data => {
              if (data.redirect_url) {
                window.location.href = data.redirect_url;
              } else if (data.messages.length) {
                data.messages.forEach(function(message) {
                  alertify.error(message);
                });

                this.inProgress = false;
              }
            },
            response => {
              if (
                response.body &&
                response.body.messages &&
                response.body.messages.length
              ) {
                response.body.messages.forEach(function(message) {
                  alertify.error(message);
                });
              } else {
                alertify.error('Could not process payment');
              }
              this.inProgress = false;
            }
          );
        })
        .catch(err => {
          alertify.error('Validation failed. Please check error messages');
          this.inProgress = false;
        });
    },

    submitAuthorizeNetForm() {
      this.inProgress = true;
      this.$validator
        .validateScopes()
        .then(data => {
          let sendData = {
            invoice_id: this.invoice.id,
            amount: Number(this.amountToPay).toFixed(2)
          };

          apiService.getAuthorizeNetForm(sendData).then(
            data => {
              if (data.status == 'success') {
                this.authorize = data.data;
                Vue.nextTick(() => {
                  document.getElementById('authorizeForm').submit();
                });
              } else if (data.messages.length) {
                data.messages.forEach(function(message) {
                  alertify.error(message);
                });

                this.inProgress = false;
              }
            },
            response => {
              if (
                response.body &&
                response.body.messages &&
                response.body.messages.length
              ) {
                response.body.messages.forEach(function(message) {
                  alertify.error(message);
                });
              } else {
                alertify.error('Could not process payment');
              }
              this.inProgress = false;
            }
          );
        })
        .catch(err => {
          alertify.error('Validation failed. Please check error messages');
          this.inProgress = false;
        });
    },

    formatDate(date) {
      return moment(date).format(DATE_FORMAT);
    },
    convertFormOptions(data) {
      Object.keys(data).forEach(service => {
        if (!data[service].locations) return;
        const locationsRaw = data[service].locations;
        const locations = Object.keys(locationsRaw).map(groupKey => {
          return {
            title: groupKey,
            options: Object.keys(locationsRaw[groupKey]).map(childKey => {
              return {
                id: childKey,
                label: locationsRaw[groupKey][childKey]
              };
            })
          };
        });
        data[service].locations = locations;
      });
      return data;
    },
    fetchEstimateData() {
      return apiService
        .getEstimateData(this.orderId)
        .then(({data}) => {
          this.data = data;
        })
        .catch(error => {
          console.error(error);
        });
    },
    fetchFormOptions(userId) {
      return apiService
        .get(
          `estimates/designs/form-options?${
            userId ? `filter[user_id]=${userId}` : ''
          }`
        )
        .then(({data}) => {
          this.formOptions = this.convertFormOptions(data);
        });
    },
    fetchFinishingsPrices(userId) {
      return apiService.getApparelFinishingPrices(userId).then(response => {
        this.finishingsPrices = response;
      });
    },
    fetchShippingMethods() {
      return apiService.getShippingMethods().then(({data}) => {
        const {ups} = data;
        this.shippingOptions = ups.services;
      });
    },
    printInvoice() {
      if (this.invoice && this.invoice.link_hash) {
        window.open('/#/invoices/' + this.invoice.link_hash + '/print');
      }
    }
  },
  created() {
    this.isLoading = true;

    apiService
      .getInvoiceDetails(this.$route.params.hash)
      .then(data => {
        if (data.status == 'success') {
          this.orderId = data.data.invoice.order_id;
          this.invoice = data.data.invoice;

          this.amountToPay = Number(this.maxAmount).toFixed(2);
        } else {
          alertify.error(data.message);
        }
      })
      .then(() =>
        Promise.all([
          this.fetchEstimateData(),
          this.fetchShippingMethods(),
          this.getInitData()
        ])
      )
      .then(() =>
        Promise.all([
          this.fetchFormOptions(this.data.account.account_id),
          this.fetchFinishingsPrices(this.data.account.account_id)
        ])
      )
      .then(() => {
        const posterColorsPromises = [];
        this.data.designs.forEach(design => {
          if (design.common.service !== 'poster') return;
          if (!design.specific.item.paper_color) return;
          posterColorsPromises.push(
            this.getPaperColors(
              design.specific.item.brand,
              design.specific.item.paper_weight
            ).then(paperColors => {
              design.specific.item.paper_color_label = flatten(
                paperColors.map(({options}) => options)
              ).find(
                ({id}) =>
                  id.toString() === design.specific.item.paper_color.toString()
              ).label;
            })
          );
        });
        return Promise.all(posterColorsPromises);
      })
      .then(() => {
        this.isLoading = false;
        if (this.$route.params.print == 'print') {
          this.$nextTick(() => {
            setTimeout(() => {
              window.focus();
              window.print();
              window.close();
            }, 500);
          });
        }
      });
  }
};
</script>
<style lang="scss" scoped>
@import '/resources/sass/base/helpers';

.invoice {
  table{
    background-color: transparent;
  }
  padding: 0;
  @media print {
    font-size: 12px;
    line-height: 16px;
    font-family: Arial;
  }

  .with-middle-font {
    font-size: 20px;
  }

  .with-separator {
    border-bottom: 2px solid $clr-blue;
  }

  &__header {
    display: flex !important;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 40px;

    & + * {
      margin-top: 15px;
    }
  }

  &__totals {
    table-layout: fixed;

    th {
      font-weight: 400;
    }

    th,
    td {
      padding: 2px 0 3px;
    }

    thead {
      & > th {
        color: $clr-blue;
      }
    }

    tfoot {
      th,
      td {
        padding: 2px 0 3px;
      }

      & > tr:first-child {
        th,
        td {
          padding-top: 25px;
        }
      }

      th,
      td {
        font-size: 20px;
        line-height: 22px;
      }
    }
  }

  &__section {
    overflow: hidden;
    padding: 30px 0;
    border: solid $clr-grey;
    border-width: 1px 0;
    page-break-inside: avoid;

    &.with-bg {
      border: none;
      background: rgba($clr-blue, 0.07);
      margin-left: -60px;
      padding-left: 60px;
      margin-right: -60px;
      padding-right: 60px;
    }

    &.with-bg-light-gray {
      border: none;
      background: rgba($clr-grey, 0.07);
      margin-left: -60px;
      padding-left: 60px;
      margin-right: -60px;
      padding-right: 60px;
    }

    & + * {
      margin-top: 25px;
    }

    &__table {
      table-layout: fixed;
      page-break-after: always;

      & > tbody {
        & > tr > td {
          padding: 20px 0;
        }
      }

      & > thead {
        & > tr > th {
          font-size: 24px;
          line-height: 26px;
          font-weight: 400;
        }
      }
    }
  }

  &__title {
    font-size: 30px;
    line-height: 32px;
    font-weight: 900;

    & + * {
      margin-top: 30px;
    }
  }

  &__field + &__field {
    margin-top: 20px;
  }

  &__field {
    &__label {
      font-weight: bold;

      & + * {
        margin-top: 5px;
      }
    }

    &__value {
    }

    &.is-large &__value {
      font-weight: bold;
      font-size: 24px;
      line-height: 26px;
    }
  }

  &__design,
  &__tag_setup,
  &__shipping {
    &__title {
      font-size: 24px;
      line-height: 26px;
      font-weight: bold;
    }

    &__table {
        table-layout: fixed;
      th {
        font-weight: inherit;
      }

      & > tbody {
        & > tr {
          & > td {
            padding: 10px 0;
          }

          &:first-child > td {
            padding-top: 20px;
          }

          &:last-child > td {
            padding-bottom: 20px;
          }

          &.is-garment {
            & > td {
              padding: 0 0 5px !important;
            }

            &.is-first-garment {
              & > td {
                padding: 10px 0 5px !important;
              }
            }
          }
        }
      }

      & > thead {
        th {
          background: rgba(#000, 0.05);
          padding: 30px 0;

          &:first-child {
            position: relative;

            &:before {
              content: '';
              position: absolute;
              top: 0;
              bottom: 0;
              width: 60px;
              background: rgba(#000, 0.05);
              right: 100%;
            }
          }

          &:last-child {
            position: relative;

            &:after {
              content: '';
              position: absolute;
              top: 0;
              bottom: 0;
              width: 60px;
              background: rgba(#000, 0.05);
              left: 100%;
            }
          }

          @media print {
            &:first-child:before,
            &:last-child:after,
            & {
              background: #f9f9f9;
            }
            & {
              border: 1px solid #f9f9f9;
            }
          }
        }
      }
    }
  }

  &__finishings {
    page-break-inside: avoid;
  }

  &__designs,
  &__finishings,
  &__tag_setups,
  &__shippings {
    & + * {
      margin-top: 30px;
    }

    & > thead {
      tr {
        th {
          padding: 0 0 20px;
        }
      }
    }

    &__title {
      text-transform: uppercase;
      color: $clr-primary2;
      font-size: 16px;
      line-height: 20px;
      font-weight: bold;
    }
  }
}
</style>
