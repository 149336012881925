<template>
  <div>
    <nav class="level">
      <!-- Left side -->
      <div class="level-left">
        <div class="level-item">
          <p class="subtitle is-5">Search companies</p>
        </div>
        <div class="level-item">
          <div class="control">
            <p class="control has-addons">
              <input type="text" class="input"
                     v-model="searchQuery"
                     v-focus="true">
            </p>
          </div>
        </div>
      </div>

      <!-- Right side -->
      <div class="level-right">
          <router-link
            v-roles="['admin', 'csr', 'production-coordinator', 'purchaser', 'art-producer']"
            class="button" to="/companies/new">Create New Company
          </router-link>
          <router-link
            v-role-access="['admin','developer']"
            class="button" to="/reports/clients">
            Create Clients Report
          </router-link>
      </div>
    </nav>

    <template v-if="!isLoading">

      <table v-if="companiesList.length" class="table is-fullwidth">
        <thead>
        <tr>
          <th>id</th>
          <th>Company</th>
          <th width="60px"></th>
          <th width="60px"></th>
        </tr>
        </thead>
        <tfoot>
        <tr>
          <th>id</th>
          <th>Company</th>
          <th></th>
          <th></th>
        </tr>
        </tfoot>
        <tbody>
        <tr v-for="(entry, rowKey) in companiesList">
          <th>{{entry.id}}</th>
          <td>{{entry.name}}</td>
          <td>
            <router-link class="button is-small"
                        :to="'/companies/' + entry.id + '/details'">View
            </router-link>
          </td>
          <td>
            <router-link class="button is-small"
                        :to="'/companies/' + entry.id + '/edit'">Edit
            </router-link>
          </td>
        </tr>
        </tbody>
      </table>

      <section v-else class="hero has-text-centered">
        <div class="hero-body">
          <div class="container">
            <p class="subtitle">No companies found</p>
          </div>
        </div>
      </section>
      <paginator v-if="companiesList.length"
                 :pagesNumber="pagesNumber"
                 :prevPageUrl="prevPageUrl"
                 :nextPageUrl="nextPageUrl"
                 :currentPage="currentPage"
                 :getListItems="getCompaniesData"/>
    </template>

    <div v-else>Loading...</div>

  </div>
</template>

<script>
import Vue from 'vue';
import { debounce } from 'lodash';
import { focus } from 'vue-focus';
import paginator from "../../common/paginator.vue";

export default {
  name: 'companies-list',
  components: {paginator},
  directives: { focus },
  data() {
    return {
      isLoading: false,
      companiesList: [],
      prevPageUrl: null,
      nextPageUrl: null,
      currentPage: 1,
      pagesNumber: 1,
      searchQuery: ''
    };
  },
  watch: {
    searchQuery: function(str) {
      if (str.length == 0 || str.length >= 3) {
        this.searchCompanies();
      }
    }
  },
  methods: {
    searchCompanies: debounce(function() {
      this.getCompaniesData();
    }, 500),
    getCompaniesData: function(page, url) {
      if (page === undefined) page = 1;

      if (url === undefined) url = `/api/companies/list?page=${page}`;

      if (this.searchQuery) {
        url +=
          url.indexOf('?') == -1
            ? '?q=' + this.searchQuery
            : '&q=' + this.searchQuery;
      }

      return this.$http.get(url).then(
        response => {
          this.companiesList = response.data.companies_data.data;
          this.prevPageUrl = response.data.companies_data.prev_page_url;
          this.nextPageUrl = response.data.companies_data.next_page_url;
          this.currentPage = response.data.companies_data.current_page;
          this.pagesNumber = response.data.companies_data.last_page;
        },
        response => {
          notify({
            message: 'Could not load companies list',
            type: 'danger'
          });
        }
      );
    }
  },
  created() {
    this.isLoading = true;
    this.getCompaniesData().then(() => {
      this.isLoading = false;
    });
  }
};
</script>
<script setup>
</script>
