<template>
  <label class="form-checkbox" :class="{
    'is-checked': checked,
    'is-indeterminate': isIndeterminate,
    'is-static': static
  }">
    <input type="checkbox"
      :checked="checked"
      :id="scope + '-' + name"
      :name="scope + '-' + name"
      :key="scope + '-' + name"
      :data-vv-as="name | startCase"
      :data-vv-name="name"
      v-validate="{ rules: rules, scope: scope }"
      :disabled="disabled"
      @change="$emit('change', $event.target.checked)"
      :indeterminate.prop="isIndeterminate"
      :scope="scope"
      v-if="!static"
      ref="input"
    >
    <span class="form-checkbox__icon" :style="{
      'flex-basis': `${iconSize}px`,
      'width': `${iconSize}px`,
      'height': `${iconSize}px`
    }">
      <svg xmlns="http://www.w3.org/2000/svg" :width="iconSize" :height="iconSize" viewBox="0 0 14 14">
        <path d="M2 0h10a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2zm8.36 4.39L6.293 8.506 4.564 6.778A.752.752 0 1 0 3.501 7.84L5.52 9.859c.427.427 1.12.427 1.547 0l4.409-4.41a.752.752 0 0 0 0-1.063l-.019-.018a.787.787 0 0 0-1.096.022z"/>
      </svg>
    </span>
    <span class="form-checkbox__title"
      :style="{
        'padding-left': `${iconSize / 2}px`,
        'line-height': `${iconSize}px`
      }"
      v-if="$slots.default || (title && title.length > 0)"
    ><slot>{{ title }}</slot>
    <span
      class="form-item-help help is-danger is-visible error-msg"
      v-show="errors.has(scope + '.' + name) && showErrors"
    >
      <slot name="errors">{{ errors.first(scope + '.' + name) }}</slot>
    </span>
    </span>

  </label>
</template>

<script>
import Vue from 'vue';
import bus from '@/bus';
import alertify from 'alertify.js';

export default {
  name: 'form-checkbox',
  model: {
    prop: 'checked',
    event: 'change'
  },
  props: {
    indeterminate: {
      default: false,
      type: Boolean
    },
    disabled: {
      default: false,
      type: Boolean
    },
    title: {
      default: '',
      type: String
    },
    name: {
      type: String,
      required: true
    },
    checked: {
      type: Boolean
    },
    static: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: false
    },
    rules: {
      type: String,
      default: ''
    },
    scope: {
      type: String,
      default: 'form-scope'
    },
    showErrors: {
      type: Boolean,
      default: false
    },
    iconSize: {
      default: 20,
      type: [Number, String]
    }
  },
  data() {
    return { isIndeterminate: false };
  },
  computed: {
    nameModel() {
      return `${this.scope + '-' + this.name.toLowerCase()}`;
    }
  },
  methods: {
    onValidate(validateScope) {
      if (validateScope) {
        this.$validator.validateAll(validateScope).catch(err => {
          this.addError(err);
        });
      } else {
        this.$validator.validateScopes().catch(err => {
          this.addError(err);
        });
      }
    },

    onClear() {
      this.errors.clear(this.scope);
    },

    addError(e) {
      console.log(e);
      if (
        e.field === this.nameModel &&
        e.scope === this.scope &&
        (!this.fieldsetName ||
          e.fullName === `${this.fieldsetName}.${this.name}`)
      ) {
        this.errors.add(
          e.field,
          e.msg.replace(e.fullName, this.getLabel),
          e.rule,
          e.scope
        );
        alertify.log(e.msg.replace(e.fullName, this.getLabel));
      }
    }
  },
  created() {
    this.isIndeterminate = this.indeterminate;
    bus.$on('add-error', this.addError);
    bus.$on('validate', this.onValidate);
    bus.$on('clear', this.onClear);
    this.$watch(
      () => this.errors.errors,
      (value, oldValue) => {
        if (value.length) {
          bus.$emit('errors-changed', value);
        } else {
          bus.$emit('errors-deleted', oldValue);
        }
      }
    );
  },
  beforeDestroy() {
    if (!this.static) {
      bus.$emit('errors-deleted', this.errors.errors);
      bus.$off('validate', this.onValidate);
      bus.$off('clear', this.onClear);
      bus.$off('add-error', this.addError);
    }
  },
  watch: {
    checked() {
      this.isIndeterminate = false;
    }
  }
};
</script>
<style lang="scss">
@import '/resources/sass/base/helpers';

.form-checkbox {
  display: flex;
  align-items: flex-start;
  input {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
  }
  &.is-checked &__icon,
  input:checked + &__icon {
    svg {
      opacity: 1;
    }
  }
  &.is-indeterminate &__icon,
  input:indeterminate + &__icon {
    background: #ccc;
    border-color: #ccc;
    &:before {
      opacity: 1;
    }
  }
  &.is-disabled &__icon,
  input:disabled + &__icon {
    cursor: not-allowed;
    opacity: 0.4;
  }
  $iconSize: 20px;
  &__icon {
    cursor: pointer;
    flex: 0 0 $iconSize;
    width: $iconSize;
    height: $iconSize;
    border-radius: 2px;
    position: relative;
    $borderWidth: 2px;
    border: solid $borderWidth #dbdbdb;
    background: #fff;
    svg {
      position: absolute;
      top: -$borderWidth;
      left: -$borderWidth;
      right: -$borderWidth;
      bottom: -$borderWidth;
      transition: opacity 0.15s ease-in-out;
      fill: $clr-primary2;
      opacity: 0;
    }
    &:before {
      content: '';
      position: absolute;
      opacity: 0;
      transition: opacity 0.15s ease-in-out;
      top: 50%;
      left: 50%;
      width: 75%;
      height: 20%;
      background: #fff;
      transform: translate(-50%, -50%);
    }
  }
  &__title {
    flex-grow: 1;
    cursor: pointer;
    padding-left: calc($iconSize / 2);
    font-size: 14px;
    line-height: $iconSize;
  }
  &.is-static &__icon,
  &.is-static &__title {
    cursor: default;
  }
}
</style>
