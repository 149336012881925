<template>
  <div>
    <form-row class="fixed-3">
      <form-multiselect
        v-model="finishingTypeModel"
        :options="formData.printTypes"
        :required="true"
        label="Category"
        :config="{
              trackBy: 'key',
            }"
        :scope="scope"
        name="category"
        :disabled="isDesignReprint"
      />
      <form-multiselect
        v-if="printTypeFormOptionsList('product_types',false).length"
        v-model="finishingProductTypeModel"
        :options="printTypeFormOptionsList('product_types',false)"
        :required="true"
        label="Product type"
        :disabled="isDesignReprint"
        :config="{
              trackBy: 'key',
            }"
        :scope="scope"
        name="product_type"
      />
      <div class="form-item" style="align-self: center" v-if="showRoundedCornersCheckbox">
        <form-checkbox
          :disabled="isDesignReprint"
          :scope="scope"
          name="has_rounded_corners"
          v-model="roundedCornersModel"
        >Rounded Corners?
        </form-checkbox>
      </div>
      <div class="form-item" style="align-self: center" v-if="showPatchCheckbox">
        <form-checkbox :scope="scope" :disabled="isDesignReprint" name="button_loops" v-model="buttonLoopsModel">
          Button Loops
        </form-checkbox>
      </div>
      <div class="form-item" style="align-self: center" v-if="showPatchCheckbox">
        <form-checkbox :scope="scope" :disabled="isDesignReprint" name="extra_colors" v-model="extraColorsModel">
          Extra Colors 9+
        </form-checkbox>
      </div>
    </form-row>
    <form-row class="fixed-3">
      <form-multiselect
        v-if="printTypeFormOptionsList('paper_types',(finishingTypeModel && finishingTypeModel.label === 'Patches')).length"
        v-model="finishingPaperTypeModel"
        :options="printTypeFormOptionsList('paper_types',(finishingTypeModel && finishingTypeModel.label === 'Patches'))"
        :required="true"
        :label="(finishingTypeModel && finishingTypeModel.label === 'Hang Tags')?'Paper':finishingTypeModel && finishingTypeModel.label === 'Patches'?'Backings':'Folding'"
        :disabled="isDesignReprint || !this.value.print_type || ( finishingTypeModel.label === 'Patches' && !this.value.product_type)"
        :config="{
              trackBy: 'key',
            }"
        :scope="scope"
        name="paperType"
      />
      <form-multiselect
        v-model="finishingSizeModel"
        :options="printTypeFormOptionsList('sizes')"
        :required="true"
        label="Size"
        :disabled="isDesignReprint || !this.value.print_type || ( finishingTypeModel.label === 'Patches' && !this.value.product_type)"
        :config="{
              trackBy: 'key',
            }"
        :scope="scope"
        name="paperSize"
      />
      <form-multiselect
        v-if="printTypeFormOptionsList('borders').length"
        v-model="finishingBordersModel"
        :options="printTypeFormOptionsList('borders')"
        :required="true"
        label="Borders"
        :disabled="!this.value || !this.value.print_type || ( finishingTypeModel && finishingTypeModel.label === 'Patches' && !this.value.product_type)"
        :config="{
              trackBy: 'key',
            }"
        :scope="scope"
        name="border"
      />
      <form-multiselect
        v-if="!allowedCustomQuantity"
        v-model="finishingQuantityModel"
        :options="printTypeFormOptionsList('quantities')"
        :required="true"
        label="Quantity"
        :disabled="!this.value|| ( finishingTypeModel && finishingTypeModel.label === 'Patches' && !this.value.product_type)"
        :config="{
              trackBy: 'key',
            }"
        :scope="scope"
        :after-label="getUnitPrice(this.value,this.formOptions)"
        name="quantity"
      />
      <form-item
        v-if="allowedCustomQuantity"
        v-model="finishingQuantityModel"
        label="Quantity"
        name="itemQuantity"
        :scope="scope"
        :required="true"
        rules="numeric|min_value:50"
        :after-label="getUnitPrice(this.value,this.formOptions)"
        :disabled="!this.value|| ( finishingTypeModel && finishingTypeModel.label === 'Patches' && !this.value.product_type)"
      ></form-item>
    </form-row>
    <form-row v-if="this.value.size === 'empty_size'">
      <form-item
        v-model="customWidthModel"
        :required="true"
        :scope="scope"
        name="customWidth"
        label="Custom width"
        :focusOnAppear="true"
        :disabled="isDesignReprint"
      />
      <form-item
        v-model="customHeightModel"
        :required="true"
        :scope="scope"
        name="customHeight"
        label="Custom height"
        :focusOnAppear="true"
        :disabled="isDesignReprint"
      />
      <form-item
        v-model="customUnitPriceModel"
        :required="true"
        :scope="scope"
        name="unitCost"
        label="Unit cost"
        type="number"
        :disabled="isDesignReprint"
      />
    </form-row>
  </div>
</template>

<script>

import {getUnitPrice} from "@/helpers/prices/finishings_creation";

export default {
  name: 'FinishingDesignProduct',
  props: {
    value: {
      required: true,
      type: Object
    },
    scope: {
      required: true,
      type: String
    },
    service: {
      required: true,
      type: String
    },
    design: {
      required: true,
      type: Object
    },
    parentForm: {
      required: true,
      type: Object
    },
    formData: {
      required: true,
      type: Object
    },
    formOptions: {
      required: true,
      type: Object
    },
    isDesignReprint: {
      default: false,
      type: Boolean
    }
  },
  data() {
    const form = Object.assign({}, JSON.parse(JSON.stringify(this.value)));
    return {
      form
    };
  },
  computed: {
    finishingTypeModel: {
      get() {
        if (this.value.print_type === null) return null;
        return (
          this.formData.printTypes.find(
            ({key}) => this.value.print_type === key
          ) || null
        );
      },
      set({key}) {
        this.value.print_type = key;
        this.value.product_type = null;
        this.value.paper_type = null;
        this.value.has_rounded_corners = null;
        this.value.size = null;
        this.value.custom_width = null;
        this.value.custom_size = null;
        this.value.custom_unit_price = null;
        this.value.custom_brand = null;
        this.value.quantity = null;
        this.value.border = null;
        this.value._border = null;
      }
    },
    finishingProductTypeModel: {
      get() {
        if (this.value.product_type === null) return null;
        return this.printTypeFormOptionsList('product_types', false).find(
          ({key}) => this.value.product_type === key
        );
      },
      set(option) {
        this.value.product_type = option.key;
        this.value._product_type = option.label;
        this.value.paper_type = null;
        this.value.size = null;
        this.value.button_loops = null;
        this.value.extra_colors = null;
        this.value.has_rounded_corners = null;
        this.value.quantity = null;
        this.value.border = null;
        this.value._border = null;
      }
    },
    roundedCornersModel: {
      get() {
        if (this.value.has_rounded_corners === null) return false;
        return !!this.value.has_rounded_corners;
      },
      set(val) {
        this.value.has_rounded_corners = val ? val : false;
      }
    },
    buttonLoopsModel: {
      get() {
        if (this.value.button_loops === null) return false;
        return !!this.value.button_loops;
      },
      set(val) {
        this.value.button_loops = val ? val : false;
      }
    },
    extraColorsModel: {
      get() {
        if (this.value.extra_colors === null) return false;
        return !!this.value.extra_colors;
      },
      set(val) {
        this.value.extra_colors = val ? val : false;
      }
    },
    finishingPaperTypeModel: {
      get() {
        if (this.value.paper_type === null) return null;
        return this.printTypeFormOptionsList('paper_types').find(
          ({key}) => this.value.paper_type === key
        );
      },
      set({key}) {
        this.value.paper_type = key;
      }
    },
    finishingSizeModel: {
      get() {
        if (this.value.size === null) return null;
        return this.printTypeFormOptionsList('sizes').find(
          ({key}) => this.value.size === key
        );
      },
      set({key}) {
        this.value.size = key;
        this.value.custom_size = null;
        this.value.custom_unit_price = null;
        this.value.custom_height = null;
        this.value.custom_width = null;
      }
    },
    finishingQuantityModel: {
      get() {
        if (this.value.quantity === null) return null;
        if (this.printTypeFormOptionsList('quantities').length) {
          return this.printTypeFormOptionsList('quantities').find(
            (value) => this.value.quantity === parseInt(value.label)
          );
        } else {
          return this.value.quantity
        }
      },
      set(value) {
        if(value.key !== undefined){
          this.value.quantity = parseInt(value.label);
        }
        else{
          this.value.quantity = value;
        }
      }
    },
    finishingBordersModel: {
      get() {
        if (this.value.border === null) return null;
        return this.printTypeFormOptionsList('borders').find(
          ({key}) => this.value.border === key
        );
      },
      set({key}) {
        this.value.border = key;
      }
    },
    customWidthModel: {
      get() {
        if (this.value.custom_width === null) return false;
        return this.value.custom_width;
      },
      set(val) {
        this.value.custom_width = val;
      }
    },
    customHeightModel: {
      get() {
        if (this.value.custom_height === null) return false;
        return this.value.custom_height;
      },
      set(val) {
        this.value.custom_height = val;
      }
    },
    customUnitPriceModel: {
      get() {
        if (this.value.custom_unit_price === null) return false;
        return this.value.custom_unit_price;
      },
      set(val) {
        this.value.custom_unit_price = val;
        const quantity = this.value.quantity;
        this.value.quantity = null;
        this.value.quantity = quantity;
      }
    },
    showRoundedCornersCheckbox() {
      return (
        this.value.has_rounded_corners || (this.finishingTypeModel && this.finishingTypeModel.label === 'Hang Tags')
      );
    },
    showPatchCheckbox() {
      return (
        (this.finishingTypeModel && this.finishingTypeModel.label === 'Patches' && this.value.product_type !== 3)
      );
    },
    allowedCustomQuantity() {
      return this.finishingTypeModel && this.finishingTypeModel.label === 'Patches' && !this.printTypeFormOptionsList('quantities').length
    },
    printTypeFormOptions() {
      const printTypeKey = this.value.print_type;
      if (printTypeKey === null) return [];

      return this.formOptions.print_type_options[printTypeKey];
    },

  },
  methods: {
    printTypeFormOptionsList(key, withProductType = true) {
      const printTypeObject = this.printTypeFormOptions;
      let optionsList = printTypeObject && printTypeObject[key] ? printTypeObject[key].slice() : [];
      if (this.value.product_type && withProductType) {
        return optionsList
          .filter(option => option.finishing_product_type_id === this.value.product_type);
      }
      return optionsList
    },
    getUnitPrice
  },
  watch: {
    form: {
      handler(val) {
        this.$emit('input', val);
      },
      deep: true
    },
  }
};
</script>
