<template>
  <div id="app" class="site-wrapper">
    <nprogress-container class="nprogress-container"></nprogress-container>
    <router-view name="nav" class="is-hidden-print"></router-view>
    <transition name="fade" mode="out-in">
      <router-view class="page" :key="$route.name"></router-view>
    </transition>
  </div>
</template>

<script>
import NprogressContainer from 'vue-nprogress/src/NprogressContainer.vue';
import pubnubService from '../services/pubnub.service';
import { mapGetters, mapMutations } from 'vuex';
import { SET_UNREAD_MESSAGES } from '../store/action-types';

import notify from '../modules/notifier/index';

export default {
  name: 'app-container',
  components: {
    NprogressContainer
  },
  mounted() {
    pubnubService.subscribeUpdateEvent('mark-as-read', this.markAsReadListener);
  },
  destroyed() {
    pubnubService.unsubscribeUpdateEvent('mark-as-read');
  },
  computed: {
    ...mapGetters(['userId', 'unreadMessages'])
  },
  methods: {
    ...mapMutations({
      setUnreadMessages: SET_UNREAD_MESSAGES
    }),
    markAsReadListener(message) {
      // Mark other unread messages which not on the current page.
      let unreadMessages = this.unreadMessages.filter(um => {
        return um.order_id === message.order_id;
      });

      if (unreadMessages) {
        this.setUnreadMessages(unreadMessages);
      }
    }
  }
};
</script>
