<template>
  <div class="mock-comment widget has-separator">
    <div v-if="!editable && internal_comment">
      <dl>
        <dt><strong>ARTWORK INTERNAL NOTES:</strong></dt>
        <dd class="break-word content-pre" v-html="internal_comment"></dd>
      </dl>
    </div>
    <div v-else-if="editable">
      <dl>
        <dt><strong>ARTWORK INTERNAL NOTES:</strong></dt>
        <dd>
          <wysiwyg ref="wysiwyg"
                   @value-changed="onInternalNotesChanged"
                   placeholder="*Enter note(s)"
                   :value="internal_comment"></wysiwyg>
        </dd>
      </dl>
    </div>
  </div>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep';
import Wysiwyg from '../../common/TextEditors/wysiwyg';

export default {
  name: 'mock-internal-comment',
  components: { Wysiwyg },
  props: {
    mock: {
      type: Object,
      required: true
    },
    editable: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      internal_comment:
        (this.mock && this.mock.internal_comment && cloneDeep(this.mock.internal_comment)) || ''
    };
  },
  watch: {
    mock(mock) {
      let internal_comment = (mock && cloneDeep(mock.internal_comment)) || '';
      this.$set(this, 'internal_comment', internal_comment);
    }
  },
  computed: {},
  methods: {
    getFormData() {
      return this.$refs.wysiwyg.getFormData();
    },
    onInternalNotesChanged(newValue){
      this.internal_comment = newValue;
    },
  }
};
</script>
