<template>
</template>

<script>
export default {
  name: 'design-info-digital',
  props: {
    design: {
      type: Object,
      required: true
    },
    order: {
      type: Object,
      required: true
    },
    mock: {
      type: Object,
      required: true
    },
    options: {
      type: Object,
      required: true
    },
    editable: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      mock_item: {
        location: '',
        width: 0,
        height: 0,
        location_comment:
          this.mock &&
          this.mock.items &&
          this.mock.items[0] &&
          this.mock.items[0].location_comment
            ? cloneDeep(this.mock.items[0].location_comment)
            : ''
      }
    };
  },
  computed: {
    print_type_label() {
      let label = 'N/A';
      if (
        this.design &&
        this.design.designable &&
        this.design.designable.item &&
        this.options['print_types']
      ) {
        let print_type_option = this.options['print_types'].filter(
          s => s.key === this.design.designable.item.print_type
        )[0];

        if (print_type_option && print_type_option.label) {
          label = print_type_option.label;
        }
      }

      return label;
    },
    size_label() {
      return this.design.designable.item.size_object
        ? this.design.designable.item.size_object.name
        : 'N/A';
    },
    quantity_label() {
      return this.design.designable.item.quantity_object
        ? this.design.designable.item.quantity_object.name
        : 'N/A';
    },
    paper_type_label() {
      return this.design.designable.item.paper_type_object
        ? this.design.designable.item.paper_type_object.name
        : 'N/A';
    },
    has_rounded_corners() {
      return this.design.designable.item.has_rounded_corners ? 'Yes' : 'No';
    }
  },
  methods: {
    getFormData() {
      return [this.mock_item];
    }
  }
};
</script>
