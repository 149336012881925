<template>
  <div>
    <filterOrder :filtersOptions="filterOptions"
                 :salesRep="false"
                 :artProducers="false"
                 :redo-category="false"
                 @takeFilters="onFilters"/>
    <br>
    <designs-grid :orders="gridData"
                 :ordersLoading="gridLoading"
                 :ordersPhase="activeOrdersPhase"
                 :getDesignProperty="getDesignProperty"
                 :mockPopupCallback="mockPopupCallback"
                 :designPrinterCallback="designPrinterCallback"
                 :stickyHeader="true"
                 :showTriangle="false"
                 :services="designServices"
                 @setPhase="setPhase"
                 @changePaymentStatus="changePaymentStatus"
                 @changeFollowUpStatus="updateMyOrdersDesignFollowUpStatus"
                 @sendSurveyCallback="sendMyOrdersSurveyCallback"
                 @sendMockCallback="sendMyOrdersMockCallback"
                 @changePrinterStatus="updateMyOrdersDesignPrinterStatus"
                 @changeBlankStatus="changeBlankStatus"
                 @onChangeFilesLinks="onChangeFilesLinks"
                 @onGridSortBy="gridUpdateOrders">
    </designs-grid>
    <!--      v-if="gridData.length"-->
    <paginator
      :pagesNumber="paginatorData.last_page"
      :prevPageUrl="paginatorData.prev_page_url"
      :nextPageUrl="paginatorData.next_page_url"
      :currentPage="paginatorData.current_page"
      :getListItems="loadMyOrdersPaginated"
      :showOffset="true"
      :totalNumber="paginatorData.total"
      :sortField="sortField"
      :sortDir="sortDir"/>
  </div>
</template>

<script>
import Vue from 'vue';
import { mapGetters, mapActions, mapMutations } from 'vuex';
import ordersGrid from '../orders/orders-grid.vue';
import paginator from '../common/paginator.vue';
import pubnubService from '../../services/pubnub.service';
import apiService from '../../services/api.service';
import { ORDER_FILTER_STATUSES } from '../../helpers/orders';

import {cloneDeep } from 'lodash';
import filterOrder from '../orders/filter-order.vue';

import {
  UPDATE_MY_ORDERS_PAYMENT_STATUS,
  UPDATE_MY_DESIGNS_BLANK_STATUS,
  UPDATE_MY_ORDERS_DESIGN_PRINTER,
  UPDATE_MY_ORDERS_DESIGN_FOLLOW_UP_STATUS,
  UPDATE_MY_ORDERS_DESIGN_MOCKS_COUNT,
  UPDATE_MY_ORDERS_PRINTER_STATUS,
  UPDATE_MY_ORDERS_DESIGN_PROGRESS,
  SET_MY_ORDERS_SURVEY_DATE,
  SET_MY_ORDERS_MOCK_SENT_DATE,
  UPDATE_MY_ORDERS_FINISHING_PROGRESS,
  LOAD_ACTIVE_ORDERS,
  SET_ACTIVE_ORDERS_PHASE,
  EMPTY_GRID_DATA
} from '../../store/action-types';
import DesignsGrid from '../orders/designs-grid';
export default {
  name: 'active-orders',
  data() {
    return {
      filters: {
        status: ORDER_FILTER_STATUSES.ORDER_FILTER_STATUS_ACTIVE
      },
      timezone: '',
      sortField: 'orders.estimate_becomes_to_order_at',
      sortDir: 'DESC',
      page: 1,
      perPage: 50,
      url: 'orders/dashboard_active_orders'
    };
  },
  computed: mapGetters([
    'gridLoading',
    'gridData',
    'paginatorData',
    'activeOrdersPhase',
    'orderFollowUpStatuses',
    'designPrintStatuses',
    'finishingPrintStatuses',
    'filterOptions',
    'designServices'
  ]),
  components: {
    DesignsGrid,
    ordersGrid,
    paginator,
    filterOrder
  },
  created() {
    this.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  },
  mounted() {
    this.gridUpdateOrders(this.sortField, this.sortDir);
    pubnubService.subscribeUpdateEvent('order', this.orderUpdateHandler);
    pubnubService.subscribeUpdateEvent('mock', this.mockUpdateHandler);
  },
  beforeDestroy() {
    this.emptyGridData();
  },
  destroyed() {
    pubnubService.unsubscribeUpdateEvent('order');
    pubnubService.unsubscribeUpdateEvent('mock', this.mockUpdateHandler);
  },
  methods: {
    ...mapActions({
      setActiveOrdersPhase: SET_ACTIVE_ORDERS_PHASE,
      updateMyOrdersPaymentStatus: UPDATE_MY_ORDERS_PAYMENT_STATUS,
      updateMyDesignsBlankStatus: UPDATE_MY_DESIGNS_BLANK_STATUS,
      updateMyOrdersMocksCount: UPDATE_MY_ORDERS_DESIGN_MOCKS_COUNT,
      updateMyOrdersDesignFollowUpStatus: UPDATE_MY_ORDERS_DESIGN_FOLLOW_UP_STATUS,
      updateMyOrdersDesignPrinterStatus: UPDATE_MY_ORDERS_PRINTER_STATUS,
      updateMyOrdersDesignPrinter: UPDATE_MY_ORDERS_DESIGN_PRINTER,
      updateMyOrdersDesignProgress: UPDATE_MY_ORDERS_DESIGN_PROGRESS,
      updateMyOrdersFinishingProgress: UPDATE_MY_ORDERS_FINISHING_PROGRESS,
      sendMyOrdersSurveyCallback: SET_MY_ORDERS_SURVEY_DATE,
      sendMyOrdersMockCallback: SET_MY_ORDERS_MOCK_SENT_DATE,
      loadActiveOrders: LOAD_ACTIVE_ORDERS,
      emptyGridData: EMPTY_GRID_DATA
    }),
    ...mapMutations({
      setDesignTrackings: 'setDesignTrackings',
      setMyDesignsBlankStatus: 'updateMyDesignsBlankStatus'
    }),
    mockUpdateHandler(message) {
      if (message.order_id) {
        const updatedOrders = this.activeOrders.find(
          order => order.id === message.order_id
        );
        if (updatedOrders) {
          this.loadActiveOrders({
            page: this.paginatorData.current_page,
            perPage: this.perPage,
            sortField: this.sortField,
            sortDir: this.sortDir
          });
        }
      }
    },

    loadMyOrdersPaginated(page, url, perPage, sortField, sortDir) {
      this.perPage = perPage;
      this.page = page;
      this.url = url;

      let pagination_filter = {
        url: url,
        perPage: perPage,
        sortField: sortField,
        sortDir: sortDir,
        timezone: this.timezone
      };

      if (page) {
        pagination_filter.page = page;
      }

      let params = Object.assign(pagination_filter, this.filters);

      return this.loadActiveOrders(params);
    },

    // Update orders in store on orders sorting.
    gridUpdateOrders(sortField, sortDir) {
      // Update values in component to populate it for "loadUnclaimedMocksPaginated"
      this.sortField = sortField;
      this.sortDir = sortDir;

      let filters = {
        url: this.url,
        perPage: this.perPage,
        sortField: this.sortField,
        sortDir: this.sortDir,
        timezone: this.timezone
      };

      if (this.page) {
        filters.page = this.page;
      }

      let params = Object.assign(filters, this.filters);
      this.loadActiveOrders(params);
    },

    setPhase(phase) {
      this.setActiveOrdersPhase(phase);
    },
    getDesignProperty(orderId, designId, prop) {
      return this.myOrders
        .filter(o => o.id === orderId)[0]
        .designs.filter(d => d.id === designId)[0][prop];
    },
    // payment
    changePaymentStatus({ orderId, status }) {
      this.updateMyOrdersPaymentStatus({
        orderId,
        status
      });
    },
    // blank
    changeBlankStatus({ designId, status }) {
      this.updateMyDesignsBlankStatus({
        designId,
        status
      });
    },
    // mocks
    mockPopupCallback(orderId, designId, mock) {
      this.setPhase('M');
      this.updateMyOrdersMocksCount({
        orderId,
        designId,
        mock
      });

      let sent = false;
      this.sendMyOrdersMockCallback({
        orderId,
        designId,
        sent
      });

      if ('design' in mock) {
        this.updateMyOrdersDesignProgress({
          orderId,
          designId,
          progress: mock.design
        });
      }
    },
    // printer
    designPrinterCallback(orderId, designId, printer, design) {
      this.updateMyOrdersDesignPrinter({
        orderId,
        designId,
        printer,
        design
      });
    },
    // on send design files
    onChangeFilesLinks({ itemId, orderId, modelType, model }) {
      switch (modelType) {
        case 'design':
          this.updateMyOrdersDesignProgress({
            orderId,
            designId: itemId,
            progress: model
          });
          break;
        case 'finishing':
          this.updateMyOrdersFinishingProgress({
            orderId,
            finishingId: itemId,
            progress: model
          });
          break;
      }
    },
    orderUpdateHandler(message) {
      if (message && message.action) {
        let action = JSON.parse(message.action);
        if (action.method && typeof this[action.method] === 'function') {
          this[action.method](action.args);
        }
      } else if (message && !message.action) {
        this.gridUpdateOrders(this.sortField, this.sortDir);
      }
    },
    updateTrackingsFedEx({ orderId, designId }) {
      this.updateTrackings({ orderId, designId });
    },
    updateTrackingsUPS({ orderId, designId }) {
      this.updateTrackings({ orderId, designId });
    },
    updateTrackings({ orderId, designId }) {
      apiService.getShippingLabels(designId).then(trackings => {
        this.setDesignTrackings({
          orderId: orderId,
          designId: designId,
          trackings: trackings
        });
      });
    },
    updateDesignBlanksProgress(payload) {
      this.setMyDesignsBlankStatus(payload);
      this.updateMyOrdersDesignProgress(payload);
    },
    onFilters(filters) {
      console.log('filters',filters)
      this.filters = cloneDeep(filters);
      this.page = 1;
      this.gridUpdateOrders(this.sortField, this.sortDir);
    }
  }
};
</script>
