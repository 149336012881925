<template>
    <div>
       <logo/>
        <form @submit.prevent="attempt" class="box">
            <div  :class="['message', 'is-' + message.type]" v-if="messages.length >0" v-for="message in messages">
                <div class="message-body">
                    {{ message.message }}
                </div>
            </div>
            <div class="field">
                <label class="label">Email Address</label>
                <div class="control">
                    <input v-model="user.email" required class="input" type="email" placeholder="jsmith@example.org">
                </div>
            </div>
            <div class="field">
                <div class="control">
                    <button type="submit" class="button is-primary" :disabled="processing">Restore</button>
                </div>
            </div>
        </form>
        <div class="field has-text-centered">
            <div class="control">
                <router-link to="/auth/login" >Go to login</router-link>
            </div>
        </div>
    </div>
</template>

<script>
import { FORGOT_PASSWORD } from '../../store/action-types';
import { forEach, isEmpty } from 'lodash';
import Logo from "../partials/logo.vue";

export default {
    components: {Logo},
  data() {
    return {
      user: {
        email: null
      },
      messages: [],
      processing: false
    };
  },

  methods: {
    attempt: function(e) {
      this.messages = [];
      if (this.user.email) {
        this.processing = true;

        this.$store
          .dispatch(FORGOT_PASSWORD, this.user)
          .then(data => {
            this.messages.push({
              type: 'info',
              message:
                'Please check your email and use the link for restoring your password.'
            });
            this.processing = false;
          })
          .catch(response => {
            this.processing = false;
            var that = this;

            if (
              response.body &&
              response.body.messages &&
              response.body.messages.length
            ) {
              response.body.messages.forEach(function(message) {
                that.messages.push({ type: 'danger', message: message });
              });
            } else {
              this.messages.push({
                type: 'danger',
                message: 'Something wrong :('
              });
            }
          });
      } else {
        this.messages.push({
          type: 'danger',
          message: 'Please fill the form fields'
        });
      }
    }
  }
};
</script>
