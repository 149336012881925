<template>
  <div :id="messengerId" :class="{messenger: true, 'is-embedded':isEmbedded}">
    <div v-if="notCustomer" class="tabs is-small has-shadow is-fullwidth">
      <ul>
        <li v-for="(tabRoles, channelName) in channels"
            v-roles="tabRoles"
            :class="{link: true, 'is-active': channel == channelName}">
          <a @click="setChannel(channelName)">
            {{channelName === 'internal' ? 'Threadbird' : channelName |
            capitalize}}
            <span v-if="unreadInChannel(channelName)"
                  :class="{'tag is-small msg-tag is-warning': true, 'is-danger': isMentionedMeInChannel(channelName)}">
              {{unreadInChannel(channelName)}}
            </span>
            <span v-if="startedMsg(channelName)" class="icon is-small">
              <span class="ff-email-plane"></span>
            </span>
          </a>
        </li>
      </ul>
    </div>

    <div id="messages-list" v-on:load.capture="scrollDown()">
      <article v-for="(message, key) in messages" class="media message-box m-0">
        <div
          :class="{'media-content': true, 'for-me': messageForMe(message.mentioned_ids)}">
          <div class="content">
            <div class="cols-flex msg-head">
              <div class="flex ">
                <figure
                  class="avatar-container avatar-container_with-text-wrapping">
                  <avatar :user="message.author"></avatar>
                </figure>
                <strong class="message-author message-name" v-if="message.author">
                  {{ message.author.first_name }} {{ message.author.last_name }}
                </strong>
                <span v-if="message.is_system"
                      data-balloon="Threadbird System"
                      :data-balloon-pos="key ? 'top' : 'down'"
                      class="icon is-small is-primary">
                    <i class="ff-info-circled" aria-hidden="true" style="line-height: 23px; padding-left: 4px; font-size: 12px;"></i>
                </span>
              </div>
              <div class="flex">
                <small class="is-light message-date" v-html="splitDate(message.created_time)"></small>
                <i v-if="isAdmin" class="icon delete_message_btn icon__smaller ff-cross-mark" @click="deleteMessage(message.id)"/>
              </div>
            </div>
            <a v-if="s3Domain && message.attachment" :href="s3Domain + message.attachment.filepath" target="_blank">
              <div class="is-inline-block gallery-thumb box image is-96x96">
                <img v-if="isThumbable(message.attachment.filepath)"
                     :src="s3Domain + message.attachment.filepath"/>
                <span v-else
                      :class="['file-thumb', 'thumb-type-' + fileExt(message.attachment.filepath)]">
                  <span>{{ fileExt(message.attachment.filepath) }}</span>
                </span>
              </div>
            </a>
            <div class="msg-body"
                 v-html="newLines(message.body)"
                 v-linkified
                 @click="maybeClosePopup"/>
            <a v-if="message.action"
               @click="msgAction(message.action)">
              {{msgLabel(message.action)}}
            </a>
            <br/>
          </div>
        </div>
      </article>
      <div v-if="!messages.length" class="no-msgs-text has-text-centered">
        There are no messages yet
      </div>
      <div class="spacer"></div>
    </div>

    <div class="media msgr-footer">
      <div class="media-content is-fullwidth">
        <p class="control">
          <at :wrapResult="true"
              :members="membersByChannel"
              name-key="title">
            <template slot="item" scope="s">
                <b v-text="s.item.title.split(':')[0]" :title="s.item.title.split(':')[1]"></b>&nbsp;<span v-text="s.item.title.split(':')[1]" :title="s.item.title.split(':')[1]"></span>
            </template>
          <quill-editor
            v-if="messagingAvailable"
            :options="editorOption"
            @change="onDivInput($event)"
            :content="this.message[this.channel]"
            @keypress.enter="submit">
          </quill-editor>
        </at>
        </p>
        <nav class="level pt-1">
          <div class="level-left">
            <div class="level-item">
              <button @click="submit"
                      :class="{'button is-primary': true, 'is-loading': processing[channel]}"
                      :disabled="possibleToSendMsg">
                Send
              </button>
              <div
                :data-balloon="!isProcessing ? 'Attach file' : 'Please wait until previous upload finish'"
                :data-balloon-pos="!isProcessing ? 'right' : 'top'"
                :class="{'icon is-medium attach-icon-ctr':true, 'attach-to-msg': !isProcessing}"
                v-roles="canSendFilesInMessenger">

                <file-upload v-show="!isProcessing"
                             title=""
                             class="ff-2x ff-paperclip"
                             name="file"
                             input-id="file2"
                             extensions="jpeg,jpg,png,zip,gzip,tif,tiff,ai,eps,psd,pdf"
                             accept=".zip,.gzip,.psd,image/vnd.adobe.photoshop,application/octet-stream,application/postscript,application/pdf,image/*"
                             :multiple="false"
                             :drop="true"
                             v-model="file[channel]"
                             ref="upload"></file-upload>
                <div v-show="isProcessing" class="spinner-icon"></div>
              </div>
            </div>
          </div>
          <p v-if="file[channel].length" class="level-item has-text-centered">
            <progress class="progress is-info"
                      :value="file[channel][0].progress" max="100">
              {{file[channel][0].progress}}%
            </progress>
          </p>
        </nav>
        <div v-if="file[channel].length" class="file-holder">
          <span>{{file[channel][0].name}}</span>
          <button @click="removeFile(channel)"
                  class="button btn is-ghost">
            <i class="ff-cross-mark" aria-hidden="true"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.is-embedded {
  .ql-editor{
    max-height: 20vh;
  }
  .level{
    margin-bottom: 0;
  }
}
</style>
<style scoped lang="scss">
.msg-head {
  justify-content: space-between;
  .flex {
    align-self: flex-start;
  }
}

.msgr-footer {
  padding-top: 10px;
  border-top: 1px solid #dbdbdb;
}

.media-content {
  padding: 0px 10px;
}
.is-embedded .media-content {
  padding: 0;
}

.icon.is-primary {
  color: #1992d1;
}

.file-holder {
  display: flex;
  align-items: center;
}

.message-box {
  .avatar-container {
    margin-right: 4px;

    & _with-text-wrapping {
      float: left;
    }
  }
  .box {
    margin: 20px 0 10px;
  }
}

.msg-body {
  line-height: 1.25em;
  word-break: break-word;
  font-size: 0.9rem;
  margin-top: 5px;

  strong {
    font-size: 0.9em;
  }

  .is-light {
    font-size: 0.9em;
  }
}

.textarea-contenteditable {
  word-break: break-word;
}

.message-name-container {
  float: left;
  width: calc(100% - 40px);
  flex-wrap: wrap;

  .message-name {
    min-width: 160px;
  }
}

.messenger .textarea {
  min-height: 102px;
}
.messenger .ql-editor{
  min-height: 120px;
}

.message-name {
  align-items: center;
}

.message-author {
  font-size: 0.85rem;
  line-height: 21px;
  padding: 0;
  display: block;
  // white-space: nowrap;
  // overflow: hidden;
  // text-overflow: ellipsis;
}

.message-date {
  padding-left: 1px;
  text-align: right;
  color: #666;
}

.is-embedded {
  &.messenger {
    overflow: hidden;
  }
  #messages-list {
    margin-right: -2px;
    padding: 0;
  }
  .media-content {
    padding: 3px;
  }
  .message-author {
    max-width: 96px;
  }
}
.no-msgs-text {
  margin-bottom: 15px;
}

.delete_message_btn {
  cursor: pointer;

  &:hover {
    color: #e8004c;
  }
}
</style>

<script>
import Vue from 'vue';
import FileUpload from 'vue-upload-component';
import { mapGetters } from 'vuex';
import s3Service from '../../services/s3.service.js';
import At from '../../modules/at/At';
import apiService from '../../services/api.service';
import bus from '../../bus';
import Avatar from './messanger-avatar/';
import alertify from 'alertify.js'
import { quillEditor } from 'vue-quill-editor'
import Quill from 'quill';
import Mentioned from '../../modules/at/atQuillModule';
Quill.register('formats/mentioned', Mentioned);
import {
  openTrackings,
  isMentionedMe,
  redirectToUPS,
  redirectToFedEx
} from '../../helpers/messages';
import { differenceBy, cloneDeep } from 'lodash';
import moment from 'moment';

import {
  LOAD_MESSAGES_BY_ORDER_ID,
  SEND_MESSAGE,
  SUBSCRIBE_TO_ORDER,
  MARK_MESSAGE_AS_READ
} from '../../store/action-types';
import { checkRoles } from '../../router/checkRoles';
import { isThumbable, fileExt } from '../../helpers/files';
import { canSendFilesInMessenger } from '../../helpers/role-access';

export default {
  name: 'messenger',
  props: {
    data: {
      type: Object,
      default: () => null
    },
    isEmbedded: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    file: {
      handler(file) {
        const channel = this.channel;

        if (
          !file[channel].length ||
          (this.filesTemp.length &&
            this.filesTemp[0] &&
            this.filesTemp[0].name === file[channel][0].name)
        ) {
          return;
        }

        this.isSending = true;
        this.processing[this.channel] = true;
        this.filesTemp = file[channel].slice();

        s3Service
          .sendFilesToOrder(this.orderId, this.file[channel], this.$refs.upload)
          .then(files => {
            this.isSending = false;
            this.processing[channel] = false;
            this.file[channel][0].path = files[0].path;
          });
      },
      deep: true
    },
    data: {
      handler: function(data) {
        if (data && data.id) {
          this.orderId = data.id;
        }
      },
      deep: true
    }
  },
  data() {
    return {
      orderId: this.data.id,
      upload: {},
      message: {
        internal: '',
        customer: '',
        printer: ''
      },
      messengerId: 'messenger-' + (Math.random() + '').substr(2),
      messagingAvailable: true,
      channel: 'internal',
      channels: {
        internal: [
          'admin',
          'csr',
          'production-coordinator',
          'purchaser',
          'art-producer'
        ],
        customer: [
          'admin',
          'csr',
          'production-coordinator',
          'purchaser',
          'art-producer',
          'customer'
        ],
        printer: [
          'admin',
          'csr',
          'production-coordinator',
          'purchaser',
          'art-producer',
          'printer'
        ]
      },
      file: {
        internal: [],
        customer: [],
        printer: []
      },
      filesTemp: [],
      members: [],
      processing: {
        internal: false,
        customer: false,
        printer: false
      },
      isSending: false,
      files: {},
      canSendFilesInMessenger,
      editorOption: {
        theme: 'snow',
        boundary: document.body,
        modules: {
          toolbar: [
            ['bold', 'italic'],
            [{ 'list': 'bullet' }],
          ]
        },
        placeholder: 'Enter message ...'
      }
    };
  },
  computed: {
    ...mapGetters([
      'userProfile',
      'allMessages',
      's3Domain',
      'unreadOrdersMessages',
      'oids',
      'roles'
    ]),
    membersByChannel() {
      let allowedRoles = this.channels[this.channel];
      return this.members.filter(member => {
        return allowedRoles.indexOf(member.role) !== -1;
      });
    },
    notCustomer() {
      return !checkRoles(this.roles, ['customer'], true);
    },
    isAdmin(){
      return checkRoles(this.roles, ['admin'], true);
    },
    messages() {
      const orderMsgs = this.allMessages[this.orderId];
      let msgs = [];
      if (orderMsgs) {
        msgs = cloneDeep(orderMsgs[this.channel]);
        if (msgs) {
          msgs.forEach((message, key) => {
            if (message && message.created_at) {
              msgs[key].created_time = moment
                .utc(message.created_at)
                .local()
                .format('MM/DD/YY LT');
            }
            this.$store.dispatch(MARK_MESSAGE_AS_READ, {
              orderId: this.orderId,
              message
            });
          });
        }
      }
      this.scrollDown();
      return msgs ? msgs : [];
    },
    isProcessing() {
      return this.processing[this.channel];
    },
    possibleToSendMsg() {
      const channel = this.channel;

      return (
        ((!this.message[channel] ||
          !this.clearMessageLength(this.message[channel])) &&
          !this.file[channel].length) ||
        this.processing[channel]
      );
    }
  },
  components: {
    At,
    FileUpload,
    Avatar,
    quillEditor
  },
  methods: {
    splitDate(value) {
      const parts = value.split(' ');
      const date = parts.shift();
      const time = parts.join(' ');
      return `${date}<br>${time}`;
    },
    loadMessages(orderId) {
      this.$store.dispatch(LOAD_MESSAGES_BY_ORDER_ID, orderId).then(msg => {
        this.scrollDown();
      });
    },

    clearMessageLength(message) {
      message = message
        .replaceAll('<br>', '')
        .replaceAll('&nbsp;', '')
        .replaceAll(' ', '')
        .replaceAll('<p><br></p>','');

      return message.length;
    },

    maybeClosePopup(e) {
      if (e.target.localName === 'a') {
        this.$emit('close');
      }
    },

    loadMembers() {
      apiService
        .getAccountsByOrder(this.orderId)
        .then(data => {
          this.members = data;
        })
        .catch(error => {
          console.log(error);
        });
    },

    newLines(text) {
      return text ? text.replace(/(?:\r\n|\r|\n)/g, '<br />') : '';
    },

    msgAction(action) {
      const obj = JSON.parse(action);
      switch (obj.method) {
        case 'openTrackings':
          openTrackings(...obj.args);
          Vue.nextTick(() => {
            this.$emit('close');
          });
          break;
        case 'updateTrackingsUPS':
          redirectToUPS(obj.args);
          Vue.nextTick(() => {
            this.$emit('close');
          });
          break;
        case 'updateTrackingsFedEx':
          redirectToFedEx(obj.args);
          Vue.nextTick(() => {
            this.$emit('close');
          });
          break;
      }
    },

    msgLabel(action) {
      const obj = JSON.parse(action);
      return obj.label;
    },

    submit(event) {
      if (event.shiftKey) return;

      if (this.isSending || this.processing[this.channel]) return;
      event.preventDefault();

      if (this.file[this.channel].length) {
        this.sendMessage(this.file[this.channel][0].path, this.channel);
      } else {
        this.sendMessage(null, this.channel);
      }
    },

    onDivInput(e) {
      this.message[this.channel] = e.html;
    },

    sendMessage(filePath = '', channel = '') {
      if (
        (!this.message[channel] && !this.file[channel].length) ||
        !this.clearMessageLength(this.message[channel])
      ) {
        return;
      }

      this.processing[channel] = true;
      this.isSending = true;

      const data = {
        order_id: this.orderId,
        message: this.message[channel].replace('<p><br></p>',''),
        channel: channel,
        filepath: filePath
      };

      /*if (!this.oids.includes(parseInt(this.orderId))) {
        this.$store.dispatch(SUBSCRIBE_TO_ORDER, {
          orderId: parseInt(this.orderId),
          vue: this
        });

        alertify.log(
          "You are not related to this order so now you're temporary subscribed to messages notifications.<br>" +
            ' The subscription will expire after the page reload.'
        );
      }*/

      this.$store.dispatch(SEND_MESSAGE, data).then(messages => {
        this.loadMessages(this.orderId);
        this.scrollDown();
        this.refreshMessage(channel);
        this.removeFile(channel);
        this.processing[channel] = false;
        this.isSending = false;
        this.file[channel] = [];
        this.filesTemp = [];
      });
    },

    refreshMessage(channel) {
      this.message[channel] = '';
      this.messagingAvailable = false;
      setTimeout(() => {
        this.messagingAvailable = true;
        // setTimeout(() => {
        //   document.querySelector(`#${this.messengerId} #message-field`).focus();
        // });
      });
    },

    removeFile(channel) {
      this.file[channel] = [];
      this.filesTemp = [];
    },

    setChannel(channel) {
      this.channel = channel;
      // document.querySelector(
      //   `#${this.messengerId} #message-field`
      // ).innerHTML = this.message[channel];
      this.scrollDown();
    },

    scrollDown() {
      Vue.nextTick(() => {
        const messagesList = document.querySelector(
          `#${this.messengerId} #messages-list`
        );
        if (messagesList) {
          messagesList.scrollTop = messagesList.scrollHeight;
        }
      });
    },

    fileExt: fileExt,
    isThumbable: isThumbable,

    unreadInChannel(channel) {
      let ordersUnread = this.unreadOrdersMessages[this.orderId];
      if (ordersUnread) {
        ordersUnread = ordersUnread.filter(msg => {
          return msg.channel == channel;
        });
        return ordersUnread.length;
      }

      return null;
    },

    startedMsg(channel) {
      return this.channel != channel && this.message[channel];
    },

    isMentionedMeInChannel(channel) {
      let ordersUnread = this.unreadOrdersMessages[this.orderId];

      if (!ordersUnread) return false;
      ordersUnread = ordersUnread.filter(msg => msg.channel == channel);
      return isMentionedMe(ordersUnread);
    },

    messageForMe(ids) {
      ids = JSON.parse(ids);

      if (!ids) return false;

      return !!ids.filter(id => {
        return id == this.userProfile.id;
      }).length;
    },
    deleteMessage(message_id) {
      alertify
        .okBtn('Delete')
        .confirm('Are you sure that you want to delete the message?', ev => {
          return apiService
            .deleteMessage(message_id)
            .then(data => {
              this.loadMessages(this.orderId);
              alertify.log(data.message);
            })
            .catch(err => {
              console.log('Error:', err);
            });
        });
    },
  },
  mounted() {
    if (checkRoles(this.roles, ['customer'], true)) {
      this.channel = 'customer';
    } else if (checkRoles(this.roles, ['printer'], true)) {
      this.channel = 'printer';
    }

    this.loadMessages(this.orderId);
    this.loadMembers();
    bus.$on('updated-accounts-by-order', () => this.loadMembers());
  }
};
</script>
