import moment from 'moment';
import { toPriceFormat } from '../grid-helper';
import { checkRoles } from '../../router/checkRoles';
import { DATE_FORMAT } from '../estimates';

export const gridHeaders = [
  { head: 'Order', subhead: '', sortField: 'id', sortDir: 'ASC' },
  { head: 'Account/Design', subhead: '' },
  {
    head: 'Date',
    subhead: '',
    sortField: 'estimate_becomes_to_order_at',
    sortDir: 'ASC'
  },
  { head: 'Total', subhead: '' },
  { head: 'Discount', subhead: '' },
  { head: 'Discount Description', subhead: '' }
];

export const getGridData = (orders, userRoles, actions) => {
  return orders.map(order => {
    let discountOrdersData = [];
    // order id.
    let idString = order.id;

    if (
      checkRoles(userRoles, [
        'admin',
        'csr',
        'production-coordinator',
        'purchaser',
        'art-producer'
      ])
    ) {
      if (order.is_order) {
        idString = `<a href="/#/orders/${order.id}/details">${order.id}</a>`;
      } else {
        idString = `<a href="/#/estimates/${order.id}/details">${order.id}</a>`;
      }
    }

    // Date.
    let createdAt = moment(order.created_at).format(DATE_FORMAT);

    // Account
    let accountName =
      order.company ||
      (order.contact_firstname &&
        order.contact_lastname &&
        `${order.contact_firstname} ${order.contact_lastname}`) ||
      '';

    accountName =
      accountName.split('').length > 18
        ? `${accountName.substring(0, 18)}...`
        : accountName;

    // Total.
    let amount = toPriceFormat((+order.total_price || 0).toFixed(2));

    // Tax
    let discount = `<span class="clr-green">${toPriceFormat(
      (+order.discount_amount || 0).toFixed(2)
    )}</span>`;
    let discount_description = order.discount_description || '';
    discount_description = discount_description
      ? `<span title="${discount_description}" class="ellipsis" style="max-width: 140px; display: inline-block;">${discount_description}</span>`
      : ' - ';

    discountOrdersData.push(
      { column: 'id', value: order.id },
      { column: 'Order', value: idString },
      { column: 'Account/Design', value: accountName },
      { column: 'Date', value: createdAt },
      { column: 'Total', value: amount },
      { column: 'Discount', value: discount },
      { column: 'Discount Description', value: discount_description }
    );

    return discountOrdersData;
  });
};
