/**
 * Calculate design total.
 *
 * @param design
 * @param prices
 * @returns {number}
 */
export const getDesignTotal = (design, prices) => {
  let otherTotal = design.specific.items.reduce((acc, item) => {
    return acc + getItemTotal(item);
  }, 0);

  return otherTotal + +design.common.upcharge;
};

export const getItemTotal = item => {
  return +item.price * +item.quantity;
};
