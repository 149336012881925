<template>
  <div v-if="data" class="columns">
    <div class="column">
      <strong>{{label}}:</strong>
      <div v-if="!editable && data.location" class="label has-text-weight-normal is-marginless">
        {{data.location}}
      </div>
      <div class="columns is-marginless is-paddingless">
        <div class="column is-marginless is-paddingless">
          <form-select
            tooltip
            tooltip-ico="pensil"
            tooltip-position="left"
            :id="`hem-tag-location-${finishingKey}`"
            v-model="data.location"
            v-if="editable"
            :name="`hem-tag-location-${finishingKey}`"
            :scope="`form${finishingKey}`"
            :hideLabel="true"
            :label="'Tag Location'"
            :options="{all: hemTagLocationsList}"/>
          <div class="control">
            <form-input v-model="data.thread_colors"
                        tooltip
                        tooltip-ico="pensil"
                        tooltip-position="left"
                        v-if="editable"
                        :id="`hem-tag-thread-colors-${finishingKey}`"
                        :scope="`form${finishingKey}`"
                        rules="required"
                        :name="`hem-tag-thread-colors-${finishingKey}`"
                        step="0.01"
                        min="0.00"
                        max="100"
                        :horizontal="true"
                        :hideLabel="true"
                        :label="'Thread Colors'"
                        placeholder="*Enter Thread Colors"/>
          </div>
          <div class="control" v-if="!editable && data.thread_colors">
            <span class="label has-text-weight-500 is-italic">Thread Colors:</span>
            <span>{{ data.thread_colors }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { cloneDeep } from 'lodash';
import FormInput from '../../../common/form/input';
import PmsColorMultiselect from '../../../common/form/pms-color-multiselect';
import FormSelect from '../../../common/form/select';

import { hemTagLocationsList } from '../../../../helpers/revision';

export default {
  name: 'hem-tag',
  props: {
    finishing: {
      type: Object,
      required: true
    },
    finishingKey: {
      type: Number,
      required: true
    },
    label: {
      type: String,
      default: 'Hem tags'
    },
    design: {
      type: Object,
      required: true
    },
    options: {
      type: Object,
      required: true
    },
    editable: {
      type: Boolean,
      default: false
    }
  },
  components: { FormSelect, FormInput },
  data() {
    return {
      data: (this.finishing && cloneDeep(this.finishing)) || {},
      locationsList: {},
      designOptions: (this.options && cloneDeep(this.options)) || {}
    };
  },
  watch: {
    design() {
      this.$nextTick(() => {
        this.prepareLocationsList();
      });
    },
    finishing(finishing) {
      let data = (finishing && cloneDeep(finishing)) || {};
      this.$set(this, 'data', data);
      this.$nextTick(() => {
        this.prepareLocationsList();
      });
    },
    options: {
      handler: function(options) {
        this.$set(this, 'designOptions', options);
        this.$nextTick(() => {
          this.prepareLocationsList();
        });
      },
      deep: true
    }
  },
  computed: {
    hemTagLocationsList: () => hemTagLocationsList
  },
  methods: {
    getFormData() {
      return this.data;
    },
    prepareLocationsList() {
      if (this.designOptions && this.designOptions.locations) {
        let options = cloneDeep(this.designOptions);

        const keys = Object.keys(options.locations);
        let locationsList = {};
        keys.forEach(k => {
          const innerKeys = Object.keys(options.locations[k]);
          locationsList[k] = {};
          return innerKeys.forEach(ik => {
            locationsList[k][k + ' - ' + options.locations[k][ik]] =
              options.locations[k][ik];
          });
        });
        this.$set(this, 'locationsList', locationsList);
      }
    }
  },
  mounted() {
    this.prepareLocationsList();
  }
};
</script>
<style>
</style>
