<template>
  <div v-if="data" class="columns">
    <div class="column">
      <strong>{{label}}:</strong>
      <div class="columns is-marginless is-paddingless">
        <div class="column is-marginless is-paddingless">
          <div class="control">
            <form-input v-model="data.thread_colors"
                        tooltip
                        tooltip-ico="pensil"
                        tooltip-position="left"
                        v-if="editable"
                        :id="`thread_colors${finishingKey}`"
                        :scope="`form${finishingKey}`"
                        type="text"
                        rules="required"
                        name="thread_colors"
                        step="0.01"
                        min="0.00"
                        max="100"
                        :horizontal="true"
                        :hideLabel="true"
                        :label="'Thread Colors'"
                        placeholder="*Enter Thread Colors"/>
          </div>
          <div class="control" v-if="!editable && data.thread_colors">
            <span class="label is-italic has-text-weight-500">Thread Colors:</span>
            <span>{{data.thread_colors}}</span>
          </div>
          <strong>Tag Removal: </strong><span class="is-uppercase">{{tagRemoval}}</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { cloneDeep } from 'lodash';
import FormInput from '../../../common/form/input';
import PmsColorMultiselect from '../../../common/form/pms-color-multiselect';
import FormSelect from '../../../common/form/select';

export default {
  name: 'woven-neck-tag',
  props: {
    finishing: {
      type: Object,
      required: true
    },
    finishingKey: {
      type: Number,
      required: true
    },
    label: {
      type: String,
      default: 'Woven Neck Tag'
    },
    design: {
      type: Object,
      required: true
    },
    options: {
      type: Object,
      required: true
    },
    editable: {
      type: Boolean,
      default: false
    }
  },
  components: { FormInput },
  data() {
    return {
      data: (this.finishing && cloneDeep(this.finishing)) || {}
    };
  },
  watch: {
    finishing(finishing) {
      let data = (finishing && cloneDeep(finishing)) || {};
      this.$set(this, 'data', data);
    }
  },
  computed: {
    tagRemoval() {
      return (
        (this.data &&
          this.data.finishable &&
          this.data.finishable.woven_neck_tag_type &&
          this.data.finishable.woven_neck_tag_type === 'Remove Original Tag' &&
          'Yes') ||
        'No'
      );
    }
  },
  methods: {
    getFormData() {
      return this.data;
    }
  },
  mounted() {}
};
</script>
<style>
</style>
