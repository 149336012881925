<template>
  <div v-if="garment.id">
    <h2>Garment details</h2>
    <table class="table is-fullwidth">
      <thead>
      <tr>
        <th v-if="this.design.designable.item.product">Product</th>
        <th>Shape</th>
        <th>Size</th>
        <th class="ta-right">QTY</th>
      </tr>
      </thead>
      <tbody>
        <tr class="has-separator">
          <th v-if="this.design.designable.item.product">{{ this.design.designable.item.product_object.name }}</th>
          <td>{{shape_label}}</td>
          <td>{{size_label}}</td>
          <td class="ta-right">{{this.design.designable.item.quantity | itemsNumberFormat}}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { canApproveMock } from '../../../../helpers/role-access';

export default {
  name: 'garment-sticker-details',
  props: {
    design: {
      type: Object,
      required: true
    },
    options: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    canApproveMock
  }),
  computed: {
    garment() {
      return this.design ? this.design.designable.item : {};
    },
    shape_label() {
      let label = 'N/A';

      if (this.design.designable.item.shape_object) {
        label = this.design.designable.item.shape_object.name;

        if (this.design.designable.item.parent_shape_object) {
          label =
            this.design.designable.item.parent_shape_object.name + ': ' + label;
        }
      }
      return label;
    },
    size_label() {
      return this.design.designable.item.size_object
        ? this.design.designable.item.size_object.name
        : 'N/A';
    }
  }
};
</script>

<style scoped>
h2 {
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 1rem;
  margin-bottom: 10px;
}
tbody {
  background: #dedede;
}
th {
  border: none;
}
tbody td {
  border-bottom: 1px solid #d6d5d5;
}
.has-separator:last-child {
  border-bottom: 1px solid;
  border-color: #eae6e6;
}
</style>
