<template>
  <div class="form-item">
    <label v-if="label" class="form-item-label">{{ label }}</label>
    <quill-editor v-model:content="editorData" contentType="html" :options="editorConfig"/>
  </div>
</template>

<script>
// require styles
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';
import 'quill/dist/quill.bubble.css';

import {quillEditor} from 'vue-quill-editor';

export default {
  name: 'wysiwyg',
  props: {
    value: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: '*Enter text here'
    }
  },
  components: {
    quillEditor
  },
  data() {
    return {
      editorConfig: {
        placeholder: this.placeholder,
        theme: 'snow',
        modules: {
          toolbar: [
            ['bold', 'italic'],
            [{list: 'bullet'}],
            [{color: []}, {background: []}],
            ['clean']
          ]
        }
      }
    };
  },
  methods: {
    getFormData() {
      return this.editorData;
    }
  },
  computed: {
    editorData: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('value-changed', value);
      }
    }
  }
};
</script>

<style scoped>
.quill-editor {
  background-color: white;
}
</style>
