<template>
  <div class="option-reference-design">
    <dl class="option-reference-design__name">
      <template>
        <dt v-if="props.option.common.printed_before">
          <span v-if="props.option.common.printed_before_order_id">Order #{{props.option.common.printed_before_order_id}}</span>
          <span class="option-reference-design__label clr-primary1" v-if="value && value.common && (value.common.printed_before_design_id === props.option.common.printed_before_design_id)">selected</span>
        </dt>
        <dt v-else>
          <span v-if="props.option.common.reference_order_id">Order #{{props.option.common.reference_order_id}}</span>
          <span class="option-reference-design__label clr-primary1" v-if="value && value.common && (value.common.reference_design_id === props.option.common.reference_design_id)">selected</span>
        </dt>
      </template>
      <dd class="option-reference-design__info__column">
        <span v-html="$mx_highlightPhrase(props.option.common.name, query)"></span>
      </dd>
    </dl>
  </div>
</template>
<script>
export default {
  name: 'option-reference-design',
  props: ['props', 'query', 'value']
};
</script>
<style lang="scss">
.option-reference-design {
  padding: 7px 20px;
  font-size: 14px;
  line-height: 20px;
  background: #fff;
  color: #333;
  margin: -10px -15px;
  &__name {
    display: flex;
    justify-content: space-between;
    & + * {
      margin-top: 7px;
    }
  }
  &__info {
    display: flex;
    color: rgba(0, 0, 0, 0.54);
    font-size: 12px;
    &__icon {
      width: 12px;
      height: 20px;
      flex: 0 0 12px;
      margin-right: 5px;
      fill: #dbdbdb;
    }
    &__value {
      flex-grow: 1;
    }
    &__column {
      padding: 0 7px;
      display: flex;
      &:first-child {
        padding-left: 0;
        flex: 0 0 33.33%;
        width: 33.33%;
      }
      &:last-child {
        padding-right: 0;
        text-align: right;
      }
    }
  }
  em {
    background-color: rgba(255, 211, 32, 0.1);
    font-style: normal;
  }
  .multiselect__option--highlight & {
    background-color: #e9f4fd;
  }
}
</style>
