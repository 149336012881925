<template>
  <div>
    <form-row class>
      <form-multiselect
        v-model="productModel"
        :options="productOptions"
        :required="true"
        label="Product"
        :config="{trackBy: 'key'}"
        :scope="scope"
        name="product"
        :disabled="isDesignReprint && !isPreviousVersionDesign"
      />
    </form-row>
    <form-row>
      <form-multiselect v-for="(productAddon, key) in productAddons"
                        v-model="value.addons"
                        :options="productAddonOptions(productAddon)"
                        :required="true"
                        :label="key"
                        :scope="scope"
                        name="addons"
                        :disabled="isDesignReprint && !isPreviousVersionDesign"
                        :key="key"
      />
    </form-row>
    <form-row>
      <form-multiselect
        v-model="sizeModel"
        :options="sizeOptions"
        :required="true"
        label="Size"
        :config="{trackBy: 'key'}"
        :scope="scope"
        name="size"
        :disabled="isDesignReprint && !isPreviousVersionDesign"
      />
      <form-item
        v-model="quantityModel"
        :options="formData.quantities.filter(option => option.available)"
        :required="true"
        label="Quantity"
        :after-label="getUnitPrice(design,formOptions)"
        :config="{
              trackBy: 'key',
            }"
        rules="numeric|min_value:50"
        :scope="scope"
        name="quantity"
      />
    </form-row>
    <form-row v-if="this.value.size === 'empty_size'">
      <form-item
        v-model="customWidthModel"
        :required="true"
        :scope="scope"
        name="customWidth"
        label="Custom width"
        :focusOnAppear="true"
        :disabled="isDesignReprint"
      />
      <form-item
        v-model="customHeightModel"
        :required="true"
        :scope="scope"
        name="customHeight"
        label="Custom height"
        :focusOnAppear="true"
        :disabled="isDesignReprint"
      />
      <form-item
        v-model="customUnitPriceModel"
        :required="true"
        :scope="scope"
        name="unitCost"
        label="Unit cost"
        type="number"
        :disabled="isDesignReprint"
      />
    </form-row>
    <router-link
      v-if="isPreviousVersionDesign"
      target="_blank"
      :to="reprintLink">
      Reprint {{design.common.printed_before_order_id}}
    </router-link>
  </div>
</template>

<script>

import {getUnitPrice} from "@/helpers/prices/sticker";

export default {
  name: 'StickerDesignProduct',
  props: {
    value: {
      required: true,
      type: Object
    },
    scope: {
      required: true,
      type: String
    },
    service: {
      required: true,
      type: String
    },
    design: {
      required: true,
      type: Object
    },
    parentForm: {
      required: true,
      type: Object
    },
    formData: {
      required: true,
      type: Object
    },
    formOptions: {
      required: true,
      type: Object
    },
    isDesignReprint: {
      default: false,
      type: Boolean
    }
  },
  data() {
    const form = Object.assign({}, JSON.parse(JSON.stringify(this.value)));
    return {
      form,
      numberOfAddons: 0,
      addons: []
    };
  },
  computed: {
    productOptions() {
      return this.formOptions.products.map((item) => {
        return {
          key: item.id,
          label: item.name
        };
      });
    },
    productModel: {
      get() {
        if (this.value.product === null) return null;
        return this.productOptions.find(
          ({key}) => this.value.product === key
        );
      },
      set(option) {
        this.value.product = option.key;
        this.value.addons = null;
        this.value.size = null;
        this.value.shape = null;
        this.value.parent_shape = null;
        // this.value.quantity = null;
        this.value._product = option.name;
        this.value.model = this.formOptions.products.find(({id}) => option.key === id);
      }
    },
    productAddons() {
      if (this.value.product === null) return [];
      return this.formOptions.options[this.value.product];
    },
    sizeOptions() {
      if (this.value.product === null) return [];
      return this.formOptions.sizes[this.value.product].map((item) => {
        if (item === 'empty_size') {
          return {
            key: item,
            label: 'Custom (Specify Below in Comments)'
          };
        } else {
          return {
            key: item,
            label: item
          };
        }
      });
    },
    quantityModel: {
      get() {
        if (this.value.quantity === null) return null;
        return this.value.quantity;
      },
      set(option) {
        this.value.quantity = option;
      }
    },
    sizeModel: {
      get() {
        if (this.value.size === null) return null;
        return this.sizeOptions.find(
          ({ key }) => this.value.size === key
        );
      },
      set({ key }) {
        this.value.size = key;
      }
    },
    customWidthModel: {
      get() {
        if (this.value.custom_width === null) return false;
        return this.value.custom_width;
      },
      set(val) {
        this.value.custom_width = val;
      }
    },
    customHeightModel: {
      get() {
        if (this.value.custom_height === null) return false;
        return this.value.custom_height;
      },
      set(val) {
        this.value.custom_height = val;
      }
    },
    customUnitPriceModel: {
      get() {
        if (this.value.custom_unit_price === null) return false;
        return this.value.custom_unit_price;
      },
      set(val) {
        this.value.custom_unit_price = val;
        const quantity = this.value.quantity;
        this.value.quantity = null;
        this.value.quantity = quantity;
      }
    },
    isPreviousVersionDesign(){
      return  this.isDesignReprint && this.value.product_object === null ;
    },
    reprintLink(){
      return `/orders/${this.design.common.printed_before_order_id}/details`
    }
  },
  methods: {
    getUnitPrice,
    resetItemCost() {
      if (this.value
        && this.value.common.redo_design_id === null
        && this.value.cost !== undefined) {
        this.value.cost = null;
      }
    },
    productAddonOptions(options) {
      return options.map((item) => {
        item.label = item.product_addon_name
        return item
      });
    },
  },
};
</script>
