<template>
  <div class="form-section form-section-contact" ref="wrapper">
    <div class="step-section">
      <header class="step-section__header">
        <h2 class="step-section__title">{{ 'Contact Info' | uppercase }}</h2>
      </header>
      <div class="step-section__body">
        <div class="form-row">
          <form-multiselect
            class="form-item-customer"
            v-model="contact.model"
            :isLoading="contact.isLoading"
            :options="contact.options"
            :required="!isNewCustomer"
            selectClass="theme-autocomplete"
            label="Search for an existing customer to create an estimate for"
            :config="{
              internalSearch: false,
              preserveSearch: true,
              label: 'email',
              trackBy: 'account_id'
            }"
            :placeholder="'Search for Contact Person, Company Name or Email'"
            :scope="scope"
            name="account"
            @search-change="onAccountSearchChange"
            @input="onAccountChange"
            :clearable="true"
            ref="contact"
          >
            <option-contact
              :props="props"
              :query="contact.searchQuery"
              :value="contact.model"
              slot="option"
              slot-scope="props"
            ></option-contact>

            <div slot="placeholder" slot-scope="props" class="form-item-customer__placeholder">
              <svg height="18px" version="1.1" viewBox="0 0 18 18" width="18px" xmlns="http://www.w3.org/2000/svg" xmlns:sketch="http://www.bohemiancoding.com/sketch/ns" xmlns:xlink="http://www.w3.org/1999/xlink">
                <path d="M12.5,11 L11.7,11 L11.4,10.7 C12.4,9.6 13,8.1 13,6.5 C13,2.9 10.1,0 6.5,0 C2.9,0 0,2.9 0,6.5 C0,10.1 2.9,13 6.5,13 C8.1,13 9.6,12.4 10.7,11.4 L11,11.7 L11,12.5 L16,17.5 L17.5,16 L12.5,11 L12.5,11 Z M6.5,11 C4,11 2,9 2,6.5 C2,4 4,2 6.5,2 C9,2 11,4 11,6.5 C11,9 9,11 6.5,11 L6.5,11 Z" />
              </svg>
              <p>Search for Contact Person, Company Name or Email</p>
            </div>

            <div slot="noOptions" slot-scope="props" v-if="!contact.searchQuery.length">Start typing...</div>

            <div slot="noResult" class="theme-autocomplete__no-result" slot-scope="props">
              <div class="theme-autocomplete__no-result__message" v-if="!contact.isLoading">No search results matching your query was found</div>
              <div class="theme-autocomplete__no-result__message" v-else>Please, wait...</div>
              <div class="theme-autocomplete__no-result__actions" v-if="!isNewCustomer && !contact.isLoading">
                <a
                  href="#"
                  @click.prevent="addNewCustomer(); $refs.contact.$refs.search.blur();"
                >Add New</a>
              </div>
            </div>

          </form-multiselect>
        </div>
        <div class="form-row" v-if="contact.model">
          <div class="form-item">
            <dl class="list-customer-info">
              <dt>Customer</dt>
              <dd>{{ contact.model.first_name }} {{ contact.model.last_name }}</dd>
              <template
                v-if="contact.model.company_id && contact.model.company_id !== 'create-new' && contact.model.company && contact.model.company.name">
                <dt>Company</dt>
                <dd>{{ contact.model.company.name}}</dd>
              </template>
              <template v-else>
                <dt class="company-edit">Company</dt>
                <dd class="company-edit">
                  <div class="form-row">
                    <form-multiselect
                      v-model="companyModel"
                      :isLoading="company.isLoading"
                      :options="companyOptions"
                      :required="true"
                      label="Search for company name"
                      :config="{
                        internalSearch: false,
                        preserveSearch: true,
                        label: 'name'
                      }"
                      :placeholder="'Type to find companies...'"
                      :scope="scope"
                      name="company"
                      @search-change="onCompanySearchChange"
                    ></form-multiselect>
                    <div class="form-item" v-if="value.company_id !== 'create-new'"></div>
                    <form-item
                      v-model="value.company"
                      type="text"
                      id="company"
                      name="company"
                      rules="required"
                      label="New Company Name"
                      :scope="scope"
                      :required="true"
                      :focusOnAppear="true"
                      @blur="onCustomCompanyBlur"
                      v-else
                    ></form-item>
                  </div>
                </dd>
              </template>
              <dt>Email</dt>
              <dd>{{ contact.model.email }}</dd>
              <dt>Phone</dt>
              <dd>{{ contact.model.phone }}</dd>
            </dl>
          </div>
        </div>
        <div class="form-row">
          <div class="form-item">or</div>
        </div>
        <div class="form-row">
          <div class="form-item">
            <button
              class="btn2 is-transparent"
              @click.prevent="addNewCustomer"
              :disabled="isNewCustomer"
            >Add New Customer</button>
          </div>
        </div>
        <template v-if="isNewCustomer">
          <div class="form-row">
            <form-multiselect
              v-model="companyModel"
              :isLoading="company.isLoading"
              :options="companyOptions"
              :required="true"
              label="Company"
              :config="{
                internalSearch: false,
                preserveSearch: true,
                label: 'name'
              }"
              :placeholder="'Type to find companies...'"
              :scope="scope"
              name="company"
              @search-change="onCompanySearchChange"
            ></form-multiselect>
            <div class="form-item" v-if="value.company_id !== 'create-new'"></div>
            <form-item
              v-model="value.company"
              type="text"
              id="company"
              name="company"
              rules="required"
              label="New Company Name"
              :scope="scope"
              :required="true"
              :focusOnAppear="true"
              @blur="onCustomCompanyBlur"
              v-else
            ></form-item>
          </div>
          <div class="form-row">
            <form-item
              v-model="emailModel"
              type="text"
              id="email"
              name="email"
              rules="required|email"
              label="Email"
              :scope="scope"
              :required="true"
            >
              <div slot="input-after" v-if="contact.duplicate">This email is already taken by existing customer. Click <a href="#" @click.prevent="confirmChangeAccount">here</a> to use this account.</div>
            </form-item>
            <div class="form-item"></div>
          </div>
          <div class="form-row">
            <form-item
              v-model="value.contact_firstname"
              type="text"
              id="contact_first_name"
              name="contact_first_name"
              rules="required|max:255|alpha_spaces"
              label="Contact First Name"
              :scope="scope"
              :required="true"
            ></form-item>
            <div class="form-item"></div>
          </div>
          <div class="form-row">
            <form-item
              v-model="value.contact_lastname"
              type="text"
              id="contact_last_name"
              name="contact_last_name"
              rules="required|max:255|alpha_spaces"
              label="Contact Last Name"
              :scope="scope"
              :required="true"
            ></form-item>
            <div class="form-item"></div>
          </div>
          <div class="form-row">
            <form-item
              v-model="value.phone"
              type="text"
              id="phone"
              name="phone"
              rules="required|verify_phone"
              label="Phone"
              :scope="scope"
              :required="true"
            ></form-item>
            <div class="form-item"></div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import StepBase from './StepBase';
import alertify from 'alertify.js';
import { debounce } from 'lodash';
import { FORM_ESTIMATE_TEMPLATE } from '@/helpers/estimates';
import OptionContact from '@/components/common/form/OptionContact';

export default {
  name: 'form-estimate-contact',
  extends: StepBase,
  components: {
    OptionContact
  },
  props: ['account'],
  data() {
    let contact = null;
    if (this.account && this.account.account_id !== undefined) {
      contact = JSON.parse(JSON.stringify(this.account));
    }

    return {
      isNewCustomer: false,
      isDirty: false,
      contact: {
        model: contact,
        options: [],
        searchQuery: '',
        isLoading: false,
        duplicate: null
      },
      company: {
        options: [],
        isLoading: false
      }
    };
  },
  computed: {
    emailModel: {
      get() {
        if (!this.value.email) return '';
        else return this.value.email.trim().toLowerCase();
      },
      set(val) {
        if (!val) return;
        this.value.email = val.trim().toLowerCase();
      }
    },
    companyOptions: {
      get() {
        return this.company.options.concat([
          { id: 'create-new', name: 'Create New' }
        ]);
      },
      set(val) {
        if (!Array.isArray(val)) return;
        this.company.options = val.slice();
      }
    },
    companyModel: {
      get() {
        const option = this.companyOptions.find(
          company => company.id === this.value.company_id
        );
        if (option) return option;

        if (!this.value.company_id) return null;

        return { id: this.value.company_id, name: this.value.company };
      },
      set(company) {
        const { name, id } = company;
        this.value.company_id = id;
        this.value.company = id === 'create-new' ? '' : name;

        if (id !== 'create-new') {
          this.$apiService
            .checkContactCompany(company.name)
            .then(({ result, company_id }) => {
              this.$emit(
                'tax-id',
                (result.account.company && result.account.company.tax_id) ||
                  null
              );
              this.$emit(
                'shipping-address',
                result.account.company &&
                  result.account.company.shipping_addresses[0]
              );
              this.$emit(
                'payment-terms',
                result.account.company
                  ? result.account.company.terms
                  : result.account.terms
              );
            })
            .catch(error => {
              console.log(error);
            });
        }
      }
    },
    isStepLoading() {
      return this.isLoading || this.contact.isLoading || this.company.isLoading;
    }
  },
  watch: {
    value: {
      handler() {
        this.isDirty = true;
      },
      deep: true
    },
    'contact.model': function(contact) {
      this.$emit('account', contact);

      if (!contact) {
        this.$emit('shipping-address', null);
        this.$emit('payment-terms', null);
        return;
      }

      if (contact.company) {
        this.$emit('shipping-address', contact.company.shipping_addresses[0]);
      }

      this.$emit(
        'payment-terms',
        contact.company ? contact.company.terms : contact.terms
      );
    },
    'value.email': debounce(function(value) {
      if (!this.isNewCustomer) return;
      if (!value || value.length === 0) return;
      this.$apiService
        .checkContactCustomer(value)
        .then(response => {
          if (!response.result.account) return;
          if (response.result.account.account_id) {
            // user exist
            alertify.success('user exist in the system');
            this.isNewCustomer = false;
            this.resetForm();
            this.fillAccountData(response.result.account);
            this.contact.model = response.result.account;
          } else {
            // user not in the system but exist in not approved orders
            this.$mx_alertModal({
              title: 'User already exists',
              text:
                '<p>A previous Estimate has already been created for this User. If you edit the User’s Account Details, all previous Estimates will reflect this change.</p>'
            }).then(() => {
              this.$emit(
                'shipping-address',
                response.result.account.company.shipping_addresses[0]
              );
              this.$emit(
                'payment-terms',
                response.result.account.company.terms
              );
              this.fillAccountData(response.result.account);
            });
          }
        })
        .catch(error => {
          console.log(error);
        });
    }, 500)
  },
  created() {
    if (this.value.id === 'new') {
      this.isNewCustomer = true;
    }

    if (!this.account && this.value.account_id && this.value.email) {
      this.contact.isLoading = true;
      this.$apiService
        .estimateFormLoadContactsList(this.value.email)
        .then(({ contacts_list }) => {
          this.contact.model = contacts_list.data[0];
          if (!this.contact.model.company && !this.contact.model.company_id) {
            this.contact.model.company = {};
            this.contact.model.company_id =
              this.value.company_id || 'create-new';
            this.contact.model.company.name = this.value.company || null;
          }
          this.onAccountChange(this.contact.model);
        })
        .catch(error => {
          console.log(error);
        })
        .then(() => {
          this.contact.isLoading = false;
        });
    }
  },
  methods: {
    addNewCustomer() {
      if (this.isNewCustomer) return;
      this.isNewCustomer = true;
      this.contact.model = null;
      this.resetForm();
      this.value.id = 'new';
      this.$nextTick(() => {
        this.isDirty = false;
      });
    },
    resetForm() {
      Object.keys(this.value).forEach(field => {
        this.value[field] = FORM_ESTIMATE_TEMPLATE.account[field];
      });
    },
    onAccountSearchChange: debounce(function(searchQuery) {
      if (!searchQuery || searchQuery.length === 0) {
        this.contact.isLoading = false;
        return;
      }

      this.contact.isLoading = true;
      this.contact.searchQuery = searchQuery;

      this.$nextTick(() => {
        this.$apiService
          .estimateFormLoadContactsList(searchQuery)
          .then(({ contacts_list }) => {
            this.contact.options = [];
            this.contact.options = contacts_list.data.slice();
          })
          .catch(error => {
            console.log(error);
          })
          .then(() => {
            this.contact.isLoading = false;
          });
      });
    }, 500),
    onCompanySearchChange: debounce(function(searchQuery) {
      if (!searchQuery || searchQuery.length === 0) {
        this.company.isLoading = false;
        return;
      }

      this.company.isLoading = true;
      this.company.searchQuery = searchQuery;

      this.$apiService
        .estimateFormLoadCompaniesList(searchQuery)
        .then(({ companies_data }) => {
          this.company.options = [];
          this.company.options = companies_data.data.slice();
        })
        .catch(error => {
          console.log(error);
        })
        .then(() => {
          this.company.isLoading = false;
        });
    }, 500),
    onAccountChange(account) {
      if (!account) {
        this.resetForm();
        return;
      }

      if (!this.isNewCustomer || !this.isDirty) {
        this.resetForm();
        this.fillAccountData(account);
        this.isNewCustomer = false;
        return;
      }

      this.$mx_confirmModal({
        title: 'Fields were changed',
        text: '<p>Are you sure? Your entered data will be lost.</p>',
        yesText: 'Yes',
        noText: 'No'
      })
        .then(() => {
          this.isNewCustomer = false;
          this.resetForm();
          this.fillAccountData(account);
        })
        .catch(() => {
          this.contact.model = null;
        });
    },
    fillAccountData(account) {
      this.value.account_id = account.account_id || '';
      this.value.contact_firstname = account.first_name || '';
      this.value.contact_lastname = account.last_name || '';
      this.value.phone = account.phone || '';
      this.value.email = account.email || '';
      this.value.avatax_id = account.avatax_id || '';

      this.value.company_id = account.company_id;
      this.value.company = account.company && account.company.name;

      this.value.address_source = account.address_source || 'company';
    },

    onEmailBlur(event) {
      const value = event.target.value;
      this.$apiService
        .checkContactCustomer(value)
        .then(response => {
          if (!response.result.account) return;
          if (response.result.account.account_id) {
            // user exist
            alertify.success('user exist in the system');
            this.isNewCustomer = false;
            this.resetForm();
            this.fillAccountData(response.result.account);
            this.contact.model = response.result.account;
          } else {
            // user not in the system but almost exist
            this.$mx_confirmModal({
              title: 'Fields were changed',
              text:
                '<p>If you change account`s data field it will influence on other estimates of this account.</p>',
              yesText: 'I agree',
              cancelText: 'I disagree'
            })
              .then(() => {
                this.$emit(
                  'shipping-address',
                  response.result.account.company.shipping_addresses[0]
                );
                this.$emit(
                  'payment-terms',
                  response.result.account.company.terms
                );
                this.fillAccountData(response.result.account);
              })
              .catch(() => {
                this.resetForm();
                this.value.email = '';
              });
          }
        })
        .catch(error => {
          console.log(error);
        });
    },

    onCustomCompanyBlur(event) {
      const companyName = event.target.value;
      this.$apiService
        .checkContactCompany(companyName)
        .then(({ result }) => {
          this.$emit(
            'shipping-address',
            result.account.company &&
              result.account.company.shipping_addresses[0]
          );
          let paymentTerms = null;
          if (result.account.company) {
            paymentTerms = result.account.company.terms;
          } else if (this.contact.model) {
            paymentTerms = this.contact.model.terms;
          }
          this.$emit('payment-terms', paymentTerms);
        })
        .catch(error => {
          console.log(error);
        });
    }
  }
};
</script>
<style lang="scss">
.form-section-contact {
  .form-row {
    max-width: 518px;
  }
  .new-customer {
    padding-top: 30px;
    & > * {
      display: inline-block;
      vertical-align: middle;
      & + * {
        margin-left: 10px;
      }
    }
  }
}
.theme-autocomplete {
  &__no-result {
    margin: -5px;
    cursor: default;
    &__message {
      text-align: center;
      font-size: 12px;
      line-height: 16px;
      padding: 25px 10px;
      color: #737373;
    }
    &__actions {
      border-top: 1px solid #dbdbdb;
      padding: 9px 18px;
    }
  }
  &.multiselect {
    color: #333;
    min-height: 0;
    height: 35px;

    &--active {
      z-index: 10;
    }
    &--active .multiselect__input {
      width: 100% !important;
    }
  }

  .multiselect {
    &__spinner {
      height: auto;
      width: 30px;
      bottom: 1px;
    }
    &__placeholder {
      margin-bottom: 0;
      display: block;
      padding-top: 0;
      font-weight: 400 !important;
    }

    &__tags {
      padding: 4px 10px;
      border-radius: 2px;
      border: 1px solid #dbdbdb;
      background: #fff;
      height: 100%;
      min-height: 0;
      display: flex;
      align-items: center;
      cursor: pointer;
    }

    &__select {
      display: none;
      width: 30px;
      height: auto;
      top: 0;
      bottom: 0;
      right: 0;
      padding: 0;

      &:before {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 0;
        height: 0;
        transform: translateX(-50%);
        margin-top: -2.5px;
      }
    }

    &__single {
      font-size: 14px;
      margin-bottom: 0;
      background: transparent;
      padding: 0;
      white-space: nowrap;
      text-overflow: ellipsis;
      display: block;
      overflow: hidden;
      border-radius: 0;
    }

    &__input {
      min-width: 0;
      margin-bottom: 0;
      background: transparent;
      padding: 0 !important; // ag-grid override
      height: 20px !important; // ag-grid override
      color: inherit !important; // ag-grid override
      border: none !important; // ag-grid overrida
      font-size: 14px;
      display: block;
    }

    &__content {
      display: block !important;
    }

    &__content-wrapper {
      border-radius: 2px;
      transform: translateY(2px);
      padding: 0 !important;
      border: 1px solid #dbdbdb;
      background-color: #fff;
      z-index: 6;
      width: auto;
      min-width: 100%;
    }

    &__option {
      font-size: 14px;
      line-height: 16px;
      padding: 5px;
      min-height: 0;
    }

    &__element .multiselect__option {
      display: block;
      background: none;
      color: #333;
      font-size: 14px;
      line-height: 16px;
      font-weight: 400;
      min-height: 0;
      padding: 10px 15px;
      white-space: normal;
      text-overflow: ellipsis;
      overflow: hidden;
      color: rgba(0, 0, 0, 0.87);

      &--highlight {
        background-color: #e9f4fd;
      }

      &--selected {
        font-weight: 700;
      }
    }
  }

  &.multiselect--above {
    .multiselect__content-wrapper {
      transform: translateY(-2px);
    }
  }

  $select-color: #333;

  &.multiselect--active {
    .multiselect {
      color: $select-color;

      &__tags {
        border-color: #2b99ee;
      }
    }
  }

  &.is-inline {
    &.multiselect {
      height: 24px;
    }

    .multiselect {
      &__tags {
        padding: 0 30px 0 0;
        background: none;
        border-width: 0;
        border-radius: 0;
      }

      &__content-wrapper {
        min-width: 300px;
        width: 100%;
      }
    }

    &:hover,
    &.multiselect--active {
      .multiselect {
        &__tags {
          border-bottom-width: 1px;
        }
      }
    }
  }
}

.form-item-customer {
  &.form-item {
    .form-item-label {
      font-size: 14px;
      line-height: 20px;
      font-weight: 400;
      color: rgba(#000, 0.54);
      & + * {
        margin-top: 10px;
      }
    }
  }
  &__placeholder {
    display: flex;
    font-size: 12px;
    align-items: center;
    svg {
      margin-right: 2px;
      fill: #dbdbdb;
      & + * {
        margin-left: 10px;
      }
    }
    p {
      flex-grow: 1;
    }
  }
}

.list-customer-info {
  dt {
    font-weight: bold;
    float: left;
    margin-right: 5px;
    &:after {
      content: ':';
    }
  }
  dd {
    overflow: hidden;
  }

  dt.company-edit {
    float: none;
    margin-bottom: 5px;
  }

  dd.company-edit {
    overflow: visible;
    padding: 10px 0;
  }
}
</style>
