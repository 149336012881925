<template>
  <div>
    <div class="columns is-vcentered">
      <div class="column is-9">
        <MainInfo
          :order="order"
          :design="design"
          class="is-pulled-left"
        />
      </div>
      <div class="column">
        <VersionSwitcher
          :editable="editable"
          class="is-pulled-right is-3"
        />
      </div>
    </div>
    <DesignTabs
      :editable="editable"
      :order="order"
      :design="design"
      :designs="designs"
    />
    <ImageGallery
      ref="images"
      :images="mock.attachments"
      :editable="editable"
      :design="design"
      :mock="mock"
    />
    <TagsWidget
      ref="warnings"
      :classes="'tags-widget'"
      :flexHolder="true"
      :title="'WARNINGS:'"
      :modelTags="mock.warnings"
      :tagsList="mocksWarnings"
      :editable="editable"/>
    <RevisionCreateActions
      :editable="editable"
      :isReadyToSaveFinal="isReadyToSaveFinal"
      :design="design"
      @saveDraft="saveDraft"
      @saveFinal="saveFinal"
    />
    <component :is="designGarmentType" :design="design" :options="options"/>
  </div>
</template>

<script>
import MainInfo from './widgets/main-info.vue';
import DesignTabs from './widgets/design-tabs.vue';
import VersionSwitcher from './widgets/version-switcher.vue';
import ImageGallery from './widgets/image-gallery.vue';
import GarmentApparelDetails from './widgets/garments-info-types/garment-apparel-details.vue';
import GarmentPosterDetails from './widgets/garments-info-types/garment-poster-details.vue';
import GarmentButtonDetails from './widgets/garments-info-types/garment-button-details.vue';
import GarmentStickerDetails from './widgets/garments-info-types/garment-sticker-details.vue';
import GarmentDigitalDetails from './widgets/garments-info-types/garment-digital-details.vue';
import GarmentFinishing_creationDetails from './widgets/garments-info-types/garment-finishing_creation-details.vue';
import GarmentOtherDetails from './widgets/garments-info-types/garment-other-details.vue';
import RevisionCreateActions from './widgets/revision-create-actions.vue';
import TagsWidget from '../common/tags-widget';

export default {
  name: 'design-details-content',
  components: {
    TagsWidget,
    MainInfo,
    DesignTabs,
    VersionSwitcher,
    ImageGallery,
    GarmentApparelDetails,
    GarmentPosterDetails,
    GarmentButtonDetails,
    GarmentStickerDetails,
    GarmentDigitalDetails,
    GarmentFinishing_creationDetails,
    GarmentOtherDetails,
    RevisionCreateActions
  },
  props: {
    order: {
      type: Object,
      required: true
    },
    mock: {
      type: Object,
      required: true
    },
    design: {
      type: Object,
      required: true
    },
    designs: {
      type: Array,
      required: true
    },
    mocksWarnings: {
      type: Array,
      required: true
    },
    editable: {
      type: Boolean,
      default: false
    },
    options: {
      type: Object,
      required: true
    },
    isReadyToSaveFinal: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    designGarmentType() {
      const defServices = ['poster', 'button', 'sticker', 'digital', 'other','finishing_creation'];
      const def = 'apparel';
      if (defServices.includes(this.design.service)) {
        return `garment-${this.design.service}-details`;
      }
      return `garment-${def}-details`;
    }
  },
  methods: {
    getFormData() {
      return Promise.all([
        this.$refs.images.getFormData(),
        this.$refs.warnings.getFormData()
      ]).then(([files, warnings]) => {
        return { files, warnings };
      });
    },
    sendFiles(data) {
      return this.$refs.images.sendFiles(data);
    },
    saveDraft() {
      this.$emit('saveDraft');
    },
    saveFinal() {
      this.$emit('saveFinal');
    }
  }
};
</script>
