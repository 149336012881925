<template>
  <div class="">
    <div class="">
      <div class="is-half is-offset-one-quarter">
        <form @submit.prevent="submitForm">
          <div class="control">
            <input type="radio" id="customer" value="customer" v-model="address_source">
            <label for="customer">Customer</label>
          </div>
          <div class="control">
            <input type="radio" id="company" value="company" v-model="address_source">
            <label for="company">Company</label>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
  import Vue from 'vue';

  export default {
    name: 'select-address',
    props: ['additional'],
    data () {
      return {
        address_source: '',
      }
    },
    watch: {
      address_source(value) {
        this.additional.popupCallback(value, true);
        this.$emit('close')
      },
    },
    destroyed() {
      if (!this.address_source) {
        this.$emit('cancel')
      }
    }
  };
</script>
