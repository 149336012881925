<template>
  <div v-if="garment.id">
    <h2>Garment details</h2>
    <table class="table is-fullwidth">
      <thead>
      <tr>
        <th>Item Description</th>
        <th>Size</th>
        <th>Has Rounded Corners</th>
        <th>QTY Total</th>
      </tr>
      </thead>
      <tbody>
      <tr class="is-name">
        <td colspan="4">PRINT TYPE: {{print_type_label}}</td>
      </tr>
      <tr class="has-separator">
        <td>{{paper_type_label}}</td>
        <td>{{size_label}}</td>
        <td>{{has_rounded_corners}}</td>
        <td class="is-pulled-right">Total: {{quantity_label | itemsNumberFormat}}</td>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { canApproveMock } from '../../../../helpers/role-access';

export default {
  name: 'garment-digital-details',
  props: {
    design: {
      type: Object,
      required: true
    },
    options: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    canApproveMock
  }),
  computed: {
    garment() {
      return this.design ? this.design.designable.item : {};
    },
    print_type_label() {
      let label = 'N/A';
      if (
        this.design &&
        this.design.designable &&
        this.design.designable.item &&
        this.options['print_types']
      ) {
        let print_type_option = this.options['print_types'].filter(
          s => s.key === this.design.designable.item.print_type
        )[0];

        if (print_type_option && print_type_option.label) {
          label = print_type_option.label;
        }
      }

      return label;
    },
    size_label() {
      return this.design.designable.item.size_object
        ? this.design.designable.item.size_object.name
        : 'N/A';
    },
    quantity_label() {
      return this.design.designable.item.quantity_object
        ? this.design.designable.item.quantity_object.name
        : 'N/A';
    },
    paper_type_label() {
      return this.design.designable.item.paper_type_object
        ? this.design.designable.item.paper_type_object.name
        : 'N/A';
    },
    has_rounded_corners() {
      return this.design.designable.item.has_rounded_corners ? 'Yes' : 'No';
    }
  }
};
</script>

<style scoped>
h2 {
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 1rem;
  margin-bottom: 10px;
}
.is-name td {
  background: #dedede;
}
th {
  border: none;
}
tbody td {
  border-bottom: 1px solid #d6d5d5;
}
.has-separator:last-child {
  border-bottom: 1px solid;
  border-color: #eae6e6;
}
</style>
