import Vue from 'vue';
import VueResource from 'vue-resource';

Vue.use(VueResource);

export class ApiService {
  constructor() {
    this.api = Vue.http;
    this.prefix = '/api/';
  }
  get(url, isTextRes = false, query) {
    if (url.match(/^http/) === null) {
      url = this.prefix + url;
    }

    return this.api.get(url, { params: query }).then(res => {
      return isTextRes ? res.text() : res.json();
    });
  }

  post(url, data, isTextRes = false) {
    return this.api.post(this.prefix + url, data).then(res => {
      return isTextRes ? res.text() : res.json();
    });
  }

  put(url, data, isTextRes = false) {
    return this.api.put(this.prefix + url, data).then(res => {
      return isTextRes ? res.text() : res.json();
    });
  }

  delete(url, data, isTextRes = false) {
    return this.api.delete(this.prefix + url, data).then(res => {
      return isTextRes ? res.text() : res.json();
    });
  }

  // User Methods
  loginUser(userCredentials) {
    return this.post('login', userCredentials);
  }

  postRegisterLoginUser(verificationToken) {
    return this.get(`accounts/post-register/${verificationToken}`);
  }

  getMe() {
    return this.get('auth/me');
  }

  forgotPassword(user) {
    return this.post('password/send_reset_email', user);
  }

  resetPassword(pass_data) {
    return this.post('password/reset', pass_data);
  }

  createPassword(pass_data) {
    return this.post('accounts/password/store', pass_data);
  }

  changePassword(pass_data) {
    return this.post('accounts/password/update', pass_data);
  }

  // Revision Methods
  getRevisionOrder(orderId) {
    return this.get(`designs/mocks/view/order/${orderId}`);
  }

  sendMocksApprovedNotification(payload) {
    return this.post(`designs/mocks/send_mocks_approved_all_email`, payload);
  }

  getMessagesByOrderId(orderId) {
    return this.get(`messages/${orderId}`);
  }

  // Dashboard Methods
  getOrder(orderId) {
    return this.get(`orders/${orderId}`);
  }

  getDesign(designId) {
    return this.get(`designs/${designId}`);
  }
  createOrder(order) {
    return this.post('orders', order);
  }

  getOrderToEdit(orderId) {
    return this.get(`orders/${orderId}/edit`);
  }

  saveOrder(orderId, order) {
    return this.put(`orders/${orderId}`, order);
  }
  archiveOrder(orderId) {
    return this.post(`orders/${orderId}/archive`);
  }
  deleteOrder(orderId) {
    return this.delete(`orders/${orderId}`);
  }


  getOrders(filters, url) {
    if (!url) {
      url = 'orders/list';
    }

    return this.get(url, false, filters);
  }

  getEstimates(filters, url) {
    if (!url) {
      url = 'orders/estimates';
    }
    return this.get(url, false, filters);
  }
  getEstimateFilters(filters, url) {
    if (!url) {
      url = 'orders/estimate_filters';
    }
    return this.get(url, false, filters);
  }

  getMyOrders(filters, url) {
    if (!url) {
      url = 'orders/my_orders';
    }

    return this.get(url, false, filters);
  }

  getDashboardActiveOrders(filters, url) {
    if (!url) {
      url = 'orders/dashboard_active_orders';
    }
    return this.get(url, false, filters);
  }

  getLatestCustomerOrders({ userId, skipId }) {
    return this.get(`orders/latest_customer_orders/${userId}/${skipId}`);
  }
  getCustomerOrder(orderId) {
    return this.get(`customer-orders/${orderId}`);
  }

  getReprintedDesigns(orderId) {
    return this.get(`designs/reprinted/${orderId}`);
  }

  getReprintDesigns(payload) {
    return this.get(`estimates/designs/reprint`, false, payload);
  }

  claim(orderId, subject) {
    return this.setOrderStatus(`claim_${subject}`, orderId);
  }

  viewed(estimateId) {
    return this.put(`estimates/${estimateId}/viewed`);
  }

  unclaim(orderId, subject) {
    return this.setOrderStatus(`unclaim_${subject}`, orderId);
  }

  setOrderStatus(status, orderId, status_reason) {
    return this.post(`orders/${orderId}/${status}`, status_reason);
  }

  approveEstimate(estimateId) {
    return this.put(`orders/${estimateId}/approve`);
  }

  setOrderTerms(orderId, terms) {
    return this.put(`orders/${orderId}/set_terms`, { terms });
  }

  updateOrderPaymentStatus(status, orderId) {
    return this.put(`orders/${orderId}/update_payment_status`, {
      payment_status: status
    });
  }

  updateDesignBlankStatus(status, designId) {
    return this.put('designs/update_blank_status', {
      design_id: designId,
      blank_status: status
    });
  }

  getOrderFiles(orderId) {
    return this.get(`orders/${orderId}/all_order_files`);
  }

  // @deprecated
  getShippingCost(postData) {
    return this.post(`orders/shipping_cost`, postData);
  }

  // Messages Methods
  getPubNubKeys() {
    return this.get('messages/pub_nub_keys');
  }

  markMessageAsRead(payload) {
    return this.post('messages/mark_read', payload);
  }

  markAllMessagesAsRead(payload) {
    return this.post('messages/mark_read/all', payload);
  }

  getS3BaseDomain() {
    return this.get('attachments/s3_base_domain', true);
  }

  prepareS3(orderId) {
    return this.get(`messages/${orderId}/s3_form_data`);
  }

  getMessages(orderId) {
    return this.get(`messages/${orderId}`);
  }

  getUnreadMessages(userId) {
    return this.get(`messages/unread/${userId}`);
  }

  sendMessage(data) {
    return this.post('messages/store', data);
  }
  deleteMessage(messageId) {
    return this.delete(`messages/${messageId}`);
  }

  // Estimates
  estimateFormLoadContactsList(query) {
    return this.get(`estimates/estimate_form_contacts_list?q=${query}`);
  }

  getPublicEstimate(token) {
    return this.get(`estimates/public/${token}`);
  }

  getFormDataToEditPublicEstimate() {
    return this.get('estimates/edit_form_init_data');
  }

  approvePublicEstimate(token) {
    return this.put(`estimates/public/${token}/approve`);
  }

  requestRevisionPublicEstimate(orderId, payload) {
    return this.post(`estimates/public/${orderId}/request_revision`, payload);
  }

  // @deprecated
  declinePublicEstimate(token) {
    return this.put(`estimates/public/${token}/decline`);
  }

  estimateFormLoadCompaniesList(query, withCreate = false) {
    return this.get(
      `companies/list?q=${query}${withCreate ? '&add-create-option=true' : ''}`
    );
  }

  copyEstimate(id) {
    return this.post(`estimates/copy-estimate/${id}`);
  }

  getOrdersDesigns(ordersIds) {
    return this.post('orders/get-designs', { ordersIds });
  }

  // Order Methods
  updateOrder(data) {
    return this.post('orders/update', data);
  }

  // Mocks
  approveMock(data) {
    return this.post('designs/mocks/approve_mock', data);
  }
  sendMock(id) {
    return this.post(`mocks/${id}/send`);
  }
  requestRevisions(data) {
    return this.post('designs/mocks/request_revisions', data);
  }
  getPmsColors() {
    return this.get('pms_colors');
  }
  getMockWarnings() {
    return this.get('designs/mocks/warnings');
  }
  getDesignLocations(designId) {
    return this.get(`designs/${designId}/locations`);
  }
  saveDesignMock(mockData) {
    return this.post('designs/mocks/save_design_mock', mockData);
  }
  getS3Form({ designId, mockId }) {
    return this.get(`designs/${designId}/mocks/${mockId}/s3_form_data`);
  }
  storeMockFiles(data) {
    return this.post(`designs/mocks/store_mock_files`, data);
  }
  getMock(mock_id) {
    return this.get(`mocks/${mock_id}`);
  }

  // Design Methods
  getFollowUpStatusesList() {
    return this.get('designs/followup_statuses_list');
  }

  getRedoCategoriesList() {
    return this.get('designs/redo_categories_list');
  }

  getLostReasonCategoriesList() {
    return this.get('orders/lost_reason_categories_list');
  }

  prepareS3FormByDesignId(designId) {
    return this.get('designs/' + designId + '/s3_form_data');
  }

  getDesignFormOptions(type, user_id) {
    return this.get(`designs/new/form_options/type/${type}${user_id ? '/' + user_id : ''}`);
  }

  getAttachments(designId, model) {
    return this.get(`${model}s/${designId}/attachments`);
  }

  updateFilesLinks(itemId, model, data) {
    return this.put(`${model}s/${itemId}/attachments`, data);
  }

  getPastOrdersAttachments(orderId) {
    return this.get(`attachments/past_orders/${orderId}`);
  }

  usePastOrderFiles(data) {
    return this.post(`attachments/use_past_order_files`, data);
  }

  getPosterPaperColors(brandId, weightId) {
    return this.get(`poster_paper_colors/${brandId}/${weightId}`);
  }

  getPaperPrintPaperColors(brandId, weightId) {
    return this.get(`paper_print_paper_colors/${brandId}/${weightId}`);
  }

  getReorderDesignsList() {
    return this.get('designs/reorder_designs_list');
  }

  reorderEstimate(checkedDesigns) {
    return this.post('designs/redo_order', checkedDesigns);
  }

  destroyReordered(orderId) {
    return this.delete(`designs/reorder/${orderId}`);
  }

  changeFollowUpStatus({
    designId,
    status,
    reasonCategoryId,
    description,
    reApproveMock
  }) {
    let data = {
      design_id: designId,
      status
    };
    if (reasonCategoryId) data.reason_category_id = reasonCategoryId;
    if (description) data.description = description;
    if (reApproveMock) data['re-approve_mock'] = reApproveMock;

    return this.post('designs/change_followup_status', data);
  }

  changePrinterStatus({ itemId, status, modelType }) {
    let data = {
      printer_status: status
    };

    return this.put(`printers/${modelType}s/${itemId}/update_status`, data);
  }

  sendSurvey(orderId) {
    return this.post(`orders/${orderId}/send_survey`);
  }

  sendEstimate(orderId) {
    return this.post(`orders/${orderId}/send_estimate`);
  }

  getGarmentBrandsByApparelType(apparelType) {
    return this.get(`brands/${apparelType}`);
  }

  getApparelProducts(apparelType, brand, userId) {
    return this.get(
      `products/type/${apparelType}/brand/${brand}/user/${userId}`
    );
  }

  getApparelProduct(id, companyId, service = '') {
    return this.get(
      `products/apparel/${id}?${companyId ? `filter[company_id]=${companyId}` : ''}${
        service ? `&filter[service]=${service}` : ''
      }`
    );
  }

  getStatesTaxes() {
    return this.get('state_taxes');
  }
  getApparelGarmentUpcharges(user_id, service) {
    return this.get(`apparel_garment_upcharges/${user_id}?service=${service}`);
  }
  getApparelFinishingPrices(user_id) {
    return this.get(`apparel_finishings_prices${user_id ? `/${user_id}` : ''}`);
  }

  // printer methods
  getPrinterStatusesList() {
    return this.get('printers/statuses_list');
  }

  // printer methods
  getFinishingPrinterStatusesList() {
    return this.get('printers/finishings_statuses_list');
  }

  getShippingLabels(designId) {
    return this.get('printers/get_shipping_labels/' + designId);
  }

  addUpsTrackings(sendData) {
    return this.post('printers/generate_ups_label', sendData);
  }

  updateTrackings(sendData) {
    return this.put('printers/update_ups_label', sendData);
  }
  // Lookup methods
  getFormDataToEditOrder(orderId) {
    return this.get(
      `estimates/edit_form_init_data${orderId ? `?orderId=${orderId}` : ''}`
    );
  }

  getStates(countryId) {
    return this.get('countries/' + countryId);
  }

  getPaymentTerms() {
    return this.get('payment_terms');
  }

  getOrderServices() {
    return this.get('services');
  }

  getUPSServices() {
    return this.get('printers/ups_services_list');
  }

  getShippingMethods() {
    return this.get('shipping/offers');
  }

  getShippingTrackingOptions() {
    return this.get('shipping/tracking_options');
  }

  getStickerSizesByShape(shapeId, userId) {
    return this.get(`sticker_sizes/${shapeId}/user/${userId}`);
  }

  getDigitaldesignFormDataByPrintType(printTypeId) {
    return this.get(`digital_print_type_relations/${printTypeId}`);
    // return Promise.resolve();
  }

  // invoices
  requestPayment({ orderId, invoice }) {
    return this.post(`invoices/order/${orderId}/request_payment`, invoice);
  }
  requestInitialPayment({ orderId, invoice }) {
    return this.post(
      `invoices/order/${orderId}/request_initial_payment`,
      invoice
    );
  }
  updateInvoice({ invoiceId, invoice }) {
    return this.put(`invoices/${invoiceId}`, invoice);
  }

  getInvoices(orderId) {
    return this.get(`invoices/${orderId}`);
  }
  getInvoice(invoiceId) {
    return this.get(`invoices/${invoiceId}/edit`);
  }
  getInvoiceStatuses() {
    return this.get(`invoices/statuses_list`);
  }
  getTransaction(transactionId) {
    return this.get(`invoices/payment/transaction/${transactionId}`);
  }

  saveTransaction(data) {
    return this.post(`invoices/payment/transaction`, data);
  }

  markAsPaid(id) {
    return this.post(`invoices/payment/mark_as_paid/${id}`);
  }

  deleteTransaction(id) {
    return this.delete(`invoices/payment/transaction/${id}`);
  }

  getInvoiceDetails(hash) {
    return this.get(`invoices/invoice_details/${hash}`);
  }

  makePayment(hash, data) {
    return this.post(`invoices/invoice_details/${hash}/pay`, data);
  }

  getAuthorizeNetForm(data) {
    return this.post(`invoices/get_authorize_net_form_data`, data);
  }

  postPaypalPayment(data) {
    return this.post(`invoices/paypal_post_payment`, data);
  }

  emailInvoice(invoiceId) {
    return this.post(`invoices/email_invoice/${invoiceId}`);
  }

  // Accounts
  getAccount(accountId, page, url) {
    if (url) {
      return this.api.get(url).then(res => {
        return res.json();
      });
    }

    page = page ? `?page=${page}` : '';
    return this.get(`accounts/${accountId}${page}`);
  }
  deleteAccount(accountId) {
    return this.delete(`accounts/${accountId}`);
  }
  saveAccount(accountId, account) {
    return this.put(`accounts/${accountId}`, account);
  }
  createAccount(account) {
    return this.post('accounts', account);
  }
  getRoles() {
    return this.get(`accounts/roles`);
  }
  getAccountsByOrder(orderId) {
    return this.get(`accounts/by_order/${orderId}`);
  }
  getOids() {
    return this.get(`accounts/oids`);
  }
  getAccCountries() {
    return this.get('accounts/countries');
  }
  getAccStates(countryId) {
    return this.get('accounts/countries/' + countryId);
  }

  // @deprecated
  getAccCompanies() {
    return this.get('accounts/companies');
  }
  updateOrderProductionNotes(id, data) {
    return this.put(`accounts/update_production_notes/${id}`, data);
  }

  findGarmentProducts(searchQuery, config = {}) {
    return this.get(
      `products/apparel?page=${config.page || 1}&per_page=${config.perPage ||
        20}&filter[product_combination_query]=${searchQuery}&filter[service]=${
        config.service
      }&filter[company_id]=${config.companyId}`
    );
  }

  findPromotionalProducts(searchQuery, config = {}) {
    return this.get(
      `products/promotional?page=${config.page || 1}&per_page=${config.perPage || 20}
      &filter[product_combination_query]=${searchQuery || ''}&filter[service]=${
        config.service
      }&filter[company_id]=${config.companyId}&filter[category_id]=${
        config.categoryId
      }`
    );
  }

  productAddons($productId) {
    return this.get(`products/promotional/${$productId}/addons`);
  }

  getCompany(id) {
    return this.get(`companies/details/${id}`);
  }

  getCompanyFormData() {
    return this.get(`companies/form_data`);
  }

  getCompanyFormStates(country_id) {
    return this.get(`companies/form_states/${country_id}`);
  }

  storeCompany(data) {
    return this.post('companies', data);
  }

  updateCompany(companyId, data) {
    return this.put(`companies/${companyId}`, data);
  }

  deleteCompany(companyId) {
    return this.delete(`companies/${companyId}`);
  }

  assignCompanyCustomers(companyId, data) {
    return this.post(`companies/assign_customers/${companyId}`, data);
  }

  removeCustomerFromCompany(customerId) {
    return this.delete(`companies/remove_customer/${customerId}`);
  }

  // Company custom prices
  getCustomPricesInitData(service, companyId) {
    return this.get(`company_prices/init_data/${service}/${companyId}`);
  }

  getAccPricesApparelBrands(shirt_type) {
    return this.get(`company_prices/apparel_brands/${shirt_type}`);
  }

  getAccPricesApparelProducts(shirt_type, brand, service) {
    return this.get(
      `company_prices/apparel_products/${service}/${shirt_type}/${brand}`
    );
  }

  storeApparelCustomProductPrices(data) {
    return this.post('company_prices/store_apparel_product', data);
  }

  deleteCustomPrice(item_id) {
    return this.delete(`company_prices/${item_id}`);
  }

  removeCompanyCustomPrices(company_id) {
    return this.delete(`company_prices/restore_prices/${company_id}`);
  }
  storeCustomPrices(data) {
    return this.post('company_prices/store', data);
  }

  findApparelProducts(product_query, type) {
    return this.get(
      `company_prices/apparel_product_by_name/${type}?q=${product_query}`
    );
  }

  addCustomersToOrderCommunication(orderId, data) {
    return this.post(`orders/${orderId}/add_to_communication`, data);
  }

  // master Search
  masterSearch(search) {
    return this.get(`orders/master_search?s=${search}`);
  }

  getSortedGarmentsSizes() {
    return this.get(`designs/garments_sizes`);
  }

  saveEstimate(payload, id) {
    return this.post(`estimates${id ? `/${id}` : ''}`, payload);
  }

  getEstimateData(id) {
    return this.get(`estimates/${id}`);
  }

  getThreadbirdAddresses() {
    return this.get('shipping/company-addresses');
  }

  checkContactCompany(company, perPage = 10) {
    return this.get(
      `estimates/contact/check-company?filter[company]=${company}&perPage=${perPage}`
    );
  }

  checkContactCustomer(email, perPage = 10) {
    return this.get(
      `estimates/contact/check-customer?filter[email]=${email}&perPage=${perPage}`
    );
  }

  validatePostalCode({ countryId, stateId, postalCode }) {
    return this.post('shipping/validate/postal', {
      country_code: countryId,
      state_code: stateId,
      postal_code: postalCode
    });
  }

  getTaxes(data) {
    return this.post(`orders/get_tax`, {
      data: data
    });
  }
  getReport(reportName, filters = {}) {
    return this.get(`reports/${reportName}`, false, filters);
  }
}

export default new ApiService();
