<template>
  <div class="design-info">
    <div v-if="design.designable && design.designable.item" class="card is-fullwidth">
      <div class="card-content">
        <div class="content">
          <table class="table is-bordered is-striped is-narrow">
            <thead>
              <tr>
                <th>Colors Number</th>
                <th>Size</th>
                <th>Quantity</th>
                <th>Brand</th>
                <th>Paper Weight</th>
                <th>Paper Color</th>
                <th>Bleed Type</th>
                <th>Ink Type</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{{number_of_colors_label}}</td>
                <td>{{size_label}}</td>
                <td>{{quantity_label | itemsNumberFormat}}</td>
                <td>{{brand_label}}</td>
                <td>{{paper_weight_label}}</td>
                <td>{{paper_color_label}}</td>
                <td>{{bleed_type_label}}</td>
                <td>{{ink_type_label}}</td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="content">
          <div v-if="design.comment && !hideComment" class="card is-fullwidth">
            <header class="card-header">
              <p class="card-header-title">Design Comment</p>
            </header>
            <div class="content-pre card-content">{{ design.comment }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'design-info-poster',
  props: {
    design: {
      type: Object,
      required: true
    },
    options: {
      type: Object,
      required: true
    },
    hideComment: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    number_of_colors_label() {
      return this.design.designable.item.colors_number_object
        ? this.design.designable.item.colors_number_object.name
        : 'N/A';
    },
    size_label() {
      let label = 'N/A';

      if (this.design.designable.item.custom_size) {
        label = this.design.designable.item.custom_size;
      } else if (this.design.designable.item.size_object) {
        label = this.design.designable.item.size_object.name;
      }
      return label;
    },
    quantity_label() {
      return this.design.designable.item.quantity_object
        ? this.design.designable.item.quantity_object.name
        : 'N/A';
    },
    brand_label() {
      let label = 'N/A';

      if (this.design.designable.item.custom_brand) {
        label = this.design.designable.item.custom_brand;
      } else if (this.design.designable.item.brand_object) {
        label = this.design.designable.item.brand_object.name;
      }
      return label;
    },
    paper_weight_label() {
      return this.design.designable.item.paper_weight_object
        ? this.design.designable.item.paper_weight_object.name
        : 'N/A';
    },
    paper_color_label() {
      let label = 'N/A';

      if (this.design.designable.item.custom_paper_color) {
        label = this.design.designable.item.custom_paper_color;
      } else if (this.design.designable.item.paper_color_object) {
        label = this.design.designable.item.paper_color_object.name;
      }
      return label;
    },
    bleed_type_label() {
      return this.design.designable.item.bleed_type_object
        ? this.design.designable.item.bleed_type_object.name
        : 'N/A';
    },
    ink_type_label() {
      return this.design.designable.item.ink_type_object
        ? this.design.designable.item.ink_type_object.name
        : 'N/A';
    }
  }
};
</script>
