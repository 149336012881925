<template>
  <div>
    <!-- Main container -->
    <nav class="level">
      <!-- Left side -->
      <div class="level-left">
        <div class="level-item">
          <p class="subtitle is-5">Search accounts</p>
        </div>
        <div class="level-item">
          <div class="control">
            <p class="control has-addons">
              <input type="text" class="input" v-model="searchQuery" v-focus="true">
            </p>
          </div>
        </div>
      </div>
    </nav>
    <div v-if="accountsList.length">
      <table class="table">
        <thead>
          <tr>
            <th width="60px">&nbsp;</th>
            <th>Username</th>
            <th>Contact</th>
            <th>Email</th>
          </tr>
        </thead>
        <tfoot>
          <tr>
            <th>&nbsp;</th>
            <th>Username</th>
            <th>Contact</th>
            <th>Email</th>
          </tr>
        </tfoot>
        <tbody>
          <tr v-for="(entry, rowKey) in accountsList">
            <td><input type="checkbox" v-model="selectedCustomers" :value="entry"></td>
            <td>{{ entry.title }}</td>
            <td>{{ entry.first_name + ' ' + entry.last_name }}</td>
            <td>{{ entry.email }}</td>
          </tr>
        </tbody>
      </table>

      <nav class="pagination is-centered is-small"
        v-if="pagesNumber > 1">
        <a class="pagination-previous button"
          :disabled="prevPageUrl == null"
          @click="getAccountsData(false, prevPageUrl)">Previous</a>
        <a class="pagination-next button"
          :disabled="nextPageUrl == null"
          @click="getAccountsData(false, nextPageUrl)">Next</a>
        <ul class="pagination-list">
          <li>
            <a class="pagination-link"
              :class="{'is-current': 1 == currentPage}"
              @click="getAccountsData(1)">1</a>
          </li>
          <li v-if="currentPage - 3 > 1">
            <span class="pagination-ellipsis">&hellip;</span>
          </li>
          <li v-for="pageNum in pagesNumber"
            v-if="(pageNum+2 >= currentPage && pageNum-2 <= currentPage) && pageNum != 1 && pageNum != pagesNumber">
            <a class="pagination-link"
              :class="{'is-current': pageNum == currentPage}"
              @click="getAccountsData(pageNum)">{{pageNum}}</a>
          </li>
          <li v-if="currentPage+3 < pagesNumber">
            <span class="pagination-ellipsis">&hellip;</span>
          </li>
          <li>
            <a class="pagination-link"
              :class="{'is-current': pagesNumber == currentPage}"
              @click="getAccountsData(pagesNumber)">{{pagesNumber}}</a>
          </li>
        </ul>
      </nav>
    </div>
    <p v-else>There are no available customers</p>
  </div>
</template>

<script>
  import Vue from 'vue';
  import {
    mapGetters,
    mapActions
  } from 'vuex';
  import popup from '../../../../modules/popupper'
  import {focus} from 'vue-focus';

  import apiService from '../../../../services/api.service';
  import {debounce} from 'lodash';
  import alertify from 'alertify.js';

  export default {
    name: 'accounts-search',
    props: {
      additional: {
        type: Object,
        default: () => null
      },
      companyId: {
        type: Number,
        default: () => null
      }
    },
    data() {
      return {
        accountsList: [],
        prevPageUrl: null,
        nextPageUrl: null,
        currentPage: 1,
        pagesNumber: 1,
        searchQuery: '',
        searchRole: 'customer',
        selectedCustomers: []
      }
    },
    watch: {
      searchQuery(str) {
        if (str.length == 0 || str.length >= 3) {
          this.searchAccounts();
        }
      }
    },
    directives: {
      focus
    },
    mounted() {
      this.getAccountsData();

      if (this.additional && this.additional.selectedCustomers) {
        this.selectedCustomers = this.additional.selectedCustomers;
      }
    },
    methods: {
      submitForm() {
        let ids = [];

        this.selectedCustomers.forEach((customer) => {
          ids.push(customer.id);
        });

        if (this.additional.onSelected) {
          this.additional.onSelected(ids, this.selectedCustomers);
        }

        this.$emit('close');
      },
      searchAccounts() {
        debounce(() => this.getAccountsData(), 500)();
      },
      getAccountsData(page, url) {
        if (page === undefined) page = 1;

        if (url === undefined) url = '/api/accounts/list/' + this.searchRole + '?page=' + page + '&has_no_company=1';

        if (this.searchQuery) {
          url += url.indexOf('?') == -1 ? '?q=' + this.searchQuery : '&q=' + this.searchQuery;
        }

        return this.$http.get(url).then((response) => {
          this.accountsList = response.data.users_data.data;
          this.prevPageUrl = response.data.users_data.prev_page_url;
          this.nextPageUrl = response.data.users_data.next_page_url;
          this.currentPage = response.data.users_data.current_page;
          this.pagesNumber = response.data.users_data.last_page;
        }, (response) => {
          alertify.error('Could not load accounts list');
        });
      }
    }
  };
</script>

