export const FED_EX = 'fed_ex';
export const FED_EX_LINK =
  'https://www.fedex.com/apps/fedextrack/?action=track&trackingnumber=';

export const UPS = 'ups';
export const UPS_LINK = 'https://wwwapps.ups.com/WebTracking/track?trackNums=';

export const generateLinkTracking = trackingsData => {
  if (trackingsData && trackingsData.tracking_option) {
    switch (trackingsData.tracking_option) {
      case FED_EX:
        return FED_EX_LINK + trackingsData.tracking_number;
        break;
      case UPS:
        return UPS_LINK + trackingsData.tracking_number;
        break;
      default:
        return UPS_LINK + trackingsData.tracking_number;
    }
  } else {
    return UPS_LINK + trackingsData.tracking_number;
  }
};
