import apiService from '../../services/api.service';
import notify from '../../modules/notifier';
import { progressFieldsList } from '../../helpers/orders';

import Vue from 'vue';
import VueResource from 'vue-resource';

import { forEach, cloneDeep, camelCase } from 'lodash';

Vue.use(VueResource);

import {
  LOAD_ESTIMATES_REPORT,
  LOAD_ORDERS_REPORTS_DATA
} from '../action-types';

const reports = {
  state: {
    sales_tax_orders_loading: false,
    sales_tax_orders: [],
    sales_tax_orders_paginator_data: [],

    discount_orders_loading: false,
    discount_orders: [],
    discount_orders_paginator_data: [],

    rush_fee_orders_loading: false,
    rush_fee_orders: [],
    rush_fee_orders_paginator_data: [],

    filter_options: [],
    actual_filters: []
  },
  getters: {
    salesTaxOrders: state => {
      return state.sales_tax_orders;
    },
    salesTaxOrdersPaginatorData: state => {
      return state.sales_tax_orders_paginator_data;
    },
    salesTaxOrdersLoading: state => {
      return state.sales_tax_orders_loading;
    },

    discountOrders: state => {
      return state.discount_orders;
    },
    discountOrdersPaginatorData: state => {
      return state.discount_orders_paginator_data;
    },
    discountOrdersLoading: state => {
      return state.discount_orders_loading;
    },

    rushFeeOrders: state => {
      return state.rush_fee_orders;
    },
    rushFeeOrdersPaginatorData: state => {
      return state.rush_fee_orders_paginator_data;
    },
    rushFeeOrdersLoading: state => {
      return state.rush_fee_orders_loading;
    }
  },
  mutations: {
    setOrdersData(state, { prop, val }) {
      state[prop] = val;
    },

    setLoading(state, { prop, val }) {
      state[prop] = val;
    }
  },
  actions: {
    [LOAD_ORDERS_REPORTS_DATA]: (
      { commit },
      { model, pagination, filters }
    ) => {
      commit('setLoading', { prop: `${model}_orders_loading`, val: true });

      if (!pagination) {
        pagination = {};
      }

      let page = pagination.page;
      let url = pagination.url;
      let perPage = pagination.perPage;
      let sortField = pagination.sortField;
      let sortDir = pagination.sortDir;

      if (!page) page = 1;

      if (!url) url = `/api/reports/${model}_orders?page=${page}`;

      if (perPage) {
        url += `&perPage=${perPage}`;
      }

      if (sortField) {
        sortField = encodeURIComponent(sortField);
        url += `&sortField=${sortField}`;
        url += `&sortDir=${sortDir}`;
      }

      if (filters.date_from) {
        let date_from = encodeURIComponent(filters.date_from);
        url += `&date_from=${date_from}`;
      }

      if (filters.date_to) {
        let date_to = encodeURIComponent(filters.date_to);
        url += `&date_to=${date_to}`;
      }
      if (filters.timezone) {
        let timezone = filters.timezone;
        url += `&timezone=${timezone}`;
      }

      return Vue.http
        .get(url)
        .then(response => {
          commit('setOrdersData', {
            prop: `${model}_orders`,
            val: response.data.data
          });
          delete response.data.data;

          commit('setOrdersData', {
            prop: `${model}_orders_paginator_data`,
            val: response.data
          });
          commit('setLoading', { prop: `${model}_orders_loading`, val: false });
        })
        .catch(err => {
          notify({
            message: 'Could not load orders list',
            type: 'danger'
          });
        });
    },
    [LOAD_ESTIMATES_REPORT]: ({ commit }) => {
      return apiService.getEstimateFilters().then(data => {
        commit('setFiltersOptions', data.filter_options);
        commit('setFiltersValues', data.actual_filters);
      });
    }
  }
};

export default reports;
