<template>
    <div class="columns">
        <div class="column">
        <div class="columns">
            <div class="column is-one-quarter">
                <label for="finishing-neck-tag-remove-original-0">
                    <input class="radio"  value="Remove Original Tag" id="finishing-neck-tag-remove-original-0"  v-model="finishing.woven_neck_tag_type" type="radio">
                    Remove Original Tag
                </label>
            </div>
            <div class="column is-one-quarter">
                <label for="finishing-neck-tag-sew-0">
                <input class="radio" value="Sew on Top of Original Tag" id="finishing-neck-tag-sew-0"  v-model="finishing.woven_neck_tag_type"  type="radio">
                    Sew on Top of Original Tag
                </label>
            </div>
        </div>
        <div class="columns">
            <div class="column is-half">
                <form-input v-model="woven_neck_tag_thread_color"
                            name="woven_neck_tag_thread_color"
                            :scope="getScope"
                            label="Thread Color"
                            placeholder="e.g. Black Thread" />
            </div>
        </div>
        </div>
    </div>
</template>
<script>
    import Vue from 'vue';

    import formInput from '../../common/form/input';

    export default {
        props: ['finishing', 'finishingKey'],
        components: {
            formInput,
        },
        data(){
            return {
                woven_neck_tag_type: this.finishing.woven_neck_tag_type || 'Remove Original Tag',
                woven_neck_tag_thread_color: this.finishing.woven_neck_tag_thread_color || ''
            }
        },
        computed:{
            getScope() {
                return 'finishings-' + this.finishingKey + '-fields'
            },
        },
        watch:{
            woven_neck_tag_thread_color(val){
                this.$set(this.finishing, 'woven_neck_tag_thread_color', val);
            }
        },
        mounted(){
            // init dynamic subfields
            this.$set(this.finishing, 'woven_neck_tag_type', this.woven_neck_tag_type);
            this.$set(this.finishing, 'woven_neck_tag_thread_color', this.woven_neck_tag_thread_color);
        },
        beforeDestroy(){
            this.$delete(this.finishing, 'woven_neck_tag_type');
            this.$delete(this.finishing, 'woven_neck_tag_thread_color');
        }
    };
</script>