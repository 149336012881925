<template>
  <div class="page-content">
    <section class="estimates-report">
      <filter-estimate
        :filters-options="filtersOptions"
        @takeFilters="onFilters">
      </filter-estimate>
      <div class="progress" style="margin-top: 10px; margin-bottom: 10px">
        <div class="determinate" :style='`width: ${progress}%`'></div>
      </div>
      <div class="row" v-if="message">
        <div class="col s6 offset-s3 m6 offset-m3">
          <div class="card-panel">
            <h5 class="center-align">{ message != null ? message : ''}</h5>
          </div>
        </div>
      </div>
    </section>
    <button @click="run()" :disabled="inProgress" class="button is-pulled-right">
      Export Estimates
    </button>
  </div>
</template>
<script>
import apiService from '../../services/api.service';
import alertify from 'alertify.js';
import Datepicker from '../../modules/datepicker/index';
import formSelect from '../common/form/select';
import {ESTIMATE_FILTER_STATUSES} from '@/helpers/estimates';
import {mapActions, mapGetters} from 'vuex';
import {LOAD_ESTIMATES_REPORT} from '@/store/action-types';
import {cloneDeep} from 'lodash';
import filterEstimate from '../estimates/filter-estimate';
import {downloadReport} from '@/helpers/reports/reports';

export default {
  name: 'estimates-report',
  data() {
    return {
      cancel: false,
      inProgress: false,
      filters: {
        csr: '',
        date_to: '',
        date_from: '',
        status: ESTIMATE_FILTER_STATUSES.STATUS_FILTER_ACTIVE
      },
      message: '',
      data: [],
      json_fields: [
        {
          label: 'Estimate #',
          value: 'estimate_id'
        },
        {
          label: 'Date Create',
          value: 'created_at'
        },
        {
          label: 'Date Lost',
          value: 'date_lost'
        },
        {
          label: 'Account/Company',
          value: 'order_company'
        },
        {
          label: 'CSR',
          value: 'csr_email'
        },
        {
          label: 'Total',
          value: 'total_price'
        },
        {
          label: 'Status',
          value: 'estimate_status'
        },
        {
          label: 'Customer Name',
          value: 'customer_name'
        },
        {
          label: 'Customer Email',
          value: 'customer_email'
        },
        {
          label: 'Comment',
          value: 'lost_comment'
        }
      ],
      progress: 0,
      downloadName: 'estimates'
    };
  },
  components: {
    Datepicker,
    formSelect,
    filterEstimate
  },
  computed: {
    ...mapGetters(['filtersOptions'])
  },
  methods: {
    ...mapActions({
      loadEstimatesReport: LOAD_ESTIMATES_REPORT
    }),
    onFilters(filters) {
      this.filters = cloneDeep(filters);
    },
    run() {
      if (!this.cancel) {
        this.$set(this, 'inProgress', true);
        apiService
          .getReport('estimates', this.filters)
          .then(res => {
            this.$set(this, 'progress', +res.to / +res.total * 100);
            if (res.data.length) {
              this.$set(this, 'data', [...this.data, ...res.data]);
            }
            if (+res.to >= +res.total) {
              this.$set(this, 'inProgress', false);
              this.download();
            } else {
              this.$set(this.filters, 'page', res.current_page + 1);
              this.run();
            }
          })
          .catch(err => {
            console.log(err);
            this.$set(this.filters, 'page', 1);
            this.$set(this, 'inProgress', false);
            alertify.error(err.body.message);
          });
      }
    },
    download() {
      downloadReport(this.data, this.json_fields,
        `${this.downloadName}_${this.filters.date_from}_${this.filters.date_to}.csv`
      );

      this.$set(this.filters, 'page', 1);
      this.$set(this, 'progress', 0);
      this.$set(this, 'data', []);
    }
  },
  mounted() {
    this.loadEstimatesReport();
  },
  beforeDestroy() {
    this.$set(this, 'cancel', true);
  }
};
</script>
<style scoped>
.progress {
  background: aliceblue;
}

.determinate {
  background: #1992d1;
  height: 12px;
}
</style>
