<template>
  <div class="columns">
    <div class="column">
      <form-select
        v-model="hem_tag"
        label="Hem Location"
        placeholder="e.g. Bottom Left Hem"
        name="hem_tag"
        :scope="getScope"
        :options="{all: hemTagLocationsList}"/>
    </div>
    <div class="column">
      <form-input v-model="hem_tag_thread_color"
        name="hem_tag_thread_color"
        :scope="getScope"
        label="Hem Tag Thread Color"
        placeholder="e.g. Black Thread" />
    </div>
  </div>
</template>

<script>
import Vue from 'vue';

import formInput from '../../common/form/input';

import { hemTagLocationsList } from '../../../helpers/revision';
import FormSelect from '../../common/form/select';

export default {
  props: ['finishing', 'finishingKey'],
  components: {
    FormSelect,
    formInput
  },
  data() {
    return {
      locationsList: {},
      hem_tag: this.finishing.hem_tag || '',
      hem_tag_thread_color: this.finishing.hem_tag_thread_color || ''
    };
  },
  watch: {
    hem_tag(val) {
      this.$set(this.finishing, 'hem_tag', val);
    },
    hem_tag_thread_color(val) {
      this.$set(this.finishing, 'hem_tag_thread_color', val);
    }
  },
  computed: {
    getScope() {
      return 'finishings-' + this.finishingKey + '-fields';
    },
    hemTagLocationsList: () => hemTagLocationsList
  },
  mounted() {
    // init dynamic subfields
    this.$set(this.finishing, 'hem_tag', this.hem_tag);
    this.$set(
      this.finishing,
      'hem_tag_thread_color',
      this.hem_tag_thread_color
    );
    this.$set(this.finishing, 'price_code', 'hem_tags');
  },
  beforeDestroy() {
    this.$delete(this.finishing, 'hem_tag');
    this.$delete(this.finishing, 'hem_tag_thread_color');
  }
};
</script>
