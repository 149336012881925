<template>
  <div>
    <grid :orders="gridData"
      :finishings="gridOrderFinishings"
      :designs="gridOrderDetails"
      :columns="gridMyEstimatesHeaders"
      :loading="unclaimedEstimatesLoading"
      noResMsg="No unclaimed estimates"
      :showTriangle="false"
      @onGridSortBy="gridUpdateOrders"></grid>

    <paginator v-if="unclaimedEstimates.length"
      :pagesNumber="unclaimedEstimatesPaginatorData.last_page"
      :prevPageUrl="unclaimedEstimatesPaginatorData.prev_page_url"
      :nextPageUrl="unclaimedEstimatesPaginatorData.next_page_url"
      :currentPage="unclaimedEstimatesPaginatorData.current_page"
      :getListItems="loadUnclaimedEstimatesPaginated"
      :showOffset="true"
      :totalNumber="unclaimedEstimatesPaginatorData.total"
      :sortField="sortField"
      :sortDir="sortDir"></paginator>
  </div>
</template>

<script>
import Vue from 'vue';
import { mapGetters, mapActions } from 'vuex';
import grid from '../common/grid/grid';
import paginator from '../common/paginator';

import { LOAD_UNCLAIMED_ESTIMATES, CLAIM } from '@/store/action-types';
import {
  gridMyEstimatesHeaders,
  getGridData,
  getGridOrderDetails,
  unclaimedActionData,
  unclaimedOrderActionsDetails,
  getGridUnclaimedEstimatesFinishings,
  unclaimedOrderActionsFinishings
} from '@/helpers/estimates';
import { cloneDeep } from 'lodash';

export default {
  name: 'dashboard-unclaimed-estimates',
  data() {
    return {
      gridMyEstimatesHeaders,
      sortField: 'created_at',
      sortDir: 'DESC',
      page: 1,
      perPage: 50
    };
  },
  computed: {
    ...mapGetters([
      'unclaimedEstimates',
      'unclaimedEstimatesPaginatorData',
      'unclaimedEstimatesLoading',
      'roles'
    ]),
    gridData() {
      return {
        data: getGridData(this.unclaimedEstimates, this.roles, {
          claim: {
            tag: 'button',
            str: 'Claim estimate',
            click: this.claim
          }
        })
        // unclaimedActionData eliminate from estimate.js
      };
    },
    gridOrderDetails() {
      return {
        data: getGridOrderDetails(this.unclaimedEstimates),
        actions: unclaimedOrderActionsDetails(this.unclaimedEstimates)
      };
    },

    gridOrderFinishings() {
      return {
        data: getGridUnclaimedEstimatesFinishings(this.unclaimedEstimates),
        actions: unclaimedOrderActionsFinishings(this.unclaimedEstimates)
      };
    }
  },
  components: {
    grid,
    paginator
  },
  methods: {
    ...mapActions({
      loadUnclaimedEstimates: LOAD_UNCLAIMED_ESTIMATES
    }),

    loadUnclaimedEstimatesPaginated(page, url, perPage, sortField, sortDir) {
      // Update values in component to populate it for other methods. For example - "gridUpdateOrders"
      this.page = page;
      this.perPage = perPage;
      return this.loadUnclaimedEstimates({
        page,
        url,
        perPage,
        sortField,
        sortDir
      });
    },

    // Update orders in store on orders sorting.
    gridUpdateOrders(sortField, sortDir) {
      // Update values in component to populate it for "loadUnclaimedMocksPaginated"
      this.sortField = sortField;
      this.sortDir = sortDir;

      // Get default or updated by "loadUnclaimedMocksPaginated" method.
      let page = this.page;
      let perPage = this.perPage;

      this.loadUnclaimedEstimates({ page, perPage, sortField, sortDir });
    },
    claim(orderId) {
      this.$store.dispatch(CLAIM, {
        orderId: orderId,
        subject: 'estimate',
        vue: this
      });
    },
    onFilters(filters) {
      this.filters = cloneDeep(filters);
      this.page = 1;
      this.gridUpdateOrders(this.sortField, this.sortDir);
    }
  },
  created() {
    this.gridUpdateOrders(this.sortField, this.sortDir);
  }
};
</script>
