<template>
  <div>
    <header id="main-nav" class="container" v-if="showHeader">
      <nav class="navbar" role="navigation" aria-label="main navigation">
        <div class="navbar-brand">
          <logo :width="180" :height="50"/>
          <MessengerNotifier widget-type="button" />
          <a role="button" class="navbar-burger is-align-content-flex-end" aria-label="menu" aria-expanded="false" data-target="navbarMenu">
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
          </a>
        </div>
        <div id="navbarMenu" class="navbar-menu">
          <div class="navbar-start">
            <div class="navbar-item field">
              <master-search v-roles="canSeeMasterSearch"/>
            </div>
          </div>
          <div class="navbar-end">
            <router-link to="/orders/create/" class="navbar-item is-hoverable"
                         v-roles="canCreateEstimate"
                         data-balloon="Create Estimate" data-balloon-pos="left">
              <span class="icon is-medium has-text-link">
                <i class="ff-2x ff-plus-circle is-link" aria-hidden="true"/>
              </span>
            </router-link>
            <MessengerNotifier/>
            <div class="navbar-item has-dropdown is-hoverable">
              <a class="navbar-link is-arrowless">
                <span class="icon is-medium has-text-link" aria-haspopup="true">
                  <i class="ff-2x ff-user-circle" aria-hidden="true"/>
                </span>
              </a>
              <div class="navbar-dropdown is-right">
                <router-link :to="'/accounts/' + userProfile.id + '/view'" class="navbar-item" exact-path>
                  <span class="icon-text is-medium">
                    <i class="ff-2x ff-user-circle" aria-hidden="true"></i>
                  </span>
                  <span>My Profile</span>
                </router-link>
                <router-link to="/accounts"
                             v-roles="['admin', 'csr', 'production-coordinator', 'purchaser', 'art-producer']"
                             class="navbar-item" exact-path>
                  <span class="icon-text is-medium">
                    <i class="ff-2x ff-person-stalker" aria-hidden="true"></i>
                  </span>
                  <span>Users</span>
                </router-link>
                <router-link to="/companies"
                             v-roles="['admin', 'csr', 'production-coordinator', 'purchaser', 'art-producer']"
                             class="navbar-item" exact-path>
                  <span class="icon-text is-medium">
                    <i class="ff-2x ff-briefcase" aria-hidden="true"></i>
                  </span>
                  <span>Companies</span>
                </router-link>
                <router-link to="/notifications" class="navbar-item">
                  <span class="icon-text is-medium">
                    <i class="ff-2x ff-bell" aria-hidden="true"></i>
                  </span>
                  <span>Notifications</span>
                </router-link>
                <router-link to="/accounts/new"
                             v-roles="['admin', 'csr', 'production-coordinator', 'purchaser', 'art-producer']"
                             class="navbar-item" exact-path>
                  <span class="icon-text is-medium">
                    <i class="ff-2x ff-user-add" aria-hidden="true"></i>
                  </span>
                  <span>New User</span>
                </router-link>
                <hr class="navbar-divider"/>
                <router-link to="/auth/logout" class="navbar-item">
                  <span class="icon-text is-medium">
                    <i class="ff-2x ff-signout" aria-hidden="true"></i>
                  </span>
                  <span>Sign Out</span>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </nav>
      <div class="tabs is-size-5 has-shadow is-fullwidth">
        <ul>
          <router-link v-roles="['admin','csr','production-coordinator','purchaser','art-producer','printer']"
                       tag="li"
                       class="link"
                       to="/dashboard">
            <a>Dashboard</a>
          </router-link>
          <router-link v-roles="['admin', 'csr', 'production-coordinator', 'purchaser', 'art-producer']"
                       tag="li"
                       class="link"
                       to="/estimates">
            <a>Estimates</a>
          </router-link>
          <router-link v-roles="['admin','csr','production-coordinator','purchaser','art-producer','printer']"
                       tag="li"
                       class="link"
                       to="/orders">
            <a>Orders</a>
          </router-link>
          <router-link v-roles="['admin', 'csr', 'production-coordinator', 'purchaser', 'art-producer']"
                       tag="li"
                       class="link"
                       to="/accounts">
            <a>Accounts</a>
          </router-link>
          <router-link v-roles="['admin', 'csr', 'production-coordinator', 'purchaser', 'art-producer']"
                       tag="li"
                       class="link"
                       to="/reports">
            <a>Reports</a>
          </router-link>
          <router-link v-roles="['customer']"
                       tag="li"
                       class="link"
                       to="/customer/dashboard">
            <a>Orders</a>
          </router-link>
          <router-link v-roles="['customer']"
                       tag="li"
                       class="link"
                       to="/notifications">
            <a>Notifications</a>
          </router-link>
          <router-link v-roles="['customer','printer']"
                       tag="li"
                       class="link"
                       :to="`/accounts/${userProfile.id}/view`">
            <a>Settings</a>
          </router-link>
        </ul>
      </div>
    </header>
  </div>
</template>
<script>
import {mapGetters} from 'vuex';
import {canCreateEstimate,printers,teamMembers} from '../../helpers/role-access';
import MessengerNotifier from '../common/messenger-notifier.vue';
import MasterSearch from './master-search.vue';
import Logo from './logo.vue';

export default {
  name: 'navigation',
  components: {Logo, MasterSearch, MessengerNotifier},
  data() {
    return {
      canCreateEstimate
    };
  },
  computed: {
    ...mapGetters(['isAuthenticated', 'userProfile']),
    showHeader() {
      return this.isAuthenticated;
    },
    canSeeMasterSearch() {
      return [...teamMembers, ...printers];
    }
  },
  mounted() {
    // Get all "navbar-burger" elements
    const $navbarBurgers = Array.prototype.slice.call(document.querySelectorAll('.navbar-burger'), 0);
    // Add a click event on each of them
    $navbarBurgers.forEach(el => {
      el.addEventListener('click', () => {
        // Get the target from the "data-target" attribute
        const target = el.dataset.target;
        const $target = document.getElementById(target);
        // Toggle the "is-active" class on both the "navbar-burger" and the "navbar-menu"
        el.classList.toggle('is-active');
        $target.classList.toggle('is-active');
      });
    });
  }
};
</script>
<style lang="scss" scoped>
.navbar-start{
  justify-content: flex-end;
  margin-inline-start: auto;
  margin-inline-end: inherit;
}
.navbar-burger {
  margin-inline-start: inherit;
}
</style>
