<template>
  <div class="option-contact">
    <div class="option-contact__name">
      <div>
        <span v-if="props.option.first_name" v-html="$mx_highlightPhrase(props.option.first_name, query)"></span>
        <span v-if="props.option.last_name" v-html="$mx_highlightPhrase(props.option.last_name, query)"></span>
        <span v-if="!props.option.first_name && !props.option.last_name" v-html="$mx_highlightPhrase(props.option.email, query)"></span>
      </div>
      <span class="option-contact__label clr-primary1" v-if="value && (value.account_id === props.option.account_id)">selected</span>
    </div>
    <div class="option-contact__info">
      <div class="option-contact__info__column">
        <svg class="option-contact__info__icon" xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48"><path d="M24 14V6H4v36h40V14H24zM12 38H8v-4h4v4zm0-8H8v-4h4v4zm0-8H8v-4h4v4zm0-8H8v-4h4v4zm8 24h-4v-4h4v4zm0-8h-4v-4h4v4zm0-8h-4v-4h4v4zm0-8h-4v-4h4v4zm20 24H24v-4h4v-4h-4v-4h4v-4h-4v-4h16v20zm-4-16h-4v4h4v-4zm0 8h-4v4h4v-4z"/></svg>
        <span class="option-contact__info__value" v-if="props.option.company" v-html="$mx_highlightPhrase(props.option.company.name, query)"></span>
      </div>
      <div class="option-contact__info__column">
        <svg class="option-contact__info__icon" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M20 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 4l-8 5-8-5V6l8 5 8-5v2z"/></svg>
        <span class="option-contact__info__value" v-html="$mx_highlightPhrase(props.option.email, query)"></span>
      </div>
      <div class="option-contact__info__column">
        <svg class="option-contact__info__icon" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M6.62 10.79c1.44 2.83 3.76 5.14 6.59 6.59l2.2-2.2c.27-.27.67-.36 1.02-.24 1.12.37 2.33.57 3.57.57.55 0 1 .45 1 1V20c0 .55-.45 1-1 1-9.39 0-17-7.61-17-17 0-.55.45-1 1-1h3.5c.55 0 1 .45 1 1 0 1.25.2 2.45.57 3.57.11.35.03.74-.25 1.02l-2.2 2.2z"/></svg>
        <span class="option-contact__info__value" v-html="$mx_highlightPhrase(props.option.phone, query)"></span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'option-contact',
  props: ['props', 'query', 'value']
};
</script>
<style lang="scss">
.option-contact {
  padding: 7px 20px;
  font-size: 14px;
  line-height: 20px;
  background: #fff;
  color: #333;
  margin: -10px -15px;
  &__name {
    display: flex;
    justify-content: space-between;
    & + * {
      margin-top: 7px;
    }
  }
  &__info {
    display: flex;
    color: rgba(0, 0, 0, 0.54);
    font-size: 12px;
    &__icon {
      width: 12px;
      height: 20px;
      flex: 0 0 12px;
      margin-right: 5px;
      fill: #dbdbdb;
    }
    &__value {
      flex-grow: 1;
    }
    &__column {
      padding: 0 7px;
      display: flex;
      &:first-child {
        padding-left: 0;
        flex: 0 0 33.33%;
        width: 33.33%;
      }
      &:last-child {
        padding-right: 0;
        text-align: right;
      }
    }
  }
  em {
    background-color: rgba(255, 211, 32, 0.1);
    font-style: normal;
  }
  .multiselect__option--highlight & {
    background-color: #e9f4fd;
  }
}
</style>
