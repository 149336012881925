import {
  PAYMENT_STATUSES_HTML,
  ORDER_STATUS_ARCHIVED,
  ORDER_STATUS_ARCHIVED_LOST
} from './orders';
import {forEach} from "lodash";
import {
  APPAREL_SERVICE_CODE, BLANK_SERVICE_CODE, BUTTON_SERVICE_CODE, DIGITAL_SERVICE_CODE,
  EMBROIDERY_SERVICE_CODE,
  INVOICE_SERVICE_CODE,
  OTHER_SERVICE_CODE, POSTER_SERVICE_CODE, STICKER_SERVICE_CODE
} from '@/store/service-types';

function phasesStateForDesign(design) {
  if (design.service === BLANK_SERVICE_CODE) {
    return {
      S: false,
      B: true,
      M: false,
      P: true,
      Sh: true,
      F: true
    };
  }
  if (
    design.service === DIGITAL_SERVICE_CODE ||
    design.service === STICKER_SERVICE_CODE ||
    design.service === POSTER_SERVICE_CODE
  ) {
    return {
      S: true,
      B: false,
      M: true,
      P: true,
      Sh: true,
      F: true
    };
  }
  if (design.service === BUTTON_SERVICE_CODE) {
    return {
      S: true,
      B: false,
      M: false,
      P: true,
      Sh: true,
      F: true
    };
  }
  if (
    design.service === APPAREL_SERVICE_CODE ||
    design.service === OTHER_SERVICE_CODE ||
    design.service === EMBROIDERY_SERVICE_CODE
  ) {
    return {
      S: true,
      B: true,
      M: true,
      P: true,
      Sh: true,
      F: true
    };
  }
  if (design.service === INVOICE_SERVICE_CODE) {
    return {
      S: false,
      B: false,
      M: false,
      P: false,
      Sh: false,
      F: false
    };
  }
  return {
    S: true,
    B: true,
    M: true,
    P: true,
    Sh: true,
    F: true
  };
}

function phasesStateForOrder(designs) {
  let activePhases = {
    S: false,
    B: false,
    M: false,
    P: false,
    Sh: false,
    F: false
  };
  let scopePhasesFromDesings = [];
  designs.forEach(design => {
    scopePhasesFromDesings.push(phasesStateForDesign(design));
  });
  scopePhasesFromDesings.forEach(scope => {
    //if some design has active phase so the all order has an active phase
    if (scope.S === true) activePhases.S = true;
    if (scope.B === true) activePhases.B = true;
    if (scope.M === true) activePhases.M = true;
    if (scope.P === true) activePhases.P = true;
    if (scope.Sh === true) activePhases.Sh = true;
    if (scope.F === true) activePhases.F = true;
  });
  return activePhases;
}

export default {
  getProgressIcon(progress, isDisabled = false, error = false) {
    const progressMax = 100;
    switch (true) {
      case error:
        return 'progress-circle-error';
      case isDisabled:
        return 'progress-circle-disabled';
      case progress >= progressMax:
        return 'progress-circle-done';
      case progress > 0:
        return 'progress-circle-inprogress';
      default:
        return 'progress-circle-none';
    }
  },
  getInner(source, id, field) {
    var val = '';
    source.forEach(design => {
      if (design[0].value == id) {
        design.forEach((col, colKey) => {
          if (col.column == field) {
            val = col.value;
          }
        });
      }
    });
    return val;
  },
  setInner(source, id, field, value) {
    source.forEach((row, key) => {
      if (row[0].value == id) {
        row.forEach((col, colKey) => {
          if (col.column == field) {
            source[key][colKey].value = value;
          }
        });
      }
    });
  },
  setInnerStr(source, id, field, value) {
    source.forEach((row, key) => {
      if (row[0].value == id) {
        row.forEach((col, colKey) => {
          if (col.column == field) {
            source[key][colKey].value.str = value;
          }
        });
      }
    });
  },
  dropRow(grid, actions, id) {
    grid.data.forEach((gridRow, key) => {
      if (gridRow && gridRow[0].value == id) {
        grid.data.splice(key, 1);
        actions.data.splice(key, 1);
      }
    });
  },
  phasesStateForOrder,
  phasesStateForDesign,
  convertProgressTypeToPhase(progressType) {
    /**
     * shipping process has two words in phases
     */
    if (
      progressType
        .split('_')[1]
        .substring(0, 2)
        .toLowerCase() === 'sh'
    )
      return 'Sh';
    return progressType.split('_')[1][0].toUpperCase();
  }
};

export const toPriceFormat = price => {
  if (!price) {
    return `$${price}`;
  } else if (typeof price === 'string') {
    return `$${parseFloat(price).toFixed(2)}`.replace(
      /\B(?=(\d{3})+(?!\d))/g,
      ','
    );
  } else if (typeof price === 'number') {
    return `$${price.toFixed(2)}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }
};

export const getGridOrderPaymentStatus = order => {
  return PAYMENT_STATUSES_HTML[order.payment_status];
};

/**
    useless method since ETPM-1710 task
    it was used in orders-summary.js  to get short desing name in getGridOrderFinishings and getGridOrderDetails methods
    and also used in orders.js  in getGridOrderDetails and getGridOrderFinishings methods
 */
export const strToShort = (str, maxCharsLength = 18, toCharsLength = 18) => {
  if (!str) {
    return '';
  }
  str = str.toString();
  if (str.toString().length > maxCharsLength) {
    return str.substring(0, toCharsLength) + '...';
  }
  return str;
};

export const colorizeArchivedOrderLinks = (order, colorizeAction) => {
  let isOrder = !!order.is_order;
  if (
    colorizeAction &&
    order &&
    (order.status === ORDER_STATUS_ARCHIVED ||
      order.status === ORDER_STATUS_ARCHIVED_LOST)
  )
    return `<a style="color: #6f6f6b"  href="/#/${
      isOrder ? 'orders' : 'estimates'
    }/${order.id}/details">${isOrder ? order.id : order.id + '(E)'}</a>`;
  else
    return `<a href="/#/${isOrder ? 'orders' : 'estimates'}/${
      order.id
    }/details">${isOrder ? order.id : order.id + '(E)'}</a>`;
};

export const formatFiltersOptions = (filter_options) => {
  if (typeof filter_options.csr_users === 'object') {
    let csr_users = [];
    let has_deleted_users = false;
    forEach(filter_options.csr_users, (v, k) => {
      if (!has_deleted_users && v.is_deleted === 1) {
        has_deleted_users = true;
        csr_users.push({ label: 'Deleted' });
      }
      csr_users.push({ label: v.csr_name, key: v.id });
    });

    let art_producers = [];
    forEach(filter_options.art_producers, v => {
      art_producers.push({ label: v.title, key: v.id });
    });

    let order_statuses = [];
    forEach(filter_options.order_statuses, (v, k) => {
      order_statuses.push({ label: v, key: k });
    });

    let estimate_statuses = [];
    forEach(filter_options.estimate_statuses, (v, k) => {
      estimate_statuses.push({ label: v, key: k });
    });

    let phases = [];
    forEach(filter_options.phases, (v, k) => {
      phases.push({ label: v, key: k });
    });

    let printers = [];
    forEach(filter_options.printers, (v, k) => {
      printers.push({ label: v, key: k });
    });

    let payment_status = [];
    forEach(filter_options.payment_status, (v, k) => {
      payment_status.push({ label: v, key: k });
    });
    let service_types = [];
    forEach(filter_options.service_types, v => {
      service_types.push({ label: v.label, key: v.key });
    });
    let redo_categories = [];
    forEach(filter_options.redo_categories, v => {
      redo_categories.push({ label: v.label, key: v.key });
    });
    let delivery_types = [];
    forEach(filter_options.delivery_types, v => {
      delivery_types.push({ label: v.label, key: v.key });
    });

    let companies = [];
    forEach(filter_options.companies, v => {
      companies.push({ label: v.label, key: v.key });
    });

    return {
      csr_users: csr_users,
      order_statuses: order_statuses,
      estimate_statuses: estimate_statuses,
      phases: phases,
      printers: printers,
      payment_status: payment_status,
      art_producers: art_producers,
      service_types: service_types,
      redo_categories: redo_categories,
      delivery_types: delivery_types,
      companies: companies
    };
  }
}
