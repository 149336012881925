<template>
  <div class="order-comment widget has-separator">
    <div v-if="!editable && order_comment">
      <dl>
        <dt><strong>ORDER NOTES:</strong></dt>
        <dd class="break-word content-pre" v-html="order_comment"></dd>
      </dl>
    </div>
    <div v-else-if="editable">
      <dl>
        <dt><strong>ORDER NOTES:</strong></dt>
        <dd>
          <wysiwyg ref="wysiwyg"
                   @value-changed="onOrderNotesChanged"
                   placeholder="*Enter note(s)"
                   :value="order_comment"></wysiwyg>
        </dd>
      </dl>
      <hr>

    </div>
  </div>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep';
import Wysiwyg from '../../common/TextEditors/wysiwyg';

export default {
  name: 'order-comment',
  components: { Wysiwyg },
  props: {
    order: {
      type: Object,
      required: true
    },
    editable: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      order_comment:
        (this.order && this.order.comment && cloneDeep(this.order.comment)) ||
        ''
    };
  },
  watch: {
    order(order) {
      let order_comment = (order && cloneDeep(order.comment)) || '';
      this.$set(this, 'order_comment', order_comment);
    }
  },
  computed: {},
  methods: {
    getFormData() {
      return this.$refs.wysiwyg.getFormData();
    },
    onOrderNotesChanged(newValue){
      this.order_comment = newValue;
    },
  }
};
</script>
