import { getDesignTotal as getApparelDesignTotal } from './apparel';
import { getDesignTotal as getBlankDesignTotal } from './blank_samples';
import { getDesignTotal as getEmbroideryDesignTotal } from './embroidery';

import { getDesignTotal as getButtonDesignTotal } from './button';
import { getDesignTotal as getDigitalDesignTotal } from './digital';
import { getDesignTotal as getPosterDesignTotal } from './poster';
import { getDesignTotal as getStickerDesignTotal } from './sticker';
import { getDesignTotal as getOtherDesignTotal } from './other';
import { getDesignTotal as getNonOrderInvoiceTotal } from './non_order_invoice';
import { getDesignTotal as getPromotionalDesignTotal } from './promotional';
import { getDesignTotal as getFinishingCreationDesignTotal } from './finishings_creation';
import { getDesignTotal as getPaperDesignTotal } from './paper';

import { finishingTotalPerDesign } from '@/helpers/prices/finishings';
import {
  APPAREL_SERVICE_CODE,
  BLANK_SERVICE_CODE,
  BUTTON_SERVICE_CODE,
  DIGITAL_SERVICE_CODE,
  EMBROIDERY_SERVICE_CODE, FINISHING_CREATION_SERVICE_CODE,
  INVOICE_SERVICE_CODE,
  OTHER_SERVICE_CODE, PAPER_SERVICE_CODE,
  POSTER_SERVICE_CODE, PROMOTIONAL_SERVICE_CODE,
  STICKER_SERVICE_CODE
} from '@/store/service-types';

export const getDesignTotal = (design, formOptions, basePrice = false) => {
  switch (design.common.service) {
    case APPAREL_SERVICE_CODE:
      return getApparelDesignTotal(design, formOptions, basePrice);
    case BLANK_SERVICE_CODE:
      return getBlankDesignTotal(design, formOptions, basePrice);
    case BUTTON_SERVICE_CODE:
      return getButtonDesignTotal(design, formOptions);
    case DIGITAL_SERVICE_CODE:
      return getDigitalDesignTotal(design, formOptions);
    case EMBROIDERY_SERVICE_CODE:
      return getEmbroideryDesignTotal(design, formOptions, basePrice);
    case POSTER_SERVICE_CODE:
      return getPosterDesignTotal(design, formOptions);
    case STICKER_SERVICE_CODE:
      return getStickerDesignTotal(design, formOptions);
    case OTHER_SERVICE_CODE:
      return getOtherDesignTotal(design, formOptions);
    case INVOICE_SERVICE_CODE:
      return getNonOrderInvoiceTotal(design, formOptions);
    case PROMOTIONAL_SERVICE_CODE:
      return getPromotionalDesignTotal(design, formOptions);
    case FINISHING_CREATION_SERVICE_CODE:
      return getFinishingCreationDesignTotal(design, formOptions);
    case PAPER_SERVICE_CODE:
      return getPaperDesignTotal(design, formOptions);
  }
};

export const getEstimateTotals = (data, formOptions, finishingsPrices) => {
  const designsTotalArray = data.designs.map(design => {
    const designFinishings = data.finishings.filter(
      ({ items }) =>
        items.filter(item => item.design_id === design.common.uniqueId).length >
        0
    );
    const cleanTotal = getDesignTotal(
      design,
      formOptions[design.common.service]
    );
    const finishingsTotal = designFinishings.reduce((total, finishing) => {
      return (
        total + finishingTotalPerDesign(finishing, design, finishingsPrices)
      );
    }, 0);

    return {
      raw: cleanTotal,
      total: cleanTotal + finishingsTotal
    };
  });

  return {
    designs: designsTotalArray,
    designsTotal: designsTotalArray.reduce(
      (total, designTotal) => +total + +designTotal.total,
      0
    ),
    tagSetups: data.finishings.reduce((total, finishing) => {
      if (finishing.specific) {
        // If finishing without tag setups.
        // Or no prices
        if (
          finishing.specific.tag_setups_number === undefined ||
          (finishing.specific.tag_setups_number_cost === undefined &&
            finishingsPrices[finishing.specific.price_code] === undefined)
        ) {
          return total;
        } else {
          // If there are tag setups.
          if (finishing.specific.tag_setups_number) {
            // If cost calculated before.
            if (
              finishing.specific.tag_setups_number_cost !== undefined &&
              finishing.specific.tag_setups_number_cost !== null
            ) {
              total +=
                finishing.specific.tag_setups_number_cost *
                finishing.specific.tag_setups_number;
            } else {
              // If prices exists
              if (
                finishingsPrices[finishing.specific.price_code] !== undefined
              ) {
                total +=
                  finishingsPrices[finishing.specific.price_code][0] *
                  finishing.specific.tag_setups_number;
              }
            }
          }
          return total;
        }
      }

      return total;
    }, 0),
    stateTaxTotal: +data.payment.state_tax,
    shipping: +data.order.shipping_cost,
    rushFee: +data.payment.rush_fee.rush_fee_amount,
    discount: +data.payment.discount.discount_amount
  };
};
