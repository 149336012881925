import { getQtySegmentUpcharge } from '@/helpers/prices/base';

/**
 * Calculate design total.
 *
 * @param design
 * @param embroideryDesignData
 * @returns {number}
 */
export const getDesignTotal = (design, embroideryDesignData, basePrice = false) => {
  let totalGarmentQty = +design.specific.extra.total_garments_qty;
  let totalLocations = +design.specific.extra.total_locations;
  let locationsCost = 0;
  const hasValidLocation = design.specific.locations.find(
    ({ location, stitches_id, depth }) =>
      location !== null && stitches_id !== null && depth !== null
  );

  if (!hasValidLocation) return 0;

  if (totalLocations > 0) {
    locationsCost = getLocationsTotal(
      design.specific.locations,
      totalGarmentQty,
      embroideryDesignData
    );
  }

  let garments = design.specific.garments;
  let garmentsUpcharge = garmentsTotal(
    garments,
    locationsCost,
    embroideryDesignData,
    basePrice
  );

  return garmentsUpcharge + +design.common.upcharge;
};

/**
 * Calculate garments total.
 *
 * @param garments
 * @param locationsCost
 * @param prices
 * @param basePrice
 * @returns {number}
 */
export const garmentsTotal = (garments, locationsCost, prices, basePrice) => {
  return garments.reduce((gt, garment) => {
    return gt + garmentTotal(garment, locationsCost, prices, basePrice);
  }, 0);
};

export const garmentTotal = (garment, locationsCost, prices, basePrice = false) => {
  return Object.keys(garment.sizes).reduce((st, sizeKey) => {
    const size = garment.sizes[sizeKey];
    const sizeCost =
      garment &&
      garment &&
      garment.size_cost &&
      garment.size_cost[sizeKey] &&
      garment.size_cost[sizeKey].cost !== undefined &&
      garment.size_cost[sizeKey].cost !== null &&
      !basePrice
        ? +garment.size_cost[sizeKey].cost
        : calculateSizeCost(
          basePrice && garment.model.baseUpcharge ? garment.model.baseUpcharge : garment.model.upcharge,
            sizeKey,
            locationsCost,
            prices
          );
    return st + (+size || 0) * sizeCost;
  }, 0);
};

/**
 * Apply locations formula to size cost.
 *
 * @param sizeBaseCost
 * @param sizeKey
 * @param locationsCost
 * @param prices
 * @returns {number}
 */
export const calculateSizeCost = (
  sizeBaseCost,
  sizeKey,
  locationsCost,
  prices
) => {
  let sizeCost = +sizeBaseCost;
  sizeCost += +(prices.garment_upcharges[`size_${sizeKey.toLowerCase()}`] || 0);
  sizeCost += locationsCost;
  return +parseFloat(sizeCost.toFixed(2));
};

/**
 * Calculate locations cost.
 *
 * @param locations
 * @param totalGarmentQty
 * @param prices
 * @returns {number}
 */
export const getLocationsTotal = (locations, totalGarmentQty, prices) => {
  return locations.reduce((acc, location) => {
    if (location.cost !== undefined && location.cost !== null) {
      return acc + +location.cost;
    }
    // Get prices per location option.
    let depthOption = prices.depth.find(depth => location.depth === depth.key);
    let stitchesOption = prices.stitches.find(
      s => +s.key === +location.stitches_id
    );
    let depthUpcharge = (depthOption && +depthOption.upcharge) || 0;
    let stitchesUpcharge =
      (stitchesOption &&
        stitchesOption.upcharge &&
        getQtySegmentUpcharge(
          stitchesOption.upcharge,
          1000,
          totalGarmentQty
        )) ||
      0;

    // Calculate cost.
    let location_cost = depthUpcharge + stitchesUpcharge;
    return acc + location_cost;
  }, 0);
};
