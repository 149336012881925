<template>
  <div v-if="garment.id">
    <h2>Garment details</h2>
    <table class="table is-fullwidth">
      <thead>
      <tr>
        <th>Item Description</th>
        <th>Size</th>
        <th class="ta-right">QTY</th>
      </tr>
      </thead>
      <tbody>
      <tr class="is-name">
        <td colspan="3">BRAND: {{brand_label}}</td>
      </tr>
      <tr class="has-separator">
        <td>{{paper_color_label}}:</td>
        <td>{{size_label}}</td>
        <td class="ta-right">{{quantity_label}}</td>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { canApproveMock } from '../../../../helpers/role-access';

export default {
  name: 'garment-poster-details',
  props: {
    design: {
      type: Object,
      required: true
    },
    options: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    canApproveMock
  }),
  computed: {
    garment() {
      return this.design ? this.design.designable.item : {};
    },
    size_label() {
      let label = 'N/A';

      if (this.design.designable.item.custom_size) {
        label = this.design.designable.item.custom_size;
      } else if (this.design.designable.item.size_object) {
        label = this.design.designable.item.size_object.name;
      }
      return label;
    },
    quantity_label() {
      return this.design.designable.item.quantity_object
        ? this.design.designable.item.quantity_object.name
        : 'N/A';
    },
    brand_label() {
      let label = 'N/A';

      if (this.design.designable.item.custom_brand) {
        label = this.design.designable.item.custom_brand;
      } else if (this.design.designable.item.brand_object) {
        label = this.design.designable.item.brand_object.name;
      }
      return label;
    },
    paper_color_label() {
      let label = 'N/A';

      if (this.design.designable.item.custom_paper_color) {
        label = this.design.designable.item.custom_paper_color;
      } else if (this.design.designable.item.paper_color_object) {
        label = this.design.designable.item.paper_color_object.name;
      }
      return label;
    },
    bleed_type_label() {
      return this.design.designable.item.bleed_type_object
        ? this.design.designable.item.bleed_type_object.name
        : 'N/A';
    }
  }
};
</script>

<style scoped>
h2 {
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 1rem;
  margin-bottom: 10px;
}
.is-name td {
  background: #dedede;
}
th {
  border: none;
}
.has-separator:last-child {
  border-bottom: 1px solid;
  border-color: #eae6e6;
}
</style>
