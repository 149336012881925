<template>
  <!-- Estimate fotm -->
  <form>
    <!-- Contact Information -->
    <h5 class="subtitle">Contact Information</h5>
    <fieldset class="contact-information control">
      <div class="columns">
        <div class="column is-4 columns">
          <div class="column">
            <form-input :readonly="true"
                        v-model="accountTitle"
                        name="account_title"
                        label="Account"
                        :scope="formScope"
                        :onClick="accountsPopup"/>
          </div>
          <div class="column" v-roles="['admin', 'csr', 'production-coordinator', 'purchaser', 'art-producer']">
            <br>
            <div class="control account-select-cntr">
              <button type="button" class="button" @click="accountsPopup">Select</button>
              <i v-if="showCustPricesIcon"
                 class="icon icon__bigger icon__red ff-warning"
                 aria-hidden="true"
                 @click="customPricesNotificationPopup"></i>
            </div>
          </div>
        </div>
        <div class="column" v-if="accountModel.id">
          <form-input v-model="estimate.account.contact_firstname"
                      name="contact_firstname"
                      :scope="formScope"
                      label="Contact First Name"/>
        </div>
        <div class="column" v-if="accountModel.id">
          <form-input v-model="estimate.account.contact_lastname"
                      name="contact_lastname"
                      :scope="formScope"
                      label="Contact Last Name"/>
        </div>
      </div>
      <div class="columns" v-if="accountModel.id">
        <div class="column is-4">
          <form-input v-model="estimate.account.email" name="email" :scope="formScope" rules="required|email"/>
        </div>
        <div class="column is-4">
          <form-input v-model="estimate.account.phone" name="phone" :scope="formScope" rules="required|verify_phone"/>
        </div>
      </div>
    </fieldset>
    <!-- END Contact Info-->

    <fieldset class="shipping-address control" v-if="accountModel.id">
      <h5 class="subtitle">Recipient</h5>
      <div class="columns">
        <div class="column">
          <form-input v-model="estimate.account.recipient_firstname"
                      name="recipient_firstname"
                      :scope="formScope"
                      label="First Name"
                      :required="false" :rules="''"/>
        </div>
        <div class="column">
          <form-input v-model="estimate.account.recipient_lastname"
                      name="recipient_lastname"
                      :scope="formScope"
                      label="Last Name"
                      :required="false"
                      :rules="''"/>
        </div>
        <div class="column">
          <form-input v-model="estimate.account.recipient_company"
                      name="recipient_company"
                      :scope="formScope"
                      label="Company"
                      :required="false" :rules="''"/>
        </div>
      </div>
    </fieldset>

    <!-- Shipping Address -->
    <fieldset class="shipping-address control" v-if="accountModel.id">
      <h5 class="subtitle">Shipping Address</h5>
      <div class="columns">
        <div class="column">
          <form-select v-model="estimate.account.s_country_id"
                       name="s_country_id"
                       :scope="formScope"
                       label="Country"
                       :options="countriesOptions"
                       @change="getStates('s')" />
        </div>
        <div class="column">
          <form-select v-if="states && states.s_country_id && states.s_country_id.length !== 0"
                       v-model="estimate.account.s_state_id"
                       :scope="formScope"
                       name="s_state_id"
                       label="State/Province"
                       :required="true"
                       :rules="'required'"
                       :options="statesOptions('s_country_id')"/>
          <form-input v-else v-model="estimate.account.s_state"
                      :scope="formScope"
                      :rules="'required|max:3|uppercase'"
                      name="s_state"
                      label="State/Province Code"/>
        </div>
        <div class="column">
          <form-input v-model="estimate.account.s_city" name="s_city" :scope="formScope" label="City"/>
        </div>
      </div>
      <div class="columns">
        <div class="column">
          <form-input v-model="estimate.account.s_mailing_address"
                      name="s_mailing_address"
                      :scope="formScope"
                      label="Mailing Address"/>
        </div>
        <div class="column">
          <form-input v-model="estimate.account.s_postal_code"
                      name="s_postal_code"
                      :scope="formScope"
                      label="Postal Code"/>
        </div>
        <div class="column flex flex__vertical-center">
          <div class="control form-checkbox-container">
            <label>
              <input v-model="estimate.account.billing_address_same"
                     type="checkbox"
                     class="checkbox">
              Billing Address Same as Shipping
            </label>
          </div>
        </div>
      </div>
    </fieldset>
    <!-- END Shipping Address -->

    <!-- Billing Address -->
    <fieldset class="billing-address control" v-if="accountModel.id">
      <h5 class="subtitle">Billing Address</h5>
      <div class="columns">
        <div class="column">
          <form-select v-model="estimate.account.b_country_id"
                       :scope="formScope"
                       name="b_country_id"
                       label="Country"
                       :options="countriesOptions"
                       @change="getStates('b')" />
        </div>
        <div class="column is-4">
          <form-select v-if="states && states.b_country_id && states.b_country_id.length !== 0"
                       v-model="estimate.account.b_state_id"
                       :scope="formScope"
                       name="b_state_id"
                       :required="true"
                       :rules="'required|max:3'"
                       label="State/Province"
                       :options="statesOptions('b_country_id')"/>
          <form-input v-else v-model="estimate.account.b_state"
                      :scope="formScope"
                      name="b_state"
                      :rules="'required|max:3|uppercase'"
                      label="State/Province Code"/>
        </div>
        <div class="column">
          <form-input v-model="estimate.account.b_city"
            :scope="formScope"
            name="b_city"
            label="City" />
        </div>
      </div>
      <div class="columns">
        <div class="column">
          <form-input v-model="estimate.account.b_mailing_address"
                      :scope="formScope"
                      name="b_mailing_address"
                      label="Mailing Address"/>
        </div>
        <div class="column  is-4">
          <form-input v-model="estimate.account.b_postal_code"
                      :scope="formScope"
                      name="b_postal_code"
                      label="Postal Code"/>
        </div>
      </div>
    </fieldset>

    <!-- Payment-->
    <fieldset class="billing-address control" v-if="accountModel.id">
      <h5 class="subtitle">Payment</h5>
      <div class="columns">
        <div class="column is-4">
          <form-select v-model="estimate.account.payment_term"
                       :scope="formScope"
                       :addEmpty="false"
                       :name="'payment_term'"
                       label="Term"
                       :options="{all: paymentOptionsAll}"/>
        </div>
      </div>
    </fieldset>
    <!-- END Billing Address -->

    <hr>
    <!-- Designs -->
    <designs :designModels="estimate.designs"
             :summary="estimate.summary.designs"
             :account="estimate.account"
             :finishings="estimate.finishings"
             :finishingOptions="finishingOptionsAll"
             :formScope="formScope"
             :accountId="accountId"
             :isEdit="isEdit"
             ref="designs"
             @summary-changed="onSummaryChanged"
             @drop-design="onDropDesign"
             @get-package-qty="onGetPackageQty"
    ></designs>
    <!-- Designs -->
    <hr>
    <div v-if="isFinishable">
      <h3 class="title is-3">Finishings</h3>
      <fieldset v-for="(finishing, key) in estimate.finishings" :key="key">
        <div class="columns">
          <div class="column is-4">
            <form-select rules="" v-model="finishing.finishing_option"
                         :scope="formScope"
                         name="finishing_option"
                         label="Finishing Option"
                         :options="{all: finishingOptionsAll}"/>
            <div class="finishings-minimum-notification notification"
                 v-if="showFinishingGarmentsMinimumWarning(finishing, key)">
              Please note, {{finishingLabel(finishing)}} require a 50 Piece Minimum.
            </div>
          </div>
          <div v-if="designFinishingsOptions.length" class="column is-8">
            <label class="label">
              <span class="required">*</span>
              Related design
            </label>
            <div class="control">
              <label v-for="item in designFinishingsOptions"
                     v-if="item.label"
                     :key="item.label"
                     class="related-design-checkbox">
                <input v-model="finishing.related_design"
                       @input="setTotalGarmentsForFinishing(key)"
                       type="checkbox"
                       :value="item.key"/> {{item.label}}
              </label>
            </div>
            <form-input type="hidden" value="1"
                        name="related_designs_checkbox"
                        :scope="formScope"
                        :rules="'one_checked:' + finishing.related_design"/>
          </div>
        </div>
        <div class="columns">
          <div class="column is-4">
            <form-input v-model="finishing.name"
                        :scope="formScope"
                        name="finishing_name"
                        label="Finishing Name"/>
          </div>
        </div>
        <component v-bind:is="finishing.finishing_option"
                   :finishing="finishing.fields"
                   :finishingKey="key"
                   :designs="estimate.designs"
                   @selectedDesignChanged="selectedDesignChanged"
                   :selectedDesigns="finishing.related_design"></component>
        <div class="control">
          <a @click="dropRow(estimate.finishings, key)"
             class="button is-danger is-small">
            <span>Delete</span>
            <i class="icon icon__bigger ff-cross-mark"></i>
          </a>
        </div>
        <hr>
      </fieldset>
      <div class="control">
        <button type="button"
                @click="addFinishing(estimate.finishings)"
                class="button">Add Finishing
        </button>
      </div>
    </div>
    <hr v-if="isFinishable">
    <div class="columns"
         v-roles="['admin', 'csr', 'production-coordinator', 'purchaser', 'art-producer', 'printer']">
      <div class="column is-4">
        <form-input name="shipping_cost" label="Shipping Cost"
                    v-model="estimate.account.shipping_cost"
                    :scope="formScope"
                    type="number" rules="min_value:0"
                    step="0.01" min="0.00"
                    :required="false"/>
      </div>
    </div>
    <div v-if="stateTaxes[estimate.account.s_state_id]">
      <br>
      <div class="columns">
        <div class="column is-8">
          <div class="notification">
            Sales Tax ({{estimate.account.s_state_id}}): {{stateTaxes[estimate.account.s_state_id]}}%
            <br>
            You can remove the tax by adding a Tax ID:
          </div>
        </div>
        <div class="column is-4">
          <form-input v-model.trim="estimate.account.tax_id"
                      :scope="formScope" name="tax_id" label="Tax id"
                      rules="max:16|alpha_dash" :required="false"/>
        </div>
      </div>
      <br>
    </div>
    <hr>

    <discount
      v-roles="['admin', 'csr', 'production-coordinator', 'purchaser', 'art-producer']"
      :scope="formScope"
      :totalDiscount="totalDiscount"
      :totalPrice="totalPrice"
      :originalPrice="originalPrice"
      :modelDiscount="estimate.discount"
      @applyDiscount="onDiscountApply"
    >
    </discount>
    <rushfee
       v-roles="['admin', 'csr', 'production-coordinator', 'purchaser', 'art-producer']"
       :scope="formScope"
       :modelRushFee="estimate.summary.rush_fee"
       :totalPrice="totalPrice"
       :orderSummaryRushfee="orderSummary.total.rush_fee"
       @updateRushFee="onUpdateRushFee"
    >
    </rushfee>

    <label class="label" for="account-comment">Order Comment</label>
    <div class="control  has-text-centered">
      <textarea v-model="estimate.account.comment"
                name="comment"
                class="textarea"
                id="account-comment"></textarea>
    </div>

    <div class="control control-form-actions" v-if="canMarkAsTest">
      <label for="test-order-option">
        <input id="test-order-option"
               type="checkbox"
               v-model="estimate.account.is_test"/>
        Check if you want to mark this order as a test.
      </label>

    </div>

    <div class="control control-form-actions">
      <button @click.prevent="submitForm(submitFormAction)"
              :class="{'is-loading': processing, 'button is-medium is-primary': true}"
              :disabled="processing || gettingPackageQty">{{ submitFormButtonText }}
      </button>
      <button v-if="!isOrder && !isSent"
              @click.prevent="submitForm('saveDraft')"
              :class="{'is-loading': processing, 'button is-medium is-primary': true}"
              :disabled="processing || gettingPackageQty">{{ options.submitDraftBtnText }}
      </button>
      <button class="button is-medium"
              :disabled="processing || gettingPackageQty"
              @click.prevent="cancel">Cancel
      </button>
    </div>
  </form>
  <!-- END Estimate form -->
</template>

<style lang="scss" scoped>
.control-form-actions {
  margin-top: 25px;
}

.control-form-actions .button {
  margin-left: 15px;
}

.control-form-actions .button:first-child {
  margin-left: 0;
}

.related-design-checkbox {
  white-space: nowrap;
  margin-right: 7px;
  float: left;

  input {
    margin-right: 5px;
  }
}

.icon__red {
  color: red;
  margin-left: 5px;
}

.account-select-cntr {
  display: flex;
  align-items: center;
}

.finishings-minimum-notification.notification {
  padding: 6px 8px;
  margin-top: 10px;
  min-width: 360px;
}
</style>

<script>
import bus from '../../bus';
import Vue from 'vue';
import VueCookie from 'vue-cookie';
import { mapGetters, mapActions } from 'vuex';

import {
  forEach,
  some,
  isEmpty,
  cloneDeep,
  filter,
  includes,
  debounce,
  isEqual
} from 'lodash';

import notify from '../../modules/notifier';
import alertify from 'alertify.js';
import moment from 'moment';
import popup from '../../modules/popupper';

import apiService from '../../services/api.service';
import { checkRoles } from '../../router/checkRoles';
import { LOAD_ACCOUNT, CLEAR_ACCOUNT } from '../../store/action-types';

//form
import formInput from '../common/form/input';
import formSelect from '../common/form/select';

import SearchPopupComponent from '../accounts/popups/search-popup.vue';
import SelectAddressSourceComponent from './popups/select-address-source.vue';

//inner
import tagPrinting from './finishings/tag-printing';
import hemTag from './finishings/hem-tag';
import wovenNeckTag from './finishings/woven-neck-tag';
import fourSidedTag from './finishings/four-sided-tag';
import foldingBagging from './finishings/folding-bagging';
import hangTag from './finishings/hang-tag';
import heatPressedTags from './finishings/heat-pressed-tags';
import sewnTagBottomOrSleeve from './finishings/sewn-tag-bottom-or-sleeve';
import sewnTagNeckAndFourSided from './finishings/sewn-tag-neck-and-four-sided';

import designs from './designs';
import Discount from './discount';
import Rushfee from './rushfee';

export default {
  name: 'estimate-edit-form',
  components: {
    Discount,
    designs,
    formInput,
    formSelect,
    tagPrinting,
    heatPressedTags,
    sewnTagBottomOrSleeve,
    sewnTagNeckAndFourSided,
    hemTag,
    wovenNeckTag,
    fourSidedTag,
    foldingBagging,
    hangTag,
    Rushfee
  },
  props: {
    options: {
      type: Object,
      default: {
        submitBtnText: 'Submit'
      }
    },
    processing: {
      type: Boolean,
      default: false
    },
    isOrder: {
      type: Boolean,
      default: false
    },
    isEdit: {
      type: Boolean,
      default: false
    },
    model: {
      type: Object,
      default: () => ({})
    },
    errorsList: {
      type: Array,
      default: []
    },
    isSent: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      checkedNames: [],
      estimate: cloneDeep(this.model),
      statesCount: 1,
      states: {
        s_country_id: [
          {
            key: '',
            label: 'Please select a country first'
          }
        ],
        b_country_id: [
          {
            key: '',
            label: 'Please select a country first'
          }
        ]
      },
      formScope: 'order',
      gettingPackageQty: false,
      new_finishing: {
        id: '',
        name: '',
        finishing_option: '',
        related_design: [],
        fields: {},
        totalGarments: 0
      },
      accountId: null,
      packagesQty: 0,
      stateTaxes: [],
      finishing_prices: {},
      finishingsTotalGarmentsMinimum: 50
    };
  },
  watch: {
    model: {
      handler(model) {
        this.$set(this, 'estimate', model);
      },
      deep: true
    },
    orderSummary: {
      handler(data) {
        this.$emit('orderSummary', data);
      },
      deep: true
    },
    errorsList(errors) {
      if (errors && errors.length) {
        errors.forEach(error => {
          if (error) {
            bus.$emit('add-error', {
              field: error.field,
              msg: error.msg,
              rule: error.rule,
              scope: error.scope,
              fullName: error.fullName
            });
          }
        });
      }
    },
    'estimate.account.billing_address_same': function(same) {
      if (same) {
        this.cloneShippingToBilling();
      }
    },
    'estimate.account.shipping_cost': function(val, oldVal) {
      bus.$emit('orderSummary', this.orderSummary);
    }
  },
  computed: {
    ...mapGetters({
      countriesAll: 'orderCountries',
      paymentOptionsAll: 'paymentTerms',
      finishingOptionsAll: 'orderFinishings',
      accountModel: 'account',
      isTestAccount: 'isTestAccount'
    }),
    canMarkAsTest() {
      return (
        (this.isTestAccount &&
          checkRoles(this.$store.getters.roles, ['admin', 'csr'])) ||
        checkRoles(this.$store.getters.roles, ['admin'])
      );
    },
    showCustPricesIcon() {
      return (
        (this.accountModel &&
          (this.accountModel.company_id &&
            this.accountModel.has_custom_price_company)) ||
        (!this.accountModel.company_id && this.accountModel.has_custom_price)
      );
    },
    accountTitle() {
      let title =
        this.accountModel && this.accountModel.title
          ? this.accountModel.title
          : '';

      switch (this.estimate.account.address_source) {
        case 'company':
          title =
            this.accountModel && this.accountModel.company
              ? this.accountModel.company.name
              : '';
          break;
        case 'customer':
          title =
            this.accountModel && this.accountModel.title
              ? this.accountModel.title
              : '';
          break;
      }

      return title;
    },
    countriesOptions() {
      let countries = {
        primary: [],
        all: []
      };

      this.countriesAll.forEach(country => {
        if (country.is_primary) {
          countries.primary.push({
            key: country.country_id,
            label: country.name
          });
        }

        countries.all.push({
          key: country.country_id,
          label: country.name
        });
      });

      return countries;
    },

    designFinishingsOptions() {
      let options = [];
      if (Object.keys(this.orderSummary.designs).length) {
        forEach(this.orderSummary.designs, (design, key) => {
          if (design.type === 'apparel' || design.type === 'embroidery') {
            options.push({
              key: +key,
              label: design.name ? design.name : 'Design #' + (+key + 1)
            });
          }
        });
      }
      return options;
    },

    isFinishable() {
      return some(this.orderSummary.designs || [], design => {
        return design.type === 'apparel' || design.type === 'embroidery';
      });
    },
    orderSummary() {
      let dateFormat = 'MM/DD/YY';
      let stateTax = this.stateTaxes[this.estimate.account.s_state_id] || 0;
      let total = {
        qty: 0,
        price: 0,
        totalDiscount: +this.estimate.discount.discount_amount || 0,
        shipping_cost: 0,
        state_tax: 0,
        packages_qty: this.estimate.account.packages_qty,
        upcharge: 0,
        rush_fee:
          (this.estimate.summary.rush_fee &&
            this.estimate.summary.rush_fee.rush_fee_amount &&
            `${this.estimate.summary.rush_fee.rush_fee_amount}`) ||
          0
      };

      if (!isEmpty(this.estimate.summary.designs)) {
        forEach(this.estimate.summary.designs, (design, key) => {
          if (design.total) {
            total.qty += design.total.qty;
            total.price += design.total.price;
            total.upcharge += design.total.upcharge || 0;
          }
        });
      }

      const o = this.estimate.account;
      const acc = this.accountModel;

      let countryFiltered =
        this.countriesAll && this.countriesAll.length
          ? this.countriesAll.find(c => c.country_id === o.s_country_id)
          : null;

      const country = countryFiltered ? countryFiltered.name : '';

      if (total.price >= 0) {
        if (stateTax) {
          total.state_tax = !o.tax_id
            ? Number(total.price / 100 * stateTax).toFixed(2) * 1
            : 0;
          total.price += total.state_tax;
          total.state = o.s_state_id;
          total.tax_id = o.tax_id;
        }
        total.shipping_cost = Number(o.shipping_cost).toFixed(2) * 1;
        total.price += total.shipping_cost;
        total.price += Number(this.finishinsUpcharge).toFixed(2) * 1;
        total.price -= total.totalDiscount;
        total.price += Number(total.rush_fee).toFixed(2) * 1;
        total.originalPrice = Number(
          total.price + total.totalDiscount - total.rush_fee
        ).toFixed(2);
        total.price = Number(total.price).toFixed(2);
      }

      const orderSummary = {
        companyName:
          o.recipient_company || (acc.company && acc.company.name) || '',
        contactName:
          (o.contact_firstname &&
            o.contact_lastname &&
            o.contact_firstname + ' ' + o.contact_lastname) ||
          '',
        address: {
          mailing_address: o.s_mailing_address,
          city: o.s_city,
          state_id: o.s_state_id,
          state: o.s_state,
          postal_code: o.s_postal_code,
          country: country
        },
        recipient: {
          firstname: o.recipient_firstname,
          lastname: o.recipient_lastname,
          company: o.recipient_company
        },
        estimateDate: moment().format(dateFormat),
        ship_by_date: this.estimate.summary.ship_by_date,
        print_by_date: this.estimate.summary.print_by_date,
        total: total,
        comment: this.estimate.account ? this.estimate.account.comment : ''
      };

      return { ...this.estimate.summary, ...orderSummary };
    },
    isShippable() {
      let design =
        (this.estimate &&
          this.estimate.designs &&
          this.estimate.designs.find(
            design =>
              design.common && design.common.type_of_delivery === 'shipping'
          )) ||
        {};
      let acc = (this.estimate && this.estimate.account) || {};

      return (
        acc &&
        acc.s_country_id === 'US' &&
        acc.s_postal_code &&
        acc.s_postal_code.length >= 5 &&
        design &&
        design.common &&
        design.common.service
      );
    },
    isReorder() {
      return (
        this.$route.params.id &&
        this.$cookie.get('reorder_id') == this.$route.params.id
      );
    },
    originalPrice() {
      let totalPrice =
        (this.orderSummary &&
          this.orderSummary.total &&
          this.orderSummary.total.originalPrice) ||
        0;
      return +totalPrice;
    },
    totalPrice() {
      let totalPrice =
        (this.orderSummary &&
          this.orderSummary.total &&
          this.orderSummary.total.price) ||
        0;
      return +totalPrice;
    },
    totalDiscount() {
      let totalPrice =
        (this.orderSummary &&
          this.orderSummary.total &&
          this.orderSummary.total.totalDiscount) ||
        0;
      return +totalPrice;
    },
    finishinsUpcharge() {
      let finishings =
        (this.estimate && cloneDeep(this.estimate.finishings)) || 0;
      let price = 0;
      if (finishings) {
        finishings.forEach(finishing => {
          if (
            finishing.fields &&
            finishing.fields.price_code !== undefined &&
            this.finishing_prices[finishing.fields.price_code] !== undefined
          ) {
            price += this.getFinishingsUpcharge(
              this.finishing_prices[finishing.fields.price_code],
              finishing
            );
          }
        });
      }
      return +price;
    },
    submitFormAction() {
      if (!this.isSent) {
        return 'saveAndSend';
      } else if (this.isSent && this.isEdit) {
        return 'saveOrder';
      }
    },
    submitFormButtonText() {
      if (!this.isSent) {
        return this.options.submitAndSendBtnText;
      } else if (this.isSent) {
        return this.options.submitBtnText;
      }
    }
  },
  methods: {
    ...mapActions({
      loadAccount: LOAD_ACCOUNT,
      clearAccount: CLEAR_ACCOUNT
    }),
    onUpdateRushFee(rushFee) {
      this.$set(this.estimate.summary, 'rush_fee', rushFee);
    },
    getFinishingPrices(user_id) {
      return apiService.getApparelFinishingPrices(user_id).then(
        data => {
          return (this.finishing_prices = data);
        },
        function(response) {
          notify({
            message: 'Could not load finishing prices',
            type: 'danger'
          });
        }
      );
    },
    getFinishingsUpcharge(items, finishing) {
      let basePrice = 0;
      let setupPrice = 0;
      let finishing_fields = finishing.fields;
      let totalGarments =
        finishing.finishing_option === 'heat-pressed-tags'
          ? +finishing_fields.tag_garments_quantity
          : finishing.totalGarments;

      forEach(items, function(price, num_garments) {
        let minMax = num_garments.split('-');

        if (minMax.length == 1) {
          if (minMax[0] == '0' || minMax[0] == 'setup') {
            if (finishing_fields.tag_setups_number !== undefined) {
              setupPrice =
                +parseFloat(price) * (finishing_fields.tag_setups_number * 1);
            } else {
              setupPrice = 0; //+parseFloat(price);
            }
          } else if (minMax[0] == 1000 && totalGarments >= 1000) {
            basePrice += parseFloat(price * totalGarments);
          }
        } else if (minMax[0] <= totalGarments && minMax[1] >= totalGarments) {
          basePrice += parseFloat(price * totalGarments);
        }
      });

      return basePrice + setupPrice;
    },

    validateBeforeSubmit(e) {
      this.clearChild();
      this.errors.errors = [];
      this.validateChild();

      return new Promise((resolve, reject) => {
        Vue.nextTick(() => {
          resolve(!this.errors.count());
        });
      });
    },

    submitForm(action) {
      this.validateBeforeSubmit().then(isValid => {
        this.$emit('submitForm', isValid, action);
      });
    },

    cancel() {
      this.$emit('cancel');
    },

    addFinishing(array) {
      let f = this.estimate.finishings.slice(0);
      if (this.estimate.finishings.length === 0) {
        f.push(Object.assign({}, this.new_finishing));
      } else {
        const clone = Object.assign({}, array[0]);
        for (let key in clone) {
          switch (typeof clone[key]) {
            case 'number':
            case 'string':
              clone[key] = '';
              break;
            case 'object':
              clone[key] = {};
              break;
            case 'boolean':
              clone[key] = false;
              break;
          }
        }
        f.push(clone);
      }
      this.$set(this.estimate, 'finishings', f);
    },

    dropRow(array, key) {
      array.splice(key, 1);
    },

    getStates(field, callback, flush_state_id = true) {
      if (this.estimate.account[field + '_country_id']) {
        return apiService
          .getStates(this.estimate.account[field + '_country_id'])
          .then(data => {
            this.statesCount = data.total;
            this.states[field + '_country_id'] = data.list;

            // Sometimes we don't need to clean the state_id.
            // For example in case when wee already set it on fillContactInfo and emitted account-change event.
            if (flush_state_id) {
              this.estimate.account[field + '_state_id'] = '';
              this.estimate.account[field + '_state'] = '';
            }
            if (callback) {
              callback();
            }
          });
      }
    },

    cloneShippingToBilling() {
      let account = this.estimate.account;

      account.b_mailing_address = account.s_mailing_address;
      account.b_city = account.s_city;
      account.b_country_id = account.s_country_id;
      account.b_postal_code = account.s_postal_code;
      account.b_state = account.s_state;

      this.getStates('b', () => {
        account.b_state_id = account.s_state_id;
      });
    },

    statesOptions(country) {
      return {
        all: this.states[country]
      };
    },

    validateChild() {
      bus.$emit('validate');
    },

    clearChild() {
      bus.$emit('clear');
    },

    accountsPopup() {
      this.clearChild();
      popup({
        title: 'Customers',
        bodyComponent: SearchPopupComponent,
        additional: {
          popupCallback: this.afterAccountSelected
        },
        modalCardLong: true,
        showButtons: false
      });
    },

    afterAccountSelected(accountId) {
      this.loadAccount(accountId).then(() => {
        if (
          this.accountModel.company &&
          this.accountModel.company.shipping_addresses &&
          this.accountModel.company.billing_addresses
        ) {
          popup({
            title: 'Select address source',
            bodyComponent: SelectAddressSourceComponent,
            showButtons: false,
            additional: {
              popupCallback: this.fillContactInfo,
              closeCallback: this.clearAccount
            },
            modalCardLong: true
          });
        } else {
          this.fillContactInfo('customer', true);
        }
      });
    },

    clearAccountDataCallback() {
      this.clearAccount();
    },

    onMountFillContactInfo(accountId) {
      return this.loadAccount(accountId).then(() => {
        this.fillContactInfo('customer');
      });
    },

    fillContactInfo(address_source, forced) {
      let acc = this.accountModel;
      let account = Object.assign({}, this.estimate.account);

      this.accountId = acc.id;

      if (!this.$route.params.id || this.isReorder || !!forced) {
        account.account_id = acc.id;
        account.contact_firstname = acc.first_name;
        account.contact_lastname = acc.last_name;
        account.email = acc.email;
        account.phone = acc.phone;
        account.address_source = address_source;

        let shipping_address = {};
        let billing_address = {};
        let payment_terms = '';

        if (address_source == 'company') {
          if (
            acc.company.billing_addresses &&
            acc.company.billing_addresses.length > 0
          ) {
            billing_address = acc.company.billing_addresses[0];
          }
          if (
            acc.company.shipping_addresses &&
            acc.company.shipping_addresses.length > 0
          ) {
            shipping_address = acc.company.shipping_addresses[0];
          }
          if (acc.company) {
            payment_terms = acc.company.terms;
          }
        } else {
          if (acc.billing_addresses && acc.billing_addresses.length > 0) {
            billing_address = acc.billing_addresses[0];
          }
          if (acc.shipping_addresses && acc.shipping_addresses.length > 0) {
            shipping_address = acc.shipping_addresses[0];
          }
          payment_terms = acc.terms;
        }

        if (shipping_address) {
          account.s_mailing_address = shipping_address.address_line_1;
          if (shipping_address.address_line_2) {
            account.s_mailing_address += ', ' + shipping_address.address_line_2;
          }
          account.s_city = shipping_address.city;
          account.s_country_id = shipping_address.country_code;
          account.s_postal_code = shipping_address.postal_code;
          account.s_state_id = shipping_address.state_code;
          account.s_state = shipping_address.state_code;
        }

        if (billing_address) {
          account.b_mailing_address = billing_address.address_line_1;
          if (billing_address.address_line_2) {
            account.b_mailing_address += ', ' + billing_address.address_line_2;
          }
          account.b_city = billing_address.city;
          account.b_country_id = billing_address.country_code;
          account.b_postal_code = billing_address.postal_code;
          account.b_state_id = billing_address.state_code;
          account.b_state = billing_address.state_code;
        }

        account.payment_term = payment_terms;

        bus.$emit('account-changed', account);

        this.$nextTick(() => {
          this.getStates('s', () => {}, false);
          this.getStates('b', () => {}, false);
        });
      }
      bus.$emit('order-set-account-info', this.accountId);
    },

    getPackageQtyDebounced(account) {
      this.packageQtyDebouncer(account);
    },

    getPackageQty() {
      if (!this.gettingPackageQty) {
        this.gettingPackageQty = true;
        let package_qty = 0;

        this.estimate.designs.forEach(design => {
          let qty = 0;
          if (
            (design.common.service === 'apparel' ||
              design.common.service === 'embroidery') &&
            design.common.type_of_delivery === 'shipping'
          ) {
            if (design.specific.garments) {
              design.specific.garments.forEach(garment => {
                qty += parseInt(garment.count);
              });
            }
            package_qty += Math.ceil(qty / 100) > 0 ? Math.ceil(qty / 100) : 0;
          } else {
            package_qty = 0;
            this.estimate.account.shipping_cost = 0;
          }
        });
        this.estimate.account.packages_qty = package_qty;
        this.gettingPackageQty = false;
      }
    },

    customPricesNotificationPopup() {
      return alertify
        .okBtn('Ok')
        .alert(
          'Please note, this customer has specific prices for some products which will be automatically applied.'
        );
    },

    getStateTaxes() {
      apiService.getStatesTaxes().then(data => {
        this.stateTaxes = data;
      });
    },

    onDropDesign(key) {
      this.$set(
        this.estimate,
        'designs',
        this.estimate.designs.filter((a, index) => index !== key)
      );
    },

    setOrder() {
      return this.$refs.designs
        .setDesigns()
        .then(designs => {
          this.$set(this.estimate, 'designs', designs);
          return this.estimate;
        })
        .catch(error => {
          notify({
            message: error.message,
            type: 'danger'
          });
        });
    },

    onSummaryChanged(designKey, summary) {
      if (summary) {
        this.$set(this.estimate.summary.designs, designKey, summary);
      } else {
        for (let i = designKey; i <= this.estimate.designs.length; i++) {
          if (this.estimate.summary.designs[i + 1] != undefined) {
            this.$set(
              this.estimate.summary.designs,
              i,
              this.estimate.summary.designs[i + 1]
            );
            this.$delete(this.estimate.summary.designs, i + 1);
          } else {
            this.$delete(this.estimate.summary.designs, i);
          }
        }
      }
      this.$nextTick(() =>
        this.estimate.finishings.map((f, fKey) =>
          this.setTotalGarmentsForFinishing(fKey)
        )
      );
    },

    onGetPackageQty(debounced) {
      this.setOrder().then(estimate => {
        if (debounced) {
          this.getPackageQtyDebounced();
        } else {
          this.getPackageQty();
        }
      });
    },

    calculateTotalGarmentsForFinishing(finishingKey) {
      // Get garments quantity from all related to a finishing designs.
      let numGarments = 0;
      if (
        this.estimate &&
        this.estimate.finishings &&
        this.estimate.finishings[finishingKey] &&
        this.estimate.finishings[finishingKey].finishing_option ===
          'heat-pressed-tags'
      ) {
        numGarments =
          (this.estimate.finishings[finishingKey].fields &&
            +this.estimate.finishings[finishingKey].fields
              .tag_garments_quantity) ||
          0;
      } else if (
        this.estimate &&
        this.estimate.finishings &&
        this.estimate.finishings[finishingKey]
      ) {
        let finishing = this.estimate.finishings[finishingKey];
        let relatedDesigns = finishing.related_design;
        let filteredDesigns =
          filter(this.orderSummary.designs, (d, dKey) =>
            includes(relatedDesigns, +dKey)
          ) || [];
        if (filteredDesigns.length) {
          filteredDesigns.map(d => {
            if (d.garments) {
              d.garments.map(
                garment => (numGarments += (garment && garment.qty) || 0)
              );
            }
          });
        }
      }
      return numGarments;
    },
    setTotalGarmentsForFinishing(finishingKey) {
      let totalGarments = this.calculateTotalGarmentsForFinishing(finishingKey);
      this.$set(
        this.estimate.finishings[finishingKey],
        'totalGarments',
        totalGarments
      );
    },
    onDiscountApply(discount) {
      this.$set(this.estimate, 'discount', discount);
      this.$set(this.estimate.summary, 'discount', discount);
    },
    showFinishingGarmentsMinimumWarning(finishing, finishingKey) {
      return (
        finishing &&
        (finishing.finishing_option === 'tag-printing' ||
          finishing.finishing_option === 'heat-pressed-tags') &&
        finishing.related_design &&
        finishing.related_design.length &&
        this.calculateTotalGarmentsForFinishing(finishingKey) <
          this.finishingsTotalGarmentsMinimum
      );
    },
    finishingLabel(finishing) {
      let label = 'Finishing';
      if (finishing && finishing.finishing_option) {
        switch (finishing.finishing_option) {
          case 'tag-printing':
            label = 'Printed tags';
            break;
          case 'heat-pressed-tags':
            label = 'Heat Pressed Tags';
            break;
        }
      }
      return label;
    },

    selectedDesignChanged() {
      this.setOrder().then(() => {
        bus.$emit('updateTagSetupsNumber');
      });
    },

    beforeUnload($event) {
      let confirmationMessage = 'o/';

      $event.returnValue = confirmationMessage; // Gecko, Trident, Chrome 34+
      return confirmationMessage; // Gecko, WebKit, Chrome <34
    }
  },
  created() {
    this.getStateTaxes();

    if (!this.$route.params.id) {
      this.clearAccount();
    }

    bus.$on('set-order', () => {
      this.$emit('set-order', this.estimate);
    });

    bus.$on('errors-changed', errors => {
      if (errors) {
        errors.forEach(e => {
          this.errors.add(e.field, e.msg, e.rule, e.scope);
        });
      }
    });

    bus.$on('errors-deleted', oldErrors => {
      if (oldErrors) {
        oldErrors.forEach(e => {
          this.errors.remove(e.field, e.scope);
        });
      }
    });

    if (
      this.estimate &&
      this.estimate.account &&
      this.estimate.account.account_id
    ) {
      this.accountId = this.estimate.account.account_id;
    }
  },
  mounted() {
    if (this.estimate.account.s_country_id) {
      this.getStates(
        's',
        (s_state_id => {
          this.estimate.account.s_state_id = s_state_id;
        }).bind(this, this.estimate.account.s_state_id)
      );
    }

    if (this.estimate.account.b_country_id) {
      this.getStates(
        'b',
        (b_state_id => {
          this.estimate.account.b_state_id = b_state_id;
        }).bind(this, this.estimate.account.b_state_id)
      );
    }

    this.$emit('orderSummary', this.orderSummary);

    if (checkRoles(this.$store.getters.roles, ['customer'], true)) {
      this.onMountFillContactInfo(this.$store.getters.userProfile.id);
    } else if (
      this.estimate &&
      this.estimate.account &&
      this.estimate.account.account_id
    ) {
      this.onMountFillContactInfo(this.estimate.account.account_id);
    }

    Vue.use(VueCookie);

    this.packageQtyDebouncer = debounce(this.getPackageQty, 500);

    bus.$on('updateFinishingsPrice', () => {
      this.estimate.finishings.map((f, fKey) =>
        this.setTotalGarmentsForFinishing(fKey)
      );
    });
    bus.$on('order-set-account-info', user_id => {
      this.getFinishingPrices(user_id);
    });
  },
  beforeMount() {
    window.addEventListener('beforeunload', this.beforeUnload);
  },
  beforeDestroy() {
    bus.$emit('account-changed', {});
    window.removeEventListener('beforeunload', this.beforeUnload);
  }
};
</script>
