<template>
  <div class="form-item" :class="{
    'is-horizontal': horizontal,
    'with-icon': $slots.icon,
    'has-errors': errors.has(scope + '.' + name)
  }">

    <div class="form-item-icon" v-if="$slots.icon">
      <slot name="icon"></slot>
    </div>

    <label
      class="form-item-label"
      :for="!static ? (scope + '-' + name) : false"
      v-if="(!hideLabel || !hasLabel) && type !== 'hidden'"
    >
      <template v-if="!fakeLabel">
        {{ label }}
        <span
          class="form-item-required"
          v-if="required && type !== 'hidden'"
        > *</span>
      </template>
      <template v-else>&nbsp;</template>
    </label>

    <slot name="input-before"></slot>
    <slot>
      <div class="form-item-control" v-if="!static">
        <button
          class="form-item-clear"
          @click.prevent="clear"
          v-if="clearable && value && value.length"
        >x</button>
        <component
          :is="money ? 'v-money' : 'input'"
          class="form-item-input"
          :type="type"
          :readonly="readonly"
          :placeholder="placeholder | capitalize"
          :id="scope + '-' + name"
          :name="scope + '-' + name"
          :disabled="disabled"
          :class="{ 'has-errors': errors.has(scope + '.' + name) }"
          :key="scope + '-' + name"
          :data-vv-name="name"
          :data-vv-as="label | capitalize"
          :data-vv-validate-on="validateEvent"
          v-bind="attrs"
          v-validate="{ rules: inputRules, scope: scope }"
          @focus="selectAllOnFocus && $event.target.select()"
          :value="value"
          @input="input"
          v-on="listeners"
          ref="input"
          v-if="type !== 'textarea'"
        ></component>
        <textarea
          class="form-item-input"
          cols="30"
          rows="10"
          :readonly="readonly"
          :placeholder="placeholder | capitalize"
          :id="scope + '-' + name"
          :name="scope + '-' + name"
          :disabled="disabled"
          :class="{ 'has-errors': errors.has(scope + '.' + name) }"
          :key="scope + '-' + name"
          :data-vv-name="name"
          :data-vv-as="label | capitalize"
          v-bind="attrs"
          v-validate="{ rules: inputRules, scope: scope }"
          @click="onClick"
          @focus="selectAllOnFocus && $event.target.select()"
          :value="value"
          @input="input"
          v-on="listeners"
          ref="input"
          v-if="type === 'textarea'"
        ></textarea>
        <div class="garment-sizes__price" v-if="hasAfterLabel" >
          <template v-if="afterLabel !== '' && afterLabel !== 0">{{ afterLabel | price }}</template>
          <template v-else>-</template>
        </div>
        <span
          class="form-item-help help is-danger is-visible error-msg"
          v-show="showErrors &&
            (errors.has(scope + '.' + name) || $slots.errors)
          "
        >
          <slot name="errors">{{ errors.first(scope + '.' + name) }}</slot>
        </span>
      </div>
      <div class="form-item-static" v-else>{{ value }}</div>
    </slot>
    <slot name="input-after"></slot>

  </div>
</template>

<script>
import Vue from 'vue';
import { cloneDeep } from 'lodash';
import bus from '../../../bus';
import alertify from 'alertify.js';
import { Money } from 'v-money';
import { mapActions } from 'vuex';
import { ADD_VALIDATION_PROMISE } from '@/store/action-types';

const defaultRules = { max: 255 };

export default {
  name: 'form-item',
  inheritAttrs: false,
  components: {
    'v-money': Money
  },
  props: {
    validateEvent: {
      default: 'change',
      type: String
    },
    money: {
      default: null,
      validator: prop => prop === null || typeof prop === 'object'
    },
    clearable: {
      type: Boolean,
      default: false
    },
    focusOnAppear: {
      type: Boolean,
      default: false
    },
    selectAllOnFocus: {
      type: Boolean,
      default: false
    },
    static: {
      type: Boolean,
      default: false
    },
    name: {},
    disabled: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: ''
    },
    hideLabel: {
      type: Boolean,
      default: false
    },
    fakeLabel: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: ''
    },
    value: {},
    required: {
      type: Boolean,
      default: false
    },
    rules: {
      type: [String, Object],
      default: 'max:255'
    },
    scope: {
      type: String,
      default: 'form-scope'
    },
    fieldsetName: String,
    type: {
      type: String,
      default: 'text'
    },
    min: {
      type: [Number, String],
      default: ''
    },
    max: {
      type: [Number, String],
      default: ''
    },
    step: {
      type: [String, Number],
      default: 0.01
    },
    horizontal: {
      type: Boolean,
      default: false
    },
    onClick: {
      type: Function,
      default: () => {
        return false;
      }
    },
    showErrors: {
      type: Boolean,
      default: true
    },
    afterLabel: {
      type: [String, Number],
      default: undefined
    },
  },
  data() {
    const attrs = {};
    if (this.type === 'number') {
      attrs.step = this.step;
      attrs.min = this.min;
      attrs.max = this.max;
    }
    if (this.money) {
      Object.assign(attrs, this.money);
    }
    return {
      attrs
    };
  },
  computed: {
    listeners() {
      const listeners = {};
      Object.keys(this.$listeners).forEach(listener => {
        if (listener === 'input') return;
        listeners[listener] = this.$listeners[listener];
      });
      return listeners;
    },
    inputRules() {
      let rules = {};
      if (typeof this.rules === 'object') {
        rules = cloneDeep(this.rules);
      } else {
        this.rules
          .trim()
          .split('|')
          .forEach(rule => {
            if (!rule || rule.length === 0) return;
            const parts = rule.split(':');
            rules[parts[0]] = parts[1] === undefined ? true : parts[1];
          });
      }
      rules.required = this.required;
      return rules;
    },
    hasLabel() {
      return this.label.length > 0 || this.fakeLabel;
    },
    hasAfterLabel() {
      return this.afterLabel !== undefined;
    },
  },
  watch: {
    value(val, prevVal) {
      if (+val === +prevVal) return;
      if (this.$refs.input) {
        this.$refs.input.value = val; // HOTFIX
      }
    },
    static: {
      handler(val) {
        if (val === true) {
          this.destroyValidation();
        } else {
          this.initValidation();
        }
      },
      immediate: true
    }
  },
  methods: {
    ...mapActions({
      addValidationPromise: ADD_VALIDATION_PROMISE
    }),
    destroyValidation() {
      bus.$emit('errors-deleted', this.errors && this.errors.errors);
      bus.$off('validate', this.onValidate);
      bus.$off('clear', this.onClear);
      bus.$off('add-error', this.addError);
    },
    initValidation() {
      bus.$on('add-error', this.addError);
      bus.$on('validate', this.onValidate);
      bus.$on('clear', this.onClear);
      this.$watch(
        () => this.errors.errors,
        (value, oldValue) => {
          if (value.length) {
            bus.$emit('errors-changed', value);
          } else {
            bus.$emit('errors-deleted', oldValue);
          }
        }
      );
    },

    clear() {
      this.$emit('input', null);
    },
    input(event) {
      this.$emit('input', event.target ? event.target.value : event);
    },
    onValidate(validateScope) {
      let promise;
      if (validateScope) {
        promise = this.$validator.validateAll(validateScope).catch(err => {});
      } else {
        promise = this.$validator.validateScopes().catch(err => {});
      }
      this.addValidationPromise(promise);
    },

    onClear() {
      this.errors.clear(this.scope);
    },

    addError(e) {
      if (
        e.field === this.name &&
        e.scope === this.scope &&
        (!this.fieldsetName ||
          e.fullName === `${this.fieldsetName}.${this.name}`)
      ) {
        this.errors.add(
          e.field,
          e.msg.replace(e.fullName, this.getLabel),
          e.rule,
          e.scope
        );
        if (this.showErrors) {
          alertify.error(e.msg.replace(e.fullName, this.getLabel));
        }
      }
    }
  },
  mounted() {
    if (this.focusOnAppear && this.$refs.input) {
      this.$refs.input.focus();
    }
  },
  beforeDestroy() {
    if (!this.static) {
      this.destroyValidation();
    }
  }
};
</script>
