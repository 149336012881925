<template>
  <div class="form-row"
    :class="{ 'with-icon': $slots.icon }"
    :style="{ '--gap': `${gap}px` }"
  >
    <div class="form-row-icon" v-if="$slots.icon">
      <slot name="icon"></slot>
    </div>
    <slot></slot>
  </div>
</template>
<script>
export default {
  name: 'form-row',
  props: {
    gap: {
      default: 15,
      type: [String, Number]
    }
  }
};
</script>
