<template>
  <div class="design-info widget has-separator" v-if="design.designable && design.designable.item">
      <dl class="info">
        <dt><strong>POSTER SIZE: </strong>{{ size_label }}</dt>
        <dt><strong>COLORS: </strong>{{ number_of_colors_label }}</dt>
        <dt><strong>INKS: </strong>{{ ink_type_label }}</dt>
        <div v-if="!editable">
          <dt class="bleed-type"><strong>BLEED: </strong>{{ bleed_type_label }}</dt>
          <dt v-if="item.colors && item.colors.length" class="pms-colors tags">
            <div class="label is-marginless">COLORS: {{item.colors.length}}
            </div>
            <span v-for="(color, key) in item.colors" :key="`mock-item-color-${key}`">
                  <span class="tag is-small">
                    <span class="color-box" :style="'background-color:#' + color.hex"> </span>
                    <span>{{ color.name }}</span>
                  </span>
                </span>
          </dt>
          <div v-if="item.location_comment" class="location-comment">
            <dt><strong>LOCATION NOTES: </strong></dt>
            <dd class="break-word content-pre" v-html="item.location_comment">
            </dd>
          </div>
        </div>
        <!-- EDITABLE FIELDS SECTION. -->
        <div v-else>
          <div class="field-editable">
            <form-select v-model="item.bleed_type"
                         :id="`poster-bleed-type`"
                         tooltip
                         tooltip-ico="pensil"
                         tooltip-position="left"
                         :name="`poster-bleed-type`"
                         :horizontal="true"
                         :hideLabel="false"
                         :label="'BLEED:'"
                         :options="{all: options.bleed}"/>
          </div>
          <div class="field-editable">
            <pms-color-multiselect
              tooltip
              tooltip-ico="pensil"
              tooltip-position="left"
              :required="true"
              :name="`poster-pms-colors`"
              :id="`poster-pms-colors`"
              v-model="item.colors"></pms-color-multiselect>
          </div>
          <div class="field-editable">
            <dl>
              <dt><strong>LOCATION NOTES:</strong></dt>
              <dd>
                <wysiwyg ref="wysiwyg"
                         placeholder="*Enter note(s)"
                         @value-changed="onLocationCommentChanged"
                         :value="item.location_comment"></wysiwyg>
              </dd>
            </dl>
          </div>
        </div>
      </dl>
    </div>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep';
import PmsColorMultiselect from '../../../common/form/pms-color-multiselect';
import FormSelect from '../../../common/form/select';
import FormTextarea from '../../../common/form/textarea';
import Wysiwyg from '../../../common/TextEditors/wysiwyg';

export default {
  name: 'design-info-poster',
  components: { Wysiwyg, FormTextarea, FormSelect, PmsColorMultiselect },
  props: {
    design: {
      type: Object,
      required: true
    },
    order: {
      type: Object,
      required: true
    },
    mock: {
      type: Object,
      required: true
    },
    options: {
      type: Object,
      required: true
    },
    editable: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      item: {
        location: '',
        bleed_type:
          this.mock &&
          this.mock.items &&
          this.mock.items[0] &&
          this.mock.items[0].bleed_type
            ? cloneDeep(this.mock.items[0].bleed_type)
            : this.setMockBleedType(),
        colors:
          this.mock &&
          this.mock.items &&
          this.mock.items[0] &&
          this.mock.items[0].colors
            ? cloneDeep(this.mock.items[0].colors)
            : this.setMockPmsColors(),
        width: 0,
        height: 0,
        location_comment:
          this.mock &&
          this.mock.items &&
          this.mock.items[0] &&
          this.mock.items[0].location_comment
            ? cloneDeep(this.mock.items[0].location_comment)
            : ''
      }
    };
  },
  watch: {
    mock(mock) {
      let bleed_type =
        mock && mock.items && mock.items[0] && mock.items[0].bleed_type
          ? cloneDeep(mock.items[0].bleed_type)
          : this.setMockBleedType();
      this.$set(this.item, 'bleed_type', bleed_type);

      let colors =
        mock && mock.items && mock.items[0] && mock.items[0].colors
          ? cloneDeep(mock.items[0].colors)
          : this.setMockPmsColors();
      this.$set(this.item, 'colors', colors);

      let location_comment =
        mock && mock.items && mock.items[0] && mock.items[0].location_comment
          ? cloneDeep(this.mock.items[0].location_comment)
          : '';
      this.$set(this.item, 'location_comment', location_comment);
    }
  },
  computed: {
    number_of_colors_label() {
      return this.design.designable.item.colors_number_object
        ? this.design.designable.item.colors_number_object.name
        : 'N/A';
    },
    size_label() {
      let label = 'N/A';

      if (this.design.designable.item.custom_size) {
        label = this.design.designable.item.custom_size;
      } else if (this.design.designable.item.size_object) {
        label = this.design.designable.item.size_object.name;
      }
      return label;
    },
    quantity_label() {
      return this.design.designable.item.quantity_object
        ? this.design.designable.item.quantity_object.name
        : 'N/A';
    },
    brand_label() {
      let label = 'N/A';

      if (this.design.designable.item.custom_brand) {
        label = this.design.designable.item.custom_brand;
      } else if (this.design.designable.item.brand_object) {
        label = this.design.designable.item.brand_object.name;
      }
      return label;
    },
    paper_weight_label() {
      return this.design.designable.item.paper_weight_object
        ? this.design.designable.item.paper_weight_object.name
        : 'N/A';
    },
    paper_color_label() {
      let label = 'N/A';

      if (this.design.designable.item.custom_paper_color) {
        label = this.design.designable.item.custom_paper_color;
      } else if (this.design.designable.item.paper_color_object) {
        label = this.design.designable.item.paper_color_object.name;
      }
      return label;
    },
    bleed_type_label() {
      let bleed_type =
        (this.mock &&
          this.mock.items &&
          this.mock.items[0] &&
          this.mock.items[0].bleed_type) ||
        (this.design.designable.item &&
          this.design.designable.item.bleed_type) ||
        null;

      return bleed_type && this.options && this.options.bleed
        ? this.options.bleed.find(bleed => +bleed.key === +bleed_type).label
        : 'N/A';
    },
    ink_type_label() {
      return this.design.designable.item.ink_type_object
        ? this.design.designable.item.ink_type_object.name
        : 'N/A';
    }
  },
  methods: {
    getFormData() {
      this.item.location_comment = this.$refs.wysiwyg.getFormData();
      return [this.item];
    },
    setMockBleedType() {
      let latest_mock =
        this.design &&
        this.design.mocks.length &&
        this.design.mocks[this.design.mocks.length - 1];
      return (
        (latest_mock &&
          latest_mock.items &&
          latest_mock.items.length &&
          cloneDeep(latest_mock.items[0].bleed_type)) ||
        (this.design.designable.item &&
          cloneDeep(this.design.designable.item.bleed_type)) ||
        null
      );
    },
    setMockPmsColors() {
      let latest_mock =
        this.design &&
        this.design.mocks.length &&
        this.design.mocks[this.design.mocks.length - 1];
      return (
        (latest_mock &&
          latest_mock.items &&
          latest_mock.items.length &&
          latest_mock.items[0].colors &&
          cloneDeep(latest_mock.items[0].colors)) ||
        []
      );
    },
    onLocationCommentChanged(newValue){
      this.item.location_comment = newValue;
    },
  }
};
</script>
<style>
.pms-colors .tag {
  background: lightgray;
  color: grey;
  border-radius: 5px;
  margin: 2px;
}
</style>
