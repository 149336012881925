<template>
  <div class="finishing">
    <h5 class="title is-5 is-marginless has-text-weight-bold">{{ label }}</h5>
    <div class="control">
      <div>
        <strong>Name: </strong>
        {{ item.finishing.name }}
      </div>
      <div>
        <strong>Remove Original Tag: </strong>
        {{item.finishing.finishable.four_sided_tag_type}}
      </div>
      <div>
        <strong>Thread Color: </strong>
        {{ item.finishing.finishable.four_sided_tag_thread_color }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'four-sided-tag',
  props: {
    item: {
      type: Object,
      required: true
    },
    label: {
      type: String,
      required: true
    }
  }
};
</script>
