<template>
  <div id="payments-root">
    <nav class="level">
      <!-- Left side -->
      <breadcrumbs :links="crumbLinks"/>
      <template v-if="!invoices.length">

      </template>
    </nav>

    <div class="payments-data-body">
      <div class="columns">
        <div class="column is-8">
          <div class="form-row payment-term-section">
            <div class="form-item">
              <label class="form-item-label">
                Payment Term
              </label>
              <div class="form-item-static">{{paymentTerms[order.payment_term]}}</div>
            </div>
          </div>
        </div>
        <div class="column">
          <div class="form-row fixed-2 payment-total-section">
            <div class="form-item" v-if="grandPaymentStatus">
              <label :class="[
                        'form-item-label',
                        'tag',
                        'is-medium',
                        `payment-status-${grandPaymentStatus.toLowerCase().replace(' ', '-')}`
                     ]"
              >
                {{grandPaymentStatus}}
              </label>
            </div>
            <div class="form-item">
              <label class="form-item-label total-label">
                Total {{order.total_price || 0 | price}}
              </label>
              <div :class="{
                      'form-item-static': true,
                      'clr-red': balanceDue > 0,
                      'clr-green': balanceDue <= 0
                    }">
                Balance Due {{ balanceDue | price}}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="columns">
        <div class="column">
          <table class="table is-fullwidth is-striped">
            <tr class="table-head">
              <th>Transaction #</th>
              <th>Status</th>
              <th>Payment Method</th>
              <th>Payment Date</th>
              <th>Note</th>
              <th>Amount Paid</th>
              <th></th>
              <th></th>
            </tr>
            <template v-if="transactions.length">
              <tr
                v-for="(transaction, index) in transactions"
                :key="`transaction-${index}`"
                :class="{
                  'table-body': true,
                  'is-failed': !transaction.edit && transaction.status === 2
                }"
              >
                <template v-if="!transaction.edit">
                  <td style="max-width: 250px;" class="break-word">
                    #{{transaction.transaction_id}}
                  </td>
                </template>
                <td class="transaction-status" :style="{paddingTop: transaction.edit ? '0px' : '10px'}">
                  <span :class="{
                          'tag': true,
                          'payment-status-paid': (transaction.status === 0 || transaction.status === 1),
                          'payment-status-unpaid': (transaction.status === null || transaction.status === 2),
                        }"
                  >
                    {{transaction.status === null ? 'Unpaid' :transactionStatuses[transaction.status]}}
                  </span>
                </td>
                <template v-if="!transaction.edit">
                  <td>{{getPaymentMethodLabel(transaction)}}</td>
                  <td>{{formatDate(transaction.created_at)}}</td>
                  <td style="max-width: 250px;" class="break-word content-pre">{{transaction.note ? transaction.note : '---'}}</td>
                  <td><strong>{{transaction.amount | price}}</strong></td>
                </template>
                <template v-if="transaction.status !== 2">
                  <template v-if="!transaction.edit">
                    <td class="transaction-actions">
                     </td>
                    <td class="transaction-actions">
                    </td>
                  </template>
                  <template v-else>
                    <td class="transaction-actions">

                    <td class="transaction-actions">

                    </td>
                  </template>
                </template>
                <template v-else>
                  <td></td>
                  <td>
                  </td>
                </template>
              </tr>
            </template>
            <template v-else>
              <tr class="payments-no-result">
                <td colspan="8"><p>There are no transactions.</p></td>
              </tr>
            </template>
            <tr class="payments-additional">
              <td style="width: 175px;">
              </td>
              <td></td>
              <td></td>
              <td></td>
              <template v-if="transactions.length">
                <td style="text-align: right; padding-right: 0;">Total Paid Amount</td>
                <td><strong>{{totalPaidAmount | price}}</strong></td>
              </template>
              <template v-else>
                <td></td>
                <td></td>
              </template>
              <td></td>
            </tr>
          </table>
        </div>
      </div>
    </div>
    <v-dialog :clickToClose="false" />
  </div>
</template>

<script>
import Vue from 'vue';
import bus from '@/bus';
import { merge } from 'lodash';

import apiService from '../../../../services/api.service';
import notify from '../../../../modules/notifier';
import breadcrumbs from './../../../common/breadcrumbs';
import moment from 'moment';
import alertify from 'alertify.js';
import { mapGetters, mapActions } from 'vuex';

const DATE_FORMAT = 'MM/DD/YY';

import { LOAD_CUSTOMER_ORDER } from '../../../../store/action-types';
import FormRow from '@/components/common/form/FormRow';
import FormItem from '@/components/common/form/FormItem';
import FormSelect from '@/components/common/form/FormSelect';
import FormDatepicker from '@/components/common/form/FormDatepicker';
import FormCheckbox from '@/components/common/form/FormCheckbox';
import { CLEAR_VALIDATION_PROMISES } from '@/store/action-types';

import { TRANSACTION_STATUSES, PAYMENT_METHODS } from '@/helpers/transactions';
import { PAYMENT_STATUS_PAID } from '@/helpers/orders';
import { PAYMENT_STATUSES } from '@/helpers/orders';

export default {
  name: 'order-payments',
  data() {
    return {
      invoices: [],
      transactions: [],
      paymentTerms: {},
      statuses: {},
      order: {},
      transactionStatuses: TRANSACTION_STATUSES,
      paymentMethods: PAYMENT_METHODS
    };
  },
  computed: {
    totalPaidAmount() {
      return this.transactions
        .reduce((acc, { status, amount }) => {
          if (status === 2) {
            return +acc;
          }
          return +acc + +amount;
        }, 0)
        .toFixed(2);
    },
    orderId() {
      return this.$route.params.id;
    },
    crumbLinks() {
      return [
        {
          url: '/customer/dashboard',
          label: 'Orders'
        },
        {
          url: '/customer/orders/' + this.orderId + '/details',
          label: '#' + this.orderId
        },
        {
          label: 'Payments'
        }
      ];
    },
    currentInvoiceId() {
      return (
        (this.invoices.length && this.invoices.find(i => !!i.is_current).id) ||
        null
      );
    },
    currentPaidSum() {
      let current_invoice = this.invoices.find(i => !!i.is_current);
      return (current_invoice && +current_invoice.paid_sum) || 0;
    },
    balanceDue() {
      if (
        this.order.total_price === undefined ||
        this.order.total_price === null
      ) {
        return 0;
      }
      return +this.order.total_price - this.currentPaidSum;
    },
    grandPaymentStatus() {
      if (
        this.order.total_price === undefined ||
        this.order.total_price === null
      ) {
        return '';
      }
      if (+this.order.total_price === 0) {
        return 'Paid';
      }
      return +this.balanceDue >= +this.order.total_price
        ? 'Unpaid'
        : this.balanceDue <= 0 ? 'Paid' : 'Paid Partially';
    },
    showMarkAsPaidButton() {
      return !!(
        !this.balanceDue &&
        this.grandPaymentStatus === 'Paid' &&
        this.order.payment_status !== PAYMENT_STATUS_PAID
      );
    }
  },
  components: {
    breadcrumbs,
    FormRow,
    FormItem,
    FormSelect,
    FormDatepicker,
    FormCheckbox
  },
  methods: {
    ...mapActions({
      loadOrder: LOAD_CUSTOMER_ORDER,
      clearValidations: CLEAR_VALIDATION_PROMISES
    }),

    getData() {
      apiService.getCustomerOrder(this.orderId).then(data => {
        this.order = data.data;
      });

      apiService.getInvoiceStatuses().then(data => {
        this.statuses = data;
      });

      apiService.getPaymentTerms().then(data => {
        data.forEach(option => {
          this.paymentTerms[option.key] = option.label;
        });
      });

      apiService
        .getInvoices(this.orderId)
        .then(data => {
          this.invoices = data;
          this.transactions = this.invoices
            .reduce((acc, item) => {
              return [
                ...acc,
                ...item.transactions.map(t => {
                  t.order_id = this.orderId;
                  t.type = PAYMENT_METHODS.find(m => m.key === t.type);
                  return t;
                })
              ];
            }, [])
            .sort((a, b) => {
              return !!(a.created_at > b.created_at && a.id > b.id) ? 1 : -1;
            })
            .sort((a, b) => (a.order_invoice_id < b.order_invoice_id ? 1 : -1));
        })
        .catch(err => {
          notify({
            message: 'Something wrong',
            type: 'danger'
          });
        });
    },

    printInvoice(invoice) {
      window.open('/#/invoices/' + invoice.link_hash + '/print');
    },
    formatDate(date) {
      return moment(date).format(DATE_FORMAT);
    },
    getPaymentMethodLabel(transaction) {
      if(transaction.type.key === 4 && transaction.payment_method){
        return transaction.payment_method;
      }
      return (transaction.type && transaction.type.label) || 'N/A';
    },
    initBusEvents() {
      bus.$on('errors-changed', errors => {
        if (errors) {
          errors.forEach(e => {
            this.errors.add(e.field, e.msg, e.rule, e.scope);
          });
        }
      });

      bus.$on('errors-deleted', oldErrors => {
        this.errors.errors = [];
        if (oldErrors) {
          oldErrors.forEach(e => {
            this.errors.remove(e.field, e.scope);
          });
        }
      });
    }
  },
  watch: {
    orderId() {
      this.getData();
    }
  },
  created() {
    let promises = [this.getData()];

    Promise.all(promises)
      .then(() => {
        this.initBusEvents();
      })
      .catch(error => {
        alertify.error('Could not load data. Please, try to reload the page.');
      });
  }
};
</script>
<style scoped lang="scss">
#payments-root {
  .payments-data-body {
    background: #fff;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 25px;

    .table-head th {
      text-transform: uppercase;
    }

    .table-body.is-failed {
      color: #d3d3d3;
    }

    .table-body:hover {
      background: none;
    }

    .table-body {
      .transaction-status {
        text-transform: uppercase;

        span {
          min-width: 100px;
        }
      }

      td {
        height: 60px;
        vertical-align: middle;

        i {
          color: #7e7e7e;
          font-size: 25px;
        }

        i:first-child {
          margin-right: 5px;
        }
      }

      .transaction-actions {
        text-align: right;
        padding: 0;

        i:hover {
          color: #d3d3d3;
        }
      }
    }

    tr.payments-additional:hover,
    tr.payments-no-result:hover {
      background: none;
    }

    tr.payments-additional td:first-child {
      padding-left: 0;

      button {
        margin-top: 10px;
      }
    }

    tr.payments-additional td {
      vertical-align: middle;
      border: none;
    }

    .payments-no-result {
      td {
        text-align: center;

        p {
          min-height: 45px;
          padding-top: 10px;
        }
      }
    }

    .payment-term-section.form-row {
      .form-item {
        label {
          font-size: 18px;
        }
      }
    }

    .payment-total-section.form-row {
      justify-content: flex-end;
      margin: 0;

      .form-item {
        flex: 0 0 auto !important;
        width: auto;
        padding: 0;
        align-self: flex-start;

        .tag {
          text-transform: uppercase;
          font-size: 12px;
        }

        label {
          font-size: 20px;
          font-weight: bold;
        }

        .total-label {
          color: black;
        }

        .form-item-static.clr-green {
          color: #00ce7d;
        }

        .form-item-static.clr-red {
          color: #ff0000;
        }

        .form-item-label {
          min-width: 140px;
        }
      }
    }

    .payment-total-section > .form-item + .form-item {
      padding-top: 5px;
      margin-left: 15px;
    }

    .payment-status-unpaid {
      background: #e4e5e6;
      color: #9ca2a4;
      font-weight: bold;
    }

    .payment-status-paid-partially {
      background: #fff6d2;
      color: #ceaa1a;
      font-weight: bold;
    }

    .payment-status-paid {
      background: #cff2e9;
      color: #0fc090;
      font-weight: bold;
    }
  }
}
</style>
