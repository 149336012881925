/**
 * Calculate design total.
 *
 * @param design
 * @param designData
 * @returns {number}
 */
export const getDesignTotal = (design, designData) => {
  let total = 0;
  let designItem = design.specific.item;
  let printType = designItem.print_type;
  let sizeId = designItem.size;
  let quantity = null;
  if (designItem.quantity) {
    quantity = designItem.quantity;
  }

  if (!printType || !sizeId || !quantity) {
    total += 0;
  } else if (designItem.cost !== undefined && designItem.cost !== null) {
    total += designItem.cost;
  } else if (designItem.size === 'empty_size' && quantity) {
    total += quantity * designItem.custom_unit_price;
  } else if (printType && designData.upcharges.by_size && designData.upcharges.by_size[printType] !== undefined) {
    let upchargeSizeObj = designData.upcharges.by_size[printType].filter(
      upcharge => {
        return (upcharge.finishing_size_id === sizeId &&
          (
            upcharge.quantity.from <= quantity &&
            (upcharge.quantity.to >= quantity || upcharge.quantity.to === null)
          )
          && (designItem.product_type === null || upcharge.product_type_id === designItem.product_type)
        );
      }
    )[0];

    if (upchargeSizeObj !== undefined) {
      let upcharge = parseFloat(upchargeSizeObj.upcharge) || 0;
      total += upcharge * quantity;

      if (designItem.has_rounded_corners && designData.upcharges.rounded_corners && designData.upcharges.rounded_corners[printType] !== undefined) {
        let upchargeCornersObj = designData.upcharges.rounded_corners[printType][0];
        if (upchargeCornersObj !== undefined) {
          total += parseFloat(upchargeCornersObj.upcharge.replace(/[^0-9 | ^.]/g, '')) || 0;
        }
      }
      if (designItem.extra_colors && designData.upcharges.extra_colors && designData.upcharges.extra_colors[printType] !== undefined) {
        let upchargeCornersObj = designData.upcharges.extra_colors[printType][0];
        if (upchargeCornersObj !== undefined) {
          total += parseFloat(upchargeCornersObj.upcharge.replace(/[^0-9 | ^.]/g, '')) * quantity;
        }
      }
      if (designItem.button_loops && designData.upcharges.button_loops && designData.upcharges.button_loops[printType] !== undefined) {
        let upchargeCornersObj = designData.upcharges.button_loops[printType][0];
        if (upchargeCornersObj !== undefined) {
          total += parseFloat(upchargeCornersObj.upcharge.replace(/[^0-9 | ^.]/g, '')) * quantity;
        }
      }
      // Backings for patches
      if (designItem.paper_type && designData.upcharges.backings[printType]) {
        let upcharge = designData.upcharges.backings[printType].filter(upcharge => {
          return ((designItem.product_type === null || upcharge.product_type_id === designItem.product_type) &&
            upcharge.quantity.from === designItem.paper_type
          );
        })[0];
        if (upcharge) {
          total += (parseFloat(upcharge.upcharge.replace(/[^0-9 | ^.]/g, '')) * quantity);
        }
      }
      // borders for patches
      if (designItem.border) {
        let upcharge = designData.upcharges.borders[printType].filter(upcharge => {
          return ((designItem.product_type === null || upcharge.product_type_id === designItem.product_type) &&
            upcharge.quantity.from === designItem.border
          );
        })[0];
        if (upcharge) {
          total += (parseFloat(upcharge.upcharge.replace(/[^0-9 | ^.]/g, '')) * quantity);
        }
      }
    }
  }

  return total + +design.common.upcharge;
};
export const getUnitPrice = (design, formOptions) => {

  if (design.custom_unit_price && design.custom_unit_price.length) {
    return design.custom_unit_price;
  }
  const printType = design.print_type;
  const printTypeOptions = formOptions.print_type_options;
  const upcharges = formOptions.upcharges;
  let sizeId = design.size;
  let quantity = null;
  let unitPrice = 0;
  if (!printType) {
    return 0;
  }
  if (design.quantity) {
    quantity = design.quantity;

    let upchargeSizeObj = upcharges.by_size[printType].filter(
      upcharge => {
        return (upcharge.finishing_size_id === sizeId &&
          (
            upcharge.quantity.from <= quantity &&
            (upcharge.quantity.to >= quantity || upcharge.quantity.to === null)
          )
          && (design.product_type === null || upcharge.product_type_id === design.product_type)
        );
      }
    )[0];
    if (upchargeSizeObj !== undefined) {
      unitPrice = (upchargeSizeObj.upcharge) || 0;
    }
    if (design.paper_type && upcharges.backings[printType]) {
      let upcharge = upcharges.backings[printType].filter(upcharge => {
        return ((design.product_type === null || upcharge.product_type_id === design.product_type) &&
          upcharge.quantity.from === design.paper_type
        );
      })[0];
      if (upcharge) {
        unitPrice = Number(unitPrice) + Number(parseFloat(upcharge.upcharge.replace(/[^0-9 | ^.]/g, '')));
      }
    }
    if (design.border && upcharges.borders[printType]) {
      let upcharge = upcharges.borders[printType].filter(upcharge => {
        return ((design.product_type === null || upcharge.product_type_id === design.product_type) &&
          upcharge.quantity.from === design.border
        );
      })[0];
      if (upcharge) {
        unitPrice = Number(unitPrice) + Number(parseFloat(upcharge.upcharge.replace(/[^0-9 | ^.]/g, '')));
      }
    }
    if (design.extra_colors && upcharges.extra_colors && upcharges.extra_colors[printType] !== undefined) {
      let upcharge = upcharges.extra_colors[printType][0];
      if (upcharge !== undefined) {
        unitPrice = Number(unitPrice) + Number(parseFloat(upcharge.upcharge.replace(/[^0-9 | ^.]/g, '')));
      }
    }
    if (design.button_loops && upcharges.button_loops && upcharges.button_loops[printType] !== undefined) {
      let upcharge = upcharges.button_loops[printType][0];
      if (upcharge !== undefined) {
        unitPrice = Number(unitPrice) + Number(parseFloat(upcharge.upcharge.replace(/[^0-9 | ^.]/g, '')));
      }
    }
  }
  return unitPrice;
};

