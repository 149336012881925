<template>
  <div class="columns">
    <div class="column is-half">
      <form-select v-model="folding_type"
        name="folding_type"
        :scope="getScope"
        label="Folding Option"
        :options="{all: foldingTypes}" />
    </div>
  </div>
</template>

<script>
  import Vue from 'vue';
  import formSelect from '../../common/form/select';

  export default {
    props: ['finishing', 'finishingKey'],
    components: {
      formSelect
    },
    data() {
      return {
        folding_type: this.finishing.folding_type || '',
        foldingTypes: [{
          'key': 'I would like my garments folded',
          'label': 'I would like my garments folded',
          'code': 'folding_only'
        }, {
          'key': 'I would like my garments folded & polybagged',
          'label': 'I would like my garments folded & polybagged',
          'code': 'folding_and_bagging'
        }]
      }
    },
    computed: {
      getScope() {
        return 'finishings-' + this.finishingKey + '-fields';
      },
    },
    watch: {
      folding_type(val) {
        this.$set(this.finishing, 'folding_type', val);

        let folding_type_obj = this.foldingTypes.filter(type => type.key == val)[0];
        let price_code = folding_type_obj ? folding_type_obj.code : '';

        this.$set(this.finishing, 'price_code', price_code);
      }
    },
    mounted() {
      this.$set(this.finishing, 'folding_type', this.folding_type);

      let folding_type_obj = this.foldingTypes
        .filter(type => type.key == this.finishing.folding_type)[0];
      let price_code = folding_type_obj ? folding_type_obj.code : '';

      this.$set(this.finishing, 'price_code', price_code);
    },
    beforeDestroy() {
      this.$delete(this.finishing, 'folding_type');
      this.$delete(this.finishing, 'price_code');
    }
  };
</script>
