<template>
  <div>
    <form-row class="fixed-3">
      <form-multiselect
        v-model="printTypeModel"
        :options="formData.printTypes"
        :required="true"
        label="Category"
        :config="{
              trackBy: 'key',
            }"
        :scope="scope"
        name="printType"
        :disabled="isDesignReprint"

      />
      <div
        class="form-item"
        style="align-self: center"
        v-if="showRoundedCornersCheckbox"
      >
        <form-checkbox
          :disabled="isDesignReprint"
          name="has_rounded_corners"
          v-model="roundedCornersModel"
        >Rounded Corners?
        </form-checkbox
        >
      </div>
    </form-row>
    <form-row class="fixed-3" v-if="this.value.print_type !== 4" >
      <form-multiselect
        v-model="paperTypeModel"
        :options="printTypeFormOptionsList('paper_types')"
        :required="true"
        label="Paper"
        :disabled="isDesignReprint || !this.value.print_type"
        :config="{
                  trackBy: 'key',
                }"
        :scope="scope"
        name="paperType"
      />
      <form-multiselect
        v-model="sizeModel"
        :options="printTypeFormOptionsList('sizes')"
        :required="true"
        label="Size"
        :disabled="isDesignReprint || !this.value.print_type"
        :config="{
                  trackBy: 'key',
                }"
        :scope="scope"
        name="paperSize"
      />
      <form-multiselect
        v-model="quantityModel"
        :options="printTypeFormOptionsList('quantities')"
        :required="true"
        label="Quantity"
        :disabled="!this.value.print_type"
        :config="{
                    trackBy: 'key',
                  }"
        :scope="scope"
        :after-label="unitPrice"
        name="quantity"
      />
    </form-row>
    <div v-if="this.value.print_type === 4">
      <form-row class="fixed-3">
        <!-- Size Of Poster -->
        <form-multiselect
          v-model="sizeModel"
          :options="printTypeFormOptionsList('sizes')"
          :required="true"
          label="Size of Poster"
          :config="{
              trackBy: 'key',
            }"
          :scope="scope"
          name="size"
          :disabled="isDesignReprint"
        />
        <!--         Custom Size | if Size of Poster is custom -->
        <form-item
          v-model="customSizeModel"
          :required="true"
          :scope="scope"
          name="customSize"
          label="Custom size"
          :focusOnAppear="true"
          :disabled="isDesignReprint"
          v-if="this.value.size === 'empty_size'"
        />
        <!--         Unit Cost | if Size of Poster is custom -->
        <form-item
          v-model="customUnitPriceModel"
          :required="true"
          :scope="scope"
          name="unitCost"
          label="Unit cost"
          type="number"
          :disabled="isDesignReprint"
          v-if="this.value.size === 'empty_size'"
        />
      </form-row>
      <form-row class="fixed-3">
        <!-- Brand -->
        <form-multiselect
          v-model="brandModel"
          :options="printTypeFormOptionsList('brands')"
          :required="true"
          label="Brand"
          :config="{
              trackBy: 'key',
            }"
          :scope="scope"
          name="brand"
          :disabled="isDesignReprint"
          @select="onBrandSelect"
        />
        <!-- Custom Paper Brand | if Brand is custom -->
        <form-item
          v-model="customBrandModel"
          :required="true"
          :scope="scope"
          name="customPaperBrand"
          label="Custom Paper Brand"
          v-if="this.value.brand === 'empty_brand'"
          :disabled="isDesignReprint"
          :focusOnAppear="true"
        />
      </form-row>
      <form-row class="fixed-3" >
        <!-- Paper Weight | triggers colors loading if brand is not empty -->
        <form-multiselect
          v-model="paperWeightModel"
          :options="printTypeFormOptionsList('weights')"
          :required="true"
          label="Paper Weight"
          :config="{
              trackBy: 'key',
            }"
          :scope="scope"
          name="paperWeight"
          :disabled="isDesignReprint"
          @select="onPaperWeightSelect"
        />
        <!-- Paper Color | async, depends from Paper Brand and Paper Weight -->
        <form-multiselect
          v-model="paperColorModel"
          :options="this.formData.paperColors"
          :required="true"
          label="Paper Color"
          :placeholder="
              this.value.paper_weight && this.value.brand
                ? 'Select...'
                : 'Depends on Brand and Weight'
            "
          :config="{
              groupValues: 'options',
              groupLabel: 'title',
            }"
          :scope="scope"
          name="paperColor"
          :disabled="
              isDesignReprint ||
              !(this.value.paper_weight && this.value.brand)
            "
          v-if="
              this.value.brand
                ? this.value.brand !== 'empty_brand'
                : true
            "
        />
        <!-- Custom Paper Color | if Custom Brand or Custom Color -->
        <form-item
          v-model="customPaperColorModel"
          :required="true"
          :scope="scope"
          name="customPaperColor"
          label="Custom Paper Color"
          :disabled="isDesignReprint"
          v-if="this.value.brand === 'empty_brand' || this.value.paper_color === 'empty_paper_color'"
        />
      </form-row>
      <form-row class="fixed-3">
        <form-multiselect
          v-model="numberOfColorsModel"
          :options="printTypeFormOptionsList('color_numbers')"
          :required="true"
          label="# of Colors"
          :config="{
              trackBy: 'key',
            }"
          :scope="scope"
          name="colors"
          :disabled="isDesignReprint"
        />
      </form-row>
      <form-row class="fixed-3" >
        <!-- Ink Types -->
        <form-multiselect
          v-model="inkTypesModel"
          :options="printTypeFormOptionsList('ink_types')"
          label="Inks"
          :config="{
              trackBy: 'key',
            }"
          :clearable="true"
          :scope="scope"
          :disabled="isDesignReprint"
          name="inkType"
        />
        <!-- Bleed -->
        <form-multiselect
          v-model="bleedTypeModel"
          :options="printTypeFormOptionsList('bleed')"
          :required="true"
          label="Bleed"
          :config="{
              trackBy: 'key',
            }"
          :scope="scope"
          :disabled="isDesignReprint"
          name="bleed"
        />
      </form-row>
      <form-row class="fixed-3">
        <!-- Quantity -->
        <form-item
          v-model="quantityModel"
          :required="true"
          label="Quantity"
          :config="{
              trackBy: 'key',
            }"
          rules="numeric|min_value:50"
          :scope="scope"
          name="quantity"
          :after-label="unitPrice"
        />
      </form-row>
    </div>
  </div>
</template>

<script>

import {getOptionLabel} from "@/helpers/estimates";

export default {
  name: 'PaperDesignProduct',
  props: {
    value: {
      required: true,
      type: Object
    },
    scope: {
      required: true,
      type: String
    },
    service: {
      required: true,
      type: String
    },
    design: {
      required: true,
      type: Object
    },
    parentForm: {
      required: true,
      type: Object
    },
    formData: {
      required: true,
      type: Object
    },
    formOptions: {
      required: true,
      type: Object
    },
    isDesignReprint: {
      default: false,
      type: Boolean
    }
  },
  data() {
    const form = Object.assign({}, JSON.parse(JSON.stringify(this.value)));
    return {
      form
    };
  },
  created() {
    this.init();
  },
  computed: {
    categoryModel: {
      get() {
        if (this.value.category === null) return null;
        return this.formOptions.categories.find(
          ({key}) => this.value.category === key
        );
      },
      set({key}) {
        this.value.category = key;
      }
    },
    printTypeModel: {
      get() {
        if (this.value.print_type === null) return null;
        return (
          this.formData.printTypes.find(
            ({key}) => this.value.print_type === key
          ) || null
        );
      },
      set({key}) {
        this.value.print_type = key;
        this.value.quantity = null;
        this.value.size = null;
        this.value.number_of_colors = null;
        this.value.custom_brand = null;
        this.value.brand = null;
        this.value.paper_weight = null;
        this.value.paper_color = null;
        this.value.ink_type = null;
        this.value.bleed_type = null;
        this.value.custom_size = null;
        this.value.custom_unit_price = null;
      }
    },
    roundedCornersModel: {
      get() {
        if (this.value.has_rounded_corners === null) return false;
        return !!this.value.has_rounded_corners;
      },
      set(val) {
        this.value.has_rounded_corners = val ? val : false;
      }
    },
    paperTypeModel: {
      get() {
        if (this.value.paper_type === null) return null;
        return this.printTypeFormOptionsList('paper_types').find(
          ({key}) => this.value.paper_type === key
        );
      },
      set({key}) {
        this.value.paper_type = key;
      }
    },
    sizeModel: {
      get() {
        if (this.value.size === null) return null;
        return this.printTypeFormOptionsList('sizes').find(
          ({key}) => this.value.size === key
        );
      },
      set({key}) {
        this.value.size = key;
        this.value.custom_size = null;
        this.value.custom_unit_price = null;
      }
    },
    quantityModel: {
      get() {
        if (this.value.quantity === null) return null;
        if(this.value.print_type === 4) return this.value.quantity;
        return this.printTypeFormOptionsList('quantities').find(
          ({key}) => this.value.quantity === key
        );
      },
      set(option) {
        if (this.value.print_type === 4) {
          this.value.quantity = option;
        } else {
          this.value.quantity = option.key;
          this.value._quantity = option.label;
        }
      }
    },
    customSizeModel: {
      get() {
        if (this.value.custom_size === null) return false;
        return this.value.custom_size;
      },
      set(val) {
        this.value.custom_size = val;
      }
    },
    customUnitPriceModel: {
      get() {
        if (this.value.custom_unit_price === null) return false;
        return this.value.custom_unit_price;
      },
      set(val) {
        this.value.custom_unit_price = val;
      }
    },
    customBrandModel: {
      get() {
        if (this.value.custom_brand === null) return false;
        return this.value.custom_brand;
      },
      set(val) {
        this.value.custom_brand = val;
      }
    },
    brandModel: {
      get() {
        if (this.value.brand === null) return null;
        return this.printTypeFormOptionsList('brands').find(
          ({key}) => this.value.brand === key
        );
      },
      set({ key }) {
        this.value.brand = key;
        if (key !== 'empty_brand') {
          this.value.custom_brand = '';
          this.value.custom_paper_color = '';
        }
      }
    },
    paperWeightModel: {
      get() {
        if (this.value.paper_weight === null) return null;
        return this.printTypeFormOptionsList('weights').find(
          ({ key }) => this.value.paper_weight === key
        );
      },
      set({ key }) {
        this.value.paper_weight = key;
      }
    },
    paperColorModel: {
      get() {
        if (this.value.paper_color === null) return null;
        let value;
        this.formData.paperColors.forEach(({ options }) => {
          if (value) return;
          value = options.find(
            ({ id }) => this.value.paper_color.toString() === id
          );
        });
        return value || null;
      },
      set(option) {
        this.value.paper_color = option.id;
        this.value._paper_color = option.label;
        if (option.id !== 'empty_paper_color') {
          this.value.custom_paper_color = '';
        }
      }
    },
    numberOfColorsModel: {
      get() {
        if (this.value.number_of_colors === null) return null;
        return this.printTypeFormOptionsList('color_numbers').find(
          ({ key }) => this.value.number_of_colors === key
        );
      },
      set({ key }) {
        this.value.number_of_colors = key;
        const quantity = this.value.quantity;
        this.value.quantity = null;
        this.value.quantity = quantity;
      }
    },
    inkTypesModel: {
      get() {
        if (this.value.ink_type === null) return null;
        return this.printTypeFormOptionsList('ink_types').find(
          ({ key }) => this.value.ink_type === key
        );
      },
      set(option) {
        if (option === null) {
          this.value.ink_type = null;
        } else {
          this.value.ink_type = option.key;
        }
      }
    },
    bleedTypeModel: {
      get() {
        if (this.value.bleed_type === null) return null;
        return this.printTypeFormOptionsList('bleed').find(
          ({ key }) => this.value.bleed_type == key
        );
      },
      set({ key }) {
        this.value.bleed_type = key;
      }
    },
    showRoundedCornersCheckbox() {
      return (this.printTypeModel && !['Digitally Printed Posters', 'Screen Printed Posters'].includes(this.printTypeModel.label))
    },


    customWidthModel: {
      get() {
        if (this.value.custom_width === null) return false;
        return this.value.custom_width;
      },
      set(val) {
        this.value.custom_width = val;
      }
    },
    customPaperColorModel: {
      get() {
        if (this.value.custom_paper_color === null) return false;
        return this.value.custom_paper_color;
      },
      set(val) {
        this.value.custom_paper_color = val;
      }
    },

    printTypeFormOptions() {
      const printTypeKey = this.value.print_type;
      if (printTypeKey === null) return [];

      return this.formOptions.print_type_options[printTypeKey];
    },
    unitPrice(){
      if (this.value.custom_unit_price && this.value.custom_unit_price.length) {
        return this.value.custom_unit_price;
      }
      const printType = this.value.print_type;
      const printTypeOptions = this.formOptions.print_type_options;
      const upcharges = this.formOptions.upcharges;
      let sizeId = this.value.size;
      let quantity = null;

      if(!printType){
        return 0;
      }
      if (this.value.quantity) {
        // Find quantity by id for all print types excluding Screen Printed Posters
        if (printType === 4) {
          quantity = this.value.quantity;
        } else {
          quantity = printTypeOptions[printType].quantities.find(q => +q.key === +this.value.quantity).label
            .replace(/[^0-9 | ^.]/g, '');
        }
      }

      let upchargeSizeObj = upcharges.by_size[printType].filter(
        upcharge => {
          return (upcharge.paper_print_size_id === sizeId &&
            (
              upcharge.quantity.from <= quantity &&
              (upcharge.quantity.to >= quantity || upcharge.quantity.to === null)
            )
            && (!this.value.number_of_colors ||
              upcharge.paper_colors_number_id === this.value.number_of_colors)
          );
        }
      )[0];
      if (upchargeSizeObj !== undefined) {
        return parseFloat(upchargeSizeObj.upcharge) || 0;
      }
      return 0;

    }
  },
  methods: {
    onBrandSelect({ key }) {
      if (!key || key === 'empty_brand') return;
      const paperWeightKey = this.value.paper_weight;

      if (!paperWeightKey) return;

      this.getPaperColors(key, paperWeightKey).then(() => {
        this.value.paper_color = null;
      });
    },
    onPaperWeightSelect({ key }) {
      const brandKey = this.value.brand;

      if (!brandKey || brandKey === 'empty_brand') return;

      this.getPaperColors(brandKey, key).then(() => {
        this.value.paper_color = null;
      });
    },
    printTypeFormOptionsList(key, withProductType = false) {
      const printTypeObject = this.printTypeFormOptions;
      let optionsList = printTypeObject && printTypeObject[key] ? printTypeObject[key].slice() : [];
      return optionsList.filter(option => typeof option.available === 'undefined' || option.available )
    },
    getPaperColors(brand, weight) {
      return this.$apiService
        .getPaperPrintPaperColors(brand, weight)
        .then(response => {
          const groups = Object.keys(response).sort((a, b) => {
            const aMinOptionKey = Object.keys(response[a]).sort()[0];
            const bMinOptionKey = Object.keys(response[b]).sort()[0];
            if (aMinOptionKey < bMinOptionKey) {
              return -1;
            }
            if (aMinOptionKey > bMinOptionKey) {
              return 1;
            }
            return 0;
          });
          this.formData.paperColors = groups.map(groupKey => {
            return {
              title: groupKey,
              options: Object.keys(response[groupKey]).map(childKey => {
                return {
                  id: childKey,
                  label: response[groupKey][childKey]
                };
              })
            };
          });
        });
    },
    init() {
      if (this.value.paper_color !== null) {
        this.getPaperColors(
          this.value.brand,
          this.value.paper_weight
        );
      }
    },
  }
};
</script>
