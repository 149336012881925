<template>
  <div class="master-search"  v-bind:class="{'open': openRow}">
    <div class="field">
      <p class="control has-icons-left">
        <input class="input"
               ref='search'
               type="text"
               v-model="search"
               @keydown.esc="refreshAll"
               @keydown.enter="enter"
               @keydown.down="down"
               @keydown.up="up"
               @input="change"
               placeholder="order #, account or company name">
        <span class="icon is-medium is-left">
          <i class="ff-lg ff-search"></i>
        </span>
      </p>
    </div>
    <table v-on-clickaway="refreshAll"  v-if="rows && rows.length && search" class="table card">
      <tbody>
      <tr v-for="(row, index) in rows"
          v-bind:class="{'is-selected': isActive(index)}"
          @click="rowClick(index)">
        <td>
          <router-link v-if="row.id"
                       @click.native="refreshAll"
                       :to="idLink(row)">
            {{ idLabel(row) }}
          </router-link>
          <span v-else> - </span>
          <small v-if="archivedStatus(row)"><i>{{ archivedStatus(row) }}</i></small>
        </td>
        <td>
          <router-link v-if="row.account_id"
                       @click.native="refreshAll"
                       :to="'/accounts/' + row.account_id + '/view'">
            {{ row.first_name }} {{ row.last_name }}
          </router-link>
          <span v-else-if="row.first_name && row.last_name">{{ row.first_name }} {{ row.last_name }}</span>
          <span v-else> - </span>
        </td>
        <td>
          <router-link v-if="row.company_id"
                       @click.native="refreshAll"
                       :to="'/companies/' + row.company_id + '/details'">
            {{ row.company }}
          </router-link>
          <span v-else-if="row.company">{{ row.company }}</span>
          <span v-else> - </span>
        </td>
      </tr>
      </tbody>
    </table>

  </div>
</template>
<style scoped lang="scss">
.master-search {
  position: relative;
  width: 500px;
  .table {
    position: absolute;
    z-index: 1020;
    width: 100%;
    table-layout: fixed;

    td {
      word-wrap: break-word;
    }
  }
}
</style>

<script>
import apiService from '../../services/api.service';
import alertify from 'alertify.js';
import { mixin as clickaway } from 'vue-clickaway';
import { debounce } from 'lodash';

export default {
  name: 'master-search',
  mixins: [clickaway],
  data() {
    return {
      current: 0,
      search: '',
      rows: []
    };
  },
  computed: {
    //The flag
    openRow() {
      return this.search !== '' && this.rows.length != 0;
    }
  },
  methods: {
    //For highlighting element
    isActive(index) {
      return index === this.current;
    },

    //for id with suffix
    idLabel(row) {
      return `${row.id}(${row.is_order ? 'O' : 'E'})`;
    },
    //for id uri
    idLink(row) {
      return `/${row.is_order ? 'orders' : 'estimates'}/${row.id}/details`;
    },

    //for id uri
    archivedStatus(row) {
      const statuses = {
        3: 'Archived',
        4: 'Lost'
      };
      return statuses[row.status] ? statuses[row.status] : null;
    },

    //When enter pressed on the input
    enter() {
      let link = '';
      if (this.rows[this.current].company_id) {
        link = '/companies/' + this.rows[this.current].company_id + '/details';
      }
      if (this.rows[this.current].account_id) {
        link = '/accounts/' + this.rows[this.current].account_id + '/view';
      }
      if (this.rows[this.current].id) {
        link = this.idLink(this.rows[this.current]);
      }
      this.$router.push(link);
      this.refreshAll();
    },

    //When up pressed while rows are open
    up() {
      if (this.current > 0) this.current--;
    },

    //When up pressed while rows are open
    down() {
      if (this.current < this.rows.length - 1) this.current++;
    },

    //When the user changes input
    change() {
      this.getResults();
    },

    //When one of the row is clicked
    rowClick(index) {
      this.current = index;
      this.$refs.search.focus();
    },

    refreshAll() {
      this.rows = [];
    },

    getResults: debounce(function() {
      if (parseInt(this.search) || this.search.length > 1) {
        apiService
          .masterSearch(this.search)
          .then(results => {
            this.rows = results;
            this.current = 0;
          })
          .catch(e => {
            alertify.error('Something wrong');
          });
      }
    }, 500)
  }
};
</script>
