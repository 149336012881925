<template>
    <div>
        <logo/>
        <div v-if="reseted">The password has been successfully updated. You will be redirected to login page in 5 seconds.</div>

        <form @submit.prevent="attempt" class="box" v-else>
            <div  :class="['message', 'is-' + message.type]" v-if="messages.length >0" v-for="message in messages">
                <div class="message-body">
                    {{ message.message }}
                </div>
            </div>

            <div class="field">
                <label class="label">E-Mail Address</label>
                <div class="control">
                    <input required v-model="reset_data.email" class="input" type="email">
                </div>
            </div>
            <div class="field">
                <label class="label">New Password</label>
                <div class="control">
                    <input required v-model="reset_data.password" class="input" type="password">
                </div>
            </div>
            <div class="field">
                <label class="label">Confirm Password</label>
                <div class="control">
                    <input required v-model="reset_data.password_confirmation" class="input" type="password">
                </div>
            </div>
            <div class="field has-text-centered">
                <div class="control">
                    <button type="submit" class="button is-primary" :disabled="processing">Reset Password</button>
                </div>
            </div>
        </form>
    </div>
</template>

<script>
import { RESET_PASSWORD } from '../../store/action-types';
import { forEach, isEmpty } from 'lodash';
import Logo from "../partials/logo.vue";

export default {
    components: {Logo},
  data() {
    return {
      reset_data: {
        password: null,
        password_confirmation: null,
        email: null,
        token: null
      },
      messages: [],
      processing: false,
      reseted: false
    };
  },
  methods: {
    attempt: function(e) {
      this.messages = [];

      if (!this.reset_data.email) {
        this.messages.push({
          type: 'danger',
          message: 'Email field is required'
        });
      } else if (!this.reset_data.password) {
        this.messages.push({
          type: 'danger',
          message: 'Please enter password and confirm password'
        });
      } else if (
        this.reset_data.password !== this.reset_data.password_confirmation
      ) {
        this.messages.push({
          type: 'danger',
          message: 'The password confirmation does not match'
        });
      } else if (this.reset_data.password) {
        this.processing = true;
        this.$store
          .dispatch(RESET_PASSWORD, this.reset_data)
          .then(data => {
            this.processing = false;
            this.reseted = true;
            setTimeout(
              function() {
                this.$router.push('/auth/login');
              }.bind(this),
              5000
            );
          })
          .catch(response => {
            this.processing = false;
            var that = this;

            if (
              response.body &&
              response.body.messages &&
              response.body.messages.length
            ) {
              response.body.messages.forEach(function(message) {
                that.messages.push({ type: 'danger', message: message });
              });
            } else {
              this.messages.push({
                type: 'danger',
                message: 'Something wrong :('
              });
            }
          });
      } else {
        this.messages.push({
          type: 'danger',
          message: 'Please fill the form fields'
        });
      }
    }
  },
  mounted() {
    this.reset_data.token = this.$route.params.token;
  }
};
</script>
