<template>
  <div class="mock-comment widget has-separator">
    <div v-if="!editable && details">
      <dl>
        <dt><strong>ARTWORK NOTES:</strong></dt>
        <dd class="break-word content-pre" v-html="details"></dd>
      </dl>
    </div>
    <div v-else-if="editable">
      <dl>
        <dt><strong>ARTWORK NOTES:</strong></dt>
        <dd>
          <wysiwyg ref="wysiwyg"
                   @value-changed="onNotesChanged"
                   placeholder="*Enter note(s)"
                   :value="details"></wysiwyg>
        </dd>
      </dl>
    </div>
  </div>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep';
import Wysiwyg from '../../common/TextEditors/wysiwyg';

export default {
  name: 'mock-comment',
  components: { Wysiwyg },
  props: {
    mock: {
      type: Object,
      required: true
    },
    editable: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      details:
        (this.mock && this.mock.details && cloneDeep(this.mock.details)) || ''
    };
  },
  watch: {
    mock(mock) {
      let details = (mock && cloneDeep(mock.details)) || '';
      this.$set(this, 'details', details);
    }
  },
  computed: {},
  methods: {
    getFormData() {
      return this.$refs.wysiwyg.getFormData();
    },
    onNotesChanged(newValue){
      this.details = newValue;
    },
  }
};
</script>
