<template>
  <div class="page-company">

    <div class="page-company__panel">

      <div class="row">
        <router-link
          :to="'/companies'"
          v-roles="['admin', 'csr', 'production-coordinator', 'purchaser', 'art-producer']"
          class="link-back"
        >
          <span class="back-char" style="padding-right: 1rem"><</span>Back to All Companies
        </router-link>
      </div>
      <div class="level">

        <div class="level-left pt-3">
          <div class="is-size-3 is-bold level-item">{{ company.name }}</div>
          <span class="stamp level-item">{{
              company.terms === 'request-payment' ? 'No Terms' : company.terms_label
            }}</span>
        </div>
        <div class="level-right" v-roles="['admin', 'csr', 'production-coordinator', 'purchaser', 'art-producer']">
          <a class="level-item link with-underline clr-destructive"
             @click.prevent="deleteCompany"
          >Delete</a>
          <router-link
            class="button level-item"
            :to="'/companies/' + companyId + '/edit'"
          >Edit
          </router-link>
          <button
            class="button level-item"
            @click="customersPopup"
            v-if="!companyCustomers.length">
            Add Customer
          </button>
          <router-link
            class="button level-item"
            :to="'/companies/' + companyId + '/custom-prices'"
          >View Custom Pricing
          </router-link>
        </div>

      </div>
      <div class="tabs is-boxed" v-roles="['admin', 'csr', 'production-coordinator', 'purchaser', 'art-producer']">
        <ul data-tabs id="dashboard-tabs">
          <router-link tag="li" class="tabs-title"
                       :to="'/companies/' + companyId + '/details'"
          ><a>Details</a></router-link>
          <router-link tag="li" class="tabs-title" :to="'/companies/' + companyId + '/reports'"
                       v-roles="['admin', 'csr']"><a>Reports</a></router-link>
        </ul>
      </div>
    </div>
    <div class="page-company__container ">
      <section id="my-threadbird">
        <router-view></router-view>
      </section>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.company-content {
  .subtitle-with-whitespace {
    margin-bottom: 10px !important;
  }
}
</style>

<script>
import alertify from 'alertify.js';
import moment from 'moment';

import {cloneDeep} from 'lodash';
import {checkRoles} from '@/router/checkRoles';
import apiService from '../../../services/api.service';
import popup from '../../../modules/popupper';
import OrderNamePopup from '../../partials/popups/order-name-popup';

import paginator from '../../common/paginator';
import Orders from './orders';
import {PAYMENT_STATUS_PAID, PAYMENT_STATUSES_HTML} from '@/helpers/orders';
import {APPROVED_STATUS, PENDING_STATUS} from '@/helpers/revision';
import VueApexCharts from 'vue-apexcharts';
import Vue from 'vue';
import CustomersPopupComponent from './popups/customers.vue';

Vue.use(VueApexCharts);

export default {
  name: 'accounts-view',
  data() {
    return {
      company: [],
      filters: {},
      report: {
        daysSinceLastOrder: null,
        amountDue: null,
        totalSalesLabels: null,
        totalSalesPerPeriod: null,
        totalSalesPerMonth: null,
        totalOrdersPerMonth: null,
        totalDesignsPerMonth: null,
        servicesTotal: null,
        printersTotal: null,
      },
      companyCustomers: [],
      orders: [],
      customersPrevPageUrl: null,
      customersNextPageUrl: null,
      customersCurrentPage: 1,
      customersPagesNumber: 1,
      ordersPrevPageUrl: null,
      ordersNextPageUrl: null,
      ordersCurrentPage: 1,
      ordersPagesNumber: 1
    };
  },
  watch: {
    $route(to, from) {
      this.loadCompanyData();
    }
  },
  computed: {


    companyId() {
      return this.$route.params.id;
    },
    isCustomer() {
      return checkRoles(this.$store.getters.roles, ['customer'], true);
    },
    canSeeStats() {
      return checkRoles(this.$store.getters.roles, ['admin', 'csr'], true);
    },
    daysSinceLastOrder() {
      return this.report && this.report.daysSinceLastOrder ? this.report.daysSinceLastOrder : '-';
    },
    amountDue() {
      return this.report && this.report.amountDue ? this.report.amountDue : 0;
    },
    totalSalesChartOptionsV2() {
      return {
        chart: {
          height: 500,
          type: 'bar'
        },
        plotOptions: {
          bar: {
            borderRadius: 4,
            horizontal: true,
            dataLabels: {
              position: 'bottom' // top, center, bottom
            }
          }
        },
        dataLabels: {
          enabled: true,
          formatter: (value) => {
            return this.$options.filters.price(value);
          },
          offsetX: 10,
          style: {
            fontSize: '12px',
            colors: ['#304758']
          },
          dropShadow: {
            enabled: false,
            color: '#fff'
          }
        },
        xaxis: {
          categories: this.totalSalesLabels
        },
        title: {
          text: this.$options.filters.price(this.totalSalesPerPeriodLabel),
          offsetX: 20,
          style: {
            fontSize: '24px'
          }
        },
        subtitle: {
          text: 'Total Sales',
          offsetX: 0,
          style: {
            fontSize: '14px'
          }
        }
      };
    },
    totalSalesLabels() {
      return this.report && this.report.totalSalesLabels ? this.report.totalSalesLabels : [];
    },
    totalSalesPerPeriodLabel() {
      return this.report && this.report.totalSalesPerPeriod ? this.report.totalSalesPerPeriod : 0;
    },
    totalSalesChartDataV2() {
      return [{
        name: 'Sales',
        data: this.report && this.report.totalSalesPerMonth ? this.report.totalSalesPerMonth : []
      }];
    },
    totalOrdersPerMonthChartOptionsV2() {
      return {
        title: {
          text: 'Number of Orders & Designs by Month'
        },
        chart: {
          height: 500,
          type: 'bar'
        },
        plotOptions: this.plotOptionsConfig,
        dataLabels: this.dataLabelsConfig,
        xaxis: {
          categories: this.totalSalesLabels
        }
      };
    },
    totalOrdersPerMonthChartDataV2() {
      return [
        {
          name: 'Orders',
          data: this.report && this.report.totalOrdersPerMonth ? this.report.totalOrdersPerMonth : []
        },
        {
          name: 'Designs',
          data: this.report && this.report.totalDesignsPerMonth ? this.report.totalDesignsPerMonth : []
        }
      ];
    },
    totalByServiceTypeChartOptionsV2() {
      return {
        title: {
          text: 'Number of Orders & Designs by Service Type'
        },
        chart: {
          type: 'bar',
          height: 475

        },
        plotOptions: this.plotOptionsConfig,
        dataLabels: this.dataLabelsConfig,
        xaxis: {
          categories: this.totalByServiceTypeLabelsV2
        },
      };
    },
    totalByServiceTypeChartDataV2() {
      return [
        {
          name: 'Orders',
          data: this.report && this.report.servicesTotal ? this.report.servicesTotal.orders : []
        },
        {
          name: 'Designs',
          data: this.report && this.report.servicesTotal ? this.report.servicesTotal.designs : []
        }
      ];
    },
    totalByServiceTypeLabelsV2() {
      return this.report && this.report.servicesTotal ? this.report.servicesTotal.labels : [];
    },
    totalByPrinterChartOptions() {
      return {
        title: {
          text: 'Number of Orders & Designs by Printer'
        },
        chart: {
          type: 'bar',
          height: 1000
        },
        plotOptions: this.plotOptionsConfig,
        dataLabels: this.dataLabelsConfig,
        xaxis: {
          categories: this.totalByPrinterLabels
        },
      };
    },
    totalByPrinterChartData() {
      return [
        {
          name: 'Orders',
          data: this.report && this.report.printersTotal ? this.report.printersTotal.orders : []
        },
        {
          name: 'Designs',
          data: this.report && this.report.printersTotal ? this.report.printersTotal.designs : []
        }
      ];
    },
    totalByPrinterLabels() {
      return this.report && this.report.printersTotal ? this.report.printersTotal.labels : [];
    },
  },
  components: {
    Orders,
    paginator,
    OrderNamePopup,
    apexchart: VueApexCharts
  },
  mounted() {
    this.loadCompanyData();
    this.loadCompanyReport();
  },
  methods: {
    getMockLink(order) {
      let link = '';
      let design = order.designs.find(design => {
        if (design.mocks && design.mocks.length) {
          return design.mocks.find(m => {
            return (
              +m.status === +PENDING_STATUS || +m.status === +APPROVED_STATUS
            );
          });
        }
      });
      if (design && design.mocks) {
        let mocks = cloneDeep(design.mocks);
        let mock = mocks.reverse().find(m => {
          return (
            +m.status === +PENDING_STATUS || +m.status === +APPROVED_STATUS
          );
        });
        if (mock && mock.design_id) {
          link = `/revision/${order.id}/${mock.design_id}/${mock.id}`;
        }
      }
      return link;
    },
    showOrderNameDialog(entry) {
      popup({
        title: 'Change order name',
        additional: {
          orderId: entry.id,
          orderName: entry.name,
          callback: this.onOrderNameChanged
        },
        bodyComponent: OrderNamePopup,
        showButtons: true,
        submitLabel: 'Submit Name',
        runCallback: true
      });
    },
    onOrderNameChanged(id, name) {
      this.orders = this.orders.map(o => {
        if (o.id === id) {
          o.name = name;
        }
        return o;
      });
    },
    getPaymentStatus(entry) {
      if (entry && entry.payment_status) {
        return PAYMENT_STATUSES_HTML[entry.payment_status];
      } else {
        return PAYMENT_STATUSES_HTML[0];
      }
    },
    isPaid(entry) {
      return entry && entry.payment_status === PAYMENT_STATUS_PAID;
    },
    formatDate(date) {
      const instance = moment(date);
      if (!instance.isValid()) return date;
      return moment(date).format('MM/DD/YY');
    },
    deleteCompany() {
      alertify
        .okBtn('Delete')
        .confirm('Are you sure that you want to delete this company?', ev => {
          return apiService.deleteCompany(this.companyId).then(data => {
            if (data.status == 'success') {
              this.$router.push('/companies');
              alertify.success(data.message);
            } else {
              alertify.error(data.message);
            }
          });
        });
    },
    getCompanyDetails() {
      return apiService.getCompany(this.companyId).then(
        response => {
          this.company = response.company;
        },
        response => {
          let msg = response.body
            ? response.body.message
            : 'Could not load company details';
          alertify.error(msg);
          this.$router.push('/');
        }
      );
    },
    loadCompanyReport() {
      console.log('filters', this.filters);
      return apiService.getReport(`company/${this.companyId}`, this.filters).then(
        res => {
          this.$set(this, 'report', res);
        }
      );
    },
    customersPopup() {
      popup({
        title: 'Customers',
        bodyComponent: CustomersPopupComponent,
        additional: {
          onSelected: this.assignCustomersToCompany
        },
        modalCardLong: true,
        showButtons: true,
        runCallback: true
      });
    },
    assignCustomersToCompany(ids) {
      return apiService
        .assignCompanyCustomers(this.companyId, {
          customers: ids
        })
        .then(
          response => {
          },
          response => {
            alertify.error(
              response.body && response.body.message
                ? response.body.message
                : 'Could not add customers to company'
            );
          }
        )
        .then(() => this.getCompanyCustomers())
        .then(() => this.getCompanyOrders());
    },
    getCompanyCustomers(page, url) {
      if (page === undefined) page = this.customersCurrentPage;

      if (url === undefined) {
        url = `/api/companies/customers/${this.companyId}?page=${page}`;
      }

      return this.$http.get(url).then(
        response => {
          this.companyCustomers = response.data.customers_data.data;
          this.customersPrevPageUrl =
            response.data.customers_data.prev_page_url;
          this.customersNextPageUrl =
            response.data.customers_data.next_page_url;
          this.customersCurrentPage = response.data.customers_data.current_page;
          this.customersPagesNumber = response.data.customers_data.last_page;
        },
        response => {
          let msg = response.body
            ? response.body.message
            : 'Could not load company customers';
          alertify.error(msg);
        }
      );
    },
    getCompanyOrders(page, url) {
      if (page === undefined) page = 1;
      if (url === undefined) {
        url = `/api/companies/customers_orders/${this.companyId}?page=${page}`;
      }

      return this.$http.get(url).then(
        response => {
          this.orders = response.data.orders.data;
          this.ordersPrevPageUrl = response.data.orders.prev_page_url;
          this.ordersNextPageUrl = response.data.orders.next_page_url;
          this.ordersCurrentPage = response.data.orders.current_page;
          this.ordersPagesNumber = response.data.orders.last_page;
        },
        response => {
          let msg = response.body
            ? response.body.message
            : 'Could not load company orders';
          alertify.error(msg);
        }
      );
    },

    loadCompanyData() {
      this.getCompanyDetails();
      this.getCompanyCustomers();

      if (this.isCustomer) {
        this.getCompanyOrders();
      }
    },
  },
};
</script>
