<template>
  <div class="messenger-avatar">
    <div class="messenger-avatar-substitude"
      v-if="user && !user.profile_image_file_name"
      v-bind:class="`messenger-avatar-substitude_${role}`"
      v-bind:data-balloon="roleLable" data-balloon-pos="right">
      <span class="messenger-avatar-substitude__label">{{label}}</span>
    </div>
    <img v-if="user && user.profile_image_file_name"
      v-bind:src="user.profile_image_file_name" v-bind:alt="user.title">
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'messanger-avatar',
  props: ['user'],
  computed: {
    ...mapGetters(['userProfile']),
    label() {
      if (this.user && this.user.first_name && this.user.last_name) {
        return `${this.user.first_name[0]}${this.user.last_name[0]}`;
      }
    },
    role() {
      if (this.user && this.user.roles) {
        return this.user.roles[0].name;
      }
    },
    roleLable() {
      if (this.user && this.user.roles) {
        return this.user.roles[0].display_name;
      }
    }
  }
};
</script>

<style scoped lang="scss" src="./style.scss">
</style>
