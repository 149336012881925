<template>
  <div v-if="data" class="columns">
    <div class="column">
      <strong>{{label}}:</strong>
      <div v-if="!editable && data.location" class="label has-text-weight-normal is-marginless">
        {{data.location}}
      </div>
      <div class="columns is-marginless is-paddingless">
        <div class="column is-marginless is-paddingless">
          <form-select
            :id="`hang-tag-location-${finishingKey}`"
            tooltip
            tooltip-ico="pensil"
            tooltip-position="left"
            v-model="data.location"
            v-if="editable"
            :name="`hang-tag-location-${finishingKey}`"
            :scope="`form${finishingKey}`"
            :isHierarchical="false"
            :hideLabel="true"
            :label="'Tag Location'"
            :options="{all: hangTagLocationsList}"/>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { cloneDeep } from 'lodash';
import FormSelect from '../../../common/form/select';

import { hangTagLocationsList } from '../../../../helpers/revision';

export default {
  name: 'hang-tag',
  props: {
    finishing: {
      type: Object,
      required: true
    },
    finishingKey: {
      type: Number,
      required: true
    },
    label: {
      type: String,
      default: 'Hang tags'
    },
    design: {
      type: Object,
      required: true
    },
    options: {
      type: Object,
      required: true
    },
    editable: {
      type: Boolean,
      default: false
    }
  },
  components: { FormSelect },
  data() {
    return {
      data: (this.finishing && cloneDeep(this.finishing)) || {},
      locationsList: {},
      designOptions: (this.options && cloneDeep(this.options)) || {}
    };
  },
  watch: {
    design() {
      this.$nextTick(() => {
        this.prepareLocationsList();
      });
    },
    finishing(finishing) {
      let data = (finishing && cloneDeep(finishing)) || {};
      this.$set(this, 'data', data);
      this.$nextTick(() => {
        this.prepareLocationsList();
      });
    },
    options: {
      handler: function(options) {
        this.$set(this, 'designOptions', options);
        this.$nextTick(() => {
          this.prepareLocationsList();
        });
      },
      deep: true
    }
  },
  computed: {
    hangTagLocationsList: () => hangTagLocationsList
  },
  methods: {
    getFormData() {
      return this.data;
    },
    prepareLocationsList() {
      if (this.designOptions && this.designOptions.locations) {
        let options = cloneDeep(this.designOptions);

        const keys = Object.keys(options.locations);
        let locationsList = {};
        keys.forEach(k => {
          const innerKeys = Object.keys(options.locations[k]);
          locationsList[k] = {};
          return innerKeys.forEach(ik => {
            locationsList[k][k + ' - ' + options.locations[k][ik]] =
              options.locations[k][ik];
          });
        });
        this.$set(this, 'locationsList', locationsList);
      }
    }
  },
  mounted() {
    this.prepareLocationsList();
  }
};
</script>
<style>
</style>
