<template>
  <div class="apparel-design-item">
    <div class="container" v-if="design.locations && design.locations.length">
      <ul class="location-list">
        <li v-for="(location, key) in design.locations"
          v-bind:key="key" class="location-list__item">
          <b>{{location.location}}</b>
          <p v-if="location.colors">
            {{getColorsLabel(location)}}
            <span v-if="location.colors" v-for="color in location.colors" class="tag is-medium">
              <span class="color-box" :style="'background-color:#' + color.hex"> </span>
              <span>{{color.name}}</span>
            </span>
          </p>
          <p v-if="location.width || location.height">
            <strong>Size:</strong>
            {{ location.width }}" X {{ location.height }}"
          </p>
          <p v-if="location.ink_type"><b>Ink Type:</b> {{location.ink_type}}</p>
          <p v-if="location.process_addons_label"><b>Process Add-Ons:</b> {{location.process_addons_label}}</p>
        </li>
      </ul>
    </div>

    <div class="container" v-if="design.garments && design.garments.length">
      <horizontal-garments-table :garments="garments"
        :sizesList="sizesList"></horizontal-garments-table>
    </div>

    <p v-if="design.ink_color_changes > 0 && design.total">
      <span>{{design.ink_color_changes}} ink color change{{design.ink_color_changes > 1 ? 's' : ''}}:</span>
      <span v-roles="['admin', 'csr', 'production-coordinator', 'purchaser', 'art-producer', 'customer']">
        {{design.total.ink_color_changes_upcharge | price}}
      </span>
    </p>

    <div v-if="design.total && design.total.upcharge > 0"  v-roles="['admin', 'csr', 'production-coordinator', 'purchaser', 'art-producer', 'customer']">
      <strong>
        Upcharge for <span v-if="design.name">{{design.name}}</span>
        <span v-else>Design #{{parseInt(index) + 1}}</span>:
      </strong>
        {{design.total.upcharge | price}}
      <p v-if="design.total.upcharge_description">{{design.total.upcharge_description}}</p>
    </div>
  </div>
</template>

<style scoped lang="scss">
.location-list {
  display: flex;
  flex-wrap: wrap;
  &__item {
    display: flex;
    flex-direction: column;
    background: #efefef;
    padding: 5px;
    width: 40%;
    border: 1px solid #aba;
    margin-right: 45px;
  }
}
</style>

<script>
import Vue from 'vue';
import { cloneDeep } from 'lodash';

import horizontalGarmentsTable from '../../common/horizontal-gaments-table.vue';

export default {
  name: 'apparel-design-type',
  props: ['design'],
  components: {
    horizontalGarmentsTable
  },
  data() {
    return {
      garments: [],
      sizesList: {},
      sortedSizes: []
    };
  },
  methods: {
    getGarments() {
      let garments = [];
      if (!this.design.garments) return garments;

      garments = this.design.garments.slice().map(garm => {
        let garment = Object.assign({}, garm);
        garment.sizes = Object.keys(garment.sizes).reduce((obj, curKey) => {
          this.sizesList[curKey] = curKey;
          obj[curKey] = garm.sizes[curKey];
          return obj;
        }, {});
        return garment;
      });

      // Sort list of garments sizes by prepared sorted list of sizes.
      this.sizesList = this.sortedSizes.filter(size =>
        Object.keys(this.sizesList).includes(size)
      );

      return garments;
    },
    getColorsLabel(location) {
      return `${location.colors_number} ${
        +location.colors_number > 1 ? 'colors' : 'color'
      }`;
    }
  },
  mounted() {
    this.sortedSizes = cloneDeep(this.$store.getters.sortedGarmentsSizes);
    this.garments = this.getGarments();
  }
};
</script>
