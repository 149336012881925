<template>
    <div class="columns">
        <div class="column">
            <messenger
              @close="$emit('close')"
              :data="additional"></messenger>
        </div>
    </div>
</template>
<script>
    import Vue from 'vue';
    import Messenger from '../../common/messenger.vue';
    export default {
        name: 'messages',
        components:{
            'messenger': Messenger
        },
        props:['additional'],
    };
</script>
