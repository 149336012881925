<template>
  <div class="summary-field" :class="{
    'has-icons': $slots.icon,
    'is-filled': filled
  }">
    <label class="summary-field__label" v-if="label">{{ label }}</label>
    <div v-if="$slots.icon" class="summary-field__icon"><slot name="icon"></slot></div>
    <div class="summary-field__value">{{ value }}</div>
  </div>
</template>
<script>
export default {
  name: 'summary-field',
  props: ['label', 'value', 'filled']
};
</script>
<style lang="scss">
@import '/resources/sass/base/helpers';

.summary-field {
  color: rgba(0, 0, 0, 0.87);
  font-size: 14px;
  line-height: 20px;
  position: relative;
    & + * {
        margin-top: 20px;
    }
  &.has-icons {
    padding-left: 35px;
  }
  &.is-filled &__icon {
    svg {
      fill: $clr-primary2;
    }
  }
  &__icon {
    position: absolute;
    top: 0;
    left: 0;
    width: 25px;
    &:first-child {
      top: 50%;
      transform: translateY(-50%);
    }
    svg {
      display: block;
      width: 100%;
      height: auto;
      fill: #dbdbdb;
    }
  }
  &__label {
    color: rgba(0, 0, 0, 0.54);
    font-size: 12px;
    line-height: 16px;
    display: block;
  }
  &__value {
    padding-top: 4px;
  }
}
</style>
