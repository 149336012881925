<template>
  <div class="design-info widget has-separator" v-if="item">
    <dl class="info">
      <dt><strong>{{ item.print_type_object.name | uppercase }}:</strong></dt>
      <dt v-if="item.print_type_object.name !== 'Screen Printed Posters'">
        <strong>Paper Type:</strong> {{ item.paper_type_object.name }}
      </dt>
      <dt><strong>Size:</strong> {{ size_label }}</dt>
      <dt v-if="showRoundedCornersCheckbox"><strong>Rounded corners:</strong>
        {{ item.has_rounded_corners ? 'Yes' : 'No' }}
      </dt>
      <dt><strong>Quantity:</strong> {{ quantity_label }}</dt>
      <dt v-if="isScreenPrintedPoster"><strong>Brand:</strong>{{ brand_label }}</dt>
      <dt v-if="isScreenPrintedPoster"><strong>Paper Weight:</strong>{{ paper_weight_label }}</dt>
      <dt v-if="isScreenPrintedPoster"><strong>Paper Color:</strong>{{ paper_color_label }}</dt>
      <dt v-if="isScreenPrintedPoster"><strong>Colors:</strong>{{ number_of_colors_label }}</dt>
      <dt v-if="isScreenPrintedPoster"><strong>Inks:</strong>{{ ink_type_label }}</dt>
      <dt v-if="isScreenPrintedPoster"><strong>Bleed:</strong>{{ bleed_type_label }}</dt>
    </dl>
  </div>
</template>

<script>
import PmsColorMultiselect from '../../../common/form/pms-color-multiselect';
import FormSelect from '../../../common/form/select';
import FormTextarea from '../../../common/form/textarea';
import Wysiwyg from '../../../common/TextEditors/wysiwyg';
import cloneDeep from "lodash/cloneDeep";

export default {
  name: 'design-info-paper',
  components: {Wysiwyg, FormTextarea, FormSelect, PmsColorMultiselect},
  props: {
    design: {
      type: Object,
      required: true
    },
    order: {
      type: Object,
      required: true
    },
    mock: {
      type: Object,
      required: true
    },
    options: {
      type: Object,
      required: true
    },
    editable: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      mock_item: {
        location: '',
        width: 0,
        height: 0,
        location_comment:
          this.mock &&
          this.mock.items &&
          this.mock.items[0] &&
          this.mock.items[0].location_comment
            ? cloneDeep(this.mock.items[0].location_comment)
            : ''
      }
    };
  },
  computed: {
    item() {
      return this.design.designable.item || {};
    },
    showRoundedCornersCheckbox() {
      return this.item.print_type_object.name !== 'Digitally Printed Posters' &&
        this.item.print_type_object.name !== 'Screen Printed Posters';
    },
    isScreenPrintedPoster() {
      return this.item.print_type_object.name === 'Screen Printed Posters';
    },
    size_label() {
      let label = 'N/A';

      if (this.item.custom_size) {
        label = this.item.custom_size;
      } else if (this.item.size_object) {
        label = this.item.size_object.name;
      }
      return label;
    },
    quantity_label() {
      if (this.item.print_type === 4) {
        return this.item.quantity;
      } else {
        return this.item.quantity_object
          ? this.item.quantity_object.name
          : 'N/A';
      }
    },
    brand_label() {
      let label = 'N/A';

      if (this.item.custom_brand) {
        label = this.item.custom_brand;
      } else if (this.item.brand_object) {
        label = this.item.brand_object.name;
      }
      return label;
    },
    paper_weight_label() {
      return this.item.paper_weight_object
        ? this.item.paper_weight_object.name
        : 'N/A';
    },
    paper_color_label() {
      let label = 'N/A';

      if (this.item.custom_paper_color) {
        label = this.item.custom_paper_color;
      } else if (this.item.paper_color_object) {
        label = this.item.paper_color_object.name;
      }
      return label;
    },
    number_of_colors_label() {
      return this.item.colors_number_object ? this.item.colors_number_object.name : 'N/A';
    },
    bleed_type_label() {
      return this.item.bleed_type_object ? this.item.bleed_type_object.name : 'N/A';
    },
    ink_type_label() {
      return this.item.ink_type_object
        ? this.item.ink_type_object.name
        : 'N/A';
    }
  },
  methods: {
    getFormData() {
      return [this.mock_item];
    },
  },
};
</script>
