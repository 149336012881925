<template>
  <div class="design-reference-columns columns">
    <div class="column">
      <div class="form-row">
        <form-multiselect
          class="form-item-design-reference"
          v-model="design.model"
          :isLoading="design.isLoading"
          :options="design.options"
          :required="false"
          selectClass="theme-autocomplete"
          :label="`Search design to ${type}`"
          :config="{
              internalSearch: false,
              preserveSearch: true,
              label: '_label',
              trackBy: 'uniqueId'
            }"
          :placeholder="'Search for Design Name'"
          scope="modal-design-reference"
          name="design-reference-search"
          @search-change="onReferenceDesignSearchChange"
          @input="onReferenceDesignSearchSelect"
          :clearable="true"
          ref="design-reference"
        >

          <option-reference-design
            :props="props"
            :query="design.searchQuery"
            :value="design.model"
            slot="option"
            slot-scope="props"
          ></option-reference-design>

          <div slot="placeholder" slot-scope="props" class="form-item-design-reference__placeholder">
            <svg height="18px" version="1.1" viewBox="0 0 18 18" width="18px" xmlns="http://www.w3.org/2000/svg"
                 xmlns:sketch="http://www.bohemiancoding.com/sketch/ns" xmlns:xlink="http://www.w3.org/1999/xlink">
              <path
                d="M12.5,11 L11.7,11 L11.4,10.7 C12.4,9.6 13,8.1 13,6.5 C13,2.9 10.1,0 6.5,0 C2.9,0 0,2.9 0,6.5 C0,10.1 2.9,13 6.5,13 C8.1,13 9.6,12.4 10.7,11.4 L11,11.7 L11,12.5 L16,17.5 L17.5,16 L12.5,11 L12.5,11 Z M6.5,11 C4,11 2,9 2,6.5 C2,4 4,2 6.5,2 C9,2 11,4 11,6.5 C11,9 9,11 6.5,11 L6.5,11 Z"/>
            </svg>
            <p>Search for Design Name</p>
          </div>

          <div slot="noOptions" slot-scope="props" v-if="!design.searchQuery.length">Start typing...</div>

          <div slot="noResult" class="theme-autocomplete__no-result" slot-scope="props">
            <div class="theme-autocomplete__no-result__message" v-if="!design.isLoading">No search results matching your
              query
              was
              found
            </div>
            <div class="theme-autocomplete__no-result__message" v-else>Please, wait...</div>
          </div>
        </form-multiselect>
      </div>
    </div>
  </div>
</template>
<script>
import { debounce } from 'lodash';
import OptionReferenceDesign from '../../common/form/OptionReferenceDesign';

export default {
  name: 'reprint-design-modal',
  components: { OptionReferenceDesign },
  props: {
    modalOverlay: {
      type: Boolean,
      default: false
    },
    modalLoading: {
      type: Boolean,
      default: false
    },
    draggable: {
      type: Boolean,
      default: false
    },
    clickToClose: {
      type: Boolean,
      default: false
    },
    account_id: {
      type: Number,
      reqiured: true
    },
    company_id: {
      type: Number,
      reqiured: true
    },
    type: {
      type: String,
      reqiured: true
    },
    callback: {
      type: Function,
      required: true
    }
  },
  data() {
    return {
      design: {
        model: null,
        options: [],
        searchQuery: '',
        isLoading: false,
        duplicate: null
      }
    };
  },
  methods: {
    onReferenceDesignSearchChange: debounce(function(searchQuery) {
      if (!searchQuery || searchQuery.length === 0) {
        this.design.isLoading = false;
        return;
      }

      this.design.isLoading = true;
      this.design.searchQuery = searchQuery;

      this.$nextTick(() => {
        this.$apiService
          .getReprintDesigns({
            q: searchQuery,
            type: this.type,
            account_id: this.account_id,
            company_id: this.company_id
          })
          .then(({ designs_list }) => {
            this.design.options = [];
            this.design.options = designs_list.slice();
          })
          .catch(error => {
            console.log(error);
          })
          .then(() => {
            this.design.isLoading = false;
          });
      });
    }, 500),
    onReferenceDesignSearchSelect(design) {
      this.callback(design);
    }
  },
  created() {
    this.$apiService
      .getReprintDesigns({
        q: '',
        type: this.type,
        account_id: this.account_id,
        company_id: this.company_id
      })
      .then(({ designs_list }) => {
        this.design.options = [];
        this.design.options = designs_list.slice();
      })
      .catch(error => {
        console.log(error);
      })
      .then(() => {
        this.design.isLoading = false;
      });
  }
};
</script>
<style lang="scss">
.v--modal-overlay {
  .v--modal-box {
    overflow: visible !important;
  }
}

.design-reference-columns {
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;

  .form-row {
    max-width: 600px;
  }
}

.theme-autocomplete {
  &__no-result {
    margin: -5px;
    cursor: default;

    &__message {
      text-align: center;
      font-size: 12px;
      line-height: 16px;
      padding: 25px 10px;
      color: #737373;
    }

    &__actions {
      border-top: 1px solid #dbdbdb;
      padding: 9px 18px;
    }
  }

  &.multiselect {
    color: #333;
    min-height: 0;
    height: 35px;

    &--active {
      z-index: 10;
    }

    &--active .multiselect__input {
      width: 100% !important;
    }
  }

  .multiselect {
    &__spinner {
      height: auto;
      width: 30px;
      bottom: 1px;
    }

    &__placeholder {
      margin-bottom: 0;
      display: block;
      padding-top: 0;
      font-weight: 400 !important;
    }

    &__tags {
      padding: 4px 10px;
      border-radius: 2px;
      border: 1px solid #dbdbdb;
      background: #fff;
      height: 100%;
      min-height: 0;
      display: flex;
      align-items: center;
      cursor: pointer;
    }

    &__select {
      display: none;
      width: 30px;
      height: auto;
      top: 0;
      bottom: 0;
      right: 0;
      padding: 0;

      &:before {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 0;
        height: 0;
        transform: translateX(-50%);
        margin-top: -2.5px;
      }
    }

    &__single {
      font-size: 14px;
      margin-bottom: 0;
      background: transparent;
      padding: 0;
      white-space: nowrap;
      text-overflow: ellipsis;
      display: block;
      overflow: hidden;
      border-radius: 0;
    }

    &__input {
      min-width: 0;
      margin-bottom: 0;
      background: transparent;
      padding: 0 !important; // ag-grid override
      height: 20px !important; // ag-grid override
      color: inherit !important; // ag-grid override
      border: none !important; // ag-grid overrida
      font-size: 14px;
      display: block;
    }

    &__content {
      display: block !important;
    }

    &__content-wrapper {
      border-radius: 2px;
      transform: translateY(2px);
      padding: 0 !important;
      border: 1px solid #dbdbdb;
      background-color: #fff;
      z-index: 6;
      width: auto;
      min-width: 100%;
    }

    &__option {
      font-size: 14px;
      line-height: 16px;
      padding: 5px;
      min-height: 0;
    }

    &__element .multiselect__option {
      display: block;
      background: none;
      color: #333;
      font-size: 14px;
      line-height: 16px;
      font-weight: 400;
      min-height: 0;
      padding: 10px 15px;
      white-space: normal;
      text-overflow: ellipsis;
      overflow: hidden;
      color: rgba(0, 0, 0, 0.87);

      &--highlight {
        background-color: #e9f4fd;
      }

      &--selected {
        font-weight: 700;
      }
    }
  }

  &.multiselect--above {
    .multiselect__content-wrapper {
      transform: translateY(-2px);
    }
  }

  $select-color: #333;

  &.multiselect--active {
    .multiselect {
      color: $select-color;

      &__tags {
        border-color: #2b99ee;
      }
    }
  }

  &.is-inline {
    &.multiselect {
      height: 24px;
    }

    .multiselect {
      &__tags {
        padding: 0 30px 0 0;
        background: none;
        border-width: 0;
        border-radius: 0;
      }

      &__content-wrapper {
        min-width: 300px;
        width: 100%;
      }
    }

    &:hover,
    &.multiselect--active {
      .multiselect {
        &__tags {
          border-bottom-width: 1px;
        }
      }
    }
  }
}

.form-item-design-reference {
  &.form-item {
    .form-item-label {
      font-size: 14px;
      line-height: 20px;
      font-weight: 400;
      color: rgba(#000, 0.54);

      & + * {
        margin-top: 10px;
      }
    }
  }

  &__placeholder {
    display: flex;
    font-size: 12px;
    align-items: center;

    svg {
      margin-right: 2px;
      fill: #dbdbdb;

      & + * {
        margin-left: 10px;
      }
    }

    p {
      flex-grow: 1;
    }
  }
}
</style>
