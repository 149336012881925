<template>
  <div class="customer-dashboard">
    <h5 class="title" style="display: flex; justify-content: space-between">My Orders
      <nav class="level">
        <div class="level-left"></div>
        <div class="level-right">
          <div class="level-item"></div>
          <div class="level-item">
            <div class="field">
              <div class="control has-icons-left has-icons-right">
                <input type="text" v-model="searchQuery" @input="searchOrders" class="input"
                       placeholder="Search by Reference">
                <span class="icon is-left"><i class="ff-search"></i></span>
                <span v-if="searchQuery" @click="resetSearch" class="icon is-medium is-right"><i class="ff-lg ff-android-close"></i></span>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </h5>
    <div v-if="!isLoading">
      <div v-if="customerOrdersGridData && customerOrdersGridData.length" class="table-container">
        <table class="table is-fullwidth" v-if="customerOrdersGridData.length">
          <thead>
            <tr>
              <th>Order</th>
              <th>Account</th>
              <th>Created On</th>
              <th>Accepted On</th>
              <th v-role-access="['admin', 'csr', 'production-coordinator', 'purchaser', 'art-producer']">Ship By</th>
              <th>Qty.</th>
              <th v-roles="['admin', 'csr', 'production-coordinator', 'purchaser', 'art-producer', 'customer']">Total</th>
              <th>Status</th>
              <th>Reference</th>
              <th></th>
              <th>Orders Actions</th>
              <th colspan="2"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(entry, rowKey) in customerOrdersGridData">
              <th class="order-link">
                <p>
                  <router-link v-if="entry.is_approved"
                               style="text-decoration: underline;"
                               :to="`/customer/orders/${entry.id}/details`">{{entry.is_order ? entry.id  : entry.id + ' (E)'}}</router-link>
                  <router-link v-else
                               style="text-decoration: underline;"
                               :to="`/customer/estimates/${entry.id}/approve`">{{entry.is_order ? entry.id : entry.id + ' (E)'}}</router-link>
                </p>
              </th>
              <th>{{entry.contact_firstname}} {{entry.contact_lastname}}</th>
              <td>{{ entry.order_date }}</td>
              <td>{{ (entry.estimate_becomes_to_order_at && formatDate(entry.estimate_becomes_to_order_at)) || '-' }}</td>
              <td v-role-access="['admin', 'csr', 'production-coordinator', 'purchaser', 'art-producer']">{{entry.ship_by_date}}</td>
              <td>{{ getOrderItemsQuantity(entry) || '-' | itemsNumberFormat }}</td>
              <td v-roles="['admin', 'csr', 'production-coordinator', 'purchaser', 'art-producer', 'customer']">{{getEntryTotal(entry) | price}}</td>
              <td v-html="getPaymentStatus(entry)"></td>
              <td>
                <div class="flex flex__horizontal-cols cols-flex">
                  <div><span style="word-break: break-word;">{{entry.name ? entry.name : '---'}}</span></div>
                  <div>
                    <button @click="showOrderNameDialog(entry)">
                      <i class="ff-compose"></i>
                    </button>
                  </div>
                </div>
              </td>
              <td>
                <p v-if="entry.need_payment">
                  <router-link v-if="!isPaid(entry) && entry.is_approved" class="button is-success has-text-white"
                               :to="'/customer/orders/' + entry.id + '/invoice/' + entry.pending_invoice.link_hash + '/'">Pay Now</router-link>
                </p>
              </td>
              <td>
                <p v-if="getMockLink(entry)">
                  <router-link class="button"
                    :to="getMockLink(entry)">
                    View/Approve Mocks
                  </router-link>
                </p>
              </td>
              <td>
                <p>
                  <router-link v-if="entry.is_approved"
                    class="button"
                    :to="`/customer/orders/${entry.id}/details`">
                    Details
                  </router-link>
                  <router-link v-else class="button"
                    :to="`/customer/estimates/${entry.id}/approve`">
                    Review Estimate
                  </router-link>
                </p>
              </td>
              <td class="notification-icon">
                <div @click="openMessenger(entry.id)">
                  <span class="icon is-medium has-text-link">
                    <i class="ff-lg ff-chatbubbles"></i>
                  </span>
                  <span  v-if="unreadOrdersMessages[entry.id] && unreadOrdersMessages[entry.id].length"
                    :class="{
                    'tag msg-tag is-warning is-small count-cntr': true,
                    'is-danger': isMentionedMe(unreadOrdersMessages[entry.id])
                  }">
                    {{unreadOrdersMessages[entry.id].length}}
                  </span>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <paginator v-if="customerOrdersGridData"
          :pagesNumber="customerOrdersPagesNumber"
          :prevPageUrl="customerOrdersPrevPageUrl"
          :nextPageUrl="customerOrdersNextPageUrl"
          :currentPage="customerOrdersCurrentPage"
          :getListItems="getCustomerOrders"></paginator>
      </div>
      <div v-else-if="(!customerOrdersGridData || !customerOrdersGridData.length)">
        <div class="table-grid-wrapper"><!---->
          <section class="hero has-text-centered">
            <div class="hero-body">
              <div class="container">
                <p class="subtitle">
                  There are no orders
                </p>
              </div>
            </div>
          </section>
        </div>
      </div>
      <h5 class="title">Company Orders</h5>
      <div v-if="companyOrdersGridData && companyOrdersGridData.length" class="table-container">
        <table class="table is-fullwidth is-hoverable">
          <thead>
          <tr>
            <th>Order</th>
            <th>Account</th>
            <th>Created On</th>
            <th>Accepted On</th>
            <th v-role-access="['admin', 'csr', 'production-coordinator', 'purchaser', 'art-producer']">Ship By</th>
            <th>Qty.</th>
            <th v-roles="['admin', 'csr', 'production-coordinator', 'purchaser', 'art-producer', 'customer']">Total</th>
            <th>Status</th>
            <th>Reference</th>
            <th></th>
            <th>Orders Actions</th>
            <th colspan="2"></th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(entry, rowKey) in companyOrdersGridData">
            <th class="order-link">
              <p>
                <router-link v-if="entry.is_approved"
                             style="text-decoration: underline;"
                             :to="`/customer/orders/${entry.id}/details`">{{entry.is_order ? entry.id  : entry.id + ' (E)'}}</router-link>
                <router-link v-else
                             style="text-decoration: underline;"
                             :to="`/customer/estimates/${entry.id}/approve`">{{entry.is_order ? entry.id : entry.id + ' (E)'}}</router-link>
              </p>
            </th>
            <th>{{entry.contact_firstname}} {{entry.contact_lastname}}</th>
            <td>{{ entry.order_date }}</td>
            <td>{{ (entry.estimate_becomes_to_order_at && formatDate(entry.estimate_becomes_to_order_at)) || '-' }}</td>
            <td v-role-access="['admin', 'csr', 'production-coordinator', 'purchaser', 'art-producer']">{{entry.ship_by_date}}</td>
            <td>{{entry.quantity | itemsNumberFormat}}</td>
            <td v-roles="['admin', 'csr', 'production-coordinator', 'purchaser', 'art-producer', 'customer']">{{getEntryTotal(entry) | price}}</td>
            <td v-html="getPaymentStatus(entry)"></td>
            <td>
              <div class="flex flex__horizontal-cols cols-flex">
                <div><span style="word-break: break-word;">{{entry.name ? entry.name : '---'}}</span></div>
                <div>
                  <button @click="showOrderNameDialog(entry)">
                    <i class="ff-compose"></i>
                  </button>
                </div>
              </div>
            </td>
            <td>
              <p v-if="entry.need_payment">
                <router-link v-if="!isPaid(entry) && entry.is_approved" class="button is-success has-text-white"
                             :to="'/customer/orders/' + entry.id + '/invoice/' + entry.pending_invoice.link_hash + '/'">Pay Now</router-link>
              </p>
            </td>
            <td>
              <p v-if="getMockLink(entry)">
                <router-link class="button"
                             :to="getMockLink(entry)">
                  View/Approve Mocks
                </router-link>
              </p>
            </td>
            <td>
              <p>
                <router-link v-if="entry.is_approved"
                             class="button"
                             :to="`/customer/orders/${entry.id}/details`">
                  Details
                </router-link>
                <router-link v-else class="button"
                             :to="`/customer/estimates/${entry.id}/approve`">
                  Review Estimate
                </router-link>
              </p>
            </td>
            <td class="notification-icon">
              <div @click="openMessenger(entry.id)">
                  <span class="icon is-medium has-text-link">
                    <i class="ff-lg ff-chatbubbles"></i>
                  </span>
                <span  v-if="unreadOrdersMessages[entry.id] && unreadOrdersMessages[entry.id].length"
                       :class="{
                    'tag msg-tag is-warning is-small count-cntr': true,
                    'is-danger': isMentionedMe(unreadOrdersMessages[entry.id])
                  }">
                    {{unreadOrdersMessages[entry.id].length}}
                  </span>
              </div>
            </td>
          </tr>
          </tbody>
        </table>
        <paginator v-if="companyOrdersGridData"
                   :pagesNumber="companyOrdersPagesNumber"
                   :prevPageUrl="companyOrdersPrevPageUrl"
                   :nextPageUrl="companyOrdersNextPageUrl"
                   :currentPage="companyOrdersCurrentPage"
                   :getListItems="getCustomerCompanyOrders">
        </paginator>
      </div>
      <div v-else-if="(!companyOrdersGridData || !companyOrdersGridData.length)">
        <div class="table-grid-wrapper"><!---->
          <section class="hero has-text-centered">
            <div class="hero-body">
              <div class="container">
                <p class="subtitle">
                  There are no company orders
                </p>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="table-grid-wrapper"><!---->
        <section class="hero has-text-centered">
          <div class="hero-body">
            <div class="container">
              <p class="subtitle">
                Loading...
              </p>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.customer-dashboard {
  .has-icons-right {
    input {
      padding-right: 2.25em;
    }

    .is-right {
      cursor: pointer;
      pointer-events: initial;
      right: 0;
    }
  }

  .table-container {
    margin-top: 15px;
    margin-bottom: 15px;

    .table {
      .cols-flex {
        justify-content: space-evenly;
      }

      thead th {
        white-space: nowrap;
        overflow: hidden;
      }

      th,
      td {
        vertical-align: middle;
      }

      th.order-link,
      td.notification-icon {
        white-space: nowrap;
        overflow: hidden;
      }
    }
  }
}
</style>

<script>
import Vue from 'vue';
import VueCookie from 'vue-cookie';
import { mapGetters } from 'vuex';

import pubnubService from '../../services/pubnub.service';
import { openMessenger, isMentionedMe } from '../../helpers/messages';

import { cloneDeep, debounce } from 'lodash';
import paginator from '../common/paginator';

import { APPROVED_STATUS, PENDING_STATUS } from '../../helpers/revision';
import {
  PAYMENT_STATUSES_HTML,
  PAYMENT_STATUS_PAID
} from '../../helpers/orders';
import { SUBSCRIBE_TO_ORDER } from '../../store/action-types';
import moment from 'moment';
import popup from '../../modules/popupper';
import OrderNamePopup from '../partials/popups/order-name-popup';

export default {
  name: 'customer-dashboard',
  data() {
    return {
      searchQuery: null,
      isLoading: false,
      customerOrdersGridData: [],
      customerOrdersPrevPageUrl: null,
      customerOrdersNextPageUrl: null,
      customerOrdersCurrentPage: 1,
      customerOrdersPagesNumber: 1,
      companyOrdersGridData: [],
      companyOrdersPrevPageUrl: null,
      companyOrdersNextPageUrl: null,
      companyOrdersCurrentPage: 1,
      companyOrdersPagesNumber: 1
    };
  },
  components: {
    paginator,
    OrderNamePopup
  },
  computed: {
    ...mapGetters(['unreadMessages', 'unreadOrdersMessages'])
  },
  methods: {
    openMessenger: openMessenger,
    isMentionedMe: isMentionedMe,

    formatDate(date, format = 'MM/DD/YY') {
      return moment(date).format(format);
    },
    onOrderNameChanged(id, name) {
      this.customerOrdersGridData = this.customerOrdersGridData.map(o => {
        if (o.id === id) {
          o.name = name;
        }
        return o;
      });

      this.companyOrdersGridData = this.companyOrdersGridData.map(o => {
        if (o.id === id) {
          o.name = name;
        }
        return o;
      });
    },
    showOrderNameDialog(entry) {
      popup({
        title: 'Change order name',
        additional: {
          orderId: entry.id,
          orderName: entry.name,
          callback: this.onOrderNameChanged
        },
        bodyComponent: OrderNamePopup,
        showButtons: true,
        submitLabel: 'Submit Name',
        runCallback: true
      });
    },
    hasDesigns(order) {
      return (
        order.designs &&
        order.designs.length &&
        order.designs.some(design => design.mocks && design.mocks.length)
      );
    },

    getCustomerOrders(page, url) {
      page = page === undefined ? this.customerOrdersCurrentPage : page;

      url =
        url ||
        `/api/orders/customer_orders?page=${page}${
          this.searchQuery ? '&name=' + this.searchQuery : ''
        }`;

      return this.$http.get(url).then(
        response => {
          this.customerOrdersGridData = response.data.orders.data;
          this.customerOrdersPrevPageUrl = response.data.orders.prev_page_url;
          this.customerOrdersNextPageUrl = response.data.orders.next_page_url;
          this.customerOrdersCurrentPage = response.data.orders.current_page;
          this.customerOrdersPagesNumber = response.data.orders.last_page;
        },
        response => {
          let msg = response.body
            ? response.body.message
            : "Could not load customer's orders";
          alertify.error(msg);
        }
      );
    },

    getCustomerCompanyOrders(page, url) {
      page = page === undefined ? this.companyOrdersCurrentPage : page;
      url =
        url ||
        `/api/orders/customer_company_orders?page=${page}${
          this.searchQuery ? '&name=' + this.searchQuery : ''
        }`;

      return this.$http.get(url).then(
        response => {
          this.companyOrdersGridData = response.data.orders.data;
          this.companyOrdersPrevPageUrl = response.data.orders.prev_page_url;
          this.companyOrdersNextPageUrl = response.data.orders.next_page_url;
          this.companyOrdersCurrentPage = response.data.orders.current_page;
          this.companyOrdersPagesNumber = response.data.orders.last_page;
        },
        response => {
          let msg = response.body
            ? response.body.message
            : 'Could not load company orders';
          alertify.error(msg);
        }
      );
    },

    getEntryTotal(entry) {
      const estimate = JSON.parse(entry.estimate);
      return estimate
        ? +estimate.total.price +
            +(estimate.total.upcharge ? estimate.total.upcharge : 0)
        : entry.total;
    },
    getMockLink(order) {
      let link = '';
      let design = order.designs.find(design => {
        if (design.mocks && design.mocks.length) {
          return design.mocks.find(m => {
            return (
              +m.status === +PENDING_STATUS || +m.status === +APPROVED_STATUS
            );
          });
        }
      });
      if (design && design.mocks) {
        let mocks = cloneDeep(design.mocks);
        let mock = mocks.reverse().find(m => {
          return (
            +m.status === +PENDING_STATUS || +m.status === +APPROVED_STATUS
          );
        });
        if (mock && mock.design_id) {
          link = `/revision/${order.id}/${mock.design_id}/${mock.id}`;
        }
      }
      return link;
    },
    orderUpdateHandler(message) {
      if (message && message.action) {
        let action = JSON.parse(message.action);
        if (action.method && typeof this[action.method] === 'function') {
          this[action.method](action.args, message);
        }
      }
    },
    onEmailSentHandler({ orderId }, message) {
      if (orderId && message && message.channel === 'on-estimate-sent') {
        this.$store.dispatch(SUBSCRIBE_TO_ORDER, { orderId });
        this.getCustomerOrders();
        this.getCustomerCompanyOrders();
      }
    },
    resetDefault() {
      this.customerOrdersGridData = [];
      this.customerOrdersPrevPageUrl = null;
      this.customerOrdersNextPageUrl = null;
      this.customerOrdersCurrentPage = 1;
      this.customerOrdersPagesNumber = 1;

      this.companyOrdersGridData = [];
      this.companyOrdersPrevPageUrl = null;
      this.companyOrdersNextPageUrl = null;
      this.companyOrdersCurrentPage = 1;
      this.companyOrdersPagesNumber = 1;
    },
    searchOrders: debounce(function() {
      this.isLoading = true;
      this.resetDefault();
      Promise.all([this.getCustomerOrders(), this.getCustomerCompanyOrders()])
        .catch(() => {
          this.isLoading = false;
        })
        .then(() => {
          this.isLoading = false;
        });
    }, 500),
    resetSearch() {
      this.searchQuery = null;
      this.$nextTick(() => {
        this.searchOrders();
      });
    },
    getPaymentStatus(entry) {
      if (entry && entry.payment_status) {
        return PAYMENT_STATUSES_HTML[entry.payment_status];
      } else {
        return PAYMENT_STATUSES_HTML[0];
      }
    },
    isPaid(entry) {
      return entry && entry.payment_status === PAYMENT_STATUS_PAID;
    },
    getOrderItemsQuantity(entry){
      return entry.designs.reduce((total, design) => {
        if(['paper','sticker','button','finishing_creation'].includes(design.service)){
          return total;
        }
        let designItemsQty;
        if (design.total_quantity) {
          designItemsQty = +design.total_quantity;
        }
        return total + designItemsQty;
      }, 0);
    }
  },
  created() {
    Vue.use(VueCookie);
    this.isLoading = true;
    Promise.all([this.getCustomerOrders(), this.getCustomerCompanyOrders()])
      .catch(() => {
        this.isLoading = false;
      })
      .then(() => {
        this.isLoading = false;
      });

    pubnubService.subscribeUpdateEvent('order', this.orderUpdateHandler);
  },
  destroyed() {
    pubnubService.unsubscribeUpdateEvent('order');
  }
};
</script>
