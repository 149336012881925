<template>
  <div :class="{'is-horizontal control': horizontal}">
    <template v-if="type != 'hidden' && !hideLabel">
      <div v-if="horizontal" class="control-label">
        <label class="label" :for="scope + '-' + name">
          <span v-if="required" class="required">*</span>
          {{getLabel}}
        </label>
      </div>
      <div v-else >
        <label class="label" :for="scope + '-' + name">
          <span v-if="required" class="required">*</span>
          {{getLabel}}
        </label>
      </div>
    </template>
    <div class="control">
      <input v-if="type == 'text'"
        :key="scope + '-' + name"
        v-model="fieldValue"
        :disabled="disabled"
        :readonly="readonly"
        v-validate="{ rules: rules, scope: scope }"
        :autocomplete="autocomplete!==undefined && !autocomplete?'off':undefined"
        :data-vv-name="name"
        :data-vv-as="getLabel"
        :placeholder="getPlaceholder"
        :class="{'input': true, 'is-danger': errors.has(scope + '.' + name)}"
        :id="scope + '-' + name"
        type="text"
        @click="onClick"
        @input="$emit('input', $event.target.value)" />
      <input v-else-if="type == 'password'"
        v-model="fieldValue"
        :disabled="disabled"
        :readonly="readonly"
        autocomplete="new-password"
        v-validate="{ rules: rules, scope: scope }"
        :data-vv-name="name"
        :data-vv-as="getLabel"
        :placeholder="getPlaceholder"
        :class="{'input': true, 'is-danger': errors.has(scope + '.' + name)}"
        :id="scope + '-' + name"
        type="password"
        @click="onClick"
        @input="$emit('input', $event.target.value)" />
      <input v-else-if="type == 'hidden'"
        v-model="fieldValue"
        :disabled="disabled"
        :readonly="readonly"
        v-validate="{ rules: rules, scope: scope }"
        :data-vv-name="name"
        :data-vv-as="getLabel"
        :placeholder="getPlaceholder"
        :class="{'input': true, 'is-danger': errors.has(scope + '.' + name)}"
        :id="scope + '-' + name"
        type="hidden"
        @click="onClick"
        @input="$emit('input', $event.target.value)" />
      <input v-else-if="type == 'number'"
        v-model="fieldValue"
        :disabled="disabled"
        v-validate="{ rules: rules, scope: scope }"
        :data-vv-name="name"
        :data-vv-as="getLabel"
        :placeholder="getPlaceholder"
        :class="{'input': true, 'is-danger': errors.has(scope + '.' + name)}"
        :id="scope + '-' + name"
        type="number"
        :step="step || '0.01'"
        :min="min"
        :max="max"
        @click="onClick"
        @input="$emit('input', $event.target.value)" />
      <span v-show="errors.has(scope + '.' + name) && showErrors"
        class="help is-danger is-visible error-msg">
          {{errors.first(scope + '.' + name)}}
      </span>
      <slot name="input-after"></slot>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.is-horizontal {
  .control {
    flex-direction: column;
  }

  .error-msg {
    padding: 3px 0 !important;
  }
}
</style>

<script>
import Vue from 'vue';
import bus from '../../../bus';
import alertify from 'alertify.js';

export default {
  name: 'form-input',
  data() {
    return {
      fieldValue: this.value
    };
  },
  watch: {
    value(value) {
      this.fieldValue = value;
    }
  },
  props: {
    name: {},
    disabled: {
      type: Boolean,
      default: false
    },
    autocomplete:{
      type: Boolean|undefined,
      default: undefined
    },
    readonly: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: ''
    },
    hideLabel: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: ''
    },
    value: {},
    required: {
      type: Boolean,
      default: true
    },
    rules: {
      type: String,
      default: 'required|max:255'
    },
    scope: {
      type: String,
      default: 'form-scope'
    },
    fieldsetName: String,
    type: {
      type: String,
      default: 'text'
    },
    min: {
      type: String,
      default: ''
    },
    max: {
      type: String,
      default: ''
    },
    step: {
      type: String,
      default: ''
    },
    horizontal: {
      type: Boolean,
      default: false
    },
    onClick: {
      type: Function,
      default: () => {
        return false;
      }
    },
    showErrors: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    getLabel() {
      //capitalize name
      return this.label
        ? this.label
        : this.name.charAt(0).toUpperCase() + this.name.slice(1);
    },
    getPlaceholder() {
      //capitalize name
      return this.placeholder ? this.placeholder : this.getLabel;
    }
  },
  methods: {
    onValidate(validateScope) {
      if (validateScope) {
        this.$validator.validateAll(validateScope).catch(err => {});
      } else {
        this.$validator.validateScopes().catch(err => {});
      }
    },

    onClear() {
      this.errors.clear(this.scope);
    },

    addError(e) {
      if (
        e.field === this.name &&
        e.scope === this.scope &&
        (!this.fieldsetName ||
          e.fullName === `${this.fieldsetName}.${this.name}`)
      ) {
        this.errors.add(
          e.field,
          e.msg.replace(e.fullName, this.getLabel),
          e.rule,
          e.scope
        );
        if (this.showErrors) {
          alertify.error(e.msg.replace(e.fullName, this.getLabel));
        }
      }
    }
  },
  created() {
    bus.$on('add-error', this.addError);
    bus.$on('validate', this.onValidate);
    bus.$on('clear', this.onClear);
    this.$watch(
      () => this.errors.errors,
      (value, oldValue) => {
        if (value.length) {
          bus.$emit('errors-changed', value);
        } else {
          bus.$emit('errors-deleted', oldValue);
        }
      }
    );
  },
  beforeDestroy() {
    bus.$emit('errors-deleted', this.errors.errors);
    bus.$off('validate', this.onValidate);
    bus.$off('clear', this.onClear);
    bus.$off('add-error', this.addError);
  }
};
</script>
