<template>
  <tr>
    <td v-if="item.paper_type">
      <invoice-field
        label="Paper Type"
        :value="getOptionLabel(item.paper_type, formOptions.print_type_options[item.print_type].paper_types)"
      />
    </td>
    <td>
      <invoice-field
        :label="sizeLabel"
        :value="sizeValue"
      />
    </td>
    <td v-if="hasRoundedCorners">
      <invoice-field
        label="Rounded Corners"
        :value="item.has_rounded_corners ? 'Yes' : 'No'"
      />
    </td>
    <td v-if="isScreenTypePoster">
      <invoice-field
        label="Paper Brand"
        :value="brandValue"
      />
    </td>
    <td v-if="isScreenTypePoster">
      <invoice-field
        label="Paper Weight"
        :value="getOptionLabel(item.paper_weight, formOptions.print_type_options[item.print_type].weights) "
      />
    </td>
    <td v-if="isScreenTypePoster">
      <invoice-field
        label="Paper Color"
        :value="paperColorValue"
      />
    </td>
    <td v-if="isScreenTypePoster">
      <invoice-field
        label="# of Colors"
        :value="getOptionLabel(item.number_of_colors, formOptions.print_type_options[item.print_type].color_numbers)"
      />
    </td>
  </tr>
</template>
<script>
import {getOptionLabel} from '@/helpers/estimates';
import InvoiceField from '@/components/common/invoice-field.vue';
import {flatten} from 'lodash';
import apiService from '@/services/api.service';

export default {
  name: 'PaperDesignInvoiceItemOptions',
  components: {InvoiceField},
  props: {
    item: {
      type: Object,
      required: true
    },
    formOptions: {
      type: Object,
      required: true
    },
  },
  data() {
    return {
      paperColors: []
    };
  },
  methods: {
    getOptionLabel,
    getPaperColors(brand, weight) {
      return apiService.getPaperPrintPaperColors(brand, weight).then(response => {
        const groups = Object.keys(response).sort((a, b) => {
          const aMinOptionKey = Object.keys(response[a]).sort()[0];
          const bMinOptionKey = Object.keys(response[b]).sort()[0];
          if (aMinOptionKey < bMinOptionKey) {
            return -1;
          }
          if (aMinOptionKey > bMinOptionKey) {
            return 1;
          }
          return 0;
        });
        this.paperColors = groups.map(groupKey => {
          return {
            title: groupKey,
            options: Object.keys(response[groupKey]).map(childKey => {
              return {
                id: childKey,
                label: response[groupKey][childKey]
              };
            })
          };
        });
      });
    },
  },
  computed: {
    hasRoundedCorners() {
      return !['Digitally Printed Posters', 'Screen Printed Posters'].includes(getOptionLabel(this.item.print_type, this.formOptions.print_types));
    },
    isScreenTypePoster() {
      return this.getOptionLabel(this.item.print_type, this.formOptions.print_types) === 'Screen Printed Posters';
    },
    sizeLabel() {
      if (this.item.custom_size) {
        return 'Size (Custom)';
      }
      return 'Size';
    },
    sizeValue() {
      if (this.item.custom_size) {
        return this.item.custom_size;
      } else {
        return getOptionLabel(this.item.size, this.formOptions.print_type_options[this.item.print_type].sizes);
      }
    },
    brandValue() {
      if (this.item.custom_brand && this.item.custom_brand.length) {
        return this.item.custom_brand;
      } else {
        return getOptionLabel(this.item.brand, this.formOptions.print_type_options[this.item.print_type].brands);
      }
    },
    paperColorValue() {
      if (this.item.custom_paper_color && this.item.custom_paper_color.length) {
        return this.item.custom_paper_color;
      } else {
        return getOptionLabel(
          this.item.paper_color,
          flatten(this.paperColors.map(({options}) => options)),
          'id'
        );
      }
    }
  },
  created() {
    if (this.isScreenTypePoster && this.item.paper_color !== null) {
      this.getPaperColors(this.item.brand, this.item.paper_weight);
    }
  },

};
</script>
