<template>
  <div>
    <label class="label" :for="scope + '-' + name">
      <template v-if="showLabel">
        <span class="required" v-if="required">*</span>PMS Colors
      </template>
      <multiselect
        class="theme-default"
        :placeholder="(required ? '*' : '')+placeholder"
        :readonly="readonly"
        :data-vv-name="name"
        :data-vv-as="getLabel"
        :name="name"
        :class="{'is-danger': errors.has(scope + '.' + name) && (showErrors || dirty)}"
        :id="scope + '-' + name"
        :multiple="true"
        :options="pmsColorsOptions"
        :disabled="disabled"
        :internal-search="false"
        v-model="this.fieldValue"
        v-validate="{rules: rules, scope: scope}"
        ref="multiselect"
        track-by="name"
        @search-change="findPmsColors"
        @input="onChange"
        @remove="onRemove"
        @open="onOpen"
        :closeOnSelect="false">
        <template slot="tag" scope="props">
          <span class="tag is-medium">
            <span class="color-box"
              :style="'background-color:#' + props.option.hex"></span>
            <span class="tag__label">{{props.option.name}}</span>
            <button class="button is-ghost dlt__btn" type="button"
               @click="props.remove(props.option)">
              <i class="icon icon__bigger ff-cross-mark"></i>
            </button>
          </span>
        </template>
        <template slot="option" scope="props">
          <div class="option">
            <span class="color-box"
              :style="'background-color:#' + props.option.hex"></span>
            <span>{{props.option.name}}</span>
          </div>
        </template>
      </multiselect>
      <span v-show="errors.has(scope + '.' + name) && (showErrors || dirty)"
        class="error-msg help is-danger is-visible">
        {{errors.first(scope + '.' + name)}}
      </span>
    </label>
  </div>
</template>

<style lang="scss" scoped>
.error-msg {
  font-weight: normal;
}

.tag {
  height: 24px;
  padding: 0 12px;
  margin-right: 3px;
  margin-bottom: 3px;

  &__label {
    font-size: 0.8em;
  }
}

.color-box {
  width: 13px;
  height: 13px;
  border: 0.5px solid #ccc;
}

.option {
  display: flex;
  align-items: center;
}

.dlt__btn {
  color: rgba(10, 10, 10, 0.2);
  font-size: 0.7em;
  padding: 0 5px;
  margin-right: -7px;
}
</style>

<script>
import Vue from 'vue';
import bus from '../../../bus';
import alertify from 'alertify.js';
import { differenceBy, noop, cloneDeep } from 'lodash';

import { mapGetters, mapActions } from 'vuex';
import { LOAD_PMS_COLORS } from '../../../store/action-types';
import apiService from '../../../services/api.service';

export default {
  name: 'pms-color-multiselect',
  data() {
    return {
      fieldValue: this.value,
      prevValue: this.value,
      pmsColorsOptions: [],
      dirty: false
    };
  },
  watch: {
    pmsColors(pmsColors) {
      this.pmsColorsOptions = cloneDeep(pmsColors);
    },
    value(val) {
      this.fieldValue = cloneDeep(val);
      this.prevValue = cloneDeep(val);
    }
  },
  props: {
    id: {
      type: String,
      require: true,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    value: {},
    required: {
      type: Boolean,
      default: false
    },
    scope: {
      type: String,
      default: 'form-scope'
    },
    name: {
      type: String,
      default: 'pms_colors'
    },
    readonly: {
      type: Boolean,
      default: false
    },
    rules: {
      type: String,
      default: 'required'
    },
    showErrors: {
      type: Boolean,
      default: true
    },
    showLabel: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: 'PMS colors'
    },
    placeholder: {
      type: String,
      default: 'Enter PMS colors'
    }
  },
  methods: {
    ...mapActions({
      getPmsColors: LOAD_PMS_COLORS
    }),

    findPmsColors(query) {
      this.pmsColorsOptions = this.pmsColors.filter(
        pmsColor =>
          pmsColor.name.toLowerCase().indexOf(query.toLowerCase()) !== -1
      );
    },

    onChange(value) {
      if (
        differenceBy(value, this.prevValue, 'name').length ||
        differenceBy(this.prevValue, value, 'name').length
      ) {
        this.$emit('input', value);
        this.prevValue = value;
      }
    },

    onValidate(validateScope) {
      if (validateScope) {
        this.$validator.validateAll(validateScope).catch(err => {});
      } else {
        this.$validator.validateScopes().catch(err => {});
      }
    },

    onClear() {
      this.errors.clear(this.scope);
    },

    addError(e) {
      if (
        e.field === this.name &&
        e.scope === this.scope &&
        (!this.fieldsetName ||
          e.fullName === `${this.fieldsetName}.${this.name}`)
      ) {
        this.errors.add(
          e.field,
          e.msg.replace(e.fullName, this.getLabel),
          e.rule,
          e.scope
        );
      }
    },

    onRemove: noop,

    onOpen() {
      this.dirty = true;
    }
  },
  computed: {
    ...mapGetters({
      pmsColors: 'pmsColors'
    }),

    getLabel() {
      //capitalize name
      return this.label
        ? this.label
        : this.name.charAt(0).toUpperCase() + this.name.slice(1);
    }
  },
  created() {
    bus.$on('add-error', this.addError);
    bus.$on('validate', this.onValidate);
    bus.$on('clear', this.onClear);
    this.$watch(
      () => this.errors.errors,
      (value, oldValue) => {
        if (value.length) {
          bus.$emit('errors-changed', value);
        } else {
          bus.$emit('errors-deleted', oldValue);
        }
      }
    );
  },
  mounted() {
    if (!this.pmsColors.length) {
      this.getPmsColors();
    } else {
      this.pmsColorsOptions = cloneDeep(this.pmsColors);
    }
  },
  beforeDestroy() {
    bus.$emit('errors-deleted', this.errors.errors);
    bus.$off('validate', this.onValidate);
    bus.$off('clear', this.onClear);
    bus.$off('add-error', this.addError);
  }
};
</script>
