<template>
  <div id="payments-root">
    <nav class="level">
      <!-- Left side -->
      <breadcrumbs :links="crumbLinks"/>
      <template v-if="!invoices.length">

      </template>
    </nav>

    <div class="payments-data-body">
      <div class="columns">
        <div class="column is-8">
          <div class="form-row payment-term-section">
            <div class="form-item">
              <label class="form-item-label">
                Payment Term
              </label>
              <div class="form-item-static">{{paymentTerms[order.payment_term]}}</div>
            </div>
          </div>
        </div>
        <div class="column">
          <div class="form-row fixed-2 payment-total-section">
            <div class="form-item" v-if="grandPaymentStatus">
              <label :class="[
                        'form-item-label',
                        'tag',
                        'is-medium',
                        `payment-status-${grandPaymentStatus.toLowerCase().replace(' ', '-')}`
                     ]"
              >
                {{grandPaymentStatus}}
              </label>
            </div>
            <div class="form-item">
              <label class="form-item-label total-label">
                Total {{order.total_price || 0 | price}}
              </label>
              <div :class="{
                      'form-item-static': true,
                      'clr-red': balanceDue > 0,
                      'clr-green': balanceDue <= 0
                    }">
                Balance Due {{ balanceDue | price}}
              </div>
              <button v-roles="['admin']" v-if="showMarkAsPaidButton" style="float: right" class="button is-small" @click="showMarkAsPaidConfirmModal">Mark As Paid</button>
            </div>
          </div>
        </div>
      </div>
      <div class="columns">
        <div class="column">
          <table class="table is-fullwidth is-striped">
            <tr class="table-head">
              <th>Transaction #</th>
              <th>Status</th>
              <th>Payment Method</th>
              <th>Payment Date</th>
              <th>Note</th>
              <th>Amount Paid</th>
              <th></th>
              <th></th>
            </tr>
            <template v-if="transactions.length">
              <tr
                v-for="(transaction, index) in transactions"
                :key="`transaction-${index}`"
                :class="{
                  'table-body': true,
                  'is-failed': !transaction.edit && transaction.status === 2
                }"
              >
                <template v-if="!transaction.edit">
                  <td style="max-width: 250px;" class="break-word">
                    #{{transaction.transaction_id}}
                  </td>
                </template>
                <template v-else>
                  <td style="vertical-align: top;">
                      <form-item
                        v-model="transaction.transaction_id"
                        id="transaction-id"
                        name="transaction_id"
                        :required="true"
                        :hideLabel="true"
                        :fakeLabel="true"
                        :rules="'min:1|alpha_num|unique_transaction_id:' +  transactions.map(t => t.transaction_id)"
                        placeholder="Enter"
                        :scope="'payments'"
                      ></form-item>
                  </td>
                </template>
                <td class="transaction-status" :style="{paddingTop: transaction.edit ? '0px' : '10px'}">
                  <span :class="{
                          'tag': true,
                          'payment-status-paid': (transaction.status === 0 || transaction.status === 1),
                          'payment-status-unpaid': (transaction.status === null || transaction.status === 2),
                        }"
                  >
                    {{transaction.status === null ? 'Unpaid' :transactionStatuses[transaction.status]}}
                  </span>
                </td>
                <template v-if="!transaction.edit">
                  <td>{{getPaymentMethodLabel(transaction)}}</td>
                  <td>{{formatDate(transaction.created_at)}}</td>
                  <td style="max-width: 250px;" class="break-word content-pre">{{transaction.note ? transaction.note : '---'}}</td>
                  <td><strong>{{transaction.amount | price}}</strong></td>
                </template>
                <template v-else>
                  <td style="vertical-align: top">
                    <form-multiselect
                      style="min-width: 150px;"
                      v-model="transaction.type"
                      id="type"
                      name="type"
                      placeholder="Select method"
                      :options="paymentMethods"
                      :required="true"
                      :hideLabel="true"
                      :config="{
                        trackBy: 'key'
                      }"
                      :scope="'payments'"
                    ></form-multiselect>
                  </td>
                  <td style="vertical-align: top">
                    <form-datepicker
                      :hideLabel="true"
                      :scope="'payments'"
                      :required="true"
                      placeholder="Select date"
                      name="created_at"
                      v-model="transaction.created_at"
                      :config="{
                        disabledDates: { from: new Date() },
                        format: 'MM/dd/yy'
                      }"
                      :clearable="true"
                    ></form-datepicker>
                  </td>
                  <td style="vertical-align: top">
                    <form-item
                      style="margin-bottom: 10px;"
                      v-model="transaction.note"
                      id="note"
                      name="note"
                      :required="false"
                      :hideLabel="true"
                      :fakeLabel="true"
                      placeholder="Enter"
                      :scope="'payments'"
                    ></form-item>
                    <form-checkbox
                      style="min-width: 280px;"
                      :name="'email-transaction'"
                      ::scope="'payments'"
                      v-model="transaction.send_email"
                    >Email the customer payment notification</form-checkbox>
                  </td>
                  <td style="vertical-align: top">
                    <form-item
                      style="min-width: 130px;"
                      v-model="transaction.amount"
                      id="amount"
                      name="amount"
                      :required="false"
                      :hideLabel="true"
                      :fakeLabel="true"
                      :scope="'payments'"
                      placeholder="Enter"
                      rules="min_value:0.01|max_value:999999.99"
                      :money="{
                        decimal: '.',
                        thousands: ',',
                        prefix: '$ ',
                        precision: 2
                      }"
                    ></form-item>
                  </td>
                </template>
                <template v-if="transaction.status !== 2">
                  <template v-if="!transaction.edit">
                    <td class="transaction-actions">
                      <i v-roles="['admin']" v-if="transaction.source === 2" class="ff-android-create" @click="editTransaction(index, true)"></i></td>
                    <td class="transaction-actions">
                      <i v-roles="['admin']" v-if="transaction.source === 2" class="ff-android-delete" @click="showDeleteConfirmationModal(index)"></i>
                    </td>
                  </template>
                  <template v-else>
                    <td class="transaction-actions">
                      <i v-roles="['admin']" class="ff-floppy-o" @click="saveTransaction(index)"></i></td>
                    <td class="transaction-actions">
                      <i v-roles="['admin']" class="ff-android-close" @click="editTransaction(index, false)"></i>
                    </td>
                  </template>
                </template>
                <template v-else>
                  <td></td>
                  <td>
                  </td>
                </template>
              </tr>
            </template>
            <template v-else>
              <tr class="payments-no-result">
                <td colspan="8"><p>There are no transactions.</p></td>
              </tr>
            </template>
            <tr class="payments-additional">
              <td style="width: 175px;">
                <button v-roles="['admin']" class="button is-medium" @click="addTransaction">Add Transaction</button>
              </td>
              <td></td>
              <td></td>
              <td></td>
              <template v-if="transactions.length">
                <td style="text-align: right; padding-right: 0;">Total Paid Amount</td>
                <td><strong>{{totalPaidAmount | price}}</strong></td>
              </template>
              <template v-else>
                <td></td>
                <td></td>
              </template>
              <td></td>
            </tr>
          </table>
        </div>
      </div>
    </div>
    <v-dialog :clickToClose="false" />
  </div>
</template>

<script>
import Vue from 'vue';
import bus from '@/bus';
import { merge } from 'lodash';

import apiService from '../../../../services/api.service';
import notify from '../../../../modules/notifier';
import breadcrumbs from './../../../common/breadcrumbs';
import moment from 'moment';
import alertify from 'alertify.js';
import { mapGetters, mapActions } from 'vuex';

const DATE_FORMAT = 'MM/DD/YY';

import { LOAD_ORDER } from '../../../../store/action-types';
import FormRow from '@/components/common/form/FormRow';
import FormItem from '@/components/common/form/FormItem';
import FormSelect from '@/components/common/form/FormSelect';
import FormDatepicker from '@/components/common/form/FormDatepicker';
import FormCheckbox from '@/components/common/form/FormCheckbox';
import { CLEAR_VALIDATION_PROMISES } from '@/store/action-types';

import { TRANSACTION_STATUSES, PAYMENT_METHODS } from '@/helpers/transactions';
import { PAYMENT_STATUS_PAID } from '@/helpers/orders';
import { PAYMENT_STATUSES } from '@/helpers/orders';

export default {
  name: 'order-payments',
  data() {
    return {
      invoices: [],
      transactions: [],
      paymentTerms: {},
      statuses: {},
      order: {},
      transactionStatuses: TRANSACTION_STATUSES,
      paymentMethods: PAYMENT_METHODS
    };
  },
  computed: {
    totalPaidAmount() {
      return this.transactions
        .reduce((acc, { status, amount }) => {
          if (status === 2) {
            return +acc;
          }
          return +acc + +amount;
        }, 0)
        .toFixed(2);
    },
    orderId() {
      return this.$route.params.id;
    },
    crumbLinks() {
      return [
        {
          url: '/orders',
          label: 'Orders'
        },
        {
          url: '/orders/' + this.orderId,
          label: '#' + this.orderId
        },
        {
          label: 'Payments'
        }
      ];
    },
    currentInvoiceId() {
      return (
        (this.invoices.length && this.invoices.find(i => !!i.is_current).id) ||
        null
      );
    },
    currentPaidSum() {
      let current_invoice = this.invoices.find(i => !!i.is_current);
      return (current_invoice && +current_invoice.paid_sum) || 0;
    },
    balanceDue() {
      if (
        this.order.total_price === undefined ||
        this.order.total_price === null
      ) {
        return 0;
      }
      return +this.order.total_price - this.currentPaidSum;
    },
    grandPaymentStatus() {
      if (
        this.order.total_price === undefined ||
        this.order.total_price === null
      ) {
        return '';
      }
      if (+this.order.total_price === 0) {
        return 'Paid';
      }
      return +this.balanceDue >= +this.order.total_price
        ? 'Unpaid'
        : this.balanceDue <= 0 ? 'Paid' : 'Paid Partially';
    },
    showMarkAsPaidButton() {
      return !!(
        !this.balanceDue &&
        this.grandPaymentStatus === 'Paid' &&
        this.order.payment_status !== PAYMENT_STATUS_PAID
      );
    }
  },
  components: {
    breadcrumbs,
    FormRow,
    FormItem,
    FormSelect,
    FormDatepicker,
    FormCheckbox
  },
  methods: {
    ...mapActions({
      loadOrder: LOAD_ORDER,
      clearValidations: CLEAR_VALIDATION_PROMISES
    }),

    getData() {
      apiService.getOrder(this.orderId).then(data => {
        this.order = data.data;
      });

      apiService.getInvoiceStatuses().then(data => {
        this.statuses = data;
      });

      apiService.getPaymentTerms().then(data => {
        data.forEach(option => {
          this.paymentTerms[option.key] = option.label;
        });
      });

      apiService
        .getInvoices(this.orderId)
        .then(data => {
          this.invoices = data;
          this.transactions = this.invoices
            .reduce((acc, item) => {
              return [
                ...acc,
                ...item.transactions.map(t => {
                  t.order_id = this.orderId;
                  t.type = PAYMENT_METHODS.find(m => m.key === t.type);
                  return t;
                })
              ];
            }, [])
            .sort((a, b) => {
              return !!(a.created_at > b.created_at && a.id > b.id) ? 1 : -1;
            })
            .sort((a, b) => (a.order_invoice_id < b.order_invoice_id ? 1 : -1));
        })
        .catch(err => {
          notify({
            message: 'Something wrong',
            type: 'danger'
          });
        });
    },

    printInvoice(invoice) {
      window.open('/#/invoices/' + invoice.link_hash + '/print');
    },

    sendInitialInvoice(invoice) {
      alertify.okBtn('Send').confirm('Initial invoice will be sent', ev => {
        let data = {
          invoice: {
            payment_term: this.order.payment_term,
            description: 'Initial invoice for order #' + this.orderId
          },
          orderId: this.orderId
        };
        apiService.requestInitialPayment(data).then(response => {
          apiService
            .getInvoices(this.orderId)
            .then(response => {
              this.invoices = response;
              alertify.success('Invoice was sent.');
            })
            .catch(err => {
              notify({
                message: Object.values(err.body.messages).join('<br>'),
                type: 'danger'
              });
            });
          this.$router.push('/orders/' + this.orderId + '/invoices');
        });
      });
    },

    emailInvoice(id) {
      apiService
        .emailInvoice(id)
        .then(data => {
          if (data.status == 'success') {
            notify({
              message: 'Invoice sent.',
              type: 'success'
            });
          } else {
            notify({
              message: data.message,
              type: 'danger'
            });
          }
        })
        .catch(err => {
          notify({
            message: 'Something wrong.',
            type: 'danger'
          });
        });
    },

    formatDate(date) {
      return moment(date).format(DATE_FORMAT);
    },
    addTransaction() {
      if (this.transactions.find(t => !!t.edit)) {
        alertify.log(
          'You are currently editing transaction. Please save or cancel it before!'
        );
      } else {
        this.transactions.unshift({
          id: null,
          order_invoice_id: this.currentInvoiceId,
          order_id: this.orderId,
          transaction_id: null,
          status: null,
          type: null,
          created_at: null,
          note: '',
          amount: 0,
          edit: true,
          is_new: true,
          send_email: false
        });
      }
    },
    showMarkAsPaidConfirmModal() {
      if (this.order && this.currentInvoiceId) {
        if (this.balanceDue > 0) {
          this.$mx_confirmModal({
            title: 'Mark As Paid',
            text:
              'There is unpaid balance! Are you sure you want to mark this order as paid?'
          })
            .then(() => {
              this.markAsPaid();
            })
            .catch(() => {});
        } else {
          this.markAsPaid();
        }
      } else {
        alertify.error('There is no invoices!');
      }
    },
    markAsPaid() {
      apiService
        .markAsPaid(this.currentInvoiceId)
        .then(data => {
          alertify.log(
            `Invoice has been successfully marked as ${
              PAYMENT_STATUSES[data.invoice_status]
            }.`
          );
          this.order.payment_status = PAYMENT_STATUS_PAID;
        })
        .catch(e => {
          if (e.body && e.body.message) alertify.error(e.body.message);
        });
    },
    editTransaction(key, isEdit) {
      if (this.transactions.find((t, index) => key !== index && !!t.edit)) {
        alertify.log(
          'You are currently editing transaction. Please save or cancel it before!'
        );
      } else {
        this.transactions = this.transactions
          .map((transaction, index) => {
            if (index === key) {
              transaction.edit = isEdit;
            }
            return transaction;
          })
          .filter((transaction, index) => {
            return (
              !!transaction.transaction_id &&
              !!transaction.type &&
              !!transaction.created_at &&
              !!transaction.amount &&
              !transaction.is_new
            );
          });
      }
    },
    deleteTransaction(key) {
      let transaction = this.transactions.find((transaction, index) => {
        return index === key;
      });
      if (transaction && transaction.id) {
        apiService.deleteTransaction(transaction.id).then(() => {
          this.getData();
        });
      }
    },
    saveTransaction(key) {
      this.validateBeforeSubmit().then(isValid => {
        if (isValid) {
          const transaction = this.transactions.find((t, i) => i === key);
          if (transaction) {
            transaction.status = 0;
            apiService
              .saveTransaction(transaction)
              .then(data => {
                this.getData();
                alertify.log('Transaction is successfully saved!');
              })
              .catch(err => {
                if (err && err.body && err.body.message) {
                  alertify.error(err.body.message);
                }
              });
          }
        } else {
          alertify.log('Please, fix all validation errors to proceed.');
        }
      });
    },
    getPaymentMethodLabel(transaction) {
      if(transaction.type.key === 4 && transaction.payment_method){
        return transaction.payment_method;
      }
      return (transaction.type && transaction.type.label) || 'N/A';
    },
    showDeleteConfirmationModal(key) {
      this.$mx_confirmModal({
        title: 'Delete transaction',
        text: 'Are you sure?'
      })
        .then(() => {
          this.deleteTransaction(key);
        })
        .catch(() => {});
    },
    validateBeforeSubmit() {
      bus.$emit('clear');
      this.errors.errors = [];
      bus.$emit('validate', `payments`);

      return new Promise((resolve, reject) => {
        Vue.nextTick(() => {
          Promise.all(this.$store.state.common.validations).then(() => {
            this.clearValidations();
            resolve(!this.errors.count());
          });
        });
      });
    },
    initBusEvents() {
      bus.$on('errors-changed', errors => {
        if (errors) {
          errors.forEach(e => {
            this.errors.add(e.field, e.msg, e.rule, e.scope);
          });
        }
      });

      bus.$on('errors-deleted', oldErrors => {
        this.errors.errors = [];
        if (oldErrors) {
          oldErrors.forEach(e => {
            this.errors.remove(e.field, e.scope);
          });
        }
      });
    }
  },
  watch: {
    orderId() {
      this.getData();
    }
  },
  created() {
    let promises = [this.getData()];

    Promise.all(promises)
      .then(() => {
        this.initBusEvents();
      })
      .catch(error => {
        alertify.error('Could not load data. Please, try to reload the page.');
      });
  }
};
</script>
<style scoped lang="scss">
#payments-root {
  .payments-data-body {
    background: #fff;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 25px;

    .table-head th {
      text-transform: uppercase;
    }

    .table-body.is-failed {
      color: #d3d3d3;
    }

    .table-body:hover {
      background: none;
    }

    .table-body {
      .transaction-status {
        text-transform: uppercase;

        span {
          min-width: 100px;
        }
      }

      td {
        height: 60px;
        vertical-align: middle;

        i {
          color: #7e7e7e;
          font-size: 25px;
        }

        i:first-child {
          margin-right: 5px;
        }
      }

      .transaction-actions {
        text-align: right;
        padding: 0;

        i:hover {
          color: #d3d3d3;
        }
      }
    }

    tr.payments-additional:hover,
    tr.payments-no-result:hover {
      background: none;
    }

    tr.payments-additional td:first-child {
      padding-left: 0;

      button {
        margin-top: 10px;
      }
    }

    tr.payments-additional td {
      vertical-align: middle;
      border: none;
    }

    .payments-no-result {
      td {
        text-align: center;

        p {
          min-height: 45px;
          padding-top: 10px;
        }
      }
    }

    .payment-term-section.form-row {
      .form-item {
        label {
          font-size: 18px;
        }
      }
    }

    .payment-total-section.form-row {
      justify-content: flex-end;
      margin: 0;

      .form-item {
        flex: 0 0 auto !important;
        width: auto;
        padding: 0;
        align-self: flex-start;

        .tag {
          text-transform: uppercase;
          font-size: 12px;
        }

        label {
          font-size: 20px;
          font-weight: bold;
        }

        .total-label {
          color: black;
        }

        .form-item-static.clr-green {
          color: #00ce7d;
        }

        .form-item-static.clr-red {
          color: #ff0000;
        }

        .form-item-label {
          min-width: 140px;
        }
      }
    }

    .payment-total-section > .form-item + .form-item {
      padding-top: 5px;
      margin-left: 15px;
    }

    .payment-status-unpaid {
      background: #e4e5e6;
      color: #9ca2a4;
      font-weight: bold;
    }

    .payment-status-paid-partially {
      background: #fff6d2;
      color: #ceaa1a;
      font-weight: bold;
    }

    .payment-status-paid {
      background: #cff2e9;
      color: #0fc090;
      font-weight: bold;
    }
  }
}
</style>
