<template>
  <div class="content">
    <div class="is-grayed-title">
      <strong>Finishing: {{finishing.label}}</strong><br>
      <span class="is-italic">{{finishing.name}}</span>
    </div>
    <table class="table table__without-hover" v-if="groupedGarments">
      <thead>
      <tr>
        <th colspan="6">Tag Information</th>
        <th class="align-right">Qty.</th>
      </tr>
      </thead>

      <tbody>
      <tr v-for="garment in groupedGarments">
        <td colspan="6">
          <div class="garments-details">
            <span>{{`Garment: ${garment.brand.join(', ')}`}}</span><br/>
            <span>{{`Material: ${garment.material}`}}</span><br/>
            <span>{{`COO: ${garment.coo ? garment.coo : 'N/A'}`}}</span><br/>
            <span>{{`Setups: ${garment.sizes && garment.sizes.join(', ')}`}}</span><br/>
          </div>
        </td>
        <td class="align-right">
          <span>{{garment.qty | itemsNumberFormat}}</span>
        </td>
      </tr>
      <tr v-if="finishing && finishing.fields && finishing.fields.tag_setups_number">
        <td colspan="6">Tag Setups</td>
        <td class="align-right">{{finishing.fields.tag_setups_number | itemsNumberFormat}}</td>
      </tr>
      <tr v-roles="['admin', 'csr', 'production-coordinator', 'purchaser', 'art-producer', 'customer']" class="">
        <td colspan="6"><strong>Total:</strong></td>
        <td class="align-right">{{finishing.price | price}}</td>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import Vue from 'vue';
import apiService from '../../../../services/api.service';
import { cloneDeep, union } from 'lodash';

export default {
  props: ['finishing', 'design'],
  name: 'finishing-summary-tag-printing',
  data() {
    return {
      sortedSizes: []
    };
  },
  computed: {
    groupedGarments() {
      const groupedGarments = {};
      const sizes = [];
      let garments =
        (this.design &&
          this.design.designable &&
          this.design.designable.garments) ||
        (this.design && this.design.garments) ||
        [];
      if (garments && garments.length) {
        garments.forEach(garment => {
          const fabric = garment.fabric || '';
          const brand = garment.brand || '';
          const number = garment.number || '';
          const coo = garment.origin || '';
          const qty = +garment.qty || 0;
          const sizesOfCurrentGarment = cloneDeep(garment.sizes);

          Object.keys(sizesOfCurrentGarment).forEach(size => {
            if (!+sizesOfCurrentGarment[size].qty) {
              delete sizesOfCurrentGarment[size];
            }
          });

          const sizesArray = Object.keys(sizesOfCurrentGarment);

          const groupedGarmentsKey =
            coo && fabric ? `${fabric} - ${coo}` : fabric || coo;

          if (groupedGarmentsKey && groupedGarmentsKey !== '') {
            if (!groupedGarments[groupedGarmentsKey]) {
              sizes[groupedGarmentsKey] = sizesOfCurrentGarment;
              groupedGarments[groupedGarmentsKey] = {
                sizes: sizesArray,
                brand: [`${brand} ${number}`],
                material: fabric,
                coo: coo,
                qty: qty
              };
            } else {
              sizes[groupedGarmentsKey] = Object.assign(
                sizes[groupedGarmentsKey],
                sizesOfCurrentGarment
              );
              groupedGarments[groupedGarmentsKey].sizes = union(
                groupedGarments[groupedGarmentsKey].sizes,
                sizesArray
              );
              groupedGarments[groupedGarmentsKey].brand = union(
                groupedGarments[groupedGarmentsKey].brand,
                [`${brand} ${number}`]
              );
              groupedGarments[groupedGarmentsKey].material = fabric;
              groupedGarments[groupedGarmentsKey].coo = coo;
              groupedGarments[groupedGarmentsKey].qty += qty;
            }
            groupedGarments[groupedGarmentsKey].sizes = this.sortedSizes.filter(
              size =>
                groupedGarments[groupedGarmentsKey].sizes.includes(size) &&
                !!sizes[groupedGarmentsKey][size]
            );
          }
        });
      }
      return groupedGarments;
    }
  },
  methods: {},
  mounted() {
    this.sortedSizes = cloneDeep(this.$store.getters.sortedGarmentsSizes);
  }
};
</script>
<style scoped>
.garments-details {
  margin-bottom: 5px;
}
</style>
