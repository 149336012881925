<template>
  <div class="form-section form-section-finishings" ref="wrapper">
    <div class="step-section">
      <header class="step-section__header">
        <h3 class="step-section__title">{{ 'Shipping' || uppercase }}</h3>
      </header>
      <shipping
        class="step-section__item"
        v-for="(item, index) in value"
        v-model="value[index]"
        :key="item.common.uniqueId"
        :index="index"
        :scope="scope"
        :qty="value.length"
        :shippingOptions="shippingOptions"
        :shippingDefault="shippingDefault"
        :threadbirdAddresses="threadbirdAddresses"
        :shippingAddress="shippingAddress"
        :parentForm="parentForm"
        :shippingItems="shippingItems"
        :loading="isLoading"
        @delete="deleteShipping"
      ></shipping>
      <div class="step-section__actions">
        <a href="#" class="link-add" @click.prevent="addShipping">
          <span class="link-add__icon"></span>
          Add Shipping
        </a>
      </div>
    </div>
    <div class="step-section">
      <header class="step-section__header">
        <h3 class="step-section__title">{{ 'Shipping Cost' || uppercase }}</h3>
      </header>
      <div class="step-section__body">
        <form-row>
          <form-item
            :value="itemsQty || '-' "
            :static="true"
            label="Items"
          >
            <svg slot="icon" height="6.82666in" viewBox="0 0 6.82666 6.82666" width="6.82666in" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
              <path d="M2.00814 5.77576l0 -2.60974 -0.188984 0.327327 0.000165354 9.44882e-005c-0.0220906,0.0382638 -0.0710197,0.051374 -0.109283,0.0292835 -0.00216929,-0.00125197 -0.00425197,-0.00259449 -0.00625591,-0.00401181l-0.857638 -0.495157 0.039689 -0.069063 -0.04 0.0692835c-0.0382638,-0.0220906 -0.051374,-0.0710197 -0.0292835,-0.109283 0.00125197,-0.00216929 0.00259843,-0.00425197 0.00401181,-0.00625591l0.795406 -1.37769 -0.000220472 -0.000311024c0.00994882,-0.0172323 0.0253386,-0.0293622 0.042748,-0.0354724l0.840189 -0.341331 -3.54331e-005 -8.66142e-005c0.0355827,-0.0144528 0.0754961,-0.00144882 0.0962441,0.0290827l0.770941 1.03307 0.773012 -1.03585 -5.11811e-005 -3.54331e-005c0.0230984,-0.0309528 0.0643189,-0.0404134 0.0979764,-0.0246496l0.839866 0.341205 0.000110236 -5.51181e-005c0.019622,0.0079685 0.0343583,0.0228465 0.042563,0.0406811l0.795508 1.37787 0.000299213 -0.000173228c0.0220906,0.0382638 0.00898031,0.0871929 -0.0292835,0.109283 -0.00216535,0.00125197 -0.00437008,0.00238189 -0.00659843,0.00340945l-0.85778 0.49524 0.000173228 0.000299213c-0.0382638,0.0220906 -0.0871929,0.00898031 -0.109283,-0.0292835 -0.000917323,-0.00159055 -0.00176772,-0.00320472 -0.00256693,-0.00482677l-0.186252 -0.322594 0 2.60974c0,0.0441811 -0.0358189,0.08 -0.08,0.08l-2.55539 0c-0.0441811,0 -0.08,-0.0358189 -0.08,-0.08z"/>
            </svg>
          </form-item>
          <form-item
            v-model="parentForm.order.packages_qty"
            :selectAllOnFocus="true"
            name="packagesQty"
            rules="numeric"
            :scope="scope"
            label="Boxes"
          >
            <svg slot="icon" enable-background="new 0 0 30 30" height="30px" version="1.1" viewBox="0 0 30 30" width="30px" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
              <polygon points="15.01,18.35 9,23 6,21 6,24 15.01,29 24,24 24,21 21,23"/>
              <polygon points="30,16 21,22.042 15,17 24,12"/>
              <polygon points="9,1 0,7.083 6,12 15,6"/>
              <polygon points="30,7.042 21,1 15,6 24,12"/>
              <polygon points="0,16 9,22 15,17 6,12"/>
            </svg>
          </form-item>
          <form-item
            v-model.number="parentForm.order.shipping_cost"
            name="shippingCost"
            rules="max_value:9999.99"
            :scope="scope"
            :money="{
              decimal: '.',
              thousands: ',',
              prefix: '$ ',
              precision: 2
            }"
            label="Cost"
          >
            <svg slot="icon" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
              <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1.41 16.09V20h-2.67v-1.93c-1.71-.36-3.16-1.46-3.27-3.4h1.96c.1 1.05.82 1.87 2.65 1.87 1.96 0 2.4-.98 2.4-1.59 0-.83-.44-1.61-2.67-2.14-2.48-.6-4.18-1.62-4.18-3.67 0-1.72 1.39-2.84 3.11-3.21V4h2.67v1.95c1.86.45 2.79 1.86 2.85 3.39H14.3c-.05-1.11-.64-1.87-2.22-1.87-1.5 0-2.4.68-2.4 1.64 0 .84.65 1.39 2.67 1.91s4.18 1.39 4.18 3.91c-.01 1.83-1.38 2.83-3.12 3.16z"/>
            </svg>
          </form-item>
        </form-row>
      </div>
    </div>
  </div>
</template>
<script>
import alertify from 'alertify.js';
import { uniqueId, merge, cloneDeep } from 'lodash';
import { FORM_ESTIMATE_TEMPLATES } from '@/helpers/estimates';
import StepBase from './StepBase';
import Shipping from './Fieldsets/Shipping';

export default {
  name: 'form-estimate-shipping',
  extends: StepBase,
  components: {
    Shipping
  },
  props: {
    shippingAddress: {
      type: Object
    },
    itemsQty: {
      type: Number,
      required: true
    },
    threadbirdAddresses: {
      type: Object,
      required: true
    },
    shippingOptions: {
      type: Array,
      required: true
    },
    shippingDefault: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      isLoading: false
    };
  },
  watch: {
    'parentForm.order.shipping_cost'(){
      this.$emit('setShippingCost');
    },
    shippingsQty(val, prevVal) {
      if (val === 1 && prevVal > 1) {
        const shipping = this.value[0];
        shipping.specific.items = [];
        this.parentForm.designs.forEach((design, index) => {
          if (design.specific.garments) {
            design.specific.garments.forEach(garment => {
              const item = {
                design_id: design.uniqueId,
                garment_id: garment.uniqueId,
                sizes: {}
              };
              Object.keys(garment.sizes).forEach(sizeKey => {
                if (+garment.sizes[sizeKey] === 0) return;
                item.sizes[sizeKey] = garment.sizes[sizeKey];
              });
              shipping.specific.items.push(item);
            });
          } else {
            shipping.specific.items.push({
              design_id: design.uniqueId
            });
          }
        });
      }
    }
  },
  computed: {
    shippingsQty() {
      return this.value.length;
    },
    shippingItems() {
      const options = [];
      this.parentForm.designs.forEach((design, index) => {
        if (design.specific.garments) {
          design.specific.garments.forEach(garment => {
            options.push({
              id: `${design.uniqueId}-${garment.uniqueId}`, // trackBy
              common: design.common,
              design_id: design.uniqueId,
              garment,
              garment_id: garment.uniqueId,
              index
            });
          });
        } else {
          options.push({
            id: design.uniqueId,
            common: design.common,
            design_id: design.uniqueId,
            index
          });
        }
      });
      return options;
    }
  },
  created() {
    if (this.value.length === 0) this.addShipping();
  },
  methods: {
    addShipping() {
      const template = cloneDeep(FORM_ESTIMATE_TEMPLATES.shipping);

      const recipient = this.parentForm.account.company;
      const address = { recipient };

      if (this.shippingAddress) {
        address.address_1 = this.shippingAddress.address_line_1;
        address.address_2 = this.shippingAddress.address_line_2;
        address.city = this.shippingAddress.city;
        address.country_id = this.shippingAddress.country_code;
        address.postal_code = this.shippingAddress.postal_code;
        address.state_id = this.shippingAddress.state_code;
        address.is_taxed = this.shippingAddress.is_taxed;
      }

      const shipping = merge(template, {
        common: { uniqueId: uniqueId('shipping_') },
        specific: { address }
      });

      if (this.value.length === 0) {
        this.parentForm.designs.forEach((design, index) => {
          if (design.specific.garments) {
            design.specific.garments.forEach(garment => {
              const item = {
                design_id: design.uniqueId,
                garment_id: garment.uniqueId,
                sizes: {}
              };
              Object.keys(garment.sizes).forEach(sizeKey => {
                if (+garment.sizes[sizeKey] === 0) return;
                item.sizes[sizeKey] = garment.sizes[sizeKey];
              });
              shipping.specific.items.push(item);
            });
          } else {
            shipping.specific.items.push({
              design_id: design.uniqueId
            });
          }
        });
      }

      this.value.push(shipping);
    },
    deleteShipping(index) {
      this.value.splice(index, 1);
    }
  }
};
</script>
