<template>
  <div class="">
    <div class="">
      <div class="is-half is-offset-one-quarter">
        <form @submit.prevent="submitForm">
          <div class="control">
            <form-input
              v-model="invoice.amount"
              :horizontal="true"
              :disabled="true"
              name="amount"
              type="number"
              step="0.01"
              min="0.01"/>
          </div>
          <div class="control">
            <form-input
              v-model="invoice.description"
              :horizontal="true"
              name="description"/>
          </div>
          <div class="control">
            <form-select
              v-model="invoice.payment_term"
              :horizontal="true"
              name="payment_term"
              label="Term"
              :options="{all: paymentOptions}"/>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
  import Vue from 'vue';

  //form
  import formInput from '../../common/form/input';
  import formSelect from '../../common/form/select';
  import apiService from '../../../services/api.service';
  import alertify from 'alertify.js';
  import bus from '../../../bus';
  import {mapGetters} from 'vuex';

  export default {
    name: 'create-invoices',
    props: ['submitted', 'additional'],
    data() {
      return {
        invoice: {
          amount: this.additional.amount || null,
          description: this.additional.description || null,
          payment_term: this.additional.payment_term || null,
          estimate: this.additional.estimate || null
        },
        processing: false
      }
    },
    components: {
      formInput,
      formSelect,
    },
    computed: {
      ...mapGetters({
        paymentOptions: 'paymentTerms'
      })
    },
    created() {
      bus.$on('errors-changed', (errors) => {
        if (errors) {
          errors.forEach(e => {
            this.errors.add(e.field, e.msg, e.rule, e.scope);
          });
        }
      });
      bus.$on('errors-deleted', (oldErrors) => {
        if (oldErrors) {
          oldErrors.forEach(e => {
            this.errors.remove(e.field, e.scope);
          });
        }
      });
    },
    methods: {
      validateBeforeSubmit(e) {
        this.clearErrors();
        this.validateChild();

        return new Promise((resolve, reject) => {
          Vue.nextTick(() => {
            resolve(!this.errors.count());
          });
        });
      },
      submitForm() {
        this.validateBeforeSubmit()
          .then(isValid => {
            if (!isValid) {
              this.$emit('submitFail');
              return alertify.error('Validation failed. Please check error messages.');
            }

            this.processing = true;

            apiService
              .requestPayment({
                orderId: this.additional.orderId,
                invoice: this.invoice
              })
              .then(data => {
                this.processing = false;
                this.$emit('success');
              })
              .catch(error => {
                this.$emit('submitFail');
                alertify.error(error && error.message || 'Unexpected Error.');

              });
          })
      },
      validateChild() {
        bus.$emit('validate');
      },
      clearErrors() {
        bus.$emit('clear');
        this.errors.errors = [];
      }
    }
  };
</script>
