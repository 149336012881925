<template>
  <div class="content">
    <div v-if="finishing.name">
      <strong>Finishing:</strong> {{finishing.name || finishing.label}}
    </div>
    <div><strong>{{finishing.label}}:</strong>
      <span v-roles="['admin', 'csr', 'production-coordinator', 'purchaser', 'art-producer', 'customer']">
        {{finishing.price | price}}
      </span>
    </div>
    <table class="table table__without-hover">
      <tr v-if="finishing.fields.hem_tag != ''">
        <td>Hem Location</td>
        <td>{{finishing.fields.hem_tag}}</td>
      </tr>
      <tr v-if="finishing.fields.hem_tag_thread_color != ''">
        <td>Hem Tag Thread Color</td>
        <td>{{finishing.fields.hem_tag_thread_color}}</td>
      </tr>
    </table>
  </div>
</template>

<script>
  import Vue from 'vue'

  export default {
    props: ['finishing'],
    name: 'finishing-summary-hem-tag'
  };
</script>
