<template>
  <div class="design-info">
    <div v-if="mock.finishings && mock.finishings.length"
      class="card is-fullwidth">
      <header class="card-header">
        <p class="card-header-title">Finishings</p>
      </header>
      <div class="card-content">
        <div class="content" v-for="item in mock.finishings">
          <component v-if="finishComponent(item.finishing.finishable_type)"
            :is="finishComponent(item.finishing.finishable_type).component"
            :label="finishComponent(item.finishing.finishable_type).label"
            :item="item"></component>
        </div>
      </div>
    </div>
    <br v-if="designable.finishings && designable.finishings.length">
    <div v-if="designable.garments" class="card is-fullwidth">
      <div class="card-content">
        <div class="content" v-for="garment in garments">
          <table class="table is-bordered is-striped is-narrow">
            <thead>
              <tr>
                <th>Color</th>
                <th>Item</th>
                <th v-for="(size, sizeKey) in garment.sizes" v-if="+size > 0"
                    :key="`${size}-${sizeKey}`">{{sizeKey}}</th>
                <th v-if="inkStyles">Ink Style</th>
                <th v-if="processAddons">Process Add-Ons</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{{garment.color}}</td>
                <td>
                  <span v-if="garment.product_object.number">{{garment.product_object.number}} - </span>
                  <span>{{garment.product_object.name}}</span>
                  <ul v-if="garment.product_object.fabric || garment.product_object.origin">
                    <li v-if="garment.product_object.fabric">Fabric - {{garment.product_object.fabric}}</li>
                    <li v-if="garment.product_object.origin">Origin - {{garment.product_object.origin}}</li>
                  </ul>
                </td>
                <td v-for="(size, sizeKey) in sizesList"
                    v-if="typeof garment.sizes[size] !== 'undefined' && +garment.sizes[size] > 0"
                    :key="`${size}-${sizeKey}`">{{garment.sizes[size] | itemsNumberFormat}}</td>
                <td v-if="inkStyles">{{inkStyles}}</td>
                <td v-if="processAddons">{{processAddons}}</td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="content">
          <div v-if="design.comment && !hideComment" class="card is-fullwidth">
            <header class="card-header">
              <p class="card-header-title">Design Comment</p>
            </header>
            <div class="content-pre card-content">{{design.comment}}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import isEmpty from 'lodash/isEmpty';
import { finishingComponents } from '../../../helpers/revision';
import moment from 'moment';

// finishings
import hemTag from '../finishings/hem-tag';
import tagPrinting from '../finishings/tag-printing';
import wovenNeckTag from '../finishings/woven-neck-tag';
import fourSided from '../finishings/four-sided-tag';
import foldingBagging from '../finishings/folding-bagging';
import hangTag from '../finishings/hang-tag';
import heatPressedTags from '../finishings/heat-pressed-tags';
import sewnTagBottomOrSleeve from '../finishings/sewn-tag-bottom-or-sleeve';
import sewnTagNeckAndFourSided from '../finishings/sewn-tag-neck-and-four-sided';

export default {
  name: 'design-info-apparel',
  components: {
    tagPrinting,
    hemTag,
    wovenNeckTag,
    foldingBagging,
    hangTag,
    heatPressedTags,
    sewnTagBottomOrSleeve,
    sewnTagNeckAndFourSided,
    fourSided
  },
  props: {
    design: {
      type: Object,
      required: true
    },
    mock: {
      type: Object,
      required: true
    },
    options: {
      type: Object,
      required: true
    },
    hideComment: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      sizesList: []
    };
  },
  methods: {
    finishComponent(type) {
      return finishingComponents[type] || null;
    }
  },
  computed: {
    inkStyles() {
      let styles = [];
      if (!isEmpty(this.options) && !isEmpty(this.designable.locations)) {
        this.designable.locations.forEach(location => {
          if (!isEmpty(this.options.ink_types)) {
            this.options.ink_types.forEach(style => {
              if (+location.ink_type == style.key) {
                styles.push(style.label);
              }
            });
          }
        });
      }
      return styles.join(', ');
    },
    processAddons() {
      let addons = [];
      if (!isEmpty(this.options) && !isEmpty(this.designable.locations)) {
        this.designable.locations.forEach(location => {
          if (!isEmpty(this.options.process_addons)) {
            this.options.process_addons.forEach(addon => {
              location.process_addons.forEach(location_addon => {
                if (+location_addon.key == addon.key) {
                  addons.push(addon.label);
                }
              });
            });
          }
        });
      }

      return addons.join(', ');
    },
    designable() {
      return this.design.designable || {};
    },
    garments() {
      let garments = [];
      if (
        !this.design &&
        !this.design.designable &&
        !this.design.designable.garments
      )
        return [];

      garments = this.design.designable.garments.slice().map(garm => {
        let garment = Object.assign({}, garm);
        let sizes = JSON.parse(garment.size_qty);

        // Sort list of garments sizes by prepared sorted list of sizes.
        garment.sizes = this.sizesList
          .filter(size => Object.keys(sizes).includes(size) && !!+sizes[size])
          .reduce((size, curKey) => {
            size[curKey] = sizes[curKey];
            return size;
          }, {});
        return garment;
      });
      return garments;
    }
  },
  mounted() {
    this.sizesList = cloneDeep(this.$store.getters.sortedGarmentsSizes);
  }
};
</script>
