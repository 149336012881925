export const agregatePrices = (data) => {
  if (!data || !(data instanceof Object)) return data;

  return Object.keys(data).reduce(
    (obj, key) => {
      obj[key] = data[key].replace(',', '');
      return obj;
    },
    {}
  );
};
