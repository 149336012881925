<template>
  <div>
    <p>Qty: {{total.qty | itemsNumberFormat}}
      <strong  v-roles="['admin', 'csr', 'production-coordinator', 'purchaser', 'art-producer', 'customer']">
        / Design Total: {{totalPrice | price}}
      </strong>
    </p>
  </div>
</template>

<script>
  import Vue from 'vue';

  export default {
    name: 'quantity-cost-block',
    props: ['total'],
    computed: {
      totalPrice() {
        return +this.total.price + (this.total.upcharge ? this.total.upcharge : 0);
      }
    }
  };
</script>
