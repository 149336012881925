<template>
  <!--  new version of design-->
  <div v-if="item.product">
    <li v-if="item.product">
      <label>Product</label>
      <p>{{ item.model.name }}</p>
    </li>
    <li>
      <label>{{ productAddonName }}</label>
      <strong>{{ item.addons.product_addon_name }}</strong>
    </li>
    <li>
      <label v-if="item.size === 'empty_size' && item.custom_width && item.custom_height">Size (Custom)</label>
      <label v-else>Size</label>
      <strong>
        <template v-if="item.size === 'empty_size' && item.custom_width && item.custom_height">
          {{ item.custom_width }} x {{ item.custom_height }}
        </template>
        <template v-else>{{ item.size }}</template>
      </strong>
    </li>
    <li>
      <label>Quantity</label>
      <strong>{{ getOptionLabel(item.quantity, formOptions.quantities) }}</strong>
    </li>
    <li v-roles="['admin', 'csr', 'production-coordinator', 'purchaser', 'art-producer', 'developer', 'customer']">
      <label v-if="item.custom_unit_price && item.custom_unit_price.length">Unit Price(Custom)</label>
      <label v-else>Unit Price</label>
      <strong>{{ getUnitPrice({specific: {item: item},}, formOptions)| price }}</strong>
    </li>
  </div>
  <div v-else>
    <li>
      <label>Shape</label>
      <strong>{{ getOptionLabel(item.shape, formOptions.shapes) }}</strong>
    </li>
    <li>
      <label>Size</label>
      <strong>{{ getOptionLabel(item.size, formOptions.shapes.find(({ key }) => key === item.shape).sizes) }}</strong>
    </li>
    <li>
      <label>Quantity</label>
      <strong>{{ getOptionLabel(item.quantity, formOptions.quantities) }}</strong>
    </li>
  </div>
</template>
<script>
import {getOptionLabel} from "@/helpers/estimates";
import {getUnitPrice} from "@/helpers/prices/sticker";

export default {
  name: 'StickerPrintingItem',
  props: {
    item: {
      type: Object,
      required: true
    },
    formOptions: {
      type: Object,
      required: true
    },
  },
  methods: {
    getOptionLabel,
    getUnitPrice,
  },
  computed: {
    productAddonName() {
      const productAddons = this.formOptions.options[this.item.product];
      let label = '';
      Object.keys(productAddons).forEach(key => {
        productAddons[key].forEach(item => {
            if (item.id === this.item.addons.id) {
              label = key
            }
          }
        )
      })
      return label
    }
  }
}
</script>
