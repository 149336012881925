<template>
  <div v-if="finishings && finishings.length" class="finishings-info widget has-separator">
    <div
      class="info has-separator"
      v-for="(finishing, finishingKey) in finishings"
      :key="finishingKey"
      v-if="finishComponent(finishing.finishable_type) && finishComponent(finishing.finishable_type).editable"
    >
      <component
        :class="finishComponent(finishing.finishable_type).component"
        :is="finishComponent(finishing.finishable_type).component"
        :label="finishComponent(finishing.finishable_type).label"
        :editable="editable && finishComponent(finishing.finishable_type).editable"
        :finishing="finishing"
        :design="design"
        :options="options"
        :ref="`finishing-${finishingKey}`"
        :finishingKey="finishingKey"
      ></component>
    </div>
  </div>
</template>

<script>
import { finishingComponents } from '../../../helpers/revision';
import hemTag from './finishings-types/hem-tag';
import tagPrinting from './finishings-types/tag-printing';
import wovenNeckTag from './finishings-types/woven-neck-tag';
import fourSidedTag from './finishings-types/four-sided-tag';
import foldingBagging from './finishings-types/folding-bagging';
import hangTag from './finishings-types/hang-tag';
import heatPressedTags from './finishings-types/heat-pressed-tags';
import sewnTagBottomOrSleeve from './finishings-types/sewn-tag-bottom-or-sleeve';
import sewnTagNeckAndFourSided from './finishings-types/sewn-tag-neck-and-four-sided';

import { cloneDeep } from 'lodash';

import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'finishings',
  props: {
    design: {
      type: Object,
      required: true
    },
    mock: {
      type: Object,
      required: true
    },
    editable: {
      type: Boolean,
      default: false
    },
    options: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      finishings: []
    };
  },
  watch: {
    mock() {
      this.aggregateFinishings();
    },
    design() {
      this.aggregateFinishings();
    }
  },
  components: {
    tagPrinting,
    hemTag,
    wovenNeckTag,
    foldingBagging,
    hangTag,
    heatPressedTags,
    sewnTagBottomOrSleeve,
    sewnTagNeckAndFourSided,
    fourSidedTag
  },
  computed: {
    designFinishings() {
      return (
        (this.design.designable.finishings &&
          this.design.designable.finishings.length &&
          this.design.designable.finishings) ||
        []
      );
    },
    mockFinishings() {
      return (
        (this.mock.finishings &&
          this.mock.finishings.length &&
          this.mock.finishings) ||
        (this.design.mocks &&
          this.design.mocks.length &&
          this.design.mocks[this.design.mocks.length - 1] &&
          this.design.mocks[this.design.mocks.length - 1].finishings) ||
        []
      );
    }
  },
  methods: {
    aggregateFinishings() {
      let finishings = this.designFinishings.map(finishing => {
        let mock_finishing = this.mockFinishings.find(
          mock_finish =>
            +mock_finish.apparel_design_finishing_id === +finishing.id
        );
        switch (this.finishComponent(finishing.finishable_type).component) {
          case 'hem-tag':
          case 'hem-tags-fleece':
            return Object.assign(
              {
                location:
                  (mock_finishing && mock_finishing.location) ||
                  (finishing &&
                    finishing.finishable &&
                    finishing.finishable.hem_tag) ||
                  '',
                thread_colors:
                  (mock_finishing && mock_finishing.thread_colors) ||
                  (finishing &&
                    finishing.finishable &&
                    finishing.finishable.hem_tag_thread_color) ||
                  ''
              },
              finishing
            );
          case 'hang-tag':
            return Object.assign(
              {
                location:
                  (mock_finishing && mock_finishing.location) ||
                  (finishing &&
                    finishing.finishable &&
                    finishing.finishable.hang_tag) ||
                  ''
              },
              finishing
            );
          case 'tag-printing':
          case 'heat-pressed-tags':
            return Object.assign(
              {
                colors: (mock_finishing && mock_finishing.colors) || [],
                width:
                  (mock_finishing && mock_finishing.width) ||
                  (finishing &&
                    finishing.finishable &&
                    finishing.finishable.tag_width) ||
                  '',
                height:
                  (mock_finishing && mock_finishing.height) ||
                  (finishing &&
                    finishing.finishable &&
                    finishing.finishable.tag_height) ||
                  '',
                location_comment:
                  (mock_finishing && mock_finishing.location_comment) || ''
              },
              finishing
            );
          case 'woven-neck-tag':
            return Object.assign(
              {
                thread_colors:
                  (mock_finishing && mock_finishing.thread_colors) ||
                  (finishing &&
                    finishing.finishable &&
                    finishing.finishable.woven_neck_tag_thread_color) ||
                  ''
              },
              finishing
            );
          case 'sewn-tag-neck-and-four-sided':
          case 'neck-tags-fleece':
            return Object.assign(
              {
                thread_colors:
                  (mock_finishing && mock_finishing.thread_colors) ||
                  (finishing &&
                    finishing.finishable &&
                    finishing.finishable.sewn_tag_neck_tag_thread_color) ||
                  ''
              },
              finishing
            );
          default:
            return finishing;
        }
      });

      this.$set(this, 'finishings', finishings);
    },
    finishComponent(type) {
      return finishingComponents[type] || null;
    },
    getFormData() {
      let finishings = [];
      this.finishings.forEach((finishing, finishingKey) => {
        if (this.finishComponent(finishing.finishable_type).editable) {
          console.log(this.$refs);
          let finishing_data = this.$refs[
            `finishing-${finishingKey}`
          ][0].getFormData();
          finishings.push(finishing_data);
        }
      });
      return finishings;
    }
  },
  mounted() {
    this.aggregateFinishings();
  },
  destroyed() {}
};
</script>
