<template>
  <div>
    <li>
      <label>Print Type</label>
      <strong>{{ getOptionLabel(item.print_type, formOptions.print_types) }}</strong>
    </li>
    <li>
      <label>Paper Type</label>
      <strong>{{ getOptionLabel(item.paper_type, formOptions.print_type_options[item.print_type].paper_types) }}</strong>
    </li>
    <li>
      <label>Size</label>
      <strong>{{ getOptionLabel(item.size, formOptions.print_type_options[item.print_type].sizes) }}</strong>
    </li>
    <li>
      <label>Quantity</label>
      <strong>{{ getOptionLabel(item.quantity, formOptions.print_type_options[item.print_type].quantities) }}</strong>
    </li>
    <li v-if="getOptionLabel(item.print_type, formOptions.print_types)!=='Digital Posters'">
      <label>Rounded Corners</label>
      <strong>{{ item.has_rounded_corners ? 'Yes' : 'No' }}</strong>
    </li>
  </div>
</template>
<script>
import {getOptionLabel} from "@/helpers/estimates";

export default {
  name: 'DigitalPrintItem',
  props: {
    item: {
      type: Object,
      required: true
    },
    formOptions: {
      type: Object,
      required: true
    },
  },
  methods: {
    getOptionLabel,
  }
}
</script>
