import Vue from 'vue';
import Vuex from 'vuex';
Vue.use(Vuex);

import revisionModule from './modules/revision';
import commonModule from './modules/common';
import dashboardModule from './modules/dashboard';
import messagesModule from './modules/messages';
import orderDetailsModule from './modules/order-details';
import ordersSummaryModule from './modules/orders-summary';
import accounts from './modules/accounts';
import reports from './modules/reports';

export default new Vuex.Store({
  namespaced: true,
  strict: process.env.NODE_ENV !== 'production',
  modules: {
    common: commonModule,
    revision: revisionModule,
    dashboard: dashboardModule,
    messages: messagesModule,
    orderDetails: orderDetailsModule,
    ordersSummary: ordersSummaryModule,
    accounts: accounts,
    reports: reports
  }
});
