<template>
  <div class="page-create-estimate page-content">
    <div class="page-container">
      <form-estimate
        :data="form"
        v-if="!isLoading"
      ></form-estimate>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import { map, isEmpty, cloneDeep, isEqual } from 'lodash';
import { mapActions } from 'vuex';

import notify from '../../modules/notifier';
import alertify from 'alertify.js';

import apiService from '../../services/api.service';
//inner
import FormEstimate from '../forms/FormEstimate';
import estimateSummary from './estimate-summary';
import bus from '../../bus';
import store from '../../store';
import { checkRoles } from '../../router/checkRoles';

import {
  RESUBSCRIBE_PUBNUB,
  LOAD_EDIT_ORDER_INIT_DATA,
  CLEAR_ACCOUNT,
  CLAIM
} from '../../store/action-types';

export default {
  components: {
    FormEstimate,
    estimateSummary
  },
  name: 'create-order',
  data() {
    return {
      form: null,
      isLoading: !!this.$route.params.id,
      estimate: {
        account: {
          id: '',
          contact_firstname: '',
          contact_lastname: '',
          email: '',
          phone: '',
          s_mailing_address: '',
          s_city: '',
          s_country_id: '',
          s_postal_code: '',
          s_state: '',
          s_state_id: '',
          billing_address_same: false,
          b_mailing_address: '',
          b_city: '',
          b_country_id: '',
          b_postal_code: '',
          b_state: '',
          b_state_id: '',
          payment_term: 'request-payment',
          comment: '',
          shipping_cost: 0,
          tax_id: '',
          packages_qty: 0,
          recipient_firstname: '',
          recipient_lastname: '',
          recipient_company: '',
          address_source: null,
          is_test: false
        },
        designs: [
          {
            common: {},
            specific: {}
          }
        ],
        summary: {
          designs: {},
          discount: {
            discount_amount: 0,
            discount_percentage: 0,
            discount_description: '',
            discount_field_for_calculation: ''
          }
        },
        finishings: [],
        discount: {
          discount_amount: 0,
          discount_percentage: 0,
          discount_description: '',
          discount_field_for_calculation: ''
        }
      },
      orderSummary: null,
      options: {
        submitAndSendBtnText: 'Create and Send',
        submitDraftBtnText: 'Save Draft'
      },
      errorsList: [],
      processing: false
    };
  },
  computed: {
    isCustomer() {
      return checkRoles(store.getters.roles, ['customer'], true);
    }
  },
  methods: {
    ...mapActions({
      getInitData: LOAD_EDIT_ORDER_INIT_DATA,
      clearAccount: CLEAR_ACCOUNT,
      claimEstimate: CLAIM
    }),
    onSaveDraft(payload) {
      // JUST SAVE
      console.log('SAVE');
    },
    onCreateEstimate(payload) {
      // SAVE AND SEND
      console.log('SAVE');
    },
    onSetOrder(model) {
      this.$set(this, 'estimate', _.cloneDeep(model));
    },
    setOrderSummary(data) {
      this.$set(this, 'orderSummary', _.cloneDeep(data));
    },
    submitForm(isValid, action) {
      if (isValid) {
        //Propagate events to collect data into model from all components
        return this.$refs.estimateForm
          .setOrder()
          .then(estimate => {
            if (
              !estimate ||
              !estimate.designs ||
              estimate.designs.length === 0 ||
              (estimate.designs.length === 1 &&
                !estimate.designs[0].common.service)
            ) {
              return Promise.reject(
                new Error('Please add at least one design.')
              );
            }

            this.processing = true;
            this.$set(this, 'estimate', estimate);
            let data = cloneDeep(estimate);
            data.summary = this.orderSummary;

            if (typeof this[action] === 'function') {
              this[action](data);
            }
          })
          .catch(error => {
            this.processing = false;
            notify({
              message: error.message,
              type: 'danger'
            });
          });
      } else {
        notify({
          message:
            'Some validation errors have been detected. Please check error messages.',
          type: 'danger'
        });
      }
    },
    cancel() {
      if (checkRoles(store.getters.roles, ['customer'], true)) {
        this.$router.push('/customer/dashboard');
      } else {
        this.$router.push('/dashboard/my-estimates');
      }
    },
    createOrder(data, send) {
      apiService.createOrder(data).then(
        data => {
          //Redirect to next step
          notify({
            message: 'The estimate has been saved.',
            type: 'success'
          });

          this.createInvoice(data.data.id, send);

          // If csr or team have created the estimate
          if (!checkRoles(store.getters.roles, ['customer', 'printer'], true)) {
            // Auto claim estimate.
            this.claimEstimate({
              orderId: data.data.id,
              subject: 'estimate',
              vue: this
            });

            // If "Create and Send" button clicked.
            if (send) {
              apiService
                .sendEstimate(data.data.id)
                .then(() => {
                  notify({
                    message: 'The estimate has been sent.',
                    type: 'success'
                  });
                  this.pushToDashboard();
                })
                .catch(err => {
                  notify({
                    message:
                      "Unfortunately, estimate wasn't sent. Please try to resend it from dashboard",
                    type: 'danger'
                  });
                });
            } else {
              //  If "Save Draft" button clicked.
              this.pushToDashboard();
            }
          } else {
            // Redirect to dashboard if customer has created an estimate.
            this.pushToDashboard();
          }
        },
        response => {
          this.processing = false;

          if (response.status == 422 && response.data.errors) {
            notify({
              message:
                'Some validation errors have been detected. Please check error messages.',
              type: 'danger'
            });

            let errors = map(response.data.errors, (messages, fullName) => {
              let chunks = fullName.split('.');
              let name = chunks.splice(chunks.length - 1, 1);
              let scope = chunks.join('-');

              return {
                field: name[0],
                msg: messages.join('\n'),
                rule: 'required',
                scope: scope,
                fullName: fullName
              };
            });

            this.$set(this, 'errorsList', errors);
          } else if (response.body && response.body.error) {
            notify({
              message: response.body.error,
              type: 'danger'
            });
          } else {
            notify({
              message: "An Error occurred. The estimate hasn't been saved.",
              type: 'danger'
            });
          }
        }
      );
    },
    saveAndSend(data) {
      this.createOrder(data, true);
    },
    saveDraft(data) {
      this.createOrder(data, false);
    },
    pushToDashboard() {
      this.clearAccount();

      if (checkRoles(store.getters.roles, ['customer'], true)) {
        this.$router.push('/customer/dashboard');
        this.$store.dispatch(RESUBSCRIBE_PUBNUB, this);
      } else {
        this.$router.push('/dashboard/my-estimates');
      }
    },
    createInvoice(orderId, send) {
      let data = {
        invoice: {
          payment_term: this.estimate.account.payment_term,
          description: 'Initial invoice for order #' + orderId,
          send: send
        },
        orderId: orderId
      };

      apiService
        .requestInitialPayment(data)
        .then(response => {
          if (response.status === 'success') {
            notify({
              message: Object.values(response.messages).join('<br>'),
              type: 'success'
            });

            if (
              response.invoice &&
              response.invoice.payment_term !== 'request-payment'
            ) {
              notify({
                message: 'The estimate has been converted to order.',
                type: 'success'
              });
            }
          } else {
            notify({
              message: Object.values(response.messages).join('<br>'),
              type: 'danger'
            });
          }
        })
        .catch(err => {
          if (err.messages) {
            notify({
              message: Object.values(response.messages).join('<br>'),
              type: 'danger'
            });
          } else {
            notify({
              message:
                `Invoice for Order #${orderId} is not created.<br>` +
                `Please go to  <a href="#/orders/${orderId}/invoices">Invoices tab</a> and click "Send initial invoice button".`,
              type: 'danger'
            });
          }
        });
    }
  },
  watch: {
    '$route.params.id': function(val) {
      this.isLoading = true;
      this.$apiService
        .getEstimateData(this.$route.params.id)
        .then(({ data }) => {
          this.form = data;
        })
        .catch(error => {
          console.error(error);
        })
        .then(() => {
          this.isLoading = false;
        });
    }
  },
  created() {
    if (this.$route.params.id) {
      this.isLoading = true;
      this.$apiService
        .getEstimateData(this.$route.params.id)
        .then(({ data }) => {
          this.form = data;
        })
        .catch(error => {
          console.error(error);
        })
        .then(() => {
          this.isLoading = false;
        });
    } else if (this.$route.query.copy_from_id) {
      this.isLoading = true;
      this.$apiService
        .copyEstimate(this.$route.query.copy_from_id)
        .then(({ order }) => {
          this.form = order;
        })
        .catch(error => {
          console.error(error);
        })
        .then(() => {
          this.isLoading = false;
        });
    }
  },
  beforeDestroy() {
    bus.$emit('account-changed', {});
  }
};
</script>
<style lang="scss">
.page-create-estimate {
  background: #f9f9f9;
  .page-container {
    $padding: 15px;
    padding: 0 $padding;
    margin: 0 auto;
    max-width: 1100px + ($padding * 2);
  }
}
</style>
