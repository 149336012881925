<template>
  <div>
    <h5 class="subtitle">Create Company</h5>

    <company-form :model="company"
      :errorsList="errorsList"
      :processing="processing"
      :options="options"
      @submitForm="submitForm"></company-form>
  </div>
</template>

<script>
import Vue from 'vue';
import { map } from 'lodash';
import { mapGetters, mapActions } from 'vuex';
import { checkRoles } from '../../../router/checkRoles';
import filter from 'lodash/filter';
import apiService from '../../../services/api.service';

import alertify from 'alertify.js';

//form
import companyForm from './form/company-form';
import { LOAD_ACCOUNT_FORM } from '../../../store/action-types';

export default {
  name: 'create-company',
  data() {
    return {
      termsOptions: [],
      company: {
        name: '',
        terms: 'request-payment',
        shipping_address: {
          address_line_1: '',
          address_line_2: '',
          city: '',
          state_code: '',
          country_code: 'US',
          postal_code: ''
        },
        billing_address: {
          address_line_1: '',
          address_line_2: '',
          city: '',
          state_code: '',
          country_code: 'US',
          postal_code: ''
        },
        new_customers: [],
        selected_customers: []
      },
      options: {
        submitBtnText: 'Create'
      },
      errorsList: [],
      processing: false
    };
  },
  components: {
    companyForm
  },
  computed: {
    ...mapGetters({
      roles: 'systemRoles',
      userRoles: 'roles'
    }),
    allowedRoles() {
      //remove admin role for non admin users
      let allowedRoles = this.roles;
      if (!checkRoles(this.userRoles, ['admin'])) {
        allowedRoles = filter(this.roles, r => {
          return r.key != 'admin';
        });
      }
      return allowedRoles;
    }
  },
  methods: {
    ...mapActions({
      loadAccountForm: LOAD_ACCOUNT_FORM
    }),
    submitForm() {
      return apiService
        .storeCompany(this.company)
        .then(res => {
          alertify.success('Company Saved');

          this.$router.push(`/companies`);
        })
        .catch(err => {
          if (err.status == 422 && err.data.errors) {
            alertify.error(
              'Some validation errors have been detected. Please check error messages.'
            );
            let errors = map(err.data.errors, (messages, fullName) => {
              let chunks = fullName.split('.');
              let name = chunks.splice(chunks.length - 1, 1);
              let scope = 'company-form';

              if (chunks[0] == 'shipping_address') {
                scope = 'company-form-shipping-address';
              } else if (chunks[0] == 'billing_address') {
                scope = 'company-form-billing-address';
              }

              return {
                field: name[0],
                msg: messages.join('\n'),
                rule: 'required',
                scope: scope,
                fullName: fullName
              };
            });

            this.$set(this, 'errorsList', errors);
          } else {
            let msg = err.body.message ? err.body.message : 'Something wrong.';
            alertify.error(msg);
          }
        });
    }
  },
  mounted() {
    this.loadAccountForm();
  }
};
</script>
