import moment from 'moment';
import store from '../store';

import { isMentionedMe, openMessenger } from './messages';

import { checkRoles } from '../router/checkRoles';
import gridHelper, { toPriceFormat } from './grid-helper';
import { BLANK_STATUS_STOCK_ISSUE } from './orders';
import {teamMembers} from '@/helpers/role-access';
import {APPAREL_SERVICE_CODE} from '@/store/service-types';

export const DATE_FORMAT = 'MM/DD/YY';
export const STATUS_DRAFT = 'Draft';
export const STATUS_SENT = 'Sent';
export const STATUS_VIEWED = 'Viewed';
export const STATUS_APPROVED = 'Approved';
export const STATUS_DECLINED = 'Declined';
export const STATUS_REVISION_REQUESTED = 'Revision requested';

export const ESTIMATE_FILTER_STATUSES = {
  STATUS_FILTER_ACTIVE: 1,
  STATUS_FILTER_SENT: 2,
  STATUS_FILTER_VIEWED: 3,
  STATUS_FILTER_APPROVED: 4,
  STATUS_FILTER_LOST: 5,
  STATUS_FILTER_ALL: 6
};

export const MIN_GARMENT_ITEMS = 24;
export const MIN_REDO_ITEMS = 0;
export const MIN_GARMENT_ITEMS_BLANK = 1;

export const FIELD_OPTIONS_MAP = {
  apparel: {
    colors_number: 'colors_numbers',
    ink_type: 'ink_types',
    location: 'locations',
    depth: 'depth',
    stitches_id: 'stitches'
  },
  embroidery: {
    location: 'locations',
    depth: 'depth',
    stitches_id: 'stitches'
  },
  digital: {
    print_type: 'print_types',
    paper_type: 'paper_types',
    size: 'sizes',
    quantity: 'quantities'
  },
  paper: {
    print_type: 'print_types',
    paper_type: 'paper_types',
    size: 'sizes',
    quantity: 'quantities',
    number_of_colors: 'color_numbers',
    brand: 'brands',
    paper_weight: 'weights',
    paper_color: null,
    bleed_type: 'bleed',
    ink_type: 'ink_type'
  },
  finishing_creation: {
    print_type: 'print_types',
    paper_type: 'paper_types',
    size: 'sizes',
    quantity: 'quantities',
    border: 'borders',
    brand: 'brands'
  },
  sticker: {
    shape: 'shapes',
    size: 'sizes',
    quantity: 'quantities'
  },
  button: {
    size: 'sizes',
    quantity: 'quantities'
  },
  poster: {
    number_of_colors: 'color_numbers',
    size: 'sizes',
    quantity: 'quantities',
    brand: 'brands',
    paper_weight: 'weights',
    paper_color: null,
    bleed_type: 'bleed',
    ink_type: 'ink_type'
  }
};

export const FORM_ESTIMATE_TEMPLATE = {
  account: {
    account_id: null,
    contact_firstname: '',
    contact_lastname: '',
    phone: '',
    email: '',
    company_id: null,
    company: '',
    address_source: 'company',
    customer_production_notes: null,
    company_production_notes: null
  },
  designs: [],
  finishings: [],
  shippings: [],
  payment: {
    payment_term: 'request-payment',
    tax_id: null,
    state_tax: null,
    rush_fee: {
      rush_fee_type: 'usd',
      rush_fee_amount: 0,
      rush_fee_percentage: 0,
      rush_fee_description: ''
    },
    discount: {
      discount_type: 'usd',
      discount_amount: 0,
      discount_percentage: 0,
      discount_description: ''
    }
  },
  order: {
    id: null,
    comment: '',
    name: '',
    shipping_cost: 0,
    packages_qty: null
  }
};

export const FORM_ESTIMATE_TEMPLATES = {
  contact: {
    id: null,
    first_name: '',
    last_name: '',
    phone: '',
    email: '',
    company_id: null,
    company: '',
    address_source: 'company'
  },
  design: {
    common: {
      id: null,
      service: APPAREL_SERVICE_CODE,
      name: '',
      comment: '',
      redo_design_id: null,
      reference_order_id: null,
      reference_design_id: null,
      upcharge: 0,
      upcharge_description: '',
      original_files_link: '',
      in_hands_date: null,
      ship_by_date: null,
      print_by_date: null,
      uniqueId: null
    },
    specific: {}
  },
  designSpecific: {
    apparel: {
      extra: {
        id: null,
        ink_color_changes: null,
        total_garments_qty: null,
        total_locations: null,
        total_colors_number: null
      },
      locations: [],
      garments: []
    },
    promotional: {
      extra: {
        id: null
      },
      item: {
        product_type: null,
        product_category: null,
        product: null,
        size: null,
        quantity: null,
        color: null,
        number_of_colors: null,
        cost: null,
        addons: []
      }
    },
    blank: {
      extra: {
        id: null,
        total_garments_qty: null
      },
      garments: []
    },
    embroidery: {
      extra: {
        id: null,
        total_garments_qty: null,
        total_locations: null
      },
      locations: [],
      garments: []
    },
    digital: {
      extra: {
        id: null
      },
      item: {
        print_type: null,
        paper_type: null,
        size: null,
        quantity: null,
        has_rounded_corners: false
      }
    },
    paper: {
      extra: {
        id: null
      },
      item: {
        print_type: null,
        paper_type: null,
        size: null,
        quantity: null,
        has_rounded_corners: false,
        number_of_colors: null,
        brand: null,
        paper_weight: null,
        paper_color: null,
        bleed_type: null,
        ink_type: null,
        custom_brand: '',
        custom_paper_color: '',
        custom_size: '',
        custom_unit_price: null,
        uniqueId: '' // decimal
      }
    },
    finishing_creation: {
      extra: {
        id: null
      },
      item: {
        product_type: null,
        print_type: null,
        paper_type: null,
        border: null,
        size: null,
        quantity: null,
        has_rounded_corners: false,
        button_loops: false,
        extra_colors: false,
        custom_unit_price: null
      }
    },
    sticker: {
      extra: {
        id: null
      },
      item: {
        addons: null,
        size: null,
        quantity: null,
        product: null
      }
    },
    button: {
      extra: {
        id: null
      },
      item: {
        size: null,
        quantity: null
      }
    },
    poster: {
      extra: {
        id: null
      },
      item: {
        number_of_colors: null,
        size: null,
        quantity: null,
        brand: null,
        paper_weight: null,
        paper_color: null,
        bleed_type: null,
        ink_type: null,
        custom_brand: '',
        custom_paper_color: '',
        custom_size: '',
        custom_unit_price: null,
        uniqueId: '' // decimal
      }
    },
    other: {
      extra: {
        id: null
      },
      items: []
    },
    non_order_invoice: {
      extra: {
        id: null
      },
      items: []
    }
  },
  designSpecificOther: {
    name: '',
    description: '',
    price: '',
    quantity: '',
    uniqueId: ''
  },
  designSpecificNon_order_invoice: {
    name: '',
    price: '',
    quantity: '',
    uniqueId: ''
  },
  garment: {
    id: null,
    apparel_design_id: null,
    apparel_type: null,
    brand: null,
    product: null,
    color: null,
    sizes: {}, // size: qty
    model: null
  },
  location: {
    common: {
      id: null,
      location: null,
      width: null,
      height: null
    },
    specific: {
      apparel: {
        colors_number: null,
        colors: [
          // {
          //   id: null,
          //   name: '',
          //   hex: ''
          // }
        ],
        has_jumbo_print: false,
        ink_type: null,
        process_addons: [
          // {
          //   key: null,
          //   label: ''
          // }
        ]
      },
      embroidery: {
        depth: null,
        stitches_id: null,
        thread_colors: ''
      }
    }
  },
  finishing: {
    common: {
      id: null,
      name: '',
      original_files_link: '',
      comment: '',
      finishing_option: null
    },
    specific: {},
    related_designs: [],
    items: []
  },
  finishingSpecific: {
    'tag-printing': {
      id: null,
      tag_setups_number: null,
      tag_garments_quantity: null,
      tag_setups_number_cost: null,
      tag_garments_quantity_cost: null,
      tag_width: null,
      tag_height: null,
      remove_manufacturer_tag: true,
      price_code: 'tag_printing_and_removal'
    },
    'heat-pressed-tags': {
      id: null,
      tag_setups_number: null,
      tag_garments_quantity: null,
      tag_setups_number_cost: null,
      tag_garments_quantity_cost: null,
      tag_width: null,
      tag_height: null,
      remove_manufacturer_tag: true,
      price_code: 'heat_pressed_tags'
    },
    'hem-tag': {
      id: null,
      hem_tag: null,
      hem_tag_thread_color: '',
      tag_garments_quantity: null,
      tag_garments_quantity_cost: null,
      price_code: 'hem_tags'
    },
    'hem-tags-fleece': {
      id: null,
      hem_tag: null,
      hem_tag_thread_color: '',
      tag_garments_quantity: null,
      tag_garments_quantity_cost: null,
      price_code: 'hem_tags_fleece'
    },
    'sewn-tag-neck-and-four-sided': {
      id: null,
      sewn_tag_neck_tag_type: '', // "Remove Original Tag|Sew on Top of Original Tag"
      sewn_tag_neck_tag_thread_color: '', // Black
      tag_garments_quantity: null,
      tag_garments_quantity_cost: null,
      price_code: 'sewn_tag_neck_and_four_sided'
    },
    'neck-tags-fleece': {
      id: null,
      sewn_tag_neck_tag_type: '', // "Remove Original Tag|Sew on Top of Original Tag"
      sewn_tag_neck_tag_thread_color: '', // Black
      tag_garments_quantity: null,
      tag_garments_quantity_cost: null,
      price_code: 'neck_tags_fleece'
    },
    'four-sided-tag': {
      id: null,
      tag_garments_quantity: null,
      tag_garments_quantity_cost: null,
      price_code: 'four_sided_tags'
    },
    'folding-and-bagging': {
      id: null,
      folding_type: 'I would like my garments folded & polybagged',
      tag_garments_quantity: null,
      tag_garments_quantity_cost: null,
      price_code: 'folding_and_bagging'
    },
    'folding-only': {
      id: null,
      folding_type: 'I would like my garments folded',
      tag_garments_quantity: null,
      tag_garments_quantity_cost: null,
      price_code: 'folding_only'
    },
    'folding-and-bagging-fleece': {
      id: null,
      folding_type: 'I would like my garments folded & polybagged (Fleece)',
      tag_garments_quantity: null,
      tag_garments_quantity_cost: null,
      price_code: 'folding_and_bagging_fleece'
    },
    'folding-only-fleece': {
      id: null,
      folding_type: 'I would like my garments folded (Fleece)',
      tag_garments_quantity: null,
      tag_garments_quantity_cost: null,
      price_code: 'folding_only_fleece'
    },
    'hang-tag': {
      id: null,
      hang_tag: null,
      tag_garments_quantity: null,
      tag_garments_quantity_cost: null,
      price_code: 'hang_tags'
    },
    'sticker-attachment': {
      id: null,
      sticker_attachment: 'Sticker Attachment',
      tag_garments_quantity: null,
      tag_garments_quantity_cost: null,
      price_code: 'sticker_attachment'
    },
    'woven-neck-tag': {
      id: null,
      woven_neck_tag_type: '', // "Remove Original Tag|Sew on Top of Original Tag"
      woven_neck_tag_thread_color: '', // Black
      tag_garments_quantity: null,
      tag_garments_quantity_cost: null,
      price_code: 'woven_neck_tags'
    },
    'sewn-tag-bottom-or-sleeve': {
      id: null,
      tag_garments_quantity: null,
      tag_garments_quantity_cost: null,
      price_code: 'sewn_tag_bottom_or_sleeve'
    }
  },
  shipping: {
    common: {
      id: null,
      type_of_delivery: 'shipping',
      shipping_method: '03',
      shipping_status: 0,
      items_qty: 0,
      packages_qty: 0,
      default_company_address: false, // for now always false, separate ticket
      customer_account_number: null
    },
    specific: {
      items: [],
      address: {
        id: null,
        address_1: '',
        address_2: '',
        address_3: '',
        city: '',
        country_id: 'US',
        state_id: null,
        postal_code: '',
        recipient: '',
        is_taxed: false
      }
    }
  }
};

export const ESTIMATE_DESIGN_SERVICE_CONFIG = {
  apparel: {
    garments: true,
    locations: true,
    colorsNumber: true,
    depth: false,
    threadColors: false,
    jumbo: true,
    inkType: true,
    processAddons: true,
    addLocation: true,
    otherDetails: true
  },
  promotional: {
    otherDetails: true
  },
  blank: {
    garments: true,
    locations: false,
    colorsNumber: true,
    depth: false,
    threadColors: false,
    jumbo: true,
    inkType: true,
    processAddons: true,
    addLocation: false,
    otherDetails: false
  },
  embroidery: {
    garments: true,
    locations: true,
    colorsNumber: false,
    depth: true,
    threadColors: true,
    jumbo: false,
    inkType: false,
    processAddons: false,
    addLocation: true,
    otherDetails: true
  },
  digital: {
    otherDetails: true,
    printTypes: true
  },
  paper: {
    otherDetails: true,
    printTypes: true
  },
  finishing_creation: {
    otherDetails: true,
    printTypes: true
  },
  sticker: {
    otherDetails: true,
    quantities: true,
    shapes: true
  },
  button: {
    otherDetails: true
  },
  poster: {
    otherDetails: true
  }
};

/**
 * Headers
 */
export const gridHeaders = [
  { head: 'Estimate', subhead: '', sortField: 'id' },
  { head: 'Date', subhead: '', sortField: 'created_at' },
  { head: 'Date Lost', subhead: '', sortField: 'date_lost', sortDir: 'DESC' },
  { head: 'Account/Design', subhead: '' },
  { head: 'CSR', subhead: '' },
  { head: 'Messages', subhead: '' },
  { head: 'Total', subhead: '' },
  { head: 'Status', subhead: '' },
  { head: 'Lost Reason', subhead: '' },
  { head: 'Actions', subhead: '' }
];

/**
 * Headers
 */
export const gridMyEstimatesHeaders = [
  { head: 'Estimate', subhead: '', sortField: 'id' },
  { head: 'Date', subhead: '', sortField: 'created_at' },
  { head: 'Account/Design', subhead: '' },
  { head: 'Messages', subhead: '' },
  { head: 'Total', subhead: '' },
  { head: 'Status', subhead: '' },
  { head: 'Actions', subhead: '' }
];

export const progressFieldsList = [
  'progress_setup',
  'progress_blank',
  'progress_mock',
  'progress_print',
  'progress_shipment',
  'progress_finish'
];

export const activeGridActionHeaders = [
  { head: '', subhead: '' },
  { head: '', subhead: '' }
];

export const unclaimedGridActionHeaders = [{ head: 'Actions', subhead: '' }];

/**
 * Left part data processor
 */
export const getGridData = (
  estimates,
  userRoles,
  actions,
  additionalFields = false
) => {
  return estimates.map((estimate, index) => {
    let hasEvent = false;
    if (!estimate.designs) return;

    estimate.designs.forEach(design => {
      if (!hasEvent) hasEvent = !!design.in_hands_date;
    });
    let accountName =
      estimate.recipient_company ||
      (estimate.user &&
        ((estimate.user.company && estimate.user.company.name) ||
          (estimate.user.first_name &&
            estimate.user.last_name &&
            `${estimate.user.first_name} ${estimate.user.last_name}`) ||
          (estimate.user.first_name && `${estimate.user.first_name}`) ||
          (estimate.user.last_name && `${estimate.user.last_name}`) ||
          estimate.user.title)) ||
      estimate.company ||
      (estimate.contact_firstname &&
        estimate.contact_lastname &&
        `${estimate.contact_firstname} ${estimate.contact_lastname}`) ||
      '';
    accountName =
      accountName.split('').length > 18
        ? `${accountName.substring(0, 18)}...`
        : accountName;

    let notice = null;
    let idString = estimate.id;
    let summary = (estimate && JSON.parse(estimate.estimate)) || {};
    let amount = +(
      +estimate.total_price ||
      (summary &&
        summary.total &&
        +summary.total.price + +summary.total.upcharge) ||
      0
    ).toFixed(2);

    if (
      checkRoles(userRoles, [
        'admin',
        'csr',
        'production-coordinator',
        'purchaser',
        'art-producer'
      ])
    ) {
      idString = `<a href="/#/estimates/${estimate.id}/details">${
        estimate.id
      }</a>`;
    }

    let uM = store.getters.unreadOrdersMessages[estimate.id]
      ? store.getters.unreadOrdersMessages[estimate.id]
      : [];
    let data = [
      { column: 'id', value: estimate.id, isFirst: estimate.is_first, roles: teamMembers},
      { column: 'Estimate', value: idString, notice },
      {
        column: 'Date',
        value: moment(estimate.created_at).format(DATE_FORMAT)
      }
    ];
    if (additionalFields) {
      let lostAt =
        (estimate.lost_order_reason &&
          estimate.lost_order_reason.created_at &&
          moment(estimate.lost_order_reason.created_at).format(DATE_FORMAT)) ||
        ' - ';
      data.push({ column: 'Date Lost', value: lostAt });
    }
    data.push({
      column: 'Account/Design',
      value: accountName,
      alignLeft: false,
      strong: true
    });
    if (estimate.csr && estimate.csr.title) {
      data.push({
        column: 'CSR',
        value: estimate.csr && estimate.csr.title ? estimate.csr.title : ' - '
      });
    } else if (additionalFields) {
      data.push({
        column: 'CSR',
        value: ' - '
      });
    }
    data.push({
      column: 'Messages',
      value: {
        count: uM.length ? uM.length : null,
        isCountDanger: isMentionedMe(uM),
        tag: 'custom-icon',
        str: 'Read',
        customClass: 'ff-lg ff-chatbubbles',
        click: openMessenger
      }
    });
    data.push({
      column: 'Total',
      value: toPriceFormat(amount),
      roles: [
        'admin',
        'csr',
        'production-coordinator',
        'purchaser',
        'art-producer'
      ]
    });
    data.push({
      column: 'Status',
      roles: [
        'admin',
        'csr',
        'production-coordinator',
        'purchaser',
        'art-producer'
      ],
      value: setEstimateStatus(estimate)
    });
    if (additionalFields) {
      data.push({
        column: 'Lost Reason',
        value: estimate.lost_order_reason
          ? {
              tag: 'custom-icon',
              str: 'Show Lost Reason',
              customClass: 'ff-lg ff-info-circled',
              click: actions.showLostReason
            }
          : '-'
      });
    }
    if (actions.sendEstimate && !estimate.lost_order_reason) {
      data.push({
        column: 'Actions',
        roles:  ['admin', 'csr', 'production-coordinator', 'purchaser', 'art-producer'],
        value: {
          tag: 'button',
          str: estimate.estimate_sent_at ? 'Re-Send' : 'Send',
          click: actions.sendEstimate,
          subtagActions: actions.subtag(estimate),
          subtag:
            (actions.subtag(estimate) &&
              actions.subtag(estimate)[0] &&
              actions.subtag(estimate)[0].tag) ||
            ''
        }
      });
    }
    if (actions.claim && !estimate.lost_order_reason) {
      data.push({
        column: 'Actions',
        roles: [
          'admin',
          'csr',
          'production-coordinator',
          'purchaser',
          'art-producer'
        ],
        value: actions.claim
      });
    }
    if (actions.setStatusLost && !estimate.lost_order_reason) {
      data.push({
        column: 'Actions',
        roles: [
          'admin',
          'csr',
          'production-coordinator',
          'purchaser',
          'art-producer'
        ],
        value: {
          tag: 'button',
          subtagActions: actions.subtag(estimate),
          subtag:
            (actions.subtag(estimate) &&
              actions.subtag(estimate)[0] &&
              actions.subtag(estimate)[0].tag) ||
            '',
          str: 'Mark as Lost',
          click: actions.setStatusLost
        }
      });
    }
    if (actions.deleteEstimate && estimate.lost_order_reason) {
      data.push({
        column: 'Actions',
        roles: [
          'admin',
          'csr',
          'production-coordinator',
          'purchaser',
          'art-producer'
        ],
        value: {
          tag: 'button',
          subtagActions: actions.subtag(estimate),
          subtag:
            (actions.subtag(estimate) &&
              actions.subtag(estimate)[0] &&
              actions.subtag(estimate)[0].tag) ||
            '',
          str: 'Delete Estimate',
          customClass: 'button is-small is-danger',
          click: actions.deleteEstimate
        }
      });
    }

    return data;
  });
};

/**
 * Left part details data processor
 */
export const getGridOrderDetails = (estimates, showProgress) => {
  let details = {};
  estimates.forEach(estimate => {
    if (!estimate.designs) return;

    details[estimate.id] = estimate.designs.map(design => {
      let designName = design.name;
      if (design.printed_before_order_id || design.printed_before_design_id) {
        designName += ' (Reprint)';
      } else if (design.reference_order_id || design.reference_design_id) {
        designName += ' (Reference)';
      }

      let designDetails = [
        { column: 'id', value: design.id },
        { column: 'Estimate', value: ' - ' },
        { column: 'Date', value: ' - ' },
        {
          column: 'Account/Design',
          value: designName,
          alignLeft: false
        },
        { column: 'CSR', value: ' - ' }
      ];

      if (showProgress) {
        progressFieldsList.forEach(progressType => {
          let hasError = false;
          if (progressType === 'progress_blank') {
            hasError = +design.blank_status === +BLANK_STATUS_STOCK_ISSUE;
          }
          designDetails.push({
            column: 'Phase',
            value: `<div class="${gridHelper.getProgressIcon(
              design[progressType],
              false,
              hasError
            )}"></div>`
          });
        });
      }

      designDetails.push({
        column: 'Messages',
        value: ' - '
      });

      return designDetails;
    });
  });
  return details;
};

/**
 * active estimates actions
 */
export const unclaimedActionData = (estimates, actions) => {
  return estimates.map(estimate => {
    return [
      {
        column: 'id',
        value: estimate.id
      },
      {
        column: 'Actions',
        roles: [
          'admin',
          'csr',
          'production-coordinator',
          'purchaser',
          'art-producer'
        ],
        value: actions.claim
      }
    ];
  });
};

export const unclaimedOrderActionsDetails = estimates => {
  const details = {};

  estimates.forEach(estimate => {
    if (!estimate.designs) return;

    details[estimate.id] = estimate.designs.map(design => {
      return [{ column: 'id', value: design.id }, { column: '', value: '' }];
    });
  });

  return details;
};

export const getGridUnclaimedFinishingsActions = estimates => {
  const details = {};

  estimates.forEach(estimate => {
    let orderFinishings = {};
    if (!estimate.designs) return;

    estimate.designs.map(design => {
      if (design.designable && design.designable.finishings) {
        design.designable.finishings.forEach(finishing => {
          orderFinishings[finishing.id] = finishing;
        });
      }
    });

    details[estimate.id] = Object.keys(orderFinishings).map(key => {
      return [{ column: 'id', value: orderFinishings[key].id }];
    });
  });

  return details;
};

export const getGridMyEstimatesFinishingsActions = estimates => {
  const details = {};

  estimates.forEach(estimate => {
    let orderFinishings = {};
    if (!estimate.designs) return;

    estimate.designs.map(design => {
      if (design.designable && design.designable.finishings) {
        design.designable.finishings.forEach(finishing => {
          orderFinishings[finishing.id] = finishing;
        });
      }
    });

    details[estimate.id] = Object.keys(orderFinishings).map(key => {
      return [
        { column: 'id', value: orderFinishings[key].id },
        { column: '', value: '' }
      ];
    });
  });

  return details;
};

export const unclaimedOrderActionsFinishings = (estimates, actions) => {
  let finishings = {};
  estimates.forEach(estimate => {
    let orderFinishings = {};
    if (!estimate.designs) return;

    estimate.designs.map(design => {
      if (design.designable && design.designable.finishings) {
        design.designable.finishings.forEach(finishing => {
          orderFinishings[finishing.id] = finishing;
        });
      }
    });

    if (!Object.keys(orderFinishings).length) return;

    // prepare finishings
    if (actions) {
      finishings[estimate.id] = Object.keys(orderFinishings).map(key => {
        return [
          { column: 'id', value: orderFinishings[key].id },
          {
            column: 'Files',
            roles: [
              'admin',
              'csr',
              'production-coordinator',
              'purchaser',
              'art-producer'
            ],
            value: {
              tag: 'button',
              str: '', // Manage files
              click: actions.filesPopup,
              customClass:
                'button btn is-primary btn__circle ff-link icon_smaller'
            }
          },
          { column: 'Printer', value: '' },
          { column: 'Payment', value: '' }
        ];
      });
    } else {
      finishings[estimate.id] = Object.keys(orderFinishings).map(key => {
        return [
          { column: 'id', value: orderFinishings[key].id },
          { column: '', value: '' }
        ];
      });
    }
  });
  return finishings;
};

/**
 * active estimates actions
 */
export const activeActionData = (estimates, actions, unreadMessages) => {
  return estimates.map(estimate => {
    return [
      {
        column: 'id',
        value: estimate.id
      },
      {
        column: 'Actions',
        roles: [
          'admin',
          'csr',
          'production-coordinator',
          'purchaser',
          'art-producer'
        ],
        value: {
          tag: 'button',
          str: 'Mark as Lost',
          click: actions.setStatusLost
        }
      },
      {
        column: 'Actions',
        roles: [
          'admin',
          'csr',
          'production-coordinator',
          'purchaser',
          'art-producer'
        ],
        value: estimate.is_approved
          ? {
              tag: 'button',
              str: 'Convert to Order',
              click: actions.setStatusOrder
            }
          : ''
      }
    ];
  });
};

export const activeOrderActionsDetails = estimates => {
  let details = {};
  estimates.forEach(estimate => {
    if (!estimate.designs) return;

    details[estimate.id] = estimate.designs.map(design => {
      return [
        { column: 'id', value: design.id },
        { column: '', value: '' },
        { column: '', value: '' }
      ];
    });
  });
  return details;
};

export const myOrderActionsDetails = (orders, actions) => {
  let details = {};
  orders.forEach(order => {
    let orderFinishings = {};
    if (!order.designs) return;

    details[order.id] = order.designs.map(design => {
      let fileLinkClass = 'ff-cloud-upload has-text-grey';

      if (design.original_files_link) {
        fileLinkClass = 'ff-link has-text-primary';
      }

      return [
        { column: 'id', value: design.id },
        {
          column: 'Files',
          value: {
            tag: 'button',
            str: '', // Manage files
            click: actions.filesPopup,
            customClass:
              'button btn is-ghost icon icon__bigger ' + fileLinkClass
          }
        },
        { column: '', value: '' },
        { column: '', value: '' }
      ];
    });

    details[order.id].finishings = Object.keys(orderFinishings).map(key => {
      return [
        { column: 'id', value: orderFinishings[key].id },
        {
          column: 'Files',
          roles: [
            'admin',
            'csr',
            'production-coordinator',
            'purchaser',
            'art-producer'
          ],
          value: {
            tag: 'button',
            str: '', // Manage files
            click: actions.filesPopup,
            customClass:
              'button btn is-primary btn__circle ff-link icon_smaller'
          }
        },
        { column: 'Printer', value: '' },
        { column: 'Payment', value: '' }
      ];
    });
  });
  return details;
};

export const getGridOrderFinishings = orders => {
  let allFinishings = {};
  orders.forEach(order => {
    let orderFinishings = {};
    if (!order.designs) return;

    order.designs.map(design => {
      if (!design.designable || !design.designable.finishings) return;

      design.designable.finishings.forEach(finishing => {
        if (orderFinishings[finishing.id]) {
          orderFinishings[finishing.id].designNames += ', ' + design.name;
        } else {
          orderFinishings[finishing.id] = {
            finishing,
            designNames: design.name
          };
        }
      });
    });

    if (!Object.keys(orderFinishings).length) return;

    allFinishings[order.id] = Object.keys(orderFinishings).map(key => {
      let finishing = orderFinishings[key].finishing;
      let data = [
        { column: 'id', value: finishing.id },
        { column: 'Estimate', value: '' },
        { column: 'Date', value: '' },
        {
          column: 'Account/Design',
          value: finishing.label,
          alignLeft: false
        },
        { column: '', value: '' },
        { column: '', value: '' }
      ];

      progressFieldsList.forEach(progressType => {
        let progress = null;
        switch (progressType) {
          case 'progress_print':
            progress = finishing.printer_status * 50;
            break;
          case 'progress_setup':
            progress = !!finishing.original_files_link * 100;
            break;
        }

        data.push({
          column: 'Phase',
          value:
            progress !== null
              ? `<div class="${gridHelper.getProgressIcon(progress)}"></div>`
              : '-'
        });
      });

      data.push({
        column: 'Messages',
        value: ''
      });

      data.push({
        column: '',
        value: ''
      });

      return data;
    });
  });
  return allFinishings;
};

export const getGridUnclaimedEstimatesFinishings = orders => {
  let allFinishings = {};
  orders.forEach(order => {
    let orderFinishings = {};
    if (!order.designs) return;

    order.designs.map(design => {
      if (!design.designable || !design.designable.finishings) return;

      design.designable.finishings.forEach(finishing => {
        if (orderFinishings[finishing.id]) {
          orderFinishings[finishing.id].designNames += ', ' + design.name;
        } else {
          orderFinishings[finishing.id] = {
            finishing,
            designNames: design.name
          };
        }
      });
    });

    if (!Object.keys(orderFinishings).length) return;

    allFinishings[order.id] = Object.keys(orderFinishings).map(key => {
      let finishing = orderFinishings[key].finishing;
      let data = [
        { column: 'id', value: finishing.id },
        { column: 'Estimate', value: '' },
        { column: 'Date', value: '' },
        {
          column: 'Account/Design',
          value: finishing.label,
          alignLeft: false
        },
        { column: 'CSR', value: '' },
        { column: 'Messages', value: '' },
        { column: '', value: '' }
      ];

      return data;
    });
  });
  return allFinishings;
};

export const getGridMyEstimatesFinishings = orders => {
  let allFinishings = {};
  orders.forEach(order => {
    let orderFinishings = {};
    if (!order.designs) return;

    order.designs.map(design => {
      if (!design.designable || !design.designable.finishings) return;

      design.designable.finishings.forEach(finishing => {
        if (orderFinishings[finishing.id]) {
          orderFinishings[finishing.id].designNames += ', ' + design.name;
        } else {
          orderFinishings[finishing.id] = {
            finishing,
            designNames: design.name
          };
        }
      });
    });

    if (!Object.keys(orderFinishings).length) return;

    allFinishings[order.id] = Object.keys(orderFinishings).map(key => {
      let finishing = orderFinishings[key].finishing;
      let data = [
        { column: 'id', value: finishing.id },
        { column: 'Estimate', value: '' },
        { column: 'Date', value: '' },
        {
          column: 'Account/Design',
          value: finishing.label,
          alignLeft: false
        },
        { column: 'CSR', value: '' },
        { head: 'Messages', value: '' }
      ];

      return data;
    });
  });
  return allFinishings;
};

export function setEstimateStatus(estimate) {
  // At first we will check if estimate is declined.
  if (estimate && estimate.is_declined) return STATUS_DECLINED;
  else if (estimate && !estimate.estimate_sent_at)
    // If estimate is not declined and not sent then is a draft.
    return STATUS_DRAFT;
  else if (estimate && estimate.is_revision_requested)
    // If estimate is sent check if "Revision requested" first.
    return STATUS_REVISION_REQUESTED;
  else if (estimate && estimate.is_approved)
    // If revision is not requested check if estimate is approved.
    return STATUS_APPROVED;
  else if (estimate && estimate.is_viewed)
    // If estimate is not approved check if it is viewed.
    return STATUS_VIEWED;
  else if (estimate && estimate.estimate_sent_at)
    // If estimate is not viewed and not approved an revision is not requested and estimate is sent show sent status.
    return STATUS_SENT;
  else
    // Show noting in other cases.
    return ' - ';
}

export function getOptionLabel(optionKey, options, keyField = 'key') {
  if (!options || !optionKey) return optionKey;
  const option = options.find(
    option => option[keyField].toString() === optionKey.toString()
  );
  return option ? option.label : optionKey;
}
