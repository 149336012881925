<template>
  <div v-on-clickaway="away">
    <div class="label" v-if="label">
      <label :for="scope + '-' + name" class="label"><span v-if="required" class="required">*</span>
        {{label}}
      </label>
    </div>
  <div class="autocomplete" :class="{'no-label': !label, 'disabled': disabled}">
    <div
      :class="{'input': true, 'disabled': disabled}" @click="toggleVisible"
      v-text="parentPlaceHolder"
    >
    </div>
    <div class="popover" v-show="visible">
      <input
        :disabled="disabled"
        ref="input"
        :class="{'input': true, 'is-danger': errors.has(scope + '.' + name)}"
        autocomplete="off"
        v-validate="{ rules: rules, scope: scope }"
        :data-vv-name="name"
        :data-vv-as="getLabel"
        :key="scope + '-' + name"
        :id="scope + '-' + name"
        type="text"
        v-model="query"
        :placeholder="placeholder"
        @keydown.up="up"
        @keydown.down="down"
        @keydown.enter.prevent="enter"
        @input="change"
      />
      <div class="options" ref="optionsList">
        <ul>
          <li v-for="(match, index) in matches"
              :key="`${match[filterBy]} - ${index}`"
              @click="onSelect(index)"
              :class="{'selected': index === selected}"
              v-text="match[filterBy]">
          </li>
        </ul>
      </div>
    </div>
  </div>
  </div>
</template>

<script>
import Vue from 'vue';
import bus from '../../../bus';
import { debounce, cloneDeep, isEmpty } from 'lodash';

export default {
  name: 'autocomplete',
  props: {
    searchCallback: {
      type: Function,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    name: {
      type: String,
      required: true
    },
    scope: {
      type: String,
      required: true
    },
    required: {
      type: Boolean,
      default: false
    },
    rules: {
      type: String,
      default: 'max:255'
    },
    fieldsetName: String,
    label: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: 'Start typing...'
    },
    filterBy: {
      type: String | Number,
      required: true
    },
    value: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      itemHeight: 35,
      visible: false,
      selectedItem: !isEmpty(this.value) ? cloneDeep(this.value) : null,
      selected: !isEmpty(this.value) ? 0 : null,
      query: !isEmpty(this.value) ? cloneDeep(this.value[this.filterBy]) : '',
      suggestions: !isEmpty(this.value) ? [cloneDeep(this.value)] : []
    };
  },
  computed: {
    getLabel() {
      //capitalize name
      return this.label
        ? this.label
        : this.name.charAt(0).toUpperCase() + this.name.slice(1);
    },
    //Filtering the suggestion based on the input
    matches() {
      if (this.query === '') {
        return [];
      }
      return this.suggestions.filter(suggestion => {
        return (
          (suggestion[this.filterBy] &&
            suggestion[this.filterBy].toLowerCase().includes(this.query)) ||
          []
        );
      });
    },
    parentPlaceHolder() {
      return this.selectedItem && this.selectedItem[this.filterBy]
        ? this.selectedItem[this.filterBy]
        : this.disabled ? 'No company selected' : 'Select One...';
    },
    //The flag
    openSuggestion() {
      return (
        this.visible === true && this.query !== '' && this.matches.length !== 0
      );
    }
  },
  watch: {
    query(newQuery) {
      if (newQuery === '') {
        this.selectedItem = {};
        this.selected = 0;
        this.suggestions = [];
        this.$emit('selected', cloneDeep(this.selectedItem));
      }
    }
  },
  methods: {
    away() {
      this.visible = false;
    },
    toggleVisible() {
      if (!this.disabled) {
        this.visible = !this.visible;
        if (this.visible) {
          setTimeout(() => {
            this.$refs.input.focus();
          });
        }
      }
    },
    //When one of the suggestion is clicked
    onSelect(index) {
      this.selected = index;
      this.selectItem();
    },
    selectItem() {
      this.selectedItem = this.matches[this.selected];
      this.query = this.matches[this.selected][this.filterBy];
      this.visible = false;

      this.$emit('selected', cloneDeep(this.selectedItem));
    },
    //When enter pressed on the input
    enter() {
      this.selectItem();
    },
    //When up pressed while suggestions are open
    up() {
      console.log('up');
      if (this.selected > 0) {
        this.selected--;
        this.scrollToItem();
      }
    },
    //When up pressed while suggestions are open
    down() {
      console.log('down');
      if (this.selected < this.suggestions.length - 1) {
        this.selected++;
        this.scrollToItem();
      }
    },
    //When the user changes input
    change: debounce(function() {
      if (this.query) {
        this.searchCallback(this.query).then(data => {
          this.suggestions = data;
          if (this.visible === false) {
            this.visible = true;
            this.selected = 0;
          }
        });
      }
    }, 500),
    scrollToItem() {
      this.$refs.optionsList.scrollTop = this.selected * this.itemHeight;
    },
    onValidate(validateScope) {
      if (validateScope) {
        this.$validator.validateAll(validateScope).catch(err => {});
      } else {
        this.$validator.validateScopes().catch(err => {});
      }
    },

    onClear() {
      this.errors.clear(this.scope);
    },

    addError(e) {
      if (
        e.field === this.name &&
        e.scope === this.scope &&
        (!this.fieldsetName ||
          e.fullName === `${this.fieldsetName}.${this.name}`)
      ) {
        this.errors.add(
          e.field,
          e.msg.replace(e.fullName, this.getLabel),
          e.rule,
          e.scope
        );
      }
    }
  },
  created() {
    bus.$on('add-error', this.addError);
    bus.$on('validate', this.onValidate);
    bus.$on('clear', this.onClear);
    this.$watch(
      () => this.errors.errors,
      (value, oldValue) => {
        if (value.length) {
          bus.$emit('errors-changed', value);
        } else {
          bus.$emit('errors-deleted', oldValue);
        }
      }
    );
  },
  beforeDestroy() {
    bus.$emit('errors-deleted', this.errors.errors);
    bus.$off('validate', this.onValidate);
    bus.$off('clear', this.onClear);
    bus.$off('add-error', this.addError);

    this.selectedItem = null;
    this.selected = null;
    this.query = '';
    this.suggestions = [];
  }
};
</script>

<style scoped>
.no-label {
  margin-top: 19px;
}

.autocomplete {
  position: relative;
}

.popover {
  z-index: 9999999;
  background: #fff;
  min-height: 50px;
  border: 2px solid lightgray;
  position: absolute;
  top: 30px;
  left: 0;
  right: 0;
  border-radius: 3px;
  text-align: center;
}

.popover input {
  width: 95%;
  margin-top: 5px;
}

.options {
  max-height: 150px;
  overflow-y: scroll;
  margin-top: 0px;
}

.options ul {
  list-style: none;
  text-align: left;
  padding: 5px;
  margin-top: 0;
}

.options ul li {
  border-bottom: 1px solid #62bbea;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  padding: 8px;
  margin: 3px;
  cursor: pointer;
  background: white;
}

.options ul li:hover {
  background: #62bbea;
  color: #fff;
}

.options ul li.selected {
  background: #1992d1;
  color: #fff;
  border-radius: 3px;
}
.disabled {
  background: #f5f4f4;
}
</style>
