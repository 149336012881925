<template>
  <tr>
    <td colspan="2">
      <invoice-field
        label="Product"
        value="Buttons"
      />
    </td>
    <td>
      <invoice-field
        label="Size"
        :value="formOptions.sizes.find(({ key }) => key === item.size).label"
      />
    </td>
    <td>
      <invoice-field
        label="Quantity"
        :value="formOptions.quantities.find(({ key }) => key === item.quantity).label | intlNumber"
      />
    </td>
    <td>
      <invoice-field
        label="Price"
        :value="item.cost/formOptions.quantities.find(({ key }) => key === item.quantity).label | price"
      />
    </td>
  </tr>
</template>
<script>
import InvoiceField from '@/components/common/invoice-field.vue';

export default {
  name: 'ButtonInvoiceItem',
  components: {InvoiceField},
  props: {
    item: {
      type: Object,
      required: true
    },
    formOptions: {
      type: Object,
      required: true
    },
    totalPrice:
    {
      type: String,
      required: true
    }
  },
}
</script>
