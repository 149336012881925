import apiService from '../../services/api.service';
import notify from '../../modules/notifier';
import { progressFieldsList } from '../../helpers/orders';
import { mergeWith, cloneDeep } from 'lodash';
import {
  JUMBO_PRINT_WIDTH,
  JUMBO_PRINT_HEIGHT
} from '@/helpers/design-location';

import {
  LOAD_ORDER,
  LOAD_CUSTOMER_ORDER,
  LOAD_ORDER_OPTIONS,
  UPDATE_DESIGN_FOLLOW_UP_STATUS,
  SET_SURVEY_DATE,
  SET_MOCK_DATE,
  SET_ORDER_PHASE,
  UPDATE_ORDER_PAYMENT_STATUS,
  UPDATE_PRODUCTION_NOTES,
  UPDATE_DESIGN_BLANK_STATUS,
  UPDATE_ORDER_DESIGN_PRINTER,
  UPDATE_ORDER_PRINTER_STATUS,
  LOAD_ORDER_TO_EDIT,
  UPDATE_ORDER_TO_EDIT,
  LOAD_ORDER_SERVICES,
  LOAD_UPS_SERVICES,
  LOAD_EDIT_ORDER_INIT_DATA,
  LOAD_PMS_COLORS,
  UPDATE_ORDER_DESIGN_SETUP_PROGRESS,
  UPDATE_ORDER_DESIGN_BLANK_PROGRESS,
  UPDATE_ORDER_DESIGN_MOCK_PROGRESS,
  UPDATE_ORDER_DESIGN_PRINT_PROGRESS,
  UPDATE_ORDER_DESIGN_SHIPMENT_PROGRESS,
  UPDATE_ORDER_DESIGN_FINISH_PROGRESS,
  UPDATE_ORDER_DESIGN_PROGRESS,
  UPDATE_ORDER_FINISHING_PROGRESS,
  RESUBSCRIBE_PUBNUB,
  UNCLAIM,
  UPDATE_DESIGN_MOCK_STATUS,
  UPDATE_ORDER_PRINT_BY_AND_SHIP_BY_DATES,
  UPDATE_ORDER_DESIGN_PRINT_BY_AND_SHIP_BY_DATES,
  SET_ORDER_APPROVE_STATUS,
  LOAD_EDIT_PUBLIC_ESTIMATE_INIT_DATA
} from '../action-types';
import {APPAREL_SERVICE_CODE, EMBROIDERY_SERVICE_CODE} from '@/store/service-types';

const errorNotify = er => {
  console.log(er);
  notify({
    message: 'Something wrong',
    type: 'danger'
  });
};

const orderDetails = {
  state: {
    order: {},
    order_summary: {},
    order_phase: 'S',
    order_loading: false,
    order_follow_up_statuses: [],
    order_to_edit: {},
    order_to_edit_loading: false,
    order_services: [],
    delivery_types: [],
    order_countries: [],
    order_finishings: [],
    orderOptions: {},
    ups_services: [],
    pms_colors: [],
    extra: {}
  },
  getters: {
    jumbo: state => {
      return mergeWith(
        {},
        state.extra.jumbo,
        {
          jumbo_print_width: JUMBO_PRINT_WIDTH,
          jumbo_print_height: JUMBO_PRINT_HEIGHT
        },
        (objValue, srcValue) => objValue || srcValue
      );
    },
    orderDetails: state => {
      return state.order.id ? [state.order] : [];
    },
    orderDetailsSummary: state => {
      return state.order_summary;
    },
    orderDetailsLoading: state => {
      return state.order_loading;
    },
    orderDetailsPhase: state => {
      return state.order_phase;
    },
    orderToEdit: state => {
      return state.order_to_edit;
    },
    orderToEditLoading: state => {
      return state.order_to_edit_loading;
    },
    orderServices: state => {
      return state.order_services;
    },
    deliveryTypes: state => {
      return state.delivery_types;
    },
    orderCountries: state => {
      return state.order_countries;
    },
    orderFinishings: state => {
      return state.order_finishings;
    },
    upsServices: state => {
      return state.ups_services;
    },
    pmsColors: state => {
      return state.pms_colors;
    },
    orderDetailsOptions(state) {
      return state.orderOptions;
    }
  },
  mutations: {
    setOrder(state, order) {
      state.order = order;
      if (order && order.estimate) {
        state.order_summary = JSON.parse(order.estimate);
      }
    },
    setOrderOptions(state, options) {
      let opts = { ...state.orderOptions };
      opts[options.type] = options.form_data;
      state.orderOptions = opts;
    },
    updateOrderPaymentStatus(state, status) {
      state.order = { ...state.order, payment_status: status };
    },
    updateOrderProductionNotes(state, data) {
      if (data.entity === 'user') {
        state.order.user.production_notes = data.message;
      } else if (data.entity === 'company') {
        state.order.user.company.production_notes = data.message;
      }
      state.order = { ...state.order };
    },
    updateDesignPrinterStatusForCurrentOrder(state, { designId, status }) {
      if (state.order.designs) {
        state.order.designs
          .filter(design => design.id === designId)
          .forEach(design => {
            design.printer_status = status;
          });
        state.order = { ...state.order };
      }
    },
    updateDesignBlankStatus(state, { status, designId }) {
      let designs = state.order.designs.map(d => {
        if (d.id === designId) {
          d.blank_status = status;
        }
        return d;
      });
      state.order = { ...state.order, designs };
    },
    updateDesignMockStatus(state, { mock_id, status, designId }) {
      let designs = state.order.designs.map(d => {
        if (d.id === designId) {
          d.mocks.map(m => {
            if (m.id === mock_id) {
              m.status = status;
            }
            return m;
          });
        }
        return d;
      });
      state.order = { ...state.order, designs };
    },
    updateOrderDesignPrinter(state, { designId, printer }) {
      let designs = state.order.designs.map(d => {
        if (d.id === designId) {
          d.printer = printer;
          let printerId = printer == null ? null : printer.id;
          d.printer_id = printerId;
        }
        return d;
      });
      state.order = { ...state.order, designs };
    },
    setOrderPhase(state, phase) {
      state.order_phase = phase;
    },
    setSurveyDate(state, orderId) {
      state.order.survey_sent_at = true;
    },
    setMockDate(state, { orderId, designId, sent }) {
      state.order.designs = state.order.designs.map(d => {
        if (d.id === designId) {
          d.mock_sent_at = sent;
          if (d.mocks.length && d.mocks[d.mocks.length - 1]) {
            d.mocks[d.mocks.length - 1].is_sent = sent; // Change "is_sent" attribute for a last mock after it is sent to the customer.
          }
        }
        return d;
      });
    },
    setLoading(state, val) {
      state.order_loading = val;
      state.order_to_edit_loading = val;
    },
    updateDesignFollowUpStatus(state, { designId, status, newDesign }) {
      let designs = state.order.designs.map(d => {
        if (d.id === designId) {
          d.followup_status = status;
        }
        return d;
      });
      if (status == 2 && newDesign) {
        designs.push(newDesign);
      }
      state.order = { ...state.order, designs };
    },
    setOrderToEdit(state, order) {
      order.designs.forEach(design => {
        design.designable = design.designable || {};
        design.designable.garments = design.designable.garments || [];
        design.designable.locations = design.designable.locations || [];
        design.designable.finishings = design.designable.finishings || [];
      });
      state.order_to_edit = order;
      state.order_summary = JSON.parse(order.estimate);
    },
    setOrderServices(state, services) {
      state.order_services = services;
    },
    setDeliveryTypes(state, types) {
      state.delivery_types = types;
    },
    setOrderCountries(state, countries) {
      state.order_countries = (countries || []).sort(function(a, b) {
        return a.order > b.order ? 1 : b.order > a.order ? -1 : 0;
      });
    },
    setOrderFinishings(state, finishings) {
      state.order_finishings = finishings;
    },
    setExtraFields(state, extraFields) {
      state.extra = extraFields;
    },
    setUpsServices(state, upsServices) {
      state.ups_services = upsServices;
    },
    setPmsColors(state, pmsColors) {
      state.pms_colors = pmsColors;
    },
    updateOrderDesignPhaseProgress(
      state,
      { designId, progressName, progress }
    ) {
      state.order.designs = state.order.designs.map(design => {
        if (design.id === designId) {
          design[progressName] = progress;
        }
        return design;
      });
    },
    updateOrderDesignProgress(state, { designId, progress }) {
      state.order.designs = state.order.designs.map(design => {
        if (+design.id === +designId) {
          progressFieldsList.forEach(progressName => {
            if (progressName in progress) {
              design[progressName] = progress[progressName];
            }
            if (design.original_files_link !== progress.original_files_link)
              design.original_files_link = progress.original_files_link;
          });
        }
        return design;
      });
    },
    updateOrderFinishingProgress(state, { finishingId, progress }) {
      state.order.designs = state.order.designs.map(d => {
        d.designable.finishings = d.designable.finishings.map(f => {
          if (f.id === finishingId) {
            f.original_files_link = progress.original_files_link;
            f.print_ready_files_link = progress.print_ready_files_link;
            f.printer_status = progress.printer_status;
          }
          return f;
        });
        return d;
      });
    },
    unclaimModel(state, subject) {
      state.order[`claim_${subject}_user_id`] = null;
      state.order[`${subject}_claimed_at`] = null;
    },
    updateOrderDesignPrintByAndShipByDates(state, { designId, dates }) {
      state.order.designs =
        state.order.designs &&
        state.order.designs.map(d => {
          if (designId === d.id) {
            d = { ...d, ...dates };
          }
          return d;
        });
      state.order_summary.designs =
        (state.order_summary &&
          state.order_summary.designs &&
          state.order_summary.designs.map(d => {
            if (designId === d.id) {
              d = { ...d, ...dates };
            }
            return d;
          })) ||
        [];
    },
    updateOrderPrintByAndShipByDates(state, { orderId, dates }) {
      if (state.order.id === orderId) {
        state.order = { ...state.order, ...dates };
        state.order_summary = { ...state.order_summary, ...dates };
      }
    },
    setDesignTrackings(state, { orderId, designId, trackings }) {
      if (state.order && +state.order.id === +orderId && state.order.designs) {
        state.order.designs = state.order.designs.map(d => {
          if (+d.id === designId) {
            d.trackings = trackings;
          }
          return d;
        });
      }
    },
    setOrderApproveStatus(state, { orderId, status }) {
      if (state.order && +state.order.id === +orderId) {
        state.order.is_approved = status;
      }
    }
  },
  actions: {
    [LOAD_ORDER_OPTIONS]: ({ commit }, type) => {
      apiService.getDesignFormOptions(type).then(data => {
        commit('setOrderOptions', data);
      });
    },
    [LOAD_ORDER]: ({ commit, dispatch }, id) => {
      commit('setLoading', true);
      return apiService.getOrder(id).then(data => {
        let order = cloneDeep(data.data);
        order.designs = order.designs.map(design => {
          if ([APPAREL_SERVICE_CODE, EMBROIDERY_SERVICE_CODE].includes(design.service)) {
            design.designable.finishings = [];
            order.finishings
              .filter(finishing => {
                return finishing.items.find(
                  ({ design_id }) => design_id === design.id
                );
              })
              .forEach(finishing =>
                design.designable.finishings.push(cloneDeep(finishing))
              );
          }
          return design;
        });
        const services = order.designs
          .map(({ service }) => service)
          .filter((value, index, self) => self.indexOf(value) === index);
        services.forEach(service => {
          dispatch(LOAD_ORDER_OPTIONS, service);
        });
        commit('setOrder', order);
        commit('setLoading', false);
      });
    },
    [LOAD_CUSTOMER_ORDER]: ({ commit, dispatch }, id) => {
      commit('setLoading', true);
      return apiService.getCustomerOrder(id).then(data => {
        const services = data.data.designs
          .map(({ service }) => service)
          .filter((value, index, self) => self.indexOf(value) === index);
        services.forEach(service => {
          dispatch(LOAD_ORDER_OPTIONS, service);
        });
        commit('setOrder', data.data);
        commit('setLoading', false);
      });
    },
    [UPDATE_ORDER_PAYMENT_STATUS]: ({ commit }, { orderId, status }) => {
      return apiService
        .updateOrderPaymentStatus(status, orderId)
        .then(data => {
          commit('updateOrderPaymentStatus', status);
          notify({
            message: data.message,
            type: data.status
          });
        })
        .catch(errorNotify);
    },
    [UPDATE_PRODUCTION_NOTES]: ({ commit, dispatch }, { id, data }) => {
      return apiService
        .updateOrderProductionNotes(id, data)
        .then(response => {
          if (!data.isEstimate) {
            commit('updateOrderProductionNotes', data);
          }
          notify({
            message: response.message,
            type: response.status
          });
        })
        .catch(errorNotify);
    },
    [UPDATE_DESIGN_BLANK_STATUS]: (
      { commit, dispatch },
      { designId, status }
    ) => {
      return apiService
        .updateDesignBlankStatus(status, designId)
        .then(data => {
          commit('updateDesignBlankStatus', { status, designId });
          notify({
            message: data.message,
            type: data.status
          });
          dispatch(UPDATE_ORDER_DESIGN_PROGRESS, {
            designId: data.design.id,
            progress: data.design
          });
          //if ('designs' in data.order) {
          //  data.order.designs.map((design) => {
          //    dispatch(UPDATE_ORDER_DESIGN_PROGRESS, {
          //      designId: design.id,
          //      progress: design
          //    });
          //  });
          //}
        })
        .catch(errorNotify);
    },
    [UPDATE_ORDER_DESIGN_PRINTER]: (
      { commit, dispatch },
      { designId, printer, design }
    ) => {
      commit('updateOrderDesignPrinter', { designId, printer });
      if (design) {
        dispatch(UPDATE_ORDER_DESIGN_PROGRESS, { designId, progress: design });
      }
    },
    [UPDATE_DESIGN_FOLLOW_UP_STATUS]: (
      { commit, dispatch },
      { designId, status, design, newDesign }
    ) => {
      commit('updateDesignFollowUpStatus', { designId, status, newDesign });
      if (design) {
        dispatch(UPDATE_ORDER_DESIGN_PROGRESS, {
          designId,
          progress: design
        });
      }
    },
    [UPDATE_ORDER_PRINTER_STATUS]: (
      { commit, dispatch },
      { orderId, itemId, status, modelType, model }
    ) => {
      if (modelType == 'design') {
        commit('updateDesignPrinterStatusForCurrentOrder', {
          orderId,
          designId: itemId,
          status
        });
      }

      if (model) {
        switch (modelType) {
          case 'design':
            dispatch(UPDATE_ORDER_DESIGN_PROGRESS, {
              designId: itemId,
              progress: model
            });
            break;
          case 'finishing':
            dispatch(UPDATE_ORDER_FINISHING_PROGRESS, {
              finishingId: itemId,
              progress: model
            });
            break;
        }
      }
    },
    [SET_ORDER_PHASE]: ({ commit }, phase) => {
      commit('setOrderPhase', phase);
    },
    [SET_SURVEY_DATE]: ({ commit }, orderId) => {
      commit('setSurveyDate', orderId);
    },
    [SET_MOCK_DATE]: ({ commit }, { orderId, designId, sent }) => {
      commit('setMockDate', { orderId, designId, sent });
    },
    [LOAD_ORDER_TO_EDIT]: ({ commit }, { orderId }) => {
      commit('setLoading', true);
      return apiService
        .getOrderToEdit(orderId)
        .then(
          ({
            services,
            countries,
            finishings,
            payment_terms,
            order,
            delivery_types
          }) => {
            commit('setOrderServices', services);
            commit('setDeliveryTypes', delivery_types);
            commit('setOrderCountries', countries);
            commit('setPaymentTerms', payment_terms);
            commit('setOrderFinishings', finishings);
            commit('setOrderToEdit', order);
            commit('setLoading', false);
          }
        );
    },
    [UPDATE_ORDER_TO_EDIT]: ({ commit }, { orderId, data }) => {
      commit('setLoading', true);
      return apiService.saveOrder(orderId, data).then(response => {
        commit('setOrderToEdit', response.data);
        commit('setLoading', false);
      });
    },
    [LOAD_ORDER_SERVICES]: ({ commit, getters }) => {
      if (getters.orderServices.length) {
        return Promise.resolve(getters.orderServices);
      }
      return apiService.getOrderServices().then(data => {
        commit('setOrderServices', data);
      });
    },
    [LOAD_UPS_SERVICES]: ({ commit, getters }) => {
      if (getters.upsServices.length) {
        return Promise.resolve(getters.upsServices);
      }
      return apiService.getUPSServices().then(data => {
        commit('setUpsServices', data);
      });
    },
    [LOAD_EDIT_ORDER_INIT_DATA]: ({ commit }, orderId) => {
      return apiService
        .getFormDataToEditOrder(orderId)
        .then(
          ({
            services,
            countries,
            finishings,
            payment_terms,
            delivery_types,
            extra
          }) => {
            commit('setOrderServices', services);
            commit('setDeliveryTypes', delivery_types);
            commit('setOrderCountries', countries);
            commit('setPaymentTerms', payment_terms);
            commit('setOrderFinishings', finishings);
            commit('setExtraFields', extra);
          }
        );
    },
    [LOAD_EDIT_PUBLIC_ESTIMATE_INIT_DATA]: ({ commit }) => {
      return apiService
        .getFormDataToEditPublicEstimate()
        .then(
          ({
            services,
            countries,
            finishings,
            payment_terms,
            delivery_types,
            extra
          }) => {
            commit('setOrderServices', services);
            commit('setDeliveryTypes', delivery_types);
            commit('setOrderCountries', countries);
            commit('setPaymentTerms', payment_terms);
            commit('setOrderFinishings', finishings);
            commit('setExtraFields', extra);
          }
        );
    },
    [LOAD_PMS_COLORS]: ({ commit, getters }) => {
      if (getters.pmsColors.length) {
        return Promise.resolve(getters.pmsColors);
      }
      return apiService.getPmsColors().then(data => {
        commit('setPmsColors', data);
      });
    },
    [UPDATE_ORDER_DESIGN_SETUP_PROGRESS]: (
      { commit },
      { designId, progress }
    ) => {
      commit('updateOrderDesignPhaseProgress', {
        designId,
        progressName: 'progress_setup',
        progress
      });
    },
    [UPDATE_ORDER_DESIGN_BLANK_PROGRESS]: (
      { commit },
      { designId, progress }
    ) => {
      commit('updateOrderDesignPhaseProgress', {
        designId,
        progressName: 'progress_blank',
        progress
      });
    },
    [UPDATE_ORDER_DESIGN_MOCK_PROGRESS]: (
      { commit },
      { designId, progress }
    ) => {
      commit('updateOrderDesignPhaseProgress', {
        designId,
        progressName: 'progress_mock',
        progress: progress
      });
    },
    [UPDATE_ORDER_DESIGN_PRINT_PROGRESS]: (
      { commit },
      { designId, progress }
    ) => {
      commit('updateOrderDesignPhaseProgress', {
        designId,
        progressName: 'progress_print',
        progress
      });
    },
    [UPDATE_ORDER_DESIGN_SHIPMENT_PROGRESS]: (
      { commit },
      { designId, progress }
    ) => {
      commit('updateOrderDesignPhaseProgress', {
        designId,
        progressName: 'progress_shipment',
        progress
      });
    },
    [UPDATE_ORDER_DESIGN_FINISH_PROGRESS]: (
      { commit },
      { designId, progress }
    ) => {
      commit('updateOrderDesignPhaseProgress', {
        designId,
        progressName: 'progress_finish',
        progress
      });
    },
    [UPDATE_ORDER_DESIGN_PROGRESS]: ({ commit }, { designId, progress }) => {
      commit('updateOrderDesignProgress', {
        designId,
        progress
      });
    },
    [UPDATE_ORDER_FINISHING_PROGRESS]: (
      { commit },
      { finishingId, progress }
    ) => {
      commit('updateOrderFinishingProgress', {
        finishingId,
        progress
      });
    },
    [UNCLAIM]: ({ commit, dispatch }, { orderId, subject, vue }) => {
      return apiService
        .unclaim(orderId, subject)
        .then(data => {
          if (data.status === 'success') {
            notify({
              message: data.message,
              type: 'success'
            });
            dispatch(RESUBSCRIBE_PUBNUB, vue);
            commit('unclaimModel', subject);
          } else {
            notify({
              message: data.message,
              type: 'danger'
            });
          }
        })
        .catch(errorNotify);
    },
    [UPDATE_DESIGN_MOCK_STATUS]: (
      { commit },
      { mock_id, status, designId }
    ) => {
      commit('updateDesignMockStatus', { mock_id, status, designId });
    },
    [UPDATE_ORDER_PRINT_BY_AND_SHIP_BY_DATES]: (
      { commit },
      { orderId, dates }
    ) => {
      commit('updateOrderPrintByAndShipByDates', { orderId, dates });
    },
    [UPDATE_ORDER_DESIGN_PRINT_BY_AND_SHIP_BY_DATES]: (
      { commit },
      { designId, dates }
    ) => {
      commit('updateOrderDesignPrintByAndShipByDates', { designId, dates });
    },
    [SET_ORDER_APPROVE_STATUS]: ({ commit }, { orderId, status }) => {
      commit('setOrderApproveStatus', { orderId, status });
    }
  }
};

export default orderDetails;
