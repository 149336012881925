// todo: remove
export const getDesignTotal = (design, posterDesignData) => {
  let designTotal = 0;
  let posterItem = design.specific.item;

  // Get posterItem qty.
  let quantities = posterDesignData.quantities;
  let quantityOption = quantities.filter(
    qty => qty.key === posterItem.quantity
  )[0];
  let quantity = (quantityOption && parseInt(quantityOption.label)) || 0;

  // Calculate poster item cost.
  let posterItemCost = getPosterItemCost(
    posterItem,
    quantity,
    posterDesignData
  );

  designTotal += quantity * +posterItemCost;

  // Add ink type upcharge.
  if (
    posterItem.ink_type_cost !== undefined &&
    posterItem.ink_type_cost !== null
  ) {
    designTotal += +posterItem.ink_type_cost;
  } else {
    let inkTypes = posterDesignData.ink_types;
    let inkOption = inkTypes.filter(ink => ink.key === posterItem.ink_type)[0];
    if (inkOption && inkOption.upcharge) {
      let inkUpcharge = parseFloat(inkOption.upcharge);
      designTotal += +inkUpcharge;
    }
  }

  return designTotal + +design.common.upcharge;
};

export const getPosterItemCost = (posterItem, quantity, posterDesignData) => {
  let cost = 0;

  let isCustomSizeSelected = posterItem.size === 'empty_size';

  // Prepare upcharges by design options.
  let sizes = posterDesignData.sizes;
  let sizeOption = sizes.filter(size => size.key === posterItem.size)[0];

  if (!isCustomSizeSelected && sizeOption) {
    // Return original cost if exists.
    if (
      posterItem &&
      posterItem.cost !== undefined &&
      posterItem.cost !== null
    ) {
      return +posterItem.cost;
    }

    let colorNumUpcharges =
      sizeOption.color_num_upcharges[posterItem.number_of_colors];
    let colorNumUpchargeRate = 0;
    let weightUpchargeRate =
      parseFloat(sizeOption.weight_upcharges[posterItem.paper_weight]) || 0;

    if (colorNumUpcharges) {
      let rangeRate = Object.keys(colorNumUpcharges)
        .filter(range => {
          let adges = range.toString().split('-');
          let from = Number(adges[0]);
          let to = Number(adges[1]);

          return !isNaN(to)
            ? quantity >= from && quantity <= to
            : quantity >= from;
        })
        .map(range => {
          return parseFloat(colorNumUpcharges[range]);
        })[0];

      colorNumUpchargeRate = rangeRate || 0;
    }

    cost = +colorNumUpchargeRate + +weightUpchargeRate;

    cost = +cost.toFixed(2);
  } else if (isCustomSizeSelected) {
    cost = +posterItem.custom_unit_price;
  }

  return cost;
};
