<template>
  <div>
    <div class="columns">
      <div class="column is-4 input-cntr">
        <label class="label" for="date_from"  style="margin-bottom: 0">From</label>
        <div class="control">
          <datepicker
            v-model="filters.date_from"
            :config="{dateFormat: 'm/d/Y', wrap: true }"
            id="date_from"
          />
        </div>
      </div>
      <div class="column is-4 input-cntr">
        <label class="label" for="date_to" style="margin-bottom: 0">To</label>
        <div class="control">
          <datepicker
            v-model="filters.date_to"
            :config="{dateFormat: 'm/d/Y', wrap: true }"
            id="date_to"
          />
        </div>
      </div>
    </div>
    <div class="progress" style="margin-top: 10px; margin-bottom: 10px">
      <div class="determinate" :style='`width: ${progress}%`'></div>
    </div>
    <div class="row" v-if="message">
      <div class="col s6 offset-s3 m6 offset-m3">
        <div class="card-panel">
          <h5 class="center-align">{ message != null ? message : ''}</h5>
        </div>
      </div>
    </div>
    <button @click="run()" :disabled="inProgress" class="button is-pulled-right">
      Export Report
    </button>
  </div>
</template>
<script>
import apiService from '../../../services/api.service';
import alertify from 'alertify.js';
import Datepicker from '../../../modules/datepicker/index';
import {downloadReport} from '@/helpers/reports/reports';

export default {
  name: 'designs-report',
  data() {
    return {
      filters: {
        page: 1,
        date_to: '',
        date_from: ''
      },
      cancel: false,
      inProgress: false,
      message: '',
      data: [],
      json_fields: [
        {
          label: 'Order Number',
          value: 'order_id'
        },
        {
          label: 'Design',
          value: 'design_name'
        },
        {
          label: 'Total Quantity',
          value: 'total_quantity'
        }
      ],
      progress: 0,
      downloadName: 'designs'
    };
  },
  components: {
    Datepicker
  },
  computed: {},
  methods: {
    run() {
      if (!this.cancel) {
        this.$set(this, 'inProgress', true);
        apiService
          .getReport('order_designs', this.filters)
          .then(res => {
            this.$set(this, 'progress', +res.to / +res.total * 100);
            if (res.data.length) {
              this.$set(this, 'data', [...this.data, ...res.data]);
            }
            if (+res.to >= +res.total) {
              this.$set(this, 'inProgress', false);
              this.download();
            } else {
              this.$set(this.filters, 'page', res.current_page + 1);
              this.run();
            }
          })
          .catch(err => {
            console.log(err);
            this.$set(this.filters, 'page', 1);
            this.$set(this, 'inProgress', false);
            alertify.error(err.body.message);
          });
      }
    },
    download() {
      let maxLocationsCount = 0;

      let newData = this.data.map(design => {
        let designDetails = {
          order_id: design.order_id,
          design_name: design.name,
          total_quantity: design.total_quantity
        };
        if (design.designable.locations) {
          if (maxLocationsCount < design.designable.locations.length) {
            maxLocationsCount = design.designable.locations.length;
          }
          design.designable.locations.forEach((location, key) => {
            designDetails[`location_${key}_colors`] = location.colors_number;
          });
        }

        return designDetails;
      });
      let additional_fields = [];

      for (let i = 0; i < maxLocationsCount; i++) {
        additional_fields.push({
          label: `# of Colors for Location ${i + 1}`,
          value: `location_${i}_colors`
        });
      }

      downloadReport(newData, this.json_fields.concat(additional_fields),
        `${this.downloadName}_${this.filters.date_from}_${this.filters.date_to}.csv`
      );

      this.$set(this.filters, 'page', 1);
      this.$set(this, 'progress', 0);
      this.$set(this, 'data', []);
    }
  },
  mounted() {
  },
  beforeDestroy() {
    this.$set(this, 'cancel', true);
  }
};
</script>
<style scoped>
.progress {
  background: aliceblue;
}

.determinate {
  background: #1992d1;
  height: 12px;
}
</style>
