<template>
  <div class="card is-fullwidth production-notes">
    <header class="card-header">
      <p class="card-header-title">Production Notes</p>
    </header>
    <div class="card-content">
      <div>
        <ul v-if="designPrintersCodes && designPrintersCodes.length > 0">
          <li>
           <b class="subtitle is-6 has-text-weight-bold">CSR:</b>&nbsp;<span v-if="csrFullNameName">{{ csrFullNameName }}</span>
          </li>
          <li>
           <b class="subtitle is-6 has-text-weight-bold">Mocks:</b>&nbsp;<span v-if="artProducerFullName">{{ artProducerFullName }}</span>
          </li>
            <li><b class="subtitle is-6 has-text-weight-bold">Printer(s):</b>&nbsp;<span v-if="availablePrinters"><span v-for="(code) in availablePrinters.printers">{{code + ' '}}</span></span></li>
        </ul>

        <span class="has-text-weight-bold small-padding">Last 5 jobs</span>
        <ul v-if="latestOrders.length">
          <li v-for="order in latestOrders">
            <router-link class="link" :to="'/orders/' + order.id">#{{ order.id }}</router-link>
            <span v-if="order.printers">({{order.printers}})</span>
            <small v-else>Printers not assigned.</small>
          </li>
        </ul>
        <p class="paragraph" v-else>This is the first order for this customer.</p>
      </div>

      <div v-if="referenceDesigns.length" >
        <h5 class="subtitle is-6 has-text-weight-bold">Reference jobs</h5>
        <ul>
          <li v-for="design in referenceDesigns">
            <router-link class="link" :to="`/orders/${design.order_id}/details/design-${design.id}`">#{{ design.order_id }}
              - Design #{{ design.number }}
            </router-link>
          </li>
        </ul>
      </div>

      <hr>

      <div v-roles="['admin','csr','production-coordinator','purchaser','art-producer']">
        <div v-if="isCompany">
          <div class="flex flex__horizontal-cols cols-flex">
            <div>
              <h5 class="subtitle is-6 has-text-weight-bold">Company Notes</h5>
            </div>
            <div>
              <button @click="openNotesPopup('company')">
                <i class="icon icon__bigger ff-compose" v-if="notes.company"></i>
                <i class="icon icon__bigger ff-plus-circle" v-else></i>
              </button>
            </div>
          </div>
          <div v-if="notes.company">
            <p class="content-pre paragraph">{{ notes.company }}</p>
          </div>
          <br>
        </div>

        <div>
          <div class="flex flex__horizontal-cols cols-flex">
            <div>
              <h5 class="subtitle is-6 has-text-weight-bold">Customer Notes</h5>
            </div>
            <div>
              <button @click="openNotesPopup('user')">
                <i class="icon icon__bigger ff-compose" v-if="notes.user"></i>
                <i class="icon icon__bigger ff-plus-circle" v-else></i>
              </button>
            </div>
          </div>

          <div v-if="notes.user">
            <p class="content-pre paragraph">{{ notes.user }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.icon-btn {
  padding: 2px;
}
.small-padding {
  display: inline-block;
  padding-top: 15px !important;
  padding-bottom: 10px !important;
}
.paragraph {
  font-size: 0.9rem;
}
</style>

<script>
import Vue from 'vue';
import bus from '../../../bus';

import ProductionNotesPopup from '../popups/add-production-nodes';
import popup from '../../../modules/popupper';

import apiService from '../../../services/api.service';
import alertify from 'alertify.js';
import { isEqual, sortedUniq } from 'lodash';

export default {
  name: 'production-notes',
  props: ['id', 'orderDetails'],
  data() {
    return {
      latestOrders: [],
      referenceDesigns: []
    };
  },
  computed: {
    accountId() {
      return this.orderDetails[0].account_id;
    },
    notes() {
      let acc = this.orderDetails[0].user ? this.orderDetails[0].user : null;
      return {
        user: acc ? acc.production_notes : '',
        company: acc && acc.company ? acc.company.production_notes : ''
      };
    },
    isCompany() {
      let acc = this.orderDetails[0].user ? this.orderDetails[0].user : null;
      return !!(acc && acc.company);
    },
    ids() {
      let acc = this.orderDetails[0].user ? this.orderDetails[0].user : null;
      return {
        user: acc ? acc.id : '',
        company: acc && acc.company ? acc.company.id : ''
      };
    },
    designPrintersCodes() {
      return (
        (this.orderDetails &&
          this.orderDetails[0] &&
          this.orderDetails[0].designs &&
          this.orderDetails[0].designs.map((design, index) => {
            return {
              designNumber: index + 1,
              printerCode: design.printer && design.printer.code
            };
          })) ||
        []
      );
    },
    availablePrinters() {
      let obj = {};
      if (
        this.orderDetails &&
        this.orderDetails[0] &&
        this.orderDetails[0].designs
      ) {
        let allPrinters = this.orderDetails[0].designs.map(
          design => design.printer && design.printer.code
        );

        allPrinters.forEach(element => {
          if (!element) {
            return false;
          }

          obj[element] = true;
        });
      }
      return {
        printers: Object.keys(obj)
      };
    },
    csrFullNameName() {
      return (
        this.orderDetails &&
        this.orderDetails[0] &&
        this.orderDetails[0].csr &&
        this.orderDetails[0].csr.first_name &&
        this.orderDetails[0].csr.last_name &&
        `${this.orderDetails[0].csr.first_name} ${
          this.orderDetails[0].csr.last_name
        }`
      );
    },
    artProducerFullName() {
      return (
        this.orderDetails &&
        this.orderDetails[0] &&
        this.orderDetails[0].designer &&
        this.orderDetails[0].designer.first_name &&
        this.orderDetails[0].designer.last_name &&
        `${this.orderDetails[0].designer.first_name} ${
          this.orderDetails[0].designer.last_name
        }`
      );
    }
  },
  methods: {
    openNotesPopup(type) {
      popup({
        title: type === 'user' ? 'Add Customer Note' : 'Add Production Note',
        additional: {
          id: this.ids[type],
          type: type,
          message: this.notes[type]
        },
        bodyComponent: ProductionNotesPopup,
        showButtons: true,
        submitLabel: 'Submit Notes',
        runCallback: true
      });
    },

    loadInfo() {
      let data = {
        userId: this.accountId,
        skipId: this.id
      };
      if (data.userId) {
        apiService.getLatestCustomerOrders(data).then(data => {
          this.latestOrders = data.orders.data.map(order => {
            return {
              id: order.id,
              printers: sortedUniq(
                order.designs
                  .map(design => {
                    if (design.printer) {
                      return design.printer.code;
                    }
                  })
                  .filter(function(n) {
                    return n != undefined;
                  })
              ).join(' / ')
            };
          });
        });
      }

      let order = this.orderDetails[0];
      apiService.getReprintedDesigns(order.id).then(data => {
        this.referenceDesigns = data;
      });

      return [];
    }
  },
  mounted() {
    this.loadInfo();
  }
};
</script>
