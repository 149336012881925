<template>
    <div :class="{'is-horizontal control': horizontal}">
        <div v-if="horizontal" class="control-label">
            <label class="label" :for="scope + '-' + name">
                <span v-if="required" class="required">*</span>
                {{ getLabel }}
            </label>
        </div>
        <div v-else>
            <label  class="label" :for="scope + '-' + name">
                <span v-if="required" class="required">*</span>
                {{ getLabel }}
            </label>
        </div>
        <div
          v-bind="tooltipIco"
          class="control has-text-centered"
        >
            <textarea
                    v-model="fieldValue"
                    :disabled="disabled"
                    :readonly="readonly"
                    v-validate="{ rules: rules, scope: scope }"
                    :data-vv-name="name"
                    :data-vv-as="getLabel"
                    :class="{'textarea': true, 'is-danger': errors.has(scope + '.' + name)}"
                    :id="scope + '-' + name"
                    :placeholder="placeholder"
                    @input="$emit('input', $event.target.value)"
                    >
                    </textarea>
            <span v-show="errors.has(scope + '.' + name)" class="help is-danger is-visible">
                {{ errors.first(scope + '.' + name) }}
            </span>
        </div>
    </div>
</template>
<script>
import Vue from 'vue';
import bus from '../../../bus';
import alertify from 'alertify.js';

export default {
  name: 'form-textarea',
  data: function() {
    return { fieldValue: this.value };
  },
  watch: {
    value: function(value) {
      this.fieldValue = value;
      this.$validator.validate(this.name, this.value, this.scope);
    }
  },
  props: {
    name: {},
    editTooltip: {
      type: Boolean,
      default: false
    },
    isHierarchical: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
    addEmpty: {
      type: Boolean,
      default: true
    },
    label: {
      type: String,
      default: ''
    },
    value: {},
    required: {
      type: Boolean,
      default: true
    },
    rules: {
      type: String,
      default: 'required|max:255'
    },
    scope: {
      type: String,
      default: 'form-scope'
    },
    horizontal: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: ''
    }
  },
  methods: {
    onChange(e) {
      if (this.fieldValue !== this.value) {
        this.$emit('change', e.target.value);
      }
    },
    onValidate(validateScope) {
      if (validateScope) {
        this.$validator.validateAll(validateScope).catch(err => {});
      } else {
        this.$validator.validateScopes().catch(err => {});
      }
    },
    onClear() {
      this.errors.clear(this.scope);
    },
    addError(e) {
      if (e.field == this.name && e.scope == this.scope) {
        console.log(
          e.fullName,
          this.getLabel,
          e.msg.replace(e.fullName, this.getLabel)
        );
        this.errors.add(
          e.field,
          e.msg.replace(e.fullName, this.getLabel),
          e.rule,
          e.scope
        );
        alertify.error(e.msg.replace(e.fullName, this.getLabel));
      }
    }
  },
  computed: {
    tooltipIco() {
      if (this.editTooltip) {
        return {
          tooltip: true,
          'tooltip-ico': 'pensil',
          'tooltip-position': 'left'
        };
      }
      return {};
    },
    getLabel() {
      //capitalize name
      return this.label
        ? this.label
        : this.name.charAt(0).toUpperCase() + this.name.slice(1);
    }
  },
  created() {
    bus.$on('add-error', this.addError);
    bus.$on('validate', this.onValidate);
    bus.$on('clear', this.onClear);
    this.$watch(
      () => this.errors.errors,
      (value, oldValue) => {
        if (value.length) {
          bus.$emit('errors-changed', value);
        } else {
          bus.$emit('errors-deleted', oldValue);
        }
      }
    );
  },
  beforeDestroy() {
    bus.$emit('errors-deleted', this.errors.errors);
    bus.$off('validate', this.onValidate);
    bus.$off('clear', this.onClear);
    bus.$off('add-error', this.addError);
  }
};
</script>
