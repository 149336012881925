<template>
  <div class="container has-text-centered">
    <p class="icon is-success is-large"><i class="ff-android-done"></i></p>
    <h2 class="title is-2 has-text-centered">Thank You.</h2>
    <h4 class="subtitle is-4 has-text-centered">Your payment is being processed.</h4>
    <p>
      You will be notified when it is done.
    </p>
  </div>
</template>

<style lang="scss" scoped>
.container {
  margin-top: 20px;
}
</style>

<script>
import Vue from 'vue';
import apiService from '../../../../services/api.service';
export default {
  name: 'order-invoices-payment-thank-you',
  data() {
    return {
      transaction: {}
    };
  },
  computed: {},
  mounted() {}
};
</script>
