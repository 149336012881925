import filter from 'lodash/filter';
import includes from 'lodash/includes';

export const checkRoles = (roles, routeRoles, ignoreDeveloper) => {
  const checkRoles = [...routeRoles];
  if (!ignoreDeveloper) {
    checkRoles.push('developer'); // always allowed
  }

  const allowedRoles = filter(roles, r => {
    return includes(checkRoles, r);
  });
  return allowedRoles.length;
};
