<template>
  <tr>
    <td :colspan="productCellWidth">
      <invoice-field
        label="Product"
        :value="getOptionLabel(item.print_type, formOptions.print_types)"
      />
    </td>
    <td v-if="isScreenTypePoster && item.ink_type">
      <invoice-field
        label="Ink Type"
        :value="getOptionLabel(item.ink_type, formOptions.print_type_options[item.print_type].ink_types)"
      />
    </td>
    <td v-if="isScreenTypePoster">
      <invoice-field
        label="Bleed Type"
        :value="getOptionLabel(item.bleed_type, formOptions.print_type_options[item.print_type].bleed)"
      />
    </td>
    <td>
      <invoice-field
        label="Quantity"
        :value="quantityValue"
      />
    </td>
    <td>
      <invoice-field
        label="Price"
        :value="parseFloat(item.cost)/parseFloat(quantityValue) | price"
      />
    </td>
  </tr>
</template>
<script>
import {getOptionLabel} from '@/helpers/estimates';
import InvoiceField from '@/components/common/invoice-field.vue';
import apiService from '@/services/api.service';

export default {
  name: 'PaperDesignInvoiceItem',
  components: {InvoiceField},
  props: {
    item: {
      type: Object,
      required: true
    },
    formOptions: {
      type: Object,
      required: true
    },
  },
  data() {
    return {
      paperColors: []
    };
  },
  methods: {
    getOptionLabel,
    getPaperColors(brand, weight) {
      return apiService.getPaperPrintPaperColors(brand, weight).then(response => {
        const groups = Object.keys(response).sort((a, b) => {
          const aMinOptionKey = Object.keys(response[a]).sort()[0];
          const bMinOptionKey = Object.keys(response[b]).sort()[0];
          if (aMinOptionKey < bMinOptionKey) {
            return -1;
          }
          if (aMinOptionKey > bMinOptionKey) {
            return 1;
          }
          return 0;
        });
        this.paperColors = groups.map(groupKey => {
          return {
            title: groupKey,
            options: Object.keys(response[groupKey]).map(childKey => {
              return {
                id: childKey,
                label: response[groupKey][childKey]
              };
            })
          };
        });
      });
    }
  },
  computed: {
    quantityValue() {
      return getOptionLabel(this.item.quantity, this.formOptions.print_type_options[this.item.print_type].quantities);
    },
    isScreenTypePoster() {
      return this.getOptionLabel(this.item.print_type, this.formOptions.print_types) === 'Screen Printed Posters';
    },
    productCellWidth(){
      return this.isScreenTypePoster?1:3;
    }
  }
};
</script>
