<template>
  <div class="columns">
    <div class="column manage-files">
      <div class="level-item" style="margin-bottom: -25px">
        <div class="control">
          <p class="control has-addons">
            <input type="text" class="input"
                   v-model="searchQuery"
                   v-focus="true"
                   placeholder="Search printers"
            >
          </p>
        </div>
      </div>
      <div class="has-text-centered">
        <h5 class="subtitle is-5">Order #{{orderId}} <span v-if="designName"> - {{designName}}</span></h5>
        <table class="table-manage-printers table table__without-hover">
          <colgroup>
            <col width="1%">
            <col>
            <col>
            <col>
            <col>
            <col width="1%">
          </colgroup>
          <thead>
          <tr>
            <th>Code</th>
            <th>Printer</th>
            <th># of Orders</th>
            <th># of Designs</th>
            <th># of Pieces</th>
            <th></th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(printer, key) in printers">
            <td>
              {{ printer.code }}
            </td>
            <td class="printer-name">
              {{ printer.name }}
            </td>
            <td>
              {{ printer.active_orders }}
            </td>
            <td>
              {{ printer.active_designs }}
            </td>
            <td>
              {{ printer.total_items }}
            </td>
            <td>
              <button v-if="assignedPrinterId == printer.id"
                      @click="assignPrinter(null)"
                      class="button is-small">
                Unassign
              </button>
              <button v-else @click="assignPrinter(printer)"
                      class="button is-small">
                Assign
              </button>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import notify from '../../../modules/notifier';

const top3Printers = ['Unassigned', 'Extreme', 'Impressionz', 'A-Creations'];

export default {
  name: 'manage-printers',
  props: ['additional'],
  data() {
    return {
      orderId: null,
      design: {},
      designName: null,
      printers: [],
      assignedPrinterId: null,
      modalCardLong: true,
      searchQuery: ''
    };
  },
  watch: {
    searchQuery: function (str) {
      if (str.length == 0 || str.length >= 3) {
        this.getPrinters();
      }
    },
  },
  methods: {
    getPrinters() {
      let url = '/api/designs/' + this.additional.orderId + '/printers_list';
      if (this.searchQuery) {
        url += '?q=' + this.searchQuery;
      }
      this.$http
        .get(url)
        .then(({data}) => {
          const printers = data.slice();
          let printersList = [];
          top3Printers.forEach(topPrinterName => {
            const printer = data.find(({name}) => name === topPrinterName);
            if (printer) printersList.push(printer);
          });
          printersList = printersList.concat(
            printers
              .filter(({name}) => !top3Printers.includes(name))
              .sort((a, b) => {
                return a.name > b.name ? 1 : a.name < b.name ? -1 : 0;
              })
          );
          this.printers = printersList;
          this.designName = this.additional.designName;
          this.orderId = this.additional.orderId;
          this.assignedPrinterId = this.additional.assignedPrinterId;
        })
        .catch(() => {
          console.log('Oops');
        });
    },
    assignPrinter(printer) {
      let printerId = printer == null ? null : printer.id;
      let url = '/api/designs/design/' + this.additional.designId + '/assign_printer';
      // assign printer to all designs
      if(!this.additional.designId && this.additional.orderId){
        url = '/api/orders/'+ this.additional.orderId + '/designs/assign_printer';
      }
      this.$http
        .post(
          url,
          {
            printer_id: printerId
          }
        )
        .then(response => {
          if (!this.additional.designId && this.additional.orderId) {
            response.data.design.forEach((design, index) => {
              this.additional.designPrinterCallback(
                this.additional.orderId,
                design.id,
                printer,
                design
              );
            });
          } else {
            this.additional.designPrinterCallback(
              this.additional.orderId,
              this.additional.designId,
              printer,
              response.data.design
            );
          }
          this.$emit('close');
        })
        .catch(ex => {
          console.log('Oops');
        });
    }
  },
  created() {
    this.getPrinters();
  }
};
</script>
<style lang="scss" scoped>
.table-manage-printers {
  table-layout: auto;
}
</style>
