<template>
  <div class="design-info">
    <div v-if="design.designable && design.designable.item" class="card is-fullwidth">
      <div class="card-content">
        <div class="content">
          <table class="table is-bordered is-striped is-narrow">
            <thead>
              <tr>
                <th>Size</th>
                <th>Quantity</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{{size_label}}</td>
                <td>{{quantity_label | itemsNumberFormat}}</td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="content">
          <div v-if="design.comment && !hideComment" class="card is-fullwidth">
            <header class="card-header">
              <p class="card-header-title">Design Comment</p>
            </header>
            <div class="content-pre card-content">{{ design.comment }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'design-info-button',
  props: {
    design: {
      type: Object,
      required: true,
      default: false
    },
    options: {
      type: Object,
      required: true
    },
    hideComment: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    size_label() {
      let label = 'N/A';
      if (
        this.design &&
        this.design.designable &&
        this.design.designable.item &&
        this.options['sizes']
      ) {
        let size_option = this.options['sizes'].filter(
          s => s.key === this.design.designable.item.size
        )[0];

        if (size_option && size_option.label) {
          label = size_option.label;
        }
      }

      return label;
    },
    quantity_label() {
      let label = 'N/A';
      if (
        this.design &&
        this.design.designable &&
        this.design.designable.item &&
        this.options['sizes']
      ) {
        let qty_option = this.options['quantities'].filter(
          s => s.key === this.design.designable.item.quantity
        )[0];

        if (qty_option && qty_option.label) {
          label = qty_option.label;
        }
      }

      return label;
    }
  }
};
</script>
