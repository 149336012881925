<template>
  <div class="page-content container section">
    <section id="notifications">
      <router-view></router-view>
    </section>
  </div>
</template>

<script>
import Vue from 'vue';

export default {
  name: 'notifications-index'
};
</script>
