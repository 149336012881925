import Vue from 'vue';
import { startCase } from 'lodash';

Vue.filter('intlNumber', value => {
  if (isNaN(+value)) return value;
  return Intl.NumberFormat().format(+value);
});

Vue.filter('fileName', path => {
  const parts = path.split('/');
  return parts[parts.length - 1];
});

Vue.filter('price', price => {
  if (price === undefined) {
    return price;
  } else if (typeof price === 'string') {
    return `$${parseFloat(price).toFixed(2)}`.replace(
      /\B(?=(\d{3})+(?!\d))/g,
      ','
    );
  } else if (typeof price === 'number') {
    return `$${price.toFixed(2)}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }
});

Vue.filter('startCase', string => {
  if (!string) return string;
  return startCase(string);
});

Vue.filter('capitalize', (string, lowercase = true) => {
  if (!string) return string;
  return (
    string.charAt(0).toUpperCase() +
    (lowercase ? string.slice(1).toLowerCase() : string.slice(1))
  );
});

Vue.filter('uppercase', string => {
  if (!string) return string;
  return string.toUpperCase();
});

Vue.filter('comma', string => {
  if (!string) return string;
  return (string + '').replace('.', ',');
});

Vue.filter('itemsNumberFormat', number => {
  if (!number) return number;
  return ('' + number).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
});
