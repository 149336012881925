<template>
  <div class="poster-design-item">
    <div class="table-container">
      <table class="table">
        <thead>
          <tr>
            <th>Shape</th>
            <th>Size</th>
            <th>Quantity</th>
            <th v-roles="['admin', 'csr', 'production-coordinator', 'purchaser', 'art-producer', 'customer']">Total</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{{design.shape}}</td>
            <td>{{design.size}}</td>
            <td>{{design.total.qty | itemsNumberFormat}}</td>
            <td v-roles="['admin', 'csr', 'production-coordinator', 'purchaser', 'art-producer', 'customer']">{{design.total.price | price}}</td>
          </tr>
        </tbody>
      </table>
    </div>

    <div v-if="design.total && design.total.upcharge > 0" v-roles="['admin', 'csr', 'production-coordinator', 'purchaser', 'art-producer', 'customer']">
      <strong>Upcharge for <span v-if="design.name">{{design.name}}</span><span v-else>Design #{{ parseInt(index)+1 }}</span>:</strong> {{design.total.upcharge | price}}
      <p v-if="design.total.upcharge_description">{{design.total.upcharge_description}}</p>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  .table-container {
    max-width: 600px;
  }
</style>

<script>
  import Vue from 'vue';

  export default {
    name: 'sticker-design-type',
    props: ['design']
  };
</script>

