const roleAdmin = 'admin';
const roleCsr = 'csr';
const roleProductionCoordinator = 'production-coordinator';
const rolePurchaser = 'purchaser';
const roleArtProducer = 'art-producer';
const roleDeveloper = 'developer';
const roleCustomer = 'customer';
const rolePrinter = 'printer';
const defRoles = [
  roleAdmin,
  roleCsr,
  roleProductionCoordinator,
  rolePurchaser,
  roleArtProducer,
  roleDeveloper
];

export const canCreateMock = [...defRoles];
export const canApproveMock = [...defRoles, roleCustomer];
export const canRequestRevisionMock = [roleCustomer];
export const canPrintMock = [...defRoles, rolePrinter];
export const canSendMocks = [...defRoles];
export const canCreateEstimate = [
  ...defRoles
]; /* By default it should be: [...defRoles/!*, 'customer'*!/];*/
export const canEditEstimate = [...defRoles];
export const canSendFilesInMessenger = [...defRoles, rolePrinter, roleCustomer];
export const canSeeProduct = [...defRoles, rolePrinter, roleCustomer];
export const canSeeOrderPayments = [roleAdmin, roleDeveloper, roleCsr, roleProductionCoordinator, rolePurchaser];
export const canSeeCustomerDashboard = [roleCustomer]
export const canSeeProductionForm = [...defRoles];
export const canSeeCommunicationForm = [...defRoles, rolePrinter];
export const canSeeCompanyReports = [roleAdmin, roleCsr];
export const canSeePaymentStatusPage = [roleAdmin, roleCsr, roleCustomer];
export const canSeeCsrConversionsPage = [roleAdmin, roleCsr, roleCustomer];

export const admins = [roleAdmin,roleDeveloper];
export const teamMembers = [...defRoles];
export const customers = [roleCustomer];
export const printers = [rolePrinter];
