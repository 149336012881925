<script>
import alertify from 'alertify.js';

export default {
  props: {
    value: {
      required: true,
      type: [Object, Array]
    },
    scope: {
      required: true,
      type: String
    },
    parentForm: {
      required: true,
      type: Object
    },
    formOptions: {
      required: true,
      type: Object
    }
  },
  data() {
    return {
      isLoading: false,
      form: JSON.parse(JSON.stringify(this.value))
    };
  },
  watch: {
    form: {
      handler() {
        this.$emit('input', this.form);
      },
      deep: true
    }
  },
  mounted() {
    if (this.$options.name === 'form-estimate-summary') {
      this.$scrollTo(this.$el, 300, {
        offset: -10
      });
    } else {
      this.$scrollTo(this.$el);
    }
  },
  methods: {
    onPassed() {
      this.value.isPassed = true;
    },
    onError() {
      alertify.log('Please, fix all validation errors to proceed.');
    },
    emitData() {
      this.$emit('data', this.form);
    }
  }
};
</script>
