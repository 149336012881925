<template>
  <div>
    <div class="columns is-fullwidth " style="overflow-x: auto">
      <div v-if="additional.warnings"
        v-for="(warning, key) in additional.warnings"
        class="column">
        <div class="card is-fullwidth">
          <header class="card-header is-primary">
            <p class="card-header-title is-primary">{{warning.title}}</p>
          </header>
          <div class="card-content">{{warning.body}}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import { mapActions } from 'vuex';
import { APPROVE_MOCK } from '../../../store/action-types';
import popup from '../../../modules/popupper';
import apiService from '../../../services/api.service';

export default {
  name: 'approve-mock',
  props: ['additional'],
  methods: {
    ...mapActions('revision', {
      approveMock: APPROVE_MOCK
    }),
    submitForm() {
      this.approveMock(this.additional.mockId).then(() => {
        popup({
          title: 'Confirmation',
          message:
            'Approved! You will be notified once your order has been printed and shipped.',
          showButtons: false,
          bodyComponent: false
        });
        this.$emit('success');
        this.$emit('close');
      });
    }
  }
};
</script>
