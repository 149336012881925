<template>
  <div>
    <div class="column">
      <estimates-report></estimates-report>
    </div>
  </div>
</template>

<script>
import Datepicker from '../../../modules/datepicker/index';
import EstimatesReport from "@/components/reports/estimates-report.vue";

export default {
  name: 'estimates-report-tab',
  components: {EstimatesReport, Datepicker},
};
</script>
