<template>
  <div class="item-location">
    <header class="step-section__subheader">
      <h4 class="step-section__subheader__title">Location {{ index + 1 }}</h4>
      <a href="#" class="clr-destructive"
        @click.prevent="$emit('delete', index)"
        v-if="locationsQty > 1"
      >Delete Location</a>
    </header>
    <div class="step-section__body">
      <form-row class="fixed-3">
        <!-- <svg slot="icon" height="18px" version="1.1" viewBox="0 0 18 18" width="18px" xmlns="http://www.w3.org/2000/svg" xmlns:sketch="http://www.bohemiancoding.com/sketch/ns" xmlns:xlink="http://www.w3.org/1999/xlink">
          <path d="M18,16 L18,2 C18,0.9 17.1,0 16,0 L2,0 C0.9,0 0,0.9 0,2 L0,16 C0,17.1 0.9,18 2,18 L16,18 C17.1,18 18,17.1 18,16 L18,16 Z M5.5,10.5 L8,13.5 L11.5,9 L16,15 L2,15 L5.5,10.5 L5.5,10.5 Z"/>
        </svg> -->
        <svg slot="icon" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M19 12h-2v3h-3v2h5v-5zM7 9h3V7H5v5h2V9zm14-6H3c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h18c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 16.01H3V4.99h18v14.02z"/></svg>
        <!-- Location -->
        <form-multiselect
          v-model="locationModel"
          :disabled="isReprint"
          :options="formData.locations || []"
          :required="true"
          :label="`${locationType} Location`"
          :config="{
            groupValues: 'options',
            groupLabel: 'title',
            groupSelect: false
          }"
          :scope="scope"
          name="location"
        ></form-multiselect>
      </form-row>
      <form-row>
        <svg slot="icon" style="enable-background:new 0 0 50 50;" version="1.1" viewBox="0 0 50 50" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
          <path d="M0.586,34L16,49.414L49.414,16L34,0.586L0.586,34z M16,46.586L3.414,34L5.5,31.914l4.793,4.793l1.414-1.414L6.914,30.5 l3.586-3.586l2.793,2.793l1.414-1.414L11.914,25.5l3.586-3.586l4.793,4.793l1.414-1.414L16.914,20.5l3.586-3.586l2.793,2.793 l1.414-1.414L21.914,15.5l3.586-3.586l4.793,4.793l1.414-1.414L26.914,10.5L30.5,6.914l2.793,2.793l1.414-1.414L31.914,5.5 L34,3.414L46.586,16L16,46.586z"/>
        </svg>
        <!-- Width -->
        <form-item
          v-model="form.width"
          label="Width"
          name="width"
          id="width"
          placeholder="Inches"
          rules="min_value:0.01|decimal:2"
          :disabled="isReprint"
          style="max-width: 94px;"
        ></form-item>
        <div class="form-item-text" style="align-self: center;">X</div>
        <!-- Height -->
        <form-item
          v-model="form.height"
          label="Height"
          name="height"
          id="height"
          placeholder="Inches"
          rules="min_value:0.01|decimal:2"
          :disabled="isReprint"
          style="max-width: 94px;"
        ></form-item>
        <!-- Jumbo -->
        <div class="form-item-text" v-if="service === 'apparel'" style="align-self: center;">
          <form-checkbox v-model="form.has_jumbo_print" name="has_jumbo_print" :static="true">Jumbo Print</form-checkbox>
        </div>
      </form-row>
      <form-row v-if="service === 'apparel'">
        <svg slot="icon" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
          <path d="M12 3c-4.97 0-9 4.03-9 9s4.03 9 9 9c.83 0 1.5-.67 1.5-1.5 0-.39-.15-.74-.39-1.01-.23-.26-.38-.61-.38-.99 0-.83.67-1.5 1.5-1.5H16c2.76 0 5-2.24 5-5 0-4.42-4.03-8-9-8zm-5.5 9c-.83 0-1.5-.67-1.5-1.5S5.67 9 6.5 9 8 9.67 8 10.5 7.33 12 6.5 12zm3-4C8.67 8 8 7.33 8 6.5S8.67 5 9.5 5s1.5.67 1.5 1.5S10.33 8 9.5 8zm5 0c-.83 0-1.5-.67-1.5-1.5S13.67 5 14.5 5s1.5.67 1.5 1.5S15.33 8 14.5 8zm3 4c-.83 0-1.5-.67-1.5-1.5S16.67 9 17.5 9s1.5.67 1.5 1.5-.67 1.5-1.5 1.5z"/>
        </svg>
        <!-- # of Colors -->
        <form-multiselect
          v-model="colorsNumberModel"
          :options="colorsNumbersValidated"
          :required="true"
          label="# of Colors"
          :config="{
            trackBy: 'key'
          }"
          :scope="scope"
          name="colorsNumber"
          :disabled="isReprint"
        ></form-multiselect>
        <!-- Ink Type -->
        <form-multiselect
          label="Ink Type"
          :required="true"
          v-model="inkTypeModel"
          :options="formData.inkTypes || []"
          :config="{
            trackBy: 'key'
          }"
          :scope="scope"
          name="inkType"
          :disabled="isReprint"
        ></form-multiselect>
        <!-- Process Add-Ons -->
        <form-multiselect
          label="Add-Ons"
          v-model="form.process_addons"
          :options="formData.processAddons || []"
          :config="{
            trackBy: 'key',
            allowEmpty: true,
            closeOnSelect: false,
            multiple: true
          }"
          name="processAddon"
          :scope="scope"
          :disabled="isReprint"
        >
          <template slot="selection" slot-scope="{ values }">
            <span
              class="multiselect__single"
              v-if="values.length"
            >{{ values.length }} options selected</span>
          </template>
        </form-multiselect>
      </form-row>
      <form-row v-if="service === 'embroidery'">
        <span slot="icon"></span>
        <!-- Depth -->
        <form-multiselect
          v-model="depthModel"
          :options="formData.depth || []"
          :required="true"
          :config="{
            trackBy: 'key'
          }"
          :scope="scope"
          name="depth"
          label="Depth"
          :disabled="isReprint"
        ></form-multiselect>
        <!-- Thread Colors -->
        <form-item
          v-model="form.thread_colors"
          label="Thread colors"
          name="thread"
          id="thread"
          :scope="scope"
        ></form-item>
        <!-- Stitches -->
        <form-multiselect
          label="Stitches"
          :required="true"
          v-model="stitchesModel"
          :options="formData.stitches || []"
          :scope="scope"
          name="stitch"
          :config="{
            trackBy: 'key',
            customLabel: ({ label }) => getStitchesLabel(label)
          }"
          :disabled="isReprint"
        ></form-multiselect>
      </form-row>
      <form-row v-if="service === 'apparel'">
        <span slot="icon"></span>
        <form-multiselect
          label="PMS Colors"
          v-model="form.colors"
          :options="pmsColorsOptions"
          placeholder="Search..."
          :config="{
            label: 'hex',
            internalSearch: false,
            multiple: true,
            closeOnSelect: false,
            allowEmpty: true
          }"
          name="pmsColors"
          :scope="scope"
          @search-change="onPMSColorsSearch"
          :disabled="isReprint"
        >
          <template slot="option" slot-scope="{ option }">
            <span :style="{
              'background-color': `#${option.hex}`,
              'width': '1em',
              'height': '1em',
              'border-radius': '50%',
              'display': 'inline-block',
              'vertical-align': 'middle',
              'border': '0.5px solid #ccc'
            }"></span>
            {{ option.name }}
          </template>
          <template slot="tag" slot-scope="{ option, remove }">
            <span class="pms-color">
              <span class="pms-color__circle" :style="'background-color:#' + option.hex"></span>
              <span class="pms-color__label">{{ option.name }}</span>
              <button v-if="!isReprint" class="pms-color__remove"
                @click="remove(option)">
                <span class="icon is-medium">
                  <i class="ff-lg ff-cross-mark"></i>
                </span>
              </button>
            </span>
          </template>
        </form-multiselect>
      </form-row>
    </div>
  </div>
</template>
<script>
import { debounce, flatten } from 'lodash';
import { mapGetters, mapActions } from 'vuex';
import { LOAD_PMS_COLORS } from '@/store/action-types';

export default {
  name: 'fieldset-location',
  props: {
    value: {
      required: true,
      type: Object
    },
    scope: {
      required: true,
      type: String
    },
    index: {
      required: true,
      type: Number
    },
    formData: {
      required: true,
      type: Object
    },
    design: {
      required: true,
      type: Object
    }
  },
  data() {
    const form = Object.assign({}, JSON.parse(JSON.stringify(this.value)));

    return {
      form,
      pmsColorsOptions: []
    };
  },
  computed: {
    ...mapGetters(['pmsColors', 'jumbo']),
    service() {
      return this.design.common.service;
    },
    isReprint() {
      return (
        !!(
          this.design.common &&
          this.design.common.printed_before_order_id &&
          this.design.common.printed_before_design_id
        ) || false
      );
    },
    isRedoDesign() {
      return ((this.design.common && this.design.common.redo_design_id !== null) || false);
    },
    locationType() {
      switch (this.service) {
        case 'embroidery':
          return 'Embroidery';
        default:
          return 'Print';
      }
    },
    locationModel: {
      get() {
        if (this.form.location === null) return null;
        return (
          this.formData.locations
            .reduce((list, group) => list.concat(group.options), [])
            .find(({ id }) => +this.form.location === +id) || null
        );
      },
      set(option) {
        this.form.location = option.id;
        this.form._location = option.label;
      }
    },
    colorsNumberModel: {
      get() {
        if (this.form.colors_number === null) return null;
        return (
          this.colorsNumbersValidated.find(
            ({ key }) => this.form.colors_number === key
          ) || null
        );
      },
      set(option) {
        this.form.colors_number = option.key;
        this.form._colors_number = option.label;
      }
    },
    inkTypeModel: {
      get() {
        if (this.form.ink_type === null) return null;
        return (
          this.formData.inkTypes.find(
            ({ key }) => this.form.ink_type === key
          ) || null
        );
      },
      set(option) {
        this.form.ink_type = option.key;
        this.form._ink_type = option.label;
      }
    },
    depthModel: {
      get() {
        if (this.form.depth === null) return null;
        return (
          this.formData.depth.find(({ key }) => this.form.depth === key) || null
        );
      },
      set(option) {
        this.form.depth = option.key;
        this.form._depth = option.label;
      }
    },
    stitchesModel: {
      get() {
        if (this.form.stitches_id === null) return null;
        return (
          this.formData.stitches.find(
            ({ key }) => this.form.stitches_id === key
          ) || null
        );
      },
      set(option) {
        this.form.stitches_id = option.key;
        this.form._stitches_id = option.label;
      }
    },
    locationsQty() {
      return this.design.specific.locations.length;
    },
    garmentsTotalSizesQty() {
      const garmentsSizesQty = this.design.specific.garments.reduce(
        (acc, garment) => {
          let garmentSizesQty = 0;
          if (garment.sizes) {
            garmentSizesQty = Object.keys(garment.sizes).reduce(
              (acc2, size) => acc2 + +garment.sizes[size],
              0
            );
          }
          return acc + garmentSizesQty;
        },
        0
      );
      return garmentsSizesQty;
    },
    colorsNumbersValidated() {
      const options = this.formData.colorsNumbers || [];
      return options.map(({ key, label, min_order_qty }) => {
        const option = {
          key,
          min_order_qty
        };
        if (key === 0) {
          option.label = label;
          return option;
        }
        option.label = `${key}`;
        if (!this.isRedoDesign && this.garmentsTotalSizesQty < min_order_qty) {
          option.label += ` - Required min - ${min_order_qty} garments`;
          option.$isDisabled = true;
        }
        return option;
      });
    }
  },
  watch: {
    'form.width': function(val) {
      if (this.form.has_jumbo_print !== undefined) {
        this.recalculateJumbo();
      }
    },
    'form.height': function(val) {
      if (this.form.has_jumbo_print !== undefined) {
        this.recalculateJumbo();
      }
    },
    'form.has_jumbo_print': function(val) {
      this.resetCost();
    },
    'form.colors_number': function(val) {
      this.resetCost();
    },
    'form.ink_type': function(val) {
      this.resetCost();
    },
    'form.process_addons': function(val) {
      this.resetCost();
    },
    'form.stitches_id': function(val) {
      this.resetCost();
    },
    'form.depth': function(val) {
      this.resetCost();
    },
    form: {
      handler(val) {
        this.$emit('input', val);
      },
      deep: true
    },
    pmsColors(val) {
      this.pmsColorsOptions = val.slice();
    },
    garmentsTotalSizesQty: debounce(function(val) {
      const minOrderQty =
        this.colorsNumberModel && this.colorsNumberModel.min_order_qty;
      if (!this.isRedoDesign && minOrderQty > 0 && val < minOrderQty) {
        this.form.colors_number = null;
      }
      this.resetCost();
    }, 300)
  },
  created() {
    if (!this.pmsColors.length) {
      this.getPmsColors();
    }
  },
  methods: {
    ...mapActions({
      getPmsColors: LOAD_PMS_COLORS
    }),
    getStitchesLabel(label) {
      if (isNaN(+label)) {
        return label;
      } else {
        return `${Intl.NumberFormat().format(+label)}`;
      }
    },
    resetCost() {
      if (this.design.common.redo_design_id === null) {
        this.form.cost = null;
      }
    },
    recalculateJumbo() {
      const isJumbo =
        this.form.width > this.jumbo.jumbo_print_width ||
        this.form.height > this.jumbo.jumbo_print_height;

      this.form.has_jumbo_print = isJumbo;
    },
    onPMSColorsSearch(query) {
      this.pmsColorsOptions = this.pmsColors.filter(
        pmsColor =>
          pmsColor.name.toLowerCase().indexOf(query.toLowerCase()) !== -1
      );
    }
  }
};
</script>
<style lang="scss">
@import '/resources/sass/base/helpers';

.pms-color {
  height: 32px;
  border-radius: 16px;
  background: $clr-grey-light;
  font-size: 14px;
  line-height: 20px;
  padding: 6px;
  display: inline-flex;
  vertical-align: middle;
  margin: 0 4px 4px 0;
  &__circle {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    display: inline-block;
    vertical-align: middle;
    border: 0.5px solid #ccc;
    & + * {
      margin-left: 4px;
    }
  }
  &__label {
    display: inline-block;
    vertical-align: middle;
  }
  &__remove {
    background: none;
    margin: 0 0 0 4px;
    padding: 0;
    border: none;
    width: 20px;
    height: 20px;
    outline: none;
    cursor: pointer;
    .icon {
      width: 100%;
      height: 100%;
      color: $clr-grey-dark;
    }
    &:hover {
      .icon {
        color: $clr-destructive;
      }
    }
  }
}
</style>
