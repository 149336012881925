import Vue from 'vue';
import { checkRoles } from '../router/checkRoles';
import Sortable from 'sortablejs';
import store from '../store';
import linkify from 'vue-linkify';
import { directive as onClickaway } from 'vue-clickaway';
import { isMobile } from 'mobile-device-detect';

Vue.directive('linkified', linkify);

Vue.directive('dropdown-menu', {
  inserted(el) {
    el.classList.add('drop-component');
    const btn = el.firstChild;
    const menu = el.getElementsByClassName('dropmenu');

    if (isMobile) {
      el.onclick = () => {
        if (menu[0] && menu[0].classList) {
          menu[0].classList.toggle('is-open');
        }
      };
    } else {
      el.onmouseenter = () => {
        if (menu[0] && menu[0].classList) {
          menu[0].classList.add('is-open');
        }
      };

      el.onmouseleave = () => {
        if (menu[0] && menu[0].classList) {
          menu[0].classList.remove('is-open');
        }
      };
    }
  }
});

Vue.directive('roles', {
  bind(el, binding) {
    binding.def.handle_roles = (el, binding) => {
      let v = binding.value;
      if (
        typeof v === 'object' &&
        v.length &&
        !checkRoles(store.getters.roles, v)
      ) {
        if (binding.modifiers.act) {
          if (el.parentNode) {
            if (el.tagName === 'A') {
              el.setAttribute('href', 'javascript: void(0)');
            }

            el.setAttribute('disabled', 'disabled');
            el.onclick = window.accessError;
            el.value = el.value ? el.value : '';
          }
        } else {
          if (el.parentNode) {
            el.parentNode.removeChild(el);
          }
        }
      }
    };
  },
  inserted(el, binding) {
    binding.def.handle_roles(el, binding);
  },
  update(el, binding) {
    binding.def.handle_roles(el, binding);
  }
});

Vue.directive('role-access', {
  inserted(el, binding, vnode) {
    let v = binding.value;
    if (!checkRoles(store.getters.roles, v, true)) {
      vnode.elm.parentElement.removeChild(vnode.elm);
    }
  }
});

const onStickyDirectiveScroll = (el, binding) => {
  if (el.parentNode.parentNode.getBoundingClientRect().top <= 0) {
    el.style.transform = `translateY(${Math.abs(
      el.parentNode.parentNode.getBoundingClientRect().top
    )}px)`;
    el.style.transition = 'all .2s';
  } else {
    el.style.transform = null;
  }
};

Vue.directive('sticky-table-header', {
  inserted(el, binding) {
    if (!binding.value) return;
    window.addEventListener(
      'scroll',
      onStickyDirectiveScroll.bind(this, el, binding)
    );
    el.classList.add('sticky-header');
  },
  unbind(el, binding) {
    window.removeEventListener(
      'scroll',
      onStickyDirectiveScroll.bind(this, el, binding)
    );
  }
});

Vue.directive('decimalonly', {
  inserted(el, binding) {
    el.type = 'text';
    el.oninput = e => {
      if (/[^.\d]/.test(el.value)) {
        e.target.value = e.target.value.replace(/[^0-9.]/g, '');
      }
    };
  },
  unbind(el, binding) {
    el.oninput = null;
  }
});

Vue.directive('sortable', {
  inserted(el, binding, vnode) {
    const sortableOptions = {
      draggable: '.item',
      wrapperSelector: '',
      sort: true,
      ...binding.value
    };
    if (sortableOptions.sort) {
      const sortEl = sortableOptions.wrapperSelector
        ? el.querySelector(sortableOptions.wrapperSelector)
        : el;
      el.sort = Sortable.create(sortEl, sortableOptions);
    }
  },
  update(el, binding, vnode) {
    const sortableOptions = {
      draggable: '.item',
      wrapperSelector: '',
      sort: true,
      ...binding.value
    };
    if (sortableOptions.sort) {
      const sortEl = sortableOptions.wrapperSelector
        ? el.querySelector(sortableOptions.wrapperSelector)
        : el;
      el.sort = Sortable.create(sortEl, sortableOptions);
    }
  },
  unbind(el) {
    el.sort && el.sort.destroy();
  }
});

Vue.directive('onClickaway', onClickaway);

Vue.directive('focus', {
  inserted: el => {
    el.focus();
  }
});
