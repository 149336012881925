<template>
  <transition name="fade" mode="in-out" appear>
    <div v-if="show" :class="{'modal-animated': true, 'modal': true, 'is-active': show}">
      <div class="modal-background" @click="cancel"></div>
      <div class="modal-card" :class="{ 'modal-card-long': modalCardLong, [customClass]: customClass}">
        <header class="modal-card-head p-4" v-if="!hideHeader">
          <p class="modal-card-title" v-if="title" v-html="title"></p>
          <span class="icon is-medium">
            <button class="ff-lg ff-cross-mark" @click="cancel"></button>
          </span>
        </header>
        <section class="modal-card-body" v-if="message || bodyComponent">
          <p class="subtitle is-5 has-text-centered" v-if="subtitle" v-html="subtitle"></p>
          <p class="subtitle is-5 has-text-centered" v-if="orderId">Order <a @click="close" :href = 'getOrderPageUrl(orderId)'>#{{ orderId }}</a></p>
          <div v-if="message" v-html="message"></div>
          <div v-if="isComponent">
            <component :is="bodyComponent"
              @close="close"
              @submitFail="submitFail"
              @success="triggerSuccess"
              @cancel="triggerCancel"
              @toggleButtons="toggleButtons"
              :closing="closing"
              :additional="additional"
              :popupBody="true"
              ref="bodyComp">
            </component>
          </div>
          <label v-if="confirm" for="understand">
            <input class="checkbox" id="understand" v-model="understand" type="checkbox">
            {{confirm}}
          </label>
        </section>
        <footer class="modal-card-foot buttons" v-if="!hideFooter">
          <a v-if="showBtns"
            :disabled="(confirm && ! understand) || processing"
            :class="{button: true, 'is-primary': true, 'is-loading': processing}"
            @click="submit">{{ submitLabel }}</a>
          <a v-if="showBtns && !hideCancel" class="button" @click="cancel">Cancel</a>
        </footer>
      </div>
    </div>
  </transition>
</template>

<script>
  import Vue from 'vue'
  import isEmpty from 'lodash/isEmpty'
  import order from "@/components/customer/order/index.vue";

  export default {
    name: 'popup',
    props: {
      title: String,
      hideHeader: {
        type: Boolean,
        default: false
      },
      hideFooter: {
        type: Boolean,
        default: false
      },
      subtitle: String,
      orderId: {
        type: Number|Boolean,
        default: false
      },
      message: String,
      runCallback: {
        type: Boolean,
        default: false
      },
      runCancellCallback: {
        type: Boolean,
        default: false
      },
      bodyComponent: {},
      additional: Object,
      submitLabel: {
        type: String,
        default: 'Submit'
      },
      showButtons: {
        type: Boolean,
        default: true
      },
      modalCardLong: {
        type: Boolean,
        default: false
      },
      customClass: {
        type: String|undefined,
        default: undefined
      },
      container: {
        type: String,
        default: '.popups'
      },
      confirm: {
        default: false
      },
      hideCancel: {
        type: Boolean
      }
    },

    data() {
      return {
        $_parent_: null,
        show: true,
        processing: false,
        closing: false,
        understand: false,
        showBtns: !!this.showButtons
      };
    },

    created() {
      let $parent = this.$parent;
      if (!$parent) {
        let parent = document.querySelector(this.container);
        if (!parent) {
          // Lazy creating `div.popups` container.
          const className = this.container.replace('.', '');
          const Popups = Vue.extend({
            name: 'Popups',
            render(h) {
              return h('div', {
                'class': {
                  [`${className}`]: true
                }
              });
            }
          });
          $parent = new Popups().$mount();
          document.body.appendChild($parent.$el);
        } else {
          $parent = parent.__vue__;
        }
        this.$_parent_ = $parent;
      }
    },

    mounted() {
      if (this.$_parent_) {
        this.$_parent_.$el.appendChild(this.$el);
        this.$parent = this.$_parent_;
        delete this.$_parent_;
      }
    },

    destroyed() {
      this.$el.remove();
    },

    computed: {
      order() {
        return order
      },
      isComponent() {
        return !isEmpty(this.bodyComponent);
      }
    },

    methods: {
      submit() {
        if (!this.isComponent && typeof this.additional.callback == 'function') {
          this.additional.callback();
          this.close();
          this.processing = true;
        } else if (
          this.runCallback &&
          this.$refs.bodyComp &&
          this.$refs.bodyComp.submitForm
        ) {
          const callback = this.$refs.bodyComp.submitForm();

          this.$nextTick(() => {
            if (this.$refs.bodyComp.processing) {
              this.processing = true;
            }
          });
          if (callback instanceof Promise) {
            Promise.resolve(callback).then(() => {
              this.processing = false;
            }).catch(() => {
              this.processing = false;
            });
          } else {
            this.processing = true;
          }
        }
      },

      triggerSuccess(data) {
        if (typeof this.additional.callback == 'function') {
          this.additional.callback();
        }
        this.close();
      },

      close() {
        this.closing++;
        Vue.nextTick(() => {
          clearTimeout(this.timer);
          this.show = false;
        })
      },

      cancel() {
        if (
          this.runCancellCallback ||
          (!this.isComponent && typeof this.additional.closeCallback == 'function')
        ) {
          this.additional.closeCallback();
        }
        this.close()
      },

      triggerCancel(data) {
        if (typeof this.additional.closeCallback == 'function') {
          this.additional.closeCallback();
        }
        this.close()
      },

      submitFail() {
        this.processing = false;
      },

      afterLeave() {
        this.$destroy();
      },

      toggleButtons() {
        this.showBtns = !this.showBtns;
      },
      getOrderPageUrl(id) {
        return window.location.hash.indexOf('customer') !== -1
          ? `#/customer/orders/${id}/details`
          : `#/orders/${id}`;
      }
    }
  }
</script>
