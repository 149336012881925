import moment from 'moment';
import gridHelper from '../helpers/grid-helper';
import {
  getGridOrderPaymentStatus,
  toPriceFormat,
  colorizeArchivedOrderLinks
} from './grid-helper';

import {teamMembers} from './role-access';
import {
  BLANK_SERVICE_CODE,
  BUTTON_SERVICE_CODE,
  DIGITAL_SERVICE_CODE,
  INVOICE_SERVICE_CODE, POSTER_SERVICE_CODE, STICKER_SERVICE_CODE
} from '@/store/service-types';
import {BLANK_STATUS_STOCK_ISSUE} from './orders';
const DATE_FORMAT = 'MM/DD/YY';

export const gridHeaders = () => [
  { head: 'Order', subhead: '', sortField: 'id' },
  { head: 'Account/Design', subhead: '' },
  { head: 'Date', subhead: '', sortField: 'estimate_becomes_to_order_at' },
  { head: 'Print By', subhead: '', sortField: 'print_by_date' },
  {
    head: 'Ship By',
    subhead: '(event = red)',
    sortField: 'ship_by_date',
    sortDir: 'ASC'
  },
  { head: 'Printer', subhead: '' },
  { head: 'CSR', subhead: '', sortField: 'csr_title', sortDir: 'ASC' },
  { head: 'Art', subhead: '' },
  { head: 'Des/Loc', subhead: '' },
  { head: 'PD', subhead: '' },
  { head: 'Notes', subhead: '' },
  { head: '', subhead: { value: 'S' } },
  { head: '', subhead: { value: 'B' } },
  { head: 'Phases', subhead: { value: 'M' } },
  { head: '', subhead: { value: 'P' } },
  { head: '', subhead: { value: 'Sh' } },
  { head: '', subhead: { value: 'F' } },
  { head: 'Total', subhead: '', roles: teamMembers },
  { head: 'Status', subhead: '' }
];

export const progressFieldsList = [
  'progress_setup',
  'progress_blank',
  'progress_mock',
  'progress_print',
  'progress_shipment',
  'progress_finish'
];

export const getGridData = (orders, actions, address_source = 'company') => {
  return orders.map((order, index) => {
    let summary = (order && order.estimate && JSON.parse(order.estimate)) || {};
    let printer = '';
    let hasEvent = false;
    let ship_by_date = order.ship_by_date
      ? moment(order.ship_by_date).format(DATE_FORMAT)
      : ' - ';
    let print_by_date = order.print_by_date
      ? moment(order.print_by_date).format(DATE_FORMAT)
      : ' - ';
    let orderProgressStatusesSum = {};
    let designsLength = 0;
    let designsHasErrors = false;
    let isNonOrderinvoice = false;

    if (order.designs) {
      designsLength = order.designs.length;
      designsHasErrors = order.designs.some(
        ({ blank_status }) => +blank_status === +BLANK_STATUS_STOCK_ISSUE
      );
      progressFieldsList.forEach(progressType => {
        if (!orderProgressStatusesSum[progressType]) {
          orderProgressStatusesSum[progressType] = 0;
        }

        order.designs.forEach(design => {
          if (design.service === 'non_order_invoice') isNonOrderinvoice = true;
          if (!printer && design.printer && design.printer.code)
            printer = design.printer.code;
          else if (printer && design.printer && printer !== design.printer.code)
            printer = 'MIX';

          if (!hasEvent) hasEvent = !!design.in_hands_date;

          let designStatus = design[progressType] || 0;

          if (
            design.designable &&
            design.designable.finishings &&
            design.designable.finishings.length &&
            (progressType === 'progress_print' ||
              progressType === 'progress_setup')
          ) {
            const finishingStatus = design.designable.finishings.reduce(
              (status, finish) => {
                switch (progressType) {
                  case 'progress_print':
                    return finish.printer_status * 50 <= status
                      ? finish.printer_status * 50
                      : status;
                  case 'progress_setup':
                    return !!finish.original_files_link * 100 <= status
                      ? !!finish.original_files_link * 100
                      : status;
                }
              },
              100
            );
            designStatus =
              finishingStatus <= designStatus
                ? finishingStatus
                : design[progressType];
          }

          let phasesStatusForDesing = gridHelper.phasesStateForDesign(design);
          let currentPhase = gridHelper.convertProgressTypeToPhase(
            progressType
          );

          if (!phasesStatusForDesing[currentPhase]) {
            let designProgressesSum = 0;
            order.designs.forEach(
              design => (designProgressesSum += design[progressType])
            );
            if (designProgressesSum) designStatus = 100;
          }
          orderProgressStatusesSum[progressType] += designStatus;
        });

        orderProgressStatusesSum[progressType] =
          orderProgressStatusesSum[progressType] / designsLength;
      });
    }

    if (hasEvent)
      ship_by_date =
        '<span class="event-date has-color-error">' + ship_by_date + '</span>';

    let accountName = '';
    switch (address_source) {
      default:
      case 'company':
        accountName =
          order.company ||
          (order.user && order.user.company && order.user.company.name) ||
          '';
        break;
      case 'user':
        accountName =
          (order.contact_firstname &&
            order.contact_lastname &&
            `${order.contact_firstname} ${order.contact_lastname}`) ||
          (order.user &&
            order.user.first_name &&
            order.user.last_name &&
            `${order.user.first_name} ${order.user.last_name}`) ||
          '';
    }

    accountName =
      address_source === 'user' && order.account_id
        ? `<span class="ellipsis" style="max-width: 140px"><a href="/#/accounts/${
            order.account_id
          }/view">${accountName}</a></span>`
        : `<span class="ellipsis" style="max-width: 140px">${accountName}</span>`;

    let dateColumn =
      (order &&
        order.estimate_becomes_to_order_at &&
        moment(`${order.estimate_becomes_to_order_at}`).format(DATE_FORMAT)) ||
      '-';
    let amount = +(
      +order.total_price ||
      (summary &&
        summary.total &&
        +summary.total.price + +summary.total.upcharge) ||
      0
    ).toFixed(2);

    let data = [
      {column: 'id', value: order.id, isFirst: order.is_first, roles: teamMembers},
      {
        column: 'Order',
        value: colorizeArchivedOrderLinks(
          order,
          actions.colorizeArchivedOrderLinks
        )
      },
      {
        column: 'Account/Design',
        value: accountName,
        alignLeft: true,
        strong: true
      },
      {
        column: 'Date',
        value: dateColumn
      },
      { column: 'Print By', value: print_by_date },
      { column: 'Ship By', value: ship_by_date },
      { column: 'Printer', value: printer },
      { column: 'CSR', value: (order.csr && order.csr.title) || ' ' },
      { column: 'Art', value: (order.designer && order.designer.title) || ' ' },
      { column: 'Des/Loc', value: designsLength },
      { column: 'PD', value: order.days_in_production || ' - ' },
      {
        column: 'Notes',
        value:
          (order.user &&
            order.user.company &&
            order.user.company.production_notes &&
            `<span class="ellipsis" title="${
              order.user.company.production_notes
            }" style="max-width: 50px">${
              order.user.company.production_notes
            }</span>`) ||
          ' - '
      }
    ];
    let isDisabled = false;
    if (isNonOrderinvoice) {
      isDisabled = true;
    }
    progressFieldsList.forEach(progressType => {
      data.push({
        column: 'Phase',
        value: `<div class="${gridHelper.getProgressIcon(
          orderProgressStatusesSum[progressType],
          isDisabled,
          progressType === 'progress_blank' ? designsHasErrors : false
        )}"></div>`
      });
    });

    data.push({
      column: 'Total',
      value: toPriceFormat(amount),
      roles: teamMembers
    });

    data.push({
      column: 'Status',
      value: getGridOrderPaymentStatus(order)
    });

    return data;
  });
};

export const getGridOrderDetails = orders => {
  let details = {};
  orders.forEach(order => {
    if (!order.designs) return;

    details[order.id] = order.designs.map(design => {
      let ship_by_date = design.ship_by_date
        ? moment(design.ship_by_date).format(DATE_FORMAT)
        : ' - ';
      let print_by_date = design.print_by_date
        ? moment(design.print_by_date).format(DATE_FORMAT)
        : ' - ';

      if (design.in_hands_date) {
        ship_by_date = `<span class="has-color-error">${ship_by_date}</span>`;
      }

      let printer = (design && design.printer && design.printer.code) || '  ';

      let designName = design.name;
      if (design.printed_before_order_id || design.printed_before_design_id) {
        designName += ' (Reprint)';
      } else if (design.reference_order_id || design.reference_design_id) {
        designName += ' (Reference)';
      }

      let designDetails = [
        { column: 'id', value: design.id },
        { column: 'Order', value: '  ' },
        {
          column: 'Account/Design',
          value: designName,
          alignLeft: true
        },
        { column: 'Date', value: ' - ' },
        {
          column: 'Print By',
          value: print_by_date
        },
        { column: 'Ship By', value: ship_by_date }
      ];

      designDetails.push({ column: 'Printer', value: printer });
      designDetails.push({ column: 'CSR', value: '  ' });
      designDetails.push({ column: 'Art', value: '  ' });
      designDetails.push({
        column: 'Des/Loc',
        value:
          design.designable && design.designable.locations
            ? design.designable.locations.length
            : ' - '
      });
      designDetails.push({ column: 'PD', value: '  ' });

      designDetails.push({ column: 'Notes', value: ' - ' });

      let isSpecificService = [BLANK_SERVICE_CODE, DIGITAL_SERVICE_CODE, BUTTON_SERVICE_CODE, STICKER_SERVICE_CODE, POSTER_SERVICE_CODE, INVOICE_SERVICE_CODE]
        .includes(design.service);

      progressFieldsList.forEach(progressType => {
        if (isSpecificService) {
          switch (design.service) {
            case INVOICE_SERVICE_CODE:
              switch (progressType) {
                case 'progress_setup':
                case 'progress_blank':
                case 'progress_mock':
                case 'progress_print':
                case 'progress_shipment':
                case 'progress_finish':
                  designDetails.push({
                    column: 'Phase',
                    value: `<div class="${gridHelper.getProgressIcon(
                      design[progressType],
                      true
                    )}"></div>`
                  });
                  break;
              }
              break;
            case BLANK_SERVICE_CODE:
              switch (progressType) {
                case 'progress_blank':
                  designDetails.push({
                    column: 'Phase',
                    value: `<div class="${gridHelper.getProgressIcon(
                      design[progressType],
                      true,
                      +design.blank_status === +BLANK_STATUS_STOCK_ISSUE
                    )}"></div>`
                  });
                  break;
                case 'progress_setup':
                case 'progress_mock':
                case 'progress_print':
                  designDetails.push({
                    column: 'Phase',
                    value: `<div class="${gridHelper.getProgressIcon(
                      design[progressType],
                      true
                    )}"></div>`
                  });
                  break;
                default:
                  designDetails.push({
                    column: 'Phase',
                    value: `<div class="${gridHelper.getProgressIcon(
                      design[progressType]
                    )}"></div>`
                  });
                  break;
              }
              break;
            case DIGITAL_SERVICE_CODE:
            case STICKER_SERVICE_CODE:
            case POSTER_SERVICE_CODE:
              switch (progressType) {
                case 'progress_blank':
                  designDetails.push({
                    column: 'Phase',
                    value: `<div class="${gridHelper.getProgressIcon(
                      design[progressType],
                      true,
                      +design.blank_status === +BLANK_STATUS_STOCK_ISSUE
                    )}"></div>`
                  });
                  break;
                default:
                  designDetails.push({
                    column: 'Phase',
                    value: `<div class="${gridHelper.getProgressIcon(
                      design[progressType]
                    )}"></div>`
                  });
                  break;
              }
              break;
            case BUTTON_SERVICE_CODE:
              switch (progressType) {
                case 'progress_blank':
                  designDetails.push({
                    column: 'Phase',
                    value: `<div class="${gridHelper.getProgressIcon(
                      design[progressType],
                      true,
                      +design.blank_status === +BLANK_STATUS_STOCK_ISSUE
                    )}"></div>`
                  });
                  break;
                case 'progress_mock':
                  designDetails.push({
                    column: 'Phase',
                    value: `<div class="${gridHelper.getProgressIcon(
                      design[progressType],
                      true
                    )}"></div>`
                  });
                  break;
                default:
                  designDetails.push({
                    column: 'Phase',
                    value: `<div class="${gridHelper.getProgressIcon(
                      design[progressType]
                    )}"></div>`
                  });
                  break;
              }
              break;
          }
        } else {
          if (progressType === 'progress_blank') {
            designDetails.push({
              column: 'Phase',
              value: `<div class="${gridHelper.getProgressIcon(
                design[progressType],
                false,
                +design.blank_status === +BLANK_STATUS_STOCK_ISSUE
              )}"></div>`
            });
          } else {
            designDetails.push({
              column: 'Phase',
              value: `<div class="${gridHelper.getProgressIcon(
                design[progressType]
              )}"></div>`
            });
          }
        }
      });

      designDetails.push({
        column: 'Messages',
        value: '  '
      });

      designDetails.push({
        column: 'Total',
        value: '  ',
        roles: teamMembers
      });

      designDetails.push({
        column: 'Status',
        value: '  '
      });
      return designDetails;
    });
  });
  return details;
};

export const getGridOrderFinishings = orders => {
  let allFinishings = {};
  orders.forEach(order => {
    let orderFinishings = {};
    if (!order.designs) return;

    order.designs.map(design => {
      if (!design.designable.finishings) return;

      let designName = design.name;
      design.designable.finishings.forEach(finishing => {
        if (orderFinishings[finishing.id]) {
          orderFinishings[finishing.id].designNames += ', ' + designName;
          orderFinishings[finishing.id].label = design.redo_design_id
            ? finishing.label + '-R'
            : finishing.label;
        } else {
          orderFinishings[finishing.id] = {
            finishing,
            label: design.redo_design_id
              ? finishing.label + '-R'
              : finishing.label,
            designNames: designName
          };
        }
      });
    });

    if (!Object.keys(orderFinishings).length) return;

    allFinishings[order.id] = Object.keys(orderFinishings).map(key => {
      let finishing = orderFinishings[key].finishing;

      let data = [
        { column: 'id', value: finishing.id },
        { column: 'Order', value: '   ' },
        {
          column: 'Account/Design',
          value: orderFinishings[key].label || finishing.label,
          alignLeft: true
        },
        { column: 'Date', value: ' - ' },
        { column: 'Print By', value: ' - ' },
        { column: 'Ship By', value: ' - ' },
        { column: 'Printer', value: '   ' },
        { column: 'CSR', value: '   ' },
        { column: 'Art', value: '   ' },
        {
          column: 'Des/Loc',
          value: orderFinishings[key].designNames
        },
        { column: 'PD', value: '   ' },
        { column: 'Notes', value: ' - ' }
      ];

      progressFieldsList.forEach(progressType => {
        let progress = null;

        switch (progressType) {
          case 'progress_print':
            progress = finishing.printer_status * 50;
            break;
          case 'progress_setup':
            progress = !!finishing.original_files_link * 100;
            break;
        }

        data.push({
          column: 'Phase',
          value:
            progress !== null
              ? `<div class="${gridHelper.getProgressIcon(progress)}"></div>`
              : '-'
        });
      });

      data.push({
        column: 'Total',
        value: '   ',
        roles: teamMembers
      });

      data.push({
        column: 'Status',
        value: '   '
      });

      return data;
    });
  });
  return allFinishings;
};
