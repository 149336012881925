<template>
  <apparelDesign
    :designCommon="designCommon"
    :designSpecific="designSpecific"
    :isEmbroidery="true"
    :designKey="designKey"
    :upsServices="upsServices"
    :deliveryTypes="deliveryTypes"
    :resetKey="resetKey"
    :finishings="finishings"
    :finishingOptions="finishingOptions"
    :accountId="accountId"
    :index="index"
    :getScope="getScope"
    :isEdit="isEdit"
    :account="account"
    ref="design"
    @summary-changed="onSummaryChanged"
  ></apparelDesign>
</template>

<script>
import Vue from 'vue';
import apparelDesign from '../design-types/apparel';

export default {
  props: {
    getScope: {
      type: Function,
      default: () => () => ''
    },
    designCommon: {
      type: Object,
      default: () => {}
    },
    designSpecific: Object,
    account: Object,
    finishings: Array,
    finishingOptions: Array,
    designKey: Number,
    isEmbroidery: Boolean,
    upsServices: Array,
    deliveryTypes: Array,
    isEdit: Boolean,
    resetKey: Number,
    accountId: Number,
    index: Number
  },
  components: {
    apparelDesign
  },
  methods: {
    setDesign() {
      return this.$refs.design.setDesign();
    },

    onSummaryChanged(designKey, summary) {
      this.$emit('summary-changed', designKey, summary);
    }
  }
};
</script>
