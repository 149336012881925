import axios from "axios";

import('./interceptors');
import('./validators');

import Vue from 'vue';
import router from './router';
import * as Sentry from "@sentry/vue";
import store from './store';
import pubNubService from './services/pubnub.service';
import s3Service from './services/s3.service';
import NProgress from 'vue-nprogress';
import alertify from 'alertify.js';
import VueAwesomeSwiper from 'vue-awesome-swiper';
import application from './components/app.vue';
alertify.logPosition('top right');

import { sync } from 'vuex-router-sync';
sync(store, router);

import './globals';
import './directives';
import './filters';
import 'swiper/dist/css/swiper.css';
import 'please-wait/build/please-wait.css';
import 'spinkit/css/spinkit.css';

import.meta.glob([
  '../images/**',
  '../fonts/**',
]);
Vue.use(VueAwesomeSwiper);
Vue.use(NProgress, {
  router: false
});
const nprogress = new NProgress({
  parent: 'body'
});

import { GET_USER_DATA } from './store/action-types';

function initApp() {
  // Init App
  Vue.use(pubNubService, {
    store
  });
  Vue.use(s3Service, {
    store
  });

  Sentry.init({
    Vue,
    dsn: import.meta.env.VITE_SENTRY_LARAVEL_DSN,
    environment: import.meta.env.VITE_SENTRY_ENVIRONMENT,
    integrations: [
      Sentry.replayIntegration(),
    ],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });


  const App = Vue.extend(import('./components/app.vue'));
  new App({
    name: 'main-app',
    // el: '#app',
    store,
    nprogress,
    router,  render: h => h(application)
}).$mount('#app')
}


function jwtInterceptor() {
    Vue.http.interceptors.push(function (request, next) {
        // add auth header with jwt if account is logged in and request is to the api url
        const token = localStorage.getItem('jwt-token');
        if (token) {
            request.headers.set(
                'Authorization',
                `Bearer ${token}`
            );
        }
        next();
    });
}

// The app has just been initialized,
const token = localStorage.getItem('jwt-token');
const user = localStorage.getItem('user');
if (token && user) {
    jwtInterceptor();
  // check if we can get the user data with an already existing token
  store
    .dispatch(GET_USER_DATA)
    .then(initApp)
    .catch(err => {
      store.commit('destroyLogin');
      initApp();
      console.log(err);
    });
} else {
  store.commit('destroyLogin');
  initApp();
}
