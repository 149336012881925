/**
 * Calculate design total.
 *
 * @param design
 * @param designData
 * @returns {number}
 */
export const getDesignTotal = (design, designData) => {
  let total = 0;
  let designItem = design.specific.item;
  let printType = designItem.print_type;
  let sizeId = designItem.size;
  let quantity = null;
  if (designItem.quantity) {
    // Find quantity by id for all print types excluding Screen Printed Posters
    if (printType === 4) {
      quantity = designItem.quantity;
    } else {
      quantity = designData.print_type_options[printType].quantities.find(q => +q.key === +designItem.quantity).label
        .replace(/[^0-9 | ^.]/g, '');
    }
  }
  if (!printType || !sizeId || !quantity) {
    total += 0;
  } else if (designItem.cost !== undefined && designItem.cost !== null) {
    total += +designItem.cost;
  } else if (designItem.size === 'empty_size' && quantity) {
    total += quantity * designItem.custom_unit_price;
  } else if (
    printType &&
    designData.upcharges.by_size &&
    designData.upcharges.by_size[printType] !== undefined
  ) {
    let upchargeSizeObj = designData.upcharges.by_size[printType].filter(
      upcharge => {
        return (upcharge.paper_print_size_id === sizeId &&
          (
            upcharge.quantity.from <= quantity &&
            (upcharge.quantity.to >= quantity || upcharge.quantity.to === null)
            && (!designItem.number_of_colors ||
              upcharge.paper_colors_number_id === designItem.number_of_colors)
          )
        );
      }
    )[0];

    if (upchargeSizeObj !== undefined) {
      let upcharge = parseFloat(upchargeSizeObj.upcharge) || 0;
      total += upcharge * quantity;

      if (designItem.has_rounded_corners &&
        designData.upcharges.rounded_corners &&
        designData.upcharges.rounded_corners[printType] !== undefined
      ) {
        let upchargeCornersObj = designData.upcharges.rounded_corners[printType][0];

        if (upchargeCornersObj !== undefined) {
          total += quantity * (parseFloat(upchargeCornersObj.upcharge) || 0);
        }
      }
    }
  }

  return total + +design.common.upcharge;
};
