<template>
  <div>
    <hr>
    <h2 class="is-grayed-title">SHIPPING INFORMATION</h2>
    <table class="table is-fullwidth">
      <thead>
      <tr>
        <th>Shipping #</th>
        <th>Recipient</th>
        <th>Address</th>
        <th>Garment</th>
        <th>Sizes</th>
      </tr>
      </thead>
      <tbody>
      <template v-for="(designShipping, index) in designShippingsFiltered">
        <tr v-for="shippingItem in designShipping.shipping_items">
          <td>
            <div>
              <div v-role-access="['admin', 'csr', 'production-coordinator', 'purchaser', 'art-producer','developer']">Shipping #{{designShipping.number}}</div>
              <div v-role-access="['printer']">Shipping #{{index+1}}</div>
              <span v-if="designShipping.type_of_delivery === 'fulfillment'">**HOLD FOR FULFILLMENT**</span>
              <span v-if="designShipping.type_of_delivery === 'pickup'">**PICKUP**</span>
            </div>
          </td>
          <template>
            <td v-if="designShipping && designShipping.shipping_address && designShipping.shipping_address.recipient">{{designShipping.shipping_address.recipient}}</td>
            <td v-else>
              {{order.contact_firstname + ' ' + order.contact_lastname}}
            </td>
          </template>
          <template>
            <td v-if="hasAddress(designShipping)">
              <dl>
                <dd v-if="designShipping.shipping_address.address_1">{{designShipping.shipping_address.address_1 }}{{designShipping.shipping_address.address_2 ? ' ,' + designShipping.shipping_address.address_2 : '' }}</dd>
                <dd>{{designShipping.shipping_address.city}}, {{designShipping.shipping_address.state_id}} {{designShipping.shipping_address.postal_code}}, {{designShipping.shipping_address.country_id}}</dd>
              </dl>
            </td>
            <td v-else>N/A</td>
          </template>
          <td>
            {{getGarmentLine(shippingItem.garment_id)}}
          </td>
          <td>
            {{getSizesString(shippingItem.sizes)}}
          </td>
        </tr>
      </template>
      </tbody>
    </table>

  </div>
</template>

<script>
import { cloneDeep } from 'lodash';

export default {
  name: 'design-details-shippings',
  components: {},
  props: {
    order: {
      type: Object,
      required: true
    },
    design: {
      type: Object,
      required: true
    },
    designShippings: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      sortedSizes: []
    };
  },
  computed: {
    designShippingsFiltered() {
      let designShippings = cloneDeep(this.designShippings);
      return designShippings.map(ds => {
        ds.shipping_items = ds.shipping_items.filter(
          si => +si.design_id === +this.design.id
        );
        return ds;
      });
    }
  },
  methods: {
    getGarmentLine(gId) {
      if (!gId) return 'N/A';
      let garment = this.design.designable.garments.find(g => +g.id === +gId);
      return (
        (garment &&
          garment.product_object &&
          garment.product_object.number &&
          garment.brand_object &&
          garment.brand_object.name &&
          `${garment.brand_object.name} ${garment.product_object.number} (${
            garment.color
          })`) ||
        'N/A'
      );
    },
    getSizesString(sizesJson) {
      if (!sizesJson) return 'N/A';
      let sizes = JSON.parse(sizesJson);
      const sortedSizes = this.sortedSizes
        .filter(size => Object.keys(sizes).includes(size))
        .map(size => [size, sizes[size]]);

      const sizesString = sortedSizes
        .filter(item => +item[1] > 0) // filter empty sizes
        .map(item => item.join('-'))
        .join(', ');

      const sizesTotalQty = Object.values(sizes).reduce(
        (result, i) => result + parseInt(i, 10),
        0
      );
      return sizesString + ` = ${sizesTotalQty}`;
    },
    hasAddress(shipping) {
      return (
        (shipping.shipping_address &&
          shipping.shipping_address.address_1 &&
          shipping.shipping_address.city &&
          shipping.shipping_address.postal_code &&
          shipping.shipping_address.state_id &&
          shipping.shipping_address.country_id) ||
        false
      );
    }
  },
  mounted() {
    this.sortedSizes = cloneDeep(this.$store.getters.sortedGarmentsSizes);
  }
};
</script>
