<template>
  <div v-if="transaction.invoice" class="container has-text-centered payment-status">
    <p>
      <img v-if="need_to_pay > 0" src="@/../images/icons/payment_partial.png" width="81" height="75"/>
      <img v-else src="@/../images/icons/payment_success.png" width="81" height="75"/>
    </p>
    <div v-if="need_to_pay > 0" class="page-header">
      <p>Partial Payment Successful</p>
    </div>
    <div v-else class="page-header">
      <p>Thank you!</p>
      <p>Payment Successful</p>
    </div>
    <div class="payment-info-block column is-4 is-offset-4">
      <div class="payment-details">
        <div class="header">Payment summary</div>
        <div class="columns">
          <div class="column is-4 left">Payment type</div>
          <div class="column is-8 right">{{paymentType}}</div>
        </div>
        <div class="columns">
          <div class="column is-4 left">Transaction ID</div>
          <div class="column is-8 right">{{ transaction.transaction_id }}</div>
        </div>
      </div>
      <div class="has-separator"></div>
      <div class="amount-details ">
        <div class="columns">
          <div class="column is-4 left">Total cost</div>
          <div class="column is-8 right">{{ transaction.invoice.amount | price }}</div>
        </div>
        <div class="columns">
          <div class="column is-4 left">Amount Paid</div>
          <div class="column is-8 right large">{{ transaction.amount | price }}</div>
        </div>
      </div>
    </div>
    <div :class="[need_to_pay>0 ? 'partial':'']" class="balance-due-block column is-4 is-offset-4">
      <div class="balance-due">
        <div class="columns">
          <div class="column is-4 left">Balance Due</div>
          <div class="column is-8 right large">{{ need_to_pay | price }}</div>
        </div>
      </div>
    </div>
    <div class="buttons action-buttons is-justify-content-center">
      <router-link class="button is-rounded pt-5 pb-5 has-text-weight-bold is-size-5 mt-6" :to="`/customer/orders/${transaction.invoice.order_id}/details`">
        View Order Details
      </router-link>
      <router-link class="button white is-rounded pt-5 pb-5 has-text-weight-bold is-size-5 mt-6" v-if="need_to_pay>0" :to="'/customer/orders/' + transaction.invoice.order_id + '/invoice/' + transaction.invoice.link_hash">Make Another Payment</router-link>
      <router-link class="button white is-rounded pt-5 pb-5 has-text-weight-bold is-size-5 mt-6" :to="`/`" v-else>Go to Dashboard</router-link>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.container {
  margin-top: 4.1rem;
  color: #353544;

  .page-header {
    font-size: 2.15rem;
    font-weight: bold;
    margin-top: 2rem;
    line-height: 3.5rem;
  }

  .payment-info-block {
    box-shadow: 0 0 0.7rem rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
    border-radius: 2rem 2rem 0 0;
    margin-top: 2rem;
    padding: 1.5rem 2rem 0;

    .column {
      margin-bottom: 0;
      padding-top: 0;
    }

    .columns {
      margin-bottom: 0;
    }

    .payment-details {
      font-size: 0.85rem;
      line-height: 2rem;
      color: #B5B6C6;

      .header {
        width: 100%;
        text-align: left;
        font-weight: 700;
        font-size: 1rem;
        //line-height: 4.3px;
        color: #353544;
        padding-bottom: 0.7rem;
      }
    }

    .amount-details {
      padding-top: 1rem;
      color: #353544;
      line-height: 2.15rem;
      font-weight: 700;

      .large {
        font-size: 1.15rem;
      }
    }
  }

  .balance-due-block {
    background-color: #def4fd;
    border-radius: 0 0 2rem 2rem;
    box-shadow: 0 0 0.7rem rgba(0, 0, 0, 0.1);
    padding: 1.4rem 2rem;
    color: #009BDF;
    font-size: 1.15rem;
    font-weight: 700;
    line-height: 2.15rem;

    &.partial{
      background-color: #fde582;
      color: #353544;
    }

    .large {
      font-size: 1.85rem
    }
  }

  .left {
    text-align: left;
  }

  .right {
    text-align: right;
  }
}
</style>

<script>
import apiService from '../../../../services/api.service';

export default {
  name: 'order-invoices-payment-success',
  data() {
    return {
      transaction: {}
    };
  },
  computed: {
    need_to_pay: function () {
      return Math.round((this.transaction.invoice.amount - this.transaction.invoice.paid_sum) * 100) / 100
    },
    paymentType: function (){
      if (!this.transaction.type) return '';
      switch (this.transaction.type) {
        case 1:
          return 'Authorize.net';
        case 3:
          return 'Paypal';
        case 4:
          return this.transaction.paymentMethod?this.transaction.paymentMethod:'Authorize.net';
        default:
          return 'Manual';
      }
    }
  },
  mounted() {
    apiService.getTransaction(this.$route.params.transactionId).then(data => {
      this.transaction = data.transaction;
      this.transaction.paymentMethod = data.paymentMethod;
    });
  },
};
</script>

