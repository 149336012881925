<template>
  <div>
    <p>Please add a message</p>
    <textarea class="textarea" v-model="message"></textarea>
  </div>
</template>

<script>
  import Vue from 'vue';
  import {REQUEST_REVISIONS} from '../../../store/action-types';
  import apiService from '../../../services/api.service';
  import alertify from 'alertify.js';
  import {
    mapGetters,
    mapActions
  } from 'vuex';

  import {UPDATE_PRODUCTION_NOTES} from '../../../store/action-types';

  export default {
    name: 'add-production-nodes',
    props: ['additional'],
    data() {
      return {
        message: this.additional.message,
      }
    },
    methods: {
      ...mapActions({
        updateProductionNotes: UPDATE_PRODUCTION_NOTES
      }),
      submitForm() {
        let data = {
          id: this.additional.id,
          data: {
            message: this.message,
            entity: this.additional.type,
            isEstimate: this.additional.isEstimate
          },
        };
        this.updateProductionNotes(data).then(() => {
          if( typeof this.additional.callback == 'function'){
            this.additional.callback(this.message, this.additional.type);
          }
          this.$emit('close');
        });
      }
    }
  };
</script>
