<template>
  <nav class="breadcrumb has-succeeds-separator">
    <ul>
      <template v-for="(link, key) in links">
        <li>
          <router-link v-if="link.url" :to="link.url">{{ link.label }}</router-link>
          <a v-else>{{ link.label }}</a>
        </li>
      </template>
    </ul>
  </nav>
</template>

<script>
export default {
  name: 'breadcrumbs',
  props: ['links']
};
</script>
