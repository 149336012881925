<template>
  <div class="main-info">
    <breadcrumbs v-if="isCustomer" :links="crumbLinks"/>
    <div v-if="!isCustomer">Order
      <router-link :to="`/orders/${order.id}/details`">#{{ order.id }}</router-link>
    </div>
    <div v-if="reprintOrderId" class="printed-before">
      <span>Design has been printed before: <strong>Reprint - <a :href="reprintOrderLink">{{`#${reprintOrderId}`}}</a></strong></span>
    </div>
    <div v-if="referenceOrderId" class="printed-before">
      <span>Reference design has been printed before: <strong>Reference - <a :href="referenceOrderLink">{{`#${referenceOrderId}`}}</a></strong></span>
    </div>
    <h1 class="title">{{ accountName }}</h1>
  </div>
</template>

<script>
import { checkRoles } from '../../../router/checkRoles';
import store from '../../../store';
import breadcrumbs from '../../common/breadcrumbs';

export default {
  name: 'main-info',
  props: {
    order: {
      type: Object,
      required: true
    },
    design: {
      type: Object,
      required: true
    }
  },
  components: {
    breadcrumbs
  },
  computed: {
    accountName: function() {
      let accountName = '';

      if (this.order && this.order.user) {
        accountName = this.order.user.company
          ? this.order.user.company.name
          : this.order.user.title;
      } else {
        accountName =
          this.order.contact_firstname + ' ' + this.order.contact_lastname;
      }
      return accountName;
    },
    isCustomer() {
      return checkRoles(store.getters.roles, ['customer'], true);
    },
    crumbLinks() {
      return [
        {
          url: '/customer/dashboard',
          label: 'Dashboard'
        },
        {
          label: 'Order #' + this.order.id,
          url: `/customer/orders/${this.order.id}/details`
        }
      ];
    },
    reprintOrderId() {
      return this.design && this.design.printed_before_order_id;
    },
    reprintOrderLink() {
      let link = `orders/${this.reprintOrderId}/details`;
      return this.isCustomer ? `#/customer/${link}` : `#/${link}`;
    },
    referenceOrderId() {
      return this.design && this.design.reference_order_id;
    },
    referenceOrderLink() {
      let link = `orders/${this.referenceOrderId}/details`;
      return this.isCustomer ? `#/customer/${link}` : `#/${link}`;
    }
  }
};
</script>

<style scoped>
.main-info strong {
  color: #999999;
}
.main-info h1 {
  font-size: 30px;
  font-weight: bold;
  margin: 0;
}
.main-info .printed-before strong {
  color: #363636;
}
</style>
