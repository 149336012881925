<template>
  <tr>
    <td colspan="3">
      <invoice-field
        label="Product"
        :value="productInformation"
      />
    </td>
    <td>
      <invoice-field
        label="Quantity"
        :value="item.quantity"
      />
    </td>
    <td>
      <invoice-field
        label="Price"
        :value="parseFloat(item.cost)/parseFloat(item.quantity) | price"
      />
    </td>
  </tr>
</template>
<script>
import {getOptionLabel} from "@/helpers/estimates";

import InvoiceField from '@/components/common/invoice-field.vue';

export default {
  name: 'PromotionalDesignInvoiceItem',
  components: {InvoiceField},
  props: {
    item: {
      type: Object,
      required: true
    },
    formOptions: {
      type: Object,
      required: true
    },
  },
  methods: {
    getOptionLabel,
  },
  computed: {
    productInformation(){
      return ` ${this.item.model.product_brand.name } ${ this.item.model.number } ${ this.item.model.name }`
    },
    numberOfColorsLabel() {
      if (this.isPetProduct && !this.isPetToysCategory) {
        return 'Pet Product Size';
      }
      if (this.isShoelacesProduct) {
        return 'Shoelace Options';
      }
      if (this.isCuffBeanieProduct) {
        return 'Beanie Option';
      }
      if (this.isScarfsProduct) {
        return 'Scarfs';
      }

      return 'Number of Colors';
    },
    isPetProduct() {
      return this.item.model.product_type && this.item.model.product_type.name === 'Pet Products';
    },
    isCustomProduct(){
      return this.item.model.product_type && this.item.model.product_type.name === 'Custom Products'
    },
    isPetToysCategory() {
      return this.isPetProduct && this.item.model
        && this.item.model.product_category && this.item.model.product_category.name === 'Toys'
    },
    isShoelacesProduct() {
      return this.isCustomProduct && this.item.model && this.item.model.product_category && this.item.model.product_category.name === 'Shoelaces'
    },
    isCuffBeanieProduct() {
      return this.isCustomProduct && this.item.model && this.item.model.product_category && this.item.model.product_category.name === 'Cuff Beanie'
    },
    isScarfsProduct() {
      return this.isCustomProduct && this.item.model && this.item.model.product_category && this.item.model.product_category.name.trim() === 'Scarfs'
    },
  },
}
</script>
