<template>
  <div>
    <h5 class="subtitle">Create Account</h5>
    <account-form ref="accountForm"
      :model="account"
      :roles="allowedRoles"
      :options="options"
      :errorsList="errorsList"
      :processing="processing"
      :hideSubmitBtn="popupBody"
      @submitForm="submit"
      @accountChanged="accountChanged"
      v-if="!isLoading"
    ></account-form>
    <div v-else>Loading...</div>
  </div>
</template>

<script>
import Vue from 'vue';
import { map } from 'lodash';
import { mapGetters, mapActions } from 'vuex';
import { checkRoles } from '../../router/checkRoles';
import filter from 'lodash/filter';

import { CREATE_ACCOUNT, LOAD_ACCOUNT_FORM } from '../../store/action-types';
import alertify from 'alertify.js';
import bus from '../../bus';

//form
import accountForm from './form/account-form';

export default {
  name: 'create-account',
  data() {
    return {
      isLoading: false,
      account: {
        role: '',
        user: {
          first_name: '',
          last_name: '',
          title: '',
          email: '',
          phone: '',
          terms: 'request-payment',
          company_id: '',
          company_name: '',
          password: '',
          is_test: false
        }
      },
      options: {
        submitBtnText: 'Create',
        hideRole: false
      },
      errorsList: [],
      processing: false
    };
  },
  props: {
    additional: {
      type: Object,
      default: () => null
    },
    popupBody: {
      type: Boolean
    }
  },
  components: {
    accountForm
  },
  computed: {
    ...mapGetters({
      roles: 'systemRoles',
      userRoles: 'roles'
    }),
    allowedRoles() {
      //remove admin role for non admin users
      let allowedRoles = this.roles;
      if (!checkRoles(this.userRoles, ['admin'])) {
        allowedRoles = filter(this.roles, r => {
          return r.key != 'admin';
        });
      }
      return allowedRoles;
    }
  },
  methods: {
    ...mapActions({
      createAccount: CREATE_ACCOUNT,
      loadAccountForm: LOAD_ACCOUNT_FORM
    }),
    submitForm() {
      return new Promise((res, rej) => {
        bus.$emit('validateAndSubmit');
        res();
      }).catch(err => {
        rej();
      });
    },
    submit() {
      this.processing = true;
      return new Promise((resolve, reject) => {
        this.createAccount(this.account)
          .then(res => {
            alertify.success('Account Saved');

            if (this.additional) {
              this.additional.popupCallback(res.user_id);
              this.$emit('close');
            } else {
              this.$router.push(`/accounts`);
            }

            this.processing = false;
            resolve(res);
          })
          .catch(err => {
            if (err.status == 422 && err.data.errors) {
              alertify.error(
                'Some validation errors have been detected. Please check error messages.'
              );
              let errors = map(err.data.errors, (messages, fullName) => {
                let chunks = fullName.split('.');
                let name = chunks.splice(chunks.length - 1, 1);
                let scope = chunks.join('-');

                return {
                  field: name[0],
                  msg: messages.join('\n'),
                  rule: 'required',
                  scope: scope,
                  fullName: fullName
                };
              });

              this.$set(this, 'errorsList', errors);
            } else {
              let msg =
                err.body && err.body.error
                  ? err.body.error
                  : 'Something wrong.';
              alertify.error(msg);

              if (err.body && err.body.code && err.body.code == -1) {
                //email error code
                alertify.success('Account Created');

                if (this.additional) {
                  this.additional.popupCallback(err.body.user_id);
                  this.$emit('close');
                } else {
                  this.$router.push('/accounts');
                }
              }
            }

            this.processing = false;
            reject(err);
          });
      });
    },
    accountChanged(account) {
      this.$set(this, 'account', account);
    }
  },
  mounted() {
    if (this.additional) {
      this.account.role = this.additional.role;
      this.options.hideRole = true;
    }
    this.isLoading = true;
    this.loadAccountForm().then(() => {
      this.isLoading = false;
    });
  }
};
</script>
