<template>
   <div v-if="addons && Object.keys(addonTypesComputed).length">
     <header class="step-section__header">
       <h3 class="step-section__title">{{ 'Addons' | uppercase }}</h3>
     </header>
      <div v-for="addonType in Object.keys(addonTypesComputed)">
        <header class="step-section__subheader">
          <h4 class="step-section__subheader__title">{{addonType}}</h4>
        </header>
        <div class="step-section__body" v-if="addonTypesComputed.hasOwnProperty(addonType)">
          <form-row v-for="addon in addonTypesComputed[addonType]" :key="addon.id">
            <form-checkbox
              class="form-item"
              :name="`addon_name-${addon.id}`"
              :title="addonName(addon.product_addon_name)"
              @change="addonsChanged($event, addon.product_addon_name)"
              :checked="!!designAddons.find(a => a.product_addon_name === addon.product_addon_name)">
            </form-checkbox>
          </form-row>
        </div>
      </div>
   </div>
</template>

<script>
import apiService from '@/services/api.service';
import { replace } from 'lodash';

export default {
  name: 'ProductAddons',
  props: {
    designAddons: {
      type: Array,
      required: true
    },
    productId: {
      type: Number,
      required: true
    }
  },
  watch: {
    productId: {
      handler: function(val) {
        apiService
          .productAddons(val)
          .then(res => {
            this.$set(this, 'addons', res);
          })
          .catch(err => {
            console.log(err);
          });
      },
      immediate: true
    }
  },
  computed: {
    addonTypesComputed() {
      if (!this.addons) return {};
      let types = {};
      this.addons.forEach(a => {
        if (a.product_addon_type_name) {
          if (!types.hasOwnProperty(a.product_addon_type_name)) {
            types[a.product_addon_type_name] = [];
          }
          types[a.product_addon_type_name].push(a);
        }
      });
      return types;
    }
  },
  methods: {
    addonName(addonName) {
      return replace(addonName, '&quot;', '"');
    },
    addonsChanged($event, val) {
      let addon = this.addons.find(a => a.product_addon_name === val);
      if (addon) {
        this.$emit('addonsChanged', $event, addon);
      }
    }
  },
  data() {
    return {
      addons: null
    };
  }
};
</script>
