<template>
  <div class="form-section form-section-designs" ref="wrapper">
    <div class="form-section__header">
      <h2 v-if="value.common.service === 'non_order_invoice'" class="form-section__title">Reference</h2>
      <h2 v-else class="form-section__title">Design {{ index + 1 }}</h2>
      <div class="form-section__actions">
        <a
          v-if="canReprint"
          href="#"
          @click.prevent="reprintDesignModal('reference')"
        >Reference</a
        >
        <a
          v-if="canReprint"
          href="#"
          @click.prevent="reprintDesignModal('reprint')"
        >Reprint</a
        >
        <a
          href="#"
          class="clr-destructive"
          @click.prevent="deleteDesign"
          v-if="isDesignReprint || parentForm.designs.length > 1"
        >Delete Design</a
        >
      </div>
    </div>
    <div class="step-section">
      <div class="step-section__body">
        <form-row>
          <form-multiselect
            v-model="serviceModel"
            :options="services"
            :required="true"
            label="Service"
            :config="{
              trackBy: 'key',
            }"
            :scope="scope"
            :disabled="isDesignReference || isDesignReprint || isRedoDesign"
            name="service"
          ></form-multiselect>
          <form-item
            class="x2"
            v-model="value.common.name"
            name="name"
            :required="true"
            :label="value.common.service === 'non_order_invoice' ? 'Reference Name' : 'Design Name'"
            :scope="scope"
            :focusOnAppear="
              !value.common.name || value.common.name.length === 0
            "
          ></form-item>
        </form-row>
      </div>
    </div>

    <div class="step-section" v-if="value.common.service === 'non_order_invoice'">
      <header class="step-section__header">
        <h3 class="step-section__title">{{ 'Items' | uppercase }}</h3>
      </header>

      <draggable
        :list="value.specific.items"
        :disabled="!dragable.enabled"
        class="list-group"
        ghost-class="ghost"
        @start="dragable.dragging = true"
        @end="dragable.dragging = false"
      >
        <transition-group type="transition" name="flip-list">
          <div class="list-group-item step-section__body step-section__item" v-for="item in value.specific.items"
               :key="item.uniqueId">
            <a
              href="#"
              class="step-section__item-delete clr-destructive"
              @click.prevent="deleteItem(item.uniqueId)"
              v-if="value.specific.items.length > 1"
            >Delete Design</a>
            <form-row class="fixed-3">
              <form-item
                label="Item Name"
                name="itemName"
                :scope="scope"
                :required="true"
                v-model="item.name"
                :disabled="isDesignReprint"
              ></form-item>
              <form-item
                label="Price"
                name="itemPrice"
                :scope="scope"
                :money="{
              decimal: '.',
              thousands: ',',
              prefix: '$ ',
              precision: 2
            }"
                v-model="item.price"
                :disabled="isDesignReprint"
              ></form-item>
              <form-item
                label="Quantity"
                name="itemQuantity"
                :scope="scope"
                :required="true"
                v-model="item.quantity"
                rules="decimal:2|max_value:10000"
              ></form-item>
            </form-row>
          </div>
        </transition-group>
      </draggable>
      <div class="step-section__actions" v-if="!isDesignReprint">
        <a href="#" class="link-add" @click.prevent="addNonOrderInvoiceItem">
          <span class="link-add__icon"></span>
          Add Item
        </a>
      </div>
    </div>

    <div class="step-section" v-if="value.common.service === 'other'">
      <header class="step-section__header">
        <h3 class="step-section__title">{{ 'Design Items' | uppercase }}</h3>
      </header>
      <div
        class="step-section__body step-section__item"
        v-for="item in value.specific.items"
        :key="item.uniqueId"
      >
        <a
          href="#"
          class="step-section__item-delete clr-destructive"
          @click.prevent="deleteItem(item.uniqueId)"
          v-if="value.specific.items.length > 1"
        >Delete Design</a
        >
        <form-row class="fixed-3">
          <form-item
            label="Item Name"
            name="itemName"
            :scope="scope"
            :required="true"
            v-model="item.name"
            :disabled="isDesignReprint"
          ></form-item>
          <form-item
            label="Price"
            name="itemPrice"
            :scope="scope"
            :money="{
              decimal: '.',
              thousands: ',',
              prefix: '$ ',
              precision: 2,
            }"
            v-model="item.price"
            :disabled="isDesignReprint"
          ></form-item>
          <form-item
            label="Quantity"
            name="itemQuantity"
            :scope="scope"
            :required="true"
            v-model="item.quantity"
            rules="numeric|max_value:10000"
          ></form-item>
        </form-row>
        <form-row>
          <form-item
            type="textarea"
            label="Description"
            name="itemDescription"
            :scope="scope"
            :required="true"
            v-model="item.description"
            rules="max:1000"
          ></form-item>
        </form-row>
      </div>
      <div class="step-section__actions" v-if="!isDesignReprint">
        <a href="#" class="link-add" @click.prevent="addOtherItem">
          <span class="link-add__icon"></span>
          Add Design Item
        </a>
      </div>
    </div>

    <!-- Custom Fields -->
    <div
      class="step-section custom-fields"
      v-if="
        !['apparel', 'blank', 'embroidery', 'other', 'non_order_invoice'].includes(
          value.common.service
        )
      "
    >
      <div class="step-section__body">
        <!--        //todo: remove-->
        <form-row class="fixed-3" v-if="value.common.service === 'digital'">
          <form-multiselect
            v-model="printTypeModel"
            :options="formData.printTypes"
            :required="true"
            label="Category"
            :config="{
              trackBy: 'key',
            }"
            :scope="scope"
            name="category"
            :disabled="isDesignReprint"
            @input="onPrintTypeChange"
          ></form-multiselect>
          <div
            class="form-item"
            style="align-self: center"
            v-if="showRoundedCornersCheckbox"
          >
            <form-checkbox
              :disabled="isDesignReprint"
              name="has_rounded_corners"
              v-model="value.specific.item.has_rounded_corners"
            >Rounded Corners?
            </form-checkbox
            >
          </div>
        </form-row>
        <!--        //todo: remove-->
        <form-row class="fixed-3" v-if="value.common.service === 'digital'">
          <form-multiselect
            v-model="paperTypeModel"
            :options="digitalPaperTypes"
            :required="true"
            label="Paper"
            :disabled="isDesignReprint || !value.specific.item.print_type"
            :config="{
              trackBy: 'key',
            }"
            :scope="scope"
            name="paperType"
          ></form-multiselect>
          <form-multiselect
            v-model="digitalSizeModel"
            :options="digitalSizes"
            :required="true"
            label="Size"
            :disabled="isDesignReprint || !value.specific.item.print_type"
            :config="{
              trackBy: 'key',
            }"
            :scope="scope"
            name="paperSize"
          ></form-multiselect>
          <form-multiselect
            v-model="digitalQuantityModel"
            :options="digitalQuantities"
            :required="true"
            label="Quantity"
            :disabled="!value.specific.item.print_type"
            :config="{
              trackBy: 'key',
            }"
            :scope="scope"
            name="quantity"
          ></form-multiselect>
        </form-row>
        <form-row class="fixed-3" v-if="value.common.service === 'button'">
          <form-multiselect
            v-model="buttonSizeModel"
            :options="formData.sizes"
            :required="true"
            label="Size"
            :config="{
              trackBy: 'key',
            }"
            :scope="scope"
            name="size"
            :disabled="isDesignReprint"
          ></form-multiselect>
          <form-multiselect
            v-model="quantityModel"
            :options="formData.quantities"
            :required="true"
            label="Quantity"
            :config="{
              trackBy: 'key',
            }"
            :scope="scope"
            name="quantity"
          ></form-multiselect>
        </form-row>
        <!--        //todo: remove-->
        <form-row class="fixed-3" v-if="value.common.service === 'poster'">
          <!-- Size Of Poster -->
          <form-multiselect
            v-model="posterSizeModel"
            :options="formData.sizes"
            :required="true"
            label="Size of Poster"
            :config="{
              trackBy: 'key',
            }"
            :scope="scope"
            name="size"
            :disabled="isDesignReprint"
          ></form-multiselect>
          <!-- Custom Size | if Size of Poster is custom -->
          <form-item
            v-model="value.specific.item.custom_size"
            :required="true"
            :scope="scope"
            name="customSize"
            label="Custom size"
            :focusOnAppear="true"
            :disabled="isDesignReprint"
            v-if="value.specific.item.size === 'empty_size'"
          ></form-item>
          <!-- Unit Cost | if Size of Poster is custom -->
          <form-item
            v-model="value.specific.item.custom_unit_price"
            :required="true"
            :scope="scope"
            name="unitCost"
            label="Unit cost"
            type="number"
            :disabled="isDesignReprint"
            v-if="value.specific.item.size === 'empty_size'"
          ></form-item>
        </form-row>
        <!--        //todo: remove-->
        <form-row class="fixed-3" v-if="value.common.service === 'poster'">
          <!-- Brand -->
          <form-multiselect
            v-model="brandModel"
            :options="formData.brands"
            :required="true"
            label="Brand"
            :config="{
              trackBy: 'key',
            }"
            :scope="scope"
            name="brand"
            :disabled="isDesignReprint"
            @select="onBrandSelect"
          ></form-multiselect>
          <!-- Custom Paper Brand | if Brand is custom -->
          <form-item
            v-model="value.specific.item.custom_brand"
            :required="true"
            :scope="scope"
            name="customPaperBrand"
            label="Custom Paper Brand"
            v-if="value.specific.item.brand === 'empty_brand'"
            :disabled="isDesignReprint"
            :focusOnAppear="true"
          ></form-item>
        </form-row>
        <!--        //todo: remove -->
        <form-row class="fixed-3" v-if="value.common.service === 'poster'">
          <!-- Paper Weight | triggers colors loading if brand is not empty -->
          <form-multiselect
            v-model="paperWeightModel"
            :options="formData.weights"
            :required="true"
            label="Paper Weight"
            :config="{
              trackBy: 'key',
            }"
            :scope="scope"
            name="paperWeight"
            :disabled="isDesignReprint"
            @select="onPaperWeightSelect"
          ></form-multiselect>
          <!-- Paper Color | async, depends from Paper Brand and Paper Weight -->
          <form-multiselect
            v-model="paperColorModel"
            :options="formData.paperColors"
            :required="true"
            label="Paper Color"
            :placeholder="
              value.specific.item.paper_weight && value.specific.item.brand
                ? 'Select...'
                : 'Depends on Brand and Weight'
            "
            :config="{
              groupValues: 'options',
              groupLabel: 'title',
            }"
            :scope="scope"
            name="paperColor"
            :disabled="
              isDesignReprint ||
              !(value.specific.item.paper_weight && value.specific.item.brand)
            "
            v-if="
              value.specific.item.brand
                ? value.specific.item.brand !== 'empty_brand'
                : true
            "
          ></form-multiselect>
          <!-- Custom Paper Color | if Custom Brand or Custom Color -->
          <form-item
            v-model="value.specific.item.custom_paper_color"
            :required="true"
            :scope="scope"
            name="customPaperColor"
            label="Custom Paper Color"
            :disabled="isDesignReprint"
            v-if="
              value.specific.item.brand === 'empty_brand' ||
              value.specific.item.paper_color === 'empty_paper_color'
            "
          ></form-item>
        </form-row>
        <form-row class="fixed-3" v-if="value.common.service === 'poster'">
          <!-- # of Colors -->
          <form-multiselect
            v-model="numberOfColorsModel"
            :options="formData.colorNumbers"
            :required="true"
            label="# of Colors"
            :config="{
              trackBy: 'key',
            }"
            :scope="scope"
            name="colors"
            :disabled="isDesignReprint"
          ></form-multiselect>
        </form-row>
        <form-row class="fixed-3" v-if="value.common.service === 'poster'">
          <!-- Ink Types -->
          <form-multiselect
            v-model="inkTypeModel"
            :options="formData.inkTypes"
            label="Inks"
            :config="{
              trackBy: 'key',
            }"
            :clearable="true"
            :scope="scope"
            :disabled="isDesignReprint"
            name="inkType"
          ></form-multiselect>
          <!-- Bleed -->
          <form-multiselect
            v-model="bleedTypeModel"
            :options="formData.bleed"
            :required="true"
            label="Bleed"
            :config="{
              trackBy: 'key',
            }"
            :scope="scope"
            :disabled="isDesignReprint"
            name="bleed"
          ></form-multiselect>
        </form-row>
        <form-row class="fixed-3" v-if="value.common.service === 'poster'">
          <!-- Quantity -->
          <form-multiselect
            v-model="quantityModel"
            :options="formData.quantities"
            :required="true"
            label="Quantity"
            :config="{
              trackBy: 'key',
            }"
            :scope="scope"
            name="quantity"
          ></form-multiselect>
        </form-row>
        <finishing-design-product
          v-if="value.common.service === 'finishing_creation'"
          v-model="value.specific.item"
          :scope="scope"
          :service="value.common.service"
          :design="value"
          :parentForm="parentForm"
          :formData="formData"
          :formOptions="formOptions.finishing_creation"
          :is-design-reprint="isDesignReprint"
          @input="onPromotionalProductChange"
        />
        <paper-design-product
          v-if="value.common.service === 'paper'"
          v-model="value.specific.item"
          :form-options="formOptions.paper"
          :form-data="formData"
          :parent-form="parentForm"
          :design="value"
          :service="value.common.service"
          :is-design-reprint="isDesignReprint"
          :scope="scope"/>
        <promotional-design-product
          v-if="value.common.service === 'promotional'"
          v-model="value.specific.item"
          :scope="scope"
          :service="value.common.service"
          :design="value"
          :parentForm="parentForm"
          :formData="formData"
          :formOptions="formOptions.promotional"
          :is-design-reprint="isDesignReprint"
          @input="onPromotionalProductChange"
        />
        <sticker-design-product
          v-if="value.common.service === 'sticker'"
          v-model="value.specific.item"
          :scope="scope"
          :service="value.common.service"
          :design="value"
          :parentForm="parentForm"
          :formData="formData"
          :formOptions="formOptions.sticker"
          :is-design-reprint="isDesignReprint"
        />
      </div>
    </div>

    <div class="step-section" v-if="value.common.service === 'promotional' && value.specific.item.product">
      <product-addons
        :design-addons="value.specific.item.addons"
        :product-id="value.specific.item.product"
        @addonsChanged="onAddonsChanged"
      />
    </div>

    <!-- Garments -->
    <div class="step-section" v-if="value.specific.garments">
      <header class="step-section__header">
        <h3 class="step-section__title">{{ 'Garments' | uppercase }}</h3>
      </header>
      <garment
        class="step-section__item"
        v-for="(garment, index) in value.specific.garments"
        v-model="value.specific.garments[index]"
        :key="garment.uniqueId"
        :index="index"
        :scope="scope"
        :service="value.common.service"
        :design="value"
        :parentForm="parentForm"
        :locationsTotal="locationsTotal"
        :formOptions="formOptions"
        @delete="deleteGarment"
        v-if="value.specific.garments"
      ></garment>
      <div class="step-section__actions">
        <a
          href="#"
          class="link-add"
          @click.prevent="addGarment"
          v-if="!isDesignReprint"
        >
          <span class="link-add__icon"></span>
          Add Garment
        </a>
      </div>
    </div>

    <!-- Locations -->
    <div class="step-section" v-if="value.specific.locations">
      <header class="step-section__header">
        <h3 class="step-section__title">{{ 'Locations' | uppercase }}</h3>
      </header>
      <location
        class="step-section__item"
        v-for="(item, index) in value.specific.locations"
        v-model="value.specific.locations[index]"
        :key="item.uniqueId"
        :index="index"
        :scope="scope"
        :design="value"
        :formData="formData"
        @delete="deleteLocation"
        v-if="value.specific.locations"
      ></location>
      <div class="step-section__actions">
        <a
          href="#"
          class="link-add"
          @click.prevent="addLocation"
          v-if="!isDesignReprint"
        >
          <span class="link-add__icon"></span>
          Add Location
        </a>
      </div>
    </div>

    <!-- Dates -->
    <div class="step-section" v-if="value.common.service !== 'non_order_invoice'">
      <header class="step-section__header">
        <h3 class="step-section__title">{{ 'Dates' | uppercase }}</h3>
      </header>
      <div class="step-section__body">
        <form-row class="fixed-4">
          <svg
            slot="icon"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
          >
            <path
              d="M17 12h-5v5h5v-5zM16 1v2H8V1H6v2H5c-1.11 0-1.99.9-1.99 2L3 19c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2h-1V1h-2zm3 18H5V8h14v11z"
            />
          </svg>
          <form-datepicker
            label="In Hands Date"
            :scope="scope"
            name="inHandsDate"
            v-model="inHandsDateModel"
            :config="{
              disabledDates: { to: yesterday },
              format: 'MM/dd/yy',
            }"
            :clearable="true"
          ></form-datepicker>
          <form-datepicker
            label="Ship By Date"
            :scope="scope"
            name="shipByDate"
            v-model="shipByDateModel"
            :config="{
              disabledDates: shipByDateDisabledDates,
              format: 'MM/dd/yy',
            }"
            :clearable="true"
          ></form-datepicker>
          <form-datepicker
            label="Print By Date"
            :scope="scope"
            name="printByDate"
            v-model="printByDateModel"
            :config="{
              disabledDates: printByDateDisabledDates,
              format: 'MM/dd/yy',
            }"
            :clearable="true"
          ></form-datepicker>
        </form-row>
      </div>
    </div>

    <!-- Common Fields (all except Ink Color Changes) -->
    <div class="step-section">
      <header class="step-section__header">
        <h3 class="step-section__title">{{ 'Other details' | uppercase }}</h3>
      </header>
      <div class="step-section__body">
        <form-row v-if="value.common.service === 'apparel'">
          <span slot="icon"></span>
          <form-item
            label="Ink Color Changes"
            v-model="value.specific.extra.ink_color_changes"
            name="inkColorChanges"
            :scope="scope"
            rules="numeric"
            style="max-width: 155px"
          ></form-item>
        </form-row>
        <form-row>
          <svg v-if="value.common.service !== 'non_order_invoice'" slot="icon" xmlns="http://www.w3.org/2000/svg"
               width="24" height="24" viewBox="0 0 24 24">
            <path
              d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1.41 16.09V20h-2.67v-1.93c-1.71-.36-3.16-1.46-3.27-3.4h1.96c.1 1.05.82 1.87 2.65 1.87 1.96 0 2.4-.98 2.4-1.59 0-.83-.44-1.61-2.67-2.14-2.48-.6-4.18-1.62-4.18-3.67 0-1.72 1.39-2.84 3.11-3.21V4h2.67v1.95c1.86.45 2.79 1.86 2.85 3.39H14.3c-.05-1.11-.64-1.87-2.22-1.87-1.5 0-2.4.68-2.4 1.64 0 .84.65 1.39 2.67 1.91s4.18 1.39 4.18 3.91c-.01 1.83-1.38 2.83-3.12 3.16z"/>
          </svg>
          <form-item
            v-if="value.common.service !== 'non_order_invoice'"
            :label="`Upcharge for Design #${index + 1}`"
            v-model="value.common.upcharge"
            :money="{
              decimal: '.',
              thousands: ',',
              prefix: '$ ',
              precision: 2,
            }"
            name="upcharge"
            :scope="scope"
            :selectAllOnFocus="true"
            style="max-width: 155px"
            rules="min_value:0"
          ></form-item>
          <form-item
            label="Comment"
            name="comment"
            v-model="value.common.upcharge_description"
            :scope="scope"
            v-show="value.common.upcharge > 0"
          ></form-item>
        </form-row>
        <form-row>
          <svg v-if="value.common.service !== 'non_order_invoice'" slot="icon" xmlns="http://www.w3.org/2000/svg"
               width="24" height="24" viewBox="0 0 24 24">
            <path
              d="M3.9 12c0-1.71 1.39-3.1 3.1-3.1h4V7H7c-2.76 0-5 2.24-5 5s2.24 5 5 5h4v-1.9H7c-1.71 0-3.1-1.39-3.1-3.1zM8 13h8v-2H8v2zm9-6h-4v1.9h4c1.71 0 3.1 1.39 3.1 3.1s-1.39 3.1-3.1 3.1h-4V17h4c2.76 0 5-2.24 5-5s-2.24-5-5-5z"/>
          </svg>
          <form-item
            v-if="value.common.service !== 'non_order_invoice'"
            label="Art Link"
            v-model="value.common.original_files_link"
            name="art"
            :scope="scope"
            :clearable="true"
            :rules="{
              url: {
                require_protocol: true,
              },
            }"
          ></form-item>
        </form-row>
        <form-row>
          <svg
            slot="icon"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
          >
            <path
              d="M20 2H4c-1.1 0-1.99.9-1.99 2L2 22l4-4h14c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zM6 9h12v2H6V9zm8 5H6v-2h8v2zm4-6H6V6h12v2z"
            />
          </svg>
          <wysiwyg ref="wysiwyg"
                   :label="value.common.service === 'non_order_invoice' ? 'Comments': 'Design Comment'"
                   placeholder="*Enter comment(s)"
                   :value="value.common.comment"
                   @value-changed="onCommentChanged"
                   rules="max:1000"
                   style="width: 100%;height: 100%;"
          />
        </form-row>
        <form-row>
          <form-item
            :static="true"
            :value="$options.filters.price(designTotal)"
            label="Total Cost:"
            :horizontal="true"
            class="is-large is-price"
          ></form-item>
        </form-row>
      </div>
    </div>
  </div>
</template>
<script>
import notify from '@/modules/notifier';
import moment from 'moment';
import draggable from 'vuedraggable';
import StepBase from './StepBase';
import FormDatepicker from '@/components/common/form/FormDatepicker';
import ReprintDesignModal from './ReprintDesignModal';
import {mapGetters} from 'vuex';
import {camelCase, cloneDeep, merge, uniqueId} from 'lodash';
import {FORM_ESTIMATE_TEMPLATES} from '@/helpers/estimates';
import Garment from './Fieldsets/Garment';
import Location from './Fieldsets/Location';
import PromotionalDesignProduct from './Fieldsets/PromotionalDesignProduct';

import {getDesignTotal} from '@/helpers/prices/';
import {getLocationsTotal as getApparelLocationsTotal} from '@/helpers/prices/apparel';
import {getLocationsTotal as getEmbroideryLocationsTotal} from '@/helpers/prices/embroidery';
import ProductAddons from './Fieldsets/ProductAddons';
import FinishingCreation from '@/components/orders/design-types/finishing-creation.vue';
import FinishingDesignProduct from '@/components/forms/FormEstimate/Fieldsets/FinishingDesignProduct.vue';
import PaperDesignProduct from '@/components/forms/FormEstimate/Fieldsets/PaperDesignProduct.vue';
import StickerDesignProduct from '@/components/forms/FormEstimate/Fieldsets/StickerDesignProduct.vue';
import Wysiwyg from '@/components/common/TextEditors/wysiwyg.vue';

const formFields = [
  'ink_types',
  'colors_numbers',
  'process_addons',
  'print_types',
  'quantities',
  'shapes',
  'sizes',
  'bleed',
  'brands',
  'color_numbers',
  'weights',
  'paper_colors',
  'depth',
  'stitches',
  'locations',
  'paper_types'
];

export default {
  name: 'form-estimate-designs',
  extends: StepBase,
  components: {
    Wysiwyg,
    StickerDesignProduct,
    PaperDesignProduct,
    FinishingDesignProduct,
    FinishingCreation,
    ProductAddons,
    PromotionalDesignProduct,
    FormDatepicker,
    Garment,
    Location,
    draggable
  },
  props: {
    index: {
      required: true,
      type: [String, Number]
    },
    data: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  data() {
    const date = new Date();
    date.setDate(date.getDate() - 1);
    const form = Object.assign(
      {},
      FORM_ESTIMATE_TEMPLATES.design,
      {common: {name: `Design ${this.index + 1}`}},
      JSON.parse(JSON.stringify(this.value))
    );

    const formData = {};
    formFields.forEach(key => {
      formData[camelCase(key)] = [];
    });
    const dragable = {
      enabled: true,
      dragging: false
    };
    return {
      form,
      formData,
      yesterday: date,
      isLoading: false,
      isDirty: false,
      dragable: dragable
    };
  },
  computed: {
    ...mapGetters({
      services: 'orderServices'
    }),
    showRoundedCornersCheckbox() {
      return (
        this.value.specific.item.has_rounded_corners ||
        (
          (this.printTypeModel && this.printTypeModel.label !== 'Digital Posters')
        )
      );
    },
    service() {
      return this.value.common.service;
    },
    canReprint() {
      return !!(
        this.parentForm.account.account_id &&
        this.parentForm.account.company_id &&
        this.parentForm.account.company_id !== 'create-new'
      );
    },
    isDesignReference() {
      return (
        !!(
          this.value.common &&
          this.value.common.reference_order_id &&
          this.value.common.reference_design_id
        ) || false
      );
    },
    isDesignReprint() {
      return (
        !!(
          this.value.common &&
          this.value.common.printed_before_order_id &&
          this.value.common.printed_before_design_id
        ) || false
      );
    },
    isRedoDesign() {
      return (
        (this.value.common && this.value.common.redo_design_id !== null) ||
        false
      );
    },
    digitalPaperTypes() {
      const printTypeKey = this.value.specific.item.print_type;
      if (printTypeKey === null) return [];
      const printTypeObject = this.formOptions.digital.print_type_options[
        printTypeKey
        ];
      return printTypeObject ? printTypeObject.paper_types.slice() : [];
    },
    digitalSizes() {
      const printTypeKey = this.value.specific.item.print_type;
      if (printTypeKey === null) return [];
      const printTypeObject = this.formOptions.digital.print_type_options[
        printTypeKey
        ];
      return printTypeObject ? printTypeObject.sizes.slice() : [];
    },
    digitalQuantities() {
      const printTypeKey = this.value.specific.item.print_type;
      if (printTypeKey === null) return [];
      const printTypeObject = this.formOptions.digital.print_type_options[
        printTypeKey
        ];
      return printTypeObject ? printTypeObject.quantities.slice() : [];
    },

    locationsTotal() {
      switch (this.value.common.service) {
        case 'apparel':
          return getApparelLocationsTotal(
            this.value.specific.locations,
            this.formOptions.apparel
          );
        case 'embroidery':
          return getEmbroideryLocationsTotal(
            this.value.specific.locations,
            this.value.specific.extra.total_garments_qty,
            this.formOptions.embroidery
          );
      }
    },
    designTotal() {
      return getDesignTotal(
        this.value,
        this.formOptions[this.value.common.service]
      );
    },
    serviceModel: {
      get() {
        if (this.value.common.service === null) return null;
        return (
          this.services.find(({key}) => this.value.common.service === key) ||
          null
        );
      },
      set({key}) {
        this.prepareSpecificFields(key, this.value.common.service);
        this.getServiceFormData(key);
        this.value.common.service = key;
      }
    },
    printTypeModel: {
      get() {
        if (!this.value.specific.item || this.value.specific.item.print_type === null) return null;
        return (
          this.formData.printTypes.find(
            ({key}) => this.value.specific.item.print_type === key
          ) || null
        );
      },
      set({key}) {
        this.value.specific.item.print_type = key;
      }
    },
    paperTypeModel: {
      get() {
        if (this.value.specific.item.paper_type === null) return null;
        return this.digitalPaperTypes.find(
          ({key}) => this.value.specific.item.paper_type === key
        );
      },
      set({key}) {
        this.value.specific.item.paper_type = key;
      }
    },

    buttonSizeModel: {
      get() {
        if (this.value.specific.item.size === null) return null;
        return this.formData.sizes.find(
          ({key}) => this.value.specific.item.size === key
        );
      },
      set({key}) {
        this.value.specific.item.size = key;
      }
    },
    posterSizeModel: {
      get() {
        if (this.value.specific.item.size === null) return null;
        return this.formData.sizes.find(
          ({key}) => this.value.specific.item.size === key
        );
      },
      set({key}) {
        this.value.specific.item.size = key;
        if (key !== 'empty_size') {
          this.value.specific.item.custom_size = '';
          this.value.specific.item.custom_unit_price = null;
        }
      }
    },
    digitalSizeModel: {
      get() {
        if (this.value.specific.item.size === null) return null;
        return this.digitalSizes.find(
          ({key}) => this.value.specific.item.size === key
        );
      },
      set({key}) {
        this.value.specific.item.size = key;
      }
    },
    digitalQuantityModel: {
      get() {
        if (this.value.specific.item.quantity === null) return null;
        return this.digitalQuantities.find(
          ({key}) => this.value.specific.item.quantity === key
        );
      },
      set(option) {
        this.value.specific.item.quantity = option.key;
        this.value.specific.item._quantity = option.label;
      }
    },
    quantityModel: {
      get() {
        if (this.value.specific.item.quantity === null) return null;
        return this.formData.quantities.filter(option => {
          return option.available || option.available === undefined;
        }).find(
          ({key}) => this.value.specific.item.quantity === key
        );
      },
      set(option) {
        this.value.specific.item.quantity = option.key;
        this.value.specific.item._quantity = option.label;
      }
    },
    numberOfColorsModel: {
      get() {
        if (this.value.specific.item.number_of_colors === null) return null;
        return this.formData.colorNumbers.find(
          ({key}) => this.value.specific.item.number_of_colors === key
        );
      },
      set({key}) {
        this.value.specific.item.number_of_colors = key;
      }
    },
    brandModel: {
      get() {
        if (this.value.specific.item.brand === null) return null;
        return this.formData.brands.find(
          ({key}) => this.value.specific.item.brand === key
        );
      },
      set({key}) {
        this.value.specific.item.brand = key;
        if (key !== 'empty_brand') {
          this.value.specific.item.custom_brand = '';
          this.value.specific.item.custom_paper_color = '';
        }
      }
    },
    paperWeightModel: {
      get() {
        if (this.value.specific.item.paper_weight === null) return null;
        return this.formData.weights.find(
          ({key}) => this.value.specific.item.paper_weight === key
        );
      },
      set({key}) {
        this.value.specific.item.paper_weight = key;
      }
    },
    paperColorModel: {
      get() {
        if (this.value.specific.item.paper_color === null) return null;
        let value;
        this.formData.paperColors.forEach(({options}) => {
          if (value) return;
          value = options.find(
            ({id}) => this.value.specific.item.paper_color.toString() === id
          );
        });
        return value || null;
      },
      set(option) {
        this.value.specific.item.paper_color = option.id;
        this.value.specific.item._paper_color = option.label;
        if (option.id !== 'empty_paper_color') {
          this.value.specific.item.custom_paper_color = '';
        }
      }
    },
    bleedTypeModel: {
      get() {
        if (this.value.specific.item.bleed_type === null) return null;
        return this.formData.bleed.find(
          ({key}) => this.value.specific.item.bleed_type === key
        );
      },
      set({key}) {
        this.value.specific.item.bleed_type = key;
      }
    },
    inkTypeModel: {
      get() {
        if (this.value.specific.item.ink_type === null) return null;
        return this.formData.inkTypes.find(
          ({key}) => this.value.specific.item.ink_type === key
        );
      },
      set(option) {
        if (option === null) {
          this.value.specific.item.ink_type = null;
          delete this.value.specific.item._ink_type;
        } else {
          this.value.specific.item.ink_type = option.key;
          this.value.specific.item._ink_type = option.label;
        }
      }
    },
    garmentsQty() {
      if (!this.value.specific.garments) return false;
      return this.value.specific.garments.reduce((total, garment) => {
        return (
          total +
          Object.values(garment.sizes).reduce((sum, size) => {
            return sum + (+size || 0);
          }, 0)
        );
      }, 0);
    },
    locationsQty() {
      if (!this.value.specific.locations) return false;
      return this.value.specific.locations.length;
    },
    colorNumbersQty() {
      if (this.value.common.service !== 'apparel') return false;
      return this.value.specific.locations.reduce(
        (sum, location) => sum + (location.colors_number || 0),
        0
      );
    },
    shipByDateDisabledDates() {
      const from = new Date(this.inHandsDateModel);
      from.setDate(from.getDate() + 1);
      return {
        to: this.yesterday,
        from
      };
    },
    printByDateDisabledDates() {
      const from = new Date(this.shipByDateModel);
      from.setDate(from.getDate() + 1);
      return {
        to: this.yesterday,
        from
      };
    },
    inHandsDateModel: {
      get() {
        if (this.value.common.in_hands_date === null) return null;
        return moment(this.value.common.in_hands_date).toDate();
      },
      set(val) {
        if (!val) {
          this.value.common.in_hands_date = null;
          this.shipByDateModel = null;
        } else {
          this.value.common.in_hands_date = moment(val).format('MM/DD/YY');
          const shipByDateTimestamp =
            this.shipByDateModel && moment(this.shipByDateModel).unix();
          if (shipByDateTimestamp && shipByDateTimestamp > moment(val).unix()) {
            this.shipByDateModel = val;
          }
        }
      }
    },
    shipByDateModel: {
      get() {
        if (this.value.common.ship_by_date === null) return null;
        return moment(this.value.common.ship_by_date).toDate();
      },
      set(val) {
        if (!val) {
          this.value.common.ship_by_date = null;
          this.printByDateModel = null;
        } else {
          this.value.common.ship_by_date = moment(val).format('MM/DD/YY');
          const printByDateTimestamp =
            this.printByDateModel && moment(this.printByDateModel).unix();
          if (
            printByDateTimestamp &&
            printByDateTimestamp > moment(val).unix()
          ) {
            this.printByDateModel = val;
          }
        }
      }
    },
    printByDateModel: {
      get() {
        if (this.value.common.print_by_date === null) return null;
        return moment(this.value.common.print_by_date).toDate();
      },
      set(val) {
        this.value.common.print_by_date = val
          ? moment(val).format('MM/DD/YY')
          : null;
      }
    }
  },
  watch: {
    garmentsQty: {
      immediate: true,
      handler(val, old) {
        if (val === false) return;
        if (old && val !== this.value.specific.extra.total_garments_qty) {
          this.$emit('garments-qty-changed', this.value.common.uniqueId);
        }
        this.value.specific.extra.total_garments_qty = val;
      }
    },
    locationsQty: {
      immediate: true,
      handler(val) {
        if (val === false) return;
        this.value.specific.extra.total_locations = val;
      }
    },
    colorNumbersQty: {
      immediate: true,
      handler(val) {
        if (val === false) return;
        this.value.specific.extra.total_colors_number = val;
      }
    },
    // Digital options watch.
    'value.specific.item.print_type': {
      handler(val) {
        this.resetItemCost();
        if (
          this.printTypeModel &&
          this.printTypeModel.label === 'Digital Posters'
        ) {
          this.value.specific.item.has_rounded_corners = false;
        }
      }
    },
    'value.specific.item.paper_type': {
      handler(val) {
        this.resetItemCost();
      }
    },
    'value.specific.item.has_rounded_corners': {
      handler(val) {
        this.resetItemCost();
      }
    },

    // Poster options watch.
    'value.specific.item.number_of_colors': {
      handler(val) {
        this.resetItemCost();
      }
    },
    'value.specific.item.paper_weight': {
      handler(val) {
        this.resetItemCost();
      }
    },
    'value.specific.item.ink_type': {
      handler(val) {
        this.resetItemCost('ink_type_cost');
      }
    },

    // Whole items watchers (there are same options for all designs)
    'value.specific.item.size': {
      handler(val) {
        this.resetItemCost();
      }
    },
    'value.specific.item.quantity': {
      handler(val) {
        this.resetItemCost();
      }
    },
    'value.specific.item.product': {
      handler(val) {
        this.resetItemCost();
      }
    },
    'value.specific.extra.ink_color_changes': {
      handler(val) {
        if (this.service === 'apparel') {
          if (
            this.value &&
            this.value.common &&
            this.value.common.redo_design_id !== undefined &&
            this.value.common.redo_design_id === null &&
            this.value.specific &&
            this.value.specific.extra &&
            this.value.specific.extra.ink_color_changes_cost !== undefined
          ) {
            this.value.specific.extra.ink_color_changes_cost = null;
          }
        }
      }
    }
  },
  created() {
    this.init();
  },
  methods: {
    onAddonsChanged(bool, val) {
      this.resetItemCost();
      if (!!bool) {
        this.value.specific.item.addons.push(val);
      } else {
        this.value.specific.item.addons =
          !!val &&
          this.value.specific.item.addons.filter(
            a => a.product_addon_name !== val.product_addon_name
          );
      }
    },
    onCommentChanged(newValue) {
      this.value.common.comment = newValue;
    },
    onPromotionalProductChange(val) {
      this.value.specific.item = Object.assign(
        {},
        JSON.parse(JSON.stringify(val))
      );
    },
    resetItemCost(cost_field = 'cost') {
      if (
        this.value.specific.item &&
        this.value.common.redo_design_id === null &&
        this.value.specific.item[cost_field] !== undefined
      ) {
        this.value.specific.item[cost_field] = null;
      }
    },
    deleteItem(uniqueId) {
      this.value.specific.items = this.value.specific.items.filter(
        item => item.uniqueId !== uniqueId
      );
    },
    initDesignFormOptions(service) {
      this.getServiceFormData(service);

      if (
        this.value.common.service === 'poster' &&
        this.value.specific.item.paper_color !== null
      ) {
        this.getPaperColors(
          this.value.specific.item.brand,
          this.value.specific.item.paper_weight
        ).then(() => {
          this.$watch(
            'value',
            () => {
              this.isDirty = true;
            },
            {deep: true}
          );
        });
      } else {
        this.$watch(
          'value',
          () => {
            this.isDirty = true;
          },
          {deep: true}
        );
      }
    },
    init() {
      this.isLoading = true;

      if (!this.value.common.id && !this.value.isPassed) {
        this.prepareSpecificFields(this.service);
      }

      this.initDesignFormOptions(this.service);
    },
    reprintDesignModal(type = 'reprint') {
      if (this.canReprint) {
        let uniqueId = this.value.common.uniqueId;
        this.$modal.show(
          ReprintDesignModal,
          {
            type: type,
            account_id: this.parentForm.account.account_id,
            company_id: this.parentForm.account.company_id,
            callback: design => {
              this.reprintDesign(uniqueId, design);
              this.$modal.hide('reprint-design-modal');
            }
          },
          {
            name: 'reprint-design-modal',
            title: type === 'reprint' ? 'Reprint Design' : 'Reference Design',
            height: 'auto'
          }
        );
      }
    },
    reprintDesign(designUniqueId, designCopy) {
      //reset size_cost for garments
      if (designCopy.specific && designCopy.specific.garments) {
        designCopy.specific.garments.forEach(garment => {
          garment.size_cost = null;
        });
      }
      this.$emit('remove-shipping-design-item', this.value.common.uniqueId);
      this.$emit('remove-finishing-design-item', this.value.common.uniqueId);

      this.$nextTick(() => {
        designCopy.uniqueId = designUniqueId;
        designCopy.common.uniqueId = designUniqueId;
        this.value.common = designCopy.common;
        this.value.specific = designCopy.specific;

        if (this.value.specific.finishings) {
          this.value.specific.finishings.forEach(df => {
            let finishing = this.parentForm.finishings.find(f => {
              if (
                df &&
                f &&
                f.common.finishing_option === df.common.finishing_option &&
                f.common.name === df.common.name
              ) {
                // Tag Printing and Heat Pressed Tags.
                if (
                  f.common.finishing_option === 'tag-printing' ||
                  f.common.finishing_option === 'heat-pressed-tags'
                ) {
                  return (
                    f.specific.remove_manufacturer_tag ===
                    df.specific.remove_manufacturer_tag &&
                    f.specific.tag_width === df.specific.tag_width &&
                    f.specific.tag_height === df.specific.tag_height
                  );
                } else if (f.common.finishing_option === 'hem-tag'||f.common.finishing_option === 'hem-tags-fleece') {
                  // Hem Tag.
                  return (
                    f.specific.hem_tag === df.specific.hem_tag &&
                    f.specific.hem_tag_thread_color ===
                    df.specific.hem_tag_thread_color
                  );
                } else if (
                  f.common.finishing_option === 'sewn-tag-neck-and-four-sided' ||
                  f.common.finishing_option === 'neck-tags-fleece'
                ) {
                  // Sewn In Neck Tags.
                  return (
                    f.specific.sewn_tag_neck_tag_type ===
                    df.specific.sewn_tag_neck_tag_type &&
                    f.specific.sewn_tag_neck_tag_thread_color ===
                    df.specific.sewn_tag_neck_tag_thread_color
                  );
                } else if (
                  f.common.finishing_option === 'folding-and-bagging' ||
                  f.common.finishing_option === 'folding-and-bagging-fleece' ||
                  f.common.finishing_option === 'folding-only-fleece' ||
                  f.common.finishing_option === 'folding-only'
                ) {
                  // Folding And Bagging.
                  return f.specific.folding_type === df.specific.folding_type;
                } else if (f.common.finishing_option === 'four-sided-tag') {
                  // Four Sided Tags.
                  return true;
                } else if (f.common.finishing_option === 'hang-tag') {
                  // Hang Tags.
                  return f.specific.hang_tag === df.specific.hang_tag;
                } else if (f.common.finishing_option === 'woven-neck-tag') {
                  // Woven Neck Tags.
                  return (
                    f.specific.woven_neck_tag_type ===
                    df.specific.woven_neck_tag_type &&
                    f.specific.woven_neck_tag_thread_color ===
                    df.specific.woven_neck_tag_thread_color
                  );
                } else if (
                  f.common.finishing_option === 'sewn-tag-bottom-or-sleeve'
                ) {
                  // Sewn Bottom Or Sleeve Tags.
                  return true;
                } else if (
                  f.common.finishing_option === 'sticker-attachment'
                ) {
                  // Sticker Attachment
                  return true;
                }
              }
            });

            df.items = df.items.map(item => {
              item.design_id = designCopy.uniqueId;
              return item;
            });

            // If there are no finishings on the form like from reprint design then create new one.
            if (!finishing) {
              this.$emit('add-finishing', df);
            } else {
              // If the form already has finishings like from reprint design then add reprint as related design.
              finishing.items = finishing.items.filter(
                item => item.design_id !== this.value.uniqueId
              );
              df.items.forEach(item => {
                if (item.design_id === this.value.uniqueId) {
                  finishing.items.push(item);
                }
              });
            }
          });
        }

        this.initDesignFormOptions(designCopy.common.service);
        this.$nextTick(() => {
          this.$emit('validateStep');
        });
      });
    },
    deleteDesign() {
      this.$emit('delete-design', this.value.common.uniqueId);
    },
    deleteLocation(index) {
      this.value.specific.locations.splice(index, 1);
    },
    deleteGarment(index) {
      const garment_uniqueId = this.value.specific.garments[index].uniqueId;
      this.$emit('delete-garment', garment_uniqueId);
      this.value.specific.garments.splice(index, 1);
    },
    onPrintTypeChange({key}) {
      this.value.specific.item.paper_type = null;
      this.value.specific.item.size = null;
      this.value.specific.item.quantity = null;
    },
    getPaperColors(brand, weight) {
      return this.$apiService
        .getPosterPaperColors(brand, weight)
        .then(response => {
          const groups = Object.keys(response).sort((a, b) => {
            const aMinOptionKey = Object.keys(response[a]).sort()[0];
            const bMinOptionKey = Object.keys(response[b]).sort()[0];
            if (aMinOptionKey < bMinOptionKey) {
              return -1;
            }
            if (aMinOptionKey > bMinOptionKey) {
              return 1;
            }
            return 0;
          });
          this.formData.paperColors = groups.map(groupKey => {
            return {
              title: groupKey,
              options: Object.keys(response[groupKey]).map(childKey => {
                return {
                  id: childKey,
                  label: response[groupKey][childKey]
                };
              })
            };
          });
        });
    },
    onBrandSelect({key}) {
      if (!key || key === 'empty_brand') return;
      const paperWeightKey = this.value.specific.item.paper_weight;

      if (!paperWeightKey) return;

      this.getPaperColors(key, paperWeightKey).then(() => {
        this.value.specific.item.paper_color = null;
      });
    },
    onPaperWeightSelect({key}) {
      const brandKey = this.value.specific.item.brand;

      if (!brandKey || brandKey === 'empty_brand') return;

      this.getPaperColors(brandKey, key).then(() => {
        this.value.specific.item.paper_color = null;
      });
    },
    handleServiceFormData(service, formData) {
      formFields.forEach(key => {
        const data = formData[key];
        if (!data) {
          this.formData[key] = [];
          return;
        }
        switch (key) {
          case 'garment_upcharges':
            break;

          default:
            this.formData[camelCase(key)] = Array.isArray(data)
              ? data.slice()
              : data;
            break;
        }
      });
    },
    prepareSpecificFields(service, prevService) {
      const specific = cloneDeep(
        FORM_ESTIMATE_TEMPLATES.designSpecific[service]
      );

      if (prevService != service) {
        this.$emit('changeService', this.value.uniqueId, service);
      }
      if (specific.garments && this.value.specific.garments) {
        if (!prevService) {
          specific.garments = this.value.specific.garments.slice();
        } else {
          if (
            ['apparel', 'blank'].includes(service) &&
            ['apparel', 'blank'].includes(prevService)
          ) {
            specific.garments = this.value.specific.garments.slice();
          }
        }
      }

      if (specific.locations && this.value.specific.locations && !prevService) {
        specific.locations = this.value.specific.locations.slice();
      }

      if (specific.garments && specific.garments.length === 0) {
        specific.garments.push(this.createGarment());
      }

      if (specific.locations && specific.locations.length === 0) {
        specific.locations.push(this.createLocation(service));
      }

      if (Array.isArray(specific.items)) {
        switch (service) {
          case 'other':
            if (this.value.specific.items) {
              specific.items = this.value.specific.items.map(item => {
                return {
                  name: item.name,
                  price: item.price,
                  quantity: item.quantity,
                  description: item.description,
                  uniqueId: uniqueId('other-design_')
                };
              });
            } else {
              specific.items.push(
                merge(
                  {uniqueId: uniqueId('other-design_')},
                  cloneDeep(FORM_ESTIMATE_TEMPLATES.designSpecificOther)
                )
              );
            }
            break;
          case 'non_order_invoice':
            if (this.value.specific.items) {
              specific.items = this.value.specific.items.map(item => {
                return {
                  name: item.name,
                  price: item.price,
                  quantity: item.quantity,
                  uniqueId: uniqueId('non_order_invoice_')
                };
              });
            } else {
              specific.items.push(
                merge(
                  {uniqueId: uniqueId('non_order_invoice_')},
                  cloneDeep(
                    FORM_ESTIMATE_TEMPLATES.designSpecificNon_order_invoice
                  )
                )
              );
            }
            break;
          default:
            break;
        }
      }

      if (specific.extra.total_garments_qty !== undefined) {
        specific.extra.total_garments_qty = this.garmentsQty;
      }

      if (specific.extra.total_locations !== undefined) {
        specific.extra.total_locations = this.locationsQty;
      }

      if (specific.extra.total_colors_number !== undefined) {
        specific.extra.total_colors_number = this.colorNumbersQty;
      }

      if (specific.extra.ink_color_changes !== undefined) {
        specific.extra.ink_color_changes = this.value.specific.extra.ink_color_changes;
      }

      if (specific.item && this.value.specific.item && !prevService) {
        specific.item = this.value.specific.item;
      }

      this.value.specific = specific;
    },
    addOtherItem() {
      this.value.specific.items.push(
        merge(cloneDeep(FORM_ESTIMATE_TEMPLATES.designSpecificOther), {
          uniqueId: uniqueId('other-design_')
        })
      );
    },
    addNonOrderInvoiceItem() {
      console.log('add invoice item');
      this.value.specific.items.push(
        merge(
          cloneDeep(FORM_ESTIMATE_TEMPLATES.designSpecificNon_order_invoice),
          {
            uniqueId: uniqueId('non_order_invoice_')
          }
        )
      );
    },
    getServiceFormData(service) {
      this.handleServiceFormData(service, this.formOptions[service]);
    },
    createGarment() {
      return merge(
        {uniqueId: uniqueId('garment_')},
        FORM_ESTIMATE_TEMPLATES.garment
      );
    },
    addGarment() {
      const garment = merge(
        {uniqueId: uniqueId('garment_')},
        FORM_ESTIMATE_TEMPLATES.garment
      );
      this.value.specific.garments.push(garment);
    },
    createLocation(service) {
      return merge(
        {uniqueId: uniqueId('location_')},
        FORM_ESTIMATE_TEMPLATES.location.common,
        FORM_ESTIMATE_TEMPLATES.location.specific[service]
      );
    },
    addLocation() {
      const location = merge(
        {uniqueId: uniqueId('location_')},
        FORM_ESTIMATE_TEMPLATES.location.common,
        FORM_ESTIMATE_TEMPLATES.location.specific[this.value.common.service]
      );
      this.value.specific.locations.push(location);
    },
    getUpcharges(accountId = undefined) {
      return this.$apiService
        .getApparelGarmentUpcharges(accountId, this.value.common.service)
        .then(
          data => {
            return (this.upcharges = data);
          },
          function(response) {
            notify({
              message: 'Could not load prices',
              type: 'danger'
            });
          }
        );
    }
  }
};
</script>
<style lang="scss">
@import '/resources/sass/base/helpers';

.item-other-design {
  & + & {
    margin-top: 10px;
    padding-top: 10px;
    border-top: 5px solid #ccc;
  }
}

.ql-editor {
  min-height: 100px;
}
.list-group-item {
  cursor: move;
}
</style>
