<template>
  <div>
    <form-row class="fixed-2">
      <form-multiselect
        v-model="promotionalProductTypeModel"
        :options="promotionalProductTypes"
        :required="true"
        label="Product type"
        :disabled="isDesignReprint"
        :config="{
          trackBy: 'key',
        }"
        :scope="scope"
        name="product_type"
      ></form-multiselect>
      <form-multiselect
        v-model="promotionalProductCategoryModel"
        :options="promotionalProductCategories"
        :required="true"
        label="Category"
        :disabled="isDesignReprint"
        :config="{
          trackBy: 'key',
        }"
        :scope="scope"
        name="category"
      ></form-multiselect>
    </form-row>
    <form-row>
      <form-multiselect
        v-model="promotionalProductModel"
        :disabled="isDesignReprint"
        :options="promotionalProduct.options"
        :required="true"
        :isLoading="promotionalProduct.isLoading"
        placeholder="Search for Products"
        :config="{
          internalSearch: true,
          preserveSearch: true,
          customLabel: ({ product_brand, number, name }) =>
            `${product_brand.name} ${number} ${name}`,
        }"
        @search-change="onPromotionalProductSearchChange"
        :scope="scope"
        name="promotional-product"
      >
        <div
          slot="noOptions"
          slot-scope="props"
          v-if="!promotionalProduct.searchQuery.length"
        >
          Start typing...
        </div>
      </form-multiselect>
    </form-row>
    <form-row class="fixed-3" v-if="form.product">
      <form-multiselect
        v-if="promotionalProductModel && (!isPetProduct || isPetToysCategory)"
        v-model="promotionalSizeModel"
        :options="promotionalSizes"
        :required="!!promotionalProductModel"
        label="Size"
        :disabled="isDesignReprint"
        :config="{
          trackBy: 'key',
        }"
        :scope="scope"
        name="size"
      ></form-multiselect>
      <form-multiselect
        v-if="promotionalColors.length"
        v-model="promotionalColorModel"
        :options="promotionalColors"
        :required="!!promotionalColors.length"
        label="Color"
        :config="{
          trackBy: 'key',
        }"
        :scope="scope"
        name="color"
      ></form-multiselect>
      <form-multiselect
        v-if="numberOfColors.length"
        v-model="numberOfColorsModel"
        :options="numberOfColors"
        :required="promotionalProductModel && !!numberOfColors.length"
        :label="numberOfColorsLabel"
        :config="{
          trackBy: 'key',
        }"
        :scope="scope"
        name="number_of_colors"
      ></form-multiselect>
      <form-item
        label="Quantity"
        :after-label="productPrice"
        name="itemQuantity"
        :scope="scope"
        :required="true"
        v-model="promotionalQuantityModel"
        :disabled="isQuantityDisabled"
        :rules="`numeric|min_value:${qtyMinMax.min}|max_value:${qtyMinMax.max}`"
      ></form-item>
    </form-row>
  </div>
</template>

<script>
import { debounce, uniq, replace } from 'lodash';
import { findProductPrice, getAddonsTotal} from '@/helpers/prices/promotional';
import { mapGetters } from 'vuex';
import { naturalSort } from '@/helpers/form';

export default {
  name: 'PromotionalDesignProduct',
  props: {
    value: {
      required: true,
      type: Object
    },
    scope: {
      required: true,
      type: String
    },
    service: {
      required: true,
      type: String
    },
    design: {
      required: true,
      type: Object
    },
    parentForm: {
      required: true,
      type: Object
    },
    formData: {
      required: true,
      type: Object
    },
    formOptions: {
      required: true,
      type: Object
    },
    isDesignReprint: {
      default: false,
      type: Boolean
    }
  },
  data() {
    const form = Object.assign({}, JSON.parse(JSON.stringify(this.value)));
    return {
      form,
      promotionalProduct: {
        options: [],
        searchQuery: '',
        isLoading: false
      }
    };
  },
  watch: {
    form: {
      handler(val) {
        this.$emit('input', val);
      },
      deep: true
    },
    'form.product_type': {
      handler(val) {
        this.form.product_category = null;
        this.form._product_category = null;
        this.form.addons = [];
        this.form.quantity = null;
      }
    },
    'form.product_category': {
      handler(val) {
        this.promotionalProduct.options = [];
        this.form.product = null;
        this.form._product = null;
        this.form.quantity = null;
      }
    },
    'form.product': {
      handler(val) {
        this.form.size = null;
        this.form.model =
          this.promotionalProduct.options.find(po => po.id === val) || null;
      }
    },
    'form.size': {
      handler(val) {
        this.form.quantity = null;
        this.form.color = null;
        this.form.number_of_colors = null;
      }
    }
  },
  computed: {
    ...mapGetters(['sortedGarmentsSizes']),
    qtyMinMax() {
      if (!this.promotionalQuantities.length) {
        return {
          min: 1,
          max: 10000
        };
      }
      let qties = [];
      this.promotionalQuantities.forEach(q => {
        qties = uniq([...qties, ...q.quantity.split('-')]);
      });
      qties.sort((a, b) => a - b);
      let maxRange = replace((qties && qties.length && qties[qties.length - 1]), ',', '') || '';

      return {
        min: (qties && qties.length && replace(qties[0], ',', '')) || 1,
        max: !!maxRange.match(/\+/)
          ? replace(maxRange, '+', '') > 10000 ? replace(maxRange, '+', '') : 10000
          : replace(
            qties && qties.length && qties[qties.length - 1],
            '+',
            ''
          ) ||
          10000 ||
          10000
      };
    },
    isQuantityDisabled() {
      return  false;
      // return !!(
      //   !this.promotionalProductModel ||
      //   !this.promotionalSizeModel ||
      //   (!this.numberOfColorsModel && this.form.model.product_type.name !== 'Blankets')
      // );
    },
    numberOfColors() {
      const product = this.form.product;
      const size = this.form.size;
      if (product === null || size === null  && !this.isPetProduct) {
        return [];
      }

      const productObject =
        this.form.model ||
        this.promotionalProduct.options.find(({ id }) => product === id);
      if (!productObject) {
        return [];
      }
      let numberOfColors = [];
      if (this.isPetProduct) {
        numberOfColors = productObject.prices;
      } else {
        numberOfColors = productObject.prices.filter(
          p => !!p.number_of_colors && p.size === size
        );
      }
      let flags = [],
        output = [],
        l = numberOfColors.length,
        i;
      for (i = 0; i < l; i++) {
        if (flags[numberOfColors[i].number_of_colors]) {
          continue;
        }
        flags[numberOfColors[i].number_of_colors] = true;
        output.push(numberOfColors[i]);
      }
      output = naturalSort(output.map(o => o.number_of_colors.trim()));
      return output.length
        ? output.map(p => ({
            key: p.trim(),
            label: p.trim()
          }))
        : [];
    },
    promotionalSizes() {
      const product = this.form.product;

      if (product === null) {
        return [];
      }
      const productObject =
        this.form.model ||
        this.promotionalProduct.options.find(({ id }) => product === id);
      let productSizes = productObject
        ? productObject.sizes.split(',').map(s => s.trim())
        : [];

      let productSizesFiltered = this.sortedGarmentsSizes.filter(gs =>
        productSizes.includes(gs)
      );
      return productSizesFiltered
        ? productSizesFiltered.map(s => ({
            key: s.trim(),
            label: s.trim()
          }))
        : [];
    },
    promotionalQuantities() {
      if (
        !this.promotionalProductModel ||
        !this.promotionalProductModel.pricing_formula
      ) {
        return [];
      }
      let prices = [];
      const size = this.form.size;
      const number_of_colors = this.form.number_of_colors;
      switch (this.promotionalProductModel.pricing_formula) {
        case 'quantity_size':
          if (!size) {
            return [];
          }
          prices = this.promotionalProductModel.prices.filter(
            p => p.size === size
          );
          break;
        case 'quantity_per_item_size':
          if (!size) {
            return [];
          }
          prices = this.promotionalProductModel.prices.filter(
            p => p.size === size && p.number_of_colors === number_of_colors
          );
          break;
        case 'quantity_per_item_fixed':
          prices = this.promotionalProductModel.prices;
          break;
      }
      const quantityObject = prices.map(p => ({
        key: p.quantity,
        label: p.quantity,
        quantity: p.quantity
      }));
      return quantityObject ? quantityObject.slice() : [];
    },
    promotionalColors() {
      const product = this.form.product;
      if (product === null || (this.form.model && this.isPetProduct) ) {
        return [];
      }
      const productObject =
        this.form.model ||
        this.promotionalProduct.options.find(({ id }) => product === id);
      return productObject
        ? productObject.colors.map(c => ({
            key: c,
            label: c
          }))
        : [];
    },
    promotionalProductTypes() {
      const productTypeObject = this.formOptions.product_types;
      return productTypeObject ? productTypeObject.slice() : [];
    },
    promotionalProductCategories() {
      const productCategoryObject = this.formOptions.product_categories[this.form.product_type];
      return productCategoryObject ? productCategoryObject.slice() : [];
    },
    numberOfColorsModel: {
      get() {
        if (this.form.number_of_colors === null) {
          return null;
        }
        return this.numberOfColors.find(
          ({ key }) => this.form.number_of_colors === key
        );
      },
      set(option) {
        this.form.number_of_colors = option.key;
        this.form._number_of_colors = option.label;
      }
    },
    numberOfColorsLabel() {
      if (this.isPetProduct && !this.isPetToysCategory) {
        return 'Pet Product Size';
      }
      if(this.isShoelacesProduct){
        return 'Shoelace Options';
      }
      if (this.isCuffBeanieProduct) {
        return 'Beanie Option';
      }
      if (this.isScarfsProduct) {
        return 'Scarfs';
      }
      return 'Number of Colors';
    },
    promotionalSizeModel: {
      get() {
        if (this.form.size === null) {
          return null;
        }
        return this.promotionalSizes.find(size => size.key === this.form.size);
      },
      set({ key }) {
        this.form.size = key;
      }
    },
    promotionalProductModel: {
      get() {
        if (this.form.product === null) {
          return null;
        }
        return (
          this.form.model ||
          this.promotionalProduct.options.find(
            ({ id }) => this.form.product === id || null
          )
        );
      },
      set(option) {
        const sizes = [];
        option.sizes.split(',').forEach(sizeKey => {
          sizes.push({
            key: sizeKey.trim(),
            label: sizeKey.trim()
          });
        });
        this.formData.sizes = sizes;
        this.form.product = option.id;
        this.form._product = option.name;
        this.form.model = option;
      }
    },
    isPetProduct() {
      return this.promotionalProductTypeModel && this.promotionalProductTypeModel.label === 'Pet Products';
    },
    isCustomProduct(){
      return this.promotionalProductTypeModel && this.promotionalProductTypeModel.label === 'Custom Products';
    },
    isShoelacesProduct() {
      return this.isCustomProduct && this.promotionalProductModel && this.form.model.product_category.name === 'Shoelaces'
    },
    isCuffBeanieProduct() {
      return this.isCustomProduct && this.promotionalProductModel && this.form.model.product_category.name === 'Cuff Beanie'
    },
    isScarfsProduct() {
      return this.isCustomProduct && this.promotionalProductModel && this.form.model.product_category.name.trim() === 'Scarfs'
    },
    isPetToysCategory() {
      return this.isPetProduct && this.promotionalProductModel && this.form.model.product_category.name === 'Toys'
    },
    promotionalProductTypeModel: {
      get() {
        if (this.form.product_type === null) {
          return null;
        }
        return this.promotionalProductTypes.find(
          ({ key }) => this.form.product_type === key
        );
      },
      set(option) {
        this.form.product_type = option.key;
        this.form._product_type = option.label;
      }
    },
    promotionalProductCategoryModel: {
      get() {
        if (this.form.product_category === null) {
          return null;
        }
        return this.promotionalProductCategories.find(
          ({ key }) => this.form.product_category === key
        );
      },
      set(option) {
        this.form.product_category = option.key;
        this.form._product_category = option.label;
        this.onPromotionalProductSearchChange()
      }
    },
    promotionalQuantityModel: {
      get() {
        if (this.form.quantity === null) {
          return null;
        }
        return this.form.quantity;
        /*return this.promotionalQuantities.find(
          ({ key }) => this.form.quantity === key
        );*/
      },
      set(option) {
        this.form.quantity = option;
        this.form._quantity = option;
      }
    },
    promotionalColorModel: {
      get() {
        if (this.form.color === null) {
          return null;
        }
        return this.promotionalColors.find(
          ({ key }) => this.form.color === key
        );
      },
      set(option) {
        this.form.color = option.key;
        this.form._color = option.label;
      }
    },
    productPrice() {
      if (this.promotionalQuantityModel) {
        return findProductPrice(this.design.specific.item, this.formOptions) + getAddonsTotal(this.design, this.formOptions) / this.promotionalQuantityModel
      }
      return 0;
    }
  },
  methods: {
    findProductPrice: findProductPrice,
    getAddonsTotal: getAddonsTotal,
    onPromotionalProductSearchChange: debounce(function(searchQuery) {
      if (!searchQuery || searchQuery.length === 0) {
        // this.promotionalProduct.isLoading = false;
        // return;
      }

      this.promotionalProduct.isLoading = true;
      this.promotionalProduct.searchQuery = searchQuery||'';

      this.$apiService
        .findPromotionalProducts(searchQuery, {
          service: this.service,
          perPage: 30,
          categoryId: this.form.product_category,
          companyId:
            this.parentForm.account.company_id === 'create-new'
              ? null
              : this.parentForm.account.company_id
        })
        .then(({ data }) => {
          this.promotionalProduct.options = data.slice();
        })
        .catch(error => {
          console.log(error);
        })
        .then(() => {
          this.promotionalProduct.isLoading = false;
        });
    }, 500)
  }
};
</script>
