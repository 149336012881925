<template>
  <div class="navbar-item has-dropdown is-hoverable is-hidden-touch" v-if="widgetType === 'navbar'">
    <router-link :to="'/notifications'" class="navbar-link is-arrowless is-ignore-active"
                 :class="{
      'has-text-warning': isWarn && !isMentionedMe(uM),
      'has-text-danger': isMentionedMe(uM),
      'has-text-link': !isWarn,
    }">
      <span class="icon is-medium has-text-link" aria-haspopup="true">
        <i class="ff-2x ff-bell" aria-hidden="true"/>
        <span v-if="isWarn"
              class="tag message-icon is-warning is-rounded has-text-white"
              :class="{'is-danger': isMentionedMe(uM)}">{{ isWarn }}</span>
      </span>
    </router-link>
    <div class="navbar-dropdown is-right" v-show="isWarn">
      <a v-for="key in Object.keys(unreadOrdersMessages)"
         v-if="unreadOrdersMessages[key].length"
         @click="openMessenger(unreadOrdersMessages[key][0].order_id)"
         class="dropdown-item">
        <span
          class="tag is-warning is-rounded"
          :class="{'is-danger': isMentionedMe(unreadOrdersMessages[key])}">
          {{ unreadOrdersMessages[key].length }}
        </span>
        <span>Order #{{ key }}</span>
      </a>
      <hr class="navbar-divider"/>
      <router-link :to="'/notifications'" class="dropdown-item">View All</router-link>
    </div>
  </div>
  <router-link v-else :to="'/notifications'" class="is-flex navbar-link is-arrowless is-pulled-right-messenger is-ignore-active
  is-hidden-desktop
"
               :class="{
      'has-text-warning': isWarn && !isMentionedMe(uM),
      'has-text-danger': isMentionedMe(uM),
      'has-text-link': !isWarn
    }">
    <span class="icon is-medium has-text-link" aria-haspopup="true">
      <i class="ff-2x ff-bell" aria-hidden="true"/>
      <span v-if="isWarn"
            class="tag message-icon is-warning is-rounded has-text-white"
            :class="{'is-danger': isMentionedMe(uM)}">{{ isWarn }}</span>
    </span>
  </router-link>
</template>

<style lang="scss" scoped>
</style>

<script>
import {mapGetters} from 'vuex';
import {checkRoles} from '../../router/checkRoles';
import {isMentionedMe, openMessenger} from '../../helpers/messages';

import {LOAD_UNREAD_MESSAGES} from '../../store/action-types';

export default {
  name: 'messenger-notifier',
  props: {
    widgetType: {
      type: String,
      default: 'navbar'
    }
  },
  data: () => ({
    uM: []
  }),
  computed: {
    ...mapGetters(['unreadMessages', 'unreadOrdersMessages']),
    notCustomer() {
      return !checkRoles(this.$store.getters.roles, ['customer'], true);
    },
    isWarn: function() {
      return this.uM && this.uM.length;
    }
  },
  methods: {
    openMessenger: openMessenger,
    isMentionedMe: isMentionedMe
  },
  watch: {
    // add delay for icon
    unreadMessages: function(uM) {
      if (this.timer) clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        this.uM = uM;
      }, 1000);
    }
  },
  mounted() {
    this.$store.dispatch(LOAD_UNREAD_MESSAGES);
  }
};
</script>

<style lang="scss" scoped>
.message-icon {
  position: absolute;
  top: 5px;
  right: 8px;
  font-size: 10px;
}

.tag {
  line-height: 22px;
  color: white;
}

.tag + span {
  margin-left: 5px;
}
.is-ignore-active {
  &.is-active{
    --bulma-navbar-item-background-l: transparent;
  }
}
.is-pulled-right-messenger {
  align-self: center;
  margin-inline-start: auto;
  margin-inline-end: 0.375rem;
}
</style>
