<template>
  <div>
    <div class="columns filters-block">
      <div class="column is-12 ">
        <div class="columns">
          <button v-for="filter in availableFilters"
                  @click="applyFilters(filter.key,{
                date_from: filter.date_from,
                date_to:filter.date_to
              })"
                  :class="{ active: activeFilter === filter.key }"
                  :disabled="isDataFetching" class="button">
            {{ filter.name }}
          </button>
          <button
            @click="activeFilter = 'custom'"
            :class="{ active: this.activeFilter === 'custom' }"
            :disabled="isDataFetching" class="button">
            Custom
          </button>
          <button @click="applyFilters(null,{})" :disabled="isDataFetching" class="button clear-filters">
            Clear filters
          </button>
        </div>
        <div class="columns" v-if="activeFilter === 'custom'">
          <div class="column is-3 input-cntr">
            <label class="label" for="date_from"  style="margin-bottom: 0">From</label>
            <div class="control">
              <datepicker
                :disabled="isDataFetching"
                v-model="filters.date_from"
                :config="{wrap: true, dateFormat: 'Z', altInput: true, altFormat: 'm/d/Y'}"
                id="date_from"
              />
            </div>
          </div>
          <div class="column is-3 input-cntr">
            <label class="label" for="date_from" style="margin-bottom: 0">To</label>
            <div class="control">
              <datepicker
                :disabled="isDataFetching"
                v-model="filters.date_to"
                :config="{wrap: true, dateFormat: 'Z', altInput: true, altFormat: 'm/d/Y'}"
                id="date_to"
              />
            </div>
          </div>

          <button
            @click="applyFilters('custom',filters)"
            :disabled="isDataFetching" class="button apply-filters">
            Apply
          </button>
        </div>
      </div>
    </div>

    <div class="columns charts">
      <div class="column is-6">
        <apexchart class="chart" :options="conversionsCasChartOptions" :series="conversionsCasChartData"/>
      </div>
      <div class="column is-6">
        <apexchart class="chart" :options="conversionsNonCasChartOptions" :series="conversionsNonCasChartData"/>
      </div>
    </div>
    <div class="columns charts">
      <div class="column is-6">
        <apexchart class="chart" :options="totalsCasChartOptions" :series="totalsCasChartData"/>
      </div>
      <div class="column is-6">
        <apexchart class="chart" :options="totalsNotCasChartOptions" :series="totalsNotCasChartData"/>
      </div>
    </div>
    <div class="columns charts">
      <div class="column is-6">
        <apexchart class="chart" :options="avgOrderValueCasChartOptions" :series="avgOrderValueCasChartData"/>
      </div>
      <div class="column is-6">
        <apexchart class="chart" :options="avgOrderValueNotCasChartOptions" :series="avgOrderValueNotCasChartData"/>
      </div>
    </div>
    <div class="columns charts">
      <div class="column is-6">
        <apexchart class="chart" :options="conversionRatioCasChartOptions" :series="conversionRatioCasChartData"/>
      </div>
      <div class="column is-6">
        <apexchart class="chart" :options="conversionRatioNotCasChartOptions" :series="conversionRatioNotCasChartData"/>
      </div>
    </div>

  </div>
</template>

<script>
import Vue from 'vue';
import VueApexCharts from 'vue-apexcharts';
import apiService from '@/services/api.service';
import alertify from 'alertify.js';
import Datepicker from '../../../modules/datepicker/index';

Vue.use(VueApexCharts);

export default {
  name: 'csr-convesions',
  components: {
    apexchart: VueApexCharts,
    Datepicker
  },

  data() {
    return {
      activeFilter: null,
      filters: {},
      isDataFetching: false,
      dashboardData: {
        orders: null,
        total: null,
        avg: null,
        conv: null
      },
      availableFilters: [
        {
          key: 'rolling_12',
          date_from: new Date(new Date().getFullYear() - 1, new Date().getMonth(), new Date().getDate()).toISOString(),
          date_to: new Date().toISOString(),
          name: 'Rolling 12'
        },
        {
          key: 'year_to_date',
          date_from: new Date(new Date().getFullYear(), 0, 1).toISOString(),
          date_to: new Date(new Date().getFullYear(), 11, 31, 23, 59, 59).toISOString(),
          name: 'Year to Date'
        },
        {
          key: 'current_month',
          date_from: new Date(new Date().getFullYear(), new Date().getMonth(), 1).toISOString(),
          date_to: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0, 23, 59, 59).toISOString(),
          name: 'Current Month'
        },
        {
          key: 'previous_month',
          date_from: new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1).toISOString(),
          date_to: new Date(new Date().getFullYear(), new Date().getMonth(), 0, 23, 59, 59).toISOString(),
          name: 'Previous Month'
        },
        {
          key: 'last_year',
          date_from: new Date(new Date().getFullYear() - 1, 0, 1).toISOString(),
          date_to: new Date(new Date().getFullYear() - 1, 11, 31, 23, 59, 59).toISOString(),
          name: 'Last Year'
        }
      ]
    }
      ;

  },
  computed: {
    dataLabelsConfig() {
      return {
        enabled: true,
        offsetX: 30,
        position: 'bottom', // top, center, bottom
        style: {
          fontSize: '12px',
          colors: ['#304758']
        }
      };
    },
    dataLabelsConfigPercentage() {
      return {
        enabled: true,
        offsetX: 30,
        position: 'bottom', // top, center, bottom
        style: {
          fontSize: '12px',
          colors: ['#304758']
        },
        formatter: (value) => {
          return value + ' %';
        }
      };
    },
    dataLabelsConfigFormatted() {
      return {
        enabled: true,
        offsetX: 75,
        position: 'bottom', // top, center, bottom
        style: {
          fontSize: '12px',
          colors: ['#304758']
        },
        formatter: (value) => {
          return this.$options.filters.price(value);
        }
      };
    },
    plotOptionsConfig() {
      return {
        bar: {
          horizontal: true,
          dataLabels: {
            position: 'top',
            total: {
              enabled: true,
              offsetX: 0,
              style: {
                fontSize: '13px',
                fontWeight: 900,
                colors: ['#304758']
              }
            }
          }
        }
      };
    },
    subtitleChartStyle() {
      return {
        fontSize: '12px',
        fontWeight: 'bold'
      };
    },

    casLabels() {
      return this.dashboardData && this.dashboardData.orders ? this.dashboardData.orders.cas.labels : ['No data'];
    },
    nonCasLabels() {
      return this.dashboardData && this.dashboardData.orders && this.dashboardData.orders.not_cas ? this.dashboardData.orders.not_cas.labels : ['No data'];
    },
    conversionsCasChartSubtitle(isCas = true) {
      let totalConverted = 0;
      let totalUnconverted = 0;
      if (this.dashboardData && this.dashboardData.orders) {
        this.dashboardData.orders.cas.series.converted.forEach((element) => totalConverted += element);
        this.dashboardData.orders.cas.series.unconverted.forEach((element) => totalUnconverted += element);
      }

      return 'Total Converted / Unconverted: ' + totalConverted + ' / ' + totalUnconverted;
    },
    conversionsNotCasChartSubtitle(isCas = true) {
      let totalConverted = 0;
      let totalUnconverted = 0;
      if (this.dashboardData && this.dashboardData.orders && this.dashboardData.orders.not_cas) {
        this.dashboardData.orders.not_cas.series.converted.forEach((element) => totalConverted += element);
        this.dashboardData.orders.not_cas.series.unconverted.forEach((element) => totalUnconverted += element);
      }

      return 'Total Converted / Unconverted: ' + totalConverted + ' / ' + totalUnconverted;
    },

    orderTotalCasChartSubtitle() {
      let totalValue = 0;
      let totalOrderValue = 0;
      if (this.dashboardData && this.dashboardData.total) {

        this.dashboardData.total.cas.series.order_value.forEach((element) => totalOrderValue += Number(element));
        this.dashboardData.total.cas.series.total_value.forEach((element) => totalValue += Number(element));
      }

      return 'Order Value / Total Value: ' + this.$options.filters.price(totalOrderValue) + ' / ' + this.$options.filters.price(totalValue);
    },
    orderTotalNotCasChartSubtitle() {
      let totalValue = 0;
      let totalOrderValue = 0;
      if (this.dashboardData && this.dashboardData.total && this.dashboardData.total.not_cas) {
        this.dashboardData.total.not_cas.series.order_value.forEach((element) => totalOrderValue += Number(element));
        this.dashboardData.total.not_cas.series.total_value.forEach((element) => totalValue += Number(element));
      }
      return 'Order Value / Total Value: ' + this.$options.filters.price(totalOrderValue) + ' / ' + this.$options.filters.price(totalValue);
    },
    averageCasOrderValue(){
      let totalValue = 0;
      if (this.dashboardData && this.dashboardData.avg) {
        this.dashboardData.avg.cas.series.avg_order.forEach((element) => totalValue += Number(element));
        totalValue = totalValue/this.dashboardData.avg.cas.series.avg_order.length
      }
      return 'Average Order Value: ' + this.$options.filters.price(totalValue);
    },
    averageNotCasOrderValue(){
      let totalValue = 0;
      if (this.dashboardData && this.dashboardData.avg && this.dashboardData.avg.not_cas) {
        this.dashboardData.avg.not_cas.series.avg_order.forEach((element) => totalValue += Number(element));
        totalValue = totalValue/this.dashboardData.avg.not_cas.series.avg_order.length
      }
      return 'Average Order Value: ' + this.$options.filters.price(totalValue);
    },
    conversionCasTotalChartSubtitle(){
      let totalValue = 0;
      if (this.dashboardData && this.dashboardData.conv) {
        this.dashboardData.conv.cas.series.conversion_ratio.forEach((element) => totalValue += Number(element));
        totalValue = totalValue/this.dashboardData.conv.cas.series.conversion_ratio.length
      }
      return 'Average Conversion Ratio: ' + Math.round(totalValue) + '%';
    },
    conversionNotCasTotalChartSubtitle(){
      let totalValue = 0;
      if (this.dashboardData && this.dashboardData.conv && this.dashboardData.conv.not_cas) {
        this.dashboardData.conv.not_cas.series.conversion_ratio.forEach((element) => totalValue += Number(element));
        totalValue = totalValue/this.dashboardData.conv.not_cas.series.conversion_ratio.length
      }
      return 'Average Conversion Ratio: ' + Math.round(totalValue) + '%';
    },
    conversionsCasChartOptions() {
      return {
        title: {
          text: 'Converted / Unconverted Estimates (CAS users)'
        },
        subtitle: {
          text: this.conversionsCasChartSubtitle,
          style: this.subtitleChartStyle
        },
        chart: {
          height: 400,
          type: 'bar',
          stacked: true
        },
        plotOptions: this.plotOptionsConfig,
        dataLabels: {
          enabled: false
        },
        xaxis: {
          categories: this.casLabels
        }
      };
    },
    totalsCasChartOptions() {
      return {
        title: {
          text: 'Order value / Total value (CAS users)'
        },
        subtitle: {
          text: this.orderTotalCasChartSubtitle,
          style: this.subtitleChartStyle
        },
        chart: {
          height: 400,
          type: 'bar'
        },
        plotOptions: this.plotOptionsConfig,
        dataLabels: this.dataLabelsConfigFormatted,
        xaxis: {
          categories: this.casLabels
        }
      };
    },
    avgOrderValueCasChartOptions() {
      return {
        title: {
          text: 'Average Order Value (CAS users)'
        },
        subtitle:{
          text: this.averageCasOrderValue,
          style: this.subtitleChartStyle
        },
        chart: {
          height: 400,
          type: 'bar'
        },
        plotOptions: this.plotOptionsConfig,
        dataLabels: this.dataLabelsConfigFormatted,
        xaxis: {
          categories: this.casLabels
        }
      };
    },
    conversionRatioCasChartOptions() {
      return {
        title: {
          text: 'Converted Percentage (CAS users)'
        },
        subtitle: {
          text: this.conversionCasTotalChartSubtitle,
          style: this.subtitleChartStyle
        },
        chart: {
          height: 400,
          type: 'bar'
        },
        plotOptions: this.plotOptionsConfig,
        dataLabels: this.dataLabelsConfigPercentage,
        xaxis: {
          categories: this.casLabels,
          labels:
            {
              formatter: (value) => {
                return value + '%';
              }
            }
        }
      };
    },
    conversionRatioNotCasChartOptions() {
      return {
        title: {
          text: 'Converted Percentage (Not CAS users)'
        },
        subtitle: {
          text: this.conversionNotCasTotalChartSubtitle,
          style: this.subtitleChartStyle
        },
        chart: {
          height: 400,
          type: 'bar'
        },
        plotOptions: this.plotOptionsConfig,
        dataLabels: this.dataLabelsConfigPercentage,
        xaxis: {
          categories: this.nonCasLabels,
          labels:
            {
              formatter: (value) => {
                return value + '%';
              }
            }
        }
      };
    },
    avgOrderValueNotCasChartOptions() {
      return {
        title: {
          text: 'Average Order Value (Not CAS users)'
        },
        subtitle:{
          text: this.averageNotCasOrderValue,
          style: this.subtitleChartStyle
        },
        chart: {
          height: 400,
          type: 'bar'
        },
        plotOptions: this.plotOptionsConfig,
        dataLabels: this.dataLabelsConfigFormatted,
        xaxis: {
          categories: this.nonCasLabels
        }
      };
    },
    totalsNotCasChartOptions() {
      return {
        title: {
          text: 'Order value / Total value (Not CAS users)'
        },
        subtitle: {
          text: this.orderTotalNotCasChartSubtitle,
          style: this.subtitleChartStyle
        },
        chart: {
          height: 400,
          type: 'bar'
        },
        plotOptions: this.plotOptionsConfig,
        dataLabels: this.dataLabelsConfigFormatted,
        xaxis: {
          categories: this.nonCasLabels
        }
      };
    },
    conversionsNonCasChartOptions() {
      return {
        title: {
          text: 'Converted / Unconverted Estimates (Not CAS users)'
        },
        subtitle: {
          text: this.conversionsNotCasChartSubtitle,
          style: this.subtitleChartStyle
        },
        chart: {
          height: 400,
          type: 'bar',
          stacked: true
        },
        plotOptions: this.plotOptionsConfig,
        dataLabels: {
          enabled: false
        },
        xaxis: {
          categories: this.nonCasLabels
        }
      };
    },
    conversionsCasChartData() {
      return [
        {
          name: 'Converted',
          data: this.dashboardData && this.dashboardData.orders ? this.dashboardData.orders.cas.series.converted : []
        },
        {
          name: 'Unconverted',
          data: this.dashboardData && this.dashboardData.orders ? this.dashboardData.orders.cas.series.unconverted : []
        }
      ];
    },
    totalsCasChartData() {
      return [
        {
          name: 'Order Value',
          data: this.dashboardData && this.dashboardData.total ? this.dashboardData.total.cas.series.order_value : []
        },
        {
          name: 'Total Value',
          data: this.dashboardData && this.dashboardData.total ? this.dashboardData.total.cas.series.total_value : []
        }
      ];
    },
    avgOrderValueCasChartData() {
      return [
        {
          name: 'Average Order Value',
          data: this.dashboardData && this.dashboardData.avg ? this.dashboardData.avg.cas.series.avg_order : []
        }
      ];
    },
    conversionRatioCasChartData() {
      return [
        {
          name: 'Conversion Ratio',
          data: this.dashboardData && this.dashboardData.conv ? this.dashboardData.conv.cas.series.conversion_ratio : []
        }
      ];
    },
    conversionRatioNotCasChartData() {
      return [
        {
          name: 'Conversion Ratio',
          data: this.dashboardData && this.dashboardData.conv && this.dashboardData.conv.not_cas ? this.dashboardData.conv.not_cas.series.conversion_ratio : []
        }
      ];
    },
    avgOrderValueNotCasChartData() {
      return [
        {
          name: 'Average Order Value',
          data: this.dashboardData && this.dashboardData.avg && this.dashboardData.avg.not_cas ? this.dashboardData.avg.not_cas.series.avg_order : []
        }
      ];
    },
    totalsNotCasChartData() {
      return [
        {
          name: 'Order Value',
          data: this.dashboardData && this.dashboardData.total && this.dashboardData.total.not_cas ? this.dashboardData.total.not_cas.series.order_value : []
        },
        {
          name: 'Total Value',
          data: this.dashboardData && this.dashboardData.total && this.dashboardData.total.not_cas ? this.dashboardData.total.not_cas.series.total_value : []
        }
      ];
    },
    conversionsNonCasChartData() {
      return [
        {
          name: 'Converted',
          data: this.dashboardData && this.dashboardData.orders && this.dashboardData.orders.not_cas ? this.dashboardData.orders.not_cas.series.converted : []
        },
        {
          name: 'Unconverted',
          data: this.dashboardData && this.dashboardData.orders && this.dashboardData.orders.not_cas ? this.dashboardData.orders.not_cas.series.unconverted : []
        }
      ];
    }
  },
  methods: {
    loadDashboardData() {
      this.isDataFetching = true;
      apiService
        .getReport('csr_conversion', this.filters)
        .then(res => {
          this.$set(this, 'dashboardData', res);
        })
        .catch(err => {
          alertify.error(err.body.message);
        })
        .finally(() => {
          this.isDataFetching = false;
        });
    },
    applyFilters(filterName, filters) {
      this.activeFilter = filterName;
      this.dashboardData = {
        orders: null,
        total: null,
        avg: null,
        conv: null
      };
      this.filters = {...filters, ...{timezone: this.filters.timezone}};
      this.loadDashboardData();
    }
  },
  created() {
    this.filters.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const currentDate = new Date();
    this.applyFilters('rolling_12', {
      date_from: new Date(currentDate.getFullYear() - 1, currentDate.getMonth(), currentDate.getDate()).toISOString(),
      date_to: currentDate.toISOString()
    });
  }
};
</script>
<style lang="scss" scoped>
.filters-block {
  margin-top: 1rem;
  padding: 1rem;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);

  button {
    margin: 1rem 0 1rem 1rem;

    &.apply-filters {
      margin-top: 30px;
    }
  }

  .clear-filters {
    background-color: inherit;
    border: none;

    &.active,
    &:hover,
    &:focus {
      color: #1793D3;
    }

    &:active {
      box-shadow: none;
    }

    &[disabled] {
      background-color: inherit;
      border-color: inherit;
    }
  }
}

.totals {
  .block {
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    padding: 10px;
  }

  .column {
    margin-top: 10px;
    &:first-child {
      margin-left: -10px;
      margin-right: 10px;
    }

    &:last-child {
      margin-left: 10px;
      margin-right: -10px;
    }
  }
}

.charts {
  .chart {
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    padding: 10px;
    margin-left: -10px;
    margin-right: -10px;
  }

  .column {
    &.is-6 {
      width: 49.5%;

      &:first-child {
        margin-right: 0.5%;
      }

      &:last-child {
        margin-left: 0.5%;
      }
    }
  }

  .aparel-quantity-range {
    button {
      margin: 1rem 0 1rem 1rem;
    }
  }
}
</style>
