<template>
  <div>
    <div class="columns">
      <div class="column">
        <companies-report-form/>
      </div>
    </div>
    <v-dialog></v-dialog>
  </div>
</template>

<script>
import Grid from '../../common/grid/grid';
import Paginator from '../../common/paginator';
import Datepicker from '../../../modules/datepicker/index';
import CompaniesReportForm from '../companies-report-form';

export default {
  name: 'companies-report',
  components: {CompaniesReportForm, Datepicker, Paginator, Grid},
  data() {
    return {
      sortField: 'estimate_becomes_to_order_at',
      sortDir: 'DESC',
      page: 1,
      perPage: 50,
      date_from: null,
      date_to: null,
      reportActive: false
    };
  },
  watch: {},
  computed: {},
  methods: {},
  created() {
  }
};
</script>
