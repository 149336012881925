<template>
  <tr>
    <td>&nbsp;</td>
    <td>&nbsp;</td>
    <td>&nbsp;</td>
    <td>&nbsp;</td>
    <td>
      <invoice-field
        :label="getFieldLabel"
        :value="totalPrice | price"
        class="is-large"
      ></invoice-field>
    </td>
  </tr>
</template>
<script>
import InvoiceField from '@/components/common/invoice-field.vue';
import {flatten} from 'lodash';
import {APPAREL_SERVICE_CODE, EMBROIDERY_SERVICE_CODE} from '@/store/service-types';

export default {
  name: 'total-row',
  components: {InvoiceField},
  props: {
    totalQuantity: {
      type: Number|String|Boolean,
      required: true
    },
    totalPrice: {
      type: Number|String,
      required: true
    },

  },
  computed:{
    getFieldLabel(){
      let label = 'Total Cost';
      if(this.totalQuantity){
        label += ` (${this.$options.filters.intlNumber(this.totalQuantity)} items)`;
      }
      return label
    }
  }
};
</script>
