<template>
  <tr :key="id">
    <td>
      <span class="icon icon_smaller" v-if="!parentId && isNewOrder" v-roles="this.row[0].roles">
        <i class="ff-star-circled tooltip" style="color: #5ac946;">
          <span class="tooltiptext">First Order!</span>
        </i>
      </span>
    </td>
    <!-- expander -->
    <td v-if="showTriangle && !forceExpanded && !parentId">
          <span class="icon icon_smaller clr-blue" @click="$emit('toggleDetailRow', id)">
            <i :class="[
              showDesigns ? 'ff-triangle-down': 'ff-triangle-right'
            ]"></i>
          </span>
    </td>
    <td v-if="!forceExpanded && parentId"></td>
    <td v-if="showTriangle === false"></td>

    <!-- data cells -->
    <cell v-for="(column, cellKey) in dataColumns"
      :cell="cell(cellKey)"
      :id="id"
      :parentId="parentId"
      :rowType="rowType"
      :cellKey="cellKey"
      v-roles="column.roles"
      :phase="phase"
          :actionMenu="actionMenu"
          @handleActionsMenu="handleActionsMenu"
      :key="rowType + parentId + id + phase + cellKey +'data'"></cell>
    <!-- end data cells -->

    <!-- action cells -->
    <template v-if="actions">
      <cell v-for="(column, cellKey) in actionColumns"
        :cell="actCell(cellKey)"
        :id="id"
        :rowType="rowType"
        :cellKey="cellKey"
        :phase="phase"
        :parentId="parentId"
         v-roles="column.roles"
            :actionMenu="actionMenu"
            @handleActionsMenu="handleActionsMenu"
            :key="rowType + parentId + id + phase + cellKey +'action'"></cell>
    </template>
    <!-- end action cells-->
  </tr>
</template>

<script>
import Vue from 'vue';
import { forEach, isEmpty } from 'lodash';
import Cell from './cell';
import PmsColorMultiselect from '@/components/common/form/pms-color-multiselect.vue';

export default {
  name: 'row',
  template: '#grid-template',
  props: {
    forceExpanded: {
      type: Boolean,
      default: false
    },
    showDesigns: {
      type: Boolean,
      default: false
    },

    showTriangle: {
      type: Boolean,
      default: true
    },
    rowClass: {
      type: String,
      default: ''
    },
    phase: {
      type: String,
      default: ''
    },
    rowType: {
      type: String,
      default: ''
    },
    id: {
      type: Number,
      default: 0
    },
    parentId: {
      type: Number,
      default: 0
    },
    row: {
      type: Array,
      default: () => {
        [];
      }
    },
    actions: {
      type: Array,
      default: () => {
        [];
      }
    },
    dataColumns: {
      type: Array,
      default: () => {
        [];
      }
    },
    actionColumns: {
      type: Array,
      default: () => {
        [];
      }
    },
    actionMenu: {
      type: Boolean | String,
      default: false
    }
  },
  components: {
    PmsColorMultiselect,
    Cell
  },
  methods: {
    cell(key) {
      // console.log(this.row)
      return this.row[key + 1];
    },
    actCell(key) {
      return this.actions[key + 1];
    },
    handleActionsMenu(cellKey) {
      this.$emit('handleActionsMenu', cellKey);
    }
  },
  computed:{
    isNewOrder(){
      return this.row[0].isFirst;
    }
  }
};
</script>

<style lang="scss" scoped>
/* Tooltip container */
.tooltip {
  position: relative;
  display: inline-block;
}

/* Tooltip text */
.tooltip .tooltiptext {
  visibility: hidden;
  font-size: 0.7rem;
  font-weight: bold;
  font-style: normal;
  background-color: rgba(86, 86, 99, 0.9);
  color: #fff;
  text-align: center;
  padding: 0;
  border-radius: 20px;

  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 1000;
  width: 60px;
  bottom: 120%;
  left: 50%;
  margin-left: -30px; /* Use half of the width (120/2 = 60), to center the tooltip */
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
  visibility: visible;
}
.tooltip .tooltiptext::after {
  content: " ";
  position: absolute;
  top: 100%; /* At the bottom of the tooltip */
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: rgba(86, 86, 99, 0.9) transparent transparent transparent;
}
</style>
