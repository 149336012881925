<template>
  <div class="content">
    <div v-if="finishing.name">
      <strong>Finishing:</strong> {{finishing.name || finishing.label}}
    </div>
    <div><strong>{{finishing.label}}:</strong>
      <span v-roles="['admin', 'csr', 'production-coordinator', 'purchaser', 'art-producer', 'customer']">
        {{finishing.price | price}}
      </span>
    </div>
  </div>
</template>

<script>
  import Vue from 'vue'

  export default {
    props: ['finishing'],
    name: 'finishing-summary-hang-tag'
  };
</script>
