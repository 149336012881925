<template>
  <div class="poster-design-item">
    <table class="table">
      <thead>
        <tr>
          <th>Number of Colors</th>
          <th v-roles="['admin', 'csr', 'production-coordinator', 'purchaser', 'art-producer', 'customer']">Cost</th>
          <th>Size</th>
          <th>Quantity</th>
          <th>Brand</th>
          <th>Paper Weight</th>
          <th>Paper Color</th>
          <th>Bleed Type</th>
          <th>Ink Type</th>
          <th v-roles="['admin', 'csr', 'production-coordinator', 'purchaser', 'art-producer', 'customer']">Total</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{{design.numOfColors}}</td>
          <td v-roles="['admin', 'csr', 'production-coordinator', 'purchaser', 'art-producer', 'customer']">{{(design.total.price / design.total.qty) | price}}</td>
          <td>{{design.size}}</td>
          <td>{{design.total.qty | itemsNumberFormat}}</td>
          <td>{{design.brand}}</td>
          <td>{{design.paperWeight}}</td>
          <td>{{design.paperColor}}</td>
          <td>{{design.bleedType}}</td>
          <td>{{design.inkType}}</td>
          <td v-roles="['admin', 'csr', 'production-coordinator', 'purchaser', 'art-producer', 'customer']">{{design.total.price | price}}</td>
        </tr>
      </tbody>
    </table>

    <div v-if="design.total && design.total.upcharge > 0" v-roles="['admin', 'csr', 'production-coordinator', 'purchaser', 'art-producer', 'customer']">
      <strong>Upcharge for <span v-if="design.name">{{design.name}}</span><span v-else>Design #{{ parseInt(index)+1 }}</span>:</strong> {{design.total.upcharge | price}}
      <p v-if="design.total.upcharge_description">{{design.total.upcharge_description}}</p>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  .table {
    td, th {
      padding: 8px 4px;
    }
  }
</style>

<script>
  import Vue from 'vue';

  export default {
    name: 'poster-design-type',
    props: ['design']
  };
</script>

