<template>
  <div class="form-section form-section-finishings" ref="wrapper">
    <div class="step-section">
      <header class="step-section__header">
        <h3 class="step-section__title">{{ 'Finishings' | uppercase }}</h3>
      </header>
      <finishing
        class="step-section__item"
        v-for="item, index in value"
        v-model="value[index]"
        :key="item.uniqueId"
        :index="index"
        :scope="scope"
        :qty="value.length"
        :parentForm="parentForm"
        :finishingsPrices="finishingsPrices"
        @delete="deleteFinishing"
      ></finishing>
      <div class="step-section__actions">
        <a href="#" class="link-add" @click.prevent="addFinishing">
          <span class="link-add__icon"></span>
          Add Finishing
        </a>
      </div>
    </div>
  </div>
</template>
<script>
import { uniqueId, merge, cloneDeep } from 'lodash';

import { FORM_ESTIMATE_TEMPLATES } from '@/helpers/estimates';

import StepBase from './StepBase';
import Finishing from './Fieldsets/Finishing';

export default {
  name: 'form-estimate-finishings',
  extends: StepBase,
  components: {
    Finishing
  },
  props: {
    finishingsPrices: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      isDirty: false
    };
  },
  computed: {},
  created() {
    if (this.value.length === 0) this.addFinishing();
    this.$watch(
      'value',
      value => {
        if (
          !value.every(
            finishing =>
              finishing.common.finishing_option === null ||
              (finishing.specific.tag_garments_quantity <= 0 ||
                finishing.specific.tag_garments_quantity === null)
          )
        ) {
          this.isDirty = true;
        }
      },
      { deep: true, immediate: true }
    );
  },
  methods: {
    onPassed() {
      this.value.isPassed = true;
      this.$emit(
        'data',
        this.value.filter(finishing => !!finishing.common.finishing_option)
      );
    },
    deleteFinishing(index) {
      this.value.splice(index, 1);
      if (this.value.length === 0) {
        this.addFinishing();
        this.$nextTick(() => {
          this.isDirty = false;
        });
      } else if (
        this.value.every(
          finishing =>
            finishing.common.finishing_option === null ||
            (finishing.specific.tag_garments_quantity <= 0 ||
              finishing.specific.tag_garments_quantity === null)
        )
      ) {
        this.$nextTick(() => {
          this.isDirty = false;
        });
      }
    },
    addFinishing() {
      this.$emit('add-finishing');
    }
  }
};
</script>
