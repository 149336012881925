<template>
  <div class="customer-order-details">
    <nav class="level">
      <!-- Left side -->
      <div class="level-item level-left">
        <breadcrumbs :links="crumbLinks"/>
      </div>
      <div class="level-item level-right">
        <button
          class="button is-small btn-with-margin"
          @click="printInvoice">Print invoice
        </button>
        <router-link v-if="!isPaid && invoice && invoice.link_hash && data.order.is_approved" class="button has-text-white is-small is-success btn-pay-now btn-with-margin"
                     :to="'/customer/orders/' + id + '/invoice/' + invoice.link_hash">Pay Now</router-link>
      </div>
    </nav>
    <div class="columns">
      <div class="column is-9">
        <details-estimate
          :data="data"
          :formOptions="formOptions"
          :shippingOptions="shippingOptions"
          :finishingsPrices="finishingsPrices"
          v-if="!isLoading"
        ></details-estimate>
        <div v-else>Loading...</div>
      </div>
      <div class="column is-3">
        <div class="estimate-totals" style="margin-bottom: 20px;" v-if="!isLoading">
          <h3 class="estimate-totals__title">Order Summary</h3>
          <ul class="estimate-totals__list">
            <li>
              <div class="item">
                <span class="item__label">Designs</span>
                <span class="item__total">{{ totals.designsTotal  | price }}</span>
              </div>
              <ul class="estimate-totals__subitems">
                <li v-for="designTotal, index in totals.designs" :key="index">
                  <div class="item">
                    <label class="item__label">Design {{ index + 1 }}</label>
                    <span class="item__total">{{ designTotal.total | price }}</span>
                  </div>
                </li>
              </ul>
            </li>
            <li class="estimate-totals__item" v-if="totals.tagSetups > 0">
              <div class="item">
                <span class="item__label">Tag Setups</span>
                <span class="item__total">{{ totals.tagSetups | price }}</span>
              </div>
            </li>
            <li class="estimate-totals__item">
              <div class="item">
                <span class="item__label">Shipping</span>
                <span class="item__total">{{ totals.shipping | price }}</span>
              </div>
            </li>
            <li class="estimate-totals__item" v-if="data.payment.state_tax">
              <div class="item">
                <span class="item__label">State Tax</span>
                <span class="item__total">{{ data.payment.state_tax | price }}</span>
              </div>
            </li>
            <li class="estimate-totals__item" v-if="totals.rushFee > 0">
              <div class="item">
                <label class="item__label">Rush Fee ({{ data.payment.rush_fee.rush_fee_percentage }}%)</label>
                <span class="item__total">{{ totals.rushFee | price }}</span>
              </div>
            </li>
            <li class="estimate-totals__item" v-if="totals.discount > 0">
              <div class="item">
                <label class="item__label">Discount ({{ data.payment.discount.discount_percentage }}%)</label>
                <span class="item__total">{{ totals.discount | price }}</span>
              </div>
            </li>
          </ul>
          <ul class="estimate-totals__list is-total">
            <li>
              <div class="item">
                <span class="item__label">Total Cost</span>
                <span class="item__total">{{ estimateTotal | price }}</span>
              </div>
            </li>
            <li class="is-amount" v-if="data.order.invoice">
              <div class="item">
                <span class="item__label">Amount Paid</span>
                <span class="item__total">{{ data.order.invoice.paid_sum | price }}</span>
              </div>
            </li>
          </ul>
          <div class="estimate-totals__balance-due"
            :class="{ 'is-paid': balanceDue === 0 }"
            v-if="data.order.invoice"
          >
            <span>Balance Due</span>
            <span class="value">{{ balanceDue | price }}</span>
          </div>
        </div>
        <div class="card is-fullwidth embedded-messenger">
          <header class="card-header">
            <p class="card-header-title">Order Activity</p>
          </header>
          <div class="card-content">
            <messenger :data="messages" :isEmbedded="true"></messenger>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss">
.customer-order-details {
  .btn-with-margin {
    margin-left: 5px;
  }

  .details-base-info {
    box-shadow: 0 1px 2px rgba(10, 10, 10, 0.1);
  }

  .paid-amount {
    padding: 20px;
    display: flex;
    justify-content: flex-end;

    .paid-amount-content {
      width: 25%;
    }
  }
}
</style>
<script>
import Vue from 'vue';
import { mapGetters, mapActions } from 'vuex';
import VueCookie from 'vue-cookie';
import popup from '../../../modules/popupper';
import breadcrumbs from '../../common/breadcrumbs';
import { getEstimateTotals } from '@/helpers/prices';
import apiService from '@/services/api.service';

import estimateSummary from './../../orders/estimate-summary';
import messenger from './../../common/messenger';
import { PAYMENT_STATUS_PAID } from '../../../helpers/orders';
import { LOAD_EDIT_ORDER_INIT_DATA } from '@/store/action-types';

import DetailsEstimate from '@/components/common/DetailsEstimate/DetailsEstimate';
import { IS_VIEWED } from '@/store/action-types';

export default {
  name: 'customer-order-details',
  data() {
    return {
      messages: {
        id: this.$route.params.id
      },
      isLoading: true,
      data: null,
      formOptions: null,
      finishingsPrices: null,
      shippingOptions: null
    };
  },
  watch: {
    id(id) {
      this.$set(this.messages, 'id', id);
    }
  },
  computed: {
    ...mapGetters([
      'orderDetails',
      'orderDetailsSummary',
      'orderDetailsLoading'
    ]),
    id() {
      return this.$route.params.id;
    },
    crumbLinks() {
      return [
        { url: '/customer/dashboard', label: 'Orders' },
        {
          url: '/customer/orders/' + this.id + '/details',
          label: '#' + this.id
        },
        { label: 'Details' }
      ];
    },
    isNotEditable() {
      return (
        (this.orderDetails &&
          this.orderDetails[0] &&
          this.orderDetails[0].designs) ||
        []
      ).some(design => {
        return design.printer_status >= 2; // "In production" and older
      });
    },
    isPaid() {
      if (
        !this.orderDetails ||
        !this.orderDetails[0] ||
        !this.data ||
        !this.data.order
      ) {
        return;
      }

      return (
        this.paidAmount >= this.data.order.total_price ||
        +this.orderDetails[0].payment_status === +PAYMENT_STATUS_PAID
      );
    },
    showPaidAndRemain() {
      return (
        this.orderDetails &&
        this.orderDetails.length &&
        this.orderDetails[0].invoices &&
        this.orderDetails[0].invoices.length
      );
    },
    paidAmount() {
      if (
        !this.orderDetails ||
        !this.orderDetails[0] ||
        !this.orderDetails[0].invoices ||
        !this.orderDetails[0].invoices.length
      ) {
        return;
      }

      return this.orderDetails[0].latest_invoice.paid_sum;
    },
    remainAmount() {
      if (
        !this.orderDetails ||
        !this.orderDetails[0] ||
        !this.orderDetails[0].invoices ||
        !this.orderDetails[0].invoices.length
      ) {
        return;
      }

      const remaining =
        this.orderDetails[0].latest_invoice.amount - this.paidAmount;
      return remaining > 0 ? remaining : 0;
    },
    totalPrice() {
      if (this.estimate.total.upcharge) {
        return +this.estimate.total.price + +this.estimate.total.upcharge;
      } else {
        return +this.estimate.total.price;
      }
    },
    estimate() {
      if (
        !this.orderDetails ||
        !this.orderDetails[0] ||
        !this.orderDetails[0].estimate
      )
        return;

      return typeof this.orderDetails[0].estimate === 'string'
        ? JSON.parse(this.orderDetails[0].estimate)
        : this.orderDetails[0].estimate;
    },
    totals() {
      return getEstimateTotals(
        this.data,
        this.formOptions,
        this.finishingsPrices
      );
    },
    estimateRawTotal() {
      let total = 0;
      total += +this.totals.designsTotal;
      total += +this.totals.shipping;
      total += +this.totals.tagSetups;
      total += +this.totals.stateTaxTotal;
      if (total === 0) return 0;
      return total;
    },
    estimateTotal() {
      let total = this.estimateRawTotal;
      if (total === 0) return 0;
      total -= +this.totals.discount;
      total += +this.totals.rushFee;
      if (total <= 0) return 0;
      return total;
    },
    balanceDue() {
      return this.data.order.invoice.amount - this.data.order.invoice.paid_sum;
    },
    invoice() {
      return this.data && this.data.order && this.data.order.invoice;
    }
  },
  components: {
    estimateSummary,
    messenger,
    breadcrumbs,
    DetailsEstimate
  },
  methods: {
    ...mapActions({
      getInitData: LOAD_EDIT_ORDER_INIT_DATA
    }),
    getDesignProperty(orderId, designId, prop) {
      return this.orderDetails[0].designs.filter(d => d.id === designId)[0][
        prop
      ];
    },
    printInvoice() {
      if (this.invoice && this.invoice.link_hash) {
        window.open('/#/invoices/' + this.invoice.link_hash + '/print');
      }
    },
    convertFormOptions(data) {
      Object.keys(data).forEach(service => {
        if (!data[service].locations) return;
        const locationsRaw = data[service].locations;
        const locations = Object.keys(locationsRaw).map(groupKey => {
          return {
            title: groupKey,
            options: Object.keys(locationsRaw[groupKey]).map(childKey => {
              return {
                id: childKey,
                label: locationsRaw[groupKey][childKey]
              };
            })
          };
        });
        data[service].locations = locations;
      });
      return data;
    },
    fetchEstimateData() {
      return apiService
        .getEstimateData(this.id)
        .then(({ data }) => {
          this.data = data;
        })
        .catch(error => {
          console.error(error);
        });
    },
    fetchFormOptions(userId) {
      return apiService
        .get(
          `estimates/designs/form-options?${
            userId ? `filter[user_id]=${userId}` : ''
          }`
        )
        .then(({ data }) => {
          this.formOptions = this.convertFormOptions(data);
        });
    },
    fetchFinishingsPrices(userId) {
      return apiService.getApparelFinishingPrices(userId).then(response => {
        this.finishingsPrices = response;
      });
    },
    fetchShippingMethods() {
      return apiService.getShippingMethods().then(({ data }) => {
        const { ups } = data;
        this.shippingOptions = ups.services;
      });
    }
  },
  created() {
    this.isLoading = true;
    Promise.all([
      this.fetchEstimateData(),
      this.fetchShippingMethods(),
      this.getInitData()
    ])
      .then(() =>
        Promise.all([
          this.fetchFormOptions(this.data.account.account_id),
          this.fetchFinishingsPrices(this.data.account.account_id)
        ])
      )
      .then(() => {
        this.isLoading = false;

        if (!this.data.order.is_viewed) {
          this.$store.dispatch(IS_VIEWED, {
            estimateId: this.id,
            vue: this
          });
        }
      });
  },
  mounted() {
    Vue.use(VueCookie);
  }
};
</script>
