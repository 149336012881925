<template>
  <div class="columns">
    <div class="column">
      <clients-report/>
    </div>
  </div>
</template>

<script>

import ClientsReport from "@/components/reports/clients-report.vue";

export default {
  name: 'client-report-tab',
  components: {ClientsReport},
};
</script>
