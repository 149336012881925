<template>
  <div>
    <div class="columns">
      <div class="column is-3">
        <h5 class="subtitle">Select Designs</h5>
      </div>
      <div class="column is-4">
        <div class="control">
          <p class="control has-addons">
            <input type="text" class="input" v-model="searchQuery" placeholder="Search by design name">
          </p>
        </div>
      </div>
    </div>

    <div v-if="ordersWithDesignsData.length">
      <table class="table table-reprint">
        <tbody>
        <tr v-for="(entry, key) in ordersWithDesignsData">
          <td>Order #{{entry.id}}</td>
          <td>
            <table class="table">
              <tr v-for="(design, subKey) in entry.designs">
                <td>{{design.name}}</td>
                <td><span @click="selectDesign(key, subKey)" class="button is-small">Select</span></td>
              </tr>
            </table>
          </td>
        </tr>
        </tbody>
      </table>
      <paginator :pagesNumber="ordersWithDesignsPagesNumber"
                 :prevPageUrl="ordersWithDesignsPrevPageUrl"
                 :nextPageUrl="ordersWithDesignsNextPageUrl"
                 :currentPage="ordersWithDesignsCurrentPage"
                 :getListItems="getOrdersWithDesigns">
      </paginator>
    </div>
    <p v-else>You do not have any orders yet.</p>

  </div>
</template>

<script>
import Vue from 'vue';
import { map } from 'lodash';
import { mapGetters, mapActions } from 'vuex';
import apiService from '../../../services/api.service';
import alertify from 'alertify.js';
import paginator from '../../common/paginator';
import { debounce } from 'lodash';
import moment from 'moment';

export default {
  name: 'reprint-designs-list',
  data() {
    return {
      ordersWithDesignsData: [],
      ordersWithDesignsPrevPageUrl: null,
      ordersWithDesignsNextPageUrl: null,
      ordersWithDesignsCurrentPage: 1,
      ordersWithDesignsPagesNumber: 1,
      searchQuery: ''
    };
  },
  props: {
    additional: {
      type: Object,
      default: () => null
    },
    processing: {
      type: Boolean
    }
  },
  components: {
    paginator
  },
  computed: {},
  watch: {
    searchQuery(str) {
      if (str.length == 0 || str.length >= 3) {
        this.ordersWithDesignsCurrentPage = 1;
        this.searchOrdersByDesign();
      }
    }
  },
  methods: {
    searchOrdersByDesign: debounce(function() {
      this.getOrdersWithDesigns();
    }, 500),
    selectDesign: function(orderKey, designKey) {
      let design = this.ordersWithDesignsData[orderKey]['designs'][designKey];
      design.designable = design.designable || {};
      design.designable.garments = design.designable.garments || [];
      let checkedDesign = {
        common: {
          id: design.id,
          in_hands_date: null,
          name: design.name,
          comment: design.comment,
          printed_before: design.printed_before,
          service: design.service,
          shipping_method: design.shipping_method,
          type_of_delivery: design.type_of_delivery,
          upcharge: design.upcharge,
          upcharge_description: design.upcharge_description,
          printed_before_order_id: design.printed_before_order_id,
          printed_before_design_id: design.printed_before_design_id,
          reference_order_id: design.reference_order_id,
          reference_design_id: design.reference_design_id,
          ship_by_date: null,
          print_by_date: null
        },
        specific: {
          item: Object.assign({}, design.designable.item),
          items: Object.assign({}, design.designable.items),
          ink_color_changes: design.designable.ink_color_changes,
          garments: design.designable.garments.map(garment => {
            return {
              id: garment.id,
              apparel_type: garment.apparel_type,
              brand: garment.brand,
              product: garment.product,
              color: garment.color,
              count: garment.count,
              price: garment.price,
              sizes: JSON.parse(garment.size_qty),
              fabric: garment.product_object.fabric,
              origin: garment.product_object.origin
            };
          }),
          locations: design.designable.locations || []
        },
        finishings: design.finishings || []
      };
      console.log(design, checkedDesign);
      this.additional.callback(checkedDesign);
      this.$emit('close');
    },
    getOrdersWithDesigns: function(page, url) {
      let reprint_type =
        this.additional.reprint_type != undefined
          ? this.additional.reprint_type
          : 'reprint';
      if (page === undefined) page = this.ordersWithDesignsCurrentPage;

      if (url === undefined)
        url = `/api/designs/designs_for_reprint/${
          this.additional.userId
        }/${reprint_type}?page=${page}`;

      if (this.searchQuery) {
        url +=
          url.indexOf('?') == -1
            ? '?q=' + this.searchQuery
            : '&q=' + this.searchQuery;
      }
      return this.$http.get(url).then(
        response => {
          this.ordersWithDesignsData = response.data.orders_with_designs;
          this.ordersWithDesignsPrevPageUrl = response.data.prev_page_url;
          this.ordersWithDesignsNextPageUrl = response.data.next_page_url;
          this.ordersWithDesignsCurrentPage = response.data.current_page;
          this.ordersWithDesignsPagesNumber = response.data.last_page;
        },
        function(response) {
          alertify.error('Could not load older designs');
        }
      );
    }
  },
  mounted() {
    this.getOrdersWithDesigns();
  }
};
</script>
