import moment from 'moment';
import gridHelper, {colorizeArchivedOrderLinks} from './grid-helper';

import {teamMembers} from './role-access';

import store from '../store';
import {APPROVED_STATUS} from './revision';

const DATE_FORMAT = 'MM/DD';
export const BLANK_STATUS_NOT_ORDERED = '0';
export const BLANK_STATUS_PARTIAL_ORDERED = '1';
export const BLANK_STATUS_ORDERED = '2';
export const BLANK_STATUS_STOCK_ISSUE = '3';
export const FOLLOWUP_STATUS_APPROVED = 1;

export const headers = [
  {head: 'Order', subhead: '', sortField: 'order_id'},
  {head: 'Company Name', subhead: ''},
  {head: 'Design Name', subhead: ''},
  {head: 'Service Type', subhead: ''},
  {head: 'Date In', subhead: ''},
  {head: 'Mock Approved Date', subhead: ''},
  {head: 'Blanks ETA', subhead: ''},
  {head: 'PD', subhead: ''},
  {head: 'Print By', subhead: ''},
  {head: 'Ship By', subhead: ''},
  {head: 'Printer', subhead: ''},
  {head: 'Locations', subhead: ''},
  {head: 'Pieces', subhead: ''},
  {head: 'Finishings', subhead: ''},
  {head: 'Shipping Method', subhead: ''}
];

export const gridHeaders = (setActions, isActiveAction, phaseForGrid = 'no-specific-phases') =>
  headers.concat([
    {
      head: '',
      subhead: {
        value: 'SET',
        iconClass: 'ff-settings'
      }
    },
    {
      head: '',
      subhead: {
        value: 'BLK',
        iconClass: 'ff-tshirt'
      }
    },
    {
      head: 'Phases',
      subhead: {
        value: 'MOC',
        iconClass: 'ff-photos-pictures'
      }
    },
    {
      head: '',
      subhead: {
        value: 'PRT',
        iconClass: 'ff-droplet'
      }
    },
    {
      head: '',
      subhead: {
        value: 'SHP',
        iconClass: 'ff-pin-map'
      }
    },
    {
      head: '',
      subhead: {
        value: 'FUP',
        iconClass: 'ff-heart'
      }
    }
  ]);

export const progressFieldsList = [
  'progress_setup',
  'progress_blank',
  'progress_mock',
  'progress_print',
  'progress_shipment',
  'progress_finish'
];

export const getGridData = (designs, services, actions) => {
  return designs.map((design, index) => {
    let shippingMethods = [];
    design.shipping_items.forEach(shipping_item => {
      shippingMethods = shipping_item.shipping.type_of_delivery == 'shipping' ? shipping_item.shipping.shipping_method : shipping_item.shipping.type_of_delivery
    });
    let mockApprovedAt = null;
    if (design.mocks[0] && design.mocks[0].status === APPROVED_STATUS) {
      mockApprovedAt = design.mocks[0].status_updated_at;
    }
    const serviceName = services ? services.find(({ key }) => design.service === key).label:'-';
    let data = [
      {column: 'id', value: design.id, isFirst: design.is_first, roles: teamMembers},
      {
        column: 'Order',
        value: colorizeArchivedOrderLinks(design.order)
      },
      {
        column: 'Company Name',
        value: design.order.company ? design.order.company.name : ' - '
      },
      {column: 'Design Name', value: design.name},
    // if (design.printed_before_order_id || design.printed_before_design_id) {
    //   designName += ' (Reprint)';
    // } else if (design.reference_order_id || design.reference_design_id) {
    //   designName += ' (Reference)';
    // }
      {column: 'Service Type', value: serviceName},
      {
        column: 'Date In',
        value: design.order.estimate_becomes_to_order_at
          ? moment(design.order.estimate_becomes_to_order_at).format(DATE_FORMAT)
          : ' - '
      },
      {column: 'Mock Approved Date', value: mockApprovedAt ? moment(mockApprovedAt).format(DATE_FORMAT) : '-'},
      {column: 'Blanks ETA', value: '-'},
      {column: 'PD', value: design.order.days_in_production || '-'},
      {
        column: 'Print By',
        value: design.print_by_date
          ? moment(design.print_by_date).format(DATE_FORMAT)
          : '-'
      },
      {
        column: 'Ship By',
        value: design.ship_by_date
          ? moment(design.ship_by_date).format(DATE_FORMAT)
          : '-'
      },
    // if (design.in_hands_date) {
    //   ship_by_date = `<span class="has-color-error">${ship_by_date}</span>`;
    // }
      {column: 'Printer', value: design.printer ? design.printer.code : '-'},
      {
        column: 'Locations',
        value: design.designable.locations
          ? design.designable.locations.length
          : '-'
      },
      {column: 'Pieces', value: design.total_quantity || '-'},
      {
        column: 'Finishings',
        value: design.order.finishings.length ? 'Y' : 'N'
      },
      {
        column: 'Shipping Method',
        value: shippingMethods || '-'
      }
    ];
    progressFieldsList.forEach(progressType => {
      let progress = design[progressType];

      data.push({
        column: 'Phase',
        value:
          progress !== null
            ? `<div class="${gridHelper.getProgressIcon(progress)}"></div>`
            : '-'
      });
    });

    return data;
  });
};

export const orderModel = {
  id: null,
  csr_id: '',
  contact: {
    account_id: null,
    email: '',
    phone: '',

    contact_firstname: '',
    contact_lastname: '',

    recipient_company: ''
  },

  address: {
    recipient_firstname: '',
    recipient_lastname: '',

    payment_term: 'request-payment',
    shipping_cost: 0,

    s_mailing_address: '',
    s_city: '',
    s_country_id: '',
    s_state_id: '',
    s_state: '',
    s_postal_code: '',

    b_mailing_address: '',
    b_city: '',
    b_country_id: '',
    b_state_id: '',
    b_state: '',
    b_postal_code: ''
  }
};
