import { forEach } from 'lodash';

/**
 * Get location upcharges by garments qty.
 *
 * @param items
 * @param topQtySegment
 * @param qtyTotal
 * @returns {number}
 */
export const getQtySegmentUpcharge = (items, topQtySegment, qtyTotal) => {
  let basePrice = 0;
  forEach(items, (price, num_garments) => {
    let minMax = num_garments.split('-');
    if (
      (minMax[0] <= qtyTotal && minMax[1] >= qtyTotal) ||
      (minMax.length === 1 &&
        +minMax[0] === +topQtySegment &&
        qtyTotal >= +topQtySegment)
    ) {
      basePrice = parseFloat(price);
    }
  });
  return basePrice;
};
