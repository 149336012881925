<template>
  <div>
    <grid :orders="gridData"
      :designs="gridOrderDetails"
      :finishings="gridOrderFinishings"
      :columns="gridHeadersSimple"
      :actionColumns="gridActionHeaders"
      :loading="unclaimedBlanksLoading"
      noResMsg="No unclaimed blanks"
      @onGridSortBy="gridUpdateOrders"></grid>

    <paginator v-if="unclaimedBlanks.length"
      :pagesNumber="unclaimedBlanksPaginatorData.last_page"
      :prevPageUrl="unclaimedBlanksPaginatorData.prev_page_url"
      :nextPageUrl="unclaimedBlanksPaginatorData.next_page_url"
      :currentPage="unclaimedBlanksPaginatorData.current_page"
      :getListItems="loadUnclaimedBlanksPaginated"
      :showOffset="true"
      :totalNumber="unclaimedBlanksPaginatorData.total"
      :sortField="sortField"
      :sortDir="sortDir"></paginator>
  </div>
</template>

<script>
import Vue from 'vue';
import { mapGetters, mapActions } from 'vuex';
import { checkRoles } from '@/router/checkRoles';
import pubnubService from '../../services/pubnub.service';

import { LOAD_UNCLAIMED_BLANKS, CLAIM } from '@/store/action-types';
import {
  unclaimedGridActionHeaders,
  unclaimedActionData,
  unclaimedOrderActionsDetails,
  unclaimedOrderActionsFinishings
} from '@/helpers/estimates';
import {
  getGridData,
  getGridOrderDetails,
  gridHeadersSimple,
  getGridOrderFinishings
} from '@/helpers/orders';

import grid from '../common/grid/grid';
import paginator from '../common/paginator';

export default {
  name: 'dashboard-unclaimed-blanks',
  data: () => {
    return {
      gridHeadersSimple,
      gridActionHeaders: unclaimedGridActionHeaders,
      sortField: 'estimate_becomes_to_order_at',
      sortDir: 'DESC',
      page: 1,
      perPage: 50
    };
  },
  computed: {
    ...mapGetters([
      'unclaimedBlanks',
      'unclaimedBlanksPaginatorData',
      'unclaimedBlanksLoading',
      'roles'
    ]),
    gridData() {
      const disabled = !checkRoles(this.$store.getters.roles, [
        'admin',
        'csr',
        'production-coordinator',
        'purchaser',
        'art-producer'
      ]);
      const claim = {
        tag: 'button',
        str: 'Claim blank',
        click: this.claim,
        disabled
      };

      return {
        data: getGridData(this.unclaimedBlanks, this.roles, true),
        actions: unclaimedActionData(this.unclaimedBlanks, { claim })
      };
    },
    gridOrderDetails() {
      return {
        data: getGridOrderDetails(this.unclaimedBlanks, true),
        actions: unclaimedOrderActionsDetails(this.unclaimedBlanks)
      };
    },
    gridOrderFinishings() {
      return {
        data: getGridOrderFinishings(this.unclaimedBlanks),
        actions: unclaimedOrderActionsFinishings(this.unclaimedBlanks)
      };
    }
  },
  components: {
    grid,
    paginator
  },
  methods: {
    ...mapActions({
      loadUnclaimedBlanks: LOAD_UNCLAIMED_BLANKS
    }),

    loadUnclaimedBlanksPaginated(page, url, perPage, sortField, sortDir) {
      // Update values in component to populate it for other methods. For example - "gridUpdateOrders"
      this.page = page;
      this.perPage = perPage;
      return this.loadUnclaimedBlanks({
        page,
        url,
        perPage,
        sortField,
        sortDir
      });
    },

    // Update orders in store on orders sorting.
    gridUpdateOrders(sortField, sortDir) {
      // Update values in component to populate it for "loadUnclaimedMocksPaginated"
      this.sortField = sortField;
      this.sortDir = sortDir;

      // Get default or updated by "loadUnclaimedMocksPaginated" method.
      let page = this.page;
      let perPage = this.perPage;

      this.loadUnclaimedBlanks({ page, perPage, sortField, sortDir });
    },

    claim(orderId) {
      this.$store.dispatch(CLAIM, {
        orderId: orderId,
        subject: 'blank',
        vue: this
      });
    },
    mockUpdateHandler(message) {
      if (message.order_id) {
        const updatedOrders = this.unclaimedBlanks.find(
          order => order.id === message.order_id
        );
        if (updatedOrders) {
          this.loadUnclaimedBlanks({
            page: this.unclaimedBlanksPaginatorData.current_page,
            perPage: this.perPage,
            sortField: this.sortField,
            sortDir: this.sortDir
          });
        }
      }
    },
    orderUpdateHandler(message) {
      if (message.order_id) {
        const updatedOrders = this.unclaimedBlanks.find(
          order => order.id === message.order_id
        );
        if (updatedOrders) {
          this.loadUnclaimedBlanks({
            page: this.unclaimedBlanksPaginatorData.current_page,
            perPage: this.perPage,
            sortField: this.sortField,
            sortDir: this.sortDir
          });
        }
      }
    }
  },
  created() {
    this.gridUpdateOrders(this.sortField, this.sortDir);
  },
  mounted() {
    pubnubService.subscribeUpdateEvent('order', this.orderUpdateHandler);
    pubnubService.subscribeUpdateEvent('mock', this.mockUpdateHandler);
  },
  destroyed() {
    pubnubService.unsubscribeUpdateEvent('order');
    pubnubService.unsubscribeUpdateEvent('mock', this.mockUpdateHandler);
  }
};
</script>
