<template>
  <div class="form-item" :class="{
    'is-horizontal': horizontal,
    'with-icon': $slots.icon
  }">
    <div class="form-item-icon" v-if="$slots.icon">
      <slot name="icon"></slot>
    </div>

    <label
      class="form-item-label"
      :for="scope + '-' + name"
      v-if="!hideLabel && hasLabel"
    >
      {{ label }}
      <span
        class="form-item-required"
        v-if="required"
      > *</span>
    </label>

    <slot name="input-before"></slot>

    <div class="form-item-control">
      <multiselect
        :value="value"
        :placeholder="placeholder || ''"
        :disabled="disabled"
        :id="scope + '-' + name"
        :name="scope + '-' + name"
        :class="selectClasses"
        :key="scope + '-' + name"
        :data-vv-as="name | startCase"
        :data-vv-name="name"
        v-validate="{ rules: selectRules, scope: scope }"
        v-bind="selectConfig"
        :options="options"
        @search-change="onSearchChange"
        @select="onSelect"
        @input="onInput"
        :loading="isLoading"
        ref="select"
      >

        <div
          class="multiselect__clear"
          slot="clear"
          slot-scope="props"
          title="Clear"
          @mousedown.prevent.stop="clear"
          v-if="clearable && value"
        ></div>

        <slot
          v-for="slot in Object.keys($slots)"
          :name="slot"
          :slot="slot"
          v-if="!['icon', 'input-before', 'input-after'].includes(slot)"
        ></slot>

        <template
          v-for="slot in Object.keys($scopedSlots)"
          :slot="slot"
          slot-scope="scope"
          v-if="!['icon', 'input-before', 'input-after'].includes(slot)"
        ><slot :name="slot" v-bind="scope"/></template>

      </multiselect>
      <div class="garment-sizes__price" v-if="hasAfterLabel" >
        <template v-if="afterLabel !== '' && afterLabel !== 0">{{ afterLabel | price }}</template>
        <template v-else>-</template>
      </div>
      <span
        class="form-item-help help is-danger is-visible error-msg"
        v-show="errors.has(scope + '.' + name) && showErrors"
      >
        <slot name="errors">{{ errors.first(scope + '.' + name) }}</slot>
      </span>
    </div>

    <slot name="input-after"></slot>

  </div>
</template>

<script>
import Vue from 'vue';
import bus from '@/bus';
import alertify from 'alertify.js';

const defaultConfig = {
  trackBy: 'id',
  label: 'label',
  showLabels: false,
  allowEmpty: false
};

export default {
  name: 'form-item',
  props: {
    value: { required: true },
    clearable: {
      type: Boolean,
      default: false
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    options: {
      type: Array,
      required: true
    },
    required: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: ''
    },
    afterLabel: {
      type: [String, Number],
      default: undefined
    },
    hideLabel: {
      type: Boolean,
      default: false
    },

    // two variants for now - elevator-dropdown OR theme-autocomplete
    selectClass: {
      type: [String, Array],
      default: 'elevator-dropdown is-inline'
    },

    // multiselect config
    config: {},

    // common attributes, any form element may have ones
    disabled: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: [String, Boolean],
      default: 'Select...'
    },
    rules: {
      type: String,
      default: ''
    },
    scope: {
      type: String,
      default: 'form-scope'
    },
    name: {
      type: String,
      required: true
    },
    horizontal: {
      type: Boolean,
      default: false
    },
    showErrors: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    hasLabel() {
      return this.label.length > 0;
    },
    hasAfterLabel() {
      return this.afterLabel !== undefined;
    },
    selectClasses() {
      const classes = {
        'has-errors': this.errors.has(this.scope + '.' + this.name)
      };
      this.selectClass
        .trim()
        .split(' ')
        .forEach(className => {
          classes[className] = true;
        });
      return classes;
    },
    selectConfig() {
      return Object.assign({}, defaultConfig, this.config);
    },
    selectRules() {
      const rules = {};
      this.rules
        .trim()
        .split('|')
        .forEach(rule => {
          if (!rule || rule.length === 0) return;
          const parts = rule.split(':');
          rules[parts[0]] = parts[1] === undefined ? true : parts[1];
        });
      rules.required = this.required;
      return rules;
    }
  },
  methods: {
    clear() {
      if (this.$refs.select.$refs.search) {
        this.$refs.select.$refs.search.blur();
      }
      this.$emit('input', null);
    },
    onInput(value) {
      this.$emit('input', JSON.parse(JSON.stringify(value)));
    },
    onSelect(value) {
      this.$emit('select', JSON.parse(JSON.stringify(value)));
    },
    onSearchChange(searchQuery) {
      this.$emit('search-change', searchQuery);
    },
    onValidate(validateScope) {
      if (validateScope) {
        this.$validator.validateAll(validateScope).catch(err => {});
      } else {
        this.$validator.validateScopes().catch(err => {});
      }
    },

    onClear() {
      this.errors.clear(this.scope);
    },

    addError(e) {
      if (
        e.field === this.name &&
        e.scope === this.scope &&
        (!this.fieldsetName ||
          e.fullName === `${this.fieldsetName}.${this.name}`)
      ) {
        this.errors.add(
          e.field,
          e.msg.replace(e.fullName, this.getLabel),
          e.rule,
          e.scope
        );
        if (this.showErrors) {
          alertify.error(e.msg.replace(e.fullName, this.getLabel));
        }
      }
    }
  },
  created() {
    if (this.static) return;
    bus.$on('add-error', this.addError);
    bus.$on('validate', this.onValidate);
    bus.$on('clear', this.onClear);
    this.$watch(
      () => this.errors.errors,
      (value, oldValue) => {
        if (value.length) {
          bus.$emit('errors-changed', value);
        } else {
          bus.$emit('errors-deleted', oldValue);
        }
      }
    );
  },
  beforeDestroy() {
    if (!this.static) {
      bus.$emit('errors-deleted', this.errors.errors);
      bus.$off('validate', this.onValidate);
      bus.$off('clear', this.onClear);
      bus.$off('add-error', this.addError);
    }
  }
};
</script>
