<template>
  <div>
    <li>
      <label>Print Type</label>
      <strong>{{ getOptionLabel(item.print_type, formOptions.print_types) }}</strong>
    </li>
    <li v-if="item.paper_type">
      <label>Paper Type</label>
      <strong>{{
          getOptionLabel(item.paper_type, formOptions.print_type_options[item.print_type].paper_types)
        }}</strong>
    </li>
    <li>
      <label>Size</label>
      <strong>
        <template v-if="item.custom_size && item.custom_size.length">{{ item.custom_size }}</template>
        <template v-else>{{
            getOptionLabel(item.size, formOptions.print_type_options[item.print_type].sizes)
          }}
        </template>
      </strong>
    </li>
    <li>
      <label>Quantity</label>
      <strong>{{ getOptionLabel(item.quantity, formOptions.print_type_options[item.print_type].quantities) }}</strong>
    </li>
    <li v-if="hasRoundedCorners">
      <label>Rounded Corners</label>
      <strong>{{ item.has_rounded_corners ? 'Yes' : 'No' }}</strong>
    </li>
    <li v-if="isScreenTypePoster">
      <label>Brand</label>
      <strong>
        <template v-if="item.custom_brand && item.custom_brand.length">{{ item.custom_brand }}</template>
        <template v-else>
          {{ getOptionLabel(item.brand, formOptions.print_type_options[item.print_type].brands) }}
        </template>
      </strong>
    </li>
    <li v-if="isScreenTypePoster">
      <label>Paper Weight</label>
      <strong>{{ getOptionLabel(item.paper_weight, formOptions.print_type_options[item.print_type].weights) }}</strong>
    </li>
    <li v-if="isScreenTypePoster">
      <label>Paper Color</label>
      <strong>
        <template v-if="item.custom_paper_color && item.custom_paper_color.length">{{ item.custom_paper_color }}</template>
        <template v-else>{{ posterColor }}</template>
      </strong>
    </li>
    <li v-if="isScreenTypePoster">
      <label># of Colors</label>
      <strong>{{
          getOptionLabel(item.number_of_colors, formOptions.print_type_options[item.print_type].color_numbers)
        }}</strong>
    </li>
    <li v-if="isScreenTypePoster && item.ink_type">
      <label>Ink Type</label>
      <strong>{{ getOptionLabel(item.ink_type, formOptions.print_type_options[item.print_type].ink_types) }}</strong>
    </li>
    <li v-if="isScreenTypePoster">
      <label>Bleed Type</label>
      <strong>{{ getOptionLabel(item.bleed_type, formOptions.print_type_options[item.print_type].bleed) }}</strong>
    </li>
    <li v-roles="['admin', 'csr', 'production-coordinator', 'purchaser', 'art-producer', 'developer', 'customer']">
      <label v-if="isScreenTypePoster && item.custom_unit_price && item.custom_unit_price.length">Unit Price(Custom)</label>
      <label v-else>Unit Price</label>
      <strong>{{ unitPrice| price }}</strong>
    </li>
  </div>
</template>
<script>
import {getOptionLabel} from "@/helpers/estimates";
import {flatten} from "lodash";
import apiService from "@/services/api.service";

export default {
  name: 'PaperPrintingItem',
  props: {
    item: {
      type: Object,
      required: true
    },
    formOptions: {
      type: Object,
      required: true
    },
  },
  data() {
    return {
      paperColors: []
    };
  },
  methods: {
    getOptionLabel,
    getPaperColors(brand, weight) {
      return apiService.getPaperPrintPaperColors(brand, weight).then(response => {
        const groups = Object.keys(response).sort((a, b) => {
          const aMinOptionKey = Object.keys(response[a]).sort()[0];
          const bMinOptionKey = Object.keys(response[b]).sort()[0];
          if (aMinOptionKey < bMinOptionKey) {
            return -1;
          }
          if (aMinOptionKey > bMinOptionKey) {
            return 1;
          }
          return 0;
        });
        this.paperColors = groups.map(groupKey => {
          return {
            title: groupKey,
            options: Object.keys(response[groupKey]).map(childKey => {
              return {
                id: childKey,
                label: response[groupKey][childKey]
              };
            })
          };
        });
      });
    },
  },
  computed: {
    posterColor() {
      return getOptionLabel(
        this.item.paper_color,
        flatten(this.paperColors.map(({options}) => options)),
        'id'
      );
    },
    isScreenTypePoster() {
      return this.getOptionLabel(this.item.print_type, this.formOptions.print_types) === 'Screen Printed Posters'
    },
    hasRoundedCorners() {
      return !['Digitally Printed Posters', 'Screen Printed Posters'].includes(getOptionLabel(this.item.print_type, this.formOptions.print_types))
    },
    unitPrice(){
      if (this.item.custom_unit_price && this.item.custom_unit_price.length) {
        return this.item.custom_unit_price;
      }
      const printType = this.item.print_type;
      const printTypeOptions = this.formOptions.print_type_options;
      const upcharges = this.formOptions.upcharges;
      let sizeId = this.item.size;
      let quantity = null;

      if (this.item.quantity) {
        // Find quantity by id for all print types excluding Screen Printed Posters
        if (printType === 4) {
          quantity = this.item.quantity
        }else{
          quantity = printTypeOptions[printType].quantities.find(q => +q.key === +this.item.quantity).label
            .replace(/[^0-9 | ^.]/g, '');
        }
      }

      let upchargeSizeObj = upcharges.by_size[printType].filter(
        upcharge => {
          return (upcharge.paper_print_size_id === sizeId &&
            (
              upcharge.quantity.from <= quantity &&
              (upcharge.quantity.to >= quantity || upcharge.quantity.to === null)
            )
            && (!this.item.number_of_colors ||
              upcharge.paper_colors_number_id === this.item.number_of_colors)
          );
        }
      )[0];
      if (upchargeSizeObj !== undefined) {
        return parseFloat(upchargeSizeObj.upcharge) || 0;
      }
      return 0;

    }
  },
  created() {
    if (this.isScreenTypePoster && this.item.paper_color !== null) {
      this.getPaperColors(this.item.brand, this.item.paper_weight);
    }
  },
}
</script>
