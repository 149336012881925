<template>
  <form class="form form-estimate" novalidate @submit.prevent autocomplete="off">
    <header class="form-estimate__header">
      <h1 class="form-estimate__title">
        <template v-if="$route.params.id">Edit</template>
        <template v-else>Create</template>
        <template v-if="isApproved">Order</template>
        <template v-else-if="isNonOrderInvoiceOrder">General Invoice</template>
        <template v-else>Estimate</template>
        <template v-if="isCopy">- Copy</template>
      </h1>
      <div class="form-estimate__actions">
        <button
          class="btn2 is-secondary"
          @click.prevent="saveAsDraft"
          :disabled="saveAsDraftDisabled"
        >
          Save
          <template v-if="$route.name === 'New order'">as Draft</template>
          <template v-else>
            <template v-if="isApproved">Order</template>
            <template v-else>Estimate</template>
          </template>
        </button>
        <dropdown :items="dropdownOptions"></dropdown>
      </div>
    </header>
    <div class="form-estimate__container columns" :class="{
      'is-inverted': ['order'].includes(currentStep.id)
    }">
      <aside class="form-estimate__aside column is-3">
        <div class="form-estimate__aside__container" v-sticky sticky-side="top" v-show="currentStep.id !== 'order'">
          <div class="form-estimate__totals">
            <div class="total">
              <span>Subtotal</span>
              <strong>{{ estimateSubTotalTweened | price }}</strong>
            </div>
            <ul class="total-additional">
              <li class="total">
                <span>Shipping Cost</span>
                <strong>
                  <template v-if="showShippingCost">
                    {{ form.order.shipping_cost | price }}
                  </template>
                  <template v-else>-</template>
                </strong>
              </li>
              <li class="total">
                <span>Items</span>
                <strong>{{ itemsQty || '-' }}</strong>
              </li>
              <li class="total">
                <span>Boxes</span>
                <strong>{{ form.order.packages_qty || '-' }}</strong>
              </li>
            </ul>
          </div>
          <div class="form-estimate__steps">
            <ul class="list-steps">
              <li
                class="list-steps__item"
                v-for="step, index in steps"
                :key="`${step.id}${step.index !== undefined ? `-${step.index}` : ''}`"
                :class="{
                  'is-passed': currentStepIndex > index,
                  'is-active': currentStepIndex === index
                }"
              >
                <svg v-if="currentStepIndex > index" class="list-steps__icon" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                  <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 15l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z"/>
                </svg>
                <a href="#" class="list-steps__title is-passed"
                  @click.prevent="goToStep({ type: step.id, index: step.index })"
                  v-if="isStepPassed(step)"
                >
                  {{ step.label }}
                  <template v-if="step.index >= 0 && !isNonOrderInvoiceOrder">{{ step.index + 1 }}</template>
                </a>
                <strong class="list-steps__title" v-else>
                  {{ step.label }}
                  <template v-if="step.index >= 0 && !isNonOrderInvoiceOrder">{{ step.index + 1 }}</template>
                </strong>
                <div class="list-steps__data" v-if="step.data">
                  <p v-for="line, index in step.data">{{ Array.isArray(line) ? line.join(', ') : line }}</p>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div class="form-estimate__aside__container" v-sticky sticky-side="top" v-show="currentStep.id === 'order'">
          <div v-show="currentStep.id === 'order'">
            <div class="estimate-totals">
              <h3 class="estimate-totals__title">
                <template v-if="isApproved">Order</template>
                <template v-else-if="isNonOrderInvoiceOrder">Invoice</template>
                <template v-else>Estimate</template>
                Summary
              </h3>
              <ul class="estimate-totals__list">
                <li>
                  <div class="item">
                    <a href="#" class="item__label" @click.prevent="goToStep(1)">
                      <template v-if="isNonOrderInvoiceOrder">References</template>
                      <template v-else>Designs</template>
                    </a>
                    <span class="item__total">{{ designsTotal  | price }}</span>
                  </div>
                  <ul class="estimate-totals__subitems">
                    <li v-for="designTotal, index in designsTotalArray" :key="index">
                      <div class="item">
                        <label class="item__label">
                          <template v-if="isNonOrderInvoiceOrder">Reference</template>
                          <template v-else>Design</template> {{ index + 1 }}
                        </label>
                        <span class="item__total">{{ designTotal.total | price }}</span>
                      </div>
                    </li>
                  </ul>
                </li>
                <li class="estimate-totals__item" v-if="totals.tagSetups > 0">
                  <div class="item">
                    <a href="#" class="item__label" @click.prevent="goToStep('finishings')">Tag Setups</a>
                    <span class="item__total">{{ totals.tagSetups | price }}</span>
                  </div>
                </li>
                <li class="estimate-totals__item" v-if="!isNonOrderInvoiceOrder">
                  <div class="item">
                    <a href="#" class="item__label" @click.prevent="goToStep('shippings')">Shipping</a>
                    <span class="item__total">{{ form.order.shipping_cost | price }}</span>
                  </div>
                </li>
                <li class="estimate-totals__item" v-if="form.payment.state_tax">
                  <div class="item">
                    <strong class="label">State Tax:</strong>
                    <strong class="total">{{ form.payment.state_tax | price }}</strong>
                  </div>
                </li>
                <li class="estimate-totals__item" v-if="form.payment.rush_fee.rush_fee_amount > 0">
                  <div class="item">
                    <label class="item__label">Rush Fee ({{ form.payment.rush_fee.rush_fee_percentage }}%)</label>
                    <span class="item__total">{{ form.payment.rush_fee.rush_fee_amount | price }}</span>
                  </div>
                </li>
                <li class="estimate-totals__item" v-if="form.payment.discount.discount_amount > 0">
                  <div class="item">
                    <label class="item__label">Discount ({{ form.payment.discount.discount_percentage }}%)</label>
                    <span class="item__total">{{ form.payment.discount.discount_amount | price }}</span>
                  </div>
                </li>
              </ul>
              <ul class="estimate-totals__list is-total">
                <li>
                  <div class="item">
                    <span class="item__label">Total Cost</span>
                    <span class="item__total">{{ estimateTotal | price }}</span>
                  </div>
                </li>
                <li class="is-amount" v-if="form.order.invoice">
                  <div class="item">
                    <span class="item__label">Amount Paid</span>
                    <span class="item__total">{{ form.order.invoice.paid_sum | price }}</span>
                  </div>
                </li>
              </ul>
              <div class="estimate-totals__balance-due"
                :class="{ 'is-paid': balanceDue === 0 }"
                v-if="form.order.invoice"
              >
                <span>Balance Due</span>
                <span class="value">{{ balanceDue | price }}</span>
              </div>
            </div>
          </div>
        </div>
      </aside>
      <div class="form-estimate__body column is-9">
        <component
          v-for="(step, index) in steps"
          :key="`${step.id}-${index}-${step.uniqueId ? step.uniqueId : ''}`"
          :is="`step-${step.id}`"
          :formOptions="formOptions"
          :scope="`form-estimate-${step.id}`"
          :value="step.index === undefined ? form[step.id] : form[step.id][step.index]"
          :index="step.index"
          :parentForm="form"
          :shippingAddress="shippingAddress"
          :itemsQty="itemsQty"
          :finishingsPrices="finishingsPrices"
          :rawTotal="estimateRawTotal"
          :totals="totals"
          :threadbirdAddresses="threadbirdAddresses"
          :shippingOptions="shippingMethods.options"
          :shippingDefault="shippingMethods.default"
          :account="account"
          @data="onStepData"
          @input="onStepData"
          @shipping-address="shippingAddress = $event"
          @account="account = $event"
          @tax-id="setTaxId"
          @payment-terms="onPaymentTermChange"
          @delete-design="onDeleteDesign"
          @delete-garment="onDeleteGarment"
          @add-finishing="onAddFinishing"
          @remove-finishing-design-item="onRemoveFinishingDesignItem"
          @remove-shipping-design-item="onRemoveShippingDesignItem"
          @go="goToStep"
          @validateStep="validateBeforeSubmit"
          @loading="isLoading = $event"
          @garments-qty-changed="resetCost"
          @changeService="onChangeService"
          @setTaxedAddress="onTaxedAddressChange"
          @setShippingCost="onShippingCostChange"
          @setAdditionalFee="onAdditionalFeeChange"
          ref="step"
          v-if="currentStep.id === step.id && (step.index !== undefined ? currentStep.index === step.index : true)"
        >
          <div class="box">{{ step.label }} IN DEVELOPMENT</div>
        </component>
        <div class="form-actions">
          <div>
            <button
              class="btn2 is-secondary"
              @click.prevent="back()"
              v-shortkey="['shift', 'f2']"
              @shortkey="back()"
              title="[Shift + F2]"
              v-if="currentStepIndex > 0"
            >Back</button>
          </div>
          <div>
            <dropdown
              :items="dropdownOptions"
              direction="above"
            >
              <a href="javascript:;" slot="opener" class="link-arrow">Add More Sections</a>
            </dropdown>
            <button
              class="btn2"
              @click.prevent="next()"
              v-shortkey="['f2']"
              @shortkey="next()"
              title="[F2]"
              v-if="currentStep.id !== 'order'"
            >Next</button>
            <template v-else>
              <button
                class="btn2"
                @click.prevent="submit()"
              >
                Save
                <template v-if="$route.name === 'New order'">as Draft</template>
                <template v-else>
                  <template v-if="isApproved">Order</template>
                  <template v-else>Estimate</template>
                </template>
              </button>
              <button
                class="btn2"
                @click.prevent="submit(true)"
                v-if="!isApproved"
              >
                <template v-if="estimateId">Update</template>
                <template v-else>Create</template>
                & Send
              </button>
              <button
                class="btn2 is-destructive"
                @click.prevent="cancel"
                v-else
              >
                Cancel
              </button>
            </template>
          </div>
        </div>
      </div>
    </div>

    <div class="form-estimate__overlay" :class="{ 'is-active': isLoading }">
      <pulse-loader class="form-estimate__loader" :loading="isLoading" :color="'#2b99ee'" :size="'24px'"></pulse-loader>
    </div>
    <modals-container :clickToClose="false"/>
    <v-dialog :clickToClose="false" />
  </form>
</template>

<script>
import Vue from 'vue';
import Sticky from 'vue-sticky-directive';
import { TweenLite } from 'gsap/TweenLite';
import PulseLoader from 'vue-spinner/src/PulseLoader.vue';
import { mapActions, mapGetters } from 'vuex';
import VueShortkey from 'vue-shortkey';
import {
  debounce,
  intersection,
  cloneDeep,
  merge,
  uniqueId,
  isEmpty,
  flatten,
  get
} from 'lodash';
import alertify from 'alertify.js';
import notify from '../../modules/notifier';

import apiService from '@/services/api.service';
import bus from '@/bus';
import {
  FORM_ESTIMATE_TEMPLATE,
  FORM_ESTIMATE_TEMPLATES
} from '@/helpers/estimates';
import { getDesignTotal } from '@/helpers/prices/';
import { finishingTotalPerDesign } from '@/helpers/prices/finishings';

import StepAccount from './FormEstimate/StepAccount';
import StepDesigns from './FormEstimate/StepDesigns';
import StepFinishings from './FormEstimate/StepFinishings';
import StepShippings from './FormEstimate/StepShippings';
import StepPayment from './FormEstimate/StepPayment';
import StepOrder from './FormEstimate/StepSummary';
import Dropdown from '@/components/common/Dropdown';
import FormRow from '@/components/common/form/FormRow';
import FormItem from '@/components/common/form/FormItem';
import FormSelect from '@/components/common/form/FormSelect';
import FormCheckbox from '@/components/common/form/FormCheckbox';

import {
  RESUBSCRIBE_PUBNUB,
  LOAD_EDIT_ORDER_INIT_DATA,
  CLEAR_VALIDATION_PROMISES
} from '@/store/action-types';

Vue.use(VueShortkey);
Vue.prototype.$apiService = apiService;
Vue.component('form-row', FormRow);
Vue.component('form-item', FormItem);
Vue.component('form-multiselect', FormSelect);
Vue.component('form-checkbox', FormCheckbox);

export default {
  name: 'form-estimate',
  components: {
    PulseLoader,
    Dropdown,
    StepAccount,
    StepDesigns,
    StepFinishings,
    StepShippings,
    StepPayment,
    StepOrder
  },
  directives: {
    Sticky
  },
  props: {
    data: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    const form = cloneDeep(
      isEmpty(this.data) ? FORM_ESTIMATE_TEMPLATE : this.data
    );
    return {
      isLoading: false,
      statesTaxes: {},
      formOptions: {},
      finishingsPrices: {},
      currentStepIndex: 0,
      form,
      estimateSubTotalTweened: 0,
      account: null,
      shippingAddress: null,
      paymentTerms: null,
      threadbirdAddresses: [],
      shippingMethods: {
        options: [],
        default: 'fed_ex'
      },
      isDirty: false,
      taxAmount: null
    };
  },
  computed: {
    ...mapGetters(['orderServices', 'deliveryTypes']),
    isNonOrderInvoiceOrder() {
      return this.form.designs.every(
        design => design.common.service === 'non_order_invoice'
      );
    },
    isCopy() {
      return this.form.order.is_copy;
    },
    company() {
      return this.account && this.account.company;
    },
    isApproved() {
      return this.form && this.form.order.is_approved;
    },
    estimateId() {
      return this.$route.params.id;
    },
    formPayload() {
      const {
        account,
        designs,
        finishings,
        shippings,
        payment,
        order
      } = this.form;

      const formPayload = {
        account,
        designs: cloneDeep(designs),
        finishings,
        shippings,
        payment,
        order
      };

      return formPayload;
    },
    currentStep() {
      return this.steps[this.currentStepIndex];
    },
    saveAsDraftDisabled() {
      switch (this.currentStep.id) {
        case 'payment':
        case 'order':
          return false;
        default:
          return true;
      }
    },
    accountId() {
      return this.form.account.account_id;
    },

    // Helpers
    showShippingCost() {
      if (this.form.order.id) {
        return true;
      }
      return !!this.form.shippings.length;
    },
    itemsQty() {
      return this.form.designs.reduce((total, design) => {
        if(['paper','sticker','button','finishing_creation'].includes(design.common.service)){
          return total;
        }
        let designItemsQty;
        if (design.specific.item) {
          designItemsQty = +design.specific.item.quantity;
        } else if (design.specific.items) {
          designItemsQty = design.specific.items.reduce(
            (itemsTotal, item) => itemsTotal + +item.quantity,
            0
          );
        } else if (design.specific.garments) {
          designItemsQty = design.specific.garments.reduce(
            (garmentsTotal, garment) => {
              return (
                garmentsTotal +
                Object.values(garment.sizes).reduce((sizesTotal, size) => {
                  return sizesTotal + (+size || 0);
                }, 0)
              );
            },
            0
          );
        }
        return total + designItemsQty;
      }, 0);
    },
    packagesQty() {
      return this.form.designs.reduce((total, design) => {
        let designPackages;
        if (!design.specific.garments) {
          designPackages = 1;
        } else {
          const garmentsSizesQty = design.specific.garments.reduce(
            (garmentsTotal, garment) => {
              return (
                garmentsTotal +
                Object.values(garment.sizes).reduce(
                  (sizesTotal, size) => sizesTotal + (+size || 0),
                  0
                )
              );
            },
            0
          );
          designPackages = Math.ceil(garmentsSizesQty / 100);
        }
        return total + designPackages;
      }, 0);
    },
    finishableDesigns() {
      return this.form.designs.filter(({ common }) =>
        ['apparel', 'embroidery'].includes(common.service)
      );
    },
    hasFinishings() {
      return !!this.finishableDesigns.length;
    },

    deliveredDesigns() {},
    undeliveredDesignsQty() {
      return 0;
    },
    undeliveredGarmentsQty() {
      return 0;
    },
    undeliveredQty() {
      return this.undeliveredDesignsQty + this.undeliveredGarmentsQty;
    },

    // SIDEBAR
    contactFullName() {
      const { contact_firstname, contact_lastname } = this.form.account;
      if (!contact_firstname.length && !contact_lastname.length) return false;
      return `${contact_firstname || ''} ${contact_lastname}`;
    },
    contactSidebarData() {
      const data = [];
      if (this.contactFullName || this.form.account.company) {
        const items = [];
        if (this.contactFullName) items.push(this.contactFullName);
        if (this.form.account.company) items.push(this.form.account.company);
        data.push(items);
      }
      if (data.length && !this.account) {
        data.push('New Customer');
      }
      return data;
    },
    designsSidebarData() {
      return this.form.designs.map(design => {
        const data = [];
        if (
          design.common.printed_before &&
          design.common.printed_before_order_id
        ) {
          data.push(`Reprint #${design.common.printed_before_order_id}`);
        } else if (design.common.reference_order_id) {
          data.push(`Reference #${design.common.reference_order_id}`);
        }
        if (design.common.name && design.common.name.length) {
          data.push(design.common.name);
        }
        if (design.specific.garments && design.specific.garments.length) {
          const line = [];
          const garmentsQty = design.specific.garments.length;
          line.push(`${garmentsQty} Garment${garmentsQty > 1 ? 's' : ''}`);
          if (design.specific.locations) {
            const locationsQty = design.specific.locations.length;
            let locationType;
            switch (design.common.service) {
              case 'embroidery':
                locationType = 'Embroidery';
                break;
              default:
                locationType = 'Print';
                break;
            }
            line.push(
              `${locationsQty} ${locationType} Location${
                locationsQty > 1 ? 's' : ''
              }`
            );
          }
          data.push(line);
        }
        if (design.specific.item) {
          if (
            this.orderServices.find(({ key }) => design.common.service === key)
          ) {
            data.push(
              this.orderServices.find(
                ({ key }) => design.common.service === key
              ).label
            );
          }
        }

        return data;
      });
    },
    finishingsSidebarData() {
      const data = [];
      const finishingsQty = this.form.finishings.length;
      const tagSetupsQty = this.form.finishings.reduce((total, finishing) => {
        return total + +finishing.specific.tag_setups_number;
      }, 0);
      if (finishingsQty > 0) {
        data.push(`${finishingsQty} Finishing${finishingsQty > 1 ? 's' : ''}`);
        if (tagSetupsQty > 0) {
          data.push(`${tagSetupsQty} Tag Setup${tagSetupsQty > 1 ? 's' : ''}`);
        }
      }
      return data;
    },
    shippingsSidebarData() {
      const data = [];
      const shippingsQty = this.form.shippings.length;
      const deliveryTypes = Array.from(
        new Set(
          this.form.shippings.map(shipping => {
            return shipping.common.type_of_delivery;
          })
        )
      ).filter(type => !!type);

      const shippingMethods = Array.from(
        new Set(
          this.form.shippings.map(shipping => {
            return shipping.common.shipping_method;
          })
        )
      ).filter(method => !!method);

      const cities = Array.from(
        new Set(
          this.form.shippings.map(shipping => {
            return shipping.specific.address.city;
          })
        )
      );

      const item = [];
      if (deliveryTypes.length > 1) {
        item.push('Multiple');
      } else if (deliveryTypes.length === 1) {
        const deliveryItem = this.deliveryTypes.find(
          ({ key }) => key === deliveryTypes[0]
        );
        const label = deliveryItem ? deliveryItem.label : deliveryTypes[0];
        item.push(label);
      }

      if (shippingMethods.length > 1) {
        item.push('Multiple');
      } else if (shippingMethods.length === 1) {
        const deliveryItem = this.deliveryTypes.find(
          ({ key }) => key === deliveryTypes[0]
        );
        let label;
        if (deliveryItem && deliveryItem.offers.ups) {
          const shippingMethodKey = Object.keys(
            deliveryItem.offers.ups.services
          ).find(key => key === shippingMethods[0]);
          label = deliveryItem.offers.ups.services[shippingMethodKey];
        }
        if (label) item.push(label);
      } else {
        if (deliveryTypes.length === 1 && deliveryTypes[0] === 'pickup') {
          if (cities.length === 1) {
            item.push(cities[0]);
          } else {
            item.push('Multiple');
          }
        }
      }

      if (this.form.shippings.length > 1) {
        item.push(`${this.form.shippings.length} Locations`);
      }

      if (item.length) data.push(item);
      return data;
    },
    steps() {
      const contact = {
        id: 'account',
        label: 'Contact Info',
        data: this.contactSidebarData
      };
      const finishings = {
        id: 'finishings',
        label: 'Finishings',
        isSkippable: true,
        data: this.finishingsSidebarData
      };

      const designs = this.form.designs.map((design, index) => {
        if (design.common.service === 'non_order_invoice') {
          return {
            id: 'designs',
            label: 'Reference',
            uniqueId: design.common.uniqueId,
            index,
            data: this.designsSidebarData[index]
          };
        } else {
          return {
            id: 'designs',
            label: 'Design',
            uniqueId: design.common.uniqueId,
            index,
            data: this.designsSidebarData[index]
          };
        }
      });

      const steps = [contact, ...designs];

      if (this.hasFinishings) {
        steps.push(finishings);
      }

      if (!this.isNonOrderInvoiceOrder) {
        steps.push({
          id: 'shippings',
          label: 'Shipping',
          data: this.shippingsSidebarData
        });
      }

      return steps.concat([
        {
          id: 'payment',
          label: 'Additional Fees'
        },
        {
          id: 'order',
          label: `Confirm ${this.isApproved ? 'Order' : 'Estimate'}`
        }
      ]);
    },

    // Estimate menu
    dropdownOptions() {
      return [
        {
          title: 'Add Design',
          action: () => {
            const step = this.$refs.step[0];

            if (!this.currentStep.isSkippable) {
              this.validateBeforeSubmit().then(isValid => {
                if (isValid) {
                  step.onPassed && step.onPassed();
                  this.addDesign();
                  this.currentStepIndex = this.form.designs.length;
                } else {
                  step.onError && step.onError();
                }
              });
            } else {
              this.validateBeforeSubmit().then(isValid => {
                if (!isValid) {
                  if (!step.isDirty) {
                    step.onPassed && step.onPassed();
                    this.$set(
                      this.currentStep.index === undefined
                        ? this.form[this.currentStep.id]
                        : this.form[this.currentStep.id][
                            this.currentStep.index
                          ],
                      'isPassed',
                      true
                    );
                    this.addDesign();
                    this.currentStepIndex = this.form.designs.length;
                    return;
                  }
                } else {
                  step.onPassed && step.onPassed();
                  this.$set(
                    this.currentStep.index === undefined
                      ? this.form[this.currentStep.id]
                      : this.form[this.currentStep.id][this.currentStep.index],
                    'isPassed',
                    true
                  );
                  this.addDesign();
                  this.currentStepIndex = this.form.designs.length;
                }
              });
            }
          },
          disabled: !!this.form.designs.every(
            design => design.common.service === 'non_order_invoice'
          )
            ? true
            : this.currentStepIndex === 0
        },
        // {
        //   title: 'Add Finishing',
        //   action: () => {},
        //   disabled: !this.hasFinishings
        // },
        'divider',
        {
          title: 'Copy Estimate',
          action: () => {
            if (this.isOrder || !this.estimateId) {
              this.showOrderCopyAlert();
            } else {
              this.showEstimateConfirmationModal();
            }
          },
          disabled: this.isOrder || !this.estimateId
        },
        {
          title: 'Cancel Estimate',
          action: () => {
            this.$mx_confirmModal()
              .then(() => {
                this.$router.push({
                  name: this.isApproved ? 'My Orders' : 'My Estimates'
                });
              })
              .catch(() => {});
          },
          style: { color: '#f00' }
        }
      ];
    },

    // TOTALS
    estimateRawTotal() {
      let total = 0;

      total +=
        this.estimateSubTotal +
        +this.form.payment.discount.discount_amount -
        +this.form.payment.rush_fee.rush_fee_amount;

      if (total === 0) return 0;
      return total;
    },
    estimateTotal() {
      let total = this.estimateSubTotal;
      total += this.stateTaxTotal;

      if (total <= 0) return 0;

      return total;
    },
    estimateSubTotal() {
      return (
        this.totals.designsTotal +
        this.totals.tagSetups +
        this.totals.rushFee -
        this.totals.discount +
        this.shippingsTotal
      );
    },
    garmentsSubTotal() {
      return (
        this.totals.designsTotal +
        this.totals.tagSetups +
        this.totals.rushFee -
        this.totals.discount
      );
    },
    hasStateTax() {
      const defaultAddress =
        this.form.shippings.length > 1
          ? this.form.shippings.find(
              shipping => shipping.common.default_company_address
            )
          : this.form.shippings[0];
      if (defaultAddress) {
        return Object.keys(this.statesTaxes).includes(
          defaultAddress.specific.address.state_id
        );
      }
      return false;
    },
    designsTotalArray() {
      if (isEmpty(this.formOptions)) return [];
      return this.form.designs.map(design => {
        const designFinishings = this.form.finishings.filter(
          ({ items }) =>
            items.filter(item => item.design_id === design.common.uniqueId)
              .length > 0
        );
        const cleanTotal = getDesignTotal(
          design,
          this.formOptions[design.common.service]
        );
        let cleanBaseTotal = getDesignTotal(
          design,
          this.formOptions[design.common.service],
          true
        );
        if(cleanBaseTotal === cleanTotal){
          cleanBaseTotal = undefined;
        }

        const finishingsTotal = designFinishings.reduce((total, finishing) => {
          return (
            total +
            finishingTotalPerDesign(finishing, design, this.finishingsPrices)
          );
        }, 0);

        return {
          raw: cleanTotal,
          total: cleanTotal + finishingsTotal,
          baseTotal: cleanBaseTotal ? cleanBaseTotal + finishingsTotal : null
        };
      });
    },
    designsTotal() {
      return this.designsTotalArray.reduce(
        (total, designTotal) => +total + +designTotal.total,
        0
      );
    },
    taxId: {
      get() {
        if (this.form.payment.tax_id === null) return null;
        if (this.form.payment.tax_id && this.form.payment.tax_id.length)
          return this.form.payment.tax_id;
      },
      set({ val }) {
        this.form.payment.tax_id = val;
      }
    },
    hasTaxId() {
      return this.taxId && !!this.taxId.length;
    },
    stateTaxTotal() {
      if (this.hasTaxId) return 0;
      return +this.form.payment.state_tax;
    },
    shippingsTotal() {
      return +this.form.order.shipping_cost;
    },
    tagSetupsTotal() {
      return this.form.finishings.reduce((total, finishing) => {
        if (finishing.specific) {
          // If finishing without tag setups.
          // Or no prices
          if (
            finishing.specific.tag_setups_number === undefined ||
            (finishing.specific.tag_setups_number_cost === undefined &&
              this.finishingsPrices[finishing.specific.price_code] ===
                undefined)
          ) {
            return total;
          } else {
            // If there are tag setups.
            if (finishing.specific.tag_setups_number) {
              // If cost calculated before.
              if (
                finishing.specific.tag_setups_number_cost !== undefined &&
                finishing.specific.tag_setups_number_cost !== null
              ) {
                total +=
                  finishing.specific.tag_setups_number_cost *
                  finishing.specific.tag_setups_number;
              } else {
                // If prices exists
                if (
                  this.finishingsPrices[finishing.specific.price_code] !==
                  undefined
                ) {
                  total +=
                    this.finishingsPrices[finishing.specific.price_code][0] *
                    finishing.specific.tag_setups_number;
                }
              }
            }
            return total;
          }
        }

        return total;
      }, 0);
    },
    totals() {
      // Don't add state tax here. This will cause infinite loop.
      return {
        designs: this.designsTotalArray,
        designsTotal: this.designsTotal,
        shipping: +this.form.order.shipping_cost,
        tagSetups: this.tagSetupsTotal,
        rushFee: +this.form.payment.rush_fee.rush_fee_amount,
        discount: +this.form.payment.discount.discount_amount
      };
    },
    flattenGarments() {
      return flatten(
        this.finishableDesigns.map(design => design.specific.garments)
      );
    },
    possibleTagSetups() {
      return this.calcTagSetups(this.flattenGarments);
    },
    possibleGarmentsQty() {
      return this.calcTagQuantity(this.flattenGarments);
    },
    balanceDue() {
      return this.estimateTotal - this.form.order.invoice.paid_sum;
    },
    isOrder() {
      return !!this.form.order.is_order;
    }
  },
  watch: {
    totals: {
      handler(val,oldVal) {
        if (!val.designsTotal) {
          this.form.payment.state_tax = null;
        } else if(val.designsTotal !== oldVal.designsTotal) {
          debounce(() =>  this.calculateStateTax(), 1000);
        }
      },
      deep: true
    },
    possibleTagSetups(val) {
      this.form.finishings.forEach(finishing => {
        if (finishing.specific.tag_setups_number === undefined) return;

        const garments = flatten(
          finishing.items.map(item => {
            return this.form.designs
              .find(design => design.common.uniqueId === item.design_id)
              .specific.garments.filter(
                garment => garment.uniqueId === item.garment_id
              );
          })
        );

        finishing.specific.tag_setups_number = this.calcTagSetups(garments);
      });
    },
    possibleGarmentsQty(val) {
      this.form.finishings.forEach(finishing => {
        if (finishing.specific.tag_garments_quantity === undefined) return;

        const garments = flatten(
          finishing.items.map(item => {
            return this.form.designs
              .find(design => design.common.uniqueId === item.design_id)
              .specific.garments.filter(
                garment => garment.uniqueId === item.garment_id
              );
          })
        );

        finishing.specific.tag_garments_quantity = this.calcTagQuantity(
          garments
        );

        garments.forEach(g => {
          finishing.items = finishing.items.map(item => {
            if (item.garment_id === g.uniqueId) {
              item.sizes = g.sizes;
            }
            return item;
          });
        });
      });
    },
    packagesQty(val) {
      this.form.order.packages_qty = val;
    },
    accountId(val) {
      const formOptionsPromise = this.getFormOptions(val);
      const finishingsPricesPromise = this.getFinishingsPrices(val);

      Promise.all([formOptionsPromise, finishingsPricesPromise]).catch(
        error => {
          console.error(error);
        }
      );
    },
    estimateSubTotal(val) {
      TweenLite.to(this.$data, 0.3, { estimateSubTotalTweened: +val });
    },
    'form.designs': {
      handler: debounce(function(val) {
        if (this.form.shippings.length !== 1) return;

        const shipping = this.form.shippings[0];
        shipping.specific.items = [];
        this.form.designs.forEach((design, index) => {
          if (design.specific.garments) {
            design.specific.garments.forEach(garment => {
              const item = {
                design_id: design.uniqueId,
                garment_id: garment.uniqueId,
                sizes: {}
              };
              Object.keys(garment.sizes).forEach(sizeKey => {
                if (+garment.sizes[sizeKey] === 0) return;
                item.sizes[sizeKey] = garment.sizes[sizeKey];
              });
              shipping.specific.items.push(item);
            });
          } else {
            shipping.specific.items.push({
              design_id: design.uniqueId
            });
          }
        });
      }, 500),
      deep: true
    },
    'form.shippings': {
      handler: debounce(function(val) {
        // todo: fix sending unnecessary requests for tax calculation
        if (!val.length) return;

        this.calculateStateTax();
      }, 500),
      deep: true
    },
    paymentTerms(val) {
      this.form.payment.payment_term =
        val || FORM_ESTIMATE_TEMPLATE.payment.payment_term;
    },
    estimateRawTotal(val) {
      // Re-calculate discount
      if (this.form.payment.discount.discount_type === 'percentage') {
        this.form.payment.discount.discount_amount = +parseFloat(
          val * this.form.payment.discount.discount_percentage / 100
        ).toFixed(2);
      }
      if (this.form.payment.discount.discount_type === 'usd') {
        this.form.payment.discount.discount_percentage = +parseFloat(
          +this.form.payment.discount.discount_amount * 100 / val
        ).toFixed(2);
      }

      // Re-calculate rush fee
      if (this.form.payment.rush_fee.rush_fee_type === 'percentage') {
        this.form.payment.rush_fee.rush_fee_amount = +parseFloat(
          val * this.form.payment.rush_fee.rush_fee_percentage / 100
        ).toFixed(2);
      }
      if (this.form.payment.rush_fee.rush_fee_type === 'usd') {
        this.form.payment.rush_fee.rush_fee_percentage = +parseFloat(
          +this.form.payment.rush_fee.rush_fee_amount * 100 / val
        ).toFixed(2);
      }
    }
  },
  created() {
    const promises = [
      this.getInitData(this.$route.params.id),
      this.getFormOptions(this.accountId),
      this.getFinishingsPrices(this.accountId),
      this.getThreadbirdAddresses(),
      this.getShippingMethods(),
      this.getStatesTaxes()
    ];

    this.isLoading = true;

    Promise.all(promises)
      .then(() => {
        this.initBusEvents();
        this.prepareForm();
        this.attachWatchers();
      })
      .catch(error => {
        alertify.error(
          'Could not load Form data. Please, try to reload the page.'
        );
      })
      .then(() => {
        this.isLoading = false;
      });
  },
  beforeRouteLeave(next) {
    let answer;

    if (!this.isLoading && this.isDirty) {
      answer = window.confirm(
        'You have unsaved changes, are you sure you want to leave this page?'
      );
    }
    next(answer);
  },
  beforeMount() {
    window.addEventListener('beforeunload', this.beforeUnload);
  },
  beforeDestroy() {
    window.removeEventListener('beforeunload', this.beforeUnload);
  },
  methods: {
    ...mapActions({
      getInitData: LOAD_EDIT_ORDER_INIT_DATA,
      clearValidations: CLEAR_VALIDATION_PROMISES,
      resubscribePubNub: RESUBSCRIBE_PUBNUB
    }),
    onTaxedAddressChange(addressKey) {
      if (this.form.shippings) {
        this.form.shippings.map((item, index) => {
          item.specific.address.is_taxed = addressKey === index;
        });
      }
      this.calculateStateTax();
    },
    onShippingCostChange() {
      this.calculateStateTax()
    },
    onAdditionalFeeChange() {
      this.calculateStateTax();
    },
    calculateStateTax(orderId) {
      let selectedShipping = {};
      let type_of_delivery = '';

      if (this.isNonOrderInvoiceOrder) {
        if (isEmpty(this.shippingAddress)) {
          this.form.payment.state_tax = 0;
          return;
        }
        type_of_delivery = 'fulfillment';
        selectedShipping.address_1 = this.shippingAddress.address_line_1;
        selectedShipping.address_2 = this.shippingAddress.address_line_2;
        selectedShipping.city = this.shippingAddress.city;
        selectedShipping.state_id = this.shippingAddress.state_code;
        selectedShipping.postal_code = this.shippingAddress.postal_code;
        selectedShipping.country_id = this.shippingAddress.country_code;
      } else {
        if (isEmpty(this.form.shippings)) {
          this.form.payment.state_tax = 0;
          return;
        }
        selectedShipping = this.form.shippings.find(
          item => item.specific.address.is_taxed
        );
        if (isEmpty(selectedShipping)) {
          selectedShipping = this.form.shippings[0];
        }
        type_of_delivery = selectedShipping.common.type_of_delivery;
        selectedShipping = selectedShipping.specific.address;
      }

      this.$apiService
        .getTaxes({
          order_id: orderId ? orderId : this.$route.params.id,
          garments_total: this.garmentsSubTotal,
          shipping_total: this.shippingsTotal,
          total: this.estimateSubTotal,
          shippings: selectedShipping,
          type_of_delivery: type_of_delivery,
          customer:
            this.form.account && this.form.account.avatax_id
              ? this.form.account.avatax_id.toUpperCase()
              : 'CUSTOMER'
        })
        .then(response => {
          this.form.payment.state_tax = response.tax;
        })
        .catch(error => {
          alertify.error(error.message);
        });
    },
    onPaymentTermChange(payment_term) {
      if (payment_term && !this.estimateId) {
        this.form.payment.payment_term = payment_term;
      }
    },
    setTaxId(taxId) {
      this.form.payment.tax_id =
        taxId || (this.company && this.company.tax_id) || null;
    },
    resetCost(designUniqueId) {
      this.resetFinishingsCost(designUniqueId);
    },
    resetFinishingsCost(designUniqueId) {
      let design = this.form.designs.find(
        d => d.common.uniqueId === designUniqueId
      );

      if (this.form.finishings) {
        this.form.finishings = this.form.finishings.map(finishing => {
          if (
            finishing &&
            finishing.items &&
            finishing.items.filter(item => item.design_id === designUniqueId)
          ) {
            // Disallow to reset cost for finishing with redo designs.
            if (!design.common.redo_design_id) {
              if (finishing.specific.tag_setups_number !== undefined) {
                finishing.specific.tag_setups_number_cost = null;
              }

              finishing.specific.tag_garments_quantity_cost = null;
            }
          }
          return finishing;
        });
      }
    },

    getStatesTaxes() {
      return this.$apiService.getStatesTaxes().then(response => {
        this.statesTaxes = response;
      });
    },
    isStepPassed(step) {
      if (this.$route.name !== 'New order') {
        return true;
      } else {
        const stepData =
          step.index === undefined
            ? this.form[step.id]
            : this.form[step.id][step.index];

        if (Array.isArray(stepData)) {
          return step.isSkippable || stepData.length > 0;
        }
        return stepData.isPassed;
      }
    },
    cancel() {
      this.$mx_confirmModal()
        .then(() => {
          this.$router.push({
            name: this.isApprived ? 'Estimate Details' : 'Order Details',
            id: this.estimateId
          });
        })
        .catch(() => {});
    },
    calcTagQuantity(garments) {
      return garments.reduce(
        (total, garment) =>
          total +
          Object.keys(garment.sizes).reduce(
            (sizesTotal, size) => sizesTotal + +garment.sizes[size],
            0
          ),
        0
      );
    },
    calcTagSetups(garments) {
      let tagSetups = 0;

      const groupedGarments = {};

      garments.forEach(garment => {
        if (!garment.model) return;
        const key = garment.model.origin + '/' + garment.model.fabric;
        if (!groupedGarments[key]) groupedGarments[key] = {};
        Object.keys(garment.sizes).forEach(size => {
          if (+garment.sizes[size] === 0) return;
          if (!groupedGarments[key][size]) groupedGarments[key][size] = 0;
          groupedGarments[key][size] += +garment.sizes[size];
        });
      });
      tagSetups = Object.values(groupedGarments).reduce(
        (total, group) => total + Object.keys(group).length,
        0
      );
      return tagSetups;
    },
    getThreadbirdAddresses() {
      this.$apiService.getThreadbirdAddresses().then(addresses => {
        this.threadbirdAddresses = addresses;
      });
    },
    getShippingMethods() {
      return this.$apiService.getShippingMethods().then(({ data }) => {
        const { ups } = data;
        this.shippingMethods.options = ups.services;
        this.shippingMethods.default = ups.default;
      });
    },
    goToStep(step) {
      if (typeof step === 'string') {
        this.next(this.steps.findIndex(({ id }) => id === step));
      }
      if (typeof step === 'object') {
        const stepIndex = this.steps.findIndex(({ id, index }) => {
          return id === step.type && index === step.index;
        });
        this.next(stepIndex);
      }
      if (typeof step === 'number') {
        this.next(step);
      }
    },
    convertFormOptions(data) {
      Object.keys(data).forEach(service => {
        if (!data[service].locations) return;
        const locationsRaw = data[service].locations;
        const locations = Object.keys(locationsRaw).map(groupKey => {
          return {
            title: groupKey,
            options: Object.keys(locationsRaw[groupKey]).map(childKey => {
              return {
                id: childKey,
                label: locationsRaw[groupKey][childKey]
              };
            })
          };
        });
        data[service].locations = locations;
      });
      return data;
    },
    getFinishingsPrices(userId) {
      return this.$apiService
        .getApparelFinishingPrices(userId)
        .then(response => {
          this.finishingsPrices = response;
        });
    },
    getFormOptions(userId) {
      return this.$apiService
        .get(
          `estimates/designs/form-options?${
            userId ? `filter[user_id]=${userId}` : ''
          }`
        )
        .then(({ data }) => {
          this.formOptions = this.convertFormOptions(data);
        });
    },
    checkShippingsBeforeSubmit(send) {
      return new Promise((resolve, reject) => {
        if (this.form.shippings.length > 1 && send) {
          return this.$mx_confirmModal({
            title: 'Confirm send',
            text:
              'Estimate contains split shipping. Please ensure that everything is configured correctly',
            yesText: 'Send anyway',
            cancelText: 'Cancel'
          })
            .then(() => {
              resolve(true);
            })
            .catch(err => {
              // No need to send estimate.
              resolve(false);
            });
        } else {
          // No need to check shippings.
          resolve(send);
        }
      });
    },
    createInvoice({ amount, description, payment_term, orderId }) {
      return this.$apiService
        .requestPayment({
          orderId: orderId,
          invoice: {
            amount: amount,
            description: description,
            payment_term: payment_term
          }
        })
        .then(data => {
          return data;
        })
        .catch(error => {
          return error;
        });
    },
    isNeedNewInvoice() {
      let isOrder = this.form.order.is_order;
      let orderId = this.form.order.id;
      let latest_invoice = this.form.order.invoice;
      let term = this.form.payment.payment_term;

      // If it's order we need to check if total price has been changed
      let price = +(+this.form.order.total_price || 0.0).toFixed(2);
      let newPrice = +(+this.estimateTotal || 0.0).toFixed(2);
      let paidSum = +(
        (latest_invoice && +latest_invoice.paid_sum) ||
        0.0
      ).toFixed(2);

      let invoice = {
        orderId: orderId,
        amount: +newPrice,
        description: 'The contents of the order have been updated.',
        payment_term: term
      };

      if (!isOrder || !orderId) {
        return Promise.resolve();
      }

      if (newPrice === price) {
        // Can save without prompt
        return Promise.resolve();
      }

      if (newPrice === paidSum || (newPrice < price && newPrice > paidSum)) {
        return this.createInvoice(invoice)
          .then(() => {
            return Promise.resolve();
          })
          .catch(error => {
            alertify.error((error && error.message) || 'Unexpected Error.');
          });
      }

      if (newPrice < paidSum) {
        // Show confirmation popup
        return new Promise((resolve, reject) => {
          return this.$mx_confirmModal({
            title: 'Confirmation',
            text:
              'Please note, you need to manually refund the client through the payment gateway because the new total is lower than paid amount.',
            yesText: 'Got it, Save changes'
          }).then(() => {
            this.isLoading = true;
            this.createInvoice(invoice)
              .then(({ invoice }) => {
                this.isLoading = false;
                resolve();
              })
              .catch(err => {
                this.isLoading = false;
                reject();
              });
          });
        });
      }

      if (newPrice > price) {
        return new Promise((resolve, reject) => {
          return this.$mx_confirmModal({
            title: 'Confirmation',
            text:
              'Please note, the new invoice will be sent to the client because the total has increased.',
            yesText: 'Got it, Add invoice'
          }).then(() => {
            this.isLoading = true;
            this.createInvoice(invoice)
              .then(({ invoice }) => {
                if (invoice && invoice.id) {
                  this.emailInvoice(invoice.id);
                }
                this.isLoading = false;
                resolve();
              })
              .catch(err => {
                this.isLoading = false;
                reject();
              });
          });
        });
      }
    },
    emailInvoice(id) {
      apiService
        .emailInvoice(id)
        .then(data => {
          if (data.status === 'success') {
            alertify.success('Invoice was sent.');
          } else {
            alertify.error(data.message);
          }
        })
        .catch(err => {
          alertify.error('Something wrong.');
        });
    },
    submit(send) {
      this.isNeedNewInvoice()
        .then(() => {
          this.checkShippingsBeforeSubmit(send).then(isConfirmed => {
            if (send && !isConfirmed) {
              return;
            }
            this.isLoading = true;
            this.calculateStateTax(this.estimateId)
            this.$apiService
              .saveEstimate(this.formPayload, this.estimateId)
              .then(({ data }) => {
                this.calculateStateTax(data.order.id);
                this.resubscribePubNub();
                if (!send) return true;
                return this.$apiService
                  .sendEstimate(data.order.id)
                  .then(() => {
                    alertify.success('The estimate has been sent.');
                    return true;
                  })
                  .catch(err => {
                    alertify.error(
                      "Unfortunately, estimate wasn't sent. Please try to resend it from dashboard"
                    );
                    return true;
                  });
              })
              .then(redirect => {
                if (redirect) {
                  if (this.$route.params.id) {
                    if (this.form.order.is_order) {
                      this.$router.push({
                        name: 'Order Details',
                        params: { id: this.$route.params.id }
                      });
                    } else {
                      this.$router.push({
                        name: 'Estimate Details',
                        params: { id: this.$route.params.id }
                      });
                    }
                  } else {
                    this.$router.push({ name: 'My Estimates' });
                  }
                }
              })
              .catch(error => {
                if (
                  error.body &&
                  error.body.errors &&
                  Object.keys(error.body.errors).length
                ) {
                  Object.keys(error.body.errors).forEach(errorKey => {
                    const fieldErrors = error.body.errors[errorKey].map(
                      errorText =>
                        errorText.replace(errorKey, get(this.form, errorKey))
                    );
                    alertify.error(`
                      ${fieldErrors.map(error => `<p>${error}</p>`).join()}
                      <p>Please, fix the errors and try again.</p>
                    `);
                  });
                } else {
                  alertify.error(
                    `Error appeared while saving ${
                      this.isApproved ? 'Order' : 'Estimate'
                    }.<br>
                    Please, try again.`
                  );
                }
              })
              .then(() => {
                this.isLoading = false;
              });
          });
        })
        .catch(err => {});
    },
    onDeleteGarment(uniqueId) {
      this.form.finishings.forEach(finishing => {
        finishing.items = finishing.items.filter(
          ({ garment_id }) => garment_id !== uniqueId
        );
      });

      this.form.shippings = this.form.shippings
        .map(shipping => {
          shipping.specific.items = shipping.specific.items.filter(
            ({ garment_id }) => garment_id !== uniqueId
          );
          return shipping;
        })
        .filter(shipping => shipping.specific.items.length > 0);
    },
    onDeleteDesign(uniqueId) {
      const step = this.$refs.step[0];

      this.$mx_confirmModal()
        .then(() => {
          this.form.designs.splice(
            this.form.designs.findIndex(design => design.uniqueId === uniqueId),
            1
          );

          this.form.finishings.forEach(finishing => {
            finishing.items = finishing.items.filter(
              ({ design_id }) => design_id !== uniqueId
            );
          });

          this.form.shippings.forEach(shipping => {
            const relatedDesignIndex = shipping.specific.items.findIndex(
              key => key === uniqueId
            );
            if (relatedDesignIndex > -1) {
              shipping.specific.items.splice(relatedDesignIndex, 1);
            }
          });

          if (this.form.designs.length === 0) {
            this.addDesign();
          }

          this.currentStepIndex = this.form.designs.length;
        })
        .catch(() => {});
    },
    saveAsDraft() {
      if (this.saveAsDraftDisabled) {
        alertify.error(
          'Please, add at least one valid design to save Your Estimate'
        );
        return;
      }
      this.submit();
    },
    attachWatchers() {
      this.$watch(
        'form',
        () => {
          if (!this.isDirty) this.isDirty = true;
        },
        { deep: true }
      );
    },
    initBusEvents() {
      bus.$on('errors-changed', errors => {
        if (errors) {
          errors.forEach(e => {
            this.errors.add(e.field, e.msg, e.rule, e.scope);
          });
        }
      });

      bus.$on('errors-deleted', oldErrors => {
        if (oldErrors) {
          oldErrors.forEach(e => {
            this.errors.remove(e.field, e.scope);
          });
        }
      });
    },
    prepareForm() {
      if (this.form.designs.length === 0) {
        this.addDesign();
      }

      this.calculateStateTax();
    },
    addDesign() {
      const designUniqueId = uniqueId('design_');
      const design = {
        uniqueId: designUniqueId, // need for multiselect trackBy option
        common: merge({}, FORM_ESTIMATE_TEMPLATES.design.common, {
          uniqueId: designUniqueId
        }),
        specific: merge(
          {},
          FORM_ESTIMATE_TEMPLATES.designSpecific[
            FORM_ESTIMATE_TEMPLATES.design.common.service // 'apparel' is used by default
          ]
        )
      };
      this.form.designs.push(design);

      if (this.form.shippings.length === 1) {
        this.form.shippings[0].specific.items.push({
          design_id: designUniqueId
        });
      }
    },
    onAddFinishing(data) {
      let finishing = data;
      if (!finishing) {
        finishing = merge(
          { uniqueId: uniqueId('finishing_') },
          cloneDeep(FORM_ESTIMATE_TEMPLATES.finishing),
          {
            common: {
              // name: `Finishing ${this.form.finishings.length + 1}`,
              uniqueId: uniqueId('finishing_')
            }
          }
        );
      }
      this.form.finishings.push(finishing);
    },
    onRemoveFinishingDesignItem(designUniqueId) {
      this.form.finishings.forEach(f => {
        f.items = f.items.filter(fi => fi.design_id !== designUniqueId);
      });
    },
    onRemoveShippingDesignItem(designUniqueId) {
      this.form.shippings.forEach(s => {
        s.specific.items = s.specific.items.filter(
          si => si.design_id !== designUniqueId
        );
      });
    },
    onReset() {
      this.form[this.currentStep.id] =
        FORM_ESTIMATE_TEMPLATE[this.currentStep.id];
    },
    onStepData(data) {
      if (this.currentStep.index === undefined) {
        this.form[this.currentStep.id] = JSON.parse(JSON.stringify(data));
      } else {
        this.$set(
          this.form[this.currentStep.id],
          this.currentStep.index,
          JSON.parse(JSON.stringify(data))
        );
      }
    },
    beforeUnload($event) {
      if (!this.isDirty) return;

      let confirmationMessage = 'o/';

      $event.returnValue = confirmationMessage; // Gecko, Trident, Chrome 34+
      return confirmationMessage; // Gecko, WebKit, Chrome <34
    },
    getDesignIndex(index) {
      return this.steps[index].id === 'design';
    },
    validateBeforeSubmit() {
      bus.$emit('clear');
      this.errors.errors = [];
      bus.$emit('validate', `form-estimate-${this.currentStep.id}`);

      this.isLoading = true;

      return new Promise((resolve, reject) => {
        Vue.nextTick(() => {
          Promise.all(this.$store.state.common.validations).then(() => {
            this.clearValidations();
            resolve(!this.errors.count());
            this.isLoading = false;
          });
        });
      });
    },
    back() {
      const step = this.$refs.step[0];

      if (this.currentStep.id === 'designs') {
        this.validateBeforeSubmit().then(isValid => {
          if (isValid) {
            step.onPassed && step.onPassed();
            this.currentStepIndex -= 1;
          } else {
            step.onError && step.onError();
          }
        });
      } else {
        step.onPassed && step.onPassed();
        this.currentStepIndex -= 1;
      }
    },
    next(index) {
      const step = this.$refs.step[0];

      if (!this.currentStep.isSkippable) {
        this.validateBeforeSubmit().then(isValid => {
          if (isValid) {
            step.onPassed && step.onPassed();
            this.$set(
              this.currentStep.index === undefined
                ? this.form[this.currentStep.id]
                : this.form[this.currentStep.id][this.currentStep.index],
              'isPassed',
              true
            );
            if (index !== undefined) {
              this.currentStepIndex = index;
            } else {
              this.currentStepIndex += 1;
            }
          } else {
            this.$scrollTo(this.$el.querySelector('.has-errors'), 300, {
              forced: true
            });
            step.onError && step.onError();
          }
        });
      } else {
        this.validateBeforeSubmit().then(isValid => {
          if (!isValid) {
            if (!step.isDirty) {
              step.onPassed && step.onPassed();
              this.$set(
                this.currentStep.index === undefined
                  ? this.form[this.currentStep.id]
                  : this.form[this.currentStep.id][this.currentStep.index],
                'isPassed',
                true
              );
              if (index !== undefined) {
                this.currentStepIndex = index;
              } else {
                this.currentStepIndex += 1;
              }
              return;
            }
          } else {
            step.onPassed && step.onPassed();
            this.$set(
              this.currentStep.index === undefined
                ? this.form[this.currentStep.id]
                : this.form[this.currentStep.id][this.currentStep.index],
              'isPassed',
              true
            );
            if (index !== undefined) {
              this.currentStepIndex = index;
            } else {
              this.currentStepIndex += 1;
            }
          }
        });
      }
    },
    copyEstimate() {
      if (!this.isOrder && this.estimateId) {
        this.$router.push({
          path: '/orders/create',
          query: {
            copy_from_id: this.estimateId
          }
        });
      } else {
        this.showOrderCopyAlert();
      }
    },
    showEstimateConfirmationModal() {
      this.$mx_confirmModal({ title: 'Copy Estimate', text: 'Are you sure?' })
        .then(() => {
          this.copyEstimate();
        })
        .catch(() => {});
    },
    showOrderCopyAlert() {
      this.$mx_alertModal({
        title: 'Copy Estimate',
        text: this.isOrder
          ? "The system doesn't support to copy the order."
          : !this.estimateId
            ? 'Please save estimate first.'
            : 'There is no ability to copy estimate for this time.'
      });
    },

    onChangeService(designId, serviceName) {
      //remove finishing from changeable design
      this.form.finishings = this.form.finishings.map(finishing => {
        finishing.items = finishing.items.filter(
          finishingItem => finishingItem.design_id != designId
        );
        return finishing;
      });
      //remove shipping from changeable design
      this.form.shippings = this.form.shippings.map(shipping => {
        shipping.specific.items = shipping.specific.items.filter(
          shippingItem => shippingItem.design_id != designId
        );
        return shipping;
      });
    }
  }
};
</script>
