<template>
  <tr>
    <td>
      <invoice-field
        label="Location"
        :value="getLocationLabel(location.location, formOptions[service].locations)"
      />
    </td>
    <td>
      <invoice-field
        label="Size"
        :value="(!location.width && !location.height) ? 'None' : getLocationSizeLabel(location)"
      />
    </td>
    <td v-if="service === apparelServiceType">
      <invoice-field
        label="# of Colors"
        :value="formOptions.apparel.colors_numbers.find(({ key }) => key === location.colors_number).label"
      />
    </td>
    <td v-if="service === apparelServiceType">
      <invoice-field
        label="Ink Type"
        :value="formOptions.apparel.ink_types.find(({ key }) => key === location.ink_type).label"
      />
    </td>
    <td v-if="service === apparelServiceType">
      <invoice-field
        label="Process Addons"
        :value="((!location.process_addons || location.process_addons.length === 0) ? 'None': location.process_addons.map(({ label }) => label).join(', '))"
      />
    </td>
    <td v-if="service === embroideryServiceType">
      <invoice-field
        label="Depth"
        :value="formOptions.embroidery.depth.find(({ key }) => key === location.depth).label"
      />
    </td>
    <td v-if="service === embroideryServiceType">
      <invoice-field
        label="Thread Colors"
        :value="location.thread_colors"
      />
    </td>
    <td v-if="service === embroideryServiceType">
      <invoice-field
        label="Stitches"
        :value="formOptions.embroidery.stitches.find(({ key }) => key === location.stitches_id).label | intlNumber"
      />
    </td>
  </tr>
</template>
<script>
import InvoiceField from '@/components/common/invoice-field.vue';
import {flatten} from 'lodash';
import {APPAREL_SERVICE_CODE, EMBROIDERY_SERVICE_CODE} from '@/store/service-types';

export default {
  name: 'ApparelLocation',
  components: {InvoiceField},
  props: {
    location: {
      type: Object,
      required: true
    },
    locationIndex: {
      type: Number,
      required: true
    },
    formOptions: {
      type: Object,
      required: true
    },
    service: {
      type: String,
      required: true
    }
  },
  methods: {
    getLocationLabel(locationId, locations) {
      return flatten(
        Object.values(locations).map(({options}) => options)
      ).find(({id}) => +id === +locationId).label;
    },
    getLocationSizeLabel(location) {
      return `${location.width ? `${location.width}"` : '*'} - ${
        location.height ? `${location.height}"` : '*'
      }`;
    }
  },
  computed: {
    apparelServiceType() {
      return APPAREL_SERVICE_CODE;
    },
    embroideryServiceType() {
      return EMBROIDERY_SERVICE_CODE;
    }
  }
};
</script>
