<template>
  <div class="design-info">
    <div v-if="design.designable && design.designable.item" class="card is-fullwidth">
      <div class="card-content">
        <div class="content">
          <table class="table is-bordered is-striped is-narrow">
            <thead>
              <tr>
                <th>Quantity</th>
                <th>Price</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{{design.designable.item.quantity | itemsNumberFormat}}</td>
                <td>{{design.designable.item.price}}</td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="content">
          <div v-if="design.comment && !hideComment" class="card is-fullwidth">
            <header class="card-header">
              <p class="card-header-title">Design Comment</p>
            </header>
            <div class="content-pre card-content">{{ design.comment }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'design-info-other',
  props: {
    design: {
      type: Object,
      required: true
    },
    options: {
      type: Object,
      required: true
    },
    hideComment: {
      type: Boolean,
      default: false
    }
  }
};
</script>
