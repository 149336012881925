<template>
  <div v-if="orderSummary" :class="{'card is-fullwidth': true, 'sticky': isSticky}">
    <div class="order-summary-body">
      <div class="order-contact-info">
        <header class="card-header is-hidden-print">
          <p class="card-header-title">Summary</p>
        </header>

        <div class="card-content details-base-info">
          <div class="content">
            <h5 class="subtitle is-marginless">Contact Info</h5>
            <div class="control">
              <div>{{orderSummary.contactName}}</div>
              <div v-if="orderSummary && orderSummary.address">
                <template v-if="orderSummary.address.mailing_address">{{orderSummary.address.mailing_address}} <br></template>
                <template v-if="orderSummary.address.city">{{orderSummary.address.city}},</template>
                <template v-if="orderSummary.address.state_id">{{orderSummary.address.state_id}}</template>
                <template v-if="orderSummary.address.postal_code">{{orderSummary.address.postal_code}} <br></template>
                <template v-if="orderSummary.address.country">{{orderSummary.address.country}}</template>
              </div>
            </div>

            <div class="control" v-if="orderSummary.recipient && (orderSummary.recipient.firstname || orderSummary.recipient.lastname || orderSummary.recipient.company)">
              <h5 class="subtitle is-marginless">Recipient Info</h5>
              <div v-if="orderSummary.recipient.firstname"><strong>First Name: </strong>{{orderSummary.recipient.firstname}}</div>
              <div v-if="orderSummary.recipient.lastname"><strong>Last Name: </strong>{{orderSummary.recipient.lastname}}</div>
              <div v-if="orderSummary.recipient.company"><strong>Company: </strong>{{orderSummary.recipient.company}}</div>
            </div>

            <h5 class="subtitle is-marginless">Estimate Date</h5>
            <div class="control">
              {{orderSummary.estimateDate}}
            </div>

            <div v-role-access="['admin', 'csr', 'production-coordinator', 'purchaser', 'art-producer']">
              <h5 class="subtitle is-marginless">Ship By</h5>
              <span>{{orderSummary.ship_by_date || " - "}}</span>
            </div>

          </div>
        </div>
        <paid-and-remain-amount
          v-if="showPaidAndRemain"
          :paidAmount="paidAmount"
          :remainAmount="remainAmount"
          v-roles="['admin', 'csr', 'production-coordinator', 'purchaser', 'art-producer', 'customer']"
          class="paid-amount_right-align"
        ></paid-and-remain-amount>
      </div>

      <div v-if="!(orderSummary.total && orderSummary.total.price)" class="card-content">
        <div class="content">
          <h5 title="subtitle ">Please fill required fields</h5>
        </div>
      </div>

      <div v-if="orderSummary.total && orderSummary.total.price">
        <header class="card-header" v-show="orderSummary.designs">
          <p class="card-header-title">Designs Details</p>
        </header>
        <div v-for="(design, key) in orderSummary.designs" class="card-content design-estimate-details">
          <component :is="getComponentNameByDesign(design)"
            :design="design"
            :hide-zero-quantity-sizes="hideZeroQuantitySizes"
            :index="key"
            :customer-order-details="customerOrderDetails"/>
        </div>
      </div>

      <div v-if="orderSummary.comment">
        <header class="card-header" v-show="orderSummary.designs">
          <p class="card-header-title">Order Comment</p>
        </header>

        <p class="card-content">{{orderSummary.comment}}</p>
      </div>
    </div>
    <footer class="card-footer">
      <header class="card-header">
        <p class="card-header-title">Estimate Total</p>
      </header>
      <div class="card-content" v-if="orderSummary.total">
        <div class="content">
          <h3 title="title">
            <span v-if="orderSummary.total.packages_qty">Boxes: {{orderSummary.total.packages_qty | itemsNumberFormat}}</span>
            <span  v-roles="['admin', 'csr', 'production-coordinator', 'purchaser', 'art-producer', 'customer']" class="is-pulled-right"
              v-if="!hideShipping && orderSummary.total.shipping_cost">
              Shipping: {{orderSummary.total.shipping_cost | price}}
            </span>
          </h3>
        </div>
        <div v-if="orderSummary.total.state_tax || orderSummary.total.tax_id"
          :class="{
            'content': true,
            'has-text-right': !orderSummary.total.tax_id
          }">
          <span v-roles="['admin', 'csr', 'production-coordinator', 'purchaser', 'art-producer', 'customer']">
            Sales Tax ({{orderSummary.total.state}}): {{orderSummary.total.state_tax | price}}
          </span>
          <div class="is-pulled-right"
            v-if="orderSummary.total.tax_id">
            * Tax id: {{orderSummary.total.tax_id}}
          </div>
        </div>
        <div class="content">
          <dl v-if="orderSummary.total.totalDiscount" class="discount">
            <dt><strong>Discount:</strong> {{orderSummary.total.totalDiscount | price}}</dt>
            <dt v-if="orderSummary.discount && orderSummary.discount.discount_description">{{orderSummary.discount.discount_description}}</dt>
          </dl>
           <dl v-if="orderSummary.total.rush_fee" class="discount">
            <dt><strong>Rush Fee:</strong> {{orderSummary.total.rush_fee | price}}</dt>
            <dt v-if="orderSummary.rush_fee && orderSummary.rush_fee.rush_fee_description">{{orderSummary.rush_fee.rush_fee_description}}</dt>
          </dl>
          <h3 title="title" class="is-marginless">{{orderSummary.total.qty | itemsNumberFormat}} items
            <span  v-roles="['admin', 'csr', 'production-coordinator', 'purchaser', 'art-producer', 'customer']" class="is-pulled-right">Total: {{totalPrice | price}}</span>
          </h3>
        </div>
      </div>
    </footer>
  </div>
</template>

<style lang="scss">
.card-footer {
  display: flex;
  flex-direction: column;
}

.card.sticky {
  max-height: calc(100vh - 152px);
  display: flex;
  flex-direction: column;
}

.card.sticky .order-summary-body {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}

.card.sticky .card-footer {
  flex-shrink: 0;
}

.design-estimate-details {
  border-top: 3px solid black;
}
.discount {
  margin-bottom: 10px;
}
</style>

<script>
import Vue from 'vue';
import SummaryApparel from './estimate-summary-types/summary-apparel';
import * as SummaryBlank from './estimate-summary-types/summary-apparel';
import SummaryPoster from './estimate-summary-types/summary-poster';
import SummaryButton from './estimate-summary-types/summary-button';
import SummarySticker from './estimate-summary-types/summary-sticker';
import SummaryDigital from './estimate-summary-types/summary-digital';
import SummaryOther from './estimate-summary-types/summary-other';
import PaidAndRemainAmount from './paid-and-remain-amount';
import { some } from 'lodash';

export default {
  props: [
    'orderSummary',
    'hideZeroQuantitySizes',
    'isSticky',
    'hideShipping',
    'customerOrderDetails',
    'showPaidAndRemain',
    'paidAmount',
    'remainAmount',
    'roles'
  ],
  name: 'estimate-summary',
  components: {
    SummaryApparel,
    SummaryBlank,
    SummaryPoster,
    SummaryButton,
    SummarySticker,
    SummaryDigital,
    SummaryOther,
    PaidAndRemainAmount
  },
  methods: {
    getComponentNameByDesign(d) {
      // Embroidery uses the same models/components as apparel,
      // with some different fields.
      return 'summary-' + (d.type === 'embroidery' ? 'apparel' : d.type);
    }
  },

  computed: {
    hasApparel() {
      return some(
        this.orderSummary.designs,
        design => design.type === 'apparel'
      );
    },
    totalPrice() {
      if (this.orderSummary.total.upcharge) {
        return (
          +this.orderSummary.total.price + +this.orderSummary.total.upcharge
        );
      } else {
        return +this.orderSummary.total.price;
      }
    }
  }
};
</script>
