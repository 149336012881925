import apiService from './api.service';
import Xml from '../helpers/xml-helper';
import notify from '../modules/notifier';

import { SET_S3_DOMAIN } from '../store/action-types';

class S3Service {
  getS3BaseDomain() {
    return apiService
      .getS3BaseDomain()
      .then(data => {
        this.domain = data;
        this.store.dispatch(SET_S3_DOMAIN, this.domain);
      })
      .catch(console.error);
  }

  sendFilesToDesign(designId, files, uploader) {
    return this._sendFiles('design', designId, files, uploader);
  }

  sendFilesToOrder(orderId, files, uploader) {
    return this._sendFiles('order', orderId, files, uploader);
  }

  _sendFiles(context, id, files, uploader) {
    let prepareMethod =
      context === 'order' ? 'prepareS3' : 'prepareS3FormByDesignId';
    return apiService[prepareMethod](id)
      .then(data => {
        files.forEach(file => {
          file.postAction = data.form_url;
          file.data = { ...file.data, ...data.inputs };
        });

        return new Promise((resolve, reject) => {
          uploader.active = !uploader.active;
          const addMsg = () => {
            if (!uploader.uploaded) {
              setTimeout(addMsg, 100);
            } else {
              let uploadedFiles = [];
              const filesToHandle =
                files && typeof files[0].response === 'string'
                  ? files
                  : uploader.value;
              filesToHandle.forEach(file => {
                let keys = Xml.parse(file.response).getElementsByTagName('Key');
                if (keys.length) {
                  uploadedFiles.push({
                    path: keys[0].innerHTML
                  });
                }
              });
              resolve(uploadedFiles);
            }
          };
          addMsg();
        });
      })
      .catch(err => {
        notify({
          message: 'Something wrong',
          type: 'danger'
        });
        console.error(err);
      });
  }

  create() {
    this.getS3BaseDomain();
  }

  install(Vue, { store }) {
    if (store && store.getters.isAuthenticated) {
      this.create();
    } else if (store) {
      store.subscribe((mutation, state) => {
        if (!this.domain && !this.creating && store.getters.isAuthenticated) {
          this.creating = true;
          this.create();
        }
      });
    }
    this.store = store;
  }
}

export default new S3Service();
