<template>
  <fieldset class="control">
    <fieldset class="company control">
      <div class="columns">
        <div class="column is-4" v-roles="['admin', 'csr', 'production-coordinator', 'purchaser', 'art-producer']">
          <form-select
            v-model="customerFields.terms"
            name="terms"
            label="Term"
            :addEmpty="false"
            scope="company-form"
            :options="{all: paymentOptionsAll}"
          />
        </div>
        <div class="column is-4">
          <autocomplete
            v-if="!options.hideCompany"
            :disabled="!canEditCompany"
            :searchCallback="searchCompanies"
            :filterBy="'name'"
            name="company-autocomplete"
            scope="company-form"
            :label="'Company'"
            @selected="onCompanySelect"
            :value="userData && userData.company || {}"
          ></autocomplete>
        </div>
        <div v-if="customerFields.company_id === 'create-new'" class="column is-4">
          <form-input
            v-model="customerFields.company_name"
            name="company_name"
            label="Company name"
            scope="company-form"
          />
        </div>
      </div>
    </fieldset>

    <div
      class="columns"
      v-roles="['admin','csr','production-coordinator','purchaser','art-producer','printer']"
    >
      <div class="column">
        <form-textarea
          v-model="customerFields.production_notes"
          name="production_notes"
          label="Production notes"
          scope="company-form"
          :required="false"
          rules
        ></form-textarea>
      </div>
    </div>
  </fieldset>

</template>

<script>
import Vue from 'vue';
import { mapGetters, mapMutations } from 'vuex';
import { merge, filter, cloneDeep, isEmpty } from 'lodash';
import apiService from '../../../services/api.service';
import bus from '../../../bus';
import { checkRoles } from '@/router/checkRoles';
import store from '../../../store';
import {teamMembers} from '@/helpers/role-access';

//form
import formInput from '../../common/form/input';
import formSelect from '../../common/form/select';
import formTextarea from '../../common/form/textarea';
import autocomplete from '../../common/form/autocomplete';

let defaultAddress = {
  address_line_1: '',
  address_line_2: '',
  city: '',
  state_code: '',
  country_code: 'US',
  postal_code: ''
};

export default {
  name: 'company-form',
  components: {
    formInput,
    formSelect,
    formTextarea,
    autocomplete
  },
  data() {
    return {
      account: {
        user: merge({}, this.model.user),
        billing_address: merge({}, defaultAddress, this.model.billing_address),
        shipping_address: merge({}, defaultAddress, this.model.shipping_address)
      },
      customerFields: {
        company_id: cloneDeep(this.model.user.company_id),
        company_name: cloneDeep(this.model.user.company_name),
        terms: cloneDeep(this.model.user.terms),
        production_notes: cloneDeep(this.model.user.production_notes)
      },
      billing_address_same: false,
      statesCount: {
        billing_address: 0,
        shipping_address: 0
      },
      states: {
        billing_address: [],
        shipping_address: []
      },
      companyOptionsAll: [],
      companyDetailsAll: {},
      noCompanyOptions: [
        {
          key: '',
          label: 'No company selected'
        }
      ]
    };
  },
  watch: {
    account: {
      handler(account) {
        let _account = cloneDeep(account);
        let model = { ..._account, ...this.model };
        model.user = { ...model.user, ...this.customerFields };
        model.shipping_address = _account.shipping_address;
        model.billing_address = _account.billing_address;
        this.$emit('accountChanged', model, this.formKey);
      },
      deep: true
    },
    customerFields: {
      handler(customerFields) {
        let user = cloneDeep(this.account.user);
        user = { ...user, ...customerFields };
        this.$set(this.account, 'user', user);
      },
      deep: true
    },
    billing_address_same(same) {
      if (same) {
        this.cloneAddress(this.account.shipping_address, 'billing_address');
      }
    }
  },
  props: {
    errorsList: {
      type: Array,
      default: () => []
    },
    model: {
      type: Object
    },
    formKey: {
      type: Number,
      default: 0
    },
    newCompanyModel: {
      type: Object
    },
    options: {
      type: Object
    }
  },
  computed: {
    ...mapGetters({
      countriesAll: 'countries',
      paymentOptionsAll: 'paymentTerms',
      countriesOptions: 'countriesOptions',
      userData: 'account'
    }),
    hasCompany() {
      return (
        (this.account.user.company_id &&
          this.account.user.company_id !== 'create-new') ||
        (this.newCompanyModel && this.newCompanyModel.shipping_address) ||
        false
      );
    },
    canEditCompany() {
      return !!checkRoles(store.getters.roles, teamMembers, true);
    }
  },
  methods: {
    ...mapMutations({
      setAccount: 'setAccount'
    }),
    copyCompanyAddress(addressType) {
      if (
        this.newCompanyModel !== undefined &&
        this.newCompanyModel[addressType] !== undefined
      ) {
        this.cloneAddress(this.newCompanyModel[addressType], addressType);
      } else if (
        typeof this.companyDetailsAll[addressType + 'es'][0] !== 'undefined'
      ) {
        this.cloneAddress(
          this.companyDetailsAll[addressType + 'es'][0],
          addressType
        );
      }
    },
    getStates(field, callback) {
      if (!this.account[field].country_code) {
        this.statesCount[field] = 0;
        this.states[field] = [];
        this.account[field].state_code = '';
      } else {
        return apiService
          .getAccStates(this.account[field].country_code)
          .then(data => {
            this.statesCount[field] = data.total;
            this.states[field] = data.list;
            this.account[field].state_code = '';

            if (callback) {
              callback();
            }
          });
      }
    },
    cloneAddress(clone, to) {
      this.account[to].address_line_1 = clone.address_line_1;
      this.account[to].address_line_2 = clone.address_line_2;
      this.account[to].city = clone.city;
      this.account[to].country_code = clone.country_code;
      this.account[to].postal_code = clone.postal_code;
      this.getStates(to, () => {
        this.account[to].state_code = clone.state_code;
      });
    },
    statesOptions(country) {
      return {
        all: this.states[country]
      };
    },
    onValidate(validateScope) {
      if (validateScope) {
        this.$validator.validateAll(validateScope).catch(err => {});
      } else {
        this.$validator.validateScopes().catch(err => {});
      }
    },
    onClear() {
      if (this.$validator.$scopes && this.errors) {
        Object.keys(this.$validator.$scopes).map(scope =>
          this.errors.clear(scope)
        );
      }
    },
    searchCompanies(query) {
      let page = 1;

      let url = `/api/companies/list?page=${page}`;

      if (query) {
        url +=
          url.indexOf('?') == -1
            ? `?q=${query}&add-create-option=true`
            : `&q=${query}&add-create-option=true`;
      }

      return this.$http.get(url).then(
        response => {
          return response.data.companies_data.data;
        },
        response => {
          notify({
            message: 'Could not load companies list',
            type: 'danger'
          });
          return [];
        }
      );
    },
    onCompanySelect(company) {
      this.$set(this, 'companyDetailsAll', {});
      this.customerFields.company_id = null;

      if (!isEmpty(company) && company.id) {
        this.customerFields.company_id = company.id;
        this.companyDetailsAll = company;
      }
    }
  },
  created() {
    bus.$on('validate', this.onValidate);
    bus.$on('clear', this.onClear);

    this.$watch(
      () => this.errors.errors,
      value => {
        bus.$emit('errors-changed', value);
      }
    );
  },
  mounted() {
    if (this.account.billing_address.country_code) {
      let state_code = this.account.billing_address.state_code;
      this.getStates('billing_address', () => {
        this.account.billing_address.state_code = state_code;
      });
    }
    if (this.account.shipping_address.country_code) {
      let state_code = this.account.shipping_address.state_code;
      this.getStates('shipping_address', () => {
        this.account.shipping_address.state_code = state_code;
      });
    }
    if (this.userData && this.userData.company && this.userData.company.id) {
      this.companyDetailsAll = cloneDeep(this.userData.company);
    }
  },
  beforeDestroy() {
    let account = {
      ...this.model
    };
    delete account.shipping_address;
    delete account.billing_address;
    account.user.terms = 'request-payment';
    account.user.company_id = '';
    account.user.company_name = '';
    this.$emit('accountChanged', account, this.formKey);
    this.setAccount({});
  }
};
</script>
