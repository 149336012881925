<template>
  <div>
    <filter-estimate
      :filters-options="filterOptions"
      @takeFilters="onFilters"/>
    <grid :orders="gridData"
          :designs="gridOrderDetails"
          :finishings="gridOrderFinishings"
          :columns="gridMyEstimatesHeaders"
          :loading="myEstimatesLoading"
          noResMsg="No my estimates"
          :showTriangle="false"
          @onGridSortBy="gridUpdateOrders"/>

    <paginator v-if="myEstimates.length"
               :pagesNumber="myEstimatesPaginatorData.last_page"
               :prevPageUrl="myEstimatesPaginatorData.prev_page_url"
               :nextPageUrl="myEstimatesPaginatorData.next_page_url"
               :currentPage="myEstimatesPaginatorData.current_page"
               :getListItems="loadMyEstimatesPaginated"
               :showOffset="true"
               :totalNumber="myEstimatesPaginatorData.total"
               :sortField="sortField"
               :sortDir="sortDir"/>

    <v-dialog :clickToClose="false"/>
  </div>
</template>

<script>
import popup from '../../modules/popupper';
import { mapActions, mapGetters } from 'vuex';
import { PAYMENT_STATUSES } from '@/helpers/orders';
import pubnubService from '../../services/pubnub.service';
import apiService from '../../services/api.service';
import filterEstimate from '../estimates/filter-estimate';
import statusLostReasonPopupComponent from './popups/status-lost-reason.vue';

import {
  LOAD_MY_ESTIMATES,
  SEND_ESTIMATE, SET_ESTIMATE_IS_APPROVED, SET_MY_ORDERS_MOCK_SENT_DATE,
  SET_ORDER_STATUS,
  SET_ORDER_TERMS,
  UPDATE_MY_ESTIMATES_DESIGN_PROGRESS,
  UPDATE_MY_ESTIMATES_FINISHING_PROGRESS
} from '@/store/action-types';

import {
  ESTIMATE_FILTER_STATUSES,
  getGridData,
  getGridMyEstimatesFinishings,
  getGridOrderDetails,
  gridMyEstimatesHeaders,
  myOrderActionsDetails,
  unclaimedOrderActionsFinishings
} from '@/helpers/estimates';

import grid from '../common/grid/grid';
import paginator from '../common/paginator';
import { cloneDeep } from 'lodash';
import alertify from "alertify.js";

export default {
  name: 'my-estimates',
  data() {
    return {
      gridMyEstimatesHeaders,
      sortField: 'created_at',
      sortDir: 'DESC',
      page: 1,
      perPage: 50,
      timezone: '',
      filters: {
        date_to: '',
        date_from: '',
        status: ESTIMATE_FILTER_STATUSES.STATUS_FILTER_ACTIVE
      }
    };
  },
  computed: {
    ...mapGetters([
      'myEstimates',
      'myEstimatesPaginatorData',
      'myEstimatesLoading',
      'unreadOrdersMessages',
      'roles',
      'paymentTerms',
      'filterOptions'
    ]),
    termsOptions() {
      let options = {};
      this.paymentTerms.forEach(option => {
        options[option.key] = option.label;
      });
      return options;
    },
    gridData() {
      return {
        data: getGridData(this.myEstimates, this.roles, {
          // filesPopup: this.filesPopup, this method will attach the files to an Estimate
          // setStatus: this.setStatus,
          termsCell: this.termsCell,
          sendEstimate: this.sendEstimate,
          // statusCell: this.statusCell,
          subtag: this.subActions
        })
      };
    },
    gridOrderDetails() {
      return {
        data: getGridOrderDetails(this.myEstimates),
        actions: myOrderActionsDetails(this.myEstimates, {
          filesPopup: this.filesPopup
        })
      };
    },
    gridOrderFinishings() {
      return {
        data: getGridMyEstimatesFinishings(this.myEstimates),
        actions: unclaimedOrderActionsFinishings(this.myEstimates, {
          filesPopup: this.filesPopup
        })
      };
    }
  },
  components: {
    grid,
    paginator,
    filterEstimate
  },
  created() {
    this.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  },
  mounted() {
    this.gridUpdateOrders(this.sortField, this.sortDir);
    pubnubService.subscribeUpdateEvent('order', this.orderUpdateHandler);
  },
  destroyed() {
    pubnubService.unsubscribeUpdateEvent('order', this.orderUpdateHandler);
  },
  methods: {
    ...mapActions({
      loadMyEstimates: LOAD_MY_ESTIMATES,
      updateMyEstimatesDesignProgress: UPDATE_MY_ESTIMATES_DESIGN_PROGRESS,
      updateMyEstimatesFinishingProgress: UPDATE_MY_ESTIMATES_FINISHING_PROGRESS,
      setMyEstimateIsApproved: SET_ESTIMATE_IS_APPROVED,
    }),
    onFilters(filters) {
      this.filters = cloneDeep(filters);
      this.page = 1;
      this.gridUpdateOrders(this.sortField, this.sortDir);
    },
    orderUpdateHandler(message) {
      if (message.order_id) {
        const updatedOrders = this.myEstimates.find(
          order => order.id === message.order_id
        );
        if (updatedOrders) {
          let filters = {
            url: url,
            perPage: perPage,
            sortField: sortField,
            sortDir: sortDir,
            page: this.myEstimatesPaginatorData.current_page
          };

          let params = Object.assign(filters, this.filters);
          this.loadMyEstimates(params);
        }
      }
    },
    loadMyEstimatesPaginated(page, url, perPage, sortField, sortDir) {
      this.perPage = perPage;
      this.page = page;
      this.url = url;

      let pagination_filter = {
        url: url,
        perPage: perPage,
        sortField: sortField,
        sortDir: sortDir,
        timezone: this.timezone
      };

      if (page) {
        pagination_filter.page = page;
      }

      let params = Object.assign(pagination_filter, this.filters);

      return this.loadMyEstimates(params);
    },

    // Update orders in store on orders sorting.
    gridUpdateOrders(sortField, sortDir) {
      // Update values in component to populate it for "loadUnclaimedMocksPaginated"
      this.sortField = sortField;
      this.sortDir = sortDir;

      let filters = {
        url: this.url,
        perPage: this.perPage,
        sortField: this.sortField,
        sortDir: this.sortDir,
        timezone: this.timezone
      };

      let params = Object.assign(filters, this.filters);
      this.loadMyEstimates(params);
    },
    setLostStatus(orderId) {
      popup({
        title: 'Lost Order Reason',
        additional: {
          orderId: orderId,
          status: 'close_lost_order',
          model: 'my_estimates'
        },
        bodyComponent: statusLostReasonPopupComponent,
        runCallback: true,
        showButtons: true
      });
    },

    setConvertToOrderStatus(orderId) {
      this.$store.dispatch(SET_ORDER_STATUS, {
        model: 'my_estimates',
        orderId,
        status: 'estimate_to_order',
        status_reason: {}
      });
    },

    approveEstimate(estimateId) {
      let estimate = this.myEstimates.find(e => e.id === estimateId);
      if (estimate) {
        apiService
          .approveEstimate(estimate.id)
          .then(data => {
            alertify.log(data.message);
            const key = 'my_estimates';
            this.setMyEstimateIsApproved({ estimateId, key})
          })
          .catch(err => {
            console.log('Error:', err);
          });
      }
    },

    /**
     * payment Cell
     */
    termsCell(estimate) {
      if (estimate.is_approved) {
        return PAYMENT_STATUSES[estimate.payment_status];
      }

      return {
        tag: 'select',
        options: this.termsOptions,
        value: estimate.payment_term,
        change: (orderId, terms) => {
          this.$store.dispatch(SET_ORDER_TERMS, {
            model: 'my_estimates',
            orderId,
            terms
          });
        }
      };
    },

    sendEstimateCell(estimate) {
      if (estimate.is_approved) {
        return '<span class="is-success">Approved</span>';
      }

      return {
        tag: 'button',
        str: estimate.estimate_sent_at ? 'Sent (Resend)' : 'Send Estimate',
        click: this.sendEstimate
      };
    },

    statusCell(estimate) {
      let options = {
        '': ' - Select - ',
        close_lost_order: 'Lost'
      };
      if (estimate.is_approved) {
        options.estimate_to_order = 'Convert to Order';
      }

      return {
        tag: 'button',
        options: options,
        value: '',
        click: this.setStatus
      };
    },

    sendEstimate(orderId) {
      const estimate = this.myEstimates.find(e => +e.id === orderId);
      if (!estimate) return false;

      if (estimate.shippings && estimate.shippings.length > 1) {
        this.$mx_confirmModal({
          title: 'Confirm send',
          text:
            'Estimate contains split shipping. Please ensure that everything is configured correctly',
          yesText: 'Send anyway',
          cancelText: 'Cancel'
        })
          .then(() => {
            this.$store.dispatch(SEND_ESTIMATE, orderId);
          })
          .catch(err => {
            return false;
          });
      } else {
        this.$store.dispatch(SEND_ESTIMATE, orderId);
      }
    },

    estimateDetails(id) {
      this.$router.push(`/estimates/${id}/details`);
    },
    isReadyForCsrApproval(estimate) {
      return (
        !estimate.is_approved &&
        !estimate.is_declined &&
        !estimate.is_revision_requested
      );
    },
    subActions(estimate) {
      let actions = [
        {
          tag: 'action-menu',
          str: 'Lost',
          status: 'close_lost_order',
          click: this.setLostStatus
        },
        {
          tag: 'action-menu',
          str: 'Copy Estimate',
          click: this.copyEstimate
        }
      ];
      if (this.isReadyForCsrApproval(estimate)) {
        actions.push({
          tag: 'action-menu',
          str: 'Approve',
          status: 'approve',
          click: this.approveEstimate
        });
      } else if (estimate.is_approved) {
        actions.push({
          tag: 'action-menu',
          str: 'Convert to Order',
          status: 'estimate_to_order',
          click: this.setConvertToOrderStatus
        });
      }
      return actions;
    },
    copyEstimate(estimateId) {
      this.$router.push({
        path: '/orders/create',
        query: {
          copy_from_id: estimateId
        }
      });
    }
  }
};
</script>
