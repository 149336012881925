<template>
  <div class="button-design">
    <div class="columns">
      <div class="column is-4">
        <form-select v-model="design.item.size"
          name="size"
          label="Size"
          :scope="getScope(designKey)"
          :options="{all: sizes}"
          :disabled="isDisabled" />
      </div>
      <div class="column is-4">
        <form-select v-model="design.item.quantity"
          name="quantity"
          label="Quantity"
          :scope="getScope(designKey)"
          :options="{all: quantities}" />
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import bus from '../../../bus';
import apiService from '../../../services/api.service';
import { isEmpty, cloneDeep, forEach, isNumber, merge } from 'lodash';

import formInput from '../../common/form/input';
import formSelect from '../../common/form/select';

let emptyDesignModel = {
  item: {
    size: '',
    quantity: ''
  }
};

export default {
  name: 'buttonPrinting',
  props: {
    getScope: {
      type: Function,
      default: () => () => ''
    },
    designCommon: {
      type: Object,
      default: () => {}
    },
    designSpecific: Object,
    designKey: Number,
    isEmbroidery: Boolean,
    upsServices: Array,
    deliveryTypes: Array,
    resetKey: Number,
    accountId: Number,
    index: Number
  },
  data() {
    return {
      type: 'button',
      sizes: [],
      quantities: [],
      summary: {},
      design: cloneDeep(
        Object.assign({}, emptyDesignModel, this.designSpecific)
      )
    };
  },
  components: {
    formInput,
    formSelect
  },
  computed: {
    isDisabled() {
      return this.designCommon.printed_before_order_id ||
        this.designCommon.printed_before_design_id
        ? 'disabled'
        : false;
    }
  },
  watch: {
    quantities: {
      handler(val, oldVal) {
        this.calculateSummary();
      },
      deep: false
    },
    designCommon: {
      handler(design, oldDesign) {
        this.calculateSummary();
      },
      deep: true
    },
    design: {
      handler(design, oldDesign) {
        this.calculateSummary();
      },
      deep: true
    },
    summary: {
      handler(summary, oldSummary) {
        this.$emit('summary-changed', this.designKey, summary);
      },
      deep: true
    },
    resetKey(key) {
      this.calculateSummary();

      if (key !== null && key !== undefined && key === this.designKey) {
        this.setEmpty();
      }
    }
  },

  methods: {
    /**
     * Calculate summary
     */
    calculateSummary() {
      let quantityOption = this.quantities.filter(
        qty => qty.key == this.design.item.quantity
      )[0];
      let sizeOption = this.sizes.filter(
        size => size.key == this.design.item.size
      )[0];
      let totalPrice = 0;
      let subTotalPrice = 0;
      let quantity = (quantityOption && parseInt(quantityOption.label)) || 0;
      let summary = {
        type: this.designCommon.service,
        name: this.designCommon.name,
        size: (sizeOption && sizeOption.label) || '',
        printed_before: this.designCommon.printed_before,
        printed_before_order_id: this.designCommon.printed_before_order_id,
        printed_before_design_id: this.designCommon.printed_before_design_id,
        reference_order_id: this.designCommon.reference_order_id,
        reference_design_id: this.designCommon.reference_design_id,
        comment: this.designCommon.comment,
        type_of_delivery: this.designCommon.type_of_delivery,
        ship_by_date: this.designCommon.ship_by_date,
        print_by_date: this.designCommon.print_by_date,
        in_hands_date: this.designCommon.in_hands_date
      };

      if (sizeOption && sizeOption.upcharges) {
        subTotalPrice =
          parseFloat(sizeOption.upcharges[this.design.item.quantity]) || 0;
      }

      // if (design.upcharge) {
      //   totalPrice += parseFloat(design.upcharge);
      // }

      totalPrice += subTotalPrice;

      if (totalPrice && quantity) {
        summary.total = {
          price: Math.round(totalPrice * 100) / 100,
          subtotal: subTotalPrice,
          qty: quantity,
          upcharge: Math.round(this.designCommon.upcharge * 100) / 100,
          upcharge_description: this.designCommon.upcharge_description
        };
      }

      this.$set(this, 'summary', summary);
    },

    getDesignData(user_id) {
      return apiService.getDesignFormOptions(this.type, user_id).then(
        data => {
          var sourceData = data.form_data;
          this.$set(this, 'quantities', sourceData.quantities);
          this.$set(this, 'sizes', sourceData.sizes);
        },
        response => {
          notify({
            message: 'Could not load info for the design',
            type: 'danger'
          });
        }
      );
    },

    onValidate(validateScope) {
      if (validateScope) {
        this.$validator.validateAll(validateScope).catch(err => {});
      } else {
        this.$validator.validateScopes().catch(err => {});
      }
    },

    onClear() {
      this.errors.clear();
    },

    setDesign() {
      return {
        key: this.designKey,
        design: this.design
      };
    },

    setEmpty() {
      this.$set(this, 'design', merge({}, emptyDesignModel));
    },

    onMountData(user_id) {
      return this.getDesignData(user_id).then(this.calculateSummary());
    }
  },
  created() {
    bus.$on('validate', this.onValidate);
    bus.$on('clear', this.onClear);
    this.$watch(
      () => this.errors.errors,
      value => {
        bus.$emit('errors-changed', value);
      }
    );
  },
  mounted() {
    if (isNumber(this.resetKey) && this.resetKey === this.designKey) {
      this.setEmpty();
    }

    this.onMountData(this.accountId);

    bus.$on('order-set-account-info', user_id => {
      this.onMountData(user_id);
    });
  },
  destroyed() {
    bus.$off('order-set-account-info');
  }
};
</script>
