<template>
  <div v-if="data" class="columns">
    <div class="column">
      <strong>
        {{ label }}:
        <span v-if="!editable">
          {{ data.width ? `${+data.width}"` : '*' }} X {{ data.height ? `${+data.height}"` : '*' }}
        </span>
      </strong>
      <div class="columns is-marginless is-paddingless" v-if="editable">
        <div class="column is-marginless is-paddingless" tooltip tooltip-ico="pensil" tooltip-position="left">
          <form-input v-model="data.width"
            :id="`tag-printing-width-${finishingKey}`"
            :scope="`form${finishingKey}`"
            type="number"
            rules="between:0.00,100|required"
            :name="`tag-printing-width-${finishingKey}`"
            step="0.01"
            min="0.00"
            max="100"
            :horizontal="true"
            :hideLabel="true"
            :label="'Width'"
            :placeholder="'*Width'"
          ></form-input>
        </div>
        <div class="column is-marginless is-paddingless">
          <form-input
            v-model="data.height"
            :id="`tag-printing-height-${finishingKey}`"
            :scope="`form${finishingKey}`"
            :name="`tag-printing-height-${finishingKey}`"
            type="number"
            rules="between:0.00,100|required"
            step="0.01"
            min="0.00"
            max="100"
            :horizontal="true"
            :hideLabel="true"
            :label="'Height'"
            :placeholder="'*Height'"
          ></form-input>
        </div>
      </div>
      <div class="columns is-marginless is-paddingless">
        <div class="column is-marginless is-paddingless">
          <div class="control">
            <strong class="label">Colors: {{numberOfColors}}</strong>
            <pms-color-multiselect
              :id="`tag-printing-pms-colors-${finishingKey}`"
              :scope="`pms_colors${finishingKey}`"
              tooltip
              tooltip-ico="pensil"
              tooltip-position="left"
              :showLabel="false"
              :required="true"
              :name="`tag-printing-pms-colors-${finishingKey}`"
              v-if="editable"
              v-model="data.colors"
            ></pms-color-multiselect>
            <span
              v-else-if="!editable && data.colors"
              v-for="(color, key) in data.colors"
              :key="`finishing-item-color-${key}`"
            >
              <span class="tag is-medium">
                <span class="color-box" :style="'background-color:#' + color.hex"> </span>
                <span>{{ color.name }}</span>
              </span>
            </span>
          </div>
          <strong>Tag Removal: </strong><span class="is-uppercase">{{tagRemoval}}</span>
          <br/>
          <div class="garments-details" v-if="groupedGarments">
            <div v-for="garment in groupedGarments">
              <div>
                <span class="has-text-weight-500">{{ garment.brand.join(', ') }}</span><br/>
                <span class="has-text-weight-500" v-if="!editable">{{`Material: ${garment.material}`}}<br/></span>
                <div class="column is-marginless is-paddingless" v-if="editable" >
                  <form-input
                    :id="`tag-printing-garment-material-${garment.id}`"
                    tooltip
                    tooltip-ico="pensil"
                    tooltip-position="left"
                    rules="max:1000"
                    :hideLabel="true"
                    :required="false"
                    :name="`tag-printing-garment-material-${garment.id}`"
                    :value="garment.material"
                    @input="setGarmentFabricOrOrigin({
                      'value': $event,
                      'finishing_index': garment.finishing_index,
                      'garment_id': garment.id,
                      'key': 'fabric'
                    })"
                    :placeholder="'Material'"
                  ></form-input>
                </div>
                <span class="has-text-weight-500" v-if="!editable">{{`COO: ${garment.coo ? garment.coo : 'N/A'}`}}<br/></span>
                <div class="column is-marginless is-paddingless"  v-if="editable">
                  <form-input
                    :id="`tag-printing-garment-coo-${garment.id}`"
                    tooltip
                    tooltip-ico="pensil"
                    tooltip-position="left"
                    rules="max:1000"
                    :hideLabel="true"
                    :required="false"
                    :name="`tag-printing-garment-coo-${garment.id}`"
                    :value="garment.coo"
                    @input="setGarmentFabricOrOrigin({
                      'value':$event,
                      'finishing_index':garment.finishing_index,
                      'garment_id':garment.id,
                      'key':'origin',
                    })"
                    :placeholder="'Country of origin'"
                  ></form-input>
                </div>
                <span class="has-text-weight-500">{{ garment.sizes && garment.sizes.join(', ') }}</span><br/>
              </div>
              <br/>
            </div>
          </div>
          <div v-if="!editable && data.location_comment" class="location-comment">
            <dt><strong>LOCATION NOTES: </strong></dt>
            <dd class="break-word content-pre" v-html="data.location_comment">
            </dd>
          </div>
          <div class="location-comment" v-else-if="editable">
            <div>
              <dl>
                <dt><strong>LOCATION NOTES:</strong></dt>
                <dd>
                  <wysiwyg :ref="`wysiwyg-tag-printing-location-comment-${finishingKey}`"
                           placeholder="*Enter note(s)"
                           @value-changed="onLocationCommentChanged"
                           :value="data.location_comment"></wysiwyg>
                </dd>
              </dl>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { cloneDeep, union, flatten } from 'lodash';
import FormInput from '../../../common/form/input';
import PmsColorMultiselect from '../../../common/form/pms-color-multiselect';
import FormTextarea from '../../../common/form/textarea';
import Wysiwyg from '../../../common/TextEditors/wysiwyg';
import {mapMutations} from "vuex";

export default {
  name: 'tag-printing',
  props: {
    finishing: {
      type: Object,
      required: true
    },
    finishingKey: {
      type: Number,
      required: true
    },
    label: {
      type: String,
      default: 'Tags'
    },
    design: {
      type: Object,
      required: true
    },
    options: {
      type: Object,
      required: true
    },
    editable: {
      type: Boolean,
      default: false
    }
  },
  components: { Wysiwyg, FormTextarea, PmsColorMultiselect, FormInput },
  data() {
    return {
      data: (this.finishing && cloneDeep(this.finishing)) || {},
      sortedSizes: []
    };
  },
  watch: {
    finishing(finishing) {
      let data = (finishing && cloneDeep(finishing)) || {};
      this.$set(this, 'data', data);
    }
  },
  computed: {
    numberOfColors() {
      return this.data.colors && this.data.colors.length;
    },
    tagRemoval() {
      return (
        (this.data &&
          this.data.finishable &&
          this.data.finishable.remove_manufacturer_tag &&
          'Yes') ||
        'No'
      );
    },
    groupedGarments() {
      const groupedGarments = {};
      const sizes = [];
      let garments = [];
      if (
        this.design &&
        this.design.designable &&
        this.design.designable.garments &&
        this.design.designable.finishings
      ) {
        this.design.designable.finishings.forEach((finishing, finishingKey)=> {
          if (finishing['finishing_option'] === 'tag-printing') {
            garments = flatten(
              finishing.items
                .filter(item => item.design_id === this.design.id)
                .map(item => {
                  return this.design.designable.garments.filter(
                    garment => garment.id === item.garment_id
                  ).map(d => {
                    return {
                      ...d,
                      fabric :item.fabric,
                      origin: item.origin,
                      finishing_index: finishingKey
                    };
                  });
                })
            );
          }
        });

        garments.forEach((garment, index) => {
          const fabric = (garment.fabric) || '';
          const brand = (garment.brand_object && garment.brand_object.name) || '';
          const number = (garment.product_object && garment.product_object.number) || '';
          const coo = (garment.origin) || '';
          if (fabric || fabric === '') {
            const garmentSizes = JSON.parse(garment.size_qty);
            Object.keys(garmentSizes).forEach(size => {
              if (!+garmentSizes[size]) {
                delete garmentSizes[size];
              }
            });

            const sizesArray = Object.keys(garmentSizes);

            const groupedGarmentsKey = coo ? `${fabric} - ${coo} - ${garment.color}` : `fabric - ${garment.color} - ${garment.product}`;
            if (!groupedGarments[groupedGarmentsKey]) {
              sizes[groupedGarmentsKey] = garmentSizes;
              groupedGarments[groupedGarmentsKey] = {
                sizes: sizesArray,
                brand: [`${brand} ${number} (${garment.color})`],
                material: fabric,
                coo: coo,
                id: garment.id,
                garment_index: index,
                finishing_index: garment.finishing_index,
              };
            } else {
              sizes[groupedGarmentsKey] = Object.assign(
                sizes[groupedGarmentsKey],
                garmentSizes
              );
              groupedGarments[groupedGarmentsKey].sizes = union(
                groupedGarments[groupedGarmentsKey].sizes,
                sizesArray
              );
              groupedGarments[groupedGarmentsKey].brand = union(
                groupedGarments[groupedGarmentsKey].brand,
                [`${brand} ${number}`]
              );
              groupedGarments[groupedGarmentsKey].material = fabric;
              groupedGarments[groupedGarmentsKey].coo = coo;
            }
            groupedGarments[groupedGarmentsKey].sizes = this.sortedSizes.filter(
              size =>
                groupedGarments[groupedGarmentsKey].sizes.includes(size) &&
                !!sizes[groupedGarmentsKey][size]
            );
          }
        });
      }
      return groupedGarments;
    }
  },
  methods: {
    ...mapMutations('revision', {
      setGarmentFabricOrOrigin: 'setGarmentFabricOrOrigin'
    }),
    getFormData() {
      this.data.location_comment = this.$refs[
        `wysiwyg-tag-printing-location-comment-${this.finishingKey}`
      ].getFormData();
      this.data.items = this.design.designable.finishings.filter(finishing => finishing.id === this.data.id)[0].items;

      return this.data;
    },
    onLocationCommentChanged(newValue){
      this.data.location_comment = newValue;
    },
  },
  mounted() {
    this.sortedSizes = cloneDeep(this.$store.getters.sortedGarmentsSizes);
  }
};
</script>
<style scoped>
.garments-details {
  margin-bottom: 10px;
  margin-top: 10px;
}
</style>
