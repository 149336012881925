<template>
    <div>
    <h3 class="title is-3">Rush Fee</h3>
        <fieldset>
            <div class="columns">
                <div class="column is-4">
                    <form-input name="rush_fee_amount" label="Amount Rush Fee"
                    v-model="rushFee.rush_fee_amount"
                    :scope="scope"
                    type="number" rules="min_value:0"
                    step="0.01" min="0.00"
                    @input="setRushFee"
                    :required="false"/>
                </div>
                <div class="column is-4">
                    <form-input name="rush_fee_percentage"
                      label="Percentage"
                      v-model="rushFee.rush_fee_percentage"
                      :scope="scope"
                      type="number" :rules="`min_value:0|max_value:100`"
                      step="0.01" min="0.00"
                      placeholder="0%"
                      @input="setRushFeePresentage"
                      :required="false"/>
                </div>
                <br>
                <div class="column is-8">
                    <form-input name="rush_fee_description"
                        label="Rush Fee"
                        v-model="rushFee.rush_fee_description"
                        :scope="scope"
                        placeholder="Enter description..."
                        rules="max:255"
                        @input="updateRushFee"
                        :required="false"/>
                </div>
            </div>
            <hr>
        </fieldset>
    </div>
</template>

<script>
import FormInput from '../../components/common/form/input';
import { cloneDeep, debounce } from 'lodash';

export default {
  name: 'rushfee',
  components: { FormInput },
  props: {
    scope: {
      type: String,
      default: 'form-scope'
    },
    modelRushFee: {
      type: Object,
      default: () => {}
    },
    totalPrice: {
      type: Number,
      default: 0
    },
    orderSummaryRushfee: {
      type: String | Number,
      default: 0
    }
  },
  data() {
    return {
      rushFee: cloneDeep(this.modelRushFee)
    };
  },
  watch: {
    modelRushFee: {
      handler: function(newModelRushFee) {
        this.$set(this, 'rushFee', cloneDeep(newModelRushFee));
      },
      deep: true
    }
  },
  computed: {
    oldTotalPrice() {
      return this.totalPrice === 'undefined'
        ? 0
        : this.totalPrice - +this.orderSummaryRushfee; // original price - (discount + rush_fee).
    }
  },
  methods: {
    updateRushFee() {
      this.$nextTick(() => {
        this.$emit(
          'updateRushFee',
          this.rushFee || {
            rush_fee_amount: 0,
            rush_fee_description: '',
            rush_fee_percentage: 0
          }
        );
      });
    },
    setRushFee(rushfeeAmount) {
      debounce(value => {
        //return % by Amount
        let rushFeePresentageByAmount =
          this.oldTotalPrice > 0
            ? +rushfeeAmount * 100 / +this.oldTotalPrice
            : 0;
        this.$set(
          this.rushFee,
          'rush_fee_percentage',
          Number(rushFeePresentageByAmount.toFixed(2) * 1) || 0
        );
        this.updateRushFee();
      }, 500)(rushfeeAmount);
    },

    setRushFeePresentage(value) {
      debounce(value => {
        //return amount by %
        let rushFeeAmountByPresentage =
          this.oldTotalPrice > 0 ? +value * +this.oldTotalPrice / 100 : 0;
        this.$set(
          this.rushFee,
          'rush_fee_amount',
          Number(rushFeeAmountByPresentage.toFixed(2) * 1) || 0
        );
        this.updateRushFee();
      }, 500)(value);
    }
  }
};
</script>
