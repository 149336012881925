<style>
.page-error .title {
    color: #999;
    font-weight: 100;
    font-family: 'Lato', Helvetica, sans-serif;
    font-size: 60px;
    text-align: center;
    padding-top: 15%;
}
.page-error .title a {
    display: inline-block;
    margin-top: 20px;
}
.page-error .title a:hover {
    text-decoration: none;
}</style>

<template>
    <div class="is-bold page-error">
        <div class="column title">
                Sorry, we couldn't find what you were looking for :-(<br>
                <router-link to="/">Go back to the dashboard</router-link>
        </div>
    </div>
</template>

<script>
    import Vue from 'vue';
    export default {
        name: 'error'
    };
</script>

