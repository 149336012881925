<template>
  <div>
    <header class="card-header">
      <p class="card-header-title">Finishings</p>
    </header>

    <div class="card-content">
      <table class="table table__without-hover">
        <thead>
          <tr>
            <th>Item</th>
            <th>Cost</th>
            <th>Qty</th>
            <th class="align-right">Total</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="finishing in finishingsTableData">
            <td>{{finishing.label}}</td>
            <td>{{(finishing.price / finishing.count) | price}}</td>
            <td>{{finishing.count | itemsNumberFormat}}</td>
            <td class="align-right">{{finishing.price | price}}</td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <th colspan="3" class="align-left">Subtotal</th>
            <td class="align-right">{{finishingSubtotal | price}}</td>
          </tr>
        </tfoot>
      </table>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'approve-summary-finishings',
    props: ['design'],
    computed: {
      finishingsTableData() {
        return this.design.finishings.reduce((allFinish, finish) => {
          if (allFinish[finish.finishing_option]) {
            allFinish[finish.finishing_option].price += finish.price;
            allFinish[finish.finishing_option].count += 1;
          } else {
            allFinish[finish.finishing_option] = Object.assign({count: 1}, finish);
          }
          return allFinish;
        }, {});
      },
      finishingSubtotal() {
        const tableData = this.finishingsTableData;
        return Object.keys(tableData).reduce((sub, key) => {
          return sub += tableData[key].price;
        }, 0);
      }
    }
  }
</script>
