<template>
  <div class="page-content">
    <section class="clients-report">
      <div class="progress" style="margin-top: 10px; margin-bottom: 10px">
        <div class="determinate" :style='`width: ${progress}%`'></div>
      </div>
      <div class="row" v-if="message">
        <div class="col s6 offset-s3 m6 offset-m3">
          <div class="card-panel">
            <h5 class="center-align">{ message != null ? message : ''}</h5>
          </div>
        </div>
      </div>
    </section>
    <button @click="run()" :disabled="inProgress" class="button is-pulled-right">
      Export Clients Report
    </button>
  </div>
</template>
<script>
import apiService from '../../services/api.service';
import alertify from 'alertify.js';
import {downloadReport} from '@/helpers/reports/reports';

export default {
  name: 'clients-report',
  data() {
    return {
      cancel: false,
      inProgress: false,
      message: '',
      filters: {
        page: 1,
        per_page: 150
      },
      data: [],
      json_fields: [
        {
          label: 'Company Name',
          value: 'company'
        },
        {
          label: 'Customer First Name',
          value: 'first_name'
        },
        {
          label: 'Customer Last Name',
          value: 'last_name'
        },
        {
          label: 'Customer Email Address',
          value: 'email'
        },
        {
          label: 'Number of created orders',
          value: 'orders'
        }
      ],
      progress: 0,
      downloadName: 'clients.csv'
    };
  },
  computed: {},
  methods: {
    run() {
      if (!this.cancel) {
        this.$set(this, 'inProgress', true);
        apiService.getReport('clients', this.filters)
          .then(res => {
            this.$set(this, 'progress', +res.to / +res.total * 100);
            if (res.data.length) {
              this.$set(this, 'data', [...this.data, ...res.data]);
            }
            if (+res.to >= +res.total) {
              this.$set(this, 'inProgress', false);
              this.download();
            } else {
              this.$set(this.filters, 'page', res.current_page + 1);
              this.run();
            }
          })
          .catch(err => {
            console.log(err);
            this.$set(this.filters, 'page', 1);
            this.$set(this, 'inProgress', false);
            alertify.error(err.body.message);
          });
      }
    },
    download() {
      downloadReport(this.data, this.json_fields, this.downloadName);
      this.$set(this.filters, 'page', 1);
      this.$set(this, 'progress', 0);
      this.$set(this, 'data', []);
    }
  },
  mounted() {
  },
  beforeDestroy() {
    this.$set(this, 'cancel', true);
  }
};
</script>
<style scoped>
.progress {
  background: aliceblue;
}

.determinate {
  background: #1992d1;
  height: 12px;
}
</style>
