<template>
  <div class="version-switcher mr-4" v-if="sortedMocks.length">
    <div class="select is-fullwidth control is-small">
      <select v-model="activeVersion" @change="setActiveVersion">
        <option
          v-for="mockVer in sortedMocks"
          :key="`mock-version-${mockVer.id}`"
          :value="mockVer.id"
        >{{ mockVer.id === 'new' && canCreateMock ? 'New mock' : `Version ${mockVer.version}` }}
        </option>
      </select>
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import {checkRoles} from '../../../router/checkRoles';
import store from '../../../store';
import {canCreateMock} from '../../../helpers/role-access';

export default {
  name: 'version-switcher',
  props: {
    editable: {
      type: Boolean,
      default: false
    }
  },
  data() {
    const isNew = this.$route.params.mockId === 'new';
    return {
      activeVersion: isNew ? 'new' : +this.$route.params.mockId
    };
  },
  computed: {
    ...mapGetters('revision', ['order', 'getActiveMock', 'currentDesign']),
    sortedMocks() {
      if (!this.currentDesign.mocks) {
        return this.isNew && this.canCreateMock ? [{id: 'new'}] : [];
      }
      const mocks = this.currentDesign.mocks
        .slice()
        .sort((a, b) => a.version - b.version);
      if (this.canCreateMock) {
        mocks.push({id: 'new'});
      }
      return mocks;
    },
    isNew() {
      return this.$route.params.mockId === 'new';
    },
    canCreateMock() {
      return checkRoles(store.getters.roles, canCreateMock, true);
    }
  },
  watch: {
    getActiveMock(activeVersion) {
      if (!this.isNew) {
        this.$set(this, 'activeVersion', activeVersion);
      } else {
        this.$set(this, 'activeVersion', 'new');
      }
    },
    $route(to, from) {
      if (this.isNew) {
        this.$set(this, 'activeVersion', 'new');
      }
    }
  },
  methods: {
    setActiveVersion() {
      if (this.activeVersion) {
        this.$router.push({
          name: 'Revision',
          params: {
            id: this.order.id,
            designId: this.currentDesign.id,
            mockId: this.activeVersion
          }
        });
      }
    }
  }
};
</script>
<style lang="scss">
.version-switcher {
  select {
    font-weight: bold;
    background-color: lightgray;
    color: grey;
  }
}
</style>
