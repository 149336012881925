<template>
  <div :class="{'is-horizontal control': horizontal}">
    <template v-if="!hideLabel">
      <div v-if="horizontal" class="control-label">
        <label class="label" :for="scope + '-' + name">
          <span v-if="required" class="required">*</span>
          {{getLabel}}
        </label>
      </div>
      <div v-else>
        <label class="label" :for="scope + '-' + name">
          <span v-if="required" class="required">*</span>
          {{getLabel}}
        </label>
      </div>
    </template>
    <div class="control">
      <div class="select is-fullwidth">
        <select v-model="fieldValue"
          v-validate="{ rules: rules, scope: scope }"
          :data-vv-name="name"
          :data-vv-as="getLabel"
          :class="{'input': true, 'is-danger': errors.has(scope + '.' + name)}"
          :id="scope + '-' + name"
          :disabled="disabled"
          @change="onChange"
          @input="$emit('input', $event.target.value)">
          <option v-if="addEmpty" value="">Select One</option>
          <template v-if="isHierarchical">
            <optgroup  v-for="(group, groupLabel) in options.all"
              :label="groupLabel">
              <option v-for="(label, key) in group" :value="key" >{{label}}</option>
            </optgroup>
          </template>
          <template v-else>
            <option v-if="options.primary"
              v-for="option in options.primary"
              :value="option.key">{{option.label}}</option>
            <option v-if="options.primary" disabled="">-------------</option>
            <option v-for="option in options.all"
              :value="option.key" :disabled="!option.key">{{option.label}}</option>
          </template>
        </select>
      </div>
      <span v-show="errors.has(scope + '.' + name)"
        class="help is-danger is-visible error-msg">
        {{errors.first(scope + '.' + name)}}
      </span>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.is-horizontal {
  .control {
    flex-direction: column;
  }

  .error-msg {
    padding: 3px 0 !important;
  }
}
select option:disabled {
  background-color: #dbdbdb;
  color: #000;
  font-weight: bold;
}
</style>

<script>
import Vue from 'vue';
import bus from '../../../bus';
import alertify from 'alertify.js';

export default {
  name: 'form-select',
  data() {
    return {
      fieldValue: this.value,
      prevValue: this.value
    };
  },
  watch: {
    value(value) {
      this.fieldValue = value;
      this.$validator.validate(this.name, this.value, this.scope);
    }
  },
  props: {
    hideLabel: {
      type: Boolean,
      default: false
    },
    name: {},
    isHierarchical: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    addEmpty: {
      type: Boolean,
      default: true
    },
    label: {
      type: String,
      default: ''
    },
    value: {},
    required: {
      type: Boolean,
      default: true
    },
    rules: {
      type: String,
      default: 'required|max:255'
    },
    scope: {
      type: String,
      default: 'form-scope'
    },
    options: {
      type: Object,
      default: {
        all: []
      }
    },
    horizontal: {
      type: Boolean,
      default: false
    },
    fieldsetName: {
      type: String
    }
  },
  methods: {
    onChange(e) {
      if (this.fieldValue != this.prevValue) {
        this.$emit('change', e.target.value);
        this.prevValue = e.target.value;
      }
    },

    onValidate(validateScope) {
      if (validateScope) {
        this.$validator.validateAll(validateScope).catch(err => {});
      } else {
        this.$validator.validateScopes().catch(err => {});
      }
    },

    onClear() {
      this.errors.clear(this.scope);
    },

    addError(e) {
      if (
        e.field === this.name &&
        e.scope === this.scope &&
        (!this.fieldsetName ||
          e.fullName === `${this.fieldsetName}.${this.name}`)
      ) {
        this.errors.add(
          e.field,
          e.msg.replace(e.fullName, this.getLabel),
          e.rule,
          e.scope
        );
        alertify.error(e.msg.replace(e.fullName, this.getLabel));
      }
    }
  },
  created() {
    bus.$on('add-error', this.addError);
    bus.$on('validate', this.onValidate);
    bus.$on('clear', this.onClear);
    this.$watch(
      () => this.errors.errors,
      (value, oldValue) => {
        if (value.length) {
          bus.$emit('errors-changed', value);
        } else {
          bus.$emit('errors-deleted', oldValue);
        }
      }
    );
  },
  beforeDestroy() {
    bus.$emit('errors-deleted', this.errors.errors);
    bus.$off('validate', this.onValidate);
    bus.$off('clear', this.onClear);
    bus.$off('add-error', this.addError);
  },
  computed: {
    getLabel() {
      //capitalize name
      return this.label
        ? this.label
        : this.name.charAt(0).toUpperCase() + this.name.slice(1);
    }
  }
};
</script>
