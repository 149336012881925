<template>
  <div class="design-type">
    <section v-for="(design, key) in designs"  :key="key + (design.common.id || '') + (design.common.uniqueKey || '')">
      <div class="controls" v-if="!isDesignSelected(key)">
        <button type="button"
          class="button"
          @click="initNewDesign(key)">Add new design</button>
        <button :disabled="!accountId"
          type="button"
          class="button"
          @click="reprintDesignsPopup(key, 'reprint')">Reprint design</button>
        <button :disabled="!accountId"
          type="button"
          class="button"
          @click="reprintDesignsPopup(key, 'reference')">Reference design</button>
      </div>
      <div v-else>
        <div class="columns">
          <div class="column is-4">
            <form-select v-model="design.common.service"
              name="service"
              @change="onChangeServiceType(design.common.service, key)"
              :scope="formScope"
              :addEmpty="false"
              :options="{all: services}"
              :disabled="!!(design.common.printed_before_order_id)"/>
          </div>
        </div>
        <h2 v-if="!isBlankSamples(design, key)" class="title is-2">Design {{key + 1}}</h2>
        <h2 v-else class="title is-2"> {{ design.common.name ||  'Design 1' }}</h2>

        <div class="columns">
          <div class="column is-4">
            <form-select v-model="design.common.type_of_delivery"
                         name="type_of_delivery"
                         @change="onChangeTypeOfDelivery()"
                         label="Delivery type"
                         :scope="getScope(key)"
                         :options="{all: deliveryTypes}"
                         :addEmpty="false" />
          </div>
          <div v-if="design.common.type_of_delivery == 'shipping'" class="column is-4">
            <form-select v-model="design.common.shipping_method"
                         name="shipping_method"
                         label="Shipping method"
                         :scope="getScope(key)"
                         :options="{all: upsServices}"
                         :addEmpty="false" />
          </div>
          <div class="column is-3">
            <label class="label" :for="'event-date' + key">In Hands Date</label>
            <div class="control">
              <datepicker v-model="design.common.in_hands_date"
                          @input="resetShipByAndPrintByDates(key)"
                          :config="{minDate: 'today', dateFormat: 'm/d/y', defaultDate: design.common.in_hands_date, wrap: true }"
                          :id="'event-date' + key">
                <a class="button" data-toggle><i class="icon icon__bigger-1 ff-calendar"></i></a>
                <a class="button" data-clear><i class="icon icon__bigger-1 ff-cross-mark"></i></a>
              </datepicker>
            </div>
            <label class="label"
                   :for="'ship-by-date' + key"
                   v-if="design.common.in_hands_date">Ship By Date</label>
            <div class="control" v-if="design.common.in_hands_date">
              <datepicker v-model="design.common.ship_by_date"
                          @input="resetPrintByDate(key)"
                          :config="{minDate: 'today', maxDate: design.common.in_hands_date, dateFormat: 'm/d/y', defaultDate: design.common.ship_by_date, wrap: true }"
                          :id="'ship-by-date' + key">
                <a class="button" data-toggle><i class="icon icon__bigger-1 ff-calendar"></i></a>
                <a class="button" data-clear><i class="icon icon__bigger-1 ff-cross-mark"></i></a>
              </datepicker>
            </div>
            <label class="label"
                   :for="'print-by-date' + key"
                   v-if="design.common.in_hands_date">Print By Date</label>
            <div class="control" v-if="design.common.in_hands_date">
              <datepicker v-model="design.common.print_by_date"
                          :config="{minDate: 'today', maxDate: design.common.ship_by_date || design.common.in_hands_date, dateFormat: 'm/d/y', defaultDate: design.common.print_by_date, wrap: true }"
                          :id="'print-by-date' + key">
                <a class="button" data-toggle><i class="icon icon__bigger-1 ff-calendar"></i></a>
                <a class="button" data-clear><i class="icon icon__bigger-1 ff-cross-mark"></i></a>
              </datepicker>
            </div>
          </div>
        </div>
        <div class="columns">
          <div class="column is-4" v-if="!isBlankSamples(design, key)">
            <form-input v-model="design.common.name"
                        name="name"
                        :rules="'required|max:255'"
                        :scope="getScope(key)"
                        label="Design Name" />
          </div>
          <div class="column is-5 flex flex__vertical-center" v-if="!isBlankSamples(design, key)">
            <div class="control form-checkbox-container">
              <label :for="'printed-before' + key ">
                <input :disabled="!!(design.common.printed_before_order_id)"
                       v-model="design.common.printed_before"
                       :id="'printed-before' + key"
                       type="checkbox"
                       class="checkbox">
                Design has been printed before
              </label>
            </div>
          </div>
        </div>
        <component :is="getComponentNameByDesign(design.common)"
          :account="account"
          :designCommon="design.common"
          :designSpecific="design.specific"
          :finishings="finishings"
          :designKey="key"
          :upsServices="upsServices"
          :deliveryTypes="deliveryTypes"
          :resetKey="designToReset"
          :finishingOptions="finishingOptions"
          :accountId="accountId"
          :index="key"
          :getScope="getScope"
          :isEdit="isEdit"
          ref="design"
          @summary-changed="onSummaryChanged"
          @get-package-qty="onGetPackageQty"
        ></component>

        <div class="columns" v-if="design.common.service != 'other' && !isBlankSamples(design, key)">
          <div class="column is-4">
            <form-input v-model="design.common.upcharge"
                        :required="false"
                        type="number"
                        rules="min_value:0"
                        step="0.01"
                        min="0.00"
                        name="upcharge"
                        :scope="getScope(key)"
                        :label="upchargeInputLabel(key)"/>
          </div>
          <div class="column is-8" v-if="design.common.upcharge > 0 && !isBlankSamples(design, key)">
            <form-input v-model="design.common.upcharge_description"
                        :required="false"
                        rules=""
                        name="upcharge_description"
                        :scope="getScope(key)"
                        label="Upcharge description"/>
          </div>
        </div>
        <div class="columns" v-if="!isBlankSamples(design, key)">
          <div class="column">
            <label class="label" :for="'comment' + key">
              Comment
            </label>
            <div class="control">
              <textarea v-model="design.common.comment"
                    class="textarea"
                    :id="'comment' + key"></textarea>
            </div>
          </div>
        </div>
      </div>
      <div v-if="designs.length > 1" class="control">
        <br>
        <a @click="dropDesign(key)" class="button is-danger">
          <span>Delete Design</span>
          <i class="ff-cross-mark"></i>
        </a>
        <hr>
      </div>
    </section>

    <div class="control submit-row" v-if="showAddDesignAreaButton">
      <br>
      <button @click="addDesign()"
        type="button"
        class="button is-medium">Add Design</button>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import { mapGetters, mapActions } from 'vuex';
import { cloneDeep } from 'lodash';
import { LOAD_UPS_SERVICES } from '../../store/action-types';
import formSelect from '../common/form/select';
import formInput from '../common/form/input';
import apparelDesign from './design-types/apparel';
import * as blankDesign from './design-types/apparel';
import embroideryDesign from './design-types/embroidery';
import digitalDesign from './design-types/digital';
import finishingDesign from './design-types/finishing-creation';
import stickerDesign from './design-types/sticker';
import posterDesign from './design-types/poster';
import buttonDesign from './design-types/button';
import otherDesign from './design-types/other';
import apiService from '../../services/api.service';
import popup from '../../modules/popupper';
import Datepicker from '../../modules/datepicker/index';
import bus from '../../bus';
import moment from 'moment';
import FormTextarea from '../common/form/textarea';
import ReprintDesignsListComponent from './popups/reprint-designs-list.vue';

let emptyDesign = {
  common: {
    id: '',
    service: 'apparel',
    in_hands_date: null,
    shipping_method: '03',
    type_of_delivery: 'shipping',
    name: '',
    printed_before: false,
    upcharge: 0,
    upcharge_description: '',
    comment: '',
    uniqueKey: '',
    ship_by_date: null,
    print_by_date: null
  },
  specific: {}
};

export default {
  name: 'designs',
  props: [
    'designModels',
    'account',
    'summary',
    'formScope',
    'finishings',
    'finishingOptions',
    'accountId',
    'isEdit'
  ],
  data() {
    return {
      designs: cloneDeep(this.designModels),
      processing: false,
      designToReset: null,
      altSummary: []
    };
  },
  watch: {
    designModels() {
      this.designs = cloneDeep(this.designModels);
    }
  },
  computed: {
    ...mapGetters({
      services: 'orderServices',
      deliveryTypes: 'deliveryTypes',
      upsServices: 'upsServices'
    }),
    showAddDesignAreaButton() {
      return this.designs.length > 0 && this.designs[0].common.service;
    }
  },
  /**
   * Register components for design types
   */
  components: {
    FormTextarea,
    apparelDesign,
    digitalDesign,
    finishingDesign,
    stickerDesign,
    posterDesign,
    otherDesign,
    buttonDesign,
    embroideryDesign,
    formSelect,
    formInput,
    Datepicker,
    blankDesign
  },
  methods: {
    ...mapActions({
      getUPSServices: LOAD_UPS_SERVICES
    }),
    upchargeInputLabel(i) {
      return 'Upcharge for Design #' + (parseInt(i) + 1);
    },
    getScope(designKey, section, key) {
      return (
        'designs-' + designKey + (section ? '-' + section + '-' + key : '')
      );
    },
    showShippingNotice(commonDesign) {
      return (
        commonDesign.service !== 'apparel' &&
        commonDesign.type_of_delivery == 'shipping'
      );
    },
    isDesignSelected(key) {
      return !!(
        this.designs[key] !== undefined && this.designs[key].common.service
      );
    },

    initNewDesign(key) {
      let newDesign = cloneDeep(emptyDesign);
      newDesign.common.uniqueKey = +new Date();
      this.$set(this.designs, key, newDesign);
    },

    reprintDesignsPopup(designKey, reprint_type) {
      popup({
        title: 'Select designs for new order',
        bodyComponent: ReprintDesignsListComponent,
        modalCardLong: true,
        showButtons: false,
        additional: {
          callback: reprint => {
            reprint.common.uniqueKey = +new Date();
            this.$set(this.designs, designKey, reprint);

            this.$nextTick(() => {
              this.$emit('get-package-qty', false);

              if (reprint.finishings.length > 0) {
                reprint.finishings.forEach(finishing => {
                  console.log(finishing);
                  finishing.related_design.push(designKey);
                  this.finishings.push(finishing);
                });
              }
            });
          },
          userId: this.accountId,
          reprint_type: reprint_type
        }
      });
    },

    addDesign() {
      let designs = _.cloneDeep(this.designs);
      designs.push({
        common: {},
        specific: {}
      });

      this.$set(this, 'designs', designs);
    },

    dropDesign(key) {
      this.$emit('drop-design', key);
      this.onSummaryChanged(key, null);

      this.$nextTick(() => {
        this.$emit('get-package-qty', false);
      });
    },

    isEmbroidery(d) {
      return d && d.service === 'embroidery';
    },

    getComponentNameByDesign(d) {
      return d && d.service + '-design';
    },

    onChangeServiceType(service, key) {
      // After design type has been changed the designId must be reseted for the backend purpose
      this.designToReset = key;
      this.onSummaryChanged(key, null);
      this.$emit('get-package-qty', false);
      this.$nextTick(() => {
        bus.$emit('clear');
      });
    },

    onChangeTypeOfDelivery() {
      this.$emit('get-package-qty', false);
    },

    setDesigns() {
      return new Promise((resolve, reject) => {
        if (this.$refs.design && this.$refs.design.length) {
          this.$nextTick(() => {
            this.$refs.design.forEach(design => {
              let data = design.setDesign();
              this.$set(this.designs[data.key], 'specific', data.design);
            });

            resolve(this.designs);
          });
        } else {
          reject(new Error('Please add at least one design.'));
        }
      });
    },

    onSummaryChanged(designKey, summary) {
      this.$emit('summary-changed', designKey, summary);
    },
    onGetPackageQty(debounced) {
      this.$emit('get-package-qty', debounced);
    },
    resetShipByAndPrintByDates(design_key) {
      if (
        this.designs &&
        this.designs[design_key] &&
        this.designs[design_key].common
      ) {
        let in_hands_date = cloneDeep(
          this.designs[design_key].common.in_hands_date
        );
        let ship_by_date = cloneDeep(
          this.designs[design_key].common.ship_by_date
        );
        let print_by_date = cloneDeep(
          this.designs[design_key].common.print_by_date
        );
        if (
          !in_hands_date ||
          moment(in_hands_date).isBefore(moment(ship_by_date))
        ) {
          this.$set(this.designs[design_key].common, 'ship_by_date', null);
        }
        if (
          !in_hands_date ||
          moment(in_hands_date).isBefore(moment(print_by_date))
        ) {
          this.$nextTick(() => this.resetPrintByDate(design_key, true));
        }
      }
    },
    resetPrintByDate(design_key, skip_ship_by_date_check) {
      if (
        this.designs &&
        this.designs[design_key] &&
        this.designs[design_key].common
      ) {
        if (!skip_ship_by_date_check) {
          let ship_by_date = cloneDeep(
            this.designs[design_key].common.ship_by_date
          );
          let print_by_date = cloneDeep(
            this.designs[design_key].common.print_by_date
          );
          if (moment(ship_by_date).isBefore(moment(print_by_date))) {
            this.$set(this.designs[design_key].common, 'print_by_date', null);
          }
        } else {
          this.$set(this.designs[design_key].common, 'print_by_date', null);
        }
      }
    },
    isBlankSamples(design, design_key) {
      if (design.common.service === 'blank') {
        this.designs[design_key].common.name = 'Blank Samples';
        return true;
      }
      return false;
    }
  },
  mounted() {
    this.getUPSServices();
  }
};
</script>
