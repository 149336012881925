import { Parser as Json2csvParser } from '@json2csv/plainjs';

export const downloadReport = (data, fields, filename) => {
  const json2csvParser = new Json2csvParser({fields});
  const csv = json2csvParser.parse(data);
  let blob = new Blob([csv], {type: 'text/csv'});
  let url = window.URL.createObjectURL(blob);

  const a = document.createElement('a');
  a.style = 'display: none';
  document.body.appendChild(a);

  a.href = url;
  a.download = filename;
  a.click();

  window.URL.revokeObjectURL(url);
};
