<template>
  <div>
    <div v-if="messages.length > 0">
      <h1 class="text-center"><a href="/"><img src="@/../images/logo.png" width="400" height="110" alt="Threadbird"></a>
      </h1>

      <div v-for="message in messages" :class="message.type" role="alert">
        {{ message.message }}
      </div>
    </div>
  </div>
</template>

<script>
import { LOGIN_USER, POST_REGISTER_LOGIN_USER } from '../../store/action-types';
import CreatePasswordForm from './create-password';

export default {
  name: 'post-register',
  data() {
    return {
      messages: [],
      loggingIn: false
    };
  },
  mounted() {
    this.getUser();
  },
  methods: {
    getUser: function() {
      this.messages = [];
      this.loggingIn = true;

      this.$store
        .dispatch(
          POST_REGISTER_LOGIN_USER,
          this.$route.params.verificationToken
        )
        .then(user => {
          if ((user && user.link_expired == true) || !user) {
            this.messages.push({
              type: 'danger',
              message: 'Sorry, your access link expired'
            });
          } else {
              this.$router.push('/accounts/' + user.id + '/create-password')
          }
        })
        .catch(response => {
          this.messages = [];
          if (response.status && response.status === 401) {
            this.messages.push({
              type: 'danger',
              message: 'Sorry, your access link expired'
            });
          }
          this.loggingIn = false;
        });
    }
  }
};
</script>
