<template>
  <div class="invoice__field">
    <label class="invoice__field__label" v-if="label !== undefined">{{ label.length ? label : '&nbsp;' }}</label>
    <div class="invoice__field__value" v-if="value !== undefined">
      <template v-if="!multiline">{{ value }}</template>
      <template v-else>
        <p v-for="line, index in value" :key="index">{{ line }}</p>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: 'invoice-field',
  props: {
    label: {
      type: String|undefined,
    },
    value: {
      type: String|undefined,
    },
    multiline: {
      type: Boolean,
      default: false
    }
  }
};
</script>
