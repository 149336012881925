<template>
  <div class="design-comment widget has-separator">
    <div v-if="!editable && design_comment">
      <dl>
        <dt><strong>DESIGN NOTES:</strong></dt>
        <dd class="break-word content-pre" v-html="design_comment"></dd>
      </dl>
    </div>
    <div v-else-if="editable">
      <dl>
        <dt><strong>DESIGN NOTES:</strong></dt>
        <dd>
          <wysiwyg ref="wysiwyg"
                   placeholder="*Enter note(s)"
                   @value-changed="onDesignNotesChanged"
                   :value="design_comment"></wysiwyg>
        </dd>
      </dl>
    </div>
  </div>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep';
import Wysiwyg from '../../common/TextEditors/wysiwyg';

export default {
  name: 'design-comment',
  components: { Wysiwyg },
  props: {
    design: {
      type: Object,
      required: true
    },
    editable: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      design_comment:
        (this.design &&
          this.design.comment &&
          cloneDeep(this.design.comment)) ||
        ''
    };
  },
  watch: {
    design(design) {
      let design_comment = (design && cloneDeep(design.comment)) || '';
      this.$set(this, 'design_comment', design_comment);
    }
  },
  computed: {},
  methods: {
    getFormData() {
      return this.$refs.wysiwyg.getFormData();
    },
    onDesignNotesChanged(newValue){
      this.design_comment = newValue;
    },
  }
};
</script>
