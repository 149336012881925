<template>
  <div class="order-files">
    <nav class="level">
      <!-- Left side -->
      <breadcrumbs :links="crumbLinks"/>
    </nav>
    <nav class="panel" v-for="design in orderDetailsFiles">
      <p class="panel-heading">
        {{design.name}}
      </p>
      <div class="panel-block">
        <div>
          <div class="content-row">
            <h4 class="title is-4" v-if="design.attachments.length">Files</h4>

            <manage-files-box
              :designId="design.id"
              :attachments="design.attachments"
              @onSubmitFiles="onSubmitFiles"></manage-files-box>
          </div>
          <div class="content-row">
            <h4 class="title is-4 has-text-centered" v-if="!design.mocks.length">There are no mocks.</h4>
            <div v-else>
              <h4 class="title is-4">Mocks</h4>
              <table class="table is-striped table__without-hover">
                <tr>
                  <th>#V</th>
                  <th>Files</th>
                  <th>Location</th>
                  <th v-if="!isEmbroidery(design)">PMS Colors</th>
                  <th v-else>Colors</th>
                  <th>Width</th>
                  <th>Height</th>
                  <th></th>
                </tr>
                <tr v-for="mock in getSortedMocks(design.mocks)">
                  <td>{{mock.version}}</td>
                  <td class="thumbnails-list">
                    <a v-if="s3Domain" v-for="(attachment, key) in mock.attachments"
                      class="is-inline-block gallery-thumb box image is-96x96"
                      target="_blank"
                      :href="s3Domain + attachment.filepath">
                      <img v-if="isThumbable(attachment.filepath)"
                        :src="s3Domain + attachment.filepath"/>
                      <div v-else
                        :class="['file-thumb', 'thumb-type-' + fileExt(attachment.filepath)]">
                        <span>{{ fileExt(attachment.filepath) }}</span>
                      </div>
                    </a>
                  </td>
                  <td class="is-nowrap">
                    <div v-for="item in mock.items">
                      {{item.location}}
                    </div>
                  </td>
                  <td class="">
                    <div v-if="!isEmbroidery(design)" v-for="item in mock.items">
                      <span v-for="(color, key) in item.colors">
                        <span class="tag is-medium">
                          <span class="color-box" :style="'background-color:#' + color.hex"> </span>
                          <span>{{color.name}}</span>
                        </span>
                      </span>
                    </div>
                    <div v-else>
                      {{item.thread_colors}}
                    </div>
                  </td>
                  <td>
                    <div v-for="item in mock.items">
                      {{item.width}}
                    </div>
                  </td>
                  <td>
                    <div v-for="item in mock.items">
                      {{item.height}}
                    </div>
                  </td>
                  <td class="has-text-right">
                    <router-link class="button is-small"
                      :to="'/revision/' + id + '/' + design.id + '/' + mock.id">
                      View
                    </router-link>
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>
    </nav>
  </div>
</template>

<style scoped>
  .content-row {
    margin: 15px 0;
  }
  .image.is-inline-block{
    display: inline-block;
    margin: 2px;
  }
  .is-nowrap{
    white-space: nowrap;
  }
</style>

<script>
  import Vue from 'vue';
  import {mapGetters} from 'vuex';

  import apiService from '../../../services/api.service';
  import ManageFilesBox from '../../dashboard/manage-files-box';;
  import breadcrumbs from './../../common/breadcrumbs';
  import {isThumbable, fileExt} from '../../../helpers/files';

  export default {
    name: 'order-files',
    data() {
      return {
        id: this.$route.params.id,
        orderDetailsFiles: []
      };
    },
    computed: {
      ...mapGetters({
        s3Domain: 's3Domain'
      }),
      crumbLinks() {
        return [
          {url: '/orders', label: 'Orders'},
          {url: '/orders/' + this.id, label: '#' + this.id},
          {label: 'Files'},
        ];
      }
    },
    components: {
      ManageFilesBox,
      breadcrumbs,
    },
    methods: {
      getSortedMocks(mocks) {
        return mocks.slice().sort((a, b) => a.version - b.version);
      },

      isEmbroidery(d) {
        return d.service === 'embroidery';
      },

      fileExt: fileExt,

      isThumbable: isThumbable,

      getFiles(orderId) {
        return apiService.getOrderFiles(orderId).then(data => {
          this.orderDetailsFiles = data;
        });
      },

      onSubmitFiles({designId, files}) {
        if (files.length) {
          this.orderDetailsFiles
            .filter(design => design.id === designId)
            .forEach(design => {
              this.$set(design, 'attachments', design.attachments.concat(files));
            });
        }
      }
    },
    mounted() {
      this.getFiles(this.id);
    }
  };
</script>
