<template>
  <div class="clearfix">
    <div class="columns orders-filters">
      <div class="column">
        <form-select
          :required="false"
          rules
          :addEmpty="false"
          label="Order status"
          name="status"
          :options="{all: filtersOptions.order_statuses}"
          v-model="filters.status"
        />
      </div>
      <div class="column">
        <form-select
          :required="false"
          rules
          label="Phase status"
          name="phase_status"
          :options="{all: filtersOptions.phases}"
          v-model="filters.phase_status"
        />
      </div>
      <div class="column">
        <form-select
          :required="false"
          rules
          label="Printer"
          name="printer"
          :options="{all: sortedPrinters}"
          v-model="filters.printer"
        />
      </div>
      <div class="column">
        <form-select
          :required="false"
          rules
          label="Payment Status"
          name="Payment Status"
          :options="{all: filtersOptions.payment_status}"
          v-model="filters.payment_status"
        />
      </div>
      <div class="column is-2 input-cntr">
        <label class="label" for="date_from" style="margin-bottom: 0">From</label>
        <div class="control">
          <datepicker
            v-model="filters.date_from"
            :config="{dateFormat: 'm/d/Y', wrap: true }"
            id="date_from"
          />
        </div>
      </div>
      <div class="column is-2 input-cntr">
        <label class="label" for="date_to" style="margin-bottom: 0">To</label>
        <div class="control">
          <datepicker
            v-model="filters.date_to"
            :config="{dateFormat: 'm/d/Y', wrap: true }"
            id="date_to"
          />
        </div>
      </div>
    </div>

    <div class="columns orders-filters is-clipped" :class="{'is-pulled-right': !moreFilters}">
      <div v-show="moreFilters && artProducers" class="column is-2 input-cntr">
        <form-select
          :required="false"
          rules
          label="Art Producer"
          name="art_producer"
          :options="{all: filtersOptions.art_producers}"
          v-model="filters.art_producer"
        />
      </div>
      <div class="column is-2 input-cntr" v-if="moreFilters && serviceType">
        <form-select
          :required="false"
          rules
          label="Service Type"
          name="service_type"
          :options="{all: filtersOptions.service_types}"
          v-model="filters.service_type"
        />
      </div>
      <div class="column is-2 input-cntr" v-if="moreFilters && redoCategory">
        <form-select
          :required="false"
          rules
          label="Redo"
          name="redo_category"
          :options="{all: filtersOptions.redo_categories}"
          v-model="filters.redo_category"
        />
      </div>
      <div class="column is-2 input-cntr" v-if="moreFilters && salesRep">
        <form-select
          :required="false"
          rules
          label="Sales Rep"
          name="csr"
          :options="{all: filtersOptions.csr_users}"
          v-model="filters.csr"
        />
      </div>
      <div class="column is-2 input-cntr" v-if="moreFilters">
        <form-select
          :required="false"
          rules
          label="Delivery Type"
          name="delivery_type"
          :options="{all: filtersOptions.delivery_types}"
          v-model="filters.delivery_type"
        />
      </div>
      <div class="column">
        <span class="span-primary text-underlined" @click="resetFilters()">Reset filters</span>
        <span
          class="blue-span-primary"
          @click="switchMoreFilters()"
          v-if="!moreFilters"
        >+ More filters</span>
        <span class="blue-span-primary" @click="switchMoreFilters()" v-else>- Less filters</span>
        <span class="blue-span-primary text-underlined" v-roles="['admin', 'csr', 'developer', 'production-coordinator', 'purchaser', 'art-producer']" @click="$router.push('/reports/production-and-purchasing')">Create reports</span>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.input-cntr {
  position: relative;

  .button {
    position: absolute;
    right: 0;
    background: white;

    &:hover {
      background: #1793d3;
    }
  }

  input {
    width: 100% !important;
  }
}

.text-underlined {
  text-decoration: underline;
}

.span-primary {
  color: #6b6060;
  cursor: pointer;
  padding-left: 7px;
  &:hover {
    color: #4db4ea;
  }
}
.blue-span-primary {
  cursor: pointer;
  padding-left: 7px;
  color: #4db4ea;
  &:hover {
    color: #4db4ea;
  }
}
</style>

<script>
import formSelect from '../common/form/select';
import Datepicker from '../../modules/datepicker/index';

import { ORDER_FILTER_STATUSES } from '../../helpers/orders';

const top3Printers = ['Unassigned', 'Extreme', 'Impressionz', 'A-Creations'];

export default {
  name: 'filter-order',

  data() {
    return {
      filters: {
        status: ORDER_FILTER_STATUSES.ORDER_FILTER_STATUS_ACTIVE,
        phase_status: '',
        csr: '',
        printer: '',
        date_to: '',
        date_from: '',
        payment_status: '',
        art_producer: '',
        service_type: '',
        redo_category: '',
        delivery_type: ''
      },
      moreFilters: false
    };
  },

  props: {
    filtersOptions: {
      type: Object,
      default: 'Select One'
    },
    salesRep: {
      type: Boolean,
      default: true
    },
    artProducers: {
      type: Boolean,
      default: true
    },
    serviceType: {
      type: Boolean,
      default: true
    },
    redoCategory:{
      type: Boolean,
      default: true
    }
  },

  components: {
    formSelect,
    Datepicker
  },
  watch: {
    filters: {
      handler: function(newFilters, oldFilters) {
        this.takeFilters(newFilters);
      },
      deep: true
    }
  },

  methods: {
    takeFilters(filters) {
      this.$emit('takeFilters', filters);
    },

    switchMoreFilters() {
      this.moreFilters = !this.moreFilters;
    },

    resetFilters() {
      this.filters.status = ORDER_FILTER_STATUSES.ORDER_FILTER_STATUS_ACTIVE;
      this.filters.phase_status = '';
      this.filters.csr = '';
      this.filters.printer = '';
      this.filters.date_to = '';
      this.filters.date_from = '';
      this.filters.payment_status = '';
      this.filters.art_producer = '';
      this.filters.service_type = '';
      this.filters.redo_category = '';
      this.filters.delivery_type = '';
    }
  },
  computed: {
    sortedPrinters() {
      let sortedPrinters = [];
      top3Printers.forEach(topPrinterName => {
        const printer = this.filtersOptions.printers.find(
          ({ label }) => label === topPrinterName
        );
        if (printer) sortedPrinters.push(printer);
      });

      return sortedPrinters.concat(
        this.filtersOptions.printers
          .filter(({ label }) => !top3Printers.includes(label))
          .sort((a, b) => {
            return a.label > b.label ? 1 : a.label < b.label ? -1 : 0;
          })
      );
    }
  }
};
</script>
