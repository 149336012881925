<template>
  <div v-if="groupedGarments.length">
    <h2 class="is-grayed-title">{{ 'Product Information' | uppercase }}</h2>
    <table class="table is-fullwidth">
      <thead>
      <tr>
        <th>Style #</th>
        <th>Color</th>
        <th v-if="showDetails">Description</th>
        <th v-if="showDetails">Fabric</th>
        <th>Quantities</th>
        <th>QTY Total</th>
      </tr>
      </thead>
      <tbody>
      <template v-for="groupedGarment in groupedGarments">
        <tr
          v-for="garment in groupedGarment.garments"
          :key="`product-garment-${garment.id}`"
        >
          <td style="width: 150px">
            <span>{{ garment.brand_object.name + ' ' + garment.product_object.number }}</span>
          </td>
          <td colspan="">{{ garment.color }}</td>
          <td v-if="showDetails"
              class="content-pre">{{ garment.product_object.name }}</td>
          <td v-if="showDetails">{{ garmentFabricLabel(garment)}}</td>
          <td>{{ garment.sizesString }}</td>
          <td class="">Total: {{ garment.sizesTotalQty }}</td>
        </tr>
      </template>
      </tbody>
    </table>
  </div>
</template>

<script>
import { cloneDeep } from 'lodash';

export default {
  name: 'garment-apparel-details',
  props: {
    design: {
      type: Object,
      required: true
    },
    options: {
      type: Object,
      required: true
    },
    showDetails: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      sortedSizes: []
    };
  },
  computed: {
    groupedGarments() {
      let groupedGarments = {};
      if (this.design && this.design.designable && this.design.designable.garments) {
        let finishingGarments = {};

        this.design.designable.finishings.filter(finishing =>
          finishing['finishing_option'] === 'tag-printing' ||
          finishing['finishing_option'] === 'heat-pressed-tags'
        ).forEach(finishing => {
          finishing.items.forEach(finishingItem => {
            finishingGarments[finishingItem.garment_id] = {
                fabric: finishingItem.fabric,
                origin: finishingItem.origin
            }
          })
        })
        this.design.designable.garments.forEach(garment => {
          const sizes = JSON.parse(garment.size_qty);
          const sortedSizes = this.sortedSizes
            .filter(size => Object.keys(sizes).includes(size))
            .map(size => [size, sizes[size]]);
          const sizesString = sortedSizes
            .filter(item => item[1]) // filter empty sizes
            .map(item => item.join('-'))
            .join(', ');
          const sizesTotalQty = Object.values(sizes).reduce(
            (result, i) => result + parseInt(i, 10),
            0
          );
          const finishingGarment = finishingGarments[garment.id];
          const parsedGarment = { ...garment, sizesTotalQty, sizesString, finishingGarment };

          if (!groupedGarments[garment.product]) {
            groupedGarments[garment.product] = {
              product: garment.product_object,
              garments: [parsedGarment]
            };
          } else {
            groupedGarments[garment.product].garments.push(parsedGarment);
          }
        });
      }
      return Object.values(groupedGarments);
    }
  },
  mounted() {
    this.sortedSizes = cloneDeep(this.$store.getters.sortedGarmentsSizes);
  },
  methods: {
    garmentFabricLabel(garment){
      return garment.finishingGarment ? garment.finishingGarment.fabric : garment.product_object.fabric;
    },
  }
};
</script>

<style scoped>
h2 {
  text-align: left;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 1rem;
  margin-bottom: 10px;
  padding: 10px;
}

.is-name td {
  background: #dedede;
}
</style>
