<template>
  <div v-if="orderSummary" :class="{'card is-fullwidth': true, 'sticky': isSticky}">
    <div class="order-summary-body">
      <div class="order-contact-info">
        <header class="card-header is-hidden-print">
          <p class="card-header-title">Order: {{summaryId}}</p>
        </header>

        <div class="card-content">
          <div class="content">
            <div class="control">
              <strong v-if="orderSummary.companyName">{{orderSummary.companyName}}</strong>
              <div>{{orderSummary.contactName}}</div>
              <div>
                <template v-if="orderSummary.address.mailing_address">{{orderSummary.address.mailing_address}} <br></template>
                <template v-if="orderSummary.address.city">{{orderSummary.address.city}},</template>
                <template v-if="orderSummary.address.state_id">{{orderSummary.address.state_id}}</template>
                <template v-if="orderSummary.address.postal_code">{{orderSummary.address.postal_code}} <br></template>
                <template v-if="orderSummary.address.country">{{orderSummary.address.country}}</template>
              </div>
            </div>

            <div class="control" v-if="orderSummary.recipient && (orderSummary.recipient.firstname || orderSummary.recipient.lastname)">
              <h5 class="subtitle is-marginless">Recipient Info</h5>
              <div v-if="orderSummary.recipient.firstname"><strong>First Name: </strong>{{orderSummary.recipient.firstname}}</div>
              <div v-if="orderSummary.recipient.lastname"><strong>Last Name: </strong>{{orderSummary.recipient.lastname}}</div>
              <div v-if="orderSummary.recipient.company"><strong>Company: </strong>{{orderSummary.recipient.company}}</div>
            </div>

            <h5 class="subtitle is-marginless">Estimate Date</h5>
            <div class="control">
              {{orderSummary.estimateDate}}
            </div>

            <div v-roles="['admin', 'csr', 'production-coordinator', 'purchaser', 'art-producer']">
              <h5 class="subtitle is-marginless">Ship By</h5>
              {{orderSummary.ship_by_date || " - "}}
            </div>
          </div>
        </div>
      </div>

      <div v-if="!(orderSummary.total && orderSummary.total.price)" class="card-content">
        <div class="content">
          <h5 title="subtitle ">Please fill required fields</h5>
        </div>
      </div>

      <div v-if="orderSummary.total && orderSummary.total.price">
        <header class="card-header" v-show="orderSummary.designs">
          <p class="card-header-title">Designs Details</p>
        </header>

        <div v-for="(design, key) in orderSummary.designs"
          v-bind:key="key" class="design-estimate-details">
          <div class="card-content">
            <component :is="getComponentNameByDesign(design)"
              :design="design"
              :hide-zero-quantity-sizes="hideZeroQuantitySizes"
              :index="key"
              :shortOne="true" />
            <div v-for="finishing in design.finishings" class="finishing-full">
              <component v-if="finishing.finishing_option"
                :is="'finishing-summary-' + finishing.finishing_option"
                :finishing="finishing"
                :design="design"
                :customer-view="true"></component>
            </div>
          </div>

          <approve-summary-other-fees v-if="design.ink_color_changes"
            :total="design.total.ink_color_changes_upcharge"
            :qty="design.ink_color_changes"></approve-summary-other-fees>
        </div>
      </div>

      <div v-if="orderSummary.comment">
        <header class="card-header" v-show="orderSummary.designs">
          <p class="card-header-title">Order Comment</p>
        </header>

        <p class="card-content">{{orderSummary.comment}}</p>
      </div>
    </div>

    <footer class="card-footer">
      <header class="card-header">
        <p class="card-header-title">Estimate Total</p>
      </header>
      <div class="card-content" v-if="orderSummary.total">
        <div class="content">
          <h3 title="title">
            <span v-if="orderSummary.total.packages_qty">Boxes: {{orderSummary.total.packages_qty}}</span>
            <span class="is-pulled-right"
              v-if="!hideShipping && orderSummary.total.shipping_cost">
              Shipping: {{orderSummary.total.shipping_cost | price}}
            </span>
          </h3>
        </div>
        <div :class="{'content': true, 'has-text-right': !orderSummary.total.tax_id}" v-if="orderSummary.total.state_tax || orderSummary.total.tax_id">
          <span>Sales Tax ({{orderSummary.total.state}}): {{orderSummary.total.state_tax | price}}</span>
          <div class="is-pulled-right" v-if="orderSummary.total.tax_id">* Tax id: {{orderSummary.total.tax_id}}</div>
        </div>
        <div class="content">
          <dl v-if="orderSummary.total.totalDiscount" class="discount">
            <dt><strong>Discount:</strong> {{orderSummary.total.totalDiscount | price}}</dt>
            <dt v-if="orderSummary.discount && orderSummary.discount.discount_description">{{orderSummary.discount.discount_description}}</dt>
          </dl>
          <h3 title="title" class="is-marginless">
            {{orderSummary.total.qty | itemsNumberFormat}} items
            <span class="is-pulled-right">Total: <strong>{{totalPrice | price}}</strong></span>
          </h3>
        </div>
      </div>
    </footer>
  </div>
</template>

<style lang="scss">
.card-footer {
  display: flex;
  flex-direction: column;
}

.card.sticky {
  max-height: calc(100vh - 152px);
  display: flex;
  flex-direction: column;
}

.card.sticky .order-summary-body {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}

.card.sticky .card-footer {
  flex-shrink: 0;
}

.design-estimate-details {
  border-top: none;

  & + .design-estimate-details {
    border-top: 3px solid black;
  }
}

.card-header {
  background: #f1f1f1;
}

.finishing-cntr {
  max-width: 500px;
}
.discount {
  margin-bottom: 10px;
}
</style>

<script>
import Vue from 'vue';
import SummaryApparel from '../../orders/estimate-summary-types/summary-apparel';
import * as SummaryBlank from '../../orders/estimate-summary-types/summary-apparel';
import SummaryPoster from '../../orders/estimate-summary-types/summary-poster';
import SummaryButton from '../../orders/estimate-summary-types/summary-button';
import SummarySticker from '../../orders/estimate-summary-types/summary-sticker';
import SummaryDigital from '../../orders/estimate-summary-types/summary-digital';
import SummaryOther from '../../orders/estimate-summary-types/summary-other';

import finishingSummaryFoldingBagging from '../../orders/estimate-summary-types/apparel-finishing-summary-types/finishing-summary-folding-bagging';
import finishingSummaryHangTag from '../../orders/estimate-summary-types/apparel-finishing-summary-types/finishing-summary-hang-tag';
import finishingSummaryHeatPressedTags from '../../orders/estimate-summary-types/apparel-finishing-summary-types/finishing-summary-heat-pressed-tags';
import finishingSummaryHemTag from '../../orders/estimate-summary-types/apparel-finishing-summary-types/finishing-summary-hem-tag';
import finishingSummarySewnTagBottomOrSleeve from '../../orders/estimate-summary-types/apparel-finishing-summary-types/finishing-summary-sewn-tag-bottom-or-sleeve';
import finishingSummarySewnTagNeckAndFourSided from '../../orders/estimate-summary-types/apparel-finishing-summary-types/finishing-summary-sewn-tag-neck-and-four-sided';
import finishingSummaryTagPrinting from '../../orders/estimate-summary-types/apparel-finishing-summary-types/finishing-summary-tag-printing';
import finishingSummaryWovenNeckTag from '../../orders/estimate-summary-types/apparel-finishing-summary-types/finishing-summary-woven-neck-tag';
import finishingSummaryFourSidedTag from '../../orders/estimate-summary-types/apparel-finishing-summary-types/finishing-summary-four-sided-tag';

import ApproveSummaryOtherFees from './other-fees';
import ApproveSummaryFinishings from './finishings';

import { some } from 'lodash';

export default {
  props: [
    'orderSummary',
    'hideZeroQuantitySizes',
    'isSticky',
    'hideShipping',
    'summaryId'
  ],
  name: 'estimate-summary',
  components: {
    SummaryApparel,
    SummaryPoster,
    SummaryBlank,
    SummaryButton,
    SummarySticker,
    SummaryDigital,
    SummaryOther,
    ApproveSummaryOtherFees,
    ApproveSummaryFinishings,

    finishingSummaryFoldingBagging,
    finishingSummaryHangTag,
    finishingSummaryHeatPressedTags,
    finishingSummaryHemTag,
    finishingSummarySewnTagBottomOrSleeve,
    finishingSummarySewnTagNeckAndFourSided,
    finishingSummaryTagPrinting,
    finishingSummaryWovenNeckTag,
    finishingSummaryFourSidedTag
  },
  computed: {
    hasApparel() {
      return some(
        this.orderSummary.designs,
        design => design.type == 'apparel'
      );
    },
    totalPrice() {
      if (this.orderSummary.total.upcharge) {
        return (
          +this.orderSummary.total.price + +this.orderSummary.total.upcharge
        );
      } else {
        return +this.orderSummary.total.price;
      }
    }
  },
  methods: {
    getComponentNameByDesign(d) {
      // Embroidery uses the same models/components as apparel,
      // with some different fields.
      return 'summary-' + (d.type === 'embroidery' ? 'apparel' : d.type);
    }
  }
};
</script>
