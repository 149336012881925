<template>
  <div class="page-content container section">
    <div class="">
      <div class="tabs is-boxed" v-roles="['admin', 'csr', 'production-coordinator', 'purchaser', 'art-producer', 'customer']">
        <ul data-tabs id="dashboard-tabs">
          <router-link v-roles="['admin', 'csr', 'production-coordinator', 'purchaser', 'art-producer', 'customer']"
            tag="li"
            class="tabs-title"
            :to="'/orders/' + id + '/details'">
            <a>Details</a>
          </router-link>
          <router-link v-roles="['admin', 'csr', 'production-coordinator', 'purchaser', 'art-producer', 'customer']"
            v-if="service !== 'non_order_invoice'"
            tag="li"
            class="tabs-title"
            :to="'/orders/' + id + '/files'">
            <a>Files</a>
          </router-link>
          <router-link v-roles="['admin', 'csr', 'production-coordinator', 'purchaser', 'art-producer']"
                       tag="li"
                       class="tabs-title"
                       :to="'/orders/' + id + '/payments'">
            <a>Payments</a>
          </router-link>
        </ul>
      </div>
      <section id="my-threadbird">
        <router-view></router-view>
      </section>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import { mapActions } from 'vuex';
import apiService from '../../../services/api.service';
import { cloneDeep } from 'lodash';

export default {
  name: 'order-view',
  data() {
    return {
      service: ''
    };
  },
  computed: {
    id() {
      return this.$route.params.id;
    }
  },
  methods: {
    loadorder() {
      apiService.getOrder(this.id).then(data => {
        let order = cloneDeep(data.data);
        order.designs.map(({ service }) => (this.service = service));
      });
    }
  },

  created() {
    this.loadorder();
  }
};
</script>
