import apiService from '../../services/api.service';
import notify from '../../modules/notifier';
import { cloneDeep, forEach } from 'lodash';

import { LOAD_ORDERS, LOAD_ORDERS_LIST_DESIGNS } from '../action-types';
import {APPAREL_SERVICE_CODE, EMBROIDERY_SERVICE_CODE} from '@/store/service-types';

const errorNotify = err => {
  console.log(err);
  notify({
    message: 'Something wrong',
    type: 'danger'
  });
};

const ordersSummary = {
  state: {
    orders: [],
    orders_paginator_data: {},
    actual_filters: [],
    filter_options: {
      csr_users: [],
      order_statuses: [],
      estimate_statuses: [],
      phases: [],
      printers: [],
      payment_status: [],
      art_producers: [],
      service_types: [],
      redo_categories: [],
      delivery_types: [],
      companies: []
    },
    orders_loading: false
  },
  getters: {
    orders: state => {
      return state.orders;
    },
    ordersPaginatorData: state => {
      return state.orders_paginator_data;
    },
    ordersLoading: state => {
      return state.orders_loading;
    },
    actualFilters: state => {
      return state.actual_filters;
    },
    filtersOptions: state => { // todo: remove and use helper
      if (typeof state.filter_options.csr_users === 'object') {
        let csr_users = [];
        let has_deleted_users = false;
        forEach(state.filter_options.csr_users, (v, k) => {
          if (!has_deleted_users && v.is_deleted === 1) {
            has_deleted_users = true;
            csr_users.push({ label: 'Deleted' });
          }
          csr_users.push({ label: v.csr_name, key: v.id });
        });

        let art_producers = [];
        forEach(state.filter_options.art_producers, v => {
          art_producers.push({ label: v.title, key: v.id });
        });

        let order_statuses = [];
        forEach(state.filter_options.order_statuses, (v, k) => {
          order_statuses.push({ label: v, key: k });
        });

        let estimate_statuses = [];
        forEach(state.filter_options.estimate_statuses, (v, k) => {
          estimate_statuses.push({ label: v, key: k });
        });

        let phases = [];
        forEach(state.filter_options.phases, (v, k) => {
          phases.push({ label: v, key: k });
        });

        let printers = [];
        forEach(state.filter_options.printers, (v, k) => {
          printers.push({ label: v, key: k });
        });

        let payment_status = [];
        forEach(state.filter_options.payment_status, (v, k) => {
          payment_status.push({ label: v, key: k });
        });
        let service_types = [];
        forEach(state.filter_options.service_types, v => {
          service_types.push({ label: v.label, key: v.key });
        });
        let redo_categories = [];
        forEach(state.filter_options.redo_categories, v => {
          redo_categories.push({ label: v.label, key: v.key });
        });
        let delivery_types = [];
        forEach(state.filter_options.delivery_types, v => {
          delivery_types.push({ label: v.label, key: v.key });
        });

        let companies = [];
        forEach(state.filter_options.companies, v => {
          companies.push({ label: v.label, key: v.key });
        });

        return {
          csr_users: csr_users,
          order_statuses: order_statuses,
          estimate_statuses: estimate_statuses,
          phases: phases,
          printers: printers,
          payment_status: payment_status,
          art_producers: art_producers,
          service_types: service_types,
          redo_categories: redo_categories,
          delivery_types: delivery_types,
          companies: companies
        };
      }
    }
  },
  mutations: {
    setOrders(state, orders) {
      state.orders = orders;
    },
    setOrdersListDesigns(state, { orders, stateName }) {
      console.log(stateName, state);
      if (state[stateName]) {
        state[stateName] = state[stateName].map(order => {
          const orderWithDesign = orders.find(o => o.id === order.id);
          if (orderWithDesign.designs) {
            order.designs = orderWithDesign.designs;
            order.designs = order.designs.map(design => {
              if ([APPAREL_SERVICE_CODE, EMBROIDERY_SERVICE_CODE].includes(design.service)) {
                design.designable.finishings = [];
                if (order.finishings) {
                  order.finishings
                    .filter(finishing => {
                      return finishing.items.find(
                        ({ design_id }) => design_id === design.id
                      );
                    })
                    .forEach(finishing =>
                      design.designable.finishings.push(cloneDeep(finishing))
                    );
                }
              }
              return design;
            });
          }
          return order;
        });
      }
    },
    setOrdersPaginatorData(state, data) {
      state.orders_paginator_data = data;
    },
    setFiltersValues(state, actual_filters) {
      state.actual_filters = actual_filters;
    },
    setFiltersOptions(state, filter_options) {
      state.filter_options = filter_options;
    },
    setLoading(state, { prop, val }) {
      state[prop] = val;
    },
    dropRow(state, { model, id }) {
      if (state[model] !== undefined) {
        state[model] = state[model].filter(order => order.id !== id);
      }
    }
  },
  actions: {
    [LOAD_ORDERS]: ({ commit, dispatch }, filters) => {
      if (!filters.timezone) {
        filters.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      }
      console.log(filters);
      commit('setLoading', { prop: 'orders_loading', val: true });
      let url = null;

      if (filters) {
        url = filters.url;
        delete filters.url;
      }

      return apiService.getOrders(filters, url).then(data => {
        const orders = cloneDeep(data.orders.data);
        const ordersId = orders.map(order => order.id);

        commit('setOrders', orders);

        delete data.orders.data;
        commit('setOrdersPaginatorData', data.orders);

        commit('setFiltersOptions', data.filter_options);
        commit('setFiltersValues', data.actual_filters);

        dispatch(LOAD_ORDERS_LIST_DESIGNS, {
          ordersId: ordersId,
          stateName: 'orders'
        });
        commit('setLoading', { prop: 'orders_loading', val: false });
      });
    },
    [LOAD_ORDERS_LIST_DESIGNS]: (
      { commit, dispatch },
      { ordersId, stateName }
    ) => {
      commit('setLoading', { prop: `${stateName}_loading`, val: true });

      return apiService.getOrdersDesigns(ordersId).then(response => {
        console.log(response);
        commit('setOrdersListDesigns', {
          orders: response.orders,
          stateName: stateName
        });
        commit('setLoading', { prop: `${stateName}_loading`, val: false });
      });
    }
  }
};

export default ordersSummary;
