<template>
  <orders :showFilters="false" :companyId="company" :status="status"></orders>
</template>

<script>
import Orders from '../../orders/orders';
import { ORDER_FILTER_STATUSES } from '../../../helpers/orders';

export default {
  name: 'company-orders',
  components: { Orders },
  props: {
    companyId: {
      type: Number | String,
      required: true
    }
  },
  watch: {
    companyId(id) {
      this.company = id;
    }
  },
  data() {
    return {
      company: this.companyId,
      status: ORDER_FILTER_STATUSES.ORDER_FILTER_STATUS_ALL
    };
  },
  beforeDestroy() {
    this.company = null;
  }
};
</script>
