<template>
  <div class="digital-design">
    <div class="columns">
      <div class="column is-8">
        <form-select v-model="design.item.print_type"
          name="print_type"
          label="Category"
          :scope="getScope(designKey)"
          :options="{all: categories}"
          :disabled="isDisabled" />
      </div>
      <div class="column is-4 flex flex__vertical-center">
        <div class="control form-checkbox-container">
          <label :for="'has-rounded-corners' + designKey" class="">
            <input v-model="design.item.has_rounded_corners"
              :id="'has-rounded-corners' + designKey"
              type="checkbox"
              class="checkbox"
              :disabled="isDisabled">
            Rounded Corners?
          </label>
        </div>
      </div>
    </div>

    <div class="columns" v-if="design.item.print_type">
      <div class="column is-4">
        <form-select v-model="design.item.paper_type"
          name="paper_type"
          label="Paper"
          :scope="getScope(designKey)"
          :options="{all: paperTypes}"
          :disabled="isDisabled" />
      </div>
      <div class="column is-4">
        <form-select v-model="design.item.size"
          name="size"
          label="Size"
          :scope="getScope(designKey)"
          :options="{all: sizes}"
          :disabled="isDisabled" />
      </div>
      <div class="column is-4">
        <form-select v-model="design.item.quantity"
          name="quantity"
          label="Quantity"
          :scope="getScope(designKey)"
          :options="{all: qtyList}" />
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import bus from '../../../bus';
import apiService from '../../../services/api.service';
import { isEmpty, forEach, isNumber, merge, cloneDeep } from 'lodash';

import formInput from '../../common/form/input';
import formSelect from '../../common/form/select';

let emptyDesignModel = {
  item: {
    print_type: '',
    paper_type: '',
    size: '',
    quantity: '',
    has_rounded_corners: false
  }
};

export default {
  props: {
    getScope: {
      type: Function,
      default: () => () => ''
    },
    designCommon: {
      type: Object,
      default: () => {}
    },
    designSpecific: Object,
    designKey: Number,
    isEmbroidery: Boolean,
    upsServices: Array,
    deliveryTypes: Array,
    resetKey: Number,
    accountId: Number,
    index: Number
  },
  data() {
    return {
      type: 'digital',
      categories: [],
      paperTypes: [],
      sizes: [],
      qtyList: [],
      summary: {},
      design: cloneDeep(
        Object.assign({}, emptyDesignModel, this.designSpecific)
      ),
      upcharges: {}
    };
  },

  components: {
    formInput,
    formSelect
  },

  computed: {
    designCategory() {
      return this.design.item.print_type;
    },

    isDisabled() {
      return this.designCommon.printed_before_order_id ||
        this.designCommon.printed_before_design_id
        ? 'disabled'
        : false;
    }
  },

  watch: {
    qtyList: {
      handler(val, oldVal) {
        this.calculateSummary();
      },
      deep: true
    },
    categories: {
      handler(val, oldVal) {
        this.calculateSummary();
      },
      deep: true
    },
    designCommon: {
      handler(design, oldDesign) {
        this.calculateSummary();
      },
      deep: true
    },
    design: {
      handler(design, oldDesign) {
        this.calculateSummary();
      },
      deep: true
    },
    summary: {
      handler(summary, oldSummary) {
        this.$emit('summary-changed', this.designKey, summary);
      },
      deep: true
    },
    designCategory(value) {
      this.onChangeCategory(value);
    },
    resetKey(key) {
      this.calculateSummary();

      if (key !== null && key !== undefined && key === this.designKey) {
        this.setEmpty();
      }
    }
  },

  methods: {
    /**
     * Calculate summary
     */
    calculateSummary() {
      let summary_price = 0;
      let summary_qty = 0;

      if (
        this.upcharges.by_size &&
        this.upcharges.by_size[this.design.item.print_type] !== undefined
      ) {
        let upchargeSizeObj = this.upcharges.by_size[
          this.design.item.print_type
        ].filter(upcharge => {
          return (
            upcharge.digital_print_size_id == this.design.item.size &&
            upcharge.digital_print_quantity_id == this.design.item.quantity
          );
        })[0];

        if (upchargeSizeObj !== undefined) {
          let upcharge = parseFloat(upchargeSizeObj.upcharge) || 0;
          let qtyOption = this.qtyList.filter(
            quantity => quantity.key == this.design.item.quantity
          )[0];
          if (qtyOption !== undefined && upcharge !== undefined) {
            summary_price += upcharge;
            summary_qty = parseInt(qtyOption.label.replace(/[^0-9 | ^.]/g, ''));
          }

          if (
            this.design.item.has_rounded_corners &&
            this.upcharges.rounded_corners[this.design.item.print_type] !==
              undefined
          ) {
            let upchargeCornersObj = this.upcharges.rounded_corners[
              this.design.item.print_type
            ][0];

            if (upchargeCornersObj !== undefined) {
              let perQtyOption = this.qtyList.filter(
                quantity =>
                  quantity.key == upchargeCornersObj.digital_print_quantity_id
              )[0];
              summary_price +=
                (parseFloat(upchargeCornersObj.upcharge) || 0) *
                summary_qty /
                parseInt(perQtyOption.label.replace(/[^0-9 | ^.]/g, ''));
            }
          }
        }
      }

      let sizeOption = this.sizes.filter(
        size => size.key == this.design.item.size
      )[0];
      let paperTypeOption = this.paperTypes.filter(
        type => type.key == this.design.item.paper_type
      )[0];
      let categoryOption = this.categories.filter(
        cat => cat.key == this.design.item.print_type
      )[0];

      this.summary = {
        name: this.designCommon.name,
        type: this.designCommon.service,
        size: (sizeOption && sizeOption.label) || '',
        paperType: (paperTypeOption && paperTypeOption.label) || '',
        category: (categoryOption && categoryOption.label) || '',
        printed_before: this.designCommon.printed_before,
        printed_before_order_id: this.designCommon.printed_before_order_id,
        printed_before_design_id: this.designCommon.printed_before_design_id,
        reference_order_id: this.designCommon.reference_order_id,
        reference_design_id: this.designCommon.reference_design_id,
        comment: this.designCommon.comment,
        type_of_delivery: this.designCommon.type_of_delivery,
        ship_by_date: this.designCommon.ship_by_date,
        print_by_date: this.designCommon.print_by_date,
        in_hands_date: this.designCommon.in_hands_date
      };

      if (sizeOption && summary_qty) {
        this.summary.total = {
          price: Math.round(summary_price * 100) / 100,
          qty: summary_qty,
          upcharge: Math.round(this.designCommon.upcharge * 100) / 100,
          upcharge_description: this.designCommon.upcharge_description
        };
      }
    },

    filterUpcharge(items) {
      let basePrice = 0;

      return basePrice;
    },

    objSize(object) {
      return Object.keys(object).length;
    },

    getDesignData(user_id) {
      apiService.getDesignFormOptions(this.type, user_id).then(
        data => {
          this.upcharges = data.form_data.upcharges;

          this.categories = data.form_data.print_types;
        },
        response => {
          notify({
            message: 'Could not load info for designs',
            type: 'danger'
          });
        }
      );
    },

    onValidate(validateScope) {
      if (validateScope) {
        this.$validator.validateAll(validateScope).catch(err => {});
      } else {
        this.$validator.validateScopes().catch(err => {});
      }
    },

    onClear() {
      this.errors.clear();
    },

    onChangeCategory(category, skipResetting) {
      if (!skipResetting) {
        // Need to reset selected paper_type field
        this.$set(this.design.item, 'paper_type', '');
        // Need to reset selected size field
        this.$set(this.design.item, 'size', '');
        // Need to reset selected Qty field
        this.$set(this.design.item, 'quantity', '');
      }

      if (category) {
        return apiService.getDigitaldesignFormDataByPrintType(category).then(
          data => {
            this.$set(this, 'paperTypes', data.paper_types);
            this.$set(this, 'sizes', data.sizes);
            this.$set(this, 'qtyList', data.quantities);
          },
          response => {
            notify({
              message: 'Could not load info for design',
              type: 'danger'
            });
          }
        );
      } else {
        return Promise.resolve();
      }
    },

    setEmpty() {
      this.$set(this, 'design', cloneDeep(emptyDesignModel));
    },

    setDesign() {
      return {
        key: this.designKey,
        design: this.design
      };
    },

    onMountData(user_id) {
      this.getDesignData(user_id);

      if (this.design.item && this.design.item.print_type) {
        this.onChangeCategory(this.design.item.print_type, true).then(() => {
          this.calculateSummary();
        });
      }
    }
  },
  created() {
    bus.$on('validate', this.onValidate);
    bus.$on('clear', this.onClear);
    this.$watch(
      () => this.errors.errors,
      value => {
        bus.$emit('errors-changed', value);
      }
    );
  },
  mounted() {
    if (isNumber(this.resetKey) && this.resetKey === this.designKey) {
      this.setEmpty();
    }

    this.onMountData(this.accountId);

    bus.$on('order-set-account-info', user_id => {
      this.onMountData(user_id);
    });
  },
  destroyed() {
    bus.$off('order-set-account-info');
  }
};
</script>
