<template>
  <div class="request-revision-popup-columns columns">
    <div class="column">
      <div class="request-revision-popup-content">
        <form class="request-revision-form" novalidate @submit.prevent autocomplete="off">
          <form-row>
            <form-item
              placeholder="Enter Your Revision Request"
              class="form-item-request-revision-message"
              v-model="message"
              :hideLabel="true"
              name="request-revision-message"
              type="textarea"
              rules="max:1000"
              :scope="'request-revision-popup'"
            ></form-item>
          </form-row>
        </form>
      </div>
      <div class="request-revision-popup-buttons">
        <button class="button is-success" @click="submit">Submit</button>
        <button class="button" @click="cancel">Cancel</button>
      </div>
    </div>
  </div>
</template>
<script>
import FormItem from '@/components/common/form/FormItem';
import FormRow from '@/components/common/form/FormRow';

export default {
  name: 'request-revision-popup',
  components: {
    FormRow,
    FormItem
  },
  props: {
    modalOverlay: {
      type: Boolean,
      default: false
    },
    modalLoading: {
      type: Boolean,
      default: false
    },
    draggable: {
      type: Boolean,
      default: false
    },
    clickToClose: {
      type: Boolean,
      default: false
    },
    callback: {
      type: Function,
      required: true
    }
  },
  data() {
    return {
      message: ''
    };
  },
  methods: {
    submit() {
      this.callback(this.message);
    },
    cancel() {
      this.$emit('close');
    }
  }
};
</script>
<style lang="scss">
.v--modal-overlay {
  .v--modal-box {
    overflow: visible !important;
  }
}

.request-revision-popup-columns {
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;

  .form-row {
    max-width: 600px;
  }

  .request-revision-popup-buttons {
    text-align: right;
    padding-right: 5px;

    .button {
      margin-right: 5px;
      margin-top: 5px;
    }
  }

  .form-item-request-revision-message {
    &.form-item {
      .form-item-label {
        font-size: 14px;
        line-height: 20px;
        font-weight: 400;
        color: rgba(#000, 0.54);

        & + * {
          margin-top: 10px;
        }
      }
    }

    &__placeholder {
      display: flex;
      font-size: 12px;
      align-items: center;

      svg {
        margin-right: 2px;
        fill: #dbdbdb;

        & + * {
          margin-left: 10px;
        }
      }

      p {
        flex-grow: 1;
      }
    }
  }
}
</style>
