<template>
  <div class="content order-summary-content">
    <h3 class="subtitle" :id="'design-' + (parseInt(index) + 1)">
      Design #{{parseInt(index) + 1}}{{shortOne ? `: ${design.name}` : ''}}
      <template v-if="!shortOne">
        <span class="printed-before-reprint-label" v-if="design.printed_before_order_id">
          - Reprint
          <router-link class="link"
            :to="getOrderPageUrl(design.printed_before_order_id)">
            #{{design.printed_before_order_id}}
          </router-link>
        </span>
        <span class="printed-before-reprint-label" v-if="design.reference_order_id">
          - Reference
          <router-link class="link"
            :to="getOrderPageUrl(design.reference_order_id)">
            #{{design.reference_order_id}}
          </router-link>
        </span>
      </template>
      <div class="is-warning tag is-pulled-right"
           v-if="design.type_of_delivery == 'pickup'">PICKUP</div><br>
    </h3>

    <div class="design-summary-notes" v-if="!shortOne">
      <div v-if="design.printed_before" class="printed-before">Design has been printed before</div>
      <div v-if="design.reference_order_id" class="printed-before">Reference design has been printed before</div>
    </div>
    <h5 v-if="design.name && !shortOne" class="subtitle"><strong>Design:</strong> {{design.name}}</h5>
    <div v-if="design.items && design.items.length">
      <div v-for="(item, key) in design.items" :key="`other-design-item-summary-${key}`">
        <table class="table table__without-hover fixed-table">
          <thead>
          <tr>
            <th colspan="2" style="padding-left: 0">Item</th>
            <!--<th>{{shortOne ? 'Description' : 'Desc.'}}</th>-->
            <th>Qty.</th>
            <th v-roles="['admin', 'csr', 'production-coordinator', 'purchaser', 'art-producer', 'customer']">Unit
              cost
            </th>
            <th v-roles="['admin', 'csr', 'production-coordinator', 'purchaser', 'art-producer', 'customer']"
                :class="{'align-right': shortOne}">Total
            </th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td class="text-cell" colspan="2">{{item.name}}</td>
            <td>{{item.qty | itemsNumberFormat}}</td>
            <td v-roles="['admin', 'csr', 'production-coordinator', 'purchaser', 'art-producer', 'customer']">
              {{item.price |
              price}}
            </td>
            <td v-roles="['admin', 'csr', 'production-coordinator', 'purchaser', 'art-producer', 'customer']"
                :class="{'align-right': shortOne}">{{item.total_cost | price}}
            </td>
          </tr>
          <tr>
            <td class="break-word content-pre"
                colspan="5"
                v-html="item.description">
            </td>
          </tr>
          </tbody>
        </table>
        <hr v-if="design.items.length > 1 && key < design.items.length - 1">
      </div>
      <table class="table table__without-hover fixed-table">
        <tbody>
        <tr v-if="!shortOne"
            v-roles="['admin', 'csr', 'production-coordinator', 'purchaser', 'art-producer', 'customer']">
          <td colspan="4" style="padding-left: 0"><strong>Total:</strong></td>
          <td class="align-right">{{itemsSubtotal | price}}</td>
        </tr>
        </tbody>
        <tbody v-if="shortOne" v-roles="['admin', 'csr', 'production-coordinator', 'purchaser', 'art-producer', 'customer']">
        <tr>
          <th colspan="4" style="padding-left: 0">Subtotal</th>
          <td class="align-right">{{itemsSubtotal | price}}</td>
        </tr>
        </tbody>
      </table>
    </div>
    <div v-if="design.comment">
      <p>
        <strong>Comment: </strong>
        <span>{{design.comment}}</span>
      </p>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.fixed-table {
  table-layout: fixed;

  td {
    word-break: break-all;
    padding: 8px 4px;
  }

  .text-cell {
    word-break: break-word;
  }
}
</style>

<script>
import Vue from 'vue';

export default {
  props: ['design', 'index', 'shortOne'],
  name: 'summary-other',
  methods: {
    getOrderPageUrl(id) {
      return this.$route.path.indexOf('customer') !== -1
        ? `/customer/orders/${id}/details`
        : `/orders/${id}`;
    }
  },
  computed: {
    itemsSubtotal() {
      console.log(this.design);
      return this.design.items.reduce(
        (subtotal, item) => subtotal + item.total_cost,
        0
      );
    }
  }
};
</script>
