<template>
  <div>
    <!-- Main container -->
    <nav class="level">
      <!-- Left side -->
      <div class="level-left">
        <div class="level-item">
          <p class="subtitle is-5">Search accounts</p>
        </div>
        <div class="level-item">
          <div class="control">
            <p class="control has-addons">
              <input type="text"
                class="input"
                v-model="searchQuery"
                v-focus="true">
            </p>
          </div>
        </div>
      </div>

      <!-- Right side -->
      <div class="level-right">
        <p class="level-item">
          <span @click="createAccount"
            v-roles="['admin', 'csr', 'production-coordinator', 'purchaser', 'art-producer']"
            class="button is-small">Create New Account</span>
        </p>
      </div>
    </nav>
    <table v-if="accountsList.length" class="table customers-table">
      <thead>
        <tr>
          <th>Username</th>
          <th>Contact</th>
          <th>Company</th>
          <th>Email</th>
          <th width="60px"></th>
        </tr>
      </thead>
      <tfoot>
        <tr>
          <th>Username</th>
          <th>Contact</th>
          <th>Company</th>
          <th>Email</th>
          <th></th>
        </tr>
      </tfoot>
      <tbody>
        <tr v-for="(entry, rowKey) in accountsList">
          <td>{{entry.title}}</td>
          <td>{{entry.first_name + ' ' + entry.last_name}}</td>
          <td>
            <span v-if="entry.company">{{entry.company}}</span>
            <span v-else>-</span>
          </td>
          <td>{{entry.email}}</td>
          <td>
            <span @click="selectAccount(entry.id)"
              class="button is-small">Select</span>
          </td>
        </tr>
      </tbody>
    </table>

    <section v-else class="hero has-text-centered">
      <div class="hero-body">
        <div class="container">
          <p class="subtitle">No accounts found</p>
        </div>
      </div>
    </section>

    <nav class="pagination is-centered is-small" v-if="pagesNumber > 1">
      <a class="pagination-previous button"
        :disabled="prevPageUrl == null"
        @click="getAccountsData(false, prevPageUrl)">Previous</a>
      <a class="pagination-next button"
        :disabled="nextPageUrl == null"
        @click="getAccountsData(false, nextPageUrl)">Next</a>
      <ul class="pagination-list">
        <li>
          <a class="pagination-link"
            :class="{'is-current': 1 == currentPage}"
            @click="getAccountsData(1)">1</a>
        </li>
        <li v-if="currentPage-3 > 1">
          <span class="pagination-ellipsis">&hellip;</span>
        </li>
        <li v-for="pageNum in pagesNumber"
          v-if="(pageNum+2 >= currentPage && pageNum-2 <= currentPage) && pageNum != 1 && pageNum != pagesNumber">
          <a class="pagination-link"
            :class="{'is-current': pageNum == currentPage}"
            @click="getAccountsData(pageNum)">{{pageNum}}</a>
        </li>
        <li v-if="currentPage+3 < pagesNumber">
          <span class="pagination-ellipsis">&hellip;</span>
        </li>
        <li>
          <a class="pagination-link"
            :class="{'is-current': pagesNumber == currentPage}"
            @click="getAccountsData(pagesNumber)">{{pagesNumber}}</a>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
  import Vue from 'vue';
  import {
    mapGetters,
    mapActions
  } from 'vuex';
  import popup from '../../../modules/popupper'

  import formSelect from '../../common/form/select';
  import apiService from '../../../services/api.service';
  import notify from '../../../modules/notifier/index';
  import {debounce} from 'lodash';
  import {focus} from 'vue-focus';
  import CreateAccountComponent from '../../accounts/create.vue';

  export default {
    name: 'accounts-search',
    directives: {
      focus
    },
    props: {
      additional: {
        type: Object,
        default: () => null
      }
    },
    data() {
      return {
        accountsList: [],
        prevPageUrl: null,
        nextPageUrl: null,
        currentPage: 1,
        pagesNumber: 1,
        searchQuery: '',
        searchRole: 'customer'
      }
    },
    watch: {
      searchQuery(str) {
        if (str.length == 0 || str.length >= 3) {
          this.searchAccounts();
        }
      }
    },
    components: {
      formSelect
    },
    mounted() {
      this.getAccountsData();
    },
    methods: {
      searchAccounts() {
        debounce(() => this.getAccountsData(), 500)();
      },

      getAccountsData(page, url) {
        if (page === undefined) page = 1;

        if (url === undefined) url = '/api/accounts/list/' + this.searchRole + '?page=' + page;

        if (this.searchQuery) {
          url += url.indexOf('?') == -1 ? '?q=' + this.searchQuery : '&q=' + this.searchQuery;
        }

        return this.$http.get(url).then((response) => {
          this.accountsList = response.data.users_data.data;
          this.prevPageUrl = response.data.users_data.prev_page_url;
          this.nextPageUrl = response.data.users_data.next_page_url;
          this.currentPage = response.data.users_data.current_page;
          this.pagesNumber = response.data.users_data.last_page;
        }, (response) => {
          notify({
            message: 'Could not load accounts list',
            type: 'danger',
          });
        });
      },
      selectAccount(accountId) {
        this.additional.popupCallback(accountId);
        this.$emit('close');
      },
      createAccount() {
        popup({
          title: 'Create Account',
          bodyComponent: CreateAccountComponent,
          additional: {
            role: 'customer',
            popupCallback: this.additional.popupCallback
          },
          modalCardLong: true,
          showButtons: true,
          runCallback: true
        });
        this.$emit('close');
      }
    }
  };
</script>

