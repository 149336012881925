<template>
  <td
    class="grid-cell"
    :class="tdClass"
    :key="rowType + parentId + id + phase + cellKey"
  >
    <!-- Some action, or complex value -->
    <div
      style="position: relative;"
      :class="{'buttons are-small is-pulled-right mr-3':value.subtag === 'action-menu'}"
      v-roles="cell.roles"
      v-if="isObject && value"
    >
      <!-- Button with Text and Tooltip -->
      <span
        :data-balloon="value.tooltip"
        :data-balloon-pos="value.tooltipPos"
      >
        <p
          :class="value.btnColor"
          v-roles="cell.roles"
          v-if="value.textBeforeBtn"
        >{{ value.textBeforeBtn }}</p>
        <button
          :class="actionClass"
          :disabled="value.disabled"
          v-roles.act="cell.rolesAct"
          v-if="value.tag == 'button'"
          @click="clickHandler(value, id, parentId)"
        >{{ value.str }}</button>
        <a href="#"
          :class="actionClass"
          v-roles.act="cell.rolesAct"
          v-if="value.tag === 'a'"
          @click.prevent="clickHandler(value, id, parentId)"
        >{{ value.str }}</a>
      </span>

      <!-- Action Menu Button -->
      <button
        class="button is-small is-outlined is-primary"
        v-roles.act="cell.rolesAct"
        v-if="value.subtag == 'action-menu'"
        @click="setActive"
      >
        <span class="icon is-medium ">
          <i class="ff-lg ff-ellipsis-v"></i>
        </span>
      </button>
      <div class="actions-menu" v-if="isActionMenuOpened">
        <ul class="actions-menu-ul" v-on-clickaway="closeActionMenu">
          <li
            v-for="action in value.subtagActions"
            class="actions-menu-li"
            v-if="action && action.str"
            @click="clickHandler(action, id, parentId)"
          >{{ action.str }}
          </li>
        </ul>
      </div>

      <!-- Custom Icon -->
      <span
        v-roles.act="cell.rolesAct"
        v-if="value.tag == 'custom-icon'"
        @click="clickHandler(value, id, parentId)"
        class="icon is-medium is-clickable has-text-link"
      ><i :class="value.customClass"></i></span>

      <button
        v-roles.act="cell.rolesAct"
        v-if="value.tag == 'button-with-icon'"
        @click="clickHandler(value, id, parentId)"
        class="button is-small button-circled"
        :class="value.disabled?'is-static':'is-primary'"
        :disabled="value.disabled"
      ><span class="icon is-medium">
        <i :class="value.customClass"></i>
      </span>
      </button>

      <!-- Select -->
      <select
        v-model="value.value"
        :disabled="value.disabled"
        v-roles="cell.roles"
        v-roles.act="cell.rolesAct"
        v-if="value.tag == 'select'"
        @change="changeHandler(value, id, parentId)"
      >
        <option
          v-for="(label, val) in value.options"
          :value="val"
        >{{ label }}
        </option>
      </select>

      <!-- Tag counter -->
      <span
        class="tag msg-tag is-warning is-small count-cntr"
        v-if="value.count"
        @click="clickHandler(value, id, parentId)"
      >{{ value.count }}</span>
    </div>

    <!-- Simple text -->
    <span v-html="value" v-roles="cell.roles" v-if="isString"></span>

    <!-- Some Warning Notification -->
    <span
      class="icon is-danger"
      data-balloon-pos="right"
      :data-balloon="notice"
      v-if="notice"
    ><i class="ff-warning" aria-hidden="true"></i></span>
  </td>
</template>
<script>

export default {
  name: 'cell',
  template: '#grid-template',
  props: {
    cell: {
      type: Object,
      default: () => {
      }
    },
    id: {
      type: Number,
      default: 0
    },
    parentId: {
      type: Number,
      default: 0
    },
    rowType: {
      type: String,
      default: ''
    },
    phase: {
      type: String,
      default: ''
    },
    cellKey: {
      type: Number,
      default: ''
    },
    actionMenu: {
      type: Boolean | String,
      default: false
    }
  },

  methods: {
    clickHandler(value, id, parentId) {
      if (typeof value.click === 'undefined') return;

      if (parentId) {
        value.click(parentId, id, this.rowType);
      } else {
        value.click(id, this.rowType);
      }
      this.closeActionMenu();
    },
    changeHandler(value, id, parendId) {
      if (parendId) {
        value.change(id, parendId, value.value, this.rowType);
      } else {
        value.change(id, value.value, this.rowType);
      }
    },
    setActive() {
      this.$emit('handleActionsMenu', this.activeMenuKey);
    },
    closeActionMenu() {
      this.$emit('handleActionsMenu', false);
    }
  },
  computed: {
    isObject() {
      return !!this.cell && typeof this.cell.value === 'object';
    },
    isString() {
      return (
        this.cell &&
        (typeof this.cell.value === 'string' ||
          typeof this.cell.value === 'number')
      );
    },
    tdClass() {
      return (
        this.cell && {
          'has-text-left': !!this.cell.alignLeft,
          'is-strong': !!this.cell.strong,
          'is-small': !!this.cell.smallText
        }
      );
    },
    value() {
      return this.cell && this.cell.value;
    },
    notice() {
      return this.cell && this.cell.notice;
    },
    actionClass() {
      const value = this.value;
      const tag = value.count ? 'count' : this.value.tag;
      const classes = {
        button: [
          'button is-small ',
          !value.btnColor ? 'is-primary' : ' ' + value.btnColor
        ],
        'icon-read': ['icon'],
        count: {
          'tag msg-tag is-warning is-small': true,
          'is-danger': value.isCountDanger
        },
        a: {
          'grid-link': true,
          'is-disabled': value.disabled
        }
      };

      return value.customClass
        ? `${classes[tag]} ${value.customClass}`
        : classes[tag];
    },
    activeMenuKey() {
      return this.rowType + this.parentId + this.id + this.phase + this.cellKey;
    },
    isActionMenuOpened() {
      return this.actionMenu === this.activeMenuKey;
    }
  }
};
</script>

<style lang="scss" scoped>
.actions-menu {
  color: black;
  border-radius: 3px;
  box-shadow: 0 0 10px 0 rgba(101, 111, 122, 0.5);
  min-width: 120px;
  z-index: 11110 !important;
  position: absolute;
  left: 0;
  top: 100%;
  background-color: white;
  right: 65px;
  margin-top: 2px;
}

.actions-menu-ul {
  max-height: 135px;
  padding: 0;
  margin: 0;
}

.actions-menu-li {
  box-sizing: border-box;
  height: 35px;
  padding: 0 12px;
  white-space: nowrap;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.actions-menu-li:hover {
  box-sizing: border-box;
  height: 35px;
  padding: 0 12px;
  white-space: nowrap;
  display: flex;
  align-items: center;
  cursor: pointer;
  background-color: #eaf4fd;
}

.grid-cell {
  &.is-small {
    font-size: 12px;
    line-height: 1em;
  }
}

.grid-link {
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }

  &.is-disabled {
    color: #ccc;
  }
}
</style>
