<template>
  <div class="account-view-cnt">
    <header class="header">
      <router-link v-roles="['admin', 'csr', 'production-coordinator', 'purchaser', 'art-producer']"
                   :to="'/accounts'"><span class="back-char"><</span>Back to All Accounts
      </router-link>
      <h3 class="title without-margin">Edit Account</h3>
    </header>
    <!-- Account form -->
    <account-form
      :class="{'skeleton-block' :processing}"
      :model="account"
      :roles="roles"
      :options="options"
      :errorsList="errorsList"
      :processing="processing"
      @submitForm="submitForm"
      @accountChanged="accountChanged"></account-form>
    <!-- END Account form -->
  </div>
</template>

<script>
import Vue from 'vue';
import { mapGetters, mapActions } from 'vuex';
import { map } from 'lodash';
import { checkRoles } from '../../router/checkRoles';

import {
  SAVE_ACCOUNT,
  LOAD_ACCOUNT_FORM,
  LOAD_ACCOUNT
} from '../../store/action-types';
import alertify from 'alertify.js';

//form
import accountForm from './form/account-form';

export default {
  name: 'edit-account',
  data() {
    return {
      account: {
        role: '',
        user: {
          first_name: '',
          last_name: '',
          title: '',
          email: '',
          phone: '',
          terms: '',
          company_id: '',
          company_name: '',
          password: '',
          is_test: false
        }
      },
      options: {
        submitBtnText: 'Update',
        hideRole: false
      },
      errorsList: [],
      processing: false
    };
  },
  watch: {
    accountModel(model) {
      // Need to update the local state
      this.applyModel(model);
    },
    $route(to, from) {
      this.loadAccountForm().then(() => {
        this.loadAccount(this.$route.params.id).catch(err => {
          alertify.error(
            err.body.message ? err.body.message : 'Can not load data.'
          );
          this.$router.push('/');
        });
      });
    }
  },
  components: {
    accountForm
  },
  computed: {
    ...mapGetters({
      roles: 'systemRoles',
      accountModel: 'account',
      userId: 'userId'
    })
  },
  methods: {
    ...mapActions({
      saveAccount: SAVE_ACCOUNT,
      loadAccountForm: LOAD_ACCOUNT_FORM,
      loadAccount: LOAD_ACCOUNT
    }),
    submitForm() {
      this.saveAccount({
        accountId: this.$route.params.id,
        account: this.account
      })
        .then(() => {
          alertify.success('Account Saved');
          if (+this.$route.params.id === this.userId) {
            this.$router.push(`/accounts/${this.userId}/view`);
          } else {
            this.$router.push(`/accounts/`);
          }
        })
        .catch(err => {
          if (err.status == 422 && err.data.errors) {
            alertify.error(
              'Some validation errors have been detected. Please check error messages.'
            );

            let errors = map(err.data.errors, (messages, fullName) => {
              let chunks = fullName.split('.');
              let name = chunks.splice(chunks.length - 1, 1);
              let scope = chunks.join('-');

              return {
                field: name[0],
                msg: messages.join('\n'),
                rule: 'required',
                scope: scope,
                fullName: fullName
              };
            });

            this.$set(this, 'errorsList', errors);
          } else {
            let msg =
              err.body.code == -1
                ? 'Sandbox email error.'
                : err.body.error ? err.body.error : 'Something wrong.';
            alertify.error(msg);
          }
        });
    },
    accountChanged(account) {
      this.account = account;
    },
    applyModel(model) {
      let account = {
        role: model.roles?model.roles[0].name:null,
        user: {
          first_name: model.first_name,
          last_name: model.last_name,
          title: model.title,
          email: model.email,
          phone: model.phone,
          terms: model.terms ? model.terms : '',
          production_notes: model.production_notes,
          company_id: model.company ? model.company.id : '',
          password: '',
          is_test: model.is_test
        }
      };
      if (model.shipping_addresses) {
        account.shipping_address = model.shipping_addresses[0];
      }
      if (model.billing_addresses) {
        account.billing_address = model.billing_addresses[0];
      }
      if (model.printer) {
        account.printer = model.printer;
      }

      this.accountChanged(account);
    }
  },
  mounted() {
    if (checkRoles(this.$store.getters.roles, ['customer'], true)) {
      this.$set(this.options, 'hideRole', true);
    }
    this.processing = true;
    this.loadAccountForm().then(() => {
      this.processing = false;
      this.loadAccount(this.$route.params.id).catch(err => {
        alertify.error(
          err.body.message ? err.body.message : 'Can not load data.'
        );
        this.$router.push('/');
      });
    });
  }
};
</script>

<style lang="scss" scoped>
.account-view-cnt{
  .header{
    .title{
      padding-bottom: 2rem;
      padding-top: 2rem;
    }
  }
}
</style>
