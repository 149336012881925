<template>
  <div>
    <button v-if="showBtn"
            class="button is-small is-pulled-right"
            @click="addCustomersToCommunication">
      Save
    </button>
    <h5 class="subtitle is-6">Add to Communication</h5>
    <ul class="order-communication-related">
      <li v-for="customer in customers">
        <div class="field">
          <div class="control">
            <label class="checkbox cols-flex">
              <div>
                <input v-if="order.user.id == customer.id"
                       disabled checked="checked" class="checkbox-field"type="checkbox">
                <input v-else
                       class="checkbox-field"
                       type="checkbox"
                       name="customersForCommunications"
                       :value="customer.id"
                       v-model="customersForCommunications">
              </div>
              <div class="customer-info">
                <p class="has-text-weight-bold customer-name">{{customer.first_name}} {{customer.last_name}}</p>
                <span>{{customer.email}}</span>
              </div>
            </label>
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<style lang="scss" scoped>
  .checkbox {
    align-items: flex-start;
  }

  .checkbox-field {
    position: relative;
    top: -1px;
  }

  .customer-info {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .customer-name {
    display: inline-block;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
    width: 100%;
  }
</style>

<script>
  import Vue from 'vue';
  import bus from '../../../bus';

  import apiService from '../../../services/api.service';
  import alertify from 'alertify.js';
  import {
    isEqual
  } from 'lodash';

  export default {
    name: 'add-to-messages',
    props: ['id', 'customers', 'orderDetails'],
    data() {
      return {
        customersForCommunications: [],
        initialCustomers: [],
      }
    },
    computed: {
      showBtn() {
        return !isEqual(this.customersForCommunications.sort(), this.initialCustomers.sort());
      },
      order() {
        return this.orderDetails && this.orderDetails[0] || null;
      },
      accountId() {
        return this.order.account_id;
      },
    },
    methods: {
      addCustomersToCommunication() {
        let data = {
          customers: this.customersForCommunications
        };

        return apiService.addCustomersToOrderCommunication(this.id, data).then(response => {
          bus.$emit('updated-accounts-by-order');
          this.initialCustomers = this.customersForCommunications;
          alertify.success('Customers in communication changed');
        }, (response) => {
          alertify.error(response.body && response.body.message ? response.body.message : 'Could not add customers to communication');
        })
      }
    },
    mounted() {
      if (this.order && this.order.customers_related_for_communication) {
        this.order.customers_related_for_communication.forEach(customer => {
          this.initialCustomers.push(customer.id);
          this.customersForCommunications.push(customer.id);
        });
      }
    }
  };
</script>
