<template>
  <div class="page-company">
    <div class="page-company__container" style="padding-top: 1rem;">
      <div class="columns">
        <div class="column is-4">
          <div class="el-box company-address" v-if="company.shipping_address">
            <div class="el-box__section">
              <h3 class="el-box__title">Shipping Address</h3>
              <div class="el-box__info">
                <p>
                  {{ company.shipping_address.address_line_1 }}
                  <template v-if="company.shipping_address.address_line_2">
                    , {{ company.shipping_address.address_line_2 }}
                  </template>
                </p>
                <p>
                  {{ company.shipping_address.city }}
                  <template v-if="company.shipping_address.state">
                    , {{ company.shipping_address.state.name }}
                  </template>
                  {{ company.shipping_address.postal_code }}
                </p>
                <p v-if="company.shipping_address.country">{{ company.shipping_address.country.name }}</p>
              </div>
            </div>
            <div class="el-box__section"
                 v-if="company.production_notes"
                 v-roles="['admin','csr','production-coordinator','purchaser','art-producer','printer']"
            >
              <h3 class="el-box__title">Production notes</h3>
              <div class="el-box__info">
                <p style="word-break: break-word"><i>{{ company.production_notes }}</i></p>
              </div>
            </div>
          </div>
          <div class="el-box company-avatax-info" style="margin-top: 1rem" v-if="company.avatax_id">
            <div class="el-box__section">
              <h3 class="el-box__title">AvaTax Info</h3>
              <div class="el-box__info">
                <p>
                  Customer ID: <b>{{ company.avatax_id }}</b>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="column is-8">
          <div class="el-box company-accounts">
            <header class="el-box__header">
              <h3 class="el-box__title">Company Accounts</h3>
              <button
                class="button"
                @click="customersPopup"
                v-roles="['admin', 'csr', 'production-coordinator', 'purchaser', 'art-producer']"
                v-if="companyCustomers.length"
              >Add Customer
              </button>
            </header>
            <table class="el-table" v-if="companyCustomers.length">
              <colgroup>
                <col>
                <col>
                <col>
                <col width="1%">
              </colgroup>
              <thead>
              <tr>
                <th>Name</th>
                <th>Email</th>
                <th>Username</th>
                <th>&nbsp;</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="entry, rowIndex in companyCustomers" :key="rowIndex">
                <td>{{ `${entry.first_name} ${entry.last_name}` }}</td>
                <td class="has-word-break">{{ entry.email }}</td>
                <td class="has-word-break">{{ entry.title }}</td>
                <td class="has-nowrap">
                  <div class="field is-grouped">
                  <router-link
                    class="button is-outlined is-info"
                    :to="'/accounts/' + entry.id + '/view'"
                    v-roles="['admin']"
                  >View
                  </router-link>
                  <router-link
                    class="button is-outlined is-info"
                    :to="'/accounts/' + entry.id + '/edit'"
                    v-roles="['admin']"
                  >Edit
                  </router-link>
                  <button
                    class="button is-outlined is-danger btn__delete"
                    v-roles="['admin', 'csr', 'production-coordinator', 'purchaser', 'art-producer']"
                    @click="removeCustomerFromCompany(entry.id)"
                  >Remove
                  </button>
                  </div>
                </td>
              </tr>
              </tbody>
            </table>
            <template v-else>
              <p>There are no customers</p>
              <button
                class="button"
                @click="customersPopup"
                v-roles="['admin', 'csr', 'production-coordinator', 'purchaser', 'art-producer']"
              >Add Customer
              </button>
            </template>

            <paginator
              :pagesNumber="customersPagesNumber"
              :prevPageUrl="customersPrevPageUrl"
              :nextPageUrl="customersNextPageUrl"
              :currentPage="customersCurrentPage"
              :getListItems="getCompanyCustomers"
              v-if="companyCustomers"
            ></paginator>
          </div>
        </div>
      </div>

    </div>
    <div class="company-content">
      <h5 class="subtitle is-marginless has-text-weight-bold subtitle-with-whitespace">
        Company Orders</h5>
      <template v-if="isCustomer">
        <table class="table">
          <thead>
          <tr>
            <th>Order</th>
            <th>Account</th>
            <th>Created On</th>
            <th>Accepted On</th>
            <th v-role-access="['admin', 'csr', 'production-coordinator', 'purchaser', 'art-producer']">Ship By</th>
            <th>Qty.</th>
            <th v-roles="['admin', 'csr', 'production-coordinator', 'purchaser', 'art-producer', 'customer']">Total</th>
            <th>Status</th>
            <th>Reference</th>
            <th></th>
            <th>Orders Actions</th>
            <th colspan="2"></th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(entry, rowKey) in orders">
            <th>
              <p>
                <router-link v-if="entry.is_approved"
                             style="text-decoration: underline;"
                             :to="`/customer/orders/${entry.id}/details`">
                  {{ entry.is_order ? entry.id : entry.id + ' (E)' }}
                </router-link>
                <router-link v-else
                             style="text-decoration: underline;"
                             :to="`/customer/estimates/${entry.id}/approve`">
                  {{ entry.is_order ? entry.id : entry.id + ' (E)' }}
                </router-link>
              </p>
            </th>
            <th>{{ entry.contact_firstname }} {{ entry.contact_lastname }}</th>
            <td>{{ entry.order_date }}</td>
            <td>{{ (entry.estimate_becomes_to_order_at && formatDate(entry.estimate_becomes_to_order_at)) || '-' }}</td>
            <td v-role-access="['admin', 'csr', 'production-coordinator', 'purchaser', 'art-producer']">
              {{ entry.ship_by_date }}
            </td>
            <td>{{ entry.quantity | itemsNumberFormat }}</td>
            <td v-roles="['admin', 'csr', 'production-coordinator', 'purchaser', 'art-producer', 'customer']">
              {{ getEntryTotal(entry) | price }}
            </td>
            <td v-html="getPaymentStatus(entry)"></td>
            <td>
              <div class="flex flex__horizontal-cols cols-flex">
                <div><span style="word-break: break-word;">{{ entry.name ? entry.name : '---' }}</span></div>
                <div>
                  <button @click="showOrderNameDialog(entry)" class="button btn is-ghost">
                    <i class="ff-compose"></i>
                  </button>
                </div>
              </div>
            </td>
            <td>
              <p v-if="entry.need_payment">
                <router-link v-if="!isPaid(entry) && entry.is_approved" class="button is-success"
                             :to="'/customer/orders/' + entry.id + '/invoice/' + entry.pending_invoice.link_hash + '/'">
                  Pay Now
                </router-link>
              </p>
            </td>
            <td>
              <p v-if="getMockLink(entry)">
                <router-link class="button"
                             :to="getMockLink(entry)">
                  View/Approve Mocks
                </router-link>
              </p>
            </td>
            <td>
              <p>
                <router-link v-if="entry.is_approved"
                             class="button"
                             :to="`/customer/orders/${entry.id}/details`">
                  Details
                </router-link>
                <router-link v-else class="button"
                             :to="`/customer/estimates/${entry.id}/approve`">
                  Review Estimate
                </router-link>
              </p>
            </td>
            <td>
            </td>
          </tr>
          </tbody>
        </table>
        <paginator
          :pagesNumber="ordersPagesNumber"
          :prevPageUrl="ordersPrevPageUrl"
          :nextPageUrl="ordersNextPageUrl"
          :currentPage="ordersCurrentPage"
          :getListItems="getCompanyOrders"
          v-if="orders"
        ></paginator>
      </template>
      <template v-else-if="!isCustomer">
        <orders :company-id="companyId">
        </orders>
      </template>
      <p v-else>There are no orders</p>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.company-content {
  .subtitle-with-whitespace {
    margin-bottom: 10px !important;
  }
}
</style>

<script>
import alertify from 'alertify.js';
import moment from 'moment';

import {checkRoles} from '@/router/checkRoles';
import apiService from '../../../../services/api.service';
import popup from '../../../../modules/popupper';
import OrderNamePopup from '../../../partials/popups/order-name-popup';

import paginator from '../../../common/paginator';
import Orders from './../orders';
import {PAYMENT_STATUS_PAID, PAYMENT_STATUSES_HTML} from '@/helpers/orders';
import {APPROVED_STATUS, PENDING_STATUS} from '@/helpers/revision';
import CustomersPopupComponent from './../popups/customers.vue';

export default {
  name: 'accounts-view',
  data() {
    return {
      company: [],
      companyCustomers: [],
      orders: [],
      customersPrevPageUrl: null,
      customersNextPageUrl: null,
      customersCurrentPage: 1,
      customersPagesNumber: 1,
      ordersPrevPageUrl: null,
      ordersNextPageUrl: null,
      ordersCurrentPage: 1,
      ordersPagesNumber: 1
    };
  },
  watch: {
    $route(to, from) {
      this.loadCompanyData();
    }
  },
  computed: {


    companyId() {
      return this.$route.params.id;
    },
    isCustomer() {
      return checkRoles(this.$store.getters.roles, ['customer'], true);
    },
  },
  components: {
    Orders,
    paginator,
    OrderNamePopup,
  },
  mounted() {
    this.loadCompanyData();
  },
  methods: {
    getMockLink(order) {
      let link = '';
      let design = order.designs.find(design => {
        if (design.mocks && design.mocks.length) {
          return design.mocks.find(m => {
            return (
              +m.status === +PENDING_STATUS || +m.status === +APPROVED_STATUS
            );
          });
        }
      });
      if (design && design.mocks) {
        let mocks = cloneDeep(design.mocks);
        let mock = mocks.reverse().find(m => {
          return (
            +m.status === +PENDING_STATUS || +m.status === +APPROVED_STATUS
          );
        });
        if (mock && mock.design_id) {
          link = `/revision/${order.id}/${mock.design_id}/${mock.id}`;
        }
      }
      return link;
    },
    showOrderNameDialog(entry) {
      popup({
        title: 'Change order name',
        additional: {
          orderId: entry.id,
          orderName: entry.name,
          callback: this.onOrderNameChanged
        },
        bodyComponent: OrderNamePopup,
        showButtons: true,
        submitLabel: 'Submit Name',
        runCallback: true
      });
    },
    onOrderNameChanged(id, name) {
      this.orders = this.orders.map(o => {
        if (o.id === id) {
          o.name = name;
        }
        return o;
      });
    },
    getPaymentStatus(entry) {
      if (entry && entry.payment_status) {
        return PAYMENT_STATUSES_HTML[entry.payment_status];
      } else {
        return PAYMENT_STATUSES_HTML[0];
      }
    },
    isPaid(entry) {
      return entry && entry.payment_status === PAYMENT_STATUS_PAID;
    },
    formatDate(date) {
      const instance = moment(date);
      if (!instance.isValid()) return date;
      return moment(date).format('MM/DD/YY');
    },
    deleteCompany() {
      alertify
        .okBtn('Delete')
        .confirm('Are you sure that you want to delete this company?', ev => {
          return apiService.deleteCompany(this.companyId).then(data => {
            if (data.status == 'success') {
              this.$router.push('/companies');
              alertify.success(data.message);
            } else {
              alertify.error(data.message);
            }
          });
        });
    },
    getCompanyDetails() {
      return apiService.getCompany(this.companyId).then(
        response => {
          this.company = response.company;
        },
        response => {
          let msg = response.body
            ? response.body.message
            : 'Could not load company details';
          alertify.error(msg);
          this.$router.push('/');
        }
      );
    },
    customersPopup() {
      popup({
        title: 'Customers',
        bodyComponent: CustomersPopupComponent,
        additional: {
          onSelected: this.assignCustomersToCompany
        },
        modalCardLong: true,
        showButtons: true,
        runCallback: true
      });
    },
    assignCustomersToCompany(ids) {
      return apiService
        .assignCompanyCustomers(this.companyId, {
          customers: ids
        })
        .then(
          response => {
          },
          response => {
            alertify.error(
              response.body && response.body.message
                ? response.body.message
                : 'Could not add customers to company'
            );
          }
        )
        .then(() => this.getCompanyCustomers())
        .then(() => this.getCompanyOrders());
    },
    getCompanyCustomers(page, url) {
      if (page === undefined) page = this.customersCurrentPage;

      if (url === undefined) {
        url = `/api/companies/customers/${this.companyId}?page=${page}`;
      }

      return this.$http.get(url).then(
        response => {
          this.companyCustomers = response.data.customers_data.data;
          this.customersPrevPageUrl =
            response.data.customers_data.prev_page_url;
          this.customersNextPageUrl =
            response.data.customers_data.next_page_url;
          this.customersCurrentPage = response.data.customers_data.current_page;
          this.customersPagesNumber = response.data.customers_data.last_page;
        },
        response => {
          let msg = response.body
            ? response.body.message
            : 'Could not load company customers';
          alertify.error(msg);
        }
      );
    },
    getCompanyOrders(page, url) {
      if (page === undefined) page = 1;
      if (url === undefined) {
        url = `/api/companies/customers_orders/${this.companyId}?page=${page}`;
      }

      return this.$http.get(url).then(
        response => {
          this.orders = response.data.orders.data;
          this.ordersPrevPageUrl = response.data.orders.prev_page_url;
          this.ordersNextPageUrl = response.data.orders.next_page_url;
          this.ordersCurrentPage = response.data.orders.current_page;
          this.ordersPagesNumber = response.data.orders.last_page;
        },
        response => {
          let msg = response.body
            ? response.body.message
            : 'Could not load company orders';
          alertify.error(msg);
        }
      );
    },
    removeCustomerFromCompany(customerId) {
      alertify
        .okBtn('Remove')
        .confirm(
          'Are you sure that you want to remove customer from this company?',
          ev => {
            return apiService
              .removeCustomerFromCompany(customerId)
              .then(data => {
                if (data.status == 'success') {
                  let current_page = this.customersCurrentPage;
                  if (this.companyCustomers.length == 1 && current_page > 1) {
                    current_page -= 1;
                  }
                  this.getCompanyCustomers(current_page);
                  this.getCompanyOrders();
                  alertify.success(
                    'Customer was successfully removed from the company'
                  );
                } else {
                  alertify.error(data.message);
                }
              });
          }
        );
    },
    loadCompanyData() {
      this.getCompanyDetails();
      this.getCompanyCustomers();

      if (this.isCustomer) {
        this.getCompanyOrders();
      }
    },
    getEntryTotal(entry) {
      const estimate = JSON.parse(entry.estimate);
      return estimate
        ? +estimate.total.price +
        +(estimate.total.upcharge ? estimate.total.upcharge : 0)
        : entry.total;
    }
  },
};
</script>
