<template>
  <div class="apparel-design">
    <!-- .location-fieldset -->
    <h3  class="title is-3" v-if="!isBlankSamples">
      <template v-if="isEmbroidery">Embroidery</template>
      <template v-else>Print</template>
        Locations
    </h3>
    <fieldset v-if="!isBlankSamples" v-for="(location, key) in design.locations" class=" location-fieldset" :key="'location' + key">
      <h5 class="subtitle is-5">Location #{{ parseInt(key)+1 }}</h5>
      <div class="columns">
        <div class="column is-6">
          <form-select v-model="location.location"
            name="location" :scope="getScope(designKey, 'locations', key)"
            :isHierarchical="true"
            :options="{all: locationsList}"
            :disabled="isDisabled" />
        </div>
        <div v-if="!isEmbroidery" class="column is-6">
          <form-select v-model="location.colors_number"
            name="colors_number" label="Number of Colors"
            :scope="getScope(designKey, 'locations', key)"
            :addEmpty="false"
            :disabled="isDisabled"
            :options="{all: numOfColors}" />
        </div>
      </div>
      <div class="columns">
        <template v-if="isEmbroidery">
          <div class="column is-6">
            <form-select v-model="location.depth"
                         name="depth"
                         :scope="getScope(designKey, 'locations', key)"
                         :disabled="isDisabled"
                         :options="{all: depthList}" />
          </div>
          <div class="column is-6">
            <form-input v-model="location.thread_colors"
                        :required="false"
                        name="thread_colors"
                        :scope="getScope(designKey)"
                        :rules="''"
                        label="Thread colors" />
          </div>
        </template>
        <div v-else class="column is-6">
          <label class="label" :for="getScope(designKey, 'locations', key) + key">PMS Colors</label>
          <div class="control">
            <pms-color-multiselect v-model="location.colors"
              :id="getScope(designKey, 'locations', key) + key"
              :scope="getScope(designKey, 'locations', key)"
              :disabled="isDisabled"
              :rules="''"></pms-color-multiselect>
          </div>
        </div>
      </div>
      <div class="columns">
        <div class="column is-3">
          <form-input v-model="location.width"
            name="width"
            :scope="getScope(designKey, 'locations', key)"
            type="number"
            rules="between:0.00,100"
            :required="false"
            step="0.01"
            min="0.00"
            max="100"
            @input="setJumboPrint(key)"
            placeholder="Enter width (in inches)"
            :disabled="isDisabled" />
        </div>
        <div class="column is-3">
          <form-input v-model="location.height"
            name="height"
            type="number"
            rules="between:0.00,100"
            :scope="getScope(designKey, 'locations', key)"
            :required="false"
            step="0.01"
            min="0.00"
            max="100"
            @input="setJumboPrint(key)"
            placeholder="Enter height (in inches)"
            :disabled="isDisabled" />
        </div>
        <div v-if="!isEmbroidery" class="column is-4 flex flex__vertical-center">
          <div class="control form-checkbox-container">
            <label :for="'location-jumbo-print-' + designKey + key" class="">
              <input disabled="disabled"
                     v-model="location.has_jumbo_print"
                     :id="'location-jumbo-print-' + designKey + key"
                     type="checkbox"
                     class="checkbox" />
              Jumbo Print?
            </label>
          </div>
        </div>
        <div v-else class="column is-6">
          <form-select v-model="location.stitches_id"
                       name="stitches_id"
                       label="Stitches"
                       :scope="getScope(designKey, 'locations', key)"
                       :disabled="isDisabled"
                       :options="{all: stitchesList}" />
        </div>
      </div>
      <div v-if="!isEmbroidery" class="columns">
        <div  class="column is-4">
          <form-select v-model="location.ink_type"
            name="ink_type"
            label="Ink Type"
            :scope="getScope(designKey, 'locations', key)"
            :options="{all: inkTypes}"
            :disabled="isDisabled" />
        </div>
        <div class="column is-8">
          <label class="label" :for="getScope(designKey, 'locations', key) + key">Process Add-Ons</label>
          <div class="control">
            <multiselect placeholder="Select Process Add-Ons"
              v-model="location.process_addons"
              :id="getScope(designKey, 'locations', key) + key"
              :multiple="true"
              :options="processAddonsOptions"
              :required="false"
              :rules="''"
              data-vv-name="process_addons"
              data-vv-value-path="value"
              data-vv-as="Process Add-Ons"
              :class="{'is-danger': errors.has(getScope(designKey, 'locations', key) + '.process_addons')}"
              track-by="key"
              label="label"
              :disabled="isDisabled">
            </multiselect>
            <span v-show="errors.has(getScope(designKey, 'locations', key) + '.process_addons')" class="help is-danger is-visible">
              {{errors.first(getScope(designKey, 'locations', key) + '.process_addons')}}
            </span>
          </div>
        </div>
      </div>
      <a v-if="objSize(design.locations) > 1" @click="dropRow('locations', key)" class="button is-danger is-small ">
        <span>Delete</span>
        <i class="icon icon__bigger ff-cross-mark"></i>
      </a>
    </fieldset>

    <div class="btn-cnt add-location-btn-cnt" v-if="!isBlankSamples">
      <button type="button" @click="addRow('locations')" class="button" :disabled="isDisabled">Add Location</button>
    </div>

    <hr>

    <!-- .garments-fieldset -->
    <h3 class="title is-3">Garments</h3>
    <fieldset v-for="(garment, key) in design.garments"
      :key="'garment' + key" class="garments-fieldset">
      <h5 class="subtitle is-5">Product #{{parseInt(key) + 1}}</h5>
      <div class="columns">
        <div class="column is-4">
          <div class="products-list-suggestions">
            <label :for="'product-query-' + key" class="label">Search</label>
            <input v-model="products_query[key]"
              name="search_product"
              label="Find Product"
              :required="false"
              class="input"
              placeholder="Find Product"
              @keydown.enter="suggestionOnEnter($event, key)"
              @keydown.down="suggestionOnDown"
              @keydown.up="suggestionOnUp"
              @input="changeProductsQuery($event, key)"
              :id="'product-query-' + key"
              autocomplete="off"
              :disabled="isDisabled" />
            <ul class="dropmenu panel"
              :class="{'is-open':openSuggestion(key)}">
              <li v-for="(suggestion, index) in productMatches" :key="'suggestion' + index"
                :class="{'active': isSuggestionActive(index)}"
                @click="suggestionClick(index, key)">
                <span v-if="suggestion.apparel_brand">{{suggestion.apparel_brand}} </span>
                <span>{{suggestion.number}} - {{suggestion.label}}</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="columns">
        <div class="column is-4">
          <form-select v-model="garment.apparel_type"
            name="apparel_type"
            label="Shirt Type"
            :scope="getScope(designKey, 'garments', key)"
            :options="{all: shirtTypes}"
            @change="getBrands(key)"
            :disabled="isDisabled" />
        </div>
        <div class="column is-4">
          <form-select v-model="garment.brand"
            name="brand"
            :scope="getScope(designKey, 'garments', key)"
            :options="{all: garment.brandsData}"
            @change="getProducts(key)"
            :disabled="isDisabled" />
        </div>
      </div>
      <div class="columns">
        <div class="column is-4">
          <form-select v-model="garment.product"
            name="product"
            :scope="getScope(designKey, 'garments', key)"
            :options="{all: garment.productsData}"
            @change="getColorsSizes(key)" :disabled="isDisabled" />
        </div>
        <div class="column is-4">
          <form-select v-model="garment.color"
            name="color"
            :scope="getScope(designKey, 'garments', key)"
            :options="{all: garment.colorsData}"
            :disabled="isDisabled" />
        </div>
      </div>

      <h5 class="subtitle is-5" v-if="garment.sizesData">Sizes</h5>
      <div class="size-fields columns" v-if="garment.sizesData">
        <div class="column is-12">
          <div class="columns">
            <div v-for="(garmentSize, sizeKey) in sizesList"
                 v-if="garment.sizesData.length && garment.sizesData.includes(garmentSize)"
                 :key="'suggestion' + sizeKey"
                 class="column controls is-2">
              <form-input v-model="garment.sizes[garmentSize]"
                          :name="'sizes'" :label="garmentSize" type="number"
                          :rules="'required|min_value:0|decimal:0'"
                          :scope="getScope('garments', key)"
                          step="1" min="0"
                          :showErrors="false"
                          placeholder="0" @input="onGarmentCountChanged()" />
            </div>

            <div class="column controls is-2" v-if="garment.sizesData">
              <form-input v-model="garment.count"
                name="total" type="number" :disabled="true"
                :showErrors="false"
                :rules="'required|min_value:0|decimal:0'"
                :scope="getScope('garments', key)"
                step="1" min="0" placeholder="0"/>
            </div>
          </div>
          <form-input  v-if="garment.sizesData"
            type="hidden" name="garment_sizes"
            v-model="garment.sizes"
            :scope="getScope('garments', key)"
            :rules="`minimum_number_of_garment:${getMinGarmentsQuantity(design)}`" />
        </div>
      </div>

      <div class="details" v-if="getProductData(key).fabric || getProductData(key).origin || getProductData(key).number">
        <strong>Garment details:</strong>
        <br />
        <ul>
          <li v-if="getProductData(key).number">Number - {{getProductData(key).number}}</li>
          <li v-if="getProductData(key).fabric">Fabric - {{getProductData(key).fabric}}</li>
          <li v-if="getProductData(key).origin">Origin - {{getProductData(key).origin}}</li>
        </ul>
      </div>
      <a v-if="objSize(design.garments) > 1"
        @click="dropRow('garments', key)"
        class="button is-danger is-small ">
        <span>Delete</span>
        <span class="icon is-medium">
          <i class="ff-lg ff-cross-mark"></i>
        </span>
      </a>
    </fieldset>

    <div class="btn-cnt">
      <button type="button"
        @click="addRow('garments')"
        class="button"
        :disabled="isDisabled">Add Garment</button>
    </div>
    <hr>

    <div v-if="!isEmbroidery && !isBlankSamples" class="columns">
      <div class="column is-4">
        <form-input v-model="design.ink_color_changes"
          :required="false"
          type="number"
          rules="min_value:0"
          step="1"
          min="0"
          name="ink_color_changes"
          :scope="getScope(designKey)"
          label="Ink Color Changes" />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.add-location-btn-cnt {
  margin-top: 32px;
}
.color-box {
  display: inline-block;
  width: 15px;
  height: 15px;
  margin: 0 5px 0 0px;
  border: 1px solid #6b6b6b;
}
</style>

<script>
import Vue from 'vue';
import bus from '../../../bus';
import apiService from '../../../services/api.service';
import { isEmpty, forEach, isNumber, cloneDeep, debounce } from 'lodash';
import notify from '../../../modules/notifier';

import formInput from '../../common/form/input';
import formSelect from '../../common/form/select';
import pmsColorMultiselect from '../../common/form/pms-color-multiselect';

import {
  JUMBO_PRINT_WIDTH,
  JUMBO_PRINT_HEIGHT
} from '../../../helpers/design-location';

let emptyDesignModel = {
  ink_color_changes: 0,
  locations: [
    {
      id: '',
      location: '',
      depth: '',
      stitches_id: null,
      thread_colors: '',
      location_group: '',
      colors_number: '1',
      colors: [],
      width: '',
      height: '',
      has_jumbo_print: false,
      ink_type: '',
      process_addons: [],
      process_addons_obj: []
    }
  ],
  garments: [
    {
      id: '',
      apparel_type: '',
      brand: '',
      product: '',
      color: '',
      sizes: {},
      price: ''
    }
  ]
};

export default {
  props: {
    getScope: {
      type: Function,
      default: () => () => ''
    },
    designCommon: {
      type: Object,
      default: () => {}
    },
    designSpecific: Object,
    account: Object,
    finishings: Array,
    finishingOptions: Array,
    designKey: Number,
    isEmbroidery: Boolean,
    upsServices: Array,
    deliveryTypes: Array,
    isEdit: Boolean,
    resetKey: Number,
    accountId: Number,
    index: Number
  },
  data() {
    return {
      selected: null,
      numOfColors: {},
      inkTypes: [],
      depthList: [],
      stitchesList: [],
      processAddons: [],
      processAddonsOptions: [],
      processAddonsUpcharges: {},
      locationsList: [],
      shirtTypes: [],
      garmentsTotal: [],
      upcharges: {},
      finishing_prices: {},
      summary: {},
      design: cloneDeep(
        Object.assign({}, emptyDesignModel, this.designSpecific)
      ),
      products_query: [],
      productMatches: [],
      currentSuggestion: 0,
      suggestionsOpen: [],
      currentFoundProduct: {},
      minEmbroideryGarmentQty: 24,
      blankSamplesMinGarmentQnt: 1,
      sizesList: []
    };
  },
  components: {
    formInput,
    formSelect,
    pmsColorMultiselect
  },
  computed: {
    isDisabled() {
      return this.designCommon.printed_before_order_id ||
        this.designCommon.printed_before_design_id
        ? 'disabled'
        : false;
    },
    isBlankSamples() {
      return (
        (this.designCommon && this.designCommon.service === 'blank') || false
      );
    }
  },
  watch: {
    finishings: {
      handler(val, oldVal) {
        this.calculateSummary();
      },
      deep: true
    },
    designCommon: {
      handler(design, oldDesign) {
        this.calculateSummary();
      },
      deep: true
    },
    design: {
      handler(design, oldDesign) {
        this.calculateSummary();
      },
      deep: true
    },
    summary: {
      handler(summary, oldSummary) {
        this.$emit('summary-changed', this.designKey, summary);
      },
      deep: true
    },
    resetKey(key) {
      this.calculateSummary();

      if (key !== null && key !== undefined && key === this.designKey) {
        this.setEmpty();
      }
    },
    pmsColors() {
      this.pmsColorsOption = this.pmsColors;
    }
  },
  methods: {
    getMinGarmentsQuantity({ locations, garments }) {
      let minQty = 24;
      if (this.isEmbroidery) {
        minQty = this.minEmbroideryGarmentQty;
      } else if (this.isBlankSamples) {
        minQty = this.blankSamplesMinGarmentQnt;
      } else {
        const numOfColors = this.numOfColors || [];
        minQty = Math.max.apply(
          Math,
          locations.map(function(o) {
            const selected = numOfColors.find(
              num => num.key === parseInt(o.colors_number)
            );
            return (selected && selected.min_order_qty) || 24;
          })
        );
      }
      const garmQty = garments.reduce((total, garm) => {
        return (
          total +
          Object.keys(garm.sizes).reduce(
            (sizesTot, size) => sizesTot + (parseInt(garm.sizes[size]) || 0),
            0
          )
        );
      }, 0);

      return minQty > garmQty ? parseInt(minQty) - parseInt(garmQty) : 0;
    },

    onGarmentCountChanged() {
      this.emitUpdateTagSetupsNumber();
      this.updatePackageQty();
      this.emitUpdateFinishingsPrice();
    },

    emitUpdateTagSetupsNumber() {
      bus.$emit('updateTagSetupsNumber');
    },

    updatePackageQty(e) {
      this.$emit('get-package-qty', true);
    },

    isSuggestionActive(index) {
      return index === this.currentSuggestion;
    },

    findApparelProducts(key) {
      return apiService
        .findApparelProducts(
          this.products_query[key],
          this.designCommon.service
        )
        .then(response => {
          this.productMatches = response.data;
        });
    },

    findApparelProductsDebounced(key) {
      debounce(key => this.findApparelProducts(key), 500)(key);
    },

    openSuggestion(key) {
      return (
        this.products_query[key] &&
        this.products_query[key] !== '' &&
        this.productMatches.length != 0 &&
        this.suggestionsOpen[key] === true
      );
    },

    changeProductsQuery(e, key) {
      if (
        this.suggestionsOpen[key] == false ||
        this.suggestionsOpen[key] == undefined
      ) {
        this.suggestionsOpen[key] = true;
        this.currentSuggestion = 0;
      }

      if (e.target.value.length == 0 || e.target.value.length >= 3) {
        this.findApparelProductsDebounced(key);
      } else {
        this.productMatches = [];
      }
    },

    suggestionClick(index, garment_key) {
      this.currentFoundProduct = this.productMatches[index];
      this.products_query[garment_key] = this.currentFoundProduct.label;
      this.suggestionsOpen[garment_key] = false;

      const garment = this.design.garments[garment_key];
      this.$set(
        garment,
        'apparel_type',
        this.currentFoundProduct.apparel_type_id
      );
      this.$set(garment, 'brand', this.currentFoundProduct.apparel_brand_id);

      this.fillFoundProductForm(garment_key);
      this.productMatches = [];
    },

    suggestionOnEnter(event, garment_key) {
      event.preventDefault();
      this.currentFoundProduct = this.productMatches[this.currentSuggestion];
      this.products_query[garment_key] = this.currentFoundProduct.label;
      this.suggestionsOpen[garment_key] = false;
      this.design.garments[
        garment_key
      ].apparel_type = this.currentFoundProduct.apparel_type_id;

      this.fillFoundProductForm(garment_key);

      this.productMatches = [];
    },

    suggestionOnUp() {
      if (this.currentSuggestion > 0) this.currentSuggestion--;
    },

    suggestionOnDown() {
      if (this.currentSuggestion < this.productMatches.length - 1) {
        this.currentSuggestion++;
      }
    },

    fillFoundProductForm(garment_key) {
      return this.getFoundProductBrands(garment_key)
        .then(response => this.getFoundProductProducts(garment_key))
        .then(response => this.getColorsSizes(garment_key))
        .then(response => {
          this.currentFoundProduct = {};
        });
    },

    cleanGarment(key) {
      const garment = this.design.garments[key];

      this.$set(garment, 'product', '');
      this.$set(garment, 'color', '');
      this.$set(garment, 'sizes', {});
      this.$set(garment, 'sizesData', {});
      this.$set(garment, 'productsData', []);
    },

    getFoundProductBrands(garment_key) {
      let garment = this.design.garments[garment_key];

      this.cleanGarment(garment_key);

      let brand_id = this.currentFoundProduct.apparel_brand_id;

      if (garment.apparel_type) {
        return apiService
          .getAccPricesApparelBrands(garment.apparel_type)
          .then(({ data }) => {
            this.$set(garment, 'brandsData', data);

            if (brand_id) {
              this.$set(garment, 'brand', brand_id);
            } else if (this.currentFoundProduct.apparel_brand_id) {
              this.$set(
                garment,
                'brand',
                this.currentFoundProduct.apparel_brand_id
              );
            }
          });
      } else {
        return Promise.resolve();
      }
    },

    getFoundProductProducts(garment_key) {
      let garment = this.design.garments[garment_key];
      let prod_type = garment.apparel_type;
      let brand = this.currentFoundProduct.apparel_brand_id
        ? this.currentFoundProduct.apparel_brand_id
        : garment.brand;

      this.cleanGarment(garment_key);

      if (prod_type && brand) {
        return apiService
          .getApparelProducts(prod_type, brand, this.accountId)
          .then(data => {
            this.$set(garment, 'productsData', data);
            if (this.currentFoundProduct.key) {
              this.$set(garment, 'product', this.currentFoundProduct.key);
            }
            return data;
          });
      } else {
        return Promise.resolve();
      }
    },

    getProductData(garment_key) {
      let garment = this.design.garments[garment_key];
      let productData = {};

      if (garment && garment.productsData) {
        productData =
          garment.productsData.filter(item => item.key == garment.product)[0] ||
          {};
      }

      return productData;
    },

    /**
     * Calculate summary
     */
    calculateSummary() {
      let totalGarments = 0;
      this.summary = {
        name: this.designCommon.name,
        type: this.designCommon.service,
        type_of_delivery: this.designCommon.type_of_delivery,
        locations: [],
        garments: [],
        total: {},
        printed_before: this.designCommon.printed_before,
        printed_before_order_id: this.designCommon.printed_before_order_id,
        printed_before_design_id: this.designCommon.printed_before_design_id,
        ink_color_changes: this.design.ink_color_changes,
        reference_order_id: this.designCommon.reference_order_id,
        reference_design_id: this.designCommon.reference_design_id,
        comment: this.designCommon.comment,
        ship_by_date: this.designCommon.ship_by_date,
        print_by_date: this.designCommon.print_by_date,
        in_hands_date: this.designCommon.in_hands_date
      };

      //count garments per product and total
      forEach(this.design.garments, (garment, index) => {
        let numGarments = 0;
        if (!isEmpty(garment.sizes)) {
          numGarments = Object.keys(garment.sizes)
            .map(key => garment.sizes[key])
            .reduce((a, b) => {
              a = !a ? 0 : +a;
              b = !b ? 0 : +b;
              return a + b;
            });
        }
        this.$set(garment, 'count', numGarments * 1);
        totalGarments += numGarments * 1;
      });

      this.garmentsTotal = totalGarments;

      let locationUpcharge = 0;
      let colorUpcharge = 0;
      let jumboUpcharge = 0;
      let inkTypeUpcharge = 0;
      let processAddonsUpcharge = 0;
      let inkColorChangeUpcharge = 0;
      let priceStr = '';
      //embroidery
      let stitchesUpcharge = 0;
      let depthUpcharge = 0;

      //upcharge locations colors and print types
      forEach(this.design.locations, (location, index) => {
        if (!location.location) {
          return;
        }

        //set jumbo
        if (!this.isEmbroidery) {
          location.has_jumbo_print = this.isJubmboPrintSizes(
            location.width,
            location.height
          );
        }

        //Add price for first location
        if (!this.isEmbroidery) {
          if (!parseInt(index)) {
            locationUpcharge += this.filterUpcharge(
              this.upcharges.first_print_location,
              2500
            );
            priceStr +=
              ' + ' +
              this.filterUpcharge(this.upcharges.first_print_location, 2500) +
              ' ( first location ) ';
          } else {
            //Add upcharge for additional locations
            locationUpcharge += this.filterUpcharge(
              this.upcharges.additional_location,
              2500
            );
            priceStr +=
              ' + ' +
              this.filterUpcharge(this.upcharges.additional_location, 2500) +
              ' ( additional location ) ';
          }
        }

        //Add upcharge for additional colors
        if (!this.isEmbroidery && location.colors_number - 1) {
          priceStr +=
            ' + ' +
            this.filterUpcharge(this.upcharges.add_colors, 2500) +
            ' * ' +
            (location.colors_number - 1) +
            ' ( colors ) ';
          colorUpcharge +=
            this.filterUpcharge(this.upcharges.add_colors, 2500) *
            (location.colors_number - 1);
        }

        //Add upcharge for jubmo
        if (location.has_jumbo_print) {
          priceStr += ' + ' + parseFloat(this.upcharges.jumbo) + ' ( jumbo ) ';
          jumboUpcharge += parseFloat(this.upcharges.jumbo);
        }

        //Add upcharge for ink type
        let inkTypeLabel = '';
        if (!this.isEmbroidery) {
          let inkTypeOption = this.inkTypes.filter(
            style => style.key == location.ink_type
          )[0];
          inkTypeLabel = (inkTypeOption && inkTypeOption.label) || '';

          let inkTypeUpchargePerColor =
            (inkTypeOption && inkTypeOption.upcharge * 1) || 0;
          inkTypeUpcharge +=
            Math.round(inkTypeUpchargePerColor * location.colors_number * 100) /
            100;
        }

        //location name
        let locLabel = '';
        forEach(this.locationsList, (locs, group) => {
          if (typeof locs[location.location] !== 'undefined') {
            locLabel = group + ' - ' + locs[location.location];
          }
        });

        if (!location.colors) location.colors = [];

        let selected_process_addons = [];
        if (!this.isEmbroidery) {
          forEach(location.process_addons, (process_addon, index) => {
            selected_process_addons.push(process_addon.label);

            if (!isNaN(this.processAddonsUpcharges[process_addon.key] * 1)) {
              processAddonsUpcharge +=
                this.processAddonsUpcharges[process_addon.key] * 1;
            }
          });
        }

        let stitchesLabel = '';
        if (this.isEmbroidery) {
          if (location.stitches_id && this.stitchesList.length) {
            let stitchesOption = this.stitchesList.filter(
              item => item.key == location.stitches_id
            )[0];
            stitchesLabel = (stitchesOption && stitchesOption.label) || '';
            stitchesUpcharge += this.filterUpcharge(
              stitchesOption.upcharge,
              2500
            );
            priceStr +=
              ' + ' +
              this.filterUpcharge(stitchesOption.upcharge, 2499) +
              ' ( stitchesUpcharge ) ';
          }
          if (location.depth && this.depthList.length) {
            let depthOption = this.depthList.filter(
              item => item.key == location.depth
            )[0];
            depthUpcharge += parseFloat(depthOption.upcharge);
            priceStr += ' + ' + depthUpcharge + ' ( depthUpcharge ) ';
          }
        }

        this.$set(this.summary.locations, index, {
          location: locLabel,
          colors_number: location.colors_number,
          colors: location.colors,
          thread_colors: location.thread_colors,
          depth: location.depth,
          stitches: stitchesLabel || '',
          ink_type: inkTypeLabel || '',
          process_addons_label: selected_process_addons.join(', '),
          width: parseFloat(location.width),
          height: parseFloat(location.height),
          has_jumbo_print: location.has_jumbo_print
        });
      });

      if (!this.isEmbroidery && this.upcharges.color_ink_change) {
        inkColorChangeUpcharge =
          Math.round(
            parseFloat(
              this.upcharges.color_ink_change * this.design.ink_color_changes
            ) * 100
          ) / 100;
      }

      let basePrice =
        locationUpcharge +
        colorUpcharge +
        jumboUpcharge +
        inkTypeUpcharge +
        processAddonsUpcharge +
        stitchesUpcharge +
        depthUpcharge;
      let designTotal = 0 + inkColorChangeUpcharge;

      //loop through garments and calculate upcharges
      forEach(this.design.garments, (garment, index) => {
        if (!isEmpty(garment.productsData) && garment.product) {
          let productInfo = garment.productsData.filter(
            item => item.key == garment.product
          )[0];
          let brandData =
            garment.brandsData.filter(item => item.key == garment.brand)[0] ||
            {};

          let productUpcharge = productInfo
            ? parseFloat(productInfo.upcharge)
            : 0;
          let sizes = Object.assign({}, garment.sizes);
          let label = productInfo ? productInfo.label : '';
          let garmentTotal = 0;
          let price = productUpcharge + basePrice;
          let sizesStr = '0';

          //calculate sizes
          forEach(garment.sizes, (amount, size) => {
            let lower = size.toLowerCase(),
              sizeUpcharge = 0;
            amount = !amount ? 0 : amount;
            if (typeof this.upcharges['size_' + lower] !== 'undefined') {
              sizeUpcharge = parseFloat(this.upcharges['size_' + lower]);
              sizesStr +=
                ' + ' +
                sizeUpcharge * amount +
                '(' +
                size +
                ': ' +
                sizeUpcharge +
                ' * ' +
                amount +
                ')';
            }

            sizes[size] = {
              cost:
                Math.round(
                  (parseFloat(price) + parseFloat(sizeUpcharge)) * 100
                ) / 100,
              qty: amount,
              total: Math.round((price + sizeUpcharge) * amount * 100) / 100
            };
            garmentTotal += sizes[size].total;
          });

          this.$set(this.summary.garments, index, {
            id: productInfo.key,
            name: label,
            number: productInfo.number,
            brand: brandData.label,
            description: productInfo.description,
            fabric: productInfo.fabric,
            origin: productInfo.origin,
            color: garment.color,
            qty: garment.count,
            sizes: sizes,
            total: Math.round(garmentTotal * 100) / 100
          });

          priceStr =
            productUpcharge +
            ' (for product) ' +
            priceStr +
            ' = ' +
            price +
            ' * ' +
            garment.count +
            ' = ' +
            price * garment.count;
          designTotal += this.summary.garments[index].total;
        }
      });

      let finishings_sumary = [];
      let finishings_grand_total = 0;

      forEach(this.finishings, (finishing, index) => {
        let finishing_upcharge = 0;
        let finishing_label = '';

        if (!(finishing.related_design instanceof Array)) {
          finishing.related_design = [];
        }
        if (
          finishing.related_design &&
          finishing.related_design.indexOf(this.designKey) !== -1
        ) {
          if (
            finishing.fields.price_code !== undefined &&
            this.finishing_prices[finishing.fields.price_code] !== undefined
          ) {
            finishing_upcharge = this.getFinishingsUpcharge(
              this.finishing_prices[finishing.fields.price_code],
              finishing
            );
          }

          let finishingOption = this.finishingOptions.filter(
            option => option.key == finishing.finishing_option
          )[0];
          if (finishingOption !== undefined) {
            finishing_label = finishingOption.label;
          }

          finishings_sumary.push({
            name: finishing.name,
            label: finishing_label,
            price: Math.round(finishing_upcharge * 100) / 100,
            fields: finishing.fields,
            finishing_option: finishing.finishing_option
          });
          finishings_grand_total += finishing_upcharge;
        }
      });
      this.$set(this.summary, 'finishings', finishings_sumary);

      this.$set(this.summary, 'total', {
        price: Math.round(designTotal * 100) / 100 || 0,
        qty: this.garmentsTotal,
        upcharge: Math.round(this.designCommon.upcharge * 100) / 100,
        upcharge_description: this.designCommon.upcharge_description,
        ink_color_changes_upcharge: inkColorChangeUpcharge
      });
    },

    filterUpcharge(items, top) {
      let basePrice = 0;

      forEach(items, (price, num_garments) => {
        let minMax = num_garments.split('-');
        if (
          (minMax[0] <= this.garmentsTotal &&
            minMax[1] >= this.garmentsTotal) ||
          (minMax.length == 1 &&
            +minMax[0] === +top &&
            this.garmentsTotal >= +top)
        ) {
          basePrice = parseFloat(price);
        }
      });

      return basePrice;
    },

    getFinishingsUpcharge(items, finishing) {
      let basePrice = 0;
      let setupPrice = 0;
      let finishing_fields = finishing.fields;
      let totalGarments =
        finishing.finishing_option === 'heat-pressed-tags'
          ? +finishing_fields.tag_garments_quantity
          : finishing.totalGarments;

      forEach(items, function(price, num_garments) {
        let minMax = num_garments.split('-');

        if (minMax.length == 1) {
          if (minMax[0] == '0' || minMax[0] == 'setup') {
            if (finishing_fields.tag_setups_number !== undefined) {
              setupPrice =
                +parseFloat(price) * (finishing_fields.tag_setups_number * 1);
            } else {
              setupPrice = 0;
            }
          } else if (minMax[0] == 1000 && totalGarments >= 1000) {
            basePrice += parseFloat(price * totalGarments);
          }
        } else if (minMax[0] <= totalGarments && minMax[1] >= totalGarments) {
          basePrice += parseFloat(price * totalGarments);
        }
      });

      return basePrice + setupPrice;
    },

    addRow(arrayName) {
      this.design[arrayName].push(cloneDeep(emptyDesignModel[arrayName][0]));
    },

    dropRow(arrayName, key) {
      this.design[arrayName].splice(key, 1);
    },

    objSize(object) {
      return Object.keys(object).length;
    },

    getBrands(key, notFlush) {
      let garment = this.design.garments[key];
      let type = garment.apparel_type;

      //flush previous
      if (!notFlush) {
        this.cleanGarment(key);
      }

      if (type) {
        return apiService.getGarmentBrandsByApparelType(type).then(data => {
          this.$set(garment, 'brandsData', data);
          return data;
        });
      } else {
        this.$set(garment, 'brandsData', []);
        return Promise.resolve(garment.brandsData);
      }
    },

    getProducts(key, notFlush, user_id) {
      if (user_id == undefined) user_id = this.accountId;
      let garment = this.design.garments[key];
      let type = garment.apparel_type;
      let brand = garment.brand;

      //flush previous
      if (!notFlush) {
        garment.product = '';
        garment.color = '';
        garment.sizes = {};
        garment.sizesData = {};
      }

      if (brand) {
        return apiService.getApparelProducts(type, brand, user_id).then(res => {
          this.$set(garment, 'productsData', res);
          return res;
        });
      } else {
        this.$set(garment, 'productsData', []);

        return Promise.resolve(garment.productsData);
      }
    },

    getColorsSizes(garmentKey, notFlush) {
      var garment = this.design.garments[garmentKey],
        product = garment.product;

      //flush previous
      if (!notFlush) {
        garment.color = '';
        garment.sizes = {};
        garment.sizesData = {};
      }
      if (product) {
        // get product prices
        let productInfo = garment.productsData.filter(
            item => item.key == product
          )[0],
          colors = [],
          sizes = [];

        if (productInfo) {
          sizes = productInfo.sizes.split(',').map(function(item) {
            return item.trim();
          });
        }

        if (!notFlush) {
          sizes.forEach((size, key) => {
            this.$set(garment.sizes, size, 0);
          });
        }

        if (productInfo) {
          productInfo.colors.split(',').forEach((color, key) => {
            colors.push({
              key: color.trim(),
              label: color.trim()
            });
          });
        }
        this.$set(garment, 'colorsData', colors);
        this.$set(garment, 'sizesData', sizes);
      } else {
        this.$set(garment, 'colorsData', []);
        this.$set(garment, 'sizesData', []);
      }
    },

    getDesignData(user_id) {
      return apiService
        .getDesignFormOptions(this.designCommon.service, user_id)
        .then(
          data => {
            const sourceData = data.form_data;
            this.numOfColors = sourceData.colors_numbers;
            this.locationsList = sourceData.locations;
            this.shirtTypes = sourceData.shirt_types;
            if (this.isEmbroidery) {
              this.depthList = sourceData.depth;
              this.stitchesList = sourceData.stitches;
            } else {
              this.inkTypes = sourceData.ink_types;
              this.processAddons = sourceData.process_addons;
            }

            // processAddonsOptions
            forEach(this.processAddons, (processAddon, index) => {
              this.processAddonsOptions.push({
                key: processAddon.key,
                label: processAddon.label
              });
              this.processAddonsUpcharges[processAddon.key] =
                processAddon.upcharge;
            });
          },
          function(response) {
            notify({
              message: 'Could not load info for designs',
              type: 'danger'
            });
          }
        );
    },

    getUpcharges(user_id) {
      return apiService
        .getApparelGarmentUpcharges(user_id, this.designCommon.service)
        .then(
          data => {
            return (this.upcharges = data);
          },
          function(response) {
            notify({
              message: 'Could not load prices',
              type: 'danger'
            });
          }
        );
    },

    getFinishingPrices(user_id) {
      return apiService.getApparelFinishingPrices(user_id).then(
        data => {
          return (this.finishing_prices = data);
        },
        function(response) {
          notify({
            message: 'Could not load finishing prices',
            type: 'danger'
          });
        }
      );
    },

    onValidate(validateScope) {
      if (validateScope) {
        this.$validator.validateAll(validateScope).catch(err => {});
      } else {
        this.$validator.validateScopes().catch(err => {});
      }
    },

    onClear() {
      if (this.$validator.$scopes && this.errors) {
        Object.keys(this.$validator.$scopes).map(scope =>
          this.errors.clear(scope)
        );
      }
    },

    setEmpty() {
      this.$set(this, 'design', cloneDeep(emptyDesignModel));
    },

    setDesign() {
      return {
        key: this.designKey,
        design: this.design
      };
    },

    onMountData(user_id) {
      this.getDesignData(user_id)
        .then(this.getUpcharges(user_id))
        .then(this.getFinishingPrices(user_id))
        .then(this.calculateSummary());

      this.design.garments.forEach((garment, key) => {
        if (!garment) return;
        let promise = Promise.resolve();
        if (garment.apparel_type) {
          promise = promise.then(() => {
            return this.getBrands(key, true);
          });
        }

        if (garment.brand) {
          promise = promise.then(() => {
            return this.getProducts(key, true, user_id);
          });
        }

        if (garment.product) {
          promise = promise.then(() => {
            return this.getColorsSizes(key, true);
          });
        }
      });
    },
    emitUpdateFinishingsPrice() {
      bus.$emit('updateFinishingsPrice');
    },
    setJumboPrint(locationKey) {
      if (
        !this.isEmbroidery &&
        this.design &&
        this.design.locations &&
        this.design.locations[locationKey]
      ) {
        let location = this.design.locations[locationKey];
        let has_jumbo_print = this.isJubmboPrintSizes(
          location.width,
          location.height
        );
        this.$set(location, 'has_jumbo_print', has_jumbo_print);
      }
    },
    isJubmboPrintSizes(width, height) {
      return width > JUMBO_PRINT_WIDTH || height > JUMBO_PRINT_HEIGHT;
    }
  },
  created() {
    bus.$on('validate', this.onValidate);
    bus.$on('clear', this.onClear);

    this.$watch(
      () => this.errors.errors,
      value => {
        bus.$emit('errors-changed', value);
      }
    );
  },
  mounted() {
    if (isNumber(this.resetKey) && this.resetKey === this.designKey) {
      this.setEmpty();
    }
    this.onMountData(this.accountId);

    bus.$on('order-set-account-info', user_id => {
      this.onMountData(user_id);
    });
    this.sizesList = cloneDeep(this.$store.getters.sortedGarmentsSizes);
  },
  destroyed() {
    bus.$off('order-set-account-info');
  }
};
</script>

<style>
.btn-cnt {
  display: block;
  margin-top: 10px;
}
</style>
