<template>
  <div>
    <section class="section">
      <div class="content">
        <h5 class="subtitle">Product</h5>
        <fieldset>
          <div class="columns">
            <div class="column is-4">
              <div class="products-list-suggestions">
                <input v-model="product_query"
                  name="search_product"
                  label="Find Product"
                  :required="false"
                  class="input"
                  placeholder="Find Product"
                  @keydown.enter='enter'
                  @keydown.down='down'
                  @keydown.up='up'
                  @input="change" />
                <ul class="dropmenu panel" :class="{'is-open':openSuggestion}">
                  <li v-for="(suggestion, index) in productMatches"
                    :class="{'active': isSuggestionActive(index)}"
                    @click="suggestionClick(index)"
                    autocomplete="off">
                    <span v-if="suggestion.apparel_brand">{{suggestion.apparel_brand.name}} </span>
                    <span>{{suggestion.number}} - {{suggestion.label}}</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </fieldset>
        <form @submit.prevent="submitProductForm('custom-price-product')">
          <fieldset>
            <p>or select one:</p>
            <div class="columns">
              <div class="column is-4">
                <form-select v-model="selectedProduct.type"
                  name="apparel_type"
                  label="Shirt Type"
                  :scope="'custom-price-product'"
                  :options="{all: formData.shirt_types}"
                  @change="getBrands()"
                  :rules="'required'" />
              </div>
              <div class="column is-4">
                <form-select v-model="selectedProduct.brand"
                  name="brand"
                  :scope="'custom-price-product'"
                  :options="{all: brandsData}"
                  @change="getProducts()"
                  :rules="'required'" />
              </div>
              <div class="column is-4">
                <form-select v-model="selectedProduct.product"
                  name="product"
                  :scope="'custom-price-product'"
                  :options="{all: productsData}"
                  :rules="'required'" />
              </div>
            </div>
            <div class="columns">
              <div class="column is-4" v-if="selectedProduct.regular_price">
                <span><strong>Reqular Price: </strong>{{selectedProduct.regular_price | price}}</span>
              </div>
              <div class="column is-4">
                <form-input v-model="selectedProduct.new_price"
                  type="number"
                  :rules="'required|decimal:2|min_value:0|max_value:9999.99'"
                  name="new_price"
                  :scope="'custom-price-product'"
                  label="New Price"
                  placeholder="0.00" />
              </div>
            </div>
          </fieldset>
          <br>
          <div class="control control-form-actions">
            <button type="submit"
              :class="{'is-loading': processing, 'button is-medium is-primary': true}"
              :disabled="processing">Save Product Info</button>
          </div>
        </form>
      </div>
    </section>

    <form @submit.prevent="submitForm()">
      <div v-if="formData.default_prices && !isEmbroidery">
        <accordion-item>
          <div slot="title">
            <h5 class="subtitle">Process Addons</h5>
          </div>

          <div slot="content">
            <table class="table is-fullwidth">
              <thead>
                <tr>
                  <th>Item</th>
                  <th>Price</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(process_addon, process_addon_id) in formData.default_prices.process_addons">
                  <td><strong>{{process_addon.label}}</strong></td>
                  <td :class="{ has_custom_price: newPrices['type=process_addon&item_id=' + process_addon_id] }">
                    <span><strong>Reqular Price: </strong>{{process_addon.upcharge | price}}</span>
                    <form-input v-model="newPrices['type=process_addon&item_id=' + process_addon_id]"
                      type="number"
                      name="new_price"
                      :scope="formScope"
                      label="New Price"
                      :required="false"
                      :rules="'decimal:2|min_value:0|max_value:9999.99'"
                      placeholder="0.00" />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </accordion-item>
      </div>

      <div v-if="formData.default_prices && !isEmbroidery">
        <accordion-item>
          <div slot="title">
            <h5 class="subtitle">Ink Types</h5>
          </div>

          <div slot="content">
            <table class="table is-fullwidth">
              <thead>
                <tr>
                  <th>Item</th>
                  <th>Price</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(ink_type, ink_type_id) in formData.default_prices.ink_types">
                  <td><strong>{{ink_type.label}}</strong></td>
                  <td :class="{ has_custom_price: newPrices['type=ink_type&item_id=' + ink_type_id] }">
                    <span><strong>Reqular Price: </strong>{{ink_type.upcharge | price}}</span>
                    <form-input v-model="newPrices['type=ink_type&item_id=' + ink_type_id]"
                      type="number"
                      name="new_price"
                      :scope="formScope"
                      label="New Price"
                      :required="false"
                      :rules="'decimal:2|min_value:0|max_value:9999.99'"
                      placeholder="0.00" />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </accordion-item>
      </div>

      <div v-if="formData.default_prices && !isEmbroidery">
        <accordion-item>
          <div slot="title">
            <h5 class="subtitle">Locations</h5>
          </div>

          <div slot="content">
            <table class="table is-fullwidth">
              <thead>
                <tr>
                  <th>Quantity</th>
                  <th>First Print Location</th>
                  <th>Additional Location</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(qty_segment, index) in formData.qty_segments">
                  <td>{{qty_segment | itemsNumberFormat}}</td>
                  <td :class="{ has_custom_price: newPrices['type=first_print_location&qty=' + qty_segment] }">
                    <span><strong>Reqular Price: </strong>{{formData.default_prices.first_print_location[qty_segment] | price}}</span>
                    <form-input v-model="newPrices['type=first_print_location&qty=' + qty_segment]"
                      type="number"
                      name="new_price"
                      :scope="formScope"
                      label="New Price"
                      :required="false"
                      :rules="'decimal:2|min_value:0|max_value:9999.99'"
                      placeholder="0.00" />
                  </td>
                  <td :class="{ has_custom_price: newPrices['type=additional_location&qty=' + qty_segment] }">
                    <span><strong>Reqular Price: </strong>{{formData.default_prices.additional_location[qty_segment] | price}}</span>
                    <form-input v-model="newPrices['type=additional_location&qty=' + qty_segment]"
                      type="number"
                      name="new_price"
                      :scope="formScope"
                      label="New Price"
                      :required="false"
                      :rules="'decimal:2|min_value:0|max_value:9999.99'"
                      placeholder="0.00" />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </accordion-item>
      </div>

      <div v-if="formData.default_prices && !isEmbroidery">
        <accordion-item>
          <div slot="title">
            <h5 class="subtitle">Additional Colors</h5>
          </div>

          <div slot="content">
            <table class="table is-fullwidth">
              <thead>
                <tr>
                  <th>Quantity</th>
                  <th>Price</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(qty_segment, index) in formData.qty_segments">
                  <td>{{qty_segment | itemsNumberFormat}}</td>
                  <td :class="{ has_custom_price: newPrices['type=add_colors&qty=' + qty_segment] }">
                    <span><strong>Reqular Price: </strong>{{formData.default_prices.add_colors[qty_segment] | price}}</span>
                    <form-input v-model="newPrices['type=add_colors&qty=' + qty_segment]"
                      type="number"
                      name="new_price"
                      :scope="formScope"
                      label="New Price"
                      :required="false"
                      :rules="'decimal:2|min_value:0|max_value:9999.99'"
                      placeholder="0.00" />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </accordion-item>
      </div>

      <div v-if="formData.default_prices">
        <accordion-item>
          <div slot="title">
            <h5 class="subtitle">Extra</h5>
          </div>

          <div slot="content">
            <table class="table is-fullwidth">
              <thead>
                <tr>
                  <th>Item</th>
                  <th>Price</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>2XL</td>
                  <td :class="{ has_custom_price: newPrices['type=size_2xl'] }">
                    <span><strong>Reqular Price: </strong>{{formData.default_prices.size_2xl | price}}</span>
                    <form-input v-model="newPrices['type=size_2xl']"
                      type="number"
                      name="new_price"
                      :scope="formScope"
                      label="New Price"
                      :required="false"
                      :rules="'decimal:2|min_value:0|max_value:9999.99'"
                      placeholder="0.00" />
                  </td>
                </tr>
                <tr>
                  <td>3XL</td>
                  <td :class="{ has_custom_price: newPrices['type=size_3xl'] }">
                    <span><strong>Reqular Price: </strong>{{formData.default_prices.size_3xl | price}}</span>
                    <form-input v-model="newPrices['type=size_3xl']"
                      type="number"
                      name="new_price"
                      :scope="formScope"
                      label="New Price"
                      :required="false"
                      :rules="'decimal:2|min_value:0|max_value:9999.99'"
                      placeholder="0.00" />
                  </td>
                </tr>
                <tr>
                  <td>4XL</td>
                  <td :class="{ has_custom_price: newPrices['type=size_4xl'] }">
                    <span><strong>Reqular Price: </strong>{{formData.default_prices.size_4xl | price}}</span>
                    <form-input v-model="newPrices['type=size_4xl']"
                      type="number"
                      name="new_price"
                      :scope="formScope"
                      label="New Price"
                      :required="false"
                      :rules="'decimal:2|min_value:0|max_value:9999.99'"
                      placeholder="0.00" />
                  </td>
                </tr>
                <tr>
                  <td>5XL</td>
                  <td :class="{ has_custom_price: newPrices['type=size_5xl'] }">
                    <span><strong>Reqular Price: </strong>{{formData.default_prices.size_5xl | price}}</span>
                    <form-input v-model="newPrices['type=size_5xl']"
                      type="number"
                      name="new_price"
                      :scope="formScope"
                      label="New Price"
                      :required="false"
                      :rules="'decimal:2|min_value:0|max_value:9999.99'"
                      placeholder="0.00" />
                  </td>
                </tr>
                <tr>
                  <td>6XL</td>
                  <td :class="{ has_custom_price: newPrices['type=size_6xl'] }">
                    <span><strong>Reqular Price: </strong>{{formData.default_prices.size_6xl | price}}</span>
                    <form-input v-model="newPrices['type=size_6xl']"
                                type="number"
                                name="new_price"
                                :scope="formScope"
                                label="New Price"
                                :required="false"
                                :rules="'decimal:2|min_value:0|max_value:9999.99'"
                                placeholder="0.00" />
                  </td>
                </tr>
                <tr v-if="!isEmbroidery">
                  <td>Jumbo</td>
                  <td :class="{ has_custom_price: newPrices['type=jumbo'] }">
                    <span><strong>Reqular Price: </strong>{{formData.default_prices.jumbo | price}}</span>
                    <form-input v-model="newPrices['type=jumbo']"
                      type="number"
                      name="new_price"
                      :scope="formScope"
                      label="New Price"
                      :required="false"
                      :rules="'decimal:2|min_value:0|max_value:9999.99'"
                      placeholder="0.00" />
                  </td>
                </tr>
                <tr v-if="!isEmbroidery">
                  <td>Change Color Ink</td>
                  <td :class="{ has_custom_price: newPrices['type=color_ink_change'] }">
                    <span><strong>Reqular Price: </strong>{{formData.default_prices.color_ink_change | price}}</span>
                    <form-input v-model="newPrices['type=color_ink_change']"
                      type="number"
                      name="new_price"
                      :scope="formScope"
                      label="New Price"
                      :required="false"
                      :rules="'decimal:2|min_value:0|max_value:9999.99'"
                      placeholder="0.00" />
                  </td>
                <tr v-if="isEmbroidery">
                  <td>3D Puff</td>
                  <td :class="{ has_custom_price: newPrices['type=3d_puff'] }">
                    <span><strong>Reqular Price: </strong>{{formData.default_prices['3d_puff'] | price}}</span>
                    <form-input v-model="newPrices['type=3d_puff']"
                      type="number"
                      name="new_price"
                      :scope="formScope"
                      label="New Price"
                      :required="false"
                      :rules="'decimal:2|min_value:0|max_value:9999.99'"
                      placeholder="0.00" />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </accordion-item>
      </div>

      <div v-if="formData.default_finishing_prices && formData.finishing_qty_segments && !isEmbroidery">
        <accordion-item>
          <div slot="title">
            <h5 class="subtitle">Finishings</h5>
          </div>

          <div slot="content">
            <table class="table is-fullwidth">
              <thead>
                <tr>
                  <th>Item</th>
                  <th v-for="qty_label in formData.finishing_qty_segments">{{qty_label}}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(finishing_data, code) in formData.default_finishing_prices">
                  <td>{{finishing_data.label}}</td>
                  <td v-for="(qty_label, qty) in formData.finishing_qty_segments" :class="{ has_custom_price: newPrices['type=finishing&code=' + code + '&qty=' + qty.trim()] }">
                    <span><strong>Reqular Price: </strong>{{finishing_data.prices[qty.trim()] | price}}</span>

                    <form-input v-model="newPrices['type=finishing&code=' + code + '&qty=' + qty.trim()]"
                      type="number"
                      name="new_price"
                      :scope="formScope"
                      label="New Price"
                      :required="false"
                      :rules="'decimal:2|min_value:0|max_value:9999.99'"
                      placeholder="0.00" />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </accordion-item>
      </div>

      <div v-if="formData.default_prices && formData.default_prices.base && isEmbroidery">
        <accordion-item>
          <div slot="title">
            <h5 class="subtitle">Stitches</h5>
          </div>
          <div slot="content">
            <table class="table is-fullwidth">
              <thead>
                <tr>
                  <th>Item</th>
                  <th v-for="qty_segment in formData.qty_segments">{{qty_segment}}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(items, stitches) in formData.default_prices.base">
                  <td>{{stitches}}</td>
                  <td v-for="(price, qty) in items" :class="{ has_custom_price: newPrices['type=base&stitches=' + stitches + '&qty=' + qty.trim()] }">
                    <span><strong>Reqular Price: </strong>{{price | price}}</span>

                    <form-input v-model="newPrices['type=base&stitches=' + stitches + '&qty=' + qty.trim()]"
                      type="number"
                      name="new_price"
                      :scope="formScope"
                      label="New Price"
                      :required="false"
                      :rules="'decimal:2|min_value:0|max_value:9999.99'"
                      placeholder="0.00" />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </accordion-item>
      </div>

      <div class="control control-form-actions">
        <button type="submit" :class="{'is-loading': processing, 'button is-medium is-primary': true} " :disabled="processing">Save Types</button>
      </div>
    </form>
  </div>
</template>


<script>
import Vue from 'vue';
import apiService from '../../../services/api.service';
import alertify from 'alertify.js';

import formInput from '../../common/form/input';
import formSelect from '../../common/form/select';
import AccordionItem from '../../common/accordion-item';
import bus from '../../../bus';
import { debounce } from 'lodash';
import { agregatePrices } from '../../../helpers/custom-price';

export default {
  name: 'accounts-apparel-custom-prices',
  props: ['formScope', 'isEmbroidery'],
  components: {
    formSelect,
    formInput,
    AccordionItem
  },
  data() {
    return {
      formData: {},
      newPrices: {},
      productPrices: {},

      product_query: '',
      brandsData: [],
      productsData: [],
      selectedProduct: {
        type: '',
        brand: '',
        product: '',
        regular_price: null,
        custom_price: null
      },

      productMatches: [],
      currentSuggestion: 0,
      suggestionsOpen: false,
      currentFoundProduct: {},
      processing: false
    };
  },
  computed: {
    openSuggestion() {
      return (
        this.product_query !== '' &&
        this.productMatches.length != 0 &&
        this.suggestionsOpen === true
      );
    },
    userId() {
      return this.$route.params.userId;
    },
    companyId() {
      return this.$route.params.companyId;
    }
  },
  watch: {
    currentFoundProduct: {
      handler: function(val, oldval) {
        if (val.apparel_type_id) {
          this.selectedProduct.type = val.apparel_type_id;
        }
      },
      deep: true
    },
    'selectedProduct.product': {
      handler: function(val, oldval) {
        if (val) {
          this.selectedProduct.new_price = this.productPrices[
            'apparel_product=' + val
          ];

          let current = this.productsData.filter(
            product => product.key == val
          )[0];

          if (current) {
            this.selectedProduct.regular_price = current.upcharge;
          }
        }
      }
    }
  },
  methods: {
    submitProductForm(scope) {
      let entity_type = 'customer';
      let entity_id = this.userId;

      if (this.companyId) {
        entity_type = 'company';
        entity_id = this.companyId;
      }

      this.validateBeforeSubmit(scope).then(isValid => {
        if (isValid) {
          alertify
            .okBtn('Save Prices')
            .confirm(
              'Please note, these prices will be automatically applied for the new estimate with this account',
              ev => {
                return apiService
                  .storeApparelCustomProductPrices({
                    product_data: this.selectedProduct,
                    entity_id: entity_id,
                    entity_type: entity_type,
                    service: this.isEmbroidery ? 'embroidery' : 'apparel'
                  })
                  .then(
                    response => {
                      this.product_query = '';
                      bus.$emit('updated-customer-prices-for-design');
                    },
                    response => {
                      alertify.error('Could not save new price');
                    }
                  );
              }
            );
        }
      });
    },

    submitForm() {
      this.validateBeforeSubmit(this.formScope).then(isValid => {
        if (isValid) {
          let entity_type = 'customer';
          let entity_id = this.userId;

          if (this.companyId) {
            entity_type = 'company';
            entity_id = this.companyId;
          }

          alertify
            .okBtn('Save Prices')
            .confirm(
              'Please note, these prices will be automatically applied for the new estimate with this account',
              ev => {
                return apiService
                  .storeCustomPrices({
                    params: this.newPrices,
                    service: this.isEmbroidery ? 'embroidery' : 'apparel',
                    entity_id: entity_id,
                    entity_type: entity_type
                  })
                  .then(
                    response => {
                      if (!response.error && response.data) {
                        this.newPrices = agregatePrices(response.data);
                        bus.$emit('updated-customer-prices-for-design');
                      }
                    },
                    response => {
                      alertify.error('Could not save new price(s)');
                    }
                  );
              }
            );
        }
      });
    },

    validateBeforeSubmit(scope) {
      this.clearChild(scope);
      this.errors.errors = [];
      this.validateChild(scope);

      return new Promise((resolve, reject) => {
        Vue.nextTick(() => {
          resolve(!this.errors.count());
        });
      });
    },

    validateChild(scope) {
      bus.$emit('validate', scope);
    },

    clearChild(scope) {
      bus.$emit('clear', scope);
    },

    findApparelProductsDebounced(key) {
      debounce(key => this.findApparelProducts(key), 500)(key);
    },

    findApparelProducts() {
      let service = this.isEmbroidery ? 'embroidery' : 'apparel';
      return apiService
        .findApparelProducts(this.product_query, service)
        .then(response => {
          this.productMatches = response.data;
        });
    },

    getInitData() {
      if (this.companyId) {
        let isEmbroidery = !!this.isEmbroidery;

        return apiService
          .getCustomPricesInitData(isEmbroidery ? 'embroidery' : 'apparel', this.companyId)
          .then(response => {
            this.formData = response.init_data;
            if (response.new_prices && response.new_prices.length === undefined) {
              this.newPrices = response.new_prices;
            }
            if (response.product_prices && response.product_prices.length === undefined) {
              this.productPrices = response.product_prices;
            }
          });
      }

      return [];
    },

    getBrands() {
      this.selectedProduct.brand = '';
      this.selectedProduct.product = '';
      this.brandsData = [];
      this.productsData = [];

      let brand_id = this.currentFoundProduct.apparel_brand_id;

      if (this.selectedProduct.type) {
        return apiService
          .getAccPricesApparelBrands(this.selectedProduct.type)
          .then(response => {
            this.brandsData = response.data;
            if (brand_id) {
              this.selectedProduct.brand = brand_id;
            } else if (this.currentFoundProduct.apparel_brand_id) {
              this.selectedProduct.brand = this.currentFoundProduct.apparel_brand_id;
            }
          });
      } else {
        return Promise.resolve();
      }
    },

    getProducts() {
      let prod_type = this.selectedProduct.type,
        brand = this.currentFoundProduct.apparel_brand_id
          ? this.currentFoundProduct.apparel_brand_id
          : this.selectedProduct.brand;

      this.selectedProduct.product = '';
      this.productsData = [];

      if (prod_type && brand) {
        const service = this.isEmbroidery ? 'embroidery' : 'apparel';
        return apiService
          .getAccPricesApparelProducts(prod_type, brand, service)
          .then(response => {
            this.productsData = response.data;

            if (this.currentFoundProduct.key) {
              this.selectedProduct.product = this.currentFoundProduct.key;
            }
          });
      } else {
        return Promise.resolve();
      }
    },

    isSuggestionActive(index) {
      return index === this.currentSuggestion;
    },

    change(e) {
      if (this.suggestionsOpen == false) {
        this.suggestionsOpen = true;
        this.currentSuggestion = 0;
      }

      if (e.target.value.length == 0 || e.target.value.length >= 3) {
        this.findApparelProductsDebounced();
      }
    },

    suggestionClick(index) {
      this.currentFoundProduct = this.productMatches[index];
      this.product_query = this.currentFoundProduct.label;
      this.suggestionsOpen = false;
      this.selectedProduct.type = this.currentFoundProduct.apparel_type_id;
      this.fillFoundProductForm();
    },

    enter() {
      this.currentFoundProduct = this.productMatches[this.currentSuggestion];
      this.product_query = this.currentFoundProduct.label;
      this.suggestionsOpen = false;
      this.selectedProduct.type = this.currentFoundProduct.apparel_type_id;
      this.fillFoundProductForm();
    },

    up() {
      if (this.currentSuggestion > 0) {
        this.currentSuggestion--;
      }
    },

    down() {
      if (this.currentSuggestion < this.productMatches.length - 1) {
        this.currentSuggestion++;
      }
    },

    fillFoundProductForm() {
      return this.getBrands().then(
        this.getProducts().then(() => {
          this.currentFoundProduct = {};
        })
      );
    }
  },
  mounted() {
    this.getInitData();

    bus.$on('resetPrices', () => {
      this.newPrices = {};
    });
  },
  created() {
    bus.$on('errors-changed', errors => {
      if (errors) {
        errors.forEach(e => {
          this.errors.add(e.field, e.msg, e.rule, e.scope);
        });
      }
    });

    bus.$on('errors-deleted', oldErrors => {
      if (oldErrors) {
        oldErrors.forEach(e => {
          this.errors.remove(e.field, e.scope);
        });
      }
    });

    bus.$on('apparel-customer-prices-updated', () => {
      this.getInitData();
    });
  },
  destroyed() {
    bus.$off('apparel-customer-prices-updated');
  }
};
</script>
