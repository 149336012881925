<template>
  <div class="stick-block" :style="{ top: 'top', [side]: '10px' }">
    <button class="btn" v-show="!openState" @click="toggle" :style="{ [side]: '3px' }">
      <slot name="open-button">Open</slot>
    </button>
    <main v-show="openState">
      <button class="btn" @click="toggle" :style="{ [side]: '3px' }">
        <slot name="close-button">Close</slot>
      </button>
      <slot>Content</slot>
    </main>
  </div>
</template>

<script>
export default {
  name: 'stick-block',
  props: {
    open: {
      type: Boolean,
      default: false
    },
    right: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      openState: this.open,
      side: this.right ? 'right' : 'left'
    };
  },
  watch: {
    open(bool) {
      this.$set(this, 'openState', bool);
    }
  },
  methods: {
    toggle() {
      this.openState = !this.openState;
      this.$emit('toggleMessenger', this.openState);
    }
  }
};
</script>

<style lang="scss" scoped>
.stick-block {
  position: fixed;
  z-index: 100;
  top: 15vh;
  @media only screen and (max-height: 768px) {
    top: 5vh;
  }

  .btn {
    position: absolute;
    top: 0;
    background: none;
    border: none;
    padding: 0;
    outline: none;
  }
}
</style>
