<template>
  <div>
    <grid :orders="gridData"
      :designs="gridOrderDetails"
      :finishings="gridOrderFinishings"
      :columns="gridHeadersSimpleUnclaimedMocks"
      :actionColumns="gridActionHeaders"
      :loading="unclaimedMocksLoading"
      :defaultSortfield="sortField"
      @onGridSortBy="gridUpdateOrders"
      noResMsg="No unclaimed mocks"></grid>

    <paginator v-if="unclaimedMocks.length"
      :pagesNumber="unclaimedMocksPaginatorData.last_page"
      :prevPageUrl="unclaimedMocksPaginatorData.prev_page_url"
      :nextPageUrl="unclaimedMocksPaginatorData.next_page_url"
      :currentPage="unclaimedMocksPaginatorData.current_page"
      :getListItems="loadUnclaimedMocksPaginated"
      :showOffset="true"
      :sortField="sortField"
      :sortDir="sortDir"
      :totalNumber="unclaimedMocksPaginatorData.total"></paginator>
  </div>
</template>

<script>
import Vue from 'vue';
import { mapGetters, mapActions } from 'vuex';
import { checkRoles } from '../../router/checkRoles';

import { LOAD_UNCLAIMED_MOCKS, CLAIM } from '../../store/action-types';
import {
  unclaimedGridActionHeaders,
  unclaimedActionData,
  unclaimedOrderActionsDetails,
  getGridUnclaimedFinishingsActions
} from '@/helpers/estimates';

import grid from '../common/grid/grid';
import paginator from '../common/paginator';

import {
  getGridOrderDetails,
  getGridData,
  gridHeadersSimpleUnclaimedMocks,
  getGridOrderFinishings
} from '../../helpers/orders';

import pubnubService from '../../services/pubnub.service';

export default {
  name: 'dashboard-unclaimed-mocks',
  data: () => {
    return {
      gridHeadersSimpleUnclaimedMocks,
      gridActionHeaders: unclaimedGridActionHeaders,
      sortField: 'estimate_becomes_to_order_at',
      sortDir: 'DESC',
      page: 1,
      perPage: 50
    };
  },
  computed: {
    ...mapGetters([
      'unclaimedMocks',
      'unclaimedMocksPaginatorData',
      'unclaimedMocksLoading',
      'roles'
    ]),
    gridData() {
      const disabled = !checkRoles(this.$store.getters.roles, [
        'admin',
        'csr',
        'production-coordinator',
        'purchaser',
        'art-producer'
      ]);

      const claim = {
        tag: 'button',
        str: 'Claim mock',
        click: this.claim,
        disabled
      };

      return {
        data: getGridData(this.unclaimedMocks, this.roles, true),
        actions: unclaimedActionData(this.unclaimedMocks, { claim })
      };
    },
    gridOrderDetails() {
      return {
        data: getGridOrderDetails(this.unclaimedMocks, true),
        actions: unclaimedOrderActionsDetails(this.unclaimedMocks)
      };
    },
    gridOrderFinishings() {
      return {
        data: getGridOrderFinishings(this.unclaimedMocks),
        actions: getGridUnclaimedFinishingsActions(this.unclaimedMocks)
      };
    }
  },
  components: {
    grid,
    paginator
  },
  methods: {
    ...mapActions({
      loadUnclaimedMocks: LOAD_UNCLAIMED_MOCKS
    }),

    // Get orders on pagination.
    loadUnclaimedMocksPaginated(page, url, perPage, sortField, sortDir) {
      // Update values in component to populate it for other methods. For example - "gridUpdateOrders"
      this.page = page;
      this.perPage = perPage;

      return this.loadUnclaimedMocks({
        page,
        url,
        perPage,
        sortField,
        sortDir
      });
    },
    // Update orders in store on orders sorting.
    gridUpdateOrders(sortField, sortDir) {
      // Update values in component to populate it for "loadUnclaimedMocksPaginated"
      this.sortField = sortField;
      this.sortDir = sortDir;

      // Get default or updated by "loadUnclaimedMocksPaginated" method.
      let page = this.page;
      let perPage = this.perPage;

      this.loadUnclaimedMocks({ page, perPage, sortField, sortDir });
    },
    claim(orderId) {
      this.$store.dispatch(CLAIM, {
        orderId: orderId,
        subject: 'mock',
        vue: this
      });
    },
    mockUpdateHandler(message) {
      if (message.order_id) {
        const updatedOrders = this.unclaimedMocks.find(
          order => order.id === message.order_id
        );
        if (updatedOrders) {
          this.loadUnclaimedMocks({
            page: this.unclaimedMocksPaginatorData.current_page,
            perPage: this.perPage,
            sortField: this.sortField,
            sortDir: this.sortDir
          });
        }
      }
    },
    orderUpdateHandler(message) {
      if (message.order_id) {
        const updatedOrders = this.unclaimedMocks.find(
          order => order.id === message.order_id
        );
        if (updatedOrders) {
          this.loadUnclaimedMocks({
            page: this.unclaimedMocksPaginatorData.current_page,
            perPage: this.perPage,
            sortField: this.sortField,
            sortDir: this.sortDir
          });
        }
      }
    }
  },
  created() {
    this.gridUpdateOrders(this.sortField, this.sortDir);
  },
  mounted() {
    pubnubService.subscribeUpdateEvent('mock', this.mockUpdateHandler);
    pubnubService.subscribeUpdateEvent('order', this.orderUpdateHandler);
  },
  destroyed() {
    pubnubService.unsubscribeUpdateEvent('order', this.orderUpdateHandler);
    pubnubService.unsubscribeUpdateEvent('mock', this.mockUpdateHandler);
  }
};
</script>
