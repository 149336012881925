<template>
  <div class="container">
    <nav class="pagination is-centered is-small" v-if="pagesNumber > 1">
      <a class="pagination-previous"
        :disabled="prevPageUrl == null"
        @click="changeStep(false, prevPageUrl)">Previous</a>

      <ul class="pagination-list">
        <li>
          <a class="pagination-link"
            :class="{'is-current': 1 == currentPage}"
            @click="changeStep(1)">1</a>
        </li>

        <li v-if="currentPage - 3 > 1">
          <span class="pagination-ellipsis">&hellip;</span>
        </li>

        <li v-for="pageNum in pagesNumber"
          v-if="(pageNum + 2 >= currentPage && pageNum - 2 <= currentPage) && pageNum !== 1 && pageNum !== pagesNumber">
          <a class="pagination-link" :class="{'is-current': pageNum == currentPage}" @click="changeStep(pageNum)">{{pageNum}}</a>
        </li>

        <li v-if="currentPage + 3 < pagesNumber">
          <span class="pagination-ellipsis">&hellip;</span>
        </li>

        <li>
          <a class="pagination-link"
            :class="{'is-current': pagesNumber == currentPage}"
            @click="changeStep(pagesNumber)">{{pagesNumber}}</a>
        </li>
      </ul>

      <a class="pagination-next"
        :disabled="nextPageUrl == null"
        @click="changeStep(false, nextPageUrl)">Next</a>
    </nav>

    <div v-if="showOffset" class="offset-container">
      <span v-if="totalNumber">Total: {{totalNumber}}</span>
      <div class="select-container">
        <form-select v-model="offset"
          :options="{all: options}"
          :required="false"
          :hideLabel="true"
          :addEmpty="false"
          rules=""
          name="offset"
          @change="changedOffset" />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.container {
  display: flex;
  justify-content: space-between;
}
.pagination-link{
  &.is-current{
    color:white;
  }
}

.offset-container {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: flex-end;
}

.select-container {
  margin-left: 10px;
}
</style>

<script>
import formSelect from '../common/form/select';

const options = [
  {
    key: 20,
    label: '20'
  },
  {
    key: 50,
    label: '50'
  }
];

export default {
  props: {
    totalNumber: {
      type: Number,
      required: false
    },
    showOffset: {
      type: Boolean,
      required: false
    },
    offsetNumber: {
      default: 50,
      type: Number,
      required: false
    },
    pagesNumber: {
      default: 0,
      type: Number,
      required: false
    },
    prevPageUrl: {
      default: '',
      type: String,
      required: false
    },
    nextPageUrl: {
      default: '',
      type: String,
      required: false
    },
    currentPage: {
      default: 0,
      type: Number,
      required: false
    },
    getListItems: {
      type: Function,
      required: false
    },
    sortField: String,
    sortDir: String
  },

  components: {
    formSelect
  },

  computed: {
    options: () => options
  },

  methods: {
    changeStep(page, url) {
      this.getListItems(
        page,
        url,
        this.showOffset ? this.offset : null,
        this.sortField,
        this.sortDir
      );
    },

    changedOffset(value) {
      this.getListItems(1, undefined, value);
    }
  },

  data() {
    return {
      offset: this.offsetNumber
    };
  }
};
</script>
