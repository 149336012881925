<template>
  <div class="design-details-sidebar">
    <RevisionActions
      :mock="mock"
      :order="order"
      :design="design"
      @printMock="onPrintMock"
      @toggleMessenger="toggleMessenger"/>
    <ShipTo
      :order="order"
      :design="design"/>
    <ShipBy
      :shipBy="shipBy"
      :isEvent="isEvent"/>
    <component
      ref="designInfo"
      :is="getComponentNameByDesign(design)"
      v-if="options"
      :order="order"
      :design="design"
      :mock="mock"
      :editable="editable"
      :options="options">
    </component>
    <Finishings
      ref="finishings"
      v-if="designFinishings.length"
      :editable="editable"
      :design="design"
      :options="options"
      :mock="mock"/>
    <MockComment
      ref="mockComment"
      :editable="editable"
      :mock="mock"
      v-if="editable || mock.details"/>
    <MockInternalComment
      v-role-access="['admin', 'csr', 'production-coordinator', 'purchaser', 'art-producer','developer','printer']"
      ref="mockInternalComment"
      :editable="editable"
      :mock="mock"
      v-if="editable || mock.internal_comment"/>
    <DesignComment
      ref="designComment"
      :editable="editable"
      :design="design"
      v-if="editable || design.comment"/>
    <OrderComment
      ref="orderComment"
      :editable="editable"
      :order="order"
      v-if="editable || order.comment"/>
    <TagsWidget
      v-if="designFinishingsTags.length"
      :classes="'finishings-tags widget tags-widget has-separator'"
      :title="'FINISHINGS:'"
      :modelTags="designFinishingsTags"
      :tagsList="designFinishingsTags"
      :editable="false"/>
  </div>
</template>

<script>
import RevisionActions from './widgets/revision-actions.vue';
import ShipTo from './widgets/ship-to.vue';
import ShipBy from './widgets/ship-by.vue';
import OrderComment from './widgets/order-comment.vue';
import MockComment from './widgets/mock-comment.vue';
import DesignComment from './widgets/design-comment.vue';
import MockInternalComment from './widgets/mock-internal-comment';
import DesignInfoApparel from './widgets/design-info-types/design-info-apparel';
import DesignInfoButton from './widgets/design-info-types/design-info-button';
import DesignInfoDigital from './widgets/design-info-types/design-info-digital';
import DesignInfoOther from './widgets/design-info-types/design-info-other';
import DesignInfoFinishing from './widgets/design-info-types/design-info-finishing';
import DesignInfoPaper from './widgets/design-info-types/design-info-paper';
import DesignInfoPoster from './widgets/design-info-types/design-info-poster';
import DesignInfoSticker from './widgets/design-info-types/design-info-sticker';
import DesignInfoPromotional from './widgets/design-info-types/design-info-promotional';
import TagsWidget from '../common/tags-widget';
import Finishings from './widgets/finishings';

export default {
  name: 'design-details-sidebar',
  components: {
    Finishings,
    TagsWidget,
    DesignInfoApparel,
    DesignInfoButton,
    DesignInfoDigital,
    DesignInfoOther,
    DesignInfoPoster,
    DesignInfoSticker,
    DesignInfoPromotional,
    DesignInfoFinishing,
    DesignInfoPaper,
    RevisionActions,
    ShipTo,
    ShipBy,
    OrderComment,
    MockComment,
    DesignComment,
    MockInternalComment
  },
  props: {
    order: {
      type: Object,
      required: true
    },
    mock: {
      type: Object,
      required: true
    },
    design: {
      type: Object,
      required: true
    },
    shipBy: {
      type: String,
      required: true
    },
    isEvent: {
      type: Boolean,
      required: true
    },
    editable: {
      type: Boolean,
      default: false
    },
    options: {
      type: Object,
      required: true
    }
  },
  data() {
    return {};
  },
  computed: {
    isFinishable() {
      return (
        this.design.service === 'apparel' ||
        this.design.service === 'embroidery'
      );
    },
    designFinishings() {
      return (
        (this.design.designable.finishings &&
          this.design.designable.finishings.length &&
          this.design.designable.finishings) ||
        []
      );
    },
    designFinishingsTags() {
      return (
        (this.designFinishings.length &&
          this.designFinishings.map(finishing => {
            return {
              id: finishing.id,
              title:
                finishing.label === 'Folding & Bagging'
                  ? finishing.finishable.folding_type ===
                    'I would like my garments folded'
                    ? 'FOLDING ONLY'
                    : 'FOLDING & BAGGING'
                  : finishing.label,
              body: finishing.name,
              comment: finishing.comment
            };
          })) ||
        []
      );
    }
  },
  methods: {
    getFormData() {
      return Promise.all([
        this.$refs.designInfo.getFormData(),
        this.$refs.mockComment.getFormData(),
        this.$refs.mockInternalComment.getFormData(),
        this.$refs.designComment.getFormData(),
        this.$refs.orderComment.getFormData(),
        (this.$refs.finishings && this.$refs.finishings.getFormData()) || []
      ]).then(([items, details, mockInternalComment, design_comment, order_comment, finishings]) => {
        return { items, details, mockInternalComment, design_comment, order_comment, finishings };
      });
    },
    getComponentNameByDesign(d) {
      // Embroidery uses the same models/components as apparel,
      // with some different fields.
      return (
        'design-info-' +
        (d.service === 'embroidery' || d.service === 'blank'
          ? 'apparel'
          : d.service === 'finishing_creation' ? 'finishing' : d.service)
      );
    },
    toggleMessenger(bool) {
      this.$emit('toggleMessenger', bool);
    },
    onPrintMock(bool) {
      this.$emit('printMock', bool);
    }
  }
};
</script>
<style lang="scss">
.field-editable .control-label {
  margin: 0;
}

.upcharge-comment {
  h3 {
    font-weight: bold;
  }
}

.design-details-sidebar .column {
  min-width: 0;
}
</style>
