import apiService from '../../services/api.service';
import notify from '../../modules/notifier';
import { forEach } from 'lodash';

import {
  LOAD_ACCOUNT,
  CLEAR_ACCOUNT,
  SAVE_ACCOUNT,
  CREATE_ACCOUNT,
  LOAD_ACCOUNT_FORM,
  LOAD_ROLES,
  LOAD_PAYMENT_TERMS
} from '../action-types';

const accounts = {
  state: {
    account_loading: false,
    account: {},
    roles: [],
    countries: []
  },
  getters: {
    account: state => {
      return state.account;
    },
    systemRoles: state => {
      return state.roles;
    },
    accountLoading: state => {
      return state.account_loading;
    },
    countries: state => {
      return state.countries;
    },
    countriesOptions: state => {
      let countries = {
        primary: [],
        all: []
      };
      state.countries.forEach(function(country) {
        if (country.is_primary) {
          countries.primary.push({
            key: country.country_id,
            label: country.name
          });
        }
        countries.all.push({
          key: country.country_id,
          label: country.name
        });
      });
      return countries;
    }
  },
  mutations: {
    setLoading(state, { prop, val }) {
      state[prop] = val;
    },
    setAccount(state, account) {
      state.account = account;
    },
    setRoles(state, roles) {
      state.roles = roles;
    },
    setCountries(state, countries) {
      state.countries = (countries || []).sort(function(a, b) {
        return a.order > b.order ? 1 : b.order > a.order ? -1 : 0;
      });
    }
  },
  actions: {
    [LOAD_ACCOUNT]: ({ commit }, accountId) => {
      commit('setLoading', { prop: 'account_loading', val: true });
      return apiService.getAccount(accountId).then(data => {
        commit('setAccount', data.user);
        commit('setLoading', { prop: 'account_loading', val: false });
      });
    },
    [CLEAR_ACCOUNT]: ({ commit }) => {
      commit('setAccount', {});

      return Promise.resolve();
    },
    [LOAD_ACCOUNT_FORM]: ({ commit, dispatch }) => {
      return apiService.getRoles().then(data => {
        commit('setRoles', data);

        const accCountriesPromise = apiService
          .getAccCountries()
          .then(countries => {
            commit('setCountries', countries);
          });

        const paymentTermsPromise = dispatch(LOAD_PAYMENT_TERMS);

        return Promise.all([accCountriesPromise, paymentTermsPromise]);
      });
    },
    [LOAD_ROLES]: ({ commit, dispatch }) => {
      return apiService.getRoles().then(data => {
        commit('setRoles', data);
      });
    },
    [SAVE_ACCOUNT]: ({ commit }, { accountId, account }) => {
      return apiService.saveAccount(accountId, account).then(data => {
        //commit('setRoles', data);
        return apiService.getMe().then(data => {
          commit('setProfile', data.user);
        });
      });
    },
    [CREATE_ACCOUNT]: ({ commit }, account) => {
      return apiService.createAccount(account);
    }
  }
};

export default accounts;
