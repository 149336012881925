<template>
  <div class="clearfix">
    <div class="columns orders-filters">
      <div class="column is-2">
        <form-select
          :required="false"
          rules
          :addEmpty="false"
          label="Estimate status"
          name="status"
          :options="{all: filtersOptions.estimate_statuses}"
          v-model="filters.status"
        />
      </div>
      <div class="column is-2 input-cntr" v-if="salesRep">
        <form-select
          :required="false"
          rules
          label="Sales Rep"
          name="csr"
          :options="{all: filtersOptions.csr_users}"
          v-model="filters.csr"
        />
      </div>
      <div class="column is-2 input-cntr">
        <label class="label" for="date_from"  style="margin-bottom: 0">From</label>
        <div class="control">
          <datepicker
            v-model="filters.date_from"
            :config="{dateFormat: 'm/d/Y', wrap: true }"
            id="date_from"
          />
        </div>
      </div>
      <div class="column is-2 input-cntr">
        <label class="label" for="date_to" style="margin-bottom: 0">To</label>
        <div class="control">
          <datepicker
            v-model="filters.date_to"
            :config="{dateFormat: 'm/d/Y', wrap: true }"
            id="date_to"
          />
        </div>
      </div>
    </div>
    <div class="columns orders-filters is-clipped is-pulled-right" >
      <div class="column">
        <span class="span-primary text-underlined" @click="resetFilters()">Reset filters</span>
        <span class="blue-span-primary text-underlined" v-roles="['admin', 'csr', 'developer', 'production-coordinator', 'purchaser', 'art-producer']" @click="$router.push('/reports/estimates')">Create reports</span>
      </div>
    </div>
    <br/>
  </div>
</template>

<style lang="scss" scoped>
.input-cntr {
  position: relative;

  .button {
    position: absolute;
    right: 0;
    background: white;

    &:hover {
      background: #1793d3;
    }
  }

  input {
    width: 100% !important;
  }
}

.text-underlined {
  text-decoration: underline;
}

.span-primary {
  color: #6b6060;
  cursor: pointer;
  padding-left: 7px;
  &:hover {
    color: #4db4ea;
  }
}
.blue-span-primary {
  cursor: pointer;
  padding-left: 7px;
  color: #4db4ea;
  &:hover {
    color: #4db4ea;
  }
}
</style>

<script>
import formSelect from '../common/form/select';
import Datepicker from '../../modules/datepicker/index';

import { ESTIMATE_FILTER_STATUSES } from '../../helpers/estimates';

export default {
  name: 'filter-estimate',

  data() {
    return {
      filters: {
        status: ESTIMATE_FILTER_STATUSES.STATUS_FILTER_ACTIVE,
        csr: '',
        date_to: '',
        date_from: ''
      }
    };
  },

  props: {
    filtersOptions: {
      type: Object,
      default: 'Select One'
    },
    salesRep: {
      type: Boolean,
      default: true
    }
  },

  components: {
    formSelect,
    Datepicker
  },
  watch: {
    filters: {
      handler: function(newFilters, oldFilters) {
        this.takeFilters(newFilters);
      },
      deep: true
    }
  },

  methods: {
    takeFilters(filters) {
      this.$emit('takeFilters', filters);
    },

    resetFilters() {
      this.filters.status = ESTIMATE_FILTER_STATUSES.STATUS_FILTER_ACTIVE;
      this.filters.csr = '';
      this.filters.date_to = '';
      this.filters.date_from = '';
    }
  }
};
</script>
