import Vue from 'vue';
import Notification from './Notification.vue';
const NotificationComponent = Vue.extend(Notification);

const notify = (propsData) => {
    propsData = Object.assign({
      title: '',
      message: '',
      type: '',
      direction: '',
      duration: 4500,
      container: '.notifications'
    }, propsData);

    return new NotificationComponent({
        el: document.createElement('div'),
        propsData
    })
};

export default notify;