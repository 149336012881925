<template>
  <!--  @deprecated-->
  <div>
    <form @submit.prevent="submitForm()">
      <div v-if="formData.default_prices && formData.default_prices.by_size">
        <h5>By size</h5>
        <div v-for="(type_item, type_id) in formData.types" v-if="formData.default_prices.by_size[type_id]">
          <accordion-item>
            <div slot="title">
              <h5 class="subtitle">{{type_item}}</h5>
            </div>
            <div slot="content">
              <table class="table">
                <thead>
                  <tr>
                    <th>Size/Quantity</th>
                    <th v-for="qty in formData.quantities[type_id]">{{qty.label}}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="size in formData.sizes[type_id]" v-if="formData.default_prices.by_size[type_id][size.id]">
                    <td>{{size.label}}</td>
                    <td v-for="qty in formData.quantities[type_id]" v-if="formData.default_prices.by_size[type_id][size.id][qty.id]" :class="{ has_custom_price: newPrices['type_id=' + type_id + '&size_id=' + size.id + '&qty_id=' + qty.id] }">
                      <span><strong>Reqular Price: </strong>{{formData.default_prices.by_size[type_id][size.id][qty.id] | price}}</span>
                      <form-input v-model="newPrices['type_id=' + type_id + '&size_id=' + size.id + '&qty_id=' + qty.id]"
                        type="number"
                        name="new_price"
                        :scope="formScope"
                        label="New Price"
                        :required="false"
                        :rules="'decimal:2|min_value:0|max_value:9999.99'"
                        placeholder="0.00" />
                    </td>
                    <td v-else>N/A</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </accordion-item>
        </div>
      </div>

      <div>
        <accordion-item>
          <!--<h3 class="title">Rounded Corners</h3>-->
          <div slot="title">
            <h5 class="subtitle">Rounded Corners</h5>
          </div>
          <div slot="content">
            <table class="table">
              <thead>
                <tr>
                  <th>Print Type</th>
                  <th>Price</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(type_item, type_id) in formData.types" v-if="formData.default_prices.rounded_corners[type_id]">
                  <td>{{type_item}}</td>
                  <td :class="{ has_custom_price: newPrices['type_id=' + type_id +  '&per_qty_id=' + formData.default_prices.rounded_corners[type_id].per_qty] }">
                    <span><strong>Reqular Price: </strong>{{formData.default_prices.rounded_corners[type_id].price | price}}</span>
                    <form-input v-model="newPrices['type_id=' + type_id +  '&per_qty_id=' + formData.default_prices.rounded_corners[type_id].per_qty]"
                      type="number"
                      name="new_price"
                      :scope="formScope"
                      label="New Price"
                      :required="false"
                      :rules="'decimal:2|min_value:0|max_value:9999.99'"
                      placeholder="0.00" />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </accordion-item>
      </div>

      <div class="control control-form-actions">
        <button type="submit" :class="{'is-loading': processing, 'button is-medium is-primary': true} " :disabled="processing">Save</button>
      </div>
    </form>
  </div>
</template>


<script>
  import Vue from 'vue';
  import apiService from '../../../services/api.service';
  import bus from '../../../bus';
  import alertify from 'alertify.js';
  import AccordionItem from '../../common/accordion-item'

  import formInput from '../../common/form/input';
  import {
    agregatePrices
  } from '../../../helpers/custom-price';

  export default {
    name: 'accounts-digital-custom-prices',
    props: ['formScope'],
    components: {
      formInput,
      AccordionItem
    },
    data() {
      return {
        formData: {},
        newPrices: {},
        processing: false
      }
    },
    computed: {
      userId() {
        return this.$route.params.userId;
      },
      companyId() {
        return this.$route.params.companyId;
      },
    },
    methods: {
      getInitData: function () {
        if (this.companyId) {
          return apiService
            .getCustomPricesInitData('digital', this.companyId)
            .then(response => {
              this.formData = response.init_data;

              if (response.new_prices && response.new_prices.length == undefined) {
                this.newPrices = response.new_prices;
              }
            })
        }
        return [];
      },
      submitForm() {
        this.validateBeforeSubmit().then(isValid => {
          if (isValid) {
            let entity_type = 'customer';
            let entity_id = this.userId;

            if (this.companyId) {
              entity_type = 'company';
              entity_id = this.companyId;
            }

            alertify.okBtn('Save Prices')
              .confirm('Please note, these prices will be automatically applied for the new estimate with this account', (ev) => {
                return apiService.storeCustomPrices({
                    params: this.newPrices,
                    service: 'digital',
                    entity_id: entity_id,
                    entity_type: entity_type
                  })
                  .then((response) => {
                    if (!response.error && response.data) {
                      this.newPrices = agregatePrices(response.data);
                      bus.$emit('updated-customer-prices-for-design');
                    }
                  }, (response) => {
                    alertify.error('Could not save new price(s)');
                  });
              });
          }
        });
      },
      validateBeforeSubmit() {
        this.clearChild();
        this.errors.errors = [];
        this.validateChild();

        return new Promise((resolve, reject) => {
          Vue.nextTick(() => {
            resolve(!this.errors.count());
          });
        });
      },
      validateChild() {
        bus.$emit('validate');
      },
      clearChild() {
        bus.$emit('clear');
      },
    },
    mounted: function() {
      this.getInitData()

      bus.$on('resetPrices', () => {
        this.newPrices = {}
      })
    },
    created() {
      bus.$on('errors-changed', (errors) => {
        if (errors) {
          errors.forEach(e => {
            this.errors.add(e.field, e.msg, e.rule, e.scope);
          });
        }
      });
      bus.$on('errors-deleted', (oldErrors) => {
        if (oldErrors) {
          oldErrors.forEach(e => {
            this.errors.remove(e.field, e.scope);
          });
        }
      });
      bus.$on('digital-customer-prices-updated', () => {
        this.getInitData()
      })
    },
    destroyed() {
      bus.$off('digital-customer-prices-updated')
    }
  }
</script>
