<template>
  <section class="design-list-item" v-bind:class="{'with-background': withBackground}">
    <header>
      <h3 class="cols-flex main-title">
        <div class="col">
          <span><strong>Design #{{design_number}}:</strong> {{design.name}}</span>
          <b v-if="design.service">({{serviceCapitalizedString}})</b>
          <span class="printed-before-reprint-label" v-if="design.printed_before_order_id">
            - Reprint
            <router-link class="link"
              :to="`/orders/${design.printed_before_order_id}`">
              #{{design.printed_before_order_id}}
            </router-link>
          </span>
          <span class="printed-before-reprint-label" v-if="design.reference_order_id">
            - Reference
            <router-link class="link"
              :to="`/orders/${design.reference_order_id}`">
              #{{design.reference_order_id}}
            </router-link>
          </span>
        </div>

        <quantity-cost-block v-if="designSummary && designSummary.total"
          :total="designSummary.total"></quantity-cost-block>
      </h3>
      <div class="is-warning tag is-pulled-right"
           v-if="design.type_of_delivery == 'pickup'">PICKUP</div><br>

      <div class="design-options"
        v-if="design.printed_before">
        <p v-if="design.printed_before">Design has been printed before</p>
      </div>

      <p class="view-mocks" v-if="design.mocks && design.mocks.length">
        Mocks:
        <router-link class="link"
          :to="`/revision/${design.order_id}/${design.id}`">View Mocks</router-link>
      </p>

      <div class="card is-fullwidth comment-card" v-if="design.comment && designSummary && designSummary.type !== 'embroidery'">
        <header class="card-header">
          <p class="card-header-title">Comment</p>
        </header>

        <div class="card-content">
          <p>{{design.comment}}</p>
        </div>
      </div>
    </header>

    <div class="designs-container" v-if="designSummary && designSummary.type">
      <component
        :is="getComponentNameByDesign(designSummary)"
        :design="designSummary"
        :hideHeading="true"></component>
    </div>

    <div class="container"
      v-if="!isEmbroidery && designSummary && designSummary.finishings && designSummary.finishings.length">
      <div v-if="designSummary" class="finishing-container"
        v-for="(finishing, key) in designSummary.finishings" v-bind:key="key">
        <component v-if="finishing.finishing_option"
          :is="'finishing-summary-' + finishing.finishing_option"
          :design="designSummary"
          :finishing="finishing"></component>
      </div>
    </div>
  </section>
</template>

<script>
import Vue from 'vue';

import DesignInfoApparel from './apparel';
import SummaryApparel from '../estimate-summary-types/summary-apparel';
import DesignInfoPoster from './poster';
import DesignInfoButton from './button';
import DesignInfoDigital from './digital';
import DesignInfoOther from './other';
import DesignInfoSticker from './sticker';
import QuantityCostBlock from './quantity-cost-block';

import finishingSummaryFoldingBagging from '../estimate-summary-types/apparel-finishing-summary-types/finishing-summary-folding-bagging';
import finishingSummaryHangTag from '../estimate-summary-types/apparel-finishing-summary-types/finishing-summary-hang-tag';
import finishingSummaryHeatPressedTags from '../estimate-summary-types/apparel-finishing-summary-types/finishing-summary-heat-pressed-tags';
import finishingSummaryHemTag from '../estimate-summary-types/apparel-finishing-summary-types/finishing-summary-hem-tag';
import finishingSummarySewnTagBottomOrSleeve from '../estimate-summary-types/apparel-finishing-summary-types/finishing-summary-sewn-tag-bottom-or-sleeve';
import finishingSummarySewnTagNeckAndFourSided from '../estimate-summary-types/apparel-finishing-summary-types/finishing-summary-sewn-tag-neck-and-four-sided';
import finishingSummaryTagPrinting from '../estimate-summary-types/apparel-finishing-summary-types/finishing-summary-tag-printing';
import finishingSummaryWovenNeckTag from '../estimate-summary-types/apparel-finishing-summary-types/finishing-summary-woven-neck-tag';
import finishingSummaryFourSidedTag from '../estimate-summary-types/apparel-finishing-summary-types/finishing-summary-four-sided-tag';

export default {
  name: 'design-list-item',

  props: ['design', 'orderDetailsSummary', 'withBackground', 'design_number'],

  components: {
    DesignInfoApparel,
    SummaryApparel,
    DesignInfoButton,
    DesignInfoDigital,
    DesignInfoOther,
    DesignInfoPoster,
    DesignInfoSticker,
    QuantityCostBlock,

    finishingSummaryFoldingBagging,
    finishingSummaryHangTag,
    finishingSummaryHeatPressedTags,
    finishingSummaryHemTag,
    finishingSummarySewnTagBottomOrSleeve,
    finishingSummarySewnTagNeckAndFourSided,
    finishingSummaryTagPrinting,
    finishingSummaryWovenNeckTag,
    finishingSummaryFourSidedTag
  },

  computed: {
    serviceCapitalizedString() {
      if (!this.design.service) return;
      const { service } = this.design;
      return service.replace(service[0], service[0].toUpperCase());
    },

    designSummary() {
      if (!this.orderDetailsSummary || !this.orderDetailsSummary.designs)
        return {};
      return this.orderDetailsSummary.designs.filter(
        des => des.id === this.design.id
      )[0];
    },
    isEmbroidery() {
      return this.design && this.design.service === 'embroidery';
    }
  },

  methods: {
    getComponentNameByDesign(d) {
      // Embroidery uses the same models/components as apparel,
      // with some different fields.
      return d.type === 'embroidery' || d.type === 'blank'
        ? 'summary-apparel'
        : 'design-info-' + d.type;
    },
    getProcessAddonsString(location) {
      if (!location) return;
      return location.process_addons.map(addon => addon.label).join(', ');
    }
  }
};
</script>

<style lang="scss" src="./styles.scss">
</style>
