<template>

</template>

<script>
export default {
  name: 'design-info-button',
  props: {
    design: {
      type: Object,
      required: true
    },
    order: {
      type: Object,
      required: true
    },
    mock: {
      type: Object,
      required: true
    },
    options: {
      type: Object,
      required: true
    },
    editable: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      mock_item: {
        location: '',
        width: 0,
        height: 0,
        location_comment:
          this.mock &&
          this.mock.items &&
          this.mock.items[0] &&
          this.mock.items[0].location_comment
            ? cloneDeep(this.mock.items[0].location_comment)
            : ''
      }
    };
  },
  computed: {
    size_label() {
      let label = 'N/A';
      if (
        this.design &&
        this.design.designable &&
        this.design.designable.item &&
        this.options['sizes']
      ) {
        let size_option = this.options['sizes'].filter(
          s => s.key === this.design.designable.item.size
        )[0];

        if (size_option && size_option.label) {
          label = size_option.label;
        }
      }

      return label;
    },
    quantity_label() {
      let label = 'N/A';
      if (
        this.design &&
        this.design.designable &&
        this.design.designable.item &&
        this.options['sizes']
      ) {
        let qty_option = this.options['quantities'].filter(
          s => s.key === this.design.designable.item.quantity
        )[0];

        if (qty_option && qty_option.label) {
          label = qty_option.label;
        }
      }

      return label;
    }
  },
  methods: {
    getFormData() {
      return [this.mock_item];
    }
  }
};
</script>
