export const APPAREL_SERVICE_CODE = 'apparel';
export const EMBROIDERY_SERVICE_CODE = 'embroidery';
export const FINISHING_CREATION_SERVICE_CODE = 'finishing_creation';
export const PROMOTIONAL_SERVICE_CODE = 'promotional';
export const PAPER_SERVICE_CODE = 'paper';
export const STICKER_SERVICE_CODE = 'sticker';
export const BUTTON_SERVICE_CODE = 'button';
export const BLANK_SERVICE_CODE = 'blank';
export const OTHER_SERVICE_CODE = 'other';
export const INVOICE_SERVICE_CODE = 'non_order_invoice';

// @deprecated services
export const DIGITAL_SERVICE_CODE = 'digital';
export const POSTER_SERVICE_CODE = 'poster';
