<template>
  <dl class="ship-by widget has-separator" v-if="isEvent">
    <dt><strong>SHIP BY DATE: </strong>{{ shipBy }}</dt>
    <dt><strong style="color:red;">EVENT</strong></dt>
  </dl>
  <dl class="ship-by widget has-separator" v-else>
    <dt><strong>Estimated Ship By Date*: </strong>{{ shipBy }}</dt>
    <dt style="font-size: 0.85rem;"><i>*Contact your account rep for more information.</i></dt>
  </dl>
</template>

<script>
export default {
  name: 'ship-by',
  props: {
    shipBy: {
      type: String,
      required: true
    },
    isEvent: {
      type: Boolean,
      required: true
    }
  }
};
</script>
