<template>
  <thead :class="{'hidden': hidden}">
    <tr class="head-row"
      ref="headRow">
      <th></th>
      <th v-if="!forceExpanded"></th>
      <th v-for="column in dataColumns"
          v-roles="column.roles"
        @click="column.head && column.sortField && sortBy(column.sortField, column.sortDir?column.sortDir:sortDir)"
        :class="{
          'sortable': column.sortField ? true : false,
          'is-active': column.head && sortField === column.sortField
        }">
        {{column.head}}
        <span v-if="column.sortField && column.sortField == sortField" class="icon is-small">
            <i :class="sortDir == 'ASC' ? 'ff-triangle-up' : 'ff-triangle-down'"></i>
          </span>
      </th>
      <template v-if="orders.actions">
        <th :colspan="actionColumns.length" class="actions-head">
          <span v-if="actionColumns.length">
            {{actionColumns[0].head}}
          </span>
        </th>
      </template>
    </tr>
    <tr class="subhead-row"  v-if="isSubheadExists()"
      ref="subheadRow">
      <th></th>
      <th v-if="!forceExpanded"></th>
      <th v-for="column in dataColumns"
          v-roles="column.roles"
        :class="{
          'active': column.subhead.isActive && column.subhead.isActive(column.subhead.value),
          'clickable-area': column.subhead.clickableArea,
          'with-icon': column.subhead.iconClass,
          'disable-area': !column.subhead.clickableArea
        }"
        @click="column.subhead.clickableArea ? column.subhead.click(column.subhead.value) : null">
        <span v-if="typeof column.subhead == 'object'">
          <p v-if="column.subhead.str || column.subhead.value">
            <span v-if="!column.subhead.str">{{column.subhead.value}}</span>
            <a v-if="column.subhead.tag == 'link'"
              @click="column.subhead.click(column.subhead.value)"
              v-roles="column.subhead.roles"
              v-roles.act="column.subhead.rolesAct">
              {{column.subhead.str}}
            </a>
          </p>
          <button class="button is-primary is-small"
            v-if="column.subhead.tag == 'button'"
            @click="column.subhead.click(column.subhead.str)"
            v-roles="column.subhead.roles"
            v-roles.act="column.subhead.rolesAct">
            {{column.subhead.str}}
          </button>
          <i v-if="column.subhead.iconClass"
            :class="column.subhead.iconClass"></i>
        </span>
        <span v-else v-html="column.subhead"></span>
      </th>
      <template v-if="orders.actions">
        <th v-for="column in actionColumns"
          v-roles="column.roles"
          class="actions-subhead">
          {{column.subhead}}
        </th>
      </template>
    </tr>
  </thead>
</template>

<style lang="scss" scoped>
.hidden {
  visibility: hidden;
}
</style>

<script>
export default {
  name: 'grid-head',

  props: [
    'dataColumns',
    'actionColumns',
    'orders',
    'forceExpanded',
    'sortField',
    'hidden',
    'sortDir'
  ],
  methods: {
    sortBy: function(sortField, sortDir) {
      console.log('sortBy',sortDir, sortField)
      this.$emit('sortBy', { sortField: sortField, sortDir: sortDir });
    },

    isSubheadExists() {
      let subhead = false;
      this.dataColumns.forEach(column => {
        if (column.subhead) {
          subhead = true;
        }
      });
      return subhead;
    }
  }
};
</script>
