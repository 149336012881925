<template>
  <form @submit.prevent="submitForm" >
    <form-input v-model="invoice.amount"
      :horizontal="true"
      name="amount"
      type="number"
      :disabled="true"
      step="0.01" min="0.01" />
    <form-input
      v-model="invoice.paid_sum"
      :horizontal="true"
      name="paid_sum"
      label="Paid Sum"
      type="number"
      :rules="`min_value:0|max_value:${invoice.amount}`"
      step="0.01" min="0.00" />
    <form-input v-model="invoice.description"
      name="description"
      :horizontal="true" />
    <form-select v-model="invoice.payment_term"
      name="payment_term"
      label="Term"
      :options="{all: paymentOptions}"
      :horizontal="true" />
    <div class="has-text-centered">
      <br>
      <button type="submit"
        :class="{'is-loading': processing, 'button is-primary': true}"
        :disabled="processing">
        <template v-if="isEdit">Update </template>
        <template v-else>Create </template> invoice
      </button>
    </div>
  </form>
</template>

<script>
import Vue from 'vue';

import bus from '../../../../bus';
import { map, cloneDeep } from 'lodash';
import apiService from '../../../../services/api.service';

import formInput from '../../../common/form/input';
import formSelect from '../../../common/form/select';

export default {
  props: ['invoice', 'processing'],
  components: {
    formInput,
    formSelect
  },
  data() {
    return {
      paymentOptions: [],
      statuses: [],
      initialInvoiceData: cloneDeep(this.invoice)
    };
  },
  watch: {},
  mounted() {
    apiService.getPaymentTerms().then(data => {
      this.paymentOptions = data;
    });

    apiService.getInvoiceStatuses().then(data => {
      this.statuses = data;
    });
  },
  created() {
    bus.$on('errors-changed', errors => {
      if (errors) {
        errors.forEach(e => {
          this.errors.add(e.field, e.msg, e.rule, e.scope);
        });
      }
    });

    bus.$on('errors-deleted', oldErrors => {
      if (oldErrors) {
        oldErrors.forEach(e => {
          this.errors.remove(e.field, e.scope);
        });
      }
    });
  },
  methods: {
    validateBeforeSubmit(e) {
      this.clearErrors();
      this.validateChild();

      return new Promise((resolve, reject) => {
        Vue.nextTick(() => {
          resolve(!this.errors.count());
        });
      });
    },

    submitForm() {
      this.validateBeforeSubmit().then(isValid => {
        this.$emit('submitForm', {
          isValid,
          model: this.invoice
        });
      });
    },

    validateChild() {
      bus.$emit('validate');
    },

    clearErrors() {
      bus.$emit('clear');
      this.errors.errors = [];
    }
  },
  computed: {
    isEdit() {
      return this.$route.params.invoiceId > 0;
    },
    statusesOptions() {
      let statuses = [];
      map(this.statuses, (label, status) => {
        if (label !== 'Paid' && label !== 'Declined') {
          statuses.push({
            key: status,
            label: label
          });
        }
      });

      return statuses;
    }
  }
};
</script>
