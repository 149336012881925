<template>
  <div class="columns">
    <form class="column manage-files"
      @submit.prevent="submitForm">
      <div class="has-text-centered">
        <h4 class="subtitle is-4">Order #{{additional.orderId}} - {{additional.designName}}</h4>
      </div>
      <h5 class="subtitle is-5">Labels</h5>
      <div class="columns is-multiline is-gapless">
        <div class="column is-half" v-for="label in labels">
          <input 
              type="text"
              v-if="label.editable"
              v-model="label.tracking_number"
              @keyup.enter="label.editable = false; updateTrackingNumber(label)"
              v-focus
          >
          <i v-if="label.editable" @click="onUpdate(label)" class="fa ff-plus sm"></i>
          <i v-if="label.editable" @click="onCancel(label)" class="fa ff-cross-mark sm"></i>
          <label @click="label.editable = true;"
                 v-if="!label.editable">#{{label.tracking_number}}<i class="fa ff-pencil sm"></i></label>  
        </div>
      </div>

      <div class="columns">
        <div class="column is-4">
          <label class="label" for="form-shipping_service">Shipping method</label>
          <br>
          <div class="select is-fullwidth control">
            <select v-model="sendData.shipping_service" v-validate="{rules: 'required', scope: 'form'}"
              data-vv-name="shipping_service"
              data-vv-as="Type of delivery"
              :class="{'input': true, 'is-danger': errors.has('form.shipping_service')}"
              id="form-shipping_service">
              <option v-for="option in shipping_service" 
                :selected="sendData.shipping_service === 'ups'"
                :value="option.key">{{option.label}}</option>
            </select>
            <span v-show="errors.has('form.shipping_service')" class="help is-danger is-visible">
              {{errors.first('form.shipping_service')}}
            </span>
          </div>
        </div>


        <div class="column is-8">
          <label class="label" for="tracking_number"><span class="required"></span>Enter Tracking Number</label>
          <span>For when the Tracking # is created outside the system</span>
          <div class="control">
            <input v-model="sendData.tracking_number"
              v-validate="{rules: 'required', scope: 'form'}"
              data-vv-name="tracking_number"
              data-vv-as="Tracking Number"
              :class="{'input': true, 'is-danger': errors.has('form.tracking_number')}"
              type="text" id="form-tracking_number"
              step="1" min="1"
              max="50" />
            <span v-show="errors.has('form.tracking_number')" class="help is-danger is-visible">
              {{errors.first('form.tracking_number')}}
            </span>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import Vue from 'vue';
import notify from '../../../modules/notifier';
import forEach from 'lodash/forEach';
import apiService from '../../../services/api.service';

export default {
  name: 'shipping-tracking',
  props: ['additional'],
  data() {
    return {
      sendData: {
        design_id: this.additional.designId,
        tracking_number: this.additional.tracking_number,
        shipping_service: this.additional.shipping_service || 'ups'
      },
      labels: [],
      shipping_service: [],
      processing: false
    };
  },

  methods: {
    submitForm() {
      if (this.errors.has('form.packages_qty')) {
        return new Promise((res, rej) => rej('form.packages_qty'));
      }
      if (
        this.sendData &&
        this.sendData.tracking_number &&
        this.sendData.shipping_service
      ) {
        return new Promise((res, rej) => {
          this.processing = true;
          this.$validator
            .validateAll()
            .then(() => {
              apiService
                .addUpsTrackings(this.sendData)
                .then(data => {
                  if (data.status == 'error') {
                    notify({
                      message: data.messages.join('<br>'),
                      type: 'danger'
                    });
                    rej(data.status);
                  } else {
                    notify({
                      message: 'Trackings have been added',
                      type: 'success'
                    });
                    data.data.editable = false;
                    this.labels = this.labels.concat(data.data);
                    res(data);
                  }

                  this.processing = false;
                })
                .catch(err => {
                  notify({
                    message: 'Something wrong',
                    type: 'danger'
                  });
                  rej(err);

                  this.processing = false;
                });
            })
            .catch(() => {
              rej();
              this.processing = false;
            });
        });
      } else {
        notify({
          message: 'Fields can`t be empty',
          type: 'danger'
        });
        return new Promise((res, rej) => rej());
      }
    },

    updateTrackingNumber(label) {
      return new Promise((res, rej) => {
        if (label.tracking_number.length) {
          this.$nextTick(() => {
            this.processing = true;
            apiService
              .updateTrackings(label)
              .then(data => {
                if (data.status == 'error') {
                  notify({
                    message: data.messages.join('<br>'),
                    type: 'danger'
                  });
                  rej(data.status);
                } else {
                  notify({
                    message: 'Trackings have been updated',
                    type: 'success'
                  });
                  res(data);
                }
                this.processing = false;
              })
              .catch(err => {
                notify({
                  message: 'Something wrong',
                  type: 'danger'
                });
                this.processing = false;
              });
          }, 500);
        } else {
          notify({
            message: 'Tracking number can not be empty',
            type: 'danger'
          });
          apiService
            .getShippingLabels(this.additional.designId)
            .then(labels => {
              labels = labels.map(l => {
                l.editable = false;
                return l;
              });
              this.labels = labels;
            });
          this.processing = false;
        }
      });
    },
    onUpdate(label) {
      apiService.getShippingLabels(this.additional.designId).then(labels => {
        labels = labels.forEach(l => {
          if (
            l.id === label.id &&
            l.tracking_number !== label.tracking_number
          ) {
            this.updateTrackingNumber(label);
            this.labels = this.labels.map(l => {
              l.editable = false;
              return l;
            });
          }
        });
      });
    },
    onCancel(label) {
      apiService.getShippingLabels(this.additional.designId).then(labels => {
        labels = labels.map(l => {
          l.editable = false;
          return l;
        });
        this.labels = labels;
      });
    }
  },

  mounted() {
    apiService.getShippingLabels(this.additional.designId).then(labels => {
      labels = labels.map(l => {
        l.editable = false;
        return l;
      });
      this.labels = labels;
    });

    apiService.getShippingTrackingOptions().then(shippingMethods => {
      this.shipping_service = shippingMethods.data.shipping_tracking.services;
    });
  }
};
</script>


<style lang="stylus" scoped>
  .sm{
    margin-left: 7px;
    font-size: 1.25rem;
    color: #157CB1;
    cursor: pointer;
  }
</style>
