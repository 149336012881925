<template>
  <div class="form-section form-section-payment" ref="wrapper">
    <div class="step-section">
      <header class="step-section__header">
        <h2 class="step-section__title">{{ 'Additional Fees Options' | uppercase }}</h2>
      </header>
      <div class="step-section__body">
        <form-row class="fixed-3" v-if="!isNonOrderInvoice">
          <form-multiselect
            label="Tax Pay Address"
            :required="false"
            v-model="addressOptionsModel"
            :options="addressOptions"
            :config="{
              searchable: false,
              trackBy: 'key'
            }"
            name="taxPayAddress"
            :scope="scope"
          ></form-multiselect>
          <form-item
            label="Estimated State Tax"
            v-model="parentForm.payment.state_tax"
            name="taxId"
            :scope="scope"
            readonly
          ></form-item>
          <span v-show="parentForm.payment.state_tax<0" class="form-item-help help is-danger is-visible error-msg"
                style="margin-top: 20px;">
            <slot name="errors">The State Tax is negative, please check if the prices are correct.</slot>
          </span>
        </form-row>
        <form-row class="fixed-3">
          <form-multiselect
            label="Payment Term"
            :required="true"
            v-model="paymentTermModel"
            :options="paymentTerms"
            :config="{
              searchable: false,
              trackBy: 'key'
            }"
            name="paymentTerm"
            :scope="scope"
          ></form-multiselect>
          <form-item
            label="Tax Id"
            v-model="form.tax_id"
            name="taxId"
            :scope="scope"
          ></form-item>
        </form-row>
        <form-row :gap="5">
          <form-multiselect
            v-if="!isNonOrderInvoice"
            label="Rush Fee"
            :required="true"
            v-model="rushFeeTypeModel"
            :options="percentUsdOptions"
            :config="{ searchable: false }"
            name="rushFeeType"
            :scope="scope"
            style="max-width: 60px; white-space: nowrap;"
          ></form-multiselect>
          <form-item
            v-model.number="form.rush_fee.rush_fee_amount"
            :fakeLabel="true"
            name="rushFeeAmount"
            :scope="scope"
            :selectAllOnFocus="true"
            @keypress="onKeyPress"
            @blur="onBlur($event, 'rush_fee', 'rush_fee_amount')"
            style="max-width: 240px;"
            key="rushFeeAmount"
            v-if="!isNonOrderInvoice && form.rush_fee.rush_fee_type === 'usd'"
          ></form-item>
          <form-item
            v-model.number="form.rush_fee.rush_fee_percentage"
            :fakeLabel="true"
            name="rushFeePercentage"
            :scope="scope"
            :selectAllOnFocus="true"
            @keypress="onKeyPress"
            @blur="onBlur($event, 'rush_fee', 'rush_fee_percentage')"
            style="max-width: 240px;"
            rules="max_value:100"
            key="rushFeePercentage"
            v-if="!isNonOrderInvoice && form.rush_fee.rush_fee_type === 'percentage'"
          ></form-item>
          <form-item
            v-if="!isNonOrderInvoice"
            label="Rush Fee Description"
            name="rushFeeDescription"
            :scope="scope"
            v-model="form.rush_fee.rush_fee_description"
          ></form-item>
        </form-row>
        <form-row :gap="5">
          <form-multiselect
            label="Discount"
            :required="true"
            v-model="discountTypeModel"
            :options="percentUsdOptions"
            :config="{ searchable: false }"
            name="discountType"
            :scope="scope"
            style="max-width: 60px; white-space: nowrap;"
          ></form-multiselect>
          <form-item
            v-model.number="form.discount.discount_amount"
            :fakeLabel="true"
            name="discountAmount"
            :scope="scope"
            :selectAllOnFocus="true"
            @keypress="onKeyPress"
            @blur="onBlur($event, 'discount', 'discount_amount')"
            style="max-width: 240px;"
            key="discountAmount"
            v-if="form.discount.discount_type === 'usd'"
          ></form-item>
          <form-item
            v-model.number="form.discount.discount_percentage"
            :fakeLabel="true"
            name="discountAmount"
            :scope="scope"
            :selectAllOnFocus="true"
            @keypress="onKeyPress"
            @blur="onBlur($event, 'discount', 'discount_percentage')"
            style="max-width: 240px;"
            rules="max_value:100"
            key="discountPercentage"
            v-if="form.discount.discount_type === 'percentage'"
          ></form-item>
          <form-item
            label="Discount Description"
            name="discountDescription"
            :scope="scope"
            v-model="form.discount.discount_description"
          ></form-item>
        </form-row>
      </div>
    </div>
  </div>
</template>
<script>
import StepBase from './StepBase';
import { mapGetters } from 'vuex';
import { debounce } from 'lodash';

export default {
  name: 'form-estimate-payment',
  extends: StepBase,
  props: {
    rawTotal: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      percentUsdOptions: [
        { id: 'usd', label: '$' },
        { id: 'percentage', label: '%' }
      ],
      addressOptions: this.getAddressOptions(),
      selectedAddressId: this.getSelectedAddressId() || 0
    };
  },
  computed: {
    ...mapGetters(['paymentTerms']),
    addressOptionsModel: {
      get() {
        if (!this.addressOptions) return null;
        return this.addressOptions.find(
          ({ key }) => this.selectedAddressId === key
        );
      },
      set({ key }) {
        this.selectedAddressId = key;
        this.$emit('setTaxedAddress', key);
      }
    },
    paymentTermModel: {
      get() {
        if (this.form.payment_term === null) return null;
        return this.paymentTerms.find(
          ({ key }) => this.form.payment_term === key
        );
      },
      set({ key, label }) {
        this.form.payment_term = key;
        this.form._payment_term = label;
      }
    },
    rushFeeTypeModel: {
      get() {
        if (this.form.rush_fee.rush_fee_type === null) return null;
        return this.percentUsdOptions.find(
          ({ id }) => this.form.rush_fee.rush_fee_type === id
        );
      },
      set({ id, label }) {
        this.form.rush_fee.rush_fee_type = id;
        this.form.rush_fee._rush_fee_type = label;
      }
    },
    discountTypeModel: {
      get() {
        if (this.form.discount.discount_type === null) return null;
        return this.percentUsdOptions.find(
          ({ id }) => this.form.discount.discount_type === id
        );
      },
      set({ id, label }) {
        this.form.discount.discount_type = id;
        this.form.discount._discount_type = label;
      }
    },
    isNonOrderInvoice() {
      return !!(
        this.parentForm &&
        this.parentForm.designs[0] &&
        this.parentForm.designs[0].common.service === 'non_order_invoice'
      );
    }
  },
  watch: {
    'form.rush_fee.rush_fee_amount': function(val) {
      this.$emit('setAdditionalFee');
      if (this.form.rush_fee.rush_fee_type === 'percentage') return;
      this.form.rush_fee.rush_fee_percentage = +parseFloat(
        +val * 100 / this.rawTotal
      ).toFixed(2);
    },
    'form.rush_fee.rush_fee_percentage': function(val) {
      if (this.form.rush_fee.rush_fee_type === 'usd') return;
      this.form.rush_fee.rush_fee_amount = +parseFloat(
        this.rawTotal * val / 100
      ).toFixed(2);
    },
    'form.discount.discount_amount': function(val) {
      this.$emit('setAdditionalFee');
      if (this.form.discount.discount_type === 'percentage') return;
      this.form.discount.discount_percentage = +parseFloat(
        +val * 100 / this.rawTotal
      ).toFixed(2);
    },
    'form.discount.discount_percentage': function(val) {
      if (this.form.discount.discount_type === 'usd') return;
      this.form.discount.discount_amount = +parseFloat(
        this.rawTotal * val / 100
      ).toFixed(2);
    }
  },
  methods: {
    getSelectedAddressId() {
      const addressIndex = this.parentForm.shippings.findIndex(
        item => item.specific.address.is_taxed
      );
      return addressIndex === -1 ? 0 : addressIndex;
    },
    getAddressOptions() {
      return this.parentForm.shippings.map((item, index) => {
        return {
          key: index,
          label: `${item.specific.address.address_1} ${
            item.specific.address.city
          }, ${item.specific.address.state_id}`
        };
      });
    },
    onKeyUp: debounce(function(event, type, fieldName) {
      const value = this.form[type][fieldName].toString().replace(/\.+/g, '.');
      this.form[type][fieldName] = isNaN(parseFloat(value))
        ? 0
        : parseFloat(value).toFixed(2);
    }, 200),
    onBlur(event, type, fieldName) {
      const value = this.form[type][fieldName].toString().replace(/\.+/g, '.');
      this.form[type][fieldName] = isNaN(parseFloat(value))
        ? 0
        : +parseFloat(value).toFixed(2);
    },
    onKeyPress(event) {
      const charCode = event.which ? event.which : event.keyCode;
      const value = event.target.value;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        event.preventDefault();
      } else {
        if (charCode === 46) {
          if (value.indexOf('.') > -1) {
            event.preventDefault();
            return false;
          }
        }
        return true;
      }
    }
  }
};
</script>
