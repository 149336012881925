<template>
  <stick-block v-if="order.id" :right="right" :open="open" @toggleMessenger="toggleMessenger">
    <template slot="open-button">
      <span class="icon is-large has-text-primary">
        <i class="ff-3x ff-chatbox-working"></i>
      </span>
      <span class="unread-msgs has-background-warning" v-if="messages"></span>
    </template>
    <template slot="close-button">
      <span class="icon has-text-primary">
        <i class="ff-lg ff-cross-mark"></i>
      </span>
    </template>
    <!-- content start -->
    <div class="messenger-content">
      <header class="has-background-grey-lighter">
        Messages #{{ orderId }}
      </header>
      <Messenger class="messenger" :data="order" :isEmbedded="true" />
    </div>
    <!-- content end -->
  </stick-block>
</template>

<script>
import StickBlock from './stick-block';
import Messenger from './messenger';

import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'stick-messenger',
  components: {
    StickBlock,
    Messenger
  },
  props: {
    order: {
      type: Object,
      default: {}
    },
    right: {
      type: Boolean,
      default: false
    },
    open: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters('revision', ['messages']),
    orderId() {
      return this.order ? this.order.id : '';
    }
  },
  methods: {
    toggleMessenger(bool) {
      this.$emit('toggleMessenger', bool);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '/resources/sass/base/helpers';
.messenger-content {
  background: #fff;
  width: 420px;
  border-radius: 3px;
  border: 1px solid #ccc;

  .messenger {
    padding: 10px 15px;
  }
  header {
    padding: 2px 5px;
    text-transform: uppercase;
  }
}

.unread-msgs {
  position: absolute;
  top: 0;
  right: 0;
  width: 12px;
  height: 12px;
  border-radius: 50%;
}
</style>
