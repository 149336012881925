<template>
  <div v-if="displayTags.length" :class="classes">
    <h3 v-if="title">{{title}}</h3>
    <div :class="flexHolder ? 'flex-holder' : ''">
      <div
        class="tag-wrapper"
        v-for="(tag, index) in displayTags"
        :key="tag.title + index"
        :title="tag.body">

        <label class="tag">
          {{ tag.title }}
          <input v-if="editable" name="tags" :value="tag.id" type="checkbox" v-model="tagsToSave" />
          <span v-if="editable" class="checkmark"></span>
        </label>
        <div v-if="tag.comment">
          <div class="icon">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
              <path
                d="M20 2H4c-1.1 0-1.99.9-1.99 2L2 22l4-4h14c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zM6 9h12v2H6V9zm8 5H6v-2h8v2zm4-6H6V6h12v2z"/>
            </svg>
          </div>
          <pre
            class="comment-field"
            style="font-family: inherit; white-space: pre-line; word-break: break-word; list-style: none; display: inline list-item; background-color:transparent;"
            v-html="tag.comment"
          ></pre>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'tags-widget',
  props: {
    editable: {
      type: Boolean,
      default: false
    },
    classes: {
      type: String,
      default: 'tags-widget'
    },
    title: {
      type: String,
      default: ''
    },
    flexHolder: {
      type: Boolean,
      default: false
    },
    modelTags: {
      type: Array,
      default: () => []
    },
    tagsList: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      tagsToSave: this.modelTags ? this.modelTags.map(w => w.id) : []
    };
  },
  computed: {
    displayTags() {
      return this.editable ? this.tagsList : this.modelTags;
    }
  },
  watch: {
    modelTags(modelTags) {
      let selectedTags = modelTags ? modelTags.map(w => w.id) : [];
      this.$set(this, 'tagsToSave', selectedTags);
    },
    tagsList() {
      let selectedTags = this.modelTags ? this.modelTags.map(w => w.id) : [];
      this.$set(this, 'tagsToSave', selectedTags);
    }
  },
  methods: {
    getFormData() {
      return this.tagsToSave;
    }
  },
  beforeDestroy() {
    this.$set(this, 'tagsToSave', []);
  }
};
</script>

<style lang="scss" scoped>
.tags-widget {
  margin-bottom: 20px;

  .flex-holder {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .tag-wrapper {
    flex: 0 calc(50% - 3px);
    margin: 0 0 6px 0;
    overflow: hidden;

    .tag {
      text-align: center;
      border-radius: 5px;
      line-height: 35px;
      height: 35px;
      width: 100%;
      background-color: #dedede;
      text-transform: uppercase;
      position: relative;
    }
    input {
      position: absolute;
      left: -100px;
      opacity: 0;
    }
    .checkmark {
      position: absolute;
      width: 7px;
      height: 7px;
      right: 10px;
      top: calc(50% - 3px);
      background-color: white;
    }
    input:checked + .checkmark {
      background-color: #2196f3;
    }
  }

  h3 {
    text-transform: uppercase;
    font-weight: bold;
    font-size: 1rem;
    margin-bottom: 10px;
  }
}
</style>
