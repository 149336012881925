<template>
  <div v-if="editable" class="revision-create-actions">
    <a
      v-if="artFiles"
      :href="artFiles"
      class="button"
      target="_blank"
    >Art Files</a>
    <a
      class="button is-primary is-pulled-right"
      :disabled="isReadyToSaveFinal === false"
      @click.prevent="saveFinal"
    >Save Final</a>
    <a
      class="button is-primary is-pulled-right"
      @click.prevent="saveDraft"
    >Save Draft</a>
  </div>
</template>

<script>
export default {
  name: 'revision-create-actions',
  props: {
    editable: {
      type: Boolean,
      required: true
    },
    design: {
      type: Object,
      required: true
    },
    isReadyToSaveFinal: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    saveDraft() {
      this.$emit('saveDraft');
    },
    saveFinal() {
      this.$emit('saveFinal');
    }
  },
  computed: {
    artFiles() {
      return this.design && this.design.original_files_link
        ? `${this.design.original_files_link}`
        : '';
    }
  },
  mounted() {}
};
</script>

<style scoped>
.revision-create-actions {
  margin-bottom: 60px;
}
.is-pulled-right {
  margin-right: 10px;
}
.button[disabled] {
  background-color: #6b6a6a !important;
  border-color: #dbdbdb !important;
  color: #dbdbdb !important;
}
</style>
