<template>
    <div class="columns">
        <div class="column">
        <div class="columns">
            <div class="column is-one-quarter">
                <label for="finishing-neck-tag-remove-original-0">
                    <input class="radio"  value="Remove Original Tag" id="finishing-neck-tag-remove-original-0"  v-model="finishing.four_sided_tag_type" type="radio">
                    Remove Original Tag
                </label>
            </div>
            <div class="column is-one-quarter">
                <label for="finishing-four-sided-tag-0">
                <input class="radio" value="Sew on Top of Original Tag" id="finishing-four-sided-tag-0"  v-model="finishing.four_sided_tag_type"  type="radio">
                    Sew on Top of Original Tag
                </label>
            </div>
        </div>
        <div class="columns">
            <div class="column is-half">
                <form-input v-model="four_sided_tag_thread_color"
                            name="four_sided_tag_thread_color"
                            :scope="getScope"
                            label="Thread Color"
                            placeholder="e.g. Black Thread" />
            </div>
        </div>
        </div>
    </div>
</template>
<script>
import Vue from 'vue';

import formInput from '../../common/form/input';

export default {
  props: ['finishing', 'finishingKey'],
  components: {
    formInput
  },
  data() {
    return {
      four_sided_tag_type:
        this.finishing.four_sided_tag_type || 'Remove Original Tag',
      four_sided_tag_thread_color:
        this.finishing.four_sided_tag_thread_color || ''
    };
  },
  computed: {
    getScope() {
      return 'finishings-' + this.finishingKey + '-fields';
    }
  },
  watch: {
    four_sided_tag_thread_color(val) {
      this.$set(this.finishing, 'four_sided_tag_thread_color', val);
    }
  },
  mounted() {
    // init dynamic subfields
    this.$set(this.finishing, 'four_sided_tag_type', this.four_sided_tag_type);
    this.$set(
      this.finishing,
      'four_sided_tag_thread_color',
      this.four_sided_tag_thread_color
    );
    this.$set(this.finishing, 'price_code', 'four_sided_tags');
  },
  beforeDestroy() {
    this.$delete(this.finishing, 'four_sided_tag_type');
    this.$delete(this.finishing, 'four_sided_tag_thread_color');
  }
};
</script>
