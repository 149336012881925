<template>
  <div class="page-content container section pt-3">
    <div class="">
      <div class="tabs is-boxed">
        <ul data-tabs id="dashboard-tabs">
          <router-link
            v-roles="['admin', 'csr', 'production-coordinator', 'purchaser', 'art-producer']"
            tag="li" class="tabs-title" to="/dashboard/my-estimates">
            <a>My Estimates</a>
          </router-link>
          <router-link
            v-roles="['admin', 'csr', 'production-coordinator', 'purchaser', 'art-producer','printer']"
            tag="li" class="tabs-title" to="/dashboard/my-orders">
            <a>My Orders</a>
          </router-link>
          <router-link
            v-roles="['admin', 'csr', 'production-coordinator', 'purchaser', 'art-producer','printer']"
            tag="li" class="tabs-title" to="/dashboard/active-orders">
            <a>Active Orders</a>
          </router-link>
          <router-link
            v-roles="['admin', 'csr', 'production-coordinator', 'purchaser', 'art-producer']"
            tag="li" class="tabs-title" to="/dashboard/unclaimed-estimates">
            <a>Unclaimed Estimates</a>
          </router-link>
          <router-link
            v-roles="['admin', 'csr', 'production-coordinator', 'purchaser', 'art-producer']"
            tag="li" class="tabs-title" to="/dashboard/unclaimed-blanks">
            <a>Unclaimed Blanks</a>
          </router-link>
          <router-link
            v-roles="['admin', 'csr', 'production-coordinator', 'purchaser', 'art-producer']"
            tag="li" class="tabs-title" to="/dashboard/unclaimed-mocks">
            <a>Unclaimed Mocks</a>
          </router-link>
        </ul>
      </div>
      <section id="my-threadbird">
        <router-view></router-view>
      </section>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import VeeValidate from 'vee-validate';

Vue.use(VeeValidate);

export default {
  name: 'dashboard'
};
</script>
