<template>
  <div>
    <header class="card-header">
      <p class="card-header-title">Other Fees</p>
    </header>

    <div class="card-content">
      <table class="table table__without-hover">
        <thead>
          <tr>
            <th>Item</th>
            <th>Cost</th>
            <th>Qty</th>
            <th class="align-right">Total</th>
          </tr>
        </thead>
        <tr>
          <td>Ink Changes</td>
          <td>{{(total / qty) | price}}</td>
          <td>{{qty | itemsNumberFormat}}</td>
          <td class="align-right">{{total | price}}</td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'approve-summary-other-fees',
    props: ['total', 'qty']
  }
</script>

