<template>
  <div class="columns">
    <div class="column manage-files">
      <div class="has-text-centered">
        <h4 class="subtitle is-4">Order #{{ orderId }} - {{ name }}</h4>
      </div>
      <h5 class="subtitle is-5">Links: </h5>
      <table class="table">
        <tr>
          <th>Tracking Link</th>
        </tr>
        <tr v-for="tracking in trackings">
          <td>
            <a :href="tracking.link"
               target="_blank">
              #{{tracking.tracking_number}}
            </a>
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
import apiService from '../../../services/api.service';
import { generateLinkTracking } from '../../../helpers/shipping-tracking';

export default {
  name: 'shipping-tracking-list-popup',
  props: ['additional', 'closing'],
  data() {
    return {
      name: '',
      orderId: '',
      trackings: []
    };
  },
  mounted() {
    return apiService
      .getDesign(this.additional.designId)
      .then(data => {
        this.orderId = data.design.order_id;
        this.name = data.design.name;
        let trackingsFromData = data.design.trackings.concat(
          data.design.shippings_trackings_number
        );
        let trackingNumbersWithLink = trackingsFromData.map(t => {
          t.link = generateLinkTracking(t);
          return t;
        });
        this.trackings = trackingNumbersWithLink;
      })
      .catch(err => {
        console.log('Error:', err);
      });
  }
};
</script>
