<template>
  <div v-if="data">
    {{data.name}}
  </div>
</template>
<script>
import { cloneDeep } from 'lodash';

export default {
  props: {
    finishing: {
      type: Object,
      required: true
    }
  },
  components: {},
  data() {
    return {
      data: (this.finishing && cloneDeep(this.finishing)) || {}
    };
  },
  methods: {
    getFormData() {
      return this.data;
    }
  }
};
</script>
<style>
</style>
