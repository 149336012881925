<template>
        <!-- Printer Information -->
    <fieldset class="printer-information control">
        <h5 class="subtitle">Printer Info</h5>
        <div class="columns">
            <div class="column is-4">
                <form-input v-model="printer.code" scope="printer" name="code" rules="required|min:3|max:3" />
            </div>
        </div>
        <div class="columns">
            <div class="column is-4">
                <form-input v-model="printer.name" scope="printer"  name="name" />
            </div>
            <div class="column is-4">
                <form-input v-model="printer.capacity" scope="printer"  name="capacity" type="number" rules="required|min_value:1" step="1" min="1" placeholder="1" />
            </div>
            <div class="column is-4">
                <form-input v-model="printer.max_points" scope="printer" name="max_points" label="Max points" type="number" rules="required|min_value:1" step="1" min="1" placeholder="1" />
            </div>
        </div>
        <div class="columns">
            <div class="column">
                <form-input v-model="printer.address_line_1" scope="printer"  name="address_line_1"  label="Address" />
            </div>
            <div class="column is-4">
                <form-select v-model="printer.country_code" scope="printer" name="country_code"  label="Country" :options="countriesOptions" @change="getStates('printer')" />
            </div>
        </div>
        <div class="columns">
            <div class="column is-4">
                <form-select v-if="statesCount != 0" v-model="printer.state_code" scope="printer"  name="state_code" label="State/Province" :options="statesOptions('printer')" />
                <form-input v-else v-model="printer.state_code" scope="printer"  name="state_code" label="State/Province Code" />
            </div>
            <div class="column is-4">
                <form-input v-model="printer.city" scope="printer" name="city"  label="City" />
            </div>
            <div class="column is-4">
                <form-input v-model="printer.location_zip_code" scope="printer" name="location_zip_code"  label="Postal code" />
            </div>
        </div>
    </fieldset>
    <!-- END Printer Info-->
</template>

<script>
import Vue from 'vue';
import { mapGetters } from 'vuex';

//form
import formInput from '../../common/form/input';
import formSelect from '../../common/form/select';
import apiService from '../../../services/api.service';

import { merge } from 'lodash';

var defaultFields = {
  code: '',
  name: '',
  capacity: '',
  address_line_1: '',
  city: '',
  state_code: '',
  country_code: 'US',
  location_zip_code: '',
  max_points: ''
};
export default {
  name: 'printer-fields',
  components: {
    formInput,
    formSelect
  },
  data: function() {
    return {
      billing_address_same: false,
      statesCount: 0,
      states: [],
      printer: merge({}, defaultFields, this.model.printer)
    };
  },
  props: {
    options: {
      type: Object
    },
    roles: {
      type: Array
    },
    model: {
      type: Object
    },
    processing: {
      type: Boolean
    }
  },
  watch: {
    printer: {
      handler: function(printer) {
        let account = { ...this.model };
        account.printer = printer;
        this.$emit('accountChanged', account);
      },
      deep: true
    }
  },
  methods: {
    getStates: function(field, callback) {
      return apiService.getAccStates(this[field].country_code).then(data => {
        this.statesCount = data.total;
        this.states[field] = data.list;
        this[field].state_code = '';

        if (callback) {
          callback();
        }
      });
    },
    statesOptions: function(country) {
      return {
        all: this.states[country]
      };
    }
  },
  computed: {
    ...mapGetters({
      countriesAll: 'countries',
      countriesOptions: 'countriesOptions'
    })
  },
  mounted() {
    if (this.printer.country_code) {
      this.getStates(
        'printer',
        function(state_code) {
          this.printer.state_code = state_code;
        }.bind(this, this.printer.state_code)
      );
    }
  },
  beforeDestroy() {
    let account = { ...this.model };
    delete account.printer;
    this.$emit('accountChanged', account);
  }
};
</script>
