<template>
  <div>
    <h2 class="is-grayed-title">{{ 'Product Information' | uppercase }}</h2>
    <table class="table is-fullwidth">
      <thead>
      <tr>
        <th>Item</th>
        <th>QTY Total</th>
      </tr>
      </thead>
      <tbody>
      <tr>
        <td>
          <span>Custom {{ design.designable.item.print_type_object.name }}</span>
        </td>
        <td>Total: {{ design.designable.item.quantity }}</td>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<script>

export default {
  name: 'garment-finishing_creation-details',
  props: {
    design: {
      type: Object,
      required: true
    },
    options: {
      type: Object,
      required: true
    },
    showDetails: {
      type: Boolean,
      default: true
    }
  }
};
</script>

<style scoped>
h2 {
  text-align: left;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 1rem;
  margin-bottom: 10px;
  padding: 10px;
}

.is-name td {
  background: #dedede;
}
</style>
