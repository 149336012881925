/**
 * Calculate design total.
 *
 * @param design
 * @param prices
 * @returns {number}
 */
export const getDesignTotal = (design, prices) => {
  // let totalGarmentQty = design.specific.extra.total_garments_qty;
  let garments = design.specific.garments;
  let garmentsUpcharge = garmentsTotal(garments, prices);

  return garmentsUpcharge + +design.common.upcharge;
};

/**
 * Calculate garments total.
 *
 * @param garments
 * @returns {number}
 */
export const garmentsTotal = (garments, prices) => {
  return garments.reduce((gt, garment) => {
    return gt + garmentTotal(garment, prices);
  }, 0);
};

export const garmentTotal = (garment, prices) => {
  return Object.keys(garment.sizes).reduce((st, sizeKey) => {
    const size = garment.sizes[sizeKey];
    const sizeCost =
      garment &&
      garment &&
      garment.size_cost &&
      garment.size_cost[sizeKey] &&
      garment.size_cost[sizeKey].cost !== undefined &&
      garment.size_cost[sizeKey].cost !== null
        ? +garment.size_cost[sizeKey].cost
        : calculateSizeCost(garment.model.upcharge, sizeKey, prices);
    return st + (+size || 0) * sizeCost;
  }, 0);
};

/**
 * Apply locations formula to size cost.
 * TODO: add cost calculation from api/apparel_garment_upcharges/undefined?service=${service}
 *
 * @param size
 * @param prices
 * @returns {number}
 */
export const calculateSizeCost = (sizeBaseCost, sizeKey, prices) => {
  let sizeCost = +sizeBaseCost;
  sizeCost += +(prices.garment_upcharges[`size_${sizeKey.toLowerCase()}`] || 0);
  sizeCost += +(prices.garment_upcharges[`blank_samples`] || 0);

  return +parseFloat(sizeCost.toFixed(2));
};
