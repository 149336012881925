<template>
  <div v-if="garments.length">
    <h2>Garment details</h2>
    <div v-for="(item, index) in garments" :key="index + item.name">
      <table class="table is-fullwidth">
        <thead>
        <tr>
          <th colspan="2" style="padding-left: 0;">Item</th>
          <th>QTY Total</th>
        </tr>
        </thead>
        <tbody>
        <tr class="has-separator">
          <td colspan="2">{{item.name}}</td>
          <td class="is-pulled-right">Total: {{item.quantity | itemsNumberFormat}}</td>
        </tr>
        <tr class="table-head-row">
          <th colspan="5">Description:</th>
        </tr>
        <tr>
          <td class="break-word content-pre"
              colspan="5"
              v-html="item.description">
          </td>
        </tr>
        </tbody>
      </table>
      <hr v-if="garments.length > 1 && index < garments.length - 1">
    </div>
  </div>
</template>

<script>
import { canApproveMock } from '../../../../helpers/role-access';

export default {
  name: 'garment-other-details',
  props: {
    design: {
      type: Object,
      required: true
    },
    options: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    canApproveMock
  }),
  computed: {
    garments() {
      return this.design ? this.design.designable.items : [];
    }
  },
  methods: {
    fullPrice(item) {
      return item.price * item.quantity;
    }
  }
};
</script>

<style scoped lang="scss">
h2 {
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 1rem;
  margin-bottom: 10px;
}
.is-name td {
  background: #dedede;
}
tbody {
  background: #dedede;
}
th {
  border: none;
}
tbody td {
  border-bottom: 1px solid #d6d5d5;
}
.has-separator:last-child {
  border-bottom: 1px solid;
  border-color: #eae6e6;
}
.table-head-row {
  background-color: white;

  th {
    padding-left: 0 !important;
    color: #7a7a7a !important;
  }
}
hr {
  margin-bottom: 5px;
}
</style>
