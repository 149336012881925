<template>
  <div class="columns cut is-gapless" v-if="garments">
    <div :class="['column table-parts', expandedClasses.titles]" @click="toggleExpand('titles')">
      <table class="table">
        <thead>
          <tr>
            <th width="80px">Product</th>
          </tr>
        </thead>
          <tbody>
          <tr :title="garment.name"
            v-for="(garment, key) in garments"
            v-bind:key="key">
            <td>{{garment.brand}} {{garment.number}} {{garment.name}} ({{garment.color}})</td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <th>Totals</th>
            <td></td>
          </tr>
        </tfoot>
      </table>
    </div>
    <div :class="['column table-parts bordered', expandedClasses.sizes]" @click="toggleExpand('sizes')">
      <table class="table" >
        <thead>
          <tr>
            <th v-for="(size, index) in sizesList" v-bind:key="index">{{size}}</th>
          </tr>
        </thead>
        <tbody>
          <tr :title="garment.name" v-for="(garment, key) in garments" v-bind:key="key">
            <td v-for="(size, index) in sizesList"
              v-bind:key="index">
              {{(garment.sizes[size] && garment.sizes[size].qty ? garment.sizes[size].qty : '-') | itemsNumberFormat}}
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <th v-for="(size, index) in sizesList" v-bind:key="index">
              {{getGarmentsSizeTotal(size) || '-'}}
            </th>
          </tr>
        </tfoot>
      </table>
    </div>
    <div :class="['column table-parts', expandedClasses.prices]">
      <table class="table">
        <thead>
          <tr>
            <th>Qty</th>
            <th v-roles="['admin', 'csr', 'production-coordinator', 'purchaser', 'art-producer', 'customer']">Avg. Price</th>
            <th v-roles="['admin', 'csr', 'production-coordinator', 'purchaser', 'art-producer', 'customer']" class="align-right">Total</th>
          </tr>
        </thead>
        <tbody>
          <tr :title="garment.name" v-for="(garment, key) in garments" v-bind:key="key">
            <td>{{garment.qty | itemsNumberFormat}}</td>
            <td v-roles="['admin', 'csr', 'production-coordinator', 'purchaser', 'art-producer', 'customer']">{{(garment.total / garment.qty) | price}}</td>
            <td v-roles="['admin', 'csr', 'production-coordinator', 'purchaser', 'art-producer', 'customer']" class="align-right">{{garment.total | price}}</td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <th>{{(getTotalQty() || '-') | itemsNumberFormat}}</th>
            <th v-roles="['admin', 'csr', 'production-coordinator', 'purchaser', 'art-producer', 'customer']">-</th>
            <th v-roles="['admin', 'csr', 'production-coordinator', 'purchaser', 'art-producer', 'customer']" class="align-right">{{getGarmentsTotal() | price}}</th>
          </tr>
        </tfoot>
      </table>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.table-parts.bordered {
  box-shadow: 0px 0px 14px -4px rgba(0, 0, 0, 1);
  td,
  th {
    text-align: center;
  }
}

.table {
  white-space: nowrap;
}

.table,
.card {
  background: transparent;
  td,
  th {
    padding: 8px 2px;
  }
}

.table tr:hover {
  background: transparent;
}

.table-parts {
  overflow-x: auto;
  transition: all 0.3s ease;
}

.cut {
  overflow: hidden;
}
</style>

<script>
export default {
  name: 'horizontal-garments-table',
  props: ['garments', 'sizesList', 'divideTotalCol'],
  data() {
    return {
      expanded: false
    };
  },
  computed: {
    sizesListCount() {
      return Object.keys(this.sizesList).length;
    },
    expandedClasses() {
      if (!this.expanded) {
        return {
          titles: 'is-3',
          sizes: 'is-6',
          prices: 'is-3'
        };
      }
      return {
        titles: {
          titles: 'is-10',
          sizes: 'is-1',
          prices: 'is-1'
        },
        sizes: {
          titles: 'is-1',
          sizes: 'is-10',
          prices: 'is-1'
        }
      }[this.expanded];
    }
  },
  methods: {
    toggleExpand(tableName) {
      this.expanded = this.expanded !== tableName ? tableName : null;
    },
    getGarmentsSizeTotal(size) {
      if (!size) return;
      return this.garments.reduce((prev, garm) => {
        return garm.sizes[size]
          ? (prev += parseInt(garm.sizes[size].qty))
          : prev;
      }, 0);
    },
    getTotalQty() {
      return this.garments.reduce((total, garm) => {
        return (total += garm.qty);
      }, 0);
    },
    getGarmentsTotal() {
      return this.garments.reduce((total, garm) => {
        return (total += garm.total);
      }, 0);
    }
  }
};
</script>
