<template>
  <div class="page-content container section pt-3">
    <section id="accounts">
      <router-view :key="$route.fullPath"></router-view>
    </section>
  </div>
</template>

<script>
  export default {
    name: 'index-accounts'
  };
</script>

