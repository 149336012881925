<template>
  <div class="revision-page">
    <section class="print-version">
      <template v-if="hasMocks">
        <div class="warnings-page" v-if="currentMock.warnings && currentMock.warnings.length">
          <header class="page-header">
            <img class="img-logo gray-filter" src="@/../images/logo.png"
                 alt="Threadbird">
            <h1 class="title">WARNINGS &amp; TERMS OF ART APPROVAL <span
              class="title is-4">Order #{{order.id}}. Design: {{currentDesign.name}}. V{{currentMock.version}}.</span>
            </h1>
          </header>
          <h2 class="sub-page-title">
            By approving this mock, you agree to the following warnings &amp; terms of conditions:</h2>
          <div class="warnings-list">
            <div class="warning-list-item"
                 v-for="(warning, index) in currentMock.warnings">
              <article class="warning-card">
                <header class="warning-header">
                  <span class="num">{{index + 1}}</span>
                  <p class="warning-header-title">{{warning.title}}</p>
                </header>
                <div class="warning-content"> {{warning.body}}</div>
              </article>
            </div>
          </div>
        </div>
        <div class="design-page">
          <div class="col-l">
            <article class="info-box danger-info-box">
              <h1 class="info-box-head">SHIP TO / EVENT</h1>
              <div class="info-box-content">
                  <div v-if="currentDesign.type_of_delivery == 'pickup'">**PICKUP**</div>
                  <div v-else>
                    <div
                      v-if="order.recipient_firstname || order.recipient_lastname">
                      {{order.recipient_firstname + ' ' + order.recipient_lastname}}
                    </div>
                    <div v-else>
                      {{order.contact_firstname + ' ' + order.contact_lastname}}
                    </div>
                    <p>{{order.s_mailing_address}}</p>
                    <p>{{order.s_city}}</p>
                    <p>
                      {{order.s_state_id}} {{order.s_postal_code}}, {{order.s_country_id}}</p>
                  </div>
              </div>
              <div class="info-box-bottom">SHIP BY DATE: {{shipBy}}</div>
              <div class="info-box-bottom">EVENT: {{ isEvent ? 'Yes' : 'No' }}</div>
            </article>
            <article class="info-box">
              <h1 class="info-box-head">NOTES</h1>
              <div class="info-box-content">
                <div v-for="(item, itemKey) in currentMock.items" :key="item.location" v-if="showLocationInfo">
                  <div class="label is-marginless">{{ item.location }}</div>
                  <div class="info">{{ item.width ? `${+item.width}"` : '*' }} X {{ item.height ? `${+item.height}"` : '*' }}</div>
                  <div v-if="item.colors && item.colors.length"
                       class="label is-marginless">COLORS
                  </div>
                  <span v-if="item.colors" v-for="(color, key) in item.colors" :key="color + key">
                    <span class="tag is-medium">
                      <span class="color-box"
                            :style="'background-color:#' + color.hex"> </span>
                      <span>{{ color.name }}</span>
                    </span>
                  </span>
                  <div v-if="itemFromEstimate(itemKey).depth">
                    <strong>DEPTH:</strong> {{itemFromEstimate(itemKey).depth.toUpperCase()}}
                  </div>
                  <div v-if="item.thread_colors">
                    <strong>THREAD COLORS:</strong> {{item.thread_colors}}
                  </div>
                  <div v-if="itemFromEstimate(itemKey).stitches">
                    <strong>STITCH COUNT:</strong>
                    {{itemFromEstimate(itemKey).stitches | itemsNumberFormat}}
                  </div>
                  <br>
                  <div v-if="inkChangesNumber > 0">{{inkChangesNumber}} ink color changes</div>
                  <br>
                  <div class="is-grayed-title" v-if="item.location_comment">
                    <strong>LOCATION NOTES:</strong><br>
                    <span class="break-word content-pre" v-html="item.location_comment"></span>
                  </div>
                  <br>
                </div>
                <div class="is-grayed-title" v-if="currentMock.details">
                  <strong>ARTWORK NOTES:</strong><br>
                  <span class="break-word content-pre" v-html="currentMock.details"></span>
                </div>
                <br>
                <div class="is-grayed-title" v-if="currentDesign.comment">
                  <strong>DESIGN NOTES:</strong><br/>
                  <span class="break-word content-pre" v-html="currentDesign.comment"></span>
                </div>
                <br>
                <div class="is-grayed-title" v-if="order.comment">
                  <strong>ORDER NOTES:</strong><br/>
                  <span class="break-word content-pre" v-html="order.comment"></span>
                </div>
              </div>
            </article>
          </div>
          <div class="col-main">
            <header class="page-header">
              <div class="creator-logo">
                <div class="top">
                  MOCK CREATOR: {{currentMock.creator || '&ndash;'}}
                </div>
                <img class="img gray-filter"
                     src="@/../images/logo.png"
                     alt="Threadbird">
              </div>
              <h1 class="title">
                <span>Order #{{order.id}}. Design: {{currentDesign.name}}. </span>
                <span>V{{currentMock.version}}.</span>
              </h1>
            </header>
            <div class="content">
              <div class="images">
                <img v-if="isThumbable(attachment.filepath)"
                     v-for="attachment in currentMock.attachments"
                     class="img"
                     :key="attachment.filepath"
                     :src="s3Domain + attachment.filepath"/>
              </div>
              <component :is="getComponentNameByDesign(currentDesign)"
                         v-if="currentOptions"
                         :order="order"
                         :design="currentDesign"
                         :mock="currentMock"
                         :hideComment="true"
                         :options="currentOptions"></component>
            </div>
          </div>
        </div>
      </template>
      <div class="has-text-centered" v-else>
        <h3 class="title is-3">
          There are no mocks for the design <i>{{currentDesign.name}}</i>
        </h3>
      </div>
    </section>
  </div>
</template>

<script>
import Vue from 'vue';
import { mapGetters } from 'vuex';

import DesignInfoApparel from './design-info-types/design-info-apparel';
import DesignInfoButton from './design-info-types/design-info-button';
import DesignInfoDigital from './design-info-types/design-info-digital';
import DesignInfoOther from './design-info-types/design-info-other';
import DesignInfoPoster from './design-info-types/design-info-poster';
import DesignInfoSticker from './design-info-types/design-info-sticker';
import { isThumbable, fileExt } from '../../helpers/files';

import {
  REVISION_LOAD_ORDER,
  REVISION_LOAD_MESSAGES,
  SET_ACTIVE_DESIGN,
  SET_ACTIVE_MOCK
} from '../../store/action-types';

export default {
  name: 'print-version',
  data() {
    return {
      id: this.$route.params.id
    };
  },
  components: {
    DesignInfoApparel,
    DesignInfoButton,
    DesignInfoDigital,
    DesignInfoOther,
    DesignInfoPoster,
    DesignInfoSticker
  },
  computed: {
    ...mapGetters('revision', [
      'order',
      'currentOptions',
      'designs',
      'currentDesign',
      'currentMock',
      'hasMocks',
      'showMockActions',
      'isEvent',
      'shipBy',
      'getActiveMock'
    ]),
    ...mapGetters(['s3Domain']),
    showLocationInfo() {
      return (
        this.currentDesign.service === 'apparel' ||
        this.currentDesign.service === 'embroidery'
      );
    },
    inkChangesNumber() {
      let designableItem = this.currentDesign.designable;

      return designableItem &&
        designableItem.ink_color_changes &&
        designableItem.ink_color_changes > 0
        ? designableItem.ink_color_changes
        : 0;
    }
  },
  methods: {
    fileExt: fileExt,
    isThumbable: isThumbable,
    itemFromEstimate(key) {
      let estimate = JSON.parse(this.order.estimate);
      const filteredDesigns = estimate.designs.filter(
        design => design.id == this.currentDesign.id
      );
      return filteredDesigns &&
        filteredDesigns.length &&
        filteredDesigns[0].locations
        ? filteredDesigns[0].locations[key]
        : {};
    },
    getComponentNameByDesign(d) {
      // Embroidery uses the same models/components as apparel,
      // with some different fields.
      return (
        'design-info-' +
        (d.service === 'embroidery' || d.service === 'blank'
          ? 'apparel'
          : d.service)
      );
    },
    addPrintLandscapeStyle() {
      let $style = (this.$style = document.createElement('style'));

      $style.innerHTML = `
          @media print {
              @page {
                  size: landscape;
              }
          }
        `;

      this.$el.appendChild($style);
    },
    removePrintLandscapeStyle() {
      this.$style.parentNode.removeChild(this.$style);
    }
  },
  mounted() {
    this.addPrintLandscapeStyle();
    this.$nextTick(() => {
      setTimeout(() => {
        window.print();
      }, 0);
    });
  },
  beforeDestroy() {
    this.removePrintLandscapeStyle();
  }
};
</script>
