<template>
  <div class="finishing">
    <h5 class="title is-5 is-marginless has-text-weight-bold">{{ label }}</h5>
    <div class="control">
      <div>
        <strong>Name: </strong>
        {{ item.finishing.name }}
      </div>
      <div>
        <strong>Folding Option: </strong>
        {{ item.finishing.finishable.folding_type }}
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'folding-bagging',
    props: {
      item: {
        type: Object,
        required: true
      },
      label: {
        type: String,
        required: true
      }
    },
  }
</script>
