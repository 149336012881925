<template>
  <div v-if="items" class="design-info widget has-separator">
      <div v-for="(item, itemKey) in items" class="info has-separator">
        <div class="columns">
          <div class="column">
            <form-select
              tooltip
              tooltip-ico="pensil"
              tooltip-position="left"
              v-model="item.location"
              v-if="editable"
              name="location"
              :id="`location-location-${itemKey}`"
              :scope="`form${itemKey}`"
              :isHierarchical="true"
              :hideLabel="true"
              :label="'Location'"
              :options="{all: locationsList}"/>
            <div v-else class="label is-marginless">{{ getLocationLabel(item) }}:
              <span>{{ item.width ? `${+item.width}"` : '*' }} X {{ item.height ? `${+item.height}"` : '*' }}</span>
            </div>
            <div class="columns" v-if="editable">
              <div class="column">
                <form-input v-model="item.width"
                            tooltip
                            tooltip-ico="pensil"
                            tooltip-position="left"
                            :id="`location-width-${itemKey}`"
                            :scope="`form${itemKey}`"
                            type="number"
                            rules="between:0.00,100|required"
                            :name="`location-width-${itemKey}`"
                            step="0.01"
                            min="0.00"
                            max="100"
                            :horizontal="true"
                            :hideLabel="true"
                            :label="'Width'"
                            :placeholder="'*Width'"/>
              </div>
              <div class="column">
                <form-input v-model="item.height"
                            :id="`location-height-${itemKey}`"
                            :scope="`form${itemKey}`"
                            :name="`location-height-${itemKey}`"
                            type="number"
                            rules="between:0.00,100|required"
                            step="0.01"
                            min="0.00"
                            max="100"
                            :horizontal="true"
                            :hideLabel="true"
                            :label="'Height'"
                            :placeholder="'*Height'"/>
              </div>
            </div>
            <dl>
              <dt v-if="item.ink_type">
                <strong>INK: </strong>{{ inkType(item.ink_type) }}
              </dt>
              <dt v-if="item.process_addons && item.process_addons.length">
                <strong>PROCESS ADDONS: </strong>{{processAddons(item.process_addons)}}
              </dt>
              <dt v-if="item.depth">
                <strong>DEPTH: </strong>{{item.depth.toUpperCase()}}
              </dt>
              <div v-if="item.stitches && item.stitches.name">
                <strong>STITCHES:</strong>
                {{item.stitches.name | itemsNumberFormat}}
              </div>
              <div v-else-if="isEmbroidery">
                <strong>STITCHES:</strong>
                  I Don't know, I need help!
              </div>
            </dl>
            <div class="control" v-if="!isEmbroidery">
              <div v-if="item.colors_number" class="label is-marginless">COLORS:
                {{item.colors_number}}
              </div>
              <div v-if="designable && designable.ink_color_changes > 0"
                class="label has-text-weight-500 is-italic is-marginless">*Ink Change Required</div>
              <pms-color-multiselect
                :id="`location-pms-colors-${itemKey}`"
                :scope="`pms_colors${itemKey}`"
                 tooltip
                 tooltip-ico="pensil"
                 tooltip-position="left"
                 :required="true"
                 :name="`location-pms-colors-${itemKey}`"
                 v-if="editable"
                 v-model="item.colors"></pms-color-multiselect>

              <div v-else  class="tags">
                <div v-if="item.colors" v-for="(color, key) in item.colors" :key="`mock-item-color-${key}`">
                  <span class="tag is-medium">
                    <span class="color-box" :style="'background-color:#' + color.hex"> </span>
                    <span>{{ color.name }}</span>
                  </span>
                </div>
              </div>
            </div>
            <div class="control" v-else>
              <form-input v-model="item.thread_colors"
                          tooltip
                          tooltip-ico="pensil"
                          tooltip-position="left"
                          v-if="editable"
                          :id="`location-thread-colors-${itemKey}`"
                          :scope="`form${itemKey}`"
                          rules="required"
                          :name="`location-thread-colors-${itemKey}`"
                          step="0.01"
                          min="0.00"
                          max="100"
                          :horizontal="true"
                          :hideLabel="true"
                          :label="'Thread Colors'"
                          placeholder="*Enter Thread Colors"/>
              <div v-if="!editable && item.thread_colors" class="label is-marginless">THREAD COLORS:
                {{item.thread_colors}}
              </div>
            </div>
            <div v-if="!editable && item.location_comment" class="location-comment">
              <dt><strong>LOCATION NOTES: </strong></dt>
              <dd class="break-word content-pre" v-html="item.location_comment">
              </dd>
            </div>
            <div v-else-if="editable">
              <dl>
                <dt><strong>LOCATION NOTES:</strong></dt>
                <dd>
                  <wysiwyg :ref="`wysiwyg-location-comment-${itemKey}`"
                           placeholder="*Enter note(s)"
                           @value-changed="onLocationCommentChanged($event,itemKey)"
                           :value="item.location_comment"></wysiwyg>
                </dd>
              </dl>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { isEmpty, cloneDeep } from 'lodash';
import moment from 'moment';
import apiService from '../../../../services/api.service';
import formTextarea from '../../../common/form/textarea';
import formInput from '../../../common/form/input';
import formSelect from '../../../common/form/select';
import pmsColorMultiselect from '../../../common/form/pms-color-multiselect';
import Wysiwyg from '../../../common/TextEditors/wysiwyg';

export default {
  name: 'design-info-apparel',
  components: {
    Wysiwyg,
    formTextarea,
    formInput,
    formSelect,
    pmsColorMultiselect
  },
  props: {
    design: {
      type: Object,
      required: true
    },
    order: {
      type: Object,
      required: true
    },
    mock: {
      type: Object,
      required: true
    },
    options: {
      type: Object,
      required: true
    },
    editable: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      locationsList: {},
      designOptions: (this.options && cloneDeep(this.options)) || {},
      items: this.setMockItems()
    };
  },
  watch: {
    mock: {
      handler: function(mock) {
        this.$set(this, 'items', mock.items);
        this.$nextTick(() => {
          this.initFormData();
        });
      },
      deep: true
    },
    options: {
      handler: function(options) {
        this.$set(this, 'designOptions', options);
        this.$nextTick(() => {
          this.initFormData();
        });
      },
      deep: true
    }
  },
  methods: {
    getFormData() {
      return this.items;
    },
    inkType(inkTypeId) {
      let ink_type_label = '';
      if (!isEmpty(this.options) && !isEmpty(this.options.ink_types)) {
        let ink_type = this.options.ink_types.find(
          ink_type => +ink_type.key === +inkTypeId
        );
        if (ink_type && ink_type.label) {
          ink_type_label = ink_type.label;
        }
      }
      return ink_type_label;
    },
    processAddons(process_addons) {
      let addons = [];
      process_addons.forEach(addon => {
        addons.push(addon.label);
      });
      return addons.length ? addons.join(', ') : '';
    },
    itemFromEstimate(key) {
      let filteredDesigns = [];
      if (this.order && this.order.estimate && this.design && this.design.id) {
        let estimate = JSON.parse(this.order.estimate);
        filteredDesigns = estimate.designs.filter(
          design => +design.id === +this.design.id
        );
      }

      return filteredDesigns &&
        filteredDesigns.length &&
        filteredDesigns[0].locations
        ? filteredDesigns[0].locations[key]
        : {};
    },
    initFormData() {
      if (this.designOptions && this.designOptions.locations) {
        let options = cloneDeep(this.designOptions);

        const keys = Object.keys(options.locations);
        let locationsList = {};
        let locationsIds = {};
        keys.forEach(k => {
          const innerKeys = Object.keys(options.locations[k]);
          locationsList[k] = {};
          return innerKeys.forEach(ik => {
            locationsIds[+ik] = k + ' - ' + options.locations[k][ik];
            locationsList[k][k + ' - ' + options.locations[k][ik]] =
              options.locations[k][ik];
          });
        });
        this.$set(this, 'locationsList', locationsList);

        // Prepare mock items.
        let mock_items =
          this.mock.items ||
          (this.design.mocks.length &&
            this.design.mocks[this.design.mocks.length - 1].items);
        let items = this.designable.locations.map((location, key) => {
          let loc = cloneDeep(location);
          if (mock_items && mock_items[key]) {
            let item = cloneDeep(mock_items[key]);
            loc = { ...loc, ...item }; // Merge location data from designable and mock.
          } else {
            loc.location = locationsIds[loc.location]; // Designable locations have location id. So lets get it from pre-defined object.
          }
          return loc;
        });
        this.$set(this, 'items', items);
      }
    },
    setMockItems() {
      return (
        (this.mock && cloneDeep(this.mock.items)) ||
        (this.design.mocks.length &&
          cloneDeep(this.design.mocks[this.design.mocks.length - 1].items)) ||
        cloneDeep(this.design.designable.locations) ||
        []
      );
    },
    getLocationLabel(item) {
      let location = null;
      Object.keys(this.locationsList).forEach(locGrpKey => {
        if (
          item &&
          item.location &&
          this.locationsList[locGrpKey][item.location]
        ) {
          location = this.locationsList[locGrpKey][item.location];
        }
      });
      return (
        location ||
        (item && item.location_obj && item.location_obj.name) ||
        'N/A'
      );
    },
    onLocationCommentChanged(newValue, itemKey){
      this.items[itemKey].location_comment = newValue;
    },
  },
  computed: {
    designable() {
      return this.design.designable || {};
    },
    isEmbroidery() {
      return (
        this.design &&
        this.design.service &&
        this.design.service === 'embroidery'
      );
    }
  },
  created() {},
  mounted() {
    this.initFormData();
  }
};
</script>
