<template>
<!--  @deprecated-->
  <div>
    <div v-if="formData.default_prices">
      <h5 class="subtitle">Prices</h5>
      <form @submit.prevent="submitForm()">
        <table class="table">
          <thead>
            <tr>
              <th>Size/Quantity</th>
              <th v-for="size in formData.sizes">{{size}}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(qty, qty_id) in formData.quantities" v-if="formData.default_prices[qty_id]">
              <td>{{qty | itemsNumberFormat}}</td>
              <td v-for="(size, size_id) in formData.sizes" v-if="formData.default_prices[qty_id][size_id]" :class="{ has_custom_price: newPrices['qty_id=' + qty_id + '&size_id=' + size_id] }">
                <span><strong>Reqular Price: </strong>{{formData.default_prices[qty_id][size_id] | price}}</span>
                <form-input v-model="newPrices['qty_id=' + qty_id + '&size_id=' + size_id]"
                  type="number"
                  name="new_price"
                  :scope="formScope"
                  label="New Price"
                  :required="false"
                  :rules="'decimal:2|min_value:0|max_value:9999.99'"
                  placeholder="0.00" />
              </td>
              <td v-else>N/A</td>
            </tr>
          </tbody>
        </table>

        <div class="control control-form-actions">
          <button type="submit" :class="{'is-loading': processing, 'button is-medium is-primary': true} " :disabled="processing">Save</button>
        </div>
      </form>
    </div>
  </div>
</template>


<script>
  import Vue from 'vue';
  import apiService from '../../../services/api.service';
  import bus from '../../../bus';
  import alertify from 'alertify.js';

  import formInput from '../../common/form/input';
  import {
    agregatePrices
  } from '../../../helpers/custom-price';

  export default {
    name: 'accounts-button-custom-prices',
    props: ['formScope'],
    components: {
      formInput,
    },
    data() {
      return {
        formData: {},
        newPrices: {},
        processing: false
      }
    },
    computed: {
      userId() {
        return this.$route.params.userId;
      },
      companyId() {
        return this.$route.params.companyId;
      },
    },
    methods: {
      getInitData: function () {
        if (this.companyId) {
          return apiService.getCustomPricesInitData('button', this.companyId).then(response => {
            this.formData = response.init_data;

            if (response.new_prices && response.new_prices.length == undefined) {
              this.newPrices = response.new_prices;
            }
          })
        }
        return [];
      },
      submitForm() {

        this.validateBeforeSubmit().then(isValid => {
          if (isValid) {
            let entity_type = 'customer';
            let entity_id = this.userId;

            if (this.companyId) {
              entity_type = 'company';
              entity_id = this.companyId;
            }

            alertify.okBtn('Save Prices')
              .confirm('Please note, these prices will be automatically applied for the new estimate with this account', (ev) => {
                return apiService.storeCustomPrices({
                    params: this.newPrices,
                    service: 'button',
                    entity_id: entity_id,
                    entity_type: entity_type
                  })
                  .then((response) => {
                    if (!response.error && response.data) {
                      this.newPrices = agregatePrices(response.data);
                      bus.$emit('updated-customer-prices-for-design');
                    }
                  }, (response) => {
                    alertify.error('Could not save new price(s)');
                  });
              });
          }
        });
      },
      validateBeforeSubmit() {
        this.clearChild();
        this.errors.errors = [];
        this.validateChild();

        return new Promise((resolve, reject) => {
          Vue.nextTick(() => {
            resolve(!this.errors.count());
          });
        });
      },
      validateChild() {
        bus.$emit('validate');
      },
      clearChild() {
        bus.$emit('clear');
      },
    },
    mounted: function() {
      this.getInitData()

      bus.$on('resetPrices', () => {
        this.newPrices = {}
      })
    },
    created() {
      bus.$on('errors-changed', (errors) => {
        if (errors) {
          errors.forEach(e => {
            this.errors.add(e.field, e.msg, e.rule, e.scope);
          });
        }
      });
      bus.$on('errors-deleted', (oldErrors) => {
        if (oldErrors) {
          oldErrors.forEach(e => {
            this.errors.remove(e.field, e.scope);
          });
        }
      });
      bus.$on('button-customer-prices-updated', () => {
        this.getInitData()
      })
    },
    destroyed() {
      bus.$off('button-customer-prices-updated')
    }
  }
</script>
