<template>
  <div class="design-info">
    <div v-if="design.designable && design.designable.item" class="card is-fullwidth">
      <div class="card-content">
        <div class="content">
          <table class="table is-bordered is-striped is-narrow">
            <thead>
              <tr>
                <th>Print Type</th>
                <th>Paper Type</th>
                <th>Size</th>
                <th>Quantity</th>
                <th>Has Rounded Corners</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{{print_type_label}}</td>
                <td>{{paper_type_label}}</td>
                <td>{{size_label}}</td>
                <td>{{quantity_label | itemsNumberFormat}}</td>
                <td>{{has_rounded_corners}}</td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="content">
          <div v-if="design.comment && !hideComment" class="card is-fullwidth">
            <header class="card-header">
              <p class="card-header-title">Design Comment</p>
            </header>
            <div class="content-pre card-content">{{ design.comment }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'design-info-digital',
  props: {
    design: {
      type: Object,
      required: true
    },
    options: {
      type: Object,
      required: true
    },
    hideComment: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    print_type_label() {
      let label = 'N/A';
      if (
        this.design &&
        this.design.designable &&
        this.design.designable.item &&
        this.options['print_types']
      ) {
        let print_type_option = this.options['print_types'].filter(
          s => s.key === this.design.designable.item.print_type
        )[0];

        if (print_type_option && print_type_option.label) {
          label = print_type_option.label;
        }
      }

      return label;
    },
    size_label() {
      return this.design.designable.item.size_object
        ? this.design.designable.item.size_object.name
        : 'N/A';
    },
    quantity_label() {
      return this.design.designable.item.quantity_object
        ? this.design.designable.item.quantity_object.name
        : 'N/A';
    },
    paper_type_label() {
      return this.design.designable.item.paper_type_object
        ? this.design.designable.item.paper_type_object.name
        : 'N/A';
    },
    has_rounded_corners() {
      return this.design.designable.item.has_rounded_corners ? 'Yes' : 'No';
    }
  }
};
</script>
