<template>
  <div>
    <nav class="level">
      <breadcrumbs :links="crumbLinks"/>
      <router-link
        v-roles="['admin', 'csr', 'production-coordinator', 'purchaser', 'art-producer']"
        tag="span"
        class="button is-primary level-right"
        :to="`/orders/${id}/edit`"
        v-if="!isApproved"
      >Edit</router-link>
    </nav>

    <div class="columns">
      <div class="column is-9">
        <details-estimate
          :data="data"
          :formOptions="formOptions"
          :shippingOptions="shippingOptions"
          :finishingsPrices="finishingsPrices"
          v-if="!isLoading"
        ></details-estimate>
        <div v-else>Loading...</div>
      </div>
      <div class="column is-3">
        <div class="estimate-totals" style="margin-bottom: 20px;" v-if="!isLoading">
          <h3 class="estimate-totals__title">
            <template v-if="isNonOrderInvoice">Invoice</template>
            <template v-else>Estimate</template> Summary
          </h3>
          <ul class="estimate-totals__list">
            <li>
              <div class="item">
                <span class="item__label">
                  <template v-if="isNonOrderInvoice">References</template>
                  <template v-else>Designs</template>
                </span>
                <span class="item__total">{{ totals.designsTotal  | price }}</span>
              </div>
              <ul class="estimate-totals__subitems">
                <li v-for="designTotal, index in totals.designs" :key="index">
                  <div class="item">
                    <label class="item__label">
                      <template v-if="isNonOrderInvoice">Reference</template>
                      <template v-else>Design</template> {{ index + 1 }}
                    </label>
                    <span class="item__total">{{ designTotal.total | price }}</span>
                  </div>
                </li>
              </ul>
            </li>
            <li class="estimate-totals__item" v-if="totals.tagSetups > 0">
              <div class="item">
                <span class="item__label">Tag Setups</span>
                <span class="item__total">{{ totals.tagSetups | price }}</span>
              </div>
            </li>
            <li class="estimate-totals__item" v-if="!isNonOrderInvoice">
              <div class="item">
                <span class="item__label">Shipping</span>
                <span class="item__total">{{ totals.shipping | price }}</span>
              </div>
            </li>
            <li class="estimate-totals__item" v-if="data.payment.state_tax">
              <div class="item">
                <span class="item__label">State Tax</span>
                <span class="item__total">{{ data.payment.state_tax | price }}</span>
              </div>
            </li>
            <li class="estimate-totals__item" v-if="totals.rushFee > 0">
              <div class="item">
                <label class="item__label">Rush Fee ({{ data.payment.rush_fee.rush_fee_percentage }}%)</label>
                <span class="item__total">{{ totals.rushFee | price }}</span>
              </div>
            </li>
            <li class="estimate-totals__item" v-if="totals.discount > 0">
              <div class="item">
                <label class="item__label">Discount ({{ data.payment.discount.discount_percentage }}%)</label>
                <span class="item__total">{{ totals.discount | price }}</span>
              </div>
            </li>
          </ul>
          <ul class="estimate-totals__list is-total">
            <li>
              <div class="item">
                <span class="item__label">Total Cost</span>
                <span class="item__total">{{ estimateTotal | price }}</span>
              </div>
            </li>
            <li class="is-amount" v-if="data.order.invoice">
              <div class="item">
                <span class="item__label">Amount Paid</span>
                <span class="item__total">{{ data.order.invoice.paid_sum | price }}</span>
              </div>
            </li>
          </ul>
          <div class="estimate-totals__balance-due"
            :class="{ 'is-paid': balanceDue === 0 }"
            v-if="data.order.invoice"
          >
            <span>Balance Due</span>
            <span class="value">{{ balanceDue | price }}</span>
          </div>
        </div>
        <production-notes
          v-if="!isNonOrderInvoice && !isLoading"
          :estimateDetails="data" />
        <br>
        <div class="card is-fullwidth embedded-messenger" v-if="!isLoading">
          <header class="card-header">
            <p class="card-header-title">
              <template v-if="isNonOrderInvoice">Invoice</template>
              <template v-else>Estimate</template> Activity
            </p>
          </header>
          <div class="card-content">
            <messenger :data="messages" :isEmbedded="true"></messenger>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss">
.details-base-info {
  box-shadow: 0 1px 2px rgba(10, 10, 10, 0.1);
}
.paid-amount {
  padding: 20px;
  display: flex;
  justify-content: flex-end;

  .paid-amount-content {
    width: 25%;
  }
}
</style>
<script>
import Vue from 'vue';
import { mapActions, mapGetters } from 'vuex';
import VueCookie from 'vue-cookie';
import apiService from '@/services/api.service';
import breadcrumbs from '../common/breadcrumbs';
import ProductionNotes from './production-notes';

import { getEstimateTotals } from '@/helpers/prices';
import estimateSummary from '../orders/estimate-summary';
import messenger from '../common/messenger';
import { PAYMENT_STATUS_PAID } from '../../helpers/orders';
import {
  LOAD_EDIT_ORDER_INIT_DATA,
  SET_ORDER_APPROVE_STATUS
} from '../../store/action-types';

import DetailsEstimate from '@/components/common/DetailsEstimate/DetailsEstimate';

export default {
  name: 'estimate-details',
  components: {
    estimateSummary,
    messenger,
    breadcrumbs,
    ProductionNotes,
    DetailsEstimate
  },
  data() {
    return {
      messages: {
        id: this.$route.params.id
      },
      isLoading: true,
      data: null,
      formOptions: null,
      finishingsPrices: null,
      shippingOptions: null
    };
  },
  computed: {
    ...mapGetters(['orderDetails', 'orderDetailsSummary']),
    id() {
      return this.$route.params.id;
    },
    crumbLinks() {
      return [
        {
          url: '/dashboard/my-estimates',
          label: 'Dashboard'
        },
        {
          label: 'Estimate #' + this.id
        }
      ];
    },
    isNotEditable() {
      return (
        (this.orderDetails &&
          this.orderDetails[0] &&
          this.orderDetails[0].designs) ||
        []
      ).some(design => {
        return design.printer_status >= 2; // "In production" and older
      });
    },
    isNonOrderInvoice() {
      return !!(
        this.data &&
        this.data.designs &&
        this.data.designs[0] &&
        this.data.designs[0].common.service === 'non_order_invoice'
      );
    },
    isPaid() {
      if (!this.orderDetails || !this.orderDetails[0]) {
        return;
      }

      return (
        this.paidAmount > 0 ||
        +this.orderDetails[0].payment_status === +PAYMENT_STATUS_PAID
      );
    },
    showPaidAndRemain() {
      return (
        this.orderDetails &&
        this.orderDetails.length &&
        this.orderDetails[0].invoices &&
        this.orderDetails[0].invoices.length
      );
    },
    paidAmount() {
      if (
        !this.orderDetails ||
        !this.orderDetails[0] ||
        !this.orderDetails[0].invoices ||
        !this.orderDetails[0].invoices.length
      ) {
        return;
      }

      return this.orderDetails[0].latest_invoice.paid_sum;
    },
    remainAmount() {
      if (
        !this.orderDetails ||
        !this.orderDetails[0] ||
        !this.orderDetails[0].invoices ||
        !this.orderDetails[0].invoices.length
      ) {
        return;
      }

      const remaining =
        this.orderDetails[0].latest_invoice.amount - this.paidAmount;
      return remaining > 0 ? remaining : 0;
    },
    totalPrice() {
      if (this.estimate.total.upcharge) {
        return +this.estimate.total.price + +this.estimate.total.upcharge;
      } else {
        return +this.estimate.total.price;
      }
    },
    estimate() {
      if (
        !this.orderDetails ||
        !this.orderDetails[0] ||
        !this.orderDetails[0].estimate
      )
        return;

      return typeof this.orderDetails[0].estimate === 'string'
        ? JSON.parse(this.orderDetails[0].estimate)
        : this.orderDetails[0].estimate;
    },
    isApproved() {
      return this.data && this.data.order.is_approved;
    },
    totals() {
      return getEstimateTotals(
        this.data,
        this.formOptions,
        this.finishingsPrices
      );
    },
    estimateRawTotal() {
      let total = 0;
      total += +this.totals.designsTotal;
      total += +this.totals.shipping;
      total += +this.totals.stateTaxTotal;
      total += +this.totals.tagSetups;
      if (total === 0) return 0;
      return total;
    },
    estimateTotal() {
      let total = this.estimateRawTotal;
      if (total === 0) return 0;
      total -= +this.totals.discount;
      total += +this.totals.rushFee;
      if (total <= 0) return 0;
      return total;
    },
    balanceDue() {
      return this.data.order.invoice.amount - this.data.order.invoice.paid_sum;
    }
  },
  watch: {
    id(id) {
      this.isLoading = true;
      this.messages.id = id;
      Promise.all([
        this.fetchEstimateData(),
        this.fetchShippingMethods(),
        this.getInitData()
      ])
        .then(() =>
          Promise.all([
            this.fetchFormOptions(this.data.account.account_id),
            this.fetchFinishingsPrices(this.data.account.account_id)
          ])
        )
        .then(() => {
          this.isLoading = false;
        });
    }
  },
  created() {
    this.isLoading = true;
    Promise.all([
      this.fetchEstimateData(),
      this.fetchShippingMethods(),
      this.getInitData()
    ])
      .then(() =>
        Promise.all([
          this.fetchFormOptions(this.data.account.account_id),
          this.fetchFinishingsPrices(this.data.account.account_id)
        ])
      )
      .then(() => {
        this.isLoading = false;
      });
  },
  mounted() {
    Vue.use(VueCookie);
  },
  methods: {
    ...mapActions({
      setApprovedStatusToOrder: SET_ORDER_APPROVE_STATUS,
      getInitData: LOAD_EDIT_ORDER_INIT_DATA
    }),
    convertFormOptions(data) {
      Object.keys(data).forEach(service => {
        if (!data[service].locations) return;
        const locationsRaw = data[service].locations;
        const locations = Object.keys(locationsRaw).map(groupKey => {
          return {
            title: groupKey,
            options: Object.keys(locationsRaw[groupKey]).map(childKey => {
              return {
                id: childKey,
                label: locationsRaw[groupKey][childKey]
              };
            })
          };
        });
        data[service].locations = locations;
      });
      return data;
    },
    fetchEstimateData() {
      return apiService
        .getEstimateData(this.id)
        .then(({ data }) => {
          this.data = data;
        })
        .catch(error => {
          console.error(error);
        });
    },
    fetchFormOptions(userId) {
      return apiService
        .get(
          `estimates/designs/form-options?${
            userId ? `filter[user_id]=${userId}` : ''
          }`
        )
        .then(({ data }) => {
          this.formOptions = this.convertFormOptions(data);
        });
    },
    fetchFinishingsPrices(userId) {
      return apiService.getApparelFinishingPrices(userId).then(response => {
        this.finishingsPrices = response;
      });
    },
    fetchShippingMethods() {
      return apiService.getShippingMethods().then(({ data }) => {
        const { ups } = data;
        this.shippingOptions = ups.services;
      });
    },
    getDesignProperty(orderId, designId, prop) {
      return this.orderDetails[0].designs.filter(d => d.id === designId)[0][
        prop
      ];
    },
    goToComplete() {
      this.$router.push('/orders/' + this.id + '/edit');
    }
  }
};
</script>
