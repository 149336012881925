<template>
    <div class="page-content container section pt-3">
        <div>
            <section id="my-threadbird">
                <router-view></router-view>
            </section>
        </div>
    </div>
</template>

<script>
export default {
  name: 'estimates',
  computed: {
    isEstimateDetails() {
      return this.$route.name === 'Estimate Details';
    }
  }
};
</script>
