<template>
  <div class="shipping-summary">
    <header class="step-section__subheader">
      <h4 class="step-section__subheader__title">Shipping {{ index + 1 }}</h4>
      <a href="#" @click.prevent="$emit('go', 'shippings')">Edit Shippings</a>
    </header>
    <div class="step-section__body shipping-summary__body">

      <!-- Fields -->
      <div class="shipping-summary__section">
        <div class="shipping-summary__fields">
          <div class="shipping-summary__fields-aside">

            <!-- Delivery Type & Method -->
            <summary-field
              :label="deliveryTypeLabel"
              :value="deliveryTypeValue"
            >
              <svg slot="icon" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M0 0h24v24H0z" fill="none"/><path d="M20 8h-3V4H3c-1.1 0-2 .9-2 2v11h2c0 1.66 1.34 3 3 3s3-1.34 3-3h6c0 1.66 1.34 3 3 3s3-1.34 3-3h2v-5l-3-4zM6 18.5c-.83 0-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5 1.5.67 1.5 1.5-.67 1.5-1.5 1.5zm13.5-9l1.96 2.5H17V9.5h2.5zm-1.5 9c-.83 0-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5 1.5.67 1.5 1.5-.67 1.5-1.5 1.5z"/></svg>
            </summary-field>

            <!-- Recipient -->
            <summary-field
              label="Company / Recipient"
              :value="specific.address.recipient"
            >
              <svg slot="icon" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0 2c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z"/><path d="M0 0h24v24H0z" fill="none"/></svg>
            </summary-field>

            <!-- Shipping Address -->
            <summary-field
              label="Shipping Address"
              :value="shippingAddress"
            >
              <svg slot="icon" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z"/><path d="M0 0h24v24H0z" fill="none"/></svg>
            </summary-field>

            <!-- Dates -->
            <div class="date-fields" v-if="common.in_hands_date">
              <summary-field
                label="In Hands"
                :value="common.in_hands_date"
              >
                <svg slot="icon" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M20 3h-1V1h-2v2H7V1H5v2H4c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 18H4V8h16v13z"/><path fill="none" d="M0 0h24v24H0z"/></svg>
              </summary-field>
              <summary-field
                label="Print By"
                :value="common.print_by_date"
                v-if="common.print_by_date"
              ></summary-field>
              <summary-field
                label="Ship By"
                :value="common.ship_by_date"
                v-if="common.ship_by_date"
              ></summary-field>
            </div>
          </div>
          <div class="shipping-summary__fields-items">
            <svg class="shipping-summary__fields-icon" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
              <path d="M7 14c-1.66 0-3 1.34-3 3 0 1.31-1.16 2-2 2 .92 1.22 2.49 2 4 2 2.21 0 4-1.79 4-4 0-1.66-1.34-3-3-3zm13.71-9.37l-1.34-1.34c-.39-.39-1.02-.39-1.41 0L9 12.25 11.75 15l8.96-8.96c.39-.39.39-1.02 0-1.41z"/>
            </svg>
            <table class="table is-borderless table-design-data is-garment is-fullwidth" v-for="design, index in designs">
              <thead>
                <tr>
                  <th>Design {{ design.index + 1 }} / {{ design.name }}</th>
                  <th style="width: 70px;">
                    <template v-if="index === 0">Total Qty</template>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td :data-title="`Design ${design.index + 1} / ${design.name}`">
                    <template v-if="design.sizes">
                      <p>{{ getSizesLabel(design.sizes) }}</p>
                      <p>{{ design.garmentLabel }}</p>
                    </template>
                    <template v-else-if="design.service === 'finishing_creation'">Finishing creation</template>
                    <template v-else-if="design.service === 'non_order_invoice'">Invoice</template>
                    <template v-else>{{ design.service }}</template>
                  </td>
                  <td data-title="Total Qty">{{ design.sizes ? Object.values(design.sizes).reduce((acc, qty) => acc + +qty, 0) : 1 }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import SummaryField from './SummaryField';

export default {
  name: 'design-summary',
  components: {
    SummaryField
  },
  props: {
    data: {
      type: Object,
      required: true
    },
    index: {
      type: Number,
      required: true
    },
    parentForm: {
      type: Object,
      required: true
    },
    total: {
      type: Number,
      required: true
    },
    shippingOptions: {
      type: Array,
      required: true
    }
  },
  computed: {
    ...mapGetters(['sortedGarmentsSizes', 'orderCountries', 'deliveryTypes']),
    common() {
      return this.data.common;
    },
    specific() {
      return this.data.specific;
    },
    shippingAddress() {
      const address = this.specific.address;
      let str = `${address.address_1}. `;
      if (address && address.address_2 && address.address_2.length) {
        str += `${address.address_2}. `;
      }
      str += `${address.city}, `;
      str += `${address.state_id}, `;
      str += `${address.postal_code}, `;
      str += this.orderCountries.find(
        ({ country_id }) => country_id === address.country_id
      ).name;
      return str;
    },
    deliveryTypeLabel() {
      return `Delivery Type${
        this.common.type_of_delivery === 'shipping' ? ' & Method' : ''
      }`;
    },
    deliveryTypeValue() {
      let str = this.deliveryTypes.find(
        ({ key }) => key === this.common.type_of_delivery
      ).label;
      if (this.common.shipping_method) {
        str += ' (';
        str += this.shippingOptions.find(
          ({ key }) => key === this.common.shipping_method
        ).label;
        str += ')';
      }
      if(this.common.shipping_method === 'customer_account' && this.common.customer_account_number){
        str += "\n"+this.common.customer_account_number;
      }
      return str;
    },
    designs() {
      return this.specific.items.map(({ design_id, garment_id, sizes }) => {
        const index = this.parentForm.designs.findIndex(
          design => design.common.uniqueId === design_id
        );
        const design = this.parentForm.designs[index];
        if (!garment_id) {
          return {
            index,
            name: design.common.name,
            service: design.common.service,
            design_id
          };
        } else {
          const garment = design.specific.garments.find(
            ({ uniqueId }) => uniqueId === garment_id
          );
          return {
            index,
            name: design.common.name,
            service: design.common.service,
            sizes,
            design_id,
            garment_id,
            garmentLabel: this.getGarmentLabel(garment)
          };
        }
      });
    },

    garmentsSizesQty() {
      return this.garments.reduce((garmentsTotal, garment) => {
        return (
          garmentsTotal +
          Object.values(garment.sizes).reduce((sizesTotal, size) => {
            return sizesTotal + (+size || 0);
          }, 0)
        );
      }, 0);
    }
  },
  methods: {
    getGarmentLabel(garment) {
      const { apparel_brand, number, name } = garment.model;
      return `${apparel_brand.name} ${number} ${name} (${garment.color})`;
    },
    getSizesLabel(sizes) {
      return this.sortedGarmentsSizes
        .map(
          sizeKey =>
            +sizes[sizeKey] > 0 ? `${sizeKey}-${+sizes[sizeKey]}` : false
        )
        .filter(item => !!item)
        .join(', ');
    }
  }
};
</script>
