<template>
  <div>
    <li>
      <label>Size</label>
      <strong>
        <template v-if="item.custom_size.length">{{ item.custom_size }}</template>
        <template v-else>{{ getOptionLabel(item.size, formOptions.sizes) }}</template>
      </strong>
    </li>

    <li
      v-roles="['admin', 'csr', 'production-coordinator', 'purchaser', 'art-producer', 'developer', 'customer']"
      v-if="item.custom_unit_price && item.custom_unit_price.length"
    >
      <label>Custom Unit Price</label>
      <strong>{{ item.custom_unit_price | price }}</strong>
    </li>

    <li>
      <label>Brand</label>
      <strong>
        <template v-if="item.custom_brand && item.custom_brand.length">{{ item.custom_brand }}</template>
        <template v-else>
          {{ getOptionLabel(item.brand, formOptions.brands) }}
        </template>
      </strong>
    </li>

    <li>
      <label>Paper Weight</label>
      <strong>{{ getOptionLabel(item.paper_weight, formOptions.weights) }}</strong>
    </li>

    <li>
      <label>Paper Color</label>
      <strong>
        <template v-if="item.custom_paper_color.length">{{ item.custom_paper_color }}</template>
        <template v-else>{{ posterColor }}</template>
      </strong>
    </li>

    <li>
      <label># of Colors</label>
      <strong>{{ getOptionLabel(item.number_of_colors, formOptions.color_numbers) }}</strong>
    </li>

    <li v-if="item.ink_type">
      <label>Ink Type</label>
      <strong>{{ getOptionLabel(item.ink_type, formOptions.ink_types) }}</strong>
    </li>

    <li>
      <label>Bleed Type</label>
      <strong>{{ getOptionLabel(item.bleed_type, formOptions.bleed) }}</strong>
    </li>

    <li>
      <label>Quantity</label>
      <strong>{{ getOptionLabel(item.quantity, formOptions.quantities) }}</strong>
    </li>
  </div>
</template>
<script>
import {getOptionLabel} from "@/helpers/estimates";
import {flatten} from "lodash";

/**
 * @deprecated
 */
export default {
  name: 'PosterPrintItem',
  props: {
    item: {
      type: Object,
      required: true
    },
    formOptions: {
      type: Object,
      required: true
    },
    paperColors: {
      type: Array,
      required: true
    },
  },
  methods: {
    getOptionLabel,
  },
  computed: {
    posterColor() {
      return getOptionLabel(
        this.item.paper_color,
        flatten(this.paperColors.map(({options}) => options)),
        'id'
      );
    },
  }
}
</script>
