<template>
  <div class="design-info">
    <div v-if="design.designable && design.designable.item" class="card is-fullwidth">
      <div class="card-content">
        <div class="content">
          <table class="table is-bordered is-striped is-narrow">
            <thead>
              <tr>
                <th>Shape</th>
                <th>Size</th>
                <th>Quantity</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{{shape_label}}</td>
                <td>{{size_label}}</td>
                <td>{{quantity_label | itemsNumberFormat}}</td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="content">
          <div v-if="design.comment && !hideComment" class="card is-fullwidth">
            <header class="card-header">
              <p class="card-header-title">Design Comment</p>
            </header>
            <div class="content-pre card-content">{{design.comment}}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'design-info-sticker',
  props: {
    design: {
      type: Object,
      required: true
    },
    options: {
      type: Object,
      required: true
    },
    hideComment: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    shape_label() {
      let label = 'N/A';

      if (this.design.designable.item.shape_object) {
        label = this.design.designable.item.shape_object.name;

        if (this.design.designable.item.parent_shape_object) {
          label =
            this.design.designable.item.parent_shape_object.name + ': ' + label;
        }
      }
      return label;
    },
    size_label() {
      return this.design.designable.item.size_object
        ? this.design.designable.item.size_object.name
        : 'N/A';
    },
    quantity_label() {
      return this.design.designable.item.quantity_object
        ? this.design.designable.item.quantity_object.name
        : 'N/A';
    }
  }
};
</script>
