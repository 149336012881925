<template>
    <div>
        <h5 class="subtitle">Edit Company</h5>

        <company-form
            :model="company"
            :errorsList="errorsList"
            :processing="processing"
            :options="options"
            @submitForm="submitForm">
        </company-form>
    </div>
</template>

<script>
import Vue from 'vue';
import { map } from 'lodash';
import { mapGetters, mapActions } from 'vuex';
import { checkRoles } from '../../../router/checkRoles';
import filter from 'lodash/filter';
import apiService from '../../../services/api.service';

import alertify from 'alertify.js';

//form
import companyForm from './form/company-form';
import { LOAD_ACCOUNT_FORM } from '../../../store/action-types';

export default {
  name: 'edit-company',
  data() {
    return {
      termsOptions: [],
      company: {
        name: '',
        term: '',
        production_notes: '',
        shipping_address: {},
        billing_address: {},
        new_customers: [],
        selected_customers: []
      },
      options: {
        submitBtnText: 'Update'
      },
      errorsList: [],
      processing: false
    };
  },
  watch: {
    $route(to, from) {
      this.setCompanyModel();
      this.loadAccountForm();
    }
  },
  components: {
    companyForm
  },
  computed: {
    ...mapGetters({
      roles: 'systemRoles',
      userRoles: 'roles'
    }),
    allowedRoles() {
      //remove admin role for non admin users
      let allowedRoles = this.roles;
      if (!checkRoles(this.userRoles, ['admin'])) {
        allowedRoles = filter(this.roles, r => {
          return r.key != 'admin';
        });
      }
      return allowedRoles;
    }
  },
  methods: {
    ...mapActions({
      loadAccountForm: LOAD_ACCOUNT_FORM
    }),
    submitForm() {
      return apiService
        .updateCompany(this.$route.params.id, this.company)
        .then(res => {
          alertify.success('Company Updated');
          this.$router.push(`/companies`);
        })
        .catch(err => {
          if (err.status == 422 && err.data.errors) {
            alertify.error(
              'Some validation errors have been detected. Please check error messages.'
            );
            let errors = map(err.data.errors, (messages, fullName) => {
              let chunks = fullName.split('.');
              let name = chunks.splice(chunks.length - 1, 1);
              let scope = 'company-form';

              if (chunks[0] == 'shipping_address') {
                scope = 'company-form-shipping-address';
              } else if (chunks[0] == 'billing_address') {
                scope = 'company-form-billing-address';
              }

              return {
                field: name[0],
                msg: messages.join('\n'),
                rule: 'required',
                scope: scope,
                fullName: fullName
              };
            });

            this.$set(this, 'errorsList', errors);
          } else {
            let msg = err.body.message ? err.body.message : 'Something wrong.';
            alertify.error(msg);
          }
        });
    },
    setCompanyModel() {
      return apiService.getCompany(this.$route.params.id).then(
        response => {
          this.company = Object.assign(this.company, response.company);
        },
        response => {
          alertify.error(
            response.company
              ? response.company
              : 'Could not load company details'
          );
          this.$router.push('/');
        }
      );
    }
  },
  mounted() {
    this.setCompanyModel();

    this.loadAccountForm();
  }
};
</script>
