<template>
    <fieldset class="contact-information control">
        <div class="columns">
            <div v-if="!options.hideRole" class="column is-4">
                <form-select v-model="model.role" name="role" scope="account-form" :options="{all: roles}"/>
            </div>
            <div class="column is-4">
                <form-input v-model="model.user.title" :autocomplete="false" name="title" scope="account-form" :required="false" rules="max:255" label="Username"/>
            </div>
          <div class="column is-4" v-role-access="['admin', 'csr']">
            <form-input v-model.trim="model.user.password" name="password" type="password" scope="account-form" :required="false" rules="min:6|max:255" label="Password"/>
          </div>
        </div>
        <div class="columns">
            <div class="column is-4">
                <form-input v-model="model.user.first_name" name="first_name" scope="account-form" rules="required"  label="First Name"/>
            </div>
            <div class="column is-4">
                <form-input v-model="model.user.last_name" name="last_name" scope="account-form" rules="required"  label="Last Name"/>
            </div>
        </div>
        <div class="columns">
            <div class="column is-4">
                <form-input v-model="emailModel" name="email" scope="account-form"  rules="required|email" />
            </div>
            <div class="column is-4">
                <form-input v-model="model.user.phone" name="phone" scope="account-form"  rules="required|verify_phone" />
            </div>
        </div>
    </fieldset>
</template>

<script>
import Vue from 'vue';

import formInput from '../../common/form/input.vue';
import formSelect from '../../common/form/select.vue';
import bus from '../../../bus';
import notify from '../../../modules/notifier';

export default {
  name: 'account-form',
  components: {
    formInput,
    formSelect
  },
  props: {
    options: { type: Object },
    roles: { type: Array },
    model: { type: Object }
  },
  computed: {
    emailModel: {
      get() {
        if (!this.model.user.email) return '';
        else return this.model.user.email.trim().toLowerCase();
      },
      set(val) {
        if (!val) return;
        this.model.user.email = val.trim().toLowerCase();
      }
    }
  },
  methods: {
    onValidate(validateScope) {
      if (validateScope) {
        this.$validator.validateAll(validateScope).catch(err => {});
      } else {
        this.$validator.validateScopes().catch(err => {});
      }
    },
    onClear() {
      if (this.$validator.$scopes && this.errors) {
        Object.keys(this.$validator.$scopes).map(scope =>
          this.errors.clear(scope)
        );
      }
    }
  },
  created() {
    bus.$on('validate', this.onValidate);
    bus.$on('clear', this.onClear);
    this.$watch(
      () => this.errors.errors,
      value => {
        bus.$emit('errors-changed', value);
      }
    );
  }
};
</script>
