import popup from '../modules/popupper';
import Messenger from '../components/dashboard/popups/messages.vue';
import store from '../store';
import ShippingTrackingListComponent from '../components/orders/popups/shipping-tracking-list.vue';

export const openMessenger = orderId => {
  popup({
    title: 'Messages',
    subtitle: '',
    orderId: orderId,
    additional: {
      id: orderId
    },
    bodyComponent: Messenger,
    showButtons: false
  });
};

export const openTrackings = designId => {
  popup({
    title: 'Tracking Numbers',
    additional: {
      target: 'design',
      designId: designId
    },
    showButtons: false,
    bodyComponent: ShippingTrackingListComponent
  });
};

export const redirectToUPS = message => {
  window.open(
    `https://wwwapps.ups.com/WebTracking/track?trackNums=${
      message.tracking_number
    }`,
    '_blank'
  );
};

export const redirectToFedEx = message => {
  window.open(
    `https://www.fedex.com/apps/fedextrack/?action=track&trackingnumber=${
      message.tracking_number
    }`,
    '_blank'
  );
};

export const isMentionedMe = messages => {
  return (
    messages &&
    !!messages.filter(msg => {
      let ids = (msg.mentioned_ids && JSON.parse(msg.mentioned_ids)) || null;
      if (!ids) return false;

      return !!ids.filter(id => {
        return +id === +store.getters.userProfile.id;
      }).length;
    }).length
  );
};
