<template>
  <div class="design-info widget has-separator" v-if="item">
    <dl class="info">
      <dt><strong>{{ item.print_type_object.name | uppercase }}:</strong></dt>
      <div v-if="item.print_type_object.name ==='Hang Tags'">
        <dt><strong>Paper Type:</strong> {{ item.paper_type_object.name }}</dt>
        <dt><strong>Size:</strong> {{ item.size_object.name }}</dt>
        <dt><strong>Rounded corners:</strong> {{ item.has_rounded_corners ? 'Yes' : 'No' }}</dt>
      </div>
      <div v-else-if="item.print_type_object.name ==='Hem Tags / Woven Labels'">
        <div v-if="!editable">
          <dt><strong>Folding Type:</strong> {{ foldingLabel }}</dt>
          <dt v-if="sizeLabel"><strong>Size:</strong> {{ sizeLabel }}</dt>
        </div>
        <div v-else>
          <div class="field-editable">
            <div class="label is-marginless">Folding Type:</div>
            <form-select v-model="foldingObject"
                         id="finishing-creation-paper-type"
                         tooltip
                         tooltip-ico="pensil"
                         tooltip-position="left"
                         name="finishing-creation-paper-type"
                         label="Folding Type"
                         :horizontal="true"
                         :hideLabel="true"
                         :options="{all: options.print_type_options?options.print_type_options[item.print_type].paper_types:[]}"/>
          </div>
          <div class="field-editable" v-if="!item.custom_width && !item.custom_height">
            <div class="label is-marginless">Size:</div>
            <form-select v-model="sizeObject"
                         id="finishing-creation-size"
                         tooltip
                         tooltip-ico="pensil"
                         tooltip-position="left"
                         name="finishing-creation-size"
                         label="Size"
                         :horizontal="true"
                         :hideLabel="true"
                         :options="{all: sizeOptions}"/>
          </div>
        </div>
        <dt v-if="item.custom_width && item.custom_height"><strong>Custom Size:</strong> {{ item.custom_width }}” x
          {{ item.custom_height }}”
        </dt>
        <div class="control" v-if="editable">
          <div class="label is-marginless">{{ 'Thread Colors:'| uppercase }}</div>
          <form-input v-model="threadColorsObject"
                      tooltip
                      tooltip-ico="pensil"
                      tooltip-position="left"
                      v-if="editable"
                      :id="`location-thread-colors`"
                      :scope="`form`"
                      rules="required"
                      :name="`location-thread-colors`"
                      step="0.01"
                      min="0.00"
                      max="100"
                      :horizontal="true"
                      :hideLabel="true"
                      :label="'Thread Colors'"
                      placeholder="*Enter Thread Colors"/>
        </div>
        <div v-else-if="!editable && mock_item.thread_colors" class="label is-marginless">
          {{ 'Thread Colors:'| uppercase }}
          {{ threadColorsObject }}
        </div>
        <div v-if="editable">
          <dl>
            <dt><strong>LOCATION NOTES:</strong></dt>
            <dd>
              <wysiwyg ref="wysiwyg-location-comment"
                       placeholder="*Enter note(s)"
                       @value-changed="onLocationCommentChanged"
                       :value="locationComment"/>
            </dd>
          </dl>
        </div>
        <div v-else-if="!editable && locationComment" class="location-comment">
          <dt><strong>LOCATION NOTES: </strong></dt>
          <dd class="break-word content-pre" v-html="locationComment"/>
        </div>
      </div>
      <div v-else-if="item.print_type_object.name ==='Patches'">
        <dt><strong>Type:</strong> {{ item.product_type_object.name }}</dt>
        <dt v-if="item.size_object"><strong>Size:</strong> {{ item.size_object.name }}</dt>
        <dt v-if="item.custom_width && item.custom_height"><strong>Custom Size:</strong> {{ item.custom_width }}” x
          {{ item.custom_height }}”
        </dt>
        <dt v-if="item.product_type_object.name !== 'Faux Leather Patch'"><strong>Backings:</strong>
          {{ item.paper_type_object.name }}
        </dt>
        <dt v-if="item.product_type_object.name !== 'Faux Leather Patch'"><strong>Borders:</strong>
          {{ item.border_objects.name }}
        </dt>
        <dt v-if="item.product_type_object.name !== 'Faux Leather Patch'"><strong>Button Loops :</strong>
          {{ item.button_loops ? 'Yes' : 'No' }}
        </dt>
        <dt v-if="item.product_type_object.name !== 'Faux Leather Patch'"><strong>Extra Colors 9+:</strong>
          {{ item.extra_colors ? 'Yes' : 'No' }}
        </dt>
      </div>
    </dl>
  </div>
</template>

<script>
import formSelect from '@/components/common/form/select.vue';
import formInput from '@/components/common/form/input.vue';
import Wysiwyg from '@/components/common/TextEditors/wysiwyg.vue';
import cloneDeep from 'lodash/cloneDeep';

export default {
  name: 'design-info-finishing',
  components: {Wysiwyg, formInput, formSelect},
  props: {
    design: {
      type: Object,
      required: true
    },
    order: {
      type: Object,
      required: true
    },
    mock: {
      type: Object,
      required: true
    },
    options: {
      type: Object,
      required: true
    },
    editable: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      mock_item: {
        location: '',
        width: 0,
        height: 0,
        size: this.getFieldFromMockData('size'),
        folding: this.getFieldFromMockData('folding'),
        thread_colors: this.getFieldFromMockData('thread_colors'),
        location_comment: this.getFieldFromMockData('location_comment')
      }
    };
  },
  methods: {
    getFormData() {
      this.mock_item.location_comment = this.locationComment;
      return [this.mock_item];
    },
    getFieldFromMockData(fieldName) {
      return this.mock &&
      this.mock.items &&
      this.mock.items[0] &&
      this.mock.items[0][fieldName]
        ? cloneDeep(this.mock.items[0][fieldName])
        : '';
    },
    onLocationCommentChanged(newValue) {
      this.item.location_comment = newValue;
      this.mock_item.location_comment = newValue;
    }
  },
  computed: {
    isNew() {
      return this.$route.params.mockId === 'new';
    },
    item() {
      return this.design.designable.item || {};
    },
    locationComment() {
      return this.mock_item.location_comment ? cloneDeep(this.mock_item.location_comment) : '';
    },
    threadColorsObject: {
      get() {
        if (this.isNew) {
          if (this.design.mocks.length === 0) {
            return '';
          } else {
            return this.design.mocks[this.design.mocks.length - 1].items[0].thread_colors;
          }
        }
        return this.mock.items[0].thread_colors;
      },
      set(value) {
        this.mock_item.thread_colors = value;
      }
    },
    sizeObject: {
      get() {
        if (this.isNew) {
          if (this.design.mocks.length === 0) {
            return this.design.designable.item.size;
          }  if (this.design.mocks[this.design.mocks.length - 1].items[0].size) {
            return this.design.mocks[this.design.mocks.length - 1].items[0].size;
          } else {
            return this.design.designable.item.size;
          }
        }
        if (this.mock && this.mock.items && this.mock.items[0].size) {
          return this.mock.items[0].size;
        } else if (this.design.mocks && this.design.mocks[this.design.mocks.length - 1] && this.design.mocks[this.design.mocks.length - 1].items[0].size) {
          return this.design.mocks[this.design.mocks.length - 1].items[0].size;
        } else {
          return this.design.designable.item.size;
        }
      },
      set(value) {
        this.mock_item.size = value;
      }
    },
    foldingObject: {
      get() {
        if (this.mock && this.mock.items && this.mock.items[0].folding) {
          return this.mock.items[0].folding;
        } else if (this.design.mocks && this.design.mocks[this.design.mocks.length - 1] && this.design.mocks[this.design.mocks.length - 1].items[0].folding) {
          return this.design.mocks[this.design.mocks.length - 1].items[0].folding;
        } else {
          return this.design.designable.item.paper_type;
        }
      },
      set(value) {
        this.mock_item.folding = value;
      }
    },
    sizeOptions() {
      let sizeOptions = [];
      if (this.options &&
        this.options.print_type_options &&
        this.options.print_type_options[this.item.print_type] &&
        this.options.print_type_options[this.item.print_type].sizes
      ) {
        sizeOptions = this.options.print_type_options[this.item.print_type].sizes.filter(size => size.key !== 'empty_size');
      }

      return sizeOptions;
    },
    sizeLabel() {
      if (this.options &&
        this.options.print_type_options &&
        this.options.print_type_options[this.item.print_type] &&
        this.options.print_type_options[this.item.print_type].sizes
      ) {
        let size = this.options.print_type_options[this.item.print_type].sizes.filter(size => size.key == this.sizeObject);

        if (size[0]) {
          return size[0].label;
        }
      }
    },
    foldingLabel() {
      if (this.options &&
        this.options.print_type_options &&
        this.options.print_type_options[this.item.print_type] &&
        this.options.print_type_options[this.item.print_type].paper_types
      ) {
        let folding = this.options.print_type_options[this.item.print_type].paper_types.filter(folding => folding.key == this.foldingObject);

        if (folding[0]) {
          return folding[0].label;
        }
      }
    }
  },
  watch: {
    mock: {
      handler: function(mock) {
        if (mock.items) {
          this.$set(this, 'mock_item', mock.items[0]);
        }else{
          this.$set(this, 'mock_item.location_notes', '');
        }
      },
      deep: true
    }
  }
}
;
</script>
